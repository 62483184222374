import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'

export const SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY: { items: ProductKeywordSummary[] } = {
  items: [
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_braces floss',
      keyword: 'braces floss',
      searchVolume: 3644,
      organicStartRank: 91,
      organicHighestRank: 91,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 3644,
          organicRank: 91,

          keywordSales: 278
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_braces toothbrush',
      keyword: 'braces toothbrush',
      searchVolume: 3479,
      organicStartRank: 227,
      organicHighestRank: 102,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-09-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4191,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-09-09',
          searchVolume: 4191,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-09-08',
          searchVolume: 4191,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 168
        },
        {
          date: '2024-09-07',
          searchVolume: 4191,
          organicRank: 303,

          keywordSales: 168
        },
        {
          date: '2024-09-06',
          searchVolume: 4191,
          organicRank: 194,

          keywordSales: 168
        },
        {
          date: '2024-09-05',
          searchVolume: 4191,
          organicRank: 174,

          keywordSales: 168
        },
        {
          date: '2024-09-04',
          searchVolume: 4191,
          organicRank: 206,

          keywordSales: 168
        },
        {
          date: '2024-09-01',
          searchVolume: 4191,
          organicRank: 180,

          keywordSales: 168
        },
        {
          date: '2024-08-28',
          searchVolume: 4549,
          organicRank: 150,

          keywordSales: 168
        },
        {
          date: '2024-08-27',
          searchVolume: 4549,
          organicRank: 187,

          keywordSales: 168
        },
        {
          date: '2024-08-26',
          searchVolume: 4549,
          organicRank: 182,

          keywordSales: 168
        },
        {
          date: '2024-08-25',
          searchVolume: 4549,
          organicRank: 174,

          keywordSales: 168
        },
        {
          date: '2024-08-24',
          searchVolume: 4549,
          organicRank: 170,

          keywordSales: 168
        },
        {
          date: '2024-08-21',
          searchVolume: 4907,
          organicRank: 162,

          keywordSales: 168
        },
        {
          date: '2024-08-20',
          searchVolume: 4907,
          organicRank: 170,

          keywordSales: 168
        },
        {
          date: '2024-08-19',
          searchVolume: 4907,
          organicRank: 172,

          keywordSales: 168
        },
        {
          date: '2024-08-18',
          searchVolume: 4907,
          organicRank: 167,

          keywordSales: 168
        },
        {
          date: '2024-08-15',
          searchVolume: 4693,
          organicRank: 172,

          keywordSales: 168
        },
        {
          date: '2024-08-14',
          searchVolume: 4693,
          organicRank: 177,

          keywordSales: 168
        },
        {
          date: '2024-08-13',
          searchVolume: 4693,
          organicRank: 202,

          keywordSales: 168
        },
        {
          date: '2024-08-12',
          searchVolume: 4693,
          organicRank: 204,

          keywordSales: 168
        },
        {
          date: '2024-08-11',
          searchVolume: 4693,
          organicRank: 201,

          keywordSales: 168
        },
        {
          date: '2024-08-10',
          searchVolume: 4693,
          organicRank: 203,

          keywordSales: 168
        },
        {
          date: '2024-08-09',
          searchVolume: 4585,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-08-08',
          searchVolume: 4585,
          organicRank: 203,

          keywordSales: 168
        },
        {
          date: '2024-08-07',
          searchVolume: 4585,
          organicRank: 206,

          keywordSales: 168
        },
        {
          date: '2024-08-06',
          searchVolume: 4585,
          organicRank: 200,

          keywordSales: 168
        },
        {
          date: '2024-08-05',
          searchVolume: 4585,
          organicRank: 202,

          keywordSales: 168
        },
        {
          date: '2024-08-04',
          searchVolume: 4585,
          organicRank: 197,

          keywordSales: 168
        },
        {
          date: '2024-08-03',
          searchVolume: 4585,
          organicRank: 197,

          keywordSales: 168
        },
        {
          date: '2024-08-02',
          searchVolume: 4204,
          organicRank: 200,

          keywordSales: 168
        },
        {
          date: '2024-08-01',
          searchVolume: 4204,
          organicRank: 202,

          keywordSales: 168
        },
        {
          date: '2024-07-31',
          searchVolume: 4204,
          organicRank: 196,

          keywordSales: 168
        },
        {
          date: '2024-07-30',
          searchVolume: 4204,
          organicRank: 196,

          keywordSales: 168
        },
        {
          date: '2024-07-29',
          searchVolume: 4204,
          organicRank: 193,

          keywordSales: 168
        },
        {
          date: '2024-07-28',
          searchVolume: 4204,
          organicRank: 193,

          keywordSales: 168
        },
        {
          date: '2024-07-27',
          searchVolume: 4204,
          organicRank: 102,

          keywordSales: 168
        },
        {
          date: '2024-07-26',
          searchVolume: 3479,
          organicRank: 189,

          keywordSales: 168
        },
        {
          date: '2024-07-25',
          searchVolume: 3479,
          organicRank: 192,

          keywordSales: 168
        },
        {
          date: '2024-07-24',
          searchVolume: 3479,
          organicRank: 200,

          keywordSales: 168
        },
        {
          date: '2024-07-23',
          searchVolume: 3479,
          organicRank: 210,

          keywordSales: 168
        },
        {
          date: '2024-07-21',
          searchVolume: 3479,
          organicRank: 277,

          keywordSales: 168
        },
        {
          date: '2024-07-20',
          searchVolume: 3479,
          organicRank: 219,

          keywordSales: 168
        },
        {
          date: '2024-07-19',
          searchVolume: 4110,
          organicRank: 227,

          keywordSales: 168
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_clearpik',
      keyword: 'clearpik',
      searchVolume: 642,
      organicStartRank: 44,
      organicHighestRank: 7,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 582,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-09-09',
          searchVolume: 582,
          organicRank: 306,

          keywordSales: 12
        },
        {
          date: '2024-09-08',
          searchVolume: 582,
          organicRank: 9,

          keywordSales: 12
        },
        {
          date: '2024-09-07',
          searchVolume: 582,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-09-06',
          searchVolume: 582,
          organicRank: 8,
          sponsoredRank: 26,
          keywordSales: 12
        },
        {
          date: '2024-09-05',
          searchVolume: 582,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-09-04',
          searchVolume: 582,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-09-02',
          searchVolume: 582,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-29',
          searchVolume: 886,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-28',
          searchVolume: 886,
          organicRank: 9,

          keywordSales: 12
        },
        {
          date: '2024-08-27',
          searchVolume: 886,
          organicRank: 10,

          keywordSales: 12
        },
        {
          date: '2024-08-26',
          searchVolume: 886,
          organicRank: 10,

          keywordSales: 12
        },
        {
          date: '2024-08-25',
          searchVolume: 886,
          organicRank: 12,

          keywordSales: 12
        },
        {
          date: '2024-08-24',
          searchVolume: 886,
          organicRank: 9,

          keywordSales: 12
        },
        {
          date: '2024-08-22',
          searchVolume: 884,
          organicRank: 11,

          keywordSales: 12
        },
        {
          date: '2024-08-21',
          searchVolume: 884,
          organicRank: 7,

          keywordSales: 12
        },
        {
          date: '2024-08-20',
          searchVolume: 884,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-19',
          searchVolume: 884,
          organicRank: 7,

          keywordSales: 12
        },
        {
          date: '2024-08-18',
          searchVolume: 884,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-16',
          searchVolume: 642,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-15',
          searchVolume: 642,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-14',
          searchVolume: 642,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 12
        },
        {
          date: '2024-08-13',
          searchVolume: 642,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-12',
          searchVolume: 642,
          organicRank: 7,

          keywordSales: 12
        },
        {
          date: '2024-08-11',
          searchVolume: 642,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 12
        },
        {
          date: '2024-08-10',
          searchVolume: 642,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-09',
          searchVolume: 698,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-08',
          searchVolume: 698,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 12
        },
        {
          date: '2024-08-07',
          searchVolume: 698,
          organicRank: 17,

          keywordSales: 12
        },
        {
          date: '2024-08-06',
          searchVolume: 698,
          organicRank: 20,

          keywordSales: 12
        },
        {
          date: '2024-08-05',
          searchVolume: 698,
          organicRank: 21,

          keywordSales: 12
        },
        {
          date: '2024-08-04',
          searchVolume: 698,
          organicRank: 22,

          keywordSales: 12
        },
        {
          date: '2024-08-03',
          searchVolume: 698,
          organicRank: 20,

          keywordSales: 12
        },
        {
          date: '2024-08-02',
          searchVolume: 818,
          organicRank: 22,
          sponsoredRank: 20,
          keywordSales: 12
        },
        {
          date: '2024-08-01',
          searchVolume: 818,
          organicRank: 27,
          sponsoredRank: 3,
          keywordSales: 12
        },
        {
          date: '2024-07-31',
          searchVolume: 818,
          organicRank: 27,

          keywordSales: 12
        },
        {
          date: '2024-07-30',
          searchVolume: 818,
          organicRank: 28,

          keywordSales: 12
        },
        {
          date: '2024-07-29',
          searchVolume: 818,
          organicRank: 29,

          keywordSales: 12
        },
        {
          date: '2024-07-28',
          searchVolume: 818,
          organicRank: 28,

          keywordSales: 12
        },
        {
          date: '2024-07-27',
          searchVolume: 818,
          organicRank: 27,
          sponsoredRank: 6,
          keywordSales: 12
        },
        {
          date: '2024-07-26',
          searchVolume: 642,
          organicRank: 26,

          keywordSales: 12
        },
        {
          date: '2024-07-25',
          searchVolume: 642,
          organicRank: 25,

          keywordSales: 12
        },
        {
          date: '2024-07-24',
          searchVolume: 642,
          organicRank: 26,

          keywordSales: 12
        },
        {
          date: '2024-07-23',
          searchVolume: 642,
          organicRank: 29,

          keywordSales: 12
        },
        {
          date: '2024-07-22',
          searchVolume: 642,
          organicRank: 29,

          keywordSales: 12
        },
        {
          date: '2024-07-21',
          searchVolume: 642,
          organicRank: 30,

          keywordSales: 12
        },
        {
          date: '2024-07-20',
          searchVolume: 642,
          organicRank: 33,

          keywordSales: 12
        },
        {
          date: '2024-07-19',
          searchVolume: 832,
          organicRank: 33,

          keywordSales: 12
        },
        {
          date: '2024-07-18',
          searchVolume: 832,
          organicRank: 44,

          keywordSales: 12
        },
        {
          date: '2024-07-17',
          searchVolume: 832,
          organicRank: 45,

          keywordSales: 12
        },
        {
          date: '2024-07-16',
          searchVolume: 832,
          organicRank: 38,

          keywordSales: 12
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_cordless water flosser',
      keyword: 'cordless water flosser',
      searchVolume: 10244,
      organicStartRank: 16,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 5780,
          organicRank: 5,

          keywordSales: 259
        },
        {
          date: '2024-09-09',
          searchVolume: 5780,
          organicRank: 5,

          keywordSales: 259
        },
        {
          date: '2024-09-08',
          searchVolume: 5780,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-09-07',
          searchVolume: 5780,
          organicRank: 6,

          keywordSales: 259
        },
        {
          date: '2024-09-06',
          searchVolume: 5780,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-09-05',
          searchVolume: 5780,
          organicRank: 5,

          keywordSales: 259
        },
        {
          date: '2024-09-01',
          searchVolume: 5780,
          organicRank: 3,
          sponsoredRank: 31,
          keywordSales: 259
        },
        {
          date: '2024-08-31',
          searchVolume: 5780,
          organicRank: 3,

          keywordSales: 259
        },
        {
          date: '2024-08-28',
          searchVolume: 6740,
          organicRank: 3,

          keywordSales: 259
        },
        {
          date: '2024-08-27',
          searchVolume: 6740,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 259
        },
        {
          date: '2024-08-26',
          searchVolume: 6740,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-08-25',
          searchVolume: 6740,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-08-24',
          searchVolume: 6740,
          organicRank: 4,
          sponsoredRank: 5,
          keywordSales: 259
        },
        {
          date: '2024-08-23',
          searchVolume: 6751,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-08-22',
          searchVolume: 6751,
          organicRank: 4,
          sponsoredRank: 6,
          keywordSales: 259
        },
        {
          date: '2024-08-20',
          searchVolume: 6751,
          organicRank: 4,
          sponsoredRank: 8,
          keywordSales: 259
        },
        {
          date: '2024-08-19',
          searchVolume: 6751,
          organicRank: 2,

          keywordSales: 259
        },
        {
          date: '2024-08-17',
          searchVolume: 6751,
          organicRank: 3,
          sponsoredRank: 30,
          keywordSales: 259
        },
        {
          date: '2024-08-16',
          searchVolume: 5175,
          organicRank: 3,
          sponsoredRank: 59,
          keywordSales: 259
        },
        {
          date: '2024-08-15',
          searchVolume: 5175,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 259
        },
        {
          date: '2024-08-14',
          searchVolume: 5175,
          organicRank: 3,
          sponsoredRank: 41,
          keywordSales: 259
        },
        {
          date: '2024-08-13',
          searchVolume: 5175,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 259
        },
        {
          date: '2024-08-12',
          searchVolume: 5175,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 259
        },
        {
          date: '2024-08-11',
          searchVolume: 5175,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-10',
          searchVolume: 5175,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-09',
          searchVolume: 5556,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-08',
          searchVolume: 5556,
          organicRank: 2,

          keywordSales: 259
        },
        {
          date: '2024-08-07',
          searchVolume: 5556,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-06',
          searchVolume: 5556,
          organicRank: 2,

          keywordSales: 259
        },
        {
          date: '2024-08-05',
          searchVolume: 5556,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 259
        },
        {
          date: '2024-08-04',
          searchVolume: 5556,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-03',
          searchVolume: 5556,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-08-02',
          searchVolume: 5260,
          organicRank: 2,

          keywordSales: 259
        },
        {
          date: '2024-08-01',
          searchVolume: 5260,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-07-31',
          searchVolume: 5260,
          organicRank: 2,

          keywordSales: 259
        },
        {
          date: '2024-07-30',
          searchVolume: 5260,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-07-29',
          searchVolume: 5260,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 259
        },
        {
          date: '2024-07-28',
          searchVolume: 5260,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 259
        },
        {
          date: '2024-07-27',
          searchVolume: 5260,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 259
        },
        {
          date: '2024-07-26',
          searchVolume: 10244,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 259
        },
        {
          date: '2024-07-25',
          searchVolume: 10244,
          organicRank: 4,

          keywordSales: 259
        },
        {
          date: '2024-07-24',
          searchVolume: 10244,
          organicRank: 6,

          keywordSales: 259
        },
        {
          date: '2024-07-23',
          searchVolume: 10244,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 259
        },
        {
          date: '2024-07-22',
          searchVolume: 10244,
          organicRank: 8,

          keywordSales: 259
        },
        {
          date: '2024-07-21',
          searchVolume: 10244,
          organicRank: 9,

          keywordSales: 259
        },
        {
          date: '2024-07-20',
          searchVolume: 10244,
          organicRank: 7,

          keywordSales: 259
        },
        {
          date: '2024-07-19',
          searchVolume: 4603,
          organicRank: 11,

          keywordSales: 259
        },
        {
          date: '2024-07-18',
          searchVolume: 4603,
          organicRank: 16,

          keywordSales: 259
        },
        {
          date: '2024-07-17',
          searchVolume: 4603,
          organicRank: 14,

          keywordSales: 259
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_cordless waterpik',
      keyword: 'cordless waterpik',
      searchVolume: 8611,
      organicStartRank: 3,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4203,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-09-09',
          searchVolume: 4203,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-09-08',
          searchVolume: 4203,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-09-07',
          searchVolume: 4203,
          organicRank: 2,

          keywordSales: 193
        },
        {
          date: '2024-09-06',
          searchVolume: 4203,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-09-05',
          searchVolume: 4203,
          organicRank: 1,
          sponsoredRank: 9,
          keywordSales: 193
        },
        {
          date: '2024-09-04',
          searchVolume: 4203,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-09-01',
          searchVolume: 4203,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 193
        },
        {
          date: '2024-08-29',
          searchVolume: 4547,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-08-27',
          searchVolume: 4547,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 193
        },
        {
          date: '2024-08-26',
          searchVolume: 4547,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-08-25',
          searchVolume: 4547,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-24',
          searchVolume: 4547,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-23',
          searchVolume: 4550,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-08-22',
          searchVolume: 4550,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-21',
          searchVolume: 4550,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 193
        },
        {
          date: '2024-08-20',
          searchVolume: 4550,
          organicRank: 1,
          sponsoredRank: 32,
          keywordSales: 193
        },
        {
          date: '2024-08-19',
          searchVolume: 4550,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-17',
          searchVolume: 4550,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 193
        },
        {
          date: '2024-08-16',
          searchVolume: 4170,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-15',
          searchVolume: 4170,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-14',
          searchVolume: 4170,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-13',
          searchVolume: 4170,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-12',
          searchVolume: 4170,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-11',
          searchVolume: 4170,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-10',
          searchVolume: 4170,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-09',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-08',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-07',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-06',
          searchVolume: 4237,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-05',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-04',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-08-03',
          searchVolume: 4237,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-08-02',
          searchVolume: 3991,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-08-01',
          searchVolume: 3991,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-07-31',
          searchVolume: 3991,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-07-30',
          searchVolume: 3991,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 193
        },
        {
          date: '2024-07-29',
          searchVolume: 3991,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-28',
          searchVolume: 3991,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-27',
          searchVolume: 3991,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-26',
          searchVolume: 8611,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-25',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-07-24',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-07-23',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 193
        },
        {
          date: '2024-07-22',
          searchVolume: 8611,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-21',
          searchVolume: 8611,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 193
        },
        {
          date: '2024-07-20',
          searchVolume: 8611,
          organicRank: 2,

          keywordSales: 193
        },
        {
          date: '2024-07-19',
          searchVolume: 4272,
          organicRank: 3,

          keywordSales: 193
        },
        {
          date: '2024-07-18',
          searchVolume: 4272,
          organicRank: 3,

          keywordSales: 193
        },
        {
          date: '2024-07-17',
          searchVolume: 4272,
          organicRank: 3,

          keywordSales: 193
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental brushes between teeth',
      keyword: 'dental brushes between teeth',
      searchVolume: 2262,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 22,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-09',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-08',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-07',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-06',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-05',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-04',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-09-01',
          searchVolume: 2315,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-29',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-27',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-26',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-25',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-24',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-22',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-21',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-20',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-19',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-18',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-16',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-15',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-14',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-13',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-12',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-11',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-10',
          searchVolume: 2284,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-09',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-08',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-07',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-06',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-05',
          searchVolume: 2262,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 119
        },
        {
          date: '2024-08-04',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-03',
          searchVolume: 2262,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-02',
          searchVolume: 1777,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-01',
          searchVolume: 1777,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-31',
          searchVolume: 1777,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-30',
          searchVolume: 1764,
          organicRank: 306,

          keywordSales: 133
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental cleaning kit',
      keyword: 'dental cleaning kit',
      searchVolume: 4189,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4189,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-09-09',
          searchVolume: 4189,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-09-08',
          searchVolume: 4189,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-09-07',
          searchVolume: 4189,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 188
        },
        {
          date: '2024-09-05',
          searchVolume: 4189,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-28',
          searchVolume: 3863,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-27',
          searchVolume: 3863,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-26',
          searchVolume: 3863,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-25',
          searchVolume: 3863,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-24',
          searchVolume: 3863,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-23',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-22',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-21',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-20',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-18',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-15',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-14',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-13',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-12',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-11',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-10',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-09',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-08',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-07',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-06',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-05',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-04',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-03',
          searchVolume: 3556,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-02',
          searchVolume: 3352,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-08-01',
          searchVolume: 3352,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-07-31',
          searchVolume: 3352,
          organicRank: 306,

          keywordSales: 188
        },
        {
          date: '2024-07-30',
          searchVolume: 3002,
          organicRank: 306,

          keywordSales: 196
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental cleaning tools',
      keyword: 'dental cleaning tools',
      searchVolume: 1843,
      organicStartRank: 154,
      organicHighestRank: 82,
      sponsoredStartRank: 45,
      sponsoredHighestRank: 45,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2315,
          organicRank: 110,

          keywordSales: 85
        },
        {
          date: '2024-09-09',
          searchVolume: 2315,
          organicRank: 144,

          keywordSales: 85
        },
        {
          date: '2024-09-08',
          searchVolume: 2315,
          organicRank: 143,

          keywordSales: 85
        },
        {
          date: '2024-09-07',
          searchVolume: 2315,
          organicRank: 145,

          keywordSales: 85
        },
        {
          date: '2024-09-06',
          searchVolume: 2315,
          organicRank: 141,

          keywordSales: 85
        },
        {
          date: '2024-09-05',
          searchVolume: 2315,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 85
        },
        {
          date: '2024-09-03',
          searchVolume: 2315,
          organicRank: 151,

          keywordSales: 85
        },
        {
          date: '2024-09-01',
          searchVolume: 2315,
          organicRank: 150,

          keywordSales: 85
        },
        {
          date: '2024-08-28',
          searchVolume: 2428,
          organicRank: 122,

          keywordSales: 85
        },
        {
          date: '2024-08-27',
          searchVolume: 2428,
          organicRank: 116,

          keywordSales: 85
        },
        {
          date: '2024-08-26',
          searchVolume: 2428,
          organicRank: 82,

          keywordSales: 85
        },
        {
          date: '2024-08-25',
          searchVolume: 2428,
          organicRank: 107,

          keywordSales: 85
        },
        {
          date: '2024-08-24',
          searchVolume: 2428,
          organicRank: 107,

          keywordSales: 85
        },
        {
          date: '2024-08-23',
          searchVolume: 1924,
          organicRank: 104,

          keywordSales: 85
        },
        {
          date: '2024-08-22',
          searchVolume: 1924,
          organicRank: 124,

          keywordSales: 85
        },
        {
          date: '2024-08-20',
          searchVolume: 1924,
          organicRank: 178,

          keywordSales: 85
        },
        {
          date: '2024-08-19',
          searchVolume: 1924,
          organicRank: 141,

          keywordSales: 85
        },
        {
          date: '2024-08-18',
          searchVolume: 1924,
          organicRank: 145,

          keywordSales: 85
        },
        {
          date: '2024-08-16',
          searchVolume: 1839,
          organicRank: 139,

          keywordSales: 85
        },
        {
          date: '2024-08-15',
          searchVolume: 1839,
          organicRank: 141,

          keywordSales: 85
        },
        {
          date: '2024-08-14',
          searchVolume: 1839,
          organicRank: 99,

          keywordSales: 85
        },
        {
          date: '2024-08-13',
          searchVolume: 1839,
          organicRank: 101,

          keywordSales: 85
        },
        {
          date: '2024-08-12',
          searchVolume: 1839,
          organicRank: 100,

          keywordSales: 85
        },
        {
          date: '2024-08-11',
          searchVolume: 1839,
          organicRank: 106,

          keywordSales: 85
        },
        {
          date: '2024-08-10',
          searchVolume: 1839,
          organicRank: 108,

          keywordSales: 85
        },
        {
          date: '2024-08-09',
          searchVolume: 1796,
          organicRank: 116,

          keywordSales: 85
        },
        {
          date: '2024-08-08',
          searchVolume: 1796,
          organicRank: 112,

          keywordSales: 85
        },
        {
          date: '2024-08-07',
          searchVolume: 1796,
          organicRank: 112,

          keywordSales: 85
        },
        {
          date: '2024-08-06',
          searchVolume: 1796,
          organicRank: 94,

          keywordSales: 85
        },
        {
          date: '2024-08-05',
          searchVolume: 1796,
          organicRank: 96,

          keywordSales: 85
        },
        {
          date: '2024-08-04',
          searchVolume: 1796,
          organicRank: 103,

          keywordSales: 85
        },
        {
          date: '2024-08-03',
          searchVolume: 1796,
          organicRank: 102,

          keywordSales: 85
        },
        {
          date: '2024-08-02',
          searchVolume: 1782,
          organicRank: 108,

          keywordSales: 85
        },
        {
          date: '2024-08-01',
          searchVolume: 1782,
          organicRank: 109,

          keywordSales: 85
        },
        {
          date: '2024-07-31',
          searchVolume: 1782,
          organicRank: 120,

          keywordSales: 85
        },
        {
          date: '2024-07-30',
          searchVolume: 1782,
          organicRank: 109,

          keywordSales: 85
        },
        {
          date: '2024-07-29',
          searchVolume: 1782,
          organicRank: 104,

          keywordSales: 85
        },
        {
          date: '2024-07-28',
          searchVolume: 1782,
          organicRank: 114,

          keywordSales: 85
        },
        {
          date: '2024-07-27',
          searchVolume: 1782,
          organicRank: 118,

          keywordSales: 85
        },
        {
          date: '2024-07-26',
          searchVolume: 1843,
          organicRank: 116,

          keywordSales: 85
        },
        {
          date: '2024-07-25',
          searchVolume: 1843,
          organicRank: 111,

          keywordSales: 85
        },
        {
          date: '2024-07-24',
          searchVolume: 1843,
          organicRank: 118,

          keywordSales: 85
        },
        {
          date: '2024-07-23',
          searchVolume: 1843,
          organicRank: 122,

          keywordSales: 85
        },
        {
          date: '2024-07-22',
          searchVolume: 1843,
          organicRank: 130,

          keywordSales: 85
        },
        {
          date: '2024-07-21',
          searchVolume: 1843,
          organicRank: 127,

          keywordSales: 85
        },
        {
          date: '2024-07-20',
          searchVolume: 1843,
          organicRank: 124,

          keywordSales: 85
        },
        {
          date: '2024-07-19',
          searchVolume: 2052,
          organicRank: 140,

          keywordSales: 85
        },
        {
          date: '2024-07-18',
          searchVolume: 2052,
          organicRank: 154,

          keywordSales: 85
        },
        {
          date: '2024-07-17',
          searchVolume: 2052,
          organicRank: 154,

          keywordSales: 85
        },
        {
          date: '2024-07-16',
          searchVolume: 2052,
          organicRank: 140,

          keywordSales: 85
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental floss for braces',
      keyword: 'dental floss for braces',
      searchVolume: 1830,
      organicStartRank: 216,
      organicHighestRank: 48,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1876,
          organicRank: 168,

          keywordSales: 119
        },
        {
          date: '2024-09-09',
          searchVolume: 1876,
          organicRank: 152,

          keywordSales: 119
        },
        {
          date: '2024-09-08',
          searchVolume: 1876,
          organicRank: 172,

          keywordSales: 119
        },
        {
          date: '2024-09-07',
          searchVolume: 1876,
          organicRank: 242,
          sponsoredRank: 24,
          keywordSales: 119
        },
        {
          date: '2024-09-06',
          searchVolume: 1876,
          organicRank: 161,

          keywordSales: 119
        },
        {
          date: '2024-09-05',
          searchVolume: 1876,
          organicRank: 48,

          keywordSales: 119
        },
        {
          date: '2024-09-04',
          searchVolume: 1876,
          organicRank: 52,

          keywordSales: 119
        },
        {
          date: '2024-09-02',
          searchVolume: 1876,
          organicRank: 150,

          keywordSales: 119
        },
        {
          date: '2024-08-29',
          searchVolume: 1934,
          organicRank: 129,

          keywordSales: 119
        },
        {
          date: '2024-08-28',
          searchVolume: 1934,
          organicRank: 241,

          keywordSales: 119
        },
        {
          date: '2024-08-27',
          searchVolume: 1934,
          organicRank: 127,

          keywordSales: 119
        },
        {
          date: '2024-08-26',
          searchVolume: 1934,
          organicRank: 132,

          keywordSales: 119
        },
        {
          date: '2024-08-25',
          searchVolume: 1934,
          organicRank: 237,

          keywordSales: 119
        },
        {
          date: '2024-08-24',
          searchVolume: 1934,
          organicRank: 82,

          keywordSales: 119
        },
        {
          date: '2024-08-22',
          searchVolume: 1928,
          organicRank: 236,

          keywordSales: 119
        },
        {
          date: '2024-08-21',
          searchVolume: 1928,
          organicRank: 135,

          keywordSales: 119
        },
        {
          date: '2024-08-20',
          searchVolume: 1928,
          organicRank: 139,

          keywordSales: 119
        },
        {
          date: '2024-08-19',
          searchVolume: 1928,
          organicRank: 111,

          keywordSales: 119
        },
        {
          date: '2024-08-18',
          searchVolume: 1928,
          organicRank: 124,

          keywordSales: 119
        },
        {
          date: '2024-08-16',
          searchVolume: 1839,
          organicRank: 122,

          keywordSales: 119
        },
        {
          date: '2024-08-15',
          searchVolume: 1839,
          organicRank: 122,

          keywordSales: 119
        },
        {
          date: '2024-08-14',
          searchVolume: 1839,
          organicRank: 124,

          keywordSales: 119
        },
        {
          date: '2024-08-13',
          searchVolume: 1839,
          organicRank: 130,

          keywordSales: 119
        },
        {
          date: '2024-08-12',
          searchVolume: 1839,
          organicRank: 126,

          keywordSales: 119
        },
        {
          date: '2024-08-11',
          searchVolume: 1839,
          organicRank: 160,

          keywordSales: 119
        },
        {
          date: '2024-08-10',
          searchVolume: 1839,
          organicRank: 162,

          keywordSales: 119
        },
        {
          date: '2024-08-09',
          searchVolume: 2191,
          organicRank: 257,

          keywordSales: 119
        },
        {
          date: '2024-08-08',
          searchVolume: 2191,
          organicRank: 132,
          sponsoredRank: 1,
          keywordSales: 119
        },
        {
          date: '2024-08-07',
          searchVolume: 2191,
          organicRank: 130,

          keywordSales: 119
        },
        {
          date: '2024-08-06',
          searchVolume: 2191,
          organicRank: 170,

          keywordSales: 119
        },
        {
          date: '2024-08-05',
          searchVolume: 2191,
          organicRank: 169,

          keywordSales: 119
        },
        {
          date: '2024-08-04',
          searchVolume: 2191,
          organicRank: 160,

          keywordSales: 119
        },
        {
          date: '2024-08-03',
          searchVolume: 2191,
          organicRank: 154,

          keywordSales: 119
        },
        {
          date: '2024-08-02',
          searchVolume: 2018,
          organicRank: 164,

          keywordSales: 119
        },
        {
          date: '2024-08-01',
          searchVolume: 2018,
          organicRank: 167,

          keywordSales: 119
        },
        {
          date: '2024-07-31',
          searchVolume: 2018,
          organicRank: 162,

          keywordSales: 119
        },
        {
          date: '2024-07-30',
          searchVolume: 2018,
          organicRank: 159,

          keywordSales: 119
        },
        {
          date: '2024-07-29',
          searchVolume: 2018,
          organicRank: 161,

          keywordSales: 119
        },
        {
          date: '2024-07-28',
          searchVolume: 2018,
          organicRank: 149,

          keywordSales: 119
        },
        {
          date: '2024-07-27',
          searchVolume: 2018,
          organicRank: 154,
          sponsoredRank: 13,
          keywordSales: 119
        },
        {
          date: '2024-07-26',
          searchVolume: 1830,
          organicRank: 146,

          keywordSales: 119
        },
        {
          date: '2024-07-25',
          searchVolume: 1830,
          organicRank: 160,

          keywordSales: 119
        },
        {
          date: '2024-07-24',
          searchVolume: 1830,
          organicRank: 154,

          keywordSales: 119
        },
        {
          date: '2024-07-23',
          searchVolume: 1830,
          organicRank: 183,

          keywordSales: 119
        },
        {
          date: '2024-07-22',
          searchVolume: 1830,
          organicRank: 193,

          keywordSales: 119
        },
        {
          date: '2024-07-21',
          searchVolume: 1830,
          organicRank: 251,

          keywordSales: 119
        },
        {
          date: '2024-07-20',
          searchVolume: 1830,
          organicRank: 208,

          keywordSales: 119
        },
        {
          date: '2024-07-19',
          searchVolume: 2052,
          organicRank: 216,

          keywordSales: 119
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental floss picks',
      keyword: 'dental floss picks',
      searchVolume: 38672,
      organicStartRank: 232,
      organicHighestRank: 228,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-09',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-08',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-07',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-06',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-05',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-09-01',
          searchVolume: 54767,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-28',
          searchVolume: 56500,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-27',
          searchVolume: 56500,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-26',
          searchVolume: 56500,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-25',
          searchVolume: 56500,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-24',
          searchVolume: 56500,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-20',
          searchVolume: 56461,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-19',
          searchVolume: 56461,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-18',
          searchVolume: 56461,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-16',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-15',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-14',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-13',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-12',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-11',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-10',
          searchVolume: 53914,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-09',
          searchVolume: 50030,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-08',
          searchVolume: 50030,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 1592
        },
        {
          date: '2024-08-07',
          searchVolume: 50030,
          organicRank: 286,

          keywordSales: 1592
        },
        {
          date: '2024-08-06',
          searchVolume: 50030,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-05',
          searchVolume: 50030,
          organicRank: 306,

          keywordSales: 1592
        },
        {
          date: '2024-08-04',
          searchVolume: 50030,
          organicRank: 295,

          keywordSales: 1592
        },
        {
          date: '2024-08-03',
          searchVolume: 50030,
          organicRank: 274,

          keywordSales: 1592
        },
        {
          date: '2024-08-02',
          searchVolume: 49501,
          organicRank: 295,

          keywordSales: 1592
        },
        {
          date: '2024-08-01',
          searchVolume: 49501,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 1592
        },
        {
          date: '2024-07-31',
          searchVolume: 49501,
          organicRank: 250,

          keywordSales: 1592
        },
        {
          date: '2024-07-30',
          searchVolume: 49501,
          organicRank: 251,

          keywordSales: 1592
        },
        {
          date: '2024-07-29',
          searchVolume: 49501,
          organicRank: 265,

          keywordSales: 1592
        },
        {
          date: '2024-07-28',
          searchVolume: 49501,
          organicRank: 228,

          keywordSales: 1592
        },
        {
          date: '2024-07-27',
          searchVolume: 49501,
          organicRank: 232,

          keywordSales: 1592
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental flossers',
      keyword: 'dental flossers',
      searchVolume: 3463,
      organicStartRank: 287,
      organicHighestRank: 60,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4189,
          organicRank: 226,

          keywordSales: 294
        },
        {
          date: '2024-09-09',
          searchVolume: 4189,
          organicRank: 306,

          keywordSales: 294
        },
        {
          date: '2024-09-08',
          searchVolume: 4189,
          organicRank: 204,
          sponsoredRank: 52,
          keywordSales: 294
        },
        {
          date: '2024-09-07',
          searchVolume: 4189,
          organicRank: 101,

          keywordSales: 294
        },
        {
          date: '2024-09-06',
          searchVolume: 4189,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 294
        },
        {
          date: '2024-09-04',
          searchVolume: 4189,
          organicRank: 101,

          keywordSales: 294
        },
        {
          date: '2024-09-03',
          searchVolume: 4189,
          organicRank: 108,

          keywordSales: 294
        },
        {
          date: '2024-08-31',
          searchVolume: 4189,
          organicRank: 112,

          keywordSales: 294
        },
        {
          date: '2024-08-28',
          searchVolume: 4322,
          organicRank: 71,

          keywordSales: 294
        },
        {
          date: '2024-08-27',
          searchVolume: 4322,
          organicRank: 60,
          sponsoredRank: 6,
          keywordSales: 294
        },
        {
          date: '2024-08-26',
          searchVolume: 4322,
          organicRank: 82,

          keywordSales: 294
        },
        {
          date: '2024-08-25',
          searchVolume: 4322,
          organicRank: 87,

          keywordSales: 294
        },
        {
          date: '2024-08-24',
          searchVolume: 4322,
          organicRank: 90,

          keywordSales: 294
        },
        {
          date: '2024-08-23',
          searchVolume: 4237,
          organicRank: 89,

          keywordSales: 294
        },
        {
          date: '2024-08-22',
          searchVolume: 4237,
          organicRank: 72,

          keywordSales: 294
        },
        {
          date: '2024-08-21',
          searchVolume: 4237,
          organicRank: 69,

          keywordSales: 294
        },
        {
          date: '2024-08-20',
          searchVolume: 4237,
          organicRank: 94,

          keywordSales: 294
        },
        {
          date: '2024-08-19',
          searchVolume: 4237,
          organicRank: 73,

          keywordSales: 294
        },
        {
          date: '2024-08-17',
          searchVolume: 4237,
          organicRank: 99,

          keywordSales: 294
        },
        {
          date: '2024-08-16',
          searchVolume: 3671,
          organicRank: 96,

          keywordSales: 294
        },
        {
          date: '2024-08-15',
          searchVolume: 3671,
          organicRank: 100,

          keywordSales: 294
        },
        {
          date: '2024-08-14',
          searchVolume: 3671,
          organicRank: 96,

          keywordSales: 294
        },
        {
          date: '2024-08-13',
          searchVolume: 3671,
          organicRank: 89,

          keywordSales: 294
        },
        {
          date: '2024-08-12',
          searchVolume: 3671,
          organicRank: 90,

          keywordSales: 294
        },
        {
          date: '2024-08-11',
          searchVolume: 3671,
          organicRank: 88,

          keywordSales: 294
        },
        {
          date: '2024-08-10',
          searchVolume: 3671,
          organicRank: 93,

          keywordSales: 294
        },
        {
          date: '2024-08-09',
          searchVolume: 3241,
          organicRank: 85,

          keywordSales: 294
        },
        {
          date: '2024-08-08',
          searchVolume: 3241,
          organicRank: 84,

          keywordSales: 294
        },
        {
          date: '2024-08-07',
          searchVolume: 3241,
          organicRank: 86,

          keywordSales: 294
        },
        {
          date: '2024-08-06',
          searchVolume: 3241,
          organicRank: 80,

          keywordSales: 294
        },
        {
          date: '2024-08-05',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 294
        },
        {
          date: '2024-08-04',
          searchVolume: 3241,
          organicRank: 159,

          keywordSales: 294
        },
        {
          date: '2024-08-02',
          searchVolume: 3181,
          organicRank: 186,

          keywordSales: 294
        },
        {
          date: '2024-08-01',
          searchVolume: 3181,
          organicRank: 184,

          keywordSales: 294
        },
        {
          date: '2024-07-31',
          searchVolume: 3181,
          organicRank: 252,

          keywordSales: 294
        },
        {
          date: '2024-07-30',
          searchVolume: 3181,
          organicRank: 195,

          keywordSales: 294
        },
        {
          date: '2024-07-29',
          searchVolume: 3181,
          organicRank: 178,

          keywordSales: 294
        },
        {
          date: '2024-07-27',
          searchVolume: 3181,
          organicRank: 125,

          keywordSales: 294
        },
        {
          date: '2024-07-26',
          searchVolume: 3463,
          organicRank: 127,

          keywordSales: 294
        },
        {
          date: '2024-07-25',
          searchVolume: 3463,
          organicRank: 191,

          keywordSales: 294
        },
        {
          date: '2024-07-24',
          searchVolume: 3463,
          organicRank: 191,

          keywordSales: 294
        },
        {
          date: '2024-07-20',
          searchVolume: 3463,
          organicRank: 287,

          keywordSales: 294
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental flossers for adults',
      keyword: 'dental flossers for adults',
      searchVolume: 886,
      organicStartRank: 306,
      organicHighestRank: 14,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1181,
          organicRank: 71,

          keywordSales: 67
        },
        {
          date: '2024-09-09',
          searchVolume: 1181,
          organicRank: 76,

          keywordSales: 67
        },
        {
          date: '2024-09-08',
          searchVolume: 1181,
          organicRank: 63,

          keywordSales: 67
        },
        {
          date: '2024-09-07',
          searchVolume: 1181,
          organicRank: 66,
          sponsoredRank: 21,
          keywordSales: 67
        },
        {
          date: '2024-09-06',
          searchVolume: 1181,
          organicRank: 64,

          keywordSales: 67
        },
        {
          date: '2024-09-05',
          searchVolume: 1181,
          organicRank: 63,

          keywordSales: 67
        },
        {
          date: '2024-09-04',
          searchVolume: 1181,
          organicRank: 53,

          keywordSales: 67
        },
        {
          date: '2024-09-02',
          searchVolume: 1181,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-08-29',
          searchVolume: 1222,
          organicRank: 38,

          keywordSales: 67
        },
        {
          date: '2024-08-28',
          searchVolume: 1222,
          organicRank: 39,

          keywordSales: 67
        },
        {
          date: '2024-08-27',
          searchVolume: 1222,
          organicRank: 36,

          keywordSales: 67
        },
        {
          date: '2024-08-26',
          searchVolume: 1222,
          organicRank: 39,

          keywordSales: 67
        },
        {
          date: '2024-08-25',
          searchVolume: 1222,
          organicRank: 36,

          keywordSales: 67
        },
        {
          date: '2024-08-24',
          searchVolume: 1222,
          organicRank: 37,

          keywordSales: 67
        },
        {
          date: '2024-08-22',
          searchVolume: 1221,
          organicRank: 34,

          keywordSales: 67
        },
        {
          date: '2024-08-21',
          searchVolume: 1221,
          organicRank: 27,

          keywordSales: 67
        },
        {
          date: '2024-08-20',
          searchVolume: 1221,
          organicRank: 28,

          keywordSales: 67
        },
        {
          date: '2024-08-19',
          searchVolume: 1221,
          organicRank: 25,

          keywordSales: 67
        },
        {
          date: '2024-08-18',
          searchVolume: 1221,
          organicRank: 25,

          keywordSales: 67
        },
        {
          date: '2024-08-16',
          searchVolume: 773,
          organicRank: 22,

          keywordSales: 67
        },
        {
          date: '2024-08-15',
          searchVolume: 773,
          organicRank: 20,

          keywordSales: 67
        },
        {
          date: '2024-08-14',
          searchVolume: 773,
          organicRank: 18,

          keywordSales: 67
        },
        {
          date: '2024-08-13',
          searchVolume: 773,
          organicRank: 19,

          keywordSales: 67
        },
        {
          date: '2024-08-12',
          searchVolume: 773,
          organicRank: 18,

          keywordSales: 67
        },
        {
          date: '2024-08-11',
          searchVolume: 773,
          organicRank: 23,

          keywordSales: 67
        },
        {
          date: '2024-08-10',
          searchVolume: 773,
          organicRank: 20,

          keywordSales: 67
        },
        {
          date: '2024-08-09',
          searchVolume: 886,
          organicRank: 21,

          keywordSales: 67
        },
        {
          date: '2024-08-08',
          searchVolume: 886,
          organicRank: 14,
          sponsoredRank: 4,
          keywordSales: 67
        },
        {
          date: '2024-08-07',
          searchVolume: 886,
          organicRank: 22,

          keywordSales: 67
        },
        {
          date: '2024-08-06',
          searchVolume: 886,
          organicRank: 15,

          keywordSales: 67
        },
        {
          date: '2024-08-05',
          searchVolume: 886,
          organicRank: 27,

          keywordSales: 67
        },
        {
          date: '2024-08-04',
          searchVolume: 886,
          organicRank: 47,

          keywordSales: 67
        },
        {
          date: '2024-08-03',
          searchVolume: 886,
          organicRank: 48,

          keywordSales: 67
        },
        {
          date: '2024-08-02',
          searchVolume: 880,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-08-01',
          searchVolume: 880,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 67
        },
        {
          date: '2024-07-31',
          searchVolume: 880,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-07-30',
          searchVolume: 1291,
          organicRank: 306,

          keywordSales: 76
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental pick',
      keyword: 'dental pick',
      searchVolume: 8858,
      organicStartRank: 288,
      organicHighestRank: 144,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-11',
          searchVolume: 11409,
          organicRank: 197,

          keywordSales: 836
        },
        {
          date: '2024-09-10',
          searchVolume: 11409,
          organicRank: 159,

          keywordSales: 836
        },
        {
          date: '2024-09-09',
          searchVolume: 11409,
          organicRank: 159,

          keywordSales: 836
        },
        {
          date: '2024-09-08',
          searchVolume: 11409,
          organicRank: 196,

          keywordSales: 836
        },
        {
          date: '2024-09-06',
          searchVolume: 11409,
          organicRank: 156,

          keywordSales: 836
        },
        {
          date: '2024-09-05',
          searchVolume: 11409,
          organicRank: 306,

          keywordSales: 836
        },
        {
          date: '2024-09-01',
          searchVolume: 11409,
          organicRank: 306,

          keywordSales: 836
        },
        {
          date: '2024-08-28',
          searchVolume: 11775,
          organicRank: 306,

          keywordSales: 836
        },
        {
          date: '2024-08-27',
          searchVolume: 11775,
          organicRank: 200,

          keywordSales: 836
        },
        {
          date: '2024-08-26',
          searchVolume: 11775,
          organicRank: 203,

          keywordSales: 836
        },
        {
          date: '2024-08-25',
          searchVolume: 11775,
          organicRank: 203,

          keywordSales: 836
        },
        {
          date: '2024-08-24',
          searchVolume: 11775,
          organicRank: 222,

          keywordSales: 836
        },
        {
          date: '2024-08-23',
          searchVolume: 12198,
          organicRank: 214,

          keywordSales: 836
        },
        {
          date: '2024-08-20',
          searchVolume: 12198,
          organicRank: 203,

          keywordSales: 836
        },
        {
          date: '2024-08-19',
          searchVolume: 12198,
          organicRank: 144,

          keywordSales: 836
        },
        {
          date: '2024-08-16',
          searchVolume: 11212,
          organicRank: 173,

          keywordSales: 836
        },
        {
          date: '2024-08-15',
          searchVolume: 11212,
          organicRank: 155,

          keywordSales: 836
        },
        {
          date: '2024-08-14',
          searchVolume: 11212,
          organicRank: 162,

          keywordSales: 836
        },
        {
          date: '2024-08-13',
          searchVolume: 11212,
          organicRank: 164,

          keywordSales: 836
        },
        {
          date: '2024-08-12',
          searchVolume: 11212,
          organicRank: 164,

          keywordSales: 836
        },
        {
          date: '2024-08-11',
          searchVolume: 11212,
          organicRank: 175,
          sponsoredRank: 11,
          keywordSales: 836
        },
        {
          date: '2024-08-10',
          searchVolume: 11212,
          organicRank: 159,

          keywordSales: 836
        },
        {
          date: '2024-08-09',
          searchVolume: 10965,
          organicRank: 183,

          keywordSales: 836
        },
        {
          date: '2024-08-08',
          searchVolume: 10965,
          organicRank: 176,

          keywordSales: 836
        },
        {
          date: '2024-08-07',
          searchVolume: 10965,
          organicRank: 147,

          keywordSales: 836
        },
        {
          date: '2024-08-06',
          searchVolume: 10965,
          organicRank: 156,

          keywordSales: 836
        },
        {
          date: '2024-08-05',
          searchVolume: 10965,
          organicRank: 184,

          keywordSales: 836
        },
        {
          date: '2024-08-04',
          searchVolume: 10965,
          organicRank: 176,

          keywordSales: 836
        },
        {
          date: '2024-08-02',
          searchVolume: 10854,
          organicRank: 154,

          keywordSales: 836
        },
        {
          date: '2024-08-01',
          searchVolume: 10854,
          organicRank: 162,

          keywordSales: 836
        },
        {
          date: '2024-07-31',
          searchVolume: 10854,
          organicRank: 156,

          keywordSales: 836
        },
        {
          date: '2024-07-30',
          searchVolume: 10854,
          organicRank: 157,

          keywordSales: 836
        },
        {
          date: '2024-07-29',
          searchVolume: 10854,
          organicRank: 172,

          keywordSales: 836
        },
        {
          date: '2024-07-28',
          searchVolume: 10854,
          organicRank: 187,

          keywordSales: 836
        },
        {
          date: '2024-07-27',
          searchVolume: 10854,
          organicRank: 193,

          keywordSales: 836
        },
        {
          date: '2024-07-26',
          searchVolume: 8858,
          organicRank: 200,
          sponsoredRank: 58,
          keywordSales: 836
        },
        {
          date: '2024-07-25',
          searchVolume: 8858,
          organicRank: 264,

          keywordSales: 836
        },
        {
          date: '2024-07-24',
          searchVolume: 8858,
          organicRank: 294,

          keywordSales: 836
        },
        {
          date: '2024-07-23',
          searchVolume: 8858,
          organicRank: 288,

          keywordSales: 836
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental tools for home use',
      keyword: 'dental tools for home use',
      searchVolume: 713,
      organicStartRank: 253,
      organicHighestRank: 91,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1118,
          organicRank: 183,

          keywordSales: 59
        },
        {
          date: '2024-09-09',
          searchVolume: 1118,
          organicRank: 180,

          keywordSales: 59
        },
        {
          date: '2024-09-08',
          searchVolume: 1118,
          organicRank: 176,

          keywordSales: 59
        },
        {
          date: '2024-09-07',
          searchVolume: 1118,
          organicRank: 209,

          keywordSales: 59
        },
        {
          date: '2024-09-06',
          searchVolume: 1118,
          organicRank: 152,

          keywordSales: 59
        },
        {
          date: '2024-09-05',
          searchVolume: 1118,
          organicRank: 170,

          keywordSales: 59
        },
        {
          date: '2024-09-04',
          searchVolume: 1118,
          organicRank: 170,

          keywordSales: 59
        },
        {
          date: '2024-09-01',
          searchVolume: 1118,
          organicRank: 181,

          keywordSales: 59
        },
        {
          date: '2024-08-28',
          searchVolume: 951,
          organicRank: 127,

          keywordSales: 59
        },
        {
          date: '2024-08-27',
          searchVolume: 951,
          organicRank: 133,

          keywordSales: 59
        },
        {
          date: '2024-08-26',
          searchVolume: 951,
          organicRank: 110,

          keywordSales: 59
        },
        {
          date: '2024-08-25',
          searchVolume: 951,
          organicRank: 128,

          keywordSales: 59
        },
        {
          date: '2024-08-24',
          searchVolume: 951,
          organicRank: 91,

          keywordSales: 59
        },
        {
          date: '2024-08-22',
          searchVolume: 951,
          organicRank: 137,

          keywordSales: 59
        },
        {
          date: '2024-08-21',
          searchVolume: 951,
          organicRank: 127,

          keywordSales: 59
        },
        {
          date: '2024-08-20',
          searchVolume: 951,
          organicRank: 100,

          keywordSales: 59
        },
        {
          date: '2024-08-19',
          searchVolume: 951,
          organicRank: 103,

          keywordSales: 59
        },
        {
          date: '2024-08-18',
          searchVolume: 951,
          organicRank: 105,

          keywordSales: 59
        },
        {
          date: '2024-08-16',
          searchVolume: 844,
          organicRank: 107,

          keywordSales: 59
        },
        {
          date: '2024-08-15',
          searchVolume: 844,
          organicRank: 105,

          keywordSales: 59
        },
        {
          date: '2024-08-14',
          searchVolume: 844,
          organicRank: 104,

          keywordSales: 59
        },
        {
          date: '2024-08-13',
          searchVolume: 844,
          organicRank: 107,

          keywordSales: 59
        },
        {
          date: '2024-08-12',
          searchVolume: 844,
          organicRank: 109,

          keywordSales: 59
        },
        {
          date: '2024-08-11',
          searchVolume: 844,
          organicRank: 110,
          sponsoredRank: 5,
          keywordSales: 59
        },
        {
          date: '2024-08-10',
          searchVolume: 844,
          organicRank: 112,

          keywordSales: 59
        },
        {
          date: '2024-08-09',
          searchVolume: 826,
          organicRank: 105,

          keywordSales: 59
        },
        {
          date: '2024-08-08',
          searchVolume: 826,
          organicRank: 100,

          keywordSales: 59
        },
        {
          date: '2024-08-07',
          searchVolume: 826,
          organicRank: 92,

          keywordSales: 59
        },
        {
          date: '2024-08-06',
          searchVolume: 826,
          organicRank: 159,

          keywordSales: 59
        },
        {
          date: '2024-08-05',
          searchVolume: 826,
          organicRank: 151,

          keywordSales: 59
        },
        {
          date: '2024-08-04',
          searchVolume: 826,
          organicRank: 123,

          keywordSales: 59
        },
        {
          date: '2024-08-03',
          searchVolume: 826,
          organicRank: 169,

          keywordSales: 59
        },
        {
          date: '2024-08-02',
          searchVolume: 880,
          organicRank: 166,

          keywordSales: 59
        },
        {
          date: '2024-08-01',
          searchVolume: 880,
          organicRank: 142,

          keywordSales: 59
        },
        {
          date: '2024-07-31',
          searchVolume: 880,
          organicRank: 116,

          keywordSales: 59
        },
        {
          date: '2024-07-30',
          searchVolume: 880,
          organicRank: 112,

          keywordSales: 59
        },
        {
          date: '2024-07-29',
          searchVolume: 880,
          organicRank: 115,

          keywordSales: 59
        },
        {
          date: '2024-07-28',
          searchVolume: 880,
          organicRank: 114,

          keywordSales: 59
        },
        {
          date: '2024-07-27',
          searchVolume: 880,
          organicRank: 176,

          keywordSales: 59
        },
        {
          date: '2024-07-26',
          searchVolume: 713,
          organicRank: 116,

          keywordSales: 59
        },
        {
          date: '2024-07-25',
          searchVolume: 713,
          organicRank: 133,

          keywordSales: 59
        },
        {
          date: '2024-07-24',
          searchVolume: 713,
          organicRank: 160,

          keywordSales: 59
        },
        {
          date: '2024-07-23',
          searchVolume: 713,
          organicRank: 191,

          keywordSales: 59
        },
        {
          date: '2024-07-22',
          searchVolume: 713,
          organicRank: 192,

          keywordSales: 59
        },
        {
          date: '2024-07-21',
          searchVolume: 713,
          organicRank: 213,

          keywordSales: 59
        },
        {
          date: '2024-07-20',
          searchVolume: 713,
          organicRank: 222,

          keywordSales: 59
        },
        {
          date: '2024-07-19',
          searchVolume: 701,
          organicRank: 230,

          keywordSales: 59
        },
        {
          date: '2024-07-18',
          searchVolume: 701,
          organicRank: 253,

          keywordSales: 59
        },
        {
          date: '2024-07-17',
          searchVolume: 701,
          organicRank: 240,

          keywordSales: 59
        },
        {
          date: '2024-07-16',
          searchVolume: 701,
          organicRank: 265,

          keywordSales: 59
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental water flosser',
      keyword: 'dental water flosser',
      searchVolume: 2433,
      organicStartRank: 65,
      organicHighestRank: 12,
      sponsoredStartRank: 30,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2106,
          organicRank: 44,

          keywordSales: 64
        },
        {
          date: '2024-09-09',
          searchVolume: 2106,
          organicRank: 61,

          keywordSales: 64
        },
        {
          date: '2024-09-08',
          searchVolume: 2106,
          organicRank: 56,

          keywordSales: 64
        },
        {
          date: '2024-09-07',
          searchVolume: 2106,
          organicRank: 50,
          sponsoredRank: 3,
          keywordSales: 64
        },
        {
          date: '2024-09-06',
          searchVolume: 2106,
          organicRank: 52,

          keywordSales: 64
        },
        {
          date: '2024-09-05',
          searchVolume: 2106,
          organicRank: 30,

          keywordSales: 64
        },
        {
          date: '2024-09-04',
          searchVolume: 2106,
          organicRank: 50,

          keywordSales: 64
        },
        {
          date: '2024-09-02',
          searchVolume: 2106,
          organicRank: 33,

          keywordSales: 64
        },
        {
          date: '2024-08-29',
          searchVolume: 2391,
          organicRank: 28,

          keywordSales: 64
        },
        {
          date: '2024-08-28',
          searchVolume: 2391,
          organicRank: 28,

          keywordSales: 64
        },
        {
          date: '2024-08-27',
          searchVolume: 2391,
          organicRank: 60,

          keywordSales: 64
        },
        {
          date: '2024-08-26',
          searchVolume: 2391,
          organicRank: 24,

          keywordSales: 64
        },
        {
          date: '2024-08-25',
          searchVolume: 2391,
          organicRank: 32,

          keywordSales: 64
        },
        {
          date: '2024-08-24',
          searchVolume: 2391,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-08-22',
          searchVolume: 2547,
          organicRank: 22,

          keywordSales: 64
        },
        {
          date: '2024-08-21',
          searchVolume: 2547,
          organicRank: 25,

          keywordSales: 64
        },
        {
          date: '2024-08-20',
          searchVolume: 2547,
          organicRank: 29,

          keywordSales: 64
        },
        {
          date: '2024-08-19',
          searchVolume: 2547,
          organicRank: 63,

          keywordSales: 64
        },
        {
          date: '2024-08-18',
          searchVolume: 2547,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-08-16',
          searchVolume: 1832,
          organicRank: 22,

          keywordSales: 64
        },
        {
          date: '2024-08-15',
          searchVolume: 1832,
          organicRank: 16,

          keywordSales: 64
        },
        {
          date: '2024-08-14',
          searchVolume: 1832,
          organicRank: 12,
          sponsoredRank: 3,
          keywordSales: 64
        },
        {
          date: '2024-08-13',
          searchVolume: 1832,
          organicRank: 13,

          keywordSales: 64
        },
        {
          date: '2024-08-12',
          searchVolume: 1832,
          organicRank: 21,

          keywordSales: 64
        },
        {
          date: '2024-08-11',
          searchVolume: 1832,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 64
        },
        {
          date: '2024-08-10',
          searchVolume: 1832,
          organicRank: 17,

          keywordSales: 64
        },
        {
          date: '2024-08-09',
          searchVolume: 1724,
          organicRank: 22,

          keywordSales: 64
        },
        {
          date: '2024-08-08',
          searchVolume: 1724,
          organicRank: 21,

          keywordSales: 64
        },
        {
          date: '2024-08-07',
          searchVolume: 1724,
          organicRank: 25,

          keywordSales: 64
        },
        {
          date: '2024-08-06',
          searchVolume: 1724,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-08-05',
          searchVolume: 1724,
          organicRank: 24,

          keywordSales: 64
        },
        {
          date: '2024-08-04',
          searchVolume: 1724,
          organicRank: 23,

          keywordSales: 64
        },
        {
          date: '2024-08-03',
          searchVolume: 1724,
          organicRank: 33,

          keywordSales: 64
        },
        {
          date: '2024-08-02',
          searchVolume: 1533,
          organicRank: 23,

          keywordSales: 64
        },
        {
          date: '2024-08-01',
          searchVolume: 1533,
          organicRank: 23,
          sponsoredRank: 5,
          keywordSales: 64
        },
        {
          date: '2024-07-31',
          searchVolume: 1533,
          organicRank: 34,

          keywordSales: 64
        },
        {
          date: '2024-07-30',
          searchVolume: 1533,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-07-29',
          searchVolume: 1533,
          organicRank: 25,

          keywordSales: 64
        },
        {
          date: '2024-07-28',
          searchVolume: 1533,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-07-27',
          searchVolume: 1533,
          organicRank: 30,
          sponsoredRank: 30,
          keywordSales: 64
        },
        {
          date: '2024-07-26',
          searchVolume: 2433,
          organicRank: 24,

          keywordSales: 64
        },
        {
          date: '2024-07-25',
          searchVolume: 2433,
          organicRank: 22,

          keywordSales: 64
        },
        {
          date: '2024-07-24',
          searchVolume: 2433,
          organicRank: 33,

          keywordSales: 64
        },
        {
          date: '2024-07-23',
          searchVolume: 2433,
          organicRank: 19,

          keywordSales: 64
        },
        {
          date: '2024-07-22',
          searchVolume: 2433,
          organicRank: 35,

          keywordSales: 64
        },
        {
          date: '2024-07-21',
          searchVolume: 2433,
          organicRank: 36,

          keywordSales: 64
        },
        {
          date: '2024-07-20',
          searchVolume: 2433,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-07-19',
          searchVolume: 1796,
          organicRank: 48,

          keywordSales: 64
        },
        {
          date: '2024-07-18',
          searchVolume: 1796,
          organicRank: 65,

          keywordSales: 64
        },
        {
          date: '2024-07-17',
          searchVolume: 1796,
          organicRank: 62,

          keywordSales: 64
        },
        {
          date: '2024-07-16',
          searchVolume: 1796,
          organicRank: 61,

          keywordSales: 64
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_dental water pick',
      keyword: 'dental water pick',
      searchVolume: 1828,
      organicStartRank: 74,
      organicHighestRank: 6,
      sponsoredStartRank: 53,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1637,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-09-09',
          searchVolume: 1637,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-09-08',
          searchVolume: 1637,
          organicRank: 19,

          keywordSales: 86
        },
        {
          date: '2024-09-07',
          searchVolume: 1637,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 86
        },
        {
          date: '2024-09-06',
          searchVolume: 1637,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-09-05',
          searchVolume: 1637,
          organicRank: 306,

          keywordSales: 86
        },
        {
          date: '2024-09-04',
          searchVolume: 1637,
          organicRank: 22,

          keywordSales: 86
        },
        {
          date: '2024-09-02',
          searchVolume: 1637,
          organicRank: 22,

          keywordSales: 86
        },
        {
          date: '2024-08-29',
          searchVolume: 1657,
          organicRank: 19,

          keywordSales: 86
        },
        {
          date: '2024-08-28',
          searchVolume: 1657,
          organicRank: 23,

          keywordSales: 86
        },
        {
          date: '2024-08-27',
          searchVolume: 1657,
          organicRank: 12,
          sponsoredRank: 11,
          keywordSales: 86
        },
        {
          date: '2024-08-26',
          searchVolume: 1657,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-08-25',
          searchVolume: 1657,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-08-24',
          searchVolume: 1657,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-08-22',
          searchVolume: 1852,
          organicRank: 18,

          keywordSales: 86
        },
        {
          date: '2024-08-21',
          searchVolume: 1852,
          organicRank: 10,

          keywordSales: 86
        },
        {
          date: '2024-08-20',
          searchVolume: 1852,
          organicRank: 18,

          keywordSales: 86
        },
        {
          date: '2024-08-19',
          searchVolume: 1852,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-08-18',
          searchVolume: 1852,
          organicRank: 18,
          sponsoredRank: 43,
          keywordSales: 86
        },
        {
          date: '2024-08-16',
          searchVolume: 1761,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-08-15',
          searchVolume: 1761,
          organicRank: 15,

          keywordSales: 86
        },
        {
          date: '2024-08-14',
          searchVolume: 1761,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 86
        },
        {
          date: '2024-08-13',
          searchVolume: 1761,
          organicRank: 6,

          keywordSales: 86
        },
        {
          date: '2024-08-12',
          searchVolume: 1761,
          organicRank: 12,

          keywordSales: 86
        },
        {
          date: '2024-08-11',
          searchVolume: 1761,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 86
        },
        {
          date: '2024-08-10',
          searchVolume: 1761,
          organicRank: 13,

          keywordSales: 86
        },
        {
          date: '2024-08-09',
          searchVolume: 1497,
          organicRank: 9,

          keywordSales: 86
        },
        {
          date: '2024-08-08',
          searchVolume: 1497,
          organicRank: 12,
          sponsoredRank: 8,
          keywordSales: 86
        },
        {
          date: '2024-08-07',
          searchVolume: 1497,
          organicRank: 11,

          keywordSales: 86
        },
        {
          date: '2024-08-06',
          searchVolume: 1497,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-08-05',
          searchVolume: 1497,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-08-04',
          searchVolume: 1497,
          organicRank: 18,

          keywordSales: 86
        },
        {
          date: '2024-08-03',
          searchVolume: 1497,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-08-02',
          searchVolume: 1321,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-08-01',
          searchVolume: 1321,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 86
        },
        {
          date: '2024-07-31',
          searchVolume: 1321,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-07-30',
          searchVolume: 1321,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-07-29',
          searchVolume: 1321,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-07-28',
          searchVolume: 1321,
          organicRank: 28,

          keywordSales: 86
        },
        {
          date: '2024-07-27',
          searchVolume: 1321,
          organicRank: 22,
          sponsoredRank: 53,
          keywordSales: 86
        },
        {
          date: '2024-07-26',
          searchVolume: 1828,
          organicRank: 33,

          keywordSales: 86
        },
        {
          date: '2024-07-25',
          searchVolume: 1828,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-07-24',
          searchVolume: 1828,
          organicRank: 32,

          keywordSales: 86
        },
        {
          date: '2024-07-23',
          searchVolume: 1828,
          organicRank: 26,

          keywordSales: 86
        },
        {
          date: '2024-07-22',
          searchVolume: 1828,
          organicRank: 32,

          keywordSales: 86
        },
        {
          date: '2024-07-21',
          searchVolume: 1828,
          organicRank: 42,

          keywordSales: 86
        },
        {
          date: '2024-07-20',
          searchVolume: 1828,
          organicRank: 50,

          keywordSales: 86
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 58,

          keywordSales: 86
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 74,

          keywordSales: 86
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 88,

          keywordSales: 86
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 66,

          keywordSales: 86
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric flosser',
      keyword: 'electric flosser',
      searchVolume: 8097,
      organicStartRank: 80,
      organicHighestRank: 13,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 6542,
          organicRank: 20,

          keywordSales: 131
        },
        {
          date: '2024-09-09',
          searchVolume: 6542,
          organicRank: 24,

          keywordSales: 131
        },
        {
          date: '2024-09-08',
          searchVolume: 6542,
          organicRank: 26,
          sponsoredRank: 35,
          keywordSales: 131
        },
        {
          date: '2024-09-07',
          searchVolume: 6542,
          organicRank: 26,
          sponsoredRank: 9,
          keywordSales: 131
        },
        {
          date: '2024-09-06',
          searchVolume: 6542,
          organicRank: 25,

          keywordSales: 131
        },
        {
          date: '2024-09-05',
          searchVolume: 6542,
          organicRank: 28,

          keywordSales: 131
        },
        {
          date: '2024-09-04',
          searchVolume: 6542,
          organicRank: 26,

          keywordSales: 131
        },
        {
          date: '2024-09-03',
          searchVolume: 6542,
          organicRank: 28,

          keywordSales: 131
        },
        {
          date: '2024-08-30',
          searchVolume: 6757,
          organicRank: 24,
          sponsoredRank: 17,
          keywordSales: 131
        },
        {
          date: '2024-08-28',
          searchVolume: 6757,
          organicRank: 23,

          keywordSales: 131
        },
        {
          date: '2024-08-27',
          searchVolume: 6757,
          organicRank: 18,

          keywordSales: 131
        },
        {
          date: '2024-08-26',
          searchVolume: 6757,
          organicRank: 20,

          keywordSales: 131
        },
        {
          date: '2024-08-25',
          searchVolume: 6757,
          organicRank: 20,

          keywordSales: 131
        },
        {
          date: '2024-08-24',
          searchVolume: 6757,
          organicRank: 21,

          keywordSales: 131
        },
        {
          date: '2024-08-23',
          searchVolume: 9024,
          organicRank: 21,

          keywordSales: 131
        },
        {
          date: '2024-08-22',
          searchVolume: 9024,
          organicRank: 20,

          keywordSales: 131
        },
        {
          date: '2024-08-20',
          searchVolume: 9024,
          organicRank: 19,
          sponsoredRank: 69,
          keywordSales: 131
        },
        {
          date: '2024-08-19',
          searchVolume: 9024,
          organicRank: 18,

          keywordSales: 131
        },
        {
          date: '2024-08-16',
          searchVolume: 6434,
          organicRank: 19,

          keywordSales: 131
        },
        {
          date: '2024-08-15',
          searchVolume: 6434,
          organicRank: 18,

          keywordSales: 131
        },
        {
          date: '2024-08-14',
          searchVolume: 6434,
          organicRank: 16,

          keywordSales: 131
        },
        {
          date: '2024-08-13',
          searchVolume: 6434,
          organicRank: 16,

          keywordSales: 131
        },
        {
          date: '2024-08-11',
          searchVolume: 6434,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 131
        },
        {
          date: '2024-08-10',
          searchVolume: 6434,
          organicRank: 18,
          sponsoredRank: 9,
          keywordSales: 131
        },
        {
          date: '2024-08-09',
          searchVolume: 5562,
          organicRank: 17,

          keywordSales: 131
        },
        {
          date: '2024-08-08',
          searchVolume: 5562,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 131
        },
        {
          date: '2024-08-07',
          searchVolume: 5562,
          organicRank: 17,

          keywordSales: 131
        },
        {
          date: '2024-08-06',
          searchVolume: 5562,
          organicRank: 13,
          sponsoredRank: 5,
          keywordSales: 131
        },
        {
          date: '2024-08-05',
          searchVolume: 5562,
          organicRank: 19,

          keywordSales: 131
        },
        {
          date: '2024-08-04',
          searchVolume: 5562,
          organicRank: 22,
          sponsoredRank: 22,
          keywordSales: 131
        },
        {
          date: '2024-08-03',
          searchVolume: 5562,
          organicRank: 19,
          sponsoredRank: 6,
          keywordSales: 131
        },
        {
          date: '2024-08-02',
          searchVolume: 6223,
          organicRank: 19,
          sponsoredRank: 32,
          keywordSales: 131
        },
        {
          date: '2024-08-01',
          searchVolume: 6223,
          organicRank: 17,
          sponsoredRank: 7,
          keywordSales: 131
        },
        {
          date: '2024-07-31',
          searchVolume: 6223,
          organicRank: 15,

          keywordSales: 131
        },
        {
          date: '2024-07-30',
          searchVolume: 6223,
          organicRank: 14,

          keywordSales: 131
        },
        {
          date: '2024-07-29',
          searchVolume: 6223,
          organicRank: 19,

          keywordSales: 131
        },
        {
          date: '2024-07-28',
          searchVolume: 6223,
          organicRank: 16,

          keywordSales: 131
        },
        {
          date: '2024-07-27',
          searchVolume: 6223,
          organicRank: 18,

          keywordSales: 131
        },
        {
          date: '2024-07-26',
          searchVolume: 8097,
          organicRank: 24,

          keywordSales: 131
        },
        {
          date: '2024-07-25',
          searchVolume: 8097,
          organicRank: 30,

          keywordSales: 131
        },
        {
          date: '2024-07-24',
          searchVolume: 8097,
          organicRank: 23,

          keywordSales: 131
        },
        {
          date: '2024-07-23',
          searchVolume: 8097,
          organicRank: 26,

          keywordSales: 131
        },
        {
          date: '2024-07-22',
          searchVolume: 8097,
          organicRank: 40,

          keywordSales: 131
        },
        {
          date: '2024-07-21',
          searchVolume: 8097,
          organicRank: 36,

          keywordSales: 131
        },
        {
          date: '2024-07-20',
          searchVolume: 8097,
          organicRank: 38,

          keywordSales: 131
        },
        {
          date: '2024-07-19',
          searchVolume: 6329,
          organicRank: 140,

          keywordSales: 131
        },
        {
          date: '2024-07-18',
          searchVolume: 6329,
          organicRank: 80,

          keywordSales: 131
        },
        {
          date: '2024-07-17',
          searchVolume: 6329,
          organicRank: 74,

          keywordSales: 131
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric flosser for teeth',
      keyword: 'electric flosser for teeth',
      searchVolume: 4349,
      organicStartRank: 39,
      organicHighestRank: 23,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4191,
          organicRank: 37,

          keywordSales: 104
        },
        {
          date: '2024-09-09',
          searchVolume: 4191,
          organicRank: 45,
          sponsoredRank: 1,
          keywordSales: 104
        },
        {
          date: '2024-09-08',
          searchVolume: 4191,
          organicRank: 39,

          keywordSales: 104
        },
        {
          date: '2024-09-07',
          searchVolume: 4191,
          organicRank: 36,

          keywordSales: 104
        },
        {
          date: '2024-09-06',
          searchVolume: 4191,
          organicRank: 67,

          keywordSales: 104
        },
        {
          date: '2024-09-04',
          searchVolume: 4191,
          organicRank: 306,

          keywordSales: 104
        },
        {
          date: '2024-09-03',
          searchVolume: 4191,
          organicRank: 40,

          keywordSales: 104
        },
        {
          date: '2024-09-01',
          searchVolume: 4191,
          organicRank: 43,

          keywordSales: 104
        },
        {
          date: '2024-08-28',
          searchVolume: 4322,
          organicRank: 45,

          keywordSales: 104
        },
        {
          date: '2024-08-27',
          searchVolume: 4322,
          organicRank: 38,

          keywordSales: 104
        },
        {
          date: '2024-08-26',
          searchVolume: 4322,
          organicRank: 38,

          keywordSales: 104
        },
        {
          date: '2024-08-25',
          searchVolume: 4322,
          organicRank: 39,

          keywordSales: 104
        },
        {
          date: '2024-08-24',
          searchVolume: 4322,
          organicRank: 41,

          keywordSales: 104
        },
        {
          date: '2024-08-22',
          searchVolume: 5990,
          organicRank: 42,

          keywordSales: 104
        },
        {
          date: '2024-08-21',
          searchVolume: 5990,
          organicRank: 41,

          keywordSales: 104
        },
        {
          date: '2024-08-20',
          searchVolume: 5990,
          organicRank: 37,

          keywordSales: 104
        },
        {
          date: '2024-08-19',
          searchVolume: 5990,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-08-18',
          searchVolume: 5990,
          organicRank: 38,

          keywordSales: 104
        },
        {
          date: '2024-08-16',
          searchVolume: 3462,
          organicRank: 27,

          keywordSales: 104
        },
        {
          date: '2024-08-15',
          searchVolume: 3462,
          organicRank: 43,

          keywordSales: 104
        },
        {
          date: '2024-08-14',
          searchVolume: 3462,
          organicRank: 39,
          sponsoredRank: 5,
          keywordSales: 104
        },
        {
          date: '2024-08-13',
          searchVolume: 3462,
          organicRank: 24,

          keywordSales: 104
        },
        {
          date: '2024-08-11',
          searchVolume: 3462,
          organicRank: 40,
          sponsoredRank: 2,
          keywordSales: 104
        },
        {
          date: '2024-08-10',
          searchVolume: 3462,
          organicRank: 41,
          sponsoredRank: 8,
          keywordSales: 104
        },
        {
          date: '2024-08-09',
          searchVolume: 3556,
          organicRank: 40,
          sponsoredRank: 5,
          keywordSales: 104
        },
        {
          date: '2024-08-08',
          searchVolume: 3556,
          organicRank: 41,
          sponsoredRank: 2,
          keywordSales: 104
        },
        {
          date: '2024-08-07',
          searchVolume: 3556,
          organicRank: 45,
          sponsoredRank: 3,
          keywordSales: 104
        },
        {
          date: '2024-08-06',
          searchVolume: 3556,
          organicRank: 43,
          sponsoredRank: 7,
          keywordSales: 104
        },
        {
          date: '2024-08-05',
          searchVolume: 3556,
          organicRank: 41,

          keywordSales: 104
        },
        {
          date: '2024-08-04',
          searchVolume: 3556,
          organicRank: 44,
          sponsoredRank: 17,
          keywordSales: 104
        },
        {
          date: '2024-08-03',
          searchVolume: 3556,
          organicRank: 38,
          sponsoredRank: 8,
          keywordSales: 104
        },
        {
          date: '2024-08-02',
          searchVolume: 3990,
          organicRank: 40,
          sponsoredRank: 23,
          keywordSales: 104
        },
        {
          date: '2024-08-01',
          searchVolume: 3990,
          organicRank: 39,
          sponsoredRank: 6,
          keywordSales: 104
        },
        {
          date: '2024-07-31',
          searchVolume: 3990,
          organicRank: 40,

          keywordSales: 104
        },
        {
          date: '2024-07-30',
          searchVolume: 3990,
          organicRank: 40,
          sponsoredRank: 48,
          keywordSales: 104
        },
        {
          date: '2024-07-29',
          searchVolume: 3990,
          organicRank: 33,

          keywordSales: 104
        },
        {
          date: '2024-07-28',
          searchVolume: 3990,
          organicRank: 32,

          keywordSales: 104
        },
        {
          date: '2024-07-27',
          searchVolume: 3990,
          organicRank: 27,

          keywordSales: 104
        },
        {
          date: '2024-07-26',
          searchVolume: 4349,
          organicRank: 28,

          keywordSales: 104
        },
        {
          date: '2024-07-25',
          searchVolume: 4349,
          organicRank: 31,

          keywordSales: 104
        },
        {
          date: '2024-07-24',
          searchVolume: 4349,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-23',
          searchVolume: 4349,
          organicRank: 29,

          keywordSales: 104
        },
        {
          date: '2024-07-22',
          searchVolume: 4349,
          organicRank: 31,

          keywordSales: 104
        },
        {
          date: '2024-07-21',
          searchVolume: 4349,
          organicRank: 26,

          keywordSales: 104
        },
        {
          date: '2024-07-20',
          searchVolume: 4349,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-19',
          searchVolume: 3582,
          organicRank: 32,

          keywordSales: 104
        },
        {
          date: '2024-07-18',
          searchVolume: 3582,
          organicRank: 39,

          keywordSales: 104
        },
        {
          date: '2024-07-17',
          searchVolume: 3582,
          organicRank: 36,

          keywordSales: 104
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric flossers for adults',
      keyword: 'electric flossers for adults',
      searchVolume: 1286,
      organicStartRank: 91,
      organicHighestRank: 10,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-14',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 922,
          organicRank: 20,

          keywordSales: 26
        },
        {
          date: '2024-09-09',
          searchVolume: 922,
          organicRank: 28,

          keywordSales: 26
        },
        {
          date: '2024-09-08',
          searchVolume: 922,
          organicRank: 25,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-09-07',
          searchVolume: 922,
          organicRank: 21,

          keywordSales: 26
        },
        {
          date: '2024-09-06',
          searchVolume: 922,
          organicRank: 22,

          keywordSales: 26
        },
        {
          date: '2024-09-05',
          searchVolume: 922,
          organicRank: 25,

          keywordSales: 26
        },
        {
          date: '2024-09-04',
          searchVolume: 922,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-09-01',
          searchVolume: 922,
          organicRank: 24,

          keywordSales: 26
        },
        {
          date: '2024-08-28',
          searchVolume: 886,
          organicRank: 20,

          keywordSales: 26
        },
        {
          date: '2024-08-27',
          searchVolume: 886,
          organicRank: 17,

          keywordSales: 26
        },
        {
          date: '2024-08-26',
          searchVolume: 886,
          organicRank: 18,

          keywordSales: 26
        },
        {
          date: '2024-08-25',
          searchVolume: 886,
          organicRank: 20,

          keywordSales: 26
        },
        {
          date: '2024-08-24',
          searchVolume: 886,
          organicRank: 20,

          keywordSales: 26
        },
        {
          date: '2024-08-23',
          searchVolume: 1221,
          organicRank: 21,

          keywordSales: 26
        },
        {
          date: '2024-08-22',
          searchVolume: 1221,
          organicRank: 17,

          keywordSales: 26
        },
        {
          date: '2024-08-21',
          searchVolume: 1221,
          organicRank: 22,

          keywordSales: 26
        },
        {
          date: '2024-08-20',
          searchVolume: 1221,
          organicRank: 21,

          keywordSales: 26
        },
        {
          date: '2024-08-19',
          searchVolume: 1221,
          organicRank: 16,

          keywordSales: 26
        },
        {
          date: '2024-08-17',
          searchVolume: 1221,
          organicRank: 20,

          keywordSales: 26
        },
        {
          date: '2024-08-16',
          searchVolume: 571,
          organicRank: 17,

          keywordSales: 26
        },
        {
          date: '2024-08-15',
          searchVolume: 571,
          organicRank: 10,

          keywordSales: 26
        },
        {
          date: '2024-08-14',
          searchVolume: 571,
          organicRank: 17,

          keywordSales: 26
        },
        {
          date: '2024-08-13',
          searchVolume: 571,
          organicRank: 26,

          keywordSales: 26
        },
        {
          date: '2024-08-12',
          searchVolume: 571,
          organicRank: 29,

          keywordSales: 26
        },
        {
          date: '2024-08-11',
          searchVolume: 571,
          organicRank: 36,

          keywordSales: 26
        },
        {
          date: '2024-08-10',
          searchVolume: 571,
          organicRank: 34,

          keywordSales: 26
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 40,

          keywordSales: 26
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 38,

          keywordSales: 26
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 39,

          keywordSales: 26
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 39,

          keywordSales: 26
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 41,

          keywordSales: 26
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 43,

          keywordSales: 26
        },
        {
          date: '2024-08-02',
          searchVolume: 690,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-08-01',
          searchVolume: 690,
          organicRank: 44,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-07-31',
          searchVolume: 690,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-07-30',
          searchVolume: 690,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-07-29',
          searchVolume: 690,
          organicRank: 46,

          keywordSales: 26
        },
        {
          date: '2024-07-28',
          searchVolume: 690,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-07-27',
          searchVolume: 690,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-07-26',
          searchVolume: 1286,
          organicRank: 43,

          keywordSales: 26
        },
        {
          date: '2024-07-25',
          searchVolume: 1286,
          organicRank: 41,

          keywordSales: 26
        },
        {
          date: '2024-07-24',
          searchVolume: 1286,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-07-23',
          searchVolume: 1286,
          organicRank: 40,

          keywordSales: 26
        },
        {
          date: '2024-07-22',
          searchVolume: 1286,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-07-21',
          searchVolume: 1286,
          organicRank: 42,

          keywordSales: 26
        },
        {
          date: '2024-07-20',
          searchVolume: 1286,
          organicRank: 51,

          keywordSales: 26
        },
        {
          date: '2024-07-19',
          searchVolume: 760,
          organicRank: 61,

          keywordSales: 26
        },
        {
          date: '2024-07-18',
          searchVolume: 760,
          organicRank: 91,

          keywordSales: 26
        },
        {
          date: '2024-07-17',
          searchVolume: 760,
          organicRank: 88,

          keywordSales: 26
        },
        {
          date: '2024-07-16',
          searchVolume: 760,
          organicRank: 77,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric tooth flosser',
      keyword: 'electric tooth flosser',
      searchVolume: 410,
      organicStartRank: 61,
      organicHighestRank: 15,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 333,
          organicRank: 65,

          keywordSales: 2
        },
        {
          date: '2024-09-09',
          searchVolume: 333,
          organicRank: 70,

          keywordSales: 2
        },
        {
          date: '2024-09-08',
          searchVolume: 333,
          organicRank: 52,

          keywordSales: 2
        },
        {
          date: '2024-09-07',
          searchVolume: 333,
          organicRank: 48,

          keywordSales: 2
        },
        {
          date: '2024-09-06',
          searchVolume: 333,
          organicRank: 65,

          keywordSales: 2
        },
        {
          date: '2024-09-05',
          searchVolume: 333,
          organicRank: 50,

          keywordSales: 2
        },
        {
          date: '2024-09-04',
          searchVolume: 333,
          organicRank: 50,

          keywordSales: 2
        },
        {
          date: '2024-09-02',
          searchVolume: 333,
          organicRank: 15,

          keywordSales: 2
        },
        {
          date: '2024-08-29',
          searchVolume: 374,
          organicRank: 69,

          keywordSales: 2
        },
        {
          date: '2024-08-28',
          searchVolume: 374,
          organicRank: 68,

          keywordSales: 2
        },
        {
          date: '2024-08-27',
          searchVolume: 374,
          organicRank: 32,

          keywordSales: 2
        },
        {
          date: '2024-08-26',
          searchVolume: 374,
          organicRank: 29,

          keywordSales: 2
        },
        {
          date: '2024-08-25',
          searchVolume: 374,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-08-24',
          searchVolume: 374,
          organicRank: 35,

          keywordSales: 2
        },
        {
          date: '2024-08-22',
          searchVolume: 372,
          organicRank: 35,

          keywordSales: 2
        },
        {
          date: '2024-08-21',
          searchVolume: 372,
          organicRank: 28,

          keywordSales: 2
        },
        {
          date: '2024-08-20',
          searchVolume: 372,
          organicRank: 26,

          keywordSales: 2
        },
        {
          date: '2024-08-19',
          searchVolume: 372,
          organicRank: 29,
          sponsoredRank: 29,
          keywordSales: 2
        },
        {
          date: '2024-08-18',
          searchVolume: 372,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-08-16',
          searchVolume: 371,
          organicRank: 29,

          keywordSales: 2
        },
        {
          date: '2024-08-15',
          searchVolume: 371,
          organicRank: 34,

          keywordSales: 2
        },
        {
          date: '2024-08-14',
          searchVolume: 371,
          organicRank: 30,
          sponsoredRank: 7,
          keywordSales: 2
        },
        {
          date: '2024-08-13',
          searchVolume: 371,
          organicRank: 22,

          keywordSales: 2
        },
        {
          date: '2024-08-12',
          searchVolume: 371,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-08-11',
          searchVolume: 371,
          organicRank: 30,
          sponsoredRank: 8,
          keywordSales: 2
        },
        {
          date: '2024-08-10',
          searchVolume: 371,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-08-09',
          searchVolume: 284,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-08-08',
          searchVolume: 284,
          organicRank: 30,
          sponsoredRank: 12,
          keywordSales: 2
        },
        {
          date: '2024-08-07',
          searchVolume: 284,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-08-06',
          searchVolume: 284,
          organicRank: 20,

          keywordSales: 2
        },
        {
          date: '2024-08-05',
          searchVolume: 284,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-08-04',
          searchVolume: 284,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-08-03',
          searchVolume: 284,
          organicRank: 36,

          keywordSales: 2
        },
        {
          date: '2024-08-02',
          searchVolume: 278,
          organicRank: 32,

          keywordSales: 2
        },
        {
          date: '2024-08-01',
          searchVolume: 278,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-07-31',
          searchVolume: 278,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-07-30',
          searchVolume: 278,
          organicRank: 34,

          keywordSales: 2
        },
        {
          date: '2024-07-29',
          searchVolume: 278,
          organicRank: 32,

          keywordSales: 2
        },
        {
          date: '2024-07-28',
          searchVolume: 278,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-07-27',
          searchVolume: 278,
          organicRank: 26,

          keywordSales: 2
        },
        {
          date: '2024-07-26',
          searchVolume: 410,
          organicRank: 34,

          keywordSales: 2
        },
        {
          date: '2024-07-25',
          searchVolume: 410,
          organicRank: 37,

          keywordSales: 2
        },
        {
          date: '2024-07-24',
          searchVolume: 410,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-07-23',
          searchVolume: 410,
          organicRank: 24,

          keywordSales: 2
        },
        {
          date: '2024-07-22',
          searchVolume: 410,
          organicRank: 31,

          keywordSales: 2
        },
        {
          date: '2024-07-21',
          searchVolume: 410,
          organicRank: 37,

          keywordSales: 2
        },
        {
          date: '2024-07-20',
          searchVolume: 410,
          organicRank: 35,

          keywordSales: 2
        },
        {
          date: '2024-07-19',
          searchVolume: 256,
          organicRank: 37,

          keywordSales: 2
        },
        {
          date: '2024-07-18',
          searchVolume: 256,
          organicRank: 61,

          keywordSales: 2
        },
        {
          date: '2024-07-17',
          searchVolume: 256,
          organicRank: 50,

          keywordSales: 2
        },
        {
          date: '2024-07-16',
          searchVolume: 256,
          organicRank: 44,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric toothbrush and water flosser',
      keyword: 'electric toothbrush and water flosser',
      searchVolume: 1587,
      organicStartRank: 90,
      organicHighestRank: 30,
      sponsoredStartRank: 53,
      sponsoredHighestRank: 53,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1305,
          organicRank: 62,

          keywordSales: 26
        },
        {
          date: '2024-09-09',
          searchVolume: 1305,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-09-08',
          searchVolume: 1305,
          organicRank: 80,

          keywordSales: 26
        },
        {
          date: '2024-09-07',
          searchVolume: 1305,
          organicRank: 77,

          keywordSales: 26
        },
        {
          date: '2024-09-06',
          searchVolume: 1305,
          organicRank: 68,

          keywordSales: 26
        },
        {
          date: '2024-09-05',
          searchVolume: 1305,
          organicRank: 76,

          keywordSales: 26
        },
        {
          date: '2024-09-04',
          searchVolume: 1305,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-09-01',
          searchVolume: 1305,
          organicRank: 61,

          keywordSales: 26
        },
        {
          date: '2024-08-28',
          searchVolume: 953,
          organicRank: 59,

          keywordSales: 26
        },
        {
          date: '2024-08-27',
          searchVolume: 953,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-08-26',
          searchVolume: 953,
          organicRank: 57,

          keywordSales: 26
        },
        {
          date: '2024-08-25',
          searchVolume: 953,
          organicRank: 49,

          keywordSales: 26
        },
        {
          date: '2024-08-24',
          searchVolume: 953,
          organicRank: 54,

          keywordSales: 26
        },
        {
          date: '2024-08-23',
          searchVolume: 1430,
          organicRank: 43,

          keywordSales: 26
        },
        {
          date: '2024-08-22',
          searchVolume: 1430,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-08-21',
          searchVolume: 1430,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-08-20',
          searchVolume: 1430,
          organicRank: 30,

          keywordSales: 26
        },
        {
          date: '2024-08-19',
          searchVolume: 1430,
          organicRank: 31,

          keywordSales: 26
        },
        {
          date: '2024-08-17',
          searchVolume: 1430,
          organicRank: 34,

          keywordSales: 26
        },
        {
          date: '2024-08-16',
          searchVolume: 1099,
          organicRank: 34,

          keywordSales: 26
        },
        {
          date: '2024-08-15',
          searchVolume: 1099,
          organicRank: 30,

          keywordSales: 26
        },
        {
          date: '2024-08-14',
          searchVolume: 1099,
          organicRank: 34,

          keywordSales: 26
        },
        {
          date: '2024-08-13',
          searchVolume: 1099,
          organicRank: 46,

          keywordSales: 26
        },
        {
          date: '2024-08-12',
          searchVolume: 1099,
          organicRank: 55,

          keywordSales: 26
        },
        {
          date: '2024-08-11',
          searchVolume: 1099,
          organicRank: 46,

          keywordSales: 26
        },
        {
          date: '2024-08-10',
          searchVolume: 1099,
          organicRank: 49,

          keywordSales: 26
        },
        {
          date: '2024-08-09',
          searchVolume: 888,
          organicRank: 52,

          keywordSales: 26
        },
        {
          date: '2024-08-08',
          searchVolume: 888,
          organicRank: 79,

          keywordSales: 26
        },
        {
          date: '2024-08-07',
          searchVolume: 888,
          organicRank: 52,

          keywordSales: 26
        },
        {
          date: '2024-08-06',
          searchVolume: 888,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-08-05',
          searchVolume: 888,
          organicRank: 54,

          keywordSales: 26
        },
        {
          date: '2024-08-04',
          searchVolume: 888,
          organicRank: 52,

          keywordSales: 26
        },
        {
          date: '2024-08-03',
          searchVolume: 888,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-08-02',
          searchVolume: 878,
          organicRank: 55,

          keywordSales: 26
        },
        {
          date: '2024-08-01',
          searchVolume: 878,
          organicRank: 68,

          keywordSales: 26
        },
        {
          date: '2024-07-31',
          searchVolume: 878,
          organicRank: 56,

          keywordSales: 26
        },
        {
          date: '2024-07-30',
          searchVolume: 878,
          organicRank: 59,
          sponsoredRank: 56,
          keywordSales: 26
        },
        {
          date: '2024-07-29',
          searchVolume: 878,
          organicRank: 67,

          keywordSales: 26
        },
        {
          date: '2024-07-28',
          searchVolume: 878,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-07-27',
          searchVolume: 878,
          organicRank: 64,
          sponsoredRank: 53,
          keywordSales: 26
        },
        {
          date: '2024-07-26',
          searchVolume: 1587,
          organicRank: 56,

          keywordSales: 26
        },
        {
          date: '2024-07-25',
          searchVolume: 1587,
          organicRank: 69,

          keywordSales: 26
        },
        {
          date: '2024-07-24',
          searchVolume: 1587,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-07-23',
          searchVolume: 1587,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-07-22',
          searchVolume: 1587,
          organicRank: 63,

          keywordSales: 26
        },
        {
          date: '2024-07-21',
          searchVolume: 1587,
          organicRank: 74,

          keywordSales: 26
        },
        {
          date: '2024-07-20',
          searchVolume: 1587,
          organicRank: 74,

          keywordSales: 26
        },
        {
          date: '2024-07-19',
          searchVolume: 832,
          organicRank: 78,

          keywordSales: 26
        },
        {
          date: '2024-07-18',
          searchVolume: 832,
          organicRank: 90,

          keywordSales: 26
        },
        {
          date: '2024-07-17',
          searchVolume: 832,
          organicRank: 91,

          keywordSales: 26
        },
        {
          date: '2024-07-16',
          searchVolume: 832,
          organicRank: 84,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric toothbrush and waterpik combo',
      keyword: 'electric toothbrush and waterpik combo',
      searchVolume: 1582,
      organicStartRank: 56,
      organicHighestRank: 16,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 22,
      organicPageOneWinDate: '2024-08-07',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 654,
          organicRank: 47,

          keywordSales: 14
        },
        {
          date: '2024-09-09',
          searchVolume: 654,
          organicRank: 52,

          keywordSales: 14
        },
        {
          date: '2024-09-08',
          searchVolume: 654,
          organicRank: 54,
          sponsoredRank: 22,
          keywordSales: 14
        },
        {
          date: '2024-09-07',
          searchVolume: 654,
          organicRank: 45,

          keywordSales: 14
        },
        {
          date: '2024-09-06',
          searchVolume: 654,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-09-05',
          searchVolume: 654,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-09-04',
          searchVolume: 654,
          organicRank: 43,

          keywordSales: 14
        },
        {
          date: '2024-09-01',
          searchVolume: 654,
          organicRank: 55,
          sponsoredRank: 76,
          keywordSales: 14
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 28,

          keywordSales: 14
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 44,

          keywordSales: 14
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 43,

          keywordSales: 14
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 29,

          keywordSales: 14
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 26,

          keywordSales: 14
        },
        {
          date: '2024-08-23',
          searchVolume: 747,
          organicRank: 32,

          keywordSales: 14
        },
        {
          date: '2024-08-22',
          searchVolume: 747,
          organicRank: 27,

          keywordSales: 14
        },
        {
          date: '2024-08-21',
          searchVolume: 747,
          organicRank: 30,

          keywordSales: 14
        },
        {
          date: '2024-08-20',
          searchVolume: 747,
          organicRank: 24,

          keywordSales: 14
        },
        {
          date: '2024-08-19',
          searchVolume: 747,
          organicRank: 19,
          sponsoredRank: 48,
          keywordSales: 14
        },
        {
          date: '2024-08-17',
          searchVolume: 747,
          organicRank: 20,

          keywordSales: 14
        },
        {
          date: '2024-08-16',
          searchVolume: 713,
          organicRank: 21,

          keywordSales: 14
        },
        {
          date: '2024-08-15',
          searchVolume: 713,
          organicRank: 24,

          keywordSales: 14
        },
        {
          date: '2024-08-14',
          searchVolume: 713,
          organicRank: 22,

          keywordSales: 14
        },
        {
          date: '2024-08-13',
          searchVolume: 713,
          organicRank: 23,

          keywordSales: 14
        },
        {
          date: '2024-08-12',
          searchVolume: 713,
          organicRank: 23,

          keywordSales: 14
        },
        {
          date: '2024-08-11',
          searchVolume: 713,
          organicRank: 22,

          keywordSales: 14
        },
        {
          date: '2024-08-10',
          searchVolume: 713,
          organicRank: 20,

          keywordSales: 14
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 23,

          keywordSales: 14
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 22,

          keywordSales: 14
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 16,

          keywordSales: 14
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 27,

          keywordSales: 14
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 27,

          keywordSales: 14
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 30,

          keywordSales: 14
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 27,

          keywordSales: 14
        },
        {
          date: '2024-08-02',
          searchVolume: 690,
          organicRank: 31,

          keywordSales: 14
        },
        {
          date: '2024-08-01',
          searchVolume: 690,
          organicRank: 25,

          keywordSales: 14
        },
        {
          date: '2024-07-31',
          searchVolume: 690,
          organicRank: 31,

          keywordSales: 14
        },
        {
          date: '2024-07-30',
          searchVolume: 690,
          organicRank: 25,

          keywordSales: 14
        },
        {
          date: '2024-07-29',
          searchVolume: 690,
          organicRank: 28,

          keywordSales: 14
        },
        {
          date: '2024-07-28',
          searchVolume: 690,
          organicRank: 33,

          keywordSales: 14
        },
        {
          date: '2024-07-27',
          searchVolume: 690,
          organicRank: 33,

          keywordSales: 14
        },
        {
          date: '2024-07-26',
          searchVolume: 1582,
          organicRank: 36,

          keywordSales: 14
        },
        {
          date: '2024-07-25',
          searchVolume: 1582,
          organicRank: 42,

          keywordSales: 14
        },
        {
          date: '2024-07-24',
          searchVolume: 1582,
          organicRank: 40,

          keywordSales: 14
        },
        {
          date: '2024-07-23',
          searchVolume: 1582,
          organicRank: 37,

          keywordSales: 14
        },
        {
          date: '2024-07-22',
          searchVolume: 1582,
          organicRank: 41,

          keywordSales: 14
        },
        {
          date: '2024-07-21',
          searchVolume: 1582,
          organicRank: 45,

          keywordSales: 14
        },
        {
          date: '2024-07-20',
          searchVolume: 1582,
          organicRank: 48,

          keywordSales: 14
        },
        {
          date: '2024-07-19',
          searchVolume: 1081,
          organicRank: 50,

          keywordSales: 14
        },
        {
          date: '2024-07-18',
          searchVolume: 1081,
          organicRank: 56,

          keywordSales: 14
        },
        {
          date: '2024-07-17',
          searchVolume: 1081,
          organicRank: 59,

          keywordSales: 14
        },
        {
          date: '2024-07-16',
          searchVolume: 1081,
          organicRank: 58,

          keywordSales: 14
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric toothbrush for braces',
      keyword: 'electric toothbrush for braces',
      searchVolume: 1334,
      organicStartRank: 178,
      organicHighestRank: 55,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-09-09',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-09-08',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-09-07',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-09-06',
          searchVolume: 1361,
          organicRank: 148,

          keywordSales: 35
        },
        {
          date: '2024-09-05',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-09-04',
          searchVolume: 1361,
          organicRank: 106,

          keywordSales: 35
        },
        {
          date: '2024-09-01',
          searchVolume: 1361,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-28',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-27',
          searchVolume: 1432,
          organicRank: 119,

          keywordSales: 35
        },
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 115,

          keywordSales: 35
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 104,

          keywordSales: 35
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 74,

          keywordSales: 35
        },
        {
          date: '2024-08-23',
          searchVolume: 1432,
          organicRank: 94,

          keywordSales: 35
        },
        {
          date: '2024-08-22',
          searchVolume: 1432,
          organicRank: 80,

          keywordSales: 35
        },
        {
          date: '2024-08-21',
          searchVolume: 1432,
          organicRank: 87,

          keywordSales: 35
        },
        {
          date: '2024-08-20',
          searchVolume: 1432,
          organicRank: 77,

          keywordSales: 35
        },
        {
          date: '2024-08-19',
          searchVolume: 1432,
          organicRank: 55,

          keywordSales: 35
        },
        {
          date: '2024-08-17',
          searchVolume: 1432,
          organicRank: 58,

          keywordSales: 35
        },
        {
          date: '2024-08-16',
          searchVolume: 1533,
          organicRank: 73,

          keywordSales: 35
        },
        {
          date: '2024-08-15',
          searchVolume: 1533,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-14',
          searchVolume: 1533,
          organicRank: 97,

          keywordSales: 35
        },
        {
          date: '2024-08-13',
          searchVolume: 1533,
          organicRank: 118,

          keywordSales: 35
        },
        {
          date: '2024-08-12',
          searchVolume: 1533,
          organicRank: 124,

          keywordSales: 35
        },
        {
          date: '2024-08-11',
          searchVolume: 1533,
          organicRank: 109,

          keywordSales: 35
        },
        {
          date: '2024-08-10',
          searchVolume: 1533,
          organicRank: 104,

          keywordSales: 35
        },
        {
          date: '2024-08-09',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-08',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-07',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-06',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-05',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-04',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-03',
          searchVolume: 1334,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-02',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-08-01',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-07-31',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-07-30',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-07-24',
          searchVolume: 1584,
          organicRank: 109,

          keywordSales: 35
        },
        {
          date: '2024-07-23',
          searchVolume: 1584,
          organicRank: 123,

          keywordSales: 35
        },
        {
          date: '2024-07-22',
          searchVolume: 1584,
          organicRank: 142,

          keywordSales: 35
        },
        {
          date: '2024-07-21',
          searchVolume: 1584,
          organicRank: 138,

          keywordSales: 35
        },
        {
          date: '2024-07-20',
          searchVolume: 1584,
          organicRank: 139,

          keywordSales: 35
        },
        {
          date: '2024-07-19',
          searchVolume: 1327,
          organicRank: 147,

          keywordSales: 35
        },
        {
          date: '2024-07-18',
          searchVolume: 1327,
          organicRank: 178,

          keywordSales: 35
        },
        {
          date: '2024-07-17',
          searchVolume: 1327,
          organicRank: 177,

          keywordSales: 35
        },
        {
          date: '2024-07-16',
          searchVolume: 1327,
          organicRank: 148,

          keywordSales: 35
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric toothbrush waterpik combo',
      keyword: 'electric toothbrush waterpik combo',
      searchVolume: 2436,
      organicStartRank: 51,
      organicHighestRank: 14,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-08-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1399,
          organicRank: 33,

          keywordSales: 21
        },
        {
          date: '2024-09-09',
          searchVolume: 1399,
          organicRank: 45,

          keywordSales: 21
        },
        {
          date: '2024-09-08',
          searchVolume: 1399,
          organicRank: 37,
          sponsoredRank: 16,
          keywordSales: 21
        },
        {
          date: '2024-09-07',
          searchVolume: 1399,
          organicRank: 45,

          keywordSales: 21
        },
        {
          date: '2024-09-06',
          searchVolume: 1399,
          organicRank: 34,
          sponsoredRank: 48,
          keywordSales: 21
        },
        {
          date: '2024-09-05',
          searchVolume: 1399,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-09-04',
          searchVolume: 1399,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-09-01',
          searchVolume: 1399,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-28',
          searchVolume: 1657,
          organicRank: 28,

          keywordSales: 21
        },
        {
          date: '2024-08-27',
          searchVolume: 1657,
          organicRank: 34,
          sponsoredRank: 5,
          keywordSales: 21
        },
        {
          date: '2024-08-26',
          searchVolume: 1657,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-25',
          searchVolume: 1657,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-24',
          searchVolume: 1657,
          organicRank: 31,

          keywordSales: 21
        },
        {
          date: '2024-08-23',
          searchVolume: 1432,
          organicRank: 27,

          keywordSales: 21
        },
        {
          date: '2024-08-22',
          searchVolume: 1432,
          organicRank: 27,

          keywordSales: 21
        },
        {
          date: '2024-08-21',
          searchVolume: 1432,
          organicRank: 26,

          keywordSales: 21
        },
        {
          date: '2024-08-20',
          searchVolume: 1432,
          organicRank: 24,

          keywordSales: 21
        },
        {
          date: '2024-08-19',
          searchVolume: 1432,
          organicRank: 14,

          keywordSales: 21
        },
        {
          date: '2024-08-17',
          searchVolume: 1432,
          organicRank: 16,

          keywordSales: 21
        },
        {
          date: '2024-08-16',
          searchVolume: 1209,
          organicRank: 16,

          keywordSales: 21
        },
        {
          date: '2024-08-15',
          searchVolume: 1209,
          organicRank: 20,

          keywordSales: 21
        },
        {
          date: '2024-08-14',
          searchVolume: 1209,
          organicRank: 16,

          keywordSales: 21
        },
        {
          date: '2024-08-13',
          searchVolume: 1209,
          organicRank: 19,

          keywordSales: 21
        },
        {
          date: '2024-08-12',
          searchVolume: 1209,
          organicRank: 16,

          keywordSales: 21
        },
        {
          date: '2024-08-11',
          searchVolume: 1209,
          organicRank: 15,

          keywordSales: 21
        },
        {
          date: '2024-08-10',
          searchVolume: 1209,
          organicRank: 19,

          keywordSales: 21
        },
        {
          date: '2024-08-09',
          searchVolume: 888,
          organicRank: 25,

          keywordSales: 21
        },
        {
          date: '2024-08-08',
          searchVolume: 888,
          organicRank: 18,

          keywordSales: 21
        },
        {
          date: '2024-08-07',
          searchVolume: 888,
          organicRank: 18,

          keywordSales: 21
        },
        {
          date: '2024-08-06',
          searchVolume: 888,
          organicRank: 23,

          keywordSales: 21
        },
        {
          date: '2024-08-05',
          searchVolume: 888,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-04',
          searchVolume: 888,
          organicRank: 30,

          keywordSales: 21
        },
        {
          date: '2024-08-03',
          searchVolume: 888,
          organicRank: 34,

          keywordSales: 21
        },
        {
          date: '2024-08-02',
          searchVolume: 1322,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 30,

          keywordSales: 21
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 34,

          keywordSales: 21
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 44,

          keywordSales: 21
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 40,

          keywordSales: 21
        },
        {
          date: '2024-07-26',
          searchVolume: 2436,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-07-25',
          searchVolume: 2436,
          organicRank: 31,

          keywordSales: 21
        },
        {
          date: '2024-07-24',
          searchVolume: 2436,
          organicRank: 37,

          keywordSales: 21
        },
        {
          date: '2024-07-23',
          searchVolume: 2436,
          organicRank: 35,

          keywordSales: 21
        },
        {
          date: '2024-07-22',
          searchVolume: 2436,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-07-21',
          searchVolume: 2436,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-07-20',
          searchVolume: 2436,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-07-19',
          searchVolume: 2280,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-07-18',
          searchVolume: 2280,
          organicRank: 51,

          keywordSales: 21
        },
        {
          date: '2024-07-17',
          searchVolume: 2280,
          organicRank: 52,

          keywordSales: 21
        },
        {
          date: '2024-07-16',
          searchVolume: 2280,
          organicRank: 47,

          keywordSales: 21
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric toothbrush with water flosser',
      keyword: 'electric toothbrush with water flosser',
      searchVolume: 10542,
      organicStartRank: 90,
      organicHighestRank: 33,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4409,
          organicRank: 69,

          keywordSales: 114
        },
        {
          date: '2024-09-09',
          searchVolume: 4409,
          organicRank: 80,

          keywordSales: 114
        },
        {
          date: '2024-09-08',
          searchVolume: 4409,
          organicRank: 83,

          keywordSales: 114
        },
        {
          date: '2024-09-07',
          searchVolume: 4409,
          organicRank: 82,

          keywordSales: 114
        },
        {
          date: '2024-09-05',
          searchVolume: 4409,
          organicRank: 81,

          keywordSales: 114
        },
        {
          date: '2024-09-01',
          searchVolume: 4409,
          organicRank: 306,

          keywordSales: 114
        },
        {
          date: '2024-08-28',
          searchVolume: 4556,
          organicRank: 69,

          keywordSales: 114
        },
        {
          date: '2024-08-27',
          searchVolume: 4556,
          organicRank: 49,

          keywordSales: 114
        },
        {
          date: '2024-08-26',
          searchVolume: 4556,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-08-25',
          searchVolume: 4556,
          organicRank: 53,

          keywordSales: 114
        },
        {
          date: '2024-08-24',
          searchVolume: 4556,
          organicRank: 64,

          keywordSales: 114
        },
        {
          date: '2024-08-23',
          searchVolume: 4550,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-08-22',
          searchVolume: 4550,
          organicRank: 59,

          keywordSales: 114
        },
        {
          date: '2024-08-20',
          searchVolume: 4550,
          organicRank: 61,

          keywordSales: 114
        },
        {
          date: '2024-08-19',
          searchVolume: 4550,
          organicRank: 56,

          keywordSales: 114
        },
        {
          date: '2024-08-17',
          searchVolume: 4550,
          organicRank: 54,

          keywordSales: 114
        },
        {
          date: '2024-08-16',
          searchVolume: 4123,
          organicRank: 47,

          keywordSales: 114
        },
        {
          date: '2024-08-15',
          searchVolume: 4123,
          organicRank: 47,

          keywordSales: 114
        },
        {
          date: '2024-08-14',
          searchVolume: 4123,
          organicRank: 33,

          keywordSales: 114
        },
        {
          date: '2024-08-13',
          searchVolume: 4123,
          organicRank: 73,

          keywordSales: 114
        },
        {
          date: '2024-08-12',
          searchVolume: 4123,
          organicRank: 41,

          keywordSales: 114
        },
        {
          date: '2024-08-11',
          searchVolume: 4123,
          organicRank: 76,

          keywordSales: 114
        },
        {
          date: '2024-08-10',
          searchVolume: 4123,
          organicRank: 75,

          keywordSales: 114
        },
        {
          date: '2024-08-09',
          searchVolume: 4236,
          organicRank: 71,

          keywordSales: 114
        },
        {
          date: '2024-08-08',
          searchVolume: 4236,
          organicRank: 63,

          keywordSales: 114
        },
        {
          date: '2024-08-07',
          searchVolume: 4236,
          organicRank: 44,

          keywordSales: 114
        },
        {
          date: '2024-08-06',
          searchVolume: 4236,
          organicRank: 64,

          keywordSales: 114
        },
        {
          date: '2024-08-05',
          searchVolume: 4236,
          organicRank: 66,

          keywordSales: 114
        },
        {
          date: '2024-08-04',
          searchVolume: 4236,
          organicRank: 65,

          keywordSales: 114
        },
        {
          date: '2024-08-03',
          searchVolume: 4236,
          organicRank: 62,

          keywordSales: 114
        },
        {
          date: '2024-08-02',
          searchVolume: 4746,
          organicRank: 67,

          keywordSales: 114
        },
        {
          date: '2024-08-01',
          searchVolume: 4746,
          organicRank: 62,

          keywordSales: 114
        },
        {
          date: '2024-07-31',
          searchVolume: 4746,
          organicRank: 60,

          keywordSales: 114
        },
        {
          date: '2024-07-30',
          searchVolume: 4746,
          organicRank: 63,

          keywordSales: 114
        },
        {
          date: '2024-07-29',
          searchVolume: 4746,
          organicRank: 67,

          keywordSales: 114
        },
        {
          date: '2024-07-28',
          searchVolume: 4746,
          organicRank: 64,

          keywordSales: 114
        },
        {
          date: '2024-07-27',
          searchVolume: 4746,
          organicRank: 65,

          keywordSales: 114
        },
        {
          date: '2024-07-26',
          searchVolume: 10542,
          organicRank: 63,

          keywordSales: 114
        },
        {
          date: '2024-07-25',
          searchVolume: 10542,
          organicRank: 68,

          keywordSales: 114
        },
        {
          date: '2024-07-24',
          searchVolume: 10542,
          organicRank: 68,

          keywordSales: 114
        },
        {
          date: '2024-07-23',
          searchVolume: 10542,
          organicRank: 60,

          keywordSales: 114
        },
        {
          date: '2024-07-22',
          searchVolume: 10542,
          organicRank: 68,

          keywordSales: 114
        },
        {
          date: '2024-07-21',
          searchVolume: 10542,
          organicRank: 68,

          keywordSales: 114
        },
        {
          date: '2024-07-20',
          searchVolume: 10542,
          organicRank: 70,

          keywordSales: 114
        },
        {
          date: '2024-07-19',
          searchVolume: 5369,
          organicRank: 94,

          keywordSales: 114
        },
        {
          date: '2024-07-18',
          searchVolume: 5369,
          organicRank: 90,

          keywordSales: 114
        },
        {
          date: '2024-07-17',
          searchVolume: 5369,
          organicRank: 87,

          keywordSales: 114
        },
        {
          date: '2024-07-16',
          searchVolume: 5369,
          organicRank: 104,

          keywordSales: 114
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_electric water flosser',
      keyword: 'electric water flosser',
      searchVolume: 1827,
      organicStartRank: 59,
      organicHighestRank: 4,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-08-01',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 22,

          keywordSales: 49
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 24,

          keywordSales: 49
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 25,
          sponsoredRank: 8,
          keywordSales: 49
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 25,

          keywordSales: 49
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 23,
          sponsoredRank: 9,
          keywordSales: 49
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 24,

          keywordSales: 49
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 28,

          keywordSales: 49
        },
        {
          date: '2024-09-01',
          searchVolume: 1388,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-08-28',
          searchVolume: 1432,
          organicRank: 25,

          keywordSales: 49
        },
        {
          date: '2024-08-27',
          searchVolume: 1432,
          organicRank: 18,

          keywordSales: 49
        },
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 17,
          sponsoredRank: 6,
          keywordSales: 49
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 23,

          keywordSales: 49
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 18,

          keywordSales: 49
        },
        {
          date: '2024-08-23',
          searchVolume: 1664,
          organicRank: 20,

          keywordSales: 49
        },
        {
          date: '2024-08-22',
          searchVolume: 1664,
          organicRank: 4,

          keywordSales: 49
        },
        {
          date: '2024-08-21',
          searchVolume: 1664,
          organicRank: 20,

          keywordSales: 49
        },
        {
          date: '2024-08-20',
          searchVolume: 1664,
          organicRank: 8,

          keywordSales: 49
        },
        {
          date: '2024-08-19',
          searchVolume: 1664,
          organicRank: 16,
          sponsoredRank: 65,
          keywordSales: 49
        },
        {
          date: '2024-08-17',
          searchVolume: 1664,
          organicRank: 15,

          keywordSales: 49
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 14,

          keywordSales: 49
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 14,

          keywordSales: 49
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 16,

          keywordSales: 49
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 16,

          keywordSales: 49
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 16,

          keywordSales: 49
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 13,

          keywordSales: 49
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 16,

          keywordSales: 49
        },
        {
          date: '2024-08-09',
          searchVolume: 1334,
          organicRank: 29,

          keywordSales: 49
        },
        {
          date: '2024-08-08',
          searchVolume: 1334,
          organicRank: 26,

          keywordSales: 49
        },
        {
          date: '2024-08-07',
          searchVolume: 1334,
          organicRank: 23,

          keywordSales: 49
        },
        {
          date: '2024-08-06',
          searchVolume: 1334,
          organicRank: 22,

          keywordSales: 49
        },
        {
          date: '2024-08-05',
          searchVolume: 1334,
          organicRank: 26,
          sponsoredRank: 6,
          keywordSales: 49
        },
        {
          date: '2024-08-04',
          searchVolume: 1334,
          organicRank: 23,

          keywordSales: 49
        },
        {
          date: '2024-08-03',
          searchVolume: 1334,
          organicRank: 27,

          keywordSales: 49
        },
        {
          date: '2024-08-02',
          searchVolume: 1303,
          organicRank: 21,

          keywordSales: 49
        },
        {
          date: '2024-08-01',
          searchVolume: 1303,
          organicRank: 20,
          sponsoredRank: 4,
          keywordSales: 49
        },
        {
          date: '2024-07-31',
          searchVolume: 1303,
          organicRank: 30,

          keywordSales: 49
        },
        {
          date: '2024-07-30',
          searchVolume: 1303,
          organicRank: 27,

          keywordSales: 49
        },
        {
          date: '2024-07-29',
          searchVolume: 1303,
          organicRank: 28,

          keywordSales: 49
        },
        {
          date: '2024-07-28',
          searchVolume: 1303,
          organicRank: 34,

          keywordSales: 49
        },
        {
          date: '2024-07-27',
          searchVolume: 1303,
          organicRank: 33,

          keywordSales: 49
        },
        {
          date: '2024-07-26',
          searchVolume: 1827,
          organicRank: 33,
          sponsoredRank: 4,
          keywordSales: 49
        },
        {
          date: '2024-07-25',
          searchVolume: 1827,
          organicRank: 42,

          keywordSales: 49
        },
        {
          date: '2024-07-24',
          searchVolume: 1827,
          organicRank: 28,

          keywordSales: 49
        },
        {
          date: '2024-07-23',
          searchVolume: 1827,
          organicRank: 27,

          keywordSales: 49
        },
        {
          date: '2024-07-22',
          searchVolume: 1827,
          organicRank: 34,

          keywordSales: 49
        },
        {
          date: '2024-07-21',
          searchVolume: 1827,
          organicRank: 36,

          keywordSales: 49
        },
        {
          date: '2024-07-20',
          searchVolume: 1827,
          organicRank: 40,

          keywordSales: 49
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 45,

          keywordSales: 49
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 59,

          keywordSales: 49
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 46,

          keywordSales: 49
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_floss for braces',
      keyword: 'floss for braces',
      searchVolume: 10276,
      organicStartRank: 221,
      organicHighestRank: 35,
      sponsoredStartRank: 30,
      sponsoredHighestRank: 30,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 11393,
          organicRank: 127,

          keywordSales: 693
        },
        {
          date: '2024-09-09',
          searchVolume: 11393,
          organicRank: 142,

          keywordSales: 693
        },
        {
          date: '2024-09-08',
          searchVolume: 11393,
          organicRank: 145,
          sponsoredRank: 36,
          keywordSales: 693
        },
        {
          date: '2024-09-07',
          searchVolume: 11393,
          organicRank: 105,

          keywordSales: 693
        },
        {
          date: '2024-09-06',
          searchVolume: 11393,
          organicRank: 103,

          keywordSales: 693
        },
        {
          date: '2024-09-05',
          searchVolume: 11393,
          organicRank: 125,
          sponsoredRank: 57,
          keywordSales: 693
        },
        {
          date: '2024-09-01',
          searchVolume: 11393,
          organicRank: 116,

          keywordSales: 693
        },
        {
          date: '2024-08-28',
          searchVolume: 11771,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 693
        },
        {
          date: '2024-08-27',
          searchVolume: 11771,
          organicRank: 105,

          keywordSales: 693
        },
        {
          date: '2024-08-26',
          searchVolume: 11771,
          organicRank: 103,

          keywordSales: 693
        },
        {
          date: '2024-08-25',
          searchVolume: 11771,
          organicRank: 107,

          keywordSales: 693
        },
        {
          date: '2024-08-24',
          searchVolume: 11771,
          organicRank: 108,

          keywordSales: 693
        },
        {
          date: '2024-08-23',
          searchVolume: 11771,
          organicRank: 94,

          keywordSales: 693
        },
        {
          date: '2024-08-22',
          searchVolume: 11771,
          organicRank: 70,

          keywordSales: 693
        },
        {
          date: '2024-08-20',
          searchVolume: 11771,
          organicRank: 75,

          keywordSales: 693
        },
        {
          date: '2024-08-19',
          searchVolume: 11771,
          organicRank: 73,

          keywordSales: 693
        },
        {
          date: '2024-08-16',
          searchVolume: 12817,
          organicRank: 117,

          keywordSales: 693
        },
        {
          date: '2024-08-15',
          searchVolume: 12817,
          organicRank: 102,

          keywordSales: 693
        },
        {
          date: '2024-08-14',
          searchVolume: 12817,
          organicRank: 91,

          keywordSales: 693
        },
        {
          date: '2024-08-13',
          searchVolume: 12817,
          organicRank: 104,

          keywordSales: 693
        },
        {
          date: '2024-08-12',
          searchVolume: 12817,
          organicRank: 105,

          keywordSales: 693
        },
        {
          date: '2024-08-11',
          searchVolume: 12817,
          organicRank: 112,

          keywordSales: 693
        },
        {
          date: '2024-08-10',
          searchVolume: 12817,
          organicRank: 126,

          keywordSales: 693
        },
        {
          date: '2024-08-09',
          searchVolume: 12534,
          organicRank: 121,

          keywordSales: 693
        },
        {
          date: '2024-08-08',
          searchVolume: 12534,
          organicRank: 106,

          keywordSales: 693
        },
        {
          date: '2024-08-07',
          searchVolume: 12534,
          organicRank: 104,

          keywordSales: 693
        },
        {
          date: '2024-08-06',
          searchVolume: 12534,
          organicRank: 121,

          keywordSales: 693
        },
        {
          date: '2024-08-05',
          searchVolume: 12534,
          organicRank: 125,

          keywordSales: 693
        },
        {
          date: '2024-08-04',
          searchVolume: 12534,
          organicRank: 120,

          keywordSales: 693
        },
        {
          date: '2024-08-03',
          searchVolume: 12534,
          organicRank: 159,

          keywordSales: 693
        },
        {
          date: '2024-08-02',
          searchVolume: 12381,
          organicRank: 116,

          keywordSales: 693
        },
        {
          date: '2024-08-01',
          searchVolume: 12381,
          organicRank: 101,

          keywordSales: 693
        },
        {
          date: '2024-07-31',
          searchVolume: 12381,
          organicRank: 118,

          keywordSales: 693
        },
        {
          date: '2024-07-30',
          searchVolume: 12381,
          organicRank: 112,

          keywordSales: 693
        },
        {
          date: '2024-07-29',
          searchVolume: 12381,
          organicRank: 104,

          keywordSales: 693
        },
        {
          date: '2024-07-28',
          searchVolume: 12381,
          organicRank: 42,

          keywordSales: 693
        },
        {
          date: '2024-07-27',
          searchVolume: 12381,
          organicRank: 42,

          keywordSales: 693
        },
        {
          date: '2024-07-26',
          searchVolume: 10276,
          organicRank: 42,

          keywordSales: 693
        },
        {
          date: '2024-07-25',
          searchVolume: 10276,
          organicRank: 35,

          keywordSales: 693
        },
        {
          date: '2024-07-24',
          searchVolume: 10276,
          organicRank: 136,

          keywordSales: 693
        },
        {
          date: '2024-07-23',
          searchVolume: 10276,
          organicRank: 170,

          keywordSales: 693
        },
        {
          date: '2024-07-22',
          searchVolume: 10276,
          organicRank: 167,

          keywordSales: 693
        },
        {
          date: '2024-07-21',
          searchVolume: 10276,
          organicRank: 178,

          keywordSales: 693
        },
        {
          date: '2024-07-20',
          searchVolume: 10276,
          organicRank: 179,

          keywordSales: 693
        },
        {
          date: '2024-07-19',
          searchVolume: 11031,
          organicRank: 148,

          keywordSales: 693
        },
        {
          date: '2024-07-18',
          searchVolume: 11031,
          organicRank: 221,

          keywordSales: 693
        },
        {
          date: '2024-07-17',
          searchVolume: 11031,
          organicRank: 213,

          keywordSales: 693
        },
        {
          date: '2024-07-16',
          searchVolume: 11031,
          organicRank: 175,

          keywordSales: 693
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_floss picks for braces',
      keyword: 'floss picks for braces',
      searchVolume: 1839,
      organicStartRank: 253,
      organicHighestRank: 75,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 22,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2468,
          organicRank: 113,

          keywordSales: 131
        },
        {
          date: '2024-09-09',
          searchVolume: 2468,
          organicRank: 183,

          keywordSales: 131
        },
        {
          date: '2024-09-08',
          searchVolume: 2468,
          organicRank: 136,

          keywordSales: 131
        },
        {
          date: '2024-09-07',
          searchVolume: 2468,
          organicRank: 169,

          keywordSales: 131
        },
        {
          date: '2024-09-06',
          searchVolume: 2468,
          organicRank: 158,

          keywordSales: 131
        },
        {
          date: '2024-09-05',
          searchVolume: 2468,
          organicRank: 165,

          keywordSales: 131
        },
        {
          date: '2024-09-01',
          searchVolume: 2468,
          organicRank: 154,

          keywordSales: 131
        },
        {
          date: '2024-08-30',
          searchVolume: 2430,
          organicRank: 142,

          keywordSales: 131
        },
        {
          date: '2024-08-28',
          searchVolume: 2430,
          organicRank: 153,
          sponsoredRank: 22,
          keywordSales: 131
        },
        {
          date: '2024-08-27',
          searchVolume: 2430,
          organicRank: 122,

          keywordSales: 131
        },
        {
          date: '2024-08-26',
          searchVolume: 2430,
          organicRank: 115,

          keywordSales: 131
        },
        {
          date: '2024-08-25',
          searchVolume: 2430,
          organicRank: 80,

          keywordSales: 131
        },
        {
          date: '2024-08-24',
          searchVolume: 2430,
          organicRank: 123,

          keywordSales: 131
        },
        {
          date: '2024-08-23',
          searchVolume: 2353,
          organicRank: 122,

          keywordSales: 131
        },
        {
          date: '2024-08-22',
          searchVolume: 2353,
          organicRank: 104,

          keywordSales: 131
        },
        {
          date: '2024-08-21',
          searchVolume: 2353,
          organicRank: 101,

          keywordSales: 131
        },
        {
          date: '2024-08-19',
          searchVolume: 2353,
          organicRank: 93,

          keywordSales: 131
        },
        {
          date: '2024-08-18',
          searchVolume: 2353,
          organicRank: 93,

          keywordSales: 131
        },
        {
          date: '2024-08-16',
          searchVolume: 2434,
          organicRank: 103,

          keywordSales: 131
        },
        {
          date: '2024-08-15',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 131
        },
        {
          date: '2024-08-14',
          searchVolume: 2434,
          organicRank: 89,

          keywordSales: 131
        },
        {
          date: '2024-08-13',
          searchVolume: 2434,
          organicRank: 75,

          keywordSales: 131
        },
        {
          date: '2024-08-12',
          searchVolume: 2434,
          organicRank: 116,

          keywordSales: 131
        },
        {
          date: '2024-08-11',
          searchVolume: 2434,
          organicRank: 124,

          keywordSales: 131
        },
        {
          date: '2024-08-10',
          searchVolume: 2434,
          organicRank: 131,

          keywordSales: 131
        },
        {
          date: '2024-08-09',
          searchVolume: 2265,
          organicRank: 131,

          keywordSales: 131
        },
        {
          date: '2024-08-08',
          searchVolume: 2265,
          organicRank: 135,

          keywordSales: 131
        },
        {
          date: '2024-08-07',
          searchVolume: 2265,
          organicRank: 142,

          keywordSales: 131
        },
        {
          date: '2024-08-06',
          searchVolume: 2265,
          organicRank: 130,

          keywordSales: 131
        },
        {
          date: '2024-08-05',
          searchVolume: 2265,
          organicRank: 306,

          keywordSales: 131
        },
        {
          date: '2024-08-04',
          searchVolume: 2265,
          organicRank: 142,

          keywordSales: 131
        },
        {
          date: '2024-08-03',
          searchVolume: 2265,
          organicRank: 124,

          keywordSales: 131
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 126,

          keywordSales: 131
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 114,

          keywordSales: 131
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 133,

          keywordSales: 131
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 131,

          keywordSales: 131
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 130,

          keywordSales: 131
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 129,

          keywordSales: 131
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 135,

          keywordSales: 131
        },
        {
          date: '2024-07-26',
          searchVolume: 1839,
          organicRank: 133,

          keywordSales: 131
        },
        {
          date: '2024-07-25',
          searchVolume: 1839,
          organicRank: 130,

          keywordSales: 131
        },
        {
          date: '2024-07-24',
          searchVolume: 1839,
          organicRank: 121,

          keywordSales: 131
        },
        {
          date: '2024-07-23',
          searchVolume: 1839,
          organicRank: 131,

          keywordSales: 131
        },
        {
          date: '2024-07-22',
          searchVolume: 1839,
          organicRank: 152,

          keywordSales: 131
        },
        {
          date: '2024-07-21',
          searchVolume: 1839,
          organicRank: 152,

          keywordSales: 131
        },
        {
          date: '2024-07-20',
          searchVolume: 1839,
          organicRank: 148,

          keywordSales: 131
        },
        {
          date: '2024-07-19',
          searchVolume: 2113,
          organicRank: 155,

          keywordSales: 131
        },
        {
          date: '2024-07-18',
          searchVolume: 2113,
          organicRank: 253,

          keywordSales: 131
        },
        {
          date: '2024-07-17',
          searchVolume: 2113,
          organicRank: 254,

          keywordSales: 131
        },
        {
          date: '2024-07-16',
          searchVolume: 2113,
          organicRank: 192,

          keywordSales: 131
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flosser',
      keyword: 'flosser',
      searchVolume: 13531,
      organicStartRank: 86,
      organicHighestRank: 6,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-28',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 10723,
          organicRank: 33,

          keywordSales: 565
        },
        {
          date: '2024-09-09',
          searchVolume: 10723,
          organicRank: 43,

          keywordSales: 565
        },
        {
          date: '2024-09-08',
          searchVolume: 10723,
          organicRank: 53,
          sponsoredRank: 11,
          keywordSales: 565
        },
        {
          date: '2024-09-07',
          searchVolume: 10723,
          organicRank: 42,

          keywordSales: 565
        },
        {
          date: '2024-09-06',
          searchVolume: 10723,
          organicRank: 35,

          keywordSales: 565
        },
        {
          date: '2024-09-05',
          searchVolume: 10723,
          organicRank: 38,

          keywordSales: 565
        },
        {
          date: '2024-09-01',
          searchVolume: 10723,
          organicRank: 20,
          sponsoredRank: 24,
          keywordSales: 565
        },
        {
          date: '2024-08-28',
          searchVolume: 11741,
          organicRank: 15,

          keywordSales: 565
        },
        {
          date: '2024-08-27',
          searchVolume: 11741,
          organicRank: 13,

          keywordSales: 565
        },
        {
          date: '2024-08-26',
          searchVolume: 11741,
          organicRank: 16,

          keywordSales: 565
        },
        {
          date: '2024-08-25',
          searchVolume: 11741,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 565
        },
        {
          date: '2024-08-24',
          searchVolume: 11741,
          organicRank: 16,

          keywordSales: 565
        },
        {
          date: '2024-08-23',
          searchVolume: 11752,
          organicRank: 11,

          keywordSales: 565
        },
        {
          date: '2024-08-22',
          searchVolume: 11752,
          organicRank: 13,

          keywordSales: 565
        },
        {
          date: '2024-08-21',
          searchVolume: 11752,
          organicRank: 16,

          keywordSales: 565
        },
        {
          date: '2024-08-20',
          searchVolume: 11752,
          organicRank: 15,

          keywordSales: 565
        },
        {
          date: '2024-08-19',
          searchVolume: 11752,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 565
        },
        {
          date: '2024-08-17',
          searchVolume: 11752,
          organicRank: 10,
          sponsoredRank: 45,
          keywordSales: 565
        },
        {
          date: '2024-08-16',
          searchVolume: 10300,
          organicRank: 8,

          keywordSales: 565
        },
        {
          date: '2024-08-15',
          searchVolume: 10300,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 565
        },
        {
          date: '2024-08-14',
          searchVolume: 10300,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-08-13',
          searchVolume: 10300,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-08-12',
          searchVolume: 10300,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-08-11',
          searchVolume: 10300,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-08-10',
          searchVolume: 10300,
          organicRank: 18,

          keywordSales: 565
        },
        {
          date: '2024-08-09',
          searchVolume: 10050,
          organicRank: 19,

          keywordSales: 565
        },
        {
          date: '2024-08-08',
          searchVolume: 10050,
          organicRank: 15,

          keywordSales: 565
        },
        {
          date: '2024-08-07',
          searchVolume: 10050,
          organicRank: 17,

          keywordSales: 565
        },
        {
          date: '2024-08-06',
          searchVolume: 10050,
          organicRank: 17,

          keywordSales: 565
        },
        {
          date: '2024-08-05',
          searchVolume: 10050,
          organicRank: 15,
          sponsoredRank: 33,
          keywordSales: 565
        },
        {
          date: '2024-08-04',
          searchVolume: 10050,
          organicRank: 16,
          sponsoredRank: 6,
          keywordSales: 565
        },
        {
          date: '2024-08-02',
          searchVolume: 9928,
          organicRank: 14,
          sponsoredRank: 5,
          keywordSales: 565
        },
        {
          date: '2024-08-01',
          searchVolume: 9928,
          organicRank: 23,
          sponsoredRank: 3,
          keywordSales: 565
        },
        {
          date: '2024-07-31',
          searchVolume: 9928,
          organicRank: 26,

          keywordSales: 565
        },
        {
          date: '2024-07-30',
          searchVolume: 9928,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-07-29',
          searchVolume: 9928,
          organicRank: 20,

          keywordSales: 565
        },
        {
          date: '2024-07-28',
          searchVolume: 9928,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 565
        },
        {
          date: '2024-07-27',
          searchVolume: 9928,
          organicRank: 21,

          keywordSales: 565
        },
        {
          date: '2024-07-26',
          searchVolume: 13531,
          organicRank: 24,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-07-25',
          searchVolume: 13531,
          organicRank: 61,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-07-24',
          searchVolume: 13531,
          organicRank: 79,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-07-23',
          searchVolume: 13531,
          organicRank: 73,
          sponsoredRank: 1,
          keywordSales: 565
        },
        {
          date: '2024-07-22',
          searchVolume: 13531,
          organicRank: 69,

          keywordSales: 565
        },
        {
          date: '2024-07-21',
          searchVolume: 13531,
          organicRank: 63,

          keywordSales: 565
        },
        {
          date: '2024-07-20',
          searchVolume: 13531,
          organicRank: 71,

          keywordSales: 565
        },
        {
          date: '2024-07-19',
          searchVolume: 10107,
          organicRank: 83,

          keywordSales: 565
        },
        {
          date: '2024-07-18',
          searchVolume: 10107,
          organicRank: 86,

          keywordSales: 565
        },
        {
          date: '2024-07-17',
          searchVolume: 10107,
          organicRank: 83,

          keywordSales: 565
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flosser picks',
      keyword: 'flosser picks',
      searchVolume: 2374,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-09',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-08',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-07',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-06',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-05',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-09-01',
          searchVolume: 2468,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-30',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-28',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-27',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-26',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-25',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-24',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-23',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-22',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-20',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-19',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-16',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-15',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-14',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-13',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-12',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-11',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-10',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-09',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-08',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-07',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-06',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-05',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-04',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-03',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 210
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 210
        },
        {
          date: '2024-07-30',
          searchVolume: 2149,
          organicRank: 306,

          keywordSales: 217
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flossers',
      keyword: 'flossers',
      searchVolume: 36146,
      organicStartRank: 182,
      organicHighestRank: 76,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-09',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-08',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-07',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-06',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-05',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-04',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-09-01',
          searchVolume: 43627,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-30',
          searchVolume: 52299,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-28',
          searchVolume: 52299,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-27',
          searchVolume: 52299,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-26',
          searchVolume: 52299,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-25',
          searchVolume: 52299,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 1327
        },
        {
          date: '2024-08-24',
          searchVolume: 52299,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-23',
          searchVolume: 51207,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-22',
          searchVolume: 51207,
          organicRank: 306,

          keywordSales: 1327
        },
        {
          date: '2024-08-20',
          searchVolume: 51207,
          organicRank: 124,

          keywordSales: 1327
        },
        {
          date: '2024-08-19',
          searchVolume: 51207,
          organicRank: 134,

          keywordSales: 1327
        },
        {
          date: '2024-08-16',
          searchVolume: 42662,
          organicRank: 123,

          keywordSales: 1327
        },
        {
          date: '2024-08-15',
          searchVolume: 42662,
          organicRank: 129,

          keywordSales: 1327
        },
        {
          date: '2024-08-14',
          searchVolume: 42662,
          organicRank: 152,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-13',
          searchVolume: 42662,
          organicRank: 142,
          sponsoredRank: 3,
          keywordSales: 1327
        },
        {
          date: '2024-08-12',
          searchVolume: 42662,
          organicRank: 133,
          sponsoredRank: 4,
          keywordSales: 1327
        },
        {
          date: '2024-08-11',
          searchVolume: 42662,
          organicRank: 130,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-10',
          searchVolume: 42662,
          organicRank: 136,
          sponsoredRank: 18,
          keywordSales: 1327
        },
        {
          date: '2024-08-09',
          searchVolume: 41654,
          organicRank: 121,
          sponsoredRank: 6,
          keywordSales: 1327
        },
        {
          date: '2024-08-08',
          searchVolume: 41654,
          organicRank: 125,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-07',
          searchVolume: 41654,
          organicRank: 76,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-06',
          searchVolume: 41654,
          organicRank: 115,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-05',
          searchVolume: 41654,
          organicRank: 111,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-08-04',
          searchVolume: 41654,
          organicRank: 108,
          sponsoredRank: 10,
          keywordSales: 1327
        },
        {
          date: '2024-08-02',
          searchVolume: 37396,
          organicRank: 117,

          keywordSales: 1327
        },
        {
          date: '2024-08-01',
          searchVolume: 37396,
          organicRank: 110,
          sponsoredRank: 20,
          keywordSales: 1327
        },
        {
          date: '2024-07-31',
          searchVolume: 37396,
          organicRank: 114,

          keywordSales: 1327
        },
        {
          date: '2024-07-30',
          searchVolume: 37396,
          organicRank: 129,
          sponsoredRank: 10,
          keywordSales: 1327
        },
        {
          date: '2024-07-29',
          searchVolume: 37396,
          organicRank: 133,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-07-28',
          searchVolume: 37396,
          organicRank: 137,
          sponsoredRank: 24,
          keywordSales: 1327
        },
        {
          date: '2024-07-27',
          searchVolume: 37396,
          organicRank: 142,
          sponsoredRank: 7,
          keywordSales: 1327
        },
        {
          date: '2024-07-26',
          searchVolume: 36146,
          organicRank: 159,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-07-25',
          searchVolume: 36146,
          organicRank: 154,
          sponsoredRank: 4,
          keywordSales: 1327
        },
        {
          date: '2024-07-24',
          searchVolume: 36146,
          organicRank: 158,
          sponsoredRank: 2,
          keywordSales: 1327
        },
        {
          date: '2024-07-23',
          searchVolume: 36146,
          organicRank: 172,
          sponsoredRank: 1,
          keywordSales: 1327
        },
        {
          date: '2024-07-22',
          searchVolume: 36146,
          organicRank: 177,

          keywordSales: 1327
        },
        {
          date: '2024-07-21',
          searchVolume: 36146,
          organicRank: 172,

          keywordSales: 1327
        },
        {
          date: '2024-07-20',
          searchVolume: 36146,
          organicRank: 167,

          keywordSales: 1327
        },
        {
          date: '2024-07-19',
          searchVolume: 35672,
          organicRank: 168,

          keywordSales: 1327
        },
        {
          date: '2024-07-18',
          searchVolume: 35672,
          organicRank: 182,

          keywordSales: 1327
        },
        {
          date: '2024-07-17',
          searchVolume: 35672,
          organicRank: 171,

          keywordSales: 1327
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flossers for adults',
      keyword: 'flossers for adults',
      searchVolume: 24175,
      organicStartRank: 158,
      organicHighestRank: 114,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 31502,
          organicRank: 161,

          keywordSales: 959
        },
        {
          date: '2024-09-09',
          searchVolume: 31502,
          organicRank: 163,

          keywordSales: 959
        },
        {
          date: '2024-09-08',
          searchVolume: 31502,
          organicRank: 151,
          sponsoredRank: 6,
          keywordSales: 959
        },
        {
          date: '2024-09-07',
          searchVolume: 31502,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-09-06',
          searchVolume: 31502,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-09-05',
          searchVolume: 31502,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-09-01',
          searchVolume: 31502,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-28',
          searchVolume: 32174,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-27',
          searchVolume: 32174,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-26',
          searchVolume: 32174,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-25',
          searchVolume: 32174,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-24',
          searchVolume: 32174,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-23',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-22',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-21',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-20',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-19',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-17',
          searchVolume: 32168,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-16',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-15',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-14',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-13',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-12',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-11',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-10',
          searchVolume: 30666,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-09',
          searchVolume: 29651,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-08',
          searchVolume: 29651,
          organicRank: 306,

          keywordSales: 959
        },
        {
          date: '2024-08-07',
          searchVolume: 29651,
          organicRank: 129,

          keywordSales: 959
        },
        {
          date: '2024-08-06',
          searchVolume: 29651,
          organicRank: 133,

          keywordSales: 959
        },
        {
          date: '2024-08-05',
          searchVolume: 29651,
          organicRank: 135,

          keywordSales: 959
        },
        {
          date: '2024-08-04',
          searchVolume: 29651,
          organicRank: 143,

          keywordSales: 959
        },
        {
          date: '2024-08-03',
          searchVolume: 29651,
          organicRank: 142,

          keywordSales: 959
        },
        {
          date: '2024-08-02',
          searchVolume: 29654,
          organicRank: 136,

          keywordSales: 959
        },
        {
          date: '2024-08-01',
          searchVolume: 29654,
          organicRank: 133,
          sponsoredRank: 6,
          keywordSales: 959
        },
        {
          date: '2024-07-31',
          searchVolume: 29654,
          organicRank: 136,

          keywordSales: 959
        },
        {
          date: '2024-07-30',
          searchVolume: 29654,
          organicRank: 134,

          keywordSales: 959
        },
        {
          date: '2024-07-29',
          searchVolume: 29654,
          organicRank: 130,

          keywordSales: 959
        },
        {
          date: '2024-07-28',
          searchVolume: 29654,
          organicRank: 132,

          keywordSales: 959
        },
        {
          date: '2024-07-27',
          searchVolume: 29654,
          organicRank: 131,

          keywordSales: 959
        },
        {
          date: '2024-07-22',
          searchVolume: 24175,
          organicRank: 120,

          keywordSales: 959
        },
        {
          date: '2024-07-21',
          searchVolume: 24175,
          organicRank: 114,

          keywordSales: 959
        },
        {
          date: '2024-07-20',
          searchVolume: 24175,
          organicRank: 158,

          keywordSales: 959
        },
        {
          date: '2024-07-19',
          searchVolume: 27212,
          organicRank: 165,

          keywordSales: 959
        },
        {
          date: '2024-07-18',
          searchVolume: 27212,
          organicRank: 158,

          keywordSales: 959
        },
        {
          date: '2024-07-17',
          searchVolume: 27212,
          organicRank: 154,

          keywordSales: 959
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flossers for braces',
      keyword: 'flossers for braces',
      searchVolume: 5436,
      organicStartRank: 107,
      organicHighestRank: 33,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 6542,
          organicRank: 64,
          sponsoredRank: 4,
          keywordSales: 450
        },
        {
          date: '2024-09-09',
          searchVolume: 6542,
          organicRank: 78,

          keywordSales: 450
        },
        {
          date: '2024-09-08',
          searchVolume: 6542,
          organicRank: 65,

          keywordSales: 450
        },
        {
          date: '2024-09-07',
          searchVolume: 6542,
          organicRank: 73,

          keywordSales: 450
        },
        {
          date: '2024-09-05',
          searchVolume: 6542,
          organicRank: 50,

          keywordSales: 450
        },
        {
          date: '2024-09-04',
          searchVolume: 6542,
          organicRank: 47,

          keywordSales: 450
        },
        {
          date: '2024-09-03',
          searchVolume: 6542,
          organicRank: 49,

          keywordSales: 450
        },
        {
          date: '2024-09-01',
          searchVolume: 6542,
          organicRank: 50,
          sponsoredRank: 24,
          keywordSales: 450
        },
        {
          date: '2024-08-28',
          searchVolume: 7290,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 450
        },
        {
          date: '2024-08-27',
          searchVolume: 7290,
          organicRank: 51,
          sponsoredRank: 17,
          keywordSales: 450
        },
        {
          date: '2024-08-26',
          searchVolume: 7290,
          organicRank: 48,
          sponsoredRank: 19,
          keywordSales: 450
        },
        {
          date: '2024-08-25',
          searchVolume: 7290,
          organicRank: 45,
          sponsoredRank: 10,
          keywordSales: 450
        },
        {
          date: '2024-08-24',
          searchVolume: 7290,
          organicRank: 50,

          keywordSales: 450
        },
        {
          date: '2024-08-23',
          searchVolume: 7709,
          organicRank: 47,
          sponsoredRank: 22,
          keywordSales: 450
        },
        {
          date: '2024-08-20',
          searchVolume: 7709,
          organicRank: 33,
          sponsoredRank: 34,
          keywordSales: 450
        },
        {
          date: '2024-08-19',
          searchVolume: 7709,
          organicRank: 44,

          keywordSales: 450
        },
        {
          date: '2024-08-18',
          searchVolume: 7709,
          organicRank: 43,
          sponsoredRank: 32,
          keywordSales: 450
        },
        {
          date: '2024-08-16',
          searchVolume: 7357,
          organicRank: 54,

          keywordSales: 450
        },
        {
          date: '2024-08-14',
          searchVolume: 7357,
          organicRank: 63,
          sponsoredRank: 18,
          keywordSales: 450
        },
        {
          date: '2024-08-13',
          searchVolume: 7357,
          organicRank: 60,
          sponsoredRank: 31,
          keywordSales: 450
        },
        {
          date: '2024-08-12',
          searchVolume: 7357,
          organicRank: 62,
          sponsoredRank: 6,
          keywordSales: 450
        },
        {
          date: '2024-08-11',
          searchVolume: 7357,
          organicRank: 66,
          sponsoredRank: 7,
          keywordSales: 450
        },
        {
          date: '2024-08-10',
          searchVolume: 7357,
          organicRank: 59,
          sponsoredRank: 7,
          keywordSales: 450
        },
        {
          date: '2024-08-09',
          searchVolume: 7145,
          organicRank: 57,

          keywordSales: 450
        },
        {
          date: '2024-08-08',
          searchVolume: 7145,
          organicRank: 51,
          sponsoredRank: 35,
          keywordSales: 450
        },
        {
          date: '2024-08-07',
          searchVolume: 7145,
          organicRank: 53,
          sponsoredRank: 35,
          keywordSales: 450
        },
        {
          date: '2024-08-06',
          searchVolume: 7145,
          organicRank: 53,
          sponsoredRank: 43,
          keywordSales: 450
        },
        {
          date: '2024-08-05',
          searchVolume: 7145,
          organicRank: 50,

          keywordSales: 450
        },
        {
          date: '2024-08-04',
          searchVolume: 7145,
          organicRank: 49,
          sponsoredRank: 1,
          keywordSales: 450
        },
        {
          date: '2024-08-02',
          searchVolume: 6237,
          organicRank: 58,

          keywordSales: 450
        },
        {
          date: '2024-08-01',
          searchVolume: 6237,
          organicRank: 58,
          sponsoredRank: 24,
          keywordSales: 450
        },
        {
          date: '2024-07-31',
          searchVolume: 6237,
          organicRank: 86,

          keywordSales: 450
        },
        {
          date: '2024-07-30',
          searchVolume: 6237,
          organicRank: 80,
          sponsoredRank: 5,
          keywordSales: 450
        },
        {
          date: '2024-07-29',
          searchVolume: 6237,
          organicRank: 91,
          sponsoredRank: 2,
          keywordSales: 450
        },
        {
          date: '2024-07-28',
          searchVolume: 6237,
          organicRank: 110,
          sponsoredRank: 16,
          keywordSales: 450
        },
        {
          date: '2024-07-27',
          searchVolume: 6237,
          organicRank: 107,
          sponsoredRank: 3,
          keywordSales: 450
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flossing',
      keyword: 'flossing',
      searchVolume: 1167,
      organicStartRank: 29,
      organicHighestRank: 29,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1167,
          organicRank: 29,
          sponsoredRank: 7,
          keywordSales: 29
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_flossing toothbrush',
      keyword: 'flossing toothbrush',
      searchVolume: 11224,
      organicStartRank: 172,
      organicHighestRank: 47,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-09-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3514,
          organicRank: 99,

          keywordSales: 132
        },
        {
          date: '2024-09-09',
          searchVolume: 3514,
          organicRank: 94,

          keywordSales: 132
        },
        {
          date: '2024-09-08',
          searchVolume: 3514,
          organicRank: 97,
          sponsoredRank: 13,
          keywordSales: 132
        },
        {
          date: '2024-09-07',
          searchVolume: 3514,
          organicRank: 95,

          keywordSales: 132
        },
        {
          date: '2024-09-06',
          searchVolume: 3514,
          organicRank: 133,

          keywordSales: 132
        },
        {
          date: '2024-09-05',
          searchVolume: 3514,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-09-04',
          searchVolume: 3514,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-09-01',
          searchVolume: 3514,
          organicRank: 80,

          keywordSales: 132
        },
        {
          date: '2024-08-28',
          searchVolume: 3887,
          organicRank: 70,

          keywordSales: 132
        },
        {
          date: '2024-08-27',
          searchVolume: 3887,
          organicRank: 63,

          keywordSales: 132
        },
        {
          date: '2024-08-26',
          searchVolume: 3887,
          organicRank: 64,

          keywordSales: 132
        },
        {
          date: '2024-08-25',
          searchVolume: 3887,
          organicRank: 54,

          keywordSales: 132
        },
        {
          date: '2024-08-24',
          searchVolume: 3887,
          organicRank: 59,

          keywordSales: 132
        },
        {
          date: '2024-08-23',
          searchVolume: 3620,
          organicRank: 62,

          keywordSales: 132
        },
        {
          date: '2024-08-22',
          searchVolume: 3620,
          organicRank: 70,

          keywordSales: 132
        },
        {
          date: '2024-08-21',
          searchVolume: 3620,
          organicRank: 49,

          keywordSales: 132
        },
        {
          date: '2024-08-20',
          searchVolume: 3620,
          organicRank: 75,

          keywordSales: 132
        },
        {
          date: '2024-08-19',
          searchVolume: 3620,
          organicRank: 69,

          keywordSales: 132
        },
        {
          date: '2024-08-17',
          searchVolume: 3620,
          organicRank: 79,

          keywordSales: 132
        },
        {
          date: '2024-08-16',
          searchVolume: 3301,
          organicRank: 63,

          keywordSales: 132
        },
        {
          date: '2024-08-15',
          searchVolume: 3301,
          organicRank: 67,

          keywordSales: 132
        },
        {
          date: '2024-08-14',
          searchVolume: 3301,
          organicRank: 76,

          keywordSales: 132
        },
        {
          date: '2024-08-13',
          searchVolume: 3301,
          organicRank: 47,

          keywordSales: 132
        },
        {
          date: '2024-08-12',
          searchVolume: 3301,
          organicRank: 95,

          keywordSales: 132
        },
        {
          date: '2024-08-11',
          searchVolume: 3301,
          organicRank: 60,

          keywordSales: 132
        },
        {
          date: '2024-08-10',
          searchVolume: 3301,
          organicRank: 62,

          keywordSales: 132
        },
        {
          date: '2024-08-09',
          searchVolume: 3372,
          organicRank: 59,

          keywordSales: 132
        },
        {
          date: '2024-08-08',
          searchVolume: 3372,
          organicRank: 66,

          keywordSales: 132
        },
        {
          date: '2024-08-07',
          searchVolume: 3372,
          organicRank: 49,

          keywordSales: 132
        },
        {
          date: '2024-08-06',
          searchVolume: 3372,
          organicRank: 69,

          keywordSales: 132
        },
        {
          date: '2024-08-05',
          searchVolume: 3372,
          organicRank: 58,

          keywordSales: 132
        },
        {
          date: '2024-08-04',
          searchVolume: 3372,
          organicRank: 104,

          keywordSales: 132
        },
        {
          date: '2024-08-03',
          searchVolume: 3372,
          organicRank: 109,

          keywordSales: 132
        },
        {
          date: '2024-08-02',
          searchVolume: 3587,
          organicRank: 89,

          keywordSales: 132
        },
        {
          date: '2024-08-01',
          searchVolume: 3587,
          organicRank: 93,

          keywordSales: 132
        },
        {
          date: '2024-07-31',
          searchVolume: 3587,
          organicRank: 99,

          keywordSales: 132
        },
        {
          date: '2024-07-30',
          searchVolume: 3587,
          organicRank: 101,

          keywordSales: 132
        },
        {
          date: '2024-07-29',
          searchVolume: 3587,
          organicRank: 104,

          keywordSales: 132
        },
        {
          date: '2024-07-28',
          searchVolume: 3587,
          organicRank: 103,

          keywordSales: 132
        },
        {
          date: '2024-07-27',
          searchVolume: 3587,
          organicRank: 91,

          keywordSales: 132
        },
        {
          date: '2024-07-26',
          searchVolume: 11224,
          organicRank: 62,

          keywordSales: 132
        },
        {
          date: '2024-07-25',
          searchVolume: 11224,
          organicRank: 73,

          keywordSales: 132
        },
        {
          date: '2024-07-24',
          searchVolume: 11224,
          organicRank: 96,

          keywordSales: 132
        },
        {
          date: '2024-07-23',
          searchVolume: 11224,
          organicRank: 116,

          keywordSales: 132
        },
        {
          date: '2024-07-22',
          searchVolume: 11224,
          organicRank: 134,

          keywordSales: 132
        },
        {
          date: '2024-07-21',
          searchVolume: 11224,
          organicRank: 136,

          keywordSales: 132
        },
        {
          date: '2024-07-20',
          searchVolume: 11224,
          organicRank: 140,

          keywordSales: 132
        },
        {
          date: '2024-07-19',
          searchVolume: 3038,
          organicRank: 147,

          keywordSales: 132
        },
        {
          date: '2024-07-18',
          searchVolume: 3038,
          organicRank: 172,

          keywordSales: 132
        },
        {
          date: '2024-07-17',
          searchVolume: 3038,
          organicRank: 180,

          keywordSales: 132
        },
        {
          date: '2024-07-16',
          searchVolume: 3038,
          organicRank: 141,

          keywordSales: 132
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_gum picks for teeth cleaning',
      keyword: 'gum picks for teeth cleaning',
      searchVolume: 536,
      organicStartRank: 145,
      organicHighestRank: 145,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 536,
          organicRank: 145,

          keywordSales: 53
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_hard plaque remover',
      keyword: 'hard plaque remover',
      searchVolume: 6789,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-09',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-08',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-07',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-06',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-05',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-04',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-09-01',
          searchVolume: 15180,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-29',

          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-27',

          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-26',

          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-25',

          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-24',

          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-22',
          searchVolume: 11043,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-21',
          searchVolume: 11043,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-20',
          searchVolume: 11043,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-19',
          searchVolume: 11043,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-18',
          searchVolume: 11043,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-16',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-15',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-14',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-13',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-12',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-11',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-10',
          searchVolume: 8611,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-09',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-08',
          searchVolume: 6789,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 184
        },
        {
          date: '2024-08-07',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-06',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-05',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-04',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-03',
          searchVolume: 6789,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-02',
          searchVolume: 6945,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-08-01',
          searchVolume: 6945,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-07-31',
          searchVolume: 6945,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-07-30',
          searchVolume: 6445,
          organicRank: 306,

          keywordSales: 162
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_in between teeth stain remover',
      keyword: 'in between teeth stain remover',
      searchVolume: 1211,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 44,
      sponsoredHighestRank: 44,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1211,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 41
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_irrigador bucal alta presion',
      keyword: 'irrigador bucal alta presion',
      searchVolume: 1763,
      organicStartRank: 28,
      organicHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1763,
          organicRank: 28,

          keywordSales: 31
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_irrigador dental',
      keyword: 'irrigador dental',
      searchVolume: 8095,
      organicStartRank: 34,
      organicHighestRank: 34,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 8095,
          organicRank: 34,

          keywordSales: 161
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_kids water flosser',
      keyword: 'kids water flosser',
      searchVolume: 2912,
      organicStartRank: 306,
      organicHighestRank: 19,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-09-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3705,
          organicRank: 48,

          keywordSales: 107
        },
        {
          date: '2024-09-09',
          searchVolume: 3705,
          organicRank: 51,

          keywordSales: 107
        },
        {
          date: '2024-09-08',
          searchVolume: 3705,
          organicRank: 55,

          keywordSales: 107
        },
        {
          date: '2024-09-07',
          searchVolume: 3705,
          organicRank: 50,
          sponsoredRank: 22,
          keywordSales: 107
        },
        {
          date: '2024-09-06',
          searchVolume: 3705,
          organicRank: 48,

          keywordSales: 107
        },
        {
          date: '2024-09-05',
          searchVolume: 3705,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 107
        },
        {
          date: '2024-09-04',
          searchVolume: 3705,
          organicRank: 19,

          keywordSales: 107
        },
        {
          date: '2024-09-02',
          searchVolume: 3705,
          organicRank: 44,

          keywordSales: 107
        },
        {
          date: '2024-08-29',
          searchVolume: 3631,
          organicRank: 39,

          keywordSales: 107
        },
        {
          date: '2024-08-28',
          searchVolume: 3631,
          organicRank: 44,

          keywordSales: 107
        },
        {
          date: '2024-08-27',
          searchVolume: 3631,
          organicRank: 40,

          keywordSales: 107
        },
        {
          date: '2024-08-26',
          searchVolume: 3631,
          organicRank: 42,

          keywordSales: 107
        },
        {
          date: '2024-08-25',
          searchVolume: 3631,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-24',
          searchVolume: 3631,
          organicRank: 42,

          keywordSales: 107
        },
        {
          date: '2024-08-22',
          searchVolume: 4324,
          organicRank: 136,

          keywordSales: 107
        },
        {
          date: '2024-08-21',
          searchVolume: 4324,
          organicRank: 52,

          keywordSales: 107
        },
        {
          date: '2024-08-20',
          searchVolume: 4324,
          organicRank: 27,

          keywordSales: 107
        },
        {
          date: '2024-08-19',
          searchVolume: 4324,
          organicRank: 49,
          sponsoredRank: 74,
          keywordSales: 107
        },
        {
          date: '2024-08-18',
          searchVolume: 4324,
          organicRank: 45,
          sponsoredRank: 13,
          keywordSales: 107
        },
        {
          date: '2024-08-16',
          searchVolume: 3641,
          organicRank: 37,

          keywordSales: 107
        },
        {
          date: '2024-08-15',
          searchVolume: 3641,
          organicRank: 21,

          keywordSales: 107
        },
        {
          date: '2024-08-14',
          searchVolume: 3641,
          organicRank: 48,
          sponsoredRank: 24,
          keywordSales: 107
        },
        {
          date: '2024-08-13',
          searchVolume: 3641,
          organicRank: 44,

          keywordSales: 107
        },
        {
          date: '2024-08-12',
          searchVolume: 3641,
          organicRank: 30,

          keywordSales: 107
        },
        {
          date: '2024-08-11',
          searchVolume: 3641,
          organicRank: 51,
          sponsoredRank: 1,
          keywordSales: 107
        },
        {
          date: '2024-08-10',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-09',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-08',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-07',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-06',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-05',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-04',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-03',
          searchVolume: 2912,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-02',
          searchVolume: 2599,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-08-01',
          searchVolume: 2599,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-07-31',
          searchVolume: 2599,
          organicRank: 306,

          keywordSales: 107
        },
        {
          date: '2024-07-30',
          searchVolume: 3321,
          organicRank: 306,

          keywordSales: 110
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_kids waterpik',
      keyword: 'kids waterpik',
      searchVolume: 2282,
      organicStartRank: 19,
      organicHighestRank: 5,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1858,
          organicRank: 14,

          keywordSales: 67
        },
        {
          date: '2024-09-09',
          searchVolume: 1858,
          organicRank: 14,

          keywordSales: 67
        },
        {
          date: '2024-09-08',
          searchVolume: 1858,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-09-07',
          searchVolume: 1858,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-09-06',
          searchVolume: 1858,
          organicRank: 13,

          keywordSales: 67
        },
        {
          date: '2024-09-05',
          searchVolume: 1858,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-09-04',
          searchVolume: 1858,
          organicRank: 13,

          keywordSales: 67
        },
        {
          date: '2024-09-02',
          searchVolume: 1858,
          organicRank: 11,

          keywordSales: 67
        },
        {
          date: '2024-08-29',
          searchVolume: 1924,
          organicRank: 13,

          keywordSales: 67
        },
        {
          date: '2024-08-28',
          searchVolume: 1924,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-08-27',
          searchVolume: 1924,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-08-26',
          searchVolume: 1924,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-08-25',
          searchVolume: 1924,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-08-24',
          searchVolume: 1924,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-08-22',
          searchVolume: 1923,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-08-21',
          searchVolume: 1923,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-08-20',
          searchVolume: 1923,
          organicRank: 8,

          keywordSales: 67
        },
        {
          date: '2024-08-19',
          searchVolume: 1923,
          organicRank: 5,
          sponsoredRank: 30,
          keywordSales: 67
        },
        {
          date: '2024-08-18',
          searchVolume: 1923,
          organicRank: 8,

          keywordSales: 67
        },
        {
          date: '2024-08-16',
          searchVolume: 1836,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-15',
          searchVolume: 1836,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-14',
          searchVolume: 1836,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-08-13',
          searchVolume: 1836,
          organicRank: 8,

          keywordSales: 67
        },
        {
          date: '2024-08-12',
          searchVolume: 1836,
          organicRank: 6,

          keywordSales: 67
        },
        {
          date: '2024-08-11',
          searchVolume: 1836,
          organicRank: 6,

          keywordSales: 67
        },
        {
          date: '2024-08-10',
          searchVolume: 1836,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-09',
          searchVolume: 1725,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-08',
          searchVolume: 1725,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 67
        },
        {
          date: '2024-08-07',
          searchVolume: 1725,
          organicRank: 6,

          keywordSales: 67
        },
        {
          date: '2024-08-06',
          searchVolume: 1725,
          organicRank: 5,

          keywordSales: 67
        },
        {
          date: '2024-08-05',
          searchVolume: 1725,
          organicRank: 8,

          keywordSales: 67
        },
        {
          date: '2024-08-04',
          searchVolume: 1725,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-03',
          searchVolume: 1725,
          organicRank: 7,

          keywordSales: 67
        },
        {
          date: '2024-08-02',
          searchVolume: 1771,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-08-01',
          searchVolume: 1771,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-07-31',
          searchVolume: 1771,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-07-30',
          searchVolume: 1771,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-07-29',
          searchVolume: 1771,
          organicRank: 10,

          keywordSales: 67
        },
        {
          date: '2024-07-28',
          searchVolume: 1771,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-07-27',
          searchVolume: 1771,
          organicRank: 9,

          keywordSales: 67
        },
        {
          date: '2024-07-26',
          searchVolume: 2282,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-07-25',
          searchVolume: 2282,
          organicRank: 13,

          keywordSales: 67
        },
        {
          date: '2024-07-24',
          searchVolume: 2282,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-07-23',
          searchVolume: 2282,
          organicRank: 12,

          keywordSales: 67
        },
        {
          date: '2024-07-22',
          searchVolume: 2282,
          organicRank: 15,

          keywordSales: 67
        },
        {
          date: '2024-07-21',
          searchVolume: 2282,
          organicRank: 18,

          keywordSales: 67
        },
        {
          date: '2024-07-20',
          searchVolume: 2282,
          organicRank: 19,

          keywordSales: 67
        },
        {
          date: '2024-07-19',
          searchVolume: 1581,
          organicRank: 19,

          keywordSales: 67
        },
        {
          date: '2024-07-18',
          searchVolume: 1581,
          organicRank: 19,

          keywordSales: 67
        },
        {
          date: '2024-07-17',
          searchVolume: 1581,
          organicRank: 20,

          keywordSales: 67
        },
        {
          date: '2024-07-16',
          searchVolume: 1581,
          organicRank: 19,

          keywordSales: 67
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_mini water flosser',
      keyword: 'mini water flosser',
      searchVolume: 1764,
      organicStartRank: 82,
      organicHighestRank: 46,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2105,
          organicRank: 65,

          keywordSales: 43
        },
        {
          date: '2024-09-09',
          searchVolume: 2105,
          organicRank: 78,

          keywordSales: 43
        },
        {
          date: '2024-09-08',
          searchVolume: 2105,
          organicRank: 76,

          keywordSales: 43
        },
        {
          date: '2024-09-07',
          searchVolume: 2105,
          organicRank: 78,

          keywordSales: 43
        },
        {
          date: '2024-09-06',
          searchVolume: 2105,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-05',
          searchVolume: 2105,
          organicRank: 66,

          keywordSales: 43
        },
        {
          date: '2024-09-04',
          searchVolume: 2105,
          organicRank: 91,

          keywordSales: 43
        },
        {
          date: '2024-09-02',
          searchVolume: 2105,
          organicRank: 70,

          keywordSales: 43
        },
        {
          date: '2024-08-29',
          searchVolume: 2353,
          organicRank: 62,

          keywordSales: 43
        },
        {
          date: '2024-08-28',
          searchVolume: 2353,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-08-27',
          searchVolume: 2353,
          organicRank: 52,

          keywordSales: 43
        },
        {
          date: '2024-08-26',
          searchVolume: 2353,
          organicRank: 59,

          keywordSales: 43
        },
        {
          date: '2024-08-25',
          searchVolume: 2353,
          organicRank: 58,

          keywordSales: 43
        },
        {
          date: '2024-08-24',
          searchVolume: 2353,
          organicRank: 56,

          keywordSales: 43
        },
        {
          date: '2024-08-22',
          searchVolume: 2352,
          organicRank: 64,

          keywordSales: 43
        },
        {
          date: '2024-08-21',
          searchVolume: 2352,
          organicRank: 66,

          keywordSales: 43
        },
        {
          date: '2024-08-20',
          searchVolume: 2352,
          organicRank: 56,

          keywordSales: 43
        },
        {
          date: '2024-08-19',
          searchVolume: 2352,
          organicRank: 59,

          keywordSales: 43
        },
        {
          date: '2024-08-18',
          searchVolume: 2352,
          organicRank: 57,

          keywordSales: 43
        },
        {
          date: '2024-08-16',
          searchVolume: 2073,
          organicRank: 46,

          keywordSales: 43
        },
        {
          date: '2024-08-15',
          searchVolume: 2073,
          organicRank: 58,

          keywordSales: 43
        },
        {
          date: '2024-08-14',
          searchVolume: 2073,
          organicRank: 51,

          keywordSales: 43
        },
        {
          date: '2024-08-13',
          searchVolume: 2073,
          organicRank: 53,

          keywordSales: 43
        },
        {
          date: '2024-08-12',
          searchVolume: 2073,
          organicRank: 46,

          keywordSales: 43
        },
        {
          date: '2024-08-11',
          searchVolume: 2073,
          organicRank: 56,
          sponsoredRank: 12,
          keywordSales: 43
        },
        {
          date: '2024-08-10',
          searchVolume: 2073,
          organicRank: 54,

          keywordSales: 43
        },
        {
          date: '2024-08-09',
          searchVolume: 1724,
          organicRank: 56,

          keywordSales: 43
        },
        {
          date: '2024-08-08',
          searchVolume: 1724,
          organicRank: 70,
          sponsoredRank: 4,
          keywordSales: 43
        },
        {
          date: '2024-08-07',
          searchVolume: 1724,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-08-06',
          searchVolume: 1724,
          organicRank: 51,

          keywordSales: 43
        },
        {
          date: '2024-08-05',
          searchVolume: 1724,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-08-04',
          searchVolume: 1724,
          organicRank: 62,

          keywordSales: 43
        },
        {
          date: '2024-08-03',
          searchVolume: 1724,
          organicRank: 62,

          keywordSales: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 1705,
          organicRank: 61,

          keywordSales: 43
        },
        {
          date: '2024-08-01',
          searchVolume: 1705,
          organicRank: 65,

          keywordSales: 43
        },
        {
          date: '2024-07-31',
          searchVolume: 1705,
          organicRank: 69,

          keywordSales: 43
        },
        {
          date: '2024-07-30',
          searchVolume: 1705,
          organicRank: 60,

          keywordSales: 43
        },
        {
          date: '2024-07-29',
          searchVolume: 1705,
          organicRank: 69,

          keywordSales: 43
        },
        {
          date: '2024-07-28',
          searchVolume: 1705,
          organicRank: 70,

          keywordSales: 43
        },
        {
          date: '2024-07-27',
          searchVolume: 1705,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-07-26',
          searchVolume: 1764,
          organicRank: 66,

          keywordSales: 43
        },
        {
          date: '2024-07-25',
          searchVolume: 1764,
          organicRank: 66,

          keywordSales: 43
        },
        {
          date: '2024-07-24',
          searchVolume: 1764,
          organicRank: 63,

          keywordSales: 43
        },
        {
          date: '2024-07-23',
          searchVolume: 1764,
          organicRank: 53,

          keywordSales: 43
        },
        {
          date: '2024-07-22',
          searchVolume: 1764,
          organicRank: 61,

          keywordSales: 43
        },
        {
          date: '2024-07-21',
          searchVolume: 1764,
          organicRank: 53,

          keywordSales: 43
        },
        {
          date: '2024-07-20',
          searchVolume: 1764,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-07-19',
          searchVolume: 1585,
          organicRank: 67,

          keywordSales: 43
        },
        {
          date: '2024-07-18',
          searchVolume: 1585,
          organicRank: 82,

          keywordSales: 43
        },
        {
          date: '2024-07-17',
          searchVolume: 1585,
          organicRank: 91,

          keywordSales: 43
        },
        {
          date: '2024-07-16',
          searchVolume: 1585,
          organicRank: 74,

          keywordSales: 43
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_miracle smile water flosser as seen on tv',
      keyword: 'miracle smile water flosser as seen on tv',
      searchVolume: 844,
      organicStartRank: 15,
      organicHighestRank: 3,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 544,
          organicRank: 5,

          keywordSales: 11
        },
        {
          date: '2024-09-09',
          searchVolume: 544,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-09-08',
          searchVolume: 544,
          organicRank: 4,
          sponsoredRank: 40,
          keywordSales: 11
        },
        {
          date: '2024-09-07',
          searchVolume: 544,
          organicRank: 5,
          sponsoredRank: 39,
          keywordSales: 11
        },
        {
          date: '2024-09-06',
          searchVolume: 544,
          organicRank: 3,
          sponsoredRank: 32,
          keywordSales: 11
        },
        {
          date: '2024-09-05',
          searchVolume: 544,
          organicRank: 3,

          keywordSales: 11
        },
        {
          date: '2024-09-04',
          searchVolume: 544,
          organicRank: 3,

          keywordSales: 11
        },
        {
          date: '2024-09-02',
          searchVolume: 544,
          organicRank: 3,

          keywordSales: 11
        },
        {
          date: '2024-08-30',
          searchVolume: 507,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-28',
          searchVolume: 507,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-27',
          searchVolume: 507,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-26',
          searchVolume: 507,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-25',
          searchVolume: 507,
          organicRank: 5,

          keywordSales: 11
        },
        {
          date: '2024-08-24',
          searchVolume: 507,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-23',
          searchVolume: 506,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-22',
          searchVolume: 506,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-20',
          searchVolume: 506,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-19',
          searchVolume: 506,
          organicRank: 4,
          sponsoredRank: 24,
          keywordSales: 11
        },
        {
          date: '2024-08-18',
          searchVolume: 506,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-16',
          searchVolume: 461,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-15',
          searchVolume: 461,
          organicRank: 4,

          keywordSales: 11
        },
        {
          date: '2024-08-14',
          searchVolume: 461,
          organicRank: 4,
          sponsoredRank: 9,
          keywordSales: 11
        },
        {
          date: '2024-08-13',
          searchVolume: 461,
          organicRank: 3,

          keywordSales: 11
        },
        {
          date: '2024-08-12',
          searchVolume: 461,
          organicRank: 7,

          keywordSales: 11
        },
        {
          date: '2024-08-11',
          searchVolume: 461,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-08-10',
          searchVolume: 461,
          organicRank: 8,
          sponsoredRank: 33,
          keywordSales: 11
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 7,
          sponsoredRank: 34,
          keywordSales: 11
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 11
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 5,

          keywordSales: 11
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 6,

          keywordSales: 11
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 11
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 8,

          keywordSales: 11
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 8,

          keywordSales: 11
        },
        {
          date: '2024-08-02',
          searchVolume: 450,
          organicRank: 8,

          keywordSales: 11
        },
        {
          date: '2024-08-01',
          searchVolume: 450,
          organicRank: 9,

          keywordSales: 11
        },
        {
          date: '2024-07-31',
          searchVolume: 450,
          organicRank: 7,

          keywordSales: 11
        },
        {
          date: '2024-07-30',
          searchVolume: 450,
          organicRank: 7,

          keywordSales: 11
        },
        {
          date: '2024-07-29',
          searchVolume: 450,
          organicRank: 8,

          keywordSales: 11
        },
        {
          date: '2024-07-28',
          searchVolume: 450,
          organicRank: 9,

          keywordSales: 11
        },
        {
          date: '2024-07-27',
          searchVolume: 450,
          organicRank: 9,

          keywordSales: 11
        },
        {
          date: '2024-07-26',
          searchVolume: 844,
          organicRank: 7,

          keywordSales: 11
        },
        {
          date: '2024-07-25',
          searchVolume: 844,
          organicRank: 7,

          keywordSales: 11
        },
        {
          date: '2024-07-24',
          searchVolume: 844,
          organicRank: 5,

          keywordSales: 11
        },
        {
          date: '2024-07-23',
          searchVolume: 844,
          organicRank: 8,

          keywordSales: 11
        },
        {
          date: '2024-07-22',
          searchVolume: 844,
          organicRank: 11,

          keywordSales: 11
        },
        {
          date: '2024-07-21',
          searchVolume: 844,
          organicRank: 10,

          keywordSales: 11
        },
        {
          date: '2024-07-20',
          searchVolume: 844,
          organicRank: 12,

          keywordSales: 11
        },
        {
          date: '2024-07-19',
          searchVolume: 1323,
          organicRank: 15,

          keywordSales: 11
        },
        {
          date: '2024-07-18',
          searchVolume: 1323,
          organicRank: 15,

          keywordSales: 11
        },
        {
          date: '2024-07-17',
          searchVolume: 1323,
          organicRank: 17,

          keywordSales: 11
        },
        {
          date: '2024-07-16',
          searchVolume: 1323,
          organicRank: 11,

          keywordSales: 11
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_oral b water flosser',
      keyword: 'oral b water flosser',
      searchVolume: 4340,
      organicStartRank: 8,
      organicHighestRank: 8,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-30',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 4340,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 140
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_oral irrigator',
      keyword: 'oral irrigator',
      searchVolume: 1768,
      organicStartRank: 112,
      organicHighestRank: 8,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-08-08',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1871,
          organicRank: 28,

          keywordSales: 23
        },
        {
          date: '2024-09-09',
          searchVolume: 1871,
          organicRank: 25,

          keywordSales: 23
        },
        {
          date: '2024-09-08',
          searchVolume: 1871,
          organicRank: 29,

          keywordSales: 23
        },
        {
          date: '2024-09-07',
          searchVolume: 1871,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-09-06',
          searchVolume: 1871,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-09-05',
          searchVolume: 1871,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-09-04',
          searchVolume: 1871,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-09-02',
          searchVolume: 1871,
          organicRank: 29,

          keywordSales: 23
        },
        {
          date: '2024-09-01',
          searchVolume: 1871,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-08-28',
          searchVolume: 2547,
          organicRank: 22,

          keywordSales: 23
        },
        {
          date: '2024-08-27',
          searchVolume: 2547,
          organicRank: 17,

          keywordSales: 23
        },
        {
          date: '2024-08-26',
          searchVolume: 2547,
          organicRank: 17,

          keywordSales: 23
        },
        {
          date: '2024-08-25',
          searchVolume: 2547,
          organicRank: 18,

          keywordSales: 23
        },
        {
          date: '2024-08-24',
          searchVolume: 2547,
          organicRank: 19,

          keywordSales: 23
        },
        {
          date: '2024-08-23',
          searchVolume: 2186,
          organicRank: 22,

          keywordSales: 23
        },
        {
          date: '2024-08-22',
          searchVolume: 2186,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-08-20',
          searchVolume: 2186,
          organicRank: 18,

          keywordSales: 23
        },
        {
          date: '2024-08-19',
          searchVolume: 2186,
          organicRank: 8,

          keywordSales: 23
        },
        {
          date: '2024-08-16',
          searchVolume: 2073,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-08-15',
          searchVolume: 2073,
          organicRank: 24,

          keywordSales: 23
        },
        {
          date: '2024-08-14',
          searchVolume: 2073,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-08-13',
          searchVolume: 2073,
          organicRank: 10,

          keywordSales: 23
        },
        {
          date: '2024-08-12',
          searchVolume: 2073,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-08-11',
          searchVolume: 2073,
          organicRank: 28,

          keywordSales: 23
        },
        {
          date: '2024-08-10',
          searchVolume: 2073,
          organicRank: 28,

          keywordSales: 23
        },
        {
          date: '2024-08-09',
          searchVolume: 1784,
          organicRank: 23,

          keywordSales: 23
        },
        {
          date: '2024-08-08',
          searchVolume: 1784,
          organicRank: 19,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-08-07',
          searchVolume: 1784,
          organicRank: 38,

          keywordSales: 23
        },
        {
          date: '2024-08-06',
          searchVolume: 1784,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-08-05',
          searchVolume: 1784,
          organicRank: 24,

          keywordSales: 23
        },
        {
          date: '2024-08-04',
          searchVolume: 1784,
          organicRank: 36,

          keywordSales: 23
        },
        {
          date: '2024-08-03',
          searchVolume: 1784,
          organicRank: 33,

          keywordSales: 23
        },
        {
          date: '2024-08-02',
          searchVolume: 1780,
          organicRank: 40,

          keywordSales: 23
        },
        {
          date: '2024-08-01',
          searchVolume: 1780,
          organicRank: 48,

          keywordSales: 23
        },
        {
          date: '2024-07-31',
          searchVolume: 1780,
          organicRank: 39,

          keywordSales: 23
        },
        {
          date: '2024-07-30',
          searchVolume: 1780,
          organicRank: 38,

          keywordSales: 23
        },
        {
          date: '2024-07-29',
          searchVolume: 1780,
          organicRank: 47,

          keywordSales: 23
        },
        {
          date: '2024-07-28',
          searchVolume: 1780,
          organicRank: 49,

          keywordSales: 23
        },
        {
          date: '2024-07-27',
          searchVolume: 1780,
          organicRank: 38,

          keywordSales: 23
        },
        {
          date: '2024-07-26',
          searchVolume: 1768,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-07-25',
          searchVolume: 1768,
          organicRank: 45,

          keywordSales: 23
        },
        {
          date: '2024-07-24',
          searchVolume: 1768,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-07-23',
          searchVolume: 1768,
          organicRank: 39,

          keywordSales: 23
        },
        {
          date: '2024-07-22',
          searchVolume: 1768,
          organicRank: 49,

          keywordSales: 23
        },
        {
          date: '2024-07-21',
          searchVolume: 1768,
          organicRank: 58,

          keywordSales: 23
        },
        {
          date: '2024-07-20',
          searchVolume: 1768,
          organicRank: 63,

          keywordSales: 23
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 64,

          keywordSales: 23
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 112,

          keywordSales: 23
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 90,

          keywordSales: 23
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 64,

          keywordSales: 23
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_oral irrigator water flosser',
      keyword: 'oral irrigator water flosser',
      searchVolume: 2319,
      organicStartRank: 49,
      organicHighestRank: 9,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-10',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3699,
          organicRank: 31,

          keywordSales: 109
        },
        {
          date: '2024-09-09',
          searchVolume: 3699,
          organicRank: 26,
          sponsoredRank: 1,
          keywordSales: 109
        },
        {
          date: '2024-09-08',
          searchVolume: 3699,
          organicRank: 34,
          sponsoredRank: 27,
          keywordSales: 109
        },
        {
          date: '2024-09-07',
          searchVolume: 3699,
          organicRank: 35,

          keywordSales: 109
        },
        {
          date: '2024-09-06',
          searchVolume: 3699,
          organicRank: 28,

          keywordSales: 109
        },
        {
          date: '2024-09-04',
          searchVolume: 3699,
          organicRank: 306,

          keywordSales: 109
        },
        {
          date: '2024-09-03',
          searchVolume: 3699,
          organicRank: 81,

          keywordSales: 109
        },
        {
          date: '2024-09-01',
          searchVolume: 3699,
          organicRank: 79,

          keywordSales: 109
        },
        {
          date: '2024-08-28',
          searchVolume: 4237,
          organicRank: 86,

          keywordSales: 109
        },
        {
          date: '2024-08-27',
          searchVolume: 4237,
          organicRank: 29,

          keywordSales: 109
        },
        {
          date: '2024-08-26',
          searchVolume: 4237,
          organicRank: 23,

          keywordSales: 109
        },
        {
          date: '2024-08-25',
          searchVolume: 4237,
          organicRank: 77,

          keywordSales: 109
        },
        {
          date: '2024-08-24',
          searchVolume: 4237,
          organicRank: 22,

          keywordSales: 109
        },
        {
          date: '2024-08-22',
          searchVolume: 4556,
          organicRank: 26,

          keywordSales: 109
        },
        {
          date: '2024-08-21',
          searchVolume: 4556,
          organicRank: 21,

          keywordSales: 109
        },
        {
          date: '2024-08-20',
          searchVolume: 4556,
          organicRank: 23,

          keywordSales: 109
        },
        {
          date: '2024-08-19',
          searchVolume: 4556,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-18',
          searchVolume: 4556,
          organicRank: 22,

          keywordSales: 109
        },
        {
          date: '2024-08-16',
          searchVolume: 3458,
          organicRank: 22,

          keywordSales: 109
        },
        {
          date: '2024-08-15',
          searchVolume: 3458,
          organicRank: 11,

          keywordSales: 109
        },
        {
          date: '2024-08-14',
          searchVolume: 3458,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 109
        },
        {
          date: '2024-08-13',
          searchVolume: 3458,
          organicRank: 24,

          keywordSales: 109
        },
        {
          date: '2024-08-12',
          searchVolume: 3458,
          organicRank: 27,

          keywordSales: 109
        },
        {
          date: '2024-08-11',
          searchVolume: 3458,
          organicRank: 24,
          sponsoredRank: 1,
          keywordSales: 109
        },
        {
          date: '2024-08-10',
          searchVolume: 3458,
          organicRank: 20,

          keywordSales: 109
        },
        {
          date: '2024-08-09',
          searchVolume: 3947,
          organicRank: 24,

          keywordSales: 109
        },
        {
          date: '2024-08-08',
          searchVolume: 3947,
          organicRank: 32,
          sponsoredRank: 5,
          keywordSales: 109
        },
        {
          date: '2024-08-07',
          searchVolume: 3947,
          organicRank: 35,

          keywordSales: 109
        },
        {
          date: '2024-08-06',
          searchVolume: 3947,
          organicRank: 25,

          keywordSales: 109
        },
        {
          date: '2024-08-05',
          searchVolume: 3947,
          organicRank: 30,

          keywordSales: 109
        },
        {
          date: '2024-08-04',
          searchVolume: 3947,
          organicRank: 26,

          keywordSales: 109
        },
        {
          date: '2024-08-03',
          searchVolume: 3947,
          organicRank: 29,

          keywordSales: 109
        },
        {
          date: '2024-08-02',
          searchVolume: 3345,
          organicRank: 27,

          keywordSales: 109
        },
        {
          date: '2024-08-01',
          searchVolume: 3345,
          organicRank: 37,
          sponsoredRank: 3,
          keywordSales: 109
        },
        {
          date: '2024-07-31',
          searchVolume: 3345,
          organicRank: 39,

          keywordSales: 109
        },
        {
          date: '2024-07-30',
          searchVolume: 3345,
          organicRank: 46,

          keywordSales: 109
        },
        {
          date: '2024-07-29',
          searchVolume: 3345,
          organicRank: 45,

          keywordSales: 109
        },
        {
          date: '2024-07-28',
          searchVolume: 3345,
          organicRank: 43,

          keywordSales: 109
        },
        {
          date: '2024-07-27',
          searchVolume: 3345,
          organicRank: 36,

          keywordSales: 109
        },
        {
          date: '2024-07-26',
          searchVolume: 2319,
          organicRank: 53,

          keywordSales: 109
        },
        {
          date: '2024-07-25',
          searchVolume: 2319,
          organicRank: 46,

          keywordSales: 109
        },
        {
          date: '2024-07-24',
          searchVolume: 2319,
          organicRank: 34,

          keywordSales: 109
        },
        {
          date: '2024-07-23',
          searchVolume: 2319,
          organicRank: 35,

          keywordSales: 109
        },
        {
          date: '2024-07-22',
          searchVolume: 2319,
          organicRank: 39,

          keywordSales: 109
        },
        {
          date: '2024-07-21',
          searchVolume: 2319,
          organicRank: 34,

          keywordSales: 109
        },
        {
          date: '2024-07-20',
          searchVolume: 2319,
          organicRank: 39,

          keywordSales: 109
        },
        {
          date: '2024-07-19',
          searchVolume: 2052,
          organicRank: 40,

          keywordSales: 109
        },
        {
          date: '2024-07-18',
          searchVolume: 2052,
          organicRank: 49,

          keywordSales: 109
        },
        {
          date: '2024-07-17',
          searchVolume: 2052,
          organicRank: 46,

          keywordSales: 109
        },
        {
          date: '2024-07-16',
          searchVolume: 2052,
          organicRank: 39,

          keywordSales: 109
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_oralfree water flosser',
      keyword: 'oralfree water flosser',
      searchVolume: 908,
      organicStartRank: 72,
      organicHighestRank: 15,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 654,
          organicRank: 38,

          keywordSales: 16
        },
        {
          date: '2024-09-09',
          searchVolume: 654,
          organicRank: 306,

          keywordSales: 16
        },
        {
          date: '2024-09-08',
          searchVolume: 654,
          organicRank: 44,

          keywordSales: 16
        },
        {
          date: '2024-09-07',
          searchVolume: 654,
          organicRank: 41,
          sponsoredRank: 14,
          keywordSales: 16
        },
        {
          date: '2024-09-06',
          searchVolume: 654,
          organicRank: 41,

          keywordSales: 16
        },
        {
          date: '2024-09-05',
          searchVolume: 654,
          organicRank: 39,

          keywordSales: 16
        },
        {
          date: '2024-09-04',
          searchVolume: 654,
          organicRank: 42,

          keywordSales: 16
        },
        {
          date: '2024-09-02',
          searchVolume: 654,
          organicRank: 27,

          keywordSales: 16
        },
        {
          date: '2024-08-29',
          searchVolume: 747,
          organicRank: 36,

          keywordSales: 16
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 36,

          keywordSales: 16
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 32,

          keywordSales: 16
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 21,

          keywordSales: 16
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 18,

          keywordSales: 16
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 30,

          keywordSales: 16
        },
        {
          date: '2024-08-22',
          searchVolume: 747,
          organicRank: 24,

          keywordSales: 16
        },
        {
          date: '2024-08-21',
          searchVolume: 747,
          organicRank: 19,

          keywordSales: 16
        },
        {
          date: '2024-08-20',
          searchVolume: 747,
          organicRank: 27,

          keywordSales: 16
        },
        {
          date: '2024-08-19',
          searchVolume: 747,
          organicRank: 24,

          keywordSales: 16
        },
        {
          date: '2024-08-18',
          searchVolume: 747,
          organicRank: 25,

          keywordSales: 16
        },
        {
          date: '2024-08-16',
          searchVolume: 1163,
          organicRank: 19,

          keywordSales: 16
        },
        {
          date: '2024-08-15',
          searchVolume: 1163,
          organicRank: 15,

          keywordSales: 16
        },
        {
          date: '2024-08-14',
          searchVolume: 1163,
          organicRank: 25,

          keywordSales: 16
        },
        {
          date: '2024-08-13',
          searchVolume: 1163,
          organicRank: 16,

          keywordSales: 16
        },
        {
          date: '2024-08-12',
          searchVolume: 1163,
          organicRank: 17,

          keywordSales: 16
        },
        {
          date: '2024-08-11',
          searchVolume: 1163,
          organicRank: 17,
          sponsoredRank: 5,
          keywordSales: 16
        },
        {
          date: '2024-08-10',
          searchVolume: 1163,
          organicRank: 17,

          keywordSales: 16
        },
        {
          date: '2024-08-09',
          searchVolume: 824,
          organicRank: 15,

          keywordSales: 16
        },
        {
          date: '2024-08-08',
          searchVolume: 824,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 16
        },
        {
          date: '2024-08-07',
          searchVolume: 824,
          organicRank: 16,

          keywordSales: 16
        },
        {
          date: '2024-08-06',
          searchVolume: 824,
          organicRank: 16,

          keywordSales: 16
        },
        {
          date: '2024-08-05',
          searchVolume: 824,
          organicRank: 16,

          keywordSales: 16
        },
        {
          date: '2024-08-04',
          searchVolume: 824,
          organicRank: 16,

          keywordSales: 16
        },
        {
          date: '2024-08-03',
          searchVolume: 824,
          organicRank: 20,

          keywordSales: 16
        },
        {
          date: '2024-08-02',
          searchVolume: 690,
          organicRank: 21,

          keywordSales: 16
        },
        {
          date: '2024-08-01',
          searchVolume: 690,
          organicRank: 19,
          sponsoredRank: 8,
          keywordSales: 16
        },
        {
          date: '2024-07-31',
          searchVolume: 690,
          organicRank: 18,

          keywordSales: 16
        },
        {
          date: '2024-07-30',
          searchVolume: 690,
          organicRank: 17,
          sponsoredRank: 33,
          keywordSales: 16
        },
        {
          date: '2024-07-29',
          searchVolume: 690,
          organicRank: 20,

          keywordSales: 16
        },
        {
          date: '2024-07-28',
          searchVolume: 690,
          organicRank: 22,

          keywordSales: 16
        },
        {
          date: '2024-07-27',
          searchVolume: 690,
          organicRank: 21,
          sponsoredRank: 33,
          keywordSales: 16
        },
        {
          date: '2024-07-26',
          searchVolume: 908,
          organicRank: 21,

          keywordSales: 16
        },
        {
          date: '2024-07-25',
          searchVolume: 908,
          organicRank: 19,

          keywordSales: 16
        },
        {
          date: '2024-07-24',
          searchVolume: 908,
          organicRank: 24,

          keywordSales: 16
        },
        {
          date: '2024-07-23',
          searchVolume: 908,
          organicRank: 68,

          keywordSales: 16
        },
        {
          date: '2024-07-22',
          searchVolume: 908,
          organicRank: 34,

          keywordSales: 16
        },
        {
          date: '2024-07-21',
          searchVolume: 908,
          organicRank: 29,

          keywordSales: 16
        },
        {
          date: '2024-07-20',
          searchVolume: 908,
          organicRank: 41,

          keywordSales: 16
        },
        {
          date: '2024-07-19',
          searchVolume: 703,
          organicRank: 45,

          keywordSales: 16
        },
        {
          date: '2024-07-18',
          searchVolume: 703,
          organicRank: 72,

          keywordSales: 16
        },
        {
          date: '2024-07-17',
          searchVolume: 703,
          organicRank: 91,

          keywordSales: 16
        },
        {
          date: '2024-07-16',
          searchVolume: 703,
          organicRank: 52,

          keywordSales: 16
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_orthodontic flossers for braces',
      keyword: 'orthodontic flossers for braces',
      searchVolume: 5040,
      organicStartRank: 306,
      organicHighestRank: 49,
      sponsoredStartRank: 44,
      sponsoredHighestRank: 36,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4409,
          organicRank: 109,

          keywordSales: 313
        },
        {
          date: '2024-09-09',
          searchVolume: 4409,
          organicRank: 105,

          keywordSales: 313
        },
        {
          date: '2024-09-08',
          searchVolume: 4409,
          organicRank: 120,

          keywordSales: 313
        },
        {
          date: '2024-09-07',
          searchVolume: 4409,
          organicRank: 117,

          keywordSales: 313
        },
        {
          date: '2024-09-06',
          searchVolume: 4409,
          organicRank: 100,

          keywordSales: 313
        },
        {
          date: '2024-09-05',
          searchVolume: 4409,
          organicRank: 88,

          keywordSales: 313
        },
        {
          date: '2024-09-04',
          searchVolume: 4409,
          organicRank: 64,

          keywordSales: 313
        },
        {
          date: '2024-09-02',
          searchVolume: 4409,
          organicRank: 86,

          keywordSales: 313
        },
        {
          date: '2024-08-30',
          searchVolume: 4550,
          organicRank: 85,

          keywordSales: 313
        },
        {
          date: '2024-08-28',
          searchVolume: 4550,
          organicRank: 60,

          keywordSales: 313
        },
        {
          date: '2024-08-27',
          searchVolume: 4550,
          organicRank: 73,

          keywordSales: 313
        },
        {
          date: '2024-08-26',
          searchVolume: 4550,
          organicRank: 68,

          keywordSales: 313
        },
        {
          date: '2024-08-25',
          searchVolume: 4550,
          organicRank: 72,

          keywordSales: 313
        },
        {
          date: '2024-08-24',
          searchVolume: 4550,
          organicRank: 74,

          keywordSales: 313
        },
        {
          date: '2024-08-23',
          searchVolume: 4905,
          organicRank: 54,

          keywordSales: 313
        },
        {
          date: '2024-08-22',
          searchVolume: 4905,
          organicRank: 69,

          keywordSales: 313
        },
        {
          date: '2024-08-21',
          searchVolume: 4905,
          organicRank: 49,

          keywordSales: 313
        },
        {
          date: '2024-08-19',
          searchVolume: 4905,
          organicRank: 86,
          sponsoredRank: 54,
          keywordSales: 313
        },
        {
          date: '2024-08-18',
          searchVolume: 4905,
          organicRank: 75,

          keywordSales: 313
        },
        {
          date: '2024-08-16',
          searchVolume: 4679,
          organicRank: 90,

          keywordSales: 313
        },
        {
          date: '2024-08-15',
          searchVolume: 4679,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-14',
          searchVolume: 4679,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-13',
          searchVolume: 4679,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 313
        },
        {
          date: '2024-08-12',
          searchVolume: 4679,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 313
        },
        {
          date: '2024-08-11',
          searchVolume: 4679,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-10',
          searchVolume: 4679,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-09',
          searchVolume: 5040,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 313
        },
        {
          date: '2024-08-08',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-07',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-06',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-05',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-04',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-03',
          searchVolume: 5040,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-02',
          searchVolume: 4749,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-08-01',
          searchVolume: 4749,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-07-31',
          searchVolume: 4749,
          organicRank: 306,

          keywordSales: 313
        },
        {
          date: '2024-07-30',
          searchVolume: 4170,
          organicRank: 306,

          keywordSales: 415
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_philips water flosser',
      keyword: 'philips water flosser',
      searchVolume: 4344,
      organicStartRank: 12,
      organicHighestRank: 6,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1633,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-09-09',
          searchVolume: 1633,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-09-08',
          searchVolume: 1633,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 34
        },
        {
          date: '2024-09-07',
          searchVolume: 1633,
          organicRank: 8,
          sponsoredRank: 17,
          keywordSales: 34
        },
        {
          date: '2024-09-06',
          searchVolume: 1633,
          organicRank: 9,
          sponsoredRank: 7,
          keywordSales: 34
        },
        {
          date: '2024-09-05',
          searchVolume: 1633,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-09-04',
          searchVolume: 1633,
          organicRank: 8,
          sponsoredRank: 7,
          keywordSales: 34
        },
        {
          date: '2024-09-02',
          searchVolume: 1633,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-29',
          searchVolume: 1930,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-08-28',
          searchVolume: 1930,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-27',
          searchVolume: 1930,
          organicRank: 8,
          sponsoredRank: 22,
          keywordSales: 34
        },
        {
          date: '2024-08-26',
          searchVolume: 1930,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-25',
          searchVolume: 1930,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-24',
          searchVolume: 1930,
          organicRank: 6,

          keywordSales: 34
        },
        {
          date: '2024-08-22',
          searchVolume: 1924,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-21',
          searchVolume: 1924,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-20',
          searchVolume: 1924,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-19',
          searchVolume: 1924,
          organicRank: 7,
          sponsoredRank: 34,
          keywordSales: 34
        },
        {
          date: '2024-08-18',
          searchVolume: 1924,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-16',
          searchVolume: 1829,
          organicRank: 6,
          sponsoredRank: 64,
          keywordSales: 34
        },
        {
          date: '2024-08-15',
          searchVolume: 1829,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-14',
          searchVolume: 1829,
          organicRank: 6,

          keywordSales: 34
        },
        {
          date: '2024-08-13',
          searchVolume: 1829,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-12',
          searchVolume: 1829,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-11',
          searchVolume: 1829,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-10',
          searchVolume: 1829,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-09',
          searchVolume: 1786,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-08',
          searchVolume: 1786,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-07',
          searchVolume: 1786,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-06',
          searchVolume: 1786,
          organicRank: 9,

          keywordSales: 34
        },
        {
          date: '2024-08-05',
          searchVolume: 1786,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-04',
          searchVolume: 1786,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-08-03',
          searchVolume: 1786,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 34
        },
        {
          date: '2024-07-26',
          searchVolume: 4344,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-25',
          searchVolume: 4344,
          organicRank: 9,

          keywordSales: 34
        },
        {
          date: '2024-07-24',
          searchVolume: 4344,
          organicRank: 10,

          keywordSales: 34
        },
        {
          date: '2024-07-23',
          searchVolume: 4344,
          organicRank: 7,

          keywordSales: 34
        },
        {
          date: '2024-07-22',
          searchVolume: 4344,
          organicRank: 9,

          keywordSales: 34
        },
        {
          date: '2024-07-21',
          searchVolume: 4344,
          organicRank: 9,

          keywordSales: 34
        },
        {
          date: '2024-07-20',
          searchVolume: 4344,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-07-19',
          searchVolume: 2582,
          organicRank: 9,

          keywordSales: 34
        },
        {
          date: '2024-07-18',
          searchVolume: 2582,
          organicRank: 12,

          keywordSales: 34
        },
        {
          date: '2024-07-17',
          searchVolume: 2582,
          organicRank: 13,

          keywordSales: 34
        },
        {
          date: '2024-07-16',
          searchVolume: 2582,
          organicRank: 12,

          keywordSales: 34
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_plaque remover',
      keyword: 'plaque remover',
      searchVolume: 2688,
      organicStartRank: 306,
      organicHighestRank: 27,
      sponsoredStartRank: 62,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-09-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3368,
          organicRank: 269,

          keywordSales: 122
        },
        {
          date: '2024-09-09',
          searchVolume: 3368,
          organicRank: 123,

          keywordSales: 122
        },
        {
          date: '2024-09-08',
          searchVolume: 3368,
          organicRank: 99,
          sponsoredRank: 9,
          keywordSales: 122
        },
        {
          date: '2024-09-07',
          searchVolume: 3368,
          organicRank: 112,

          keywordSales: 122
        },
        {
          date: '2024-09-06',
          searchVolume: 3368,
          organicRank: 271,

          keywordSales: 122
        },
        {
          date: '2024-09-05',
          searchVolume: 3368,
          organicRank: 118,

          keywordSales: 122
        },
        {
          date: '2024-09-04',
          searchVolume: 3368,
          organicRank: 245,

          keywordSales: 122
        },
        {
          date: '2024-09-01',
          searchVolume: 3368,
          organicRank: 282,
          sponsoredRank: 62,
          keywordSales: 122
        },
        {
          date: '2024-08-29',
          searchVolume: 3429,
          organicRank: 83,

          keywordSales: 122
        },
        {
          date: '2024-08-27',
          searchVolume: 3429,
          organicRank: 47,

          keywordSales: 122
        },
        {
          date: '2024-08-26',
          searchVolume: 3429,
          organicRank: 77,

          keywordSales: 122
        },
        {
          date: '2024-08-25',
          searchVolume: 3429,
          organicRank: 68,

          keywordSales: 122
        },
        {
          date: '2024-08-24',
          searchVolume: 3429,
          organicRank: 52,

          keywordSales: 122
        },
        {
          date: '2024-08-23',
          searchVolume: 3434,
          organicRank: 47,

          keywordSales: 122
        },
        {
          date: '2024-08-22',
          searchVolume: 3434,
          organicRank: 52,

          keywordSales: 122
        },
        {
          date: '2024-08-20',
          searchVolume: 3434,
          organicRank: 54,

          keywordSales: 122
        },
        {
          date: '2024-08-19',
          searchVolume: 3434,
          organicRank: 47,

          keywordSales: 122
        },
        {
          date: '2024-08-17',
          searchVolume: 3434,
          organicRank: 54,

          keywordSales: 122
        },
        {
          date: '2024-08-16',
          searchVolume: 2982,
          organicRank: 63,

          keywordSales: 122
        },
        {
          date: '2024-08-15',
          searchVolume: 2982,
          organicRank: 64,

          keywordSales: 122
        },
        {
          date: '2024-08-14',
          searchVolume: 2982,
          organicRank: 34,

          keywordSales: 122
        },
        {
          date: '2024-08-13',
          searchVolume: 2982,
          organicRank: 30,

          keywordSales: 122
        },
        {
          date: '2024-08-12',
          searchVolume: 2982,
          organicRank: 31,

          keywordSales: 122
        },
        {
          date: '2024-08-11',
          searchVolume: 2982,
          organicRank: 32,

          keywordSales: 122
        },
        {
          date: '2024-08-10',
          searchVolume: 2982,
          organicRank: 31,

          keywordSales: 122
        },
        {
          date: '2024-08-09',
          searchVolume: 3016,
          organicRank: 29,

          keywordSales: 122
        },
        {
          date: '2024-08-08',
          searchVolume: 3016,
          organicRank: 27,

          keywordSales: 122
        },
        {
          date: '2024-08-07',
          searchVolume: 3016,
          organicRank: 28,

          keywordSales: 122
        },
        {
          date: '2024-08-06',
          searchVolume: 3016,
          organicRank: 29,

          keywordSales: 122
        },
        {
          date: '2024-08-05',
          searchVolume: 3016,
          organicRank: 30,

          keywordSales: 122
        },
        {
          date: '2024-08-04',
          searchVolume: 3016,
          organicRank: 33,

          keywordSales: 122
        },
        {
          date: '2024-08-03',
          searchVolume: 3016,
          organicRank: 128,

          keywordSales: 122
        },
        {
          date: '2024-08-02',
          searchVolume: 2886,
          organicRank: 122,

          keywordSales: 122
        },
        {
          date: '2024-08-01',
          searchVolume: 2886,
          organicRank: 148,

          keywordSales: 122
        },
        {
          date: '2024-07-31',
          searchVolume: 2886,
          organicRank: 306,

          keywordSales: 122
        },
        {
          date: '2024-07-30',
          searchVolume: 2688,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 152
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_plaque remover for teeth',
      keyword: 'plaque remover for teeth',
      searchVolume: 21064,
      organicStartRank: 160,
      organicHighestRank: 35,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 33264,
          organicRank: 94,

          keywordSales: 1290
        },
        {
          date: '2024-09-09',
          searchVolume: 33264,
          organicRank: 306,

          keywordSales: 1290
        },
        {
          date: '2024-09-08',
          searchVolume: 33264,
          organicRank: 82,

          keywordSales: 1290
        },
        {
          date: '2024-09-07',
          searchVolume: 33264,
          organicRank: 256,

          keywordSales: 1290
        },
        {
          date: '2024-09-05',
          searchVolume: 33264,
          organicRank: 83,

          keywordSales: 1290
        },
        {
          date: '2024-09-01',
          searchVolume: 33264,
          organicRank: 35,

          keywordSales: 1290
        },
        {
          date: '2024-08-29',
          searchVolume: 34326,
          organicRank: 306,

          keywordSales: 1290
        },
        {
          date: '2024-08-27',
          searchVolume: 34326,
          organicRank: 306,

          keywordSales: 1290
        },
        {
          date: '2024-08-26',
          searchVolume: 34326,
          organicRank: 136,

          keywordSales: 1290
        },
        {
          date: '2024-08-25',
          searchVolume: 34326,
          organicRank: 306,

          keywordSales: 1290
        },
        {
          date: '2024-08-24',
          searchVolume: 34326,
          organicRank: 306,

          keywordSales: 1290
        },
        {
          date: '2024-08-23',
          searchVolume: 32833,
          organicRank: 101,

          keywordSales: 1290
        },
        {
          date: '2024-08-20',
          searchVolume: 32833,
          organicRank: 162,

          keywordSales: 1290
        },
        {
          date: '2024-08-19',
          searchVolume: 32833,
          organicRank: 104,

          keywordSales: 1290
        },
        {
          date: '2024-08-16',
          searchVolume: 27660,
          organicRank: 153,

          keywordSales: 1290
        },
        {
          date: '2024-08-15',
          searchVolume: 27660,
          organicRank: 144,

          keywordSales: 1290
        },
        {
          date: '2024-08-14',
          searchVolume: 27660,
          organicRank: 127,

          keywordSales: 1290
        },
        {
          date: '2024-08-13',
          searchVolume: 27660,
          organicRank: 169,

          keywordSales: 1290
        },
        {
          date: '2024-08-12',
          searchVolume: 27660,
          organicRank: 167,

          keywordSales: 1290
        },
        {
          date: '2024-08-11',
          searchVolume: 27660,
          organicRank: 179,
          sponsoredRank: 5,
          keywordSales: 1290
        },
        {
          date: '2024-08-10',
          searchVolume: 27660,
          organicRank: 189,

          keywordSales: 1290
        },
        {
          date: '2024-08-09',
          searchVolume: 29955,
          organicRank: 175,

          keywordSales: 1290
        },
        {
          date: '2024-08-08',
          searchVolume: 29955,
          organicRank: 166,

          keywordSales: 1290
        },
        {
          date: '2024-08-07',
          searchVolume: 29955,
          organicRank: 180,

          keywordSales: 1290
        },
        {
          date: '2024-08-06',
          searchVolume: 29955,
          organicRank: 157,

          keywordSales: 1290
        },
        {
          date: '2024-08-05',
          searchVolume: 29955,
          organicRank: 169,

          keywordSales: 1290
        },
        {
          date: '2024-08-04',
          searchVolume: 29955,
          organicRank: 153,

          keywordSales: 1290
        },
        {
          date: '2024-08-03',
          searchVolume: 29955,
          organicRank: 160,

          keywordSales: 1290
        },
        {
          date: '2024-08-02',
          searchVolume: 26764,
          organicRank: 160,

          keywordSales: 1290
        },
        {
          date: '2024-08-01',
          searchVolume: 26764,
          organicRank: 162,

          keywordSales: 1290
        },
        {
          date: '2024-07-31',
          searchVolume: 26764,
          organicRank: 170,

          keywordSales: 1290
        },
        {
          date: '2024-07-30',
          searchVolume: 26764,
          organicRank: 266,

          keywordSales: 1290
        },
        {
          date: '2024-07-29',
          searchVolume: 26764,
          organicRank: 174,

          keywordSales: 1290
        },
        {
          date: '2024-07-27',
          searchVolume: 26764,
          organicRank: 160,

          keywordSales: 1290
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_portable water flosser',
      keyword: 'portable water flosser',
      searchVolume: 10544,
      organicStartRank: 15,
      organicHighestRank: 2,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 10728,
          organicRank: 6,

          keywordSales: 301
        },
        {
          date: '2024-09-09',
          searchVolume: 10728,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-09-08',
          searchVolume: 10728,
          organicRank: 12,
          sponsoredRank: 11,
          keywordSales: 301
        },
        {
          date: '2024-09-07',
          searchVolume: 10728,
          organicRank: 12,

          keywordSales: 301
        },
        {
          date: '2024-09-06',
          searchVolume: 10728,
          organicRank: 16,

          keywordSales: 301
        },
        {
          date: '2024-09-04',
          searchVolume: 10728,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 301
        },
        {
          date: '2024-09-03',
          searchVolume: 10728,
          organicRank: 11,

          keywordSales: 301
        },
        {
          date: '2024-09-01',
          searchVolume: 10728,
          organicRank: 10,
          sponsoredRank: 6,
          keywordSales: 301
        },
        {
          date: '2024-08-28',
          searchVolume: 10785,
          organicRank: 10,

          keywordSales: 301
        },
        {
          date: '2024-08-27',
          searchVolume: 10785,
          organicRank: 7,

          keywordSales: 301
        },
        {
          date: '2024-08-26',
          searchVolume: 10785,
          organicRank: 19,
          sponsoredRank: 4,
          keywordSales: 301
        },
        {
          date: '2024-08-25',
          searchVolume: 10785,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-08-24',
          searchVolume: 10785,
          organicRank: 27,

          keywordSales: 301
        },
        {
          date: '2024-08-23',
          searchVolume: 10770,
          organicRank: 10,

          keywordSales: 301
        },
        {
          date: '2024-08-22',
          searchVolume: 10770,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-08-21',
          searchVolume: 10770,
          organicRank: 12,
          sponsoredRank: 21,
          keywordSales: 301
        },
        {
          date: '2024-08-20',
          searchVolume: 10770,
          organicRank: 3,

          keywordSales: 301
        },
        {
          date: '2024-08-19',
          searchVolume: 10770,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 301
        },
        {
          date: '2024-08-17',
          searchVolume: 10770,
          organicRank: 9,

          keywordSales: 301
        },
        {
          date: '2024-08-16',
          searchVolume: 8092,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-08-15',
          searchVolume: 8092,
          organicRank: 2,

          keywordSales: 301
        },
        {
          date: '2024-08-14',
          searchVolume: 8092,
          organicRank: 11,

          keywordSales: 301
        },
        {
          date: '2024-08-13',
          searchVolume: 8092,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-12',
          searchVolume: 8092,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-11',
          searchVolume: 8092,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-10',
          searchVolume: 8092,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-09',
          searchVolume: 7900,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-08',
          searchVolume: 7900,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 301
        },
        {
          date: '2024-08-07',
          searchVolume: 7900,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-06',
          searchVolume: 7900,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-08-05',
          searchVolume: 7900,
          organicRank: 6,

          keywordSales: 301
        },
        {
          date: '2024-08-04',
          searchVolume: 7900,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 301
        },
        {
          date: '2024-08-03',
          searchVolume: 7900,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 301
        },
        {
          date: '2024-08-02',
          searchVolume: 7828,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-08-01',
          searchVolume: 7828,
          organicRank: 7,
          sponsoredRank: 4,
          keywordSales: 301
        },
        {
          date: '2024-07-31',
          searchVolume: 7828,
          organicRank: 5,

          keywordSales: 301
        },
        {
          date: '2024-07-30',
          searchVolume: 7828,
          organicRank: 6,
          sponsoredRank: 20,
          keywordSales: 301
        },
        {
          date: '2024-07-29',
          searchVolume: 7828,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-07-28',
          searchVolume: 7828,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-07-27',
          searchVolume: 7828,
          organicRank: 6,

          keywordSales: 301
        },
        {
          date: '2024-07-26',
          searchVolume: 10544,
          organicRank: 6,

          keywordSales: 301
        },
        {
          date: '2024-07-25',
          searchVolume: 10544,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 301
        },
        {
          date: '2024-07-24',
          searchVolume: 10544,
          organicRank: 5,

          keywordSales: 301
        },
        {
          date: '2024-07-23',
          searchVolume: 10544,
          organicRank: 5,

          keywordSales: 301
        },
        {
          date: '2024-07-22',
          searchVolume: 10544,
          organicRank: 7,

          keywordSales: 301
        },
        {
          date: '2024-07-21',
          searchVolume: 10544,
          organicRank: 7,

          keywordSales: 301
        },
        {
          date: '2024-07-20',
          searchVolume: 10544,
          organicRank: 8,

          keywordSales: 301
        },
        {
          date: '2024-07-19',
          searchVolume: 7209,
          organicRank: 16,

          keywordSales: 301
        },
        {
          date: '2024-07-18',
          searchVolume: 7209,
          organicRank: 15,

          keywordSales: 301
        },
        {
          date: '2024-07-17',
          searchVolume: 7209,
          organicRank: 12,

          keywordSales: 301
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_portable waterpik',
      keyword: 'portable waterpik',
      searchVolume: 7360,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4763,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-09-09',
          searchVolume: 4763,
          organicRank: 1,
          sponsoredRank: 17,
          keywordSales: 234
        },
        {
          date: '2024-09-08',
          searchVolume: 4763,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-09-07',
          searchVolume: 4763,
          organicRank: 1,
          sponsoredRank: 10,
          keywordSales: 234
        },
        {
          date: '2024-09-06',
          searchVolume: 4763,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-09-05',
          searchVolume: 4763,
          organicRank: 2,
          sponsoredRank: 33,
          keywordSales: 234
        },
        {
          date: '2024-09-04',
          searchVolume: 4763,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-09-02',
          searchVolume: 4763,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-29',
          searchVolume: 5150,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-08-28',
          searchVolume: 5150,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-08-27',
          searchVolume: 5150,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-08-26',
          searchVolume: 5150,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-25',
          searchVolume: 5150,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-24',
          searchVolume: 5150,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-22',
          searchVolume: 5407,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-21',
          searchVolume: 5407,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 234
        },
        {
          date: '2024-08-20',
          searchVolume: 5407,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 234
        },
        {
          date: '2024-08-19',
          searchVolume: 5407,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 234
        },
        {
          date: '2024-08-18',
          searchVolume: 5407,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 234
        },
        {
          date: '2024-08-16',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 234
        },
        {
          date: '2024-08-15',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-14',
          searchVolume: 4684,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-13',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-12',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-11',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 234
        },
        {
          date: '2024-08-10',
          searchVolume: 4684,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-09',
          searchVolume: 4576,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-08',
          searchVolume: 4576,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-07',
          searchVolume: 4576,
          organicRank: 1,
          sponsoredRank: 17,
          keywordSales: 234
        },
        {
          date: '2024-08-06',
          searchVolume: 4576,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-05',
          searchVolume: 4576,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-04',
          searchVolume: 4576,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-03',
          searchVolume: 4576,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-08-02',
          searchVolume: 4754,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-08-01',
          searchVolume: 4754,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 234
        },
        {
          date: '2024-07-31',
          searchVolume: 4754,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-30',
          searchVolume: 4754,
          organicRank: 1,
          sponsoredRank: 8,
          keywordSales: 234
        },
        {
          date: '2024-07-29',
          searchVolume: 4754,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-28',
          searchVolume: 4754,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-07-27',
          searchVolume: 4754,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-26',
          searchVolume: 7360,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-25',
          searchVolume: 7360,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-07-24',
          searchVolume: 7360,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 234
        },
        {
          date: '2024-07-23',
          searchVolume: 7360,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-22',
          searchVolume: 7360,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-21',
          searchVolume: 7360,
          organicRank: 1,

          keywordSales: 234
        },
        {
          date: '2024-07-20',
          searchVolume: 7360,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-07-19',
          searchVolume: 4614,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-07-18',
          searchVolume: 4614,
          organicRank: 2,

          keywordSales: 234
        },
        {
          date: '2024-07-17',
          searchVolume: 4614,
          organicRank: 3,

          keywordSales: 234
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_power dental flossers',
      keyword: 'power dental flossers',
      searchVolume: 4691,
      organicStartRank: 12,
      organicHighestRank: 4,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1858,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-09-09',
          searchVolume: 1858,
          organicRank: 9,

          keywordSales: 45
        },
        {
          date: '2024-09-08',
          searchVolume: 1858,
          organicRank: 43,

          keywordSales: 45
        },
        {
          date: '2024-09-07',
          searchVolume: 1858,
          organicRank: 10,
          sponsoredRank: 19,
          keywordSales: 45
        },
        {
          date: '2024-09-06',
          searchVolume: 1858,
          organicRank: 50,
          sponsoredRank: 32,
          keywordSales: 45
        },
        {
          date: '2024-09-05',
          searchVolume: 1858,
          organicRank: 10,

          keywordSales: 45
        },
        {
          date: '2024-09-04',
          searchVolume: 1858,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-09-02',
          searchVolume: 1858,
          organicRank: 12,

          keywordSales: 45
        },
        {
          date: '2024-08-29',
          searchVolume: 1924,
          organicRank: 11,
          sponsoredRank: 36,
          keywordSales: 45
        },
        {
          date: '2024-08-28',
          searchVolume: 1924,
          organicRank: 12,

          keywordSales: 45
        },
        {
          date: '2024-08-27',
          searchVolume: 1924,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-26',
          searchVolume: 1924,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-08-25',
          searchVolume: 1924,
          organicRank: 9,

          keywordSales: 45
        },
        {
          date: '2024-08-24',
          searchVolume: 1924,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-08-22',
          searchVolume: 1915,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-21',
          searchVolume: 1915,
          organicRank: 26,

          keywordSales: 45
        },
        {
          date: '2024-08-20',
          searchVolume: 1915,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-08-19',
          searchVolume: 1915,
          organicRank: 5,
          sponsoredRank: 50,
          keywordSales: 45
        },
        {
          date: '2024-08-18',
          searchVolume: 1915,
          organicRank: 8,
          sponsoredRank: 54,
          keywordSales: 45
        },
        {
          date: '2024-08-16',
          searchVolume: 1832,
          organicRank: 16,

          keywordSales: 45
        },
        {
          date: '2024-08-15',
          searchVolume: 1832,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-14',
          searchVolume: 1832,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 45
        },
        {
          date: '2024-08-13',
          searchVolume: 1832,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-12',
          searchVolume: 1832,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-08-11',
          searchVolume: 1832,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 45
        },
        {
          date: '2024-08-10',
          searchVolume: 1832,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-08-09',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-08',
          searchVolume: 1782,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 45
        },
        {
          date: '2024-08-07',
          searchVolume: 1782,
          organicRank: 4,

          keywordSales: 45
        },
        {
          date: '2024-08-06',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 45
        },
        {
          date: '2024-08-05',
          searchVolume: 1782,
          organicRank: 9,

          keywordSales: 45
        },
        {
          date: '2024-08-04',
          searchVolume: 1782,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-08-03',
          searchVolume: 1782,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-08-02',
          searchVolume: 1766,
          organicRank: 9,

          keywordSales: 45
        },
        {
          date: '2024-08-01',
          searchVolume: 1766,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 45
        },
        {
          date: '2024-07-31',
          searchVolume: 1766,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-07-30',
          searchVolume: 1766,
          organicRank: 7,
          sponsoredRank: 48,
          keywordSales: 45
        },
        {
          date: '2024-07-29',
          searchVolume: 1766,
          organicRank: 6,

          keywordSales: 45
        },
        {
          date: '2024-07-28',
          searchVolume: 1766,
          organicRank: 5,

          keywordSales: 45
        },
        {
          date: '2024-07-27',
          searchVolume: 1766,
          organicRank: 6,
          sponsoredRank: 11,
          keywordSales: 45
        },
        {
          date: '2024-07-26',
          searchVolume: 4691,
          organicRank: 5,

          keywordSales: 45
        },
        {
          date: '2024-07-25',
          searchVolume: 4691,
          organicRank: 5,

          keywordSales: 45
        },
        {
          date: '2024-07-24',
          searchVolume: 4691,
          organicRank: 5,

          keywordSales: 45
        },
        {
          date: '2024-07-23',
          searchVolume: 4691,
          organicRank: 6,

          keywordSales: 45
        },
        {
          date: '2024-07-22',
          searchVolume: 4691,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-07-21',
          searchVolume: 4691,
          organicRank: 7,

          keywordSales: 45
        },
        {
          date: '2024-07-20',
          searchVolume: 4691,
          organicRank: 6,

          keywordSales: 45
        },
        {
          date: '2024-07-19',
          searchVolume: 2207,
          organicRank: 6,

          keywordSales: 45
        },
        {
          date: '2024-07-18',
          searchVolume: 2207,
          organicRank: 12,

          keywordSales: 45
        },
        {
          date: '2024-07-17',
          searchVolume: 2207,
          organicRank: 13,

          keywordSales: 45
        },
        {
          date: '2024-07-16',
          searchVolume: 2207,
          organicRank: 16,

          keywordSales: 45
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_power flosser',
      keyword: 'power flosser',
      searchVolume: 1367,
      organicStartRank: 15,
      organicHighestRank: 15,

      organicPageOneWinDate: '2024-07-30',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1367,
          organicRank: 15,

          keywordSales: 49
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_rechargeable water flosser',
      keyword: 'rechargeable water flosser',
      searchVolume: 1367,
      organicStartRank: 8,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1394,
          organicRank: 4,

          keywordSales: 62
        },
        {
          date: '2024-09-09',
          searchVolume: 1394,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-09-08',
          searchVolume: 1394,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-09-07',
          searchVolume: 1394,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-09-06',
          searchVolume: 1394,
          organicRank: 5,
          sponsoredRank: 6,
          keywordSales: 62
        },
        {
          date: '2024-09-05',
          searchVolume: 1394,
          organicRank: 8,
          sponsoredRank: 21,
          keywordSales: 62
        },
        {
          date: '2024-09-04',
          searchVolume: 1394,
          organicRank: 8,

          keywordSales: 62
        },
        {
          date: '2024-09-01',
          searchVolume: 1394,
          organicRank: 8,

          keywordSales: 62
        },
        {
          date: '2024-08-28',
          searchVolume: 1432,
          organicRank: 6,
          sponsoredRank: 10,
          keywordSales: 62
        },
        {
          date: '2024-08-27',
          searchVolume: 1432,
          organicRank: 6,

          keywordSales: 62
        },
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 6,
          sponsoredRank: 6,
          keywordSales: 62
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 6,

          keywordSales: 62
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-08-23',
          searchVolume: 1659,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-08-22',
          searchVolume: 1659,
          organicRank: 4,

          keywordSales: 62
        },
        {
          date: '2024-08-21',
          searchVolume: 1659,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 62
        },
        {
          date: '2024-08-20',
          searchVolume: 1659,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-19',
          searchVolume: 1659,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 62
        },
        {
          date: '2024-08-17',
          searchVolume: 1659,
          organicRank: 3,

          keywordSales: 62
        },
        {
          date: '2024-08-16',
          searchVolume: 1341,
          organicRank: 7,

          keywordSales: 62
        },
        {
          date: '2024-08-15',
          searchVolume: 1341,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-08-14',
          searchVolume: 1341,
          organicRank: 4,

          keywordSales: 62
        },
        {
          date: '2024-08-13',
          searchVolume: 1341,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-08-12',
          searchVolume: 1341,
          organicRank: 1,

          keywordSales: 62
        },
        {
          date: '2024-08-11',
          searchVolume: 1341,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-10',
          searchVolume: 1341,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-09',
          searchVolume: 1135,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-08',
          searchVolume: 1135,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-07',
          searchVolume: 1135,
          organicRank: 9,

          keywordSales: 62
        },
        {
          date: '2024-08-06',
          searchVolume: 1135,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-05',
          searchVolume: 1135,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-08-04',
          searchVolume: 1135,
          organicRank: 1,

          keywordSales: 62
        },
        {
          date: '2024-08-03',
          searchVolume: 1135,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-08-02',
          searchVolume: 880,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-08-01',
          searchVolume: 880,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 62
        },
        {
          date: '2024-07-31',
          searchVolume: 880,
          organicRank: 2,

          keywordSales: 62
        },
        {
          date: '2024-07-30',
          searchVolume: 880,
          organicRank: 4,
          sponsoredRank: 22,
          keywordSales: 62
        },
        {
          date: '2024-07-29',
          searchVolume: 880,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-07-28',
          searchVolume: 880,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-07-27',
          searchVolume: 880,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-26',
          searchVolume: 1367,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-25',
          searchVolume: 1367,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-24',
          searchVolume: 1367,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-22',
          searchVolume: 1367,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 62
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 3,

          keywordSales: 62
        },
        {
          date: '2024-07-20',
          searchVolume: 1367,
          organicRank: 3,

          keywordSales: 62
        },
        {
          date: '2024-07-19',
          searchVolume: 1147,
          organicRank: 5,

          keywordSales: 62
        },
        {
          date: '2024-07-18',
          searchVolume: 1147,
          organicRank: 8,

          keywordSales: 62
        },
        {
          date: '2024-07-17',
          searchVolume: 1147,
          organicRank: 6,

          keywordSales: 62
        },
        {
          date: '2024-07-16',
          searchVolume: 1147,
          organicRank: 6,

          keywordSales: 62
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_rechargeable waterpik',
      keyword: 'rechargeable waterpik',
      searchVolume: 1536,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-18',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 859,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 34
        },
        {
          date: '2024-09-09',
          searchVolume: 859,
          organicRank: 2,

          keywordSales: 34
        },
        {
          date: '2024-09-08',
          searchVolume: 859,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 34
        },
        {
          date: '2024-09-07',
          searchVolume: 859,
          organicRank: 2,

          keywordSales: 34
        },
        {
          date: '2024-09-06',
          searchVolume: 859,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-09-05',
          searchVolume: 859,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 34
        },
        {
          date: '2024-09-04',
          searchVolume: 859,
          organicRank: 2,

          keywordSales: 34
        },
        {
          date: '2024-09-01',
          searchVolume: 859,
          organicRank: 2,
          sponsoredRank: 10,
          keywordSales: 34
        },
        {
          date: '2024-08-28',
          searchVolume: 953,
          organicRank: 2,
          sponsoredRank: 11,
          keywordSales: 34
        },
        {
          date: '2024-08-27',
          searchVolume: 953,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-08-26',
          searchVolume: 953,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 34
        },
        {
          date: '2024-08-25',
          searchVolume: 953,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-24',
          searchVolume: 953,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-23',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-22',
          searchVolume: 1430,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-21',
          searchVolume: 1430,
          organicRank: 2,

          keywordSales: 34
        },
        {
          date: '2024-08-20',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 43,
          keywordSales: 34
        },
        {
          date: '2024-08-19',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-08-17',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 17,
          keywordSales: 34
        },
        {
          date: '2024-08-16',
          searchVolume: 845,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-15',
          searchVolume: 845,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-14',
          searchVolume: 845,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-13',
          searchVolume: 845,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-12',
          searchVolume: 845,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-11',
          searchVolume: 845,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-10',
          searchVolume: 845,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-09',
          searchVolume: 888,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-08',
          searchVolume: 888,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-07',
          searchVolume: 888,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-06',
          searchVolume: 888,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-08-05',
          searchVolume: 888,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-04',
          searchVolume: 888,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-03',
          searchVolume: 888,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-08-02',
          searchVolume: 1243,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-08-01',
          searchVolume: 1243,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-31',
          searchVolume: 1243,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-30',
          searchVolume: 1243,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-29',
          searchVolume: 1243,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-28',
          searchVolume: 1243,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-27',
          searchVolume: 1243,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 34
        },
        {
          date: '2024-07-26',
          searchVolume: 1536,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-25',
          searchVolume: 1536,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-24',
          searchVolume: 1536,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-23',
          searchVolume: 1536,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-22',
          searchVolume: 1536,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 34
        },
        {
          date: '2024-07-21',
          searchVolume: 1536,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-20',
          searchVolume: 1536,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 1,

          keywordSales: 34
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 1,

          keywordSales: 34
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_shower waterpik',
      keyword: 'shower waterpik',
      searchVolume: 1163,
      organicStartRank: 7,
      organicHighestRank: 7,
      sponsoredStartRank: 35,
      sponsoredHighestRank: 35,
      organicPageOneWinDate: '2024-07-30',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1163,
          organicRank: 7,
          sponsoredRank: 35,
          keywordSales: 37
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_super floss dental floss',
      keyword: 'super floss dental floss',
      searchVolume: 2374,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-09',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-08',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-07',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-06',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-05',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-04',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-09-02',
          searchVolume: 2722,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-29',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-28',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-27',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-26',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-25',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-24',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-22',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-21',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-20',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-19',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-18',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-16',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-15',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-14',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-13',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-12',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-11',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-10',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-09',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-08',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-07',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-06',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-05',
          searchVolume: 2374,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 221
        },
        {
          date: '2024-08-04',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-03',
          searchVolume: 2374,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-02',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 221
        },
        {
          date: '2024-07-30',
          searchVolume: 2318,
          organicRank: 306,

          keywordSales: 220
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tartar remover for teeth',
      keyword: 'tartar remover for teeth',
      searchVolume: 10014,
      organicStartRank: 306,
      organicHighestRank: 75,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 8991,
          organicRank: 113,

          keywordSales: 303
        },
        {
          date: '2024-09-09',
          searchVolume: 8991,
          organicRank: 108,

          keywordSales: 303
        },
        {
          date: '2024-09-08',
          searchVolume: 8991,
          organicRank: 116,

          keywordSales: 303
        },
        {
          date: '2024-09-07',
          searchVolume: 8991,
          organicRank: 100,

          keywordSales: 303
        },
        {
          date: '2024-09-06',
          searchVolume: 8991,
          organicRank: 306,

          keywordSales: 303
        },
        {
          date: '2024-09-05',
          searchVolume: 8991,
          organicRank: 115,

          keywordSales: 303
        },
        {
          date: '2024-09-03',
          searchVolume: 8991,
          organicRank: 113,

          keywordSales: 303
        },
        {
          date: '2024-09-01',
          searchVolume: 8991,
          organicRank: 106,

          keywordSales: 303
        },
        {
          date: '2024-08-28',
          searchVolume: 9030,
          organicRank: 100,

          keywordSales: 303
        },
        {
          date: '2024-08-27',
          searchVolume: 9030,
          organicRank: 97,

          keywordSales: 303
        },
        {
          date: '2024-08-26',
          searchVolume: 9030,
          organicRank: 75,

          keywordSales: 303
        },
        {
          date: '2024-08-25',
          searchVolume: 9030,
          organicRank: 93,

          keywordSales: 303
        },
        {
          date: '2024-08-24',
          searchVolume: 9030,
          organicRank: 87,

          keywordSales: 303
        },
        {
          date: '2024-08-23',
          searchVolume: 8484,
          organicRank: 95,

          keywordSales: 303
        },
        {
          date: '2024-08-22',
          searchVolume: 8484,
          organicRank: 90,

          keywordSales: 303
        },
        {
          date: '2024-08-20',
          searchVolume: 8484,
          organicRank: 103,

          keywordSales: 303
        },
        {
          date: '2024-08-19',
          searchVolume: 8484,
          organicRank: 90,

          keywordSales: 303
        },
        {
          date: '2024-08-18',
          searchVolume: 8484,
          organicRank: 102,

          keywordSales: 303
        },
        {
          date: '2024-08-16',
          searchVolume: 8089,
          organicRank: 130,

          keywordSales: 303
        },
        {
          date: '2024-08-15',
          searchVolume: 8089,
          organicRank: 120,

          keywordSales: 303
        },
        {
          date: '2024-08-14',
          searchVolume: 8089,
          organicRank: 112,
          sponsoredRank: 10,
          keywordSales: 303
        },
        {
          date: '2024-08-13',
          searchVolume: 8089,
          organicRank: 105,

          keywordSales: 303
        },
        {
          date: '2024-08-12',
          searchVolume: 8089,
          organicRank: 108,

          keywordSales: 303
        },
        {
          date: '2024-08-11',
          searchVolume: 8089,
          organicRank: 108,

          keywordSales: 303
        },
        {
          date: '2024-08-10',
          searchVolume: 8089,
          organicRank: 109,

          keywordSales: 303
        },
        {
          date: '2024-08-09',
          searchVolume: 10014,
          organicRank: 106,

          keywordSales: 303
        },
        {
          date: '2024-08-08',
          searchVolume: 10014,
          organicRank: 109,
          sponsoredRank: 1,
          keywordSales: 303
        },
        {
          date: '2024-08-07',
          searchVolume: 10014,
          organicRank: 113,

          keywordSales: 303
        },
        {
          date: '2024-08-06',
          searchVolume: 10014,
          organicRank: 110,

          keywordSales: 303
        },
        {
          date: '2024-08-05',
          searchVolume: 10014,
          organicRank: 124,

          keywordSales: 303
        },
        {
          date: '2024-08-04',
          searchVolume: 10014,
          organicRank: 123,

          keywordSales: 303
        },
        {
          date: '2024-08-03',
          searchVolume: 10014,
          organicRank: 115,

          keywordSales: 303
        },
        {
          date: '2024-08-02',
          searchVolume: 9959,
          organicRank: 306,

          keywordSales: 303
        },
        {
          date: '2024-08-01',
          searchVolume: 9959,
          organicRank: 306,

          keywordSales: 303
        },
        {
          date: '2024-07-31',
          searchVolume: 9959,
          organicRank: 306,

          keywordSales: 303
        },
        {
          date: '2024-07-30',
          searchVolume: 8608,
          organicRank: 306,

          keywordSales: 449
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth care',
      keyword: 'teeth care',
      searchVolume: 1587,
      organicStartRank: 146,
      organicHighestRank: 146,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1587,
          organicRank: 146,
          sponsoredRank: 1,
          keywordSales: 32
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth cleaner',
      keyword: 'teeth cleaner',
      searchVolume: 1835,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 32,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-09',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-08',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-07',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-06',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-05',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-03',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-09-01',
          searchVolume: 1640,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-28',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-27',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-26',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-25',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-24',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-22',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-21',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-20',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-19',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-18',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-16',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-15',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-14',
          searchVolume: 1366,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-08-13',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-12',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-11',
          searchVolume: 1366,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-08-10',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-09',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-08',
          searchVolume: 1784,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-08-07',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-06',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-05',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-04',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-03',
          searchVolume: 1784,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-02',
          searchVolume: 1782,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-08-01',
          searchVolume: 1782,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-07-31',
          searchVolume: 1782,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-07-30',
          searchVolume: 1835,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 57
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth cleaning',
      keyword: 'teeth cleaning',
      searchVolume: 1827,
      organicStartRank: 84,
      organicHighestRank: 13,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1613,
          organicRank: 73,

          keywordSales: 40
        },
        {
          date: '2024-09-09',
          searchVolume: 1613,
          organicRank: 90,

          keywordSales: 40
        },
        {
          date: '2024-09-08',
          searchVolume: 1613,
          organicRank: 85,

          keywordSales: 40
        },
        {
          date: '2024-09-07',
          searchVolume: 1613,
          organicRank: 84,

          keywordSales: 40
        },
        {
          date: '2024-09-06',
          searchVolume: 1613,
          organicRank: 84,
          sponsoredRank: 45,
          keywordSales: 40
        },
        {
          date: '2024-09-05',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-09-04',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-09-01',
          searchVolume: 1613,
          organicRank: 86,

          keywordSales: 40
        },
        {
          date: '2024-08-28',
          searchVolume: 1926,
          organicRank: 41,

          keywordSales: 40
        },
        {
          date: '2024-08-27',
          searchVolume: 1926,
          organicRank: 69,

          keywordSales: 40
        },
        {
          date: '2024-08-26',
          searchVolume: 1926,
          organicRank: 60,

          keywordSales: 40
        },
        {
          date: '2024-08-25',
          searchVolume: 1926,
          organicRank: 72,

          keywordSales: 40
        },
        {
          date: '2024-08-24',
          searchVolume: 1926,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-22',
          searchVolume: 1930,
          organicRank: 46,

          keywordSales: 40
        },
        {
          date: '2024-08-21',
          searchVolume: 1930,
          organicRank: 35,

          keywordSales: 40
        },
        {
          date: '2024-08-20',
          searchVolume: 1930,
          organicRank: 42,

          keywordSales: 40
        },
        {
          date: '2024-08-19',
          searchVolume: 1930,
          organicRank: 22,

          keywordSales: 40
        },
        {
          date: '2024-08-18',
          searchVolume: 1930,
          organicRank: 26,

          keywordSales: 40
        },
        {
          date: '2024-08-16',
          searchVolume: 1827,
          organicRank: 26,

          keywordSales: 40
        },
        {
          date: '2024-08-15',
          searchVolume: 1827,
          organicRank: 13,

          keywordSales: 40
        },
        {
          date: '2024-08-14',
          searchVolume: 1827,
          organicRank: 17,

          keywordSales: 40
        },
        {
          date: '2024-08-13',
          searchVolume: 1827,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-08-12',
          searchVolume: 1827,
          organicRank: 21,

          keywordSales: 40
        },
        {
          date: '2024-08-11',
          searchVolume: 1827,
          organicRank: 22,
          sponsoredRank: 2,
          keywordSales: 40
        },
        {
          date: '2024-08-10',
          searchVolume: 1827,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-08-09',
          searchVolume: 1725,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-08-08',
          searchVolume: 1725,
          organicRank: 35,
          sponsoredRank: 1,
          keywordSales: 40
        },
        {
          date: '2024-08-07',
          searchVolume: 1725,
          organicRank: 29,

          keywordSales: 40
        },
        {
          date: '2024-08-06',
          searchVolume: 1725,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-08-05',
          searchVolume: 1725,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-08-04',
          searchVolume: 1725,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-08-03',
          searchVolume: 1725,
          organicRank: 24,

          keywordSales: 40
        },
        {
          date: '2024-08-02',
          searchVolume: 1561,
          organicRank: 25,

          keywordSales: 40
        },
        {
          date: '2024-08-01',
          searchVolume: 1561,
          organicRank: 45,

          keywordSales: 40
        },
        {
          date: '2024-07-31',
          searchVolume: 1561,
          organicRank: 52,

          keywordSales: 40
        },
        {
          date: '2024-07-30',
          searchVolume: 1561,
          organicRank: 22,

          keywordSales: 40
        },
        {
          date: '2024-07-29',
          searchVolume: 1561,
          organicRank: 26,

          keywordSales: 40
        },
        {
          date: '2024-07-28',
          searchVolume: 1561,
          organicRank: 25,

          keywordSales: 40
        },
        {
          date: '2024-07-27',
          searchVolume: 1561,
          organicRank: 24,

          keywordSales: 40
        },
        {
          date: '2024-07-26',
          searchVolume: 1827,
          organicRank: 61,

          keywordSales: 40
        },
        {
          date: '2024-07-25',
          searchVolume: 1827,
          organicRank: 54,

          keywordSales: 40
        },
        {
          date: '2024-07-24',
          searchVolume: 1827,
          organicRank: 57,

          keywordSales: 40
        },
        {
          date: '2024-07-23',
          searchVolume: 1827,
          organicRank: 49,

          keywordSales: 40
        },
        {
          date: '2024-07-22',
          searchVolume: 1827,
          organicRank: 66,

          keywordSales: 40
        },
        {
          date: '2024-07-21',
          searchVolume: 1827,
          organicRank: 70,

          keywordSales: 40
        },
        {
          date: '2024-07-20',
          searchVolume: 1827,
          organicRank: 67,

          keywordSales: 40
        },
        {
          date: '2024-07-19',
          searchVolume: 1585,
          organicRank: 89,

          keywordSales: 40
        },
        {
          date: '2024-07-18',
          searchVolume: 1585,
          organicRank: 84,

          keywordSales: 40
        },
        {
          date: '2024-07-17',
          searchVolume: 1585,
          organicRank: 75,

          keywordSales: 40
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth cleaning tools',
      keyword: 'teeth cleaning tools',
      searchVolume: 10558,
      organicStartRank: 128,
      organicHighestRank: 67,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-11',
          searchVolume: 13022,
          organicRank: 96,

          keywordSales: 559
        },
        {
          date: '2024-09-10',
          searchVolume: 13022,
          organicRank: 87,

          keywordSales: 559
        },
        {
          date: '2024-09-09',
          searchVolume: 13022,
          organicRank: 137,

          keywordSales: 559
        },
        {
          date: '2024-09-07',
          searchVolume: 13022,
          organicRank: 136,

          keywordSales: 559
        },
        {
          date: '2024-09-06',
          searchVolume: 13022,
          organicRank: 142,

          keywordSales: 559
        },
        {
          date: '2024-09-05',
          searchVolume: 13022,
          organicRank: 144,

          keywordSales: 559
        },
        {
          date: '2024-09-04',
          searchVolume: 13022,
          organicRank: 133,

          keywordSales: 559
        },
        {
          date: '2024-09-03',
          searchVolume: 13022,
          organicRank: 133,

          keywordSales: 559
        },
        {
          date: '2024-08-31',
          searchVolume: 13022,
          organicRank: 91,

          keywordSales: 559
        },
        {
          date: '2024-08-28',
          searchVolume: 14122,
          organicRank: 71,

          keywordSales: 559
        },
        {
          date: '2024-08-27',
          searchVolume: 14122,
          organicRank: 86,

          keywordSales: 559
        },
        {
          date: '2024-08-26',
          searchVolume: 14122,
          organicRank: 70,

          keywordSales: 559
        },
        {
          date: '2024-08-25',
          searchVolume: 14122,
          organicRank: 67,

          keywordSales: 559
        },
        {
          date: '2024-08-24',
          searchVolume: 14122,
          organicRank: 79,

          keywordSales: 559
        },
        {
          date: '2024-08-23',
          searchVolume: 14163,
          organicRank: 75,

          keywordSales: 559
        },
        {
          date: '2024-08-22',
          searchVolume: 14163,
          organicRank: 85,

          keywordSales: 559
        },
        {
          date: '2024-08-20',
          searchVolume: 14163,
          organicRank: 87,

          keywordSales: 559
        },
        {
          date: '2024-08-19',
          searchVolume: 14163,
          organicRank: 72,

          keywordSales: 559
        },
        {
          date: '2024-08-18',
          searchVolume: 14163,
          organicRank: 90,

          keywordSales: 559
        },
        {
          date: '2024-08-16',
          searchVolume: 13509,
          organicRank: 93,

          keywordSales: 559
        },
        {
          date: '2024-08-15',
          searchVolume: 13509,
          organicRank: 91,

          keywordSales: 559
        },
        {
          date: '2024-08-14',
          searchVolume: 13509,
          organicRank: 87,

          keywordSales: 559
        },
        {
          date: '2024-08-13',
          searchVolume: 13509,
          organicRank: 74,

          keywordSales: 559
        },
        {
          date: '2024-08-12',
          searchVolume: 13509,
          organicRank: 76,

          keywordSales: 559
        },
        {
          date: '2024-08-11',
          searchVolume: 13509,
          organicRank: 94,

          keywordSales: 559
        },
        {
          date: '2024-08-10',
          searchVolume: 13509,
          organicRank: 91,

          keywordSales: 559
        },
        {
          date: '2024-08-09',
          searchVolume: 14584,
          organicRank: 88,

          keywordSales: 559
        },
        {
          date: '2024-08-08',
          searchVolume: 14584,
          organicRank: 92,

          keywordSales: 559
        },
        {
          date: '2024-08-07',
          searchVolume: 14584,
          organicRank: 81,

          keywordSales: 559
        },
        {
          date: '2024-08-06',
          searchVolume: 14584,
          organicRank: 94,

          keywordSales: 559
        },
        {
          date: '2024-08-05',
          searchVolume: 14584,
          organicRank: 88,

          keywordSales: 559
        },
        {
          date: '2024-08-04',
          searchVolume: 14584,
          organicRank: 87,

          keywordSales: 559
        },
        {
          date: '2024-08-03',
          searchVolume: 14584,
          organicRank: 95,

          keywordSales: 559
        },
        {
          date: '2024-08-02',
          searchVolume: 14406,
          organicRank: 96,

          keywordSales: 559
        },
        {
          date: '2024-08-01',
          searchVolume: 14406,
          organicRank: 100,
          sponsoredRank: 2,
          keywordSales: 559
        },
        {
          date: '2024-07-31',
          searchVolume: 14406,
          organicRank: 91,

          keywordSales: 559
        },
        {
          date: '2024-07-30',
          searchVolume: 14406,
          organicRank: 98,

          keywordSales: 559
        },
        {
          date: '2024-07-29',
          searchVolume: 14406,
          organicRank: 113,

          keywordSales: 559
        },
        {
          date: '2024-07-28',
          searchVolume: 14406,
          organicRank: 125,

          keywordSales: 559
        },
        {
          date: '2024-07-27',
          searchVolume: 14406,
          organicRank: 102,

          keywordSales: 559
        },
        {
          date: '2024-07-26',
          searchVolume: 10558,
          organicRank: 96,

          keywordSales: 559
        },
        {
          date: '2024-07-25',
          searchVolume: 10558,
          organicRank: 101,

          keywordSales: 559
        },
        {
          date: '2024-07-24',
          searchVolume: 10558,
          organicRank: 100,

          keywordSales: 559
        },
        {
          date: '2024-07-23',
          searchVolume: 10558,
          organicRank: 100,

          keywordSales: 559
        },
        {
          date: '2024-07-22',
          searchVolume: 10558,
          organicRank: 97,

          keywordSales: 559
        },
        {
          date: '2024-07-21',
          searchVolume: 10558,
          organicRank: 98,

          keywordSales: 559
        },
        {
          date: '2024-07-20',
          searchVolume: 10558,
          organicRank: 98,

          keywordSales: 559
        },
        {
          date: '2024-07-19',
          searchVolume: 12596,
          organicRank: 108,

          keywordSales: 559
        },
        {
          date: '2024-07-18',
          searchVolume: 12596,
          organicRank: 128,

          keywordSales: 559
        },
        {
          date: '2024-07-17',
          searchVolume: 12596,
          organicRank: 120,

          keywordSales: 559
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth cleaning tools plaque remover',
      keyword: 'teeth cleaning tools plaque remover',
      searchVolume: 755,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-09',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-08',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-07',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-06',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-05',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-03',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-09-01',
          searchVolume: 1311,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-28',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-27',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-26',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-25',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-24',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-22',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-21',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-20',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-19',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-18',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-16',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-15',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-14',
          searchVolume: 845,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 43
        },
        {
          date: '2024-08-13',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-12',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-11',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-10',
          searchVolume: 845,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-09',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-08',
          searchVolume: 755,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 43
        },
        {
          date: '2024-08-07',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-06',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-05',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-04',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-03',
          searchVolume: 755,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-01',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-07-31',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-07-30',
          searchVolume: 844,
          organicRank: 306,

          keywordSales: 45
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth floss',
      keyword: 'teeth floss',
      searchVolume: 2319,
      organicStartRank: 263,
      organicHighestRank: 101,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2474,
          organicRank: 127,

          keywordSales: 183
        },
        {
          date: '2024-09-09',
          searchVolume: 2474,
          organicRank: 143,

          keywordSales: 183
        },
        {
          date: '2024-09-08',
          searchVolume: 2474,
          organicRank: 148,

          keywordSales: 183
        },
        {
          date: '2024-09-07',
          searchVolume: 2474,
          organicRank: 141,

          keywordSales: 183
        },
        {
          date: '2024-09-06',
          searchVolume: 2474,
          organicRank: 128,

          keywordSales: 183
        },
        {
          date: '2024-09-05',
          searchVolume: 2474,
          organicRank: 131,

          keywordSales: 183
        },
        {
          date: '2024-09-04',
          searchVolume: 2474,
          organicRank: 153,

          keywordSales: 183
        },
        {
          date: '2024-09-02',
          searchVolume: 2474,
          organicRank: 121,

          keywordSales: 183
        },
        {
          date: '2024-08-29',
          searchVolume: 2548,
          organicRank: 101,

          keywordSales: 183
        },
        {
          date: '2024-08-28',
          searchVolume: 2548,
          organicRank: 134,

          keywordSales: 183
        },
        {
          date: '2024-08-27',
          searchVolume: 2548,
          organicRank: 133,

          keywordSales: 183
        },
        {
          date: '2024-08-26',
          searchVolume: 2548,
          organicRank: 124,

          keywordSales: 183
        },
        {
          date: '2024-08-25',
          searchVolume: 2548,
          organicRank: 106,

          keywordSales: 183
        },
        {
          date: '2024-08-24',
          searchVolume: 2548,
          organicRank: 125,

          keywordSales: 183
        },
        {
          date: '2024-08-22',
          searchVolume: 2548,
          organicRank: 102,

          keywordSales: 183
        },
        {
          date: '2024-08-21',
          searchVolume: 2548,
          organicRank: 120,

          keywordSales: 183
        },
        {
          date: '2024-08-20',
          searchVolume: 2548,
          organicRank: 122,

          keywordSales: 183
        },
        {
          date: '2024-08-19',
          searchVolume: 2548,
          organicRank: 129,

          keywordSales: 183
        },
        {
          date: '2024-08-18',
          searchVolume: 2548,
          organicRank: 131,

          keywordSales: 183
        },
        {
          date: '2024-08-16',
          searchVolume: 2429,
          organicRank: 159,

          keywordSales: 183
        },
        {
          date: '2024-08-15',
          searchVolume: 2429,
          organicRank: 129,

          keywordSales: 183
        },
        {
          date: '2024-08-14',
          searchVolume: 2429,
          organicRank: 148,

          keywordSales: 183
        },
        {
          date: '2024-08-13',
          searchVolume: 2429,
          organicRank: 116,

          keywordSales: 183
        },
        {
          date: '2024-08-12',
          searchVolume: 2429,
          organicRank: 121,

          keywordSales: 183
        },
        {
          date: '2024-08-11',
          searchVolume: 2429,
          organicRank: 118,

          keywordSales: 183
        },
        {
          date: '2024-08-10',
          searchVolume: 2429,
          organicRank: 115,

          keywordSales: 183
        },
        {
          date: '2024-08-09',
          searchVolume: 2376,
          organicRank: 116,

          keywordSales: 183
        },
        {
          date: '2024-08-08',
          searchVolume: 2376,
          organicRank: 119,

          keywordSales: 183
        },
        {
          date: '2024-08-07',
          searchVolume: 2376,
          organicRank: 103,

          keywordSales: 183
        },
        {
          date: '2024-08-06',
          searchVolume: 2376,
          organicRank: 121,

          keywordSales: 183
        },
        {
          date: '2024-08-05',
          searchVolume: 2376,
          organicRank: 128,

          keywordSales: 183
        },
        {
          date: '2024-08-04',
          searchVolume: 2376,
          organicRank: 133,

          keywordSales: 183
        },
        {
          date: '2024-08-03',
          searchVolume: 2376,
          organicRank: 130,

          keywordSales: 183
        },
        {
          date: '2024-08-02',
          searchVolume: 2245,
          organicRank: 171,

          keywordSales: 183
        },
        {
          date: '2024-08-01',
          searchVolume: 2245,
          organicRank: 174,

          keywordSales: 183
        },
        {
          date: '2024-07-31',
          searchVolume: 2245,
          organicRank: 136,

          keywordSales: 183
        },
        {
          date: '2024-07-30',
          searchVolume: 2245,
          organicRank: 138,

          keywordSales: 183
        },
        {
          date: '2024-07-29',
          searchVolume: 2245,
          organicRank: 168,

          keywordSales: 183
        },
        {
          date: '2024-07-28',
          searchVolume: 2245,
          organicRank: 172,

          keywordSales: 183
        },
        {
          date: '2024-07-27',
          searchVolume: 2245,
          organicRank: 171,

          keywordSales: 183
        },
        {
          date: '2024-07-26',
          searchVolume: 2319,
          organicRank: 165,

          keywordSales: 183
        },
        {
          date: '2024-07-25',
          searchVolume: 2319,
          organicRank: 177,

          keywordSales: 183
        },
        {
          date: '2024-07-24',
          searchVolume: 2319,
          organicRank: 169,

          keywordSales: 183
        },
        {
          date: '2024-07-23',
          searchVolume: 2319,
          organicRank: 192,

          keywordSales: 183
        },
        {
          date: '2024-07-21',
          searchVolume: 2319,
          organicRank: 156,

          keywordSales: 183
        },
        {
          date: '2024-07-20',
          searchVolume: 2319,
          organicRank: 253,

          keywordSales: 183
        },
        {
          date: '2024-07-19',
          searchVolume: 2052,
          organicRank: 263,

          keywordSales: 183
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth flosser water',
      keyword: 'teeth flosser water',
      searchVolume: 1366,
      organicStartRank: 43,
      organicHighestRank: 5,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1559,
          organicRank: 16,

          keywordSales: 44
        },
        {
          date: '2024-09-09',
          searchVolume: 1559,
          organicRank: 20,

          keywordSales: 44
        },
        {
          date: '2024-09-08',
          searchVolume: 1559,
          organicRank: 67,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-09-07',
          searchVolume: 1559,
          organicRank: 55,

          keywordSales: 44
        },
        {
          date: '2024-09-06',
          searchVolume: 1559,
          organicRank: 22,

          keywordSales: 44
        },
        {
          date: '2024-09-05',
          searchVolume: 1559,
          organicRank: 22,

          keywordSales: 44
        },
        {
          date: '2024-09-04',
          searchVolume: 1559,
          organicRank: 26,

          keywordSales: 44
        },
        {
          date: '2024-09-01',
          searchVolume: 1559,
          organicRank: 21,

          keywordSales: 44
        },
        {
          date: '2024-08-28',
          searchVolume: 1664,
          organicRank: 21,

          keywordSales: 44
        },
        {
          date: '2024-08-27',
          searchVolume: 1664,
          organicRank: 16,

          keywordSales: 44
        },
        {
          date: '2024-08-26',
          searchVolume: 1664,
          organicRank: 13,

          keywordSales: 44
        },
        {
          date: '2024-08-25',
          searchVolume: 1664,
          organicRank: 18,

          keywordSales: 44
        },
        {
          date: '2024-08-24',
          searchVolume: 1664,
          organicRank: 16,

          keywordSales: 44
        },
        {
          date: '2024-08-23',
          searchVolume: 1919,
          organicRank: 18,
          sponsoredRank: 7,
          keywordSales: 44
        },
        {
          date: '2024-08-22',
          searchVolume: 1919,
          organicRank: 17,

          keywordSales: 44
        },
        {
          date: '2024-08-21',
          searchVolume: 1919,
          organicRank: 13,
          sponsoredRank: 12,
          keywordSales: 44
        },
        {
          date: '2024-08-20',
          searchVolume: 1919,
          organicRank: 14,

          keywordSales: 44
        },
        {
          date: '2024-08-19',
          searchVolume: 1919,
          organicRank: 20,

          keywordSales: 44
        },
        {
          date: '2024-08-17',
          searchVolume: 1919,
          organicRank: 14,

          keywordSales: 44
        },
        {
          date: '2024-08-16',
          searchVolume: 1341,
          organicRank: 11,

          keywordSales: 44
        },
        {
          date: '2024-08-15',
          searchVolume: 1341,
          organicRank: 10,

          keywordSales: 44
        },
        {
          date: '2024-08-14',
          searchVolume: 1341,
          organicRank: 14,

          keywordSales: 44
        },
        {
          date: '2024-08-13',
          searchVolume: 1341,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-08-12',
          searchVolume: 1341,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-08-11',
          searchVolume: 1341,
          organicRank: 12,

          keywordSales: 44
        },
        {
          date: '2024-08-10',
          searchVolume: 1341,
          organicRank: 10,

          keywordSales: 44
        },
        {
          date: '2024-08-09',
          searchVolume: 1256,
          organicRank: 12,

          keywordSales: 44
        },
        {
          date: '2024-08-08',
          searchVolume: 1256,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-08-07',
          searchVolume: 1256,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 44
        },
        {
          date: '2024-08-06',
          searchVolume: 1256,
          organicRank: 5,

          keywordSales: 44
        },
        {
          date: '2024-08-05',
          searchVolume: 1256,
          organicRank: 8,

          keywordSales: 44
        },
        {
          date: '2024-08-04',
          searchVolume: 1256,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-08-03',
          searchVolume: 1256,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-08-02',
          searchVolume: 1170,
          organicRank: 10,

          keywordSales: 44
        },
        {
          date: '2024-08-01',
          searchVolume: 1170,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-07-31',
          searchVolume: 1170,
          organicRank: 12,

          keywordSales: 44
        },
        {
          date: '2024-07-30',
          searchVolume: 1170,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-07-29',
          searchVolume: 1170,
          organicRank: 16,

          keywordSales: 44
        },
        {
          date: '2024-07-28',
          searchVolume: 1170,
          organicRank: 14,

          keywordSales: 44
        },
        {
          date: '2024-07-27',
          searchVolume: 1170,
          organicRank: 13,

          keywordSales: 44
        },
        {
          date: '2024-07-26',
          searchVolume: 1366,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-07-25',
          searchVolume: 1366,
          organicRank: 17,
          sponsoredRank: 12,
          keywordSales: 44
        },
        {
          date: '2024-07-24',
          searchVolume: 1366,
          organicRank: 28,
          sponsoredRank: 9,
          keywordSales: 44
        },
        {
          date: '2024-07-23',
          searchVolume: 1366,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 44
        },
        {
          date: '2024-07-22',
          searchVolume: 1366,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 44
        },
        {
          date: '2024-07-21',
          searchVolume: 1366,
          organicRank: 24,

          keywordSales: 44
        },
        {
          date: '2024-07-20',
          searchVolume: 1366,
          organicRank: 31,

          keywordSales: 44
        },
        {
          date: '2024-07-19',
          searchVolume: 893,
          organicRank: 31,

          keywordSales: 44
        },
        {
          date: '2024-07-18',
          searchVolume: 893,
          organicRank: 43,

          keywordSales: 44
        },
        {
          date: '2024-07-17',
          searchVolume: 893,
          organicRank: 45,

          keywordSales: 44
        },
        {
          date: '2024-07-16',
          searchVolume: 893,
          organicRank: 40,

          keywordSales: 44
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth flossers',
      keyword: 'teeth flossers',
      searchVolume: 1607,
      organicStartRank: 126,
      organicHighestRank: 22,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1862,
          organicRank: 64,

          keywordSales: 102
        },
        {
          date: '2024-09-09',
          searchVolume: 1862,
          organicRank: 71,

          keywordSales: 102
        },
        {
          date: '2024-09-08',
          searchVolume: 1862,
          organicRank: 126,

          keywordSales: 102
        },
        {
          date: '2024-09-07',
          searchVolume: 1862,
          organicRank: 128,
          sponsoredRank: 10,
          keywordSales: 102
        },
        {
          date: '2024-09-06',
          searchVolume: 1862,
          organicRank: 66,

          keywordSales: 102
        },
        {
          date: '2024-09-05',
          searchVolume: 1862,
          organicRank: 127,

          keywordSales: 102
        },
        {
          date: '2024-09-04',
          searchVolume: 1862,
          organicRank: 63,

          keywordSales: 102
        },
        {
          date: '2024-09-02',
          searchVolume: 1862,
          organicRank: 58,

          keywordSales: 102
        },
        {
          date: '2024-08-29',
          searchVolume: 1690,
          organicRank: 40,

          keywordSales: 102
        },
        {
          date: '2024-08-28',
          searchVolume: 1690,
          organicRank: 51,

          keywordSales: 102
        },
        {
          date: '2024-08-27',
          searchVolume: 1690,
          organicRank: 57,

          keywordSales: 102
        },
        {
          date: '2024-08-26',
          searchVolume: 1690,
          organicRank: 33,

          keywordSales: 102
        },
        {
          date: '2024-08-25',
          searchVolume: 1690,
          organicRank: 29,

          keywordSales: 102
        },
        {
          date: '2024-08-24',
          searchVolume: 1690,
          organicRank: 41,

          keywordSales: 102
        },
        {
          date: '2024-08-22',
          searchVolume: 2173,
          organicRank: 52,

          keywordSales: 102
        },
        {
          date: '2024-08-21',
          searchVolume: 2173,
          organicRank: 60,
          sponsoredRank: 5,
          keywordSales: 102
        },
        {
          date: '2024-08-20',
          searchVolume: 2173,
          organicRank: 38,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-08-19',
          searchVolume: 2173,
          organicRank: 22,
          sponsoredRank: 28,
          keywordSales: 102
        },
        {
          date: '2024-08-18',
          searchVolume: 2173,
          organicRank: 31,

          keywordSales: 102
        },
        {
          date: '2024-08-16',
          searchVolume: 1827,
          organicRank: 31,
          sponsoredRank: 35,
          keywordSales: 102
        },
        {
          date: '2024-08-15',
          searchVolume: 1827,
          organicRank: 27,

          keywordSales: 102
        },
        {
          date: '2024-08-14',
          searchVolume: 1827,
          organicRank: 22,
          sponsoredRank: 9,
          keywordSales: 102
        },
        {
          date: '2024-08-13',
          searchVolume: 1827,
          organicRank: 46,
          sponsoredRank: 12,
          keywordSales: 102
        },
        {
          date: '2024-08-12',
          searchVolume: 1827,
          organicRank: 26,
          sponsoredRank: 10,
          keywordSales: 102
        },
        {
          date: '2024-08-11',
          searchVolume: 1827,
          organicRank: 40,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-08-10',
          searchVolume: 1827,
          organicRank: 47,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-08-09',
          searchVolume: 1788,
          organicRank: 46,
          sponsoredRank: 1,
          keywordSales: 102
        },
        {
          date: '2024-08-08',
          searchVolume: 1788,
          organicRank: 45,
          sponsoredRank: 3,
          keywordSales: 102
        },
        {
          date: '2024-08-07',
          searchVolume: 1788,
          organicRank: 28,

          keywordSales: 102
        },
        {
          date: '2024-08-06',
          searchVolume: 1788,
          organicRank: 48,

          keywordSales: 102
        },
        {
          date: '2024-08-05',
          searchVolume: 1788,
          organicRank: 44,

          keywordSales: 102
        },
        {
          date: '2024-08-04',
          searchVolume: 1788,
          organicRank: 49,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-08-03',
          searchVolume: 1788,
          organicRank: 60,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-08-02',
          searchVolume: 1554,
          organicRank: 53,

          keywordSales: 102
        },
        {
          date: '2024-08-01',
          searchVolume: 1554,
          organicRank: 57,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-07-31',
          searchVolume: 1554,
          organicRank: 56,

          keywordSales: 102
        },
        {
          date: '2024-07-30',
          searchVolume: 1554,
          organicRank: 44,
          sponsoredRank: 6,
          keywordSales: 102
        },
        {
          date: '2024-07-29',
          searchVolume: 1554,
          organicRank: 54,

          keywordSales: 102
        },
        {
          date: '2024-07-28',
          searchVolume: 1554,
          organicRank: 48,

          keywordSales: 102
        },
        {
          date: '2024-07-27',
          searchVolume: 1554,
          organicRank: 56,

          keywordSales: 102
        },
        {
          date: '2024-07-26',
          searchVolume: 1607,
          organicRank: 71,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-07-25',
          searchVolume: 1607,
          organicRank: 79,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-07-24',
          searchVolume: 1607,
          organicRank: 84,
          sponsoredRank: 1,
          keywordSales: 102
        },
        {
          date: '2024-07-23',
          searchVolume: 1607,
          organicRank: 74,
          sponsoredRank: 2,
          keywordSales: 102
        },
        {
          date: '2024-07-22',
          searchVolume: 1607,
          organicRank: 77,
          sponsoredRank: 1,
          keywordSales: 102
        },
        {
          date: '2024-07-20',
          searchVolume: 1607,
          organicRank: 111,

          keywordSales: 102
        },
        {
          date: '2024-07-19',
          searchVolume: 1562,
          organicRank: 117,

          keywordSales: 102
        },
        {
          date: '2024-07-18',
          searchVolume: 1562,
          organicRank: 126,

          keywordSales: 102
        },
        {
          date: '2024-07-17',
          searchVolume: 1562,
          organicRank: 125,

          keywordSales: 102
        },
        {
          date: '2024-07-16',
          searchVolume: 1562,
          organicRank: 120,

          keywordSales: 102
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth flossers picks',
      keyword: 'teeth flossers picks',
      searchVolume: 3189,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 40,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-09',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-08',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-07',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-06',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-05',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-04',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-09-02',
          searchVolume: 3030,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-29',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-28',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-27',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-26',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-25',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-24',
          searchVolume: 3449,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-22',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-21',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-20',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-19',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-18',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-16',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-15',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-14',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-13',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-12',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-11',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-10',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-09',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-08',
          searchVolume: 3189,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 229
        },
        {
          date: '2024-08-07',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-06',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-05',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-04',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-03',
          searchVolume: 3189,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-02',
          searchVolume: 2987,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-08-01',
          searchVolume: 2987,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 229
        },
        {
          date: '2024-07-31',
          searchVolume: 2987,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-07-30',
          searchVolume: 2986,
          organicRank: 306,

          keywordSales: 307
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth picks',
      keyword: 'teeth picks',
      searchVolume: 1582,
      organicStartRank: 280,
      organicHighestRank: 66,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1613,
          organicRank: 186,

          keywordSales: 102
        },
        {
          date: '2024-09-09',
          searchVolume: 1613,
          organicRank: 184,

          keywordSales: 102
        },
        {
          date: '2024-09-08',
          searchVolume: 1613,
          organicRank: 189,

          keywordSales: 102
        },
        {
          date: '2024-09-07',
          searchVolume: 1613,
          organicRank: 166,

          keywordSales: 102
        },
        {
          date: '2024-09-06',
          searchVolume: 1613,
          organicRank: 186,

          keywordSales: 102
        },
        {
          date: '2024-09-05',
          searchVolume: 1613,
          organicRank: 66,

          keywordSales: 102
        },
        {
          date: '2024-09-04',
          searchVolume: 1613,
          organicRank: 195,

          keywordSales: 102
        },
        {
          date: '2024-09-02',
          searchVolume: 1613,
          organicRank: 182,

          keywordSales: 102
        },
        {
          date: '2024-08-29',
          searchVolume: 1687,
          organicRank: 185,

          keywordSales: 102
        },
        {
          date: '2024-08-28',
          searchVolume: 1687,
          organicRank: 196,

          keywordSales: 102
        },
        {
          date: '2024-08-27',
          searchVolume: 1687,
          organicRank: 175,

          keywordSales: 102
        },
        {
          date: '2024-08-26',
          searchVolume: 1687,
          organicRank: 211,

          keywordSales: 102
        },
        {
          date: '2024-08-25',
          searchVolume: 1687,
          organicRank: 200,

          keywordSales: 102
        },
        {
          date: '2024-08-24',
          searchVolume: 1687,
          organicRank: 198,

          keywordSales: 102
        },
        {
          date: '2024-08-22',
          searchVolume: 1690,
          organicRank: 180,

          keywordSales: 102
        },
        {
          date: '2024-08-21',
          searchVolume: 1690,
          organicRank: 185,

          keywordSales: 102
        },
        {
          date: '2024-08-20',
          searchVolume: 1690,
          organicRank: 165,

          keywordSales: 102
        },
        {
          date: '2024-08-19',
          searchVolume: 1690,
          organicRank: 197,

          keywordSales: 102
        },
        {
          date: '2024-08-18',
          searchVolume: 1690,
          organicRank: 200,

          keywordSales: 102
        },
        {
          date: '2024-08-16',
          searchVolume: 1377,
          organicRank: 157,

          keywordSales: 102
        },
        {
          date: '2024-08-15',
          searchVolume: 1377,
          organicRank: 190,

          keywordSales: 102
        },
        {
          date: '2024-08-14',
          searchVolume: 1377,
          organicRank: 151,

          keywordSales: 102
        },
        {
          date: '2024-08-13',
          searchVolume: 1377,
          organicRank: 167,

          keywordSales: 102
        },
        {
          date: '2024-08-12',
          searchVolume: 1377,
          organicRank: 140,

          keywordSales: 102
        },
        {
          date: '2024-08-11',
          searchVolume: 1377,
          organicRank: 148,

          keywordSales: 102
        },
        {
          date: '2024-08-10',
          searchVolume: 1377,
          organicRank: 151,

          keywordSales: 102
        },
        {
          date: '2024-08-09',
          searchVolume: 1346,
          organicRank: 164,

          keywordSales: 102
        },
        {
          date: '2024-08-08',
          searchVolume: 1346,
          organicRank: 160,

          keywordSales: 102
        },
        {
          date: '2024-08-07',
          searchVolume: 1346,
          organicRank: 154,

          keywordSales: 102
        },
        {
          date: '2024-08-06',
          searchVolume: 1346,
          organicRank: 165,

          keywordSales: 102
        },
        {
          date: '2024-08-05',
          searchVolume: 1346,
          organicRank: 207,

          keywordSales: 102
        },
        {
          date: '2024-08-04',
          searchVolume: 1346,
          organicRank: 182,

          keywordSales: 102
        },
        {
          date: '2024-08-03',
          searchVolume: 1346,
          organicRank: 190,

          keywordSales: 102
        },
        {
          date: '2024-08-02',
          searchVolume: 1533,
          organicRank: 259,

          keywordSales: 102
        },
        {
          date: '2024-08-01',
          searchVolume: 1533,
          organicRank: 161,

          keywordSales: 102
        },
        {
          date: '2024-07-31',
          searchVolume: 1533,
          organicRank: 154,

          keywordSales: 102
        },
        {
          date: '2024-07-30',
          searchVolume: 1533,
          organicRank: 157,

          keywordSales: 102
        },
        {
          date: '2024-07-29',
          searchVolume: 1533,
          organicRank: 156,

          keywordSales: 102
        },
        {
          date: '2024-07-28',
          searchVolume: 1533,
          organicRank: 155,

          keywordSales: 102
        },
        {
          date: '2024-07-27',
          searchVolume: 1533,
          organicRank: 175,

          keywordSales: 102
        },
        {
          date: '2024-07-26',
          searchVolume: 1582,
          organicRank: 168,

          keywordSales: 102
        },
        {
          date: '2024-07-25',
          searchVolume: 1582,
          organicRank: 229,

          keywordSales: 102
        },
        {
          date: '2024-07-24',
          searchVolume: 1582,
          organicRank: 213,

          keywordSales: 102
        },
        {
          date: '2024-07-23',
          searchVolume: 1582,
          organicRank: 214,

          keywordSales: 102
        },
        {
          date: '2024-07-22',
          searchVolume: 1582,
          organicRank: 220,

          keywordSales: 102
        },
        {
          date: '2024-07-21',
          searchVolume: 1582,
          organicRank: 212,

          keywordSales: 102
        },
        {
          date: '2024-07-20',
          searchVolume: 1582,
          organicRank: 230,

          keywordSales: 102
        },
        {
          date: '2024-07-19',
          searchVolume: 1559,
          organicRank: 239,

          keywordSales: 102
        },
        {
          date: '2024-07-16',
          searchVolume: 1559,
          organicRank: 280,

          keywordSales: 102
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth stain remover',
      keyword: 'teeth stain remover',
      searchVolume: 1793,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-09-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-09',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-08',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-07',
          searchVolume: 1793,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 46
        },
        {
          date: '2024-09-06',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-05',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-04',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-09-02',
          searchVolume: 1793,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-29',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-28',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-27',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-26',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-25',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-24',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-22',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-21',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-20',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-19',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-18',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-16',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-15',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-14',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-13',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-12',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-11',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-10',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-09',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-08',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-07',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-06',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-05',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-04',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-03',
          searchVolume: 1722,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-02',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-08-01',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-07-31',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 46
        },
        {
          date: '2024-07-30',
          searchVolume: 1589,
          organicRank: 306,

          keywordSales: 70
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_teeth water flosser',
      keyword: 'teeth water flosser',
      searchVolume: 1582,
      organicStartRank: 65,
      organicHighestRank: 9,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-24',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 46
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 22,

          keywordSales: 46
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 21,

          keywordSales: 46
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 31,

          keywordSales: 46
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 20,

          keywordSales: 46
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 24,

          keywordSales: 46
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 21,
          sponsoredRank: 11,
          keywordSales: 46
        },
        {
          date: '2024-09-02',
          searchVolume: 1388,
          organicRank: 20,

          keywordSales: 46
        },
        {
          date: '2024-08-29',
          searchVolume: 1432,
          organicRank: 16,

          keywordSales: 46
        },
        {
          date: '2024-08-28',
          searchVolume: 1432,
          organicRank: 13,

          keywordSales: 46
        },
        {
          date: '2024-08-27',
          searchVolume: 1432,
          organicRank: 16,

          keywordSales: 46
        },
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 15,

          keywordSales: 46
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 14,

          keywordSales: 46
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 13,

          keywordSales: 46
        },
        {
          date: '2024-08-22',
          searchVolume: 1661,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-21',
          searchVolume: 1661,
          organicRank: 14,
          sponsoredRank: 15,
          keywordSales: 46
        },
        {
          date: '2024-08-20',
          searchVolume: 1661,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-19',
          searchVolume: 1661,
          organicRank: 10,

          keywordSales: 46
        },
        {
          date: '2024-08-18',
          searchVolume: 1661,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-16',
          searchVolume: 1581,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-15',
          searchVolume: 1581,
          organicRank: 14,

          keywordSales: 46
        },
        {
          date: '2024-08-14',
          searchVolume: 1581,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-13',
          searchVolume: 1581,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-12',
          searchVolume: 1581,
          organicRank: 9,
          sponsoredRank: 7,
          keywordSales: 46
        },
        {
          date: '2024-08-11',
          searchVolume: 1581,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-10',
          searchVolume: 1581,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-09',
          searchVolume: 1135,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-08',
          searchVolume: 1135,
          organicRank: 12,
          sponsoredRank: 10,
          keywordSales: 46
        },
        {
          date: '2024-08-07',
          searchVolume: 1135,
          organicRank: 9,

          keywordSales: 46
        },
        {
          date: '2024-08-06',
          searchVolume: 1135,
          organicRank: 12,
          sponsoredRank: 3,
          keywordSales: 46
        },
        {
          date: '2024-08-05',
          searchVolume: 1135,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-04',
          searchVolume: 1135,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-03',
          searchVolume: 1135,
          organicRank: 12,

          keywordSales: 46
        },
        {
          date: '2024-08-02',
          searchVolume: 880,
          organicRank: 15,

          keywordSales: 46
        },
        {
          date: '2024-08-01',
          searchVolume: 880,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 46
        },
        {
          date: '2024-07-31',
          searchVolume: 880,
          organicRank: 16,

          keywordSales: 46
        },
        {
          date: '2024-07-30',
          searchVolume: 880,
          organicRank: 10,
          sponsoredRank: 9,
          keywordSales: 46
        },
        {
          date: '2024-07-29',
          searchVolume: 880,
          organicRank: 13,

          keywordSales: 46
        },
        {
          date: '2024-07-28',
          searchVolume: 880,
          organicRank: 16,

          keywordSales: 46
        },
        {
          date: '2024-07-27',
          searchVolume: 880,
          organicRank: 13,
          sponsoredRank: 18,
          keywordSales: 46
        },
        {
          date: '2024-07-26',
          searchVolume: 1582,
          organicRank: 13,

          keywordSales: 46
        },
        {
          date: '2024-07-25',
          searchVolume: 1582,
          organicRank: 19,

          keywordSales: 46
        },
        {
          date: '2024-07-24',
          searchVolume: 1582,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-23',
          searchVolume: 1582,
          organicRank: 27,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-22',
          searchVolume: 1582,
          organicRank: 30,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-21',
          searchVolume: 1582,
          organicRank: 28,

          keywordSales: 46
        },
        {
          date: '2024-07-20',
          searchVolume: 1582,
          organicRank: 42,

          keywordSales: 46
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 30,

          keywordSales: 46
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 65,

          keywordSales: 46
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 39,

          keywordSales: 46
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 36,

          keywordSales: 46
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth cleaner',
      keyword: 'tooth cleaner',
      searchVolume: 696,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-09',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-08',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-07',
          searchVolume: 785,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 8
        },
        {
          date: '2024-09-06',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-05',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-04',
          searchVolume: 785,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-09-02',
          searchVolume: 785,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 8
        },
        {
          date: '2024-08-29',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-22',
          searchVolume: 810,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-21',
          searchVolume: 810,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-20',
          searchVolume: 810,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-19',
          searchVolume: 810,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-18',
          searchVolume: 810,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-16',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-15',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-14',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-13',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-12',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-11',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-10',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 8
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-02',
          searchVolume: 817,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-01',
          searchVolume: 817,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-07-31',
          searchVolume: 817,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-07-30',
          searchVolume: 713,
          organicRank: 306,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth floss',
      keyword: 'tooth floss',
      searchVolume: 5517,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 33,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-09',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-08',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-07',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-06',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-05',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-04',
          searchVolume: 5517,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-09-02',
          searchVolume: 5517,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 425
        },
        {
          date: '2024-08-29',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-28',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-27',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-26',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-25',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-24',
          searchVolume: 5953,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-22',
          searchVolume: 5696,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-21',
          searchVolume: 5696,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-20',
          searchVolume: 5696,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-19',
          searchVolume: 5696,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-18',
          searchVolume: 5696,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-16',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-15',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-14',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-13',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-12',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-11',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-10',
          searchVolume: 5161,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-09',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-08',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-07',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-06',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-05',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-04',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-03',
          searchVolume: 5299,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-02',
          searchVolume: 4206,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-08-01',
          searchVolume: 4206,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-07-31',
          searchVolume: 4206,
          organicRank: 306,

          keywordSales: 425
        },
        {
          date: '2024-07-30',
          searchVolume: 4340,
          organicRank: 306,

          keywordSales: 454
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth flossers',
      keyword: 'tooth flossers',
      searchVolume: 2245,
      organicStartRank: 270,
      organicHighestRank: 25,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2355,
          organicRank: 68,

          keywordSales: 168
        },
        {
          date: '2024-09-09',
          searchVolume: 2355,
          organicRank: 71,

          keywordSales: 168
        },
        {
          date: '2024-09-08',
          searchVolume: 2355,
          organicRank: 67,

          keywordSales: 168
        },
        {
          date: '2024-09-07',
          searchVolume: 2355,
          organicRank: 67,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-09-06',
          searchVolume: 2355,
          organicRank: 58,

          keywordSales: 168
        },
        {
          date: '2024-09-05',
          searchVolume: 2355,
          organicRank: 62,

          keywordSales: 168
        },
        {
          date: '2024-09-04',
          searchVolume: 2355,
          organicRank: 27,

          keywordSales: 168
        },
        {
          date: '2024-09-02',
          searchVolume: 2355,
          organicRank: 25,

          keywordSales: 168
        },
        {
          date: '2024-08-29',
          searchVolume: 2394,
          organicRank: 52,

          keywordSales: 168
        },
        {
          date: '2024-08-28',
          searchVolume: 2394,
          organicRank: 57,

          keywordSales: 168
        },
        {
          date: '2024-08-27',
          searchVolume: 2394,
          organicRank: 50,

          keywordSales: 168
        },
        {
          date: '2024-08-26',
          searchVolume: 2394,
          organicRank: 56,

          keywordSales: 168
        },
        {
          date: '2024-08-25',
          searchVolume: 2394,
          organicRank: 49,

          keywordSales: 168
        },
        {
          date: '2024-08-24',
          searchVolume: 2394,
          organicRank: 49,

          keywordSales: 168
        },
        {
          date: '2024-08-22',
          searchVolume: 2396,
          organicRank: 55,

          keywordSales: 168
        },
        {
          date: '2024-08-21',
          searchVolume: 2396,
          organicRank: 54,
          sponsoredRank: 17,
          keywordSales: 168
        },
        {
          date: '2024-08-20',
          searchVolume: 2396,
          organicRank: 27,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-19',
          searchVolume: 2396,
          organicRank: 43,
          sponsoredRank: 44,
          keywordSales: 168
        },
        {
          date: '2024-08-18',
          searchVolume: 2396,
          organicRank: 47,

          keywordSales: 168
        },
        {
          date: '2024-08-16',
          searchVolume: 2147,
          organicRank: 47,
          sponsoredRank: 48,
          keywordSales: 168
        },
        {
          date: '2024-08-15',
          searchVolume: 2147,
          organicRank: 43,

          keywordSales: 168
        },
        {
          date: '2024-08-14',
          searchVolume: 2147,
          organicRank: 44,
          sponsoredRank: 29,
          keywordSales: 168
        },
        {
          date: '2024-08-13',
          searchVolume: 2147,
          organicRank: 49,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-12',
          searchVolume: 2147,
          organicRank: 45,
          sponsoredRank: 8,
          keywordSales: 168
        },
        {
          date: '2024-08-11',
          searchVolume: 2147,
          organicRank: 44,
          sponsoredRank: 7,
          keywordSales: 168
        },
        {
          date: '2024-08-10',
          searchVolume: 2147,
          organicRank: 45,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-09',
          searchVolume: 1803,
          organicRank: 53,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-08',
          searchVolume: 1803,
          organicRank: 45,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-07',
          searchVolume: 1803,
          organicRank: 60,
          sponsoredRank: 17,
          keywordSales: 168
        },
        {
          date: '2024-08-06',
          searchVolume: 1803,
          organicRank: 61,

          keywordSales: 168
        },
        {
          date: '2024-08-05',
          searchVolume: 1803,
          organicRank: 70,

          keywordSales: 168
        },
        {
          date: '2024-08-04',
          searchVolume: 1803,
          organicRank: 64,
          sponsoredRank: 1,
          keywordSales: 168
        },
        {
          date: '2024-08-03',
          searchVolume: 1803,
          organicRank: 62,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-08-02',
          searchVolume: 2207,
          organicRank: 61,

          keywordSales: 168
        },
        {
          date: '2024-08-01',
          searchVolume: 2207,
          organicRank: 67,
          sponsoredRank: 13,
          keywordSales: 168
        },
        {
          date: '2024-07-31',
          searchVolume: 2207,
          organicRank: 81,

          keywordSales: 168
        },
        {
          date: '2024-07-30',
          searchVolume: 2207,
          organicRank: 74,
          sponsoredRank: 1,
          keywordSales: 168
        },
        {
          date: '2024-07-29',
          searchVolume: 2207,
          organicRank: 74,
          sponsoredRank: 38,
          keywordSales: 168
        },
        {
          date: '2024-07-28',
          searchVolume: 2207,
          organicRank: 81,

          keywordSales: 168
        },
        {
          date: '2024-07-27',
          searchVolume: 2207,
          organicRank: 86,
          sponsoredRank: 1,
          keywordSales: 168
        },
        {
          date: '2024-07-26',
          searchVolume: 2245,
          organicRank: 129,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-07-25',
          searchVolume: 2245,
          organicRank: 103,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-07-24',
          searchVolume: 2245,
          organicRank: 99,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-07-23',
          searchVolume: 2245,
          organicRank: 103,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-07-22',
          searchVolume: 2245,
          organicRank: 116,
          sponsoredRank: 2,
          keywordSales: 168
        },
        {
          date: '2024-07-21',
          searchVolume: 2245,
          organicRank: 177,

          keywordSales: 168
        },
        {
          date: '2024-07-20',
          searchVolume: 2245,
          organicRank: 174,

          keywordSales: 168
        },
        {
          date: '2024-07-19',
          searchVolume: 2038,
          organicRank: 139,

          keywordSales: 168
        },
        {
          date: '2024-07-18',
          searchVolume: 2038,
          organicRank: 270,

          keywordSales: 168
        },
        {
          date: '2024-07-17',
          searchVolume: 2038,
          organicRank: 281,

          keywordSales: 168
        },
        {
          date: '2024-07-16',
          searchVolume: 2038,
          organicRank: 171,

          keywordSales: 168
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth flossers for adults',
      keyword: 'tooth flossers for adults',
      searchVolume: 1288,
      organicStartRank: 86,
      organicHighestRank: 22,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1196,
          organicRank: 60,

          keywordSales: 61
        },
        {
          date: '2024-09-09',
          searchVolume: 1196,
          organicRank: 64,

          keywordSales: 61
        },
        {
          date: '2024-09-08',
          searchVolume: 1196,
          organicRank: 62,
          sponsoredRank: 1,
          keywordSales: 61
        },
        {
          date: '2024-09-07',
          searchVolume: 1196,
          organicRank: 59,

          keywordSales: 61
        },
        {
          date: '2024-09-06',
          searchVolume: 1196,
          organicRank: 57,

          keywordSales: 61
        },
        {
          date: '2024-09-05',
          searchVolume: 1196,
          organicRank: 62,

          keywordSales: 61
        },
        {
          date: '2024-09-04',
          searchVolume: 1196,
          organicRank: 26,

          keywordSales: 61
        },
        {
          date: '2024-09-01',
          searchVolume: 1196,
          organicRank: 56,

          keywordSales: 61
        },
        {
          date: '2024-08-28',
          searchVolume: 1349,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-08-27',
          searchVolume: 1349,
          organicRank: 48,

          keywordSales: 61
        },
        {
          date: '2024-08-26',
          searchVolume: 1349,
          organicRank: 48,

          keywordSales: 61
        },
        {
          date: '2024-08-25',
          searchVolume: 1349,
          organicRank: 49,
          sponsoredRank: 12,
          keywordSales: 61
        },
        {
          date: '2024-08-24',
          searchVolume: 1349,
          organicRank: 52,

          keywordSales: 61
        },
        {
          date: '2024-08-23',
          searchVolume: 1352,
          organicRank: 50,

          keywordSales: 61
        },
        {
          date: '2024-08-22',
          searchVolume: 1352,
          organicRank: 45,

          keywordSales: 61
        },
        {
          date: '2024-08-21',
          searchVolume: 1352,
          organicRank: 50,

          keywordSales: 61
        },
        {
          date: '2024-08-20',
          searchVolume: 1352,
          organicRank: 49,

          keywordSales: 61
        },
        {
          date: '2024-08-19',
          searchVolume: 1352,
          organicRank: 50,

          keywordSales: 61
        },
        {
          date: '2024-08-17',
          searchVolume: 1352,
          organicRank: 52,

          keywordSales: 61
        },
        {
          date: '2024-08-16',
          searchVolume: 1101,
          organicRank: 43,

          keywordSales: 61
        },
        {
          date: '2024-08-15',
          searchVolume: 1101,
          organicRank: 50,

          keywordSales: 61
        },
        {
          date: '2024-08-14',
          searchVolume: 1101,
          organicRank: 42,

          keywordSales: 61
        },
        {
          date: '2024-08-13',
          searchVolume: 1101,
          organicRank: 32,

          keywordSales: 61
        },
        {
          date: '2024-08-12',
          searchVolume: 1101,
          organicRank: 50,

          keywordSales: 61
        },
        {
          date: '2024-08-11',
          searchVolume: 1101,
          organicRank: 48,

          keywordSales: 61
        },
        {
          date: '2024-08-10',
          searchVolume: 1101,
          organicRank: 42,

          keywordSales: 61
        },
        {
          date: '2024-08-09',
          searchVolume: 826,
          organicRank: 39,

          keywordSales: 61
        },
        {
          date: '2024-08-08',
          searchVolume: 826,
          organicRank: 36,

          keywordSales: 61
        },
        {
          date: '2024-08-07',
          searchVolume: 826,
          organicRank: 36,

          keywordSales: 61
        },
        {
          date: '2024-08-06',
          searchVolume: 826,
          organicRank: 46,

          keywordSales: 61
        },
        {
          date: '2024-08-05',
          searchVolume: 826,
          organicRank: 48,

          keywordSales: 61
        },
        {
          date: '2024-08-04',
          searchVolume: 826,
          organicRank: 38,

          keywordSales: 61
        },
        {
          date: '2024-08-03',
          searchVolume: 826,
          organicRank: 32,

          keywordSales: 61
        },
        {
          date: '2024-08-02',
          searchVolume: 1125,
          organicRank: 37,

          keywordSales: 61
        },
        {
          date: '2024-08-01',
          searchVolume: 1125,
          organicRank: 42,
          sponsoredRank: 3,
          keywordSales: 61
        },
        {
          date: '2024-07-31',
          searchVolume: 1125,
          organicRank: 46,

          keywordSales: 61
        },
        {
          date: '2024-07-30',
          searchVolume: 1125,
          organicRank: 44,

          keywordSales: 61
        },
        {
          date: '2024-07-29',
          searchVolume: 1125,
          organicRank: 48,

          keywordSales: 61
        },
        {
          date: '2024-07-28',
          searchVolume: 1125,
          organicRank: 51,

          keywordSales: 61
        },
        {
          date: '2024-07-27',
          searchVolume: 1125,
          organicRank: 41,

          keywordSales: 61
        },
        {
          date: '2024-07-26',
          searchVolume: 1288,
          organicRank: 54,

          keywordSales: 61
        },
        {
          date: '2024-07-25',
          searchVolume: 1288,
          organicRank: 53,

          keywordSales: 61
        },
        {
          date: '2024-07-24',
          searchVolume: 1288,
          organicRank: 52,

          keywordSales: 61
        },
        {
          date: '2024-07-23',
          searchVolume: 1288,
          organicRank: 55,

          keywordSales: 61
        },
        {
          date: '2024-07-22',
          searchVolume: 1288,
          organicRank: 59,

          keywordSales: 61
        },
        {
          date: '2024-07-21',
          searchVolume: 1288,
          organicRank: 62,

          keywordSales: 61
        },
        {
          date: '2024-07-20',
          searchVolume: 1288,
          organicRank: 76,

          keywordSales: 61
        },
        {
          date: '2024-07-19',
          searchVolume: 1146,
          organicRank: 69,

          keywordSales: 61
        },
        {
          date: '2024-07-18',
          searchVolume: 1146,
          organicRank: 86,

          keywordSales: 61
        },
        {
          date: '2024-07-17',
          searchVolume: 1146,
          organicRank: 84,

          keywordSales: 61
        },
        {
          date: '2024-07-16',
          searchVolume: 1146,
          organicRank: 77,

          keywordSales: 61
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth picks',
      keyword: 'tooth picks',
      searchVolume: 6445,
      organicStartRank: 229,
      organicHighestRank: 175,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 8221,
          organicRank: 226,

          keywordSales: 605
        },
        {
          date: '2024-09-09',
          searchVolume: 8221,
          organicRank: 212,

          keywordSales: 605
        },
        {
          date: '2024-09-08',
          searchVolume: 8221,
          organicRank: 239,

          keywordSales: 605
        },
        {
          date: '2024-09-07',
          searchVolume: 8221,
          organicRank: 231,

          keywordSales: 605
        },
        {
          date: '2024-09-06',
          searchVolume: 8221,
          organicRank: 238,

          keywordSales: 605
        },
        {
          date: '2024-09-05',
          searchVolume: 8221,
          organicRank: 230,

          keywordSales: 605
        },
        {
          date: '2024-09-04',
          searchVolume: 8221,
          organicRank: 239,

          keywordSales: 605
        },
        {
          date: '2024-09-03',
          searchVolume: 8221,
          organicRank: 221,

          keywordSales: 605
        },
        {
          date: '2024-09-02',
          searchVolume: 8221,
          organicRank: 236,

          keywordSales: 605
        },
        {
          date: '2024-08-30',
          searchVolume: 8480,
          organicRank: 202,

          keywordSales: 605
        },
        {
          date: '2024-08-28',
          searchVolume: 8480,
          organicRank: 199,

          keywordSales: 605
        },
        {
          date: '2024-08-27',
          searchVolume: 8480,
          organicRank: 204,

          keywordSales: 605
        },
        {
          date: '2024-08-26',
          searchVolume: 8480,
          organicRank: 206,

          keywordSales: 605
        },
        {
          date: '2024-08-25',
          searchVolume: 8480,
          organicRank: 210,

          keywordSales: 605
        },
        {
          date: '2024-08-24',
          searchVolume: 8480,
          organicRank: 272,

          keywordSales: 605
        },
        {
          date: '2024-08-23',
          searchVolume: 7713,
          organicRank: 194,

          keywordSales: 605
        },
        {
          date: '2024-08-22',
          searchVolume: 7713,
          organicRank: 205,
          sponsoredRank: 11,
          keywordSales: 605
        },
        {
          date: '2024-08-20',
          searchVolume: 7713,
          organicRank: 190,

          keywordSales: 605
        },
        {
          date: '2024-08-19',
          searchVolume: 7713,
          organicRank: 175,

          keywordSales: 605
        },
        {
          date: '2024-08-16',
          searchVolume: 7355,
          organicRank: 186,

          keywordSales: 605
        },
        {
          date: '2024-08-15',
          searchVolume: 7355,
          organicRank: 209,

          keywordSales: 605
        },
        {
          date: '2024-08-14',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-08-13',
          searchVolume: 7355,
          organicRank: 214,

          keywordSales: 605
        },
        {
          date: '2024-08-12',
          searchVolume: 7355,
          organicRank: 184,

          keywordSales: 605
        },
        {
          date: '2024-08-11',
          searchVolume: 7355,
          organicRank: 191,

          keywordSales: 605
        },
        {
          date: '2024-08-10',
          searchVolume: 7355,
          organicRank: 197,

          keywordSales: 605
        },
        {
          date: '2024-08-09',
          searchVolume: 7154,
          organicRank: 279,

          keywordSales: 605
        },
        {
          date: '2024-08-08',
          searchVolume: 7154,
          organicRank: 215,

          keywordSales: 605
        },
        {
          date: '2024-08-07',
          searchVolume: 7154,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-08-06',
          searchVolume: 7154,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-08-05',
          searchVolume: 7154,
          organicRank: 289,

          keywordSales: 605
        },
        {
          date: '2024-08-04',
          searchVolume: 7154,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-08-03',
          searchVolume: 7154,
          organicRank: 290,

          keywordSales: 605
        },
        {
          date: '2024-08-02',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-08-01',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 605
        },
        {
          date: '2024-07-31',
          searchVolume: 7830,
          organicRank: 279,

          keywordSales: 605
        },
        {
          date: '2024-07-30',
          searchVolume: 7830,
          organicRank: 206,

          keywordSales: 605
        },
        {
          date: '2024-07-29',
          searchVolume: 7830,
          organicRank: 207,

          keywordSales: 605
        },
        {
          date: '2024-07-28',
          searchVolume: 7830,
          organicRank: 207,

          keywordSales: 605
        },
        {
          date: '2024-07-27',
          searchVolume: 7830,
          organicRank: 194,

          keywordSales: 605
        },
        {
          date: '2024-07-26',
          searchVolume: 6445,
          organicRank: 199,

          keywordSales: 605
        },
        {
          date: '2024-07-24',
          searchVolume: 6445,
          organicRank: 229,

          keywordSales: 605
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth picks flossers',
      keyword: 'tooth picks flossers',
      searchVolume: 11235,
      organicStartRank: 306,
      organicHighestRank: 134,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 29,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 16118,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-09-09',
          searchVolume: 16118,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-09-08',
          searchVolume: 16118,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-09-07',
          searchVolume: 16118,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 1258
        },
        {
          date: '2024-09-05',
          searchVolume: 16118,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-28',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-27',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-26',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-25',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-24',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-23',
          searchVolume: 16620,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-22',
          searchVolume: 16620,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-21',
          searchVolume: 16620,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-20',
          searchVolume: 16620,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-19',
          searchVolume: 16620,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-16',
          searchVolume: 15855,
          organicRank: 164,

          keywordSales: 1258
        },
        {
          date: '2024-08-15',
          searchVolume: 15855,
          organicRank: 306,

          keywordSales: 1258
        },
        {
          date: '2024-08-14',
          searchVolume: 15855,
          organicRank: 157,

          keywordSales: 1258
        },
        {
          date: '2024-08-13',
          searchVolume: 15855,
          organicRank: 147,

          keywordSales: 1258
        },
        {
          date: '2024-08-12',
          searchVolume: 15855,
          organicRank: 154,

          keywordSales: 1258
        },
        {
          date: '2024-08-11',
          searchVolume: 15855,
          organicRank: 162,

          keywordSales: 1258
        },
        {
          date: '2024-08-10',
          searchVolume: 15855,
          organicRank: 149,

          keywordSales: 1258
        },
        {
          date: '2024-08-09',
          searchVolume: 15481,
          organicRank: 150,

          keywordSales: 1258
        },
        {
          date: '2024-08-08',
          searchVolume: 15481,
          organicRank: 154,

          keywordSales: 1258
        },
        {
          date: '2024-08-07',
          searchVolume: 15481,
          organicRank: 134,

          keywordSales: 1258
        },
        {
          date: '2024-08-06',
          searchVolume: 15481,
          organicRank: 151,

          keywordSales: 1258
        },
        {
          date: '2024-08-05',
          searchVolume: 15481,
          organicRank: 159,

          keywordSales: 1258
        },
        {
          date: '2024-08-04',
          searchVolume: 15481,
          organicRank: 136,

          keywordSales: 1258
        },
        {
          date: '2024-08-02',
          searchVolume: 14490,
          organicRank: 164,

          keywordSales: 1258
        },
        {
          date: '2024-08-01',
          searchVolume: 14490,
          organicRank: 165,

          keywordSales: 1258
        },
        {
          date: '2024-07-31',
          searchVolume: 14490,
          organicRank: 162,

          keywordSales: 1258
        },
        {
          date: '2024-07-30',
          searchVolume: 11235,
          organicRank: 306,

          keywordSales: 1321
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth picks flossers with bristles',
      keyword: 'tooth picks flossers with bristles',
      searchVolume: 1544,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 23,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-09',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-08',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-07',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-06',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-05',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-04',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-09-02',
          searchVolume: 1791,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-29',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-28',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-27',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-26',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-25',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-24',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-22',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-21',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-20',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-19',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-18',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-16',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-15',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-14',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-13',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-12',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-11',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-10',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-09',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-08',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-07',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-06',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-05',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-04',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-03',
          searchVolume: 1544,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-02',
          searchVolume: 1555,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-08-01',
          searchVolume: 1555,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 113
        },
        {
          date: '2024-07-31',
          searchVolume: 1555,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-07-30',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 63
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_tooth water flosser',
      keyword: 'tooth water flosser',
      searchVolume: 1367,
      organicStartRank: 44,
      organicHighestRank: 8,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-26',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1367,
          organicRank: 23,
          sponsoredRank: 3,
          keywordSales: 47
        },
        {
          date: '2024-09-09',
          searchVolume: 1367,
          organicRank: 29,

          keywordSales: 47
        },
        {
          date: '2024-09-08',
          searchVolume: 1367,
          organicRank: 37,

          keywordSales: 47
        },
        {
          date: '2024-09-07',
          searchVolume: 1367,
          organicRank: 33,
          sponsoredRank: 9,
          keywordSales: 47
        },
        {
          date: '2024-09-06',
          searchVolume: 1367,
          organicRank: 29,

          keywordSales: 47
        },
        {
          date: '2024-09-05',
          searchVolume: 1367,
          organicRank: 30,

          keywordSales: 47
        },
        {
          date: '2024-09-04',
          searchVolume: 1367,
          organicRank: 31,
          sponsoredRank: 7,
          keywordSales: 47
        },
        {
          date: '2024-09-02',
          searchVolume: 1367,
          organicRank: 29,

          keywordSales: 47
        },
        {
          date: '2024-08-29',
          searchVolume: 1351,
          organicRank: 27,

          keywordSales: 47
        },
        {
          date: '2024-08-28',
          searchVolume: 1351,
          organicRank: 22,

          keywordSales: 47
        },
        {
          date: '2024-08-27',
          searchVolume: 1351,
          organicRank: 13,

          keywordSales: 47
        },
        {
          date: '2024-08-26',
          searchVolume: 1351,
          organicRank: 16,

          keywordSales: 47
        },
        {
          date: '2024-08-25',
          searchVolume: 1351,
          organicRank: 18,

          keywordSales: 47
        },
        {
          date: '2024-08-24',
          searchVolume: 1351,
          organicRank: 17,

          keywordSales: 47
        },
        {
          date: '2024-08-22',
          searchVolume: 1432,
          organicRank: 16,

          keywordSales: 47
        },
        {
          date: '2024-08-21',
          searchVolume: 1432,
          organicRank: 17,
          sponsoredRank: 11,
          keywordSales: 47
        },
        {
          date: '2024-08-20',
          searchVolume: 1432,
          organicRank: 20,
          sponsoredRank: 8,
          keywordSales: 47
        },
        {
          date: '2024-08-19',
          searchVolume: 1432,
          organicRank: 8,

          keywordSales: 47
        },
        {
          date: '2024-08-18',
          searchVolume: 1432,
          organicRank: 17,

          keywordSales: 47
        },
        {
          date: '2024-08-16',
          searchVolume: 1366,
          organicRank: 19,

          keywordSales: 47
        },
        {
          date: '2024-08-15',
          searchVolume: 1366,
          organicRank: 19,

          keywordSales: 47
        },
        {
          date: '2024-08-14',
          searchVolume: 1366,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-08-13',
          searchVolume: 1366,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-08-12',
          searchVolume: 1366,
          organicRank: 18,

          keywordSales: 47
        },
        {
          date: '2024-08-11',
          searchVolume: 1366,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-08-10',
          searchVolume: 1366,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-08-09',
          searchVolume: 888,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-08-08',
          searchVolume: 888,
          organicRank: 11,
          sponsoredRank: 11,
          keywordSales: 47
        },
        {
          date: '2024-08-07',
          searchVolume: 888,
          organicRank: 13,

          keywordSales: 47
        },
        {
          date: '2024-08-06',
          searchVolume: 888,
          organicRank: 14,

          keywordSales: 47
        },
        {
          date: '2024-08-05',
          searchVolume: 888,
          organicRank: 17,
          sponsoredRank: 13,
          keywordSales: 47
        },
        {
          date: '2024-08-04',
          searchVolume: 888,
          organicRank: 15,
          sponsoredRank: 4,
          keywordSales: 47
        },
        {
          date: '2024-08-03',
          searchVolume: 888,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 47
        },
        {
          date: '2024-08-02',
          searchVolume: 878,
          organicRank: 17,

          keywordSales: 47
        },
        {
          date: '2024-08-01',
          searchVolume: 878,
          organicRank: 13,
          sponsoredRank: 5,
          keywordSales: 47
        },
        {
          date: '2024-07-31',
          searchVolume: 878,
          organicRank: 19,

          keywordSales: 47
        },
        {
          date: '2024-07-30',
          searchVolume: 878,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-29',
          searchVolume: 878,
          organicRank: 18,

          keywordSales: 47
        },
        {
          date: '2024-07-28',
          searchVolume: 878,
          organicRank: 16,

          keywordSales: 47
        },
        {
          date: '2024-07-27',
          searchVolume: 878,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-26',
          searchVolume: 1367,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-25',
          searchVolume: 1367,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-24',
          searchVolume: 1367,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 24,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-22',
          searchVolume: 1367,
          organicRank: 27,
          sponsoredRank: 1,
          keywordSales: 47
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 28,

          keywordSales: 47
        },
        {
          date: '2024-07-20',
          searchVolume: 1367,
          organicRank: 30,

          keywordSales: 47
        },
        {
          date: '2024-07-19',
          searchVolume: 1144,
          organicRank: 31,

          keywordSales: 47
        },
        {
          date: '2024-07-18',
          searchVolume: 1144,
          organicRank: 44,

          keywordSales: 47
        },
        {
          date: '2024-07-17',
          searchVolume: 1144,
          organicRank: 43,

          keywordSales: 47
        },
        {
          date: '2024-07-16',
          searchVolume: 1144,
          organicRank: 42,

          keywordSales: 47
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_toothbrush and water flosser combo',
      keyword: 'toothbrush and water flosser combo',
      searchVolume: 1832,
      organicStartRank: 59,
      organicHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 724,
          organicRank: 46,

          keywordSales: 18
        },
        {
          date: '2024-09-09',
          searchVolume: 724,
          organicRank: 47,

          keywordSales: 18
        },
        {
          date: '2024-09-08',
          searchVolume: 724,
          organicRank: 46,

          keywordSales: 18
        },
        {
          date: '2024-09-07',
          searchVolume: 724,
          organicRank: 49,

          keywordSales: 18
        },
        {
          date: '2024-09-06',
          searchVolume: 724,
          organicRank: 46,

          keywordSales: 18
        },
        {
          date: '2024-09-05',
          searchVolume: 724,
          organicRank: 46,

          keywordSales: 18
        },
        {
          date: '2024-09-04',
          searchVolume: 724,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-09-01',
          searchVolume: 724,
          organicRank: 42,

          keywordSales: 18
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 40,

          keywordSales: 18
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 38,

          keywordSales: 18
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 37,

          keywordSales: 18
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 30,

          keywordSales: 18
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 31,

          keywordSales: 18
        },
        {
          date: '2024-08-23',
          searchVolume: 747,
          organicRank: 30,

          keywordSales: 18
        },
        {
          date: '2024-08-22',
          searchVolume: 747,
          organicRank: 26,

          keywordSales: 18
        },
        {
          date: '2024-08-21',
          searchVolume: 747,
          organicRank: 37,

          keywordSales: 18
        },
        {
          date: '2024-08-20',
          searchVolume: 747,
          organicRank: 31,

          keywordSales: 18
        },
        {
          date: '2024-08-19',
          searchVolume: 747,
          organicRank: 41,

          keywordSales: 18
        },
        {
          date: '2024-08-17',
          searchVolume: 747,
          organicRank: 40,

          keywordSales: 18
        },
        {
          date: '2024-08-16',
          searchVolume: 644,
          organicRank: 35,

          keywordSales: 18
        },
        {
          date: '2024-08-15',
          searchVolume: 644,
          organicRank: 29,

          keywordSales: 18
        },
        {
          date: '2024-08-14',
          searchVolume: 644,
          organicRank: 27,

          keywordSales: 18
        },
        {
          date: '2024-08-13',
          searchVolume: 644,
          organicRank: 31,

          keywordSales: 18
        },
        {
          date: '2024-08-12',
          searchVolume: 644,
          organicRank: 29,

          keywordSales: 18
        },
        {
          date: '2024-08-11',
          searchVolume: 644,
          organicRank: 45,

          keywordSales: 18
        },
        {
          date: '2024-08-10',
          searchVolume: 644,
          organicRank: 35,

          keywordSales: 18
        },
        {
          date: '2024-08-09',
          searchVolume: 627,
          organicRank: 52,

          keywordSales: 18
        },
        {
          date: '2024-08-08',
          searchVolume: 627,
          organicRank: 33,

          keywordSales: 18
        },
        {
          date: '2024-08-07',
          searchVolume: 627,
          organicRank: 25,

          keywordSales: 18
        },
        {
          date: '2024-08-06',
          searchVolume: 627,
          organicRank: 34,

          keywordSales: 18
        },
        {
          date: '2024-08-05',
          searchVolume: 627,
          organicRank: 25,

          keywordSales: 18
        },
        {
          date: '2024-08-04',
          searchVolume: 627,
          organicRank: 34,

          keywordSales: 18
        },
        {
          date: '2024-08-03',
          searchVolume: 627,
          organicRank: 38,

          keywordSales: 18
        },
        {
          date: '2024-08-02',
          searchVolume: 748,
          organicRank: 37,

          keywordSales: 18
        },
        {
          date: '2024-08-01',
          searchVolume: 748,
          organicRank: 33,

          keywordSales: 18
        },
        {
          date: '2024-07-31',
          searchVolume: 748,
          organicRank: 39,

          keywordSales: 18
        },
        {
          date: '2024-07-30',
          searchVolume: 748,
          organicRank: 25,

          keywordSales: 18
        },
        {
          date: '2024-07-29',
          searchVolume: 748,
          organicRank: 26,

          keywordSales: 18
        },
        {
          date: '2024-07-28',
          searchVolume: 748,
          organicRank: 34,

          keywordSales: 18
        },
        {
          date: '2024-07-27',
          searchVolume: 748,
          organicRank: 30,

          keywordSales: 18
        },
        {
          date: '2024-07-26',
          searchVolume: 1832,
          organicRank: 35,

          keywordSales: 18
        },
        {
          date: '2024-07-25',
          searchVolume: 1832,
          organicRank: 32,

          keywordSales: 18
        },
        {
          date: '2024-07-24',
          searchVolume: 1832,
          organicRank: 36,

          keywordSales: 18
        },
        {
          date: '2024-07-23',
          searchVolume: 1832,
          organicRank: 37,

          keywordSales: 18
        },
        {
          date: '2024-07-22',
          searchVolume: 1832,
          organicRank: 38,

          keywordSales: 18
        },
        {
          date: '2024-07-21',
          searchVolume: 1832,
          organicRank: 44,

          keywordSales: 18
        },
        {
          date: '2024-07-20',
          searchVolume: 1832,
          organicRank: 40,

          keywordSales: 18
        },
        {
          date: '2024-07-19',
          searchVolume: 701,
          organicRank: 50,

          keywordSales: 18
        },
        {
          date: '2024-07-18',
          searchVolume: 701,
          organicRank: 59,

          keywordSales: 18
        },
        {
          date: '2024-07-17',
          searchVolume: 701,
          organicRank: 58,

          keywordSales: 18
        },
        {
          date: '2024-07-16',
          searchVolume: 701,
          organicRank: 54,

          keywordSales: 18
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_travel size waterpik',
      keyword: 'travel size waterpik',
      searchVolume: 1366,
      organicStartRank: 6,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-09-09',
          searchVolume: 1365,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-09-08',
          searchVolume: 1365,
          organicRank: 3,
          sponsoredRank: 6,
          keywordSales: 40
        },
        {
          date: '2024-09-07',
          searchVolume: 1365,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-09-06',
          searchVolume: 1365,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 40
        },
        {
          date: '2024-09-05',
          searchVolume: 1365,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-09-04',
          searchVolume: 1365,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-09-01',
          searchVolume: 1365,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-08-28',
          searchVolume: 1432,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-08-27',
          searchVolume: 1432,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 40
        },
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 40
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-08-23',
          searchVolume: 1430,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-22',
          searchVolume: 1430,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-21',
          searchVolume: 1430,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-20',
          searchVolume: 1430,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-19',
          searchVolume: 1430,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-08-17',
          searchVolume: 1430,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 1,

          keywordSales: 40
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-09',
          searchVolume: 1333,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-08',
          searchVolume: 1333,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-07',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 40
        },
        {
          date: '2024-08-06',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 40
        },
        {
          date: '2024-08-05',
          searchVolume: 1333,
          organicRank: 2,

          keywordSales: 40
        },
        {
          date: '2024-08-04',
          searchVolume: 1333,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-03',
          searchVolume: 1333,
          organicRank: 2,

          keywordSales: 40
        },
        {
          date: '2024-08-02',
          searchVolume: 1247,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-08-01',
          searchVolume: 1247,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 40
        },
        {
          date: '2024-07-31',
          searchVolume: 1247,
          organicRank: 2,

          keywordSales: 40
        },
        {
          date: '2024-07-30',
          searchVolume: 1247,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-07-29',
          searchVolume: 1247,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 40
        },
        {
          date: '2024-07-28',
          searchVolume: 1247,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-07-27',
          searchVolume: 1247,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 40
        },
        {
          date: '2024-07-26',
          searchVolume: 1366,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-07-25',
          searchVolume: 1366,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-07-24',
          searchVolume: 1366,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-07-23',
          searchVolume: 1366,
          organicRank: 3,

          keywordSales: 40
        },
        {
          date: '2024-07-22',
          searchVolume: 1366,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-07-21',
          searchVolume: 1366,
          organicRank: 4,

          keywordSales: 40
        },
        {
          date: '2024-07-20',
          searchVolume: 1366,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 40
        },
        {
          date: '2024-07-19',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 40
        },
        {
          date: '2024-07-18',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 40
        },
        {
          date: '2024-07-17',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 40
        },
        {
          date: '2024-07-16',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 40
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_travel water flosser',
      keyword: 'travel water flosser',
      searchVolume: 6447,
      organicStartRank: 35,
      organicHighestRank: 7,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4758,
          organicRank: 16,

          keywordSales: 166
        },
        {
          date: '2024-09-09',
          searchVolume: 4758,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-09-08',
          searchVolume: 4758,
          organicRank: 21,

          keywordSales: 166
        },
        {
          date: '2024-09-07',
          searchVolume: 4758,
          organicRank: 23,

          keywordSales: 166
        },
        {
          date: '2024-09-06',
          searchVolume: 4758,
          organicRank: 19,

          keywordSales: 166
        },
        {
          date: '2024-09-04',
          searchVolume: 4758,
          organicRank: 21,

          keywordSales: 166
        },
        {
          date: '2024-09-03',
          searchVolume: 4758,
          organicRank: 22,

          keywordSales: 166
        },
        {
          date: '2024-09-01',
          searchVolume: 4758,
          organicRank: 22,

          keywordSales: 166
        },
        {
          date: '2024-08-28',
          searchVolume: 4918,
          organicRank: 24,

          keywordSales: 166
        },
        {
          date: '2024-08-27',
          searchVolume: 4918,
          organicRank: 13,

          keywordSales: 166
        },
        {
          date: '2024-08-26',
          searchVolume: 4918,
          organicRank: 7,

          keywordSales: 166
        },
        {
          date: '2024-08-25',
          searchVolume: 4918,
          organicRank: 18,

          keywordSales: 166
        },
        {
          date: '2024-08-24',
          searchVolume: 4918,
          organicRank: 12,

          keywordSales: 166
        },
        {
          date: '2024-08-23',
          searchVolume: 5143,
          organicRank: 17,

          keywordSales: 166
        },
        {
          date: '2024-08-22',
          searchVolume: 5143,
          organicRank: 12,
          sponsoredRank: 5,
          keywordSales: 166
        },
        {
          date: '2024-08-21',
          searchVolume: 5143,
          organicRank: 14,

          keywordSales: 166
        },
        {
          date: '2024-08-20',
          searchVolume: 5143,
          organicRank: 12,

          keywordSales: 166
        },
        {
          date: '2024-08-19',
          searchVolume: 5143,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 166
        },
        {
          date: '2024-08-17',
          searchVolume: 5143,
          organicRank: 14,

          keywordSales: 166
        },
        {
          date: '2024-08-15',
          searchVolume: 4346,
          organicRank: 13,
          sponsoredRank: 35,
          keywordSales: 166
        },
        {
          date: '2024-08-14',
          searchVolume: 4346,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-08-13',
          searchVolume: 4346,
          organicRank: 8,
          sponsoredRank: 6,
          keywordSales: 166
        },
        {
          date: '2024-08-12',
          searchVolume: 4346,
          organicRank: 16,
          sponsoredRank: 12,
          keywordSales: 166
        },
        {
          date: '2024-08-11',
          searchVolume: 4346,
          organicRank: 17,
          sponsoredRank: 24,
          keywordSales: 166
        },
        {
          date: '2024-08-10',
          searchVolume: 4346,
          organicRank: 12,
          sponsoredRank: 9,
          keywordSales: 166
        },
        {
          date: '2024-08-09',
          searchVolume: 4237,
          organicRank: 17,
          sponsoredRank: 6,
          keywordSales: 166
        },
        {
          date: '2024-08-08',
          searchVolume: 4237,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-08-07',
          searchVolume: 4237,
          organicRank: 14,
          sponsoredRank: 6,
          keywordSales: 166
        },
        {
          date: '2024-08-06',
          searchVolume: 4237,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-08-05',
          searchVolume: 4237,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-08-04',
          searchVolume: 4237,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 166
        },
        {
          date: '2024-08-03',
          searchVolume: 4237,
          organicRank: 26,
          sponsoredRank: 4,
          keywordSales: 166
        },
        {
          date: '2024-08-02',
          searchVolume: 3991,
          organicRank: 15,

          keywordSales: 166
        },
        {
          date: '2024-08-01',
          searchVolume: 3991,
          organicRank: 20,
          sponsoredRank: 6,
          keywordSales: 166
        },
        {
          date: '2024-07-31',
          searchVolume: 3991,
          organicRank: 24,

          keywordSales: 166
        },
        {
          date: '2024-07-30',
          searchVolume: 3991,
          organicRank: 30,
          sponsoredRank: 2,
          keywordSales: 166
        },
        {
          date: '2024-07-29',
          searchVolume: 3991,
          organicRank: 19,

          keywordSales: 166
        },
        {
          date: '2024-07-28',
          searchVolume: 3991,
          organicRank: 26,

          keywordSales: 166
        },
        {
          date: '2024-07-27',
          searchVolume: 3991,
          organicRank: 21,

          keywordSales: 166
        },
        {
          date: '2024-07-26',
          searchVolume: 6447,
          organicRank: 26,
          sponsoredRank: 39,
          keywordSales: 166
        },
        {
          date: '2024-07-25',
          searchVolume: 6447,
          organicRank: 22,

          keywordSales: 166
        },
        {
          date: '2024-07-24',
          searchVolume: 6447,
          organicRank: 25,

          keywordSales: 166
        },
        {
          date: '2024-07-23',
          searchVolume: 6447,
          organicRank: 26,

          keywordSales: 166
        },
        {
          date: '2024-07-22',
          searchVolume: 6447,
          organicRank: 20,

          keywordSales: 166
        },
        {
          date: '2024-07-21',
          searchVolume: 6447,
          organicRank: 24,

          keywordSales: 166
        },
        {
          date: '2024-07-20',
          searchVolume: 6447,
          organicRank: 27,

          keywordSales: 166
        },
        {
          date: '2024-07-19',
          searchVolume: 4058,
          organicRank: 30,

          keywordSales: 166
        },
        {
          date: '2024-07-18',
          searchVolume: 4058,
          organicRank: 35,

          keywordSales: 166
        },
        {
          date: '2024-07-17',
          searchVolume: 4058,
          organicRank: 34,

          keywordSales: 166
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_travel waterpik',
      keyword: 'travel waterpik',
      searchVolume: 10577,
      organicStartRank: 5,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 7064,
          organicRank: 3,

          keywordSales: 157
        },
        {
          date: '2024-09-09',
          searchVolume: 7064,
          organicRank: 4,

          keywordSales: 157
        },
        {
          date: '2024-09-08',
          searchVolume: 7064,
          organicRank: 3,
          sponsoredRank: 6,
          keywordSales: 157
        },
        {
          date: '2024-09-07',
          searchVolume: 7064,
          organicRank: 4,

          keywordSales: 157
        },
        {
          date: '2024-09-06',
          searchVolume: 7064,
          organicRank: 5,
          sponsoredRank: 18,
          keywordSales: 157
        },
        {
          date: '2024-09-05',
          searchVolume: 7064,
          organicRank: 4,
          sponsoredRank: 46,
          keywordSales: 157
        },
        {
          date: '2024-09-01',
          searchVolume: 7064,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 157
        },
        {
          date: '2024-08-29',
          searchVolume: 7288,
          organicRank: 3,

          keywordSales: 157
        },
        {
          date: '2024-08-27',
          searchVolume: 7288,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 157
        },
        {
          date: '2024-08-26',
          searchVolume: 7288,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 157
        },
        {
          date: '2024-08-25',
          searchVolume: 7288,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 157
        },
        {
          date: '2024-08-24',
          searchVolume: 7288,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-08-23',
          searchVolume: 7455,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-22',
          searchVolume: 7455,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 157
        },
        {
          date: '2024-08-20',
          searchVolume: 7455,
          organicRank: 2,
          sponsoredRank: 30,
          keywordSales: 157
        },
        {
          date: '2024-08-19',
          searchVolume: 7455,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 157
        },
        {
          date: '2024-08-17',
          searchVolume: 7455,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 157
        },
        {
          date: '2024-08-16',
          searchVolume: 6953,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 157
        },
        {
          date: '2024-08-15',
          searchVolume: 6953,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-08-14',
          searchVolume: 6953,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-08-13',
          searchVolume: 6953,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-12',
          searchVolume: 6953,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-11',
          searchVolume: 6953,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-08-10',
          searchVolume: 6953,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-08-09',
          searchVolume: 6789,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-08-08',
          searchVolume: 6789,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-07',
          searchVolume: 6789,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-06',
          searchVolume: 6789,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-05',
          searchVolume: 6789,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-04',
          searchVolume: 6789,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-03',
          searchVolume: 6789,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-08-02',
          searchVolume: 7089,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-08-01',
          searchVolume: 7089,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-07-31',
          searchVolume: 7089,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-07-30',
          searchVolume: 7089,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-07-29',
          searchVolume: 7089,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 157
        },
        {
          date: '2024-07-28',
          searchVolume: 7089,
          organicRank: 2,
          sponsoredRank: 11,
          keywordSales: 157
        },
        {
          date: '2024-07-27',
          searchVolume: 7089,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-07-26',
          searchVolume: 10577,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-07-25',
          searchVolume: 10577,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 157
        },
        {
          date: '2024-07-24',
          searchVolume: 10577,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-07-23',
          searchVolume: 10577,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-07-22',
          searchVolume: 10577,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-07-21',
          searchVolume: 10577,
          organicRank: 1,

          keywordSales: 157
        },
        {
          date: '2024-07-20',
          searchVolume: 10577,
          organicRank: 2,

          keywordSales: 157
        },
        {
          date: '2024-07-19',
          searchVolume: 8469,
          organicRank: 3,

          keywordSales: 157
        },
        {
          date: '2024-07-18',
          searchVolume: 8469,
          organicRank: 5,

          keywordSales: 157
        },
        {
          date: '2024-07-17',
          searchVolume: 8469,
          organicRank: 4,

          keywordSales: 157
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water dental flosser',
      keyword: 'water dental flosser',
      searchVolume: 8863,
      organicStartRank: 101,
      organicHighestRank: 11,
      sponsoredStartRank: 17,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-21',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 6551,
          organicRank: 40,

          keywordSales: 249
        },
        {
          date: '2024-09-09',
          searchVolume: 6551,
          organicRank: 49,
          sponsoredRank: 1,
          keywordSales: 249
        },
        {
          date: '2024-09-08',
          searchVolume: 6551,
          organicRank: 48,
          sponsoredRank: 21,
          keywordSales: 249
        },
        {
          date: '2024-09-07',
          searchVolume: 6551,
          organicRank: 55,
          sponsoredRank: 4,
          keywordSales: 249
        },
        {
          date: '2024-09-06',
          searchVolume: 6551,
          organicRank: 40,
          sponsoredRank: 27,
          keywordSales: 249
        },
        {
          date: '2024-09-05',
          searchVolume: 6551,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 249
        },
        {
          date: '2024-09-02',
          searchVolume: 6551,
          organicRank: 37,

          keywordSales: 249
        },
        {
          date: '2024-09-01',
          searchVolume: 6551,
          organicRank: 33,
          sponsoredRank: 23,
          keywordSales: 249
        },
        {
          date: '2024-08-31',
          searchVolume: 6551,
          organicRank: 34,
          sponsoredRank: 20,
          keywordSales: 249
        },
        {
          date: '2024-08-30',
          searchVolume: 5930,
          organicRank: 36,
          sponsoredRank: 4,
          keywordSales: 249
        },
        {
          date: '2024-08-29',
          searchVolume: 5930,
          organicRank: 36,
          sponsoredRank: 12,
          keywordSales: 249
        },
        {
          date: '2024-08-28',
          searchVolume: 5930,
          organicRank: 39,

          keywordSales: 249
        },
        {
          date: '2024-08-27',
          searchVolume: 5930,
          organicRank: 50,

          keywordSales: 249
        },
        {
          date: '2024-08-26',
          searchVolume: 5930,
          organicRank: 47,

          keywordSales: 249
        },
        {
          date: '2024-08-25',
          searchVolume: 5930,
          organicRank: 69,

          keywordSales: 249
        },
        {
          date: '2024-08-24',
          searchVolume: 5930,
          organicRank: 81,

          keywordSales: 249
        },
        {
          date: '2024-08-23',
          searchVolume: 6005,
          organicRank: 74,

          keywordSales: 249
        },
        {
          date: '2024-08-22',
          searchVolume: 6005,
          organicRank: 74,

          keywordSales: 249
        },
        {
          date: '2024-08-21',
          searchVolume: 6005,
          organicRank: 11,

          keywordSales: 249
        },
        {
          date: '2024-08-20',
          searchVolume: 6005,
          organicRank: 58,

          keywordSales: 249
        },
        {
          date: '2024-08-19',
          searchVolume: 6005,
          organicRank: 55,

          keywordSales: 249
        },
        {
          date: '2024-08-18',
          searchVolume: 6005,
          organicRank: 87,

          keywordSales: 249
        },
        {
          date: '2024-08-16',
          searchVolume: 5433,
          organicRank: 30,

          keywordSales: 249
        },
        {
          date: '2024-08-15',
          searchVolume: 5433,
          organicRank: 22,

          keywordSales: 249
        },
        {
          date: '2024-08-14',
          searchVolume: 5433,
          organicRank: 25,

          keywordSales: 249
        },
        {
          date: '2024-08-13',
          searchVolume: 5433,
          organicRank: 24,

          keywordSales: 249
        },
        {
          date: '2024-08-11',
          searchVolume: 5433,
          organicRank: 28,
          sponsoredRank: 11,
          keywordSales: 249
        },
        {
          date: '2024-08-10',
          searchVolume: 5433,
          organicRank: 28,
          sponsoredRank: 9,
          keywordSales: 249
        },
        {
          date: '2024-08-09',
          searchVolume: 4028,
          organicRank: 23,
          sponsoredRank: 5,
          keywordSales: 249
        },
        {
          date: '2024-08-08',
          searchVolume: 4028,
          organicRank: 29,
          sponsoredRank: 27,
          keywordSales: 249
        },
        {
          date: '2024-08-07',
          searchVolume: 4028,
          organicRank: 35,
          sponsoredRank: 4,
          keywordSales: 249
        },
        {
          date: '2024-08-06',
          searchVolume: 4028,
          organicRank: 44,
          sponsoredRank: 22,
          keywordSales: 249
        },
        {
          date: '2024-08-05',
          searchVolume: 4028,
          organicRank: 43,
          sponsoredRank: 11,
          keywordSales: 249
        },
        {
          date: '2024-08-04',
          searchVolume: 4028,
          organicRank: 41,
          sponsoredRank: 11,
          keywordSales: 249
        },
        {
          date: '2024-08-03',
          searchVolume: 4028,
          organicRank: 40,
          sponsoredRank: 8,
          keywordSales: 249
        },
        {
          date: '2024-08-02',
          searchVolume: 3990,
          organicRank: 48,
          sponsoredRank: 11,
          keywordSales: 249
        },
        {
          date: '2024-08-01',
          searchVolume: 3990,
          organicRank: 49,
          sponsoredRank: 17,
          keywordSales: 249
        },
        {
          date: '2024-07-31',
          searchVolume: 3990,
          organicRank: 45,

          keywordSales: 249
        },
        {
          date: '2024-07-30',
          searchVolume: 3990,
          organicRank: 59,

          keywordSales: 249
        },
        {
          date: '2024-07-29',
          searchVolume: 3990,
          organicRank: 56,

          keywordSales: 249
        },
        {
          date: '2024-07-27',
          searchVolume: 3990,
          organicRank: 57,

          keywordSales: 249
        },
        {
          date: '2024-07-26',
          searchVolume: 8863,
          organicRank: 54,

          keywordSales: 249
        },
        {
          date: '2024-07-25',
          searchVolume: 8863,
          organicRank: 37,

          keywordSales: 249
        },
        {
          date: '2024-07-24',
          searchVolume: 8863,
          organicRank: 39,

          keywordSales: 249
        },
        {
          date: '2024-07-23',
          searchVolume: 8863,
          organicRank: 42,

          keywordSales: 249
        },
        {
          date: '2024-07-22',
          searchVolume: 8863,
          organicRank: 45,

          keywordSales: 249
        },
        {
          date: '2024-07-21',
          searchVolume: 8863,
          organicRank: 54,

          keywordSales: 249
        },
        {
          date: '2024-07-20',
          searchVolume: 8863,
          organicRank: 64,

          keywordSales: 249
        },
        {
          date: '2024-07-19',
          searchVolume: 4659,
          organicRank: 89,

          keywordSales: 249
        },
        {
          date: '2024-07-18',
          searchVolume: 4659,
          organicRank: 101,

          keywordSales: 249
        },
        {
          date: '2024-07-17',
          searchVolume: 4659,
          organicRank: 89,

          keywordSales: 249
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water dental flosser by makjuns',
      keyword: 'water dental flosser by makjuns',
      searchVolume: 2073,
      organicStartRank: 41,
      organicHighestRank: 7,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-24',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1858,
          organicRank: 53
        },
        {
          date: '2024-09-09',
          searchVolume: 1858,
          organicRank: 47
        },
        {
          date: '2024-09-08',
          searchVolume: 1858,
          organicRank: 64
        },
        {
          date: '2024-09-07',
          searchVolume: 1858,
          organicRank: 49,
          sponsoredRank: 8
        },
        {
          date: '2024-09-06',
          searchVolume: 1858,
          organicRank: 56
        },
        {
          date: '2024-09-05',
          searchVolume: 1858,
          organicRank: 53
        },
        {
          date: '2024-09-04',
          searchVolume: 1858,
          organicRank: 58
        },
        {
          date: '2024-09-02',
          searchVolume: 1858,
          organicRank: 32
        },
        {
          date: '2024-08-30',
          searchVolume: 1850,
          organicRank: 49
        },
        {
          date: '2024-08-28',
          searchVolume: 1850,
          organicRank: 30
        },
        {
          date: '2024-08-27',
          searchVolume: 1850,
          organicRank: 36
        },
        {
          date: '2024-08-26',
          searchVolume: 1850,
          organicRank: 52
        },
        {
          date: '2024-08-25',
          searchVolume: 1850,
          organicRank: 29
        },
        {
          date: '2024-08-24',
          searchVolume: 1850,
          organicRank: 18
        },
        {
          date: '2024-08-23',
          searchVolume: 1928,
          organicRank: 31
        },
        {
          date: '2024-08-22',
          searchVolume: 1928,
          organicRank: 14
        },
        {
          date: '2024-08-20',
          searchVolume: 1928,
          organicRank: 27
        },
        {
          date: '2024-08-19',
          searchVolume: 1928,
          organicRank: 7
        },
        {
          date: '2024-08-17',
          searchVolume: 1928,
          organicRank: 17
        },
        {
          date: '2024-08-16',
          searchVolume: 2085,
          organicRank: 18
        },
        {
          date: '2024-08-15',
          searchVolume: 2085,
          organicRank: 16
        },
        {
          date: '2024-08-14',
          searchVolume: 2085,
          organicRank: 13
        },
        {
          date: '2024-08-13',
          searchVolume: 2085,
          organicRank: 17
        },
        {
          date: '2024-08-12',
          searchVolume: 2085,
          organicRank: 15,
          sponsoredRank: 11
        },
        {
          date: '2024-08-11',
          searchVolume: 2085,
          organicRank: 18,
          sponsoredRank: 4
        },
        {
          date: '2024-08-10',
          searchVolume: 2085,
          organicRank: 18
        },
        {
          date: '2024-08-09',
          searchVolume: 2376,
          organicRank: 19
        },
        {
          date: '2024-08-08',
          searchVolume: 2376,
          organicRank: 16
        },
        {
          date: '2024-08-07',
          searchVolume: 2376,
          organicRank: 14
        },
        {
          date: '2024-08-06',
          searchVolume: 2376,
          organicRank: 16
        },
        {
          date: '2024-08-05',
          searchVolume: 2376,
          organicRank: 16
        },
        {
          date: '2024-08-04',
          searchVolume: 2376,
          organicRank: 13
        },
        {
          date: '2024-08-02',
          searchVolume: 2356,
          organicRank: 14
        },
        {
          date: '2024-08-01',
          searchVolume: 2356,
          organicRank: 16,
          sponsoredRank: 6
        },
        {
          date: '2024-07-31',
          searchVolume: 2356,
          organicRank: 16
        },
        {
          date: '2024-07-30',
          searchVolume: 2356,
          organicRank: 13
        },
        {
          date: '2024-07-29',
          searchVolume: 2356,
          organicRank: 16
        },
        {
          date: '2024-07-28',
          searchVolume: 2356,
          organicRank: 15
        },
        {
          date: '2024-07-27',
          searchVolume: 2356,
          organicRank: 13
        },
        {
          date: '2024-07-26',
          searchVolume: 2073,
          organicRank: 15
        },
        {
          date: '2024-07-25',
          searchVolume: 2073,
          organicRank: 15
        },
        {
          date: '2024-07-24',
          searchVolume: 2073,
          organicRank: 15
        },
        {
          date: '2024-07-23',
          searchVolume: 2073,
          organicRank: 23
        },
        {
          date: '2024-07-22',
          searchVolume: 2073,
          organicRank: 26
        },
        {
          date: '2024-07-21',
          searchVolume: 2073,
          organicRank: 29
        },
        {
          date: '2024-07-20',
          searchVolume: 2073,
          organicRank: 29
        },
        {
          date: '2024-07-19',
          searchVolume: 2280,
          organicRank: 33
        },
        {
          date: '2024-07-18',
          searchVolume: 2280,
          organicRank: 41
        },
        {
          date: '2024-07-17',
          searchVolume: 2280,
          organicRank: 37
        },
        {
          date: '2024-07-16',
          searchVolume: 2280,
          organicRank: 33
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water floss',
      keyword: 'water floss',
      searchVolume: 21055,
      organicStartRank: 52,
      organicHighestRank: 5,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 16094,
          organicRank: 16,

          keywordSales: 606
        },
        {
          date: '2024-09-09',
          searchVolume: 16094,
          organicRank: 18,

          keywordSales: 606
        },
        {
          date: '2024-09-08',
          searchVolume: 16094,
          organicRank: 22,
          sponsoredRank: 13,
          keywordSales: 606
        },
        {
          date: '2024-09-07',
          searchVolume: 16094,
          organicRank: 23,
          sponsoredRank: 7,
          keywordSales: 606
        },
        {
          date: '2024-09-06',
          searchVolume: 16094,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 606
        },
        {
          date: '2024-09-05',
          searchVolume: 16094,
          organicRank: 54,
          sponsoredRank: 6,
          keywordSales: 606
        },
        {
          date: '2024-09-02',
          searchVolume: 16094,
          organicRank: 23,
          sponsoredRank: 9,
          keywordSales: 606
        },
        {
          date: '2024-09-01',
          searchVolume: 16094,
          organicRank: 11,
          sponsoredRank: 9,
          keywordSales: 606
        },
        {
          date: '2024-08-31',
          searchVolume: 16094,
          organicRank: 19,
          sponsoredRank: 18,
          keywordSales: 606
        },
        {
          date: '2024-08-30',
          searchVolume: 17693,
          organicRank: 18,
          sponsoredRank: 5,
          keywordSales: 606
        },
        {
          date: '2024-08-29',
          searchVolume: 17693,
          organicRank: 18,
          sponsoredRank: 6,
          keywordSales: 606
        },
        {
          date: '2024-08-28',
          searchVolume: 17693,
          organicRank: 15,

          keywordSales: 606
        },
        {
          date: '2024-08-27',
          searchVolume: 17693,
          organicRank: 10,

          keywordSales: 606
        },
        {
          date: '2024-08-26',
          searchVolume: 17693,
          organicRank: 11,

          keywordSales: 606
        },
        {
          date: '2024-08-25',
          searchVolume: 17693,
          organicRank: 13,

          keywordSales: 606
        },
        {
          date: '2024-08-24',
          searchVolume: 17693,
          organicRank: 12,
          sponsoredRank: 12,
          keywordSales: 606
        },
        {
          date: '2024-08-23',
          searchVolume: 20527,
          organicRank: 12,

          keywordSales: 606
        },
        {
          date: '2024-08-22',
          searchVolume: 20527,
          organicRank: 11,
          sponsoredRank: 5,
          keywordSales: 606
        },
        {
          date: '2024-08-20',
          searchVolume: 20527,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 606
        },
        {
          date: '2024-08-19',
          searchVolume: 20527,
          organicRank: 7,
          sponsoredRank: 30,
          keywordSales: 606
        },
        {
          date: '2024-08-17',
          searchVolume: 20527,
          organicRank: 12,
          sponsoredRank: 8,
          keywordSales: 606
        },
        {
          date: '2024-08-16',
          searchVolume: 15826,
          organicRank: 13,

          keywordSales: 606
        },
        {
          date: '2024-08-15',
          searchVolume: 15826,
          organicRank: 12,

          keywordSales: 606
        },
        {
          date: '2024-08-14',
          searchVolume: 15826,
          organicRank: 9,

          keywordSales: 606
        },
        {
          date: '2024-08-13',
          searchVolume: 15826,
          organicRank: 5,

          keywordSales: 606
        },
        {
          date: '2024-08-12',
          searchVolume: 15826,
          organicRank: 5,

          keywordSales: 606
        },
        {
          date: '2024-08-11',
          searchVolume: 15826,
          organicRank: 10,

          keywordSales: 606
        },
        {
          date: '2024-08-10',
          searchVolume: 15826,
          organicRank: 10,

          keywordSales: 606
        },
        {
          date: '2024-08-09',
          searchVolume: 12521,
          organicRank: 11,

          keywordSales: 606
        },
        {
          date: '2024-08-08',
          searchVolume: 12521,
          organicRank: 12,

          keywordSales: 606
        },
        {
          date: '2024-08-07',
          searchVolume: 12521,
          organicRank: 12,

          keywordSales: 606
        },
        {
          date: '2024-08-06',
          searchVolume: 12521,
          organicRank: 12,
          sponsoredRank: 7,
          keywordSales: 606
        },
        {
          date: '2024-08-05',
          searchVolume: 12521,
          organicRank: 11,
          sponsoredRank: 10,
          keywordSales: 606
        },
        {
          date: '2024-08-04',
          searchVolume: 12521,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 606
        },
        {
          date: '2024-08-02',
          searchVolume: 10845,
          organicRank: 9,

          keywordSales: 606
        },
        {
          date: '2024-08-01',
          searchVolume: 10845,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 606
        },
        {
          date: '2024-07-31',
          searchVolume: 10845,
          organicRank: 6,

          keywordSales: 606
        },
        {
          date: '2024-07-30',
          searchVolume: 10845,
          organicRank: 6,

          keywordSales: 606
        },
        {
          date: '2024-07-29',
          searchVolume: 10845,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 606
        },
        {
          date: '2024-07-28',
          searchVolume: 10845,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 606
        },
        {
          date: '2024-07-27',
          searchVolume: 10845,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 606
        },
        {
          date: '2024-07-26',
          searchVolume: 21055,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 606
        },
        {
          date: '2024-07-25',
          searchVolume: 21055,
          organicRank: 12,
          sponsoredRank: 5,
          keywordSales: 606
        },
        {
          date: '2024-07-24',
          searchVolume: 21055,
          organicRank: 10,

          keywordSales: 606
        },
        {
          date: '2024-07-23',
          searchVolume: 21055,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 606
        },
        {
          date: '2024-07-22',
          searchVolume: 21055,
          organicRank: 18,

          keywordSales: 606
        },
        {
          date: '2024-07-21',
          searchVolume: 21055,
          organicRank: 19,

          keywordSales: 606
        },
        {
          date: '2024-07-20',
          searchVolume: 21055,
          organicRank: 13,

          keywordSales: 606
        },
        {
          date: '2024-07-19',
          searchVolume: 10412,
          organicRank: 45,

          keywordSales: 606
        },
        {
          date: '2024-07-18',
          searchVolume: 10412,
          organicRank: 52,

          keywordSales: 606
        },
        {
          date: '2024-07-17',
          searchVolume: 10412,
          organicRank: 46,

          keywordSales: 606
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water floss pick',
      keyword: 'water floss pick',
      searchVolume: 2984,
      organicStartRank: 23,
      organicHighestRank: 5,
      sponsoredStartRank: 45,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1613,
          organicRank: 11,

          keywordSales: 36
        },
        {
          date: '2024-09-09',
          searchVolume: 1613,
          organicRank: 19,

          keywordSales: 36
        },
        {
          date: '2024-09-08',
          searchVolume: 1613,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 36
        },
        {
          date: '2024-09-07',
          searchVolume: 1613,
          organicRank: 16,
          sponsoredRank: 44,
          keywordSales: 36
        },
        {
          date: '2024-09-06',
          searchVolume: 1613,
          organicRank: 16,
          sponsoredRank: 20,
          keywordSales: 36
        },
        {
          date: '2024-09-05',
          searchVolume: 1613,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 36
        },
        {
          date: '2024-09-02',
          searchVolume: 1613,
          organicRank: 17,
          sponsoredRank: 6,
          keywordSales: 36
        },
        {
          date: '2024-09-01',
          searchVolume: 1613,
          organicRank: 22,
          sponsoredRank: 7,
          keywordSales: 36
        },
        {
          date: '2024-08-31',
          searchVolume: 1613,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 36
        },
        {
          date: '2024-08-30',
          searchVolume: 1930,
          organicRank: 13,
          sponsoredRank: 8,
          keywordSales: 36
        },
        {
          date: '2024-08-29',
          searchVolume: 1930,
          organicRank: 12,
          sponsoredRank: 21,
          keywordSales: 36
        },
        {
          date: '2024-08-28',
          searchVolume: 1930,
          organicRank: 12,

          keywordSales: 36
        },
        {
          date: '2024-08-27',
          searchVolume: 1930,
          organicRank: 11,

          keywordSales: 36
        },
        {
          date: '2024-08-26',
          searchVolume: 1930,
          organicRank: 10,

          keywordSales: 36
        },
        {
          date: '2024-08-25',
          searchVolume: 1930,
          organicRank: 14,

          keywordSales: 36
        },
        {
          date: '2024-08-24',
          searchVolume: 1930,
          organicRank: 10,

          keywordSales: 36
        },
        {
          date: '2024-08-23',
          searchVolume: 3468,
          organicRank: 10,
          sponsoredRank: 46,
          keywordSales: 36
        },
        {
          date: '2024-08-22',
          searchVolume: 3468,
          organicRank: 13,

          keywordSales: 36
        },
        {
          date: '2024-08-21',
          searchVolume: 3468,
          organicRank: 13,

          keywordSales: 36
        },
        {
          date: '2024-08-19',
          searchVolume: 3468,
          organicRank: 7,

          keywordSales: 36
        },
        {
          date: '2024-08-17',
          searchVolume: 3468,
          organicRank: 7,

          keywordSales: 36
        },
        {
          date: '2024-08-16',
          searchVolume: 2987,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 36
        },
        {
          date: '2024-08-15',
          searchVolume: 2987,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 36
        },
        {
          date: '2024-08-14',
          searchVolume: 2987,
          organicRank: 8,

          keywordSales: 36
        },
        {
          date: '2024-08-13',
          searchVolume: 2987,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 36
        },
        {
          date: '2024-08-12',
          searchVolume: 2987,
          organicRank: 7,
          sponsoredRank: 6,
          keywordSales: 36
        },
        {
          date: '2024-08-11',
          searchVolume: 2987,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 36
        },
        {
          date: '2024-08-10',
          searchVolume: 2987,
          organicRank: 9,

          keywordSales: 36
        },
        {
          date: '2024-08-09',
          searchVolume: 2378,
          organicRank: 6,
          sponsoredRank: 45,
          keywordSales: 36
        },
        {
          date: '2024-08-08',
          searchVolume: 2378,
          organicRank: 5,
          sponsoredRank: 9,
          keywordSales: 36
        },
        {
          date: '2024-08-07',
          searchVolume: 2378,
          organicRank: 7,
          sponsoredRank: 46,
          keywordSales: 36
        },
        {
          date: '2024-08-06',
          searchVolume: 2378,
          organicRank: 6,
          sponsoredRank: 11,
          keywordSales: 36
        },
        {
          date: '2024-08-05',
          searchVolume: 2378,
          organicRank: 5,

          keywordSales: 36
        },
        {
          date: '2024-08-04',
          searchVolume: 2378,
          organicRank: 5,

          keywordSales: 36
        },
        {
          date: '2024-08-02',
          searchVolume: 2353,
          organicRank: 5,
          sponsoredRank: 8,
          keywordSales: 36
        },
        {
          date: '2024-08-01',
          searchVolume: 2353,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 36
        },
        {
          date: '2024-07-31',
          searchVolume: 2353,
          organicRank: 8,

          keywordSales: 36
        },
        {
          date: '2024-07-30',
          searchVolume: 2353,
          organicRank: 8,
          sponsoredRank: 60,
          keywordSales: 36
        },
        {
          date: '2024-07-29',
          searchVolume: 2353,
          organicRank: 12,

          keywordSales: 36
        },
        {
          date: '2024-07-28',
          searchVolume: 2353,
          organicRank: 11,

          keywordSales: 36
        },
        {
          date: '2024-07-27',
          searchVolume: 2353,
          organicRank: 12,

          keywordSales: 36
        },
        {
          date: '2024-07-26',
          searchVolume: 2984,
          organicRank: 11,
          sponsoredRank: 45,
          keywordSales: 36
        },
        {
          date: '2024-07-25',
          searchVolume: 2984,
          organicRank: 8,

          keywordSales: 36
        },
        {
          date: '2024-07-24',
          searchVolume: 2984,
          organicRank: 10,

          keywordSales: 36
        },
        {
          date: '2024-07-23',
          searchVolume: 2984,
          organicRank: 10,

          keywordSales: 36
        },
        {
          date: '2024-07-22',
          searchVolume: 2984,
          organicRank: 14,

          keywordSales: 36
        },
        {
          date: '2024-07-21',
          searchVolume: 2984,
          organicRank: 13,

          keywordSales: 36
        },
        {
          date: '2024-07-20',
          searchVolume: 2984,
          organicRank: 9,

          keywordSales: 36
        },
        {
          date: '2024-07-19',
          searchVolume: 3399,
          organicRank: 17,

          keywordSales: 36
        },
        {
          date: '2024-07-18',
          searchVolume: 3399,
          organicRank: 23,

          keywordSales: 36
        },
        {
          date: '2024-07-17',
          searchVolume: 3399,
          organicRank: 23,

          keywordSales: 36
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flossed',
      keyword: 'water flossed',
      searchVolume: 2431,
      organicStartRank: 52,
      organicHighestRank: 6,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2315,
          organicRank: 10,

          keywordSales: 83
        },
        {
          date: '2024-09-09',
          searchVolume: 2315,
          organicRank: 22,

          keywordSales: 83
        },
        {
          date: '2024-09-08',
          searchVolume: 2315,
          organicRank: 24,
          sponsoredRank: 7,
          keywordSales: 83
        },
        {
          date: '2024-09-07',
          searchVolume: 2315,
          organicRank: 20,
          sponsoredRank: 30,
          keywordSales: 83
        },
        {
          date: '2024-09-06',
          searchVolume: 2315,
          organicRank: 17,
          sponsoredRank: 5,
          keywordSales: 83
        },
        {
          date: '2024-09-05',
          searchVolume: 2315,
          organicRank: 24,

          keywordSales: 83
        },
        {
          date: '2024-09-04',
          searchVolume: 2315,
          organicRank: 18,

          keywordSales: 83
        },
        {
          date: '2024-09-01',
          searchVolume: 2315,
          organicRank: 19,
          sponsoredRank: 18,
          keywordSales: 83
        },
        {
          date: '2024-08-28',
          searchVolume: 2548,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 83
        },
        {
          date: '2024-08-27',
          searchVolume: 2548,
          organicRank: 11,
          sponsoredRank: 22,
          keywordSales: 83
        },
        {
          date: '2024-08-26',
          searchVolume: 2548,
          organicRank: 13,
          sponsoredRank: 11,
          keywordSales: 83
        },
        {
          date: '2024-08-25',
          searchVolume: 2548,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-08-24',
          searchVolume: 2548,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-08-23',
          searchVolume: 3620,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-08-22',
          searchVolume: 3620,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-08-21',
          searchVolume: 3620,
          organicRank: 6,

          keywordSales: 83
        },
        {
          date: '2024-08-20',
          searchVolume: 3620,
          organicRank: 6,

          keywordSales: 83
        },
        {
          date: '2024-08-19',
          searchVolume: 3620,
          organicRank: 9,
          sponsoredRank: 30,
          keywordSales: 83
        },
        {
          date: '2024-08-17',
          searchVolume: 3620,
          organicRank: 11,
          sponsoredRank: 5,
          keywordSales: 83
        },
        {
          date: '2024-08-16',
          searchVolume: 2431,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-08-15',
          searchVolume: 2431,
          organicRank: 13,

          keywordSales: 83
        },
        {
          date: '2024-08-14',
          searchVolume: 2431,
          organicRank: 6,

          keywordSales: 83
        },
        {
          date: '2024-08-13',
          searchVolume: 2431,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-08-12',
          searchVolume: 2431,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-08-11',
          searchVolume: 2431,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-08-10',
          searchVolume: 2431,
          organicRank: 10,

          keywordSales: 83
        },
        {
          date: '2024-08-09',
          searchVolume: 1782,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-08-08',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-08-07',
          searchVolume: 1782,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-08-06',
          searchVolume: 1782,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-08-05',
          searchVolume: 1782,
          organicRank: 7,

          keywordSales: 83
        },
        {
          date: '2024-08-04',
          searchVolume: 1782,
          organicRank: 9,

          keywordSales: 83
        },
        {
          date: '2024-08-03',
          searchVolume: 1782,
          organicRank: 7,

          keywordSales: 83
        },
        {
          date: '2024-08-02',
          searchVolume: 1485,
          organicRank: 9,

          keywordSales: 83
        },
        {
          date: '2024-08-01',
          searchVolume: 1485,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 83
        },
        {
          date: '2024-07-31',
          searchVolume: 1485,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-07-30',
          searchVolume: 1485,
          organicRank: 6,

          keywordSales: 83
        },
        {
          date: '2024-07-29',
          searchVolume: 1485,
          organicRank: 8,
          sponsoredRank: 57,
          keywordSales: 83
        },
        {
          date: '2024-07-28',
          searchVolume: 1485,
          organicRank: 14,
          sponsoredRank: 45,
          keywordSales: 83
        },
        {
          date: '2024-07-27',
          searchVolume: 1485,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 83
        },
        {
          date: '2024-07-26',
          searchVolume: 2431,
          organicRank: 7,
          sponsoredRank: 4,
          keywordSales: 83
        },
        {
          date: '2024-07-25',
          searchVolume: 2431,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 83
        },
        {
          date: '2024-07-24',
          searchVolume: 2431,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-07-23',
          searchVolume: 2431,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 83
        },
        {
          date: '2024-07-22',
          searchVolume: 2431,
          organicRank: 13,

          keywordSales: 83
        },
        {
          date: '2024-07-21',
          searchVolume: 2431,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-07-20',
          searchVolume: 2431,
          organicRank: 13,

          keywordSales: 83
        },
        {
          date: '2024-07-19',
          searchVolume: 1557,
          organicRank: 18,
          sponsoredRank: 9,
          keywordSales: 83
        },
        {
          date: '2024-07-18',
          searchVolume: 1557,
          organicRank: 52,

          keywordSales: 83
        },
        {
          date: '2024-07-17',
          searchVolume: 1557,
          organicRank: 33,

          keywordSales: 83
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser',
      keyword: 'water flosser',
      searchVolume: 360740,
      organicStartRank: 53,
      organicHighestRank: 5,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 659249,
          organicRank: 21,

          keywordSales: 10036
        },
        {
          date: '2024-09-09',
          searchVolume: 659249,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 10036
        },
        {
          date: '2024-09-08',
          searchVolume: 659249,
          organicRank: 55,
          sponsoredRank: 12,
          keywordSales: 10036
        },
        {
          date: '2024-09-07',
          searchVolume: 659249,
          organicRank: 25,
          sponsoredRank: 5,
          keywordSales: 10036
        },
        {
          date: '2024-09-06',
          searchVolume: 659249,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 10036
        },
        {
          date: '2024-09-05',
          searchVolume: 659249,
          organicRank: 25,
          sponsoredRank: 6,
          keywordSales: 10036
        },
        {
          date: '2024-09-03',
          searchVolume: 659249,
          organicRank: 55,
          sponsoredRank: 21,
          keywordSales: 10036
        },
        {
          date: '2024-09-02',
          searchVolume: 659249,
          organicRank: 18,
          sponsoredRank: 6,
          keywordSales: 10036
        },
        {
          date: '2024-09-01',
          searchVolume: 659249,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 10036
        },
        {
          date: '2024-08-31',
          searchVolume: 659249,
          organicRank: 21,
          sponsoredRank: 5,
          keywordSales: 10036
        },
        {
          date: '2024-08-30',
          searchVolume: 717055,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 10036
        },
        {
          date: '2024-08-29',
          searchVolume: 717055,
          organicRank: 18,
          sponsoredRank: 6,
          keywordSales: 10036
        },
        {
          date: '2024-08-28',
          searchVolume: 717055,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 10036
        },
        {
          date: '2024-08-27',
          searchVolume: 717055,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 10036
        },
        {
          date: '2024-08-26',
          searchVolume: 717055,
          organicRank: 11,

          keywordSales: 10036
        },
        {
          date: '2024-08-25',
          searchVolume: 717055,
          organicRank: 10,

          keywordSales: 10036
        },
        {
          date: '2024-08-24',
          searchVolume: 717055,
          organicRank: 11,

          keywordSales: 10036
        },
        {
          date: '2024-08-23',
          searchVolume: 885238,
          organicRank: 16,

          keywordSales: 10036
        },
        {
          date: '2024-08-22',
          searchVolume: 885238,
          organicRank: 5,

          keywordSales: 10036
        },
        {
          date: '2024-08-19',
          searchVolume: 885238,
          organicRank: 10,

          keywordSales: 10036
        },
        {
          date: '2024-08-18',
          searchVolume: 885238,
          organicRank: 15,

          keywordSales: 10036
        },
        {
          date: '2024-08-16',
          searchVolume: 498957,
          organicRank: 12,

          keywordSales: 10036
        },
        {
          date: '2024-08-15',
          searchVolume: 498957,
          organicRank: 12,

          keywordSales: 10036
        },
        {
          date: '2024-08-14',
          searchVolume: 498957,
          organicRank: 11,

          keywordSales: 10036
        },
        {
          date: '2024-08-13',
          searchVolume: 498957,
          organicRank: 6,

          keywordSales: 10036
        },
        {
          date: '2024-08-11',
          searchVolume: 498957,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 10036
        },
        {
          date: '2024-08-10',
          searchVolume: 498957,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 10036
        },
        {
          date: '2024-08-09',
          searchVolume: 351434,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 10036
        },
        {
          date: '2024-08-08',
          searchVolume: 351434,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 10036
        },
        {
          date: '2024-08-07',
          searchVolume: 351434,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 10036
        },
        {
          date: '2024-08-06',
          searchVolume: 351434,
          organicRank: 12,
          sponsoredRank: 9,
          keywordSales: 10036
        },
        {
          date: '2024-08-05',
          searchVolume: 351434,
          organicRank: 10,
          sponsoredRank: 9,
          keywordSales: 10036
        },
        {
          date: '2024-08-04',
          searchVolume: 351434,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 10036
        },
        {
          date: '2024-08-03',
          searchVolume: 351434,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 10036
        },
        {
          date: '2024-08-02',
          searchVolume: 336445,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 10036
        },
        {
          date: '2024-08-01',
          searchVolume: 336445,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 10036
        },
        {
          date: '2024-07-31',
          searchVolume: 336445,
          organicRank: 8,
          sponsoredRank: 8,
          keywordSales: 10036
        },
        {
          date: '2024-07-30',
          searchVolume: 336445,
          organicRank: 8,

          keywordSales: 10036
        },
        {
          date: '2024-07-29',
          searchVolume: 336445,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 10036
        },
        {
          date: '2024-07-28',
          searchVolume: 336445,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 10036
        },
        {
          date: '2024-07-27',
          searchVolume: 336445,
          organicRank: 8,

          keywordSales: 10036
        },
        {
          date: '2024-07-26',
          searchVolume: 360740,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 10036
        },
        {
          date: '2024-07-25',
          searchVolume: 360740,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 10036
        },
        {
          date: '2024-07-24',
          searchVolume: 360740,
          organicRank: 8,

          keywordSales: 10036
        },
        {
          date: '2024-07-23',
          searchVolume: 360740,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 10036
        },
        {
          date: '2024-07-22',
          searchVolume: 360740,
          organicRank: 19,

          keywordSales: 10036
        },
        {
          date: '2024-07-21',
          searchVolume: 360740,
          organicRank: 20,

          keywordSales: 10036
        },
        {
          date: '2024-07-20',
          searchVolume: 360740,
          organicRank: 13,

          keywordSales: 10036
        },
        {
          date: '2024-07-19',
          searchVolume: 218942,
          organicRank: 25,
          sponsoredRank: 9,
          keywordSales: 10036
        },
        {
          date: '2024-07-18',
          searchVolume: 218942,
          organicRank: 53,

          keywordSales: 10036
        },
        {
          date: '2024-07-17',
          searchVolume: 218942,
          organicRank: 41,

          keywordSales: 10036
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser and toothbrush combo',
      keyword: 'water flosser and toothbrush combo',
      searchVolume: 1376,
      organicStartRank: 70,
      organicHighestRank: 16,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 24,
      organicPageOneWinDate: '2024-09-02',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 924,
          organicRank: 158,

          keywordSales: 21
        },
        {
          date: '2024-09-09',
          searchVolume: 924,
          organicRank: 74,

          keywordSales: 21
        },
        {
          date: '2024-09-08',
          searchVolume: 924,
          organicRank: 78,

          keywordSales: 21
        },
        {
          date: '2024-09-07',
          searchVolume: 924,
          organicRank: 69,
          sponsoredRank: 24,
          keywordSales: 21
        },
        {
          date: '2024-09-06',
          searchVolume: 924,
          organicRank: 62,

          keywordSales: 21
        },
        {
          date: '2024-09-05',
          searchVolume: 924,
          organicRank: 47,

          keywordSales: 21
        },
        {
          date: '2024-09-04',
          searchVolume: 924,
          organicRank: 44,

          keywordSales: 21
        },
        {
          date: '2024-09-03',
          searchVolume: 924,
          organicRank: 18,

          keywordSales: 21
        },
        {
          date: '2024-09-02',
          searchVolume: 924,
          organicRank: 16,

          keywordSales: 21
        },
        {
          date: '2024-08-31',
          searchVolume: 924,
          organicRank: 48,

          keywordSales: 21
        },
        {
          date: '2024-08-28',
          searchVolume: 886,
          organicRank: 47,

          keywordSales: 21
        },
        {
          date: '2024-08-27',
          searchVolume: 886,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-08-26',
          searchVolume: 886,
          organicRank: 32,

          keywordSales: 21
        },
        {
          date: '2024-08-25',
          searchVolume: 886,
          organicRank: 33,

          keywordSales: 21
        },
        {
          date: '2024-08-24',
          searchVolume: 886,
          organicRank: 34,

          keywordSales: 21
        },
        {
          date: '2024-08-23',
          searchVolume: 953,
          organicRank: 31,

          keywordSales: 21
        },
        {
          date: '2024-08-22',
          searchVolume: 953,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-20',
          searchVolume: 953,
          organicRank: 25,

          keywordSales: 21
        },
        {
          date: '2024-08-19',
          searchVolume: 953,
          organicRank: 33,

          keywordSales: 21
        },
        {
          date: '2024-08-17',
          searchVolume: 953,
          organicRank: 36,

          keywordSales: 21
        },
        {
          date: '2024-08-16',
          searchVolume: 713,
          organicRank: 30,

          keywordSales: 21
        },
        {
          date: '2024-08-15',
          searchVolume: 713,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-14',
          searchVolume: 713,
          organicRank: 27,

          keywordSales: 21
        },
        {
          date: '2024-08-13',
          searchVolume: 713,
          organicRank: 33,

          keywordSales: 21
        },
        {
          date: '2024-08-12',
          searchVolume: 713,
          organicRank: 29,

          keywordSales: 21
        },
        {
          date: '2024-08-11',
          searchVolume: 713,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-08-10',
          searchVolume: 713,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-08-09',
          searchVolume: 628,
          organicRank: 39,

          keywordSales: 21
        },
        {
          date: '2024-08-08',
          searchVolume: 628,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-08-07',
          searchVolume: 628,
          organicRank: 27,

          keywordSales: 21
        },
        {
          date: '2024-08-06',
          searchVolume: 628,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-08-05',
          searchVolume: 628,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-08-04',
          searchVolume: 628,
          organicRank: 32,

          keywordSales: 21
        },
        {
          date: '2024-08-03',
          searchVolume: 628,
          organicRank: 37,

          keywordSales: 21
        },
        {
          date: '2024-08-02',
          searchVolume: 621,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-08-01',
          searchVolume: 621,
          organicRank: 36,

          keywordSales: 21
        },
        {
          date: '2024-07-31',
          searchVolume: 621,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-07-30',
          searchVolume: 621,
          organicRank: 37,

          keywordSales: 21
        },
        {
          date: '2024-07-29',
          searchVolume: 621,
          organicRank: 40,

          keywordSales: 21
        },
        {
          date: '2024-07-28',
          searchVolume: 621,
          organicRank: 30,

          keywordSales: 21
        },
        {
          date: '2024-07-27',
          searchVolume: 621,
          organicRank: 38,

          keywordSales: 21
        },
        {
          date: '2024-07-26',
          searchVolume: 1376,
          organicRank: 40,

          keywordSales: 21
        },
        {
          date: '2024-07-25',
          searchVolume: 1376,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-07-24',
          searchVolume: 1376,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-07-23',
          searchVolume: 1376,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-07-22',
          searchVolume: 1376,
          organicRank: 45,

          keywordSales: 21
        },
        {
          date: '2024-07-21',
          searchVolume: 1376,
          organicRank: 59,

          keywordSales: 21
        },
        {
          date: '2024-07-20',
          searchVolume: 1376,
          organicRank: 56,

          keywordSales: 21
        },
        {
          date: '2024-07-19',
          searchVolume: 633,
          organicRank: 58,

          keywordSales: 21
        },
        {
          date: '2024-07-18',
          searchVolume: 633,
          organicRank: 70,

          keywordSales: 21
        },
        {
          date: '2024-07-17',
          searchVolume: 633,
          organicRank: 65,

          keywordSales: 21
        },
        {
          date: '2024-07-16',
          searchVolume: 633,
          organicRank: 60,

          keywordSales: 21
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser by makjuns',
      keyword: 'water flosser by makjuns',
      searchVolume: 2073,
      organicStartRank: 51,
      organicHighestRank: 13,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1858,
          organicRank: 37
        },
        {
          date: '2024-09-09',
          searchVolume: 1858,
          organicRank: 40
        },
        {
          date: '2024-09-08',
          searchVolume: 1858,
          organicRank: 47
        },
        {
          date: '2024-09-07',
          searchVolume: 1858,
          organicRank: 37
        },
        {
          date: '2024-09-06',
          searchVolume: 1858,
          organicRank: 38
        },
        {
          date: '2024-09-05',
          searchVolume: 1858,
          organicRank: 35
        },
        {
          date: '2024-09-04',
          searchVolume: 1858,
          organicRank: 37
        },
        {
          date: '2024-09-02',
          searchVolume: 1858,
          organicRank: 34
        },
        {
          date: '2024-08-29',
          searchVolume: 1930,
          organicRank: 30
        },
        {
          date: '2024-08-28',
          searchVolume: 1930,
          organicRank: 28
        },
        {
          date: '2024-08-27',
          searchVolume: 1930,
          organicRank: 22
        },
        {
          date: '2024-08-26',
          searchVolume: 1930,
          organicRank: 17
        },
        {
          date: '2024-08-25',
          searchVolume: 1930,
          organicRank: 14
        },
        {
          date: '2024-08-24',
          searchVolume: 1930,
          organicRank: 22
        },
        {
          date: '2024-08-22',
          searchVolume: 1919,
          organicRank: 20
        },
        {
          date: '2024-08-21',
          searchVolume: 1919,
          organicRank: 19
        },
        {
          date: '2024-08-20',
          searchVolume: 1919,
          organicRank: 15
        },
        {
          date: '2024-08-19',
          searchVolume: 1919,
          organicRank: 15
        },
        {
          date: '2024-08-18',
          searchVolume: 1919,
          organicRank: 17,
          sponsoredRank: 34
        },
        {
          date: '2024-08-16',
          searchVolume: 1836,
          organicRank: 22
        },
        {
          date: '2024-08-15',
          searchVolume: 1836,
          organicRank: 15
        },
        {
          date: '2024-08-14',
          searchVolume: 1836,
          organicRank: 13,
          sponsoredRank: 5
        },
        {
          date: '2024-08-13',
          searchVolume: 1836,
          organicRank: 16
        },
        {
          date: '2024-08-12',
          searchVolume: 1836,
          organicRank: 17
        },
        {
          date: '2024-08-11',
          searchVolume: 1836,
          organicRank: 20
        },
        {
          date: '2024-08-10',
          searchVolume: 1836,
          organicRank: 20
        },
        {
          date: '2024-08-09',
          searchVolume: 2372,
          organicRank: 23
        },
        {
          date: '2024-08-08',
          searchVolume: 2372,
          organicRank: 24
        },
        {
          date: '2024-08-07',
          searchVolume: 2372,
          organicRank: 16
        },
        {
          date: '2024-08-06',
          searchVolume: 2372,
          organicRank: 16
        },
        {
          date: '2024-08-05',
          searchVolume: 2372,
          organicRank: 18
        },
        {
          date: '2024-08-04',
          searchVolume: 2372,
          organicRank: 17
        },
        {
          date: '2024-08-03',
          searchVolume: 2372,
          organicRank: 17
        },
        {
          date: '2024-08-02',
          searchVolume: 2353,
          organicRank: 18
        },
        {
          date: '2024-08-01',
          searchVolume: 2353,
          organicRank: 17,
          sponsoredRank: 5
        },
        {
          date: '2024-07-31',
          searchVolume: 2353,
          organicRank: 17
        },
        {
          date: '2024-07-30',
          searchVolume: 2353,
          organicRank: 18
        },
        {
          date: '2024-07-29',
          searchVolume: 2353,
          organicRank: 21
        },
        {
          date: '2024-07-28',
          searchVolume: 2353,
          organicRank: 21
        },
        {
          date: '2024-07-27',
          searchVolume: 2353,
          organicRank: 21
        },
        {
          date: '2024-07-26',
          searchVolume: 2073,
          organicRank: 19
        },
        {
          date: '2024-07-25',
          searchVolume: 2073,
          organicRank: 20
        },
        {
          date: '2024-07-24',
          searchVolume: 2073,
          organicRank: 21
        },
        {
          date: '2024-07-23',
          searchVolume: 2073,
          organicRank: 18
        },
        {
          date: '2024-07-22',
          searchVolume: 2073,
          organicRank: 24
        },
        {
          date: '2024-07-21',
          searchVolume: 2073,
          organicRank: 29
        },
        {
          date: '2024-07-20',
          searchVolume: 2073,
          organicRank: 36
        },
        {
          date: '2024-07-19',
          searchVolume: 2278,
          organicRank: 30
        },
        {
          date: '2024-07-18',
          searchVolume: 2278,
          organicRank: 51
        },
        {
          date: '2024-07-17',
          searchVolume: 2278,
          organicRank: 47
        },
        {
          date: '2024-07-16',
          searchVolume: 2278,
          organicRank: 42
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser charger',
      keyword: 'water flosser charger',
      searchVolume: 1367,
      organicStartRank: 217,
      organicHighestRank: 128,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1869,
          organicRank: 141,

          keywordSales: 98
        },
        {
          date: '2024-09-09',
          searchVolume: 1869,
          organicRank: 128,

          keywordSales: 98
        },
        {
          date: '2024-09-08',
          searchVolume: 1869,
          organicRank: 156,

          keywordSales: 98
        },
        {
          date: '2024-09-07',
          searchVolume: 1869,
          organicRank: 160,
          sponsoredRank: 3,
          keywordSales: 98
        },
        {
          date: '2024-09-06',
          searchVolume: 1869,
          organicRank: 164,

          keywordSales: 98
        },
        {
          date: '2024-09-05',
          searchVolume: 1869,
          organicRank: 153,
          sponsoredRank: 10,
          keywordSales: 98
        },
        {
          date: '2024-09-04',
          searchVolume: 1869,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-09-02',
          searchVolume: 1869,
          organicRank: 208,
          sponsoredRank: 37,
          keywordSales: 98
        },
        {
          date: '2024-08-29',
          searchVolume: 1923,
          organicRank: 173,

          keywordSales: 98
        },
        {
          date: '2024-08-28',
          searchVolume: 1923,
          organicRank: 178,

          keywordSales: 98
        },
        {
          date: '2024-08-27',
          searchVolume: 1923,
          organicRank: 158,

          keywordSales: 98
        },
        {
          date: '2024-08-26',
          searchVolume: 1923,
          organicRank: 159,

          keywordSales: 98
        },
        {
          date: '2024-08-25',
          searchVolume: 1923,
          organicRank: 177,

          keywordSales: 98
        },
        {
          date: '2024-08-24',
          searchVolume: 1923,
          organicRank: 156,
          sponsoredRank: 32,
          keywordSales: 98
        },
        {
          date: '2024-08-22',
          searchVolume: 1934,
          organicRank: 159,

          keywordSales: 98
        },
        {
          date: '2024-08-21',
          searchVolume: 1934,
          organicRank: 157,

          keywordSales: 98
        },
        {
          date: '2024-08-20',
          searchVolume: 1934,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-19',
          searchVolume: 1934,
          organicRank: 167,

          keywordSales: 98
        },
        {
          date: '2024-08-18',
          searchVolume: 1934,
          organicRank: 169,
          sponsoredRank: 28,
          keywordSales: 98
        },
        {
          date: '2024-08-16',
          searchVolume: 1832,
          organicRank: 168,

          keywordSales: 98
        },
        {
          date: '2024-08-15',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-14',
          searchVolume: 1832,
          organicRank: 138,
          sponsoredRank: 24,
          keywordSales: 98
        },
        {
          date: '2024-08-13',
          searchVolume: 1832,
          organicRank: 158,

          keywordSales: 98
        },
        {
          date: '2024-08-12',
          searchVolume: 1832,
          organicRank: 155,

          keywordSales: 98
        },
        {
          date: '2024-08-11',
          searchVolume: 1832,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 98
        },
        {
          date: '2024-08-10',
          searchVolume: 1832,
          organicRank: 202,

          keywordSales: 98
        },
        {
          date: '2024-08-09',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-08',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-07',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-06',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-05',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-04',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-03',
          searchVolume: 1499,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-02',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 98
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 98
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 153,
          sponsoredRank: 27,
          keywordSales: 98
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 149,

          keywordSales: 98
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 154,
          sponsoredRank: 48,
          keywordSales: 98
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 142,

          keywordSales: 98
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 153,

          keywordSales: 98
        },
        {
          date: '2024-07-18',
          searchVolume: 1268,
          organicRank: 217,

          keywordSales: 98
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser cordless',
      keyword: 'water flosser cordless',
      searchVolume: 10292,
      organicStartRank: 18,
      organicHighestRank: 2,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 8223,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-09-09',
          searchVolume: 8223,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-09-08',
          searchVolume: 8223,
          organicRank: 8,

          keywordSales: 348
        },
        {
          date: '2024-09-07',
          searchVolume: 8223,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 348
        },
        {
          date: '2024-09-06',
          searchVolume: 8223,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-09-05',
          searchVolume: 8223,
          organicRank: 5,
          sponsoredRank: 6,
          keywordSales: 348
        },
        {
          date: '2024-09-01',
          searchVolume: 8223,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 348
        },
        {
          date: '2024-08-28',
          searchVolume: 8480,
          organicRank: 7,

          keywordSales: 348
        },
        {
          date: '2024-08-27',
          searchVolume: 8480,
          organicRank: 6,
          sponsoredRank: 4,
          keywordSales: 348
        },
        {
          date: '2024-08-26',
          searchVolume: 8480,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-08-25',
          searchVolume: 8480,
          organicRank: 5,

          keywordSales: 348
        },
        {
          date: '2024-08-24',
          searchVolume: 8480,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-08-23',
          searchVolume: 11054,
          organicRank: 6,

          keywordSales: 348
        },
        {
          date: '2024-08-22',
          searchVolume: 11054,
          organicRank: 7,
          sponsoredRank: 6,
          keywordSales: 348
        },
        {
          date: '2024-08-20',
          searchVolume: 11054,
          organicRank: 5,

          keywordSales: 348
        },
        {
          date: '2024-08-19',
          searchVolume: 11054,
          organicRank: 3,

          keywordSales: 348
        },
        {
          date: '2024-08-17',
          searchVolume: 11054,
          organicRank: 5,
          sponsoredRank: 31,
          keywordSales: 348
        },
        {
          date: '2024-08-16',
          searchVolume: 8092,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-08-15',
          searchVolume: 8092,
          organicRank: 5,

          keywordSales: 348
        },
        {
          date: '2024-08-14',
          searchVolume: 8092,
          organicRank: 4,

          keywordSales: 348
        },
        {
          date: '2024-08-13',
          searchVolume: 8092,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-12',
          searchVolume: 8092,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-11',
          searchVolume: 8092,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-10',
          searchVolume: 8092,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 348
        },
        {
          date: '2024-08-09',
          searchVolume: 6284,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-08',
          searchVolume: 6284,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-07',
          searchVolume: 6284,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-06',
          searchVolume: 6284,
          organicRank: 3,

          keywordSales: 348
        },
        {
          date: '2024-08-05',
          searchVolume: 6284,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-04',
          searchVolume: 6284,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-03',
          searchVolume: 6284,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-08-02',
          searchVolume: 5527,
          organicRank: 3,

          keywordSales: 348
        },
        {
          date: '2024-08-01',
          searchVolume: 5527,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 348
        },
        {
          date: '2024-07-31',
          searchVolume: 5527,
          organicRank: 3,

          keywordSales: 348
        },
        {
          date: '2024-07-30',
          searchVolume: 5527,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-07-29',
          searchVolume: 5527,
          organicRank: 6,
          sponsoredRank: 45,
          keywordSales: 348
        },
        {
          date: '2024-07-28',
          searchVolume: 5527,
          organicRank: 3,
          sponsoredRank: 6,
          keywordSales: 348
        },
        {
          date: '2024-07-27',
          searchVolume: 5527,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 348
        },
        {
          date: '2024-07-26',
          searchVolume: 10292,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 348
        },
        {
          date: '2024-07-25',
          searchVolume: 10292,
          organicRank: 5,

          keywordSales: 348
        },
        {
          date: '2024-07-24',
          searchVolume: 10292,
          organicRank: 7,

          keywordSales: 348
        },
        {
          date: '2024-07-23',
          searchVolume: 10292,
          organicRank: 3,

          keywordSales: 348
        },
        {
          date: '2024-07-22',
          searchVolume: 10292,
          organicRank: 8,

          keywordSales: 348
        },
        {
          date: '2024-07-21',
          searchVolume: 10292,
          organicRank: 10,

          keywordSales: 348
        },
        {
          date: '2024-07-20',
          searchVolume: 10292,
          organicRank: 8,

          keywordSales: 348
        },
        {
          date: '2024-07-19',
          searchVolume: 6329,
          organicRank: 11,

          keywordSales: 348
        },
        {
          date: '2024-07-18',
          searchVolume: 6329,
          organicRank: 18,

          keywordSales: 348
        },
        {
          date: '2024-07-17',
          searchVolume: 6329,
          organicRank: 11,

          keywordSales: 348
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser for braces',
      keyword: 'water flosser for braces',
      searchVolume: 4126,
      organicStartRank: 58,
      organicHighestRank: 5,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3138,
          organicRank: 20,

          keywordSales: 117
        },
        {
          date: '2024-09-09',
          searchVolume: 3138,
          organicRank: 46,
          sponsoredRank: 1,
          keywordSales: 117
        },
        {
          date: '2024-09-08',
          searchVolume: 3138,
          organicRank: 49,
          sponsoredRank: 7,
          keywordSales: 117
        },
        {
          date: '2024-09-07',
          searchVolume: 3138,
          organicRank: 22,
          sponsoredRank: 5,
          keywordSales: 117
        },
        {
          date: '2024-09-06',
          searchVolume: 3138,
          organicRank: 48,
          sponsoredRank: 21,
          keywordSales: 117
        },
        {
          date: '2024-09-05',
          searchVolume: 3138,
          organicRank: 26,
          sponsoredRank: 42,
          keywordSales: 117
        },
        {
          date: '2024-09-02',
          searchVolume: 3138,
          organicRank: 21,
          sponsoredRank: 11,
          keywordSales: 117
        },
        {
          date: '2024-09-01',
          searchVolume: 3138,
          organicRank: 46,
          sponsoredRank: 47,
          keywordSales: 117
        },
        {
          date: '2024-08-31',
          searchVolume: 3138,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 117
        },
        {
          date: '2024-08-30',
          searchVolume: 3629,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 117
        },
        {
          date: '2024-08-29',
          searchVolume: 3629,
          organicRank: 17,
          sponsoredRank: 8,
          keywordSales: 117
        },
        {
          date: '2024-08-28',
          searchVolume: 3629,
          organicRank: 46,

          keywordSales: 117
        },
        {
          date: '2024-08-27',
          searchVolume: 3629,
          organicRank: 46,

          keywordSales: 117
        },
        {
          date: '2024-08-26',
          searchVolume: 3629,
          organicRank: 10,

          keywordSales: 117
        },
        {
          date: '2024-08-25',
          searchVolume: 3629,
          organicRank: 47,

          keywordSales: 117
        },
        {
          date: '2024-08-24',
          searchVolume: 3629,
          organicRank: 14,

          keywordSales: 117
        },
        {
          date: '2024-08-20',
          searchVolume: 4549,
          organicRank: 43,

          keywordSales: 117
        },
        {
          date: '2024-08-19',
          searchVolume: 4549,
          organicRank: 10,

          keywordSales: 117
        },
        {
          date: '2024-08-16',
          searchVolume: 4174,
          organicRank: 12,

          keywordSales: 117
        },
        {
          date: '2024-08-15',
          searchVolume: 4174,
          organicRank: 10,
          sponsoredRank: 43,
          keywordSales: 117
        },
        {
          date: '2024-08-14',
          searchVolume: 4174,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 117
        },
        {
          date: '2024-08-13',
          searchVolume: 4174,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 117
        },
        {
          date: '2024-08-11',
          searchVolume: 4174,
          organicRank: 13,
          sponsoredRank: 3,
          keywordSales: 117
        },
        {
          date: '2024-08-10',
          searchVolume: 4174,
          organicRank: 12,
          sponsoredRank: 5,
          keywordSales: 117
        },
        {
          date: '2024-08-09',
          searchVolume: 4026,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 117
        },
        {
          date: '2024-08-08',
          searchVolume: 4026,
          organicRank: 18,
          sponsoredRank: 7,
          keywordSales: 117
        },
        {
          date: '2024-08-07',
          searchVolume: 4026,
          organicRank: 13,
          sponsoredRank: 19,
          keywordSales: 117
        },
        {
          date: '2024-08-06',
          searchVolume: 4026,
          organicRank: 17,
          sponsoredRank: 8,
          keywordSales: 117
        },
        {
          date: '2024-08-05',
          searchVolume: 4026,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 117
        },
        {
          date: '2024-08-04',
          searchVolume: 4026,
          organicRank: 16,
          sponsoredRank: 43,
          keywordSales: 117
        },
        {
          date: '2024-08-03',
          searchVolume: 4026,
          organicRank: 20,
          sponsoredRank: 5,
          keywordSales: 117
        },
        {
          date: '2024-08-02',
          searchVolume: 3911,
          organicRank: 19,
          sponsoredRank: 11,
          keywordSales: 117
        },
        {
          date: '2024-08-01',
          searchVolume: 3911,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 117
        },
        {
          date: '2024-07-31',
          searchVolume: 3911,
          organicRank: 19,

          keywordSales: 117
        },
        {
          date: '2024-07-30',
          searchVolume: 3911,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 117
        },
        {
          date: '2024-07-29',
          searchVolume: 3911,
          organicRank: 18,

          keywordSales: 117
        },
        {
          date: '2024-07-28',
          searchVolume: 3911,
          organicRank: 23,

          keywordSales: 117
        },
        {
          date: '2024-07-27',
          searchVolume: 3911,
          organicRank: 24,

          keywordSales: 117
        },
        {
          date: '2024-07-26',
          searchVolume: 4126,
          organicRank: 22,

          keywordSales: 117
        },
        {
          date: '2024-07-25',
          searchVolume: 4126,
          organicRank: 35,

          keywordSales: 117
        },
        {
          date: '2024-07-24',
          searchVolume: 4126,
          organicRank: 30,

          keywordSales: 117
        },
        {
          date: '2024-07-23',
          searchVolume: 4126,
          organicRank: 25,

          keywordSales: 117
        },
        {
          date: '2024-07-22',
          searchVolume: 4126,
          organicRank: 41,

          keywordSales: 117
        },
        {
          date: '2024-07-21',
          searchVolume: 4126,
          organicRank: 42,

          keywordSales: 117
        },
        {
          date: '2024-07-20',
          searchVolume: 4126,
          organicRank: 43,

          keywordSales: 117
        },
        {
          date: '2024-07-19',
          searchVolume: 3396,
          organicRank: 52,

          keywordSales: 117
        },
        {
          date: '2024-07-18',
          searchVolume: 3396,
          organicRank: 58,

          keywordSales: 117
        },
        {
          date: '2024-07-17',
          searchVolume: 3396,
          organicRank: 54,

          keywordSales: 117
        },
        {
          date: '2024-07-16',
          searchVolume: 3396,
          organicRank: 54,

          keywordSales: 117
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser for kids',
      keyword: 'water flosser for kids',
      searchVolume: 2319,
      organicStartRank: 306,
      organicHighestRank: 25,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2474,
          organicRank: 57,

          keywordSales: 72
        },
        {
          date: '2024-09-09',
          searchVolume: 2474,
          organicRank: 63,

          keywordSales: 72
        },
        {
          date: '2024-09-08',
          searchVolume: 2474,
          organicRank: 65,

          keywordSales: 72
        },
        {
          date: '2024-09-07',
          searchVolume: 2474,
          organicRank: 45,
          sponsoredRank: 15,
          keywordSales: 72
        },
        {
          date: '2024-09-06',
          searchVolume: 2474,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-09-05',
          searchVolume: 2474,
          organicRank: 46,

          keywordSales: 72
        },
        {
          date: '2024-09-04',
          searchVolume: 2474,
          organicRank: 63,
          sponsoredRank: 22,
          keywordSales: 72
        },
        {
          date: '2024-09-02',
          searchVolume: 2474,
          organicRank: 64,
          sponsoredRank: 22,
          keywordSales: 72
        },
        {
          date: '2024-08-29',
          searchVolume: 2552,
          organicRank: 46,

          keywordSales: 72
        },
        {
          date: '2024-08-28',
          searchVolume: 2552,
          organicRank: 58,

          keywordSales: 72
        },
        {
          date: '2024-08-27',
          searchVolume: 2552,
          organicRank: 49,

          keywordSales: 72
        },
        {
          date: '2024-08-26',
          searchVolume: 2552,
          organicRank: 48,

          keywordSales: 72
        },
        {
          date: '2024-08-25',
          searchVolume: 2552,
          organicRank: 44,

          keywordSales: 72
        },
        {
          date: '2024-08-24',
          searchVolume: 2552,
          organicRank: 129,

          keywordSales: 72
        },
        {
          date: '2024-08-22',
          searchVolume: 2816,
          organicRank: 61,

          keywordSales: 72
        },
        {
          date: '2024-08-21',
          searchVolume: 2816,
          organicRank: 43,

          keywordSales: 72
        },
        {
          date: '2024-08-20',
          searchVolume: 2816,
          organicRank: 25,

          keywordSales: 72
        },
        {
          date: '2024-08-19',
          searchVolume: 2816,
          organicRank: 50,

          keywordSales: 72
        },
        {
          date: '2024-08-18',
          searchVolume: 2816,
          organicRank: 43,
          sponsoredRank: 61,
          keywordSales: 72
        },
        {
          date: '2024-08-16',
          searchVolume: 2431,
          organicRank: 39,

          keywordSales: 72
        },
        {
          date: '2024-08-15',
          searchVolume: 2431,
          organicRank: 45,

          keywordSales: 72
        },
        {
          date: '2024-08-14',
          searchVolume: 2431,
          organicRank: 52,

          keywordSales: 72
        },
        {
          date: '2024-08-13',
          searchVolume: 2431,
          organicRank: 43,

          keywordSales: 72
        },
        {
          date: '2024-08-12',
          searchVolume: 2431,
          organicRank: 45,

          keywordSales: 72
        },
        {
          date: '2024-08-11',
          searchVolume: 2431,
          organicRank: 49,
          sponsoredRank: 2,
          keywordSales: 72
        },
        {
          date: '2024-08-10',
          searchVolume: 2431,
          organicRank: 55,

          keywordSales: 72
        },
        {
          date: '2024-08-09',
          searchVolume: 2097,
          organicRank: 55,

          keywordSales: 72
        },
        {
          date: '2024-08-08',
          searchVolume: 2097,
          organicRank: 55,

          keywordSales: 72
        },
        {
          date: '2024-08-07',
          searchVolume: 2097,
          organicRank: 63,

          keywordSales: 72
        },
        {
          date: '2024-08-06',
          searchVolume: 2097,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-08-05',
          searchVolume: 2097,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-08-04',
          searchVolume: 2097,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-08-03',
          searchVolume: 2097,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 72
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 72
        },
        {
          date: '2024-07-30',
          searchVolume: 2319,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 90
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser oral b',
      keyword: 'water flosser oral b',
      searchVolume: 1610,
      organicStartRank: 5,
      organicHighestRank: 5,

      organicPageOneWinDate: '2024-07-30',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1610,
          organicRank: 5,

          keywordSales: 45
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser portable',
      keyword: 'water flosser portable',
      searchVolume: 1341,
      organicStartRank: 22,
      organicHighestRank: 2,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 922,
          organicRank: 7,

          keywordSales: 9
        },
        {
          date: '2024-09-09',
          searchVolume: 922,
          organicRank: 10,
          sponsoredRank: 58,
          keywordSales: 9
        },
        {
          date: '2024-09-08',
          searchVolume: 922,
          organicRank: 5,

          keywordSales: 9
        },
        {
          date: '2024-09-07',
          searchVolume: 922,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 9
        },
        {
          date: '2024-09-05',
          searchVolume: 922,
          organicRank: 16,

          keywordSales: 9
        },
        {
          date: '2024-08-28',
          searchVolume: 884,
          organicRank: 9,

          keywordSales: 9
        },
        {
          date: '2024-08-27',
          searchVolume: 884,
          organicRank: 2,

          keywordSales: 9
        },
        {
          date: '2024-08-26',
          searchVolume: 884,
          organicRank: 4,

          keywordSales: 9
        },
        {
          date: '2024-08-25',
          searchVolume: 884,
          organicRank: 6,

          keywordSales: 9
        },
        {
          date: '2024-08-24',
          searchVolume: 884,
          organicRank: 6,

          keywordSales: 9
        },
        {
          date: '2024-08-23',
          searchVolume: 1430,
          organicRank: 8,

          keywordSales: 9
        },
        {
          date: '2024-08-22',
          searchVolume: 1430,
          organicRank: 31,
          sponsoredRank: 5,
          keywordSales: 9
        },
        {
          date: '2024-08-20',
          searchVolume: 1430,
          organicRank: 9,

          keywordSales: 9
        },
        {
          date: '2024-08-19',
          searchVolume: 1430,
          organicRank: 2,

          keywordSales: 9
        },
        {
          date: '2024-08-16',
          searchVolume: 773,
          organicRank: 5,

          keywordSales: 9
        },
        {
          date: '2024-08-15',
          searchVolume: 773,
          organicRank: 6,

          keywordSales: 9
        },
        {
          date: '2024-08-14',
          searchVolume: 773,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-08-13',
          searchVolume: 773,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 9
        },
        {
          date: '2024-08-12',
          searchVolume: 773,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-08-11',
          searchVolume: 773,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-08-10',
          searchVolume: 773,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 9
        },
        {
          date: '2024-08-09',
          searchVolume: 628,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-08-08',
          searchVolume: 628,
          organicRank: 6,

          keywordSales: 9
        },
        {
          date: '2024-08-07',
          searchVolume: 628,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 9
        },
        {
          date: '2024-08-06',
          searchVolume: 628,
          organicRank: 2,

          keywordSales: 9
        },
        {
          date: '2024-08-05',
          searchVolume: 628,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 9
        },
        {
          date: '2024-08-04',
          searchVolume: 628,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 9
        },
        {
          date: '2024-08-03',
          searchVolume: 628,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-08-02',
          searchVolume: 690,
          organicRank: 11,

          keywordSales: 9
        },
        {
          date: '2024-08-01',
          searchVolume: 690,
          organicRank: 13,
          sponsoredRank: 5,
          keywordSales: 9
        },
        {
          date: '2024-07-31',
          searchVolume: 690,
          organicRank: 10,

          keywordSales: 9
        },
        {
          date: '2024-07-30',
          searchVolume: 690,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 9
        },
        {
          date: '2024-07-29',
          searchVolume: 690,
          organicRank: 13,

          keywordSales: 9
        },
        {
          date: '2024-07-28',
          searchVolume: 690,
          organicRank: 13,

          keywordSales: 9
        },
        {
          date: '2024-07-27',
          searchVolume: 690,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-07-26',
          searchVolume: 1341,
          organicRank: 14,
          sponsoredRank: 10,
          keywordSales: 9
        },
        {
          date: '2024-07-25',
          searchVolume: 1341,
          organicRank: 14,

          keywordSales: 9
        },
        {
          date: '2024-07-24',
          searchVolume: 1341,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 9
        },
        {
          date: '2024-07-23',
          searchVolume: 1341,
          organicRank: 12,

          keywordSales: 9
        },
        {
          date: '2024-07-22',
          searchVolume: 1341,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 9
        },
        {
          date: '2024-07-21',
          searchVolume: 1341,
          organicRank: 16,

          keywordSales: 9
        },
        {
          date: '2024-07-20',
          searchVolume: 1341,
          organicRank: 19,

          keywordSales: 9
        },
        {
          date: '2024-07-19',
          searchVolume: 703,
          organicRank: 15,

          keywordSales: 9
        },
        {
          date: '2024-07-18',
          searchVolume: 703,
          organicRank: 22,

          keywordSales: 9
        },
        {
          date: '2024-07-17',
          searchVolume: 703,
          organicRank: 24,

          keywordSales: 9
        },
        {
          date: '2024-07-16',
          searchVolume: 703,
          organicRank: 23,

          keywordSales: 9
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser rechargeable',
      keyword: 'water flosser rechargeable',
      searchVolume: 3461,
      organicStartRank: 16,
      organicHighestRank: 1,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1874,
          organicRank: 4,

          keywordSales: 76
        },
        {
          date: '2024-09-09',
          searchVolume: 1874,
          organicRank: 9,

          keywordSales: 76
        },
        {
          date: '2024-09-08',
          searchVolume: 1874,
          organicRank: 7,

          keywordSales: 76
        },
        {
          date: '2024-09-07',
          searchVolume: 1874,
          organicRank: 8,

          keywordSales: 76
        },
        {
          date: '2024-09-05',
          searchVolume: 1874,
          organicRank: 4,

          keywordSales: 76
        },
        {
          date: '2024-09-04',
          searchVolume: 1874,
          organicRank: 9,

          keywordSales: 76
        },
        {
          date: '2024-09-03',
          searchVolume: 1874,
          organicRank: 5,

          keywordSales: 76
        },
        {
          date: '2024-09-01',
          searchVolume: 1874,
          organicRank: 8,
          sponsoredRank: 7,
          keywordSales: 76
        },
        {
          date: '2024-08-31',
          searchVolume: 1874,
          organicRank: 7,

          keywordSales: 76
        },
        {
          date: '2024-08-28',
          searchVolume: 2186,
          organicRank: 4,

          keywordSales: 76
        },
        {
          date: '2024-08-27',
          searchVolume: 2186,
          organicRank: 3,

          keywordSales: 76
        },
        {
          date: '2024-08-26',
          searchVolume: 2186,
          organicRank: 3,

          keywordSales: 76
        },
        {
          date: '2024-08-25',
          searchVolume: 2186,
          organicRank: 5,
          sponsoredRank: 12,
          keywordSales: 76
        },
        {
          date: '2024-08-24',
          searchVolume: 2186,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 76
        },
        {
          date: '2024-08-23',
          searchVolume: 2548,
          organicRank: 8,

          keywordSales: 76
        },
        {
          date: '2024-08-22',
          searchVolume: 2548,
          organicRank: 3,

          keywordSales: 76
        },
        {
          date: '2024-08-21',
          searchVolume: 2548,
          organicRank: 11,

          keywordSales: 76
        },
        {
          date: '2024-08-20',
          searchVolume: 2548,
          organicRank: 11,

          keywordSales: 76
        },
        {
          date: '2024-08-19',
          searchVolume: 2548,
          organicRank: 10,

          keywordSales: 76
        },
        {
          date: '2024-08-18',
          searchVolume: 2548,
          organicRank: 11,
          sponsoredRank: 48,
          keywordSales: 76
        },
        {
          date: '2024-08-15',
          searchVolume: 2434,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 76
        },
        {
          date: '2024-08-14',
          searchVolume: 2434,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-08-13',
          searchVolume: 2434,
          organicRank: 8,
          sponsoredRank: 8,
          keywordSales: 76
        },
        {
          date: '2024-08-12',
          searchVolume: 2434,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 76
        },
        {
          date: '2024-08-11',
          searchVolume: 2434,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 76
        },
        {
          date: '2024-08-10',
          searchVolume: 2434,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 76
        },
        {
          date: '2024-08-09',
          searchVolume: 2263,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 76
        },
        {
          date: '2024-08-08',
          searchVolume: 2263,
          organicRank: 6,
          sponsoredRank: 7,
          keywordSales: 76
        },
        {
          date: '2024-08-07',
          searchVolume: 2263,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-08-06',
          searchVolume: 2263,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 76
        },
        {
          date: '2024-08-05',
          searchVolume: 2263,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-08-04',
          searchVolume: 2263,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-08-03',
          searchVolume: 2263,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 76
        },
        {
          date: '2024-08-02',
          searchVolume: 2243,
          organicRank: 2,

          keywordSales: 76
        },
        {
          date: '2024-08-01',
          searchVolume: 2243,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-07-31',
          searchVolume: 2243,
          organicRank: 3,

          keywordSales: 76
        },
        {
          date: '2024-07-30',
          searchVolume: 2243,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 76
        },
        {
          date: '2024-07-29',
          searchVolume: 2243,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 76
        },
        {
          date: '2024-07-28',
          searchVolume: 2243,
          organicRank: 5,
          sponsoredRank: 34,
          keywordSales: 76
        },
        {
          date: '2024-07-27',
          searchVolume: 2243,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 76
        },
        {
          date: '2024-07-26',
          searchVolume: 3461,
          organicRank: 5,
          sponsoredRank: 9,
          keywordSales: 76
        },
        {
          date: '2024-07-25',
          searchVolume: 3461,
          organicRank: 6,

          keywordSales: 76
        },
        {
          date: '2024-07-24',
          searchVolume: 3461,
          organicRank: 1,

          keywordSales: 76
        },
        {
          date: '2024-07-23',
          searchVolume: 3461,
          organicRank: 5,

          keywordSales: 76
        },
        {
          date: '2024-07-22',
          searchVolume: 3461,
          organicRank: 6,

          keywordSales: 76
        },
        {
          date: '2024-07-21',
          searchVolume: 3461,
          organicRank: 7,

          keywordSales: 76
        },
        {
          date: '2024-07-20',
          searchVolume: 3461,
          organicRank: 7,

          keywordSales: 76
        },
        {
          date: '2024-07-19',
          searchVolume: 2393,
          organicRank: 11,

          keywordSales: 76
        },
        {
          date: '2024-07-18',
          searchVolume: 2393,
          organicRank: 16,

          keywordSales: 76
        },
        {
          date: '2024-07-17',
          searchVolume: 2393,
          organicRank: 15,

          keywordSales: 76
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser tonsil stone remover',
      keyword: 'water flosser tonsil stone remover',
      searchVolume: 844,
      organicStartRank: 67,
      organicHighestRank: 13,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-30',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 924,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-09-09',
          searchVolume: 924,
          organicRank: 28,

          keywordSales: 34
        },
        {
          date: '2024-09-08',
          searchVolume: 924,
          organicRank: 26,
          sponsoredRank: 15,
          keywordSales: 34
        },
        {
          date: '2024-09-07',
          searchVolume: 924,
          organicRank: 30,

          keywordSales: 34
        },
        {
          date: '2024-09-06',
          searchVolume: 924,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-09-05',
          searchVolume: 924,
          organicRank: 22,
          sponsoredRank: 45,
          keywordSales: 34
        },
        {
          date: '2024-09-04',
          searchVolume: 924,
          organicRank: 20,
          sponsoredRank: 52,
          keywordSales: 34
        },
        {
          date: '2024-09-01',
          searchVolume: 924,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-08-28',
          searchVolume: 951,
          organicRank: 17,

          keywordSales: 34
        },
        {
          date: '2024-08-27',
          searchVolume: 951,
          organicRank: 20,

          keywordSales: 34
        },
        {
          date: '2024-08-26',
          searchVolume: 951,
          organicRank: 16,

          keywordSales: 34
        },
        {
          date: '2024-08-25',
          searchVolume: 951,
          organicRank: 21,

          keywordSales: 34
        },
        {
          date: '2024-08-24',
          searchVolume: 951,
          organicRank: 17,

          keywordSales: 34
        },
        {
          date: '2024-08-23',
          searchVolume: 747,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-08-22',
          searchVolume: 747,
          organicRank: 20,

          keywordSales: 34
        },
        {
          date: '2024-08-21',
          searchVolume: 747,
          organicRank: 24,
          sponsoredRank: 5,
          keywordSales: 34
        },
        {
          date: '2024-08-20',
          searchVolume: 747,
          organicRank: 20,

          keywordSales: 34
        },
        {
          date: '2024-08-19',
          searchVolume: 747,
          organicRank: 20,

          keywordSales: 34
        },
        {
          date: '2024-08-17',
          searchVolume: 747,
          organicRank: 16,

          keywordSales: 34
        },
        {
          date: '2024-08-16',
          searchVolume: 713,
          organicRank: 18,

          keywordSales: 34
        },
        {
          date: '2024-08-15',
          searchVolume: 713,
          organicRank: 21,

          keywordSales: 34
        },
        {
          date: '2024-08-14',
          searchVolume: 713,
          organicRank: 19,

          keywordSales: 34
        },
        {
          date: '2024-08-13',
          searchVolume: 713,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-08-12',
          searchVolume: 713,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-08-11',
          searchVolume: 713,
          organicRank: 29,
          sponsoredRank: 8,
          keywordSales: 34
        },
        {
          date: '2024-08-10',
          searchVolume: 713,
          organicRank: 28,

          keywordSales: 34
        },
        {
          date: '2024-08-09',
          searchVolume: 473,
          organicRank: 27,

          keywordSales: 34
        },
        {
          date: '2024-08-08',
          searchVolume: 473,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-08-07',
          searchVolume: 473,
          organicRank: 13,

          keywordSales: 34
        },
        {
          date: '2024-08-06',
          searchVolume: 473,
          organicRank: 19,

          keywordSales: 34
        },
        {
          date: '2024-08-05',
          searchVolume: 473,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-08-04',
          searchVolume: 473,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-08-03',
          searchVolume: 473,
          organicRank: 17,

          keywordSales: 34
        },
        {
          date: '2024-08-02',
          searchVolume: 817,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-08-01',
          searchVolume: 817,
          organicRank: 26,
          sponsoredRank: 18,
          keywordSales: 34
        },
        {
          date: '2024-07-31',
          searchVolume: 817,
          organicRank: 17,

          keywordSales: 34
        },
        {
          date: '2024-07-30',
          searchVolume: 817,
          organicRank: 20,

          keywordSales: 34
        },
        {
          date: '2024-07-29',
          searchVolume: 817,
          organicRank: 30,

          keywordSales: 34
        },
        {
          date: '2024-07-28',
          searchVolume: 817,
          organicRank: 21,

          keywordSales: 34
        },
        {
          date: '2024-07-27',
          searchVolume: 817,
          organicRank: 21,
          sponsoredRank: 11,
          keywordSales: 34
        },
        {
          date: '2024-07-26',
          searchVolume: 844,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-25',
          searchVolume: 844,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-07-24',
          searchVolume: 844,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-23',
          searchVolume: 844,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-22',
          searchVolume: 844,
          organicRank: 30,

          keywordSales: 34
        },
        {
          date: '2024-07-21',
          searchVolume: 844,
          organicRank: 33,

          keywordSales: 34
        },
        {
          date: '2024-07-20',
          searchVolume: 844,
          organicRank: 48,

          keywordSales: 34
        },
        {
          date: '2024-07-19',
          searchVolume: 644,
          organicRank: 52,

          keywordSales: 34
        },
        {
          date: '2024-07-18',
          searchVolume: 644,
          organicRank: 67,

          keywordSales: 34
        },
        {
          date: '2024-07-17',
          searchVolume: 644,
          organicRank: 63,

          keywordSales: 34
        },
        {
          date: '2024-07-16',
          searchVolume: 644,
          organicRank: 57,

          keywordSales: 34
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser travel',
      keyword: 'water flosser travel',
      searchVolume: 1339,
      organicStartRank: 34,
      organicHighestRank: 6,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 922,
          organicRank: 13,

          keywordSales: 12
        },
        {
          date: '2024-09-09',
          searchVolume: 922,
          organicRank: 12,

          keywordSales: 12
        },
        {
          date: '2024-09-08',
          searchVolume: 922,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-09-07',
          searchVolume: 922,
          organicRank: 12,
          sponsoredRank: 8,
          keywordSales: 12
        },
        {
          date: '2024-09-06',
          searchVolume: 922,
          organicRank: 13,

          keywordSales: 12
        },
        {
          date: '2024-09-05',
          searchVolume: 922,
          organicRank: 17,

          keywordSales: 12
        },
        {
          date: '2024-09-04',
          searchVolume: 922,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-09-02',
          searchVolume: 922,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-08-29',
          searchVolume: 884,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-08-28',
          searchVolume: 884,
          organicRank: 13,

          keywordSales: 12
        },
        {
          date: '2024-08-27',
          searchVolume: 884,
          organicRank: 11,

          keywordSales: 12
        },
        {
          date: '2024-08-26',
          searchVolume: 884,
          organicRank: 12,

          keywordSales: 12
        },
        {
          date: '2024-08-25',
          searchVolume: 884,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-08-24',
          searchVolume: 884,
          organicRank: 12,

          keywordSales: 12
        },
        {
          date: '2024-08-22',
          searchVolume: 953,
          organicRank: 18,

          keywordSales: 12
        },
        {
          date: '2024-08-21',
          searchVolume: 953,
          organicRank: 13,
          sponsoredRank: 10,
          keywordSales: 12
        },
        {
          date: '2024-08-20',
          searchVolume: 953,
          organicRank: 8,

          keywordSales: 12
        },
        {
          date: '2024-08-19',
          searchVolume: 953,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 12
        },
        {
          date: '2024-08-18',
          searchVolume: 953,
          organicRank: 16,

          keywordSales: 12
        },
        {
          date: '2024-08-16',
          searchVolume: 573,
          organicRank: 12,

          keywordSales: 12
        },
        {
          date: '2024-08-15',
          searchVolume: 573,
          organicRank: 7,

          keywordSales: 12
        },
        {
          date: '2024-08-14',
          searchVolume: 573,
          organicRank: 6,
          sponsoredRank: 12,
          keywordSales: 12
        },
        {
          date: '2024-08-13',
          searchVolume: 573,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-08-12',
          searchVolume: 573,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-08-11',
          searchVolume: 573,
          organicRank: 17,
          sponsoredRank: 6,
          keywordSales: 12
        },
        {
          date: '2024-08-10',
          searchVolume: 573,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 12
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 12
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 9,

          keywordSales: 12
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 14,

          keywordSales: 12
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 13,
          sponsoredRank: 8,
          keywordSales: 12
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-08-02',
          searchVolume: 690,
          organicRank: 18,

          keywordSales: 12
        },
        {
          date: '2024-08-01',
          searchVolume: 690,
          organicRank: 20,
          sponsoredRank: 6,
          keywordSales: 12
        },
        {
          date: '2024-07-31',
          searchVolume: 690,
          organicRank: 21,

          keywordSales: 12
        },
        {
          date: '2024-07-30',
          searchVolume: 690,
          organicRank: 20,
          sponsoredRank: 8,
          keywordSales: 12
        },
        {
          date: '2024-07-29',
          searchVolume: 690,
          organicRank: 21,

          keywordSales: 12
        },
        {
          date: '2024-07-28',
          searchVolume: 690,
          organicRank: 19,

          keywordSales: 12
        },
        {
          date: '2024-07-27',
          searchVolume: 690,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-07-26',
          searchVolume: 1339,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 12
        },
        {
          date: '2024-07-25',
          searchVolume: 1339,
          organicRank: 27,

          keywordSales: 12
        },
        {
          date: '2024-07-24',
          searchVolume: 1339,
          organicRank: 22,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-07-23',
          searchVolume: 1339,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-07-22',
          searchVolume: 1339,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 12
        },
        {
          date: '2024-07-21',
          searchVolume: 1339,
          organicRank: 21,

          keywordSales: 12
        },
        {
          date: '2024-07-20',
          searchVolume: 1339,
          organicRank: 22,

          keywordSales: 12
        },
        {
          date: '2024-07-19',
          searchVolume: 633,
          organicRank: 28,

          keywordSales: 12
        },
        {
          date: '2024-07-18',
          searchVolume: 633,
          organicRank: 34,

          keywordSales: 12
        },
        {
          date: '2024-07-17',
          searchVolume: 633,
          organicRank: 35,

          keywordSales: 12
        },
        {
          date: '2024-07-16',
          searchVolume: 633,
          organicRank: 38,

          keywordSales: 12
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flosser waterpik',
      keyword: 'water flosser waterpik',
      searchVolume: 2149,
      organicStartRank: 3,
      organicHighestRank: 1,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-11',
          searchVolume: 1640,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-09-10',
          searchVolume: 1640,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-09-09',
          searchVolume: 1640,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-09-08',
          searchVolume: 1640,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-09-07',
          searchVolume: 1640,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-09-05',
          searchVolume: 1640,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 57
        },
        {
          date: '2024-09-04',
          searchVolume: 1640,
          organicRank: 2,

          keywordSales: 57
        },
        {
          date: '2024-09-03',
          searchVolume: 1640,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-08-30',
          searchVolume: 2251,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-29',
          searchVolume: 2251,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 57
        },
        {
          date: '2024-08-28',
          searchVolume: 2251,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 57
        },
        {
          date: '2024-08-27',
          searchVolume: 2251,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-26',
          searchVolume: 2251,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-25',
          searchVolume: 2251,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-24',
          searchVolume: 2251,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-08-23',
          searchVolume: 1913,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-08-22',
          searchVolume: 1913,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-21',
          searchVolume: 1913,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-20',
          searchVolume: 1913,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-19',
          searchVolume: 1913,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-08-17',
          searchVolume: 1913,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-16',
          searchVolume: 1366,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-15',
          searchVolume: 1366,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-14',
          searchVolume: 1366,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-13',
          searchVolume: 1366,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 57
        },
        {
          date: '2024-08-12',
          searchVolume: 1366,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-11',
          searchVolume: 1366,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-10',
          searchVolume: 1366,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-09',
          searchVolume: 1253,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-08-08',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-07',
          searchVolume: 1253,
          organicRank: 2,

          keywordSales: 57
        },
        {
          date: '2024-08-06',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-05',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-04',
          searchVolume: 1253,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-08-03',
          searchVolume: 1253,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-08-02',
          searchVolume: 1171,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-08-01',
          searchVolume: 1171,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-31',
          searchVolume: 1171,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-07-30',
          searchVolume: 1171,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-29',
          searchVolume: 1171,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-28',
          searchVolume: 1171,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-27',
          searchVolume: 1171,
          organicRank: 1,

          keywordSales: 57
        },
        {
          date: '2024-07-26',
          searchVolume: 2149,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-25',
          searchVolume: 2149,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-24',
          searchVolume: 2149,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-23',
          searchVolume: 2149,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-22',
          searchVolume: 2149,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 57
        },
        {
          date: '2024-07-21',
          searchVolume: 2149,
          organicRank: 2,

          keywordSales: 57
        },
        {
          date: '2024-07-20',
          searchVolume: 2149,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-07-19',
          searchVolume: 1147,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-07-18',
          searchVolume: 1147,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-07-17',
          searchVolume: 1147,
          organicRank: 3,

          keywordSales: 57
        },
        {
          date: '2024-07-16',
          searchVolume: 1147,
          organicRank: 3,

          keywordSales: 57
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flossers',
      keyword: 'water flossers',
      searchVolume: 3321,
      organicStartRank: 26,
      organicHighestRank: 2,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2182,
          organicRank: 21,

          keywordSales: 75
        },
        {
          date: '2024-09-09',
          searchVolume: 2182,
          organicRank: 18,

          keywordSales: 75
        },
        {
          date: '2024-09-08',
          searchVolume: 2182,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 75
        },
        {
          date: '2024-09-07',
          searchVolume: 2182,
          organicRank: 18,

          keywordSales: 75
        },
        {
          date: '2024-09-06',
          searchVolume: 2182,
          organicRank: 15,

          keywordSales: 75
        },
        {
          date: '2024-09-05',
          searchVolume: 2182,
          organicRank: 14,

          keywordSales: 75
        },
        {
          date: '2024-09-04',
          searchVolume: 2182,
          organicRank: 14,

          keywordSales: 75
        },
        {
          date: '2024-09-01',
          searchVolume: 2182,
          organicRank: 23,
          sponsoredRank: 4,
          keywordSales: 75
        },
        {
          date: '2024-08-28',
          searchVolume: 2251,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 75
        },
        {
          date: '2024-08-27',
          searchVolume: 2251,
          organicRank: 13,

          keywordSales: 75
        },
        {
          date: '2024-08-26',
          searchVolume: 2251,
          organicRank: 16,

          keywordSales: 75
        },
        {
          date: '2024-08-25',
          searchVolume: 2251,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-08-24',
          searchVolume: 2251,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-08-23',
          searchVolume: 2552,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 75
        },
        {
          date: '2024-08-22',
          searchVolume: 2552,
          organicRank: 6,

          keywordSales: 75
        },
        {
          date: '2024-08-21',
          searchVolume: 2552,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-20',
          searchVolume: 2552,
          organicRank: 5,

          keywordSales: 75
        },
        {
          date: '2024-08-19',
          searchVolume: 2552,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-08-17',
          searchVolume: 2552,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-08-16',
          searchVolume: 2085,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-08-15',
          searchVolume: 2085,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-08-14',
          searchVolume: 2085,
          organicRank: 2,

          keywordSales: 75
        },
        {
          date: '2024-08-13',
          searchVolume: 2085,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-12',
          searchVolume: 2085,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-11',
          searchVolume: 2085,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-10',
          searchVolume: 2085,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-08-09',
          searchVolume: 2232,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-08',
          searchVolume: 2232,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-08-07',
          searchVolume: 2232,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-06',
          searchVolume: 2232,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-08-05',
          searchVolume: 2232,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-04',
          searchVolume: 2232,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-08-03',
          searchVolume: 2232,
          organicRank: 9,

          keywordSales: 75
        },
        {
          date: '2024-08-02',
          searchVolume: 2006,
          organicRank: 10,

          keywordSales: 75
        },
        {
          date: '2024-08-01',
          searchVolume: 2006,
          organicRank: 10,
          sponsoredRank: 6,
          keywordSales: 75
        },
        {
          date: '2024-07-31',
          searchVolume: 2006,
          organicRank: 12,

          keywordSales: 75
        },
        {
          date: '2024-07-30',
          searchVolume: 2006,
          organicRank: 10,

          keywordSales: 75
        },
        {
          date: '2024-07-29',
          searchVolume: 2006,
          organicRank: 11,

          keywordSales: 75
        },
        {
          date: '2024-07-28',
          searchVolume: 2006,
          organicRank: 12,
          sponsoredRank: 9,
          keywordSales: 75
        },
        {
          date: '2024-07-27',
          searchVolume: 2006,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 75
        },
        {
          date: '2024-07-26',
          searchVolume: 3321,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 75
        },
        {
          date: '2024-07-25',
          searchVolume: 3321,
          organicRank: 11,
          sponsoredRank: 6,
          keywordSales: 75
        },
        {
          date: '2024-07-24',
          searchVolume: 3321,
          organicRank: 8,

          keywordSales: 75
        },
        {
          date: '2024-07-23',
          searchVolume: 3321,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 75
        },
        {
          date: '2024-07-22',
          searchVolume: 3321,
          organicRank: 14,

          keywordSales: 75
        },
        {
          date: '2024-07-21',
          searchVolume: 3321,
          organicRank: 13,

          keywordSales: 75
        },
        {
          date: '2024-07-20',
          searchVolume: 3321,
          organicRank: 14,

          keywordSales: 75
        },
        {
          date: '2024-07-19',
          searchVolume: 1811,
          organicRank: 17,

          keywordSales: 75
        },
        {
          date: '2024-07-18',
          searchVolume: 1811,
          organicRank: 26,

          keywordSales: 75
        },
        {
          date: '2024-07-17',
          searchVolume: 1811,
          organicRank: 26,

          keywordSales: 75
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water flossers for teeth',
      keyword: 'water flossers for teeth',
      searchVolume: 80994,
      organicStartRank: 40,
      organicHighestRank: 7,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 74693,
          organicRank: 15,

          keywordSales: 1386
        },
        {
          date: '2024-09-09',
          searchVolume: 74693,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-09-08',
          searchVolume: 74693,
          organicRank: 17,
          sponsoredRank: 32,
          keywordSales: 1386
        },
        {
          date: '2024-09-07',
          searchVolume: 74693,
          organicRank: 17,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-09-06',
          searchVolume: 74693,
          organicRank: 18,
          sponsoredRank: 26,
          keywordSales: 1386
        },
        {
          date: '2024-09-05',
          searchVolume: 74693,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 1386
        },
        {
          date: '2024-09-02',
          searchVolume: 74693,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 1386
        },
        {
          date: '2024-09-01',
          searchVolume: 74693,
          organicRank: 20,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-08-31',
          searchVolume: 74693,
          organicRank: 21,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-08-30',
          searchVolume: 84853,
          organicRank: 20,
          sponsoredRank: 8,
          keywordSales: 1386
        },
        {
          date: '2024-08-29',
          searchVolume: 84853,
          organicRank: 18,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-08-28',
          searchVolume: 84853,
          organicRank: 13,

          keywordSales: 1386
        },
        {
          date: '2024-08-27',
          searchVolume: 84853,
          organicRank: 16,

          keywordSales: 1386
        },
        {
          date: '2024-08-26',
          searchVolume: 84853,
          organicRank: 12,

          keywordSales: 1386
        },
        {
          date: '2024-08-25',
          searchVolume: 84853,
          organicRank: 12,

          keywordSales: 1386
        },
        {
          date: '2024-08-24',
          searchVolume: 84853,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 1386
        },
        {
          date: '2024-08-22',
          searchVolume: 118592,
          organicRank: 14,

          keywordSales: 1386
        },
        {
          date: '2024-08-21',
          searchVolume: 118592,
          organicRank: 14,

          keywordSales: 1386
        },
        {
          date: '2024-08-20',
          searchVolume: 118592,
          organicRank: 13,

          keywordSales: 1386
        },
        {
          date: '2024-08-19',
          searchVolume: 118592,
          organicRank: 10,

          keywordSales: 1386
        },
        {
          date: '2024-08-18',
          searchVolume: 118592,
          organicRank: 12,
          sponsoredRank: 27,
          keywordSales: 1386
        },
        {
          date: '2024-08-16',
          searchVolume: 81913,
          organicRank: 9,

          keywordSales: 1386
        },
        {
          date: '2024-08-15',
          searchVolume: 81913,
          organicRank: 11,

          keywordSales: 1386
        },
        {
          date: '2024-08-14',
          searchVolume: 81913,
          organicRank: 12,

          keywordSales: 1386
        },
        {
          date: '2024-08-13',
          searchVolume: 81913,
          organicRank: 9,

          keywordSales: 1386
        },
        {
          date: '2024-08-11',
          searchVolume: 81913,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 1386
        },
        {
          date: '2024-08-10',
          searchVolume: 81913,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 1386
        },
        {
          date: '2024-08-09',
          searchVolume: 59540,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 1386
        },
        {
          date: '2024-08-08',
          searchVolume: 59540,
          organicRank: 12,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-08-07',
          searchVolume: 59540,
          organicRank: 13,
          sponsoredRank: 21,
          keywordSales: 1386
        },
        {
          date: '2024-08-06',
          searchVolume: 59540,
          organicRank: 13,
          sponsoredRank: 5,
          keywordSales: 1386
        },
        {
          date: '2024-08-05',
          searchVolume: 59540,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 1386
        },
        {
          date: '2024-08-04',
          searchVolume: 59540,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 1386
        },
        {
          date: '2024-08-03',
          searchVolume: 59540,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-08-02',
          searchVolume: 55617,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-08-01',
          searchVolume: 55617,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 1386
        },
        {
          date: '2024-07-31',
          searchVolume: 55617,
          organicRank: 8,

          keywordSales: 1386
        },
        {
          date: '2024-07-30',
          searchVolume: 55617,
          organicRank: 7,

          keywordSales: 1386
        },
        {
          date: '2024-07-29',
          searchVolume: 55617,
          organicRank: 8,

          keywordSales: 1386
        },
        {
          date: '2024-07-28',
          searchVolume: 55617,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 1386
        },
        {
          date: '2024-07-27',
          searchVolume: 55617,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-07-26',
          searchVolume: 80994,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-07-25',
          searchVolume: 80994,
          organicRank: 11,

          keywordSales: 1386
        },
        {
          date: '2024-07-24',
          searchVolume: 80994,
          organicRank: 11,

          keywordSales: 1386
        },
        {
          date: '2024-07-23',
          searchVolume: 80994,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 1386
        },
        {
          date: '2024-07-22',
          searchVolume: 80994,
          organicRank: 22,

          keywordSales: 1386
        },
        {
          date: '2024-07-21',
          searchVolume: 80994,
          organicRank: 13,

          keywordSales: 1386
        },
        {
          date: '2024-07-20',
          searchVolume: 80994,
          organicRank: 16,

          keywordSales: 1386
        },
        {
          date: '2024-07-19',
          searchVolume: 59971,
          organicRank: 18,

          keywordSales: 1386
        },
        {
          date: '2024-07-18',
          searchVolume: 59971,
          organicRank: 40,

          keywordSales: 1386
        },
        {
          date: '2024-07-17',
          searchVolume: 59971,
          organicRank: 38,

          keywordSales: 1386
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pic',
      keyword: 'water pic',
      searchVolume: 12845,
      organicStartRank: 17,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 7476,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-09-09',
          searchVolume: 7476,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-09-08',
          searchVolume: 7476,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 373
        },
        {
          date: '2024-09-07',
          searchVolume: 7476,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-09-06',
          searchVolume: 7476,
          organicRank: 4,
          sponsoredRank: 36,
          keywordSales: 373
        },
        {
          date: '2024-09-05',
          searchVolume: 7476,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-09-04',
          searchVolume: 7476,
          organicRank: 5,

          keywordSales: 373
        },
        {
          date: '2024-09-01',
          searchVolume: 7476,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-08-28',
          searchVolume: 8482,
          organicRank: 5,
          sponsoredRank: 10,
          keywordSales: 373
        },
        {
          date: '2024-08-27',
          searchVolume: 8482,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-08-26',
          searchVolume: 8482,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-25',
          searchVolume: 8482,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-08-24',
          searchVolume: 8482,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-08-23',
          searchVolume: 8482,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-22',
          searchVolume: 8482,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-21',
          searchVolume: 8482,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-08-20',
          searchVolume: 8482,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-19',
          searchVolume: 8482,
          organicRank: 3,
          sponsoredRank: 36,
          keywordSales: 373
        },
        {
          date: '2024-08-17',
          searchVolume: 8482,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-16',
          searchVolume: 7320,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-08-15',
          searchVolume: 7320,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-14',
          searchVolume: 7320,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-13',
          searchVolume: 7320,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-12',
          searchVolume: 7320,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 373
        },
        {
          date: '2024-08-11',
          searchVolume: 7320,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 373
        },
        {
          date: '2024-08-10',
          searchVolume: 7320,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-08-09',
          searchVolume: 7185,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-08-08',
          searchVolume: 7185,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-08-07',
          searchVolume: 7185,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 373
        },
        {
          date: '2024-08-06',
          searchVolume: 7185,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 373
        },
        {
          date: '2024-08-05',
          searchVolume: 7185,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-08-04',
          searchVolume: 7185,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 373
        },
        {
          date: '2024-08-02',
          searchVolume: 6239,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 373
        },
        {
          date: '2024-08-01',
          searchVolume: 6239,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 373
        },
        {
          date: '2024-07-31',
          searchVolume: 6239,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-07-30',
          searchVolume: 6239,
          organicRank: 3,
          sponsoredRank: 5,
          keywordSales: 373
        },
        {
          date: '2024-07-29',
          searchVolume: 6239,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-07-28',
          searchVolume: 6239,
          organicRank: 3,

          keywordSales: 373
        },
        {
          date: '2024-07-27',
          searchVolume: 6239,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-07-26',
          searchVolume: 12845,
          organicRank: 3,
          sponsoredRank: 8,
          keywordSales: 373
        },
        {
          date: '2024-07-25',
          searchVolume: 12845,
          organicRank: 2,

          keywordSales: 373
        },
        {
          date: '2024-07-24',
          searchVolume: 12845,
          organicRank: 4,

          keywordSales: 373
        },
        {
          date: '2024-07-23',
          searchVolume: 12845,
          organicRank: 7,

          keywordSales: 373
        },
        {
          date: '2024-07-22',
          searchVolume: 12845,
          organicRank: 7,

          keywordSales: 373
        },
        {
          date: '2024-07-21',
          searchVolume: 12845,
          organicRank: 8,

          keywordSales: 373
        },
        {
          date: '2024-07-20',
          searchVolume: 12845,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 373
        },
        {
          date: '2024-07-19',
          searchVolume: 7237,
          organicRank: 12,

          keywordSales: 373
        },
        {
          date: '2024-07-18',
          searchVolume: 7237,
          organicRank: 17,

          keywordSales: 373
        },
        {
          date: '2024-07-17',
          searchVolume: 7237,
          organicRank: 17,

          keywordSales: 373
        },
        {
          date: '2024-07-16',
          searchVolume: 7237,
          organicRank: 13,

          keywordSales: 373
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pick',
      keyword: 'water pick',
      searchVolume: 81022,
      organicStartRank: 23,
      organicHighestRank: 3,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 61904,
          organicRank: 19,

          keywordSales: 1126
        },
        {
          date: '2024-09-09',
          searchVolume: 61904,
          organicRank: 8,

          keywordSales: 1126
        },
        {
          date: '2024-09-08',
          searchVolume: 61904,
          organicRank: 8,
          sponsoredRank: 20,
          keywordSales: 1126
        },
        {
          date: '2024-09-07',
          searchVolume: 61904,
          organicRank: 9,

          keywordSales: 1126
        },
        {
          date: '2024-09-06',
          searchVolume: 61904,
          organicRank: 6,
          sponsoredRank: 35,
          keywordSales: 1126
        },
        {
          date: '2024-09-05',
          searchVolume: 61904,
          organicRank: 7,
          sponsoredRank: 10,
          keywordSales: 1126
        },
        {
          date: '2024-09-03',
          searchVolume: 61904,
          organicRank: 9,

          keywordSales: 1126
        },
        {
          date: '2024-08-30',
          searchVolume: 63881,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-29',
          searchVolume: 63881,
          organicRank: 8,
          sponsoredRank: 53,
          keywordSales: 1126
        },
        {
          date: '2024-08-28',
          searchVolume: 63881,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 1126
        },
        {
          date: '2024-08-27',
          searchVolume: 63881,
          organicRank: 4,
          sponsoredRank: 7,
          keywordSales: 1126
        },
        {
          date: '2024-08-26',
          searchVolume: 63881,
          organicRank: 5,

          keywordSales: 1126
        },
        {
          date: '2024-08-25',
          searchVolume: 63881,
          organicRank: 4,

          keywordSales: 1126
        },
        {
          date: '2024-08-24',
          searchVolume: 63881,
          organicRank: 6,

          keywordSales: 1126
        },
        {
          date: '2024-08-23',
          searchVolume: 74396,
          organicRank: 5,

          keywordSales: 1126
        },
        {
          date: '2024-08-22',
          searchVolume: 74396,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-21',
          searchVolume: 74396,
          organicRank: 3,

          keywordSales: 1126
        },
        {
          date: '2024-08-20',
          searchVolume: 74396,
          organicRank: 6,

          keywordSales: 1126
        },
        {
          date: '2024-08-19',
          searchVolume: 74396,
          organicRank: 6,

          keywordSales: 1126
        },
        {
          date: '2024-08-16',
          searchVolume: 54013,
          organicRank: 3,

          keywordSales: 1126
        },
        {
          date: '2024-08-15',
          searchVolume: 54013,
          organicRank: 4,

          keywordSales: 1126
        },
        {
          date: '2024-08-14',
          searchVolume: 54013,
          organicRank: 5,

          keywordSales: 1126
        },
        {
          date: '2024-08-13',
          searchVolume: 54013,
          organicRank: 6,

          keywordSales: 1126
        },
        {
          date: '2024-08-11',
          searchVolume: 54013,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-10',
          searchVolume: 54013,
          organicRank: 5,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-09',
          searchVolume: 50032,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-08',
          searchVolume: 50032,
          organicRank: 6,
          sponsoredRank: 17,
          keywordSales: 1126
        },
        {
          date: '2024-08-07',
          searchVolume: 50032,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-06',
          searchVolume: 50032,
          organicRank: 9,
          sponsoredRank: 9,
          keywordSales: 1126
        },
        {
          date: '2024-08-05',
          searchVolume: 50032,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-04',
          searchVolume: 50032,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 1126
        },
        {
          date: '2024-08-03',
          searchVolume: 50032,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 1126
        },
        {
          date: '2024-08-02',
          searchVolume: 48662,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 1126
        },
        {
          date: '2024-08-01',
          searchVolume: 48662,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 1126
        },
        {
          date: '2024-07-31',
          searchVolume: 48662,
          organicRank: 8,

          keywordSales: 1126
        },
        {
          date: '2024-07-30',
          searchVolume: 48662,
          organicRank: 8,
          sponsoredRank: 9,
          keywordSales: 1126
        },
        {
          date: '2024-07-29',
          searchVolume: 48662,
          organicRank: 11,

          keywordSales: 1126
        },
        {
          date: '2024-07-28',
          searchVolume: 48662,
          organicRank: 11,

          keywordSales: 1126
        },
        {
          date: '2024-07-27',
          searchVolume: 48662,
          organicRank: 8,

          keywordSales: 1126
        },
        {
          date: '2024-07-26',
          searchVolume: 81022,
          organicRank: 12,

          keywordSales: 1126
        },
        {
          date: '2024-07-25',
          searchVolume: 81022,
          organicRank: 5,

          keywordSales: 1126
        },
        {
          date: '2024-07-24',
          searchVolume: 81022,
          organicRank: 10,

          keywordSales: 1126
        },
        {
          date: '2024-07-23',
          searchVolume: 81022,
          organicRank: 6,

          keywordSales: 1126
        },
        {
          date: '2024-07-22',
          searchVolume: 81022,
          organicRank: 5,

          keywordSales: 1126
        },
        {
          date: '2024-07-21',
          searchVolume: 81022,
          organicRank: 18,

          keywordSales: 1126
        },
        {
          date: '2024-07-20',
          searchVolume: 81022,
          organicRank: 14,

          keywordSales: 1126
        },
        {
          date: '2024-07-19',
          searchVolume: 52982,
          organicRank: 22,

          keywordSales: 1126
        },
        {
          date: '2024-07-18',
          searchVolume: 52982,
          organicRank: 23,

          keywordSales: 1126
        },
        {
          date: '2024-07-17',
          searchVolume: 52982,
          organicRank: 23,

          keywordSales: 1126
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water picks',
      keyword: 'water picks',
      searchVolume: 3004,
      organicStartRank: 27,
      organicHighestRank: 3,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1865,
          organicRank: 8,

          keywordSales: 84
        },
        {
          date: '2024-09-09',
          searchVolume: 1865,
          organicRank: 7,

          keywordSales: 84
        },
        {
          date: '2024-09-08',
          searchVolume: 1865,
          organicRank: 7,

          keywordSales: 84
        },
        {
          date: '2024-09-07',
          searchVolume: 1865,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 84
        },
        {
          date: '2024-09-06',
          searchVolume: 1865,
          organicRank: 11,
          sponsoredRank: 22,
          keywordSales: 84
        },
        {
          date: '2024-09-05',
          searchVolume: 1865,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-09-04',
          searchVolume: 1865,
          organicRank: 14,

          keywordSales: 84
        },
        {
          date: '2024-09-02',
          searchVolume: 1865,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-29',
          searchVolume: 1924,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-28',
          searchVolume: 1924,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-27',
          searchVolume: 1924,
          organicRank: 4,

          keywordSales: 84
        },
        {
          date: '2024-08-26',
          searchVolume: 1924,
          organicRank: 3,

          keywordSales: 84
        },
        {
          date: '2024-08-25',
          searchVolume: 1924,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-24',
          searchVolume: 1924,
          organicRank: 4,
          sponsoredRank: 11,
          keywordSales: 84
        },
        {
          date: '2024-08-22',
          searchVolume: 1848,
          organicRank: 7,

          keywordSales: 84
        },
        {
          date: '2024-08-21',
          searchVolume: 1848,
          organicRank: 4,

          keywordSales: 84
        },
        {
          date: '2024-08-20',
          searchVolume: 1848,
          organicRank: 3,

          keywordSales: 84
        },
        {
          date: '2024-08-19',
          searchVolume: 1848,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-08-18',
          searchVolume: 1848,
          organicRank: 4,

          keywordSales: 84
        },
        {
          date: '2024-08-16',
          searchVolume: 1535,
          organicRank: 4,

          keywordSales: 84
        },
        {
          date: '2024-08-15',
          searchVolume: 1535,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-08-14',
          searchVolume: 1535,
          organicRank: 3,
          sponsoredRank: 12,
          keywordSales: 84
        },
        {
          date: '2024-08-13',
          searchVolume: 1535,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-08-12',
          searchVolume: 1535,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-11',
          searchVolume: 1535,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 84
        },
        {
          date: '2024-08-10',
          searchVolume: 1535,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-08-09',
          searchVolume: 1784,
          organicRank: 4,

          keywordSales: 84
        },
        {
          date: '2024-08-08',
          searchVolume: 1784,
          organicRank: 6,
          sponsoredRank: 3,
          keywordSales: 84
        },
        {
          date: '2024-08-07',
          searchVolume: 1784,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-06',
          searchVolume: 1784,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-05',
          searchVolume: 1784,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-04',
          searchVolume: 1784,
          organicRank: 7,

          keywordSales: 84
        },
        {
          date: '2024-08-03',
          searchVolume: 1784,
          organicRank: 6,

          keywordSales: 84
        },
        {
          date: '2024-08-02',
          searchVolume: 1537,
          organicRank: 5,

          keywordSales: 84
        },
        {
          date: '2024-08-01',
          searchVolume: 1537,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 84
        },
        {
          date: '2024-07-31',
          searchVolume: 1537,
          organicRank: 8,

          keywordSales: 84
        },
        {
          date: '2024-07-30',
          searchVolume: 1537,
          organicRank: 7,
          sponsoredRank: 54,
          keywordSales: 84
        },
        {
          date: '2024-07-29',
          searchVolume: 1537,
          organicRank: 7,

          keywordSales: 84
        },
        {
          date: '2024-07-28',
          searchVolume: 1537,
          organicRank: 8,

          keywordSales: 84
        },
        {
          date: '2024-07-27',
          searchVolume: 1537,
          organicRank: 6,
          sponsoredRank: 4,
          keywordSales: 84
        },
        {
          date: '2024-07-26',
          searchVolume: 3004,
          organicRank: 8,

          keywordSales: 84
        },
        {
          date: '2024-07-25',
          searchVolume: 3004,
          organicRank: 10,

          keywordSales: 84
        },
        {
          date: '2024-07-24',
          searchVolume: 3004,
          organicRank: 9,

          keywordSales: 84
        },
        {
          date: '2024-07-23',
          searchVolume: 3004,
          organicRank: 10,

          keywordSales: 84
        },
        {
          date: '2024-07-22',
          searchVolume: 3004,
          organicRank: 14,

          keywordSales: 84
        },
        {
          date: '2024-07-21',
          searchVolume: 3004,
          organicRank: 18,

          keywordSales: 84
        },
        {
          date: '2024-07-20',
          searchVolume: 3004,
          organicRank: 19,

          keywordSales: 84
        },
        {
          date: '2024-07-19',
          searchVolume: 1806,
          organicRank: 24,

          keywordSales: 84
        },
        {
          date: '2024-07-18',
          searchVolume: 1806,
          organicRank: 27,

          keywordSales: 84
        },
        {
          date: '2024-07-17',
          searchVolume: 1806,
          organicRank: 25,

          keywordSales: 84
        },
        {
          date: '2024-07-16',
          searchVolume: 1806,
          organicRank: 24,

          keywordSales: 84
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water picks for teeth cleaning and flossing',
      keyword: 'water picks for teeth cleaning and flossing',
      searchVolume: 55995,
      organicStartRank: 28,
      organicHighestRank: 2,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-21',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 43339,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-09-09',
          searchVolume: 43339,
          organicRank: 13,

          keywordSales: 1001
        },
        {
          date: '2024-09-08',
          searchVolume: 43339,
          organicRank: 8,
          sponsoredRank: 22,
          keywordSales: 1001
        },
        {
          date: '2024-09-07',
          searchVolume: 43339,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 1001
        },
        {
          date: '2024-09-06',
          searchVolume: 43339,
          organicRank: 8,
          sponsoredRank: 7,
          keywordSales: 1001
        },
        {
          date: '2024-09-05',
          searchVolume: 43339,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 1001
        },
        {
          date: '2024-09-02',
          searchVolume: 43339,
          organicRank: 9,

          keywordSales: 1001
        },
        {
          date: '2024-09-01',
          searchVolume: 43339,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 1001
        },
        {
          date: '2024-08-31',
          searchVolume: 43339,
          organicRank: 8,
          sponsoredRank: 10,
          keywordSales: 1001
        },
        {
          date: '2024-08-30',
          searchVolume: 44733,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 1001
        },
        {
          date: '2024-08-29',
          searchVolume: 44733,
          organicRank: 10,
          sponsoredRank: 7,
          keywordSales: 1001
        },
        {
          date: '2024-08-28',
          searchVolume: 44733,
          organicRank: 7,

          keywordSales: 1001
        },
        {
          date: '2024-08-27',
          searchVolume: 44733,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-08-26',
          searchVolume: 44733,
          organicRank: 4,

          keywordSales: 1001
        },
        {
          date: '2024-08-25',
          searchVolume: 44733,
          organicRank: 6,

          keywordSales: 1001
        },
        {
          date: '2024-08-24',
          searchVolume: 44733,
          organicRank: 11,

          keywordSales: 1001
        },
        {
          date: '2024-08-22',
          searchVolume: 53490,
          organicRank: 7,

          keywordSales: 1001
        },
        {
          date: '2024-08-21',
          searchVolume: 53490,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-08-20',
          searchVolume: 53490,
          organicRank: 6,

          keywordSales: 1001
        },
        {
          date: '2024-08-19',
          searchVolume: 53490,
          organicRank: 3,
          sponsoredRank: 33,
          keywordSales: 1001
        },
        {
          date: '2024-08-18',
          searchVolume: 53490,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-08-16',
          searchVolume: 42660,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-08-15',
          searchVolume: 42660,
          organicRank: 4,

          keywordSales: 1001
        },
        {
          date: '2024-08-14',
          searchVolume: 42660,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-08-13',
          searchVolume: 42660,
          organicRank: 5,

          keywordSales: 1001
        },
        {
          date: '2024-08-11',
          searchVolume: 42660,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-08-10',
          searchVolume: 42660,
          organicRank: 7,
          sponsoredRank: 3,
          keywordSales: 1001
        },
        {
          date: '2024-08-09',
          searchVolume: 37730,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-08-08',
          searchVolume: 37730,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 1001
        },
        {
          date: '2024-08-07',
          searchVolume: 37730,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 1001
        },
        {
          date: '2024-08-06',
          searchVolume: 37730,
          organicRank: 5,
          sponsoredRank: 8,
          keywordSales: 1001
        },
        {
          date: '2024-08-05',
          searchVolume: 37730,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 1001
        },
        {
          date: '2024-08-04',
          searchVolume: 37730,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 1001
        },
        {
          date: '2024-08-03',
          searchVolume: 37730,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 1001
        },
        {
          date: '2024-08-02',
          searchVolume: 34957,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 1001
        },
        {
          date: '2024-08-01',
          searchVolume: 34957,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-07-31',
          searchVolume: 34957,
          organicRank: 7,

          keywordSales: 1001
        },
        {
          date: '2024-07-30',
          searchVolume: 34957,
          organicRank: 4,

          keywordSales: 1001
        },
        {
          date: '2024-07-29',
          searchVolume: 34957,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-07-28',
          searchVolume: 34957,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-07-27',
          searchVolume: 34957,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-07-26',
          searchVolume: 55995,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 1001
        },
        {
          date: '2024-07-25',
          searchVolume: 55995,
          organicRank: 14,

          keywordSales: 1001
        },
        {
          date: '2024-07-24',
          searchVolume: 55995,
          organicRank: 17,

          keywordSales: 1001
        },
        {
          date: '2024-07-23',
          searchVolume: 55995,
          organicRank: 12,

          keywordSales: 1001
        },
        {
          date: '2024-07-22',
          searchVolume: 55995,
          organicRank: 22,

          keywordSales: 1001
        },
        {
          date: '2024-07-21',
          searchVolume: 55995,
          organicRank: 18,
          sponsoredRank: 7,
          keywordSales: 1001
        },
        {
          date: '2024-07-20',
          searchVolume: 55995,
          organicRank: 23,

          keywordSales: 1001
        },
        {
          date: '2024-07-19',
          searchVolume: 50352,
          organicRank: 22,

          keywordSales: 1001
        },
        {
          date: '2024-07-18',
          searchVolume: 50352,
          organicRank: 28,

          keywordSales: 1001
        },
        {
          date: '2024-07-17',
          searchVolume: 50352,
          organicRank: 26,

          keywordSales: 1001
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water picks for teeth cleaning cordless',
      keyword: 'water picks for teeth cleaning cordless',
      searchVolume: 5647,
      organicStartRank: 13,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4189,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-09-09',
          searchVolume: 4189,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-09-08',
          searchVolume: 4189,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 211
        },
        {
          date: '2024-09-07',
          searchVolume: 4189,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-09-06',
          searchVolume: 4189,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-09-05',
          searchVolume: 4189,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-09-04',
          searchVolume: 4189,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-09-01',
          searchVolume: 4189,
          organicRank: 2,
          sponsoredRank: 10,
          keywordSales: 211
        },
        {
          date: '2024-08-28',
          searchVolume: 4324,
          organicRank: 2,
          sponsoredRank: 30,
          keywordSales: 211
        },
        {
          date: '2024-08-27',
          searchVolume: 4324,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-26',
          searchVolume: 4324,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-25',
          searchVolume: 4324,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-24',
          searchVolume: 4324,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-23',
          searchVolume: 4556,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-22',
          searchVolume: 4556,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-21',
          searchVolume: 4556,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 211
        },
        {
          date: '2024-08-20',
          searchVolume: 4556,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-19',
          searchVolume: 4556,
          organicRank: 2,
          sponsoredRank: 39,
          keywordSales: 211
        },
        {
          date: '2024-08-17',
          searchVolume: 4556,
          organicRank: 2,
          sponsoredRank: 17,
          keywordSales: 211
        },
        {
          date: '2024-08-16',
          searchVolume: 4130,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-15',
          searchVolume: 4130,
          organicRank: 1,

          keywordSales: 211
        },
        {
          date: '2024-08-14',
          searchVolume: 4130,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 211
        },
        {
          date: '2024-08-13',
          searchVolume: 4130,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-12',
          searchVolume: 4130,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-11',
          searchVolume: 4130,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 211
        },
        {
          date: '2024-08-10',
          searchVolume: 4130,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-09',
          searchVolume: 4026,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-08',
          searchVolume: 4026,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-08-07',
          searchVolume: 4026,
          organicRank: 1,

          keywordSales: 211
        },
        {
          date: '2024-08-06',
          searchVolume: 4026,
          organicRank: 1,

          keywordSales: 211
        },
        {
          date: '2024-08-05',
          searchVolume: 4026,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-08-04',
          searchVolume: 4026,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-08-03',
          searchVolume: 4026,
          organicRank: 2,

          keywordSales: 211
        },
        {
          date: '2024-08-02',
          searchVolume: 3201,
          organicRank: 3,

          keywordSales: 211
        },
        {
          date: '2024-08-01',
          searchVolume: 3201,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 211
        },
        {
          date: '2024-07-31',
          searchVolume: 3201,
          organicRank: 4,

          keywordSales: 211
        },
        {
          date: '2024-07-30',
          searchVolume: 3201,
          organicRank: 4,
          sponsoredRank: 29,
          keywordSales: 211
        },
        {
          date: '2024-07-29',
          searchVolume: 3201,
          organicRank: 7,

          keywordSales: 211
        },
        {
          date: '2024-07-28',
          searchVolume: 3201,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 211
        },
        {
          date: '2024-07-27',
          searchVolume: 3201,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 211
        },
        {
          date: '2024-07-26',
          searchVolume: 5647,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 211
        },
        {
          date: '2024-07-25',
          searchVolume: 5647,
          organicRank: 5,

          keywordSales: 211
        },
        {
          date: '2024-07-24',
          searchVolume: 5647,
          organicRank: 5,

          keywordSales: 211
        },
        {
          date: '2024-07-23',
          searchVolume: 5647,
          organicRank: 5,

          keywordSales: 211
        },
        {
          date: '2024-07-22',
          searchVolume: 5647,
          organicRank: 4,

          keywordSales: 211
        },
        {
          date: '2024-07-21',
          searchVolume: 5647,
          organicRank: 6,

          keywordSales: 211
        },
        {
          date: '2024-07-20',
          searchVolume: 5647,
          organicRank: 5,

          keywordSales: 211
        },
        {
          date: '2024-07-19',
          searchVolume: 4276,
          organicRank: 7,

          keywordSales: 211
        },
        {
          date: '2024-07-18',
          searchVolume: 4276,
          organicRank: 13,

          keywordSales: 211
        },
        {
          date: '2024-07-17',
          searchVolume: 4276,
          organicRank: 12,

          keywordSales: 211
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pik',
      keyword: 'water pik',
      searchVolume: 147944,
      organicStartRank: 11,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 82300,
          organicRank: 8,

          keywordSales: 1821
        },
        {
          date: '2024-09-09',
          searchVolume: 82300,
          organicRank: 10,

          keywordSales: 1821
        },
        {
          date: '2024-09-08',
          searchVolume: 82300,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 1821
        },
        {
          date: '2024-09-07',
          searchVolume: 82300,
          organicRank: 6,

          keywordSales: 1821
        },
        {
          date: '2024-09-05',
          searchVolume: 82300,
          organicRank: 6,
          sponsoredRank: 5,
          keywordSales: 1821
        },
        {
          date: '2024-08-28',
          searchVolume: 84907,
          organicRank: 6,

          keywordSales: 1821
        },
        {
          date: '2024-08-27',
          searchVolume: 84907,
          organicRank: 5,

          keywordSales: 1821
        },
        {
          date: '2024-08-26',
          searchVolume: 84907,
          organicRank: 4,

          keywordSales: 1821
        },
        {
          date: '2024-08-25',
          searchVolume: 84907,
          organicRank: 3,

          keywordSales: 1821
        },
        {
          date: '2024-08-24',
          searchVolume: 84907,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 1821
        },
        {
          date: '2024-08-23',
          searchVolume: 98186,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-22',
          searchVolume: 98186,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1821
        },
        {
          date: '2024-08-20',
          searchVolume: 98186,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-19',
          searchVolume: 98186,
          organicRank: 2,
          sponsoredRank: 32,
          keywordSales: 1821
        },
        {
          date: '2024-08-18',
          searchVolume: 98186,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 1821
        },
        {
          date: '2024-08-16',
          searchVolume: 80880,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-15',
          searchVolume: 80880,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-14',
          searchVolume: 80880,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-13',
          searchVolume: 80880,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-08-11',
          searchVolume: 80880,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 1821
        },
        {
          date: '2024-08-10',
          searchVolume: 80880,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 1821
        },
        {
          date: '2024-08-09',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 1821
        },
        {
          date: '2024-08-08',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 1821
        },
        {
          date: '2024-08-07',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 12,
          keywordSales: 1821
        },
        {
          date: '2024-08-06',
          searchVolume: 69389,
          organicRank: 1,
          sponsoredRank: 12,
          keywordSales: 1821
        },
        {
          date: '2024-08-05',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 1821
        },
        {
          date: '2024-08-04',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1821
        },
        {
          date: '2024-08-03',
          searchVolume: 69389,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 1821
        },
        {
          date: '2024-08-02',
          searchVolume: 68675,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 1821
        },
        {
          date: '2024-08-01',
          searchVolume: 68675,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1821
        },
        {
          date: '2024-07-31',
          searchVolume: 68675,
          organicRank: 3,

          keywordSales: 1821
        },
        {
          date: '2024-07-30',
          searchVolume: 68675,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-07-29',
          searchVolume: 68675,
          organicRank: 5,

          keywordSales: 1821
        },
        {
          date: '2024-07-28',
          searchVolume: 68675,
          organicRank: 3,

          keywordSales: 1821
        },
        {
          date: '2024-07-27',
          searchVolume: 68675,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-07-26',
          searchVolume: 147944,
          organicRank: 3,

          keywordSales: 1821
        },
        {
          date: '2024-07-25',
          searchVolume: 147944,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-07-24',
          searchVolume: 147944,
          organicRank: 2,

          keywordSales: 1821
        },
        {
          date: '2024-07-23',
          searchVolume: 147944,
          organicRank: 1,

          keywordSales: 1821
        },
        {
          date: '2024-07-22',
          searchVolume: 147944,
          organicRank: 5,

          keywordSales: 1821
        },
        {
          date: '2024-07-21',
          searchVolume: 147944,
          organicRank: 5,

          keywordSales: 1821
        },
        {
          date: '2024-07-20',
          searchVolume: 147944,
          organicRank: 4,

          keywordSales: 1821
        },
        {
          date: '2024-07-19',
          searchVolume: 79701,
          organicRank: 7,

          keywordSales: 1821
        },
        {
          date: '2024-07-18',
          searchVolume: 79701,
          organicRank: 11,

          keywordSales: 1821
        },
        {
          date: '2024-07-17',
          searchVolume: 79701,
          organicRank: 7,

          keywordSales: 1821
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pik cordless advanced water flosser',
      keyword: 'water pik cordless advanced water flosser',
      searchVolume: 5714,
      organicStartRank: 4,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3519,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-09-09',
          searchVolume: 3519,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-09-08',
          searchVolume: 3519,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-09-07',
          searchVolume: 3519,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-09-06',
          searchVolume: 3519,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-09-05',
          searchVolume: 3519,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-09-04',
          searchVolume: 3519,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-09-01',
          searchVolume: 3519,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 176
        },
        {
          date: '2024-08-28',
          searchVolume: 3817,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-08-27',
          searchVolume: 3817,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-26',
          searchVolume: 3817,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-25',
          searchVolume: 3817,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-24',
          searchVolume: 3817,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-23',
          searchVolume: 3629,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-22',
          searchVolume: 3629,
          organicRank: 1,

          keywordSales: 176
        },
        {
          date: '2024-08-21',
          searchVolume: 3629,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-20',
          searchVolume: 3629,
          organicRank: 1,

          keywordSales: 176
        },
        {
          date: '2024-08-19',
          searchVolume: 3629,
          organicRank: 2,
          sponsoredRank: 18,
          keywordSales: 176
        },
        {
          date: '2024-08-17',
          searchVolume: 3629,
          organicRank: 1,

          keywordSales: 176
        },
        {
          date: '2024-08-16',
          searchVolume: 3460,
          organicRank: 1,

          keywordSales: 176
        },
        {
          date: '2024-08-15',
          searchVolume: 3460,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-14',
          searchVolume: 3460,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-08-13',
          searchVolume: 3460,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-12',
          searchVolume: 3460,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-11',
          searchVolume: 3460,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-10',
          searchVolume: 3460,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-09',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-08',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-07',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-06',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-05',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-04',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-03',
          searchVolume: 3376,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-02',
          searchVolume: 3198,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-08-01',
          searchVolume: 3198,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-07-31',
          searchVolume: 3198,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-30',
          searchVolume: 3198,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-07-29',
          searchVolume: 3198,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-28',
          searchVolume: 3198,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-27',
          searchVolume: 3198,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 176
        },
        {
          date: '2024-07-26',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-25',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-24',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-23',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-22',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-21',
          searchVolume: 5714,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-20',
          searchVolume: 5714,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 176
        },
        {
          date: '2024-07-19',
          searchVolume: 4056,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-18',
          searchVolume: 4056,
          organicRank: 4,

          keywordSales: 176
        },
        {
          date: '2024-07-17',
          searchVolume: 4056,
          organicRank: 5,

          keywordSales: 176
        },
        {
          date: '2024-07-16',
          searchVolume: 4056,
          organicRank: 4,

          keywordSales: 176
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pik flossers for adults',
      keyword: 'water pik flossers for adults',
      searchVolume: 19502,
      organicStartRank: 7,
      organicHighestRank: 1,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 11405,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-09-09',
          searchVolume: 11405,
          organicRank: 6,

          keywordSales: 274
        },
        {
          date: '2024-09-08',
          searchVolume: 11405,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-09-07',
          searchVolume: 11405,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-09-06',
          searchVolume: 11405,
          organicRank: 5,
          sponsoredRank: 8,
          keywordSales: 274
        },
        {
          date: '2024-09-05',
          searchVolume: 11405,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 274
        },
        {
          date: '2024-09-02',
          searchVolume: 11405,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-09-01',
          searchVolume: 11405,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 274
        },
        {
          date: '2024-08-31',
          searchVolume: 11405,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 274
        },
        {
          date: '2024-08-30',
          searchVolume: 13403,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-08-29',
          searchVolume: 13403,
          organicRank: 4,
          sponsoredRank: 6,
          keywordSales: 274
        },
        {
          date: '2024-08-28',
          searchVolume: 13403,
          organicRank: 4,
          sponsoredRank: 9,
          keywordSales: 274
        },
        {
          date: '2024-08-27',
          searchVolume: 13403,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-26',
          searchVolume: 13403,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-25',
          searchVolume: 13403,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-08-24',
          searchVolume: 13403,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-23',
          searchVolume: 13403,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-22',
          searchVolume: 13403,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-21',
          searchVolume: 13403,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-08-20',
          searchVolume: 13403,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-19',
          searchVolume: 13403,
          organicRank: 2,
          sponsoredRank: 19,
          keywordSales: 274
        },
        {
          date: '2024-08-17',
          searchVolume: 13403,
          organicRank: 2,

          keywordSales: 274
        },
        {
          date: '2024-08-16',
          searchVolume: 11203,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-08-15',
          searchVolume: 11203,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-14',
          searchVolume: 11203,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-13',
          searchVolume: 11203,
          organicRank: 2,

          keywordSales: 274
        },
        {
          date: '2024-08-12',
          searchVolume: 11203,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-11',
          searchVolume: 11203,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-10',
          searchVolume: 11203,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-09',
          searchVolume: 10291,
          organicRank: 2,

          keywordSales: 274
        },
        {
          date: '2024-08-08',
          searchVolume: 10291,
          organicRank: 1,

          keywordSales: 274
        },
        {
          date: '2024-08-07',
          searchVolume: 10291,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 274
        },
        {
          date: '2024-08-06',
          searchVolume: 10291,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-08-05',
          searchVolume: 10291,
          organicRank: 2,

          keywordSales: 274
        },
        {
          date: '2024-08-04',
          searchVolume: 10291,
          organicRank: 2,

          keywordSales: 274
        },
        {
          date: '2024-08-02',
          searchVolume: 10027,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 274
        },
        {
          date: '2024-08-01',
          searchVolume: 10027,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 274
        },
        {
          date: '2024-07-31',
          searchVolume: 10027,
          organicRank: 3,

          keywordSales: 274
        },
        {
          date: '2024-07-30',
          searchVolume: 10027,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 274
        },
        {
          date: '2024-07-29',
          searchVolume: 10027,
          organicRank: 4,

          keywordSales: 274
        },
        {
          date: '2024-07-28',
          searchVolume: 10027,
          organicRank: 4,

          keywordSales: 274
        },
        {
          date: '2024-07-27',
          searchVolume: 10027,
          organicRank: 3,
          sponsoredRank: 5,
          keywordSales: 274
        },
        {
          date: '2024-07-26',
          searchVolume: 19502,
          organicRank: 4,

          keywordSales: 274
        },
        {
          date: '2024-07-25',
          searchVolume: 19502,
          organicRank: 4,

          keywordSales: 274
        },
        {
          date: '2024-07-24',
          searchVolume: 19502,
          organicRank: 4,

          keywordSales: 274
        },
        {
          date: '2024-07-23',
          searchVolume: 19502,
          organicRank: 5,

          keywordSales: 274
        },
        {
          date: '2024-07-22',
          searchVolume: 19502,
          organicRank: 6,

          keywordSales: 274
        },
        {
          date: '2024-07-21',
          searchVolume: 19502,
          organicRank: 5,

          keywordSales: 274
        },
        {
          date: '2024-07-20',
          searchVolume: 19502,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 274
        },
        {
          date: '2024-07-19',
          searchVolume: 12593,
          organicRank: 6,

          keywordSales: 274
        },
        {
          date: '2024-07-18',
          searchVolume: 12593,
          organicRank: 7,

          keywordSales: 274
        },
        {
          date: '2024-07-17',
          searchVolume: 12593,
          organicRank: 7,

          keywordSales: 274
        },
        {
          date: '2024-07-16',
          searchVolume: 12593,
          organicRank: 7,

          keywordSales: 274
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pik replacement picks',
      keyword: 'water pik replacement picks',
      searchVolume: 2372,
      organicStartRank: 306,
      organicHighestRank: 33,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2470,
          organicRank: 73,

          keywordSales: 164
        },
        {
          date: '2024-09-09',
          searchVolume: 2470,
          organicRank: 89,

          keywordSales: 164
        },
        {
          date: '2024-09-08',
          searchVolume: 2470,
          organicRank: 88,

          keywordSales: 164
        },
        {
          date: '2024-09-07',
          searchVolume: 2470,
          organicRank: 91,

          keywordSales: 164
        },
        {
          date: '2024-09-06',
          searchVolume: 2470,
          organicRank: 94,

          keywordSales: 164
        },
        {
          date: '2024-09-05',
          searchVolume: 2470,
          organicRank: 86,

          keywordSales: 164
        },
        {
          date: '2024-09-04',
          searchVolume: 2470,
          organicRank: 85,

          keywordSales: 164
        },
        {
          date: '2024-09-02',
          searchVolume: 2470,
          organicRank: 83,

          keywordSales: 164
        },
        {
          date: '2024-08-29',
          searchVolume: 2548,
          organicRank: 77,

          keywordSales: 164
        },
        {
          date: '2024-08-28',
          searchVolume: 2548,
          organicRank: 71,

          keywordSales: 164
        },
        {
          date: '2024-08-27',
          searchVolume: 2548,
          organicRank: 71,

          keywordSales: 164
        },
        {
          date: '2024-08-26',
          searchVolume: 2548,
          organicRank: 76,

          keywordSales: 164
        },
        {
          date: '2024-08-25',
          searchVolume: 2548,
          organicRank: 74,

          keywordSales: 164
        },
        {
          date: '2024-08-24',
          searchVolume: 2548,
          organicRank: 73,

          keywordSales: 164
        },
        {
          date: '2024-08-22',
          searchVolume: 2547,
          organicRank: 74,

          keywordSales: 164
        },
        {
          date: '2024-08-21',
          searchVolume: 2547,
          organicRank: 77,

          keywordSales: 164
        },
        {
          date: '2024-08-20',
          searchVolume: 2547,
          organicRank: 74,

          keywordSales: 164
        },
        {
          date: '2024-08-19',
          searchVolume: 2547,
          organicRank: 59,

          keywordSales: 164
        },
        {
          date: '2024-08-18',
          searchVolume: 2547,
          organicRank: 77,

          keywordSales: 164
        },
        {
          date: '2024-08-16',
          searchVolume: 2431,
          organicRank: 69,

          keywordSales: 164
        },
        {
          date: '2024-08-15',
          searchVolume: 2431,
          organicRank: 33,

          keywordSales: 164
        },
        {
          date: '2024-08-14',
          searchVolume: 2431,
          organicRank: 73,

          keywordSales: 164
        },
        {
          date: '2024-08-13',
          searchVolume: 2431,
          organicRank: 74,

          keywordSales: 164
        },
        {
          date: '2024-08-12',
          searchVolume: 2431,
          organicRank: 71,

          keywordSales: 164
        },
        {
          date: '2024-08-11',
          searchVolume: 2431,
          organicRank: 72,

          keywordSales: 164
        },
        {
          date: '2024-08-10',
          searchVolume: 2431,
          organicRank: 71,

          keywordSales: 164
        },
        {
          date: '2024-08-09',
          searchVolume: 2372,
          organicRank: 76,

          keywordSales: 164
        },
        {
          date: '2024-08-08',
          searchVolume: 2372,
          organicRank: 55,

          keywordSales: 164
        },
        {
          date: '2024-08-07',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-06',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-05',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-04',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-03',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-02',
          searchVolume: 2212,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-08-01',
          searchVolume: 2212,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-07-31',
          searchVolume: 2212,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-07-30',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 239
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water pik toothbrush and water flosser combo',
      keyword: 'water pik toothbrush and water flosser combo',
      searchVolume: 5558,
      organicStartRank: 30,
      organicHighestRank: 4,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1858,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-09-09',
          searchVolume: 1858,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-09-08',
          searchVolume: 1858,
          organicRank: 15,
          sponsoredRank: 13,
          keywordSales: 43
        },
        {
          date: '2024-09-07',
          searchVolume: 1858,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-09-06',
          searchVolume: 1858,
          organicRank: 11,

          keywordSales: 43
        },
        {
          date: '2024-09-05',
          searchVolume: 1858,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-09-04',
          searchVolume: 1858,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-09-01',
          searchVolume: 1858,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-08-29',
          searchVolume: 1921,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-08-27',
          searchVolume: 1921,
          organicRank: 8,

          keywordSales: 43
        },
        {
          date: '2024-08-26',
          searchVolume: 1921,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-25',
          searchVolume: 1921,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-24',
          searchVolume: 1921,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-23',
          searchVolume: 1893,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-22',
          searchVolume: 1893,
          organicRank: 11,

          keywordSales: 43
        },
        {
          date: '2024-08-21',
          searchVolume: 1893,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-20',
          searchVolume: 1893,
          organicRank: 6,

          keywordSales: 43
        },
        {
          date: '2024-08-19',
          searchVolume: 1893,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-17',
          searchVolume: 1893,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-16',
          searchVolume: 1586,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-15',
          searchVolume: 1586,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-14',
          searchVolume: 1586,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-13',
          searchVolume: 1586,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-12',
          searchVolume: 1586,
          organicRank: 6,

          keywordSales: 43
        },
        {
          date: '2024-08-11',
          searchVolume: 1586,
          organicRank: 6,

          keywordSales: 43
        },
        {
          date: '2024-08-10',
          searchVolume: 1586,
          organicRank: 5,

          keywordSales: 43
        },
        {
          date: '2024-08-09',
          searchVolume: 1755,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-08',
          searchVolume: 1755,
          organicRank: 6,

          keywordSales: 43
        },
        {
          date: '2024-08-07',
          searchVolume: 1755,
          organicRank: 4,

          keywordSales: 43
        },
        {
          date: '2024-08-06',
          searchVolume: 1755,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-05',
          searchVolume: 1755,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 43
        },
        {
          date: '2024-08-04',
          searchVolume: 1755,
          organicRank: 10,

          keywordSales: 43
        },
        {
          date: '2024-08-03',
          searchVolume: 1755,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 1787,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-01',
          searchVolume: 1787,
          organicRank: 10,

          keywordSales: 43
        },
        {
          date: '2024-07-31',
          searchVolume: 1787,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-07-30',
          searchVolume: 1787,
          organicRank: 12,

          keywordSales: 43
        },
        {
          date: '2024-07-29',
          searchVolume: 1787,
          organicRank: 12,

          keywordSales: 43
        },
        {
          date: '2024-07-28',
          searchVolume: 1787,
          organicRank: 12,

          keywordSales: 43
        },
        {
          date: '2024-07-27',
          searchVolume: 1787,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 43
        },
        {
          date: '2024-07-26',
          searchVolume: 5558,
          organicRank: 14,

          keywordSales: 43
        },
        {
          date: '2024-07-25',
          searchVolume: 5558,
          organicRank: 16,

          keywordSales: 43
        },
        {
          date: '2024-07-24',
          searchVolume: 5558,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-07-23',
          searchVolume: 5558,
          organicRank: 14,

          keywordSales: 43
        },
        {
          date: '2024-07-22',
          searchVolume: 5558,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-07-21',
          searchVolume: 5558,
          organicRank: 22,

          keywordSales: 43
        },
        {
          date: '2024-07-20',
          searchVolume: 5558,
          organicRank: 24,

          keywordSales: 43
        },
        {
          date: '2024-07-19',
          searchVolume: 2393,
          organicRank: 30,

          keywordSales: 43
        },
        {
          date: '2024-07-18',
          searchVolume: 2393,
          organicRank: 30,

          keywordSales: 43
        },
        {
          date: '2024-07-17',
          searchVolume: 2393,
          organicRank: 32,

          keywordSales: 43
        },
        {
          date: '2024-07-16',
          searchVolume: 2393,
          organicRank: 31,

          keywordSales: 43
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water tooth flosser',
      keyword: 'water tooth flosser',
      searchVolume: 2319,
      organicStartRank: 50,
      organicHighestRank: 8,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1304,
          organicRank: 18,

          keywordSales: 38
        },
        {
          date: '2024-09-09',
          searchVolume: 1304,
          organicRank: 22,

          keywordSales: 38
        },
        {
          date: '2024-09-08',
          searchVolume: 1304,
          organicRank: 20,

          keywordSales: 38
        },
        {
          date: '2024-09-07',
          searchVolume: 1304,
          organicRank: 20,
          sponsoredRank: 30,
          keywordSales: 38
        },
        {
          date: '2024-09-06',
          searchVolume: 1304,
          organicRank: 21,

          keywordSales: 38
        },
        {
          date: '2024-09-05',
          searchVolume: 1304,
          organicRank: 21,

          keywordSales: 38
        },
        {
          date: '2024-09-04',
          searchVolume: 1304,
          organicRank: 29,

          keywordSales: 38
        },
        {
          date: '2024-09-02',
          searchVolume: 1304,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-08-29',
          searchVolume: 1430,
          organicRank: 22,

          keywordSales: 38
        },
        {
          date: '2024-08-28',
          searchVolume: 1430,
          organicRank: 20,

          keywordSales: 38
        },
        {
          date: '2024-08-27',
          searchVolume: 1430,
          organicRank: 17,

          keywordSales: 38
        },
        {
          date: '2024-08-26',
          searchVolume: 1430,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-25',
          searchVolume: 1430,
          organicRank: 15,

          keywordSales: 38
        },
        {
          date: '2024-08-24',
          searchVolume: 1430,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-08-22',
          searchVolume: 1915,
          organicRank: 15,

          keywordSales: 38
        },
        {
          date: '2024-08-21',
          searchVolume: 1915,
          organicRank: 15,
          sponsoredRank: 10,
          keywordSales: 38
        },
        {
          date: '2024-08-20',
          searchVolume: 1915,
          organicRank: 9,

          keywordSales: 38
        },
        {
          date: '2024-08-19',
          searchVolume: 1915,
          organicRank: 14,

          keywordSales: 38
        },
        {
          date: '2024-08-18',
          searchVolume: 1915,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-08-16',
          searchVolume: 1366,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-15',
          searchVolume: 1366,
          organicRank: 10,

          keywordSales: 38
        },
        {
          date: '2024-08-14',
          searchVolume: 1366,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-08-13',
          searchVolume: 1366,
          organicRank: 16,
          sponsoredRank: 6,
          keywordSales: 38
        },
        {
          date: '2024-08-12',
          searchVolume: 1366,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-08-11',
          searchVolume: 1366,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-08-10',
          searchVolume: 1366,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-08-09',
          searchVolume: 1334,
          organicRank: 12,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-08-08',
          searchVolume: 1334,
          organicRank: 13,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-08-07',
          searchVolume: 1334,
          organicRank: 13,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-08-06',
          searchVolume: 1334,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-08-05',
          searchVolume: 1334,
          organicRank: 10,

          keywordSales: 38
        },
        {
          date: '2024-08-04',
          searchVolume: 1334,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-08-03',
          searchVolume: 1334,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-08-02',
          searchVolume: 1247,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-01',
          searchVolume: 1247,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-07-31',
          searchVolume: 1247,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-07-30',
          searchVolume: 1247,
          organicRank: 11,
          sponsoredRank: 21,
          keywordSales: 38
        },
        {
          date: '2024-07-29',
          searchVolume: 1247,
          organicRank: 10,

          keywordSales: 38
        },
        {
          date: '2024-07-28',
          searchVolume: 1247,
          organicRank: 14,

          keywordSales: 38
        },
        {
          date: '2024-07-27',
          searchVolume: 1247,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-07-26',
          searchVolume: 2319,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-07-25',
          searchVolume: 2319,
          organicRank: 17,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-07-24',
          searchVolume: 2319,
          organicRank: 24,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-07-23',
          searchVolume: 2319,
          organicRank: 25,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-07-22',
          searchVolume: 2319,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 38
        },
        {
          date: '2024-07-21',
          searchVolume: 2319,
          organicRank: 27,

          keywordSales: 38
        },
        {
          date: '2024-07-20',
          searchVolume: 2319,
          organicRank: 30,

          keywordSales: 38
        },
        {
          date: '2024-07-19',
          searchVolume: 1736,
          organicRank: 26,

          keywordSales: 38
        },
        {
          date: '2024-07-18',
          searchVolume: 1736,
          organicRank: 50,

          keywordSales: 38
        },
        {
          date: '2024-07-17',
          searchVolume: 1736,
          organicRank: 49,

          keywordSales: 38
        },
        {
          date: '2024-07-16',
          searchVolume: 1736,
          organicRank: 49,

          keywordSales: 38
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_water toothpick',
      keyword: 'water toothpick',
      searchVolume: 1289,
      organicStartRank: 34,
      organicHighestRank: 6,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 859,
          organicRank: 12,

          keywordSales: 37
        },
        {
          date: '2024-09-09',
          searchVolume: 859,
          organicRank: 19,

          keywordSales: 37
        },
        {
          date: '2024-09-08',
          searchVolume: 859,
          organicRank: 17,

          keywordSales: 37
        },
        {
          date: '2024-09-07',
          searchVolume: 859,
          organicRank: 19,
          sponsoredRank: 7,
          keywordSales: 37
        },
        {
          date: '2024-09-06',
          searchVolume: 859,
          organicRank: 16,
          sponsoredRank: 9,
          keywordSales: 37
        },
        {
          date: '2024-09-05',
          searchVolume: 859,
          organicRank: 18,
          sponsoredRank: 30,
          keywordSales: 37
        },
        {
          date: '2024-09-04',
          searchVolume: 859,
          organicRank: 13,

          keywordSales: 37
        },
        {
          date: '2024-09-02',
          searchVolume: 859,
          organicRank: 16,

          keywordSales: 37
        },
        {
          date: '2024-08-29',
          searchVolume: 747,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 37
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 11,

          keywordSales: 37
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 10,

          keywordSales: 37
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 9,
          sponsoredRank: 20,
          keywordSales: 37
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 9,

          keywordSales: 37
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 11,

          keywordSales: 37
        },
        {
          date: '2024-08-22',
          searchVolume: 845,
          organicRank: 12,

          keywordSales: 37
        },
        {
          date: '2024-08-21',
          searchVolume: 845,
          organicRank: 11,

          keywordSales: 37
        },
        {
          date: '2024-08-20',
          searchVolume: 845,
          organicRank: 6,

          keywordSales: 37
        },
        {
          date: '2024-08-19',
          searchVolume: 845,
          organicRank: 9,
          sponsoredRank: 24,
          keywordSales: 37
        },
        {
          date: '2024-08-18',
          searchVolume: 845,
          organicRank: 9,

          keywordSales: 37
        },
        {
          date: '2024-08-16',
          searchVolume: 907,
          organicRank: 8,

          keywordSales: 37
        },
        {
          date: '2024-08-15',
          searchVolume: 907,
          organicRank: 7,

          keywordSales: 37
        },
        {
          date: '2024-08-14',
          searchVolume: 907,
          organicRank: 9,

          keywordSales: 37
        },
        {
          date: '2024-08-13',
          searchVolume: 907,
          organicRank: 8,

          keywordSales: 37
        },
        {
          date: '2024-08-12',
          searchVolume: 907,
          organicRank: 8,

          keywordSales: 37
        },
        {
          date: '2024-08-11',
          searchVolume: 907,
          organicRank: 7,

          keywordSales: 37
        },
        {
          date: '2024-08-10',
          searchVolume: 907,
          organicRank: 6,

          keywordSales: 37
        },
        {
          date: '2024-08-09',
          searchVolume: 755,
          organicRank: 8,

          keywordSales: 37
        },
        {
          date: '2024-08-08',
          searchVolume: 755,
          organicRank: 10,

          keywordSales: 37
        },
        {
          date: '2024-08-07',
          searchVolume: 755,
          organicRank: 11,

          keywordSales: 37
        },
        {
          date: '2024-08-06',
          searchVolume: 755,
          organicRank: 13,

          keywordSales: 37
        },
        {
          date: '2024-08-05',
          searchVolume: 755,
          organicRank: 14,

          keywordSales: 37
        },
        {
          date: '2024-08-04',
          searchVolume: 755,
          organicRank: 13,

          keywordSales: 37
        },
        {
          date: '2024-08-03',
          searchVolume: 755,
          organicRank: 12,

          keywordSales: 37
        },
        {
          date: '2024-08-02',
          searchVolume: 820,
          organicRank: 8,

          keywordSales: 37
        },
        {
          date: '2024-08-01',
          searchVolume: 820,
          organicRank: 15,

          keywordSales: 37
        },
        {
          date: '2024-07-31',
          searchVolume: 820,
          organicRank: 17,

          keywordSales: 37
        },
        {
          date: '2024-07-30',
          searchVolume: 820,
          organicRank: 13,
          sponsoredRank: 32,
          keywordSales: 37
        },
        {
          date: '2024-07-29',
          searchVolume: 820,
          organicRank: 14,

          keywordSales: 37
        },
        {
          date: '2024-07-28',
          searchVolume: 820,
          organicRank: 14,

          keywordSales: 37
        },
        {
          date: '2024-07-27',
          searchVolume: 820,
          organicRank: 16,

          keywordSales: 37
        },
        {
          date: '2024-07-26',
          searchVolume: 1289,
          organicRank: 16,
          sponsoredRank: 9,
          keywordSales: 37
        },
        {
          date: '2024-07-25',
          searchVolume: 1289,
          organicRank: 14,

          keywordSales: 37
        },
        {
          date: '2024-07-24',
          searchVolume: 1289,
          organicRank: 20,

          keywordSales: 37
        },
        {
          date: '2024-07-23',
          searchVolume: 1289,
          organicRank: 20,

          keywordSales: 37
        },
        {
          date: '2024-07-22',
          searchVolume: 1289,
          organicRank: 35,

          keywordSales: 37
        },
        {
          date: '2024-07-21',
          searchVolume: 1289,
          organicRank: 26,

          keywordSales: 37
        },
        {
          date: '2024-07-20',
          searchVolume: 1289,
          organicRank: 33,

          keywordSales: 37
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 34,

          keywordSales: 37
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 34,

          keywordSales: 37
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 34,

          keywordSales: 37
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 21,

          keywordSales: 37
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterfloss',
      keyword: 'waterfloss',
      searchVolume: 2321,
      organicStartRank: 14,
      organicHighestRank: 2,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1613,
          organicRank: 11,

          keywordSales: 51
        },
        {
          date: '2024-09-09',
          searchVolume: 1613,
          organicRank: 11,

          keywordSales: 51
        },
        {
          date: '2024-09-08',
          searchVolume: 1613,
          organicRank: 12,

          keywordSales: 51
        },
        {
          date: '2024-09-07',
          searchVolume: 1613,
          organicRank: 12,
          sponsoredRank: 12,
          keywordSales: 51
        },
        {
          date: '2024-09-06',
          searchVolume: 1613,
          organicRank: 11,

          keywordSales: 51
        },
        {
          date: '2024-09-05',
          searchVolume: 1613,
          organicRank: 12,

          keywordSales: 51
        },
        {
          date: '2024-09-04',
          searchVolume: 1613,
          organicRank: 10,

          keywordSales: 51
        },
        {
          date: '2024-09-02',
          searchVolume: 1613,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-08-29',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-08-28',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-08-27',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-08-26',
          searchVolume: 1609,
          organicRank: 7,
          sponsoredRank: 16,
          keywordSales: 51
        },
        {
          date: '2024-08-25',
          searchVolume: 1609,
          organicRank: 6,

          keywordSales: 51
        },
        {
          date: '2024-08-24',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-08-22',
          searchVolume: 1917,
          organicRank: 9,

          keywordSales: 51
        },
        {
          date: '2024-08-21',
          searchVolume: 1917,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-20',
          searchVolume: 1917,
          organicRank: 2,

          keywordSales: 51
        },
        {
          date: '2024-08-19',
          searchVolume: 1917,
          organicRank: 2,
          sponsoredRank: 25,
          keywordSales: 51
        },
        {
          date: '2024-08-18',
          searchVolume: 1917,
          organicRank: 6,

          keywordSales: 51
        },
        {
          date: '2024-08-16',
          searchVolume: 1379,
          organicRank: 5,

          keywordSales: 51
        },
        {
          date: '2024-08-15',
          searchVolume: 1379,
          organicRank: 2,

          keywordSales: 51
        },
        {
          date: '2024-08-14',
          searchVolume: 1379,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 51
        },
        {
          date: '2024-08-13',
          searchVolume: 1379,
          organicRank: 3,

          keywordSales: 51
        },
        {
          date: '2024-08-12',
          searchVolume: 1379,
          organicRank: 6,

          keywordSales: 51
        },
        {
          date: '2024-08-11',
          searchVolume: 1379,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 51
        },
        {
          date: '2024-08-10',
          searchVolume: 1379,
          organicRank: 6,

          keywordSales: 51
        },
        {
          date: '2024-08-09',
          searchVolume: 1139,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-08',
          searchVolume: 1139,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 51
        },
        {
          date: '2024-08-07',
          searchVolume: 1139,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-06',
          searchVolume: 1139,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-05',
          searchVolume: 1139,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 51
        },
        {
          date: '2024-08-04',
          searchVolume: 1139,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-03',
          searchVolume: 1139,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-02',
          searchVolume: 1321,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-08-01',
          searchVolume: 1321,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 51
        },
        {
          date: '2024-07-31',
          searchVolume: 1321,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-07-30',
          searchVolume: 1321,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-07-29',
          searchVolume: 1321,
          organicRank: 7,

          keywordSales: 51
        },
        {
          date: '2024-07-28',
          searchVolume: 1321,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-07-27',
          searchVolume: 1321,
          organicRank: 6,
          sponsoredRank: 3,
          keywordSales: 51
        },
        {
          date: '2024-07-26',
          searchVolume: 2321,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-07-25',
          searchVolume: 2321,
          organicRank: 9,

          keywordSales: 51
        },
        {
          date: '2024-07-24',
          searchVolume: 2321,
          organicRank: 8,

          keywordSales: 51
        },
        {
          date: '2024-07-23',
          searchVolume: 2321,
          organicRank: 9,

          keywordSales: 51
        },
        {
          date: '2024-07-22',
          searchVolume: 2321,
          organicRank: 9,

          keywordSales: 51
        },
        {
          date: '2024-07-21',
          searchVolume: 2321,
          organicRank: 12,

          keywordSales: 51
        },
        {
          date: '2024-07-20',
          searchVolume: 2321,
          organicRank: 11,

          keywordSales: 51
        },
        {
          date: '2024-07-19',
          searchVolume: 1555,
          organicRank: 12,

          keywordSales: 51
        },
        {
          date: '2024-07-18',
          searchVolume: 1555,
          organicRank: 14,

          keywordSales: 51
        },
        {
          date: '2024-07-17',
          searchVolume: 1555,
          organicRank: 12,

          keywordSales: 51
        },
        {
          date: '2024-07-16',
          searchVolume: 1555,
          organicRank: 11,

          keywordSales: 51
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterflosser',
      keyword: 'waterflosser',
      searchVolume: 3454,
      organicStartRank: 28,
      organicHighestRank: 8,
      sponsoredStartRank: 38,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-11',
          searchVolume: 2474,
          organicRank: 13,

          keywordSales: 59
        },
        {
          date: '2024-09-10',
          searchVolume: 2474,
          organicRank: 19,
          sponsoredRank: 10,
          keywordSales: 59
        },
        {
          date: '2024-09-09',
          searchVolume: 2474,
          organicRank: 21,
          sponsoredRank: 47,
          keywordSales: 59
        },
        {
          date: '2024-09-08',
          searchVolume: 2474,
          organicRank: 21,
          sponsoredRank: 5,
          keywordSales: 59
        },
        {
          date: '2024-09-07',
          searchVolume: 2474,
          organicRank: 19,

          keywordSales: 59
        },
        {
          date: '2024-09-05',
          searchVolume: 2474,
          organicRank: 20,
          sponsoredRank: 35,
          keywordSales: 59
        },
        {
          date: '2024-09-04',
          searchVolume: 2474,
          organicRank: 21,
          sponsoredRank: 10,
          keywordSales: 59
        },
        {
          date: '2024-09-03',
          searchVolume: 2474,
          organicRank: 22,

          keywordSales: 59
        },
        {
          date: '2024-09-01',
          searchVolume: 2474,
          organicRank: 19,
          sponsoredRank: 12,
          keywordSales: 59
        },
        {
          date: '2024-08-28',
          searchVolume: 3137,
          organicRank: 15,
          sponsoredRank: 5,
          keywordSales: 59
        },
        {
          date: '2024-08-27',
          searchVolume: 3137,
          organicRank: 11,

          keywordSales: 59
        },
        {
          date: '2024-08-26',
          searchVolume: 3137,
          organicRank: 11,

          keywordSales: 59
        },
        {
          date: '2024-08-25',
          searchVolume: 3137,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 59
        },
        {
          date: '2024-08-24',
          searchVolume: 3137,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-08-23',
          searchVolume: 4322,
          organicRank: 14,

          keywordSales: 59
        },
        {
          date: '2024-08-22',
          searchVolume: 4322,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-08-21',
          searchVolume: 4322,
          organicRank: 11,

          keywordSales: 59
        },
        {
          date: '2024-08-19',
          searchVolume: 4322,
          organicRank: 11,
          sponsoredRank: 43,
          keywordSales: 59
        },
        {
          date: '2024-08-18',
          searchVolume: 4322,
          organicRank: 9,

          keywordSales: 59
        },
        {
          date: '2024-08-16',
          searchVolume: 2982,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-08-15',
          searchVolume: 2982,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-08-14',
          searchVolume: 2982,
          organicRank: 9,
          sponsoredRank: 7,
          keywordSales: 59
        },
        {
          date: '2024-08-13',
          searchVolume: 2982,
          organicRank: 9,

          keywordSales: 59
        },
        {
          date: '2024-08-12',
          searchVolume: 2982,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 59
        },
        {
          date: '2024-08-11',
          searchVolume: 2982,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 59
        },
        {
          date: '2024-08-10',
          searchVolume: 2982,
          organicRank: 9,

          keywordSales: 59
        },
        {
          date: '2024-08-09',
          searchVolume: 1796,
          organicRank: 8,

          keywordSales: 59
        },
        {
          date: '2024-08-08',
          searchVolume: 1796,
          organicRank: 9,

          keywordSales: 59
        },
        {
          date: '2024-08-07',
          searchVolume: 1796,
          organicRank: 10,

          keywordSales: 59
        },
        {
          date: '2024-08-06',
          searchVolume: 1796,
          organicRank: 13,

          keywordSales: 59
        },
        {
          date: '2024-08-05',
          searchVolume: 1796,
          organicRank: 11,

          keywordSales: 59
        },
        {
          date: '2024-08-04',
          searchVolume: 1796,
          organicRank: 18,

          keywordSales: 59
        },
        {
          date: '2024-08-03',
          searchVolume: 1796,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-08-02',
          searchVolume: 1787,
          organicRank: 10,

          keywordSales: 59
        },
        {
          date: '2024-08-01',
          searchVolume: 1787,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 59
        },
        {
          date: '2024-07-31',
          searchVolume: 1787,
          organicRank: 11,

          keywordSales: 59
        },
        {
          date: '2024-07-30',
          searchVolume: 1787,
          organicRank: 12,

          keywordSales: 59
        },
        {
          date: '2024-07-29',
          searchVolume: 1787,
          organicRank: 16,

          keywordSales: 59
        },
        {
          date: '2024-07-28',
          searchVolume: 1787,
          organicRank: 14,

          keywordSales: 59
        },
        {
          date: '2024-07-27',
          searchVolume: 1787,
          organicRank: 14,
          sponsoredRank: 4,
          keywordSales: 59
        },
        {
          date: '2024-07-26',
          searchVolume: 3454,
          organicRank: 13,
          sponsoredRank: 38,
          keywordSales: 59
        },
        {
          date: '2024-07-25',
          searchVolume: 3454,
          organicRank: 16,

          keywordSales: 59
        },
        {
          date: '2024-07-24',
          searchVolume: 3454,
          organicRank: 14,

          keywordSales: 59
        },
        {
          date: '2024-07-23',
          searchVolume: 3454,
          organicRank: 14,

          keywordSales: 59
        },
        {
          date: '2024-07-22',
          searchVolume: 3454,
          organicRank: 17,

          keywordSales: 59
        },
        {
          date: '2024-07-21',
          searchVolume: 3454,
          organicRank: 22,

          keywordSales: 59
        },
        {
          date: '2024-07-20',
          searchVolume: 3454,
          organicRank: 25,

          keywordSales: 59
        },
        {
          date: '2024-07-19',
          searchVolume: 1804,
          organicRank: 27,

          keywordSales: 59
        },
        {
          date: '2024-07-18',
          searchVolume: 1804,
          organicRank: 28,

          keywordSales: 59
        },
        {
          date: '2024-07-17',
          searchVolume: 1804,
          organicRank: 28,

          keywordSales: 59
        },
        {
          date: '2024-07-16',
          searchVolume: 1804,
          organicRank: 24,

          keywordSales: 59
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpic',
      keyword: 'waterpic',
      searchVolume: 5700,
      organicStartRank: 11,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2474,
          organicRank: 6,

          keywordSales: 151
        },
        {
          date: '2024-09-09',
          searchVolume: 2474,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-09-08',
          searchVolume: 2474,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-09-07',
          searchVolume: 2474,
          organicRank: 7,
          sponsoredRank: 11,
          keywordSales: 151
        },
        {
          date: '2024-09-06',
          searchVolume: 2474,
          organicRank: 6,
          sponsoredRank: 7,
          keywordSales: 151
        },
        {
          date: '2024-09-05',
          searchVolume: 2474,
          organicRank: 5,

          keywordSales: 151
        },
        {
          date: '2024-09-04',
          searchVolume: 2474,
          organicRank: 6,

          keywordSales: 151
        },
        {
          date: '2024-09-01',
          searchVolume: 2474,
          organicRank: 5,

          keywordSales: 151
        },
        {
          date: '2024-08-28',
          searchVolume: 3126,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-27',
          searchVolume: 3126,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-26',
          searchVolume: 3126,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 151
        },
        {
          date: '2024-08-25',
          searchVolume: 3126,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-24',
          searchVolume: 3126,
          organicRank: 3,

          keywordSales: 151
        },
        {
          date: '2024-08-23',
          searchVolume: 3126,
          organicRank: 2,

          keywordSales: 151
        },
        {
          date: '2024-08-22',
          searchVolume: 3126,
          organicRank: 2,

          keywordSales: 151
        },
        {
          date: '2024-08-21',
          searchVolume: 3126,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-20',
          searchVolume: 3126,
          organicRank: 2,
          sponsoredRank: 20,
          keywordSales: 151
        },
        {
          date: '2024-08-19',
          searchVolume: 3126,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-17',
          searchVolume: 3126,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-16',
          searchVolume: 2429,
          organicRank: 2,

          keywordSales: 151
        },
        {
          date: '2024-08-15',
          searchVolume: 2429,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-14',
          searchVolume: 2429,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-13',
          searchVolume: 2429,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-12',
          searchVolume: 2429,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-11',
          searchVolume: 2429,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-10',
          searchVolume: 2429,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-09',
          searchVolume: 2376,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-08',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-07',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 11,
          keywordSales: 151
        },
        {
          date: '2024-08-06',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-05',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-08-04',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-03',
          searchVolume: 2376,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-08-02',
          searchVolume: 2351,
          organicRank: 1,

          keywordSales: 151
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 2,

          keywordSales: 151
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-07-29',
          searchVolume: 2351,
          organicRank: 3,

          keywordSales: 151
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 151
        },
        {
          date: '2024-07-26',
          searchVolume: 5700,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-07-25',
          searchVolume: 5700,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-07-24',
          searchVolume: 5700,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-07-23',
          searchVolume: 5700,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 151
        },
        {
          date: '2024-07-22',
          searchVolume: 5700,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 151
        },
        {
          date: '2024-07-21',
          searchVolume: 5700,
          organicRank: 5,

          keywordSales: 151
        },
        {
          date: '2024-07-20',
          searchVolume: 5700,
          organicRank: 5,

          keywordSales: 151
        },
        {
          date: '2024-07-19',
          searchVolume: 2643,
          organicRank: 6,

          keywordSales: 151
        },
        {
          date: '2024-07-18',
          searchVolume: 2643,
          organicRank: 11,

          keywordSales: 151
        },
        {
          date: '2024-07-17',
          searchVolume: 2643,
          organicRank: 9,

          keywordSales: 151
        },
        {
          date: '2024-07-16',
          searchVolume: 2643,
          organicRank: 9,

          keywordSales: 151
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpick',
      keyword: 'waterpick',
      searchVolume: 19530,
      organicStartRank: 8,
      organicHighestRank: 1,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 8761,
          organicRank: 8,
          sponsoredRank: 9,
          keywordSales: 420
        },
        {
          date: '2024-09-09',
          searchVolume: 8761,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-09-08',
          searchVolume: 8761,
          organicRank: 7,
          sponsoredRank: 13,
          keywordSales: 420
        },
        {
          date: '2024-09-07',
          searchVolume: 8761,
          organicRank: 7,

          keywordSales: 420
        },
        {
          date: '2024-09-06',
          searchVolume: 8761,
          organicRank: 6,
          sponsoredRank: 9,
          keywordSales: 420
        },
        {
          date: '2024-09-04',
          searchVolume: 8761,
          organicRank: 6,
          sponsoredRank: 6,
          keywordSales: 420
        },
        {
          date: '2024-09-03',
          searchVolume: 8761,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 420
        },
        {
          date: '2024-09-01',
          searchVolume: 8761,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 420
        },
        {
          date: '2024-08-28',
          searchVolume: 9284,
          organicRank: 5,

          keywordSales: 420
        },
        {
          date: '2024-08-27',
          searchVolume: 9284,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-26',
          searchVolume: 9284,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-25',
          searchVolume: 9284,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-24',
          searchVolume: 9284,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-23',
          searchVolume: 10735,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-22',
          searchVolume: 10735,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-21',
          searchVolume: 10735,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-20',
          searchVolume: 10735,
          organicRank: 4,
          sponsoredRank: 22,
          keywordSales: 420
        },
        {
          date: '2024-08-19',
          searchVolume: 10735,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-17',
          searchVolume: 10735,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 420
        },
        {
          date: '2024-08-16',
          searchVolume: 8611,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-15',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 420
        },
        {
          date: '2024-08-14',
          searchVolume: 8611,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-08-13',
          searchVolume: 8611,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-12',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 420
        },
        {
          date: '2024-08-11',
          searchVolume: 8611,
          organicRank: 1,

          keywordSales: 420
        },
        {
          date: '2024-08-10',
          searchVolume: 8611,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-09',
          searchVolume: 7903,
          organicRank: 1,

          keywordSales: 420
        },
        {
          date: '2024-08-08',
          searchVolume: 7903,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-07',
          searchVolume: 7903,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-06',
          searchVolume: 7903,
          organicRank: 1,

          keywordSales: 420
        },
        {
          date: '2024-08-05',
          searchVolume: 7903,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-04',
          searchVolume: 7903,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-02',
          searchVolume: 7677,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-08-01',
          searchVolume: 7677,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-07-31',
          searchVolume: 7677,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-07-30',
          searchVolume: 7677,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-07-29',
          searchVolume: 7677,
          organicRank: 3,
          sponsoredRank: 22,
          keywordSales: 420
        },
        {
          date: '2024-07-28',
          searchVolume: 7677,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-07-27',
          searchVolume: 7677,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-07-26',
          searchVolume: 19530,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 420
        },
        {
          date: '2024-07-25',
          searchVolume: 19530,
          organicRank: 2,

          keywordSales: 420
        },
        {
          date: '2024-07-24',
          searchVolume: 19530,
          organicRank: 4,

          keywordSales: 420
        },
        {
          date: '2024-07-23',
          searchVolume: 19530,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 420
        },
        {
          date: '2024-07-22',
          searchVolume: 19530,
          organicRank: 5,
          sponsoredRank: 5,
          keywordSales: 420
        },
        {
          date: '2024-07-21',
          searchVolume: 19530,
          organicRank: 5,

          keywordSales: 420
        },
        {
          date: '2024-07-20',
          searchVolume: 19530,
          organicRank: 6,

          keywordSales: 420
        },
        {
          date: '2024-07-19',
          searchVolume: 8706,
          organicRank: 6,

          keywordSales: 420
        },
        {
          date: '2024-07-18',
          searchVolume: 8706,
          organicRank: 8,

          keywordSales: 420
        },
        {
          date: '2024-07-17',
          searchVolume: 8706,
          organicRank: 7,

          keywordSales: 420
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword:
        '70acc396-c92c-45cf-b7c6-69a3029e77a0_waterpick tooth brush and flosser cleaner toothbrush combo heads',
      keyword: 'waterpick tooth brush and flosser cleaner toothbrush combo heads',
      searchVolume: 1834,
      organicStartRank: 50,
      organicHighestRank: 4,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1181,
          organicRank: 8,

          keywordSales: 24
        },
        {
          date: '2024-09-09',
          searchVolume: 1181,
          organicRank: 9,

          keywordSales: 24
        },
        {
          date: '2024-09-08',
          searchVolume: 1181,
          organicRank: 7,
          sponsoredRank: 25,
          keywordSales: 24
        },
        {
          date: '2024-09-07',
          searchVolume: 1181,
          organicRank: 11,

          keywordSales: 24
        },
        {
          date: '2024-09-06',
          searchVolume: 1181,
          organicRank: 12,
          sponsoredRank: 8,
          keywordSales: 24
        },
        {
          date: '2024-09-05',
          searchVolume: 1181,
          organicRank: 10,

          keywordSales: 24
        },
        {
          date: '2024-09-04',
          searchVolume: 1181,
          organicRank: 8,

          keywordSales: 24
        },
        {
          date: '2024-09-01',
          searchVolume: 1181,
          organicRank: 11,

          keywordSales: 24
        },
        {
          date: '2024-08-28',
          searchVolume: 1219,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-27',
          searchVolume: 1219,
          organicRank: 8,
          sponsoredRank: 18,
          keywordSales: 24
        },
        {
          date: '2024-08-26',
          searchVolume: 1219,
          organicRank: 6,
          sponsoredRank: 6,
          keywordSales: 24
        },
        {
          date: '2024-08-25',
          searchVolume: 1219,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-24',
          searchVolume: 1219,
          organicRank: 4,

          keywordSales: 24
        },
        {
          date: '2024-08-23',
          searchVolume: 747,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-22',
          searchVolume: 747,
          organicRank: 5,

          keywordSales: 24
        },
        {
          date: '2024-08-21',
          searchVolume: 747,
          organicRank: 5,

          keywordSales: 24
        },
        {
          date: '2024-08-20',
          searchVolume: 747,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-19',
          searchVolume: 747,
          organicRank: 4,

          keywordSales: 24
        },
        {
          date: '2024-08-17',
          searchVolume: 747,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-16',
          searchVolume: 713,
          organicRank: 8,

          keywordSales: 24
        },
        {
          date: '2024-08-15',
          searchVolume: 713,
          organicRank: 5,

          keywordSales: 24
        },
        {
          date: '2024-08-14',
          searchVolume: 713,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-13',
          searchVolume: 713,
          organicRank: 8,

          keywordSales: 24
        },
        {
          date: '2024-08-12',
          searchVolume: 713,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-11',
          searchVolume: 713,
          organicRank: 10,

          keywordSales: 24
        },
        {
          date: '2024-08-10',
          searchVolume: 713,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 8,

          keywordSales: 24
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 6,

          keywordSales: 24
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-02',
          searchVolume: 1129,
          organicRank: 7,

          keywordSales: 24
        },
        {
          date: '2024-08-01',
          searchVolume: 1129,
          organicRank: 24,

          keywordSales: 24
        },
        {
          date: '2024-07-31',
          searchVolume: 1129,
          organicRank: 23,

          keywordSales: 24
        },
        {
          date: '2024-07-30',
          searchVolume: 1129,
          organicRank: 15,

          keywordSales: 24
        },
        {
          date: '2024-07-29',
          searchVolume: 1129,
          organicRank: 23,

          keywordSales: 24
        },
        {
          date: '2024-07-28',
          searchVolume: 1129,
          organicRank: 24,

          keywordSales: 24
        },
        {
          date: '2024-07-27',
          searchVolume: 1129,
          organicRank: 16,

          keywordSales: 24
        },
        {
          date: '2024-07-26',
          searchVolume: 1834,
          organicRank: 14,

          keywordSales: 24
        },
        {
          date: '2024-07-25',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 24
        },
        {
          date: '2024-07-24',
          searchVolume: 1834,
          organicRank: 27,

          keywordSales: 24
        },
        {
          date: '2024-07-23',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 24
        },
        {
          date: '2024-07-22',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 24
        },
        {
          date: '2024-07-21',
          searchVolume: 1834,
          organicRank: 24,

          keywordSales: 24
        },
        {
          date: '2024-07-20',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 24
        },
        {
          date: '2024-07-19',
          searchVolume: 1264,
          organicRank: 49,

          keywordSales: 24
        },
        {
          date: '2024-07-18',
          searchVolume: 1264,
          organicRank: 50,

          keywordSales: 24
        },
        {
          date: '2024-07-17',
          searchVolume: 1264,
          organicRank: 48,

          keywordSales: 24
        },
        {
          date: '2024-07-16',
          searchVolume: 1264,
          organicRank: 42,

          keywordSales: 24
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpicks by waterpik cordless',
      keyword: 'waterpicks by waterpik cordless',
      searchVolume: 4348,
      organicStartRank: 4,
      organicHighestRank: 1,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2472,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-09-09',
          searchVolume: 2472,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-09-08',
          searchVolume: 2472,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-09-07',
          searchVolume: 2472,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 127
        },
        {
          date: '2024-09-06',
          searchVolume: 2472,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-09-05',
          searchVolume: 2472,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-09-04',
          searchVolume: 2472,
          organicRank: 3,

          keywordSales: 127
        },
        {
          date: '2024-09-02',
          searchVolume: 2472,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-08-29',
          searchVolume: 2548,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 127
        },
        {
          date: '2024-08-28',
          searchVolume: 2548,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-27',
          searchVolume: 2548,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-26',
          searchVolume: 2548,
          organicRank: 1,
          sponsoredRank: 22,
          keywordSales: 127
        },
        {
          date: '2024-08-25',
          searchVolume: 2548,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-24',
          searchVolume: 2548,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-22',
          searchVolume: 2428,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-21',
          searchVolume: 2428,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-20',
          searchVolume: 2428,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-19',
          searchVolume: 2428,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 127
        },
        {
          date: '2024-08-18',
          searchVolume: 2428,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-08-16',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-15',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-14',
          searchVolume: 2318,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-08-13',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-12',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-11',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-10',
          searchVolume: 2318,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-09',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-08',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-07',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-06',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-05',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-04',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-03',
          searchVolume: 2230,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 127
        },
        {
          date: '2024-07-29',
          searchVolume: 2241,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-07-27',
          searchVolume: 2241,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 127
        },
        {
          date: '2024-07-26',
          searchVolume: 4348,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-25',
          searchVolume: 4348,
          organicRank: 1,

          keywordSales: 127
        },
        {
          date: '2024-07-24',
          searchVolume: 4348,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-23',
          searchVolume: 4348,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-22',
          searchVolume: 4348,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-21',
          searchVolume: 4348,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-20',
          searchVolume: 4348,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 127
        },
        {
          date: '2024-07-19',
          searchVolume: 3401,
          organicRank: 2,

          keywordSales: 127
        },
        {
          date: '2024-07-18',
          searchVolume: 3401,
          organicRank: 4,

          keywordSales: 127
        },
        {
          date: '2024-07-17',
          searchVolume: 3401,
          organicRank: 5,

          keywordSales: 127
        },
        {
          date: '2024-07-16',
          searchVolume: 3401,
          organicRank: 4,

          keywordSales: 127
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik',
      keyword: 'waterpik',
      searchVolume: 392279,
      organicStartRank: 11,
      organicHighestRank: 1,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 365820,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-09-09',
          searchVolume: 365820,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-09-08',
          searchVolume: 365820,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 4006
        },
        {
          date: '2024-09-07',
          searchVolume: 365820,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 4006
        },
        {
          date: '2024-09-06',
          searchVolume: 365820,
          organicRank: 8,

          keywordSales: 4006
        },
        {
          date: '2024-09-05',
          searchVolume: 365820,
          organicRank: 6,
          sponsoredRank: 3,
          keywordSales: 4006
        },
        {
          date: '2024-09-04',
          searchVolume: 365820,
          organicRank: 6,

          keywordSales: 4006
        },
        {
          date: '2024-09-03',
          searchVolume: 365820,
          organicRank: 6,
          sponsoredRank: 34,
          keywordSales: 4006
        },
        {
          date: '2024-09-02',
          searchVolume: 365820,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 4006
        },
        {
          date: '2024-08-29',
          searchVolume: 376519,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 4006
        },
        {
          date: '2024-08-28',
          searchVolume: 376519,
          organicRank: 6,
          sponsoredRank: 4,
          keywordSales: 4006
        },
        {
          date: '2024-08-27',
          searchVolume: 376519,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-26',
          searchVolume: 376519,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 4006
        },
        {
          date: '2024-08-25',
          searchVolume: 376519,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-24',
          searchVolume: 376519,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 4006
        },
        {
          date: '2024-08-23',
          searchVolume: 376576,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 4006
        },
        {
          date: '2024-08-22',
          searchVolume: 376576,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-21',
          searchVolume: 376576,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 4006
        },
        {
          date: '2024-08-20',
          searchVolume: 376576,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-19',
          searchVolume: 376576,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-16',
          searchVolume: 252683,
          organicRank: 2,

          keywordSales: 4006
        },
        {
          date: '2024-08-15',
          searchVolume: 252683,
          organicRank: 2,

          keywordSales: 4006
        },
        {
          date: '2024-08-14',
          searchVolume: 252683,
          organicRank: 2,

          keywordSales: 4006
        },
        {
          date: '2024-08-13',
          searchVolume: 252683,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 4006
        },
        {
          date: '2024-08-11',
          searchVolume: 252683,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-10',
          searchVolume: 252683,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-09',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-08',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-07',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 11,
          keywordSales: 4006
        },
        {
          date: '2024-08-06',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 9,
          keywordSales: 4006
        },
        {
          date: '2024-08-05',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-04',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-03',
          searchVolume: 268438,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-02',
          searchVolume: 242228,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-08-01',
          searchVolume: 242228,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-07-31',
          searchVolume: 242228,
          organicRank: 3,

          keywordSales: 4006
        },
        {
          date: '2024-07-30',
          searchVolume: 242228,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-07-29',
          searchVolume: 242228,
          organicRank: 2,
          sponsoredRank: 22,
          keywordSales: 4006
        },
        {
          date: '2024-07-28',
          searchVolume: 242228,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-07-27',
          searchVolume: 242228,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-07-26',
          searchVolume: 392279,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 4006
        },
        {
          date: '2024-07-25',
          searchVolume: 392279,
          organicRank: 3,

          keywordSales: 4006
        },
        {
          date: '2024-07-24',
          searchVolume: 392279,
          organicRank: 3,

          keywordSales: 4006
        },
        {
          date: '2024-07-23',
          searchVolume: 392279,
          organicRank: 4,
          sponsoredRank: 4,
          keywordSales: 4006
        },
        {
          date: '2024-07-22',
          searchVolume: 392279,
          organicRank: 4,
          sponsoredRank: 5,
          keywordSales: 4006
        },
        {
          date: '2024-07-21',
          searchVolume: 392279,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 4006
        },
        {
          date: '2024-07-20',
          searchVolume: 392279,
          organicRank: 6,

          keywordSales: 4006
        },
        {
          date: '2024-07-19',
          searchVolume: 199922,
          organicRank: 6,

          keywordSales: 4006
        },
        {
          date: '2024-07-18',
          searchVolume: 199922,
          organicRank: 11,

          keywordSales: 4006
        },
        {
          date: '2024-07-17',
          searchVolume: 199922,
          organicRank: 9,

          keywordSales: 4006
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik aquarius water flosser',
      keyword: 'waterpik aquarius water flosser',
      searchVolume: 11237,
      organicStartRank: 5,
      organicHighestRank: 2,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-18',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4416,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-09-09',
          searchVolume: 4416,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-09-08',
          searchVolume: 4416,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-09-07',
          searchVolume: 4416,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-09-06',
          searchVolume: 4416,
          organicRank: 4,
          sponsoredRank: 4,
          keywordSales: 254
        },
        {
          date: '2024-09-05',
          searchVolume: 4416,
          organicRank: 4,
          sponsoredRank: 10,
          keywordSales: 254
        },
        {
          date: '2024-09-04',
          searchVolume: 4416,
          organicRank: 10,

          keywordSales: 254
        },
        {
          date: '2024-09-02',
          searchVolume: 4416,
          organicRank: 5,

          keywordSales: 254
        },
        {
          date: '2024-08-29',
          searchVolume: 4324,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-08-28',
          searchVolume: 4324,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-08-27',
          searchVolume: 4324,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-08-26',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-25',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-24',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-22',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-21',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-20',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-19',
          searchVolume: 4324,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-08-18',
          searchVolume: 4324,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-16',
          searchVolume: 4041,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-15',
          searchVolume: 4041,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-08-14',
          searchVolume: 4041,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-08-13',
          searchVolume: 4041,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-12',
          searchVolume: 4041,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-11',
          searchVolume: 4041,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-08-10',
          searchVolume: 4041,
          organicRank: 2,

          keywordSales: 254
        },
        {
          date: '2024-08-09',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-08',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-07',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-06',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-05',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-04',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-03',
          searchVolume: 3374,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-02',
          searchVolume: 3350,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-08-01',
          searchVolume: 3350,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-07-31',
          searchVolume: 3350,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-07-30',
          searchVolume: 3350,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-07-29',
          searchVolume: 3350,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-07-28',
          searchVolume: 3350,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 254
        },
        {
          date: '2024-07-27',
          searchVolume: 3350,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 254
        },
        {
          date: '2024-07-26',
          searchVolume: 11237,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-07-25',
          searchVolume: 11237,
          organicRank: 3,

          keywordSales: 254
        },
        {
          date: '2024-07-24',
          searchVolume: 11237,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-07-23',
          searchVolume: 11237,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-07-22',
          searchVolume: 11237,
          organicRank: 5,

          keywordSales: 254
        },
        {
          date: '2024-07-21',
          searchVolume: 11237,
          organicRank: 4,

          keywordSales: 254
        },
        {
          date: '2024-07-20',
          searchVolume: 11237,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 254
        },
        {
          date: '2024-07-19',
          searchVolume: 3263,
          organicRank: 5,

          keywordSales: 254
        },
        {
          date: '2024-07-18',
          searchVolume: 3263,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 254
        },
        {
          date: '2024-07-17',
          searchVolume: 3263,
          organicRank: 7,

          keywordSales: 254
        },
        {
          date: '2024-07-16',
          searchVolume: 3263,
          organicRank: 7,

          keywordSales: 254
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik cordless',
      keyword: 'waterpik cordless',
      searchVolume: 5695,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 3372,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-09-09',
          searchVolume: 3372,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-09-08',
          searchVolume: 3372,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 138
        },
        {
          date: '2024-09-07',
          searchVolume: 3372,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-09-06',
          searchVolume: 3372,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-09-05',
          searchVolume: 3372,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 138
        },
        {
          date: '2024-09-01',
          searchVolume: 3372,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 138
        },
        {
          date: '2024-08-31',
          searchVolume: 3372,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 138
        },
        {
          date: '2024-08-29',
          searchVolume: 3143,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-08-28',
          searchVolume: 3143,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-08-27',
          searchVolume: 3143,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-08-26',
          searchVolume: 3143,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-25',
          searchVolume: 3143,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-24',
          searchVolume: 3143,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-22',
          searchVolume: 3145,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-21',
          searchVolume: 3145,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 138
        },
        {
          date: '2024-08-20',
          searchVolume: 3145,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-19',
          searchVolume: 3145,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-18',
          searchVolume: 3145,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 138
        },
        {
          date: '2024-08-16',
          searchVolume: 2434,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 138
        },
        {
          date: '2024-08-15',
          searchVolume: 2434,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-14',
          searchVolume: 2434,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-13',
          searchVolume: 2434,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-12',
          searchVolume: 2434,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-11',
          searchVolume: 2434,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-10',
          searchVolume: 2434,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-09',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-08',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-07',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-06',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 138
        },
        {
          date: '2024-08-05',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-08-04',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 138
        },
        {
          date: '2024-08-03',
          searchVolume: 2378,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-02',
          searchVolume: 2797,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-08-01',
          searchVolume: 2797,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-31',
          searchVolume: 2797,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-07-30',
          searchVolume: 2797,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-29',
          searchVolume: 2797,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-28',
          searchVolume: 2797,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-27',
          searchVolume: 2797,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-26',
          searchVolume: 5695,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-25',
          searchVolume: 5695,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-07-24',
          searchVolume: 5695,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-07-23',
          searchVolume: 5695,
          organicRank: 1,

          keywordSales: 138
        },
        {
          date: '2024-07-22',
          searchVolume: 5695,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-21',
          searchVolume: 5695,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 138
        },
        {
          date: '2024-07-20',
          searchVolume: 5695,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-07-19',
          searchVolume: 2393,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-07-18',
          searchVolume: 2393,
          organicRank: 2,

          keywordSales: 138
        },
        {
          date: '2024-07-17',
          searchVolume: 2393,
          organicRank: 2,

          keywordSales: 138
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik cordless water flosser',
      keyword: 'waterpik cordless water flosser',
      searchVolume: 19050,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 12991,
          organicRank: 2,
          sponsoredRank: 12,
          keywordSales: 517
        },
        {
          date: '2024-09-09',
          searchVolume: 12991,
          organicRank: 3,

          keywordSales: 517
        },
        {
          date: '2024-09-08',
          searchVolume: 12991,
          organicRank: 2,
          sponsoredRank: 10,
          keywordSales: 517
        },
        {
          date: '2024-09-07',
          searchVolume: 12991,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-09-06',
          searchVolume: 12991,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-09-05',
          searchVolume: 12991,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 517
        },
        {
          date: '2024-09-04',
          searchVolume: 12991,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-09-01',
          searchVolume: 12991,
          organicRank: 3,

          keywordSales: 517
        },
        {
          date: '2024-08-28',
          searchVolume: 12233,
          organicRank: 2,
          sponsoredRank: 20,
          keywordSales: 517
        },
        {
          date: '2024-08-27',
          searchVolume: 12233,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-26',
          searchVolume: 12233,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-25',
          searchVolume: 12233,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-24',
          searchVolume: 12233,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-23',
          searchVolume: 13415,
          organicRank: 1,

          keywordSales: 517
        },
        {
          date: '2024-08-22',
          searchVolume: 13415,
          organicRank: 1,

          keywordSales: 517
        },
        {
          date: '2024-08-21',
          searchVolume: 13415,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-20',
          searchVolume: 13415,
          organicRank: 1,

          keywordSales: 517
        },
        {
          date: '2024-08-19',
          searchVolume: 13415,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 517
        },
        {
          date: '2024-08-17',
          searchVolume: 13415,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 517
        },
        {
          date: '2024-08-16',
          searchVolume: 11215,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 517
        },
        {
          date: '2024-08-15',
          searchVolume: 11215,
          organicRank: 1,

          keywordSales: 517
        },
        {
          date: '2024-08-14',
          searchVolume: 11215,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-13',
          searchVolume: 11215,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-12',
          searchVolume: 11215,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-11',
          searchVolume: 11215,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-10',
          searchVolume: 11215,
          organicRank: 1,

          keywordSales: 517
        },
        {
          date: '2024-08-09',
          searchVolume: 10303,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-08',
          searchVolume: 10303,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-07',
          searchVolume: 10303,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-06',
          searchVolume: 10303,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 517
        },
        {
          date: '2024-08-05',
          searchVolume: 10303,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-04',
          searchVolume: 10303,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-03',
          searchVolume: 10303,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-08-02',
          searchVolume: 9930,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-08-01',
          searchVolume: 9930,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-31',
          searchVolume: 9930,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-30',
          searchVolume: 9930,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-29',
          searchVolume: 9930,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-28',
          searchVolume: 9930,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-27',
          searchVolume: 9930,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-26',
          searchVolume: 19050,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-25',
          searchVolume: 19050,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-24',
          searchVolume: 19050,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-23',
          searchVolume: 19050,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-22',
          searchVolume: 19050,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 517
        },
        {
          date: '2024-07-21',
          searchVolume: 19050,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-20',
          searchVolume: 19050,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-19',
          searchVolume: 10107,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-18',
          searchVolume: 10107,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-17',
          searchVolume: 10107,
          organicRank: 2,

          keywordSales: 517
        },
        {
          date: '2024-07-16',
          searchVolume: 10107,
          organicRank: 2,

          keywordSales: 517
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik flosser',
      keyword: 'waterpik flosser',
      searchVolume: 4683,
      organicStartRank: 7,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2474,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-09-09',
          searchVolume: 2474,
          organicRank: 5,

          keywordSales: 95
        },
        {
          date: '2024-09-08',
          searchVolume: 2474,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-09-07',
          searchVolume: 2474,
          organicRank: 5,

          keywordSales: 95
        },
        {
          date: '2024-09-06',
          searchVolume: 2474,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-09-05',
          searchVolume: 2474,
          organicRank: 5,
          sponsoredRank: 42,
          keywordSales: 95
        },
        {
          date: '2024-09-04',
          searchVolume: 2474,
          organicRank: 5,

          keywordSales: 95
        },
        {
          date: '2024-09-01',
          searchVolume: 2474,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 95
        },
        {
          date: '2024-08-28',
          searchVolume: 2550,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-08-27',
          searchVolume: 2550,
          organicRank: 3,

          keywordSales: 95
        },
        {
          date: '2024-08-26',
          searchVolume: 2550,
          organicRank: 3,

          keywordSales: 95
        },
        {
          date: '2024-08-25',
          searchVolume: 2550,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-08-24',
          searchVolume: 2550,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-08-23',
          searchVolume: 3479,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-08-22',
          searchVolume: 3479,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 95
        },
        {
          date: '2024-08-20',
          searchVolume: 3479,
          organicRank: 3,
          sponsoredRank: 5,
          keywordSales: 95
        },
        {
          date: '2024-08-19',
          searchVolume: 3479,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 95
        },
        {
          date: '2024-08-17',
          searchVolume: 3479,
          organicRank: 3,
          sponsoredRank: 4,
          keywordSales: 95
        },
        {
          date: '2024-08-16',
          searchVolume: 2998,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-08-15',
          searchVolume: 2998,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-08-14',
          searchVolume: 2998,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-08-13',
          searchVolume: 2998,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 95
        },
        {
          date: '2024-08-12',
          searchVolume: 2998,
          organicRank: 1,

          keywordSales: 95
        },
        {
          date: '2024-08-11',
          searchVolume: 2998,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-08-10',
          searchVolume: 2998,
          organicRank: 1,

          keywordSales: 95
        },
        {
          date: '2024-08-09',
          searchVolume: 2378,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-08-08',
          searchVolume: 2378,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-08-07',
          searchVolume: 2378,
          organicRank: 1,

          keywordSales: 95
        },
        {
          date: '2024-08-06',
          searchVolume: 2378,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-08-05',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-08-04',
          searchVolume: 2378,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-08-02',
          searchVolume: 2245,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 95
        },
        {
          date: '2024-08-01',
          searchVolume: 2245,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-31',
          searchVolume: 2245,
          organicRank: 2,

          keywordSales: 95
        },
        {
          date: '2024-07-30',
          searchVolume: 2245,
          organicRank: 3,
          sponsoredRank: 5,
          keywordSales: 95
        },
        {
          date: '2024-07-29',
          searchVolume: 2245,
          organicRank: 3,

          keywordSales: 95
        },
        {
          date: '2024-07-28',
          searchVolume: 2245,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-07-27',
          searchVolume: 2245,
          organicRank: 1,

          keywordSales: 95
        },
        {
          date: '2024-07-26',
          searchVolume: 4683,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-25',
          searchVolume: 4683,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-24',
          searchVolume: 4683,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-23',
          searchVolume: 4683,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-22',
          searchVolume: 4683,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 95
        },
        {
          date: '2024-07-21',
          searchVolume: 4683,
          organicRank: 4,

          keywordSales: 95
        },
        {
          date: '2024-07-20',
          searchVolume: 4683,
          organicRank: 5,

          keywordSales: 95
        },
        {
          date: '2024-07-19',
          searchVolume: 2245,
          organicRank: 5,

          keywordSales: 95
        },
        {
          date: '2024-07-18',
          searchVolume: 2245,
          organicRank: 7,

          keywordSales: 95
        },
        {
          date: '2024-07-17',
          searchVolume: 2245,
          organicRank: 8,

          keywordSales: 95
        },
        {
          date: '2024-07-16',
          searchVolume: 2245,
          organicRank: 8,

          keywordSales: 95
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik for braces',
      keyword: 'waterpik for braces',
      searchVolume: 1165,
      organicStartRank: 20,
      organicHighestRank: 3,
      sponsoredStartRank: 53,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 785,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-09-09',
          searchVolume: 785,
          organicRank: 6,

          keywordSales: 7
        },
        {
          date: '2024-09-08',
          searchVolume: 785,
          organicRank: 5,
          sponsoredRank: 6,
          keywordSales: 7
        },
        {
          date: '2024-09-07',
          searchVolume: 785,
          organicRank: 8,

          keywordSales: 7
        },
        {
          date: '2024-09-06',
          searchVolume: 785,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-09-05',
          searchVolume: 785,
          organicRank: 7,

          keywordSales: 7
        },
        {
          date: '2024-09-04',
          searchVolume: 785,
          organicRank: 6,

          keywordSales: 7
        },
        {
          date: '2024-09-01',
          searchVolume: 785,
          organicRank: 8,
          sponsoredRank: 20,
          keywordSales: 7
        },
        {
          date: '2024-08-28',
          searchVolume: 847,
          organicRank: 4,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-08-27',
          searchVolume: 847,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-08-26',
          searchVolume: 847,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-08-25',
          searchVolume: 847,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-24',
          searchVolume: 847,
          organicRank: 5,

          keywordSales: 7
        },
        {
          date: '2024-08-23',
          searchVolume: 951,
          organicRank: 7,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-08-22',
          searchVolume: 951,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-08-21',
          searchVolume: 951,
          organicRank: 3,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-08-20',
          searchVolume: 951,
          organicRank: 4,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-08-19',
          searchVolume: 951,
          organicRank: 5,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-08-17',
          searchVolume: 951,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-08-16',
          searchVolume: 909,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-08-15',
          searchVolume: 909,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-08-14',
          searchVolume: 909,
          organicRank: 3,

          keywordSales: 7
        },
        {
          date: '2024-08-13',
          searchVolume: 909,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-12',
          searchVolume: 909,
          organicRank: 3,

          keywordSales: 7
        },
        {
          date: '2024-08-11',
          searchVolume: 909,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-10',
          searchVolume: 909,
          organicRank: 5,

          keywordSales: 7
        },
        {
          date: '2024-08-09',
          searchVolume: 755,
          organicRank: 4,

          keywordSales: 7
        },
        {
          date: '2024-08-08',
          searchVolume: 755,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-07',
          searchVolume: 755,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-06',
          searchVolume: 755,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-05',
          searchVolume: 755,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-08-04',
          searchVolume: 755,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-08-03',
          searchVolume: 755,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-08-02',
          searchVolume: 820,
          organicRank: 5,

          keywordSales: 7
        },
        {
          date: '2024-08-01',
          searchVolume: 820,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-31',
          searchVolume: 820,
          organicRank: 5,

          keywordSales: 7
        },
        {
          date: '2024-07-30',
          searchVolume: 820,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-29',
          searchVolume: 820,
          organicRank: 7,

          keywordSales: 7
        },
        {
          date: '2024-07-28',
          searchVolume: 820,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-07-27',
          searchVolume: 820,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-26',
          searchVolume: 1165,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-07-25',
          searchVolume: 1165,
          organicRank: 7,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-24',
          searchVolume: 1165,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-23',
          searchVolume: 1165,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-07-22',
          searchVolume: 1165,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-07-21',
          searchVolume: 1165,
          organicRank: 15,

          keywordSales: 7
        },
        {
          date: '2024-07-20',
          searchVolume: 1165,
          organicRank: 14,

          keywordSales: 7
        },
        {
          date: '2024-07-19',
          searchVolume: 830,
          organicRank: 18,

          keywordSales: 7
        },
        {
          date: '2024-07-18',
          searchVolume: 830,
          organicRank: 20,
          sponsoredRank: 53,
          keywordSales: 7
        },
        {
          date: '2024-07-17',
          searchVolume: 830,
          organicRank: 19,

          keywordSales: 7
        },
        {
          date: '2024-07-16',
          searchVolume: 830,
          organicRank: 19,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik for kids',
      keyword: 'waterpik for kids',
      searchVolume: 1841,
      organicStartRank: 45,
      organicHighestRank: 7,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-26',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 10,
          sponsoredRank: 24,
          keywordSales: 43
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 43
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-09-01',
          searchVolume: 1388,
          organicRank: 13,

          keywordSales: 43
        },
        {
          date: '2024-08-29',
          searchVolume: 1607,
          organicRank: 15,
          sponsoredRank: 19,
          keywordSales: 43
        },
        {
          date: '2024-08-27',
          searchVolume: 1607,
          organicRank: 12,

          keywordSales: 43
        },
        {
          date: '2024-08-26',
          searchVolume: 1607,
          organicRank: 16,

          keywordSales: 43
        },
        {
          date: '2024-08-25',
          searchVolume: 1607,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-08-24',
          searchVolume: 1607,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-23',
          searchVolume: 1657,
          organicRank: 11,

          keywordSales: 43
        },
        {
          date: '2024-08-22',
          searchVolume: 1657,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-21',
          searchVolume: 1657,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-20',
          searchVolume: 1657,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-19',
          searchVolume: 1657,
          organicRank: 9,

          keywordSales: 43
        },
        {
          date: '2024-08-17',
          searchVolume: 1657,
          organicRank: 11,

          keywordSales: 43
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 7,

          keywordSales: 43
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-08-09',
          searchVolume: 1499,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-08-08',
          searchVolume: 1499,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 43
        },
        {
          date: '2024-08-07',
          searchVolume: 1499,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-08-06',
          searchVolume: 1499,
          organicRank: 14,

          keywordSales: 43
        },
        {
          date: '2024-08-05',
          searchVolume: 1499,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-08-04',
          searchVolume: 1499,
          organicRank: 16,

          keywordSales: 43
        },
        {
          date: '2024-08-03',
          searchVolume: 1499,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 1322,
          organicRank: 22,

          keywordSales: 43
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 25,

          keywordSales: 43
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-07-26',
          searchVolume: 1841,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-07-25',
          searchVolume: 1841,
          organicRank: 23,

          keywordSales: 43
        },
        {
          date: '2024-07-24',
          searchVolume: 1841,
          organicRank: 28,

          keywordSales: 43
        },
        {
          date: '2024-07-23',
          searchVolume: 1841,
          organicRank: 30,

          keywordSales: 43
        },
        {
          date: '2024-07-22',
          searchVolume: 1841,
          organicRank: 26,

          keywordSales: 43
        },
        {
          date: '2024-07-21',
          searchVolume: 1841,
          organicRank: 45,

          keywordSales: 43
        },
        {
          date: '2024-07-20',
          searchVolume: 1841,
          organicRank: 39,

          keywordSales: 43
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 44,

          keywordSales: 43
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 45,

          keywordSales: 43
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 43,

          keywordSales: 43
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 42,

          keywordSales: 43
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik for tonsil stones',
      keyword: 'waterpik for tonsil stones',
      searchVolume: 846,
      organicStartRank: 39,
      organicHighestRank: 14,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-08-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 859,
          organicRank: 29,

          keywordSales: 19
        },
        {
          date: '2024-09-09',
          searchVolume: 859,
          organicRank: 30,

          keywordSales: 19
        },
        {
          date: '2024-09-08',
          searchVolume: 859,
          organicRank: 35,

          keywordSales: 19
        },
        {
          date: '2024-09-07',
          searchVolume: 859,
          organicRank: 34,
          sponsoredRank: 14,
          keywordSales: 19
        },
        {
          date: '2024-09-06',
          searchVolume: 859,
          organicRank: 36,

          keywordSales: 19
        },
        {
          date: '2024-09-05',
          searchVolume: 859,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-09-04',
          searchVolume: 859,
          organicRank: 33,

          keywordSales: 19
        },
        {
          date: '2024-09-02',
          searchVolume: 859,
          organicRank: 28,

          keywordSales: 19
        },
        {
          date: '2024-08-29',
          searchVolume: 810,
          organicRank: 27,

          keywordSales: 19
        },
        {
          date: '2024-08-28',
          searchVolume: 810,
          organicRank: 28,

          keywordSales: 19
        },
        {
          date: '2024-08-27',
          searchVolume: 810,
          organicRank: 24,

          keywordSales: 19
        },
        {
          date: '2024-08-26',
          searchVolume: 810,
          organicRank: 25,

          keywordSales: 19
        },
        {
          date: '2024-08-25',
          searchVolume: 810,
          organicRank: 25,

          keywordSales: 19
        },
        {
          date: '2024-08-24',
          searchVolume: 810,
          organicRank: 22,

          keywordSales: 19
        },
        {
          date: '2024-08-22',
          searchVolume: 884,
          organicRank: 23,

          keywordSales: 19
        },
        {
          date: '2024-08-21',
          searchVolume: 884,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-08-20',
          searchVolume: 884,
          organicRank: 17,

          keywordSales: 19
        },
        {
          date: '2024-08-19',
          searchVolume: 884,
          organicRank: 27,

          keywordSales: 19
        },
        {
          date: '2024-08-18',
          searchVolume: 884,
          organicRank: 23,

          keywordSales: 19
        },
        {
          date: '2024-08-16',
          searchVolume: 1164,
          organicRank: 22,

          keywordSales: 19
        },
        {
          date: '2024-08-15',
          searchVolume: 1164,
          organicRank: 24,

          keywordSales: 19
        },
        {
          date: '2024-08-14',
          searchVolume: 1164,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-08-13',
          searchVolume: 1164,
          organicRank: 30,

          keywordSales: 19
        },
        {
          date: '2024-08-12',
          searchVolume: 1164,
          organicRank: 23,

          keywordSales: 19
        },
        {
          date: '2024-08-11',
          searchVolume: 1164,
          organicRank: 29,
          sponsoredRank: 3,
          keywordSales: 19
        },
        {
          date: '2024-08-10',
          searchVolume: 1164,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-08-09',
          searchVolume: 827,
          organicRank: 30,

          keywordSales: 19
        },
        {
          date: '2024-08-08',
          searchVolume: 827,
          organicRank: 30,

          keywordSales: 19
        },
        {
          date: '2024-08-07',
          searchVolume: 827,
          organicRank: 14,

          keywordSales: 19
        },
        {
          date: '2024-08-06',
          searchVolume: 827,
          organicRank: 21,

          keywordSales: 19
        },
        {
          date: '2024-08-05',
          searchVolume: 827,
          organicRank: 25,
          sponsoredRank: 1,
          keywordSales: 19
        },
        {
          date: '2024-08-04',
          searchVolume: 827,
          organicRank: 27,

          keywordSales: 19
        },
        {
          date: '2024-08-03',
          searchVolume: 827,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-08-02',
          searchVolume: 894,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-08-01',
          searchVolume: 894,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-31',
          searchVolume: 894,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-30',
          searchVolume: 894,
          organicRank: 28,

          keywordSales: 19
        },
        {
          date: '2024-07-29',
          searchVolume: 894,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-28',
          searchVolume: 894,
          organicRank: 25,

          keywordSales: 19
        },
        {
          date: '2024-07-27',
          searchVolume: 894,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-26',
          searchVolume: 846,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-25',
          searchVolume: 846,
          organicRank: 26,

          keywordSales: 19
        },
        {
          date: '2024-07-24',
          searchVolume: 846,
          organicRank: 24,

          keywordSales: 19
        },
        {
          date: '2024-07-23',
          searchVolume: 846,
          organicRank: 29,

          keywordSales: 19
        },
        {
          date: '2024-07-22',
          searchVolume: 846,
          organicRank: 38,

          keywordSales: 19
        },
        {
          date: '2024-07-21',
          searchVolume: 846,
          organicRank: 36,

          keywordSales: 19
        },
        {
          date: '2024-07-20',
          searchVolume: 846,
          organicRank: 33,

          keywordSales: 19
        },
        {
          date: '2024-07-19',
          searchVolume: 703,
          organicRank: 35,

          keywordSales: 19
        },
        {
          date: '2024-07-18',
          searchVolume: 703,
          organicRank: 39,

          keywordSales: 19
        },
        {
          date: '2024-07-17',
          searchVolume: 703,
          organicRank: 38,

          keywordSales: 19
        },
        {
          date: '2024-07-16',
          searchVolume: 703,
          organicRank: 36,

          keywordSales: 19
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik ion water flosser',
      keyword: 'waterpik ion water flosser',
      searchVolume: 2318,
      organicStartRank: 7,
      organicHighestRank: 3,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 924,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-09-09',
          searchVolume: 924,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-09-08',
          searchVolume: 924,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-09-07',
          searchVolume: 924,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-09-06',
          searchVolume: 924,
          organicRank: 5,
          sponsoredRank: 12,
          keywordSales: 31
        },
        {
          date: '2024-09-05',
          searchVolume: 924,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-09-04',
          searchVolume: 924,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-09-02',
          searchVolume: 924,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-08-29',
          searchVolume: 886,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-08-28',
          searchVolume: 886,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-08-27',
          searchVolume: 886,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-26',
          searchVolume: 886,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-25',
          searchVolume: 886,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-24',
          searchVolume: 886,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-22',
          searchVolume: 749,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-21',
          searchVolume: 749,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-20',
          searchVolume: 749,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-19',
          searchVolume: 749,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-08-18',
          searchVolume: 749,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-16',
          searchVolume: 713,
          organicRank: 3,

          keywordSales: 31
        },
        {
          date: '2024-08-15',
          searchVolume: 713,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-14',
          searchVolume: 713,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-13',
          searchVolume: 713,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-12',
          searchVolume: 713,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-11',
          searchVolume: 713,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-08-10',
          searchVolume: 713,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-09',
          searchVolume: 824,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-08',
          searchVolume: 824,
          organicRank: 3,

          keywordSales: 31
        },
        {
          date: '2024-08-07',
          searchVolume: 824,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-06',
          searchVolume: 824,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-05',
          searchVolume: 824,
          organicRank: 3,

          keywordSales: 31
        },
        {
          date: '2024-08-04',
          searchVolume: 824,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-03',
          searchVolume: 824,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-02',
          searchVolume: 817,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-08-01',
          searchVolume: 817,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-07-31',
          searchVolume: 817,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-30',
          searchVolume: 817,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-29',
          searchVolume: 817,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-28',
          searchVolume: 817,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-27',
          searchVolume: 817,
          organicRank: 4,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-07-26',
          searchVolume: 2318,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-25',
          searchVolume: 2318,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-24',
          searchVolume: 2318,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-23',
          searchVolume: 2318,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-22',
          searchVolume: 2318,
          organicRank: 4,

          keywordSales: 31
        },
        {
          date: '2024-07-21',
          searchVolume: 2318,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-07-20',
          searchVolume: 2318,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-07-19',
          searchVolume: 1268,
          organicRank: 5,

          keywordSales: 31
        },
        {
          date: '2024-07-18',
          searchVolume: 1268,
          organicRank: 7,

          keywordSales: 31
        },
        {
          date: '2024-07-17',
          searchVolume: 1268,
          organicRank: 7,

          keywordSales: 31
        },
        {
          date: '2024-07-16',
          searchVolume: 1268,
          organicRank: 6,

          keywordSales: 31
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik portable',
      keyword: 'waterpik portable',
      searchVolume: 1837,
      organicStartRank: 1,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 857,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-09-09',
          searchVolume: 857,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-09-08',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-09-07',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-09-06',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-09-05',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-09-04',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-09-02',
          searchVolume: 857,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-29',
          searchVolume: 747,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-28',
          searchVolume: 747,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-27',
          searchVolume: 747,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-26',
          searchVolume: 747,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-25',
          searchVolume: 747,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-24',
          searchVolume: 747,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-22',
          searchVolume: 810,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-21',
          searchVolume: 810,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 26
        },
        {
          date: '2024-08-20',
          searchVolume: 810,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-19',
          searchVolume: 810,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-18',
          searchVolume: 810,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-08-16',
          searchVolume: 642,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-08-15',
          searchVolume: 642,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-14',
          searchVolume: 642,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-13',
          searchVolume: 642,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-08-12',
          searchVolume: 642,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-11',
          searchVolume: 642,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-10',
          searchVolume: 642,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-08-09',
          searchVolume: 696,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-08',
          searchVolume: 696,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-08-07',
          searchVolume: 696,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-06',
          searchVolume: 696,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-05',
          searchVolume: 696,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-08-04',
          searchVolume: 696,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-03',
          searchVolume: 696,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-08-02',
          searchVolume: 878,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-08-01',
          searchVolume: 878,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-31',
          searchVolume: 878,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-30',
          searchVolume: 878,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 26
        },
        {
          date: '2024-07-29',
          searchVolume: 878,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-28',
          searchVolume: 878,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-27',
          searchVolume: 878,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-26',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-25',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-24',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-23',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-22',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-07-21',
          searchVolume: 1837,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-20',
          searchVolume: 1837,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-07-19',
          searchVolume: 832,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-18',
          searchVolume: 832,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-17',
          searchVolume: 832,
          organicRank: 1,

          keywordSales: 26
        },
        {
          date: '2024-07-16',
          searchVolume: 832,
          organicRank: 1,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik replacement heads',
      keyword: 'waterpik replacement heads',
      searchVolume: 24831,
      organicStartRank: 142,
      organicHighestRank: 75,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 23984,
          organicRank: 172,

          keywordSales: 1224
        },
        {
          date: '2024-09-09',
          searchVolume: 23984,
          organicRank: 177,

          keywordSales: 1224
        },
        {
          date: '2024-09-08',
          searchVolume: 23984,
          organicRank: 306,

          keywordSales: 1224
        },
        {
          date: '2024-09-07',
          searchVolume: 23984,
          organicRank: 306,

          keywordSales: 1224
        },
        {
          date: '2024-09-06',
          searchVolume: 23984,
          organicRank: 190,

          keywordSales: 1224
        },
        {
          date: '2024-09-05',
          searchVolume: 23984,
          organicRank: 188,

          keywordSales: 1224
        },
        {
          date: '2024-09-04',
          searchVolume: 23984,
          organicRank: 306,

          keywordSales: 1224
        },
        {
          date: '2024-09-02',
          searchVolume: 23984,
          organicRank: 189,

          keywordSales: 1224
        },
        {
          date: '2024-08-29',
          searchVolume: 22058,
          organicRank: 160,

          keywordSales: 1224
        },
        {
          date: '2024-08-28',
          searchVolume: 22058,
          organicRank: 157,

          keywordSales: 1224
        },
        {
          date: '2024-08-27',
          searchVolume: 22058,
          organicRank: 160,

          keywordSales: 1224
        },
        {
          date: '2024-08-26',
          searchVolume: 22058,
          organicRank: 150,

          keywordSales: 1224
        },
        {
          date: '2024-08-25',
          searchVolume: 22058,
          organicRank: 151,

          keywordSales: 1224
        },
        {
          date: '2024-08-24',
          searchVolume: 22058,
          organicRank: 80,

          keywordSales: 1224
        },
        {
          date: '2024-08-22',
          searchVolume: 22071,
          organicRank: 156,

          keywordSales: 1224
        },
        {
          date: '2024-08-21',
          searchVolume: 22071,
          organicRank: 306,

          keywordSales: 1224
        },
        {
          date: '2024-08-20',
          searchVolume: 22071,
          organicRank: 102,

          keywordSales: 1224
        },
        {
          date: '2024-08-19',
          searchVolume: 22071,
          organicRank: 91,

          keywordSales: 1224
        },
        {
          date: '2024-08-18',
          searchVolume: 22071,
          organicRank: 111,

          keywordSales: 1224
        },
        {
          date: '2024-08-16',
          searchVolume: 21033,
          organicRank: 109,

          keywordSales: 1224
        },
        {
          date: '2024-08-15',
          searchVolume: 21033,
          organicRank: 105,

          keywordSales: 1224
        },
        {
          date: '2024-08-14',
          searchVolume: 21033,
          organicRank: 100,

          keywordSales: 1224
        },
        {
          date: '2024-08-13',
          searchVolume: 21033,
          organicRank: 84,

          keywordSales: 1224
        },
        {
          date: '2024-08-12',
          searchVolume: 21033,
          organicRank: 78,

          keywordSales: 1224
        },
        {
          date: '2024-08-11',
          searchVolume: 21033,
          organicRank: 85,

          keywordSales: 1224
        },
        {
          date: '2024-08-10',
          searchVolume: 21033,
          organicRank: 86,

          keywordSales: 1224
        },
        {
          date: '2024-08-09',
          searchVolume: 20560,
          organicRank: 84,

          keywordSales: 1224
        },
        {
          date: '2024-08-08',
          searchVolume: 20560,
          organicRank: 75,

          keywordSales: 1224
        },
        {
          date: '2024-08-07',
          searchVolume: 20560,
          organicRank: 80,

          keywordSales: 1224
        },
        {
          date: '2024-08-06',
          searchVolume: 20560,
          organicRank: 91,

          keywordSales: 1224
        },
        {
          date: '2024-08-05',
          searchVolume: 20560,
          organicRank: 80,

          keywordSales: 1224
        },
        {
          date: '2024-08-04',
          searchVolume: 20560,
          organicRank: 84,

          keywordSales: 1224
        },
        {
          date: '2024-08-03',
          searchVolume: 20560,
          organicRank: 87,

          keywordSales: 1224
        },
        {
          date: '2024-08-02',
          searchVolume: 20363,
          organicRank: 85,

          keywordSales: 1224
        },
        {
          date: '2024-08-01',
          searchVolume: 20363,
          organicRank: 83,

          keywordSales: 1224
        },
        {
          date: '2024-07-31',
          searchVolume: 20363,
          organicRank: 81,

          keywordSales: 1224
        },
        {
          date: '2024-07-30',
          searchVolume: 20363,
          organicRank: 92,

          keywordSales: 1224
        },
        {
          date: '2024-07-29',
          searchVolume: 20363,
          organicRank: 87,

          keywordSales: 1224
        },
        {
          date: '2024-07-28',
          searchVolume: 20363,
          organicRank: 86,

          keywordSales: 1224
        },
        {
          date: '2024-07-27',
          searchVolume: 20363,
          organicRank: 103,

          keywordSales: 1224
        },
        {
          date: '2024-07-26',
          searchVolume: 24831,
          organicRank: 142,

          keywordSales: 1224
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik sonic fusion',
      keyword: 'waterpik sonic fusion',
      searchVolume: 14988,
      organicStartRank: 38,
      organicHighestRank: 4,
      sponsoredStartRank: 47,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2182,
          organicRank: 20,

          keywordSales: 41
        },
        {
          date: '2024-09-09',
          searchVolume: 2182,
          organicRank: 18,

          keywordSales: 41
        },
        {
          date: '2024-09-08',
          searchVolume: 2182,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 41
        },
        {
          date: '2024-09-07',
          searchVolume: 2182,
          organicRank: 17,

          keywordSales: 41
        },
        {
          date: '2024-09-06',
          searchVolume: 2182,
          organicRank: 19,

          keywordSales: 41
        },
        {
          date: '2024-09-05',
          searchVolume: 2182,
          organicRank: 20,

          keywordSales: 41
        },
        {
          date: '2024-09-04',
          searchVolume: 2182,
          organicRank: 12,

          keywordSales: 41
        },
        {
          date: '2024-09-01',
          searchVolume: 2182,
          organicRank: 22,

          keywordSales: 41
        },
        {
          date: '2024-08-28',
          searchVolume: 2251,
          organicRank: 19,
          sponsoredRank: 21,
          keywordSales: 41
        },
        {
          date: '2024-08-27',
          searchVolume: 2251,
          organicRank: 19,

          keywordSales: 41
        },
        {
          date: '2024-08-26',
          searchVolume: 2251,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 41
        },
        {
          date: '2024-08-25',
          searchVolume: 2251,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 41
        },
        {
          date: '2024-08-24',
          searchVolume: 2251,
          organicRank: 15,

          keywordSales: 41
        },
        {
          date: '2024-08-23',
          searchVolume: 2550,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-08-22',
          searchVolume: 2550,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-21',
          searchVolume: 2550,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-08-20',
          searchVolume: 2550,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-08-19',
          searchVolume: 2550,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-08-17',
          searchVolume: 2550,
          organicRank: 9,
          sponsoredRank: 47,
          keywordSales: 41
        },
        {
          date: '2024-08-16',
          searchVolume: 1841,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-08-15',
          searchVolume: 1841,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-14',
          searchVolume: 1841,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-13',
          searchVolume: 1841,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-08-12',
          searchVolume: 1841,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-11',
          searchVolume: 1841,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-08-10',
          searchVolume: 1841,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-08-09',
          searchVolume: 2912,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-08',
          searchVolume: 2912,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-08-07',
          searchVolume: 2912,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-08-06',
          searchVolume: 2912,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-08-05',
          searchVolume: 2912,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-08-04',
          searchVolume: 2912,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-08-03',
          searchVolume: 2912,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-08-02',
          searchVolume: 6230,
          organicRank: 4,

          keywordSales: 41
        },
        {
          date: '2024-08-01',
          searchVolume: 6230,
          organicRank: 4,

          keywordSales: 41
        },
        {
          date: '2024-07-31',
          searchVolume: 6230,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-07-30',
          searchVolume: 6230,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-07-29',
          searchVolume: 6230,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-28',
          searchVolume: 6230,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-07-27',
          searchVolume: 6230,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-07-26',
          searchVolume: 14988,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-07-25',
          searchVolume: 14988,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-07-24',
          searchVolume: 14988,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-23',
          searchVolume: 14988,
          organicRank: 10,

          keywordSales: 41
        },
        {
          date: '2024-07-22',
          searchVolume: 14988,
          organicRank: 12,

          keywordSales: 41
        },
        {
          date: '2024-07-21',
          searchVolume: 14988,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-07-20',
          searchVolume: 14988,
          organicRank: 18,

          keywordSales: 41
        },
        {
          date: '2024-07-19',
          searchVolume: 18491,
          organicRank: 30,

          keywordSales: 41
        },
        {
          date: '2024-07-18',
          searchVolume: 18491,
          organicRank: 38,

          keywordSales: 41
        },
        {
          date: '2024-07-17',
          searchVolume: 18491,
          organicRank: 29,

          keywordSales: 41
        },
        {
          date: '2024-07-16',
          searchVolume: 18491,
          organicRank: 33,

          keywordSales: 41
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik sonic fusion 20',
      keyword: 'waterpik sonic fusion 20',

      organicStartRank: 9,
      organicHighestRank: 9,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-30',

          organicRank: 9
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik tips',
      keyword: 'waterpik tips',
      searchVolume: 1835,
      organicStartRank: 37,
      organicHighestRank: 37,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1835,
          organicRank: 37,
          sponsoredRank: 1,
          keywordSales: 125
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik toothbrush',
      keyword: 'waterpik toothbrush',
      searchVolume: 10368,
      organicStartRank: 60,
      organicHighestRank: 6,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-07-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 4407,
          organicRank: 14,

          keywordSales: 109
        },
        {
          date: '2024-09-09',
          searchVolume: 4407,
          organicRank: 21,

          keywordSales: 109
        },
        {
          date: '2024-09-08',
          searchVolume: 4407,
          organicRank: 19,

          keywordSales: 109
        },
        {
          date: '2024-09-07',
          searchVolume: 4407,
          organicRank: 19,

          keywordSales: 109
        },
        {
          date: '2024-09-06',
          searchVolume: 4407,
          organicRank: 18,

          keywordSales: 109
        },
        {
          date: '2024-09-05',
          searchVolume: 4407,
          organicRank: 22,

          keywordSales: 109
        },
        {
          date: '2024-09-04',
          searchVolume: 4407,
          organicRank: 306,

          keywordSales: 109
        },
        {
          date: '2024-09-01',
          searchVolume: 4407,
          organicRank: 306,

          keywordSales: 109
        },
        {
          date: '2024-08-28',
          searchVolume: 4322,
          organicRank: 20,

          keywordSales: 109
        },
        {
          date: '2024-08-27',
          searchVolume: 4322,
          organicRank: 13,

          keywordSales: 109
        },
        {
          date: '2024-08-26',
          searchVolume: 4322,
          organicRank: 14,

          keywordSales: 109
        },
        {
          date: '2024-08-25',
          searchVolume: 4322,
          organicRank: 11,

          keywordSales: 109
        },
        {
          date: '2024-08-24',
          searchVolume: 4322,
          organicRank: 8,

          keywordSales: 109
        },
        {
          date: '2024-08-23',
          searchVolume: 4363,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-22',
          searchVolume: 4363,
          organicRank: 12,

          keywordSales: 109
        },
        {
          date: '2024-08-21',
          searchVolume: 4363,
          organicRank: 10,

          keywordSales: 109
        },
        {
          date: '2024-08-20',
          searchVolume: 4363,
          organicRank: 7,

          keywordSales: 109
        },
        {
          date: '2024-08-19',
          searchVolume: 4363,
          organicRank: 11,

          keywordSales: 109
        },
        {
          date: '2024-08-17',
          searchVolume: 4363,
          organicRank: 11,

          keywordSales: 109
        },
        {
          date: '2024-08-16',
          searchVolume: 4123,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-15',
          searchVolume: 4123,
          organicRank: 8,

          keywordSales: 109
        },
        {
          date: '2024-08-14',
          searchVolume: 4123,
          organicRank: 8,

          keywordSales: 109
        },
        {
          date: '2024-08-13',
          searchVolume: 4123,
          organicRank: 6,

          keywordSales: 109
        },
        {
          date: '2024-08-12',
          searchVolume: 4123,
          organicRank: 6,

          keywordSales: 109
        },
        {
          date: '2024-08-11',
          searchVolume: 4123,
          organicRank: 10,

          keywordSales: 109
        },
        {
          date: '2024-08-10',
          searchVolume: 4123,
          organicRank: 11,

          keywordSales: 109
        },
        {
          date: '2024-08-09',
          searchVolume: 3947,
          organicRank: 7,

          keywordSales: 109
        },
        {
          date: '2024-08-08',
          searchVolume: 3947,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-07',
          searchVolume: 3947,
          organicRank: 8,

          keywordSales: 109
        },
        {
          date: '2024-08-06',
          searchVolume: 3947,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-05',
          searchVolume: 3947,
          organicRank: 10,

          keywordSales: 109
        },
        {
          date: '2024-08-04',
          searchVolume: 3947,
          organicRank: 14,

          keywordSales: 109
        },
        {
          date: '2024-08-03',
          searchVolume: 3947,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-08-02',
          searchVolume: 4199,
          organicRank: 8,

          keywordSales: 109
        },
        {
          date: '2024-08-01',
          searchVolume: 4199,
          organicRank: 10,

          keywordSales: 109
        },
        {
          date: '2024-07-31',
          searchVolume: 4199,
          organicRank: 7,

          keywordSales: 109
        },
        {
          date: '2024-07-30',
          searchVolume: 4199,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 109
        },
        {
          date: '2024-07-29',
          searchVolume: 4199,
          organicRank: 12,

          keywordSales: 109
        },
        {
          date: '2024-07-28',
          searchVolume: 4199,
          organicRank: 9,

          keywordSales: 109
        },
        {
          date: '2024-07-27',
          searchVolume: 4199,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 109
        },
        {
          date: '2024-07-26',
          searchVolume: 10368,
          organicRank: 14,

          keywordSales: 109
        },
        {
          date: '2024-07-25',
          searchVolume: 10368,
          organicRank: 18,

          keywordSales: 109
        },
        {
          date: '2024-07-24',
          searchVolume: 10368,
          organicRank: 23,

          keywordSales: 109
        },
        {
          date: '2024-07-23',
          searchVolume: 10368,
          organicRank: 28,

          keywordSales: 109
        },
        {
          date: '2024-07-22',
          searchVolume: 10368,
          organicRank: 27,

          keywordSales: 109
        },
        {
          date: '2024-07-21',
          searchVolume: 10368,
          organicRank: 35,

          keywordSales: 109
        },
        {
          date: '2024-07-20',
          searchVolume: 10368,
          organicRank: 34,

          keywordSales: 109
        },
        {
          date: '2024-07-19',
          searchVolume: 5588,
          organicRank: 51,

          keywordSales: 109
        },
        {
          date: '2024-07-18',
          searchVolume: 5588,
          organicRank: 60,

          keywordSales: 109
        },
        {
          date: '2024-07-17',
          searchVolume: 5588,
          organicRank: 57,

          keywordSales: 109
        },
        {
          date: '2024-07-16',
          searchVolume: 5588,
          organicRank: 49,

          keywordSales: 109
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik toothbrush combo',
      keyword: 'waterpik toothbrush combo',
      searchVolume: 4344,
      organicStartRank: 53,
      organicHighestRank: 10,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 23,
      organicPageOneWinDate: '2024-07-25',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 25,

          keywordSales: 38
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 46,

          keywordSales: 38
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 24,
          sponsoredRank: 23,
          keywordSales: 38
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 27,

          keywordSales: 38
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 40,

          keywordSales: 38
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 22,

          keywordSales: 38
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 24,

          keywordSales: 38
        },
        {
          date: '2024-09-01',
          searchVolume: 1388,
          organicRank: 40,

          keywordSales: 38
        },
        {
          date: '2024-08-28',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 38
        },
        {
          date: '2024-08-27',
          searchVolume: 1848,
          organicRank: 18,

          keywordSales: 38
        },
        {
          date: '2024-08-26',
          searchVolume: 1848,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-08-25',
          searchVolume: 1848,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-08-24',
          searchVolume: 1848,
          organicRank: 20,

          keywordSales: 38
        },
        {
          date: '2024-08-23',
          searchVolume: 1664,
          organicRank: 16,

          keywordSales: 38
        },
        {
          date: '2024-08-22',
          searchVolume: 1664,
          organicRank: 18,

          keywordSales: 38
        },
        {
          date: '2024-08-21',
          searchVolume: 1664,
          organicRank: 17,

          keywordSales: 38
        },
        {
          date: '2024-08-20',
          searchVolume: 1664,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-08-19',
          searchVolume: 1664,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-17',
          searchVolume: 1664,
          organicRank: 16,

          keywordSales: 38
        },
        {
          date: '2024-08-16',
          searchVolume: 1582,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-15',
          searchVolume: 1582,
          organicRank: 16,

          keywordSales: 38
        },
        {
          date: '2024-08-14',
          searchVolume: 1582,
          organicRank: 12,

          keywordSales: 38
        },
        {
          date: '2024-08-13',
          searchVolume: 1582,
          organicRank: 15,

          keywordSales: 38
        },
        {
          date: '2024-08-12',
          searchVolume: 1582,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-11',
          searchVolume: 1582,
          organicRank: 15,

          keywordSales: 38
        },
        {
          date: '2024-08-10',
          searchVolume: 1582,
          organicRank: 15,

          keywordSales: 38
        },
        {
          date: '2024-08-09',
          searchVolume: 1795,
          organicRank: 11,

          keywordSales: 38
        },
        {
          date: '2024-08-08',
          searchVolume: 1795,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-07',
          searchVolume: 1795,
          organicRank: 10,

          keywordSales: 38
        },
        {
          date: '2024-08-06',
          searchVolume: 1795,
          organicRank: 13,

          keywordSales: 38
        },
        {
          date: '2024-08-05',
          searchVolume: 1795,
          organicRank: 18,

          keywordSales: 38
        },
        {
          date: '2024-08-04',
          searchVolume: 1795,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-08-03',
          searchVolume: 1795,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-08-02',
          searchVolume: 2209,
          organicRank: 27,

          keywordSales: 38
        },
        {
          date: '2024-08-01',
          searchVolume: 2209,
          organicRank: 18,

          keywordSales: 38
        },
        {
          date: '2024-07-31',
          searchVolume: 2209,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-07-30',
          searchVolume: 2209,
          organicRank: 19,

          keywordSales: 38
        },
        {
          date: '2024-07-29',
          searchVolume: 2209,
          organicRank: 21,

          keywordSales: 38
        },
        {
          date: '2024-07-28',
          searchVolume: 2209,
          organicRank: 20,

          keywordSales: 38
        },
        {
          date: '2024-07-27',
          searchVolume: 2209,
          organicRank: 17,

          keywordSales: 38
        },
        {
          date: '2024-07-26',
          searchVolume: 4344,
          organicRank: 21,

          keywordSales: 38
        },
        {
          date: '2024-07-25',
          searchVolume: 4344,
          organicRank: 20,

          keywordSales: 38
        },
        {
          date: '2024-07-24',
          searchVolume: 4344,
          organicRank: 25,

          keywordSales: 38
        },
        {
          date: '2024-07-23',
          searchVolume: 4344,
          organicRank: 22,

          keywordSales: 38
        },
        {
          date: '2024-07-22',
          searchVolume: 4344,
          organicRank: 27,

          keywordSales: 38
        },
        {
          date: '2024-07-21',
          searchVolume: 4344,
          organicRank: 29,

          keywordSales: 38
        },
        {
          date: '2024-07-20',
          searchVolume: 4344,
          organicRank: 34,

          keywordSales: 38
        },
        {
          date: '2024-07-19',
          searchVolume: 2113,
          organicRank: 36,

          keywordSales: 38
        },
        {
          date: '2024-07-18',
          searchVolume: 2113,
          organicRank: 53,

          keywordSales: 38
        },
        {
          date: '2024-07-17',
          searchVolume: 2113,
          organicRank: 52,

          keywordSales: 38
        },
        {
          date: '2024-07-16',
          searchVolume: 2113,
          organicRank: 51,

          keywordSales: 38
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik travel size',
      keyword: 'waterpik travel size',
      searchVolume: 2436,
      organicStartRank: 4,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-18',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-09-09',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-09-08',
          searchVolume: 2353,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 64
        },
        {
          date: '2024-09-07',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-09-06',
          searchVolume: 2353,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 64
        },
        {
          date: '2024-09-05',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-09-04',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-09-01',
          searchVolume: 2353,
          organicRank: 2,

          keywordSales: 64
        },
        {
          date: '2024-08-28',
          searchVolume: 1926,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 64
        },
        {
          date: '2024-08-27',
          searchVolume: 1926,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 64
        },
        {
          date: '2024-08-26',
          searchVolume: 1926,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 64
        },
        {
          date: '2024-08-25',
          searchVolume: 1926,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 64
        },
        {
          date: '2024-08-24',
          searchVolume: 1926,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-23',
          searchVolume: 1445,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-22',
          searchVolume: 1445,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-21',
          searchVolume: 1445,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-20',
          searchVolume: 1445,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-19',
          searchVolume: 1445,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 64
        },
        {
          date: '2024-08-17',
          searchVolume: 1445,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-16',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-15',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-14',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-13',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-12',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-11',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-10',
          searchVolume: 1535,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-09',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-08',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-07',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-06',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-05',
          searchVolume: 1333,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 64
        },
        {
          date: '2024-08-04',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-03',
          searchVolume: 1333,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-02',
          searchVolume: 1706,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-08-01',
          searchVolume: 1706,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 64
        },
        {
          date: '2024-07-31',
          searchVolume: 1706,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-30',
          searchVolume: 1706,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 64
        },
        {
          date: '2024-07-29',
          searchVolume: 1706,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-28',
          searchVolume: 1706,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-27',
          searchVolume: 1706,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-26',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-25',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-24',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-23',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-22',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-21',
          searchVolume: 2436,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-20',
          searchVolume: 2436,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 64
        },
        {
          date: '2024-07-19',
          searchVolume: 2113,
          organicRank: 1,

          keywordSales: 64
        },
        {
          date: '2024-07-18',
          searchVolume: 2113,
          organicRank: 4,
          sponsoredRank: 2,
          keywordSales: 64
        },
        {
          date: '2024-07-17',
          searchVolume: 2113,
          organicRank: 4,

          keywordSales: 64
        },
        {
          date: '2024-07-16',
          searchVolume: 2113,
          organicRank: 4,

          keywordSales: 64
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik ultra water flosser',
      keyword: 'waterpik ultra water flosser',
      searchVolume: 1830,
      organicStartRank: 21,
      organicHighestRank: 3,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 9,

          keywordSales: 18
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 10,

          keywordSales: 18
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 10,

          keywordSales: 18
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 10,
          sponsoredRank: 34,
          keywordSales: 18
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 10,

          keywordSales: 18
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 13,

          keywordSales: 18
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 9,

          keywordSales: 18
        },
        {
          date: '2024-09-02',
          searchVolume: 1388,
          organicRank: 9,

          keywordSales: 18
        },
        {
          date: '2024-08-29',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 18
        },
        {
          date: '2024-08-28',
          searchVolume: 1609,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-27',
          searchVolume: 1609,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-26',
          searchVolume: 1609,
          organicRank: 15,

          keywordSales: 18
        },
        {
          date: '2024-08-25',
          searchVolume: 1609,
          organicRank: 8,

          keywordSales: 18
        },
        {
          date: '2024-08-24',
          searchVolume: 1609,
          organicRank: 10,

          keywordSales: 18
        },
        {
          date: '2024-08-22',
          searchVolume: 1607,
          organicRank: 8,

          keywordSales: 18
        },
        {
          date: '2024-08-21',
          searchVolume: 1607,
          organicRank: 8,

          keywordSales: 18
        },
        {
          date: '2024-08-20',
          searchVolume: 1607,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-19',
          searchVolume: 1607,
          organicRank: 11,

          keywordSales: 18
        },
        {
          date: '2024-08-18',
          searchVolume: 1607,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-16',
          searchVolume: 1329,
          organicRank: 5,

          keywordSales: 18
        },
        {
          date: '2024-08-15',
          searchVolume: 1329,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-14',
          searchVolume: 1329,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-13',
          searchVolume: 1329,
          organicRank: 5,

          keywordSales: 18
        },
        {
          date: '2024-08-12',
          searchVolume: 1329,
          organicRank: 5,

          keywordSales: 18
        },
        {
          date: '2024-08-11',
          searchVolume: 1329,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-08-10',
          searchVolume: 1329,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-09',
          searchVolume: 1314,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-08',
          searchVolume: 1314,
          organicRank: 3,

          keywordSales: 18
        },
        {
          date: '2024-08-07',
          searchVolume: 1314,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-06',
          searchVolume: 1314,
          organicRank: 5,

          keywordSales: 18
        },
        {
          date: '2024-08-05',
          searchVolume: 1314,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-08-04',
          searchVolume: 1314,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-03',
          searchVolume: 1314,
          organicRank: 6,

          keywordSales: 18
        },
        {
          date: '2024-08-02',
          searchVolume: 880,
          organicRank: 5,

          keywordSales: 18
        },
        {
          date: '2024-08-01',
          searchVolume: 880,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-07-31',
          searchVolume: 880,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-07-30',
          searchVolume: 880,
          organicRank: 6,
          sponsoredRank: 17,
          keywordSales: 18
        },
        {
          date: '2024-07-29',
          searchVolume: 880,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-07-28',
          searchVolume: 880,
          organicRank: 7,

          keywordSales: 18
        },
        {
          date: '2024-07-27',
          searchVolume: 880,
          organicRank: 8,

          keywordSales: 18
        },
        {
          date: '2024-07-26',
          searchVolume: 1830,
          organicRank: 10,

          keywordSales: 18
        },
        {
          date: '2024-07-25',
          searchVolume: 1830,
          organicRank: 11,

          keywordSales: 18
        },
        {
          date: '2024-07-24',
          searchVolume: 1830,
          organicRank: 14,

          keywordSales: 18
        },
        {
          date: '2024-07-23',
          searchVolume: 1830,
          organicRank: 14,

          keywordSales: 18
        },
        {
          date: '2024-07-22',
          searchVolume: 1830,
          organicRank: 16,

          keywordSales: 18
        },
        {
          date: '2024-07-21',
          searchVolume: 1830,
          organicRank: 15,

          keywordSales: 18
        },
        {
          date: '2024-07-20',
          searchVolume: 1830,
          organicRank: 17,
          sponsoredRank: 8,
          keywordSales: 18
        },
        {
          date: '2024-07-19',
          searchVolume: 1269,
          organicRank: 20,

          keywordSales: 18
        },
        {
          date: '2024-07-18',
          searchVolume: 1269,
          organicRank: 21,

          keywordSales: 18
        },
        {
          date: '2024-07-17',
          searchVolume: 1269,
          organicRank: 21,

          keywordSales: 18
        },
        {
          date: '2024-07-16',
          searchVolume: 1269,
          organicRank: 20,

          keywordSales: 18
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik water flosser',
      keyword: 'waterpik water flosser',
      searchVolume: 115059,
      organicStartRank: 8,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 61929,
          organicRank: 4,

          keywordSales: 1506
        },
        {
          date: '2024-09-09',
          searchVolume: 61929,
          organicRank: 5,

          keywordSales: 1506
        },
        {
          date: '2024-09-08',
          searchVolume: 61929,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 1506
        },
        {
          date: '2024-09-07',
          searchVolume: 61929,
          organicRank: 4,

          keywordSales: 1506
        },
        {
          date: '2024-09-06',
          searchVolume: 61929,
          organicRank: 5,

          keywordSales: 1506
        },
        {
          date: '2024-09-05',
          searchVolume: 61929,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 1506
        },
        {
          date: '2024-09-04',
          searchVolume: 61929,
          organicRank: 5,

          keywordSales: 1506
        },
        {
          date: '2024-09-01',
          searchVolume: 61929,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 1506
        },
        {
          date: '2024-08-30',
          searchVolume: 63892,
          organicRank: 4,
          sponsoredRank: 4,
          keywordSales: 1506
        },
        {
          date: '2024-08-28',
          searchVolume: 63892,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-27',
          searchVolume: 63892,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-26',
          searchVolume: 63892,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-25',
          searchVolume: 63892,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-24',
          searchVolume: 63892,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-22',
          searchVolume: 63925,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-21',
          searchVolume: 63925,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 1506
        },
        {
          date: '2024-08-20',
          searchVolume: 63925,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-19',
          searchVolume: 63925,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-18',
          searchVolume: 63925,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 1506
        },
        {
          date: '2024-08-16',
          searchVolume: 55228,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-15',
          searchVolume: 55228,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-08-14',
          searchVolume: 55228,
          organicRank: 1,
          sponsoredRank: 12,
          keywordSales: 1506
        },
        {
          date: '2024-08-13',
          searchVolume: 55228,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-11',
          searchVolume: 55228,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-10',
          searchVolume: 55228,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-09',
          searchVolume: 52740,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-08',
          searchVolume: 52740,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-07',
          searchVolume: 52740,
          organicRank: 2,
          sponsoredRank: 12,
          keywordSales: 1506
        },
        {
          date: '2024-08-06',
          searchVolume: 52740,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 1506
        },
        {
          date: '2024-08-05',
          searchVolume: 52740,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-04',
          searchVolume: 52740,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-03',
          searchVolume: 52740,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-02',
          searchVolume: 58969,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-08-01',
          searchVolume: 58969,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-07-31',
          searchVolume: 58969,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-07-30',
          searchVolume: 58969,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 1506
        },
        {
          date: '2024-07-29',
          searchVolume: 58969,
          organicRank: 3,

          keywordSales: 1506
        },
        {
          date: '2024-07-28',
          searchVolume: 58969,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-07-27',
          searchVolume: 58969,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-07-26',
          searchVolume: 115059,
          organicRank: 3,

          keywordSales: 1506
        },
        {
          date: '2024-07-25',
          searchVolume: 115059,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-07-24',
          searchVolume: 115059,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 1506
        },
        {
          date: '2024-07-23',
          searchVolume: 115059,
          organicRank: 2,

          keywordSales: 1506
        },
        {
          date: '2024-07-22',
          searchVolume: 115059,
          organicRank: 5,

          keywordSales: 1506
        },
        {
          date: '2024-07-21',
          searchVolume: 115059,
          organicRank: 5,

          keywordSales: 1506
        },
        {
          date: '2024-07-20',
          searchVolume: 115059,
          organicRank: 7,

          keywordSales: 1506
        },
        {
          date: '2024-07-19',
          searchVolume: 69884,
          organicRank: 4,

          keywordSales: 1506
        },
        {
          date: '2024-07-18',
          searchVolume: 69884,
          organicRank: 8,

          keywordSales: 1506
        },
        {
          date: '2024-07-17',
          searchVolume: 69884,
          organicRank: 8,

          keywordSales: 1506
        },
        {
          date: '2024-07-16',
          searchVolume: 69884,
          organicRank: 8,

          keywordSales: 1506
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik water flosser cordless',
      keyword: 'waterpik water flosser cordless',
      searchVolume: 2280,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1388,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-09-09',
          searchVolume: 1388,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-09-08',
          searchVolume: 1388,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-09-07',
          searchVolume: 1388,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 46
        },
        {
          date: '2024-09-06',
          searchVolume: 1388,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-09-05',
          searchVolume: 1388,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-09-04',
          searchVolume: 1388,
          organicRank: 3,

          keywordSales: 46
        },
        {
          date: '2024-09-02',
          searchVolume: 1388,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-29',
          searchVolume: 1440,
          organicRank: 3,

          keywordSales: 46
        },
        {
          date: '2024-08-28',
          searchVolume: 1440,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-27',
          searchVolume: 1440,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-26',
          searchVolume: 1440,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-25',
          searchVolume: 1440,
          organicRank: 1,

          keywordSales: 46
        },
        {
          date: '2024-08-24',
          searchVolume: 1440,
          organicRank: 1,

          keywordSales: 46
        },
        {
          date: '2024-08-22',
          searchVolume: 1430,
          organicRank: 1,

          keywordSales: 46
        },
        {
          date: '2024-08-21',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 46
        },
        {
          date: '2024-08-20',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 46
        },
        {
          date: '2024-08-19',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 46
        },
        {
          date: '2024-08-18',
          searchVolume: 1430,
          organicRank: 1,
          sponsoredRank: 20,
          keywordSales: 46
        },
        {
          date: '2024-08-16',
          searchVolume: 1163,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-15',
          searchVolume: 1163,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-14',
          searchVolume: 1163,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-13',
          searchVolume: 1163,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-12',
          searchVolume: 1163,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-11',
          searchVolume: 1163,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 46
        },
        {
          date: '2024-08-10',
          searchVolume: 1163,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 46
        },
        {
          date: '2024-08-09',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-08',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-07',
          searchVolume: 1253,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-06',
          searchVolume: 1253,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-08-05',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-08-04',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-03',
          searchVolume: 1253,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 46
        },
        {
          date: '2024-08-02',
          searchVolume: 1322,
          organicRank: 1,

          keywordSales: 46
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-26',
          searchVolume: 2280,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-25',
          searchVolume: 2280,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-24',
          searchVolume: 2280,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-23',
          searchVolume: 2280,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-22',
          searchVolume: 2280,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 46
        },
        {
          date: '2024-07-21',
          searchVolume: 2280,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-20',
          searchVolume: 2280,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-19',
          searchVolume: 1343,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-18',
          searchVolume: 1343,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-17',
          searchVolume: 1343,
          organicRank: 2,

          keywordSales: 46
        },
        {
          date: '2024-07-16',
          searchVolume: 1343,
          organicRank: 2,

          keywordSales: 46
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik water flosser replacement tips',
      keyword: 'waterpik water flosser replacement tips',
      searchVolume: 1827,
      organicStartRank: 31,
      organicHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-09-10',
          searchVolume: 1795,
          organicRank: 69,

          keywordSales: 112
        },
        {
          date: '2024-09-09',
          searchVolume: 1795,
          organicRank: 68,

          keywordSales: 112
        },
        {
          date: '2024-09-08',
          searchVolume: 1795,
          organicRank: 70,

          keywordSales: 112
        },
        {
          date: '2024-09-07',
          searchVolume: 1795,
          organicRank: 70,

          keywordSales: 112
        },
        {
          date: '2024-09-06',
          searchVolume: 1795,
          organicRank: 66,

          keywordSales: 112
        },
        {
          date: '2024-09-05',
          searchVolume: 1795,
          organicRank: 69,

          keywordSales: 112
        },
        {
          date: '2024-09-04',
          searchVolume: 1795,
          organicRank: 57,

          keywordSales: 112
        },
        {
          date: '2024-09-02',
          searchVolume: 1795,
          organicRank: 57,

          keywordSales: 112
        },
        {
          date: '2024-08-29',
          searchVolume: 1921,
          organicRank: 52,

          keywordSales: 112
        },
        {
          date: '2024-08-28',
          searchVolume: 1921,
          organicRank: 54,

          keywordSales: 112
        },
        {
          date: '2024-08-27',
          searchVolume: 1921,
          organicRank: 45,

          keywordSales: 112
        },
        {
          date: '2024-08-26',
          searchVolume: 1921,
          organicRank: 41,

          keywordSales: 112
        },
        {
          date: '2024-08-25',
          searchVolume: 1921,
          organicRank: 53,

          keywordSales: 112
        },
        {
          date: '2024-08-24',
          searchVolume: 1921,
          organicRank: 53,

          keywordSales: 112
        },
        {
          date: '2024-08-22',
          searchVolume: 1915,
          organicRank: 58,

          keywordSales: 112
        },
        {
          date: '2024-08-21',
          searchVolume: 1915,
          organicRank: 58,

          keywordSales: 112
        },
        {
          date: '2024-08-20',
          searchVolume: 1915,
          organicRank: 55,

          keywordSales: 112
        },
        {
          date: '2024-08-19',
          searchVolume: 1915,
          organicRank: 57,

          keywordSales: 112
        },
        {
          date: '2024-08-18',
          searchVolume: 1915,
          organicRank: 59,

          keywordSales: 112
        },
        {
          date: '2024-08-16',
          searchVolume: 1761,
          organicRank: 46,

          keywordSales: 112
        },
        {
          date: '2024-08-15',
          searchVolume: 1761,
          organicRank: 48,

          keywordSales: 112
        },
        {
          date: '2024-08-14',
          searchVolume: 1761,
          organicRank: 49,

          keywordSales: 112
        },
        {
          date: '2024-08-13',
          searchVolume: 1761,
          organicRank: 50,

          keywordSales: 112
        },
        {
          date: '2024-08-12',
          searchVolume: 1761,
          organicRank: 55,

          keywordSales: 112
        },
        {
          date: '2024-08-11',
          searchVolume: 1761,
          organicRank: 58,

          keywordSales: 112
        },
        {
          date: '2024-08-10',
          searchVolume: 1761,
          organicRank: 48,

          keywordSales: 112
        },
        {
          date: '2024-08-09',
          searchVolume: 1782,
          organicRank: 60,

          keywordSales: 112
        },
        {
          date: '2024-08-08',
          searchVolume: 1782,
          organicRank: 39,

          keywordSales: 112
        },
        {
          date: '2024-08-07',
          searchVolume: 1782,
          organicRank: 59,

          keywordSales: 112
        },
        {
          date: '2024-08-06',
          searchVolume: 1782,
          organicRank: 57,

          keywordSales: 112
        },
        {
          date: '2024-08-05',
          searchVolume: 1782,
          organicRank: 58,

          keywordSales: 112
        },
        {
          date: '2024-08-04',
          searchVolume: 1782,
          organicRank: 53,

          keywordSales: 112
        },
        {
          date: '2024-08-03',
          searchVolume: 1782,
          organicRank: 53,

          keywordSales: 112
        },
        {
          date: '2024-08-02',
          searchVolume: 1530,
          organicRank: 48,

          keywordSales: 112
        },
        {
          date: '2024-08-01',
          searchVolume: 1530,
          organicRank: 47,

          keywordSales: 112
        },
        {
          date: '2024-07-31',
          searchVolume: 1530,
          organicRank: 49,

          keywordSales: 112
        },
        {
          date: '2024-07-30',
          searchVolume: 1530,
          organicRank: 51,

          keywordSales: 112
        },
        {
          date: '2024-07-29',
          searchVolume: 1530,
          organicRank: 45,

          keywordSales: 112
        },
        {
          date: '2024-07-28',
          searchVolume: 1530,
          organicRank: 43,

          keywordSales: 112
        },
        {
          date: '2024-07-27',
          searchVolume: 1530,
          organicRank: 31,

          keywordSales: 112
        }
      ]
    },
    {
      campaignId: 'sample-campaign-water-flosser-prime-day',
      campaignId_keyword: 'sample-campaign-water-flosser-prime-day_waterpik whitening tablets',
      keyword: 'waterpik whitening tablets',
      searchVolume: 1288,
      organicStartRank: 30,
      organicHighestRank: 30,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-30',
          searchVolume: 1288,
          organicRank: 30,
          sponsoredRank: 4,
          keywordSales: 87
        }
      ]
    }
  ]
}

import { GenericOperation, ModelTypes, Ops, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export interface ZeusArgs<
  O extends keyof typeof Ops,
  N extends keyof (ValueTypes[GenericOperation<O>] | ModelTypes[GenericOperation<O>]),
  Z extends ValueTypes[GenericOperation<O>] = ValueTypes[GenericOperation<O>]
> {
  operation: any
  graphqlOptions: any
  o: Pick<Z, N>
  opts: any
}

export interface ZeusInterceptor<
  O extends keyof typeof Ops,
  N extends keyof (ValueTypes[GenericOperation<O>] | ModelTypes[GenericOperation<O>]),
  Z extends ValueTypes[GenericOperation<O>] = ValueTypes[GenericOperation<O>],
  R extends ModelTypes[GenericOperation<O>][N] = ModelTypes[GenericOperation<O>][N]
> {
  (args: ZeusArgs<O, N, Z>): Promise<R | undefined>
}

export enum BRANDS {
  CE = 'sample-brand-ce',
  CPG = 'sample-brand-cpg'
}

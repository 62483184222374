import { CampaignClick } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_CLICKS_WIRELESS_EARBUDS: { items: CampaignClick[] } = {
  items: [
    {
      date: '2024-05-09',
      clicks: 511
    },
    {
      date: '2024-05-10',
      clicks: 630
    },
    {
      date: '2024-05-11',
      clicks: 245
    },
    {
      date: '2024-05-12',
      clicks: 160
    },
    {
      date: '2024-05-13',
      clicks: 241
    },
    {
      date: '2024-05-14',
      clicks: 197
    },
    {
      date: '2024-05-15',
      clicks: 154
    },
    {
      date: '2024-05-16',
      clicks: 118
    },
    {
      date: '2024-05-17',
      clicks: 87
    },
    {
      date: '2024-05-18',
      clicks: 54
    },
    {
      date: '2024-05-19',
      clicks: 42
    },
    {
      date: '2024-05-20',
      clicks: 62
    },
    {
      date: '2024-05-21',
      clicks: 54
    },
    {
      date: '2024-05-22',
      clicks: 46
    },
    {
      date: '2024-05-23',
      clicks: 54
    },
    {
      date: '2024-05-24',
      clicks: 58
    },
    {
      date: '2024-05-25',
      clicks: 47
    },
    {
      date: '2024-05-26',
      clicks: 37
    },
    {
      date: '2024-05-27',
      clicks: 52
    },
    {
      date: '2024-05-28',
      clicks: 57
    },
    {
      date: '2024-05-29',
      clicks: 60
    },
    {
      date: '2024-05-30',
      clicks: 50
    },
    {
      date: '2024-05-31',
      clicks: 46
    },
    {
      date: '2024-06-01',
      clicks: 56
    },
    {
      date: '2024-06-02',
      clicks: 52
    },
    {
      date: '2024-06-03',
      clicks: 58
    },
    {
      date: '2024-06-04',
      clicks: 83
    },
    {
      date: '2024-06-05',
      clicks: 52
    },
    {
      date: '2024-06-06',
      clicks: 53
    },
    {
      date: '2024-06-07',
      clicks: 59
    },
    {
      date: '2024-06-08',
      clicks: 36
    },
    {
      date: '2024-06-09',
      clicks: 27
    },
    {
      date: '2024-06-10',
      clicks: 71
    },
    {
      date: '2024-06-11',
      clicks: 94
    },
    {
      date: '2024-06-12',
      clicks: 59
    },
    {
      date: '2024-06-13',
      clicks: 86
    },
    {
      date: '2024-06-14',
      clicks: 84
    },
    {
      date: '2024-06-15',
      clicks: 48
    },
    {
      date: '2024-06-16',
      clicks: 12595
    },
    {
      date: '2024-06-17',
      clicks: 4617
    },
    {
      date: '2024-06-18',
      clicks: 2799
    },
    {
      date: '2024-06-19',
      clicks: 2109
    },
    {
      date: '2024-06-20',
      clicks: 4620
    },
    {
      date: '2024-06-21',
      clicks: 2850
    },
    {
      date: '2024-06-22',
      clicks: 2389
    },
    {
      date: '2024-06-23',
      clicks: 4577
    },
    {
      date: '2024-06-24',
      clicks: 5763
    },
    {
      date: '2024-06-25',
      clicks: 5462
    },
    {
      date: '2024-06-26',
      clicks: 5180
    },
    {
      date: '2024-06-27',
      clicks: 3703
    },
    {
      date: '2024-06-28',
      clicks: 2341
    },
    {
      date: '2024-06-29',
      clicks: 3641
    },
    {
      date: '2024-06-30',
      clicks: 1813
    },
    {
      date: '2024-07-01',
      clicks: 2169
    },
    {
      date: '2024-07-02',
      clicks: 1738
    },
    {
      date: '2024-07-03',
      clicks: 1606
    },
    {
      date: '2024-07-04',
      clicks: 439
    },
    {
      date: '2024-07-05',
      clicks: 650
    },
    {
      date: '2024-07-06',
      clicks: 257
    },
    {
      date: '2024-07-07',
      clicks: 174
    },
    {
      date: '2024-07-08',
      clicks: 195
    },
    {
      date: '2024-07-09',
      clicks: 127
    },
    {
      date: '2024-07-10',
      clicks: 74
    },
    {
      date: '2024-07-11',
      clicks: 115
    },
    {
      date: '2024-07-12',
      clicks: 94
    },
    {
      date: '2024-07-13',
      clicks: 65
    },
    {
      date: '2024-07-14',
      clicks: 49
    },
    {
      date: '2024-07-15',
      clicks: 57
    },
    {
      date: '2024-07-16',
      clicks: 60
    },
    {
      date: '2024-07-17',
      clicks: 75
    },
    {
      date: '2024-07-18',
      clicks: 84
    },
    {
      date: '2024-07-19',
      clicks: 61
    },
    {
      date: '2024-07-20',
      clicks: 41
    },
    {
      date: '2024-07-21',
      clicks: 43
    },
    {
      date: '2024-07-22',
      clicks: 67
    },
    {
      date: '2024-07-23',
      clicks: 50
    },
    {
      date: '2024-07-24',
      clicks: 68
    },
    {
      date: '2024-07-25',
      clicks: 39
    },
    {
      date: '2024-07-26',
      clicks: 33
    },
    {
      date: '2024-07-27',
      clicks: 33
    },
    {
      date: '2024-07-28',
      clicks: 35
    },
    {
      date: '2024-07-29',
      clicks: 52
    },
    {
      date: '2024-07-30',
      clicks: 42
    },
    {
      date: '2024-07-31',
      clicks: 5
    },
    {
      date: '2024-08-01',
      clicks: 1
    },
    {
      date: '2024-08-02',
      clicks: 2
    },
    {
      date: '2024-08-03',
      clicks: 2
    },
    {
      date: '2024-08-04',
      clicks: 3
    },
    {
      date: '2024-08-05',
      clicks: 6
    },
    {
      date: '2024-08-06',
      clicks: 13
    },
    {
      date: '2024-08-07',
      clicks: 4
    },
    {
      date: '2024-08-08',
      clicks: 2
    },
    {
      date: '2024-08-09',
      clicks: 2
    },
    {
      date: '2024-08-10',
      clicks: 2
    },
    {
      date: '2024-08-11',
      clicks: 3
    },
    {
      date: '2024-08-12',
      clicks: 17
    },
    {
      date: '2024-08-13',
      clicks: 5
    },
    {
      date: '2024-08-14',
      clicks: 5
    },
    {
      date: '2024-08-15',
      clicks: 3
    },
    {
      date: '2024-08-16',
      clicks: 2
    },
    {
      date: '2024-08-17',
      clicks: 5
    },
    {
      date: '2024-08-18',
      clicks: 1
    },
    {
      date: '2024-08-19',
      clicks: 6
    },
    {
      date: '2024-08-20',
      clicks: 5
    },
    {
      date: '2024-08-21',
      clicks: 3
    },
    {
      date: '2024-08-22',
      clicks: 1
    },
    {
      date: '2024-08-23',
      clicks: 2
    },
    {
      date: '2024-08-24',
      clicks: 1
    },
    {
      date: '2024-08-26',
      clicks: 2
    },
    {
      date: '2024-08-27',
      clicks: 3
    },
    {
      date: '2024-08-28',
      clicks: 2
    },
    {
      date: '2024-08-29',
      clicks: 2
    },
    {
      date: '2024-08-31',
      clicks: 1
    },
    {
      date: '2024-09-01',
      clicks: 3
    },
    {
      date: '2024-09-03',
      clicks: 2
    },
    {
      date: '2024-09-04',
      clicks: 1
    },
    {
      date: '2024-09-05',
      clicks: 2
    },
    {
      date: '2024-09-06',
      clicks: 3
    },
    {
      date: '2024-09-07',
      clicks: 3
    },
    {
      date: '2024-09-09',
      clicks: 2
    },
    {
      date: '2024-09-10',
      clicks: 1
    },
    {
      date: '2024-09-11',
      clicks: 1
    },
    {
      date: '2024-09-12',
      clicks: 2
    },
    {
      date: '2024-09-13',
      clicks: 2
    },
    {
      date: '2024-09-14',
      clicks: 1
    },
    {
      date: '2024-09-16',
      clicks: 1
    },
    {
      date: '2024-09-17',
      clicks: 1
    },
    {
      date: '2024-09-18',
      clicks: 3
    },
    {
      date: '2024-09-22',
      clicks: 1
    },
    {
      date: '2024-09-25',
      clicks: 2
    },
    {
      date: '2024-09-29',
      clicks: 1
    },
    {
      date: '2024-10-02',
      clicks: 1
    },
    {
      date: '2024-10-03',
      clicks: 2
    },
    {
      date: '2024-10-04',
      clicks: 1
    }
  ]
}

import { ThemeOptions } from "@mui/material";

export const components: ThemeOptions['components'] = {
  MuiInput: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiTextField: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiRadio: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiDrawer: {
    defaultProps: {
      color: 'secondary'
    }
  }
}

import { DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { memoize } from 'lodash'
import { SAMPLE_CONTENT_PENDING_APPROVAL_KEYBOARD } from './keyboard'
import { SAMPLE_CONTENT_PENDING_APPROVAL_LAUNDRY } from './laundry'
import { SAMPLE_CONTENT_PENDING_APPROVAL_MARKER } from './marker'
import { SAMPLE_CONTENT_PENDING_APPROVAL_PILLOW } from './pillow'
import { SAMPLE_CONTENT_PENDING_APPROVAL_WHITENING } from './whitening'
import { SAMPLE_CONTENT_PENDING_APPROVAL_HEADPHONES } from './headphones'

export const SAMPLE_CONTENT_PENDING_APPROVAL: Record<string, Array<ModelTypes['CreatorDraftContent']>> = {
  'sample-campaign-laundry': SAMPLE_CONTENT_PENDING_APPROVAL_LAUNDRY,
  'sample-campaign-keyboard': SAMPLE_CONTENT_PENDING_APPROVAL_KEYBOARD,
  'sample-campaign-whitening': SAMPLE_CONTENT_PENDING_APPROVAL_WHITENING,
  'sample-campaign-marker': SAMPLE_CONTENT_PENDING_APPROVAL_MARKER,
  'sample-campaign-pillow': SAMPLE_CONTENT_PENDING_APPROVAL_PILLOW,
  'sample-campaign-headphones': SAMPLE_CONTENT_PENDING_APPROVAL_HEADPHONES,
  'sample-campaign-bluetooth-speaker': []
}

const getAllSampleContentForApproval = memoize(() => {
  return Object.values(SAMPLE_CONTENT_PENDING_APPROVAL)
    .flat()
    .flatMap(c => c.draftContent)
})

export const isSampleContentPendingApproval = (id: string) =>
  !!getAllSampleContentForApproval().find(c => c?.id === id && c.draftStatus !== DraftStatus.APPROVED)

import { Add } from '@mui/icons-material'
import { Button, Chip, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

export const MultiTextListInput = ({
  name,
  addText,
  placeholder,
  max
}: {
  name: string
  addText: string
  placeholder?: string
  max?: number
}) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const { control, getValues } = useFormContext()
  const { append, fields, remove } = useFieldArray({ name, control })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const modifiedSearchInput = searchInput.trim()
      const vals = getValues(name)
      if (!searchInput || (vals || []).find((v: string) => v.trim() === modifiedSearchInput)) return
      handleAdd()
    }
  }

  const handleAdd = () => {
    append(searchInput)
    setSearchInput('')
  }

  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack direction={'row'} flexWrap={'wrap'}>
          {fields.map((v, index) => (
            <Chip
              key={v.id}
              label={<Typography variant={'label3'}>{getValues(`${name}[${index}]`)}</Typography>}
              size={'small'}
              sx={{ mr: 1, mb: 1 }}
              onDelete={() => {
                remove(index)
              }}
            />
          ))}
        </Stack>
      )}

      <TextField
        variant={'outlined'}
        value={searchInput}
        onKeyDown={handleKeyDown}
        onChange={e => setSearchInput(e.target.value)}
        disabled={fields.length >= 5}
        placeholder={placeholder}
      />
      <Box>
        <Button
          startIcon={<Add />}
          variant={'text'}
          onClick={handleAdd}
          disabled={!searchInput?.trim() || (!!max && fields.length >= max)}
        >
          {addText}
        </Button>
      </Box>
    </Stack>
  )
}

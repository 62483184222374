import VideoOrImage from '@momentum/components/video-or-image'
import { isVideo } from '@productwindtom/shared-node'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useEffect, useRef, useState } from 'react'
import { Box, Paper, Stack, Tooltip } from '@mui/material'
import { ApproveContentViewMode } from '../types'
import { UserAvatar } from '@momentum/components/user-avatar'
import { isNumber } from 'lodash'
import ReviewFeedbackItem from './ReviewFeedbackItem'

const DraftMedia = ({ src }: { src: string }) => {
  const {
    content: { draftStatus, reviewFeedback },
    approveContentViewMode,
    currentVideoPosition,
    setCurrentVideoPosition
  } = useApproveContentContext()

  const mediaRef = useRef<HTMLVideoElement | null>(null)
  const [duration, setDuration] = useState(0)

  const isMediaVideo = isVideo(src)

  useEffect(() => {
    const currentRefPosition = mediaRef.current?.currentTime || 0
    const positionDiff = Math.abs(currentRefPosition - currentVideoPosition)

    if (mediaRef.current && positionDiff > 1) {
      mediaRef.current.currentTime = currentVideoPosition
    }
  }, [currentVideoPosition])

  useEffect(() => {
    const currentMedia = mediaRef.current

    if (isMediaVideo && currentMedia) {
      const handleCurrentTime = () => {
        setDuration(currentMedia.duration)
        setCurrentVideoPosition(currentMedia.currentTime)
      }

      currentMedia?.addEventListener('timeupdate', handleCurrentTime)

      return () => {
        currentMedia?.removeEventListener('timeupdate', handleCurrentTime)
      }
    }
  }, [isMediaVideo, src, setCurrentVideoPosition])

  const videoCurrentPercentage = (duration ? currentVideoPosition / duration : 0) * 100

  const handleVideoTime = (e: React.MouseEvent<HTMLDivElement>) => {
    const clickPosition = e.nativeEvent.offsetX
    const elementWidth = e.currentTarget.offsetWidth
    const clickPositionInPercentage = (clickPosition / elementWidth) * 100

    if (isMediaVideo && mediaRef.current) {
      const nextDuration = (clickPositionInPercentage / 100) * mediaRef.current.duration
      setCurrentVideoPosition(nextDuration)
    }
  }

  const reviewFeedbackAtTime = reviewFeedback?.filter(({ atTime }) => isNumber(atTime))

  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <Stack bgcolor={'white'}>
        <VideoOrImage
          ref={mediaRef}
          alt="Creator draft content"
          controls={
            draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL &&
            approveContentViewMode !== ApproveContentViewMode.REQUEST_EDITS
          }
          src={src}
          objectFit='contain'
        />
        {isMediaVideo &&
          (approveContentViewMode === ApproveContentViewMode.REQUEST_EDITS ||
            draftStatus !== DraftStatus.REQUIRES_BRAND_APPROVAL) && (
            <>
              <Box
                height={10}
                width="100%"
                onClick={handleVideoTime}
                sx={theme => ({
                  background: `linear-gradient(to right, ${theme.palette.primary.dark} ${videoCurrentPercentage}%, ${theme.palette.grey.A200} ${videoCurrentPercentage}%)`,
                  transition: 'all ease-in-out 0.05s'
                })}
              ></Box>
              {!!reviewFeedbackAtTime && (
                <Box position="relative" overflow={'visible'} height={35}>
                  {reviewFeedbackAtTime.map((review, index) => {
                    return (
                      <Tooltip
                        title={
                          <Box p={1}>
                            <ReviewFeedbackItem {...review} />
                          </Box>
                        }
                        placement="top"
                      >
                        <Box
                          sx={{
                            'position': 'absolute',
                            'top': '50%',
                            'left': `${(review.atTime! / duration) * 100}%`,
                            'transform': 'translate(-50%, -50%)',
                            'transition': 'all ease-in-out 0.15s',
                            'zIndex': 1,
                            'cursor': 'pointer',
                            '&:hover': {
                              transform: `translate(-50%, -50%) scale(1.05)`
                            }
                          }}
                          key={`${index}-${review.atTime}`}
                          overflow={'visible'}
                          onClick={() => setCurrentVideoPosition(review.atTime!)}
                        >
                          <UserAvatar firstName={review.createdByFirstName} lastName={review.createdByLastName} />
                        </Box>
                      </Tooltip>
                    )
                  })}
                </Box>
              )}
            </>
          )}
      </Stack>
    </Paper>
  )
}

export default DraftMedia

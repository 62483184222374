import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { withInputBase } from '../InputBase/withInputBase'
import { RadioInputProps } from './types'
import { isArray } from 'lodash'

export const RadioInput = ({ field, options, radioProps, ...props }: RadioInputProps) => (
  <RadioGroup {...field} {...props}>
    {isArray(options) ? (
      options.map(({ label, value }, index) => (
        <FormControlLabel key={index} {...{ label, value }} control={<Radio {...radioProps} />} />
      ))
    ) : (
      <FormControlLabel label={props.label} control={<Radio {...radioProps} />} />
    )}
  </RadioGroup>
)

export default withInputBase(RadioInput)

import { ZeusInterceptor } from '../types'
import { isSampleContent } from '../utils'

export const toggleContentVisibility: ZeusInterceptor<'mutation', 'toggleContentVisibility'> = async args => {
  const id = args.o.toggleContentVisibility?.[0].input.userCampaignSocialContentId
  if (id && isSampleContent(id)) {
    return {
      userCampaignSocialContentId: id,
      isHidden: !!args.o.toggleContentVisibility?.[0].input.isHidden
    }
  }
}

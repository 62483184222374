import { Button, Stack, Typography } from '@mui/material'
import NoInvitationsImage from './NoInvitations.png'
import React from 'react'
import { Add } from '@mui/icons-material'

export const NoInvitations = ({ onInviteClick }: { onInviteClick: () => void }) => {
  return (
    <Stack alignItems={'center'} spacing={4} py={8}>
      <img src={NoInvitationsImage} alt={'No invitations'} width={202} />
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant={'h5'}>You haven’t invited any team members yet!</Typography>
      </Stack>
      <Stack alignItems={'center'}>
        <Button variant={'outlined'} startIcon={<Add />} onClick={onInviteClick}>
          Invite
        </Button>
      </Stack>
    </Stack>
  )
}

import { SAMPLE_CAMPAIGN_CLICKS_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_CLICKS_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/listCampaignClicks/sample-data/water-flosser-pd-launch'
import { CampaignClick } from '@momentum/routes/campaign/context/queries'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_CLICKS_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/listCampaignClicks/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_CLICKS_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/listCampaignClicks/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_CLICKS_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/listCampaignClicks/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_CLICKS_WIRELESS_EARBUDS } from '@momentum/api/interceptors/listCampaignClicks/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_CAMPAIGN_CLICKS: Record<string, { items: CampaignClick[] }> = {
  'sample-campaign-laundry': SAMPLE_CAMPAIGN_CLICKS_LAUNDRY,
  [SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.id]: SAMPLE_CAMPAIGN_CLICKS_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.id]: SAMPLE_CAMPAIGN_CLICKS_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id]: SAMPLE_CAMPAIGN_CLICKS_ENERGY_DRINK_PD_EXISTING,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id]: SAMPLE_CAMPAIGN_CLICKS_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id]: SAMPLE_CAMPAIGN_CLICKS_WIRELESS_EARBUDS,
  'sample-campaign-keyboard': {
    items: [
      {
        date: '2024-01-12',
        clicks: 162
      },
      {
        date: '2024-01-13',
        clicks: 95
      },
      {
        date: '2024-01-14',
        clicks: 134
      },
      {
        date: '2024-01-15',
        clicks: 165
      },
      {
        date: '2024-01-16',
        clicks: 133
      },
      {
        date: '2024-01-17',
        clicks: 187
      },
      {
        date: '2024-01-18',
        clicks: 87
      },
      {
        date: '2024-01-19',
        clicks: 96
      },
      {
        date: '2024-01-20',
        clicks: 116
      },
      {
        date: '2024-01-21',
        clicks: 77
      },
      {
        date: '2024-01-22',
        clicks: 79
      },
      {
        date: '2024-01-23',
        clicks: 74
      },
      {
        date: '2024-01-24',
        clicks: 64
      },
      {
        date: '2024-01-25',
        clicks: 79
      },
      {
        date: '2024-01-26',
        clicks: 204
      },
      {
        date: '2024-01-27',
        clicks: 64
      },
      {
        date: '2024-01-28',
        clicks: 104
      },
      {
        date: '2024-01-29',
        clicks: 79
      },
      {
        date: '2024-01-30',
        clicks: 70
      },
      {
        date: '2024-01-31',
        clicks: 97
      },
      {
        date: '2024-02-01',
        clicks: 89
      },
      {
        date: '2024-02-02',
        clicks: 110
      },
      {
        date: '2024-02-03',
        clicks: 39
      },
      {
        date: '2024-02-04',
        clicks: 203
      },
      {
        date: '2024-02-05',
        clicks: 318
      },
      {
        date: '2024-02-06',
        clicks: 258
      },
      {
        date: '2024-02-07',
        clicks: 181
      },
      {
        date: '2024-02-08',
        clicks: 4
      },
      {
        date: '2024-02-09',
        clicks: 6
      },
      {
        date: '2024-02-10',
        clicks: 8
      },
      {
        date: '2024-02-11',
        clicks: 4
      },
      {
        date: '2024-02-12',
        clicks: 3
      },
      {
        date: '2024-02-13',
        clicks: 2
      },
      {
        date: '2024-02-14',
        clicks: 2
      },
      {
        date: '2024-02-15',
        clicks: 1
      },
      {
        date: '2024-02-18',
        clicks: 1
      },
      {
        date: '2024-02-19',
        clicks: 1
      },
      {
        date: '2024-02-20',
        clicks: 2
      },
      {
        date: '2024-02-21',
        clicks: 1
      },
      {
        date: '2024-02-22',
        clicks: 1
      },
      {
        date: '2024-02-23',
        clicks: 1
      },
      {
        date: '2024-02-24',
        clicks: 1
      },
      {
        date: '2024-02-28',
        clicks: 1
      },
      {
        date: '2024-02-29',
        clicks: 2
      },
      {
        date: '2024-03-01',
        clicks: 1
      },
      {
        date: '2024-03-02',
        clicks: 3
      },
      {
        date: '2024-03-03',
        clicks: 4
      },
      {
        date: '2024-03-04',
        clicks: 1
      },
      {
        date: '2024-03-05',
        clicks: 1
      },
      {
        date: '2024-03-06',
        clicks: 2
      },
      {
        date: '2024-03-07',
        clicks: 1
      },
      {
        date: '2024-03-08',
        clicks: 1
      },
      {
        date: '2024-03-13',
        clicks: 2
      },
      {
        date: '2024-03-14',
        clicks: 4
      },
      {
        date: '2024-03-15',
        clicks: 1
      },
      {
        date: '2024-03-22',
        clicks: 1
      }
    ]
  },
  'sample-campaign-whitening': {
    items: [
      {
        date: '2024-01-30',
        clicks: 582
      },
      {
        date: '2024-01-31',
        clicks: 505
      },
      {
        date: '2024-02-01',
        clicks: 539
      },
      {
        date: '2024-02-02',
        clicks: 283
      },
      {
        date: '2024-02-03',
        clicks: 186
      },
      {
        date: '2024-02-04',
        clicks: 232
      },
      {
        date: '2024-02-05',
        clicks: 504
      },
      {
        date: '2024-02-06',
        clicks: 532
      },
      {
        date: '2024-02-07',
        clicks: 546
      },
      {
        date: '2024-02-08',
        clicks: 362
      },
      {
        date: '2024-02-09',
        clicks: 280
      },
      {
        date: '2024-02-10',
        clicks: 176
      },
      {
        date: '2024-02-11',
        clicks: 110
      },
      {
        date: '2024-02-12',
        clicks: 254
      },
      {
        date: '2024-02-13',
        clicks: 357
      },
      {
        date: '2024-02-14',
        clicks: 131
      },
      {
        date: '2024-02-15',
        clicks: 187
      },
      {
        date: '2024-02-16',
        clicks: 125
      },
      {
        date: '2024-02-17',
        clicks: 112
      },
      {
        date: '2024-02-18',
        clicks: 237
      },
      {
        date: '2024-02-19',
        clicks: 171
      },
      {
        date: '2024-02-20',
        clicks: 169
      },
      {
        date: '2024-02-21',
        clicks: 405
      },
      {
        date: '2024-02-22',
        clicks: 464
      },
      {
        date: '2024-02-23',
        clicks: 285
      },
      {
        date: '2024-02-24',
        clicks: 281
      },
      {
        date: '2024-02-25',
        clicks: 378
      },
      {
        date: '2024-02-26',
        clicks: 409
      },
      {
        date: '2024-02-27',
        clicks: 427
      },
      {
        date: '2024-02-28',
        clicks: 585
      },
      {
        date: '2024-02-29',
        clicks: 253
      },
      {
        date: '2024-03-01',
        clicks: 78
      },
      {
        date: '2024-03-02',
        clicks: 33
      },
      {
        date: '2024-03-03',
        clicks: 29
      },
      {
        date: '2024-03-04',
        clicks: 28
      },
      {
        date: '2024-03-05',
        clicks: 22
      },
      {
        date: '2024-03-06',
        clicks: 20
      },
      {
        date: '2024-03-07',
        clicks: 10
      },
      {
        date: '2024-03-08',
        clicks: 16
      },
      {
        date: '2024-03-09',
        clicks: 18
      },
      {
        date: '2024-03-10',
        clicks: 21
      },
      {
        date: '2024-03-11',
        clicks: 5
      },
      {
        date: '2024-03-12',
        clicks: 2
      },
      {
        date: '2024-03-13',
        clicks: 1
      },
      {
        date: '2024-03-14',
        clicks: 3
      },
      {
        date: '2024-03-15',
        clicks: 4
      },
      {
        date: '2024-03-16',
        clicks: 2
      },
      {
        date: '2024-03-17',
        clicks: 2
      },
      {
        date: '2024-03-18',
        clicks: 3
      },
      {
        date: '2024-03-21',
        clicks: 2
      }
    ]
  },
  'sample-campaign-marker': {
    items: [
      {
        date: '2024-02-26',
        clicks: 7
      },
      {
        date: '2024-02-27',
        clicks: 10
      },
      {
        date: '2024-02-28',
        clicks: 13
      },
      {
        date: '2024-02-29',
        clicks: 125
      },
      {
        date: '2024-03-01',
        clicks: 92
      },
      {
        date: '2024-03-02',
        clicks: 63
      },
      {
        date: '2024-03-03',
        clicks: 58
      },
      {
        date: '2024-03-04',
        clicks: 75
      },
      {
        date: '2024-03-05',
        clicks: 57
      },
      {
        date: '2024-03-06',
        clicks: 54
      },
      {
        date: '2024-03-07',
        clicks: 58
      },
      {
        date: '2024-03-08',
        clicks: 36
      },
      {
        date: '2024-03-09',
        clicks: 3
      },
      {
        date: '2024-03-10',
        clicks: 1
      },
      {
        date: '2024-03-11',
        clicks: 2
      },
      {
        date: '2024-03-13',
        clicks: 1
      },
      {
        date: '2024-03-15',
        clicks: 1
      },
      {
        date: '2024-03-20',
        clicks: 1
      },
      {
        date: '2024-03-23',
        clicks: 1
      }
    ]
  },
  'sample-campaign-pillow': {
    items: [
      {
        date: '2024-02-26',
        clicks: 49
      },
      {
        date: '2024-02-27',
        clicks: 48
      },
      {
        date: '2024-02-28',
        clicks: 52
      },
      {
        date: '2024-02-29',
        clicks: 45
      },
      {
        date: '2024-03-01',
        clicks: 43
      },
      {
        date: '2024-03-02',
        clicks: 33
      },
      {
        date: '2024-03-03',
        clicks: 49
      },
      {
        date: '2024-03-04',
        clicks: 68
      },
      {
        date: '2024-03-05',
        clicks: 71
      },
      {
        date: '2024-03-06',
        clicks: 64
      },
      {
        date: '2024-03-07',
        clicks: 60
      },
      {
        date: '2024-03-08',
        clicks: 46
      },
      {
        date: '2024-03-09',
        clicks: 45
      },
      {
        date: '2024-03-10',
        clicks: 38
      },
      {
        date: '2024-03-11',
        clicks: 17
      },
      {
        date: '2024-03-12',
        clicks: 10
      },
      {
        date: '2024-03-13',
        clicks: 9
      },
      {
        date: '2024-03-14',
        clicks: 9
      },
      {
        date: '2024-03-15',
        clicks: 11
      },
      {
        date: '2024-03-16',
        clicks: 6
      },
      {
        date: '2024-03-17',
        clicks: 11
      }
    ]
  }
}

import { BenchmarkProductsInput } from '@momentum/components/proposal-common/BenchmarkProductsInput'
import { MultiTextListInput } from '@momentum/components/proposal-common/MultiTextListInput'
import { Question } from '@momentum/components/proposal-common/Question'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { CreatorScheduleInput } from '@momentum/routes/proposals-create/timeline/CreatorScheduleInput'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { timelineFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { isHoliday, isWeekend } from '@momentum/utils/dateUtils'
import { getMinLaunchDate } from '@momentum/utils/proposalUtils'
import { ArrowForwardOutlined } from '@mui/icons-material'
import { Alert, Button, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { getAutoBuysDistribution, toWeeklyPartition } from '@productwindtom/shared-momentum'
import { Region } from '@productwindtom/shared-momentum-zeus-types'
import { DateInput } from '@productwindtom/ui-base'
import { keyBy, max } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateProposalContext } from '../context/CreateProposalContext'

export const Timeline = () => {
  const { brand } = useBrandContext()
  const { getValues, setValue } = useFormContext<ProposalCreateForm>()
  const { selectedProduct } = useCreateProposalContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedProduct) {
      navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), {
        replace: true
      })
    }
  }, [])

  const recommendedLaunchDate = getValues('recommendedLaunchDate')
  const launchDate = getValues('launchDate')

  const resetSchedule = () => {
    const isDailyScheduling = getValues('isDailyScheduling')
    const creatorPricing = getValues('creatorPricing')
    const autoBuyDistribution = keyBy(getAutoBuysDistribution({ creatorPricing }), 'type')

    setValue(
      'creatorPricing',
      creatorPricing.map(c => ({
        ...c,
        schedule: isDailyScheduling
          ? autoBuyDistribution[c.type]?.dailySchedule
          : toWeeklyPartition(autoBuyDistribution[c.type]?.dailySchedule)
      }))
    )
  }

  const minLaunchDate = getMinLaunchDate(brand.region)
  const recommendedLaunchDateMax = max([minLaunchDate.date, recommendedLaunchDate])!

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Question
          primaryText={'Select campaign launch date'}
          subtext={`Your campaign must start at least ${minLaunchDate.businessDays} business days in the future${minLaunchDate.region !== Region.US ? ' because it is outside of the US.' : '.'}`}
        >
          <Box sx={{ maxWidth: 165 }}>
            <DateInput
              fullWidth
              name={'launchDate'}
              minDate={minLaunchDate.date}
              shouldDisableDate={(day: unknown) => {
                return isWeekend(day as DateTime) || isHoliday(day as DateTime)
              }}
            />
          </Box>
        </Question>
        {!!recommendedLaunchDate && (
          <Box>
            <Button
              variant={'text'}
              disableRipple
              disableTouchRipple
              disabled={recommendedLaunchDateMax.toISODate()! === launchDate?.toISODate()}
              onClick={() => setValue('launchDate', recommendedLaunchDateMax)}
            >
              Reset to recommended launch date
            </Button>
          </Box>
        )}
        {!!recommendedLaunchDate && recommendedLaunchDate.toISODate()! === launchDate?.toISODate() && (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              {recommendedLaunchDate.toLocaleString(DateTime.DATE_SHORT)} is the recommended campaign start date to
              produce the best SEO results ahead of your holiday or promo.
            </Typography>
          </Alert>
        )}

        {recommendedLaunchDate && recommendedLaunchDate.toISODate()! < minLaunchDate.date.toISODate()! ? (
          <Alert variant={'outlined'} severity={'warning'} color={'warning'}>
            <Typography color={'black'} variant={'label3'}>
              The total creators in this campaign cannot be completed by the desired holiday or promo. Your creators
              will continue to join this campaign after your key date.
            </Typography>
          </Alert>
        ) : !!recommendedLaunchDate && recommendedLaunchDate.toISODate()! !== launchDate?.toISODate() ? (
          <Alert severity={'warning'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              {recommendedLaunchDate.toLocaleString(DateTime.DATE_SHORT)} is the recommended campaign launch date to
              produce the best SEO results ahead of your holiday or promo. Reset to the recommended launch date to
              optimize your campaign.
            </Typography>
          </Alert>
        ) : null}
      </Stack>

      <Question
        primaryText={'Review creator schedule'}
        subtext={
          <>
            Creators will join your campaign according to the schedule below. The recommended schedule is shown below.{' '}
            <Link
              variant={'link2'}
              onClick={resetSchedule}
              underline={'none'}
              sx={{ cursor: 'pointer', display: 'none' }}
            >
              Reset schedule.
            </Link>
          </>
        }
      >
        <CreatorScheduleInput />
      </Question>
      <Question
        primaryText={'What search terms are you tracking for this product? (optional)'}
        subtext={
          'Momentum will track your product across several search terms. We will include your search term when building our list.'
        }
      >
        <MultiTextListInput
          name={'searchTerms'}
          addText={'Add search term'}
          placeholder={'Enter search term'}
          max={5}
        />
      </Question>
      <Question
        primaryText={'What products are you benchmarking against? (optional)'}
        subtext={
          'Momentum will benchmark your product against other relevant products for traffic, sales, and revenue. Add up to 3 relevant products. '
        }
      >
        <BenchmarkProductsInput
          name={'benchmarkProducts'}
          addText={'Add product'}
          placeholder={selectedProduct?.store?.includes('amazon') ? 'Enter product URL or ASIN' : 'Enter product URL'}
          storeName={selectedProduct?.store}
          max={3}
        />
      </Question>
      <NavigateButtons />
    </Stack>
  )
}

const NavigateButtons = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { watch } = useFormContext()
  const data = watch()

  const handleNext = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.REVIEW].join('/'))
  }

  const handleBack = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), { replace: true })
  }

  return (
    <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} pb={2}>
      <Button variant={'text'} onClick={handleBack} data-cy="goBackButton">
        Go back
      </Button>
      <Button
        variant={'contained'}
        onClick={handleNext}
        disabled={!timelineFormValidationSchema.isValidSync(data)}
        endIcon={<ArrowForwardOutlined />}
        data-cy="nextButton"
      >
        Next
      </Button>
    </Stack>
  )
}

import FoodMenuIcon from '@momentum/components/icons/food-menu'
import Logo from '@momentum/components/icons/logo'
import Package from '@momentum/components/icons/package'
import RocketIcon from '@momentum/components/icons/rocket'
import { BrandSelector } from '@momentum/components/nav-drawer/BrandSelector'
import { Group, NavItem } from '@momentum/components/nav-drawer/NavItem'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTES } from '@momentum/routes/RouteNames'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import {
  Add,
  AppsOutlined,
  ArticleOutlined,
  DashboardOutlined,
  PeopleAltOutlined,
  SubscriptionsOutlined
} from '@mui/icons-material'
import { Box, Button, Divider, Drawer, List, Stack, Typography, useTheme } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { last } from 'lodash'
import React, { useEffect } from 'react'
import { Link as RouterLink, generatePath, useMatch, useMatches, useNavigate } from 'react-router-dom'
import VideoOrImage from '../video-or-image'

type Route = {
  id: string
  text: string
  route: string
  selected?: boolean
  disabled?: boolean
  icon: React.ReactNode
  groups?: Group[]
}

export const drawerWidth = 248
export const NavDrawer = () => {
  const {
    isAdminView,
    isAdminManagementView,
    selectedCompany,
    selectedBrand,
    agency,
    agencyId,
    isAdmin,
    loadingResources
  } = useUserSessionContext()
  const { creditsRemaining, getSubscriptionStatus } = useSubscriptionContext()
  const navigate = useNavigate()
  const matches = useMatches()
  const latestMatch = last(matches)
  const theme = useTheme()
  const brandMatch = !!useMatch(ROUTES.BRAND + '/*')
  const brandProductsMatch = !!useMatch(ROUTES.BRAND_PRODUCTS + '/*')
  const brandContentMatch = !!useMatch(ROUTES.BRAND_CONTENT + '/*')
  const subscriptionMatch = !!useMatch(ROUTES.BRAND_SUBSCRIPTION)
  const teamBaseMatch = !!useMatch(ROUTES.TEAM_BASE)
  const profileMatch = !!useMatch(ROUTES.BRAND_PROFILE)
  const brandProposalsFlag = useFlag('BrandProposals')

  const routeMatches = {
    HOME: !!useMatch(ROUTES.HOME),
    BRANDS:
      [ROUTES.HOME, ROUTES.COMPANY, ROUTES.ADMIN_BASE].includes(latestMatch?.pathname || '') ||
      latestMatch?.pathname?.startsWith(ROUTES.COMPANY_BASE),
    PRODUCTS: brandProductsMatch,
    CONTENT: brandContentMatch,
    PROPOSALS: [ROUTES.ADMIN_PROPOSALS].includes(latestMatch?.pathname || ''),
    RECOMMENDATIONS: [ROUTES.ADMIN_RECOMMENDATIONS].includes(latestMatch?.pathname || ''),
    USERS: [ROUTES.ADMIN_USERS].includes(latestMatch?.pathname || ''),
    ALL_SUBSCRIPTIONS: [ROUTES.ADMIN_SUBSCRIPTIONS].includes(latestMatch?.pathname || ''),
    COMPANY_TEAM: !!useMatch(ROUTES.COMPANY_TEAM),
    CAMPAIGNS: !brandProductsMatch && !brandContentMatch && !subscriptionMatch && !profileMatch && brandMatch,
    SUBSCRIPTION: subscriptionMatch,
    PROFILE: profileMatch,
    AGENCY: !!useMatch(ROUTES.HOME),
    AGENCY_TEAM: !!useMatch(ROUTES.AGENCY_TEAM) || teamBaseMatch,
    AGENCY_PROFILE: !!useMatch(ROUTES.AGENCY_PROFILE)
  }

  const { isActive } = getSubscriptionStatus()
  const hasSubscription = isActive || isAdminView

  const adminManagementRoutes: Route[] = [
    {
      id: 'companies',
      text: 'Companies & brands',
      route: ROUTES.ADMIN_BASE,
      selected: routeMatches.BRANDS,
      disabled: !hasSubscription,
      icon: <AppsOutlined sx={{ color: 'white' }} />
    },
    {
      id: 'proposals',
      text: 'All drafts',
      route: ROUTES.ADMIN_PROPOSALS,
      selected: routeMatches.PROPOSALS,
      disabled: !hasSubscription,
      icon: <FoodMenuIcon sx={{ color: 'white' }} />
    },
    {
      id: 'recommendations',
      text: 'All recommendations',
      route: ROUTES.ADMIN_RECOMMENDATIONS,
      selected: routeMatches.RECOMMENDATIONS,
      icon: <Package sx={{ color: 'white' }} />
    },
    {
      id: 'users',
      text: 'All users',
      route: ROUTES.ADMIN_USERS,
      selected: routeMatches.USERS,
      disabled: !hasSubscription,
      icon: <PeopleAltOutlined sx={{ color: 'white' }} />
    },
    {
      id: 'subscriptions',
      text: 'All subscriptions',
      route: ROUTES.ADMIN_SUBSCRIPTIONS,
      selected: routeMatches.ALL_SUBSCRIPTIONS,
      disabled: !hasSubscription,
      icon: <SubscriptionsOutlined sx={{ color: 'white' }} />
    }
  ]

  const agencyRoutes: Route[] = agencyId
    ? [
        {
          id: 'companies',
          text: 'Companies & brands',
          route: isAdmin ? generatePath(ROUTES.AGENCY, { agencyId }) : generatePath(ROUTES.HOME),
          selected: (isAdmin ? routeMatches.AGENCY : routeMatches.HOME) || routeMatches.BRANDS,
          icon: <AppsOutlined sx={{ color: 'white' }} />
        },
        {
          id: 'profile',
          text: 'Profile',
          route: generatePath(ROUTES.AGENCY_PROFILE, { agencyId }),
          selected: routeMatches.AGENCY_PROFILE,
          icon: <PeopleAltOutlined sx={{ color: 'white' }} />,
          groups: [Group.ADMIN]
        },
        {
          id: 'team',
          text: 'Team',
          route: isAdmin ? generatePath(ROUTES.AGENCY_TEAM, { agencyId }) : generatePath(ROUTES.TEAM_BASE),
          selected: routeMatches.AGENCY_TEAM,
          icon: <ArticleOutlined sx={{ color: 'white' }} />
        }
      ]
    : []

  const clientRoutes: Route[] = [
    {
      id: 'campaigns',
      text: 'Campaigns',
      route: selectedBrand ? generatePath(ROUTES.BRAND, { brandId: selectedBrand.id }) : ROUTES.BRANDS,
      selected: routeMatches.CAMPAIGNS,
      disabled: !hasSubscription,
      icon: <RocketIcon sx={{ color: 'white' }} />
    },
    // {
    //   text: 'All content',
    //   route: ROUTES.PRODUCTS,
    //   selected: routeMatches.PRODUCTS,
    //   icon: <Package sx={{ color: 'white' }} />,
    //   isAdmin: true
    // },
    {
      id: 'products',
      text: 'Products',
      route: generatePath(ROUTES.BRAND_PRODUCTS, { brandId: selectedBrand?.id ?? '' }),
      selected: routeMatches.PRODUCTS,
      icon: <Package sx={{ color: 'white' }} />,
      groups: [Group.ADMIN]
    },
    {
      id: 'content',
      text: 'Content',
      route: generatePath(ROUTES.BRAND_CONTENT, { brandId: selectedBrand?.id ?? '' }),
      selected: routeMatches.CONTENT,
      icon: <DashboardOutlined sx={{ color: 'white' }} />,
      groups: [Group.ADMIN]
    },
    {
      id: 'profile',
      text: 'Profile',
      route: generatePath(ROUTES.BRAND_PROFILE, { brandId: selectedBrand?.id ?? '' }),
      selected: routeMatches.PROFILE,
      disabled: !hasSubscription,
      icon: <ArticleOutlined sx={{ color: 'white' }} />
    },
    {
      id: 'team',
      text: 'Team',
      route: generatePath(ROUTES.COMPANY_TEAM, { companyId: selectedCompany?.id ?? '' }),
      selected: routeMatches.COMPANY_TEAM,
      disabled: !hasSubscription,
      icon: <PeopleAltOutlined sx={{ color: 'white' }} />
    },
    {
      id: 'subscription',
      text: 'Subscription',
      route: generatePath(ROUTES.BRAND_SUBSCRIPTION, { brandId: selectedBrand?.id ?? '' }),
      selected: routeMatches.SUBSCRIPTION,
      icon: <SubscriptionsOutlined sx={{ color: 'white' }} />
    }
  ]

  const isUnsubscribedRouteSelected =
    !loadingResources &&
    !isAdminManagementView &&
    !hasSubscription &&
    selectedBrand &&
    !!clientRoutes.find(route => route.selected && route.disabled)

  useEffect(() => {
    if (isUnsubscribedRouteSelected) {
      navigate(generatePath(ROUTES.BRAND_SUBSCRIPTION, { brandId: selectedBrand?.id ?? '' }))
    }
  }, [isUnsubscribedRouteSelected, navigate, selectedBrand?.id])

  return (
    <Drawer
      sx={{
        'width': drawerWidth,
        'flexShrink': 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: theme.palette.primary.main,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
          padding: '20px'
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <Stack justifyContent={'space-between'} height={'100%'}>
        <Stack spacing={2}>
          {!!selectedCompany && !isAdminManagementView && (
            <Stack spacing={1} data-cy={'navDrawerCompanyStack'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'white',
                    borderRadius: '4px'
                  }}
                  p={'3px'}
                >
                  <img
                    src={getCdnImageUrl(selectedCompany.logo)}
                    alt={selectedCompany.name}
                    width={'24px'}
                    height={'24px'}
                    style={{ objectFit: 'contain' }}
                  />
                </Box>
                <Typography color={'white'} variant={'h4'} noWrap>
                  {selectedCompany.name}
                </Typography>
              </Stack>

              <Typography variant={'body1'} color={'white'} textAlign={'center'} pb={1}>
                {creditsRemaining.toLocaleString()} credits remaining
              </Typography>
              {(isAdminView || brandProposalsFlag) && !!selectedBrand && (
                <Button
                  data-cy={'createProposalNav'}
                  startIcon={<Add />}
                  variant={'contained'}
                  sx={{
                    'backgroundColor': 'white',
                    'color': theme => theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: 'white'
                    }
                  }}
                  component={RouterLink}
                  to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL, { brandId: selectedBrand.id })}
                >
                  Create campaign
                </Button>
              )}
            </Stack>
          )}
          {isAdminManagementView && (
            <Stack py={3} alignItems={'center'} spacing={1}>
              <Box
                component={RouterLink}
                to={ROUTES.HOME}
                display={'flex'}
                alignItems="center"
                justifyContent={'center'}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '4px'
                }}
                p={'7px'}
                width={48}
                height={48}
              >
                {agencyId && agency ? (
                  <VideoOrImage src={getCdnImageUrl(agency.logo)} bgcolor="white" />
                ) : (
                  <Logo sx={{ height: 48, width: 48 }} />
                )}
              </Box>
              {agencyId && agency?.name && (
                <Typography variant="label1" color="white">
                  {agency.name}
                </Typography>
              )}
            </Stack>
          )}
          <Divider sx={{ background: 'white' }} />
          <BrandSelector />
          <List>
            <Stack spacing={1}>
              {(isAdminManagementView ? (agencyId ? agencyRoutes : adminManagementRoutes) : clientRoutes)
                .filter(r => !r.groups?.includes(Group.ADMIN) || isAdminView)
                .map(route => (
                  <NavItem
                    id={route.id}
                    key={route.id}
                    text={route.text}
                    disabled={route.disabled}
                    selected={route.selected}
                    onClick={() => navigate(route.route)}
                    icon={route.icon}
                    groups={route.groups}
                  />
                ))}
            </Stack>
          </List>
        </Stack>
        <Stack spacing={1}>
          {agencyId && !routeMatches.AGENCY && !routeMatches.AGENCY_TEAM && !routeMatches.HOME ? (
            <BackToHome agencyId={agencyId} />
          ) : isAdmin &&
            isAdminView &&
            (!isAdminManagementView || routeMatches.HOME || routeMatches.AGENCY || routeMatches.AGENCY_TEAM) ? (
            <BackToHome />
          ) : null}

          <Typography variant={'body1'} color={'white'} textAlign={'center'}>
            Powered by ProductWind
            <sup style={{ fontSize: '4px', fontWeight: 500 }}>TM</sup>
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  )
}

const BackToHome = ({ agencyId }: { agencyId?: string }) => {
  const { agency, isAdmin, setAgencyId } = useUserSessionContext()
  const navigate = useNavigate()

  const handleClick = () => {
    if (!agencyId && isAdmin) {
      setAgencyId(undefined)
      navigate(ROUTES.HOME)
    } else {
      navigate(generatePath(ROUTES.AGENCY, { agencyId }))
    }
  }

  const adminButton = !agencyId && isAdmin

  return (
    <Button
      data-cy={`goToHome${adminButton ? 'Admin' : 'Agency'}`}
      startIcon={!agencyId && <Logo sx={{ color: 'white' }} />}
      variant={'outlined'}
      color={'white1'}
      size={'small'}
      onClick={handleClick}
      sx={{ fontSize: 12 }}
    >
      {adminButton ? 'Go to Momentum Admin' : `Go to ${agency?.name} home`}
    </Button>
  )
}

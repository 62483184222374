import { SvgIcon, SvgIconProps } from '@mui/material'

export default function AmazonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M13.6426 12.3492C7.45128 15.2958 3.60891 12.8305 1.14929 11.3331C0.997086 11.2388 0.738398 11.3552 0.962846 11.613C1.78227 12.6066 4.46769 15.0014 7.97295 15.0014C11.4806 15.0014 13.5674 13.0874 13.8284 12.7535C14.0877 12.4225 13.9045 12.2399 13.6425 12.3492H13.6426ZM15.3814 11.389C15.2151 11.1725 14.3704 11.1321 13.8388 11.1974C13.3063 11.2608 12.5071 11.5862 12.5766 11.7816C12.6123 11.8548 12.6851 11.822 13.0508 11.7891C13.4176 11.7525 14.445 11.6228 14.6591 11.9027C14.8742 12.1845 14.3314 13.5269 14.2323 13.7435C14.1365 13.96 14.2689 14.0158 14.4488 13.8716C14.6262 13.7274 14.9475 13.3542 15.163 12.8259C15.3771 12.2948 15.5077 11.5538 15.3813 11.389H15.3814Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47826 6.3368C9.47826 7.10993 9.49779 7.75468 9.10702 8.44125C8.79162 8.99952 8.29201 9.34281 7.7338 9.34281C6.9718 9.34281 6.52802 8.76225 6.52802 7.90541C6.52802 6.21396 8.04356 5.90697 9.47826 5.90697V6.3368ZM11.4795 11.1738C11.3483 11.291 11.1585 11.2994 11.0106 11.2212C10.3519 10.6742 10.2346 10.4202 9.87179 9.89825C8.78326 11.0091 8.01291 11.3412 6.60062 11.3412C4.93154 11.3412 3.63086 10.3113 3.63086 8.2487C3.63086 6.63826 4.50453 5.54133 5.74649 5.00548C6.82389 4.53093 8.32831 4.44721 9.47826 4.31607V4.05927C9.47826 3.58754 9.5145 3.02934 9.23822 2.62186C8.99536 2.25622 8.53205 2.10549 8.12451 2.10549C7.36816 2.10549 6.6927 2.49343 6.52802 3.29726C6.49449 3.47594 6.36335 3.65179 6.18473 3.66014L4.25884 3.45364C4.09699 3.41728 3.91838 3.28614 3.96303 3.03769C4.40681 0.704382 6.51402 0.000976562 8.40079 0.000976562C9.36653 0.000976562 10.6281 0.257782 11.3901 0.989074C12.3558 1.89057 12.2637 3.09353 12.2637 4.40256V7.49511C12.2637 8.42455 12.6489 8.83202 13.0117 9.33446C13.1401 9.51307 13.168 9.72805 13.0061 9.86195C12.6014 10.1997 11.8813 10.8277 11.485 11.1794L11.4794 11.1738"
        fill="black"
      />
    </SvgIcon>
  )
}

import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS_WATER_FLOSSER_PRIME_DAY: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2024-07-29',
    toDate: '2024-07-30',
    numRatings: 2603,
    numReviews: 646,
    ratingsInPeriod: 5,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 8,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-07-30',
    toDate: '2024-07-31',
    numRatings: 2612,
    numReviews: 650,
    ratingsInPeriod: 5,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 9,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-07-31',
    toDate: '2024-08-01',
    numRatings: 2628,
    numReviews: 659,
    ratingsInPeriod: 7,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 16,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-01',
    toDate: '2024-08-02',
    numRatings: 2640,
    numReviews: 662,
    ratingsInPeriod: 9,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 12,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-02',
    toDate: '2024-08-03',
    numRatings: 2662,
    numReviews: 670,
    ratingsInPeriod: 14,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 22,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-03',
    toDate: '2024-08-04',
    numRatings: 2681,
    numReviews: 675,
    ratingsInPeriod: 14,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 19,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-04',
    toDate: '2024-08-06',
    numRatings: 2709,
    numReviews: 684,
    ratingsInPeriod: 19,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 28,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-06',
    toDate: '2024-08-07',
    numRatings: 2724,
    numReviews: 690,
    ratingsInPeriod: 9,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 15,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-07',
    toDate: '2024-08-08',
    numRatings: 2748,
    numReviews: 698,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 24,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-08',
    toDate: '2024-08-09',
    numRatings: 2768,
    numReviews: 705,
    ratingsInPeriod: 13,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 20,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-09',
    toDate: '2024-08-10',
    numRatings: 2789,
    numReviews: 711,
    ratingsInPeriod: 15,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 21,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-10',
    toDate: '2024-08-11',
    numRatings: 2810,
    numReviews: 722,
    ratingsInPeriod: 10,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 21,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-11',
    toDate: '2024-08-13',
    numRatings: 2851,
    numReviews: 729,
    ratingsInPeriod: 34,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 41,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-13',
    toDate: '2024-08-14',
    numRatings: 2876,
    numReviews: 738,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 25,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-14',
    toDate: '2024-08-16',
    numRatings: 2917,
    numReviews: 744,
    ratingsInPeriod: 35,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 41,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-16',
    toDate: '2024-08-17',
    numRatings: 2934,
    numReviews: 749,
    ratingsInPeriod: 12,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 17,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-17',
    toDate: '2024-08-18',
    numRatings: 2950,
    numReviews: 756,
    ratingsInPeriod: 9,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 16,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-18',
    toDate: '2024-08-19',
    numRatings: 2969,
    numReviews: 759,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 19,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-19',
    toDate: '2024-08-20',
    numRatings: 2993,
    numReviews: 763,
    ratingsInPeriod: 20,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 24,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-20',
    toDate: '2024-08-22',
    numRatings: 3045,
    numReviews: 774,
    ratingsInPeriod: 41,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 52,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-22',
    toDate: '2024-08-23',
    numRatings: 3060,
    numReviews: 779,
    ratingsInPeriod: 10,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 15,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-23',
    toDate: '2024-08-24',
    numRatings: 3095,
    numReviews: 787,
    ratingsInPeriod: 27,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 35,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-24',
    toDate: '2024-08-25',
    numRatings: 3128,
    numReviews: 787,
    ratingsInPeriod: 33,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 33,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-25',
    toDate: '2024-08-26',
    numRatings: 3157,
    numReviews: 800,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 29,
    reviewsInPeriod: 13,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-26',
    toDate: '2024-08-27',
    numRatings: 3185,
    numReviews: 805,
    ratingsInPeriod: 23,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 28,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-27',
    toDate: '2024-08-28',
    numRatings: 3209,
    numReviews: 808,
    ratingsInPeriod: 21,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 24,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-28',
    toDate: '2024-08-29',
    numRatings: 3235,
    numReviews: 812,
    ratingsInPeriod: 22,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 26,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-29',
    toDate: '2024-08-30',
    numRatings: 3257,
    numReviews: 818,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 22,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-30',
    toDate: '2024-08-31',
    numRatings: 3280,
    numReviews: 822,
    ratingsInPeriod: 19,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 23,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-31',
    toDate: '2024-09-01',
    numRatings: 3291,
    numReviews: 824,
    ratingsInPeriod: 9,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 11,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-01',
    toDate: '2024-09-02',
    numRatings: 3317,
    numReviews: 833,
    ratingsInPeriod: 17,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 26,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-02',
    toDate: '2024-09-03',
    numRatings: 3349,
    numReviews: 839,
    ratingsInPeriod: 26,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 32,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-03',
    toDate: '2024-09-04',
    numRatings: 3350,
    numReviews: 841,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-04',
    toDate: '2024-09-05',
    numRatings: 3406,
    numReviews: 847,
    ratingsInPeriod: 50,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 56,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-05',
    toDate: '2024-09-06',
    numRatings: 3433,
    numReviews: 852,
    ratingsInPeriod: 22,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 27,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-06',
    toDate: '2024-09-07',
    numRatings: 3459,
    numReviews: 856,
    ratingsInPeriod: 22,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 26,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-07',
    toDate: '2024-09-08',
    numRatings: 3489,
    numReviews: 860,
    ratingsInPeriod: 26,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 30,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-08',
    toDate: '2024-09-09',
    numRatings: 3514,
    numReviews: 865,
    ratingsInPeriod: 20,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 25,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-09',
    toDate: '2024-09-10',
    numRatings: 3541,
    numReviews: 872,
    ratingsInPeriod: 20,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 27,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-10',
    toDate: '2024-09-12',
    numRatings: 3587,
    numReviews: 881,
    ratingsInPeriod: 37,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 46,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-12',
    toDate: '2024-09-13',
    numRatings: 3612,
    numReviews: 887,
    ratingsInPeriod: 19,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 25,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-13',
    toDate: '2024-09-14',
    numRatings: 3635,
    numReviews: 895,
    ratingsInPeriod: 15,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 23,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-14',
    toDate: '2024-09-15',
    numRatings: 3651,
    numReviews: 897,
    ratingsInPeriod: 14,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 16,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-15',
    toDate: '2024-09-16',
    numRatings: 3684,
    numReviews: 904,
    ratingsInPeriod: 26,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 33,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-16',
    toDate: '2024-09-17',
    numRatings: 3704,
    numReviews: 911,
    ratingsInPeriod: 13,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 20,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-17',
    toDate: '2024-09-18',
    numRatings: 3724,
    numReviews: 917,
    ratingsInPeriod: 14,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 20,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-18',
    toDate: '2024-09-19',
    numRatings: 3736,
    numReviews: 920,
    ratingsInPeriod: 9,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 12,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-19',
    toDate: '2024-09-20',
    numRatings: 3757,
    numReviews: 924,
    ratingsInPeriod: 17,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 21,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-20',
    toDate: '2024-09-21',
    numRatings: 3777,
    numReviews: 924,
    ratingsInPeriod: 20,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 20,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  },
  {
    fromDate: '2024-09-21',
    toDate: '2024-09-22',
    numRatings: 3804,
    numReviews: 928,
    ratingsInPeriod: 23,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 27,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  },
  {
    fromDate: '2024-09-22',
    toDate: '2024-09-23',
    numRatings: 3823,
    numReviews: 934,
    ratingsInPeriod: 13,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 18,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'Healthier gums and brighter teeth',
        text: 'I love this product because it is the easiest and most effective way to floss you’ll have healthier gums and brighter teeth in just a minute a day. This product has clinically proven results for effective plaque removal. It removes up to 99.9% of plaque from the treated areas, it also is up to two times as effective for improving gum health vs regular brushing and flossing. This waterpik/waterflosser has a cordless pulse and it comes with one waterflosser, two tips, and a USB charging cable. I will definitely be purchasing this product for family members as gifts as Christmas .',
        name: 'Buffy Ough',
        rating: 5,
        attributedUserId: '437720eb-7a34-4d62-a4d3-63f715daf905',
        reviewedAt: '2024-09-23T00:00:00.000Z'
      }
    ],
    rating: 4.3,
    momentumRating: 5,
    organicRating: 4.261111111111111,
    vineRating: 0
  },
  {
    fromDate: '2024-09-23',
    toDate: '2024-09-24',
    numRatings: 3856,
    numReviews: 945,
    ratingsInPeriod: 22,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 33,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  },
  {
    fromDate: '2024-09-24',
    toDate: '2024-09-25',
    numRatings: 3876,
    numReviews: 952,
    ratingsInPeriod: 13,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 19,
    reviewsInPeriod: 7,
    reviews: [
      {
        title: 'Amazing product',
        text: 'Super easy to set up, charge & fill with water. After using the water pick you can feel how much more clean your teeth are.',
        name: 'Summer',
        rating: 5,
        attributedUserId: 'bba89140-30b9-49d4-99e8-e49283a2bb98',
        reviewedAt: '2024-09-25T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 4.368421052631579,
    vineRating: 0
  },
  {
    fromDate: '2024-09-25',
    toDate: '2024-09-26',
    numRatings: 3902,
    numReviews: 968,
    ratingsInPeriod: 10,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 1,
    organicRatingCount: 25,
    reviewsInPeriod: 16,
    reviews: [
      {
        title: 'Defectives',
        text: 'Mine was defective , turned on but didn’t spray. I tied both of the tips it comes with and followed all the videos and even let it charge still didn’t work. When I removed the water it did seem as it should work so I tried again and no movement still',
        name: 'makaila wardlaw',
        rating: 1,
        attributedUserId: 'ddde9480-83e9-461f-b000-f3d5f1310daa',
        reviewedAt: '2024-09-26T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 1,
    organicRating: 4.5360000000000005,
    vineRating: 0
  },
  {
    fromDate: '2024-09-26',
    toDate: '2024-09-27',
    numRatings: 3932,
    numReviews: 978,
    ratingsInPeriod: 20,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 30,
    reviewsInPeriod: 10,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-27',
    toDate: '2024-09-28',
    numRatings: 3950,
    numReviews: 989,
    ratingsInPeriod: 7,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 18,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  },
  {
    fromDate: '2024-09-28',
    toDate: '2024-09-29',
    numRatings: 3971,
    numReviews: 998,
    ratingsInPeriod: 12,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 21,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-29',
    toDate: '2024-09-30',
    numRatings: 3999,
    numReviews: 1008,
    ratingsInPeriod: 18,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4,
    organicRatingCount: 27,
    reviewsInPeriod: 10,
    reviews: [
      {
        title: 'Very useful!',
        text: 'Love how this product can be recharged through USB and can be used in the shower! Wish the water reservoir was bigger however. It can be annoying to refill if you need more water.',
        name: 'Jasmine Lowe',
        rating: 4,
        attributedUserId: 'a88af9e1-6e01-4210-a348-ade1d03c3803',
        reviewedAt: '2024-09-30T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4,
    organicRating: 4.414814814814815,
    vineRating: 0
  },
  {
    fromDate: '2024-09-30',
    toDate: '2024-10-01',
    numRatings: 4024,
    numReviews: 1017,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 25,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-10-01',
    toDate: '2024-10-02',
    numRatings: 4050,
    numReviews: 1028,
    ratingsInPeriod: 15,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 26,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  },
  {
    fromDate: '2024-10-02',
    toDate: '2024-10-03',
    numRatings: 4080,
    numReviews: 1042,
    ratingsInPeriod: 16,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 30,
    reviewsInPeriod: 14,
    reviews: [],
    rating: 4.3,
    momentumRating: 0,
    organicRating: 4.3,
    vineRating: 0
  }
]

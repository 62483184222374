import CloseButton from '@momentum/components/close-button'
import Row from '@momentum/components/row'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product } from '@momentum/routes/campaign/context/queries'
import { VariationsFields } from '@momentum/routes/proposals-create/product-creators/ProductVariationsInput'
import VariationPriceAlert from '@momentum/routes/proposals-create/product-creators/VariationPriceAlert'
import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import { notEmpty } from '@productwindtom/shared-node'
import { uniqBy } from 'lodash'
import { useState } from 'react'

const ProductVariations = ({ product, productVariationSkus }: { product: Product; productVariationSkus: string[] }) => {
  const { products } = useBrandContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const productIds = [product.id, product.parentSkuId].filter(notEmpty)

  const variations = uniqBy(
    products?.filter(p => productIds.includes(p.id) || productIds.includes(p.parentSkuId!)),
    'id'
  )

  const selectedVariations = variations.filter(
    variation => variation.skuId && productVariationSkus.includes(variation.skuId)
  )

  const prices = selectedVariations?.map(v => ({
    priceCents: v?.priceCents,
    store: v?.store
  }))

  return (
    <Stack spacing={1}>
      <Row spacing={1}>
        <CheckCircleOutline color="success" />
        <Typography variant="subtitle2">
          Creators can purchase {productVariationSkus?.length} of {variations?.length} variations of this product.
        </Typography>
        <Button variant="text" onClick={() => setIsModalOpen(true)} data-cy='productVariationsInputViewAll'>
          View all
        </Button>
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="sm" fullWidth>
          <Stack p={3} spacing={2}>
            <Row justifyContent={'space-between'}>
              <Typography variant="h4">Product variations</Typography>
              <CloseButton
                iconButtonProps={{
                  onClick: () => setIsModalOpen(false)
                }}
              />
            </Row>
            <VariationsFields
              variations={variations}
              handleClose={() => setIsModalOpen(false)}
              initialVariations={productVariationSkus}
            />
          </Stack>
        </Dialog>
      </Row>
      {prices && <VariationPriceAlert prices={prices} />}
    </Stack>
  )
}

export default ProductVariations

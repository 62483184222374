import { Stack } from '@mui/material'
import { useApproveContentContext } from '../context/ApproveContentContext'

import ReviewFeedbackItem from './ReviewFeedbackItem'

const ReviewFeedback = () => {
  const { content, selectedAsset } = useApproveContentContext()
  const { reviewFeedback } = content

  return (
    <Stack spacing={2} width="100%">
      {reviewFeedback
        ?.filter(({ forAssetAtIndex = 0 }) => forAssetAtIndex === selectedAsset)
        .map((feedback, index) => <ReviewFeedbackItem {...feedback} key={index} />)}
    </Stack>
  )
}

export default ReviewFeedback

import { memoize } from 'lodash'
import { AGENCY_SAMPLE_BRANDS, SAMPLE_BRANDS } from './getBrand/sample-data'
import { SAMPLE_CAMPAIGNS } from './getCampaign/sample-data'
import { SAMPLE_CONTENT_PENDING_APPROVAL } from './listContentPendingApproval/sample-data'
import { STORAGE } from './storage'

const getAllSampleContentIds = memoize(() => {
  return [
    ...Object.values(SAMPLE_CAMPAIGNS)
      .flatMap(c => c.creators)
      .flatMap(c => c.content)
      .flatMap(c => c.content)
      .map(c => c.id),
    ...Object.values(SAMPLE_CONTENT_PENDING_APPROVAL)
      .flat()
      .flatMap(c => c.draftContent)
      .map(c => c?.id)
  ]
})

export const isSampleContent = (socialContentId?: string) => {
  return socialContentId && getAllSampleContentIds().includes(socialContentId)
}

export const getSampleBrand = (brandId?: string | null) => {
  if (brandId && (SAMPLE_BRANDS[brandId] || AGENCY_SAMPLE_BRANDS[brandId])) {
    return SAMPLE_BRANDS[brandId] || AGENCY_SAMPLE_BRANDS[brandId]
  }
}

export const getSampleCampaign = (campaignId?: string) => {
  if (campaignId) {
    return SAMPLE_CAMPAIGNS[campaignId] || STORAGE.campaigns[campaignId]
  }
}

import { ProductFeedbackSummary } from '@momentum/routes/campaign/e-commerce/feedback/queries'
import { createContext, useContext } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

export type FeedbackTheme = {
  theme: string
  positiveFeedback: string[]
  criticalFeedback: string[]
  summary: string
  color: string
  percentage: number
}

export type AITheme = {
  theme: string
  summary: string
}

export type AIFeedback = {
  theme: string
  feedback: string
  tags: string[]
  sentiment: 'positive' | 'negative' | 'neutral'
}
export type AISummary = {
  themes: AITheme[]
  feedback: AIFeedback[]
}

export type FeedbackContextType = {
  productFeedbackSummary?: ProductFeedbackSummary
  aiSummary?: AISummary
  loading?: boolean
  themes: FeedbackTheme[]
  selectedTheme?: FeedbackTheme
  setSelectedTheme: (theme: string) => void
  setChartRef: (ref: ChartJSOrUndefined<'doughnut'> | null) => void
  generateProductFeedback: () => Promise<void>
  generatePdf: () => Promise<void>
}

const FeedbackContext = createContext<FeedbackContextType>({
  themes: [],
  setSelectedTheme: () => console.log('Not implemented'),
  setChartRef: () => console.log('Not implemented'),
  generateProductFeedback: async () => console.log('Not implemented'),
  generatePdf: async () => console.log('Not implemented')
})
FeedbackContext.displayName = 'FeedbackContext'

export default FeedbackContext

export const useFeedbackContext = () => useContext(FeedbackContext)

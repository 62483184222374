import CampaignOverviewPerformanceProvider, {
  useCampaignOverviewPerformanceContext
} from '@momentum/routes/campaign/e-commerce/performance/overviewPerformanceContext'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  ButtonGroup,
  Chip,
  Paper
} from '@mui/material'
import { ProductCard } from '@momentum/routes/campaign/e-commerce/performance/components/ProductCard'
import React, { useState, useEffect, useCallback } from 'react'
import { Add, Close } from '@mui/icons-material'
import { PerformanceComparisonMetric, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { Form, NumberInput, SubmitButton } from '@productwindtom/ui-base'
import { useFormContext } from 'react-hook-form'
import {
  CampaignPerformance,
  updateCampaignPerformanceProduct
} from '@momentum/routes/campaign/e-commerce/performance/queries'
import { Product } from '@momentum/routes/campaign/context/queries'
import { AddProductForm } from '@momentum/routes/campaign/e-commerce/performance/components/add-product-form'
import Loading from '@momentum/components/loading'
import { orderBy, debounce, capitalize } from 'lodash'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { colors } from '@momentum/routes/campaign/e-commerce/performance/productColors'
import { PerformanceGraph } from '@momentum/routes/campaign/e-commerce/performance/components/performance-graph'
import { EmptyState } from '@momentum/routes/campaign/e-commerce/performance/empty-state'
import { BoxToggle } from '@momentum/components/box-toggle'
import { Summary } from '@momentum/routes/campaign/e-commerce/performance/Summary'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { EmptyStateType } from '@momentum/routes/campaign/e-commerce/performance/empty-state-type'
import { EmptyStateDataUnavailable } from '@momentum/routes/campaign/e-commerce/performance/empty-state-data-unavailable'
import { COMPARISON_METRIC_LABELS } from '@momentum/routes/campaign/e-commerce/performance/timeToUtils'
import { getSampleCampaign } from '@momentum/api/interceptors/utils'

type FormType = CampaignPerformance & { promotedProduct: Product }

const Performance = () => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const { campaignPerformance } = useCampaignOverviewPerformanceContext()

  if (!campaignPerformance) {
    return <Loading />
  }

  const hasClientData = campaignPerformance.products.find(
    p =>
      p.productPerformanceRecords.length > 0 &&
      (!p.comparisonMetric || p.comparisonMetric === PerformanceComparisonMetric.REVIEWS)
  )

  const isVisible =
    (campaignPerformance.isTtrVisible &&
      campaignPerformance.comparisonMetric === PerformanceComparisonMetric.REVIEWS) ||
    (campaignPerformance.isBsrVisible &&
      campaignPerformance.comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK)

  const isDataUnavailable =
    (campaignPerformance.isTtrDataUnavailable &&
      campaignPerformance.comparisonMetric === PerformanceComparisonMetric.REVIEWS) ||
    (campaignPerformance.isBsrDataUnavailable &&
      campaignPerformance.comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK)

  if (!isAdminView && isDataUnavailable) {
    return <EmptyStateDataUnavailable metric={campaignPerformance.comparisonMetric!} />
  }

  if (
    !isAdminView &&
    !getSampleCampaign(campaignDetails.id) &&
    !isVisible &&
    campaignDetails.goal !== ProposalGoal.PRODUCT_LAUNCH
  ) {
    return <EmptyStateType />
  }

  if (!isAdminView && (!hasClientData || !isVisible)) {
    return <EmptyState />
  }

  return (
    <Form
      defaultValues={{
        ...campaignPerformance,
        products: orderBy(
          campaignPerformance.products,
          [c => (c.id === campaignPerformance.promotedProductId ? 1 : 0), 'createdAt'],
          ['desc', 'asc']
        )
      }}
      onSubmit={() => {
        //We have autosave, so adding this to prevent enter key submits
      }}
    >
      <CampaignPerformanceBody isDataUnavailable={!!isDataUnavailable} />
    </Form>
  )
}

export default () => (
  <CampaignOverviewPerformanceProvider>
    <Performance />
  </CampaignOverviewPerformanceProvider>
)

const CampaignPerformanceBody = ({ isDataUnavailable }: { isDataUnavailable: boolean }) => {
  const { isAdminView } = useUserSessionContext()
  const { updateCampaignPerformance } = useCampaignOverviewPerformanceContext()

  const [addProductOpen, setAddProductOpen] = useState(false)
  const { watch, setValue, getValues } = useFormContext()

  //Debounce to prevent too many requests
  const handleComparisonValueChange = useCallback(
    debounce(
      (value: number) =>
        updateCampaignPerformance({
          comparisonMetricValue: value
        }),
      500
    ),
    []
  )

  useEffect(() => {
    const watchSub = watch((value, { name }) => {
      if (
        name &&
        [
          'comparisonMetric',
          'isTtrVisible',
          'isBsrVisible',
          'isTtrDataUnavailable',
          'isBsrDataUnavailable',
          'summaryTitle'
        ].includes(name)
      ) {
        updateCampaignPerformance({
          [name]: value[name]
        })
      } else if (name === 'comparisonMetricValue') {
        handleComparisonValueChange(value.comparisonMetricValue)
      }
    })
    return () => watchSub.unsubscribe()
  }, [])

  const onToggleProductGraphVisibility = (index: number, isVisible: boolean) => {
    const product = getValues().products[index]
    if (product) {
      setValue(`products.${index}.isVisible`, isVisible)
      if (isAdminView) {
        updateCampaignPerformanceProduct({
          id: product.id,
          isVisible
        })
      }
    }
  }

  const values = watch() as FormType

  const { comparisonMetric } = values

  const hasData = values.products.find(p => p.productPerformanceRecords.length > 0)

  const comparisonMetricProducts = values.products.filter(
    p => !p.comparisonMetric || p.comparisonMetric === comparisonMetric || p.id === values.promotedProductId
  )

  return (
    <Stack spacing={5}>
      <Paper sx={{ px: 4, py: 2 }}>
        <Summary hasData={!!hasData} />
      </Paper>
      {isAdminView && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack spacing={1}>
            <Stack direction={'row'} spacing={1}>
              <Typography variant={'label1'}>Enter your benchmarking metric</Typography>
              <Chip label={'Admin'} size="small" color="secondary" />
            </Stack>
            <Stack direction={'row'} alignItems={'flex-end'} spacing={1}>
              <Typography variant={'label3'} pb={1}>
                Time to
              </Typography>
              <NumberInput
                name={'comparisonMetricValue'}
                returnAsNumber
                placeholder={'Metric'}
                disabled={isDataUnavailable}
                readOnly={isDataUnavailable}
              />
              <Typography variant={'label3'} pb={1}>
                {comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'reviews' : 'best seller rank'}
              </Typography>
            </Stack>
          </Stack>

          <ButtonGroup
            color="primary"
            variant="outlined"
            size="medium"
            disableRipple
            disableElevation
            sx={{ alignSelf: 'flex-end', ml: 'auto' }}
          >
            <Button
              variant={comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'contained' : 'outlined'}
              value={PerformanceComparisonMetric.REVIEWS}
              onClick={() => setValue('comparisonMetric', PerformanceComparisonMetric.REVIEWS)}
            >
              Time to reviews
            </Button>
            <Button
              variant={comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK ? 'contained' : 'outlined'}
              value={PerformanceComparisonMetric.BEST_SELLER_RANK}
              onClick={() => setValue('comparisonMetric', PerformanceComparisonMetric.BEST_SELLER_RANK)}
            >
              Best seller rank
            </Button>
          </ButtonGroup>
        </Stack>
      )}

      {!isDataUnavailable && (
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            alignItems={'stretch'}
            justifyContent={'stretch'}
            justifyItems={'stretch'}
          >
            {comparisonMetricProducts.map((p, index) => (
              <Grid item xs={3}>
                <BoxToggle
                  key={index}
                  value={!!p.isVisible}
                  readonly={values.promotedProductId === p.id}
                  hideClose
                  color={`${colors[index % 5]}.main`}
                  sx={{ height: '100%' }}
                  padding={0}
                  onChange={isVisible =>
                    onToggleProductGraphVisibility(
                      values.products.findIndex(pp => pp.id === p.id),
                      isVisible
                    )
                  }
                >
                  <ProductCard
                    index={values.products.findIndex(pp => pp.id === p.id)}
                    chipLabel={
                      p.id === values.promotedProductId
                        ? 'Promoted product'
                        : p.isCategoryAverage
                          ? 'Category average'
                          : 'Benchmarked product'
                    }
                    chipColor={p.id === values.promotedProductId ? 'primary' : 'secondary'}
                    canDelete={p.id !== values.promotedProductId}
                  />
                </BoxToggle>
              </Grid>
            ))}
            {comparisonMetricProducts.length < 4 && isAdminView && (
              <Grid item xs={3} alignSelf={'center'}>
                <Button
                  sx={{ ml: 2 }}
                  variant={'outlined'}
                  startIcon={<Add />}
                  onClick={() => setAddProductOpen(true)}
                  disabled={comparisonMetricProducts.length >= 4}
                >
                  Add new product
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {isDataUnavailable ? (
        <Stack alignItems={'center'} pt={10}>
          <Typography variant={'label3'} color={'grey.A700'}>
            {capitalize(COMPARISON_METRIC_LABELS[comparisonMetric!] || '')} data unavailable
          </Typography>
        </Stack>
      ) : hasData ? (
        <Stack spacing={4}>
          <PerformanceGraph />
        </Stack>
      ) : (
        <Stack alignItems={'center'} pt={10}>
          <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
            Upload {COMPARISON_METRIC_LABELS[comparisonMetric!]} data for at least one product
          </Typography>
        </Stack>
      )}

      <Dialog open={addProductOpen} maxWidth={'sm'} fullWidth>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Add new product</Typography>
            <IconButton size={'small'} onClick={() => setAddProductOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AddProductForm onClose={() => setAddProductOpen(false)} />
        </DialogContent>
      </Dialog>
      <SubmitButton sx={{ display: 'none' }} />
    </Stack>
  )
}

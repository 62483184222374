import AmazonIcon from '@momentum/components/icons/amazon'
import BestbuyIcon from '@momentum/components/icons/bestbuy'
import CostcoIcon from '@momentum/components/icons/costco'
import CAFlagIcon from '@momentum/components/icons/flags/ca'
import DEFlagIcon from '@momentum/components/icons/flags/de'
import UKFlagIcon from '@momentum/components/icons/flags/uk'
import USFlagIcon from '@momentum/components/icons/flags/us'
import KrogerIcon from '@momentum/components/icons/kroger'
import ShopifyIcon from '@momentum/components/icons/shopify'
import TargetIcon from '@momentum/components/icons/target'
import WalmartIcon from '@momentum/components/icons/walmart'
import { ShoppingCart } from '@mui/icons-material'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { Store } from '@productwindtom/shared-momentum-zeus-types'

export const StoreToIcon: Record<Store, (props: SvgIconProps) => JSX.Element> = {
  [Store.amazon]: AmazonIcon,
  [Store.amazon_ca]: AmazonIcon,
  [Store.amazon_uk]: AmazonIcon,
  [Store.amazon_de]: AmazonIcon,
  [Store.bestBuy]: BestbuyIcon,
  [Store.walmart]: WalmartIcon,
  [Store.target]: TargetIcon,
  [Store.costco]: CostcoIcon,
  [Store.shopify]: ShopifyIcon,
  [Store.dtc]: ShopifyIcon,
  [Store.kroger]: KrogerIcon,
  [Store.other]: (props: SvgIconProps) => <SvgIcon component={ShoppingCart} {...props} />
}

export const StoreToFlagIcon: Record<Store, (props: SvgIconProps) => JSX.Element> = {
  [Store.amazon]: USFlagIcon,
  [Store.amazon_ca]: CAFlagIcon,
  [Store.amazon_uk]: UKFlagIcon,
  [Store.amazon_de]: DEFlagIcon,
  [Store.bestBuy]: USFlagIcon,
  [Store.walmart]: USFlagIcon,
  [Store.target]: USFlagIcon,
  [Store.costco]: USFlagIcon,
  [Store.shopify]: USFlagIcon,
  [Store.dtc]: USFlagIcon,
  [Store.kroger]: USFlagIcon,
  [Store.other]: USFlagIcon
}

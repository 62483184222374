import { ZeusInterceptor } from '../types'
import { SAMPLE_PRODUCT_SEO_METRICS } from './sample-data'
import { SAMPLE_CAMPAIGNS } from '@momentum/api/interceptors/getCampaign/sample-data'

export const getProductSeoSummary: ZeusInterceptor<'query', 'getProductSeoSummary'> = async args => {
  const params = args.o.getProductSeoSummary?.[0]
  const campaign = params?.campaignId ? SAMPLE_CAMPAIGNS[params?.campaignId] : undefined

  if (campaign) {
    return SAMPLE_PRODUCT_SEO_METRICS[campaign.skuId]
  }
}

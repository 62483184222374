import { Close } from '@mui/icons-material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import { useSeoContext } from '../../context'

export const UploadSeoData = () => {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState<File>()

  const { uploadCsv, isUpdating } = useSeoContext()
  const csvFileRef = React.createRef<HTMLInputElement>()

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleSubmit = async () => {
    if (file) {
      await uploadCsv(file)
      setOpen(false)
    }
  }

  const handleClose = () => {
    if (!isUpdating) {
      setOpen(false)
      setFile(undefined)
    }
  }

  return (
    <>
      <Button
        startIcon={<FileUploadOutlinedIcon />}
        variant="outlined"
        component="label"
        disabled={isUpdating}
        onClick={() => setOpen(true)}
      >
        Upload search terms
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <Typography variant="h4" flex={1}>
                Upload search terms CSV
              </Typography>
              <IconButton
                size="small"
                disabled={isUpdating}
                onClick={handleClose}
                sx={{
                  color: 'black'
                }}
              >
                <Close />
              </IconButton>
            </Stack>

            <Typography variant="label3" mt={2}>
              Upload search terms to add them to Momentum. This will override any existing search term data.
            </Typography>

            {!file && (
              <Button variant="outlined" component="label" disabled={isUpdating} sx={{ alignSelf: 'start' }}>
                Attach file
                <input
                  style={{ display: 'none' }}
                  accept={'text/csv'}
                  ref={csvFileRef}
                  value={''}
                  onChange={handleFileSelected}
                  type="file"
                />
              </Button>
            )}

            {file && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <CheckCircleOutlinedIcon color="success" />
                <Typography variant={'link2'}>{file.name}</Typography>
                <IconButton
                  size="small"
                  disabled={isUpdating}
                  onClick={() => {
                    setFile(undefined)
                    if (csvFileRef.current) {
                      csvFileRef.current.value = ''
                    }
                  }}
                  sx={{
                    color: 'black'
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isUpdating}>
            Cancel
          </Button>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            loading={isUpdating}
            disabled={!file}
            disableElevation
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

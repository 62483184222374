import { DateTime } from 'luxon'
import { SalesData } from '../salesContext'

export const LAUNDRY_SALES: SalesData[] = [
  {
    date: '2023-08-01T00:00:00.000-03:00',
    fromMomentum: 3,
    fromOtherCustomers: 12,
    total: 15
  },
  {
    date: '2023-08-02T00:00:00.000-03:00',
    fromMomentum: 26,
    fromOtherCustomers: 12,
    total: 38
  },
  {
    date: '2023-08-03T00:00:00.000-03:00',
    fromMomentum: 22,
    fromOtherCustomers: 13,
    total: 35
  },
  {
    date: '2023-08-04T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 14,
    total: 14
  },
  {
    date: '2023-08-05T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 12,
    total: 12
  },
  {
    date: '2023-08-06T00:00:00.000-03:00',
    fromMomentum: 26,
    fromOtherCustomers: 11,
    total: 37
  },
  {
    date: '2023-08-07T00:00:00.000-03:00',
    fromMomentum: 30,
    fromOtherCustomers: 12,
    total: 42
  },
  {
    date: '2023-08-08T00:00:00.000-03:00',
    fromMomentum: 65,
    fromOtherCustomers: 10,
    total: 75
  },
  {
    date: '2023-08-09T00:00:00.000-03:00',
    fromMomentum: 83,
    fromOtherCustomers: 13,
    total: 96
  },
  {
    date: '2023-08-10T00:00:00.000-03:00',
    fromMomentum: 52,
    fromOtherCustomers: 14,
    total: 66
  },
  {
    date: '2023-08-11T00:00:00.000-03:00',
    fromMomentum: 44,
    fromOtherCustomers: 11,
    total: 55
  },
  {
    date: '2023-08-12T00:00:00.000-03:00',
    fromMomentum: 35,
    fromOtherCustomers: 11,
    total: 46
  },
  {
    date: '2023-08-13T00:00:00.000-03:00',
    fromMomentum: 35,
    fromOtherCustomers: 10,
    total: 45
  },
  {
    date: '2023-08-14T00:00:00.000-03:00',
    fromMomentum: 17,
    fromOtherCustomers: 12,
    total: 29
  },
  {
    date: '2023-08-15T00:00:00.000-03:00',
    fromMomentum: 61,
    fromOtherCustomers: 12,
    total: 73
  },
  {
    date: '2023-08-16T00:00:00.000-03:00',
    fromMomentum: 1,
    fromOtherCustomers: 11,
    total: 12
  },
  {
    date: '2023-08-17T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 55,
    total: 55
  },
  {
    date: '2023-08-18T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 76,
    total: 76
  },
  {
    date: '2023-08-19T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 78,
    total: 78
  },
  {
    date: '2023-08-20T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 88,
    total: 88
  },
  {
    date: '2023-08-21T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 90,
    total: 90
  },
  {
    date: '2023-08-22T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 85,
    total: 85
  },
  {
    date: '2023-08-23T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 84,
    total: 84
  },
  {
    date: '2023-08-24T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 59,
    total: 59
  },
  {
    date: '2023-08-25T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 48,
    total: 48
  },
  {
    date: '2023-08-26T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 27,
    total: 27
  },
  {
    date: '2023-08-27T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 30,
    total: 30
  },
  {
    date: '2023-08-28T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 22,
    total: 22
  },
  {
    date: '2023-08-29T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 25,
    total: 25
  },
  {
    date: '2023-08-30T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 26,
    total: 26
  },
  {
    date: '2023-08-31T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 23,
    total: 23
  },
  {
    date: '2023-09-01T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 25,
    total: 25
  },
  {
    date: '2023-09-02T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 28,
    total: 28
  },
  {
    date: '2023-09-03T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 22,
    total: 22
  },
  {
    date: '2023-09-04T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 21,
    total: 21
  },
  {
    date: '2023-09-05T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 25,
    total: 25
  },
  {
    date: '2023-09-06T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 33,
    total: 33
  },
  {
    date: '2023-09-07T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 25,
    total: 25
  },
  {
    date: '2023-09-08T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 31,
    total: 31
  },
  {
    date: '2023-09-09T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 31,
    total: 31
  },
  {
    date: '2023-09-10T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 21,
    total: 21
  },
  {
    date: '2023-09-11T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 14,
    total: 14
  },
  {
    date: '2023-09-12T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 15,
    total: 15
  },
  {
    date: '2023-09-13T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 18,
    total: 18
  },
  {
    date: '2023-09-14T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 15,
    total: 15
  },
  {
    date: '2023-09-15T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 14,
    total: 14
  },
  {
    date: '2023-09-16T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 19,
    total: 19
  },
  {
    date: '2023-09-17T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 22,
    total: 22
  },
  {
    date: '2023-09-18T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 23,
    total: 23
  },
  {
    date: '2023-09-19T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 21,
    total: 21
  },
  {
    date: '2023-09-20T00:00:00.000-03:00',
    fromMomentum: 0,
    fromOtherCustomers: 21,
    total: 21
  }
].map(d => ({ ...d, date: DateTime.fromISO(d.date) }))

export enum StoreName {
  bestBuy = 'bestBuy',
  amazon = 'amazon',
  amazon_ca = 'amazon_ca',
  amazon_uk = 'amazon_uk',
  amazon_de = 'amazon_de',
  walmart = 'walmart',
  kroger = 'kroger',
  target = 'target',
  shopify = 'shopify',
  total_wine = 'total_wine',
  dtc = 'dtc',
  ios = 'ios',
  android = 'android',
  costco = 'costco',
  in_store = 'in_store'
}

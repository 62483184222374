import AmazonChoiceIcon from '@momentum/components/icons/amazon-choice'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { GridColDef } from '@mui/x-data-grid-premium'

export type SeoRowType = {
  id: string
  keyword: string
  searchVolume: number
  pageOneWin?: boolean
  startRank?: number
  highestRank?: number
  slotsImproved: number
  hasAmazonChoiceBadge: boolean
}

export const SEO_COLUMN_DEFINITIONS: GridColDef<SeoRowType>[] = [
  {
    headerName: 'Search term',
    field: 'keyword',
    flex: 3,
    align: 'left',
    renderCell: ({ value, row }) => {
      return (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant="body2">{value}</Typography>
          {row.hasAmazonChoiceBadge && <AmazonChoiceIcon />}
        </Stack>
      )
    }
  },
  {
    headerName: 'Search volume',
    field: 'searchVolume',
    flex: 1,
    align: 'center',
    renderCell: ({ value }) => <Typography variant="label4">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Page 1 win',
    field: 'pageOneWin',
    align: 'center',
    renderCell: ({ value }) => (value ? <CheckCircleOutlineOutlinedIcon color="success" /> : null)
  },
  {
    headerName: 'Starting rank',
    field: 'startRank',
    flex: 1,
    align: 'center',
    renderCell: ({ value }) => <Typography variant="label4">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Highest rank',
    field: 'highestRank',
    flex: 1,
    align: 'center',
    renderCell: ({ value }) => <Typography variant="label4">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Slots improved',
    field: 'slotsImproved',
    flex: 1,
    align: 'center',
    renderCell: ({ value }) => (
      <Typography variant="label4" color={theme => (value ? theme.palette.success.main : undefined)}>
        {value ? `+${value.toLocaleString()}` : 0}
      </Typography>
    )
  }
]

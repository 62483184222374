export const getPercentQuotient = (totalDistribution: number, percent: number | string) =>
  totalDistribution &&
  percent &&
  Math.round(
    ((typeof percent === 'string' ? Number.parseInt(percent.replace('%', '')) : percent) / 100) * totalDistribution
  )

export const getPercentValue = (
  value: number | undefined,
  totalDistribution: number,
  decimalCount: number = 1
): string =>
  value === 0
    ? '0'
    : totalDistribution === 0
      ? '100'
      : value
        ? Math.round((value / totalDistribution) * 100).toFixed(decimalCount)
        : '0'

import LogoIcon from '@momentum/components/icons/logo'
import Row from '@momentum/components/row'
import { Stack, Typography } from '@mui/material'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { DateTime } from 'luxon'
import { useApproveContentContext } from '../context/ApproveContentContext'

const ReviewedAt = ({ row }: { row?: boolean }) => {
  const {
    content: { draftStatus, reviewerFirstName, reviewerLastName, reviewedAt }
  } = useApproveContentContext()

  const reviewerName = [reviewerFirstName, reviewerLastName].filter(notEmpty).join(' ')
  const reviewedAtDateTime = reviewedAt && DateTime.fromISO(reviewedAt)

  return (
    <Stack direction={row ? 'row' : 'column'} spacing={1}>
      <Row spacing={0.5}>
        <LogoIcon fontSize="small" />
        <Typography variant="caption2" noWrap>
          {draftStatus === DraftStatus.APPROVED
            ? 'Approved' + (reviewerName ? ` by ${reviewerName}` : '')
            : `${reviewerName} asked for creator edits`}
        </Typography>
      </Row>
      {reviewedAtDateTime && (
        <Typography variant="caption2" noWrap>
          {reviewedAtDateTime.toFormat('ha').toLowerCase()} on {reviewedAtDateTime.toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      )}
    </Stack>
  )
}

export default ReviewedAt

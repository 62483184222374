import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ShopifyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16" fill="none">
      <path
        d="M14.1595 3.121C14.1477 3.05025 14.09 2.996 14.0185 2.98925C13.9622 2.98 12.726 2.89525 12.726 2.89525L11.7835 1.95275C11.6985 1.85875 11.5105 1.88625 11.4395 1.90575C11.4302 1.90575 11.2515 1.962 10.959 2.057C10.6747 1.2345 10.1715 0.4805 9.28495 0.4805H9.2092C8.9552 0.15125 8.6442 0 8.3817 0C6.3177 0.00925 5.3342 2.58325 5.0232 3.8985L3.5807 4.341C3.1382 4.482 3.12195 4.49225 3.06245 4.91425L1.8457 14.2937L10.9627 16L15.9035 14.935C15.9035 14.9258 14.1685 3.2105 14.1595 3.121ZM10.4535 2.215C10.2242 2.29075 9.9627 2.36625 9.68095 2.45125V2.28125C9.6893 1.85704 9.62573 1.43448 9.49295 1.0315C9.95495 1.1025 10.2655 1.62075 10.4537 2.21475L10.4535 2.215ZM8.93645 1.1405C9.0872 1.585 9.1572 2.053 9.14295 2.52225V2.60725L7.5492 3.098C7.8612 1.933 8.43645 1.3575 8.93645 1.1405ZM8.32395 0.56725C8.41824 0.569256 8.5099 0.598708 8.5877 0.652C7.9227 0.9615 7.2197 1.7435 6.9182 3.30875L5.65695 3.69525C6.0127 2.5075 6.8427 0.565 8.3232 0.565L8.32395 0.56725Z"
        fill="#95BF47"
      />
      <path
        d="M14.0186 2.98923C13.9624 2.97998 12.7261 2.89523 12.7261 2.89523L11.7836 1.95273C11.7486 1.91723 11.7011 1.89698 11.6519 1.89648L10.9639 16L15.9046 14.935L14.1596 3.13048C14.154 3.09513 14.1373 3.06246 14.112 3.03714C14.0866 3.01182 14.054 2.99514 14.0186 2.98948V2.98923Z"
        fill="#5E8E3E"
      />
      <path
        d="M9.28544 5.72262L8.67194 7.53312C8.30119 7.35362 7.89594 7.25712 7.48394 7.24987C6.52194 7.24987 6.47494 7.85286 6.47494 8.00436C6.47494 8.82887 8.63394 9.14536 8.63394 11.0876C8.63394 12.6151 7.66269 13.5951 6.35894 13.5951C4.79394 13.5951 3.99219 12.6239 3.99219 12.6239L4.40844 11.2364C4.40844 11.2364 5.22844 11.9439 5.92669 11.9439C6.01016 11.947 6.0934 11.9333 6.17139 11.9033C6.24938 11.8734 6.32049 11.828 6.38041 11.7698C6.44033 11.7116 6.48783 11.6419 6.52002 11.5648C6.55221 11.4877 6.56842 11.4049 6.56769 11.3214C6.56769 10.2364 4.79519 10.1896 4.79519 8.41687C4.79519 6.92637 5.86969 5.47437 8.02869 5.47437C8.87719 5.48687 9.28319 5.72187 9.28319 5.72187L9.28544 5.72262Z"
        fill="white"
      />
    </SvgIcon>
  )
}

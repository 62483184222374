import { ZeusInterceptor } from '../types'
import { STORAGE } from '@momentum/api/interceptors/storage'
import {
  CreatorType,
  ProposalStatus,
  InvoiceStatus,
  InvoicePaymentType
} from '@productwindtom/shared-momentum-zeus-types'
import { sumBy } from 'lodash'

export const createCampaign: ZeusInterceptor<'mutation', 'createCampaign'> = async args => {
  const params = args.o.createCampaign?.[0] as {
    proposalId: string
  }

  const sampleProposal = STORAGE.proposals[params.proposalId]
  if (sampleProposal) {
    STORAGE.proposals[sampleProposal.id] = {
      ...sampleProposal,
      invoiceStatus:
        sampleProposal.invoiceStatus ||
        (sampleProposal.paymentType === InvoicePaymentType.CREDITS ? InvoiceStatus.PAID : InvoiceStatus.NOT_PAID),
      status: ProposalStatus.SUBMITTED
    }
    const proposal = STORAGE.proposals[sampleProposal.id]

    STORAGE.campaigns[sampleProposal.id] = {
      id: proposal.id,
      title: proposal.title!,
      brandId: proposal.brandId,
      cost: proposal.totalProductCostCredits!,
      goal: proposal.goal,
      proposal: proposal,
      createdAt: new Date().toISOString(),

      numCreators: sumBy(sampleProposal.creatorPricing, p => p.numCreators),
      numBrandAdvocateCreators: sampleProposal.creatorPricing.find(p => p.type === CreatorType.ADVOCATE)?.numCreators,
      numUgcCreators: sampleProposal.creatorPricing.find(p => p.type === CreatorType.UGC)?.numCreators,
      numPremiumUgcCreators: sampleProposal.creatorPricing.find(p => p.type === CreatorType.PREMIUM_UGC)?.numCreators,
      numSocialCreators: sampleProposal.creatorPricing.find(p =>
        [CreatorType.TT, CreatorType.IG, CreatorType.YOUTUBE].includes(p.type)
      )?.numCreators,
      numCreatorsJoined: 0,

      startDate: proposal.launchDate,
      anticipatedStartDate: proposal.launchDate,
      skuId: proposal.product!.id,
      product: proposal.product!,
      creators: []
    }

    return {
      id: sampleProposal.id
    }
  }
}

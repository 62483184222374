import { ThemeOptions } from '@mui/material'

export const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1536
  }
}

import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText, Typography, Chip } from '@mui/material'

export enum Group {
  ADMIN = 'Admin'
}

type NavItemProps = {
  id: string
  selected?: boolean
  text: string
  disabled?: boolean
  onClick: () => void
  icon?: React.ReactNode
  groups?: Group[]
}
export const NavItem = ({ id, selected, text, onClick, icon, disabled, groups }: NavItemProps) => {
  return (
    <ListItemButton
      data-cy={id}
      disabled={disabled}
      selected={selected}
      onClick={onClick}
      dense
      sx={{
        'px': 1,
        'borderRadius': '4px',
        '&.Mui-selected': {
          background: theme => theme.palette.primary.dark
        }
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            sx={{ fontSize: '14px', fontWeight: selected ? 800 : 400 }}
            color={theme => theme.palette.primary.contrastText}
          >
            {text}
          </Typography>
        }
      />
      {groups?.map(group => <Chip key={group} label={group} size={'small'} sx={{ backgroundColor: 'white' }} />)}
    </ListItemButton>
  )
}

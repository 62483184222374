import { TableHeadCell } from '@momentum/routes/campaign/e-commerce/common/TableHeadCell'
import { useTrafficContext } from '@momentum/routes/campaign/e-commerce/traffic/trafficContext'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material'
import { first, last, sumBy } from 'lodash'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2
}))

export const TrafficTable = () => {
  const { filteredTrafficData, isIntegrated } = useTrafficContext()

  const minDate = first(filteredTrafficData)?.date
  const maxDate = last(filteredTrafficData)?.date

  if (!minDate || !maxDate) return null

  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  const weekData = weeksArray.map((_, index) => {
    const start = minDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const weekRangeData = filteredTrafficData.filter(d => d.date >= start && d.date < end)
    return {
      start,
      end,
      momentumViews: sumBy(weekRangeData, 'momentumViews'),
      otherViews: sumBy(weekRangeData, 'otherViews'),
      totalViews: sumBy(weekRangeData, 'totalViews')
    }
  })

  return (
    <TableContainer>
      <Table
        sx={{
          display: 'block',
          overflowX: 'auto',
          whiteSpace: 'nowrap'
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Traffic type</TableCell>
            {weekData.map((d, index) => (
              <TableHeadCell key={index} week={index + 1} date={d.start} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyTableCell>ProductWind views</BodyTableCell>
            {weekData.map((d, index) => (
              <BodyTableCell key={index}>{d.momentumViews.toLocaleString()}</BodyTableCell>
            ))}
          </TableRow>
          {isIntegrated && (
            <TableRow>
              <BodyTableCell>Other views</BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index}>{d.otherViews.toLocaleString()}</BodyTableCell>
              ))}
            </TableRow>
          )}
          {isIntegrated && (
            <TableRow>
              <BodyTableCell>Total views</BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index}>{d.totalViews.toLocaleString()}</BodyTableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

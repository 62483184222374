import { DataGrid } from '@momentum/components/table'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import SortIcon from '@mui/icons-material/Sort'
import { gridClasses } from '@mui/x-data-grid-pro'
import { ListingBadge } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'
import { SEO_COLUMN_DEFINITIONS, SeoRowType } from './constants'
import { Typography, Stack } from '@mui/material'

export const KeywordTable = () => {
  const { legacyProductSEOSummary } = useCampaignContext()

  const rows: SeoRowType[] = useMemo(() => {
    return (
      legacyProductSEOSummary?.keywords.map(k => ({
        id: k.keyword,
        keyword: k.keyword,
        searchVolume: k.impressions,
        pageOneWin: k.pageOneWin,
        startRank: k.startRank,
        highestRank: k.highestRank,
        slotsImproved:
          k.highestRank && k.startRank && k.highestRank <= k.startRank ? Math.abs(k.highestRank - k.startRank) : 0,
        hasAmazonChoiceBadge: !!k.badges?.find(b => b.badge === ListingBadge.AMAZON_CHOICE)
      })) ?? []
    )
  }, [legacyProductSEOSummary?.keywords])

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Search term performance</Typography>
      <DataGrid
        autoHeight
        rows={rows}
        columns={SEO_COLUMN_DEFINITIONS}
        disableColumnMenu={true}
        disableColumnReorder={true}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10, 25, 50]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none'
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none'
          }
        }}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: (props: any) => (
            <SortIcon
              {...props}
              sx={{
                transform: 'scale(1, -1)'
              }}
            />
          )
        }}
      />
    </Stack>
  )
}

import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { HTMLAttributeAnchorTarget } from 'react'

const TableLink = ({
  to,
  label,
  className,
  target
}: {
  to: string
  label: string
  className?: string
  target?: HTMLAttributeAnchorTarget
}) => {
  return (
    <Link
      component={RouterLink}
      target={target ?? '_blank'}
      to={to}
      variant={'label4'}
      underline="hover"
      color="primary"
      className={className}
      alignSelf={'center'}
    >
      {label}
    </Link>
  )
}

export default TableLink

import { ChevronRight } from '@mui/icons-material'
import { Breadcrumbs } from '@mui/material'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { LinkRouter } from '@productwindtom/ui-base'

export const RoutesBreadcrumb = ({ crumbs }: { crumbs: { label: string; to: string }[] }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight fontSize={'mSmall'} color={'primary'} />}>
      {crumbs.map((crumb, index) => (
        <LinkRouter key={index} underline="hover" color="primary" to={crumb.to} variant={'label3'}>
          {ellipsisString(crumb.label, 20)}
        </LinkRouter>
      ))}
    </Breadcrumbs>
  )
}

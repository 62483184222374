import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Paper, Stack, Typography } from '@mui/material'

const AlertCard = ({ text, subText }: { text: string; subText?: string }) => {
  return (
    <Paper sx={{ px: 4, py: 2, alignSelf: 'flex-start' }}>
      <Stack direction={'row'} spacing={2}>
        <WarningAmberIcon color="warning" fontSize="medium" />
        <Stack direction={'column'} spacing={1}>
          <Typography variant="label3">{text}</Typography>
          {subText && <Typography variant="body1">{subText}</Typography>}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default AlertCard

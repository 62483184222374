import { EmptyIcon } from '@momentum/routes/campaign/e-commerce/performance/empty-state/EmptyIcon'
import { Stack, Typography } from '@mui/material'
import React from 'react'

export const EmptyState = () => {
  return (
    <Stack pt={12} spacing={3} alignItems={'center'} justifyContent={'center'}>
      <EmptyIcon sx={{ height: '166px', width: '168px' }} />
      <Typography variant={'h5'}>We're collecting data to benchmark your product against similar products</Typography>
      <Typography variant={'label4'}>
        Benchmarking data is typically available 3-4 weeks after campaign launch.
      </Typography>
    </Stack>
  )
}

import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Stack, Box, Typography, TextField, Autocomplete, styled, createFilterOptions } from '@mui/material'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import React, { useMemo } from 'react'
import { orderBy, keyBy } from 'lodash'

type Option = {
  id: string
  label: string
  companyName: string
  value: string
  image: React.ReactNode
}

const StyledAutocomplete = styled(Autocomplete<Option, false, true, false>)({
  '.MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    background: 'transparent',
    color: 'white',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    paddingRight: '32px !important'
  },
  '.MuiAutocomplete-input': {
    paddingLeft: '16px !important',
    paddingRight: '0px !important'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'white !important',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
    borderWidth: '1px !important',
    borderRadius: '8px'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white'
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important'
  },
  '.MuiAutocomplete-endAdornment': {
    right: '8px'
  }
})

export const BrandSelector = () => {
  const { companies, brands, isAdminManagementView, selectedBrand, setSelectedBrand, selectedCompany, isAdminView } =
    useUserSessionContext()

  const keyedCompanies = keyBy(companies, 'id')

  const companyBrands = brands.filter(b => isAdminManagementView || isAdminView || b.companyId === selectedCompany?.id)

  const options: Option[] = useMemo(
    () =>
      orderBy(
        companyBrands.map(b => ({
          id: b.id,
          label: b.name,
          companyName: isAdminView && b.companyId ? keyedCompanies[b.companyId]?.name : '',
          value: b.id,
          image: (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: 'white',
                borderRadius: '4px'
              }}
              p={'2.4px'}
            >
              <img
                src={getCdnImageUrl(b.logo)}
                alt={'Logo'}
                width={'100%'}
                height={'100%'}
                style={{ objectFit: 'contain' }}
              />
            </Box>
          )
        })),
        c => c.label,
        'asc'
      ),
    [companyBrands]
  )

  const handleChanged = (_e: React.SyntheticEvent, value: Option) => {
    setSelectedBrand(value.value)
  }

  const value = options.find(o => o.value === selectedBrand?.id) ?? null
  return (
    <StyledAutocomplete
      value={value as any}
      onChange={handleChanged}
      data-cy={'brandSelector'}
      disableClearable
      clearOnBlur
      getOptionKey={option => option.id}
      filterOptions={createFilterOptions<Option>({
        ignoreAccents: true,
        ignoreCase: true,
        stringify: option => option.label + ' ' + option.companyName
      })}
      options={options}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={option => option?.label}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack direction={'row'} spacing={1} data-cy={`brandSelector-${option.id}`}>
            {option.image}
            <Stack justifyContent={'flex-start'}>
              <Typography variant={'label3'}>{option?.label}</Typography>
              <Typography variant={'caption2'}>{option?.companyName}</Typography>
            </Stack>
          </Stack>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={'Select brand'}
          InputProps={{ ...params.InputProps, startAdornment: value?.image }}
        />
      )}
    />
  )
}

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ViewComment(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 17.17L18.83 16H4V4H20V17.17ZM20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22V4C22 2.9 21.1 2 20 2Z"
        fill="#324AA8"
      />
      <path
        d="M12 7.25C13.895 7.25 15.585 8.315 16.41 10C15.585 11.685 13.9 12.75 12 12.75C10.1 12.75 8.415 11.685 7.59 10C8.415 8.315 10.105 7.25 12 7.25ZM12 6.25C9.5 6.25 7.365 7.805 6.5 10C7.365 12.195 9.5 13.75 12 13.75C14.5 13.75 16.635 12.195 17.5 10C16.635 7.805 14.5 6.25 12 6.25ZM12 8.75C12.69 8.75 13.25 9.31 13.25 10C13.25 10.69 12.69 11.25 12 11.25C11.31 11.25 10.75 10.69 10.75 10C10.75 9.31 11.31 8.75 12 8.75ZM12 7.75C10.76 7.75 9.75 8.76 9.75 10C9.75 11.24 10.76 12.25 12 12.25C13.24 12.25 14.25 11.24 14.25 10C14.25 8.76 13.24 7.75 12 7.75Z"
        fill="#324AA8"
      />
    </SvgIcon>
  )
}

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LogoWhiteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 18" fill="none">
      <path
        d="M14.3341 11.5902C13.8781 11.5902 13.4408 11.7713 13.1183 12.0938L8.36175 16.8503C7.20606 18.006 5.23001 17.1875 5.23001 15.5531V10.562C5.23001 9.54879 4.40866 8.72744 3.39547 8.72744H2.26009C0.625693 8.72744 -0.192817 6.75139 0.962875 5.5957L5.95409 0.604476C7.10978 -0.551216 9.08583 0.267296 9.08583 1.90169V4.18216C9.08583 5.11152 9.83923 5.86492 10.7686 5.86492C11.2149 5.86492 11.6429 5.68762 11.9585 5.37206L16.7258 0.604761C17.8815 -0.550931 19.8575 0.267577 19.8575 1.90197V4.18196C19.8575 5.11143 20.611 5.86492 21.5405 5.86492C21.9868 5.86492 22.4149 5.68762 22.7305 5.37199L27.4974 0.605019C28.6533 -0.55067 30.6291 0.26784 30.6291 1.90223V6.89279C30.6291 7.90598 31.4507 8.72733 32.4638 8.72733H33.6376C35.2718 8.72733 36.0905 10.7034 34.9346 11.8591L29.9426 16.8512C28.7869 18.0069 26.8109 17.1884 26.8109 15.554V13.2631C26.8109 12.3393 26.062 11.5904 25.1381 11.5904C24.6945 11.5904 24.2691 11.7667 23.9553 12.0804L19.1854 16.8503C18.0297 18.006 16.0536 17.1875 16.0536 15.5531V13.3097C16.0536 12.36 15.2838 11.5902 14.3341 11.5902Z"
        fill="white"
      />
    </SvgIcon>
  )
}

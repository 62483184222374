import ProductSelectorV2 from '@momentum/components/form-inputs/ProductSelector'
import { useBenchmarkContext } from '../context/BenchmarkContext'
import { Box, Grid, Stack } from '@mui/material'
import BenchmarkProductCard from './BenchmarkProductCard'

const BenchmarkProducts = () => {
  const { nonBenchmarkedProducts, benchmarkedProducts } = useBenchmarkContext()

  return (
    <Stack spacing={3}>
      <ProductSelectorV2
        products={nonBenchmarkedProducts}
        disabled={!nonBenchmarkedProducts.length || benchmarkedProducts?.length === 4}
      />
      <Box>
        <Grid container spacing={3}>
          {benchmarkedProducts?.map((product, index) => (
            <Grid key={product.id} item xs={6}>
              <BenchmarkProductCard product={product} index={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  )
}

export default BenchmarkProducts

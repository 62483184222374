import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { InputBase } from '@productwindtom/ui-base'
import { useWatch } from 'react-hook-form'

type Option = { name: string; value: string; inputValue?: string }
const filter = createFilterOptions<Option>()

const SelectInputAdd = ({
  name,
  primaryText,
  options,
  onChange
}: {
  name: string
  primaryText: string
  onChange: (option: Option | null) => void
  options: Option[]
}) => {
  const value = useWatch({ name })

  const selectValue =
    typeof value === 'string' ? options.find(option => option.value === value || option.name === value) || value : value

  return (
    <InputBase name={name} primaryText={primaryText}>
      <Autocomplete
        value={selectValue || ''}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            onChange({
              value: '',
              name: newValue
            })
          } else if (newValue && newValue.name) {
            // Create a new value from the user input
            onChange({
              value: newValue.value,
              name: newValue.inputValue || newValue.name
            })
          } else {
            onChange(null)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          const { inputValue } = params
          // Suggest the creation of a new value
          const isExisting = options.some(option => inputValue === option.name)
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: '',
              inputValue,
              name: `Add "${inputValue}"`
            })
          }

          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Regular option
          // Add "xxx" option created dynamically
          return option.name
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={params => <TextField {...params} />}
      />
    </InputBase>
  )
}

export default SelectInputAdd

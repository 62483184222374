import { GridColDef } from '@mui/x-data-grid-premium'
import { Brand } from './queries'
import { DateTime } from 'luxon'
import { Stack, Typography } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Circle } from '@mui/icons-material'
import TableLink from '@momentum/components/table-link'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'

export enum BrandAlert {
  ENDING_SOON_HIGH = 'Ending in 45 days',
  ENDING_SOON_MEDIUM = 'Ending in 90 days'
}

export type RowData = Brand & {
  companyName?: string
  agencyId?: string
  creditsRemaining: number
  active: boolean
  alert?: BrandAlert
  integration: string
}

export const tableDefinition: GridColDef<RowData>[] = [
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Brand',
    flex: 1,
    renderCell: ({ value, row }) => (
      <TableLink
        to={generatePath(ROUTES.BRAND_SUBSCRIPTION, { brandId: row.id })}
        label={value}
        className={'--two-lines'}
      />
    )
  },
  {
    field: 'active',
    headerName: 'Subscription status',
    width: 128,
    renderCell: ({ value }) => <SubscriptionStatus active={value} />
  },
  {
    field: 'alert',
    headerName: 'Subscription alerts',
    width: 160,
    renderCell: ({ value }) => (value ? <AlertCell alert={value} /> : null)
  },
  {
    field: 'subscriptionStartsAt',
    headerName: 'Subscription start date',
    width: 128,
    valueFormatter: value => (value ? DateTime.fromISO(value).toLocaleString() : undefined)
  },
  {
    field: 'subscriptionEndsAt',
    headerName: 'Subscription end date',
    width: 128,
    valueFormatter: value => (value ? DateTime.fromISO(value).toLocaleString() : undefined)
  },
  {
    field: 'creditsRemaining',
    headerName: 'Credits remaining',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 170
  },
  {
    field: 'integration',
    headerName: 'Integrated status',
    width: 110,
    renderCell: ({ value }) => (
      <Typography variant={'label3'} whiteSpace={'normal'}>
        {value}
      </Typography>
    )
  }
]

const AlertCell = ({ alert }: { alert: BrandAlert }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <WarningAmberIcon color={'warning'} fontSize={'mSmall'} />
      <Typography variant={'label3'}>{alert}</Typography>
    </Stack>
  )
}

const SubscriptionStatus = ({ active }: { active: boolean }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Circle fontSize={'small'} color={active ? 'success' : 'error'} />
      <Typography variant={'label3'}>{active ? 'Active' : 'Inactive'}</Typography>
    </Stack>
  )
}

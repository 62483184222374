export const pluralWord = (word: string, pluralize: boolean = true) => {
  if (pluralize) {
    return word + 's'
  }
  return word
}

export const ellipsisString = (str: string, length: number) => {
  return str.length > length ? str.substring(0, length) + '...' : str
}

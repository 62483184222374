import { IconButton, Stack, Typography } from '@mui/material'
import { FileInput } from '@productwindtom/ui-base'
import { useFormContext, useController } from 'react-hook-form'
import { CheckCircleOutline, Close } from '@mui/icons-material'

export const CsvInput = ({ name }: { name: string }) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange }
  } = useController({ name, control })

  if (!value) {
    return <FileInput accept={'.csv'} name={name} buttonText={'Attach file'} />
  }

  const file = value as File

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
      <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
        {file.name}
      </Typography>
      <IconButton size={'small'} onClick={() => onChange(null)}>
        <Close fontSize={'mSmall'} />
      </IconButton>
    </Stack>
  )
}

// @ts-nocheck
import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_HEADPHONES: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: '7d248798-496d-438f-b2be-d80d506c8354',
    creator: {
      firstName: 'Troy',
      lastName: 'Jackson',
      instagramProfile: null,
      tiktokProfile: {
        handle: 't_jack10',
        engagementRate: 8.22680412371134,
        followers: 119557
      },
      youtubeProfile: {
        handle: 't_jack10',
        engagementRate: null,
        followers: 11800
      }
    },
    draftContent: [
      {
        id: 'c6a06450-cf79-4e25-9f65-37c11013badb',
        channel: 'TIKTOK',
        contentType: 'TT_VIDEO',
        draftSubmittedAt: '2024-01-03T22:46:13.142Z',
        draftStatus: 'REQUIRES_BRAND_APPROVAL',
        draftMedia: ['draft-media/7d248798-496d-438f-b2be-d80d506c8354/2071a32e-344e-4f78-b094-a65ff284529f.mp4'],
        draftText:
          'Since when??? No longer have to play with one headphone on and off 😂 #PanasonicGaming #BrandPartner @Panasonic',
        isHidden: null,
        reviewFeedback: [
          {
            message: '*Lada sent back',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2024-01-05T15:32:13.379Z'
          }
        ],
        reviewedAt: '2024-01-05T15:32:13.379Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  },
  {
    id: '812bc2aa-f86c-4dd5-9ebf-20a414197a5e',
    creator: {
      firstName: 'Tyy',
      lastName: 'Rex',
      instagramProfile: {
        handle: 'tyyrexofficial',
        engagementRate: 0.5079984393289114,
        followers: 25630
      },
      tiktokProfile: {
        handle: 'tyyrexofficial',
        engagementRate: 10.745355390208758,
        followers: 44893
      },
      youtubeProfile: {
        handle: 'tyy_rex',
        engagementRate: null,
        followers: 1070
      }
    },
    draftContent: [
      {
        id: 'a6b1dfee-5238-4c60-b8a7-4eed0ad9dc34',
        channel: 'INSTAGRAM',
        contentType: 'IG_REEL',
        draftSubmittedAt: '2024-01-07T01:10:11.846Z',
        draftStatus: 'APPROVED',
        draftMedia: ['draft-media/812bc2aa-f86c-4dd5-9ebf-20a414197a5e/b59987da-8cd9-4a35-84b3-1b3b22384ac5.mp4'],
        draftText:
          '#brandpartner How it feels to game with the Panasonic Sound Slayer 👾😅\n\nThis is the most immersive sound system I’ve ever used for gaming. It has so many unique qualities that blow the competition out of the water including light weight, flexible design that lets me FINALLY play games while I’m wearing my glasses without the headache that I always get with an over the ear headset.\n\nIt also sports a noise and echo cancelling dual microphone so I could clearly communicate with teammates while I ate cheezits and they couldn’t even hear the cronches!\n\nIt also has 6 audio modes to match the vibe of whatever game you’re playing. I was stoked about FPS mode, which made it so I was able to hear which direction footsteps were coming from in Call of Duty! You can even use the speaker to watch movies and I obviously had to watch my favorite (Godzilla) which felt like I was watching it in the theatre all over again.\n\nThis is the perfect gift for any gamer, no matter how cozy or intense. \n\nUse the link in my story to check it out! @panasonicgaming.na',
        isHidden: null,
        reviewFeedback: [
          {
            message: '',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2024-01-15T22:01:39.250Z'
          }
        ],
        reviewedAt: '2024-01-15T22:01:39.250Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  },
  {
    id: '4dd3dc73-37ff-42ef-b12e-d1c227e88bd4',
    creator: {
      firstName: 'Powell',
      lastName: 'Nguyen',
      instagramProfile: {
        handle: 'powhalen',
        engagementRate: 8.720262510254306,
        followers: 1219
      },
      tiktokProfile: {
        handle: 'pow.tech',
        engagementRate: 2.353607290073021,
        followers: 34169
      },
      youtubeProfile: null
    },
    draftContent: [
      {
        id: '499db7de-8350-4dcb-a910-b5c7b6df3ad9',
        channel: 'TIKTOK',
        contentType: 'TT_VIDEO',
        draftSubmittedAt: '2024-01-04T07:56:29.517Z',
        draftStatus: 'APPROVED',
        draftMedia: ['draft-media/4dd3dc73-37ff-42ef-b12e-d1c227e88bd4/0585397a-8418-4c31-afa8-602106042c09.mp4'],
        draftText:
          'No more headset hair!! You can find it at shop.panasonic.com (: @panasonic #brandpartner #panasonic #panasonicgaming #techtok #gaming #audio',
        isHidden: null,
        reviewFeedback: [
          {
            message: '*Lada sent back',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2024-01-05T15:33:10.902Z'
          }
        ],
        reviewedAt: '2024-01-05T15:33:10.903Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  },
  {
    id: '77d97edc-79a1-4185-81cb-c0d9058053e5',
    creator: {
      firstName: 'Cam',
      lastName: 'Edwards',
      instagramProfile: null,
      tiktokProfile: {
        handle: 'cleonxtdooor',
        engagementRate: 9.221902017291066,
        followers: 16763
      },
      youtubeProfile: {
        handle: 'cleonxtdoor',
        engagementRate: null,
        followers: 358
      }
    },
    draftContent: [
      {
        id: 'ec384227-bbfb-48a2-b485-70db2133e21e',
        channel: 'TIKTOK',
        contentType: 'TT_VIDEO',
        draftSubmittedAt: '2023-12-07T23:41:14.709Z',
        draftStatus: 'REQUIRES_USER_CHANGES',
        draftMedia: ['draft-media/77d97edc-79a1-4185-81cb-c0d9058053e5/67497ca9-6515-497b-8703-920a89bdf87e.mp4'],
        draftText: '@panasonic SoundSlayer speaker system #brandpartner #gaming #gamingtech Link is in my bio!',
        isHidden: null,
        reviewFeedback: [
          {
            message:
              'He says "bluetooth transmitter" but it\'s not bluetooth (it\'s a wireless transmitter). Also need to tag "@panasonicgaming.na" not "@panasonic". Please ensure the link is to shop.panasonic.com, not to panasonic.com',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2023-12-11T21:38:18.637Z'
          }
        ],
        reviewedAt: '2023-12-11T21:38:18.638Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  },
  {
    id: 'ed190bf1-dbe3-484d-af60-1567b5b1b386',
    creator: {
      firstName: 'Taylor',
      lastName: 'Cortis',
      instagramProfile: {
        handle: 'tslayz',
        engagementRate: 0.5634318739909645,
        followers: 73709
      },
      tiktokProfile: {
        handle: 'tslayz',
        engagementRate: 4.7361299052774015,
        followers: 115052
      },
      youtubeProfile: {
        handle: 'tslayz',
        engagementRate: null,
        followers: null
      }
    },
    draftContent: [
      {
        id: 'e2041e8d-69f1-4c34-9ff7-e8d843ada251',
        channel: 'INSTAGRAM',
        contentType: 'IG_REEL',
        draftSubmittedAt: '2023-12-08T23:00:25.449Z',
        draftStatus: 'APPROVED',
        draftMedia: ['draft-media/ed190bf1-dbe3-484d-af60-1567b5b1b386/5cc9dad5-2c75-4d1a-8417-68a9c3fa29d5.mp4'],
        draftText:
          'Here’s a first look at the @panasonicgaming.na Soundslayer Wireless Wearable Speaker System! #BrandPartner \n\nEnjoy incredible lag free gaming audio with 6 different sound modes! \n\nShop Now on Panasonic.com ',
        isHidden: null,
        reviewFeedback: [
          {
            message: 'We need to update the CTA to "shop.panasonic.com", not "Panasonic.com"',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2023-12-11T21:30:46.828Z'
          }
        ],
        reviewedAt: '2023-12-11T21:30:46.828Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  },
  {
    id: '74a53c9e-87a0-4872-838f-f1f1bc3c44a4',
    creator: {
      firstName: 'Vinz',
      lastName: 'Leonardo',
      instagramProfile: {
        handle: 'vinzleonardo',
        engagementRate: 0.03630119447498833,
        followers: 66389
      },
      tiktokProfile: {
        handle: 'vinzleonardo',
        engagementRate: 1.5984180604762297,
        followers: 14921
      },
      youtubeProfile: {
        handle: 'vinzleonardo',
        engagementRate: null,
        followers: null
      }
    },
    draftContent: [
      {
        id: '67a71604-4b7d-408c-bf29-9b69243883da',
        channel: 'INSTAGRAM',
        contentType: 'IG_REEL',
        draftSubmittedAt: '2023-12-06T20:07:13.975Z',
        draftStatus: 'APPROVED',
        draftMedia: ['draft-media/74a53c9e-87a0-4872-838f-f1f1bc3c44a4/4412f22f-eefb-488a-b431-f8dda86b79cc.mp4'],
        draftText:
          'Lovin my new wearable speaker system from @panasonicgaming.na\n\nThis is SoundSlayer. Whether it be for gaming or listening to music, this is your audio companion. Feels like youre in the virtual world! The audio quality is great.\nSo if youre looking for a gift to yourself or loved ones this holiday season, look no further!\n-\n#brandpartner',
        isHidden: null,
        reviewFeedback: [
          {
            message: '',
            forAssetAtIndex: null,
            atTime: null,
            createdByFirstName: null,
            createdByLastName: null,
            visibleToUser: false,
            createdAt: '2023-12-11T21:28:51.097Z'
          }
        ],
        reviewedAt: '2023-12-11T21:28:51.097Z',
        reviewerFirstName: null,
        reviewerLastName: null
      }
    ]
  }
]

import { ApprovalConfig } from '@productwindtom/shared-user-roles'
import {
  BrandApiType,
  CampaignBadgeType,
  CreationSourceVersion,
  GraphQLTypes,
  PromoCodeType,
  RequiredProductLinkFlag,
  ShopifyPromotionType,
  SocialChannel,
  SocialContentType,
  SocialQuality,
  StatusTier,
  WSLocale,
  CampaignGoal
} from '@productwindtom/shared-ws-zeus-types'
import { DateTime } from 'luxon'

export interface CampaignConfiguration {
  id: string
  existingResource?: boolean
  creationSource?: CreationSourceVersion

  title?: string
  brand?: BrandV2
  startDate: DateTime
  endDate?: DateTime
  sellingCountry?: WSLocale
  goal?: CampaignGoal
  storeType?: StoreType
  attributionTag?: string
  payForPerformanceEnabled?: boolean
  payForPerformanceCommissionFlatRate?: number
  enableContentComplianceReview?: boolean
  committedClicks?: number

  product?: CampaignProductTypes
  productVariationSkus?: string[]
  sponsorshipFlows: SponsorshipFlow[]

  visible?: CampaignVisibility
  discoverPlacementRanking?: number
  applicationProductPageUrl?: string
  applicationProductCategory?: string
  applicationProductMainImageKey?: string
  applicationProductMainUgcImageKey?: string
  applicationDescription?: string
  displayBadges?: BadgeConfiguration[]

  inspirationalContent?: InspirationalContent | null

  contentCompleteDeadline?: DateTime

  referralBonus?: number
  applicationForm?: any
  requiredProductLinksEnabled?: RequiredProductLinkFlag

  productCost?: number
  marketingFee?: number

  earlyReviewEnabled?: boolean | null
  totalEarlyReviewsAllowed?: number | null

  closeoutReleaseRate?: GraphQLTypes['RateInput']

  socialCommittedContentCount?: number
  lifestyleCommittedContentCount?: number
  premiumLifestyleCommittedContentCount?: number
  committedReviewsCount?: number
  committedPlacementWinsCount?: number
}

export interface BrandV2 {
  id?: string
  name: string
  brandInstaHandle?: string
  brandTikTokHandle?: string
  etailers?: BrandEtailer[]
  nameAudioKey?: string
}

export interface BrandEtailer {
  etailer: BrandApiType
  locale?: WSLocale
  enabled?: boolean

  shop?: string
  shopId?: string

  // Helper variable to determine if the underlying resources already exists (BrandApi)
  existing?: boolean
}

export interface InspirationalContentType {
  fileUrls: string[]
  inputUrl: string
}

export interface InspirationalContent {
  [SocialChannel.TIKTOK]: InspirationalContentType
  [SocialChannel.INSTAGRAM]: InspirationalContentType
  [SocialChannel.UGC]: InspirationalContentType
}

// underlying data model in database is SKU
export interface CampaignProduct {
  id: string
  previousId?: string // Used for switching products
  existingResource?: boolean
  type?: 'app' | 'ecommerce' | 'website' | 'in_store'
  active?: string

  //Common to all
  name: string
  sellingPrice?: number
  // creators?: number;

  primaryImageKey?: string

  requiredProductLinkEnabled?: RequiredProductLinkFlag
  productLinkId?: string

  skuId?: string
  childSkus?: string[]
}

export interface EcommerceCampaignProduct extends CampaignProduct {
  type: 'ecommerce'
  //E-commerce
  listingTitle?: string
  listingUrl?: string
  nameAudioKey?: string

  nonSponsoredAdImageKey?: string

  etailer?: Etailer
  sellingCountry?: WSLocale

  skuId?: string
  parentSku?: string
  childSkus?: string[]
  isBundle?: boolean

  ignoreMccCode?: boolean

  shippingCost?: number
  minimumOrderQuantity?: number
}

export interface AppCampaignProduct extends CampaignProduct {
  type: 'app'
  //App
  appStoreId?: string
  appStoreProvider?: string
  nameAudioKey?: string
}

export interface WebsiteCampaignProduct extends CampaignProduct {
  type: 'website'
  skuId?: string
  //Website/service
  websiteUrl?: string
  nameAudioKey?: string
}

export interface InStoreCampaignProduct extends CampaignProduct {
  type: 'in_store'
  skuId?: string
  listingTitle?: string
  etailer?: Etailer.IN_STORE
  nameAudioKey?: string
}

export type CampaignProductTypes =
  | EcommerceCampaignProduct
  | AppCampaignProduct
  | WebsiteCampaignProduct
  | InStoreCampaignProduct

export interface SponsorshipFlow {
  existingResource?: boolean
  isDuplicate?: boolean
  id?: string
  name?: string
  description?: string
  skuId?: string
  sequenceId?: string
  isPremium?: boolean
  visibleAt?: DateTime | string
  visibleEndsAt?: DateTime | string

  creatorsAllocated?: number
  liftCreatorsEnabled?: boolean
  liftCreatorCount?: number

  isQualityRequirementsEnabled?: boolean
  impressionsInstagramRangeRequirement?: { min?: number; max?: number }
  impressionsTiktokRangeRequirement?: { min?: number; max?: number }
  engagementRateInstagramRangeRequirement?: { min?: number; max?: number }
  engagementRateTiktokRangeRequirement?: { min?: number; max?: number }
  followersInstagramRangeRequirement?: { min?: number; max?: number }
  followersTiktokRangeRequirement?: { min?: number; max?: number }

  //Receiving product
  receiveMethod?: ReceiveProductMethod
  findMethod?: FindProductMethod
  purchaseMethod?: PurchaseProductMethod
  purchasePromoCode?: string
  purchasePromoCodeUrl?: string
  purchasePromoCodeDiscountPercentage?: number
  purchasePromoCodeInstructions?: string
  specialGuidance?: string
  validateProductFound?: boolean
  validateProductFoundVariations?: string[]
  autoApproveCreators?: boolean
  autoApproveCreatorsStatusTiers?: StatusTier[]

  receivingProductBonus?: number

  //Content
  contentRequired?: boolean
  contentRequirements?: ContentRequirementConfiguration[]
  contentRequirementGuidelines?: FlowContentGuidance[]
  contentTalkingPoints?: FlowContentGuidance[]
  contentApprovalConfiguration?: Omit<ApprovalConfig, 'name'>[]
  stagingApprovalConfiguration?: (Omit<ApprovalConfig, 'name'> | undefined)[]
  isStagingApprovalAllCreatorsClientReviewed?: 'TRUE' | 'FALSE'
  stagingApprovalRequiredNumCreatorsClientReviewed?: number

  marketingBrief?: string
  marketingBriefFileKey?: string
  durationToPostDays?: number
  durationToSubmitDraftDays?: number
  postingHashtags?: string[]
  ftcHashtags?: string[]
  instaHandle?: string
  tikTokHandle?: string
  sampleContentUrls?: string[]
  impressionsConfiguration?: ImpressionsConfiguration
  promoCodeConfiguration?: PromoCodeConfiguration

  //Closeout
  closeoutQuestions?: CloseoutConfiguration
  closeoutBonus?: number

  autoApprovalV2Config?: AutoApprovalV2Config

  //Pay for posting content early - We use flat structure instead of nested because we need to use durationToPostDays in the validations
  payForPostingEarlyEnabled?: boolean
  payForPostingEarlyDaysBeforeDeadline?: number
  payForPostingEarlyBonus?: number | null

  applicationClicksRequired?: boolean
  numApplicationClicksRequired?: number

  requiredProductLinkEnabled?: RequiredProductLinkFlag | null
  productLinkId?: string | null
  productLink?: GraphQLTypes['UserLink'] | null

  internalNotes?: string | null
  internalCreatorsStagingGuidance?: string | null

  requestEarlyProductFeedback?: boolean | null
  overrideReviewLink?: boolean | null
  nonRetailReviewLink?: string | null
  nonRetailReviewWebsiteName?: string | null

  stagingSocialQualityRequirement?: SocialQuality
}

export interface AutoApprovalV2Config {
  enabled?: boolean
  autoApprovalCriteria?: AutoApprovalCriteria
}

export interface AutoApprovalCriteria {
  completedCampaigns?: number
}

export interface CloseoutConfiguration {
  leaveReview?: boolean
  requestProductFeedback?: boolean
}

export interface PromoCodeConfiguration {
  promoCodeType?: PromoCodeType
  promoCode?: string
  promoCodeUrl?: string
  promoCodeUrlId?: string
  promoCodeDetails?: string

  promoIds?: string[] // Shopify
  shopifyPromoCodeType?: ShopifyPromotionType
  shopifyPromoPercentageOff?: number
  shopifyPromoAmountOff?: number
  // Later support (currently cannot put this here since p4p is on Campaign level
  // payForPerformanceEnabled?: boolean;
  // payForPerformanceBonusConfiguration?: StatusTierBonusConfiguration;
}

// Flow level content guidance with data for which content type and instance number it applies to
export type FlowContentGuidance = {
  text: string
  contentTypes: {
    instance: number
    type: SocialContentType
  }[]
}

export interface Badge {
  enabled?: boolean
  type: CampaignBadgeType
  value: string
}

export interface ContentRequirementConfiguration {
  type?: SocialContentType
  count?: number
  required?: boolean
  requiresBrandReview?: boolean
  bonusConfiguration?: StatusTierBonusConfiguration
}

export interface StatusTierBonusConfiguration {
  baseBonus?: number
  silverBonus?: number
  goldBonus?: number
  platinumBonus?: number
  diamondBonus?: number
}

export interface ImpressionsConfiguration {
  enabled: boolean
  impressionsBaseline?: number
  impressionsCap?: number
  impressionsIncrement?: number
  initialBonus?: number
  bonusGrowthRate?: number
}

export interface BadgeConfiguration {
  enabled?: boolean
  type?: CampaignBadgeType
  value?: string
}

export enum ReceiveProductMethod {
  SELF_PURCHASE = 'SELF_PURCHASE',
  DIRECT_SHIPPING = 'DIRECT_SHIPPING',
  DOWNLOAD = 'DOWNLOAD'
}

export enum FindProductMethod {
  SEARCH_KEYWORD = 'SEARCH_KEYWORD',
  LINK_PROMO_CODE = 'LINK_PROMO_CODE',
  LINK_TO_PRODUCT = 'LINK_TO_PRODUCT',
  IN_STORE = 'IN_STORE'
}

export enum PurchaseProductMethod {
  PRE_PAID_CARD = 'PRE_PAID_CARD',
  PROMO_WITH_CARD = 'PROMO_WITH_CARD',
  PROMO_NO_CARD = 'PROMO_NO_CARD'
}

export enum StoreType {
  E_COMMERCE = 'E_COMMERCE',
  IN_STORE = 'IN_STORE',
  IOS_APP = 'IOS_APP',
  WEBSITE_SERVICE = 'WEBSITE_SERVICE'
}

export enum Etailer {
  AMAZON = 'AMAZON',
  WALMART = 'WALMART',
  KROGER = 'KROGER',
  TARGET = 'TARGET',
  BESTBUY = 'BESTBUY',
  COSTCO = 'COSTCO',
  DTC_SHOPIFY = 'DTC_SHOPIFY',
  DTC_NOT_SHOPIFY = 'DTC_NOT_SHOPIFY',
  IN_STORE = 'IN_STORE',
  OTHER = 'OTHER'
}

export enum FlowTypes {
  SOCIAL_WITH_REVIEW = 'modularSocialContent_withReview_v1',
  SOCIAL_WITHOUT_REVIEW = 'modularSocialContent_noReview_v1',
  SOCIAL_WITHOUT_PURCHASE_WITHOUT_REVIEW = 'modularSocialContent_noPurchase_noReview_v1',
  NO_CONTENT = 'no_content_v1'
}

export enum CampaignVisibility {
  VISIBLE = 'true',
  VISIBLE_TO_APPLICANTS = 'visible_to_applicants',
  VISIBLE_TO_ADMINS_ONLY = 'visible_to_admins',
  NOT_VISIBLE = 'false'
}

import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { SAMPLE_CAMPAIGN_PERFORMANCE } from '@momentum/api/interceptors/getCampaignPerformance/sample-data'

export const updateCampaignPerformanceProduct: ZeusInterceptor<
  'mutation',
  'updateCampaignPerformanceProduct'
> = async args => {
  const params = args.o.updateCampaignPerformanceProduct?.[0] as {
    input: ValueTypes['ModelInputUpdateCampaignPerformanceProduct']
  }

  const data = SAMPLE_CAMPAIGN_PERFORMANCE[params.input.campaignId!]
  if (data) {
    const product = (data?.products || []).find(p => p.id === params.input.id)
    return { ...product, ...params.input } as any
  }
}

import { chain, head } from 'lodash'

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined
}

export const findMostCommonValue = <TObj, TKey extends keyof TObj, TOut extends TObj[TKey] | undefined>(
  list: TObj[],
  key: TKey
): TOut => {
  return head(
    chain(list)
      .map(l => l[key])
      .filter(notEmpty)
      .groupBy()
      .values()
      .maxBy(v => v.length)
      .value()
  ) as TOut
}

export const stripLeadingEmpty = <T>(arr: T[]) => {
  let index = 0
  while (index < arr.length && !arr[index]) {
    index++
  }
  return arr.slice(index)
}

export const stripTrailingEmpty = <T>(arr: T[]) => {
  let index = arr.length - 1
  while (index >= 0 && !arr[index]) {
    index--
  }
  return arr.slice(0, index + 1)
}

export const stripEmpty = <T>(arr: T[]) => stripTrailingEmpty(stripLeadingEmpty(arr))

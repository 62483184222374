import { Stack } from '@mui/system'
import { Typography } from '@mui/material'

export const EmptyState = () => {
  return (
    <Stack alignItems={'center'} mt={12}>
      <Typography
        variant={'label2'}
        color={theme => theme.palette.grey.A700}
        textAlign={'center'}
        alignItems={'center'}
        width={400}
      >
        Your automated campaign recommendations will appear here.
      </Typography>
    </Stack>
  )
}

import { Stack } from '@mui/material'
import { Summary } from './components/Summary'
import EmptyState from './components/EmptyState'
import { useCampaignContext } from '../../context/CampaignContext'
import DraftContentList from './components/DraftContentList'

const ApproveContent = () => {
  const { campaignContentForApproval } = useCampaignContext()

  return (
    <Stack spacing={4}>
      {campaignContentForApproval?.length ? (
        <>
          <Summary />
          <DraftContentList />
        </>
      ) : (
        <EmptyState />
      )}
    </Stack>
  )
}

export default ApproveContent

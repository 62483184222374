import { Typography, Stack } from '@mui/material'
import BonusIcon from '@momentum/components/icons/bonus'
import React from 'react'

export const CreditDisplay = ({
  value,
  text = 'Credits',
  color = 'primary',
  ...rest
}: {
  value?: string
  text?: string
  color?: 'primary' | 'secondary'
}) => {
  return (
    <Stack sx={{ borderLeft: theme => `8px solid ${theme.palette[color].main}`, pl: 1 }}>
      <Stack direction={'row'} spacing={1}>
        <BonusIcon />
        <Typography variant={'h3'} {...rest}>{value}</Typography>
      </Stack>
      <Typography variant={'subtitle2'} color={theme => theme.palette.grey.A700}>
        {text}
      </Typography>
    </Stack>
  )
}

import { currentProfile } from '@momentum/api'
import { DateTime } from 'luxon'
import { ZeusInterceptor } from '../types'
import { isSampleContent } from '../utils'

export const requestContentEdits: ZeusInterceptor<'mutation', 'requestContentEdits'> = async args => {
  const id = args.o.requestContentEdits?.[0].input.userCampaignSocialContentId
  if (id && isSampleContent(id)) {
    return [
      {
        atTime: args.o.requestContentEdits?.[0].input.atTime!,
        createdAt: DateTime.now().toISO()!,
        createdByFirstName: currentProfile.firstName!,
        createdByLastName: currentProfile.lastName!,
        forAssetAtIndex: args.o.requestContentEdits?.[0].input.forAssetAtIndex!,
        message: args.o.requestContentEdits?.[0].input.message!,
        visibleToUser: args.o.requestContentEdits?.[0].input.visibleToUser!
      }
    ]
  }
}

import { MEDIA_CDN } from '@momentum/env'
import useUploadFile from '@momentum/hooks/useUploadFile'
import { LoadingButton } from '@mui/lab'
import { Box, ButtonProps } from '@mui/material'
import { UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { InputBase, VariantInputBaseProps } from '@productwindtom/ui-base'
import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

interface ImageSelectProps extends VariantInputBaseProps {
  uploadType: UploadRequestType
  companyId?: string
  agencyId?: string
  campaignId?: string
  brandId?: string
  productId?: string
  buttonText?: string
  replacementText?: string
  readOnly?: boolean
  buttonProps?: ButtonProps
}

export const ImageSelect = ({
  uploadType,
  companyId,
  agencyId,
  campaignId,
  brandId,
  productId,
  buttonText = 'Add',
  replacementText = 'Replace',
  buttonProps,
  readOnly,
  ...rest
}: ImageSelectProps) => {
  const { control } = useFormContext()
  const { name } = { ...rest }
  const {
    field: { onChange, onBlur, value }
  } = useController({ name, control })
  const [processing, setProcessing] = React.useState<boolean>(false)
  const brandLogoRef = React.createRef<HTMLInputElement>()

  const uploadFile = useUploadFile()

  const handleImagePick = async (file: File) => {
    setProcessing(true)
    const key = await uploadFile(file, uploadType, { companyId, campaignId, brandId, agencyId, productId })
    onChange(key)
    onBlur()
    setProcessing(false)
  }

  return (
    <InputBase {...rest}>
      {!!value && (
        <Box>
          <img
            src={value.startsWith('http') ? value : `${MEDIA_CDN}${value}`}
            alt={'logo'}
            style={{ width: 140, height: 140, objectFit: 'contain' }}
          />
        </Box>
      )}
      {!readOnly && (
        <Box>
          <LoadingButton
            loading={processing}
            disabled={processing}
            onClick={() => brandLogoRef?.current?.click()}
            {...buttonProps}
          >
            {value ? replacementText : buttonText}
          </LoadingButton>
        </Box>
      )}
      <input
        style={{ display: 'none' }}
        accept={'image/jpeg, image/png'}
        ref={brandLogoRef}
        value={''}
        onChange={event => {
          if (event.target.files && event.target.files.length > 0) {
            setProcessing(true)
            handleImagePick(event.target.files[0])
          }
        }}
        type="file"
      />
    </InputBase>
  )
}

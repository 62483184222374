import { Stack, ButtonGroup, Button } from '@mui/material'
import { useReviewsContext, TopLevelViewType } from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'

export const TopLevelToggle = () => {
  const { topLevelViewType, setTopLevelViewType } = useReviewsContext()
  return (
    <Stack direction={'row'} justifyContent={'flex-end'}>
      <ButtonGroup
        color="primary"
        variant="outlined"
        size="medium"
        disableRipple
        disableElevation
        style={{
          alignSelf: 'flex-end'
        }}
      >
        <Button
          variant={topLevelViewType === TopLevelViewType.MOMENTUM ? 'contained' : 'outlined'}
          value={TopLevelViewType.MOMENTUM}
          onClick={() => setTopLevelViewType(TopLevelViewType.MOMENTUM)}
        >
          From Momentum
        </Button>
        <Button
          variant={topLevelViewType === TopLevelViewType.LISTING ? 'contained' : 'outlined'}
          value={TopLevelViewType.LISTING}
          onClick={() => setTopLevelViewType(TopLevelViewType.LISTING)}
        >
          Total on listing
        </Button>
      </ButtonGroup>
    </Stack>
  )
}

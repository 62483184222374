import { Line } from 'react-chartjs-2'
import { useBenchmarkContext } from '../context/BenchmarkContext'
import { Box, Stack, useTheme } from '@mui/material'
import { get, keyBy, last } from 'lodash'
import { BENCHMARK_PRODUCT_COLORS, BENCHMARK_TYPE_TO_VALUE } from '../constants'
import useResizing from '@momentum/hooks/useResizing'
import Loading from '@momentum/components/loading'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { DateTime } from 'luxon'
import { EtailerSkuMetrics } from '../queries'
import { useEffect, useMemo, useState } from 'react'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import Row from '@momentum/components/row'
import BenchmarkGraphToggle from './BenchmarkGraphToggle'
import { SwitchInput } from '@productwindtom/ui-base'
import { useWatch } from 'react-hook-form'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'

const BenchmarkGraph = () => {
  const theme = useTheme()
  const { products } = useBrandContext()
  const { graphMetrics, promotedProductId } = useBenchmarkContext()
  const { campaignDetails } = useCampaignContext()
  const hideChart = useResizing()
  const [enabledBenchmarkProducts, setEnabledBenchmarkProducts] = useState(graphMetrics?.benchmarkedProductIds || [])

  const startFromAvailabilityDate = useWatch({
    name: 'startFromAvailabilityDate'
  })

  useEffect(() => {
    setEnabledBenchmarkProducts(graphMetrics?.benchmarkedProductIds || [])
  }, [graphMetrics?.benchmarkedProductIds])

  const metrics = useMemo(() => {
    if (graphMetrics) {
      const { data, metricType } = graphMetrics

      const metricKey = BENCHMARK_TYPE_TO_VALUE[metricType]

      const aggregatedData = Object.entries(data).map(([skuId, metrics]) => ({
        data: aggregateData(
          metrics,
          skuId,
          startFromAvailabilityDate && products?.find(product => product.id === skuId)?.availabilityDate
        ),
        skuId
      }))

      const promotedProductData = aggregatedData.find(({ skuId }) => skuId === promotedProductId)
      const maxDays = promotedProductData?.data?.length || 0

      const labels = Array.from({ length: maxDays }, (_, i) => `Day ${i}`)

      const datasets = Object.values(aggregatedData).map(({ data, skuId }, index) => ({
        data: data.map(item => {
          const value = item[metricKey as keyof typeof item] as number

          return metricType === BenchmarkType.REVENUE && value ? value / 100 : value
        }),
        rawData: data,
        skuId: skuId,
        borderColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        pointBorderColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        pointBackgroundColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        ...(skuId === promotedProductId && {
          segment: {
            borderColor: function (context: any) {
              const date = data[context.p0DataIndex].date
              const endDate = campaignDetails.endDate || DateTime.now().toISO()

              return date >= campaignDetails.startDate && endDate >= date
                ? get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])
                : `${get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])}80`
            }
          }
        })
      }))

      return {
        labels,
        datasets
      }
    }
  }, [campaignDetails, graphMetrics, promotedProductId, theme, products, startFromAvailabilityDate])

  const datasets = metrics?.datasets
  const visibleDataSets = useMemo(() => {
    return datasets?.map(dataset => ({
      ...dataset,
      hidden: !enabledBenchmarkProducts.includes(dataset.skuId)
    }))
  }, [datasets, enabledBenchmarkProducts])

  if (!metrics || !graphMetrics) return null

  const { metricType, metricGoal } = graphMetrics
  const { labels } = metrics

  return (
    <Stack>
      <Row spacing={2}>
        {datasets?.map(({ data, skuId }, index) => {
          const isEnabled = enabledBenchmarkProducts.includes(skuId)
          return (
            <BenchmarkGraphToggle
              key={skuId}
              metricGoal={metricGoal}
              index={index}
              metricType={metricType}
              onToggle={() =>
                setEnabledBenchmarkProducts(prev => (isEnabled ? prev.filter(id => id !== skuId) : [...prev, skuId]))
              }
              data={data}
              isPromotedProduct={skuId === promotedProductId}
              enabled={enabledBenchmarkProducts.includes(skuId)}
            />
          )
        })}
      </Row>
      {!hideChart ? (
        <Box position={'relative'}>
          <Box mt={2}>
            <SwitchInput
              name="startFromAvailabilityDate"
              size="small"
              controlLabelProps={{
                label: startFromAvailabilityDate ? 'Start from Availability Date' : 'Start from first sale'
              }}
            />
          </Box>
          <Line
            data={{
              labels,
              datasets: visibleDataSets || []
            }}
            options={{
              elements: { point: { radius: 0, hitRadius: 10 } },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  ...defaultTooltipOptions,

                  callbacks: {
                    label: (item: any) => {
                      const dataItem = item?.dataset?.rawData?.[item.dataIndex]
                      const value = item.formattedValue

                      return [`${value}`, `${dataItem.date}`]
                    },
                    title: () => '',
                    footer: () => ''
                  }
                }
              },
              scales: {
                y: {
                  max: metricGoal,
                  beginAtZero: true,
                  grid: {
                    drawOnChartArea: false,
                    drawTicks: false
                  },
                  offset: true,
                  ticks: {
                    font: {
                      family: theme.typography.fontFamily as string,
                      weight: theme.typography.fontWeightMedium as number,
                      size: theme.typography.subtitle2.fontSize as number
                    },
                    color: 'black',
                    padding: 16
                  },
                  border: {
                    color: 'black',
                    width: 3
                  }
                },
                x: {
                  ticks: {
                    minRotation: 45,
                    padding: 16,
                    font: {
                      family: theme.typography.fontFamily as string,
                      weight: theme.typography.fontWeightMedium as number,
                      size: theme.typography.subtitle2.fontSize as number
                    }
                  },

                  grid: {
                    drawOnChartArea: false,
                    drawTicks: false
                  },
                  border: {
                    color: 'black',
                    width: 3
                  }
                }
              }
            }}
          />
        </Box>
      ) : (
        <Box height={'560px'} width={'100%'}>
          <Loading />
        </Box>
      )}
    </Stack>
  )
}

export default BenchmarkGraph

const aggregateData = (metrics: EtailerSkuMetrics[], skuId: string, availabilityDate?: string) => {
  if (!metrics.length) return []

  let aggregatedUnitsSold = 0
  let aggregatedTotalSalesAmount = 0
  let aggregatedPageViews = 0

  const startDate = DateTime.fromISO(availabilityDate || metrics[0].date)
  const endDate = DateTime.fromISO(last(metrics)?.date || DateTime.now().toISO())
  const metricsByDate = keyBy(metrics, 'date')

  return Array.from({ length: Math.round(endDate.diff(startDate, 'days').days + 2) }, (_, i) => {
    const currentDate = startDate.plus({ days: i })
    const existingMetric = metricsByDate[currentDate.toISODate()!]

    if (existingMetric) {
      aggregatedUnitsSold += existingMetric.unitsSold || 0
      aggregatedTotalSalesAmount += existingMetric.totalSalesAmount || 0
      aggregatedPageViews += existingMetric.pageViews || 0

      return {
        ...existingMetric,
        unitsSold: aggregatedUnitsSold,
        totalSalesAmount: aggregatedTotalSalesAmount,
        pageViews: aggregatedPageViews
      }
    } else {
      return {
        skuId,
        date: currentDate.toISODate()!,
        unitsSold: aggregatedUnitsSold,
        totalSalesAmount: aggregatedTotalSalesAmount,
        pageViews: aggregatedPageViews
      }
    }
  })
}

import { Image, View } from '@react-pdf/renderer'
import { FeedbackPDFProps } from '.'
import { styles } from './styles'

export const FeedbackDoughnutPDF = ({ chartBase64Image }: FeedbackPDFProps) => {
  return (
    <View
      style={[
        styles.debug,
        {
          marginLeft: 10
        }
      ]}
    >
      <Image
        source={chartBase64Image}
        style={{
          width: 193,
          height: 193
        }}
      />
    </View>
  )
}

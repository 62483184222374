import { SvgIcon, SvgIconProps } from '@mui/material'

export default function SocialPortraitIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 10C13.151 10 14 9.152 14 8C14 6.848 13.151 6 12 6C10.85 6 10 6.848 10 8C10 9.152 10.85 10 12 10ZM12 11C9.791 11 8 12.612 8 14.6V14.986H16V14.6C16 12.612 14.209 11 12 11Z"
        fill="currentColor"
      />
      <path
        d="M19 2H5C3.897 2 3 2.897 3 4V17C3 18.103 3.897 19 5 19H9L12 22L15 19H19C20.103 19 21 18.103 21 17V4C21 2.897 20.103 2 19 2ZM14 17L12 19L10 17H5V4H19L19.002 17H14Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

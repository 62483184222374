import { Box, Stack, Theme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { SxProps } from '@mui/system/styleFunctionSx'

type BoxToggleProps = {
  value: boolean
  color: string
  maxWidth?: string
  readonly?: boolean
  hideClose?: boolean
  padding?: number
  sx?: SxProps<Theme>
  onChange: (value: boolean) => void
  children: React.ReactNode
}

export const BoxToggle = ({
  value,
  readonly,
  hideClose,
  children,
  color,
  onChange,
  padding = 1,
  maxWidth,
  sx
}: BoxToggleProps) => {
  return (
    <Box
      flex={1}
      position={'relative'}
      borderRadius={'4px'}
      borderColor={theme => (value ? theme.palette.primary.main : theme.palette.grey.A200)}
      onClick={() => !readonly && onChange(!value)}
      sx={{
        maxWidth: maxWidth,
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: value ? 2 : 1,
        boxShadow: theme => (value ? theme.shadows[3] : 'none'),
        ...sx
      }}
    >
      {value && !readonly && !hideClose && (
        <Close
          fontSize={'small'}
          sx={{ cursor: 'pointer', position: 'absolute', right: 4, top: 8 }}
          onClick={() => onChange(false)}
        />
      )}
      <Stack spacing={padding} pb={padding} height={'100%'}>
        <Box sx={{ height: value ? '4px' : '5px' }} bgcolor={value ? color : 'transparent'} />
        {children}
      </Stack>
    </Box>
  )
}

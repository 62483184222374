import { createTheme } from '@mui/material/styles'
import { breakpoints } from '../breakpoints'
import { typography } from '../typography'
import { components } from '../components'

export default createTheme({
  breakpoints,
  typography,
  components
})

import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_REVIEWS_KEYBOARD_REVIEWS_PL: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2024-05-04',
    toDate: '2024-05-05',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-05',
    toDate: '2024-05-17',
    numRatings: 3,
    numReviews: 6,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-17',
    toDate: '2024-05-18',
    numRatings: 7,
    numReviews: 7,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-18',
    toDate: '2024-05-19',
    numRatings: 9,
    numReviews: 9,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-19',
    toDate: '2024-05-20',
    numRatings: 9,
    numReviews: 9,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-20',
    toDate: '2024-05-21',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-21',
    toDate: '2024-05-22',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-22',
    toDate: '2024-05-23',
    numRatings: 21,
    numReviews: 21,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8,
    organicRatingCount: 4,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-23',
    toDate: '2024-05-24',
    numRatings: 22,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-24',
    toDate: '2024-05-25',
    numRatings: 23,
    numReviews: 23,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-25',
    toDate: '2024-05-26',
    numRatings: 27,
    numReviews: 26,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-26',
    toDate: '2024-05-27',
    numRatings: 29,
    numReviews: 28,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-27',
    toDate: '2024-05-28',
    numRatings: 30,
    numReviews: 29,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-28',
    toDate: '2024-05-29',
    numRatings: 33,
    numReviews: 32,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-29',
    toDate: '2024-05-30',
    numRatings: 36,
    numReviews: 35,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-30',
    toDate: '2024-05-31',
    numRatings: 41,
    numReviews: 40,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-31',
    toDate: '2024-06-01',
    numRatings: 42,
    numReviews: 41,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-01',
    toDate: '2024-06-02',
    numRatings: 44,
    numReviews: 42,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-02',
    toDate: '2024-06-03',
    numRatings: 48,
    numReviews: 45,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-03',
    toDate: '2024-06-04',
    numRatings: 48,
    numReviews: 45,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-04',
    toDate: '2024-06-05',
    numRatings: 50,
    numReviews: 47,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-05',
    toDate: '2024-06-06',
    numRatings: 52,
    numReviews: 49,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-06',
    toDate: '2024-06-07',
    numRatings: 53,
    numReviews: 51,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-07',
    toDate: '2024-06-08',
    numRatings: 55,
    numReviews: 53,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-08',
    toDate: '2024-06-09',
    numRatings: 56,
    numReviews: 55,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-09',
    toDate: '2024-06-10',
    numRatings: 57,
    numReviews: 56,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-10',
    toDate: '2024-06-11',
    numRatings: 58,
    numReviews: 58,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-11',
    toDate: '2024-06-12',
    numRatings: 62,
    numReviews: 61,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-12',
    toDate: '2024-06-13',
    numRatings: 65,
    numReviews: 64,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-13',
    toDate: '2024-06-14',
    numRatings: 68,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-14',
    toDate: '2024-06-15',
    numRatings: 68,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-15',
    toDate: '2024-06-16',
    numRatings: 69,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-16',
    toDate: '2024-06-17',
    numRatings: 70,
    numReviews: 69,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-17',
    toDate: '2024-06-18',
    numRatings: 72,
    numReviews: 71,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-18',
    toDate: '2024-06-19',
    numRatings: 74,
    numReviews: 73,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-19',
    toDate: '2024-06-20',
    numRatings: 78,
    numReviews: 77,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-06-20',
    toDate: '2024-06-21',
    numRatings: 78,
    numReviews: 77,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-21',
    toDate: '2024-06-22',
    numRatings: 80,
    numReviews: 78,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-06-22',
    toDate: '2024-06-23',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-06-23',
    toDate: '2024-06-24',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-24',
    toDate: '2024-06-25',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-25',
    toDate: '2024-06-26',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-26',
    toDate: '2024-06-27',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-27',
    toDate: '2024-06-28',
    numRatings: 81,
    numReviews: 80,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-28',
    toDate: '2024-06-29',
    numRatings: 82,
    numReviews: 80,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-06-29',
    toDate: '2024-06-30',
    numRatings: 83,
    numReviews: 81,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Works great',
        text: 'Love all the functions, great quality keyboard',
        name: 'Ciera',
        rating: 5,
        attributedUserId: '9382c4fb-06c9-469c-af2f-e59698ab6f21',
        reviewedAt: '2024-06-29T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-30',
    toDate: '2024-07-01',
    numRatings: 84,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-07-01',
    toDate: '2024-07-02',
    numRatings: 84,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-02',
    toDate: '2024-07-03',
    numRatings: 85,
    numReviews: 83,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-07-03',
    toDate: '2024-07-04',
    numRatings: 86,
    numReviews: 84,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'One of the most comfortable keyboards',
        text: 'This keyboard was really comfortable to use. It wasn’t too loud either and the RGB lighting was very pleasant. Highly recommend It to anybody looking for a new gaming keyboard. Incredible quality and performs exactly as intended',
        name: 'Sebastian O.',
        rating: 5,
        attributedUserId: '6434867a-91b2-4e6e-b96f-a3bb54996f9d',
        reviewedAt: '2024-07-03T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-04',
    toDate: '2024-07-05',
    numRatings: 88,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-07-05',
    toDate: '2024-07-06',
    numRatings: 88,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-06',
    toDate: '2024-07-07',
    numRatings: 88,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-07',
    toDate: '2024-07-08',
    numRatings: 88,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-08',
    toDate: '2024-07-09',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-07-09',
    toDate: '2024-07-10',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-10',
    toDate: '2024-07-11',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-11',
    toDate: '2024-07-12',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-12',
    toDate: '2024-07-13',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-13',
    toDate: '2024-07-14',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-14',
    toDate: '2024-07-15',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-15',
    toDate: '2024-07-16',
    numRatings: 89,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  }
]

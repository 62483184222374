import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_CAMPAIGN_KEYWORDS_WIRELESS_EARBUDS: { items: ProductKeywordSummary[] } = {
  items: [
    {
      campaignId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id,
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_active noise cancelling earbuds`,
      keyword: 'active noise cancelling earbuds',
      searchVolume: 3644,
      organicStartRank: 306,
      organicHighestRank: 252,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-25',
          searchVolume: 2396,
          organicRank: 288,

          keywordSales: 44
        },
        {
          date: '2024-08-24',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-22',
          searchVolume: 2396,
          organicRank: 276,

          keywordSales: 44
        },
        {
          date: '2024-08-21',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-20',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-19',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-18',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-16',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-15',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-14',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-13',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-12',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-11',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-10',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-09',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-08',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-07',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-06',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-05',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-04',
          searchVolume: 2372,
          organicRank: 273,

          keywordSales: 44
        },
        {
          date: '2024-08-03',
          searchVolume: 2372,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 252,

          keywordSales: 44
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-29',
          searchVolume: 2241,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 265,

          keywordSales: 44
        },
        {
          date: '2024-07-26',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-25',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-24',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-23',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-22',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-21',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-20',
          searchVolume: 3644,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-19',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-18',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-17',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-16',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-15',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-14',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-13',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-12',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-11',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-10',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-09',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-08',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-07',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-06',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-05',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-04',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-03',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-02',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-01',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-30',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-29',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc ear buds`,
      keyword: 'anc ear buds',
      searchVolume: 283,
      organicStartRank: 306,
      organicHighestRank: 17,
      sponsoredStartRank: 90,
      sponsoredHighestRank: 90,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 289,
          organicRank: 247
        },
        {
          date: '2024-08-24',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 289,
          organicRank: 299
        },
        {
          date: '2024-08-22',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 289,
          organicRank: 306
        },
        {
          date: '2024-08-13',
          searchVolume: 289,
          organicRank: 306,
          sponsoredRank: 90
        },
        {
          date: '2024-08-12',
          searchVolume: 289,
          organicRank: 170
        },
        {
          date: '2024-08-11',
          searchVolume: 289,
          organicRank: 251
        },
        {
          date: '2024-08-10',
          searchVolume: 289,
          organicRank: 166
        },
        {
          date: '2024-08-09',
          searchVolume: 267,
          organicRank: 152
        },
        {
          date: '2024-08-08',
          searchVolume: 267,
          organicRank: 156
        },
        {
          date: '2024-08-07',
          searchVolume: 267,
          organicRank: 136
        },
        {
          date: '2024-08-06',
          searchVolume: 267,
          organicRank: 144
        },
        {
          date: '2024-08-05',
          searchVolume: 267,
          organicRank: 134
        },
        {
          date: '2024-08-04',
          searchVolume: 267,
          organicRank: 139
        },
        {
          date: '2024-08-03',
          searchVolume: 267,
          organicRank: 146
        },
        {
          date: '2024-08-01',
          searchVolume: 314,
          organicRank: 149
        },
        {
          date: '2024-07-31',
          searchVolume: 314,
          organicRank: 306
        },
        {
          date: '2024-07-30',
          searchVolume: 314,
          organicRank: 116
        },
        {
          date: '2024-07-29',
          searchVolume: 314,
          organicRank: 147
        },
        {
          date: '2024-07-28',
          searchVolume: 314,
          organicRank: 180
        },
        {
          date: '2024-07-27',
          searchVolume: 314,
          organicRank: 194
        },
        {
          date: '2024-07-26',
          searchVolume: 445,
          organicRank: 190
        },
        {
          date: '2024-07-25',
          searchVolume: 445,
          organicRank: 306
        },
        {
          date: '2024-07-24',
          searchVolume: 445,
          organicRank: 162
        },
        {
          date: '2024-07-23',
          searchVolume: 445,
          organicRank: 121
        },
        {
          date: '2024-07-22',
          searchVolume: 445,
          organicRank: 147
        },
        {
          date: '2024-07-21',
          searchVolume: 445,
          organicRank: 153
        },
        {
          date: '2024-07-20',
          searchVolume: 445,
          organicRank: 150
        },
        {
          date: '2024-07-19',
          searchVolume: 228,
          organicRank: 134
        },
        {
          date: '2024-07-18',
          searchVolume: 228,
          organicRank: 147
        },
        {
          date: '2024-07-17',
          searchVolume: 228,
          organicRank: 142
        },
        {
          date: '2024-07-16',
          searchVolume: 228,
          organicRank: 143
        },
        {
          date: '2024-07-15',
          searchVolume: 228,
          organicRank: 104
        },
        {
          date: '2024-07-14',
          searchVolume: 228,
          organicRank: 113
        },
        {
          date: '2024-07-13',
          searchVolume: 228,
          organicRank: 109
        },
        {
          date: '2024-07-12',
          searchVolume: 247,
          organicRank: 99
        },
        {
          date: '2024-07-11',
          searchVolume: 247,
          organicRank: 101
        },
        {
          date: '2024-07-10',
          searchVolume: 247,
          organicRank: 92
        },
        {
          date: '2024-07-09',
          searchVolume: 247,
          organicRank: 194
        },
        {
          date: '2024-07-08',
          searchVolume: 247,
          organicRank: 173
        },
        {
          date: '2024-07-07',
          searchVolume: 247,
          organicRank: 172
        },
        {
          date: '2024-07-06',
          searchVolume: 247,
          organicRank: 152
        },
        {
          date: '2024-07-05',
          searchVolume: 252,
          organicRank: 168
        },
        {
          date: '2024-07-04',
          searchVolume: 252,
          organicRank: 121
        },
        {
          date: '2024-07-03',
          searchVolume: 252,
          organicRank: 112
        },
        {
          date: '2024-07-02',
          searchVolume: 252,
          organicRank: 99
        },
        {
          date: '2024-07-01',
          searchVolume: 252,
          organicRank: 131
        },
        {
          date: '2024-06-30',
          searchVolume: 252,
          organicRank: 136
        },
        {
          date: '2024-06-29',
          searchVolume: 252,
          organicRank: 158
        },
        {
          date: '2024-06-28',
          searchVolume: 242,
          organicRank: 135
        },
        {
          date: '2024-06-27',
          searchVolume: 242,
          organicRank: 131
        },
        {
          date: '2024-06-26',
          searchVolume: 242,
          organicRank: 119
        },
        {
          date: '2024-06-25',
          searchVolume: 242,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc earbud`,
      keyword: 'anc earbud',
      searchVolume: 0,
      organicStartRank: 306,
      organicHighestRank: 36,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 27,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 0,
          organicRank: 122
        },
        {
          date: '2024-08-24',
          searchVolume: 0,
          organicRank: 125
        },
        {
          date: '2024-08-22',
          searchVolume: 0,
          organicRank: 117
        },
        {
          date: '2024-08-20',
          searchVolume: 0,
          organicRank: 108
        },
        {
          date: '2024-08-18',
          searchVolume: 0,
          organicRank: 95
        },
        {
          date: '2024-08-16',
          searchVolume: 0,
          organicRank: 112
        },
        {
          date: '2024-08-14',
          searchVolume: 0,
          organicRank: 107
        },
        {
          date: '2024-08-12',
          searchVolume: 0,
          organicRank: 92
        },
        {
          date: '2024-08-10',
          searchVolume: 0,
          organicRank: 96,
          sponsoredRank: 27
        },
        {
          date: '2024-08-08',
          searchVolume: 0,
          organicRank: 81
        },
        {
          date: '2024-08-06',
          searchVolume: 0,
          organicRank: 51
        },
        {
          date: '2024-08-04',
          searchVolume: 0,
          organicRank: 60
        },
        {
          date: '2024-08-02',
          searchVolume: 0,
          organicRank: 62
        },
        {
          date: '2024-07-31',
          searchVolume: 0,
          organicRank: 65
        },
        {
          date: '2024-07-29',
          searchVolume: 0,
          organicRank: 75
        },
        {
          date: '2024-07-27',
          searchVolume: 0,
          organicRank: 68
        },
        {
          date: '2024-07-25',
          searchVolume: 0,
          organicRank: 68
        },
        {
          date: '2024-07-23',
          searchVolume: 0,
          organicRank: 79
        },
        {
          date: '2024-07-21',
          searchVolume: 0,
          organicRank: 77
        },
        {
          date: '2024-07-19',
          searchVolume: 0,
          organicRank: 73
        },
        {
          date: '2024-07-17',
          searchVolume: 0,
          organicRank: 62
        },
        {
          date: '2024-07-15',
          searchVolume: 0,
          organicRank: 62
        },
        {
          date: '2024-07-13',
          searchVolume: 0,
          organicRank: 61
        },
        {
          date: '2024-07-11',
          searchVolume: 0,
          organicRank: 52
        },
        {
          date: '2024-07-08',
          searchVolume: 0,
          organicRank: 48
        },
        {
          date: '2024-07-06',
          searchVolume: 0,
          organicRank: 43
        },
        {
          date: '2024-07-04',
          searchVolume: 0,
          organicRank: 37
        },
        {
          date: '2024-07-02',
          searchVolume: 0,
          organicRank: 44
        },
        {
          date: '2024-06-30',
          searchVolume: 0,
          organicRank: 46
        },
        {
          date: '2024-06-28',
          searchVolume: 0,
          organicRank: 61
        },
        {
          date: '2024-06-26',
          searchVolume: 0,
          organicRank: 59
        },
        {
          date: '2024-06-24',
          searchVolume: 0,
          organicRank: 59
        },
        {
          date: '2024-06-21',
          searchVolume: 0,
          organicRank: 97
        },
        {
          date: '2024-06-19',
          searchVolume: 0,
          organicRank: 64
        },
        {
          date: '2024-06-17',
          searchVolume: 0,
          organicRank: 36
        },
        {
          date: '2024-06-15',
          searchVolume: 0,
          organicRank: 42
        },
        {
          date: '2024-06-13',
          searchVolume: 0,
          organicRank: 50
        },
        {
          date: '2024-06-11',
          searchVolume: 0,
          organicRank: 306
        },
        {
          date: '2024-06-10',
          searchVolume: 0,
          organicRank: 272
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc earbuds`,
      keyword: 'anc earbuds',
      searchVolume: 3462,
      organicStartRank: 306,
      organicHighestRank: 9,
      sponsoredStartRank: 45,
      sponsoredHighestRank: 45,
      organicPageOneWinDate: '2024-06-16',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 4324,
          organicRank: 78,

          keywordSales: 26
        },
        {
          date: '2024-08-24',
          searchVolume: 4324,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-08-23',
          searchVolume: 4324,
          organicRank: 67,

          keywordSales: 26
        },
        {
          date: '2024-08-22',
          searchVolume: 4324,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-08-21',
          searchVolume: 4324,
          organicRank: 67,

          keywordSales: 26
        },
        {
          date: '2024-08-20',
          searchVolume: 4324,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-08-19',
          searchVolume: 4324,
          organicRank: 63,

          keywordSales: 26
        },
        {
          date: '2024-08-17',
          searchVolume: 4324,
          organicRank: 60,
          sponsoredRank: 45,
          keywordSales: 26
        },
        {
          date: '2024-08-16',
          searchVolume: 3480,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-08-15',
          searchVolume: 3480,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-08-14',
          searchVolume: 3480,
          organicRank: 64,

          keywordSales: 26
        },
        {
          date: '2024-08-13',
          searchVolume: 3480,
          organicRank: 54,

          keywordSales: 26
        },
        {
          date: '2024-08-12',
          searchVolume: 3480,
          organicRank: 62,

          keywordSales: 26
        },
        {
          date: '2024-08-11',
          searchVolume: 3480,
          organicRank: 63,

          keywordSales: 26
        },
        {
          date: '2024-08-10',
          searchVolume: 3480,
          organicRank: 60,

          keywordSales: 26
        },
        {
          date: '2024-08-09',
          searchVolume: 3556,
          organicRank: 70,

          keywordSales: 26
        },
        {
          date: '2024-08-08',
          searchVolume: 3556,
          organicRank: 70,

          keywordSales: 26
        },
        {
          date: '2024-08-07',
          searchVolume: 3556,
          organicRank: 70,

          keywordSales: 26
        },
        {
          date: '2024-08-06',
          searchVolume: 3556,
          organicRank: 68,

          keywordSales: 26
        },
        {
          date: '2024-08-05',
          searchVolume: 3556,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-08-04',
          searchVolume: 3556,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-08-03',
          searchVolume: 3556,
          organicRank: 59,

          keywordSales: 26
        },
        {
          date: '2024-08-02',
          searchVolume: 3990,
          organicRank: 76,

          keywordSales: 26
        },
        {
          date: '2024-08-01',
          searchVolume: 3990,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-07-30',
          searchVolume: 3990,
          organicRank: 54,

          keywordSales: 26
        },
        {
          date: '2024-07-29',
          searchVolume: 3990,
          organicRank: 76,

          keywordSales: 26
        },
        {
          date: '2024-07-28',
          searchVolume: 3990,
          organicRank: 84,

          keywordSales: 26
        },
        {
          date: '2024-07-27',
          searchVolume: 3990,
          organicRank: 79,

          keywordSales: 26
        },
        {
          date: '2024-07-26',
          searchVolume: 6450,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-07-25',
          searchVolume: 6450,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-07-24',
          searchVolume: 6450,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-07-23',
          searchVolume: 6450,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-07-22',
          searchVolume: 6450,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-07-21',
          searchVolume: 6450,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-07-20',
          searchVolume: 6450,
          organicRank: 83,

          keywordSales: 26
        },
        {
          date: '2024-07-19',
          searchVolume: 4058,
          organicRank: 67,

          keywordSales: 26
        },
        {
          date: '2024-07-18',
          searchVolume: 4058,
          organicRank: 93,

          keywordSales: 26
        },
        {
          date: '2024-07-17',
          searchVolume: 4058,
          organicRank: 83,

          keywordSales: 26
        },
        {
          date: '2024-07-16',
          searchVolume: 4058,
          organicRank: 77,

          keywordSales: 26
        },
        {
          date: '2024-07-15',
          searchVolume: 4058,
          organicRank: 63,

          keywordSales: 26
        },
        {
          date: '2024-07-14',
          searchVolume: 4058,
          organicRank: 65,

          keywordSales: 26
        },
        {
          date: '2024-07-13',
          searchVolume: 4058,
          organicRank: 64,

          keywordSales: 26
        },
        {
          date: '2024-07-12',
          searchVolume: 4133,
          organicRank: 64,

          keywordSales: 26
        },
        {
          date: '2024-07-11',
          searchVolume: 4133,
          organicRank: 64,

          keywordSales: 26
        },
        {
          date: '2024-07-10',
          searchVolume: 4133,
          organicRank: 95,

          keywordSales: 26
        },
        {
          date: '2024-07-09',
          searchVolume: 4133,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-07-08',
          searchVolume: 4133,
          organicRank: 73,

          keywordSales: 26
        },
        {
          date: '2024-07-07',
          searchVolume: 4133,
          organicRank: 60,

          keywordSales: 26
        },
        {
          date: '2024-07-06',
          searchVolume: 4133,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-07-05',
          searchVolume: 4240,
          organicRank: 48,

          keywordSales: 26
        },
        {
          date: '2024-07-04',
          searchVolume: 4240,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-07-03',
          searchVolume: 4240,
          organicRank: 53,

          keywordSales: 26
        },
        {
          date: '2024-07-02',
          searchVolume: 4240,
          organicRank: 62,

          keywordSales: 26
        },
        {
          date: '2024-07-01',
          searchVolume: 4240,
          organicRank: 104,

          keywordSales: 26
        },
        {
          date: '2024-06-30',
          searchVolume: 4240,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-06-29',
          searchVolume: 4240,
          organicRank: 116,

          keywordSales: 26
        },
        {
          date: '2024-06-28',
          searchVolume: 3635,
          organicRank: 86,

          keywordSales: 26
        },
        {
          date: '2024-06-27',
          searchVolume: 3635,
          organicRank: 72,

          keywordSales: 26
        },
        {
          date: '2024-06-26',
          searchVolume: 3635,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-25',
          searchVolume: 3635,
          organicRank: 127,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc earbuds wireless bluetooth`,
      keyword: 'anc earbuds wireless bluetooth',
      searchVolume: 508,
      organicStartRank: 306,
      organicHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 598,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-08-25',
          searchVolume: 598,
          organicRank: 78,

          keywordSales: 5
        },
        {
          date: '2024-08-24',
          searchVolume: 598,
          organicRank: 90,

          keywordSales: 5
        },
        {
          date: '2024-08-23',
          searchVolume: 598,
          organicRank: 92,

          keywordSales: 5
        },
        {
          date: '2024-08-22',
          searchVolume: 598,
          organicRank: 108,

          keywordSales: 5
        },
        {
          date: '2024-08-21',
          searchVolume: 598,
          organicRank: 103,

          keywordSales: 5
        },
        {
          date: '2024-08-20',
          searchVolume: 598,
          organicRank: 106,

          keywordSales: 5
        },
        {
          date: '2024-08-19',
          searchVolume: 598,
          organicRank: 103,

          keywordSales: 5
        },
        {
          date: '2024-08-17',
          searchVolume: 598,
          organicRank: 80,

          keywordSales: 5
        },
        {
          date: '2024-08-16',
          searchVolume: 484,
          organicRank: 85,

          keywordSales: 5
        },
        {
          date: '2024-08-15',
          searchVolume: 484,
          organicRank: 83,

          keywordSales: 5
        },
        {
          date: '2024-08-14',
          searchVolume: 484,
          organicRank: 73,

          keywordSales: 5
        },
        {
          date: '2024-08-13',
          searchVolume: 484,
          organicRank: 58,

          keywordSales: 5
        },
        {
          date: '2024-08-12',
          searchVolume: 484,
          organicRank: 61,

          keywordSales: 5
        },
        {
          date: '2024-08-11',
          searchVolume: 484,
          organicRank: 59,

          keywordSales: 5
        },
        {
          date: '2024-08-10',
          searchVolume: 484,
          organicRank: 55,

          keywordSales: 5
        },
        {
          date: '2024-08-09',
          searchVolume: 628,
          organicRank: 64,

          keywordSales: 5
        },
        {
          date: '2024-08-08',
          searchVolume: 628,
          organicRank: 43,

          keywordSales: 5
        },
        {
          date: '2024-08-07',
          searchVolume: 628,
          organicRank: 36,

          keywordSales: 5
        },
        {
          date: '2024-08-06',
          searchVolume: 628,
          organicRank: 41,

          keywordSales: 5
        },
        {
          date: '2024-08-05',
          searchVolume: 628,
          organicRank: 25,

          keywordSales: 5
        },
        {
          date: '2024-08-04',
          searchVolume: 628,
          organicRank: 36,

          keywordSales: 5
        },
        {
          date: '2024-08-03',
          searchVolume: 628,
          organicRank: 40,

          keywordSales: 5
        },
        {
          date: '2024-08-01',
          searchVolume: 621,
          organicRank: 48,

          keywordSales: 5
        },
        {
          date: '2024-07-31',
          searchVolume: 621,
          organicRank: 54,

          keywordSales: 5
        },
        {
          date: '2024-07-30',
          searchVolume: 621,
          organicRank: 57,

          keywordSales: 5
        },
        {
          date: '2024-07-29',
          searchVolume: 621,
          organicRank: 70,

          keywordSales: 5
        },
        {
          date: '2024-07-28',
          searchVolume: 621,
          organicRank: 66,

          keywordSales: 5
        },
        {
          date: '2024-07-27',
          searchVolume: 621,
          organicRank: 67,

          keywordSales: 5
        },
        {
          date: '2024-07-26',
          searchVolume: 1100,
          organicRank: 59,

          keywordSales: 5
        },
        {
          date: '2024-07-25',
          searchVolume: 1100,
          organicRank: 62,

          keywordSales: 5
        },
        {
          date: '2024-07-24',
          searchVolume: 1100,
          organicRank: 54,

          keywordSales: 5
        },
        {
          date: '2024-07-23',
          searchVolume: 1100,
          organicRank: 61,

          keywordSales: 5
        },
        {
          date: '2024-07-22',
          searchVolume: 1100,
          organicRank: 63,

          keywordSales: 5
        },
        {
          date: '2024-07-21',
          searchVolume: 1100,
          organicRank: 48,

          keywordSales: 5
        },
        {
          date: '2024-07-20',
          searchVolume: 1100,
          organicRank: 58,

          keywordSales: 5
        },
        {
          date: '2024-07-19',
          searchVolume: 525,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-18',
          searchVolume: 525,
          organicRank: 45,

          keywordSales: 5
        },
        {
          date: '2024-07-17',
          searchVolume: 525,
          organicRank: 173,

          keywordSales: 5
        },
        {
          date: '2024-07-16',
          searchVolume: 525,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-15',
          searchVolume: 525,
          organicRank: 174,

          keywordSales: 5
        },
        {
          date: '2024-07-14',
          searchVolume: 525,
          organicRank: 212,

          keywordSales: 5
        },
        {
          date: '2024-07-13',
          searchVolume: 525,
          organicRank: 215,

          keywordSales: 5
        },
        {
          date: '2024-07-12',
          searchVolume: 483,
          organicRank: 218,

          keywordSales: 5
        },
        {
          date: '2024-07-11',
          searchVolume: 483,
          organicRank: 192,

          keywordSales: 5
        },
        {
          date: '2024-07-10',
          searchVolume: 483,
          organicRank: 171,

          keywordSales: 5
        },
        {
          date: '2024-07-09',
          searchVolume: 483,
          organicRank: 204,

          keywordSales: 5
        },
        {
          date: '2024-07-08',
          searchVolume: 483,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-07',
          searchVolume: 483,
          organicRank: 207,

          keywordSales: 5
        },
        {
          date: '2024-07-06',
          searchVolume: 483,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-05',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-04',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-03',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-02',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-07-01',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-06-30',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-06-29',
          searchVolume: 508,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-06-28',
          searchVolume: 673,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-06-27',
          searchVolume: 673,
          organicRank: 306,

          keywordSales: 5
        },
        {
          date: '2024-06-26',
          searchVolume: 673,
          organicRank: 306,

          keywordSales: 5
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc true wireless earbuds`,
      keyword: 'anc true wireless earbuds',
      searchVolume: 329,
      organicStartRank: 75,
      organicHighestRank: 7,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 283,
          organicRank: 31,

          keywordSales: 4
        },
        {
          date: '2024-08-24',
          searchVolume: 283,
          organicRank: 27,

          keywordSales: 4
        },
        {
          date: '2024-08-23',
          searchVolume: 283,
          organicRank: 30,

          keywordSales: 4
        },
        {
          date: '2024-08-22',
          searchVolume: 283,
          organicRank: 28,

          keywordSales: 4
        },
        {
          date: '2024-08-21',
          searchVolume: 283,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-20',
          searchVolume: 283,
          organicRank: 22,

          keywordSales: 4
        },
        {
          date: '2024-08-19',
          searchVolume: 283,
          organicRank: 20,

          keywordSales: 4
        },
        {
          date: '2024-08-17',
          searchVolume: 283,
          organicRank: 25,

          keywordSales: 4
        },
        {
          date: '2024-08-16',
          searchVolume: 310,
          organicRank: 23,

          keywordSales: 4
        },
        {
          date: '2024-08-15',
          searchVolume: 310,
          organicRank: 30,

          keywordSales: 4
        },
        {
          date: '2024-08-14',
          searchVolume: 310,
          organicRank: 15,

          keywordSales: 4
        },
        {
          date: '2024-08-13',
          searchVolume: 310,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-08-12',
          searchVolume: 310,
          organicRank: 12,

          keywordSales: 4
        },
        {
          date: '2024-08-11',
          searchVolume: 310,
          organicRank: 12,

          keywordSales: 4
        },
        {
          date: '2024-08-10',
          searchVolume: 310,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-08-09',
          searchVolume: 292,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-08-08',
          searchVolume: 292,
          organicRank: 12,

          keywordSales: 4
        },
        {
          date: '2024-08-07',
          searchVolume: 292,
          organicRank: 18,

          keywordSales: 4
        },
        {
          date: '2024-08-06',
          searchVolume: 292,
          organicRank: 12,

          keywordSales: 4
        },
        {
          date: '2024-08-05',
          searchVolume: 292,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-08-04',
          searchVolume: 292,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 4
        },
        {
          date: '2024-08-03',
          searchVolume: 292,
          organicRank: 17,

          keywordSales: 4
        },
        {
          date: '2024-08-01',
          searchVolume: 258,
          organicRank: 17,

          keywordSales: 4
        },
        {
          date: '2024-07-31',
          searchVolume: 258,
          organicRank: 16,

          keywordSales: 4
        },
        {
          date: '2024-07-30',
          searchVolume: 258,
          organicRank: 17,

          keywordSales: 4
        },
        {
          date: '2024-07-29',
          searchVolume: 258,
          organicRank: 20,

          keywordSales: 4
        },
        {
          date: '2024-07-28',
          searchVolume: 258,
          organicRank: 23,

          keywordSales: 4
        },
        {
          date: '2024-07-27',
          searchVolume: 258,
          organicRank: 21,

          keywordSales: 4
        },
        {
          date: '2024-07-26',
          searchVolume: 263,
          organicRank: 20,

          keywordSales: 4
        },
        {
          date: '2024-07-25',
          searchVolume: 263,
          organicRank: 20,

          keywordSales: 4
        },
        {
          date: '2024-07-24',
          searchVolume: 263,
          organicRank: 21,

          keywordSales: 4
        },
        {
          date: '2024-07-23',
          searchVolume: 263,
          organicRank: 22,

          keywordSales: 4
        },
        {
          date: '2024-07-22',
          searchVolume: 263,
          organicRank: 21,

          keywordSales: 4
        },
        {
          date: '2024-07-21',
          searchVolume: 263,
          organicRank: 15,

          keywordSales: 4
        },
        {
          date: '2024-07-20',
          searchVolume: 263,
          organicRank: 14,

          keywordSales: 4
        },
        {
          date: '2024-07-19',
          searchVolume: 197,
          organicRank: 15,

          keywordSales: 4
        },
        {
          date: '2024-07-18',
          searchVolume: 197,
          organicRank: 18,

          keywordSales: 4
        },
        {
          date: '2024-07-17',
          searchVolume: 197,
          organicRank: 15,

          keywordSales: 4
        },
        {
          date: '2024-07-16',
          searchVolume: 197,
          organicRank: 13,

          keywordSales: 4
        },
        {
          date: '2024-07-15',
          searchVolume: 197,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-07-14',
          searchVolume: 197,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-07-13',
          searchVolume: 197,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-07-12',
          searchVolume: 218,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-07-11',
          searchVolume: 218,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-07-10',
          searchVolume: 218,
          organicRank: 10,

          keywordSales: 4
        },
        {
          date: '2024-07-09',
          searchVolume: 218,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-07-08',
          searchVolume: 218,
          organicRank: 11,

          keywordSales: 4
        },
        {
          date: '2024-07-07',
          searchVolume: 218,
          organicRank: 7,

          keywordSales: 4
        },
        {
          date: '2024-07-06',
          searchVolume: 218,
          organicRank: 8,

          keywordSales: 4
        },
        {
          date: '2024-07-05',
          searchVolume: 205,
          organicRank: 8,

          keywordSales: 4
        },
        {
          date: '2024-07-04',
          searchVolume: 205,
          organicRank: 8,

          keywordSales: 4
        },
        {
          date: '2024-07-03',
          searchVolume: 205,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-07-02',
          searchVolume: 205,
          organicRank: 8,

          keywordSales: 4
        },
        {
          date: '2024-07-01',
          searchVolume: 205,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-06-30',
          searchVolume: 205,
          organicRank: 9,

          keywordSales: 4
        },
        {
          date: '2024-06-29',
          searchVolume: 205,
          organicRank: 10,

          keywordSales: 4
        },
        {
          date: '2024-06-28',
          searchVolume: 284,
          organicRank: 15,

          keywordSales: 4
        },
        {
          date: '2024-06-27',
          searchVolume: 284,
          organicRank: 12,

          keywordSales: 4
        },
        {
          date: '2024-06-26',
          searchVolume: 284,
          organicRank: 13,

          keywordSales: 4
        },
        {
          date: '2024-06-25',
          searchVolume: 284,
          organicRank: 13,

          keywordSales: 4
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_anc wireless earbuds`,
      keyword: 'anc wireless earbuds',
      searchVolume: 429,
      organicStartRank: 154,
      organicHighestRank: 12,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 366,
          organicRank: 134,

          keywordSales: 1
        },
        {
          date: '2024-08-25',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 366,
          organicRank: 102,

          keywordSales: 1
        },
        {
          date: '2024-08-23',
          searchVolume: 366,
          organicRank: 92,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 366,
          organicRank: 126,

          keywordSales: 1
        },
        {
          date: '2024-08-19',
          searchVolume: 366,
          organicRank: 127,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 380,
          organicRank: 111,

          keywordSales: 1
        },
        {
          date: '2024-08-15',
          searchVolume: 380,
          organicRank: 105,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 380,
          organicRank: 89,

          keywordSales: 1
        },
        {
          date: '2024-08-13',
          searchVolume: 380,
          organicRank: 95,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 380,
          organicRank: 86,

          keywordSales: 1
        },
        {
          date: '2024-08-11',
          searchVolume: 380,
          organicRank: 91,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 380,
          organicRank: 93,

          keywordSales: 1
        },
        {
          date: '2024-08-09',
          searchVolume: 435,
          organicRank: 93,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 435,
          organicRank: 83,

          keywordSales: 1
        },
        {
          date: '2024-08-07',
          searchVolume: 435,
          organicRank: 83,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 435,
          organicRank: 86,

          keywordSales: 1
        },
        {
          date: '2024-08-05',
          searchVolume: 435,
          organicRank: 51,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 435,
          organicRank: 61,

          keywordSales: 1
        },
        {
          date: '2024-08-02',
          searchVolume: 426,
          organicRank: 80,

          keywordSales: 1
        },
        {
          date: '2024-08-01',
          searchVolume: 426,
          organicRank: 74,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 426,
          organicRank: 77,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 426,
          organicRank: 90,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 426,
          organicRank: 93,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 426,
          organicRank: 95,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 458,
          organicRank: 83,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 458,
          organicRank: 66,

          keywordSales: 1
        },
        {
          date: '2024-07-24',
          searchVolume: 458,
          organicRank: 46,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 458,
          organicRank: 45,

          keywordSales: 1
        },
        {
          date: '2024-07-22',
          searchVolume: 458,
          organicRank: 43,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 458,
          organicRank: 42,

          keywordSales: 1
        },
        {
          date: '2024-07-20',
          searchVolume: 458,
          organicRank: 37,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 356,
          organicRank: 36,

          keywordSales: 1
        },
        {
          date: '2024-07-18',
          searchVolume: 356,
          organicRank: 39,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 356,
          organicRank: 40,

          keywordSales: 1
        },
        {
          date: '2024-07-16',
          searchVolume: 356,
          organicRank: 36,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 356,
          organicRank: 24,

          keywordSales: 1
        },
        {
          date: '2024-07-14',
          searchVolume: 356,
          organicRank: 28,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 356,
          organicRank: 28,

          keywordSales: 1
        },
        {
          date: '2024-07-12',
          searchVolume: 389,
          organicRank: 29,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 389,
          organicRank: 33,

          keywordSales: 1
        },
        {
          date: '2024-07-10',
          searchVolume: 389,
          organicRank: 31,

          keywordSales: 1
        },
        {
          date: '2024-07-09',
          searchVolume: 389,
          organicRank: 29,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 389,
          organicRank: 30,

          keywordSales: 1
        },
        {
          date: '2024-07-07',
          searchVolume: 389,
          organicRank: 30,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 389,
          organicRank: 22,

          keywordSales: 1
        },
        {
          date: '2024-07-05',
          searchVolume: 398,
          organicRank: 16,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 398,
          organicRank: 12,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 398,
          organicRank: 20,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 398,
          organicRank: 18,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 398,
          organicRank: 25,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 398,
          organicRank: 34,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 398,
          organicRank: 32,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 415,
          organicRank: 27,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 415,
          organicRank: 32,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 415,
          organicRank: 18,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 415,
          organicRank: 24,

          keywordSales: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 385,
          organicRank: 24,

          keywordSales: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 385,
          organicRank: 24,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_audio technica earbuds`,
      keyword: 'audio technica earbuds',
      searchVolume: 1846,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 48,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-25',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-24',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-23',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-22',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-21',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-20',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-19',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-17',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-16',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-15',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-14',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-13',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-12',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-11',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-10',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-09',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-08',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-07',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-06',
          searchVolume: 1547,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 2
        },
        {
          date: '2024-08-05',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-04',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-03',
          searchVolume: 1547,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 2
        },
        {
          date: '2024-08-01',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-31',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-30',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-29',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-28',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-27',
          searchVolume: 1530,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-26',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-25',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-24',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-23',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-22',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-21',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-20',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-19',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-18',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-17',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-16',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-15',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-14',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-13',
          searchVolume: 2242,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-12',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-11',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-10',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-09',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-08',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-07',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-06',
          searchVolume: 1368,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-05',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-04',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-03',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-01',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-30',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-29',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-28',
          searchVolume: 1435,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-27',
          searchVolume: 1435,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-26',
          searchVolume: 1435,
          organicRank: 306,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats earbuds`,
      keyword: 'beats earbuds',
      searchVolume: 147924,
      organicStartRank: 306,
      organicHighestRank: 85,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-25',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-24',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-23',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-22',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-21',
          searchVolume: 168945,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-19',
          searchVolume: 168945,
          organicRank: 251,

          keywordSales: 1259
        },
        {
          date: '2024-08-15',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-14',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-13',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-12',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-11',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-10',
          searchVolume: 148432,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-09',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-08',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-07',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-06',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-05',
          searchVolume: 117793,
          organicRank: 284,

          keywordSales: 1259
        },
        {
          date: '2024-08-04',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-03',
          searchVolume: 117793,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-08-01',
          searchVolume: 132131,
          organicRank: 273,

          keywordSales: 1259
        },
        {
          date: '2024-07-31',
          searchVolume: 132131,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-30',
          searchVolume: 132131,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-29',
          searchVolume: 132131,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-28',
          searchVolume: 132131,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-27',
          searchVolume: 132131,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-26',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-25',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-24',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-23',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-22',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-21',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-20',
          searchVolume: 337936,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-19',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-18',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-17',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-16',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-15',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-14',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-13',
          searchVolume: 145413,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-12',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-11',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-10',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-09',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-08',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-07',
          searchVolume: 136959,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-06',
          searchVolume: 136959,
          organicRank: 199,

          keywordSales: 1259
        },
        {
          date: '2024-07-05',
          searchVolume: 154044,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-04',
          searchVolume: 154044,
          organicRank: 204,

          keywordSales: 1259
        },
        {
          date: '2024-07-03',
          searchVolume: 154044,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-07-02',
          searchVolume: 154044,
          organicRank: 200,

          keywordSales: 1259
        },
        {
          date: '2024-07-01',
          searchVolume: 154044,
          organicRank: 207,

          keywordSales: 1259
        },
        {
          date: '2024-06-30',
          searchVolume: 154044,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-06-29',
          searchVolume: 154044,
          organicRank: 213,

          keywordSales: 1259
        },
        {
          date: '2024-06-28',
          searchVolume: 155280,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-06-27',
          searchVolume: 155280,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-06-26',
          searchVolume: 155280,
          organicRank: 191,

          keywordSales: 1259
        },
        {
          date: '2024-06-25',
          searchVolume: 155280,
          organicRank: 306,

          keywordSales: 1259
        },
        {
          date: '2024-06-23',
          searchVolume: 155280,
          organicRank: 199,

          keywordSales: 1259
        },
        {
          date: '2024-06-21',
          searchVolume: 168950,
          organicRank: 306,

          keywordSales: 1259
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats earbuds on sale`,
      keyword: 'beats earbuds on sale',
      searchVolume: 203,
      organicStartRank: 63,
      organicHighestRank: 47,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 400,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 244,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 244,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 244,
          organicRank: 306
        },
        {
          date: '2024-08-13',
          searchVolume: 244,
          organicRank: 107
        },
        {
          date: '2024-08-12',
          searchVolume: 244,
          organicRank: 104
        },
        {
          date: '2024-08-11',
          searchVolume: 244,
          organicRank: 104
        },
        {
          date: '2024-08-10',
          searchVolume: 244,
          organicRank: 104
        },
        {
          date: '2024-08-09',
          searchVolume: 192,
          organicRank: 101
        },
        {
          date: '2024-08-08',
          searchVolume: 192,
          organicRank: 103
        },
        {
          date: '2024-08-07',
          searchVolume: 192,
          organicRank: 99
        },
        {
          date: '2024-08-06',
          searchVolume: 192,
          organicRank: 97
        },
        {
          date: '2024-08-05',
          searchVolume: 192,
          organicRank: 90
        },
        {
          date: '2024-08-04',
          searchVolume: 192,
          organicRank: 96
        },
        {
          date: '2024-08-03',
          searchVolume: 192,
          organicRank: 98
        },
        {
          date: '2024-08-01',
          searchVolume: 245,
          organicRank: 98
        },
        {
          date: '2024-07-31',
          searchVolume: 245,
          organicRank: 93
        },
        {
          date: '2024-07-30',
          searchVolume: 245,
          organicRank: 95
        },
        {
          date: '2024-07-29',
          searchVolume: 245,
          organicRank: 95
        },
        {
          date: '2024-07-28',
          searchVolume: 245,
          organicRank: 101
        },
        {
          date: '2024-07-27',
          searchVolume: 245,
          organicRank: 96
        },
        {
          date: '2024-07-26',
          searchVolume: 773,
          organicRank: 96
        },
        {
          date: '2024-07-25',
          searchVolume: 773,
          organicRank: 99
        },
        {
          date: '2024-07-24',
          searchVolume: 773,
          organicRank: 93
        },
        {
          date: '2024-07-23',
          searchVolume: 773,
          organicRank: 98
        },
        {
          date: '2024-07-22',
          searchVolume: 773,
          organicRank: 306
        },
        {
          date: '2024-07-21',
          searchVolume: 773,
          organicRank: 97
        },
        {
          date: '2024-07-20',
          searchVolume: 773,
          organicRank: 99
        },
        {
          date: '2024-07-19',
          searchVolume: 1264,
          organicRank: 99
        },
        {
          date: '2024-07-18',
          searchVolume: 1264,
          organicRank: 97
        },
        {
          date: '2024-07-17',
          searchVolume: 1264,
          organicRank: 97
        },
        {
          date: '2024-07-16',
          searchVolume: 1264,
          organicRank: 96
        },
        {
          date: '2024-07-15',
          searchVolume: 1264,
          organicRank: 94
        },
        {
          date: '2024-07-14',
          searchVolume: 1264,
          organicRank: 90
        },
        {
          date: '2024-07-13',
          searchVolume: 1264,
          organicRank: 85
        },
        {
          date: '2024-07-12',
          searchVolume: 716,
          organicRank: 77
        },
        {
          date: '2024-07-11',
          searchVolume: 716,
          organicRank: 70
        },
        {
          date: '2024-07-10',
          searchVolume: 716,
          organicRank: 69
        },
        {
          date: '2024-07-09',
          searchVolume: 716,
          organicRank: 74
        },
        {
          date: '2024-07-08',
          searchVolume: 716,
          organicRank: 67
        },
        {
          date: '2024-07-07',
          searchVolume: 716,
          organicRank: 67
        },
        {
          date: '2024-07-06',
          searchVolume: 716,
          organicRank: 64
        },
        {
          date: '2024-07-05',
          searchVolume: 467,
          organicRank: 64
        },
        {
          date: '2024-07-04',
          searchVolume: 467,
          organicRank: 254
        },
        {
          date: '2024-07-03',
          searchVolume: 467,
          organicRank: 65
        },
        {
          date: '2024-07-02',
          searchVolume: 467,
          organicRank: 64
        },
        {
          date: '2024-07-01',
          searchVolume: 467,
          organicRank: 62
        },
        {
          date: '2024-06-30',
          searchVolume: 467,
          organicRank: 58
        },
        {
          date: '2024-06-29',
          searchVolume: 467,
          organicRank: 270
        },
        {
          date: '2024-06-28',
          searchVolume: 168,
          organicRank: 58
        },
        {
          date: '2024-06-27',
          searchVolume: 168,
          organicRank: 297
        },
        {
          date: '2024-06-26',
          searchVolume: 168,
          organicRank: 68
        },
        {
          date: '2024-06-25',
          searchVolume: 168,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats for sale cheap`,
      keyword: 'beats for sale cheap',
      searchVolume: 202,
      organicStartRank: 52,
      organicHighestRank: 19,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 285,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 219,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 219,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 219,
          organicRank: 159
        },
        {
          date: '2024-08-13',
          searchVolume: 219,
          organicRank: 105
        },
        {
          date: '2024-08-12',
          searchVolume: 219,
          organicRank: 119
        },
        {
          date: '2024-08-11',
          searchVolume: 219,
          organicRank: 113
        },
        {
          date: '2024-08-10',
          searchVolume: 219,
          organicRank: 118
        },
        {
          date: '2024-08-09',
          searchVolume: 216,
          organicRank: 95
        },
        {
          date: '2024-08-08',
          searchVolume: 216,
          organicRank: 98
        },
        {
          date: '2024-08-07',
          searchVolume: 216,
          organicRank: 107
        },
        {
          date: '2024-08-06',
          searchVolume: 216,
          organicRank: 97
        },
        {
          date: '2024-08-05',
          searchVolume: 216,
          organicRank: 114
        },
        {
          date: '2024-08-04',
          searchVolume: 216,
          organicRank: 306
        },
        {
          date: '2024-08-03',
          searchVolume: 216,
          organicRank: 131
        },
        {
          date: '2024-08-01',
          searchVolume: 189,
          organicRank: 144
        },
        {
          date: '2024-07-31',
          searchVolume: 189,
          organicRank: 144
        },
        {
          date: '2024-07-30',
          searchVolume: 189,
          organicRank: 112
        },
        {
          date: '2024-07-29',
          searchVolume: 189,
          organicRank: 127
        },
        {
          date: '2024-07-28',
          searchVolume: 189,
          organicRank: 54
        },
        {
          date: '2024-07-27',
          searchVolume: 189,
          organicRank: 121
        },
        {
          date: '2024-07-26',
          searchVolume: 196,
          organicRank: 306
        },
        {
          date: '2024-07-25',
          searchVolume: 196,
          organicRank: 136
        },
        {
          date: '2024-07-24',
          searchVolume: 196,
          organicRank: 103
        },
        {
          date: '2024-07-23',
          searchVolume: 196,
          organicRank: 105
        },
        {
          date: '2024-07-22',
          searchVolume: 196,
          organicRank: 113
        },
        {
          date: '2024-07-21',
          searchVolume: 196,
          organicRank: 112
        },
        {
          date: '2024-07-20',
          searchVolume: 196,
          organicRank: 111
        },
        {
          date: '2024-07-19',
          searchVolume: 244,
          organicRank: 112
        },
        {
          date: '2024-07-18',
          searchVolume: 244,
          organicRank: 117
        },
        {
          date: '2024-07-17',
          searchVolume: 244,
          organicRank: 104
        },
        {
          date: '2024-07-16',
          searchVolume: 244,
          organicRank: 124
        },
        {
          date: '2024-07-15',
          searchVolume: 244,
          organicRank: 103
        },
        {
          date: '2024-07-14',
          searchVolume: 244,
          organicRank: 105
        },
        {
          date: '2024-07-13',
          searchVolume: 244,
          organicRank: 102
        },
        {
          date: '2024-07-12',
          searchVolume: 289,
          organicRank: 101
        },
        {
          date: '2024-07-11',
          searchVolume: 289,
          organicRank: 110
        },
        {
          date: '2024-07-10',
          searchVolume: 289,
          organicRank: 97
        },
        {
          date: '2024-07-09',
          searchVolume: 289,
          organicRank: 102
        },
        {
          date: '2024-07-08',
          searchVolume: 289,
          organicRank: 91
        },
        {
          date: '2024-07-07',
          searchVolume: 289,
          organicRank: 92
        },
        {
          date: '2024-07-06',
          searchVolume: 289,
          organicRank: 80
        },
        {
          date: '2024-07-05',
          searchVolume: 345,
          organicRank: 125
        },
        {
          date: '2024-07-04',
          searchVolume: 345,
          organicRank: 88
        },
        {
          date: '2024-07-03',
          searchVolume: 345,
          organicRank: 88
        },
        {
          date: '2024-07-02',
          searchVolume: 345,
          organicRank: 154
        },
        {
          date: '2024-07-01',
          searchVolume: 345,
          organicRank: 72
        },
        {
          date: '2024-06-30',
          searchVolume: 345,
          organicRank: 88
        },
        {
          date: '2024-06-29',
          searchVolume: 345,
          organicRank: 108
        },
        {
          date: '2024-06-28',
          searchVolume: 345,
          organicRank: 95
        },
        {
          date: '2024-06-27',
          searchVolume: 345,
          organicRank: 143
        },
        {
          date: '2024-06-26',
          searchVolume: 345,
          organicRank: 91
        },
        {
          date: '2024-06-25',
          searchVolume: 345,
          organicRank: 159
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats headphones sale`,
      keyword: 'beats headphones sale',
      searchVolume: 144,
      organicStartRank: 119,
      organicHighestRank: 95,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-25',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-23',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-21',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-19',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-17',
          searchVolume: 304,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 353,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-15',
          searchVolume: 353,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 353,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-13',
          searchVolume: 353,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 353,
          organicRank: 141,

          keywordSales: 1
        },
        {
          date: '2024-08-11',
          searchVolume: 353,
          organicRank: 144,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 353,
          organicRank: 140,

          keywordSales: 1
        },
        {
          date: '2024-08-09',
          searchVolume: 311,
          organicRank: 131,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 311,
          organicRank: 133,

          keywordSales: 1
        },
        {
          date: '2024-08-07',
          searchVolume: 311,
          organicRank: 154,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 311,
          organicRank: 147,

          keywordSales: 1
        },
        {
          date: '2024-08-05',
          searchVolume: 311,
          organicRank: 138,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 311,
          organicRank: 160,

          keywordSales: 1
        },
        {
          date: '2024-08-03',
          searchVolume: 311,
          organicRank: 153,

          keywordSales: 1
        },
        {
          date: '2024-08-01',
          searchVolume: 412,
          organicRank: 162,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 412,
          organicRank: 153,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 412,
          organicRank: 144,

          keywordSales: 1
        },
        {
          date: '2024-07-29',
          searchVolume: 412,
          organicRank: 155,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 412,
          organicRank: 167,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 412,
          organicRank: 172,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 2280,
          organicRank: 157,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 2280,
          organicRank: 154,

          keywordSales: 1
        },
        {
          date: '2024-07-24',
          searchVolume: 2280,
          organicRank: 163,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 2280,
          organicRank: 147,

          keywordSales: 1
        },
        {
          date: '2024-07-22',
          searchVolume: 2280,
          organicRank: 153,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 2280,
          organicRank: 147,

          keywordSales: 1
        },
        {
          date: '2024-07-20',
          searchVolume: 2280,
          organicRank: 145,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 410,
          organicRank: 143,

          keywordSales: 1
        },
        {
          date: '2024-07-18',
          searchVolume: 410,
          organicRank: 148,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 410,
          organicRank: 138,

          keywordSales: 1
        },
        {
          date: '2024-07-16',
          searchVolume: 410,
          organicRank: 153,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 410,
          organicRank: 132,

          keywordSales: 1
        },
        {
          date: '2024-07-14',
          searchVolume: 410,
          organicRank: 138,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 410,
          organicRank: 135,

          keywordSales: 1
        },
        {
          date: '2024-07-12',
          searchVolume: 425,
          organicRank: 132,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 425,
          organicRank: 139,

          keywordSales: 1
        },
        {
          date: '2024-07-10',
          searchVolume: 425,
          organicRank: 122,

          keywordSales: 1
        },
        {
          date: '2024-07-09',
          searchVolume: 425,
          organicRank: 133,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 425,
          organicRank: 134,

          keywordSales: 1
        },
        {
          date: '2024-07-07',
          searchVolume: 425,
          organicRank: 131,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 425,
          organicRank: 124,

          keywordSales: 1
        },
        {
          date: '2024-07-05',
          searchVolume: 434,
          organicRank: 95,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 434,
          organicRank: 127,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 434,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 434,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 434,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 434,
          organicRank: 116,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 434,
          organicRank: 196,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 363,
          organicRank: 123,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 363,
          organicRank: 113,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 363,
          organicRank: 125,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats headphones wireless bluetooth`,
      keyword: 'beats headphones wireless bluetooth',
      searchVolume: 360659,
      organicStartRank: 306,
      organicHighestRank: 222,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-25',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-24',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-23',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-22',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-21',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-19',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-18',
          searchVolume: 22065,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-16',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-15',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-14',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-13',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-12',
          searchVolume: 30652,
          organicRank: 232,

          keywordSales: 346
        },
        {
          date: '2024-08-11',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-10',
          searchVolume: 30652,
          organicRank: 271,

          keywordSales: 346
        },
        {
          date: '2024-08-09',
          searchVolume: 79015,
          organicRank: 273,

          keywordSales: 346
        },
        {
          date: '2024-08-08',
          searchVolume: 79015,
          organicRank: 222,

          keywordSales: 346
        },
        {
          date: '2024-08-07',
          searchVolume: 79015,
          organicRank: 255,

          keywordSales: 346
        },
        {
          date: '2024-08-06',
          searchVolume: 79015,
          organicRank: 263,

          keywordSales: 346
        },
        {
          date: '2024-08-05',
          searchVolume: 79015,
          organicRank: 237,

          keywordSales: 346
        },
        {
          date: '2024-08-04',
          searchVolume: 79015,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-03',
          searchVolume: 79015,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-08-01',
          searchVolume: 155951,
          organicRank: 280,

          keywordSales: 346
        },
        {
          date: '2024-07-31',
          searchVolume: 155951,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-30',
          searchVolume: 155951,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-29',
          searchVolume: 155951,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-28',
          searchVolume: 155951,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-27',
          searchVolume: 155951,
          organicRank: 274,

          keywordSales: 346
        },
        {
          date: '2024-07-26',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-25',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-24',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-23',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-22',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-21',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-20',
          searchVolume: 360659,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-19',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-18',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-17',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-16',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-15',
          searchVolume: 181629,
          organicRank: 281,

          keywordSales: 346
        },
        {
          date: '2024-07-14',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-13',
          searchVolume: 181629,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-12',
          searchVolume: 161539,
          organicRank: 287,

          keywordSales: 346
        },
        {
          date: '2024-07-11',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-10',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-09',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-08',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-07',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-06',
          searchVolume: 161539,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-05',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-04',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-03',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-02',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-07-01',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-30',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-29',
          searchVolume: 183016,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-28',
          searchVolume: 182313,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-27',
          searchVolume: 182313,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-26',
          searchVolume: 182313,
          organicRank: 306,

          keywordSales: 346
        },
        {
          date: '2024-06-25',
          searchVolume: 182313,
          organicRank: 306,

          keywordSales: 346
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats on sale`,
      keyword: 'beats on sale',
      searchVolume: 2398,
      organicStartRank: 144,
      organicHighestRank: 100,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1442,
          organicRank: 179,

          keywordSales: 7
        },
        {
          date: '2024-08-25',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-24',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-23',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-22',
          searchVolume: 1442,
          organicRank: 187,

          keywordSales: 7
        },
        {
          date: '2024-08-21',
          searchVolume: 1442,
          organicRank: 189,

          keywordSales: 7
        },
        {
          date: '2024-08-20',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-19',
          searchVolume: 1442,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 7
        },
        {
          date: '2024-08-17',
          searchVolume: 1442,
          organicRank: 163,

          keywordSales: 7
        },
        {
          date: '2024-08-16',
          searchVolume: 1166,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-15',
          searchVolume: 1166,
          organicRank: 168,

          keywordSales: 7
        },
        {
          date: '2024-08-14',
          searchVolume: 1166,
          organicRank: 160,

          keywordSales: 7
        },
        {
          date: '2024-08-13',
          searchVolume: 1166,
          organicRank: 160,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-08-12',
          searchVolume: 1166,
          organicRank: 160,

          keywordSales: 7
        },
        {
          date: '2024-08-11',
          searchVolume: 1166,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-08-10',
          searchVolume: 1166,
          organicRank: 161,

          keywordSales: 7
        },
        {
          date: '2024-08-09',
          searchVolume: 886,
          organicRank: 153,

          keywordSales: 7
        },
        {
          date: '2024-08-08',
          searchVolume: 886,
          organicRank: 155,

          keywordSales: 7
        },
        {
          date: '2024-08-07',
          searchVolume: 886,
          organicRank: 158,

          keywordSales: 7
        },
        {
          date: '2024-08-06',
          searchVolume: 886,
          organicRank: 158,

          keywordSales: 7
        },
        {
          date: '2024-08-05',
          searchVolume: 886,
          organicRank: 154,

          keywordSales: 7
        },
        {
          date: '2024-08-04',
          searchVolume: 886,
          organicRank: 162,

          keywordSales: 7
        },
        {
          date: '2024-08-03',
          searchVolume: 886,
          organicRank: 160,

          keywordSales: 7
        },
        {
          date: '2024-08-01',
          searchVolume: 1302,
          organicRank: 164,

          keywordSales: 7
        },
        {
          date: '2024-07-31',
          searchVolume: 1302,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-07-30',
          searchVolume: 1302,
          organicRank: 156,

          keywordSales: 7
        },
        {
          date: '2024-07-29',
          searchVolume: 1302,
          organicRank: 162,

          keywordSales: 7
        },
        {
          date: '2024-07-28',
          searchVolume: 1302,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-07-27',
          searchVolume: 1302,
          organicRank: 154,

          keywordSales: 7
        },
        {
          date: '2024-07-26',
          searchVolume: 1835,
          organicRank: 155,

          keywordSales: 7
        },
        {
          date: '2024-07-25',
          searchVolume: 1835,
          organicRank: 160,

          keywordSales: 7
        },
        {
          date: '2024-07-24',
          searchVolume: 1835,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-07-23',
          searchVolume: 1835,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-07-22',
          searchVolume: 1835,
          organicRank: 160,

          keywordSales: 7
        },
        {
          date: '2024-07-21',
          searchVolume: 1835,
          organicRank: 162,

          keywordSales: 7
        },
        {
          date: '2024-07-20',
          searchVolume: 1835,
          organicRank: 161,

          keywordSales: 7
        },
        {
          date: '2024-07-19',
          searchVolume: 2113,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-07-18',
          searchVolume: 2113,
          organicRank: 161,

          keywordSales: 7
        },
        {
          date: '2024-07-17',
          searchVolume: 2113,
          organicRank: 153,

          keywordSales: 7
        },
        {
          date: '2024-07-16',
          searchVolume: 2113,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-07-15',
          searchVolume: 2113,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-07-14',
          searchVolume: 2113,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-07-13',
          searchVolume: 2113,
          organicRank: 155,

          keywordSales: 7
        },
        {
          date: '2024-07-12',
          searchVolume: 2249,
          organicRank: 155,

          keywordSales: 7
        },
        {
          date: '2024-07-11',
          searchVolume: 2249,
          organicRank: 153,

          keywordSales: 7
        },
        {
          date: '2024-07-10',
          searchVolume: 2249,
          organicRank: 150,

          keywordSales: 7
        },
        {
          date: '2024-07-09',
          searchVolume: 2249,
          organicRank: 151,

          keywordSales: 7
        },
        {
          date: '2024-07-08',
          searchVolume: 2249,
          organicRank: 151,

          keywordSales: 7
        },
        {
          date: '2024-07-07',
          searchVolume: 2249,
          organicRank: 150,

          keywordSales: 7
        },
        {
          date: '2024-07-06',
          searchVolume: 2249,
          organicRank: 144,

          keywordSales: 7
        },
        {
          date: '2024-07-05',
          searchVolume: 2552,
          organicRank: 136,

          keywordSales: 7
        },
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 142,

          keywordSales: 7
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 162,

          keywordSales: 7
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 143,

          keywordSales: 7
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 149,

          keywordSales: 7
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-06-28',
          searchVolume: 2807,
          organicRank: 131,

          keywordSales: 7
        },
        {
          date: '2024-06-27',
          searchVolume: 2807,
          organicRank: 139,

          keywordSales: 7
        },
        {
          date: '2024-06-26',
          searchVolume: 2807,
          organicRank: 143,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_beats plus earbuds`,
      keyword: 'beats plus earbuds',
      searchVolume: 157,
      organicStartRank: 102,
      organicHighestRank: 46,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 284,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-24',
          searchVolume: 284,
          organicRank: 140,

          keywordSales: 8
        },
        {
          date: '2024-08-23',
          searchVolume: 284,
          organicRank: 78,

          keywordSales: 8
        },
        {
          date: '2024-08-22',
          searchVolume: 284,
          organicRank: 82,

          keywordSales: 8
        },
        {
          date: '2024-08-21',
          searchVolume: 284,
          organicRank: 147,

          keywordSales: 8
        },
        {
          date: '2024-08-20',
          searchVolume: 284,
          organicRank: 77,

          keywordSales: 8
        },
        {
          date: '2024-08-19',
          searchVolume: 284,
          organicRank: 260,

          keywordSales: 8
        },
        {
          date: '2024-08-17',
          searchVolume: 284,
          organicRank: 86,

          keywordSales: 8
        },
        {
          date: '2024-08-16',
          searchVolume: 305,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-08-15',
          searchVolume: 305,
          organicRank: 81,

          keywordSales: 8
        },
        {
          date: '2024-08-14',
          searchVolume: 305,
          organicRank: 89,

          keywordSales: 8
        },
        {
          date: '2024-08-13',
          searchVolume: 305,
          organicRank: 77,

          keywordSales: 8
        },
        {
          date: '2024-08-12',
          searchVolume: 305,
          organicRank: 69,

          keywordSales: 8
        },
        {
          date: '2024-08-11',
          searchVolume: 305,
          organicRank: 66,

          keywordSales: 8
        },
        {
          date: '2024-08-10',
          searchVolume: 305,
          organicRank: 72,

          keywordSales: 8
        },
        {
          date: '2024-08-09',
          searchVolume: 311,
          organicRank: 50,

          keywordSales: 8
        },
        {
          date: '2024-08-08',
          searchVolume: 311,
          organicRank: 55,

          keywordSales: 8
        },
        {
          date: '2024-08-07',
          searchVolume: 311,
          organicRank: 59,

          keywordSales: 8
        },
        {
          date: '2024-08-06',
          searchVolume: 311,
          organicRank: 46,

          keywordSales: 8
        },
        {
          date: '2024-08-05',
          searchVolume: 311,
          organicRank: 51,

          keywordSales: 8
        },
        {
          date: '2024-08-04',
          searchVolume: 311,
          organicRank: 64,

          keywordSales: 8
        },
        {
          date: '2024-08-03',
          searchVolume: 311,
          organicRank: 56,

          keywordSales: 8
        },
        {
          date: '2024-08-01',
          searchVolume: 307,
          organicRank: 66,

          keywordSales: 8
        },
        {
          date: '2024-07-31',
          searchVolume: 307,
          organicRank: 52,

          keywordSales: 8
        },
        {
          date: '2024-07-30',
          searchVolume: 307,
          organicRank: 67,

          keywordSales: 8
        },
        {
          date: '2024-07-29',
          searchVolume: 307,
          organicRank: 66,

          keywordSales: 8
        },
        {
          date: '2024-07-28',
          searchVolume: 307,
          organicRank: 59,

          keywordSales: 8
        },
        {
          date: '2024-07-27',
          searchVolume: 307,
          organicRank: 59,

          keywordSales: 8
        },
        {
          date: '2024-07-26',
          searchVolume: 426,
          organicRank: 62,

          keywordSales: 8
        },
        {
          date: '2024-07-25',
          searchVolume: 426,
          organicRank: 62,

          keywordSales: 8
        },
        {
          date: '2024-07-24',
          searchVolume: 426,
          organicRank: 70,

          keywordSales: 8
        },
        {
          date: '2024-07-23',
          searchVolume: 426,
          organicRank: 64,

          keywordSales: 8
        },
        {
          date: '2024-07-22',
          searchVolume: 426,
          organicRank: 69,

          keywordSales: 8
        },
        {
          date: '2024-07-21',
          searchVolume: 426,
          organicRank: 77,

          keywordSales: 8
        },
        {
          date: '2024-07-20',
          searchVolume: 426,
          organicRank: 71,

          keywordSales: 8
        },
        {
          date: '2024-07-19',
          searchVolume: 182,
          organicRank: 69,

          keywordSales: 8
        },
        {
          date: '2024-07-18',
          searchVolume: 182,
          organicRank: 73,

          keywordSales: 8
        },
        {
          date: '2024-07-17',
          searchVolume: 182,
          organicRank: 72,

          keywordSales: 8
        },
        {
          date: '2024-07-16',
          searchVolume: 182,
          organicRank: 74,

          keywordSales: 8
        },
        {
          date: '2024-07-15',
          searchVolume: 182,
          organicRank: 63,

          keywordSales: 8
        },
        {
          date: '2024-07-14',
          searchVolume: 182,
          organicRank: 68,

          keywordSales: 8
        },
        {
          date: '2024-07-13',
          searchVolume: 182,
          organicRank: 73,

          keywordSales: 8
        },
        {
          date: '2024-07-12',
          searchVolume: 149,
          organicRank: 79,

          keywordSales: 8
        },
        {
          date: '2024-07-11',
          searchVolume: 149,
          organicRank: 83,

          keywordSales: 8
        },
        {
          date: '2024-07-10',
          searchVolume: 149,
          organicRank: 78,

          keywordSales: 8
        },
        {
          date: '2024-07-09',
          searchVolume: 149,
          organicRank: 78,

          keywordSales: 8
        },
        {
          date: '2024-07-08',
          searchVolume: 149,
          organicRank: 75,

          keywordSales: 8
        },
        {
          date: '2024-07-07',
          searchVolume: 149,
          organicRank: 68,

          keywordSales: 8
        },
        {
          date: '2024-07-06',
          searchVolume: 149,
          organicRank: 76,

          keywordSales: 8
        },
        {
          date: '2024-07-05',
          searchVolume: 195,
          organicRank: 102,

          keywordSales: 8
        },
        {
          date: '2024-07-04',
          searchVolume: 195,
          organicRank: 72,

          keywordSales: 8
        },
        {
          date: '2024-07-03',
          searchVolume: 195,
          organicRank: 99,

          keywordSales: 8
        },
        {
          date: '2024-07-02',
          searchVolume: 195,
          organicRank: 82,

          keywordSales: 8
        },
        {
          date: '2024-07-01',
          searchVolume: 195,
          organicRank: 80,

          keywordSales: 8
        },
        {
          date: '2024-06-30',
          searchVolume: 195,
          organicRank: 96,

          keywordSales: 8
        },
        {
          date: '2024-06-29',
          searchVolume: 195,
          organicRank: 66,

          keywordSales: 8
        },
        {
          date: '2024-06-28',
          searchVolume: 144,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-27',
          searchVolume: 144,
          organicRank: 102,

          keywordSales: 8
        },
        {
          date: '2024-06-26',
          searchVolume: 144,
          organicRank: 83,

          keywordSales: 8
        },
        {
          date: '2024-06-25',
          searchVolume: 144,
          organicRank: 107,

          keywordSales: 8
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_best earbuds`,
      keyword: 'best earbuds',
      searchVolume: 1443,
      organicStartRank: 306,
      organicHighestRank: 61,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1443,
          organicRank: 69,

          keywordSales: 22
        },
        {
          date: '2024-08-25',
          searchVolume: 1443,
          organicRank: 69,

          keywordSales: 22
        },
        {
          date: '2024-08-24',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-23',
          searchVolume: 1443,
          organicRank: 74,

          keywordSales: 22
        },
        {
          date: '2024-08-22',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-21',
          searchVolume: 1443,
          organicRank: 72,

          keywordSales: 22
        },
        {
          date: '2024-08-20',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-19',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-17',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 61,

          keywordSales: 22
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 69,

          keywordSales: 22
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-09',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-08',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-07',
          searchVolume: 1346,
          organicRank: 68,

          keywordSales: 22
        },
        {
          date: '2024-08-06',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-05',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-04',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-03',
          searchVolume: 1346,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-08-01',
          searchVolume: 1331,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-31',
          searchVolume: 1331,
          organicRank: 82,

          keywordSales: 22
        },
        {
          date: '2024-07-30',
          searchVolume: 1331,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-29',
          searchVolume: 1331,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-28',
          searchVolume: 1331,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-27',
          searchVolume: 1331,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-26',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-25',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-24',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-23',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-22',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-21',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-20',
          searchVolume: 2287,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-19',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-18',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-17',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-16',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-15',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-14',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-13',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-12',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-11',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-10',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-09',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-08',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-07',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-06',
          searchVolume: 1536,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-05',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-04',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-03',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-02',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-07-01',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-30',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-29',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-28',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-27',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-26',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 22
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_best wireless earbuds`,
      keyword: 'best wireless earbuds',
      searchVolume: 1934,
      organicStartRank: 306,
      organicHighestRank: 47,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1919,
          organicRank: 67,

          keywordSales: 19
        },
        {
          date: '2024-08-25',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-24',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-23',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-22',
          searchVolume: 1919,
          organicRank: 78,

          keywordSales: 19
        },
        {
          date: '2024-08-21',
          searchVolume: 1919,
          organicRank: 83,

          keywordSales: 19
        },
        {
          date: '2024-08-20',
          searchVolume: 1919,
          organicRank: 86,

          keywordSales: 19
        },
        {
          date: '2024-08-19',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-17',
          searchVolume: 1919,
          organicRank: 47,

          keywordSales: 19
        },
        {
          date: '2024-08-16',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-15',
          searchVolume: 1586,
          organicRank: 77,

          keywordSales: 19
        },
        {
          date: '2024-08-14',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-13',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-12',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-11',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-10',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-09',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-08',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-07',
          searchVolume: 1547,
          organicRank: 78,

          keywordSales: 19
        },
        {
          date: '2024-08-06',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-05',
          searchVolume: 1547,
          organicRank: 68,

          keywordSales: 19
        },
        {
          date: '2024-08-04',
          searchVolume: 1547,
          organicRank: 90,

          keywordSales: 19
        },
        {
          date: '2024-08-03',
          searchVolume: 1547,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-02',
          searchVolume: 1770,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-01',
          searchVolume: 1770,
          organicRank: 95,

          keywordSales: 19
        },
        {
          date: '2024-07-30',
          searchVolume: 1770,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-29',
          searchVolume: 1770,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-28',
          searchVolume: 1770,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-27',
          searchVolume: 1770,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-26',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-25',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-24',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-23',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-22',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-21',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-20',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-19',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-18',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-17',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-16',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-15',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-14',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-13',
          searchVolume: 1804,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-12',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-11',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-10',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-09',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-08',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-07',
          searchVolume: 1835,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-06',
          searchVolume: 1835,
          organicRank: 169,

          keywordSales: 19
        },
        {
          date: '2024-07-05',
          searchVolume: 1930,
          organicRank: 146,

          keywordSales: 19
        },
        {
          date: '2024-07-04',
          searchVolume: 1930,
          organicRank: 147,

          keywordSales: 19
        },
        {
          date: '2024-07-03',
          searchVolume: 1930,
          organicRank: 135,

          keywordSales: 19
        },
        {
          date: '2024-07-02',
          searchVolume: 1930,
          organicRank: 127,

          keywordSales: 19
        },
        {
          date: '2024-07-01',
          searchVolume: 1930,
          organicRank: 118,

          keywordSales: 19
        },
        {
          date: '2024-06-30',
          searchVolume: 1930,
          organicRank: 129,

          keywordSales: 19
        },
        {
          date: '2024-06-29',
          searchVolume: 1930,
          organicRank: 143,

          keywordSales: 19
        },
        {
          date: '2024-06-28',
          searchVolume: 1907,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-27',
          searchVolume: 1907,
          organicRank: 126,

          keywordSales: 19
        },
        {
          date: '2024-06-26',
          searchVolume: 1907,
          organicRank: 306,

          keywordSales: 19
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_black beats earbuds`,
      keyword: 'black beats earbuds',
      searchVolume: 223,
      organicStartRank: 92,
      organicHighestRank: 38,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 24,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 206,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 206,
          organicRank: 106
        },
        {
          date: '2024-08-23',
          searchVolume: 206,
          organicRank: 101
        },
        {
          date: '2024-08-22',
          searchVolume: 206,
          organicRank: 92
        },
        {
          date: '2024-08-21',
          searchVolume: 206,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 206,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 206,
          organicRank: 122,
          sponsoredRank: 32
        },
        {
          date: '2024-08-17',
          searchVolume: 206,
          organicRank: 126
        },
        {
          date: '2024-08-16',
          searchVolume: 179,
          organicRank: 86
        },
        {
          date: '2024-08-15',
          searchVolume: 179,
          organicRank: 94
        },
        {
          date: '2024-08-14',
          searchVolume: 179,
          organicRank: 80
        },
        {
          date: '2024-08-13',
          searchVolume: 179,
          organicRank: 71
        },
        {
          date: '2024-08-12',
          searchVolume: 179,
          organicRank: 68
        },
        {
          date: '2024-08-11',
          searchVolume: 179,
          organicRank: 69
        },
        {
          date: '2024-08-10',
          searchVolume: 179,
          organicRank: 70,
          sponsoredRank: 24
        },
        {
          date: '2024-08-09',
          searchVolume: 207,
          organicRank: 67
        },
        {
          date: '2024-08-08',
          searchVolume: 207,
          organicRank: 68
        },
        {
          date: '2024-08-07',
          searchVolume: 207,
          organicRank: 68
        },
        {
          date: '2024-08-06',
          searchVolume: 207,
          organicRank: 56
        },
        {
          date: '2024-08-05',
          searchVolume: 207,
          organicRank: 69
        },
        {
          date: '2024-08-04',
          searchVolume: 207,
          organicRank: 69
        },
        {
          date: '2024-08-02',
          searchVolume: 224,
          organicRank: 68
        },
        {
          date: '2024-08-01',
          searchVolume: 224,
          organicRank: 80
        },
        {
          date: '2024-07-31',
          searchVolume: 224,
          organicRank: 65
        },
        {
          date: '2024-07-30',
          searchVolume: 224,
          organicRank: 70
        },
        {
          date: '2024-07-29',
          searchVolume: 224,
          organicRank: 80
        },
        {
          date: '2024-07-28',
          searchVolume: 224,
          organicRank: 76
        },
        {
          date: '2024-07-27',
          searchVolume: 224,
          organicRank: 73
        },
        {
          date: '2024-07-26',
          searchVolume: 180,
          organicRank: 70
        },
        {
          date: '2024-07-25',
          searchVolume: 180,
          organicRank: 76
        },
        {
          date: '2024-07-24',
          searchVolume: 180,
          organicRank: 61
        },
        {
          date: '2024-07-23',
          searchVolume: 180,
          organicRank: 65
        },
        {
          date: '2024-07-22',
          searchVolume: 180,
          organicRank: 65
        },
        {
          date: '2024-07-21',
          searchVolume: 180,
          organicRank: 63
        },
        {
          date: '2024-07-20',
          searchVolume: 180,
          organicRank: 64
        },
        {
          date: '2024-07-19',
          searchVolume: 227,
          organicRank: 65
        },
        {
          date: '2024-07-18',
          searchVolume: 227,
          organicRank: 68
        },
        {
          date: '2024-07-17',
          searchVolume: 227,
          organicRank: 306
        },
        {
          date: '2024-07-16',
          searchVolume: 227,
          organicRank: 62
        },
        {
          date: '2024-07-15',
          searchVolume: 227,
          organicRank: 55
        },
        {
          date: '2024-07-14',
          searchVolume: 227,
          organicRank: 58
        },
        {
          date: '2024-07-13',
          searchVolume: 227,
          organicRank: 56
        },
        {
          date: '2024-07-12',
          searchVolume: 270,
          organicRank: 57
        },
        {
          date: '2024-07-11',
          searchVolume: 270,
          organicRank: 60
        },
        {
          date: '2024-07-10',
          searchVolume: 270,
          organicRank: 61
        },
        {
          date: '2024-07-09',
          searchVolume: 270,
          organicRank: 59
        },
        {
          date: '2024-07-08',
          searchVolume: 270,
          organicRank: 59
        },
        {
          date: '2024-07-07',
          searchVolume: 270,
          organicRank: 58
        },
        {
          date: '2024-07-06',
          searchVolume: 270,
          organicRank: 50
        },
        {
          date: '2024-07-05',
          searchVolume: 247,
          organicRank: 51
        },
        {
          date: '2024-07-04',
          searchVolume: 247,
          organicRank: 56
        },
        {
          date: '2024-07-03',
          searchVolume: 247,
          organicRank: 54
        },
        {
          date: '2024-07-02',
          searchVolume: 247,
          organicRank: 46
        },
        {
          date: '2024-07-01',
          searchVolume: 247,
          organicRank: 56
        },
        {
          date: '2024-06-30',
          searchVolume: 247,
          organicRank: 52
        },
        {
          date: '2024-06-29',
          searchVolume: 247,
          organicRank: 53
        },
        {
          date: '2024-06-28',
          searchVolume: 343,
          organicRank: 54
        },
        {
          date: '2024-06-27',
          searchVolume: 343,
          organicRank: 45
        },
        {
          date: '2024-06-26',
          searchVolume: 343,
          organicRank: 56
        },
        {
          date: '2024-06-25',
          searchVolume: 343,
          organicRank: 54
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_black earbud`,
      keyword: 'black earbud',
      searchVolume: 44,
      organicStartRank: 306,
      organicHighestRank: 61,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-24',
          searchVolume: 37,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 37,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 37,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-18',
          searchVolume: 37,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 45,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 45,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 45,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 45,
          organicRank: 185,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 67,
          organicRank: 190,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 67,
          organicRank: 169,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 67,
          organicRank: 134,

          keywordSales: 1
        },
        {
          date: '2024-08-02',
          searchVolume: 69,
          organicRank: 154,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 69,
          organicRank: 119,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 69,
          organicRank: 148,

          keywordSales: 1
        },
        {
          date: '2024-07-29',
          searchVolume: 69,
          organicRank: 172,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 69,
          organicRank: 148,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 69,
          organicRank: 165,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 106,
          organicRank: 159,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 106,
          organicRank: 144,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 106,
          organicRank: 122,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 106,
          organicRank: 105,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 82,
          organicRank: 111,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 82,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 82,
          organicRank: 107,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 82,
          organicRank: 89,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 62,
          organicRank: 79,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 62,
          organicRank: 82,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 62,
          organicRank: 69,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 50,
          organicRank: 63,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 50,
          organicRank: 61,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 50,
          organicRank: 63,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 68,
          organicRank: 93,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 68,
          organicRank: 86,

          keywordSales: 1
        },
        {
          date: '2024-06-24',
          searchVolume: 68,
          organicRank: 82,

          keywordSales: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 57,
          organicRank: 124,

          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 57,
          organicRank: 92,

          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 57,
          organicRank: 76,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 57,
          organicRank: 62,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 49,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 49,
          organicRank: 306,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_costzon wireless earbuds`,
      keyword: 'costzon wireless earbuds',
      searchVolume: 234,
      organicStartRank: 306,
      organicHighestRank: 277,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 234,
          organicRank: 277
        },
        {
          date: '2024-08-20',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 234,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 184,
          organicRank: 280
        },
        {
          date: '2024-08-14',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-13',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-12',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-11',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-10',
          searchVolume: 184,
          organicRank: 306
        },
        {
          date: '2024-08-09',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-08',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-07',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-06',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-05',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-04',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-03',
          searchVolume: 258,
          organicRank: 306
        },
        {
          date: '2024-08-01',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-31',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-30',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-29',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-28',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-27',
          searchVolume: 204,
          organicRank: 306
        },
        {
          date: '2024-07-26',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-25',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-24',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-23',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-22',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-21',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-20',
          searchVolume: 190,
          organicRank: 306
        },
        {
          date: '2024-07-19',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-18',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-17',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-16',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-15',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-14',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-13',
          searchVolume: 148,
          organicRank: 306
        },
        {
          date: '2024-07-12',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-11',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-10',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-09',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-08',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-07',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-06',
          searchVolume: 169,
          organicRank: 306
        },
        {
          date: '2024-07-05',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-07-04',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-07-03',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-07-02',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-07-01',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-06-30',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-06-29',
          searchVolume: 172,
          organicRank: 306
        },
        {
          date: '2024-06-28',
          searchVolume: 197,
          organicRank: 306
        },
        {
          date: '2024-06-27',
          searchVolume: 197,
          organicRank: 306
        },
        {
          date: '2024-06-26',
          searchVolume: 197,
          organicRank: 306
        },
        {
          date: '2024-06-25',
          searchVolume: 197,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_ear buds at amazon`,
      keyword: 'ear buds at amazon',
      searchVolume: 468,
      organicStartRank: 155,
      organicHighestRank: 77,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-24',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-23',
          searchVolume: 448,
          organicRank: 275,

          keywordSales: 7
        },
        {
          date: '2024-08-22',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-21',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-20',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-19',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-17',
          searchVolume: 448,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-16',
          searchVolume: 399,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-15',
          searchVolume: 399,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-14',
          searchVolume: 399,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-13',
          searchVolume: 399,
          organicRank: 299,

          keywordSales: 7
        },
        {
          date: '2024-08-12',
          searchVolume: 399,
          organicRank: 261,

          keywordSales: 7
        },
        {
          date: '2024-08-11',
          searchVolume: 399,
          organicRank: 287,

          keywordSales: 7
        },
        {
          date: '2024-08-10',
          searchVolume: 399,
          organicRank: 251,

          keywordSales: 7
        },
        {
          date: '2024-08-09',
          searchVolume: 390,
          organicRank: 194,

          keywordSales: 7
        },
        {
          date: '2024-08-08',
          searchVolume: 390,
          organicRank: 273,

          keywordSales: 7
        },
        {
          date: '2024-08-07',
          searchVolume: 390,
          organicRank: 164,

          keywordSales: 7
        },
        {
          date: '2024-08-06',
          searchVolume: 390,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-08-05',
          searchVolume: 390,
          organicRank: 179,

          keywordSales: 7
        },
        {
          date: '2024-08-04',
          searchVolume: 390,
          organicRank: 181,

          keywordSales: 7
        },
        {
          date: '2024-08-03',
          searchVolume: 390,
          organicRank: 181,

          keywordSales: 7
        },
        {
          date: '2024-08-01',
          searchVolume: 402,
          organicRank: 191,

          keywordSales: 7
        },
        {
          date: '2024-07-31',
          searchVolume: 402,
          organicRank: 182,

          keywordSales: 7
        },
        {
          date: '2024-07-30',
          searchVolume: 402,
          organicRank: 180,

          keywordSales: 7
        },
        {
          date: '2024-07-29',
          searchVolume: 402,
          organicRank: 195,

          keywordSales: 7
        },
        {
          date: '2024-07-28',
          searchVolume: 402,
          organicRank: 195,

          keywordSales: 7
        },
        {
          date: '2024-07-27',
          searchVolume: 402,
          organicRank: 156,

          keywordSales: 7
        },
        {
          date: '2024-07-26',
          searchVolume: 844,
          organicRank: 142,

          keywordSales: 7
        },
        {
          date: '2024-07-25',
          searchVolume: 844,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-07-24',
          searchVolume: 844,
          organicRank: 136,

          keywordSales: 7
        },
        {
          date: '2024-07-23',
          searchVolume: 844,
          organicRank: 136,

          keywordSales: 7
        },
        {
          date: '2024-07-22',
          searchVolume: 844,
          organicRank: 143,

          keywordSales: 7
        },
        {
          date: '2024-07-21',
          searchVolume: 844,
          organicRank: 134,

          keywordSales: 7
        },
        {
          date: '2024-07-20',
          searchVolume: 844,
          organicRank: 129,

          keywordSales: 7
        },
        {
          date: '2024-07-19',
          searchVolume: 527,
          organicRank: 110,

          keywordSales: 7
        },
        {
          date: '2024-07-18',
          searchVolume: 527,
          organicRank: 103,

          keywordSales: 7
        },
        {
          date: '2024-07-17',
          searchVolume: 527,
          organicRank: 156,

          keywordSales: 7
        },
        {
          date: '2024-07-16',
          searchVolume: 527,
          organicRank: 169,

          keywordSales: 7
        },
        {
          date: '2024-07-15',
          searchVolume: 527,
          organicRank: 158,

          keywordSales: 7
        },
        {
          date: '2024-07-14',
          searchVolume: 527,
          organicRank: 155,

          keywordSales: 7
        },
        {
          date: '2024-07-13',
          searchVolume: 527,
          organicRank: 150,

          keywordSales: 7
        },
        {
          date: '2024-07-12',
          searchVolume: 469,
          organicRank: 152,

          keywordSales: 7
        },
        {
          date: '2024-07-11',
          searchVolume: 469,
          organicRank: 148,

          keywordSales: 7
        },
        {
          date: '2024-07-10',
          searchVolume: 469,
          organicRank: 140,

          keywordSales: 7
        },
        {
          date: '2024-07-09',
          searchVolume: 469,
          organicRank: 171,

          keywordSales: 7
        },
        {
          date: '2024-07-08',
          searchVolume: 469,
          organicRank: 166,

          keywordSales: 7
        },
        {
          date: '2024-07-07',
          searchVolume: 469,
          organicRank: 132,

          keywordSales: 7
        },
        {
          date: '2024-07-06',
          searchVolume: 469,
          organicRank: 168,

          keywordSales: 7
        },
        {
          date: '2024-07-05',
          searchVolume: 489,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-07-04',
          searchVolume: 489,
          organicRank: 245,

          keywordSales: 7
        },
        {
          date: '2024-07-03',
          searchVolume: 489,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-07-02',
          searchVolume: 489,
          organicRank: 112,

          keywordSales: 7
        },
        {
          date: '2024-07-01',
          searchVolume: 489,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-30',
          searchVolume: 489,
          organicRank: 119,

          keywordSales: 7
        },
        {
          date: '2024-06-29',
          searchVolume: 489,
          organicRank: 152,

          keywordSales: 7
        },
        {
          date: '2024-06-28',
          searchVolume: 495,
          organicRank: 150,

          keywordSales: 7
        },
        {
          date: '2024-06-27',
          searchVolume: 495,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-26',
          searchVolume: 495,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-25',
          searchVolume: 495,
          organicRank: 306,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_earbuds`,
      keyword: 'earbuds',
      searchVolume: 227998,
      organicStartRank: 306,
      organicHighestRank: 119,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-25',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-24',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-23',
          searchVolume: 227998,
          organicRank: 133,

          keywordSales: 2567
        },
        {
          date: '2024-08-22',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-21',
          searchVolume: 227998,
          organicRank: 134,

          keywordSales: 2567
        },
        {
          date: '2024-08-20',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-19',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-18',
          searchVolume: 227998,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-16',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-15',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-14',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-13',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-12',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-11',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-10',
          searchVolume: 217435,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-09',
          searchVolume: 195762,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-08',
          searchVolume: 195762,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-07',
          searchVolume: 195762,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-06',
          searchVolume: 195762,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-05',
          searchVolume: 195762,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-04',
          searchVolume: 195762,
          organicRank: 119,

          keywordSales: 2567
        },
        {
          date: '2024-08-02',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-08-01',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-31',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-30',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-29',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-28',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-27',
          searchVolume: 194754,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-26',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-25',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-24',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-23',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-22',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-21',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-20',
          searchVolume: 358533,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-19',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-18',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-17',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-16',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-15',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-14',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-13',
          searchVolume: 158554,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-12',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-11',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-10',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-09',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-08',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-07',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-06',
          searchVolume: 161532,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-05',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-04',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-03',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-02',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-07-01',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-06-30',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-06-29',
          searchVolume: 169071,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-06-28',
          searchVolume: 168421,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-06-27',
          searchVolume: 168421,
          organicRank: 306,

          keywordSales: 2567
        },
        {
          date: '2024-06-26',
          searchVolume: 168421,
          organicRank: 306,

          keywordSales: 2567
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_earbuds anc`,
      keyword: 'earbuds anc',
      searchVolume: 468,
      organicStartRank: 306,
      organicHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 509,
          organicRank: 135,

          keywordSales: 11
        },
        {
          date: '2024-08-24',
          searchVolume: 509,
          organicRank: 138,

          keywordSales: 11
        },
        {
          date: '2024-08-23',
          searchVolume: 509,
          organicRank: 147,

          keywordSales: 11
        },
        {
          date: '2024-08-22',
          searchVolume: 509,
          organicRank: 120,

          keywordSales: 11
        },
        {
          date: '2024-08-21',
          searchVolume: 509,
          organicRank: 116,

          keywordSales: 11
        },
        {
          date: '2024-08-20',
          searchVolume: 509,
          organicRank: 106,

          keywordSales: 11
        },
        {
          date: '2024-08-19',
          searchVolume: 509,
          organicRank: 108,

          keywordSales: 11
        },
        {
          date: '2024-08-17',
          searchVolume: 509,
          organicRank: 106,

          keywordSales: 11
        },
        {
          date: '2024-08-16',
          searchVolume: 472,
          organicRank: 103,

          keywordSales: 11
        },
        {
          date: '2024-08-15',
          searchVolume: 472,
          organicRank: 121,

          keywordSales: 11
        },
        {
          date: '2024-08-14',
          searchVolume: 472,
          organicRank: 109,

          keywordSales: 11
        },
        {
          date: '2024-08-13',
          searchVolume: 472,
          organicRank: 86,

          keywordSales: 11
        },
        {
          date: '2024-08-12',
          searchVolume: 472,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-08-11',
          searchVolume: 472,
          organicRank: 90,

          keywordSales: 11
        },
        {
          date: '2024-08-10',
          searchVolume: 472,
          organicRank: 109,

          keywordSales: 11
        },
        {
          date: '2024-08-09',
          searchVolume: 559,
          organicRank: 80,

          keywordSales: 11
        },
        {
          date: '2024-08-08',
          searchVolume: 559,
          organicRank: 85,

          keywordSales: 11
        },
        {
          date: '2024-08-07',
          searchVolume: 559,
          organicRank: 76,

          keywordSales: 11
        },
        {
          date: '2024-08-06',
          searchVolume: 559,
          organicRank: 57,

          keywordSales: 11
        },
        {
          date: '2024-08-05',
          searchVolume: 559,
          organicRank: 45,

          keywordSales: 11
        },
        {
          date: '2024-08-04',
          searchVolume: 559,
          organicRank: 55,

          keywordSales: 11
        },
        {
          date: '2024-08-02',
          searchVolume: 470,
          organicRank: 58,

          keywordSales: 11
        },
        {
          date: '2024-08-01',
          searchVolume: 470,
          organicRank: 76,

          keywordSales: 11
        },
        {
          date: '2024-07-31',
          searchVolume: 470,
          organicRank: 71,

          keywordSales: 11
        },
        {
          date: '2024-07-30',
          searchVolume: 470,
          organicRank: 82,

          keywordSales: 11
        },
        {
          date: '2024-07-29',
          searchVolume: 470,
          organicRank: 81,

          keywordSales: 11
        },
        {
          date: '2024-07-28',
          searchVolume: 470,
          organicRank: 81,

          keywordSales: 11
        },
        {
          date: '2024-07-27',
          searchVolume: 470,
          organicRank: 76,

          keywordSales: 11
        },
        {
          date: '2024-07-26',
          searchVolume: 846,
          organicRank: 67,

          keywordSales: 11
        },
        {
          date: '2024-07-25',
          searchVolume: 846,
          organicRank: 59,

          keywordSales: 11
        },
        {
          date: '2024-07-24',
          searchVolume: 846,
          organicRank: 101,

          keywordSales: 11
        },
        {
          date: '2024-07-23',
          searchVolume: 846,
          organicRank: 102,

          keywordSales: 11
        },
        {
          date: '2024-07-22',
          searchVolume: 846,
          organicRank: 111,

          keywordSales: 11
        },
        {
          date: '2024-07-21',
          searchVolume: 846,
          organicRank: 112,

          keywordSales: 11
        },
        {
          date: '2024-07-20',
          searchVolume: 846,
          organicRank: 89,

          keywordSales: 11
        },
        {
          date: '2024-07-19',
          searchVolume: 476,
          organicRank: 108,

          keywordSales: 11
        },
        {
          date: '2024-07-18',
          searchVolume: 476,
          organicRank: 97,

          keywordSales: 11
        },
        {
          date: '2024-07-17',
          searchVolume: 476,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-07-16',
          searchVolume: 476,
          organicRank: 89,

          keywordSales: 11
        },
        {
          date: '2024-07-15',
          searchVolume: 476,
          organicRank: 83,

          keywordSales: 11
        },
        {
          date: '2024-07-14',
          searchVolume: 476,
          organicRank: 90,

          keywordSales: 11
        },
        {
          date: '2024-07-13',
          searchVolume: 476,
          organicRank: 85,

          keywordSales: 11
        },
        {
          date: '2024-07-12',
          searchVolume: 444,
          organicRank: 72,

          keywordSales: 11
        },
        {
          date: '2024-07-11',
          searchVolume: 444,
          organicRank: 61,

          keywordSales: 11
        },
        {
          date: '2024-07-10',
          searchVolume: 444,
          organicRank: 60,

          keywordSales: 11
        },
        {
          date: '2024-07-09',
          searchVolume: 444,
          organicRank: 66,

          keywordSales: 11
        },
        {
          date: '2024-07-08',
          searchVolume: 444,
          organicRank: 63,

          keywordSales: 11
        },
        {
          date: '2024-07-07',
          searchVolume: 444,
          organicRank: 54,

          keywordSales: 11
        },
        {
          date: '2024-07-06',
          searchVolume: 444,
          organicRank: 50,

          keywordSales: 11
        },
        {
          date: '2024-07-05',
          searchVolume: 506,
          organicRank: 40,

          keywordSales: 11
        },
        {
          date: '2024-07-04',
          searchVolume: 506,
          organicRank: 56,

          keywordSales: 11
        },
        {
          date: '2024-07-03',
          searchVolume: 506,
          organicRank: 36,

          keywordSales: 11
        },
        {
          date: '2024-07-02',
          searchVolume: 506,
          organicRank: 33,

          keywordSales: 11
        },
        {
          date: '2024-07-01',
          searchVolume: 506,
          organicRank: 35,

          keywordSales: 11
        },
        {
          date: '2024-06-30',
          searchVolume: 506,
          organicRank: 57,

          keywordSales: 11
        },
        {
          date: '2024-06-29',
          searchVolume: 506,
          organicRank: 49,

          keywordSales: 11
        },
        {
          date: '2024-06-28',
          searchVolume: 436,
          organicRank: 48,

          keywordSales: 11
        },
        {
          date: '2024-06-27',
          searchVolume: 436,
          organicRank: 40,

          keywordSales: 11
        },
        {
          date: '2024-06-26',
          searchVolume: 436,
          organicRank: 58,

          keywordSales: 11
        },
        {
          date: '2024-06-25',
          searchVolume: 436,
          organicRank: 32,

          keywordSales: 11
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_earbuds with anc`,
      keyword: 'earbuds with anc',
      searchVolume: 161,
      organicStartRank: 306,
      organicHighestRank: 22,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 252,
          organicRank: 133
        },
        {
          date: '2024-08-24',
          searchVolume: 252,
          organicRank: 141
        },
        {
          date: '2024-08-23',
          searchVolume: 252,
          organicRank: 258
        },
        {
          date: '2024-08-22',
          searchVolume: 252,
          organicRank: 112
        },
        {
          date: '2024-08-21',
          searchVolume: 252,
          organicRank: 132
        },
        {
          date: '2024-08-20',
          searchVolume: 252,
          organicRank: 115
        },
        {
          date: '2024-08-19',
          searchVolume: 252,
          organicRank: 114
        },
        {
          date: '2024-08-17',
          searchVolume: 252,
          organicRank: 116
        },
        {
          date: '2024-08-16',
          searchVolume: 322,
          organicRank: 102
        },
        {
          date: '2024-08-15',
          searchVolume: 322,
          organicRank: 119
        },
        {
          date: '2024-08-14',
          searchVolume: 322,
          organicRank: 121
        },
        {
          date: '2024-08-13',
          searchVolume: 322,
          organicRank: 102
        },
        {
          date: '2024-08-12',
          searchVolume: 322,
          organicRank: 100
        },
        {
          date: '2024-08-11',
          searchVolume: 322,
          organicRank: 103
        },
        {
          date: '2024-08-10',
          searchVolume: 322,
          organicRank: 101
        },
        {
          date: '2024-08-09',
          searchVolume: 259,
          organicRank: 105
        },
        {
          date: '2024-08-08',
          searchVolume: 259,
          organicRank: 105
        },
        {
          date: '2024-08-07',
          searchVolume: 259,
          organicRank: 83
        },
        {
          date: '2024-08-06',
          searchVolume: 259,
          organicRank: 61
        },
        {
          date: '2024-08-05',
          searchVolume: 259,
          organicRank: 63
        },
        {
          date: '2024-08-04',
          searchVolume: 259,
          organicRank: 70
        },
        {
          date: '2024-08-02',
          searchVolume: 284,
          organicRank: 70
        },
        {
          date: '2024-08-01',
          searchVolume: 284,
          organicRank: 74
        },
        {
          date: '2024-07-31',
          searchVolume: 284,
          organicRank: 69
        },
        {
          date: '2024-07-30',
          searchVolume: 284,
          organicRank: 67
        },
        {
          date: '2024-07-29',
          searchVolume: 284,
          organicRank: 100
        },
        {
          date: '2024-07-28',
          searchVolume: 284,
          organicRank: 85
        },
        {
          date: '2024-07-27',
          searchVolume: 284,
          organicRank: 76
        },
        {
          date: '2024-07-26',
          searchVolume: 371,
          organicRank: 64
        },
        {
          date: '2024-07-25',
          searchVolume: 371,
          organicRank: 69
        },
        {
          date: '2024-07-24',
          searchVolume: 371,
          organicRank: 86
        },
        {
          date: '2024-07-23',
          searchVolume: 371,
          organicRank: 96
        },
        {
          date: '2024-07-22',
          searchVolume: 371,
          organicRank: 89
        },
        {
          date: '2024-07-21',
          searchVolume: 371,
          organicRank: 88
        },
        {
          date: '2024-07-20',
          searchVolume: 371,
          organicRank: 92
        },
        {
          date: '2024-07-19',
          searchVolume: 113,
          organicRank: 84
        },
        {
          date: '2024-07-18',
          searchVolume: 113,
          organicRank: 82
        },
        {
          date: '2024-07-17',
          searchVolume: 113,
          organicRank: 306
        },
        {
          date: '2024-07-16',
          searchVolume: 113,
          organicRank: 77
        },
        {
          date: '2024-07-15',
          searchVolume: 113,
          organicRank: 74
        },
        {
          date: '2024-07-14',
          searchVolume: 113,
          organicRank: 83
        },
        {
          date: '2024-07-13',
          searchVolume: 113,
          organicRank: 84
        },
        {
          date: '2024-07-12',
          searchVolume: 110,
          organicRank: 72
        },
        {
          date: '2024-07-11',
          searchVolume: 110,
          organicRank: 88
        },
        {
          date: '2024-07-10',
          searchVolume: 110,
          organicRank: 64
        },
        {
          date: '2024-07-09',
          searchVolume: 110,
          organicRank: 74
        },
        {
          date: '2024-07-08',
          searchVolume: 110,
          organicRank: 71
        },
        {
          date: '2024-07-07',
          searchVolume: 110,
          organicRank: 51
        },
        {
          date: '2024-07-06',
          searchVolume: 110,
          organicRank: 53
        },
        {
          date: '2024-07-05',
          searchVolume: 164,
          organicRank: 72
        },
        {
          date: '2024-07-04',
          searchVolume: 164,
          organicRank: 51
        },
        {
          date: '2024-07-03',
          searchVolume: 164,
          organicRank: 57
        },
        {
          date: '2024-07-02',
          searchVolume: 164,
          organicRank: 67
        },
        {
          date: '2024-07-01',
          searchVolume: 164,
          organicRank: 62
        },
        {
          date: '2024-06-30',
          searchVolume: 164,
          organicRank: 50
        },
        {
          date: '2024-06-29',
          searchVolume: 164,
          organicRank: 61
        },
        {
          date: '2024-06-28',
          searchVolume: 141,
          organicRank: 72
        },
        {
          date: '2024-06-27',
          searchVolume: 141,
          organicRank: 55
        },
        {
          date: '2024-06-26',
          searchVolume: 141,
          organicRank: 64
        },
        {
          date: '2024-06-25',
          searchVolume: 141,
          organicRank: 94
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_earbuds with qi charging case`,
      keyword: 'earbuds with qi charging case',
      searchVolume: 416,
      organicStartRank: 306,
      organicHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 507,
          organicRank: 162,

          keywordSales: 3
        },
        {
          date: '2024-08-25',
          searchVolume: 507,
          organicRank: 159,

          keywordSales: 3
        },
        {
          date: '2024-08-24',
          searchVolume: 507,
          organicRank: 261,

          keywordSales: 3
        },
        {
          date: '2024-08-23',
          searchVolume: 507,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-22',
          searchVolume: 507,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-21',
          searchVolume: 507,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-20',
          searchVolume: 507,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-19',
          searchVolume: 507,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-17',
          searchVolume: 507,
          organicRank: 155,

          keywordSales: 3
        },
        {
          date: '2024-08-16',
          searchVolume: 475,
          organicRank: 157,

          keywordSales: 3
        },
        {
          date: '2024-08-15',
          searchVolume: 475,
          organicRank: 149,

          keywordSales: 3
        },
        {
          date: '2024-08-14',
          searchVolume: 475,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-13',
          searchVolume: 475,
          organicRank: 141,

          keywordSales: 3
        },
        {
          date: '2024-08-12',
          searchVolume: 475,
          organicRank: 114,

          keywordSales: 3
        },
        {
          date: '2024-08-11',
          searchVolume: 475,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-08-10',
          searchVolume: 475,
          organicRank: 140,

          keywordSales: 3
        },
        {
          date: '2024-08-09',
          searchVolume: 381,
          organicRank: 125,

          keywordSales: 3
        },
        {
          date: '2024-08-08',
          searchVolume: 381,
          organicRank: 136,

          keywordSales: 3
        },
        {
          date: '2024-08-07',
          searchVolume: 381,
          organicRank: 136,

          keywordSales: 3
        },
        {
          date: '2024-08-06',
          searchVolume: 381,
          organicRank: 119,

          keywordSales: 3
        },
        {
          date: '2024-08-05',
          searchVolume: 381,
          organicRank: 118,

          keywordSales: 3
        },
        {
          date: '2024-08-04',
          searchVolume: 381,
          organicRank: 141,

          keywordSales: 3
        },
        {
          date: '2024-08-03',
          searchVolume: 381,
          organicRank: 132,

          keywordSales: 3
        },
        {
          date: '2024-08-02',
          searchVolume: 439,
          organicRank: 160,

          keywordSales: 3
        },
        {
          date: '2024-08-01',
          searchVolume: 439,
          organicRank: 153,

          keywordSales: 3
        },
        {
          date: '2024-07-30',
          searchVolume: 439,
          organicRank: 114,

          keywordSales: 3
        },
        {
          date: '2024-07-29',
          searchVolume: 439,
          organicRank: 126,

          keywordSales: 3
        },
        {
          date: '2024-07-28',
          searchVolume: 439,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-27',
          searchVolume: 439,
          organicRank: 175,

          keywordSales: 3
        },
        {
          date: '2024-07-26',
          searchVolume: 644,
          organicRank: 163,

          keywordSales: 3
        },
        {
          date: '2024-07-25',
          searchVolume: 644,
          organicRank: 169,

          keywordSales: 3
        },
        {
          date: '2024-07-24',
          searchVolume: 644,
          organicRank: 166,

          keywordSales: 3
        },
        {
          date: '2024-07-23',
          searchVolume: 644,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-22',
          searchVolume: 644,
          organicRank: 158,

          keywordSales: 3
        },
        {
          date: '2024-07-21',
          searchVolume: 644,
          organicRank: 157,

          keywordSales: 3
        },
        {
          date: '2024-07-20',
          searchVolume: 644,
          organicRank: 152,

          keywordSales: 3
        },
        {
          date: '2024-07-19',
          searchVolume: 631,
          organicRank: 148,

          keywordSales: 3
        },
        {
          date: '2024-07-18',
          searchVolume: 631,
          organicRank: 152,

          keywordSales: 3
        },
        {
          date: '2024-07-17',
          searchVolume: 631,
          organicRank: 132,

          keywordSales: 3
        },
        {
          date: '2024-07-16',
          searchVolume: 631,
          organicRank: 151,

          keywordSales: 3
        },
        {
          date: '2024-07-15',
          searchVolume: 631,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-14',
          searchVolume: 631,
          organicRank: 139,

          keywordSales: 3
        },
        {
          date: '2024-07-13',
          searchVolume: 631,
          organicRank: 131,

          keywordSales: 3
        },
        {
          date: '2024-07-12',
          searchVolume: 452,
          organicRank: 141,

          keywordSales: 3
        },
        {
          date: '2024-07-11',
          searchVolume: 452,
          organicRank: 147,

          keywordSales: 3
        },
        {
          date: '2024-07-10',
          searchVolume: 452,
          organicRank: 134,

          keywordSales: 3
        },
        {
          date: '2024-07-09',
          searchVolume: 452,
          organicRank: 152,

          keywordSales: 3
        },
        {
          date: '2024-07-08',
          searchVolume: 452,
          organicRank: 130,

          keywordSales: 3
        },
        {
          date: '2024-07-07',
          searchVolume: 452,
          organicRank: 133,

          keywordSales: 3
        },
        {
          date: '2024-07-06',
          searchVolume: 452,
          organicRank: 136,

          keywordSales: 3
        },
        {
          date: '2024-07-05',
          searchVolume: 469,
          organicRank: 169,

          keywordSales: 3
        },
        {
          date: '2024-07-04',
          searchVolume: 469,
          organicRank: 190,

          keywordSales: 3
        },
        {
          date: '2024-07-03',
          searchVolume: 469,
          organicRank: 168,

          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 469,
          organicRank: 149,

          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 469,
          organicRank: 86,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 469,
          organicRank: 96,

          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 469,
          organicRank: 108,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 391,
          organicRank: 133,

          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 391,
          organicRank: 178,

          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 391,
          organicRank: 306,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_free pc`,
      keyword: 'free pc',
      searchVolume: 436,
      organicStartRank: 306,
      organicHighestRank: 2,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 673,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-13',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-12',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-11',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-10',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-08-09',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-08',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-07',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-06',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-05',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-04',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-03',
          searchVolume: 755,
          organicRank: 306
        },
        {
          date: '2024-08-01',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-31',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-30',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-29',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-28',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-27',
          searchVolume: 623,
          organicRank: 306
        },
        {
          date: '2024-07-26',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-25',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-24',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-23',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-22',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-21',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-20',
          searchVolume: 644,
          organicRank: 306
        },
        {
          date: '2024-07-19',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-18',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-17',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-16',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-15',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-14',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-13',
          searchVolume: 760,
          organicRank: 306
        },
        {
          date: '2024-07-12',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-11',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-10',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-09',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-08',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-07',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-06',
          searchVolume: 775,
          organicRank: 306
        },
        {
          date: '2024-07-05',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-07-04',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-07-03',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-07-02',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-07-01',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-06-30',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-06-29',
          searchVolume: 748,
          organicRank: 306
        },
        {
          date: '2024-06-28',
          searchVolume: 506,
          organicRank: 306
        },
        {
          date: '2024-06-27',
          searchVolume: 506,
          organicRank: 306
        },
        {
          date: '2024-06-26',
          searchVolume: 506,
          organicRank: 306
        },
        {
          date: '2024-06-25',
          searchVolume: 506,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_highest rated earbuds wireless`,
      keyword: 'highest rated earbuds wireless',
      searchVolume: 1432,
      organicStartRank: 306,
      organicHighestRank: 57,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1432,
          organicRank: 57,

          keywordSales: 21
        },
        {
          date: '2024-08-25',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-24',
          searchVolume: 1432,
          organicRank: 58,

          keywordSales: 21
        },
        {
          date: '2024-08-23',
          searchVolume: 1432,
          organicRank: 80,

          keywordSales: 21
        },
        {
          date: '2024-08-22',
          searchVolume: 1432,
          organicRank: 77,

          keywordSales: 21
        },
        {
          date: '2024-08-21',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-20',
          searchVolume: 1432,
          organicRank: 90,

          keywordSales: 21
        },
        {
          date: '2024-08-19',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-17',
          searchVolume: 1432,
          organicRank: 90,

          keywordSales: 21
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 78,

          keywordSales: 21
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-09',
          searchVolume: 1256,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-08',
          searchVolume: 1256,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-07',
          searchVolume: 1256,
          organicRank: 82,

          keywordSales: 21
        },
        {
          date: '2024-08-06',
          searchVolume: 1256,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-05',
          searchVolume: 1256,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-04',
          searchVolume: 1256,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-03',
          searchVolume: 1256,
          organicRank: 101,

          keywordSales: 21
        },
        {
          date: '2024-08-02',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 92,

          keywordSales: 21
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-26',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-25',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-24',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-23',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-22',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-21',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-20',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-19',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-18',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-17',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-16',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-15',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-14',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-13',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-12',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-11',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-10',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-09',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-08',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-07',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-06',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-05',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-28',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-27',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-26',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 21
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_jabra earbuds`,
      keyword: 'jabra earbuds',
      searchVolume: 19136,
      organicStartRank: 306,
      organicHighestRank: 154,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-25',
          searchVolume: 16648,
          organicRank: 166,

          keywordSales: 279
        },
        {
          date: '2024-08-24',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-23',
          searchVolume: 16648,
          organicRank: 265,

          keywordSales: 279
        },
        {
          date: '2024-08-22',
          searchVolume: 16648,
          organicRank: 286,

          keywordSales: 279
        },
        {
          date: '2024-08-21',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-19',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-18',
          searchVolume: 16648,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-16',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-15',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-14',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-13',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-12',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-11',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-10',
          searchVolume: 14974,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-09',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-08',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-07',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-06',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-05',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-04',
          searchVolume: 15534,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-02',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-08-01',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-31',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-30',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-29',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-28',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-27',
          searchVolume: 17658,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-26',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-25',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-24',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-23',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-22',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-21',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-20',
          searchVolume: 36147,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-19',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-18',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-17',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-16',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-15',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-14',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-13',
          searchVolume: 18668,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-12',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-11',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-10',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-09',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-08',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-07',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-06',
          searchVolume: 16841,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-05',
          searchVolume: 17685,
          organicRank: 164,

          keywordSales: 279
        },
        {
          date: '2024-07-04',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-03',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-02',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-07-01',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-06-30',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-06-29',
          searchVolume: 17685,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-06-28',
          searchVolume: 16584,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-06-27',
          searchVolume: 16584,
          organicRank: 306,

          keywordSales: 279
        },
        {
          date: '2024-06-26',
          searchVolume: 16584,
          organicRank: 161,

          keywordSales: 279
        },
        {
          date: '2024-06-25',
          searchVolume: 16584,
          organicRank: 306,

          keywordSales: 279
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_multipoint bluetooth earbuds`,
      keyword: 'multipoint bluetooth earbuds',
      searchVolume: 1660,
      organicStartRank: 306,
      organicHighestRank: 98,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-25',
          searchVolume: 1690,
          organicRank: 192,

          keywordSales: 19
        },
        {
          date: '2024-08-24',
          searchVolume: 1690,
          organicRank: 202,

          keywordSales: 19
        },
        {
          date: '2024-08-23',
          searchVolume: 1690,
          organicRank: 193,

          keywordSales: 19
        },
        {
          date: '2024-08-22',
          searchVolume: 1690,
          organicRank: 161,

          keywordSales: 19
        },
        {
          date: '2024-08-21',
          searchVolume: 1690,
          organicRank: 169,

          keywordSales: 19
        },
        {
          date: '2024-08-20',
          searchVolume: 1690,
          organicRank: 168,

          keywordSales: 19
        },
        {
          date: '2024-08-19',
          searchVolume: 1690,
          organicRank: 168,

          keywordSales: 19
        },
        {
          date: '2024-08-17',
          searchVolume: 1690,
          organicRank: 167,

          keywordSales: 19
        },
        {
          date: '2024-08-16',
          searchVolume: 1366,
          organicRank: 169,

          keywordSales: 19
        },
        {
          date: '2024-08-15',
          searchVolume: 1366,
          organicRank: 160,

          keywordSales: 19
        },
        {
          date: '2024-08-14',
          searchVolume: 1366,
          organicRank: 163,

          keywordSales: 19
        },
        {
          date: '2024-08-13',
          searchVolume: 1366,
          organicRank: 139,

          keywordSales: 19
        },
        {
          date: '2024-08-12',
          searchVolume: 1366,
          organicRank: 132,

          keywordSales: 19
        },
        {
          date: '2024-08-11',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-10',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-08-09',
          searchVolume: 1720,
          organicRank: 98,

          keywordSales: 19
        },
        {
          date: '2024-08-08',
          searchVolume: 1720,
          organicRank: 100,

          keywordSales: 19
        },
        {
          date: '2024-08-07',
          searchVolume: 1720,
          organicRank: 220,

          keywordSales: 19
        },
        {
          date: '2024-08-06',
          searchVolume: 1720,
          organicRank: 221,

          keywordSales: 19
        },
        {
          date: '2024-08-05',
          searchVolume: 1720,
          organicRank: 194,

          keywordSales: 19
        },
        {
          date: '2024-08-04',
          searchVolume: 1720,
          organicRank: 220,

          keywordSales: 19
        },
        {
          date: '2024-08-03',
          searchVolume: 1720,
          organicRank: 215,

          keywordSales: 19
        },
        {
          date: '2024-08-01',
          searchVolume: 1554,
          organicRank: 221,

          keywordSales: 19
        },
        {
          date: '2024-07-31',
          searchVolume: 1554,
          organicRank: 214,

          keywordSales: 19
        },
        {
          date: '2024-07-30',
          searchVolume: 1554,
          organicRank: 214,

          keywordSales: 19
        },
        {
          date: '2024-07-29',
          searchVolume: 1554,
          organicRank: 226,

          keywordSales: 19
        },
        {
          date: '2024-07-28',
          searchVolume: 1554,
          organicRank: 228,

          keywordSales: 19
        },
        {
          date: '2024-07-27',
          searchVolume: 1554,
          organicRank: 223,

          keywordSales: 19
        },
        {
          date: '2024-07-26',
          searchVolume: 1841,
          organicRank: 220,

          keywordSales: 19
        },
        {
          date: '2024-07-25',
          searchVolume: 1841,
          organicRank: 221,

          keywordSales: 19
        },
        {
          date: '2024-07-24',
          searchVolume: 1841,
          organicRank: 221,

          keywordSales: 19
        },
        {
          date: '2024-07-23',
          searchVolume: 1841,
          organicRank: 199,

          keywordSales: 19
        },
        {
          date: '2024-07-22',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-21',
          searchVolume: 1841,
          organicRank: 184,

          keywordSales: 19
        },
        {
          date: '2024-07-20',
          searchVolume: 1841,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-19',
          searchVolume: 1797,
          organicRank: 185,

          keywordSales: 19
        },
        {
          date: '2024-07-18',
          searchVolume: 1797,
          organicRank: 193,

          keywordSales: 19
        },
        {
          date: '2024-07-17',
          searchVolume: 1797,
          organicRank: 170,

          keywordSales: 19
        },
        {
          date: '2024-07-16',
          searchVolume: 1797,
          organicRank: 169,

          keywordSales: 19
        },
        {
          date: '2024-07-15',
          searchVolume: 1797,
          organicRank: 193,

          keywordSales: 19
        },
        {
          date: '2024-07-14',
          searchVolume: 1797,
          organicRank: 213,

          keywordSales: 19
        },
        {
          date: '2024-07-13',
          searchVolume: 1797,
          organicRank: 210,

          keywordSales: 19
        },
        {
          date: '2024-07-12',
          searchVolume: 1370,
          organicRank: 202,

          keywordSales: 19
        },
        {
          date: '2024-07-11',
          searchVolume: 1370,
          organicRank: 203,

          keywordSales: 19
        },
        {
          date: '2024-07-10',
          searchVolume: 1370,
          organicRank: 204,

          keywordSales: 19
        },
        {
          date: '2024-07-09',
          searchVolume: 1370,
          organicRank: 212,

          keywordSales: 19
        },
        {
          date: '2024-07-08',
          searchVolume: 1370,
          organicRank: 202,

          keywordSales: 19
        },
        {
          date: '2024-07-07',
          searchVolume: 1370,
          organicRank: 207,

          keywordSales: 19
        },
        {
          date: '2024-07-06',
          searchVolume: 1370,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-05',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-04',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-03',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-02',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-07-01',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-30',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-29',
          searchVolume: 1660,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 306,

          keywordSales: 19
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_multipoint earbuds`,
      keyword: 'multipoint earbuds',
      searchVolume: 429,
      organicStartRank: 289,
      organicHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 459,
          organicRank: 60,

          keywordSales: 3
        },
        {
          date: '2024-08-24',
          searchVolume: 459,
          organicRank: 62,

          keywordSales: 3
        },
        {
          date: '2024-08-23',
          searchVolume: 459,
          organicRank: 62,

          keywordSales: 3
        },
        {
          date: '2024-08-22',
          searchVolume: 459,
          organicRank: 57,

          keywordSales: 3
        },
        {
          date: '2024-08-21',
          searchVolume: 459,
          organicRank: 49,

          keywordSales: 3
        },
        {
          date: '2024-08-20',
          searchVolume: 459,
          organicRank: 49,

          keywordSales: 3
        },
        {
          date: '2024-08-19',
          searchVolume: 459,
          organicRank: 50,

          keywordSales: 3
        },
        {
          date: '2024-08-17',
          searchVolume: 459,
          organicRank: 51,

          keywordSales: 3
        },
        {
          date: '2024-08-16',
          searchVolume: 300,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-08-15',
          searchVolume: 300,
          organicRank: 41,

          keywordSales: 3
        },
        {
          date: '2024-08-14',
          searchVolume: 300,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-08-13',
          searchVolume: 300,
          organicRank: 30,

          keywordSales: 3
        },
        {
          date: '2024-08-12',
          searchVolume: 300,
          organicRank: 29,

          keywordSales: 3
        },
        {
          date: '2024-08-11',
          searchVolume: 300,
          organicRank: 28,

          keywordSales: 3
        },
        {
          date: '2024-08-10',
          searchVolume: 300,
          organicRank: 28,

          keywordSales: 3
        },
        {
          date: '2024-08-09',
          searchVolume: 369,
          organicRank: 35,

          keywordSales: 3
        },
        {
          date: '2024-08-08',
          searchVolume: 369,
          organicRank: 65,

          keywordSales: 3
        },
        {
          date: '2024-08-07',
          searchVolume: 369,
          organicRank: 121,

          keywordSales: 3
        },
        {
          date: '2024-08-06',
          searchVolume: 369,
          organicRank: 144,

          keywordSales: 3
        },
        {
          date: '2024-08-05',
          searchVolume: 369,
          organicRank: 88,

          keywordSales: 3
        },
        {
          date: '2024-08-04',
          searchVolume: 369,
          organicRank: 91,

          keywordSales: 3
        },
        {
          date: '2024-08-03',
          searchVolume: 369,
          organicRank: 87,

          keywordSales: 3
        },
        {
          date: '2024-08-02',
          searchVolume: 378,
          organicRank: 106,

          keywordSales: 3
        },
        {
          date: '2024-08-01',
          searchVolume: 378,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-07-30',
          searchVolume: 378,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-07-29',
          searchVolume: 378,
          organicRank: 104,

          keywordSales: 3
        },
        {
          date: '2024-07-28',
          searchVolume: 378,
          organicRank: 113,

          keywordSales: 3
        },
        {
          date: '2024-07-27',
          searchVolume: 378,
          organicRank: 111,

          keywordSales: 3
        },
        {
          date: '2024-07-26',
          searchVolume: 484,
          organicRank: 95,

          keywordSales: 3
        },
        {
          date: '2024-07-25',
          searchVolume: 484,
          organicRank: 79,

          keywordSales: 3
        },
        {
          date: '2024-07-24',
          searchVolume: 484,
          organicRank: 84,

          keywordSales: 3
        },
        {
          date: '2024-07-23',
          searchVolume: 484,
          organicRank: 84,

          keywordSales: 3
        },
        {
          date: '2024-07-22',
          searchVolume: 484,
          organicRank: 78,

          keywordSales: 3
        },
        {
          date: '2024-07-21',
          searchVolume: 484,
          organicRank: 77,

          keywordSales: 3
        },
        {
          date: '2024-07-20',
          searchVolume: 484,
          organicRank: 101,

          keywordSales: 3
        },
        {
          date: '2024-07-19',
          searchVolume: 403,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-07-18',
          searchVolume: 403,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-07-17',
          searchVolume: 403,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-07-16',
          searchVolume: 403,
          organicRank: 103,

          keywordSales: 3
        },
        {
          date: '2024-07-15',
          searchVolume: 403,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-07-14',
          searchVolume: 403,
          organicRank: 93,

          keywordSales: 3
        },
        {
          date: '2024-07-13',
          searchVolume: 403,
          organicRank: 90,

          keywordSales: 3
        },
        {
          date: '2024-07-12',
          searchVolume: 420,
          organicRank: 80,

          keywordSales: 3
        },
        {
          date: '2024-07-11',
          searchVolume: 420,
          organicRank: 89,

          keywordSales: 3
        },
        {
          date: '2024-07-10',
          searchVolume: 420,
          organicRank: 79,

          keywordSales: 3
        },
        {
          date: '2024-07-09',
          searchVolume: 420,
          organicRank: 74,

          keywordSales: 3
        },
        {
          date: '2024-07-08',
          searchVolume: 420,
          organicRank: 70,

          keywordSales: 3
        },
        {
          date: '2024-07-07',
          searchVolume: 420,
          organicRank: 66,

          keywordSales: 3
        },
        {
          date: '2024-07-06',
          searchVolume: 420,
          organicRank: 147,

          keywordSales: 3
        },
        {
          date: '2024-07-05',
          searchVolume: 447,
          organicRank: 168,

          keywordSales: 3
        },
        {
          date: '2024-07-04',
          searchVolume: 447,
          organicRank: 164,

          keywordSales: 3
        },
        {
          date: '2024-07-03',
          searchVolume: 447,
          organicRank: 163,

          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 447,
          organicRank: 162,

          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 447,
          organicRank: 170,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 447,
          organicRank: 166,

          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 447,
          organicRank: 171,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 471,
          organicRank: 173,

          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 471,
          organicRank: 159,

          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 471,
          organicRank: 172,

          keywordSales: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 471,
          organicRank: 147,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_music free them all`,
      keyword: 'music free them all',
      searchVolume: 230,
      organicStartRank: 60,
      organicHighestRank: 4,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-17',
          searchVolume: 154,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-13',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-12',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-11',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-10',
          searchVolume: 213,
          organicRank: 306
        },
        {
          date: '2024-08-09',
          searchVolume: 283,
          organicRank: 306
        },
        {
          date: '2024-08-08',
          searchVolume: 283,
          organicRank: 306
        },
        {
          date: '2024-08-07',
          searchVolume: 283,
          organicRank: 90
        },
        {
          date: '2024-08-06',
          searchVolume: 283,
          organicRank: 85
        },
        {
          date: '2024-08-05',
          searchVolume: 283,
          organicRank: 79
        },
        {
          date: '2024-08-04',
          searchVolume: 283,
          organicRank: 80
        },
        {
          date: '2024-08-03',
          searchVolume: 283,
          organicRank: 84
        },
        {
          date: '2024-08-01',
          searchVolume: 248,
          organicRank: 89
        },
        {
          date: '2024-07-31',
          searchVolume: 248,
          organicRank: 64
        },
        {
          date: '2024-07-30',
          searchVolume: 248,
          organicRank: 46
        },
        {
          date: '2024-07-29',
          searchVolume: 248,
          organicRank: 73
        },
        {
          date: '2024-07-28',
          searchVolume: 248,
          organicRank: 78
        },
        {
          date: '2024-07-27',
          searchVolume: 248,
          organicRank: 78
        },
        {
          date: '2024-07-26',
          searchVolume: 183,
          organicRank: 77
        },
        {
          date: '2024-07-25',
          searchVolume: 183,
          organicRank: 82
        },
        {
          date: '2024-07-24',
          searchVolume: 183,
          organicRank: 31
        },
        {
          date: '2024-07-23',
          searchVolume: 183,
          organicRank: 46
        },
        {
          date: '2024-07-22',
          searchVolume: 183,
          organicRank: 52
        },
        {
          date: '2024-07-21',
          searchVolume: 183,
          organicRank: 45
        },
        {
          date: '2024-07-20',
          searchVolume: 183,
          organicRank: 41
        },
        {
          date: '2024-07-19',
          searchVolume: 217,
          organicRank: 40
        },
        {
          date: '2024-07-18',
          searchVolume: 217,
          organicRank: 53
        },
        {
          date: '2024-07-17',
          searchVolume: 217,
          organicRank: 54
        },
        {
          date: '2024-07-16',
          searchVolume: 217,
          organicRank: 45
        },
        {
          date: '2024-07-15',
          searchVolume: 217,
          organicRank: 30
        },
        {
          date: '2024-07-14',
          searchVolume: 217,
          organicRank: 29
        },
        {
          date: '2024-07-13',
          searchVolume: 217,
          organicRank: 40
        },
        {
          date: '2024-07-12',
          searchVolume: 217,
          organicRank: 38
        },
        {
          date: '2024-07-11',
          searchVolume: 217,
          organicRank: 33
        },
        {
          date: '2024-07-10',
          searchVolume: 217,
          organicRank: 35
        },
        {
          date: '2024-07-09',
          searchVolume: 217,
          organicRank: 43
        },
        {
          date: '2024-07-08',
          searchVolume: 217,
          organicRank: 37
        },
        {
          date: '2024-07-07',
          searchVolume: 217,
          organicRank: 45
        },
        {
          date: '2024-07-06',
          searchVolume: 217,
          organicRank: 42
        },
        {
          date: '2024-07-05',
          searchVolume: 219,
          organicRank: 37
        },
        {
          date: '2024-07-04',
          searchVolume: 219,
          organicRank: 32
        },
        {
          date: '2024-07-03',
          searchVolume: 219,
          organicRank: 29
        },
        {
          date: '2024-07-02',
          searchVolume: 219,
          organicRank: 29
        },
        {
          date: '2024-07-01',
          searchVolume: 219,
          organicRank: 34
        },
        {
          date: '2024-06-30',
          searchVolume: 219,
          organicRank: 26
        },
        {
          date: '2024-06-29',
          searchVolume: 219,
          organicRank: 43
        },
        {
          date: '2024-06-28',
          searchVolume: 243,
          organicRank: 35
        },
        {
          date: '2024-06-27',
          searchVolume: 243,
          organicRank: 32
        },
        {
          date: '2024-06-26',
          searchVolume: 243,
          organicRank: 31
        },
        {
          date: '2024-06-25',
          searchVolume: 243,
          organicRank: 62
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_mute case`,
      keyword: 'mute case',
      searchVolume: 223,
      organicStartRank: 158,
      organicHighestRank: 58,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-22',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-21',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 275,
          organicRank: 221
        },
        {
          date: '2024-08-17',
          searchVolume: 275,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 327,
          organicRank: 306
        },
        {
          date: '2024-08-15',
          searchVolume: 327,
          organicRank: 161
        },
        {
          date: '2024-08-14',
          searchVolume: 327,
          organicRank: 199
        },
        {
          date: '2024-08-13',
          searchVolume: 327,
          organicRank: 63
        },
        {
          date: '2024-08-12',
          searchVolume: 327,
          organicRank: 144
        },
        {
          date: '2024-08-11',
          searchVolume: 327,
          organicRank: 148
        },
        {
          date: '2024-08-10',
          searchVolume: 327,
          organicRank: 169
        },
        {
          date: '2024-08-09',
          searchVolume: 242,
          organicRank: 204
        },
        {
          date: '2024-08-08',
          searchVolume: 242,
          organicRank: 198
        },
        {
          date: '2024-08-07',
          searchVolume: 242,
          organicRank: 163
        },
        {
          date: '2024-08-06',
          searchVolume: 242,
          organicRank: 149
        },
        {
          date: '2024-08-05',
          searchVolume: 242,
          organicRank: 144
        },
        {
          date: '2024-08-04',
          searchVolume: 242,
          organicRank: 156
        },
        {
          date: '2024-08-03',
          searchVolume: 242,
          organicRank: 145
        },
        {
          date: '2024-08-01',
          searchVolume: 310,
          organicRank: 159
        },
        {
          date: '2024-07-31',
          searchVolume: 310,
          organicRank: 159
        },
        {
          date: '2024-07-30',
          searchVolume: 310,
          organicRank: 142
        },
        {
          date: '2024-07-29',
          searchVolume: 310,
          organicRank: 141
        },
        {
          date: '2024-07-28',
          searchVolume: 310,
          organicRank: 140
        },
        {
          date: '2024-07-27',
          searchVolume: 310,
          organicRank: 144
        },
        {
          date: '2024-07-26',
          searchVolume: 256,
          organicRank: 137
        },
        {
          date: '2024-07-25',
          searchVolume: 256,
          organicRank: 138
        },
        {
          date: '2024-07-24',
          searchVolume: 256,
          organicRank: 141
        },
        {
          date: '2024-07-23',
          searchVolume: 256,
          organicRank: 164
        },
        {
          date: '2024-07-22',
          searchVolume: 256,
          organicRank: 118
        },
        {
          date: '2024-07-21',
          searchVolume: 256,
          organicRank: 161
        },
        {
          date: '2024-07-20',
          searchVolume: 256,
          organicRank: 117
        },
        {
          date: '2024-07-19',
          searchVolume: 234,
          organicRank: 119
        },
        {
          date: '2024-07-18',
          searchVolume: 234,
          organicRank: 118
        },
        {
          date: '2024-07-17',
          searchVolume: 234,
          organicRank: 149
        },
        {
          date: '2024-07-16',
          searchVolume: 234,
          organicRank: 140
        },
        {
          date: '2024-07-15',
          searchVolume: 234,
          organicRank: 145
        },
        {
          date: '2024-07-14',
          searchVolume: 234,
          organicRank: 183
        },
        {
          date: '2024-07-13',
          searchVolume: 234,
          organicRank: 121
        },
        {
          date: '2024-07-12',
          searchVolume: 265,
          organicRank: 128
        },
        {
          date: '2024-07-11',
          searchVolume: 265,
          organicRank: 127
        },
        {
          date: '2024-07-10',
          searchVolume: 265,
          organicRank: 127
        },
        {
          date: '2024-07-09',
          searchVolume: 265,
          organicRank: 148
        },
        {
          date: '2024-07-08',
          searchVolume: 265,
          organicRank: 152
        },
        {
          date: '2024-07-07',
          searchVolume: 265,
          organicRank: 128
        },
        {
          date: '2024-07-06',
          searchVolume: 265,
          organicRank: 121
        },
        {
          date: '2024-07-05',
          searchVolume: 278,
          organicRank: 128
        },
        {
          date: '2024-07-04',
          searchVolume: 278,
          organicRank: 191
        },
        {
          date: '2024-07-03',
          searchVolume: 278,
          organicRank: 139
        },
        {
          date: '2024-07-02',
          searchVolume: 278,
          organicRank: 125
        },
        {
          date: '2024-07-01',
          searchVolume: 278,
          organicRank: 92
        },
        {
          date: '2024-06-30',
          searchVolume: 278,
          organicRank: 93
        },
        {
          date: '2024-06-29',
          searchVolume: 278,
          organicRank: 126
        },
        {
          date: '2024-06-28',
          searchVolume: 229,
          organicRank: 122
        },
        {
          date: '2024-06-27',
          searchVolume: 229,
          organicRank: 128
        },
        {
          date: '2024-06-26',
          searchVolume: 229,
          organicRank: 195
        },
        {
          date: '2024-06-25',
          searchVolume: 229,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_noise canceling earbuds`,
      keyword: 'noise canceling earbuds',
      searchVolume: 2982,
      organicStartRank: 306,
      organicHighestRank: 63,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-24',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-23',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-22',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-21',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-20',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-19',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-18',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-16',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-15',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-14',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-13',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-12',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-11',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-10',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-09',
          searchVolume: 2232,
          organicRank: 254,

          keywordSales: 60
        },
        {
          date: '2024-08-08',
          searchVolume: 2232,
          organicRank: 259,

          keywordSales: 60
        },
        {
          date: '2024-08-07',
          searchVolume: 2232,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-06',
          searchVolume: 2232,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-05',
          searchVolume: 2232,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-04',
          searchVolume: 2232,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-03',
          searchVolume: 2232,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-02',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-26',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-25',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-24',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-23',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-22',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-21',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-20',
          searchVolume: 4917,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-19',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-18',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-17',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-16',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-15',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-14',
          searchVolume: 3254,
          organicRank: 253,

          keywordSales: 60
        },
        {
          date: '2024-07-13',
          searchVolume: 3254,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-12',
          searchVolume: 3291,
          organicRank: 245,

          keywordSales: 60
        },
        {
          date: '2024-07-11',
          searchVolume: 3291,
          organicRank: 241,

          keywordSales: 60
        },
        {
          date: '2024-07-10',
          searchVolume: 3291,
          organicRank: 254,

          keywordSales: 60
        },
        {
          date: '2024-07-09',
          searchVolume: 3291,
          organicRank: 259,

          keywordSales: 60
        },
        {
          date: '2024-07-08',
          searchVolume: 3291,
          organicRank: 209,

          keywordSales: 60
        },
        {
          date: '2024-07-07',
          searchVolume: 3291,
          organicRank: 191,

          keywordSales: 60
        },
        {
          date: '2024-07-06',
          searchVolume: 3291,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-05',
          searchVolume: 2818,
          organicRank: 186,

          keywordSales: 60
        },
        {
          date: '2024-07-04',
          searchVolume: 2818,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-03',
          searchVolume: 2818,
          organicRank: 167,

          keywordSales: 60
        },
        {
          date: '2024-07-02',
          searchVolume: 2818,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-07-01',
          searchVolume: 2818,
          organicRank: 170,

          keywordSales: 60
        },
        {
          date: '2024-06-30',
          searchVolume: 2818,
          organicRank: 150,

          keywordSales: 60
        },
        {
          date: '2024-06-29',
          searchVolume: 2818,
          organicRank: 169,

          keywordSales: 60
        },
        {
          date: '2024-06-28',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-06-27',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 60
        },
        {
          date: '2024-06-26',
          searchVolume: 2544,
          organicRank: 156,

          keywordSales: 60
        },
        {
          date: '2024-06-25',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 60
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_noise canceling earbuds wireless`,
      keyword: 'noise canceling earbuds wireless',
      searchVolume: 4693,
      organicStartRank: 306,
      organicHighestRank: 9,

      organicPageOneWinDate: '2024-06-15',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 4905,
          organicRank: 39,

          keywordSales: 147
        },
        {
          date: '2024-08-25',
          searchVolume: 4905,
          organicRank: 42,

          keywordSales: 147
        },
        {
          date: '2024-08-24',
          searchVolume: 4905,
          organicRank: 44,

          keywordSales: 147
        },
        {
          date: '2024-08-23',
          searchVolume: 4905,
          organicRank: 42,

          keywordSales: 147
        },
        {
          date: '2024-08-22',
          searchVolume: 4905,
          organicRank: 40,

          keywordSales: 147
        },
        {
          date: '2024-08-21',
          searchVolume: 4905,
          organicRank: 43,

          keywordSales: 147
        },
        {
          date: '2024-08-19',
          searchVolume: 4905,
          organicRank: 38,

          keywordSales: 147
        },
        {
          date: '2024-08-18',
          searchVolume: 4905,
          organicRank: 35,

          keywordSales: 147
        },
        {
          date: '2024-08-15',
          searchVolume: 4341,
          organicRank: 32,

          keywordSales: 147
        },
        {
          date: '2024-08-14',
          searchVolume: 4341,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-08-13',
          searchVolume: 4341,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-08-12',
          searchVolume: 4341,
          organicRank: 24,

          keywordSales: 147
        },
        {
          date: '2024-08-11',
          searchVolume: 4341,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-08-10',
          searchVolume: 4341,
          organicRank: 30,

          keywordSales: 147
        },
        {
          date: '2024-08-09',
          searchVolume: 4241,
          organicRank: 31,

          keywordSales: 147
        },
        {
          date: '2024-08-08',
          searchVolume: 4241,
          organicRank: 29,

          keywordSales: 147
        },
        {
          date: '2024-08-07',
          searchVolume: 4241,
          organicRank: 22,

          keywordSales: 147
        },
        {
          date: '2024-08-06',
          searchVolume: 4241,
          organicRank: 31,

          keywordSales: 147
        },
        {
          date: '2024-08-05',
          searchVolume: 4241,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-08-04',
          searchVolume: 4241,
          organicRank: 25,

          keywordSales: 147
        },
        {
          date: '2024-08-03',
          searchVolume: 4241,
          organicRank: 31,

          keywordSales: 147
        },
        {
          date: '2024-08-02',
          searchVolume: 3993,
          organicRank: 32,

          keywordSales: 147
        },
        {
          date: '2024-08-01',
          searchVolume: 3993,
          organicRank: 29,

          keywordSales: 147
        },
        {
          date: '2024-07-31',
          searchVolume: 3993,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-30',
          searchVolume: 3993,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-29',
          searchVolume: 3993,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-28',
          searchVolume: 3993,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-27',
          searchVolume: 3993,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-26',
          searchVolume: 8079,
          organicRank: 32,

          keywordSales: 147
        },
        {
          date: '2024-07-25',
          searchVolume: 8079,
          organicRank: 26,

          keywordSales: 147
        },
        {
          date: '2024-07-24',
          searchVolume: 8079,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-23',
          searchVolume: 8079,
          organicRank: 30,

          keywordSales: 147
        },
        {
          date: '2024-07-22',
          searchVolume: 8079,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-07-21',
          searchVolume: 8079,
          organicRank: 24,

          keywordSales: 147
        },
        {
          date: '2024-07-20',
          searchVolume: 8079,
          organicRank: 26,

          keywordSales: 147
        },
        {
          date: '2024-07-19',
          searchVolume: 4056,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-07-18',
          searchVolume: 4056,
          organicRank: 26,

          keywordSales: 147
        },
        {
          date: '2024-07-17',
          searchVolume: 4056,
          organicRank: 306,

          keywordSales: 147
        },
        {
          date: '2024-07-16',
          searchVolume: 4056,
          organicRank: 29,

          keywordSales: 147
        },
        {
          date: '2024-07-15',
          searchVolume: 4056,
          organicRank: 25,

          keywordSales: 147
        },
        {
          date: '2024-07-14',
          searchVolume: 4056,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-07-13',
          searchVolume: 4056,
          organicRank: 25,

          keywordSales: 147
        },
        {
          date: '2024-07-12',
          searchVolume: 4133,
          organicRank: 27,

          keywordSales: 147
        },
        {
          date: '2024-07-10',
          searchVolume: 4133,
          organicRank: 37,

          keywordSales: 147
        },
        {
          date: '2024-07-09',
          searchVolume: 4133,
          organicRank: 42,

          keywordSales: 147
        },
        {
          date: '2024-07-08',
          searchVolume: 4133,
          organicRank: 34,

          keywordSales: 147
        },
        {
          date: '2024-07-07',
          searchVolume: 4133,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-06',
          searchVolume: 4133,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-05',
          searchVolume: 4366,
          organicRank: 24,

          keywordSales: 147
        },
        {
          date: '2024-07-04',
          searchVolume: 4366,
          organicRank: 24,

          keywordSales: 147
        },
        {
          date: '2024-07-03',
          searchVolume: 4366,
          organicRank: 28,

          keywordSales: 147
        },
        {
          date: '2024-07-02',
          searchVolume: 4366,
          organicRank: 19,

          keywordSales: 147
        },
        {
          date: '2024-07-01',
          searchVolume: 4366,
          organicRank: 26,

          keywordSales: 147
        },
        {
          date: '2024-06-30',
          searchVolume: 4366,
          organicRank: 21,

          keywordSales: 147
        },
        {
          date: '2024-06-29',
          searchVolume: 4366,
          organicRank: 24,

          keywordSales: 147
        },
        {
          date: '2024-06-28',
          searchVolume: 4309,
          organicRank: 22,

          keywordSales: 147
        },
        {
          date: '2024-06-27',
          searchVolume: 4309,
          organicRank: 23,

          keywordSales: 147
        },
        {
          date: '2024-06-26',
          searchVolume: 4309,
          organicRank: 21,

          keywordSales: 147
        },
        {
          date: '2024-06-25',
          searchVolume: 4309,
          organicRank: 19,

          keywordSales: 147
        },
        {
          date: '2024-06-24',
          searchVolume: 4309,
          organicRank: 21,

          keywordSales: 147
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_noise cancelling earbuds`,
      keyword: 'noise cancelling earbuds',
      searchVolume: 63963,
      organicStartRank: 306,
      organicHighestRank: 235,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-25',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-24',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-23',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-22',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-21',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-19',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-18',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-17',
          searchVolume: 63973,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-15',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-14',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-13',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-12',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-11',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-10',
          searchVolume: 60955,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-09',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-08',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-07',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-06',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-05',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-04',
          searchVolume: 59509,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-02',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-08-01',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-31',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-30',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-29',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-28',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-27',
          searchVolume: 58976,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-26',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-25',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-24',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-23',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-22',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-21',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-20',
          searchVolume: 93872,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-19',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-18',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-17',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-16',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-15',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-14',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-13',
          searchVolume: 62238,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-12',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-11',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-10',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-09',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-08',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-07',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-06',
          searchVolume: 61058,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-05',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-04',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-03',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-07-02',
          searchVolume: 63963,
          organicRank: 235,

          keywordSales: 1371
        },
        {
          date: '2024-07-01',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-30',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-29',
          searchVolume: 63963,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-28',
          searchVolume: 63714,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-27',
          searchVolume: 63714,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-25',
          searchVolume: 63714,
          organicRank: 306,

          keywordSales: 1371
        },
        {
          date: '2024-06-24',
          searchVolume: 63714,
          organicRank: 306,

          keywordSales: 1371
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_pc earbud`,
      keyword: 'pc earbud',

      organicStartRank: 306,
      organicHighestRank: 42,
      sponsoredStartRank: 94,
      sponsoredHighestRank: 94,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',

          organicRank: 306
        },
        {
          date: '2024-08-24',

          organicRank: 306
        },
        {
          date: '2024-08-22',

          organicRank: 85
        },
        {
          date: '2024-08-20',

          organicRank: 306
        },
        {
          date: '2024-08-18',

          organicRank: 259,
          sponsoredRank: 94
        },
        {
          date: '2024-08-16',

          organicRank: 260
        },
        {
          date: '2024-08-14',

          organicRank: 224
        },
        {
          date: '2024-08-12',

          organicRank: 115
        },
        {
          date: '2024-08-10',

          organicRank: 114
        },
        {
          date: '2024-08-08',

          organicRank: 83
        },
        {
          date: '2024-08-06',

          organicRank: 122
        },
        {
          date: '2024-08-04',

          organicRank: 126
        },
        {
          date: '2024-08-02',

          organicRank: 146
        },
        {
          date: '2024-07-31',

          organicRank: 121
        },
        {
          date: '2024-07-29',

          organicRank: 134
        },
        {
          date: '2024-07-27',

          organicRank: 112
        },
        {
          date: '2024-07-25',

          organicRank: 115
        },
        {
          date: '2024-07-23',

          organicRank: 90
        },
        {
          date: '2024-07-21',

          organicRank: 306
        },
        {
          date: '2024-07-19',

          organicRank: 306
        },
        {
          date: '2024-07-17',

          organicRank: 77
        },
        {
          date: '2024-07-15',

          organicRank: 76
        },
        {
          date: '2024-07-13',

          organicRank: 306
        },
        {
          date: '2024-07-11',

          organicRank: 306
        },
        {
          date: '2024-07-08',

          organicRank: 74
        },
        {
          date: '2024-07-06',

          organicRank: 52
        },
        {
          date: '2024-07-04',

          organicRank: 42
        },
        {
          date: '2024-07-02',

          organicRank: 48
        },
        {
          date: '2024-06-30',

          organicRank: 45
        },
        {
          date: '2024-06-28',

          organicRank: 63
        },
        {
          date: '2024-06-26',

          organicRank: 68
        },
        {
          date: '2024-06-24',

          organicRank: 82
        },
        {
          date: '2024-06-21',

          organicRank: 82
        },
        {
          date: '2024-06-19',

          organicRank: 59
        },
        {
          date: '2024-06-17',

          organicRank: 59
        },
        {
          date: '2024-06-15',

          organicRank: 50
        },
        {
          date: '2024-06-13',

          organicRank: 97
        },
        {
          date: '2024-06-11',

          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_plantronics`,
      keyword: 'plantronics',
      searchVolume: 4913,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 59,
      sponsoredHighestRank: 59,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-24',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-23',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-22',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-21',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-20',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-19',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-17',
          searchVolume: 4913,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-16',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-15',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-14',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-13',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-12',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-11',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-10',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-09',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-08',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-07',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-06',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-05',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-04',
          searchVolume: 4576,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 84
        },
        {
          date: '2024-08-03',
          searchVolume: 4576,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-08-01',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-31',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-30',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-29',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-28',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-27',
          searchVolume: 4753,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-26',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-25',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-24',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-23',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-22',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-21',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-20',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-19',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-18',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-17',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-16',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-15',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-14',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-13',
          searchVolume: 4603,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-12',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-11',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-10',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-09',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-08',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-07',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-06',
          searchVolume: 4134,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-05',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-04',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-03',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-02',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-07-01',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-30',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-29',
          searchVolume: 4379,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-28',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-27',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-26',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 84
        },
        {
          date: '2024-06-25',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 84
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_plantronics bluetooth earbuds`,
      keyword: 'plantronics bluetooth earbuds',
      searchVolume: 264,
      organicStartRank: 306,
      organicHighestRank: 136,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 179,
          organicRank: 261,

          keywordSales: 1
        },
        {
          date: '2024-08-23',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 179,
          organicRank: 248,

          keywordSales: 1
        },
        {
          date: '2024-08-21',
          searchVolume: 179,
          organicRank: 275,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-19',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-17',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-15',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-13',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-11',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 188,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-09',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-07',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-05',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-03',
          searchVolume: 179,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-01',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-29',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 258,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-24',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-22',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-20',
          searchVolume: 327,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-18',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-16',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-14',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 161,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-12',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-10',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-09',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-07',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 203,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-05',
          searchVolume: 178,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 178,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 178,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 178,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 178,
          organicRank: 266,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 178,
          organicRank: 266,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 178,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 225,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 225,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 225,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 225,
          organicRank: 165,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_plantronics earbuds`,
      keyword: 'plantronics earbuds',
      searchVolume: 467,
      organicStartRank: 306,
      organicHighestRank: 57,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-23',
          searchVolume: 407,
          organicRank: 273,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 407,
          organicRank: 242,

          keywordSales: 1
        },
        {
          date: '2024-08-21',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-19',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-17',
          searchVolume: 407,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 419,
          organicRank: 119,

          keywordSales: 1
        },
        {
          date: '2024-08-15',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-13',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-11',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-09',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-07',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-05',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-03',
          searchVolume: 419,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-01',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-29',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 426,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-24',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-22',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-20',
          searchVolume: 460,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-18',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-16',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-14',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 454,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-12',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-10',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-09',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-07',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-05',
          searchVolume: 487,
          organicRank: 58,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 487,
          organicRank: 65,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 487,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 487,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 487,
          organicRank: 63,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 487,
          organicRank: 57,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 487,
          organicRank: 78,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 467,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 467,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 467,
          organicRank: 256,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 467,
          organicRank: 306,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_poly`,
      keyword: 'poly',
      searchVolume: 1830,
      organicStartRank: 306,
      organicHighestRank: 2,
      sponsoredStartRank: 71,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-12',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 1557,
          organicRank: 10,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 1557,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 1557,
          organicRank: 10,

          keywordSales: 1
        },
        {
          date: '2024-08-21',
          searchVolume: 1557,
          organicRank: 9,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 1557,
          organicRank: 9,
          sponsoredRank: 10,
          keywordSales: 1
        },
        {
          date: '2024-08-19',
          searchVolume: 1557,
          organicRank: 9,

          keywordSales: 1
        },
        {
          date: '2024-08-18',
          searchVolume: 1557,
          organicRank: 12,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 1588,
          organicRank: 11,
          sponsoredRank: 45,
          keywordSales: 1
        },
        {
          date: '2024-08-15',
          searchVolume: 1588,
          organicRank: 9,
          sponsoredRank: 75,
          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 1588,
          organicRank: 9,

          keywordSales: 1
        },
        {
          date: '2024-08-13',
          searchVolume: 1588,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 1588,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 1
        },
        {
          date: '2024-08-11',
          searchVolume: 1588,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 1588,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 1
        },
        {
          date: '2024-08-09',
          searchVolume: 1341,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 1341,
          organicRank: 10,
          sponsoredRank: 6,
          keywordSales: 1
        },
        {
          date: '2024-08-07',
          searchVolume: 1341,
          organicRank: 11,
          sponsoredRank: 16,
          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 1341,
          organicRank: 11,

          keywordSales: 1
        },
        {
          date: '2024-08-05',
          searchVolume: 1341,
          organicRank: 6,
          sponsoredRank: 36,
          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 1341,
          organicRank: 11,

          keywordSales: 1
        },
        {
          date: '2024-08-03',
          searchVolume: 1341,
          organicRank: 7,
          sponsoredRank: 35,
          keywordSales: 1
        },
        {
          date: '2024-08-02',
          searchVolume: 1483,
          organicRank: 11,

          keywordSales: 1
        },
        {
          date: '2024-08-01',
          searchVolume: 1483,
          organicRank: 8,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 1483,
          organicRank: 5,

          keywordSales: 1
        },
        {
          date: '2024-07-30',
          searchVolume: 1483,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-28',
          searchVolume: 1483,
          organicRank: 8,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 1483,
          organicRank: 8,

          keywordSales: 1
        },
        {
          date: '2024-07-26',
          searchVolume: 1764,
          organicRank: 8,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 1764,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-24',
          searchVolume: 1764,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 1764,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-22',
          searchVolume: 1764,
          organicRank: 5,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 1764,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-20',
          searchVolume: 1764,
          organicRank: 5,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 1343,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-18',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 1343,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-16',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 1343,
          organicRank: 5,

          keywordSales: 1
        },
        {
          date: '2024-07-14',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 1343,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-12',
          searchVolume: 1536,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 1536,
          organicRank: 8,

          keywordSales: 1
        },
        {
          date: '2024-07-10',
          searchVolume: 1536,
          organicRank: 14,

          keywordSales: 1
        },
        {
          date: '2024-07-09',
          searchVolume: 1536,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 1536,
          organicRank: 4,

          keywordSales: 1
        },
        {
          date: '2024-07-07',
          searchVolume: 1536,
          organicRank: 4,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 1536,
          organicRank: 4,

          keywordSales: 1
        },
        {
          date: '2024-07-05',
          searchVolume: 1662,
          organicRank: 3,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 1662,
          organicRank: 5,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 1662,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 1662,
          organicRank: 10,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 1662,
          organicRank: 9,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 1662,
          organicRank: 3,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 1662,
          organicRank: 11,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 1652,
          organicRank: 11,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 1652,
          organicRank: 9,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 1652,
          organicRank: 6,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 1652,
          organicRank: 7,

          keywordSales: 1
        },
        {
          date: '2024-06-24',
          searchVolume: 1652,
          organicRank: 4,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_poly earbuds`,
      keyword: 'poly earbuds',
      searchVolume: 358,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 59,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 428,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-24',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-23',
          searchVolume: 428,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-22',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 2
        },
        {
          date: '2024-08-21',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-20',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 2
        },
        {
          date: '2024-08-19',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-17',
          searchVolume: 428,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-16',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-15',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 2
        },
        {
          date: '2024-08-14',
          searchVolume: 397,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-13',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-12',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 2
        },
        {
          date: '2024-08-11',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-10',
          searchVolume: 397,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-09',
          searchVolume: 419,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-08',
          searchVolume: 419,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-07',
          searchVolume: 419,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-06',
          searchVolume: 419,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-05',
          searchVolume: 419,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-08-04',
          searchVolume: 419,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-03',
          searchVolume: 419,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-08-01',
          searchVolume: 297,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 2
        },
        {
          date: '2024-07-31',
          searchVolume: 297,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-30',
          searchVolume: 297,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-29',
          searchVolume: 297,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-28',
          searchVolume: 297,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-27',
          searchVolume: 297,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-26',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-25',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-24',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-23',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-22',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-21',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-20',
          searchVolume: 447,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-19',
          searchVolume: 391,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-18',
          searchVolume: 391,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-17',
          searchVolume: 391,
          organicRank: 2,

          keywordSales: 2
        },
        {
          date: '2024-07-16',
          searchVolume: 391,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-15',
          searchVolume: 391,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-14',
          searchVolume: 391,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-13',
          searchVolume: 391,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-12',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-11',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-10',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-09',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-08',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-07',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-06',
          searchVolume: 411,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-05',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-04',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-03',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-07-01',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-30',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-29',
          searchVolume: 456,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-28',
          searchVolume: 406,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-27',
          searchVolume: 406,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-26',
          searchVolume: 406,
          organicRank: 1,

          keywordSales: 2
        },
        {
          date: '2024-06-25',
          searchVolume: 406,
          organicRank: 1,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_poly tune`,
      keyword: 'poly tune',
      searchVolume: 160,
      organicStartRank: 12,
      organicHighestRank: 7,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 207,
          organicRank: 11
        },
        {
          date: '2024-08-24',
          searchVolume: 207,
          organicRank: 11
        },
        {
          date: '2024-08-23',
          searchVolume: 207,
          organicRank: 11
        },
        {
          date: '2024-08-22',
          searchVolume: 207,
          organicRank: 9
        },
        {
          date: '2024-08-21',
          searchVolume: 207,
          organicRank: 9
        },
        {
          date: '2024-08-20',
          searchVolume: 207,
          organicRank: 9
        },
        {
          date: '2024-08-19',
          searchVolume: 207,
          organicRank: 9
        },
        {
          date: '2024-08-17',
          searchVolume: 207,
          organicRank: 9
        },
        {
          date: '2024-08-16',
          searchVolume: 276,
          organicRank: 9
        },
        {
          date: '2024-08-15',
          searchVolume: 276,
          organicRank: 7
        },
        {
          date: '2024-08-14',
          searchVolume: 276,
          organicRank: 8
        },
        {
          date: '2024-08-13',
          searchVolume: 276,
          organicRank: 8
        },
        {
          date: '2024-08-12',
          searchVolume: 276,
          organicRank: 9
        },
        {
          date: '2024-08-11',
          searchVolume: 276,
          organicRank: 8
        },
        {
          date: '2024-08-10',
          searchVolume: 276,
          organicRank: 8
        },
        {
          date: '2024-08-09',
          searchVolume: 284,
          organicRank: 8
        },
        {
          date: '2024-08-08',
          searchVolume: 284,
          organicRank: 8
        },
        {
          date: '2024-08-07',
          searchVolume: 284,
          organicRank: 8
        },
        {
          date: '2024-08-06',
          searchVolume: 284,
          organicRank: 8
        },
        {
          date: '2024-08-05',
          searchVolume: 284,
          organicRank: 9
        },
        {
          date: '2024-08-04',
          searchVolume: 284,
          organicRank: 9
        },
        {
          date: '2024-08-03',
          searchVolume: 284,
          organicRank: 9
        },
        {
          date: '2024-08-01',
          searchVolume: 312,
          organicRank: 9
        },
        {
          date: '2024-07-31',
          searchVolume: 312,
          organicRank: 9
        },
        {
          date: '2024-07-30',
          searchVolume: 312,
          organicRank: 8
        },
        {
          date: '2024-07-29',
          searchVolume: 312,
          organicRank: 9
        },
        {
          date: '2024-07-28',
          searchVolume: 312,
          organicRank: 9
        },
        {
          date: '2024-07-27',
          searchVolume: 312,
          organicRank: 8
        },
        {
          date: '2024-07-26',
          searchVolume: 264,
          organicRank: 8
        },
        {
          date: '2024-07-25',
          searchVolume: 264,
          organicRank: 8
        },
        {
          date: '2024-07-24',
          searchVolume: 264,
          organicRank: 8
        },
        {
          date: '2024-07-23',
          searchVolume: 264,
          organicRank: 8
        },
        {
          date: '2024-07-22',
          searchVolume: 264,
          organicRank: 7
        },
        {
          date: '2024-07-21',
          searchVolume: 264,
          organicRank: 7
        },
        {
          date: '2024-07-20',
          searchVolume: 264,
          organicRank: 7
        },
        {
          date: '2024-07-19',
          searchVolume: 291,
          organicRank: 9
        },
        {
          date: '2024-07-18',
          searchVolume: 291,
          organicRank: 9
        },
        {
          date: '2024-07-17',
          searchVolume: 291,
          organicRank: 8
        },
        {
          date: '2024-07-16',
          searchVolume: 291,
          organicRank: 8
        },
        {
          date: '2024-07-15',
          searchVolume: 291,
          organicRank: 8
        },
        {
          date: '2024-07-14',
          searchVolume: 291,
          organicRank: 8
        },
        {
          date: '2024-07-13',
          searchVolume: 291,
          organicRank: 8
        },
        {
          date: '2024-07-12',
          searchVolume: 244,
          organicRank: 7
        },
        {
          date: '2024-07-11',
          searchVolume: 244,
          organicRank: 7
        },
        {
          date: '2024-07-10',
          searchVolume: 244,
          organicRank: 8
        },
        {
          date: '2024-07-09',
          searchVolume: 244,
          organicRank: 8
        },
        {
          date: '2024-07-08',
          searchVolume: 244,
          organicRank: 7
        },
        {
          date: '2024-07-07',
          searchVolume: 244,
          organicRank: 8
        },
        {
          date: '2024-07-06',
          searchVolume: 244,
          organicRank: 9
        },
        {
          date: '2024-07-05',
          searchVolume: 287,
          organicRank: 8
        },
        {
          date: '2024-07-04',
          searchVolume: 287,
          organicRank: 8
        },
        {
          date: '2024-07-03',
          searchVolume: 287,
          organicRank: 8
        },
        {
          date: '2024-07-02',
          searchVolume: 287,
          organicRank: 9
        },
        {
          date: '2024-07-01',
          searchVolume: 287,
          organicRank: 9
        },
        {
          date: '2024-06-30',
          searchVolume: 287,
          organicRank: 9
        },
        {
          date: '2024-06-29',
          searchVolume: 287,
          organicRank: 10
        },
        {
          date: '2024-06-28',
          searchVolume: 312,
          organicRank: 10
        },
        {
          date: '2024-06-27',
          searchVolume: 312,
          organicRank: 11
        },
        {
          date: '2024-06-26',
          searchVolume: 312,
          organicRank: 10
        },
        {
          date: '2024-06-25',
          searchVolume: 312,
          organicRank: 11
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_poly voyager`,
      keyword: 'poly voyager',
      searchVolume: 847,
      organicStartRank: 26,
      organicHighestRank: 1,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-12',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-08-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 1221,
          organicRank: 14,

          keywordSales: 16
        },
        {
          date: '2024-08-24',
          searchVolume: 1221,
          organicRank: 14,
          sponsoredRank: 27,
          keywordSales: 16
        },
        {
          date: '2024-08-23',
          searchVolume: 1221,
          organicRank: 14,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-08-22',
          searchVolume: 1221,
          organicRank: 14,

          keywordSales: 16
        },
        {
          date: '2024-08-21',
          searchVolume: 1221,
          organicRank: 15,

          keywordSales: 16
        },
        {
          date: '2024-08-20',
          searchVolume: 1221,
          organicRank: 10,
          sponsoredRank: 51,
          keywordSales: 16
        },
        {
          date: '2024-08-19',
          searchVolume: 1221,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 16
        },
        {
          date: '2024-08-17',
          searchVolume: 1221,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-08-16',
          searchVolume: 1365,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 16
        },
        {
          date: '2024-08-15',
          searchVolume: 1365,
          organicRank: 10,
          sponsoredRank: 18,
          keywordSales: 16
        },
        {
          date: '2024-08-14',
          searchVolume: 1365,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-08-13',
          searchVolume: 1365,
          organicRank: 10,
          sponsoredRank: 10,
          keywordSales: 16
        },
        {
          date: '2024-08-12',
          searchVolume: 1365,
          organicRank: 9,
          sponsoredRank: 12,
          keywordSales: 16
        },
        {
          date: '2024-08-11',
          searchVolume: 1365,
          organicRank: 9,

          keywordSales: 16
        },
        {
          date: '2024-08-10',
          searchVolume: 1365,
          organicRank: 10,

          keywordSales: 16
        },
        {
          date: '2024-08-09',
          searchVolume: 1139,
          organicRank: 9,

          keywordSales: 16
        },
        {
          date: '2024-08-08',
          searchVolume: 1139,
          organicRank: 15,

          keywordSales: 16
        },
        {
          date: '2024-08-07',
          searchVolume: 1139,
          organicRank: 8,

          keywordSales: 16
        },
        {
          date: '2024-08-06',
          searchVolume: 1139,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-08-05',
          searchVolume: 1139,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-08-04',
          searchVolume: 1139,
          organicRank: 8,
          sponsoredRank: 10,
          keywordSales: 16
        },
        {
          date: '2024-08-03',
          searchVolume: 1139,
          organicRank: 12,

          keywordSales: 16
        },
        {
          date: '2024-08-02',
          searchVolume: 1243,
          organicRank: 11,

          keywordSales: 16
        },
        {
          date: '2024-08-01',
          searchVolume: 1243,
          organicRank: 9,

          keywordSales: 16
        },
        {
          date: '2024-07-30',
          searchVolume: 1243,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-29',
          searchVolume: 1243,
          organicRank: 8,

          keywordSales: 16
        },
        {
          date: '2024-07-28',
          searchVolume: 1243,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-27',
          searchVolume: 1243,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-26',
          searchVolume: 1367,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-25',
          searchVolume: 1367,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-24',
          searchVolume: 1367,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-22',
          searchVolume: 1367,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-20',
          searchVolume: 1367,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-15',
          searchVolume: 895,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-14',
          searchVolume: 895,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-13',
          searchVolume: 895,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-12',
          searchVolume: 775,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-11',
          searchVolume: 775,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-10',
          searchVolume: 775,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-07-09',
          searchVolume: 775,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-07-08',
          searchVolume: 775,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-07',
          searchVolume: 775,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-06',
          searchVolume: 775,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-05',
          searchVolume: 952,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-04',
          searchVolume: 952,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-03',
          searchVolume: 952,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-02',
          searchVolume: 952,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-07-01',
          searchVolume: 952,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-06-30',
          searchVolume: 952,
          organicRank: 5,

          keywordSales: 16
        },
        {
          date: '2024-06-29',
          searchVolume: 952,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-06-28',
          searchVolume: 1217,
          organicRank: 7,

          keywordSales: 16
        },
        {
          date: '2024-06-27',
          searchVolume: 1217,
          organicRank: 8,

          keywordSales: 16
        },
        {
          date: '2024-06-26',
          searchVolume: 1217,
          organicRank: 6,

          keywordSales: 16
        },
        {
          date: '2024-06-25',
          searchVolume: 1217,
          organicRank: 8,

          keywordSales: 16
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_poly voyager free 60`,
      keyword: 'poly voyager free 60',
      searchVolume: 459,
      organicStartRank: 5,
      organicHighestRank: 2,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-12',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-08-24',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 3
        },
        {
          date: '2024-08-23',
          searchVolume: 439,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-22',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-08-21',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-08-20',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-08-19',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-08-17',
          searchVolume: 439,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-08-16',
          searchVolume: 443,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-15',
          searchVolume: 443,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-08-14',
          searchVolume: 443,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 3
        },
        {
          date: '2024-08-13',
          searchVolume: 443,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 3
        },
        {
          date: '2024-08-12',
          searchVolume: 443,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-11',
          searchVolume: 443,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-10',
          searchVolume: 443,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-09',
          searchVolume: 445,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-08',
          searchVolume: 445,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-08-07',
          searchVolume: 445,
          organicRank: 2,
          sponsoredRank: 10,
          keywordSales: 3
        },
        {
          date: '2024-08-06',
          searchVolume: 445,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-08-05',
          searchVolume: 445,
          organicRank: 2,
          sponsoredRank: 17,
          keywordSales: 3
        },
        {
          date: '2024-08-04',
          searchVolume: 445,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-08-03',
          searchVolume: 445,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-08-01',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-31',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-30',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-29',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-28',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-27',
          searchVolume: 469,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-26',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-25',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-24',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-23',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-22',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-21',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-20',
          searchVolume: 465,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-19',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-18',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-17',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-16',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-15',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-14',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-13',
          searchVolume: 441,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-12',
          searchVolume: 416,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-11',
          searchVolume: 416,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-10',
          searchVolume: 416,
          organicRank: 2,

          keywordSales: 3
        },
        {
          date: '2024-07-09',
          searchVolume: 416,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-07-08',
          searchVolume: 416,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-07-07',
          searchVolume: 416,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-07-06',
          searchVolume: 416,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-07-05',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-07-04',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-07-03',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 476,
          organicRank: 3,

          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 476,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 447,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 447,
          organicRank: 4,

          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 447,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 447,
          organicRank: 5,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_power beats 2`,
      keyword: 'power beats 2',
      searchVolume: 201,
      organicStartRank: 112,
      organicHighestRank: 40,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 198,
          organicRank: 84
        },
        {
          date: '2024-08-24',
          searchVolume: 198,
          organicRank: 98
        },
        {
          date: '2024-08-23',
          searchVolume: 198,
          organicRank: 89
        },
        {
          date: '2024-08-22',
          searchVolume: 198,
          organicRank: 79
        },
        {
          date: '2024-08-21',
          searchVolume: 198,
          organicRank: 91
        },
        {
          date: '2024-08-20',
          searchVolume: 198,
          organicRank: 82
        },
        {
          date: '2024-08-19',
          searchVolume: 198,
          organicRank: 90
        },
        {
          date: '2024-08-17',
          searchVolume: 198,
          organicRank: 76
        },
        {
          date: '2024-08-16',
          searchVolume: 185,
          organicRank: 69
        },
        {
          date: '2024-08-15',
          searchVolume: 185,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 185,
          organicRank: 70
        },
        {
          date: '2024-08-13',
          searchVolume: 185,
          organicRank: 81
        },
        {
          date: '2024-08-12',
          searchVolume: 185,
          organicRank: 75
        },
        {
          date: '2024-08-11',
          searchVolume: 185,
          organicRank: 74
        },
        {
          date: '2024-08-10',
          searchVolume: 185,
          organicRank: 75
        },
        {
          date: '2024-08-09',
          searchVolume: 222,
          organicRank: 50
        },
        {
          date: '2024-08-08',
          searchVolume: 222,
          organicRank: 60
        },
        {
          date: '2024-08-07',
          searchVolume: 222,
          organicRank: 60
        },
        {
          date: '2024-08-06',
          searchVolume: 222,
          organicRank: 46
        },
        {
          date: '2024-08-05',
          searchVolume: 222,
          organicRank: 49
        },
        {
          date: '2024-08-04',
          searchVolume: 222,
          organicRank: 68
        },
        {
          date: '2024-08-03',
          searchVolume: 222,
          organicRank: 51
        },
        {
          date: '2024-08-01',
          searchVolume: 213,
          organicRank: 57
        },
        {
          date: '2024-07-31',
          searchVolume: 213,
          organicRank: 59
        },
        {
          date: '2024-07-30',
          searchVolume: 213,
          organicRank: 56
        },
        {
          date: '2024-07-29',
          searchVolume: 213,
          organicRank: 61
        },
        {
          date: '2024-07-28',
          searchVolume: 213,
          organicRank: 63
        },
        {
          date: '2024-07-27',
          searchVolume: 213,
          organicRank: 73
        },
        {
          date: '2024-07-26',
          searchVolume: 161,
          organicRank: 70
        },
        {
          date: '2024-07-25',
          searchVolume: 161,
          organicRank: 60
        },
        {
          date: '2024-07-24',
          searchVolume: 161,
          organicRank: 56
        },
        {
          date: '2024-07-23',
          searchVolume: 161,
          organicRank: 52
        },
        {
          date: '2024-07-22',
          searchVolume: 161,
          organicRank: 67
        },
        {
          date: '2024-07-21',
          searchVolume: 161,
          organicRank: 73
        },
        {
          date: '2024-07-20',
          searchVolume: 161,
          organicRank: 77
        },
        {
          date: '2024-07-19',
          searchVolume: 174,
          organicRank: 70
        },
        {
          date: '2024-07-18',
          searchVolume: 174,
          organicRank: 71
        },
        {
          date: '2024-07-17',
          searchVolume: 174,
          organicRank: 66
        },
        {
          date: '2024-07-16',
          searchVolume: 174,
          organicRank: 75
        },
        {
          date: '2024-07-15',
          searchVolume: 174,
          organicRank: 63
        },
        {
          date: '2024-07-14',
          searchVolume: 174,
          organicRank: 63
        },
        {
          date: '2024-07-13',
          searchVolume: 174,
          organicRank: 60
        },
        {
          date: '2024-07-12',
          searchVolume: 232,
          organicRank: 65
        },
        {
          date: '2024-07-11',
          searchVolume: 232,
          organicRank: 83
        },
        {
          date: '2024-07-10',
          searchVolume: 232,
          organicRank: 80
        },
        {
          date: '2024-07-09',
          searchVolume: 232,
          organicRank: 70
        },
        {
          date: '2024-07-08',
          searchVolume: 232,
          organicRank: 72
        },
        {
          date: '2024-07-07',
          searchVolume: 232,
          organicRank: 66
        },
        {
          date: '2024-07-06',
          searchVolume: 232,
          organicRank: 72
        },
        {
          date: '2024-07-05',
          searchVolume: 190,
          organicRank: 77
        },
        {
          date: '2024-07-04',
          searchVolume: 190,
          organicRank: 58
        },
        {
          date: '2024-07-03',
          searchVolume: 190,
          organicRank: 65
        },
        {
          date: '2024-07-02',
          searchVolume: 190,
          organicRank: 61
        },
        {
          date: '2024-07-01',
          searchVolume: 190,
          organicRank: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 190,
          organicRank: 67
        },
        {
          date: '2024-06-29',
          searchVolume: 190,
          organicRank: 75
        },
        {
          date: '2024-06-28',
          searchVolume: 208,
          organicRank: 70
        },
        {
          date: '2024-06-27',
          searchVolume: 208,
          organicRank: 75
        },
        {
          date: '2024-06-26',
          searchVolume: 208,
          organicRank: 70
        },
        {
          date: '2024-06-25',
          searchVolume: 208,
          organicRank: 83
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_premium earbuds`,
      keyword: 'premium earbuds',
      searchVolume: 367,
      organicStartRank: 306,
      organicHighestRank: 272,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 39,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-24',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-23',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-22',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-21',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-20',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-19',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-17',
          searchVolume: 331,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-16',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-15',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-14',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-13',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-12',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-11',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-10',
          searchVolume: 269,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-09',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-08',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-07',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-06',
          searchVolume: 289,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 4
        },
        {
          date: '2024-08-05',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-04',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-03',
          searchVolume: 289,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-02',
          searchVolume: 291,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-08-01',
          searchVolume: 291,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-31',
          searchVolume: 291,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-30',
          searchVolume: 291,
          organicRank: 272,

          keywordSales: 4
        },
        {
          date: '2024-07-28',
          searchVolume: 291,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-27',
          searchVolume: 291,
          organicRank: 278,

          keywordSales: 4
        },
        {
          date: '2024-07-26',
          searchVolume: 367,
          organicRank: 283,

          keywordSales: 4
        },
        {
          date: '2024-07-25',
          searchVolume: 367,
          organicRank: 278,

          keywordSales: 4
        },
        {
          date: '2024-07-24',
          searchVolume: 367,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-23',
          searchVolume: 367,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-22',
          searchVolume: 367,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-21',
          searchVolume: 367,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-20',
          searchVolume: 367,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-19',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-18',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-17',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-16',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-15',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-14',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-13',
          searchVolume: 165,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-12',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-11',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-10',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-09',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-08',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-07',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-06',
          searchVolume: 235,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-05',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-04',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-03',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-02',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-07-01',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-30',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-29',
          searchVolume: 223,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-28',
          searchVolume: 237,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-27',
          searchVolume: 237,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-26',
          searchVolume: 237,
          organicRank: 306,

          keywordSales: 4
        },
        {
          date: '2024-06-25',
          searchVolume: 237,
          organicRank: 306,

          keywordSales: 4
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_qi wireless headphones`,
      keyword: 'qi wireless headphones',
      searchVolume: 245,
      organicStartRank: 156,
      organicHighestRank: 43,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 171,
          organicRank: 306
        },
        {
          date: '2024-08-24',
          searchVolume: 171,
          organicRank: 306
        },
        {
          date: '2024-08-23',
          searchVolume: 171,
          organicRank: 102
        },
        {
          date: '2024-08-22',
          searchVolume: 171,
          organicRank: 109
        },
        {
          date: '2024-08-21',
          searchVolume: 171,
          organicRank: 306
        },
        {
          date: '2024-08-20',
          searchVolume: 171,
          organicRank: 306
        },
        {
          date: '2024-08-19',
          searchVolume: 171,
          organicRank: 105
        },
        {
          date: '2024-08-17',
          searchVolume: 171,
          organicRank: 306
        },
        {
          date: '2024-08-16',
          searchVolume: 162,
          organicRank: 101
        },
        {
          date: '2024-08-15',
          searchVolume: 162,
          organicRank: 306
        },
        {
          date: '2024-08-14',
          searchVolume: 162,
          organicRank: 99
        },
        {
          date: '2024-08-13',
          searchVolume: 162,
          organicRank: 96
        },
        {
          date: '2024-08-12',
          searchVolume: 162,
          organicRank: 92
        },
        {
          date: '2024-08-11',
          searchVolume: 162,
          organicRank: 92
        },
        {
          date: '2024-08-10',
          searchVolume: 162,
          organicRank: 90
        },
        {
          date: '2024-08-09',
          searchVolume: 147,
          organicRank: 78
        },
        {
          date: '2024-08-08',
          searchVolume: 147,
          organicRank: 78
        },
        {
          date: '2024-08-07',
          searchVolume: 147,
          organicRank: 106
        },
        {
          date: '2024-08-06',
          searchVolume: 147,
          organicRank: 98
        },
        {
          date: '2024-08-05',
          searchVolume: 147,
          organicRank: 89
        },
        {
          date: '2024-08-04',
          searchVolume: 147,
          organicRank: 87
        },
        {
          date: '2024-08-03',
          searchVolume: 147,
          organicRank: 95
        },
        {
          date: '2024-08-02',
          searchVolume: 119,
          organicRank: 107
        },
        {
          date: '2024-07-31',
          searchVolume: 119,
          organicRank: 96
        },
        {
          date: '2024-07-30',
          searchVolume: 119,
          organicRank: 97
        },
        {
          date: '2024-07-29',
          searchVolume: 119,
          organicRank: 43
        },
        {
          date: '2024-07-28',
          searchVolume: 119,
          organicRank: 103
        },
        {
          date: '2024-07-27',
          searchVolume: 119,
          organicRank: 106
        },
        {
          date: '2024-07-26',
          searchVolume: 172,
          organicRank: 104
        },
        {
          date: '2024-07-25',
          searchVolume: 172,
          organicRank: 104
        },
        {
          date: '2024-07-24',
          searchVolume: 172,
          organicRank: 91
        },
        {
          date: '2024-07-23',
          searchVolume: 172,
          organicRank: 89
        },
        {
          date: '2024-07-22',
          searchVolume: 172,
          organicRank: 86
        },
        {
          date: '2024-07-21',
          searchVolume: 172,
          organicRank: 91
        },
        {
          date: '2024-07-20',
          searchVolume: 172,
          organicRank: 91
        },
        {
          date: '2024-07-19',
          searchVolume: 153,
          organicRank: 86
        },
        {
          date: '2024-07-18',
          searchVolume: 153,
          organicRank: 90
        },
        {
          date: '2024-07-17',
          searchVolume: 153,
          organicRank: 87
        },
        {
          date: '2024-07-16',
          searchVolume: 153,
          organicRank: 86
        },
        {
          date: '2024-07-15',
          searchVolume: 153,
          organicRank: 82
        },
        {
          date: '2024-07-14',
          searchVolume: 153,
          organicRank: 74
        },
        {
          date: '2024-07-13',
          searchVolume: 153,
          organicRank: 75
        },
        {
          date: '2024-07-12',
          searchVolume: 150,
          organicRank: 70
        },
        {
          date: '2024-07-11',
          searchVolume: 150,
          organicRank: 71
        },
        {
          date: '2024-07-10',
          searchVolume: 150,
          organicRank: 98
        },
        {
          date: '2024-07-09',
          searchVolume: 150,
          organicRank: 88
        },
        {
          date: '2024-07-08',
          searchVolume: 150,
          organicRank: 89
        },
        {
          date: '2024-07-07',
          searchVolume: 150,
          organicRank: 90
        },
        {
          date: '2024-07-06',
          searchVolume: 150,
          organicRank: 85
        },
        {
          date: '2024-07-05',
          searchVolume: 172,
          organicRank: 136
        },
        {
          date: '2024-07-04',
          searchVolume: 172,
          organicRank: 53
        },
        {
          date: '2024-07-03',
          searchVolume: 172,
          organicRank: 45
        },
        {
          date: '2024-07-02',
          searchVolume: 172,
          organicRank: 49
        },
        {
          date: '2024-07-01',
          searchVolume: 172,
          organicRank: 124
        },
        {
          date: '2024-06-30',
          searchVolume: 172,
          organicRank: 48
        },
        {
          date: '2024-06-29',
          searchVolume: 172,
          organicRank: 167
        },
        {
          date: '2024-06-28',
          searchVolume: 129,
          organicRank: 57
        },
        {
          date: '2024-06-27',
          searchVolume: 129,
          organicRank: 170
        },
        {
          date: '2024-06-26',
          searchVolume: 129,
          organicRank: 155
        },
        {
          date: '2024-06-25',
          searchVolume: 129,
          organicRank: 206
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_sound enhancing ear buds`,
      keyword: 'sound enhancing ear buds',
      searchVolume: 98,
      organicStartRank: 115,
      organicHighestRank: 16,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-25',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-24',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-22',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-21',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-20',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-19',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-18',
          searchVolume: 164,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-16',
          searchVolume: 131,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-15',
          searchVolume: 131,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-14',
          searchVolume: 131,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-13',
          searchVolume: 131,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-11',
          searchVolume: 131,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-09',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-08',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-07',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-06',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-05',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-04',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-03',
          searchVolume: 99,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-01',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-31',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-30',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-29',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-28',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-27',
          searchVolume: 106,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-26',
          searchVolume: 151,
          organicRank: 268,

          keywordSales: 2
        },
        {
          date: '2024-07-25',
          searchVolume: 151,
          organicRank: 243,

          keywordSales: 2
        },
        {
          date: '2024-07-24',
          searchVolume: 151,
          organicRank: 163,

          keywordSales: 2
        },
        {
          date: '2024-07-23',
          searchVolume: 151,
          organicRank: 140,

          keywordSales: 2
        },
        {
          date: '2024-07-22',
          searchVolume: 151,
          organicRank: 134,

          keywordSales: 2
        },
        {
          date: '2024-07-21',
          searchVolume: 151,
          organicRank: 120,

          keywordSales: 2
        },
        {
          date: '2024-07-20',
          searchVolume: 151,
          organicRank: 132,

          keywordSales: 2
        },
        {
          date: '2024-07-19',
          searchVolume: 193,
          organicRank: 136,

          keywordSales: 2
        },
        {
          date: '2024-07-18',
          searchVolume: 193,
          organicRank: 146,

          keywordSales: 2
        },
        {
          date: '2024-07-17',
          searchVolume: 193,
          organicRank: 142,

          keywordSales: 2
        },
        {
          date: '2024-07-16',
          searchVolume: 193,
          organicRank: 129,

          keywordSales: 2
        },
        {
          date: '2024-07-15',
          searchVolume: 193,
          organicRank: 98,

          keywordSales: 2
        },
        {
          date: '2024-07-14',
          searchVolume: 193,
          organicRank: 102,

          keywordSales: 2
        },
        {
          date: '2024-07-13',
          searchVolume: 193,
          organicRank: 82,

          keywordSales: 2
        },
        {
          date: '2024-07-12',
          searchVolume: 205,
          organicRank: 71,

          keywordSales: 2
        },
        {
          date: '2024-07-11',
          searchVolume: 205,
          organicRank: 66,

          keywordSales: 2
        },
        {
          date: '2024-07-10',
          searchVolume: 205,
          organicRank: 61,

          keywordSales: 2
        },
        {
          date: '2024-07-09',
          searchVolume: 205,
          organicRank: 255,

          keywordSales: 2
        },
        {
          date: '2024-07-08',
          searchVolume: 205,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-07',
          searchVolume: 205,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-06',
          searchVolume: 205,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-05',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-04',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-03',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-01',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-30',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-29',
          searchVolume: 176,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-28',
          searchVolume: 136,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-27',
          searchVolume: 136,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-26',
          searchVolume: 136,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-25',
          searchVolume: 136,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-24',
          searchVolume: 136,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-06-21',
          searchVolume: 120,
          organicRank: 306,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_true wireless`,
      keyword: 'true wireless',
      searchVolume: 272,
      organicStartRank: 306,
      organicHighestRank: 23,
      sponsoredStartRank: 19,
      sponsoredHighestRank: 19,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 366,
          organicRank: 79,

          keywordSales: 2
        },
        {
          date: '2024-08-24',
          searchVolume: 366,
          organicRank: 102,

          keywordSales: 2
        },
        {
          date: '2024-08-23',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-22',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-21',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-19',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-18',
          searchVolume: 366,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-16',
          searchVolume: 355,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-15',
          searchVolume: 355,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-14',
          searchVolume: 355,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-13',
          searchVolume: 355,
          organicRank: 101,

          keywordSales: 2
        },
        {
          date: '2024-08-12',
          searchVolume: 355,
          organicRank: 108,

          keywordSales: 2
        },
        {
          date: '2024-08-11',
          searchVolume: 355,
          organicRank: 108,

          keywordSales: 2
        },
        {
          date: '2024-08-10',
          searchVolume: 355,
          organicRank: 102,

          keywordSales: 2
        },
        {
          date: '2024-08-09',
          searchVolume: 291,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-08-08',
          searchVolume: 291,
          organicRank: 56,

          keywordSales: 2
        },
        {
          date: '2024-08-07',
          searchVolume: 291,
          organicRank: 62,

          keywordSales: 2
        },
        {
          date: '2024-08-06',
          searchVolume: 291,
          organicRank: 45,

          keywordSales: 2
        },
        {
          date: '2024-08-05',
          searchVolume: 291,
          organicRank: 71,

          keywordSales: 2
        },
        {
          date: '2024-08-04',
          searchVolume: 291,
          organicRank: 75,

          keywordSales: 2
        },
        {
          date: '2024-08-02',
          searchVolume: 347,
          organicRank: 70,

          keywordSales: 2
        },
        {
          date: '2024-08-01',
          searchVolume: 347,
          organicRank: 80,

          keywordSales: 2
        },
        {
          date: '2024-07-31',
          searchVolume: 347,
          organicRank: 65,

          keywordSales: 2
        },
        {
          date: '2024-07-30',
          searchVolume: 347,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-29',
          searchVolume: 347,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-28',
          searchVolume: 347,
          organicRank: 127,

          keywordSales: 2
        },
        {
          date: '2024-07-27',
          searchVolume: 347,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-26',
          searchVolume: 449,
          organicRank: 92,

          keywordSales: 2
        },
        {
          date: '2024-07-25',
          searchVolume: 449,
          organicRank: 109,

          keywordSales: 2
        },
        {
          date: '2024-07-24',
          searchVolume: 449,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-23',
          searchVolume: 449,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-22',
          searchVolume: 449,
          organicRank: 114,

          keywordSales: 2
        },
        {
          date: '2024-07-21',
          searchVolume: 449,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-20',
          searchVolume: 449,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-19',
          searchVolume: 306,
          organicRank: 114,

          keywordSales: 2
        },
        {
          date: '2024-07-18',
          searchVolume: 306,
          organicRank: 125,

          keywordSales: 2
        },
        {
          date: '2024-07-17',
          searchVolume: 306,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-07-16',
          searchVolume: 306,
          organicRank: 107,

          keywordSales: 2
        },
        {
          date: '2024-07-15',
          searchVolume: 306,
          organicRank: 56,

          keywordSales: 2
        },
        {
          date: '2024-07-14',
          searchVolume: 306,
          organicRank: 54,

          keywordSales: 2
        },
        {
          date: '2024-07-13',
          searchVolume: 306,
          organicRank: 47,

          keywordSales: 2
        },
        {
          date: '2024-07-12',
          searchVolume: 286,
          organicRank: 45,

          keywordSales: 2
        },
        {
          date: '2024-07-11',
          searchVolume: 286,
          organicRank: 52,

          keywordSales: 2
        },
        {
          date: '2024-07-10',
          searchVolume: 286,
          organicRank: 38,

          keywordSales: 2
        },
        {
          date: '2024-07-09',
          searchVolume: 286,
          organicRank: 36,

          keywordSales: 2
        },
        {
          date: '2024-07-08',
          searchVolume: 286,
          organicRank: 57,

          keywordSales: 2
        },
        {
          date: '2024-07-07',
          searchVolume: 286,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-07-06',
          searchVolume: 286,
          organicRank: 29,

          keywordSales: 2
        },
        {
          date: '2024-07-05',
          searchVolume: 340,
          organicRank: 27,

          keywordSales: 2
        },
        {
          date: '2024-07-04',
          searchVolume: 340,
          organicRank: 50,

          keywordSales: 2
        },
        {
          date: '2024-07-03',
          searchVolume: 340,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 340,
          organicRank: 27,

          keywordSales: 2
        },
        {
          date: '2024-07-01',
          searchVolume: 340,
          organicRank: 26,

          keywordSales: 2
        },
        {
          date: '2024-06-30',
          searchVolume: 340,
          organicRank: 43,

          keywordSales: 2
        },
        {
          date: '2024-06-29',
          searchVolume: 340,
          organicRank: 71,

          keywordSales: 2
        },
        {
          date: '2024-06-28',
          searchVolume: 265,
          organicRank: 67,

          keywordSales: 2
        },
        {
          date: '2024-06-27',
          searchVolume: 265,
          organicRank: 41,

          keywordSales: 2
        },
        {
          date: '2024-06-26',
          searchVolume: 265,
          organicRank: 58,

          keywordSales: 2
        },
        {
          date: '2024-06-25',
          searchVolume: 265,
          organicRank: 58,

          keywordSales: 2
        },
        {
          date: '2024-06-24',
          searchVolume: 265,
          organicRank: 56,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_true wireless earbud`,
      keyword: 'true wireless earbud',
      searchVolume: 38,
      organicStartRank: 306,
      organicHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 73,
          organicRank: 269
        },
        {
          date: '2024-08-23',
          searchVolume: 73,
          organicRank: 105
        },
        {
          date: '2024-08-21',
          searchVolume: 73,
          organicRank: 128
        },
        {
          date: '2024-08-19',
          searchVolume: 73,
          organicRank: 143
        },
        {
          date: '2024-08-17',
          searchVolume: 73,
          organicRank: 132
        },
        {
          date: '2024-08-15',
          searchVolume: 56,
          organicRank: 135
        },
        {
          date: '2024-08-13',
          searchVolume: 56,
          organicRank: 57
        },
        {
          date: '2024-08-11',
          searchVolume: 56,
          organicRank: 87
        },
        {
          date: '2024-08-09',
          searchVolume: 44,
          organicRank: 91
        },
        {
          date: '2024-08-07',
          searchVolume: 44,
          organicRank: 109
        },
        {
          date: '2024-08-05',
          searchVolume: 44,
          organicRank: 84
        },
        {
          date: '2024-08-03',
          searchVolume: 44,
          organicRank: 96
        },
        {
          date: '2024-08-01',
          searchVolume: 55,
          organicRank: 116
        },
        {
          date: '2024-07-30',
          searchVolume: 55,
          organicRank: 119
        },
        {
          date: '2024-07-28',
          searchVolume: 55,
          organicRank: 109
        },
        {
          date: '2024-07-26',
          searchVolume: 67,
          organicRank: 87
        },
        {
          date: '2024-07-24',
          searchVolume: 67,
          organicRank: 86
        },
        {
          date: '2024-07-22',
          searchVolume: 67,
          organicRank: 81
        },
        {
          date: '2024-07-20',
          searchVolume: 67,
          organicRank: 96
        },
        {
          date: '2024-07-18',
          searchVolume: 50,
          organicRank: 86
        },
        {
          date: '2024-07-16',
          searchVolume: 50,
          organicRank: 74
        },
        {
          date: '2024-07-14',
          searchVolume: 50,
          organicRank: 68
        },
        {
          date: '2024-07-12',
          searchVolume: 56,
          organicRank: 41
        },
        {
          date: '2024-07-09',
          searchVolume: 56,
          organicRank: 40
        },
        {
          date: '2024-07-07',
          searchVolume: 56,
          organicRank: 36
        },
        {
          date: '2024-07-05',
          searchVolume: 49,
          organicRank: 25
        },
        {
          date: '2024-07-03',
          searchVolume: 49,
          organicRank: 36
        },
        {
          date: '2024-07-01',
          searchVolume: 49,
          organicRank: 30
        },
        {
          date: '2024-06-29',
          searchVolume: 49,
          organicRank: 89
        },
        {
          date: '2024-06-27',
          searchVolume: 44,
          organicRank: 103
        },
        {
          date: '2024-06-25',
          searchVolume: 44,
          organicRank: 92
        },
        {
          date: '2024-06-23',
          searchVolume: 44,
          organicRank: 48
        },
        {
          date: '2024-06-20',
          searchVolume: 34,
          organicRank: 89
        },
        {
          date: '2024-06-18',
          searchVolume: 34,
          organicRank: 56
        },
        {
          date: '2024-06-16',
          searchVolume: 34,
          organicRank: 65
        },
        {
          date: '2024-06-14',
          searchVolume: 36,
          organicRank: 176
        },
        {
          date: '2024-06-13',
          searchVolume: 36,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 36,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_voyager black`,
      keyword: 'voyager black',
      searchVolume: 205,
      organicStartRank: 7,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 75,
          organicRank: 9
        },
        {
          date: '2024-08-23',
          searchVolume: 75,
          organicRank: 10
        },
        {
          date: '2024-08-21',
          searchVolume: 75,
          organicRank: 10
        },
        {
          date: '2024-08-19',
          searchVolume: 75,
          organicRank: 12
        },
        {
          date: '2024-08-17',
          searchVolume: 75,
          organicRank: 12
        },
        {
          date: '2024-08-15',
          searchVolume: 97,
          organicRank: 10
        },
        {
          date: '2024-08-13',
          searchVolume: 97,
          organicRank: 9
        },
        {
          date: '2024-08-11',
          searchVolume: 97,
          organicRank: 9
        },
        {
          date: '2024-08-09',
          searchVolume: 92,
          organicRank: 6
        },
        {
          date: '2024-08-08',
          searchVolume: 92,
          organicRank: 6
        },
        {
          date: '2024-08-07',
          searchVolume: 92,
          organicRank: 6
        },
        {
          date: '2024-08-06',
          searchVolume: 92,
          organicRank: 5
        },
        {
          date: '2024-08-05',
          searchVolume: 92,
          organicRank: 5
        },
        {
          date: '2024-08-04',
          searchVolume: 92,
          organicRank: 6
        },
        {
          date: '2024-08-03',
          searchVolume: 92,
          organicRank: 5
        },
        {
          date: '2024-08-01',
          searchVolume: 120,
          organicRank: 5
        },
        {
          date: '2024-07-31',
          searchVolume: 120,
          organicRank: 5
        },
        {
          date: '2024-07-30',
          searchVolume: 120,
          organicRank: 4
        },
        {
          date: '2024-07-29',
          searchVolume: 120,
          organicRank: 4
        },
        {
          date: '2024-07-28',
          searchVolume: 120,
          organicRank: 5
        },
        {
          date: '2024-07-27',
          searchVolume: 120,
          organicRank: 5
        },
        {
          date: '2024-07-26',
          searchVolume: 129,
          organicRank: 5
        },
        {
          date: '2024-07-25',
          searchVolume: 129,
          organicRank: 5
        },
        {
          date: '2024-07-24',
          searchVolume: 129,
          organicRank: 5
        },
        {
          date: '2024-07-23',
          searchVolume: 129,
          organicRank: 4
        },
        {
          date: '2024-07-22',
          searchVolume: 129,
          organicRank: 4
        },
        {
          date: '2024-07-21',
          searchVolume: 129,
          organicRank: 4
        },
        {
          date: '2024-07-20',
          searchVolume: 129,
          organicRank: 4
        },
        {
          date: '2024-07-19',
          searchVolume: 116,
          organicRank: 4
        },
        {
          date: '2024-07-18',
          searchVolume: 116,
          organicRank: 5
        },
        {
          date: '2024-07-17',
          searchVolume: 116,
          organicRank: 5
        },
        {
          date: '2024-07-16',
          searchVolume: 116,
          organicRank: 4
        },
        {
          date: '2024-07-15',
          searchVolume: 116,
          organicRank: 3
        },
        {
          date: '2024-07-14',
          searchVolume: 116,
          organicRank: 4
        },
        {
          date: '2024-07-13',
          searchVolume: 116,
          organicRank: 4
        },
        {
          date: '2024-07-12',
          searchVolume: 146,
          organicRank: 4
        },
        {
          date: '2024-07-11',
          searchVolume: 146,
          organicRank: 4
        },
        {
          date: '2024-07-10',
          searchVolume: 146,
          organicRank: 3
        },
        {
          date: '2024-07-09',
          searchVolume: 146,
          organicRank: 3
        },
        {
          date: '2024-07-08',
          searchVolume: 146,
          organicRank: 2
        },
        {
          date: '2024-07-07',
          searchVolume: 146,
          organicRank: 2
        },
        {
          date: '2024-07-06',
          searchVolume: 146,
          organicRank: 2
        },
        {
          date: '2024-07-05',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-07-04',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-07-03',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-07-01',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-06-30',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-06-29',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-06-28',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-06-27',
          searchVolume: 177,
          organicRank: 2
        },
        {
          date: '2024-06-26',
          searchVolume: 177,
          organicRank: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 177,
          organicRank: 5
        },
        {
          date: '2024-06-24',
          searchVolume: 177,
          organicRank: 5
        },
        {
          date: '2024-06-21',
          searchVolume: 186,
          organicRank: 4,
          sponsoredRank: 2
        },
        {
          date: '2024-06-20',
          searchVolume: 186,
          organicRank: 4,
          sponsoredRank: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 186,
          organicRank: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 186,
          organicRank: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 186,
          organicRank: 1
        },
        {
          date: '2024-06-16',
          searchVolume: 186,
          organicRank: 3
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_voyager headphones`,
      keyword: 'voyager headphones',
      searchVolume: 0,
      organicStartRank: 26,
      organicHighestRank: 6,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 0,
          organicRank: 15
        },
        {
          date: '2024-08-24',
          searchVolume: 0,
          organicRank: 14
        },
        {
          date: '2024-08-22',
          searchVolume: 0,
          organicRank: 15
        },
        {
          date: '2024-08-20',
          searchVolume: 0,
          organicRank: 14
        },
        {
          date: '2024-08-18',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-08-16',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-08-14',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-08-12',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-08-10',
          searchVolume: 0,
          organicRank: 9
        },
        {
          date: '2024-08-08',
          searchVolume: 0,
          organicRank: 9
        },
        {
          date: '2024-08-06',
          searchVolume: 0,
          organicRank: 13
        },
        {
          date: '2024-08-04',
          searchVolume: 0,
          organicRank: 12
        },
        {
          date: '2024-08-02',
          searchVolume: 0,
          organicRank: 15
        },
        {
          date: '2024-07-31',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-07-29',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-07-27',
          searchVolume: 0,
          organicRank: 6
        },
        {
          date: '2024-07-25',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-07-23',
          searchVolume: 0,
          organicRank: 12
        },
        {
          date: '2024-07-21',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-07-19',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-07-17',
          searchVolume: 0,
          organicRank: 8
        },
        {
          date: '2024-07-15',
          searchVolume: 0,
          organicRank: 8
        },
        {
          date: '2024-07-13',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-07-11',
          searchVolume: 0,
          organicRank: 13
        },
        {
          date: '2024-07-08',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-07-06',
          searchVolume: 0,
          organicRank: 13
        },
        {
          date: '2024-07-04',
          searchVolume: 0,
          organicRank: 11
        },
        {
          date: '2024-07-02',
          searchVolume: 0,
          organicRank: 10
        },
        {
          date: '2024-06-30',
          searchVolume: 0,
          organicRank: 9
        },
        {
          date: '2024-06-28',
          searchVolume: 0,
          organicRank: 13
        },
        {
          date: '2024-06-26',
          searchVolume: 0,
          organicRank: 8
        },
        {
          date: '2024-06-24',
          searchVolume: 0,
          organicRank: 33
        },
        {
          date: '2024-06-21',
          searchVolume: 0,
          organicRank: 41
        },
        {
          date: '2024-06-19',
          searchVolume: 0,
          organicRank: 22
        },
        {
          date: '2024-06-17',
          searchVolume: 0,
          organicRank: 15
        },
        {
          date: '2024-06-15',
          searchVolume: 0,
          organicRank: 13
        },
        {
          date: '2024-06-13',
          searchVolume: 0,
          organicRank: 28
        },
        {
          date: '2024-06-11',
          searchVolume: 0,
          organicRank: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 0,
          organicRank: 46
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_wired earbuds`,
      keyword: 'wired earbuds',
      searchVolume: 45976,
      organicStartRank: 306,
      organicHighestRank: 248,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-25',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-24',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-23',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-22',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-21',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-20',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-19',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-18',
          searchVolume: 109367,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-15',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-14',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-13',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-12',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-11',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-10',
          searchVolume: 105174,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-09',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-08',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-07',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-06',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-05',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-04',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-03',
          searchVolume: 91594,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-02',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-08-01',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-31',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-30',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-29',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-28',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-27',
          searchVolume: 78326,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-26',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-25',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-24',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-23',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-22',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-21',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-20',
          searchVolume: 78990,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-19',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-18',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-17',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-16',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-15',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-14',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-13',
          searchVolume: 59932,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-12',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-10',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-09',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-08',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-07',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-06',
          searchVolume: 50400,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-05',
          searchVolume: 45976,
          organicRank: 248,

          keywordSales: 1347
        },
        {
          date: '2024-07-04',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-03',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-02',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-07-01',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-30',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-29',
          searchVolume: 45976,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-28',
          searchVolume: 42665,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-27',
          searchVolume: 42665,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-26',
          searchVolume: 42665,
          organicRank: 306,

          keywordSales: 1347
        },
        {
          date: '2024-06-25',
          searchVolume: 42665,
          organicRank: 306,

          keywordSales: 1347
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_wireless earbud`,
      keyword: 'wireless earbud',
      searchVolume: 951,
      organicStartRank: 306,
      organicHighestRank: 157,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-25',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-24',
          searchVolume: 953,
          organicRank: 266,

          keywordSales: 27
        },
        {
          date: '2024-08-23',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-22',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-21',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-20',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-19',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-16',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-15',
          searchVolume: 1285,
          organicRank: 278,

          keywordSales: 27
        },
        {
          date: '2024-08-14',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-13',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-12',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-11',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-10',
          searchVolume: 1285,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-09',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-08',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-07',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-06',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-05',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-04',
          searchVolume: 888,
          organicRank: 206,

          keywordSales: 27
        },
        {
          date: '2024-08-03',
          searchVolume: 888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-02',
          searchVolume: 818,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-08-01',
          searchVolume: 818,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-31',
          searchVolume: 818,
          organicRank: 280,

          keywordSales: 27
        },
        {
          date: '2024-07-30',
          searchVolume: 818,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-29',
          searchVolume: 818,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-28',
          searchVolume: 818,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-27',
          searchVolume: 818,
          organicRank: 269,

          keywordSales: 27
        },
        {
          date: '2024-07-26',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-25',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-24',
          searchVolume: 1614,
          organicRank: 279,

          keywordSales: 27
        },
        {
          date: '2024-07-23',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-22',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-21',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-20',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-19',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-18',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-17',
          searchVolume: 701,
          organicRank: 243,

          keywordSales: 27
        },
        {
          date: '2024-07-16',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-15',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-14',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-13',
          searchVolume: 701,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-12',
          searchVolume: 775,
          organicRank: 220,

          keywordSales: 27
        },
        {
          date: '2024-07-10',
          searchVolume: 775,
          organicRank: 210,

          keywordSales: 27
        },
        {
          date: '2024-07-09',
          searchVolume: 775,
          organicRank: 222,

          keywordSales: 27
        },
        {
          date: '2024-07-08',
          searchVolume: 775,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-07',
          searchVolume: 775,
          organicRank: 221,

          keywordSales: 27
        },
        {
          date: '2024-07-06',
          searchVolume: 775,
          organicRank: 218,

          keywordSales: 27
        },
        {
          date: '2024-07-05',
          searchVolume: 748,
          organicRank: 221,

          keywordSales: 27
        },
        {
          date: '2024-07-04',
          searchVolume: 748,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-07-03',
          searchVolume: 748,
          organicRank: 216,

          keywordSales: 27
        },
        {
          date: '2024-07-02',
          searchVolume: 748,
          organicRank: 215,

          keywordSales: 27
        },
        {
          date: '2024-07-01',
          searchVolume: 748,
          organicRank: 224,

          keywordSales: 27
        },
        {
          date: '2024-06-30',
          searchVolume: 748,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-29',
          searchVolume: 748,
          organicRank: 230,

          keywordSales: 27
        },
        {
          date: '2024-06-28',
          searchVolume: 808,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-27',
          searchVolume: 808,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-26',
          searchVolume: 808,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-25',
          searchVolume: 808,
          organicRank: 218,

          keywordSales: 27
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_wireless earbud pc`,
      keyword: 'wireless earbud pc',
      searchVolume: 0,
      organicStartRank: 306,
      organicHighestRank: 15,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-26',
          searchVolume: 0,
          organicRank: 40,

          keywordSales: 1
        },
        {
          date: '2024-08-24',
          searchVolume: 0,
          organicRank: 45,

          keywordSales: 1
        },
        {
          date: '2024-08-22',
          searchVolume: 0,
          organicRank: 216,

          keywordSales: 1
        },
        {
          date: '2024-08-20',
          searchVolume: 0,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-08-18',
          searchVolume: 0,
          organicRank: 44,

          keywordSales: 1
        },
        {
          date: '2024-08-16',
          searchVolume: 0,
          organicRank: 46,

          keywordSales: 1
        },
        {
          date: '2024-08-14',
          searchVolume: 0,
          organicRank: 43,

          keywordSales: 1
        },
        {
          date: '2024-08-12',
          searchVolume: 0,
          organicRank: 34,

          keywordSales: 1
        },
        {
          date: '2024-08-10',
          searchVolume: 0,
          organicRank: 33,

          keywordSales: 1
        },
        {
          date: '2024-08-08',
          searchVolume: 0,
          organicRank: 31,

          keywordSales: 1
        },
        {
          date: '2024-08-06',
          searchVolume: 0,
          organicRank: 35,

          keywordSales: 1
        },
        {
          date: '2024-08-04',
          searchVolume: 0,
          organicRank: 39,

          keywordSales: 1
        },
        {
          date: '2024-08-02',
          searchVolume: 0,
          organicRank: 42,

          keywordSales: 1
        },
        {
          date: '2024-07-31',
          searchVolume: 0,
          organicRank: 36,

          keywordSales: 1
        },
        {
          date: '2024-07-29',
          searchVolume: 0,
          organicRank: 51,

          keywordSales: 1
        },
        {
          date: '2024-07-27',
          searchVolume: 0,
          organicRank: 44,

          keywordSales: 1
        },
        {
          date: '2024-07-25',
          searchVolume: 0,
          organicRank: 42,

          keywordSales: 1
        },
        {
          date: '2024-07-23',
          searchVolume: 0,
          organicRank: 33,

          keywordSales: 1
        },
        {
          date: '2024-07-21',
          searchVolume: 0,
          organicRank: 33,

          keywordSales: 1
        },
        {
          date: '2024-07-19',
          searchVolume: 0,
          organicRank: 31,

          keywordSales: 1
        },
        {
          date: '2024-07-17',
          searchVolume: 0,
          organicRank: 29,

          keywordSales: 1
        },
        {
          date: '2024-07-15',
          searchVolume: 0,
          organicRank: 25,

          keywordSales: 1
        },
        {
          date: '2024-07-13',
          searchVolume: 0,
          organicRank: 22,

          keywordSales: 1
        },
        {
          date: '2024-07-11',
          searchVolume: 0,
          organicRank: 21,

          keywordSales: 1
        },
        {
          date: '2024-07-08',
          searchVolume: 0,
          organicRank: 30,

          keywordSales: 1
        },
        {
          date: '2024-07-06',
          searchVolume: 0,
          organicRank: 23,

          keywordSales: 1
        },
        {
          date: '2024-07-04',
          searchVolume: 0,
          organicRank: 17,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 0,
          organicRank: 15,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 0,
          organicRank: 17,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 0,
          organicRank: 31,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 0,
          organicRank: 30,

          keywordSales: 1
        },
        {
          date: '2024-06-24',
          searchVolume: 0,
          organicRank: 23,

          keywordSales: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 0,
          organicRank: 29,

          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 0,
          organicRank: 19,

          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 0,
          organicRank: 19,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 0,
          organicRank: 20,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 0,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 0,
          organicRank: 306,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '7268c4c4-8d81-4a69-bc03-b87a47d86016',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id}_wireless earbuds noise cancelling`,
      keyword: 'wireless earbuds noise cancelling',
      searchVolume: 6751,
      organicStartRank: 306,
      organicHighestRank: 258,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-25',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-24',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-23',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-22',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-21',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-19',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-18',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-15',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-14',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-13',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-12',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-11',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-10',
          searchVolume: 8091,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-09',
          searchVolume: 7163,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-08',
          searchVolume: 7163,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-07',
          searchVolume: 7163,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-06',
          searchVolume: 7163,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-05',
          searchVolume: 7163,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-02',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-08-01',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-31',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-30',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-29',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-28',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-27',
          searchVolume: 6237,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-26',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-25',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-24',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-23',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-22',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-21',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-20',
          searchVolume: 10561,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-19',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-18',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-17',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-16',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-15',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-14',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-13',
          searchVolume: 6996,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-12',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-11',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-10',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-09',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-08',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-07',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-06',
          searchVolume: 6453,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-05',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-04',
          searchVolume: 6751,
          organicRank: 258,

          keywordSales: 240
        },
        {
          date: '2024-07-03',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-02',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-07-01',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-30',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-29',
          searchVolume: 6751,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-28',
          searchVolume: 6725,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-27',
          searchVolume: 6725,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-26',
          searchVolume: 6725,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-25',
          searchVolume: 6725,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-24',
          searchVolume: 6725,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-21',
          searchVolume: 7685,
          organicRank: 306,

          keywordSales: 240
        },
        {
          date: '2024-06-20',
          searchVolume: 7685,
          organicRank: 306,

          keywordSales: 240
        }
      ]
    }
  ]
}

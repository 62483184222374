import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import Loading from '@momentum/components/loading'
import { DataGrid } from '@momentum/components/table'
import { Search } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { gridClasses } from '@mui/x-data-grid-pro'
import { notEmpty } from '@productwindtom/shared-node'
import { keyBy, uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Invitation, User, listPendingInvitations, listUsers } from './queries'
import { UserRow, UserRowType, UserTableColumns } from './tableDefinition'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const Users = () => {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState<User[]>()
  const [invitations, setInvitations] = useState<Invitation[]>()

  const { companies, brands } = useUserSessionContext()

  const keyedBrands = keyBy(brands, 'id')
  const keyedCompanies = keyBy(companies, 'id')

  useEffect(() => {
    listPendingInvitations().then(setInvitations)
    listUsers().then(setUsers)
  }, [])

  const allRows: UserRow[] | undefined = useMemo(() => {
    if (users && invitations) {
      const mappedUsers: UserRow[] = users.map(user => {
        const primaryBrand = user.primaryBrandId ? keyedBrands[user.primaryBrandId] : undefined
        const primaryCompany = primaryBrand?.companyId ? keyedCompanies[primaryBrand.companyId] : undefined
        return {
          id: user.id,
          type: UserRowType.USER,
          email: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
          brands: user.companies
            .flatMap(c =>
              c.isAllBrandAccess
                ? brands.filter(brand => brand.companyId === c.companyId).map(b => b.id)
                : c.userBrands.map(ub => ub.brandId)
            )
            .map(brandId => keyedBrands[brandId])
            .filter(notEmpty),
          companies: uniqBy(
            user.companies.map(({ companyId }) => keyedCompanies[companyId]).filter(notEmpty),
            c => c.id
          ),
          role: user.role,
          primaryBrand: primaryBrand?.name,
          primaryCompany: primaryCompany?.name,
          lastActiveAt: user.lastActiveAt
        }
      })

      const mappedInvitations: UserRow[] = (invitations || []).map(invitation => ({
        id: invitation.id,
        type: UserRowType.INVITATION,
        email: invitation.email,
        fullName: `${invitation.firstName} ${invitation.lastName}`,
        brands: (invitation.brandIds || []).map(b => keyedBrands[b!]).filter(notEmpty),
        companies:
          invitation.companyId && keyedCompanies[invitation.companyId]
            ? [keyedCompanies[invitation.companyId]]
            : undefined,
        role: invitation.role,
        primaryBrand: invitation.primaryBrandId ? keyedBrands[invitation.primaryBrandId]?.name : undefined,
        primaryCompany: invitation.companyId && keyedCompanies[invitation.companyId]?.name
      }))

      return [...mappedUsers, ...mappedInvitations]
    }
  }, [search, users, invitations])

  const filteredRows = useMemo(() => {
    return allRows?.filter(
      r =>
        !search ||
        r.id === search ||
        r.email.toLowerCase().includes(search) ||
        r.fullName.toLowerCase().includes(search) ||
        r.companies?.find(c => c.name.toLowerCase().includes(search)) ||
        r.brands?.find(b => b.name.toLowerCase().includes(search)) ||
        r.primaryCompany?.toLowerCase().includes(search) ||
        r.primaryBrand?.toLowerCase().includes(search)
    )
  }, [allRows, search])

  if (!filteredRows) {
    return <Loading />
  }

  return (
    <Stack py={2}>
      <Typography variant={'h3'}>All Users</Typography>
      <Stack direction={'row'} my={2}>
        <DebouncedTextField
          fullWidth
          placeholder={'Search users by email, name, brand, or company'}
          sx={{ mb: 4 }}
          onChange={search => setSearch(search.toLowerCase())}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <DataGrid
          autoHeight
          rows={filteredRows}
          columns={UserTableColumns}
          disableColumnMenu={true}
          disableColumnReorder={true}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none'
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none'
            }
          }}
        />
      </Stack>
    </Stack>
  )
}

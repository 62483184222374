import { FeedbackDataInput } from '@momentum/routes/campaign/e-commerce/feedback/feedback-data-display/types'
import { useFeedbackContext } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { Box } from '@mui/material'
import { ActiveElement, Chart, ChartEvent } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { OTHER_THEME } from '../../constants'

export const FeedbackDoughnut = ({ data }: { data: FeedbackDataInput[] }) => {
  const { setSelectedTheme, setChartRef } = useFeedbackContext()
  const labels = data.map(d => d.theme)
  const numbers = data.map(d => d.percentage)
  const colors = data.map(d => d.color)

  return (
    <Box>
      <Doughnut
        ref={setChartRef}
        height={270}
        width={270}
        options={{
          responsive: true,
          cutout: '75%',
          onClick(event: ChartEvent, elements: ActiveElement[], chart: Chart) {
            if (elements.length) {
              const label = labels[elements[0].index]
              if (label && label !== OTHER_THEME) {
                setSelectedTheme(label)
              }
            }
          },
          interaction: {},
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'white',
              displayColors: false,
              padding: 4,

              bodySpacing: 0,
              bodyColor: 'black',
              bodyFont: {
                size: 10,
                weight: 400,
                lineHeight: '18px'
              },

              borderColor: '#C4C4C4',
              borderWidth: 1,
              callbacks: {
                label: item => `${item.formattedValue}% - ${item.label}`,
                title: () => '',
                footer: () => ''
              }
            }
          }
        }}
        data={{
          labels,
          datasets: [
            {
              data: numbers,
              backgroundColor: colors,
              borderWidth: 0,
              hoverBorderWidth: 6,
              hoverBorderColor: 'white'
            }
          ]
        }}
      />
    </Box>
  )
}

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { STORAGE } from '../storage'
import { ZeusInterceptor } from '../types'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS } from './sample-data'
import { SAMPLE_CAMPAIGNS } from '@momentum/api/interceptors/getCampaign/sample-data'

export const getCampaignReviewMetrics: ZeusInterceptor<'query', 'getCampaignReviewMetrics'> = async args => {
  const params = args.o.getCampaignReviewMetrics?.[0]
  const campaign = params?.campaignId
    ? SAMPLE_CAMPAIGNS[params.campaignId] || STORAGE.campaigns[params.campaignId]
    : undefined
  return (campaign?.skuId ? SAMPLE_CAMPAIGN_REVIEW_METRICS[campaign.skuId] || [] : undefined) as Array<
    ModelTypes['CampaignProductReviewRatingMetric']
  >
}

import { Stack } from '@mui/system'
import { Outlet } from 'react-router-dom'
import { TabBar } from './TabBar'

export const CampaignMyActions = () => {
  return (
    <Stack spacing={4}>
      <TabBar />
      <Outlet />
    </Stack>
  )
}

import { Font, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import { DateTime } from 'luxon'

const styles = StyleSheet.create({
  page: {
    padding: 45
  },
  title1: {
    fontFamily: 'Avenir',
    fontSize: '14px'
  },
  body: {
    fontFamily: 'Avenir',
    fontSize: '11px'
  },
  body2: {
    fontFamily: 'Avenir',
    fontSize: '11px',
    fontWeight: 'heavy'
  },

  section: {
    textAlign: 'center',
    marginBottom: 15,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  column: { flexDirection: 'column' },
  row: { width: '100%', display: 'flex', flexDirection: 'row' },

  cellHeader: {
    textAlign: 'center',
    alignContent: 'center',
    padding: 5
  },
  firstCell: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingVertical: 10,
    paddingRight: 5,
    width: '25%',
    borderTop: '1px solid black',
    borderRight: '1px solid black'
  },
  cell: {
    textAlign: 'left',
    paddingLeft: 5,
    width: '75%',
    paddingVertical: 10,
    paddingRight: 10,
    borderTop: '1px solid black'
  }
})

export const BuyCreditsOrderFormPdf = ({
  numCredits,
  contacts,
  companyName,
  creationDate,
  invoiceNumber,
  paymentDueDate
}: {
  invoiceNumber?: string
  numCredits: number
  companyName: string
  contacts: string[]
  creationDate: string
  paymentDueDate: string
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: '/fonts/Avenir/AvenirRegular.ttf'
      },
      {
        src: '/fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 'heavy'
      }
    ]
  })

  const contactsString =
    contacts.length > 2
      ? [contacts.slice(0, contacts.length - 1).join(', '), contacts.pop()].join(', and ')
      : contacts.join(' and ')

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`ProductWind Order Form for New Credits`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page style={styles.page}>
        <View style={[styles.section]}>
          <Text style={[styles.title1]}>Form 1 - ProductWind Order Form for New Credits</Text>
        </View>
        <View style={[styles.section]}>
          <Text style={[styles.body]}>
            This Order Form (the “<Text style={[styles.body2, { fontWeight: 'heavy' }]}>Order Form</Text>”) is entered
            into by and between ProductWind, Inc., a Delaware corporation (“
            <Text style={styles.body2}>ProductWind</Text>”) and {companyName} (“
            <Text style={styles.body2}>Client</Text>”), effective as of {creationDate} (the “
            <Text style={styles.body2}>Effective Date</Text>
            ”). This Order Form is subject to the Master Subscription Services Agreement (the “
            <Text style={styles.body2}>Agreement</Text>”), and is incorporated into, and made a part of, the Agreement
            by reference. Capitalized terms not defined in this Order Form are as defined in the Agreement.
          </Text>
        </View>
        <View style={[{ flexDirection: 'column', border: '1px solid black' }]}>
          <View style={[styles.cellHeader]}>
            <Text style={[styles.body2, { textDecoration: 'underline' }]}>ORDER FORM</Text>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Scope</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The Client will receive {numCredits.toLocaleString()} credits that can be redeemed on the ProductWind
                Momentum platform.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Amount</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The Client has agreed to pay {numCredits.toLocaleString()} USD for these {numCredits.toLocaleString()}{' '}
                credits.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Date</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>The total payment is due {paymentDueDate}.</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Details</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                All payments shall be made in United States dollars. All credit purchases are non-refundable.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Method</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>See invoice for accepted payment methods.</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Billing Contacts</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The corresponding invoice {invoiceNumber ? `#${invoiceNumber} has been` : 'will be'} sent to{' '}
                {contactsString}
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Date Credits Are Available</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The credits will be available for use in the Momentum platform when ProductWind receives the above
                payment. These credits will be available for use as long as the Client has an active Momentum
                subscription.
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

import { _mApi } from '@momentum/api'
import { ZeusInterceptor } from '../types'
import { SAMPLE_AGENCY } from '../getAgency/sample-data'

export const listAgencies: ZeusInterceptor<'query', 'listAgencies'> = async args => {
  const apiResult = (await _mApi(args.operation, args.graphqlOptions)(args.o, args.opts)) as any
  if (apiResult.listAgencies && !apiResult.listAgencies.nextToken) {
    apiResult.listAgencies.items = [...(apiResult.listAgencies.items ?? []), SAMPLE_AGENCY]
  }
  return apiResult.listAgencies
}

import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { listProposals, Proposal } from './queries'
import { ProposalCard } from '@momentum/components/proposal-card'
import { Stack } from '@mui/system'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search } from '@mui/icons-material'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'

export const Proposals = () => {
  const [search, setSearch] = useState('')
  const [proposals, setProposals] = useState<Proposal[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    listProposals().then(setProposals)
  }, [])

  const filteredProposals = proposals.filter(
    p =>
      !search ||
      p.product?.name.toLowerCase().includes(search.toLowerCase()) ||
      p.brand.name.toLowerCase().includes(search.toLowerCase())
  )
  const handleEdit = (brandId: string, proposalId: string, isFinancialSummaryView?: boolean) => {
    navigate(generatePath(ROUTES.BRAND_CREATE_PROPOSAL_EDIT, { brandId, id: proposalId }), {
      state: {
        isFinancialSummaryView
      }
    })
  }

  return (
    <Stack py={2}>
      <Typography variant={'h3'}>Proposals</Typography>
      <Stack direction={'row'} my={2}>
        <DebouncedTextField
          fullWidth
          placeholder={'Search Proposals'}
          sx={{ mb: 4 }}
          onChange={setSearch}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Stack>
      <Stack spacing={2}>
        {(filteredProposals || []).map(p => (
          <ProposalCard
            key={p.id}
            proposal={p}
            brandName={p.brand?.name || 'Brand'}
            onEditClick={() => handleEdit(p.brandId, p.id)}
            onViewClick={isFinancialSummaryView => handleEdit(p.brandId, p.id, isFinancialSummaryView)}
          />
        ))}
      </Stack>
    </Stack>
  )
}

import { Currency, Store } from '@productwindtom/shared-momentum-zeus-types'

export enum Retailer {
  AMAZON = 'Amazon',
  BEST_BUY = 'Best Buy',
  TARGET = 'Target',
  WALMART = 'Walmart',
  DTC = 'DTC',
  COSTCO = 'Costco',
  KROGER = 'Kroger',
  OTHER = 'Other'
}

export const STORE_TO_RETAILER: Record<Store, Retailer> = {
  [Store.amazon]: Retailer.AMAZON,
  [Store.bestBuy]: Retailer.BEST_BUY,
  [Store.target]: Retailer.TARGET,
  [Store.walmart]: Retailer.WALMART,
  [Store.costco]: Retailer.COSTCO,
  [Store.amazon_ca]: Retailer.AMAZON,
  [Store.amazon_uk]: Retailer.AMAZON,
  [Store.amazon_de]: Retailer.AMAZON,
  [Store.shopify]: Retailer.DTC,
  [Store.dtc]: Retailer.DTC,
  [Store.kroger]: Retailer.KROGER,
  [Store.other]: Retailer.OTHER
}


export const STORE_TO_CURRENCY: Record<Store, Currency> = {
  amazon: Currency.USD,
  amazon_ca: Currency.CAD,
  amazon_uk: Currency.GBP,
  amazon_de: Currency.EUR,
  bestBuy: Currency.USD,
  walmart: Currency.USD,
  target: Currency.USD,
  costco: Currency.USD,
  shopify: Currency.USD,
  dtc: Currency.USD,
  kroger: Currency.USD,
  other: Currency.USD
}
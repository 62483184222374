import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_REVIEWS_WIRELESS_EARBUDS: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2024-05-27',
    toDate: '2024-05-28',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-28',
    toDate: '2024-05-29',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-29',
    toDate: '2024-05-30',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-30',
    toDate: '2024-05-31',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-31',
    toDate: '2024-06-01',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-01',
    toDate: '2024-06-02',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-02',
    toDate: '2024-06-03',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-03',
    toDate: '2024-06-04',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-04',
    toDate: '2024-06-05',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-05',
    toDate: '2024-06-06',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-06',
    toDate: '2024-06-07',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-07',
    toDate: '2024-06-08',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-08',
    toDate: '2024-06-09',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-09',
    toDate: '2024-06-10',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-10',
    toDate: '2024-06-11',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-11',
    toDate: '2024-06-12',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-12',
    toDate: '2024-06-13',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-13',
    toDate: '2024-06-14',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-14',
    toDate: '2024-06-15',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-15',
    toDate: '2024-06-16',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-16',
    toDate: '2024-06-17',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-17',
    toDate: '2024-06-18',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-18',
    toDate: '2024-06-19',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-19',
    toDate: '2024-06-20',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-20',
    toDate: '2024-06-21',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Great!',
        text: 'I love the way they fit and how comfortable they are! The customization through the app is also a feature I really enjoyed and overall adds to the high quality and the experience.',
        name: 'sara',
        rating: 4,
        attributedUserId: '13bd5bfb-152c-4709-bc6e-619c76a9af17',
        reviewedAt: '2024-06-19T00:00:00.000Z'
      },
      {
        title: 'Excellent audio quality',
        text: 'I totally love the audio quality, great noice canceling feature and easy app to change settings. Totally recommend!',
        name: 'Mikynate',
        rating: 5,
        attributedUserId: '8294f1e7-f2fa-4921-9a8b-736791286f87',
        reviewedAt: '2024-06-21T00:00:00.000Z'
      }
    ],
    rating: 4,
    momentumRating: 4.5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-21',
    toDate: '2024-06-22',
    numRatings: 5,
    numReviews: 4,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Love them',
        text: 'I love how loud these earbuds is. I love the noise cancellation. It’s really comfortable in my ears, I don’t have to worry about my ear aching. Also they are so comfortable I forgot they were in my ears. The appearance is nice and sleek. It’s very easy to use. And the connectivity of it is very simple. And it’s great quality.',
        name: 'Ronnitra',
        rating: 5,
        attributedUserId: 'd51411e0-3772-49eb-9708-aa535a08f002',
        reviewedAt: '2024-06-22T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-06-22',
    toDate: '2024-06-23',
    numRatings: 9,
    numReviews: 8,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-06-23',
    toDate: '2024-06-24',
    numRatings: 11,
    numReviews: 10,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Great quality!',
        text: 'Amazing quality & noise canceling!',
        name: 'Hilary Griffin',
        rating: 5,
        attributedUserId: '35e9e43c-4536-4e0c-8913-1cb7ec1d72fe',
        reviewedAt: '2024-06-24T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-06-24',
    toDate: '2024-06-25',
    numRatings: 12,
    numReviews: 12,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love these earphones',
        text: 'Super easy to set up. The app was very intuitive. Love the custom fit and sleek design.',
        name: 'Casey Cohen',
        rating: 5,
        attributedUserId: '554ecf4a-b27e-4d23-a1e8-b9d7c3769e8d',
        reviewedAt: '2024-06-25T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-25',
    toDate: '2024-06-26',
    numRatings: 20,
    numReviews: 19,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 2,
    momentumReviewCount: 6,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 7,
    reviews: [
      {
        title: 'High quality!',
        text: 'I can’t say enough good things about these headphones. Super comfortable, great performance and appearance.. and overall a great value for your money.',
        name: 'Nicole Roggow',
        rating: 5,
        attributedUserId: 'd569077b-a418-40c5-b681-a2bd64524f95',
        reviewedAt: '2024-06-25T00:00:00.000Z'
      },
      {
        title: 'Good sending headphones that are great for call quality',
        text: 'These headphones were a little over the price point. I wanted to pay, but I’m glad that I did. The call quality is exceptional along with the active noise cancellation. I was talking on some business calls this afternoon and they said that they were crystal clear even when I was outside by our road. This is good to know, especially since I would rather walk around when I take my calls, then sit inside. As far as music goes, it is pretty solid, but I do think the overall fullness of the sound could be improved.',
        name: 'Carson Nicely',
        rating: 5,
        attributedUserId: '1630a4b9-6373-40e6-81f1-73420c1786d6',
        reviewedAt: '2024-06-26T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-06-26',
    toDate: '2024-06-27',
    numRatings: 24,
    numReviews: 24,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-06-27',
    toDate: '2024-06-28',
    numRatings: 28,
    numReviews: 28,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Great option!',
        text: 'If you need ear buds these are fantastic for the price. Compact and high quality',
        name: 'Taylor Jordn',
        rating: 5,
        attributedUserId: 'abb07528-0fbd-40ad-94a6-67ab6ae94847',
        reviewedAt: '2024-06-28T00:00:00.000Z'
      },
      {
        title: 'Great headphones!',
        text: 'These headphones were great and fits well and my ears',
        name: 'Kelly Trudersheim',
        rating: 5,
        attributedUserId: '6b5383b5-75a3-4dc1-a4fa-442976874f29',
        reviewedAt: '2024-06-28T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-06-28',
    toDate: '2024-06-29',
    numRatings: 33,
    numReviews: 33,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.840000000000001,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Amazing Quality!',
        text: 'These earbuds are easy to use and incredibly comfortable. The sound quality is indeed amazing, I use them during my meetings and I could hear my coworkers loud and clear. The best part is the adaptive noise canceling because my household can get pretty loud so the ANC comes in really handy. I will classify these earbuds, possibly strictly only for meetings and occasional use to listen to music because they are not made for activewear. Which means more than likely if you sweat on them they’re going to get ruined. Other than that, these earbuds are up there in the Premium quality ones.',
        name: 'Christian Gonzalez',
        rating: 5,
        attributedUserId: '6319ed53-d12f-49e4-b68c-3eb6f16b7309',
        reviewedAt: '2024-06-29T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.840000000000001,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-29',
    toDate: '2024-06-30',
    numRatings: 36,
    numReviews: 36,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.4,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Great sound and design.',
        text: 'Love the style of these buds and the sound as well. Sturdy craftsmanship and quality design.',
        name: 'Billy Baggins',
        rating: 4,
        attributedUserId: '4c3dd077-4f96-4810-ab10-f20e4fb02196',
        reviewedAt: '2024-06-30T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.4,
    organicRating: 0,
    vineRating: 3
  },
  {
    fromDate: '2024-06-30',
    toDate: '2024-07-01',
    numRatings: 38,
    numReviews: 38,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-01',
    toDate: '2024-07-02',
    numRatings: 40,
    numReviews: 41,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-02',
    toDate: '2024-07-03',
    numRatings: 42,
    numReviews: 41,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-03',
    toDate: '2024-07-04',
    numRatings: 42,
    numReviews: 41,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-04',
    toDate: '2024-07-05',
    numRatings: 44,
    numReviews: 43,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'They work amazingly well',
        text: 'The best on the market',
        name: 'Daniel Jarvie',
        rating: 5,
        attributedUserId: '11ab4740-7f4e-4527-8a95-c28a4e297697',
        reviewedAt: '2024-07-05T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-07-05',
    toDate: '2024-07-06',
    numRatings: 48,
    numReviews: 47,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Wow',
        text: 'Absolutely fantastic. Highly recommend!!!!',
        name: 'Josiah Capaci',
        rating: 5,
        attributedUserId: 'a7f64168-11db-469e-9f1a-0c1bd3112ba0',
        reviewedAt: '2024-07-05T00:00:00.000Z'
      },
      {
        title: 'Super lightweight & good audio!',
        text: 'These are perfect for the gym and walks! Love how lightweight',
        name: 'Sammy Fumagalli',
        rating: 5,
        attributedUserId: 'caef0512-3f5b-4e2f-b56e-5470d1a01af4',
        reviewedAt: '2024-07-06T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-06',
    toDate: '2024-07-07',
    numRatings: 50,
    numReviews: 49,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Long lasting!',
        text: 'Love theses headphones! They last sooo long without having to recharge. I have been using them more at the gym and they stay in place and block out the sounds there so that is a huge plus for me! Would recommend.',
        name: 'Cozy Clicks',
        rating: 5,
        attributedUserId: '34955116-7653-4064-acff-38a26d2bb81c',
        reviewedAt: '2024-07-07T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-07',
    toDate: '2024-07-08',
    numRatings: 53,
    numReviews: 52,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Great headphones',
        text: 'I love these headphones they are super comfortable and lasted all day. Plus they look fashionable',
        name: 'Jessmmmcg',
        rating: 5,
        attributedUserId: 'a69589d4-caa6-46b0-abef-fe0600c88dbc',
        reviewedAt: '2024-07-08T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-08',
    toDate: '2024-07-09',
    numRatings: 55,
    numReviews: 53,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-07-09',
    toDate: '2024-07-10',
    numRatings: 56,
    numReviews: 53,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-10',
    toDate: '2024-07-11',
    numRatings: 57,
    numReviews: 54,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-11',
    toDate: '2024-07-12',
    numRatings: 59,
    numReviews: 56,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Work very well and perfect fit!',
        text: 'These earbuds are so nice. I can’t get over the level of high quality… sound and fit are excellent!',
        name: 'Brittany',
        rating: 5,
        attributedUserId: '687309d9-cb89-414b-8eac-75967eabb762',
        reviewedAt: '2024-07-12T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-12',
    toDate: '2024-07-13',
    numRatings: 59,
    numReviews: 56,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-13',
    toDate: '2024-07-14',
    numRatings: 61,
    numReviews: 59,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'These are really nice',
        text: 'These are amazing for the price and I love that the noise cancellation is perfect for working. The color is really pretty with the rose gold and I definitely recommend.',
        name: 'California Belles',
        rating: 5,
        attributedUserId: '1f05f884-773b-48d5-b766-08bb06a42be3',
        reviewedAt: '2024-07-14T00:00:00.000Z'
      },
      {
        title: 'Great sound',
        text: 'Looks nice and has good sound. Much nicer than others I’ve tried and like that it has many ear pieces to get the right fit.',
        name: 'Vonnienono',
        rating: 4,
        attributedUserId: 'd6647fb1-2908-4e39-9cf8-4f96e61b9590',
        reviewedAt: '2024-07-14T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-14',
    toDate: '2024-07-15',
    numRatings: 62,
    numReviews: 61,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-07-15',
    toDate: '2024-07-16',
    numRatings: 65,
    numReviews: 64,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'LITERALLY MY FAVORITE',
        text: 'They were so easy to connect to my phone. You do need to download an app but it’s nothing complicated. I love how portable they are and the sound is amazing!! I put them on when I’m cleaning and I can’t hear a thing. They have noise cancellation and it’s definitely worth tour money!',
        name: 'Denise Ferreira',
        rating: 5,
        attributedUserId: '64a80d6a-9f09-4c5d-ab07-9ddb51765fb2',
        reviewedAt: '2024-07-15T00:00:00.000Z'
      },
      {
        title: 'THE BEST!',
        text: 'I love how good these earphone work. They don’t make my ears feel sore after using them for hours. They are also worth the price, I have no complains at all.',
        name: 'Julia Arreola',
        rating: 5,
        attributedUserId: '10dd1d26-2f9b-4885-a467-dd629dfcac86',
        reviewedAt: '2024-07-15T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.933333333333334,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-16',
    toDate: '2024-07-17',
    numRatings: 67,
    numReviews: 66,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Very pleased - crisp sound!',
        text: 'These earbuds are great. Very crisp sound and great at muting background noise. Perfect fit thanks to the 4 ear tip sizes that they come with.',
        name: 'Amelia',
        rating: 5,
        attributedUserId: '692f5a0e-ad8b-44cf-a218-d9ef5abe55b4',
        reviewedAt: '2024-07-17T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-07-17',
    toDate: '2024-07-18',
    numRatings: 68,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Amazing',
        text: 'This product is seriously one of my favorite ear buds and super comfortable in my ear',
        name: 'Kassidy Rae Hakemack',
        rating: 5,
        attributedUserId: '89e9c045-b8c6-4243-be7c-b989acc67f3b',
        reviewedAt: '2024-07-18T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-18',
    toDate: '2024-07-19',
    numRatings: 70,
    numReviews: 69,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Great earbuds',
        text: 'Perfect sounds and fit! Can’t recommend it enough! It comes with four sizes for the ear tips!',
        name: 'Dominiquea Nogueira',
        rating: 5,
        attributedUserId: '0c00de83-654a-4590-aef5-46fe6bf74e61',
        reviewedAt: '2024-07-18T00:00:00.000Z'
      },
      {
        title: 'The next big thing!',
        text: "What I personally love about this product is that they seamlessly fit into my ears without irritation or struggle, and are perfect for when I'm attending a business meeting remotely being that they have noise canceling mics as well as high powered speakers. All together, the features of this product make my daily life easier, being they are also very portable and easy to travel with.",
        name: 'Hassan Chammout',
        rating: 5,
        attributedUserId: 'f4ff3237-ff2a-4288-b3fd-0d4c63431065',
        reviewedAt: '2024-07-19T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-19',
    toDate: '2024-07-20',
    numRatings: 73,
    numReviews: 69,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-07-20',
    toDate: '2024-07-21',
    numRatings: 74,
    numReviews: 73,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great',
        text: 'Wear them to work in the yard. Perfect and don’t fall out or change noise when I sweat.',
        name: 'worldlyjen',
        rating: 5,
        attributedUserId: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        reviewedAt: '2024-07-20T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-21',
    toDate: '2024-07-22',
    numRatings: 76,
    numReviews: 74,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love these',
        text: 'Lightweight, sleek design, great sound, stylish',
        name: 'JDM',
        rating: 5,
        attributedUserId: 'be10fc95-bc66-4217-ba73-6d25bfa62547',
        reviewedAt: '2024-07-20T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.6,
    vineRating: 0
  },
  {
    fromDate: '2024-07-22',
    toDate: '2024-07-23',
    numRatings: 77,
    numReviews: 75,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-07-23',
    toDate: '2024-07-24',
    numRatings: 80,
    numReviews: 78,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Perfect for work from home',
        text: 'Love these for working from home. Cut out background noise really well and the charge lasts all day.',
        name: 'Katie Ann Ensminger',
        rating: 5,
        attributedUserId: '98264f43-3f54-461d-b453-5524d774920e',
        reviewedAt: '2024-07-23T00:00:00.000Z'
      },
      {
        title: "Best earbuds I've owned.",
        text: 'They stay put and snug in your ear. Comes with multiple ear pieces for custom fit. Connection to the phone was simple and fast. Nice and small charging case. Overall a great pair of earbuds!👍🏼',
        name: 'Tanya',
        rating: 5,
        attributedUserId: '650152a8-c0c9-49d1-886c-1d77dd9f1f5d',
        reviewedAt: '2024-07-24T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.933333333333334,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-24',
    toDate: '2024-07-25',
    numRatings: 81,
    numReviews: 79,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Worth every penny',
        text: "The Poly – Voyager Free 20 offers exceptional sound quality with crisp highs and rich lows, effective noise-canceling, and a comfortable, ergonomic design. It's perfect for calls and music, providing clear, immersive audio and a snug fit for extended wear. Highly recommended for anyone seeking top-notch audio performance and comfort.",
        name: 'Aladria',
        rating: 5,
        attributedUserId: '12c87ba7-068d-43a5-80d4-14a40a6be253',
        reviewedAt: '2024-07-25T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-25',
    toDate: '2024-07-26',
    numRatings: 82,
    numReviews: 80,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love Them',
        text: 'I love them. They are more comfortable than my other ear buds and love that you can set them up to your individual preferences.',
        name: 'Dani',
        rating: 5,
        attributedUserId: 'f633e4cc-58da-4951-a560-bae240363e54',
        reviewedAt: '2024-07-26T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-26',
    toDate: '2024-07-27',
    numRatings: 83,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great earbuds for a great price',
        text: 'I love these, they fit in my ear more comfortable than any other ones and the sound is so clear.',
        name: 'Ashley Bourne',
        rating: 5,
        attributedUserId: '29a3a9ed-4e8e-4e1e-aede-165610fc422f',
        reviewedAt: '2024-07-27T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-27',
    toDate: '2024-07-28',
    numRatings: 84,
    numReviews: 83,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-28',
    toDate: '2024-07-29',
    numRatings: 86,
    numReviews: 83,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-29',
    toDate: '2024-07-30',
    numRatings: 87,
    numReviews: 84,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love these wireless ear buds',
        text: 'I really love my new Poly Voyager Free 20 ear buds. I love that they are wireless, so great. They come in a great sized box so it’s easy to bring them with you anywhere. The sound is superb! I love having conversations or listening to music or really anything with them. So easy to use and great product for the price.',
        name: 'The Blonde and Co',
        rating: 5,
        attributedUserId: '68ae23d2-b9ba-4e27-a5c1-f626e20ee85a',
        reviewedAt: '2024-07-30T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-30',
    toDate: '2024-07-31',
    numRatings: 89,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-31',
    toDate: '2024-08-01',
    numRatings: 89,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-01',
    toDate: '2024-08-02',
    numRatings: 89,
    numReviews: 86,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-02',
    toDate: '2024-08-03',
    numRatings: 91,
    numReviews: 90,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-03',
    toDate: '2024-08-04',
    numRatings: 92,
    numReviews: 91,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-08-04',
    toDate: '2024-08-05',
    numRatings: 95,
    numReviews: 92,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great sound!',
        text: 'Worth the purchase. Great sound.',
        name: 'Cinthia Rivera',
        rating: 5,
        attributedUserId: 'd5993eea-19ad-484b-85be-712ef078c3d2',
        reviewedAt: '2024-08-30T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.699999999999999,
    vineRating: 0
  },
  {
    fromDate: '2024-08-05',
    toDate: '2024-08-06',
    numRatings: 96,
    numReviews: 92,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-06',
    toDate: '2024-08-07',
    numRatings: 97,
    numReviews: 93,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love these!',
        text: 'Best non-Apple earbuds I own. Battery life is excellent.',
        name: 'Kaila DeRienzo',
        rating: 5,
        attributedUserId: 'e7a3d55d-a712-40d5-9760-0fe78bda9098',
        reviewedAt: '2024-08-23T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-07',
    toDate: '2024-08-08',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'The best sound quality',
        text: 'These headphones fit in my ear so well and it sounded like I was inside the music',
        name: 'Porshe Minor',
        rating: 5,
        attributedUserId: 'a64ffceb-9fcd-4935-9612-3ab683d23b88',
        reviewedAt: '2024-08-17T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-08',
    toDate: '2024-08-09',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-09',
    toDate: '2024-08-10',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-10',
    toDate: '2024-08-11',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-11',
    toDate: '2024-08-12',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-12',
    toDate: '2024-08-13',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-13',
    toDate: '2024-08-14',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-14',
    toDate: '2024-08-15',
    numRatings: 98,
    numReviews: 94,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-15',
    toDate: '2024-08-16',
    numRatings: 99,
    numReviews: 94,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-16',
    toDate: '2024-08-17',
    numRatings: 100,
    numReviews: 95,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-08-17',
    toDate: '2024-08-18',
    numRatings: 101,
    numReviews: 0,
    ratingsInPeriod: 96,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: -95,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-18',
    toDate: '2024-08-19',
    numRatings: 102,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-19',
    toDate: '2024-08-22',
    numRatings: 102,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-22',
    toDate: '2024-08-23',
    numRatings: 102,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-23',
    toDate: '2024-08-24',
    numRatings: 102,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-24',
    toDate: '2024-08-25',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-25',
    toDate: '2024-08-26',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-26',
    toDate: '2024-08-27',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-27',
    toDate: '2024-08-28',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-28',
    toDate: '2024-08-29',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-29',
    toDate: '2024-08-30',
    numRatings: 103,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-08-30',
    toDate: '2024-08-31',
    numRatings: 104,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-08-31',
    toDate: '2024-09-01',
    numRatings: 105,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-09-01',
    toDate: '2024-09-02',
    numRatings: 106,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-09-02',
    toDate: '2024-09-03',
    numRatings: 106,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-03',
    toDate: '2024-09-04',
    numRatings: 106,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-04',
    toDate: '2024-09-05',
    numRatings: 108,
    numReviews: 0,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-09-05',
    toDate: '2024-09-06',
    numRatings: 108,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-06',
    toDate: '2024-09-07',
    numRatings: 108,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-07',
    toDate: '2024-09-08',
    numRatings: 108,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-08',
    toDate: '2024-09-09',
    numRatings: 108,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-09',
    toDate: '2024-09-10',
    numRatings: 109,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-09-10',
    toDate: '2024-09-11',
    numRatings: 109,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-11',
    toDate: '2024-09-12',
    numRatings: 109,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-12',
    toDate: '2024-09-13',
    numRatings: 109,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-13',
    toDate: '2024-09-14',
    numRatings: 110,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-09-14',
    toDate: '2024-09-15',
    numRatings: 110,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-15',
    toDate: '2024-09-16',
    numRatings: 110,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-16',
    toDate: '2024-09-17',
    numRatings: 110,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-17',
    toDate: '2024-09-18',
    numRatings: 110,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-18',
    toDate: '2024-09-19',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-09-19',
    toDate: '2024-09-20',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-20',
    toDate: '2024-09-21',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-21',
    toDate: '2024-09-22',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-22',
    toDate: '2024-09-23',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-23',
    toDate: '2024-09-24',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-24',
    toDate: '2024-09-25',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-25',
    toDate: '2024-09-26',
    numRatings: 111,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-26',
    toDate: '2024-09-27',
    numRatings: 112,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-09-27',
    toDate: '2024-09-28',
    numRatings: 112,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-28',
    toDate: '2024-09-29',
    numRatings: 112,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-29',
    toDate: '2024-09-30',
    numRatings: 112,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-30',
    toDate: '2024-10-01',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-10-01',
    toDate: '2024-10-02',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-10-02',
    toDate: '2024-10-03',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-10-03',
    toDate: '2024-10-04',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-10-04',
    toDate: '2024-10-05',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-10-05',
    toDate: '2024-10-06',
    numRatings: 113,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-10-06',
    toDate: '2024-10-07',
    numRatings: 113,
    numReviews: 105,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  }
]

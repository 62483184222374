import { SvgIcon, SvgIconProps } from '@mui/material'

export default function KrogerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 1254.03 698.6" fill="none">
      <path
        fill="#134b97"
        d="M672.54,482.31V449.53H613v201H674V539.14q15.68-34.91,52.91-34.92a104.61,104.61,0,0,1,21.56,2.14V448.64q-7.85-2.85-19.07-2.85Q698.37,445.79,672.54,482.31Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M943.58,624q29.49-30,29.49-74.2,0-43.83-29.49-73.94t-73.23-30.11q-43.74,0-73.13,30.25t-29.4,73.8q0,43.55,29.4,73.88t73.13,30.34Q914.09,654.06,943.58,624ZM838.11,584.52q-12.56-14.14-12.56-34.68,0-20.19,12.56-34.41t32.25-14.23q19.68,0,32.34,14.23t12.65,34.41q0,20.37-12.65,34.59t-32.34,14.22Q850.67,598.65,838.11,584.52Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M1328.65,445.79q-42.4,0-71.8,29.58t-29.4,74.47q0,46,28.15,75.09t73.76,29.13q30.64,0,52.38-11.44A144,144,0,0,0,1421.65,611l-44.18-24.71q-17.1,15.6-44.54,15.6-39.37,0-46.32-34.92h138.26a45.35,45.35,0,0,1,.36-4.9q.35-3.11.53-5.7c.12-1.72.18-3.17.18-4.36q0-44.18-27.26-75.18T1328.65,445.79Zm-41.33,85q4.45-16.73,15.14-25.82a37.31,37.31,0,0,1,24.94-9.09q13.54,0,24.5,10.55t13.1,24.37Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M1568,445.79q-31,0-56.83,36.53V449.53h-59.51v201h60.93V539.14q15.68-34.91,52.91-34.92a104.59,104.59,0,0,1,21.56,2.14V448.64Q1579.17,445.79,1568,445.79Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M554.3,411.61a199.64,199.64,0,0,0,1.62-20.84H556c0-78.63,64-142.6,142.6-142.6s142.6,64,142.6,142.6h57.47c0-110.32-89.75-200.07-200.07-200.07-109.17,0-198.14,87.9-200,196.64h-.1a135.6,135.6,0,0,1-1.19,16.93c-2.63,20.42-10.25,49.86-31.61,74.13-16.71,19-39.57,31.86-68.21,38.56V383.43H333V650.5h64.5V575.67a214,214,0,0,0,54.79-17l58.16,91.82h77.15L502.49,523.15c2.16-2.21,4.29-4.46,6.35-6.79C539.79,481.18,550.64,440,554.3,411.61Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M1201.71,709.27V449.53h-58.08v17.11q-20.49-20.85-51.84-20.85-38.67,0-67,29.08T996.46,547q0,42.83,29,71.4t66.28,28.56q30.28-.18,52.56-16.68c0,.4,0,60.57,0,79h0a122.57,122.57,0,0,1-245.13,0H841.71c0,99.27,80.76,180,180,180,97.48,0,177.06-77.89,179.9-174.7h.07v-2.74c0-.87.07-1.72.07-2.59Zm-70.1-128.2q-12.38,13.66-32.52,13.66-19.78,0-32.34-13.66t-12.56-34q0-20.32,12.56-34t32.34-13.67q19.95,0,32.43,13.67t12.47,34Q1144,567.4,1131.6,581.07Z"
        transform="translate(-332.98 -190.7)"
      />
      <path
        fill="#134b97"
        d="M1246.59,802.89a22.08,22.08,0,0,1-44.15,0c0-11.92,9.83-21.49,22.14-21.49S1246.59,791,1246.59,802.89Zm-38.65,0c0,9.56,7.08,17.16,16.77,17.16,9.43,0,16.38-7.6,16.38-17s-6.94-17.29-16.51-17.29S1207.95,793.46,1207.95,802.89Zm13.23,11.27h-5V792.67a43.66,43.66,0,0,1,8.25-.65c4.06,0,5.9.65,7.47,1.57a6,6,0,0,1,2.1,4.72c0,2.36-1.83,4.19-4.45,5v.26c2.09.79,3.27,2.36,3.93,5.24.66,3.27,1,4.58,1.57,5.37h-5.37c-.66-.79-1-2.75-1.7-5.24-.39-2.36-1.7-3.41-4.46-3.41h-2.36Zm.13-12.18h2.36c2.75,0,5-.92,5-3.14,0-2-1.44-3.28-4.58-3.28a11.83,11.83,0,0,0-2.75.26Z"
        transform="translate(-332.98 -190.7)"
      />
    </SvgIcon>
  )
}

import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { ChildFormProps, FormProviderProps } from './types'

export const withFormProvider =
  (Component: React.FC<ChildFormProps>, providerDefaultValues?: any) =>
  <TFieldValues extends FieldValues>({
    onSubmit,
    defaultValues,
    resolver,
    ...rest
  }: FormProviderProps<TFieldValues>) => {
    const methods = useForm({
      mode: 'onChange',
      resolver,
      defaultValues: providerDefaultValues || defaultValues
    })

    const handleSubmit = onSubmit && methods.handleSubmit(data => onSubmit(data, methods))

    return (
      <FormProvider {...methods}>
        <Component onSubmit={handleSubmit} {...rest} />
      </FormProvider>
    )
  }

//@ts-nocheck
import { ModelTypes, Store } from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_CAMPAIGN_LAUNDRY: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-laundry',
  brandId: BRANDS.CPG,
  skuId: 'sample-sku-laundry',
  title: 'Laundry Detergent',
  numCreators: 500,
  numCreatorsJoined: 500,
  numAdditionalBrandAdvocateCreatorsJoined: 375,
  numAdditionalBrandAdvocateCreators: 0,
  numBrandAdvocateCreators: 375,
  numSocialCreators: 15,
  numPremiumUgcCreators: 0,
  numUgcCreators: 100,
  expectedSocialPostCount: 15,
  expectedReviewsCount: 50,
  expectedClicksCounts: 8250,
  releaseSchedule: {
    socialWeeklySchedule: [15, 0, 0, 0, 0, 0],
    premiumUgcWeeklySchedule: [0, 0, 0, 0, 0, 0],
    ugcWeeklySchedule: [100, 0, 0, 0, 0, 0],
    brandAdvocatesWeeklySchedule: [198, 212, 157, 38, 164, 14]
  },
  createdAt: '2023-07-20T16:44:19.000Z',
  startDate: '2023-08-01T16:44:19.000Z',
  endDate: '2023-09-20T17:28:38.448Z',
  cost: 5900000,
  product: {
    id: 'sample-sku-laundry',
    brandId: BRANDS.CE,
    name: 'Laundry Detergent',
    skuId: 'B0C8RD2XHD',
    price: '$14.98',
    priceCents: 1498,
    image: 'https://media.productwind.com/sample/images/laundry.png',
    listingLink: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
    store: Store.amazon,
    ratingSnapshots: {
      items: []
    },
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z',
    recommendations: []
  },
  creators: [
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T21:05:28.000Z',
        amountCents: 1499
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T20:05:18.119Z',
          createdAt: '2023-08-17T21:00:15.138Z',
          content: [
            {
              id: 'b8c28f60-f25f-44cc-823d-2ee8892166c8',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f60a8232-b7b1-471d-9327-3701ba1a29c0/ugc/47b3ce0c-9cc1-41b0-8401-e24b9e53e441/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '9256a995-fe28-45c0-a654-ee863da26491',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2be5b163-c241-4ebd-82fb-68ebbef1ab44/ugc/68404da7-5dfc-4d44-89fa-3d4d9d8f90dd/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: 'b52cb4d1-07be-49fa-828e-4e4d36f5bf51',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2be5b163-c241-4ebd-82fb-68ebbef1ab44/ugc/68404da7-5dfc-4d44-89fa-3d4d9d8f90dd/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '2433d4c3-d487-44a7-8081-8fa48925b743'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T18:24:32.397Z',
          createdAt: '2023-08-19T19:11:11.963Z',
          content: [
            {
              id: '01249f0a-3037-4456-a9ad-ae8bda446db4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b39e2358-dd05-4352-be08-433170d5727e/ugc/0ea0ddef-1336-4b81-932d-455a8b230203/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0ea0ddef-1336-4b81-932d-455a8b230203'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b39e2358-dd05-4352-be08-433170d5727e',
        firstName: 'Jesika',
        lastName: 'Liston',
        email: 'successwithjes@gmail.com',
        instagramProfile: {
          handle: 'jesikalistoncoaching',
          followers: 3540,
          engagementRate: 0.22598870056497175,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jesikaliston3061',
          followers: 1
        }
      },
      userCampaignId: 'b39e2358-dd05-4352-be08-433170d5727e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T16:13:10.000Z',
        amountCents: 1593
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T00:00:00.000Z',
          createdAt: '2023-08-11T19:06:12.759Z',
          content: [
            {
              id: 'd28ee9d7-6a74-42e1-9202-b32c397e63fb',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d379d28-5b0c-4a77-88d9-62fdea56566e/ugc/40e195dd-22da-4fba-b740-24563b065a28/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '216870b8-7e8c-4093-ac3f-3c3e02b04ff8'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T00:00:00.000Z',
          createdAt: '2023-08-09T22:15:02.631Z',
          content: [
            {
              id: '63e16e1b-3bff-4cd1-9375-00375b909288',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17890649510880764/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6663e5df-c77c-4b98-88a5-30d860f6a313'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4a648b7a-0aab-47e5-9b4e-0ca554587cbc',
        firstName: 'Stefany',
        lastName: 'Nogueira',
        email: 'stefany_97soares@outlook.com',
        instagramProfile: {
          handle: 'stefany.brunow',
          followers: 10177,
          engagementRate: 1.5908420949199173,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'stefanybrunow',
          followers: 689,
          engagementRate: 2.8469750889679712,
          isPrimary: true
        }
      },
      userCampaignId: '4a648b7a-0aab-47e5-9b4e-0ca554587cbc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T01:11:08.000Z',
        amountCents: 1632
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-03T21:02:32.055Z',
          createdAt: '2023-08-25T17:52:58.424Z',
          content: [
            {
              id: 'b5b03cf2-9ec5-40b8-b667-2cf0004b0762',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0921bf98-0e80-4f6d-9692-951694bed505/ugc/e205ebdd-aa51-450d-8c0b-539959315678/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '84eb2f89-c296-4fea-8476-afa1f16474a6',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6c0b75b-a66a-4450-ac4a-474388ead948/ugc/3c7c30ee-ecce-4e3e-bb9a-a5e63a78da0f/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '5280bf99-ea40-4ba7-aecb-121c21182f5d',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a147dc3-4578-4887-b8bf-a223ab0467f9/ugc/b7ad56a8-3214-4c16-80e7-0bc0aeba906f/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '8f5025da-a7de-4e54-9415-c1d3425d33d9',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67bfe02e-d6c5-4bcb-a266-d677dd98ee14/ugc/5b2f6244-2c68-4f39-a101-fc8b3cda6466/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '4cbf98bd-45e9-4f7b-8b81-49d1036c5ac0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/41fb37dc-8524-4fd6-978e-bcde66968213/ugc/273ec139-2076-47c0-a039-8cc3ef91ba84/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '273ec139-2076-47c0-a039-8cc3ef91ba84'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-03T21:02:26.926Z',
          createdAt: '2023-08-18T22:32:40.117Z',
          content: [
            {
              id: '22afcb75-8f5a-4986-8c17-eaf51ad150ae',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f60a8232-b7b1-471d-9327-3701ba1a29c0/ugc/970dee78-5fb6-43ef-b8e2-e22f039ed0a6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'ea7cf7af-1460-4815-98d2-d43fb44e6497'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '41fb37dc-8524-4fd6-978e-bcde66968213',
        firstName: 'shelby',
        lastName: 'hall',
        email: 'shelbyhall2016@gmail.com',
        instagramProfile: {
          handle: 'shellzzii',
          followers: 4082,
          engagementRate: 0.32337089661930424,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'stargirl2themax',
          followers: 1673,
          engagementRate: 1.8633540372670807,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'shellzzii',
          followers: 5
        }
      },
      userCampaignId: '41fb37dc-8524-4fd6-978e-bcde66968213'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-18T01:14:43.000Z',
        amountCents: 2380
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T22:13:39.574Z',
          createdAt: '2023-08-20T19:21:14.430Z',
          content: [
            {
              id: 'fc477323-a680-4927-ac49-9ed9df5fe8ee',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38a3bdf5-f61c-4d2d-952c-159d703bac4d/ugc/a1e9bd6e-8c0f-4694-93ea-380e9eb0ed31/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'e710177b-a616-43e5-b40e-2f7c38183247',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8fbad86f-a8d1-40b8-a28a-11607eb276dd/ugc/94583d50-e9f6-47b1-88e5-05c6dd6f7a97/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'bf3a5e59-99a1-4640-880d-faf20a08d4da',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8fbad86f-a8d1-40b8-a28a-11607eb276dd/ugc/89fe28a9-0a7e-44ef-8139-a9579315dabe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '1dbccec6-90fc-4b16-bbdd-42eb4508f77a'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T18:26:15.327Z',
          createdAt: '2023-08-20T19:27:53.640Z',
          content: [
            {
              id: 'a57505b2-a6bb-4756-b2eb-4b6cceea0722',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38a3bdf5-f61c-4d2d-952c-159d703bac4d/ugc/c2ea66ad-93f2-433b-97b2-6692d550e8f3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '0eb65941-634b-4997-befe-ff0018adebbd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e24934a6-a790-49cf-bda8-6c2a1680344c',
        firstName: 'Sarah',
        lastName: 'Munir',
        email: 'sarah.munir.k@gmail.com',
        instagramProfile: {
          handle: 'thesarahmunir',
          followers: 2065,
          engagementRate: 2.0145278450363198,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sarahmunir',
          followers: 45,
          isPrimary: false
        }
      },
      userCampaignId: 'e24934a6-a790-49cf-bda8-6c2a1680344c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T21:22:43.000Z',
        amountCents: 1635
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T13:21:08.668Z',
          createdAt: '2023-08-24T03:33:40.984Z',
          content: [
            {
              id: 'af028493-aec8-47ff-8d0f-5591f1498518',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735f9720-5df0-4e6d-8f08-c54d9517f52f/ugc/78e7de3b-94ee-475d-a4d1-eb59b4871b27/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '0eb1aaa8-1a06-4c81-8917-61d845ef8b4c',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735f9720-5df0-4e6d-8f08-c54d9517f52f/ugc/7f7059b1-7e6f-40b7-9321-9ad710cb7af8/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '0a6c6400-13db-4d7b-805d-572241ed73dc',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c207199b-57e6-4de6-81d7-1a6f0360ded8/ugc/2d3890bc-3e9c-4e38-bd7f-6b775c1317fe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'ded6a5c8-445b-42d2-a99e-d95eaad9d0b8'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T01:21:58.599Z',
          createdAt: '2023-08-24T00:06:58.453Z',
          content: [
            {
              id: '161309ac-5e3a-4b6f-8d74-a49acc87fb8a',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f60a8232-b7b1-471d-9327-3701ba1a29c0/ugc/47b3ce0c-9cc1-41b0-8401-e24b9e53e441/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '374c3585-d624-45b6-b376-64b968473d9d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'be28c475-4210-43c6-81f0-450ed019e424',
        firstName: 'Anjellica',
        lastName: 'Hamrick',
        email: 'afamalaro@icloud.com',
        instagramProfile: {
          handle: 'anjellica.hamrick',
          followers: 36470,
          engagementRate: 0.8201261310666301,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'anjellicahamrick',
          followers: 10291,
          engagementRate: 10.078260869565216,
          isPrimary: true
        }
      },
      userCampaignId: 'be28c475-4210-43c6-81f0-450ed019e424'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T17:54:12.000Z',
        amountCents: 1610
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T20:48:39.508Z',
          createdAt: '2023-08-14T02:50:11.316Z',
          content: [
            {
              id: '979da38f-3ec0-4885-8b59-d78141041781',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ee7c4020-0867-4f78-9bab-98d576c1f7f4/ugc/2814331e-3210-46f5-bf07-6f428e325440/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2814331e-3210-46f5-bf07-6f428e325440'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T20:48:44.727Z',
          createdAt: '2023-08-12T23:53:36.445Z',
          content: [
            {
              id: '37810bb6-e32d-4545-964a-840367ba0c02',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2ec092bc-e0ee-4eb7-823a-a3887ec05f25/ugc/118350bf-3619-4e82-99c8-517272eb0253/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '3f5d2516-d027-4279-9673-ab39f35614b2',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f51837-954c-4ac6-9336-a840d99a5956/ugc/ccb3cce9-931d-4558-a2af-170faaaf8f9c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'f3e16854-e896-424c-a575-8f580a6fd5e5',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aeb21c8d-08a8-4fac-867e-9379ce2e5a53/ugc/3021f491-bd8f-4dfa-81bf-d5441e724844/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '518a1906-c285-4f67-9614-ba518b03dd5a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ee7c4020-0867-4f78-9bab-98d576c1f7f4',
        firstName: 'Malaya',
        lastName: 'Youngs',
        email: 'malaya.tiffany@gmail.com',
        instagramProfile: {
          handle: 'malayatiffany',
          followers: 11007,
          engagementRate: 0.7458889797401653,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'malayatiffany',
          followers: 455,
          engagementRate: 5.759439555528971,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'malaya47'
        }
      },
      userCampaignId: 'ee7c4020-0867-4f78-9bab-98d576c1f7f4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T06:42:51.000Z',
        amountCents: 2362
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T16:00:33.729Z',
          createdAt: '2023-08-24T18:52:09.092Z',
          content: [
            {
              id: '4f9c45c2-45a4-44bc-9262-a870fe4d5cac',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09e0da20-d364-495f-b046-e8eb19c946ca/ugc/d5968ef2-bfe7-4e4a-bdd5-bfb663367221/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'b9160a2f-d322-401d-9397-90838ea5a8d2',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/78ad83f6-6406-420b-a789-907363206d54/ugc/17800335-5390-44f3-83e3-401f4cfa0957/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '385d4e85-0cc2-4fe5-94e5-d405d36efd6e',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4f14c340-e9bd-448f-a90a-62703cb3cb7a/ugc/5a201343-2b4b-4464-b393-e284af2d97a8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '51fcdb89-fc8c-464f-8a58-a3b5acc95b2d'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T03:53:55.664Z',
          createdAt: '2023-08-24T18:40:57.941Z',
          content: [
            {
              id: '90fe9456-2da9-46bd-be6f-aeb223bb6327',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735f9720-5df0-4e6d-8f08-c54d9517f52f/ugc/78e7de3b-94ee-475d-a4d1-eb59b4871b27/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '49dc0361-2b6b-4385-ba54-17e0fad6e124'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5be5e790-7474-4564-b7e2-5f8e9d1fe66e',
        firstName: 'Kelli',
        lastName: 'Hines',
        email: 'khines613@yahoo.com',
        instagramProfile: {
          handle: 'kelli_creates_content',
          followers: 408,
          engagementRate: 3.8235294117647056,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kelli_hines',
          followers: 5112,
          engagementRate: 14.779423018059381,
          isPrimary: true
        }
      },
      userCampaignId: '5be5e790-7474-4564-b7e2-5f8e9d1fe66e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T06:13:26.000Z',
        amountCents: 1641
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T18:41:37.751Z',
          createdAt: '2023-08-16T04:45:28.560Z',
          content: [
            {
              id: 'd305649e-c3d1-41bc-aff0-9231a799f76a',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09e0da20-d364-495f-b046-e8eb19c946ca/ugc/41946305-01c4-41af-bf19-c59251c3abf4/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'e95460aa-fdb1-4788-8104-eb2e3ed8703b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56658dd4-a1d7-4323-a195-572870f09c03/ugc/a968e917-b9c5-4d65-83e1-73cb5f76d4fd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'c85e3208-cd50-494f-bee0-ae83f6998fc0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bceffb03-5fdb-48ac-8942-f1713ddb230f/ugc/7bf195e1-6acd-4968-a991-cdfb5facc0ad/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7bf195e1-6acd-4968-a991-cdfb5facc0ad'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T18:41:44.896Z',
          createdAt: '2023-08-16T04:31:09.521Z',
          content: [
            {
              id: 'fc87260a-51d9-4e09-888a-64173ba6852f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a52acd66-fd18-4546-b955-06435cbb89ee/ugc/38350f28-ffcc-48f7-b375-75589522839c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '705fe680-951f-4e4a-b726-4001daeca8ee'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bceffb03-5fdb-48ac-8942-f1713ddb230f',
        firstName: 'Bria',
        lastName: 'Jaime',
        email: 'everythingbriaa@gmail.com',
        instagramProfile: {
          handle: 'briajaime',
          followers: 11660,
          engagementRate: 1.0523156089193826,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'briajaimeofficial',
          followers: 222,
          engagementRate: 5.723905723905724,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'briajaimeofficial'
        }
      },
      userCampaignId: 'bceffb03-5fdb-48ac-8942-f1713ddb230f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-04T00:31:57.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T00:00:00.000Z',
          createdAt: '2023-08-14T02:45:47.464Z',
          content: [
            {
              id: 'ee1a5303-a03d-494f-9ee3-f2da68157863',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56658dd4-a1d7-4323-a195-572870f09c03/ugc/a968e917-b9c5-4d65-83e1-73cb5f76d4fd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '69d6f281-e094-4fe7-b0b0-b397c257e537'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T00:00:00.000Z',
          createdAt: '2023-08-14T02:42:24.337Z',
          content: [
            {
              id: '3df8532b-f06b-4118-90e4-906d0518e948',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/349f06d7-3d12-443b-866f-377e554f0a95/ugc/2ed7340e-a506-4dd3-bcc1-f68c3e1d1a98/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'd8a805c8-94eb-4310-87b5-2d29bcd36045'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T16:28:52.000Z',
          createdAt: '2023-08-14T02:31:51.014Z',
          content: [
            {
              id: 'a3101248-ac2f-451b-8e14-769f2745f0b5',
              caption:
                '#AD The new @armandhammerlaundry Power Sheets are making laundry a breeze.\n\n🧺No drips, no spills\n🪶Lightweight & compact packaging (instead of big bottles)\n🛏️Refreshing Fresh Linen scent\n💦Quick dissolve technology ensures sheets dissolve fast (even in cold water)\n🌎 “Climate Pledge Friendly” certified on\nAmazon - meaning they’re designed with sustainability in mind\n\nClick the link in my stories and bio to grab some from Amazon!!\n\n#brandpartner @armandhammerlaundry',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/349f06d7-3d12-443b-866f-377e554f0a95/ugc/63c2c523-4349-4b6b-9988-9dd4c988784c/UGC-CONTENT-1.mp4'
                }
              ],
              comments: 40,
              likes: 512,
              views: 944,
              engagements: 580
            }
          ],
          groupId: 'cb0ae661-dd75-4914-841a-a529247f3b31'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2246513f-93ff-4d63-94db-4cc521c5dfbc',
        firstName: 'Amanda',
        lastName: 'Friedl',
        email: 'littlefriedls@gmail.com',
        instagramProfile: {
          handle: 'amandajfriedl',
          followers: 43937,
          engagementRate: 1.6403031613446528,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'flipscot',
          followers: 2597,
          engagementRate: 1.3937282229965158,
          isPrimary: true
        }
      },
      userCampaignId: '2246513f-93ff-4d63-94db-4cc521c5dfbc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-12T23:02:55.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T16:33:18.610Z',
          createdAt: '2023-08-27T18:04:33.479Z',
          content: [
            {
              id: '014b771d-0d0c-4576-bdf7-a87e129e1697',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f51837-954c-4ac6-9336-a840d99a5956/ugc/cf6bb27e-cf86-4277-a3d0-1eaf2713fb96/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '2e2f3e2e-6346-4680-8941-e2078f4040f7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e9be75b-4d0a-4146-9362-6b990bff148f/ugc/54c8c838-0541-4d05-a070-0642bc9fa46b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c8587a7c-d998-40a3-bdfc-fcb819a21eeb',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0921bf98-0e80-4f6d-9692-951694bed505/ugc/906b5710-e313-4faa-ad2a-97ce4c48d11a/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '54c8c838-0541-4d05-a070-0642bc9fa46b'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T16:33:14.298Z',
          createdAt: '2023-08-27T18:10:38.968Z',
          content: [
            {
              id: '266cd0dc-5f9e-4d8f-9171-5da8ca60fbd6',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c207199b-57e6-4de6-81d7-1a6f0360ded8/ugc/c4e4982a-b6e0-4da8-ac13-db56fb76896f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'b032daaa-9428-4ee4-8cd1-3afa7d918ad6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7e9be75b-4d0a-4146-9362-6b990bff148f',
        firstName: 'Stephanie',
        lastName: 'Utterback',
        email: 'stephanie.utterback1@gmail.com',
        instagramProfile: {
          handle: 'motherhoodwithsteph',
          followers: 3656,
          engagementRate: 1.2937636761487963,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'josielouboutique',
          followers: 2262,
          isPrimary: false
        }
      },
      userCampaignId: '7e9be75b-4d0a-4146-9362-6b990bff148f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T21:37:11.000Z',
        amountCents: 1650
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T23:36:54.111Z',
          createdAt: '2023-08-16T22:38:36.521Z',
          content: [
            {
              id: 'f0998dc0-b799-400f-977c-fa506c95d318',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5d27aefa-b8f6-4f75-bb13-5c4ed4689662/ugc/f469a1da-5522-4eed-b3f3-b00473ceb405/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f469a1da-5522-4eed-b3f3-b00473ceb405'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T23:37:00.277Z',
          createdAt: '2023-08-16T22:42:13.680Z',
          content: [
            {
              id: '3f95140c-be17-47ab-8423-0516c005e732',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/438e214c-cddf-4ecd-b378-1af4444015b3/ugc/ecf3429c-b273-4934-80f5-7ca2e3074ad5/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'b7ec9308-2058-400c-9c02-37a902fdfe03',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2ec092bc-e0ee-4eb7-823a-a3887ec05f25/ugc/555ba048-f118-4924-9bfc-c9d37af45abd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'b3fac217-3f90-45f2-b3b5-01f6078f230f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aeb21c8d-08a8-4fac-867e-9379ce2e5a53/ugc/3021f491-bd8f-4dfa-81bf-d5441e724844/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '98a445f8-c230-4293-98c6-0dbd5ee6bbdf'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5d27aefa-b8f6-4f75-bb13-5c4ed4689662',
        firstName: 'April',
        lastName: 'Pollock',
        email: 'april@aprilpollock.com',
        instagramProfile: {
          handle: 'april.lynn.pollock',
          followers: 16157,
          engagementRate: 3.6269109364362193,
          isPrimary: true
        }
      },
      userCampaignId: '5d27aefa-b8f6-4f75-bb13-5c4ed4689662'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T23:25:56.000Z',
        amountCents: 1615
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T20:03:39.024Z',
          createdAt: '2023-08-25T05:31:43.909Z',
          content: [
            {
              id: '38011629-04ad-44f1-93b8-b20335dbefcf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/911ee8ea-3d90-440f-9bc0-c16f4ca6cdc1/ugc/dfe20960-e1bf-405d-b44c-14b62fcf6179/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '35fd74c4-3104-4ac8-95d6-c7c5086dadf2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/911ee8ea-3d90-440f-9bc0-c16f4ca6cdc1/ugc/dfe20960-e1bf-405d-b44c-14b62fcf6179/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '907f0e66-a0f8-45e9-8b16-30e7e19e060b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67bfe02e-d6c5-4bcb-a266-d677dd98ee14/ugc/49f33518-4a5b-4bc9-bced-8cd4482303b9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'dfe20960-e1bf-405d-b44c-14b62fcf6179'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T17:27:20.223Z',
          createdAt: '2023-08-25T05:00:57.551Z',
          content: [
            {
              id: '7a0c99b2-c8b7-48a2-bbc7-357712ac0f79',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f60a8232-b7b1-471d-9327-3701ba1a29c0/ugc/a0681731-85af-49f8-a1e9-ba29152ed672/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '547aba2f-b821-4c6f-8462-25d990e9ab8e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '911ee8ea-3d90-440f-9bc0-c16f4ca6cdc1',
        firstName: 'Lucas',
        lastName: 'Ficher',
        email: 'lucas4t@hotmail.com',
        instagramProfile: {
          handle: 'lucas.ficherr',
          followers: 108554,
          engagementRate: 0.5231497687786724,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lucasficherr',
          followers: 5186,
          engagementRate: 45.3416149068323,
          isPrimary: true
        }
      },
      userCampaignId: '911ee8ea-3d90-440f-9bc0-c16f4ca6cdc1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T00:39:46.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T21:04:07.023Z',
          createdAt: '2023-08-19T20:52:47.422Z',
          content: [
            {
              id: 'b1289dfa-dbb4-460d-8909-41dd5b541951',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/25027f64-0f31-4766-9f05-f63ef63ea113/ugc/2702ceab-b497-45ea-b93a-79ac4a310e00/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c1005640-12a1-46db-b005-98f4f2ebc9f9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/25027f64-0f31-4766-9f05-f63ef63ea113/ugc/2702ceab-b497-45ea-b93a-79ac4a310e00/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ac8d0492-5c21-4371-be04-2ceb99f83439',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c207199b-57e6-4de6-81d7-1a6f0360ded8/ugc/c4e4982a-b6e0-4da8-ac13-db56fb76896f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '2702ceab-b497-45ea-b93a-79ac4a310e00'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T18:00:07.591Z',
          createdAt: '2023-08-19T20:49:51.614Z',
          content: [
            {
              id: '5f08dabf-ff56-4d92-b31c-0b2902850a6d',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/438e214c-cddf-4ecd-b378-1af4444015b3/ugc/7619b90d-c9c0-45a4-a6b2-9a6afd86ead7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'dc6bfe07-dd6d-41f3-a6d7-b1a14cbe62d1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '25027f64-0f31-4766-9f05-f63ef63ea113',
        firstName: 'Rebekah',
        lastName: 'Broyles',
        email: 'infix-imagery.00@icloud.com',
        instagramProfile: {
          handle: 'beckyboo1092',
          followers: 615,
          engagementRate: 2.048780487804878,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'momtokwithbekah',
          followers: 21142,
          engagementRate: 5.240091463414634,
          isPrimary: true
        }
      },
      userCampaignId: '25027f64-0f31-4766-9f05-f63ef63ea113'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-17T09:26:13.000Z',
        amountCents: 1848
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T15:19:08.406Z',
          createdAt: '2023-08-24T03:02:25.567Z',
          content: [
            {
              id: 'fa5824cc-b9e2-4c68-8b22-66ee4e293d1f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4f14c340-e9bd-448f-a90a-62703cb3cb7a/ugc/265ff736-3592-408c-af72-4b17ac026147/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '180223d9-82c7-42cc-8214-070af09a8c9e',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c207199b-57e6-4de6-81d7-1a6f0360ded8/ugc/a2cb95dd-fb30-4778-b90d-a491a903c8cc/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'b831363a-d349-4f2f-ad0d-49d742d3f21f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1683afd-5969-4989-a707-f9884dd1d887/ugc/1d5b77d7-df58-48e8-9b57-e793fb84e55e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'd4c06b06-ea39-430e-9a1f-8850fe5e5561'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T20:33:04.739Z',
          createdAt: '2023-09-06T15:20:16.922Z',
          content: [
            {
              id: 'f7c54c69-5c17-4fad-9715-2cee13bbd738',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e09c2481-680c-4eb3-8d6e-07314a9e9c3d/ugc/03239f24-1865-4112-adfb-9bf10b071464/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'aa8dce61-55e8-4d36-afb3-060309a450df'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'dd94fc2b-483a-438a-a3d8-39daad78dd67',
        firstName: 'Destinie',
        lastName: 'Forest',
        email: 'destinieforest18@gmail.com',
        instagramProfile: {
          handle: 'destiniebrianna_',
          followers: 2818,
          engagementRate: 0.8587650816181689,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'destiniebrianna_',
          followers: 9370,
          engagementRate: 14.604024044391938,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'mommyndrose'
        }
      },
      userCampaignId: 'dd94fc2b-483a-438a-a3d8-39daad78dd67'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T17:34:10.000Z',
        amountCents: 1626
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-22T14:01:01.688Z',
          createdAt: '2023-09-21T18:24:26.899Z',
          content: [
            {
              id: '0bd4c6f0-009a-4829-8a34-7d6c04a98759',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eaac66f2-a817-497b-a00d-0ea27ce8128f/ugc/03798206-6b1c-4549-a688-6ce0d602b406/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'df2c0c90-2575-4b30-8fc0-6ae16bb8fde4'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-22T14:01:06.310Z',
          createdAt: '2023-08-16T21:36:40.224Z',
          content: [
            {
              id: '11f11f8b-d15f-43a0-92e4-89783be618f5',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6c0b75b-a66a-4450-ac4a-474388ead948/ugc/587db8f8-04da-41fe-a1ab-ede681b02838/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '0f9364ac-d005-4a10-a11a-b54ab45f5148',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/349f06d7-3d12-443b-866f-377e554f0a95/ugc/195eeb78-588c-43a5-a015-b855e1786896/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '1337697e-6f13-49c2-b159-454e8af8066a',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2ec092bc-e0ee-4eb7-823a-a3887ec05f25/ugc/555ba048-f118-4924-9bfc-c9d37af45abd/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '1c8d1165-ba30-456e-a3fc-154db4e99501',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0921bf98-0e80-4f6d-9692-951694bed505/ugc/906b5710-e313-4faa-ad2a-97ce4c48d11a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '3487a0ca-15ae-4080-b3ed-1605d4598f69'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cfe877cb-476d-43ee-8ab9-2909644c10f2',
        firstName: 'Cianelle',
        lastName: 'Hibben',
        email: 'lecianelle@gmail.com',
        instagramProfile: {
          handle: 'lecianelle',
          followers: 686,
          engagementRate: 9.606413994169097,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lecianelle',
          followers: 977,
          engagementRate: 4.2105263157894735,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'lecianelle',
          followers: 0
        }
      },
      userCampaignId: 'cfe877cb-476d-43ee-8ab9-2909644c10f2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-17T19:04:00.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-12T02:09:02.312Z',
          createdAt: '2023-09-08T03:11:17.222Z',
          content: [
            {
              id: '65057ff0-856f-478c-bde5-0b9167dd799a',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56658dd4-a1d7-4323-a195-572870f09c03/ugc/f0e53eee-89d9-471f-a546-ba3491028f93/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '5caf4b28-2cfc-4ea2-b405-1f3b779b250b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a52acd66-fd18-4546-b955-06435cbb89ee/ugc/e44401c8-c680-444a-b30f-23b60d16519a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'd36448de-1764-4b92-bb5b-8207f27f596d',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a52acd66-fd18-4546-b955-06435cbb89ee/ugc/e44401c8-c680-444a-b30f-23b60d16519a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '47883ff8-b13e-4393-9346-f3f23e9ebf7e',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735f9720-5df0-4e6d-8f08-c54d9517f52f/ugc/78e7de3b-94ee-475d-a4d1-eb59b4871b27/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '7fdc8982-a604-4989-afb7-861ac661473b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1683afd-5969-4989-a707-f9884dd1d887/ugc/0a949ec2-e405-491c-8d17-101875b08c71/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '5e9dfc55-0356-43b0-ab51-3c83b2bafe49'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-08T19:31:06.073Z',
          createdAt: '2023-09-04T12:20:34.573Z',
          content: [
            {
              id: 'b9db013c-3615-4b00-acb5-0e913c2211a0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a550dda1-74b4-49d4-b4d1-36b840484de3/ugc/97918fa9-d8c6-498b-830b-d156bb0f0bd7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '97918fa9-d8c6-498b-830b-d156bb0f0bd7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a550dda1-74b4-49d4-b4d1-36b840484de3',
        firstName: 'Courtney ',
        lastName: 'Ryrie ',
        email: 'courtneyjane0820@gmail.com',
        instagramProfile: {
          handle: 'scottishcourt',
          followers: 2857,
          engagementRate: 0.3255162758137907,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'scottishcourt',
          followers: 20927,
          engagementRate: 6.228685299162547,
          isPrimary: true
        }
      },
      userCampaignId: 'a550dda1-74b4-49d4-b4d1-36b840484de3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T19:23:01.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T14:15:20.178Z',
          createdAt: '2023-08-18T21:34:56.056Z',
          content: [
            {
              id: 'c4a7b1ab-635b-4922-9ccd-320e54868fd0',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a147dc3-4578-4887-b8bf-a223ab0467f9/ugc/837bf438-66d3-45ed-8bb1-9c5d799b6fac/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '4b6480c1-ff92-4598-a722-3d842efe0f52',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/101a2fd0-dc06-4177-ab5a-be49b3842da9/ugc/e56478ac-a84e-4972-8a1a-1e051ef4cb88/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '317301ef-82c8-4e13-bb46-ed6f4febf6f2',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67bfe02e-d6c5-4bcb-a266-d677dd98ee14/ugc/eb4038cc-a251-4628-965a-7bd19f69cbe3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'e56478ac-a84e-4972-8a1a-1e051ef4cb88'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T14:15:25.235Z',
          createdAt: '2023-08-18T21:46:41.536Z',
          content: [
            {
              id: '3d499272-8498-47ee-9a52-585126119249',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/101a2fd0-dc06-4177-ab5a-be49b3842da9/ugc/2d868b47-5725-4693-ba58-b3f08abbf44c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2d868b47-5725-4693-ba58-b3f08abbf44c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '101a2fd0-dc06-4177-ab5a-be49b3842da9',
        firstName: 'Dariya',
        lastName: 'Abdrakhmanova',
        email: 'darij1991@msn.com',
        instagramProfile: {
          handle: 'dariya09',
          followers: 4120,
          engagementRate: 0.412621359223301,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dariya1991',
          followers: 475,
          engagementRate: 6.493506493506493,
          isPrimary: true
        }
      },
      userCampaignId: '101a2fd0-dc06-4177-ab5a-be49b3842da9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T17:43:09.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T15:26:25.439Z',
          createdAt: '2023-08-25T16:21:04.713Z',
          content: [
            {
              id: 'e86c838a-64ca-4736-a7d0-c82520056edd',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e09c2481-680c-4eb3-8d6e-07314a9e9c3d/ugc/03239f24-1865-4112-adfb-9bf10b071464/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'aa770493-e1ee-4a65-9822-beba9daf626f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/438e214c-cddf-4ecd-b378-1af4444015b3/ugc/7619b90d-c9c0-45a4-a6b2-9a6afd86ead7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'dffa53fa-ebee-4b99-bdd8-843894b7b442',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4f14c340-e9bd-448f-a90a-62703cb3cb7a/ugc/265ff736-3592-408c-af72-4b17ac026147/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '5ebea650-6a2f-471c-817a-c6696d6cdff4'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T23:29:42.485Z',
          createdAt: '2023-08-25T16:35:42.665Z',
          content: [
            {
              id: 'df84000d-7328-4f80-90b1-8b67a998f7d0',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e0ca105-c428-4ee5-8b83-bfbd084e31f5/ugc/076c599c-1f57-453d-a99b-17277d4ee173/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'e49cdda7-27e6-49ed-a16a-9744982abe8a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '725be671-7f30-4801-bd85-744362903809',
        firstName: 'CORBI',
        lastName: 'Greenwalt',
        email: 'corbicorb7@gmail.com',
        instagramProfile: {
          handle: 'misstryb4youbuy',
          followers: 14045,
          engagementRate: 3.1776432894268423,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'unb0thered_777',
          followers: 871,
          engagementRate: 25.423728813559322,
          isPrimary: true
        }
      },
      userCampaignId: '725be671-7f30-4801-bd85-744362903809'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T20:25:07.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T18:31:01.489Z',
          createdAt: '2023-08-10T00:14:05.793Z',
          content: [
            {
              id: '38b457b8-b6d2-4da6-beea-6ce5cd58b1c0',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6c0b75b-a66a-4450-ac4a-474388ead948/ugc/587db8f8-04da-41fe-a1ab-ede681b02838/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'c7298a06-6a76-4143-9515-2d3ae49e39f5'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-08-10T14:28:26.826Z',
          createdAt: '2023-08-10T00:03:03.298Z',
          content: [
            {
              id: 'af85db8a-8146-4139-9869-8f7039bd06a7',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e0ca105-c428-4ee5-8b83-bfbd084e31f5/ugc/076c599c-1f57-453d-a99b-17277d4ee173/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '8875b017-c2a5-4851-a996-ade7bed4d4b2',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e0ca105-c428-4ee5-8b83-bfbd084e31f5/ugc/076c599c-1f57-453d-a99b-17277d4ee173/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '27a376cf-595c-49e8-9903-8e806bf9e320',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aeb21c8d-08a8-4fac-867e-9379ce2e5a53/ugc/3bf884ab-3552-4331-9006-7e0f05cf5887/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '472cd54a-941f-45ce-8644-261066f7686e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0c63762c-caaa-4c00-b15d-59b76c3371d0',
        firstName: 'Alexis',
        lastName: 'McCreary',
        email: 'alexismccreary333@gmail.com',
        instagramProfile: {
          handle: 'alexismccreary_',
          followers: 660,
          engagementRate: 5.549242424242425,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alexismccreary_',
          followers: 2970,
          engagementRate: 11.905947076239084,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'lifewithlex2578'
        }
      },
      userCampaignId: '0c63762c-caaa-4c00-b15d-59b76c3371d0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-12T18:22:03.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T22:52:35.344Z',
          createdAt: '2023-08-27T00:29:51.011Z',
          content: [
            {
              id: 'c42413e7-0326-4afd-a955-fb21acf45571',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6a16a544-ebe3-42e4-bfaf-576ef8c9f996/ugc/a4dde4f8-d4f0-49e2-b7e5-943813735731/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'd24423ab-dffd-4fed-9f4a-76c4b8cc7445',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/78ad83f6-6406-420b-a789-907363206d54/ugc/17800335-5390-44f3-83e3-401f4cfa0957/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: 'b0744825-fb5f-4215-bf32-f95a1638dd6e',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6a16a544-ebe3-42e4-bfaf-576ef8c9f996/ugc/a4dde4f8-d4f0-49e2-b7e5-943813735731/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '7262df8f-6235-48d9-b992-9e970073c1aa'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T13:40:08.381Z',
          createdAt: '2023-08-27T00:26:54.666Z',
          content: [
            {
              id: '990afb7a-c2d2-4ffc-b414-82b773d50355',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2678070-e2ef-4a31-bde3-b35e0ff287cc/ugc/499f2ddf-1cf3-47b8-8f31-8b0186c394bf/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '9a94d321-611f-4dde-9b80-8e04e6194a92'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4c017bce-8b62-4361-8b74-d4ef529a1823',
        firstName: 'Heather',
        lastName: 'Moe',
        email: 'keylimepixie@gmail.com',
        instagramProfile: {
          handle: 'keylimepixie_',
          followers: 630,
          engagementRate: 7.2063492063492065,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'keylimepixie_',
          followers: 3137,
          engagementRate: 1.296880232168769,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'keylimepixie_'
        }
      },
      userCampaignId: '4c017bce-8b62-4361-8b74-d4ef529a1823'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T16:12:18.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T15:58:23.898Z',
          createdAt: '2023-08-23T20:36:44.729Z',
          content: [
            {
              id: '558336ff-c2ad-42b9-a555-28f801a96219',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38a3bdf5-f61c-4d2d-952c-159d703bac4d/ugc/c2ea66ad-93f2-433b-97b2-6692d550e8f3/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              id: '7906da3b-6017-429b-b428-f65277a4e6d0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3d064deb-f378-40ac-b3d0-98de5d96c4f0/ugc/c99fb616-3786-463e-9a87-2661083f361b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1379e814-b767-415b-a9af-5e0b951143b4',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/438e214c-cddf-4ecd-b378-1af4444015b3/ugc/7619b90d-c9c0-45a4-a6b2-9a6afd86ead7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'c99fb616-3786-463e-9a87-2661083f361b'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T15:58:19.639Z',
          createdAt: '2023-08-25T04:30:01.067Z',
          content: [
            {
              id: '39c1519c-659a-4c79-9dc5-047a22e7982b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09e0da20-d364-495f-b046-e8eb19c946ca/ugc/d5968ef2-bfe7-4e4a-bdd5-bfb663367221/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '5a74709f-e041-4ba0-b820-f639ad03e7fa'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3d064deb-f378-40ac-b3d0-98de5d96c4f0',
        firstName: 'Jayde',
        lastName: 'Hoban',
        email: 'jaydehoban@gmail.com',
        instagramProfile: {
          handle: 'life.withjayde',
          followers: 1710,
          engagementRate: 7.000000000000001,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'life.withjayde',
          followers: 51471,
          engagementRate: 10.46627242155024,
          isPrimary: true
        }
      },
      userCampaignId: '3d064deb-f378-40ac-b3d0-98de5d96c4f0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T20:14:33.000Z',
        amountCents: 2374
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T23:48:45.841Z',
          createdAt: '2023-08-16T19:03:28.245Z',
          content: [
            {
              id: '093d9a75-ff38-4c0b-8824-000a283eb0cd',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2ec092bc-e0ee-4eb7-823a-a3887ec05f25/ugc/555ba048-f118-4924-9bfc-c9d37af45abd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '92052f6c-9e19-44e6-ace7-5af1375139b3',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/349f06d7-3d12-443b-866f-377e554f0a95/ugc/195eeb78-588c-43a5-a015-b855e1786896/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: 'a41ff448-13b8-4fd0-97f2-030c1a8402d9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/02c0bab2-e37d-4866-b697-bc9f2a6f7e62/ugc/5c1e686c-40d0-4c64-87c8-aae3bed01f18/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5c1e686c-40d0-4c64-87c8-aae3bed01f18'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T23:48:36.610Z',
          createdAt: '2023-08-17T02:04:15.219Z',
          content: [
            {
              id: 'e738943a-36e5-4b49-96e9-ac4e15ff0c9b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67bfe02e-d6c5-4bcb-a266-d677dd98ee14/ugc/49f33518-4a5b-4bc9-bced-8cd4482303b9/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '99a68936-5887-4f12-9d3d-dd7b21c0b374'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '02c0bab2-e37d-4866-b697-bc9f2a6f7e62',
        firstName: 'Cathleen',
        lastName: 'Pagan',
        email: 'cathleen.pagan@gmail.com',
        instagramProfile: {
          handle: 'cathleen_pagan',
          followers: 3335,
          engagementRate: 2.527736131934033,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'cathleenpagan',
          followers: 1339,
          engagementRate: 6.100205075220338,
          isPrimary: true
        }
      },
      userCampaignId: '02c0bab2-e37d-4866-b697-bc9f2a6f7e62'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T10:06:19.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T05:35:43.799Z',
          createdAt: '2023-08-22T08:42:47.771Z',
          content: [
            {
              id: '2b1ca283-705a-447d-b0a8-d2a13ecd5cc2',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eaac66f2-a817-497b-a00d-0ea27ce8128f/ugc/e044a04f-ad82-4c30-b7c3-ef8bfb664f7a/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '390c98fe-6787-43c9-a4a2-08c56ad4e262',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/54860b8c-556d-4f77-b2e0-be0380cfffaf/ugc/993ef64d-403c-4976-b51e-f5de5aebf25a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '98f29f67-360d-4705-b388-1b36de657d3c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/54860b8c-556d-4f77-b2e0-be0380cfffaf/ugc/993ef64d-403c-4976-b51e-f5de5aebf25a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '993ef64d-403c-4976-b51e-f5de5aebf25a'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T16:59:48.185Z',
          createdAt: '2023-08-22T08:44:09.891Z',
          content: [
            {
              id: '99244e16-8e9f-4c21-9aed-d2a08438f8f8',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f51837-954c-4ac6-9336-a840d99a5956/ugc/ccb3cce9-931d-4558-a2af-170faaaf8f9c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '005dfaf0-8600-4fac-82ef-da0d4f7d9fc4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '54860b8c-556d-4f77-b2e0-be0380cfffaf',
        firstName: 'Sunny',
        lastName: 'Lee',
        email: 'ugcwithsunny@gmail.com',
        instagramProfile: {
          handle: 'ugcwithsunny',
          followers: 3636,
          engagementRate: 2.370737073707371,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ugcwithsunny',
          followers: 3279,
          engagementRate: 8.546006944444445,
          isPrimary: true
        }
      },
      userCampaignId: '54860b8c-556d-4f77-b2e0-be0380cfffaf'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T20:15:36.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T01:29:31.734Z',
          createdAt: '2023-08-18T04:48:55.348Z',
          content: [
            {
              id: '5b5cab69-5cb7-4593-9ba3-4ea6806700b4',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2be5b163-c241-4ebd-82fb-68ebbef1ab44/ugc/68404da7-5dfc-4d44-89fa-3d4d9d8f90dd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '58ca7a67-2a8f-4971-af57-24b47a80cd90',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e09c2481-680c-4eb3-8d6e-07314a9e9c3d/ugc/7068a40c-99d1-4d64-9ce7-51807a15d03d/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '06b9292b-e8ab-4f43-884e-3e3a249da639',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ed9bf342-eddc-4c90-b942-af4bdd9d8bac/ugc/71d529f8-6ddf-4c2d-95fe-fd2a5cf285d5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '71d529f8-6ddf-4c2d-95fe-fd2a5cf285d5'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T14:59:57.801Z',
          createdAt: '2023-08-18T04:52:52.361Z',
          content: [
            {
              id: 'e6bb164d-2abb-460e-a615-7ff9f5021f99',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6a16a544-ebe3-42e4-bfaf-576ef8c9f996/ugc/a4dde4f8-d4f0-49e2-b7e5-943813735731/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '15ee7a0f-4bb9-4b6e-a73a-6f833e156606'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ed9bf342-eddc-4c90-b942-af4bdd9d8bac',
        firstName: 'Iryna',
        lastName: 'Bukreieva',
        email: 'irynabukreieva@gmail.com',
        instagramProfile: {
          handle: 'iryna.bukreieva',
          followers: 46530,
          engagementRate: 0.25660863958736296,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'shubrina16',
          followers: 4133,
          isPrimary: true
        }
      },
      userCampaignId: 'ed9bf342-eddc-4c90-b942-af4bdd9d8bac'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T19:22:22.000Z',
        amountCents: 2368
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T08:27:34.573Z',
          createdAt: '2023-08-15T00:30:09.599Z',
          content: [
            {
              id: 'fa2034bc-c2a9-4bea-8915-154b8d846366',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6a16a544-ebe3-42e4-bfaf-576ef8c9f996/ugc/7c6edec7-0ac8-48c7-9602-9338b01b8b83/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'b4292107-ec0e-486e-9309-554e37e356a8',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f60a8232-b7b1-471d-9327-3701ba1a29c0/ugc/47b3ce0c-9cc1-41b0-8401-e24b9e53e441/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '6594a887-99c5-4ab4-8804-c850c7c5adce',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4f14c340-e9bd-448f-a90a-62703cb3cb7a/ugc/265ff736-3592-408c-af72-4b17ac026147/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: 'bbf1573a-b464-4b34-ace3-cb99018ef365'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T08:27:29.038Z',
          createdAt: '2023-08-15T00:29:17.211Z',
          content: [
            {
              id: 'bb5798fe-0f3a-4e1b-9aa2-fb057f76f053',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56658dd4-a1d7-4323-a195-572870f09c03/ugc/a968e917-b9c5-4d65-83e1-73cb5f76d4fd/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'c6b66758-110d-48fc-bca2-fb671bb25d90'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bc2b2123-df94-4211-bb2a-05f31e86e16d',
        firstName: 'Caitlyn',
        lastName: 'Chaffin',
        email: 'caitlynbrookepalmer@gmail.com',
        instagramProfile: {
          handle: 'caitlynbpalmer',
          followers: 2852,
          engagementRate: 1.3183730715287518,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brookee00',
          followers: 2589,
          engagementRate: 6.336088154269973,
          isPrimary: true
        }
      },
      userCampaignId: 'bc2b2123-df94-4211-bb2a-05f31e86e16d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T17:16:10.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:36:08.291Z',
          createdAt: '2023-08-15T05:11:19.380Z',
          content: [
            {
              id: '04e7e094-1b12-4e31-99df-48b0dedf35bd',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f51837-954c-4ac6-9336-a840d99a5956/ugc/ccb3cce9-931d-4558-a2af-170faaaf8f9c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: '125b32c8-7ccf-4c7e-bf2e-d20d04c72b53'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:36:12.332Z',
          createdAt: '2023-08-14T13:47:43.635Z',
          content: [
            {
              id: '980380e2-afb4-46b2-bb5a-6fa558640290',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735f9720-5df0-4e6d-8f08-c54d9517f52f/ugc/6e9597db-8306-4d28-b0ab-d939c0b75c1e/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'a7641a85-5f2a-486c-8f68-e45298b3469f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eaac66f2-a817-497b-a00d-0ea27ce8128f/ugc/e044a04f-ad82-4c30-b7c3-ef8bfb664f7a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              id: '7fe55a7e-69b9-4396-9590-e956f84bb997',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1683afd-5969-4989-a707-f9884dd1d887/ugc/0a949ec2-e405-491c-8d17-101875b08c71/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'd1a5f468-ee87-4cb1-8320-d25232f7148f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2c3ad7f3-2dcb-4d3a-b41a-b60d3e88be63',
        firstName: 'Mary',
        lastName: 'Su',
        email: 'mareeesu@gmail.com',
        instagramProfile: {
          handle: '___marysu',
          followers: 844,
          engagementRate: 9.443127962085308,
          isPrimary: true
        },
        tiktokProfile: {
          handle: '___marysu',
          followers: 158,
          engagementRate: 2.054794520547945,
          isPrimary: false
        }
      },
      userCampaignId: '2c3ad7f3-2dcb-4d3a-b41a-b60d3e88be63'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-03T17:31:23.000Z',
        amountCents: 2424
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:00:00.000Z',
          createdAt: '2023-08-07T17:33:17.248Z',
          content: [
            {
              id: '375807c8-4a81-41e5-a4db-a8b9e1651139',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0921bf98-0e80-4f6d-9692-951694bed505/ugc/906b5710-e313-4faa-ad2a-97ce4c48d11a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'b20e8ec5-6d88-42de-bdbe-aa32aeac8018'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T16:17:21.000Z',
          createdAt: '2023-08-22T21:34:08.506Z',
          content: [
            {
              id: '3374b11a-8dab-4d02-b3b7-dfb37240aba9',
              caption:
                '#ad #brandpartner \nI’ve partnered with Arm & Hammer to show you these amazing laundry detergent power sheets!\n•••••\nIt helps me save time by having an already PRE measured sheet to wash my clothes. As a momma of 2 under 2, chasing my littles around…I need all the time I can get! It also helps reduce the use of plastic bottles! \n•••••\n@armandhammerlaundry is a Climate Pledge friendly product certified by Amazon! #amazing \n•••••\nYou can check out the product on the link here & in my bio! \nhttps://amzn.to/3Y81Lha',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2678070-e2ef-4a31-bde3-b35e0ff287cc/ugc/7ac82743-29d1-4b3c-8eb2-41d09f7502a4/UGC-CONTENT-1.mp4'
                }
              ],
              comments: 1,
              likes: 20,
              views: 677,
              engagements: 22
            }
          ],
          groupId: '32663177-3ef6-4bdb-9547-c0f63de0682c'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:00:00.000Z',
          createdAt: '2023-08-07T17:28:21.960Z',
          content: [
            {
              id: 'eed032ff-4cca-4f5a-bd1e-e8e9bf83f321',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09e0da20-d364-495f-b046-e8eb19c946ca/ugc/8a0972ea-ff24-484c-915a-0d6be7cd0be1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '66da21c6-1bfe-4ed2-8ba8-03d5ce221ce4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '25d8905d-4934-49ec-ad0e-88567fd1b781',
        firstName: 'Yasmina',
        lastName: 'Williams',
        email: 'sirensandchampagne@gmail.com',
        instagramProfile: {
          handle: 'sirensandchampagne',
          followers: 29520,
          engagementRate: 0.2567750677506775,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sirensandchampagne',
          followers: 714,
          engagementRate: 4.875325115910891,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'yasminacolony7810'
        }
      },
      userCampaignId: '25d8905d-4934-49ec-ad0e-88567fd1b781'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T20:05:42.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-07T09:48:21.025Z',
          createdAt: '2023-08-17T23:43:38.150Z',
          content: [
            {
              id: '60ec43b7-bc8f-4789-9846-1d01e9759908',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09e0da20-d364-495f-b046-e8eb19c946ca/ugc/d5968ef2-bfe7-4e4a-bdd5-bfb663367221/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'fe933856-d568-4c3b-8b03-2b0ec532eb93',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8fbad86f-a8d1-40b8-a28a-11607eb276dd/ugc/94583d50-e9f6-47b1-88e5-05c6dd6f7a97/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '3a395fc4-6563-47d4-b4b2-09facd251041',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/78ad83f6-6406-420b-a789-907363206d54/ugc/7a7b0616-13fb-4339-b0e6-6afa979f985b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: '5f3b7ec5-1129-4de8-833e-264285af648e'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-14T17:55:57.094Z',
          createdAt: '2023-09-10T17:33:06.265Z',
          content: [
            {
              id: '7862512b-55de-4453-9ede-8fc894683045',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cc567f6d-5f60-4d4d-9a44-3da21c2f4dd7/ugc/04cec0ba-e943-4a66-90da-6068fb77458c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '04cec0ba-e943-4a66-90da-6068fb77458c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cc567f6d-5f60-4d4d-9a44-3da21c2f4dd7',
        firstName: 'Ashley',
        lastName: 'Lemons',
        email: 'ashleyymua20@gmail.com',
        instagramProfile: {
          handle: 'ashweemariii',
          followers: 3809,
          engagementRate: 0.4620635337358887,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ashweemariii',
          followers: 9438,
          engagementRate: 11.806778169014084,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'ashweemarii'
        }
      },
      userCampaignId: 'cc567f6d-5f60-4d4d-9a44-3da21c2f4dd7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-06T22:52:35.000Z',
        amountCents: 2376
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T13:51:45.947Z',
          createdAt: '2023-08-16T02:25:09.756Z',
          content: [
            {
              id: '7226380a-6eb7-4358-92e7-c4476508b42e',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2be5b163-c241-4ebd-82fb-68ebbef1ab44/ugc/ca86afbb-b43a-4554-bf45-3b6d023550c1/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: '1d234e56-6a5c-47ec-9f39-c8166169f066',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ab8fd6c4-868f-4ac7-aed0-7f5da989e831/ugc/0c33a60c-468f-4d87-baed-8d6a9af49d9b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '483a1bdb-97dc-4148-97b4-f66203507ce6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ab8fd6c4-868f-4ac7-aed0-7f5da989e831/ugc/0c33a60c-468f-4d87-baed-8d6a9af49d9b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0c33a60c-468f-4d87-baed-8d6a9af49d9b'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T13:51:40.288Z',
          createdAt: '2023-08-16T02:24:01.102Z',
          content: [
            {
              id: 'd863eba9-0772-4eab-94df-0b1004df806a',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a147dc3-4578-4887-b8bf-a223ab0467f9/ugc/837bf438-66d3-45ed-8bb1-9c5d799b6fac/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '3de2388f-db84-439f-aa0d-7ee3c3941401'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ab8fd6c4-868f-4ac7-aed0-7f5da989e831',
        firstName: 'Kaylee',
        lastName: 'Ellsworth',
        email: 'kellsworth3634@icloud.com',
        instagramProfile: {
          handle: 'kayleedmeadows',
          followers: 1460,
          engagementRate: 2.1506849315068495,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kayleedmeadows',
          followers: 18211,
          engagementRate: 12.823324116656124,
          isPrimary: true
        }
      },
      userCampaignId: 'ab8fd6c4-868f-4ac7-aed0-7f5da989e831'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T19:13:52.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:41:45.102Z',
          createdAt: '2023-08-19T01:05:41.167Z',
          content: [
            {
              id: '83750c4c-74a6-4a86-97c3-fe824ca4a478',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6c0b75b-a66a-4450-ac4a-474388ead948/ugc/587db8f8-04da-41fe-a1ab-ede681b02838/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '7df6bd76-bbe7-4c3d-9fae-d0b3c3234dea'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:41:50.403Z',
          createdAt: '2023-08-19T01:27:26.532Z',
          content: [
            {
              id: 'c27b8f60-16cb-4ab6-a9dd-101b4f5ac2b1',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d379d28-5b0c-4a77-88d9-62fdea56566e/ugc/40e195dd-22da-4fba-b740-24563b065a28/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '018020d7-0589-4880-8216-dca47b31bd34',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d379d28-5b0c-4a77-88d9-62fdea56566e/ugc/60289947-76fa-47d5-94a2-d70f6a3d6c11/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'e2aae791-b7c3-41aa-a7c2-8ed75cc5fde6',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c207199b-57e6-4de6-81d7-1a6f0360ded8/ugc/c4e4982a-b6e0-4da8-ac13-db56fb76896f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: 'db2aec52-2c43-4613-9aeb-0093171ec5d6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3e24d963-59ff-426b-9f6b-c05e536740bc',
        firstName: 'Taylor',
        lastName: 'Vaughn',
        email: 'katherinetaylordesigns@gmail.com',
        instagramProfile: {
          handle: '_katherine_taylor_',
          followers: 1192,
          engagementRate: 2.2651006711409396,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'katherinetaylordesigns',
          followers: 28,
          isPrimary: false
        }
      },
      userCampaignId: '3e24d963-59ff-426b-9f6b-c05e536740bc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T18:12:44.000Z',
        amountCents: 1600
      },
      status: 'COMPLETED',
      creatorType: 'TT',
      content: [
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T07:00:00.000Z',
          createdAt: '2023-08-14T17:59:53.860Z',
          content: [
            {
              id: '0b84c056-54ce-4551-878f-15994778016f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/34de5d37-55ab-497f-8925-e829d62cb245/IS_REQUIRED/33a90052-76f3-4ed5-abde-a1b99cbeebf5/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '60a9e9f4-e92f-4a4d-9382-d291371d262a'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T07:00:00.000Z',
          createdAt: '2023-08-14T17:55:09.699Z',
          content: [
            {
              id: 'a29f1a1e-d033-4562-8bc3-4b908080a9b9',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67bfe02e-d6c5-4bcb-a266-d677dd98ee14/ugc/49f33518-4a5b-4bc9-bced-8cd4482303b9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '3676c3b5-6610-4165-8bb3-594db95d4be6'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T20:08:35.000Z',
          createdAt: '2023-08-10T04:55:42.987Z',
          content: [
            {
              id: '183d0ab2-51ac-42dd-886f-fd5f90176ba1',
              caption:
                '#ad @ARM & HAMMER Laundry So obsessed with the arm and hammer laundry sheets! this product is Climate Pledge Friendly certified on Amazon and has recyclable packaging, along with no plastic bottle waste. #brandpartner #ad ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7268014555450625322/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 8,
              likes: 86,
              views: 1718
            }
          ],
          groupId: '6cae2b66-fa24-4ec1-979f-3601c3d75387'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '34de5d37-55ab-497f-8925-e829d62cb245',
        firstName: 'Haley',
        lastName: 'Van Gilder',
        email: 'haleyvg63@gmail.com',
        tiktokProfile: {
          handle: 'chase.and.haley',
          followers: 46192,
          engagementRate: 5.614406779661017,
          isPrimary: true
        }
      },
      userCampaignId: '34de5d37-55ab-497f-8925-e829d62cb245'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T17:15:08.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-03T01:39:41.918Z',
          createdAt: '2023-08-26T01:08:39.590Z',
          content: [
            {
              id: 'df2a8fa2-e9d7-42aa-bc11-6c7d5bffad09',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d30d0b7d-03d8-43fb-b9a4-d970b79d7de9/ugc/a73dff73-9ba6-4915-b2a4-4c8bb3daa736/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a73dff73-9ba6-4915-b2a4-4c8bb3daa736'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T18:12:47.301Z',
          createdAt: '2023-08-26T00:49:17.772Z',
          content: [
            {
              id: 'dd357a28-7c5b-4e4b-ace3-cff453b31e0b',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2678070-e2ef-4a31-bde3-b35e0ff287cc/ugc/499f2ddf-1cf3-47b8-8f31-8b0186c394bf/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'b39bfe10-397f-486a-a4ba-443d1488e9ab',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1683afd-5969-4989-a707-f9884dd1d887/ugc/0a949ec2-e405-491c-8d17-101875b08c71/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '3c3204b8-dfac-450b-a414-bc613f12feae',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d30d0b7d-03d8-43fb-b9a4-d970b79d7de9/ugc/7ec59427-6a85-40b2-83ba-46b036920219/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7ec59427-6a85-40b2-83ba-46b036920219'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd30d0b7d-03d8-43fb-b9a4-d970b79d7de9',
        firstName: 'Laura ',
        lastName: 'Quinting ',
        email: 'lauraquintinganna@gmail.com',
        instagramProfile: {
          handle: 'foodmakeupskin',
          followers: 6407,
          engagementRate: 0.5743717808646792,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'foodmakeupskin',
          followers: 79551,
          engagementRate: 1.4908133662834426,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'foodmakeupskin'
        }
      },
      userCampaignId: 'd30d0b7d-03d8-43fb-b9a4-d970b79d7de9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T17:16:02.000Z',
        amountCents: 1615
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T15:04:30.677Z',
          createdAt: '2023-08-23T16:02:09.420Z',
          content: [
            {
              id: 'fa9b30df-4325-485e-8526-8041a73a9570',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4851600e-0afd-4e57-af06-66efa25b0efc/ugc/b106b257-cb42-4bd9-bad0-c1ea16b2d8da/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cd77f062-4ca4-4f54-aa36-0149d196d5b8',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/78ad83f6-6406-420b-a789-907363206d54/ugc/17800335-5390-44f3-83e3-401f4cfa0957/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: 'd1cac446-362f-4531-af41-270bbd8c9f1f',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2678070-e2ef-4a31-bde3-b35e0ff287cc/ugc/499f2ddf-1cf3-47b8-8f31-8b0186c394bf/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'b106b257-cb42-4bd9-bad0-c1ea16b2d8da'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T15:04:22.141Z',
          createdAt: '2023-08-23T21:21:09.383Z',
          content: [
            {
              id: '5463fdbf-ac6a-4aec-878b-a3f535bc33b0',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43b73228-fe16-4b48-aef2-0fd71eb1a7ba/ugc/8093660a-3c28-43e5-a95c-d3a1025792c7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ],
          groupId: 'be9b2dd8-d5f7-4c5d-8e69-79658507b60a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4851600e-0afd-4e57-af06-66efa25b0efc',
        firstName: 'Haley',
        lastName: 'Cox',
        email: 'hayheller@yahoo.com',
        instagramProfile: {
          handle: 'yogahay',
          followers: 3244,
          engagementRate: 0.406905055487053,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'yoga_hay',
          followers: 450,
          engagementRate: 3.1192660550458715,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'haleycox380'
        }
      },
      userCampaignId: '4851600e-0afd-4e57-af06-66efa25b0efc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T17:44:26.000Z',
        amountCents: 2362
      },
      status: 'COMPLETED',
      creatorType: 'TT',
      content: [
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T04:00:00.000Z',
          createdAt: '2023-08-15T22:30:07.756Z',
          content: [
            {
              id: '8375ecf2-d90d-4ddf-85e7-b35f4446df55',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aeb21c8d-08a8-4fac-867e-9379ce2e5a53/ugc/3021f491-bd8f-4dfa-81bf-d5441e724844/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ],
          groupId: '56926b52-8377-4d7b-b6da-804f4e69603f'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T04:00:00.000Z',
          createdAt: '2023-08-15T22:14:10.214Z',
          content: [
            {
              id: 'b954586f-de3b-4d51-869e-20a4c85137e3',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eaac66f2-a817-497b-a00d-0ea27ce8128f/ugc/e044a04f-ad82-4c30-b7c3-ef8bfb664f7a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ],
          groupId: 'dfa174d1-5103-4307-ab20-fddee2321b0f'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T19:10:17.000Z',
          createdAt: '2023-08-15T22:00:20.957Z',
          content: [
            {
              id: '877c7f7f-b9b3-4d2d-8443-4f468120f2d1',
              caption:
                '#ad #ad Oh, and did I mention these laundry sheets are Climate Pledge Friendly on Amazon with recyclable packaging? 🙌🏻 #brandpartner @ARM & HAMMER Laundry ',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8fbad86f-a8d1-40b8-a28a-11607eb276dd/ugc/94583d50-e9f6-47b1-88e5-05c6dd6f7a97/UGC-CONTENT-1.jpeg'
                }
              ],
              comments: 4,
              likes: 15,
              views: 619
            }
          ],
          groupId: 'c6dc3427-9c16-4aab-9aff-0ae0c0a120e7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7fd3d526-e639-4b92-928a-034b2007da1b',
        firstName: 'Ali',
        lastName: 'Gronski',
        email: 'allisongronski@gmail.com',
        instagramProfile: {
          handle: 'alitalksmotherhood',
          followers: 3958,
          engagementRate: 3.640727640222334,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alitalksmotherhood',
          followers: 17413,
          engagementRate: 6.170212765957447,
          isPrimary: true
        }
      },
      userCampaignId: '7fd3d526-e639-4b92-928a-034b2007da1b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T16:34:09.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-11T14:10:39.305Z',
          createdAt: '2023-09-04T00:37:11.254Z',
          content: [
            {
              id: '4fae789c-749a-4834-8987-4745316fa2bf',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38a3bdf5-f61c-4d2d-952c-159d703bac4d/ugc/c2ea66ad-93f2-433b-97b2-6692d550e8f3/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              id: '7689a8db-1b06-4622-9e09-47f3dcf76042',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6a16a544-ebe3-42e4-bfaf-576ef8c9f996/ugc/c64a247d-a59c-4a12-94d5-25a7ac39a0c2/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              id: 'cbe750eb-6bc9-47ac-9536-b39bcc5c4284',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2b03134a-ae1f-4819-a69a-f2d31dede26a/ugc/2b371674-5447-4cfd-a071-b180ff95d589/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2b371674-5447-4cfd-a071-b180ff95d589'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-11T14:10:34.559Z',
          createdAt: '2023-09-04T03:52:35.768Z',
          content: [
            {
              id: '3f9be9a9-15f0-4401-86ca-97aa1ade5206',
              caption: '',
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d379d28-5b0c-4a77-88d9-62fdea56566e/ugc/40e195dd-22da-4fba-b740-24563b065a28/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ],
          groupId: '77ceb88b-3757-4119-9752-01d064a40a88'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2b03134a-ae1f-4819-a69a-f2d31dede26a',
        firstName: 'Krystal',
        lastName: 'Giraldo',
        email: 'krystaljanet@yahoo.com',
        instagramProfile: {
          handle: 'theinclusivemama',
          followers: 6610,
          engagementRate: 2.0015128593040847,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'theinclusivemama_',
          followers: 231,
          engagementRate: 3.0939226519337018,
          isPrimary: false
        }
      },
      userCampaignId: '2b03134a-ae1f-4819-a69a-f2d31dede26a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-17T19:54:20.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T12:58:31.415Z',
          createdAt: '2023-08-24T18:04:57.889Z',
          content: [
            {
              id: '5b726e31-4737-42cb-aa25-1fe7b997862c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5f3e795-aa25-489b-9603-e560aebccf1c/ugc/d1d7e56c-e37a-4f38-8fa6-a565ae60155a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '929e1006-9bd5-4083-9b37-7b309cd6afa8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5f3e795-aa25-489b-9603-e560aebccf1c/ugc/d1d7e56c-e37a-4f38-8fa6-a565ae60155a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2056be49-1361-42af-aa56-40d706f10318',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5f3e795-aa25-489b-9603-e560aebccf1c/ugc/d1d7e56c-e37a-4f38-8fa6-a565ae60155a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd1d7e56c-e37a-4f38-8fa6-a565ae60155a'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T12:58:25.332Z',
          createdAt: '2023-08-28T22:12:23.327Z',
          content: [
            {
              id: '69b6548e-b665-41c2-a9c8-744c659c54ab',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5f3e795-aa25-489b-9603-e560aebccf1c/ugc/1f90d2b9-544b-4bcf-9880-5d83ddc692c9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1f90d2b9-544b-4bcf-9880-5d83ddc692c9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c5f3e795-aa25-489b-9603-e560aebccf1c',
        firstName: 'Jonae',
        lastName: 'Richmond',
        email: 'jonaeniicole@gmail.com',
        instagramProfile: {
          handle: 'jonaeniicole',
          followers: 2149,
          engagementRate: 1.9637040483946024,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jonaeniicole',
          followers: 194,
          engagementRate: 10.754281949934125,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jonaeniicole',
          followers: 3
        }
      },
      userCampaignId: 'c5f3e795-aa25-489b-9603-e560aebccf1c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T03:33:30.000Z',
        amountCents: 2314
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T14:46:22.580Z',
          createdAt: '2023-08-21T15:18:40.143Z',
          content: [
            {
              id: '8ef701e0-1011-4d5f-b943-2d381c560ce9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/96b7ce78-6f6d-4a3e-b39d-0e90180230b3/ugc/6898dbeb-f6f2-4f8a-8d1b-7b0b91264e32/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6ba84920-894c-4ed1-8e4b-afbdbedd8184',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/96b7ce78-6f6d-4a3e-b39d-0e90180230b3/ugc/6898dbeb-f6f2-4f8a-8d1b-7b0b91264e32/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e0fff539-5666-41e4-9658-9164cdaf200e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/96b7ce78-6f6d-4a3e-b39d-0e90180230b3/ugc/6898dbeb-f6f2-4f8a-8d1b-7b0b91264e32/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6898dbeb-f6f2-4f8a-8d1b-7b0b91264e32'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T04:57:41.009Z',
          createdAt: '2023-08-21T15:12:18.619Z',
          content: [
            {
              id: 'c681a4d0-7d02-4142-a6bb-8d92ec86a7bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/96b7ce78-6f6d-4a3e-b39d-0e90180230b3/ugc/e53eac3b-1fff-4a67-90a7-ba2866135d94/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e53eac3b-1fff-4a67-90a7-ba2866135d94'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '96b7ce78-6f6d-4a3e-b39d-0e90180230b3',
        firstName: 'Andrea',
        lastName: 'Sheridan',
        email: 'coachandreabehrer@gmail.com',
        instagramProfile: {
          handle: 'sweatingdiamonds',
          followers: 3807,
          engagementRate: 104.50485946939847,
          isPrimary: true
        }
      },
      userCampaignId: '96b7ce78-6f6d-4a3e-b39d-0e90180230b3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T18:34:59.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T18:46:22.910Z',
          createdAt: '2023-08-23T20:44:07.207Z',
          content: [
            {
              id: 'dc75e2f1-f35e-4a97-9f64-0dad26272f6c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/249fabb0-dafd-406a-afdc-da3d77637f8c/ugc/984020c0-5de8-4146-ab67-33aea3fa5705/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c9f23576-5a4a-46bc-bf8f-41e9d0091ea3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/249fabb0-dafd-406a-afdc-da3d77637f8c/ugc/984020c0-5de8-4146-ab67-33aea3fa5705/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b03e71a5-cc9a-4814-8ac7-be9a7fa1b2a7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/249fabb0-dafd-406a-afdc-da3d77637f8c/ugc/984020c0-5de8-4146-ab67-33aea3fa5705/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '984020c0-5de8-4146-ab67-33aea3fa5705'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T20:29:04.271Z',
          createdAt: '2023-08-22T22:56:23.339Z',
          content: [
            {
              id: '457fc6a7-f7f2-470d-a03c-c9b4a702db03',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/249fabb0-dafd-406a-afdc-da3d77637f8c/ugc/99cf415d-1249-48e1-9015-dfda7195a4e6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '99cf415d-1249-48e1-9015-dfda7195a4e6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '249fabb0-dafd-406a-afdc-da3d77637f8c',
        firstName: 'Cleverly Chloé',
        lastName: 'Marcano',
        email: 'cleverlychloe@gmail.com',
        instagramProfile: {
          handle: 'cleverlychloe',
          followers: 10739,
          engagementRate: 2.253468665611323,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'cleverlychloe',
          followers: 57371,
          engagementRate: 6.686738011005206,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'cleverlychloe'
        }
      },
      userCampaignId: '249fabb0-dafd-406a-afdc-da3d77637f8c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T16:23:58.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T14:42:46.798Z',
          createdAt: '2023-08-13T23:18:04.871Z',
          content: [
            {
              id: '5520e2eb-f07a-4bf7-a30b-6b5123b8d678',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84347f34-01e7-4ff3-9e57-4ebde86419ba/ugc/be2aabfb-8157-4156-917b-c3996118f13b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ebd8990d-381c-43c5-802c-f0915ae574ed',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84347f34-01e7-4ff3-9e57-4ebde86419ba/ugc/be2aabfb-8157-4156-917b-c3996118f13b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9337582b-d109-446d-b071-05e027753551',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84347f34-01e7-4ff3-9e57-4ebde86419ba/ugc/be2aabfb-8157-4156-917b-c3996118f13b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'be2aabfb-8157-4156-917b-c3996118f13b'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T21:59:32.765Z',
          createdAt: '2023-08-15T00:52:56.960Z',
          content: [
            {
              id: '69e084b2-89ec-4724-ba77-c6f27755ef88',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84347f34-01e7-4ff3-9e57-4ebde86419ba/ugc/89c3a9ac-5e5a-4a01-a51e-6a65805a98e2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '89c3a9ac-5e5a-4a01-a51e-6a65805a98e2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '84347f34-01e7-4ff3-9e57-4ebde86419ba',
        firstName: 'Katelyn',
        lastName: 'Masek',
        email: 'katelynmasek@gmail.com',
        instagramProfile: {
          handle: 'katelynmasek',
          followers: 4525,
          engagementRate: 1.4320441988950277,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'katelynmasek',
          followers: 473,
          engagementRate: 1.912568306010929,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'katelynmasek',
          followers: 305
        }
      },
      userCampaignId: '84347f34-01e7-4ff3-9e57-4ebde86419ba'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T18:03:02.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T17:31:26.035Z',
          createdAt: '2023-08-17T17:12:12.104Z',
          content: [
            {
              id: 'c1b03917-70e1-4271-a815-a3d3da10bdd7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b9515340-a165-4892-aaa4-04eeb9b02280/ugc/268ac86c-4f81-40ca-8460-55b1821fca87/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '268ac86c-4f81-40ca-8460-55b1821fca87'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T17:31:30.121Z',
          createdAt: '2023-08-14T15:53:28.546Z',
          content: [
            {
              id: '7554bb51-e1bf-483a-99ad-6af93ceff43a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b9515340-a165-4892-aaa4-04eeb9b02280/ugc/cf1f2c5f-7948-4a5b-9857-d00125041879/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '48b65cc2-1528-4f22-aeef-3caf41b165ef',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b9515340-a165-4892-aaa4-04eeb9b02280/ugc/cf1f2c5f-7948-4a5b-9857-d00125041879/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '830a176c-30a8-4157-acc5-88ac3b8e0800',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b9515340-a165-4892-aaa4-04eeb9b02280/ugc/cf1f2c5f-7948-4a5b-9857-d00125041879/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cf1f2c5f-7948-4a5b-9857-d00125041879'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b9515340-a165-4892-aaa4-04eeb9b02280',
        firstName: 'Marisa',
        lastName: 'Sheldon',
        email: 'lincrowcollabs@gmail.com',
        instagramProfile: {
          handle: 'marisalynsheldon',
          followers: 10329,
          engagementRate: 3.77674508664924,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'marisalynsheldon',
          followers: 509,
          engagementRate: 5.044136191677175,
          isPrimary: true
        }
      },
      userCampaignId: 'b9515340-a165-4892-aaa4-04eeb9b02280'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T00:46:20.000Z',
        amountCents: 2303
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T19:18:29.323Z',
          createdAt: '2023-08-19T14:22:53.404Z',
          content: [
            {
              id: 'b7c92284-8015-4431-a303-7f86d48cbfeb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/37906e90-fa1c-42e7-ab39-d6b770cda1ea/ugc/837bf51e-cb32-4aa6-9b71-cbc194db6b59/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a59facaf-fbfe-4bd9-919b-1f82109c6394',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/37906e90-fa1c-42e7-ab39-d6b770cda1ea/ugc/837bf51e-cb32-4aa6-9b71-cbc194db6b59/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e18f4088-1168-49b7-9979-cdaa9d2ac81d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/37906e90-fa1c-42e7-ab39-d6b770cda1ea/ugc/837bf51e-cb32-4aa6-9b71-cbc194db6b59/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '837bf51e-cb32-4aa6-9b71-cbc194db6b59'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T19:18:33.946Z',
          createdAt: '2023-08-19T14:21:33.923Z',
          content: [
            {
              id: '140ecad1-775f-430e-9682-c7c23853f4a7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/37906e90-fa1c-42e7-ab39-d6b770cda1ea/ugc/baf201a8-a2dd-4347-9466-abc30e4efb80/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'baf201a8-a2dd-4347-9466-abc30e4efb80'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '37906e90-fa1c-42e7-ab39-d6b770cda1ea',
        firstName: 'Desiree ',
        lastName: 'Modica ',
        email: 'desireemodica@gmail.com',
        instagramProfile: {
          handle: 'desiimodica',
          followers: 4093,
          engagementRate: 0.8404593207915954,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'desiimodicaa',
          followers: 3554,
          engagementRate: 15.083428373084743,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'desiimodica'
        }
      },
      userCampaignId: '37906e90-fa1c-42e7-ab39-d6b770cda1ea'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-17T10:58:43.000Z',
        amountCents: 1499
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T21:05:50.005Z',
          createdAt: '2023-08-25T19:50:47.938Z',
          content: [
            {
              id: '545d94d5-3042-4b48-97ce-bbf7c6ec11a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84e4a026-da5f-4a3d-b562-cfa3f2a718ed/ugc/501bc259-9569-4a0f-8e02-59ee58711bd8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '15d97cfe-1cac-45c3-b1da-c38af192d88c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84e4a026-da5f-4a3d-b562-cfa3f2a718ed/ugc/501bc259-9569-4a0f-8e02-59ee58711bd8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2434be21-fca7-47c8-bc9c-97f3e10a1836',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84e4a026-da5f-4a3d-b562-cfa3f2a718ed/ugc/501bc259-9569-4a0f-8e02-59ee58711bd8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '501bc259-9569-4a0f-8e02-59ee58711bd8'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T15:06:12.546Z',
          createdAt: '2023-08-25T19:35:14.944Z',
          content: [
            {
              id: '452d2a05-80f4-4f76-871c-ad40aa132be4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/84e4a026-da5f-4a3d-b562-cfa3f2a718ed/ugc/6e77fe41-71f3-4255-9bbe-ecf5e38c93a2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6e77fe41-71f3-4255-9bbe-ecf5e38c93a2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '84e4a026-da5f-4a3d-b562-cfa3f2a718ed',
        firstName: 'Shannon',
        lastName: 'Busque',
        email: 'shannon@unstuckmommymindset.com',
        instagramProfile: {
          handle: 'momtipsfordaytrips',
          followers: 1355,
          engagementRate: 0.5018450184501845,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'momtipsfordaytrips',
          followers: 4351,
          engagementRate: 9.648446534577134,
          isPrimary: true
        }
      },
      userCampaignId: '84e4a026-da5f-4a3d-b562-cfa3f2a718ed'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-12T22:42:05.000Z',
        amountCents: 1652
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T10:43:28.977Z',
          createdAt: '2023-08-21T02:08:01.680Z',
          content: [
            {
              id: 'cee7f399-3be0-49fb-8c55-a385d069ddce',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/347199b1-6856-4512-b0ae-975d4cf091fb/ugc/b345b293-ee48-4362-8701-f72ab3f20d62/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b345b293-ee48-4362-8701-f72ab3f20d62'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T10:43:34.209Z',
          createdAt: '2023-08-20T15:15:43.049Z',
          content: [
            {
              id: '5655d3e6-00ec-4464-93c8-14dd234ee502',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/347199b1-6856-4512-b0ae-975d4cf091fb/ugc/e75d30d9-d6b7-4860-9a9e-f1e3434857a4/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a5ff704b-e190-4b9a-8565-0bcf6d49c70f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/347199b1-6856-4512-b0ae-975d4cf091fb/ugc/e75d30d9-d6b7-4860-9a9e-f1e3434857a4/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f598f451-cbd5-4a77-8c00-0ddc25c50d09',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/347199b1-6856-4512-b0ae-975d4cf091fb/ugc/e75d30d9-d6b7-4860-9a9e-f1e3434857a4/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e75d30d9-d6b7-4860-9a9e-f1e3434857a4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '347199b1-6856-4512-b0ae-975d4cf091fb',
        firstName: 'Emilee',
        lastName: 'Eychner',
        email: 'mommyemilee22@gmail.com',
        tiktokProfile: {
          handle: 'emilee.47',
          followers: 24528,
          engagementRate: 7.561728395061729,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'emileeeychner1359'
        }
      },
      userCampaignId: '347199b1-6856-4512-b0ae-975d4cf091fb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T17:32:20.000Z',
        amountCents: 1649
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:59:05.532Z',
          createdAt: '2023-08-12T22:01:49.176Z',
          content: [
            {
              id: 'd98e44fe-dd1f-41d7-b18f-4a200c8fe0e6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6d7e05d-dfd1-4a16-94e2-2567b8623e5b/ugc/dc0cb83a-835e-4848-8ac1-1e0cd23d4dd2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'dc0cb83a-835e-4848-8ac1-1e0cd23d4dd2'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:53:58.015Z',
          createdAt: '2023-08-10T21:34:08.516Z',
          content: [
            {
              id: '96ef9f9d-dd6f-447a-9fc2-d28e9cfa23ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6d7e05d-dfd1-4a16-94e2-2567b8623e5b/ugc/0c881489-e662-4d43-9054-89ac696029bc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2d5690f6-ea4c-4421-bd0b-cbc2a17eae86',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6d7e05d-dfd1-4a16-94e2-2567b8623e5b/ugc/0c881489-e662-4d43-9054-89ac696029bc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1b58d86b-7fb9-490c-8791-9b1f1c4517cd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6d7e05d-dfd1-4a16-94e2-2567b8623e5b/ugc/0c881489-e662-4d43-9054-89ac696029bc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0c881489-e662-4d43-9054-89ac696029bc'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b6d7e05d-dfd1-4a16-94e2-2567b8623e5b',
        firstName: 'Tayler',
        lastName: 'Shaver',
        email: 'taylershaverbusiness@gmail.com',
        instagramProfile: {
          handle: 'taylershaverr',
          followers: 1062,
          engagementRate: 0.9322033898305085,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'taylershaver',
          followers: 4669,
          engagementRate: 9.350670006759712,
          isPrimary: true
        }
      },
      userCampaignId: 'b6d7e05d-dfd1-4a16-94e2-2567b8623e5b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T18:24:30.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T17:05:01.926Z',
          createdAt: '2023-08-22T04:00:54.663Z',
          content: [
            {
              id: '0c246e65-f85d-43a6-9afd-d38cce783633',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d4dcc596-89c4-445f-ae59-7be57482ddf6/ugc/ee3b8a5a-c8b7-4a26-adf0-1bd0a989765a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f2d61ae3-f969-4fff-8d24-8fa57ce260e4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d4dcc596-89c4-445f-ae59-7be57482ddf6/ugc/ee3b8a5a-c8b7-4a26-adf0-1bd0a989765a/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '96ce457d-69da-43c8-b299-8c8c860d8c36',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d4dcc596-89c4-445f-ae59-7be57482ddf6/ugc/ee3b8a5a-c8b7-4a26-adf0-1bd0a989765a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '67c03fb2-1b6a-40c6-81fc-9a03825ca719',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d4dcc596-89c4-445f-ae59-7be57482ddf6/ugc/ee3b8a5a-c8b7-4a26-adf0-1bd0a989765a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ee3b8a5a-c8b7-4a26-adf0-1bd0a989765a'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T17:05:07.438Z',
          createdAt: '2023-08-22T03:51:23.166Z',
          content: [
            {
              id: '6ade9827-e4e0-492a-b90b-d21a60331295',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d4dcc596-89c4-445f-ae59-7be57482ddf6/ugc/71168c92-e25e-474a-aec5-68104cd65a1c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '71168c92-e25e-474a-aec5-68104cd65a1c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd4dcc596-89c4-445f-ae59-7be57482ddf6',
        firstName: 'Amanda',
        lastName: 'Hughes',
        email: 'amandaslrj@gmail.com',
        instagramProfile: {
          handle: 'amandaslrj',
          followers: 25239,
          engagementRate: 7.2368160386703115,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'amandaslrj22',
          followers: 10029,
          engagementRate: 2.2080763875074596,
          isPrimary: false
        }
      },
      userCampaignId: 'd4dcc596-89c4-445f-ae59-7be57482ddf6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-03T17:20:04.000Z',
        amountCents: 1628
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T19:39:24.000Z',
          createdAt: '2023-08-10T23:38:27.309Z',
          content: [
            {
              id: 'a53c521e-85ef-401c-81ad-fdf10590c998',
              caption:
                '#AD laundry made easy🧺 \n\nhappy to have found these arm and hammer power sheets! they are pre-measured detergent sheets that dissolve in any temp of water and works in all machines. my fav part is that they smell very good- fresh linen scent🫧\n\nclick the link in my bio to find them on amazon!\n\n#brandpartner @armandhammerlaundry',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/CwimA1mtmh6/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 4,
              likes: 27,
              views: 721,
              engagements: 34
            }
          ],
          groupId: 'dfdabf98-a6f0-4535-ba0a-dcc15b9783ab'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T00:00:00.000Z',
          createdAt: '2023-08-10T23:51:24.106Z',
          content: [
            {
              id: '261ef020-2556-4db2-92a6-c781619b92d8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17991309893057210/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6d9dcffb-40ce-42b6-a6e1-05b6e73fda78'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T00:00:00.000Z',
          createdAt: '2023-08-10T23:45:43.011Z',
          content: [
            {
              id: '4b92a3dc-19eb-4cc3-b1dc-332bfef1df63',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18001227940893032/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e67f69bd-0c8f-4ffd-bd20-81154b9c53a5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4b589b05-03f8-479b-83b3-81513d56c77c',
        firstName: 'nataly',
        lastName: 'perez',
        email: 'nataly8698@gmail.com',
        instagramProfile: {
          handle: 'nataly_perez',
          followers: 2442,
          engagementRate: 1.3554463554463556,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'natalyp845',
          followers: 0
        }
      },
      userCampaignId: '4b589b05-03f8-479b-83b3-81513d56c77c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-12T17:36:24.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T12:54:44.598Z',
          createdAt: '2023-08-21T15:34:17.236Z',
          content: [
            {
              id: '39820961-539e-43b3-99ce-8558fa43e731',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a8667e0-b92c-4070-ac57-fd3c3a7b99d9/ugc/863997b0-4194-42fd-b8f0-8f3f6e2823af/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ee4f0d82-42b1-4f1d-a707-f029508ff931',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a8667e0-b92c-4070-ac57-fd3c3a7b99d9/ugc/863997b0-4194-42fd-b8f0-8f3f6e2823af/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '55b0b64b-24e8-4972-b801-e0a53d1ad21d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a8667e0-b92c-4070-ac57-fd3c3a7b99d9/ugc/863997b0-4194-42fd-b8f0-8f3f6e2823af/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '863997b0-4194-42fd-b8f0-8f3f6e2823af'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T12:54:37.281Z',
          createdAt: '2023-08-21T15:19:48.308Z',
          content: [
            {
              id: '234a16a0-df02-411a-87ca-3070191604c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a8667e0-b92c-4070-ac57-fd3c3a7b99d9/ugc/ff1d7a42-742e-46bc-96b8-25b0535c0854/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ff1d7a42-742e-46bc-96b8-25b0535c0854'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6a8667e0-b92c-4070-ac57-fd3c3a7b99d9',
        firstName: 'Rachel',
        lastName: 'Norris',
        email: 'rachelnorris312@gmail.com',
        instagramProfile: {
          handle: 'mrs_rach_norris',
          followers: 78566,
          engagementRate: 0.1556653005116717,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mrsrach_norris',
          followers: 3058,
          engagementRate: 6.81265206812652,
          isPrimary: true
        }
      },
      userCampaignId: '6a8667e0-b92c-4070-ac57-fd3c3a7b99d9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-12T20:33:44.000Z',
        amountCents: 1608
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T21:01:30.116Z',
          createdAt: '2023-08-15T19:03:18.145Z',
          content: [
            {
              id: 'eef00877-c5a8-41ba-bb8b-bb6c64a88263',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eb5f0101-72bc-4406-87b0-d276885ecb81/ugc/50121559-e67d-4d12-be0b-a5bbc76ae3ba/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c0a900d5-7041-4897-8c10-ad2142f6e390',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eb5f0101-72bc-4406-87b0-d276885ecb81/ugc/50121559-e67d-4d12-be0b-a5bbc76ae3ba/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1864371b-90e5-495c-8e75-0b7b9e134849',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eb5f0101-72bc-4406-87b0-d276885ecb81/ugc/50121559-e67d-4d12-be0b-a5bbc76ae3ba/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '50121559-e67d-4d12-be0b-a5bbc76ae3ba'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T21:01:23.447Z',
          createdAt: '2023-08-15T19:00:00.055Z',
          content: [
            {
              id: '429c8266-a4d6-4615-9183-d0537791c424',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eb5f0101-72bc-4406-87b0-d276885ecb81/ugc/77bb0372-0a6b-4d3e-aa58-83cf74eada3a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '77bb0372-0a6b-4d3e-aa58-83cf74eada3a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'eb5f0101-72bc-4406-87b0-d276885ecb81',
        firstName: 'Brittney',
        lastName: 'Brooks',
        email: 'brittneybrooks123@gmail.com',
        instagramProfile: {
          handle: 'brittneyryannn',
          followers: 4446,
          engagementRate: 0.8479532163742691,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brittneyryannn',
          followers: 15971,
          engagementRate: 16.15960334520578,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'brittneyryannn'
        }
      },
      userCampaignId: 'eb5f0101-72bc-4406-87b0-d276885ecb81'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T11:14:01.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T13:34:53.954Z',
          createdAt: '2023-08-17T20:37:38.574Z',
          content: [
            {
              id: '2b47f147-5c37-4f73-8e2b-45238b9a250a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc/ugc/5bcbd048-5985-4105-80d2-632ad143bddc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '636a1100-51d3-40dc-9a0e-8b74cf7d3664',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc/ugc/5bcbd048-5985-4105-80d2-632ad143bddc/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6f3be8aa-18ba-4312-b1a1-b00b247d0d2d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc/ugc/5bcbd048-5985-4105-80d2-632ad143bddc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd679abb7-d410-4542-a7e9-02974ae0edd7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc/ugc/5bcbd048-5985-4105-80d2-632ad143bddc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5bcbd048-5985-4105-80d2-632ad143bddc'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T11:15:10.710Z',
          createdAt: '2023-08-17T21:14:41.073Z',
          content: [
            {
              id: '9caec54f-fd60-49c2-82e1-73b8e0f85200',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc/ugc/0a598204-1c14-469a-ae82-20ff7b3aa796/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0a598204-1c14-469a-ae82-20ff7b3aa796'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc',
        firstName: 'Jhofannie',
        lastName: 'Zorrilla',
        email: 'ugcbyjhofa@gmail.com',
        instagramProfile: {
          handle: 'contentcreatorjhofa',
          followers: 114,
          engagementRate: 1.4035087719298245,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'contentcreatorjhofa',
          followers: 1092,
          engagementRate: 5.186045535326961,
          isPrimary: true
        }
      },
      userCampaignId: 'dc4840e1-0a73-4f1c-b64d-f7ebe20dddcc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T02:39:30.000Z',
        amountCents: 1608
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T18:41:45.671Z',
          createdAt: '2023-08-18T22:57:17.954Z',
          content: [
            {
              id: 'eda74ba6-0dc4-44fe-9a9b-88f3014e0bfe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a05d6cee-f3bc-4c65-b443-1093163d54f1/ugc/2e6ed7b2-9fea-4822-bb98-5ecddb6f948c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f1afccd9-87fb-4d39-aadb-2c37ecd974ed',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a05d6cee-f3bc-4c65-b443-1093163d54f1/ugc/2e6ed7b2-9fea-4822-bb98-5ecddb6f948c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cccd9a4e-1a80-40ee-946d-82f8092af29a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a05d6cee-f3bc-4c65-b443-1093163d54f1/ugc/2e6ed7b2-9fea-4822-bb98-5ecddb6f948c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '66193afd-8888-4adb-8753-e359635ac9b2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a05d6cee-f3bc-4c65-b443-1093163d54f1/ugc/2e6ed7b2-9fea-4822-bb98-5ecddb6f948c/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2e6ed7b2-9fea-4822-bb98-5ecddb6f948c'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T21:10:07.174Z',
          createdAt: '2023-08-19T18:47:07.901Z',
          content: [
            {
              id: '968c428b-c063-45d4-aa9a-824463aae765',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a05d6cee-f3bc-4c65-b443-1093163d54f1/ugc/2edf7fa5-e81e-4207-bd20-a74ab5b96fdc/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2edf7fa5-e81e-4207-bd20-a74ab5b96fdc'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a05d6cee-f3bc-4c65-b443-1093163d54f1',
        firstName: 'Lisa',
        lastName: 'Powell',
        email: 'powelisa@gmail.com',
        instagramProfile: {
          handle: 'lisa_pow',
          followers: 21247,
          engagementRate: 2.1485386172165484,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lisa_pow',
          followers: 10107,
          engagementRate: 6.339468302658487,
          isPrimary: true
        }
      },
      userCampaignId: 'a05d6cee-f3bc-4c65-b443-1093163d54f1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T18:15:02.000Z',
        amountCents: 3826
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T14:25:02.641Z',
          createdAt: '2023-08-22T17:25:06.457Z',
          content: [
            {
              id: '63a0f534-83b5-4017-be61-92cdc4b26b47',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45ae26e0-7d77-4929-90fc-ff48938895c0/ugc/9a218377-e7f0-4a70-a655-0ade4669f52e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c7f4def7-6a4c-495e-94dd-f0edd93f6715',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45ae26e0-7d77-4929-90fc-ff48938895c0/ugc/9a218377-e7f0-4a70-a655-0ade4669f52e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd9a3b9cd-de5c-44ba-9ba1-feb6415aa497',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45ae26e0-7d77-4929-90fc-ff48938895c0/ugc/9a218377-e7f0-4a70-a655-0ade4669f52e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9a218377-e7f0-4a70-a655-0ade4669f52e'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T15:07:25.527Z',
          createdAt: '2023-08-23T14:26:06.096Z',
          content: [
            {
              id: '6b562ad4-920c-45b6-82a6-c74611e597cd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45ae26e0-7d77-4929-90fc-ff48938895c0/ugc/5eb193c0-8dc4-4dc0-a2f8-1431774c1d1f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5eb193c0-8dc4-4dc0-a2f8-1431774c1d1f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '45ae26e0-7d77-4929-90fc-ff48938895c0',
        firstName: 'Marta',
        lastName: 'Adames',
        email: 'marta_adames@live.com',
        instagramProfile: {
          handle: 'magicalmarta_',
          followers: 11714,
          engagementRate: 1.0056342837630186,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'magicalmarta_',
          followers: 18804,
          engagementRate: 2.564102564102564,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'martaproductionsllc',
          followers: 26000
        }
      },
      userCampaignId: '45ae26e0-7d77-4929-90fc-ff48938895c0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-01T16:52:53.000Z',
        amountCents: 1628
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-09T16:23:37.362Z',
          createdAt: '2023-08-09T15:29:07.183Z',
          content: [
            {
              id: 'eb6ddddb-9d62-4936-9df0-8f4332873d78',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/90b96e6d-4192-4534-901e-1d6d2e91a73a/ugc/312219c3-f7b1-4d1e-9569-5cdb0a81675a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5fff2c70-0d9e-4713-9c05-6ac4eeebcbfd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/90b96e6d-4192-4534-901e-1d6d2e91a73a/ugc/312219c3-f7b1-4d1e-9569-5cdb0a81675a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b22f10db-74fc-4e00-a9c4-f0d916f41902',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/90b96e6d-4192-4534-901e-1d6d2e91a73a/ugc/312219c3-f7b1-4d1e-9569-5cdb0a81675a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '312219c3-f7b1-4d1e-9569-5cdb0a81675a'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-08-09T16:23:42.759Z',
          createdAt: '2023-08-08T17:30:56.546Z',
          content: [
            {
              id: '3ce78816-da63-4861-9638-8e2989e24494',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/90b96e6d-4192-4534-901e-1d6d2e91a73a/ugc/1006ef40-6562-4df1-9fc4-dfeb1214d379/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1006ef40-6562-4df1-9fc4-dfeb1214d379'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '90b96e6d-4192-4534-901e-1d6d2e91a73a',
        firstName: 'Meg',
        lastName: 'Blasi',
        email: 'megblasi@yahoo.com',
        instagramProfile: {
          handle: 'megblasi',
          followers: 5040,
          engagementRate: 6.797619047619048,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'megblasi',
          followers: 203,
          engagementRate: 0.141643059490085,
          isPrimary: false
        }
      },
      userCampaignId: '90b96e6d-4192-4534-901e-1d6d2e91a73a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T21:06:11.000Z',
        amountCents: 2303
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T16:18:24.002Z',
          createdAt: '2023-08-18T02:28:44.983Z',
          content: [
            {
              id: 'ea030f37-fc00-4b86-b23c-2fa0e81beffa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4745363c-dd99-4c60-9171-04baf24da1ea/ugc/ed6bb83f-7f9b-4274-83ba-901b7e1695d9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2d27116b-0cea-4cfb-9cd6-96d378fdb00b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4745363c-dd99-4c60-9171-04baf24da1ea/ugc/ed6bb83f-7f9b-4274-83ba-901b7e1695d9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1c733965-708f-4b8e-858f-632f39e5a4c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4745363c-dd99-4c60-9171-04baf24da1ea/ugc/ed6bb83f-7f9b-4274-83ba-901b7e1695d9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ed6bb83f-7f9b-4274-83ba-901b7e1695d9'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T16:18:19.497Z',
          createdAt: '2023-08-18T02:27:29.615Z',
          content: [
            {
              id: '00f028b0-559b-4feb-bd39-22aca8ffe04e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4745363c-dd99-4c60-9171-04baf24da1ea/ugc/bd744899-5e6e-45f3-9fa1-acd80aa06358/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bd744899-5e6e-45f3-9fa1-acd80aa06358'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4745363c-dd99-4c60-9171-04baf24da1ea',
        firstName: 'Alyssa',
        lastName: 'Kunz',
        email: 'alyssasentore@gmail.com',
        instagramProfile: {
          handle: 'blessed_mama_life',
          followers: 495,
          engagementRate: 3.111111111111111,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'blessed_mama_life_',
          followers: 8207,
          engagementRate: 22.8486646884273,
          isPrimary: true
        }
      },
      userCampaignId: '4745363c-dd99-4c60-9171-04baf24da1ea'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T19:23:00.000Z',
        amountCents: 1578
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T21:27:00.396Z',
          createdAt: '2023-08-20T01:30:52.961Z',
          content: [
            {
              id: '3b72da29-80ee-4eac-b71a-ec5507e57394',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43772e-c7e6-4fd0-9065-87b13eb46c47/ugc/a66a370b-335c-4b3c-8bee-1dddbddaf875/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a66a370b-335c-4b3c-8bee-1dddbddaf875'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T21:26:55.200Z',
          createdAt: '2023-08-20T01:34:07.515Z',
          content: [
            {
              id: '0ba89d5f-4f6b-4d31-91ae-0d70eff3ad86',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43772e-c7e6-4fd0-9065-87b13eb46c47/ugc/a1917552-7795-4871-b2b6-2a9de5b05bfb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '735e47f8-d669-4aa6-ba1b-e0ca221c84d5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43772e-c7e6-4fd0-9065-87b13eb46c47/ugc/a1917552-7795-4871-b2b6-2a9de5b05bfb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a255de53-0386-4e56-8447-b6da7389472b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43772e-c7e6-4fd0-9065-87b13eb46c47/ugc/a1917552-7795-4871-b2b6-2a9de5b05bfb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a1917552-7795-4871-b2b6-2a9de5b05bfb'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0f43772e-c7e6-4fd0-9065-87b13eb46c47',
        firstName: 'Ashley',
        lastName: 'Stanton',
        email: 'ashleyxnicholex@gmail.com',
        instagramProfile: {
          handle: 'ashleyxnicholex',
          followers: 10603,
          engagementRate: 0.28388192021126096,
          isPrimary: true
        }
      },
      userCampaignId: '0f43772e-c7e6-4fd0-9065-87b13eb46c47'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T19:16:41.000Z',
        amountCents: 1593
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T16:34:45.808Z',
          createdAt: '2023-08-14T03:04:54.789Z',
          content: [
            {
              id: '17b50d2f-a55f-438e-80a1-d8204bcd4f8a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697892fe-3a06-413f-88b6-f005974281df/ugc/a539e393-4373-4e33-8d41-dd845152adf4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a539e393-4373-4e33-8d41-dd845152adf4'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T18:35:27.656Z',
          createdAt: '2023-08-14T02:42:09.331Z',
          content: [
            {
              id: '107872ce-caa5-407d-836c-9ba298f5de11',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697892fe-3a06-413f-88b6-f005974281df/ugc/b9aab9bc-930b-4e55-9ed7-1f3aaa0efcfd/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5e8c23b9-2588-4b49-9220-493b26b0a4de',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697892fe-3a06-413f-88b6-f005974281df/ugc/b9aab9bc-930b-4e55-9ed7-1f3aaa0efcfd/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '37015f89-f436-4869-97a8-52e54163455c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697892fe-3a06-413f-88b6-f005974281df/ugc/b9aab9bc-930b-4e55-9ed7-1f3aaa0efcfd/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b9aab9bc-930b-4e55-9ed7-1f3aaa0efcfd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '697892fe-3a06-413f-88b6-f005974281df',
        firstName: 'Aditi',
        lastName: 'Agarwal',
        email: 'beingfrugalmom@gmail.com',
        instagramProfile: {
          handle: 'being_frugal',
          followers: 83069,
          engagementRate: 1.976068087975057,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'being_frugal',
          followers: 6619,
          engagementRate: 11.789516635708042,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'aditiscreativehub',
          followers: 11
        }
      },
      userCampaignId: '697892fe-3a06-413f-88b6-f005974281df'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-06T21:46:25.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T23:25:56.011Z',
          createdAt: '2023-08-13T18:58:11.045Z',
          content: [
            {
              id: 'ce9bef47-7b92-4cd4-a751-1b7bffe621a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5/ugc/0e01ee42-5280-4178-9577-fec8e4c2fc18/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b5dd6c13-984a-442d-85db-cb12074e64e6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5/ugc/0e01ee42-5280-4178-9577-fec8e4c2fc18/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c6f940f1-130b-4187-bfe3-c8394aa40805',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5/ugc/0e01ee42-5280-4178-9577-fec8e4c2fc18/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0e01ee42-5280-4178-9577-fec8e4c2fc18'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T23:25:48.117Z',
          createdAt: '2023-08-13T18:57:30.795Z',
          content: [
            {
              id: '72ed8a71-4b74-4bb7-ac56-729129382006',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5/ugc/6498e138-175e-4864-870c-6ea843e9a3d0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6498e138-175e-4864-870c-6ea843e9a3d0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5',
        firstName: 'Emily',
        lastName: 'Spencer',
        email: 'emilyandcody@gmail.com',
        instagramProfile: {
          handle: 'lifewith.emileee',
          followers: 6389,
          engagementRate: 0.8045077476913444,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lifewithemileee',
          followers: 393,
          engagementRate: 6.986027944111776,
          isPrimary: false
        }
      },
      userCampaignId: '3cbf1288-f986-4e44-9e3f-4eaa0ff05fc5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T19:49:05.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T03:12:32.605Z',
          createdAt: '2023-08-18T22:38:13.363Z',
          content: [
            {
              id: 'aa983c1d-47b8-4fd2-b215-c5f5fb8c7400',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c55f2d3-de48-44b6-9d12-c27c153830b3/ugc/26cde772-8cfa-429c-96f3-2c9e2058cfcd/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '370c56cb-533f-4c05-8c76-3e376c087729',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c55f2d3-de48-44b6-9d12-c27c153830b3/ugc/26cde772-8cfa-429c-96f3-2c9e2058cfcd/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '27f7af41-3e48-411e-9f47-7da913c03515',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c55f2d3-de48-44b6-9d12-c27c153830b3/ugc/26cde772-8cfa-429c-96f3-2c9e2058cfcd/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9df3a950-7953-417f-9aa1-7463158a0feb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c55f2d3-de48-44b6-9d12-c27c153830b3/ugc/26cde772-8cfa-429c-96f3-2c9e2058cfcd/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '26cde772-8cfa-429c-96f3-2c9e2058cfcd'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T00:42:10.516Z',
          createdAt: '2023-08-18T22:18:05.848Z',
          content: [
            {
              id: '292e839b-7f85-4958-a798-84dafb3c4607',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c55f2d3-de48-44b6-9d12-c27c153830b3/ugc/48857f01-54f4-4912-8c54-89e47ee76ec3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '48857f01-54f4-4912-8c54-89e47ee76ec3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1c55f2d3-de48-44b6-9d12-c27c153830b3',
        firstName: 'Chelsea',
        lastName: 'Greer',
        email: 'chelsea757@gmail.com',
        instagramProfile: {
          handle: 'chelsealizgreer',
          followers: 8772,
          engagementRate: 3.6867305061559503,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chelsealizgreer',
          followers: 177,
          engagementRate: 5.839416058394161,
          isPrimary: true
        }
      },
      userCampaignId: '1c55f2d3-de48-44b6-9d12-c27c153830b3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T18:12:00.000Z',
        amountCents: 1600
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T02:06:46.717Z',
          createdAt: '2023-08-14T18:54:38.338Z',
          content: [
            {
              id: 'f9927f9d-acad-4182-b9a3-89027b1c8745',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca4e01d1-1984-4171-80c7-58b48f36a34e/ugc/f2009693-d9ee-47f2-bb44-d40be4fa0f4f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '97f62428-83c2-45b1-8aea-1fe7e398ebfe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca4e01d1-1984-4171-80c7-58b48f36a34e/ugc/f2009693-d9ee-47f2-bb44-d40be4fa0f4f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a975e03b-69ad-4ee2-b9b2-4f9a59246588',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca4e01d1-1984-4171-80c7-58b48f36a34e/ugc/f2009693-d9ee-47f2-bb44-d40be4fa0f4f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f2009693-d9ee-47f2-bb44-d40be4fa0f4f'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T22:36:38.588Z',
          createdAt: '2023-08-14T18:53:39.467Z',
          content: [
            {
              id: '54ede141-f0b1-493d-af64-77182b772431',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca4e01d1-1984-4171-80c7-58b48f36a34e/ugc/4689d64d-b36f-4b0e-8bc9-39b9d259320d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4689d64d-b36f-4b0e-8bc9-39b9d259320d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ca4e01d1-1984-4171-80c7-58b48f36a34e',
        firstName: 'Lexi',
        lastName: 'Kornbluth',
        email: 'reality1momma@gmail.com',
        instagramProfile: {
          handle: 'reality_momma',
          followers: 804,
          engagementRate: 0.9825870646766168,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'reality_momma',
          followers: 6,
          isPrimary: true
        }
      },
      userCampaignId: 'ca4e01d1-1984-4171-80c7-58b48f36a34e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T23:25:03.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T15:49:34.207Z',
          createdAt: '2023-08-20T18:13:44.841Z',
          content: [
            {
              id: 'e0599985-8ece-4cff-aced-170b33795c07',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/44579959-f343-487c-8d36-cf8347751346/ugc/3a6be72f-5634-4a4a-874c-662ed5ceb3ed/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'da6e4e6e-b5b3-48dd-823a-8816eba42980',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/44579959-f343-487c-8d36-cf8347751346/ugc/3a6be72f-5634-4a4a-874c-662ed5ceb3ed/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '83735107-5141-486a-98df-5434d23e8132',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/44579959-f343-487c-8d36-cf8347751346/ugc/3a6be72f-5634-4a4a-874c-662ed5ceb3ed/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3a6be72f-5634-4a4a-874c-662ed5ceb3ed'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T15:49:29.753Z',
          createdAt: '2023-08-22T00:02:16.517Z',
          content: [
            {
              id: '9538e3fa-a364-42df-b900-e31f96733c44',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/44579959-f343-487c-8d36-cf8347751346/ugc/ddeda8c5-2264-46ae-bae6-bacf4013524a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ddeda8c5-2264-46ae-bae6-bacf4013524a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '44579959-f343-487c-8d36-cf8347751346',
        firstName: 'Katie',
        lastName: 'Centers',
        email: 'inlifeandlittles@gmail.com',
        instagramProfile: {
          handle: 'inlifeandlittles',
          followers: 4283,
          engagementRate: 1.1183749708148494,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'inlifeandlittles',
          followers: 61,
          isPrimary: false
        }
      },
      userCampaignId: '44579959-f343-487c-8d36-cf8347751346'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T19:35:28.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T16:52:57.199Z',
          createdAt: '2023-08-15T13:28:15.266Z',
          content: [
            {
              id: '86240cfd-0957-4561-a740-c7cb14f041e1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/71b8e4c7-8d7b-4afb-a441-180fdfbb14d1/ugc/7ba6d493-1b63-4241-8742-4c6f26fce672/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cedb9b7d-3844-4200-bc07-c49f7a9df21f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/71b8e4c7-8d7b-4afb-a441-180fdfbb14d1/ugc/7ba6d493-1b63-4241-8742-4c6f26fce672/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '38301bbf-717b-42ac-bc75-37eb35245d56',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/71b8e4c7-8d7b-4afb-a441-180fdfbb14d1/ugc/7ba6d493-1b63-4241-8742-4c6f26fce672/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7ba6d493-1b63-4241-8742-4c6f26fce672'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T16:52:52.088Z',
          createdAt: '2023-08-15T13:44:33.539Z',
          content: [
            {
              id: '63cc2596-78dc-4545-ba33-05e564d29ae5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/71b8e4c7-8d7b-4afb-a441-180fdfbb14d1/ugc/f3f79ee8-85d3-4cf0-bae5-4724223f76c2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f3f79ee8-85d3-4cf0-bae5-4724223f76c2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '71b8e4c7-8d7b-4afb-a441-180fdfbb14d1',
        firstName: 'Jenna',
        lastName: 'Britt',
        email: 'jennabrittugc@gmail.com',
        instagramProfile: {
          handle: 'jennalbritt',
          followers: 3690,
          engagementRate: 3.7994579945799454,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jenlynneblog',
          followers: 2240,
          engagementRate: 1.1721630567131416,
          isPrimary: true
        }
      },
      userCampaignId: '71b8e4c7-8d7b-4afb-a441-180fdfbb14d1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T08:25:42.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:43:22.488Z',
          createdAt: '2023-08-17T19:27:44.970Z',
          content: [
            {
              id: 'c590b524-ba22-45ee-9f17-ba540fd3559e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1003667-a587-43dc-9a51-dd6c6ba863fc/ugc/a01d3850-38d2-4a71-a3df-f79cbdf58db1/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f7a0cb77-4cf1-43a9-8f44-546be692df74',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1003667-a587-43dc-9a51-dd6c6ba863fc/ugc/a01d3850-38d2-4a71-a3df-f79cbdf58db1/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4cfe848e-2835-44fe-a003-a01cb82bb05b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1003667-a587-43dc-9a51-dd6c6ba863fc/ugc/a01d3850-38d2-4a71-a3df-f79cbdf58db1/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '415d99d6-e5cd-4075-9611-b214f1aad7fc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1003667-a587-43dc-9a51-dd6c6ba863fc/ugc/a01d3850-38d2-4a71-a3df-f79cbdf58db1/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a01d3850-38d2-4a71-a3df-f79cbdf58db1'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T20:20:17.922Z',
          createdAt: '2023-08-17T20:14:00.734Z',
          content: [
            {
              id: '0bb0c3ba-ad40-49f7-b1c9-901c597fae8e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1003667-a587-43dc-9a51-dd6c6ba863fc/ugc/04e39ffc-aeef-4b64-bd19-734077e6f737/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '04e39ffc-aeef-4b64-bd19-734077e6f737'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e1003667-a587-43dc-9a51-dd6c6ba863fc',
        firstName: 'Ashley',
        lastName: 'Dills',
        email: 'ashleydills28@yahoo.com',
        instagramProfile: {
          handle: 'ashley__dills',
          followers: 1491,
          engagementRate: 3.86317907444668,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ashley__dills',
          followers: 368,
          isPrimary: true
        }
      },
      userCampaignId: 'e1003667-a587-43dc-9a51-dd6c6ba863fc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T19:56:42.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T13:35:41.831Z',
          createdAt: '2023-08-16T15:54:34.366Z',
          content: [
            {
              id: 'd3169914-b98d-4fdb-92f1-366a33a781dd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250/ugc/18617ba0-7aa1-473f-8059-32248f2a151d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '18617ba0-7aa1-473f-8059-32248f2a151d'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T18:18:58.694Z',
          createdAt: '2023-08-14T18:05:43.890Z',
          content: [
            {
              id: '9af1fdd5-0a03-40cb-8629-67d4cdb19fe8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250/ugc/c2129436-1f52-4dd2-93f4-84a5d98ddbc2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7d2f20ee-dd97-4054-96ff-9d530dbc7f9c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250/ugc/c2129436-1f52-4dd2-93f4-84a5d98ddbc2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a037c055-24e6-403b-8c3d-b8eab55b4175',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250/ugc/c2129436-1f52-4dd2-93f4-84a5d98ddbc2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c2129436-1f52-4dd2-93f4-84a5d98ddbc2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250',
        firstName: 'Allie',
        lastName: 'McKithen',
        email: 'alliemckithen@outlook.com',
        instagramProfile: {
          handle: 'allie.mckithen',
          followers: 538,
          engagementRate: 2.6394052044609664,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'allie.mckithen',
          followers: 19598,
          engagementRate: 12.127060126376133,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'alliemckithen'
        }
      },
      userCampaignId: 'f2cf0b6e-1216-4c5c-adb2-0af7eb3b6250'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T20:05:08.000Z',
        amountCents: 1593
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:56.706Z',
          createdAt: '2023-08-11T15:18:02.107Z',
          content: [
            {
              id: 'bc2f7738-dcd7-46be-a100-ef2b214ee3ed',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/33f3f81d-31f8-4d40-9952-0d17263819cd/ugc/16910b14-b344-440f-a055-ede478b095ee/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6719f777-762e-440f-bda1-f63755bf53e3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/33f3f81d-31f8-4d40-9952-0d17263819cd/ugc/16910b14-b344-440f-a055-ede478b095ee/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '24a4abc1-8074-48ef-88bf-f85e5665cf59',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/33f3f81d-31f8-4d40-9952-0d17263819cd/ugc/16910b14-b344-440f-a055-ede478b095ee/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '16910b14-b344-440f-a055-ede478b095ee'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:46.862Z',
          createdAt: '2023-08-10T23:04:03.058Z',
          content: [
            {
              id: '9492e846-fee0-47fb-ba34-00deabc97b57',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/33f3f81d-31f8-4d40-9952-0d17263819cd/ugc/a4167594-3a2d-4bd6-b39a-c672d283ef4f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a4167594-3a2d-4bd6-b39a-c672d283ef4f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '33f3f81d-31f8-4d40-9952-0d17263819cd',
        firstName: 'Marissa',
        lastName: 'Brogan',
        email: 'marissa.brogan@gmail.com',
        instagramProfile: {
          handle: 'marissa_brogan',
          followers: 2698,
          engagementRate: 0.37064492216456635,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'marissabrogan',
          followers: 284,
          engagementRate: 15.18324607329843,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'marissabrogan'
        }
      },
      userCampaignId: '33f3f81d-31f8-4d40-9952-0d17263819cd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T18:43:41.000Z',
        amountCents: 1608
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:22:16.112Z',
          createdAt: '2023-08-12T19:36:42.489Z',
          content: [
            {
              id: '37c7e6bb-1b62-4ee6-8b25-e2770cf7c556',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0a49b39a-7937-4386-9329-82a4785ba2d0/ugc/ee7ab88a-adb7-4a68-a570-b41ddee9c0f8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7f0694a5-fa66-47a6-881f-768d1ce84e91',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0a49b39a-7937-4386-9329-82a4785ba2d0/ugc/ee7ab88a-adb7-4a68-a570-b41ddee9c0f8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '19665c07-cf90-45fc-9db9-059c6ea6927e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0a49b39a-7937-4386-9329-82a4785ba2d0/ugc/ee7ab88a-adb7-4a68-a570-b41ddee9c0f8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ee7ab88a-adb7-4a68-a570-b41ddee9c0f8'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T01:29:30.394Z',
          createdAt: '2023-08-12T19:46:21.919Z',
          content: [
            {
              id: 'b8caa579-ba1d-4b8d-9111-7af3540cc112',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0a49b39a-7937-4386-9329-82a4785ba2d0/ugc/753a0f26-0dc3-4515-a7bc-402f1505554c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '753a0f26-0dc3-4515-a7bc-402f1505554c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0a49b39a-7937-4386-9329-82a4785ba2d0',
        firstName: 'Pamela',
        lastName: 'Todd',
        email: 'pmtodd119@gmail.com',
        instagramProfile: {
          handle: 'pammtodd19',
          followers: 2467,
          engagementRate: 1.9456830158086746,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'pammtodd',
          followers: 1651,
          engagementRate: 3.0120481927710845,
          isPrimary: true
        }
      },
      userCampaignId: '0a49b39a-7937-4386-9329-82a4785ba2d0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T16:33:59.000Z',
        amountCents: 1611
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-10T16:02:45.879Z',
          createdAt: '2023-08-10T04:49:12.745Z',
          content: [
            {
              id: 'eccf7cd4-e478-41ae-b83e-de0b59114fcb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f9c384eb-b135-43e1-939f-dd5f345d4345/ugc/8a66e221-38ed-4967-97c7-eab77304da60/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8a66e221-38ed-4967-97c7-eab77304da60'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-10T16:02:42.310Z',
          createdAt: '2023-08-10T04:36:57.374Z',
          content: [
            {
              id: '1487ac45-dadd-4564-9cd1-56ae5a658ccc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f9c384eb-b135-43e1-939f-dd5f345d4345/ugc/01bb225b-56b3-484c-9d45-052751b40e8e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4cc56fcd-ac2b-49af-83c1-4bafa82b7e28',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f9c384eb-b135-43e1-939f-dd5f345d4345/ugc/01bb225b-56b3-484c-9d45-052751b40e8e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ecaa7d0b-6e3b-4e4f-a957-619210d7188a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f9c384eb-b135-43e1-939f-dd5f345d4345/ugc/01bb225b-56b3-484c-9d45-052751b40e8e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '01bb225b-56b3-484c-9d45-052751b40e8e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f9c384eb-b135-43e1-939f-dd5f345d4345',
        firstName: 'Laura',
        lastName: 'Gargani',
        email: 'lauragargani@icloud.com',
        instagramProfile: {
          handle: 'laura.gargani',
          followers: 336,
          engagementRate: 3.162202380952381,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thegentlemommy',
          followers: 15266,
          engagementRate: 2.3510971786833856,
          isPrimary: true
        }
      },
      userCampaignId: 'f9c384eb-b135-43e1-939f-dd5f345d4345'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T16:14:11.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T14:30:44.396Z',
          createdAt: '2023-08-22T01:34:58.037Z',
          content: [
            {
              id: '2f1cdddb-e279-4137-aeb2-12e45113d2d9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e53f148-4ce4-40fd-919b-0dda09681745/ugc/d6e3e772-5968-48c4-99bd-b32b561f554e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3e8fc0da-f7d9-47dd-8668-4eaa93b417dd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e53f148-4ce4-40fd-919b-0dda09681745/ugc/d6e3e772-5968-48c4-99bd-b32b561f554e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '812bcc65-df29-4da8-93a1-e7b9ed08e010',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e53f148-4ce4-40fd-919b-0dda09681745/ugc/d6e3e772-5968-48c4-99bd-b32b561f554e/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd350cb08-b59c-4fb2-a4dc-8ea427b67dc2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e53f148-4ce4-40fd-919b-0dda09681745/ugc/d6e3e772-5968-48c4-99bd-b32b561f554e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd6e3e772-5968-48c4-99bd-b32b561f554e'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T14:30:40.148Z',
          createdAt: '2023-08-22T03:30:27.850Z',
          content: [
            {
              id: '278f26e9-49b2-4833-9965-7951962bff41',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e53f148-4ce4-40fd-919b-0dda09681745/ugc/e5f8fbf9-f7a0-46b4-a7db-1abf8cdac0b9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e5f8fbf9-f7a0-46b4-a7db-1abf8cdac0b9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1e53f148-4ce4-40fd-919b-0dda09681745',
        firstName: 'Rachelle',
        lastName: 'Farquhar',
        email: 'farquharboys@gmail.com',
        instagramProfile: {
          handle: '_james.family',
          followers: 12408,
          engagementRate: 3.0375564152159895,
          isPrimary: true
        }
      },
      userCampaignId: '1e53f148-4ce4-40fd-919b-0dda09681745'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T00:16:34.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T01:09:46.208Z',
          createdAt: '2023-08-14T22:47:13.660Z',
          content: [
            {
              id: '8bd4ba49-0eb9-488f-a154-1939e43af408',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/abacdb1e-81ff-47da-a26a-0d83d5835fb3/ugc/b0e1fe28-3fc1-46f4-9a88-c887787984e0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0a857caa-feb9-4c76-9405-da6cd462d0aa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/abacdb1e-81ff-47da-a26a-0d83d5835fb3/ugc/b0e1fe28-3fc1-46f4-9a88-c887787984e0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f70feab5-ffa6-441f-a9de-0feda7e4cd96',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/abacdb1e-81ff-47da-a26a-0d83d5835fb3/ugc/b0e1fe28-3fc1-46f4-9a88-c887787984e0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b0e1fe28-3fc1-46f4-9a88-c887787984e0'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T21:46:43.473Z',
          createdAt: '2023-08-16T01:09:37.485Z',
          content: [
            {
              id: '38fe3b3a-d1eb-463c-9d0f-14b6d2c10acb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/abacdb1e-81ff-47da-a26a-0d83d5835fb3/ugc/49161ef7-3b8e-4444-bcde-8c265d9cfdf7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '49161ef7-3b8e-4444-bcde-8c265d9cfdf7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'abacdb1e-81ff-47da-a26a-0d83d5835fb3',
        firstName: 'alicia',
        lastName: 'moore',
        email: 'moorealicia309@yahoo.com',
        instagramProfile: {
          handle: 'alicia.nicole_',
          followers: 797,
          engagementRate: 7.590966122961104,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alicianicole_',
          followers: 4840,
          engagementRate: 11.144377910844977,
          isPrimary: true
        }
      },
      userCampaignId: 'abacdb1e-81ff-47da-a26a-0d83d5835fb3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T17:04:18.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T14:44:47.227Z',
          createdAt: '2023-08-15T17:53:25.824Z',
          content: [
            {
              id: '68b149ea-233c-4bf1-9ee6-f19fb46b0024',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eac31ac9-d0cc-41ab-a29d-faa95b28c5c6/ugc/5a8c798c-d31f-4d46-8d1c-87ec25040b2b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8831dd37-e7ba-4324-9a4c-f0e9c4574841',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eac31ac9-d0cc-41ab-a29d-faa95b28c5c6/ugc/5a8c798c-d31f-4d46-8d1c-87ec25040b2b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '38808a7e-6097-4bd6-afa4-491ad6a94b27',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eac31ac9-d0cc-41ab-a29d-faa95b28c5c6/ugc/5a8c798c-d31f-4d46-8d1c-87ec25040b2b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5a8c798c-d31f-4d46-8d1c-87ec25040b2b'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T21:39:39.154Z',
          createdAt: '2023-08-15T22:53:18.430Z',
          content: [
            {
              id: 'dd1b6bf9-5031-4815-b8be-f6933c9a4fe0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/eac31ac9-d0cc-41ab-a29d-faa95b28c5c6/ugc/8b160f83-5b60-496f-884a-ae06d38e72b6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8b160f83-5b60-496f-884a-ae06d38e72b6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'eac31ac9-d0cc-41ab-a29d-faa95b28c5c6',
        firstName: 'Racquel',
        lastName: 'Smith',
        email: 'quelstylz@yahoo.com',
        instagramProfile: {
          handle: 'quelstylz',
          followers: 6757,
          engagementRate: 2.761580583099008,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'quelstylz',
          followers: 2149,
          engagementRate: 14.704484554325292,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'quelstylz'
        }
      },
      userCampaignId: 'eac31ac9-d0cc-41ab-a29d-faa95b28c5c6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T13:30:25.000Z',
        amountCents: 1608
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T21:34:23.817Z',
          createdAt: '2023-08-30T01:55:40.066Z',
          content: [
            {
              id: '4a6be007-4d29-43d5-9366-19bc4fa6797f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0ff2ad1f-247c-4442-b23b-44c445a8be38/ugc/cc6ed67b-06cc-4020-ad2a-5351f2372ee2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7465aa3e-f95a-4c4a-839e-8b9c614c4968',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0ff2ad1f-247c-4442-b23b-44c445a8be38/ugc/cc6ed67b-06cc-4020-ad2a-5351f2372ee2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0fb65a0b-6cfd-434c-b3d8-c767af6bd4bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0ff2ad1f-247c-4442-b23b-44c445a8be38/ugc/cc6ed67b-06cc-4020-ad2a-5351f2372ee2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cc6ed67b-06cc-4020-ad2a-5351f2372ee2'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T21:34:18.997Z',
          createdAt: '2023-09-01T17:34:15.250Z',
          content: [
            {
              id: 'f4bc92cd-4bdb-40a2-9e8b-74419a6ae0c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0ff2ad1f-247c-4442-b23b-44c445a8be38/ugc/76efe95f-4a9e-4d80-ac76-6c36f24eb223/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '76efe95f-4a9e-4d80-ac76-6c36f24eb223'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0ff2ad1f-247c-4442-b23b-44c445a8be38',
        firstName: 'Kaila',
        lastName: 'DeRienzo',
        email: 'kderienzomedia@gmail.com',
        instagramProfile: {
          handle: 'kailaderienzo',
          followers: 3305,
          engagementRate: 1.6580937972768532,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kailaderienzo',
          followers: 1309,
          engagementRate: 10.123136203719216,
          isPrimary: false
        }
      },
      userCampaignId: '0ff2ad1f-247c-4442-b23b-44c445a8be38'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T01:43:23.000Z',
        amountCents: 2330
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:15.655Z',
          createdAt: '2023-08-24T17:36:47.025Z',
          content: [
            {
              id: '374a5b85-3898-43f9-b51e-6e61ce745bf0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56890b7f-3042-43bc-a18d-ec765b948a8f/ugc/9758b7a1-620e-417a-998b-804f9f953dfb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '07ac45ba-912d-45d4-91bd-e4d607b4a437',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56890b7f-3042-43bc-a18d-ec765b948a8f/ugc/9758b7a1-620e-417a-998b-804f9f953dfb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1b38dc52-437f-4343-b1f5-acd27c5a0eed',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56890b7f-3042-43bc-a18d-ec765b948a8f/ugc/9758b7a1-620e-417a-998b-804f9f953dfb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9758b7a1-620e-417a-998b-804f9f953dfb'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:22.264Z',
          createdAt: '2023-08-23T17:13:42.825Z',
          content: [
            {
              id: 'f6a124b1-1745-451f-a89f-350039025f62',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56890b7f-3042-43bc-a18d-ec765b948a8f/ugc/5f0048bb-a2cb-4c77-84d9-f639085bf1d9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5f0048bb-a2cb-4c77-84d9-f639085bf1d9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '56890b7f-3042-43bc-a18d-ec765b948a8f',
        firstName: 'Megan',
        lastName: 'Simpkins',
        email: 'smickx14@gmail.com',
        instagramProfile: {
          handle: 'megs.lifepodcast',
          followers: 314,
          engagementRate: 2.2611464968152863,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'megslifepodcast',
          followers: 657,
          engagementRate: 2.8925619834710745,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'smickx96',
          followers: 437
        }
      },
      userCampaignId: '56890b7f-3042-43bc-a18d-ec765b948a8f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T01:34:04.000Z',
        amountCents: 1600
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T21:16:14.206Z',
          createdAt: '2023-08-23T17:22:00.148Z',
          content: [
            {
              id: 'bd179cda-ed6c-4751-a49c-fefad9d46650',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0865b2d-d06e-4664-bef8-3b091c42ce4a/ugc/08bbbcde-8e82-45ec-9826-f90d1e60abfa/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5af06e0a-004d-46b8-aa96-d0d20c0f4975',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0865b2d-d06e-4664-bef8-3b091c42ce4a/ugc/08bbbcde-8e82-45ec-9826-f90d1e60abfa/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ba5476ee-1a77-4e1e-a309-b0810c758419',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0865b2d-d06e-4664-bef8-3b091c42ce4a/ugc/08bbbcde-8e82-45ec-9826-f90d1e60abfa/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '08bbbcde-8e82-45ec-9826-f90d1e60abfa'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T13:43:27.055Z',
          createdAt: '2023-08-23T18:14:47.171Z',
          content: [
            {
              id: '78287a8a-73d5-4fd0-b2e6-65bfbe1d614a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0865b2d-d06e-4664-bef8-3b091c42ce4a/ugc/5f5d6812-02ee-462b-8280-645a485438cd/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5f5d6812-02ee-462b-8280-645a485438cd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e0865b2d-d06e-4664-bef8-3b091c42ce4a',
        firstName: 'Danielle',
        lastName: 'Ridgway',
        email: 'danii_ridgway@yahoo.com',
        instagramProfile: {
          handle: 'greyskies.rainbowhighs',
          followers: 5119,
          engagementRate: 14.536042195741356,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'danii.ridgway',
          followers: 1482,
          engagementRate: 2.8742644983390147,
          isPrimary: true
        }
      },
      userCampaignId: 'e0865b2d-d06e-4664-bef8-3b091c42ce4a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T21:06:26.000Z',
        amountCents: 1611
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T18:48:10.519Z',
          createdAt: '2023-08-16T14:44:12.023Z',
          content: [
            {
              id: 'c5332361-4c4a-43a5-89fa-4bebf6e04cb4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d97a28f6-bd85-4f83-9b6b-5e18ac93d26f/ugc/5c295be0-a918-4b28-8cde-471f508c2295/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5c295be0-a918-4b28-8cde-471f508c2295'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T20:06:21.016Z',
          createdAt: '2023-08-15T15:16:06.083Z',
          content: [
            {
              id: 'bf6e1918-6341-44f8-9874-c94ed26b1da5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d97a28f6-bd85-4f83-9b6b-5e18ac93d26f/ugc/938342d4-d2dd-4717-a2d0-ccbfb4c1d5ee/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8ceab7b7-009a-4167-b3df-b2e85cfa1d6e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d97a28f6-bd85-4f83-9b6b-5e18ac93d26f/ugc/938342d4-d2dd-4717-a2d0-ccbfb4c1d5ee/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7b56773e-ab80-4b98-8ea6-6947ad62b5e1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d97a28f6-bd85-4f83-9b6b-5e18ac93d26f/ugc/938342d4-d2dd-4717-a2d0-ccbfb4c1d5ee/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '938342d4-d2dd-4717-a2d0-ccbfb4c1d5ee'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd97a28f6-bd85-4f83-9b6b-5e18ac93d26f',
        firstName: 'Taylor',
        lastName: 'Champion',
        email: 'taylorchampion8865@gmail.com',
        instagramProfile: {
          handle: 'tayyy_champion',
          followers: 1273,
          engagementRate: 93.76276512175961,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lastnamechampionnn',
          followers: 65443,
          engagementRate: 10.678531701890991,
          isPrimary: true
        }
      },
      userCampaignId: 'd97a28f6-bd85-4f83-9b6b-5e18ac93d26f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T18:23:35.000Z',
        amountCents: 2368
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T13:58:46.129Z',
          createdAt: '2023-08-31T18:37:04.868Z',
          content: [
            {
              id: '16ea3c55-e3d7-44d3-b58c-10becac0b814',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9f44a9e9-239f-43f0-813d-2497f0446c1f/ugc/d0d4e1a2-4917-4d41-8c73-930551e64145/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2787606f-d8cc-44da-9b39-d9bf8ca15f40',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9f44a9e9-239f-43f0-813d-2497f0446c1f/ugc/d0d4e1a2-4917-4d41-8c73-930551e64145/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '56bfd010-b1c6-4653-bfc1-9c18f7b94d40',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9f44a9e9-239f-43f0-813d-2497f0446c1f/ugc/d0d4e1a2-4917-4d41-8c73-930551e64145/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd0d4e1a2-4917-4d41-8c73-930551e64145'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T18:15:12.336Z',
          createdAt: '2023-09-04T17:41:11.196Z',
          content: [
            {
              id: '11577dc3-56d2-4e20-989c-c0decb9aa56d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9f44a9e9-239f-43f0-813d-2497f0446c1f/ugc/0e387fe5-20c9-490c-bc9d-18254976df34/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0e387fe5-20c9-490c-bc9d-18254976df34'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '9f44a9e9-239f-43f0-813d-2497f0446c1f',
        firstName: 'Michaela',
        lastName: 'Zahradnikova',
        email: 'theblondeandco1@gmail.com',
        instagramProfile: {
          handle: 'the.blonde.and.co',
          followers: 9888,
          engagementRate: 2.8367718446601944,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'theblondeandco',
          followers: 59,
          isPrimary: false
        }
      },
      userCampaignId: '9f44a9e9-239f-43f0-813d-2497f0446c1f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-06T19:53:34.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-09T16:19:41.255Z',
          createdAt: '2023-08-08T21:38:06.244Z',
          content: [
            {
              id: 'd7dc5895-353e-4f24-a600-7b895e542317',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4c39ba31-54f0-4580-b39f-482e56bc0e3a/ugc/1dd002f5-7e05-4187-97a1-df9bc43b1e81/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4bbcec93-0aa1-47e0-a857-295652a2609a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4c39ba31-54f0-4580-b39f-482e56bc0e3a/ugc/1dd002f5-7e05-4187-97a1-df9bc43b1e81/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f0b8e89e-69ef-4dd2-992e-3a2f661a5602',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4c39ba31-54f0-4580-b39f-482e56bc0e3a/ugc/1dd002f5-7e05-4187-97a1-df9bc43b1e81/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1dd002f5-7e05-4187-97a1-df9bc43b1e81'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-09T16:19:37.351Z',
          createdAt: '2023-08-08T22:23:48.892Z',
          content: [
            {
              id: 'e80e240e-a2da-475a-bf68-885729bd8df8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4c39ba31-54f0-4580-b39f-482e56bc0e3a/ugc/e0b34642-e575-4aaa-9fa5-f7ea52815a19/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e0b34642-e575-4aaa-9fa5-f7ea52815a19'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4c39ba31-54f0-4580-b39f-482e56bc0e3a',
        firstName: 'Shelby',
        lastName: 'Reyes',
        email: 'aislareyescollabs@gmail.com',
        instagramProfile: {
          handle: 'aisla.sky',
          followers: 13902,
          engagementRate: 0.7516904042583801,
          isPrimary: true
        }
      },
      userCampaignId: '4c39ba31-54f0-4580-b39f-482e56bc0e3a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T20:36:25.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T19:15:14.538Z',
          createdAt: '2023-08-29T03:38:26.053Z',
          content: [
            {
              id: 'd7e87c99-e1f7-4f23-9432-5c89e732075f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b1078f6b-38cb-4e61-b5a9-a5da7bc60b98/ugc/c19f2400-b87a-4f23-ab68-5ebbfc5f05ca/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '477d0797-dfe6-40dd-a79e-0909a6ef40a6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b1078f6b-38cb-4e61-b5a9-a5da7bc60b98/ugc/c19f2400-b87a-4f23-ab68-5ebbfc5f05ca/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '27715a34-7f28-4aa6-928a-87fc3d371a78',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b1078f6b-38cb-4e61-b5a9-a5da7bc60b98/ugc/c19f2400-b87a-4f23-ab68-5ebbfc5f05ca/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c19f2400-b87a-4f23-ab68-5ebbfc5f05ca'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T19:14:59.931Z',
          createdAt: '2023-08-29T03:20:57.480Z',
          content: [
            {
              id: '1ec44afa-40bb-49a6-81b4-65f57e81c1a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b1078f6b-38cb-4e61-b5a9-a5da7bc60b98/ugc/eb4c524e-716a-4b3d-ac74-ea2b82da64f3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'eb4c524e-716a-4b3d-ac74-ea2b82da64f3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b1078f6b-38cb-4e61-b5a9-a5da7bc60b98',
        firstName: 'Ashley',
        lastName: 'McGuire',
        email: 'mamiislas@yahoo.com',
        instagramProfile: {
          handle: 'mami.islas',
          followers: 1411,
          engagementRate: 1.715095676824947,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mamiislas',
          followers: 366,
          engagementRate: 7.720588235294118,
          isPrimary: true
        }
      },
      userCampaignId: 'b1078f6b-38cb-4e61-b5a9-a5da7bc60b98'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T19:26:26.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T17:50:51.124Z',
          createdAt: '2023-08-18T17:14:21.981Z',
          content: [
            {
              id: '98fd027f-4f72-4737-9ff9-1564d5deed00',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/887ae37f-2e16-443c-a3df-2fa43db87761/ugc/1d3f006e-ec8c-42fd-8907-700106d94807/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '71cf1348-5aff-4dc7-bf0e-ec181bd12b64',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/887ae37f-2e16-443c-a3df-2fa43db87761/ugc/1d3f006e-ec8c-42fd-8907-700106d94807/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '034173a0-5042-41df-9c1c-c5ca7e29f225',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/887ae37f-2e16-443c-a3df-2fa43db87761/ugc/1d3f006e-ec8c-42fd-8907-700106d94807/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1d3f006e-ec8c-42fd-8907-700106d94807'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T18:47:45.444Z',
          createdAt: '2023-08-18T20:14:39.306Z',
          content: [
            {
              id: '2391f57f-0b68-4fbd-a54d-f80cbaf572ad',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/887ae37f-2e16-443c-a3df-2fa43db87761/ugc/a06ce876-eb22-4ab4-9360-63a2e480d022/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a06ce876-eb22-4ab4-9360-63a2e480d022'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '887ae37f-2e16-443c-a3df-2fa43db87761',
        firstName: 'Samantha',
        lastName: 'Torres',
        email: 'ohhcooki3@gmail.com',
        instagramProfile: {
          handle: 'ohhcooki3',
          followers: 3203,
          engagementRate: 0.7586637527318139,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ohhcooki3',
          followers: 31706,
          engagementRate: 5.4146972411125525,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'ohhcooki3'
        }
      },
      userCampaignId: '887ae37f-2e16-443c-a3df-2fa43db87761'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T18:16:48.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'TT',
      content: [
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T05:00:00.000Z',
          createdAt: '2023-08-14T18:51:16.714Z',
          content: [
            {
              id: '6214871b-8285-4d4e-8e94-95c03342ffcf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/14c3fa7b-b669-4ea0-b968-4f614825bdf0/IS_REQUIRED/f5d21ed8-19bf-4982-9f16-6ecf4316bada/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '133f6c9a-e7af-4094-8d19-6f6cce0fe617'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T05:00:00.000Z',
          createdAt: '2023-08-14T18:52:43.854Z',
          content: [
            {
              id: '9e1b9d1d-392b-4b75-a3cf-33b6b42a2ca9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/14c3fa7b-b669-4ea0-b968-4f614825bdf0/IS_REQUIRED/e4bc505a-5366-4636-bfa5-a3a457968470/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1ada61ae-7bd1-4229-b2da-3ac1b3622b8d'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T18:15:40.000Z',
          createdAt: '2023-08-14T18:50:11.716Z',
          content: [
            {
              id: '3c62d927-8327-4562-b0d0-afa5402452ae',
              caption: '#ad #ad The best laundry hack to date! #brandpartner @armandhammerlaundry ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7270954083568192811/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 0,
              likes: 17,
              views: 268
            }
          ],
          groupId: 'd13846cb-0136-420d-8415-e2ff32813470'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '14c3fa7b-b669-4ea0-b968-4f614825bdf0',
        firstName: 'Tiffany',
        lastName: 'West',
        email: 'ugc.twest@gmail.com',
        instagramProfile: {
          handle: 'ugc.west',
          followers: 1186,
          engagementRate: 0.9527824620573355,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tiffanywest_',
          followers: 14881,
          engagementRate: 8.811897134880725,
          isPrimary: true
        }
      },
      userCampaignId: '14c3fa7b-b669-4ea0-b968-4f614825bdf0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T01:51:51.000Z',
        amountCents: 1608
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T03:55:52.406Z',
          createdAt: '2023-08-17T21:13:09.530Z',
          content: [
            {
              id: 'bd8bf9b2-ccd0-41f5-8ca2-55bd47667cb3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0fc414d4-abec-443a-a026-fbc881ff7a71/ugc/6f98a712-4cf4-4f2a-9f2c-b3daebeabf11/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '138da091-13b4-4a79-acbc-a11d450d21f2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0fc414d4-abec-443a-a026-fbc881ff7a71/ugc/6f98a712-4cf4-4f2a-9f2c-b3daebeabf11/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '21ca03fd-cd19-4a89-90d4-bfd4328838d0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0fc414d4-abec-443a-a026-fbc881ff7a71/ugc/6f98a712-4cf4-4f2a-9f2c-b3daebeabf11/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '26d9de04-1ed4-43ee-b9db-4bfd0a21c2fa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0fc414d4-abec-443a-a026-fbc881ff7a71/ugc/6f98a712-4cf4-4f2a-9f2c-b3daebeabf11/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6f98a712-4cf4-4f2a-9f2c-b3daebeabf11'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T03:56:02.953Z',
          createdAt: '2023-08-17T20:33:30.304Z',
          content: [
            {
              id: '71f9b048-f318-4ec8-b7d8-375129f852ee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0fc414d4-abec-443a-a026-fbc881ff7a71/ugc/e8ccdece-62e9-4ff2-bdd0-2803ffd8b062/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e8ccdece-62e9-4ff2-bdd0-2803ffd8b062'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0fc414d4-abec-443a-a026-fbc881ff7a71',
        firstName: 'Thalita',
        lastName: 'Paiva',
        email: 'thalita_vivi@hotmail.com',
        instagramProfile: {
          handle: 'thatampaiva',
          followers: 62228,
          engagementRate: 2.972456129073729,
          isPrimary: true
        }
      },
      userCampaignId: '0fc414d4-abec-443a-a026-fbc881ff7a71'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-03T18:06:49.000Z',
        amountCents: 2338
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T00:00:00.000Z',
          createdAt: '2023-08-18T05:05:50.291Z',
          content: [
            {
              id: '76996d95-e9da-4388-af4e-bb81f4687cd5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17977151774281081/thumbnail.jpg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '037a1fe0-269f-476d-921e-496cd6646092',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17922399173772062/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '67a48d98-9c0d-4b22-955f-4271b62a01e8'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T00:17:12.000Z',
          createdAt: '2023-08-18T04:48:37.534Z',
          content: [
            {
              id: '771cd5e9-9066-47cc-ad92-1ce9b0e1001f',
              caption:
                '#AD I’ve partnered with @armandhammerlaundry to tell you about a brand new product! 🧺 The Arm and Hammer Power Sheets laundry detergent are pre measured, concentrated laundry detergent sheets that leave my clothes with a fresh linen scent. This product is Climate Pledge Friendly certified on Amazon and has recyclable packaging, along with no plastic bottle waste. Available to shop on @amazon. #laundryday #laundryhacks #laundrydetergent #laundrygoals #momlifebelike #chores #laundryroom',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/CwWNuvEp1mX/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 33,
              likes: 343,
              views: 825,
              engagements: 428
            }
          ],
          groupId: '25f20c88-f41b-47ae-b86c-d55b6d858973'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1ca0ddfa-7d42-417c-9b54-18df98efda78',
        firstName: 'Kayla',
        lastName: 'Teahon',
        email: 'kaylateahon@yahoo.com',
        instagramProfile: {
          handle: 'kaylateahon',
          followers: 15316,
          engagementRate: 2.6808566205275532,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ok_kt3',
          followers: 85,
          isPrimary: false
        }
      },
      userCampaignId: '1ca0ddfa-7d42-417c-9b54-18df98efda78'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T20:21:45.000Z',
        amountCents: 1606
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T00:00:00.000Z',
          createdAt: '2023-08-17T01:28:18.316Z',
          content: [
            {
              id: '59a686b2-02a6-4dc3-b9b5-967858509d57',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18363043078069911/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e423a2d2-9e3e-4bb6-b21f-b52852eefcda'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T16:25:35.000Z',
          createdAt: '2023-08-17T01:14:11.919Z',
          content: [
            {
              id: '357865c6-bd4d-4e96-9d61-285bda8199aa',
              caption:
                '#ad No more mess and heavy bottles of detergent.\nUse Arm and Hammer Power Sheets Laundry Detergent from @armandhammerlaundry \n\nSo convenient, no mess, no plastic waste and so compact.\n“Climate Pledge Friendly” certified on\nAmazon.\nUltra concentrated with a fresh linen scent\nIt’s simple and easy to use it. Just put it in the washing machine.\nDissolves completely even in cold water.\n\nThis laundry detergent sheet is ideal for traveling.\n\nAvailable at Amazon!\nLink in bio.\n\n#brandpartner',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/CwX7FXEIgYs/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 134,
              likes: 5160,
              views: 61214,
              engagements: 5564
            }
          ],
          groupId: '49a79ef9-6976-4967-b8eb-68784239b827'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T00:00:00.000Z',
          createdAt: '2023-08-16T22:58:16.649Z',
          content: [
            {
              id: 'da23a655-c9c8-4d47-8b3d-47c04ef3cf08',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18005578129934823/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2a6217e5-6113-4cf5-8dfe-46792bc7a70c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3a902e6f-e4e5-4680-92b0-cae3a3141074',
        firstName: 'Debora',
        lastName: 'Oliver',
        email: 'debynhaoliver@icloud.com',
        instagramProfile: {
          handle: 'debbyoliverbenites',
          followers: 142723,
          engagementRate: 5.2004932631741205,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'debbyoliverbenites',
          followers: 37242,
          engagementRate: 0.9957048244236915,
          isPrimary: true
        }
      },
      userCampaignId: '3a902e6f-e4e5-4680-92b0-cae3a3141074'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T16:25:48.000Z',
        amountCents: 1625
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T00:00:00.000Z',
          createdAt: '2023-08-16T23:39:44.393Z',
          content: [
            {
              id: 'e7bbcf7b-084a-40f3-82dd-480367e033fb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17990260511246699/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f1b2c224-e516-43ec-bcc5-d58d3d8b7889'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T18:14:20.000Z',
          createdAt: '2023-08-16T23:23:27.579Z',
          content: [
            {
              id: '856505b8-7556-4480-b504-e115238d2f0d',
              caption:
                '#ad @armandhammerlaundry I love these new power sheets from Arm & Hammer! ￼ They are “climate pledge friendly” certified on Amazon and have no plastic bottle waste with recyclable packaging✨ Also love that they work so well without phosphates, bleach or brighteners! Check them out on Amazon, link in bio!',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/CwlAtZyAbdx/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 86,
              likes: 1454,
              views: 32556,
              engagements: 1697
            }
          ],
          groupId: '4560a75f-b03e-4203-a52d-562d6969fc8e'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T00:00:00.000Z',
          createdAt: '2023-08-16T23:32:49.249Z',
          content: [
            {
              id: 'dd0589d3-89ba-4f7e-a7e0-431a4c06a09f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18022783213650171/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd3be4ff1-fa0f-4b3e-8a0e-f8434e3b8cb7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3f3fad4c-86ed-4471-867b-3ef88e68cc89',
        firstName: 'Jennifer',
        lastName: 'Wakumelo',
        email: 'wakumelocollabs@gmail.com',
        instagramProfile: {
          handle: 'our.blog.life',
          followers: 32744,
          engagementRate: 5.249816760322502,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'our.blog.life',
          followers: 6039,
          engagementRate: 2.564102564102564,
          isPrimary: true
        }
      },
      userCampaignId: '3f3fad4c-86ed-4471-867b-3ef88e68cc89'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T00:13:52.000Z',
        amountCents: 1578
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:44:58.382Z',
          createdAt: '2023-08-11T22:30:24.854Z',
          content: [
            {
              id: '342da482-e14a-4603-8ce4-ab66a84f501c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/61572c98-d60b-437b-9876-476532b70de2/ugc/703e852e-aa87-40bb-8a98-a7fcdfbbc55f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5cad41e3-6e82-47f7-8082-f721826c629d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/61572c98-d60b-437b-9876-476532b70de2/ugc/703e852e-aa87-40bb-8a98-a7fcdfbbc55f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '66a6fc0c-ad5e-47d6-9e1f-8376d6291640',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/61572c98-d60b-437b-9876-476532b70de2/ugc/703e852e-aa87-40bb-8a98-a7fcdfbbc55f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '703e852e-aa87-40bb-8a98-a7fcdfbbc55f'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T21:16:06.502Z',
          createdAt: '2023-08-21T02:08:12.144Z',
          content: [
            {
              id: 'b7f6d8a9-35d5-4c67-82d9-27489b492fa4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/61572c98-d60b-437b-9876-476532b70de2/ugc/16b0b516-71b7-4b01-ba38-e1befc566b46/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '16b0b516-71b7-4b01-ba38-e1befc566b46'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '61572c98-d60b-437b-9876-476532b70de2',
        firstName: 'Chelsie',
        lastName: 'Cahoon',
        email: 'chelsiecahoon@gmail.com',
        instagramProfile: {
          handle: 'thesoberraver',
          followers: 24380,
          engagementRate: 1.1345365053322396,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thesoberraver',
          followers: 61365,
          engagementRate: 8.248548881497424,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'chelsiecahoon',
          followers: 115
        }
      },
      userCampaignId: '61572c98-d60b-437b-9876-476532b70de2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-06T21:46:26.000Z',
        amountCents: 1619
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:24:30.694Z',
          createdAt: '2023-08-13T16:58:48.895Z',
          content: [
            {
              id: '94af0720-4ae3-4f77-b4ff-9ee18f933096',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59527deb-1681-4f18-901e-7b73c2d7cda7/ugc/71bb9a81-27bb-4abc-b6c9-bb2c457aead5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '90f3dece-0f0e-4bf0-a367-f41603c948b3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59527deb-1681-4f18-901e-7b73c2d7cda7/ugc/71bb9a81-27bb-4abc-b6c9-bb2c457aead5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ae8bbee7-13ee-482d-acea-dcffbb9f961a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59527deb-1681-4f18-901e-7b73c2d7cda7/ugc/71bb9a81-27bb-4abc-b6c9-bb2c457aead5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '71bb9a81-27bb-4abc-b6c9-bb2c457aead5'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:23:48.787Z',
          createdAt: '2023-08-13T16:37:04.075Z',
          content: [
            {
              id: '616f4a4e-1c6e-4a02-a4d0-ab3a0638db4a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59527deb-1681-4f18-901e-7b73c2d7cda7/ugc/d29b3541-e4ab-419e-bb7d-64f774e49cf1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd29b3541-e4ab-419e-bb7d-64f774e49cf1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '59527deb-1681-4f18-901e-7b73c2d7cda7',
        firstName: 'Hannah',
        lastName: 'Cochran',
        email: 'hannah.thomas3@outlook.com',
        tiktokProfile: {
          handle: 'hannahtcochran',
          followers: 1680,
          engagementRate: 12.190323922103882,
          isPrimary: true
        }
      },
      userCampaignId: '59527deb-1681-4f18-901e-7b73c2d7cda7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T17:44:10.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-02T19:45:58.173Z',
          createdAt: '2023-08-22T22:32:20.788Z',
          content: [
            {
              id: '883891a9-d88a-496d-a159-cc1390f5b19f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/693bfc56-cada-4065-9381-ec5982a53a4c/ugc/b1982e36-e104-4d6e-82dd-94fc3c8d44a3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3da03d28-e255-47c0-8890-8356313b1cda',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/693bfc56-cada-4065-9381-ec5982a53a4c/ugc/b1982e36-e104-4d6e-82dd-94fc3c8d44a3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a229b05c-c440-4da1-88c3-2c0bcccfb89d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/693bfc56-cada-4065-9381-ec5982a53a4c/ugc/b1982e36-e104-4d6e-82dd-94fc3c8d44a3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b1982e36-e104-4d6e-82dd-94fc3c8d44a3'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-02T19:45:54.600Z',
          createdAt: '2023-08-22T22:11:04.942Z',
          content: [
            {
              id: '6b508299-4ae8-45ca-b371-d44b6fec5203',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/693bfc56-cada-4065-9381-ec5982a53a4c/ugc/57530686-c300-4926-bfb8-8c90587f8ffe/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '57530686-c300-4926-bfb8-8c90587f8ffe'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '693bfc56-cada-4065-9381-ec5982a53a4c',
        firstName: 'Erika',
        lastName: 'Campos',
        email: 'erikahcampos91@gmail.com',
        instagramProfile: {
          handle: 'erikahcampos',
          followers: 3874,
          engagementRate: 2.049561177077956,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'erikahcampos91',
          followers: 361,
          engagementRate: 10.150375939849624,
          isPrimary: true
        }
      },
      userCampaignId: '693bfc56-cada-4065-9381-ec5982a53a4c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T16:31:13.000Z',
        amountCents: 3178
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:00:00.000Z',
          createdAt: '2023-08-22T02:33:39.871Z',
          content: [
            {
              id: 'bdc77e32-c51f-4e08-9dbf-4c83c087dfe5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18059745175439861/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7e7eb5ad-8bdb-4136-bde4-bff8aea8383f'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-01T00:00:00.000Z',
          createdAt: '2023-08-22T02:32:37.356Z',
          content: [
            {
              id: 'b8a0a928-cc83-4c44-bb32-67bd2b2f6c99',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18036116200492954/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '43f85006-93a5-41ce-b101-561e58bd60ca'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
        firstName: 'Elen',
        lastName: 'del Rosario',
        email: 'elenmdelrosario@gmail.com',
        instagramProfile: {
          handle: 'elenmarri',
          followers: 246050,
          engagementRate: 0.7776874618979882,
          isPrimary: true
        }
      },
      userCampaignId: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T18:22:12.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-18T13:09:07.177Z',
          createdAt: '2023-08-15T21:26:53.836Z',
          content: [
            {
              id: '92d94b9e-f3f1-4aef-a25e-71fdc6ee2c2e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/8c279fc5-010d-4e07-a680-52ba3a92bb6d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '968ad7ef-7b53-4860-934a-27e484b6fe5f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/8c279fc5-010d-4e07-a680-52ba3a92bb6d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4a3a7e8c-b7b4-48c6-8e19-7a22af7d8e59',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/8c279fc5-010d-4e07-a680-52ba3a92bb6d/UGC-CONTENT-5.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f66b431f-8265-4518-a4b4-f5a5ca355bf0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/8c279fc5-010d-4e07-a680-52ba3a92bb6d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9f09dd5e-b7dd-4ed2-8a70-26c22a6b1122',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/8c279fc5-010d-4e07-a680-52ba3a92bb6d/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8c279fc5-010d-4e07-a680-52ba3a92bb6d'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T14:06:52.107Z',
          createdAt: '2023-08-17T15:48:08.448Z',
          content: [
            {
              id: 'a54cd9f3-a75f-4a14-9ea0-8271f76ca4ca',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4158c2a5-3d0f-46fa-b76c-009c35221cc2/ugc/a7f11f18-6bd2-4282-8add-37fb106f252c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a7f11f18-6bd2-4282-8add-37fb106f252c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4158c2a5-3d0f-46fa-b76c-009c35221cc2',
        firstName: 'Lauren',
        lastName: 'Jacobs',
        email: 'lauren@laurenbethjacobs.com',
        instagramProfile: {
          handle: 'laurenupsidedownorsideways',
          followers: 2132,
          engagementRate: 0.6848030018761726,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'thebodhibody',
          followers: 81,
          engagementRate: 4.072398190045249,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'thebodhibody',
          followers: 134
        }
      },
      userCampaignId: '4158c2a5-3d0f-46fa-b76c-009c35221cc2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T18:04:29.000Z',
        amountCents: 2327
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T13:07:11.187Z',
          createdAt: '2023-08-17T13:57:53.518Z',
          content: [
            {
              id: 'b582d934-83fc-49ba-9bd6-98a3874eeb88',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7cef0f34-4e66-4ffc-a075-518de7130108/ugc/0f048ccf-6bfd-4449-a8a6-57f3ed82f44f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0f048ccf-6bfd-4449-a8a6-57f3ed82f44f'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T13:07:15.056Z',
          createdAt: '2023-08-17T13:59:06.710Z',
          content: [
            {
              id: '6fa7de67-dc02-4fd7-992e-f5d56ca4673c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7cef0f34-4e66-4ffc-a075-518de7130108/ugc/2028a12e-671f-4412-acf0-60e3d7f51b31/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '736e493b-3ea7-439e-9729-e757e90fceab',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7cef0f34-4e66-4ffc-a075-518de7130108/ugc/2028a12e-671f-4412-acf0-60e3d7f51b31/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd0254de9-015d-4da1-bd2c-75e4894fa83d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7cef0f34-4e66-4ffc-a075-518de7130108/ugc/2028a12e-671f-4412-acf0-60e3d7f51b31/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2028a12e-671f-4412-acf0-60e3d7f51b31'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7cef0f34-4e66-4ffc-a075-518de7130108',
        firstName: 'Angel',
        lastName: 'Vie',
        email: 'angelinaa9802@gmail.com',
        instagramProfile: {
          handle: 'ugcby.angel',
          followers: 1070,
          engagementRate: 1.485981308411215,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ugcby.angel',
          followers: 971,
          engagementRate: 1.574803149606299,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'reevechess'
        }
      },
      userCampaignId: '7cef0f34-4e66-4ffc-a075-518de7130108'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-13T18:08:30.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T07:13:20.438Z',
          createdAt: '2023-08-20T14:49:35.661Z',
          content: [
            {
              id: '4fd4a7e4-b49a-48b3-99b5-1a709fd79728',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd2e7ed6e-d51a-4c35-9f05-250638da13e0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6e96cdfe-186a-41b7-ab28-53e7fb17f4f8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '836dbc37-4ba5-4a6b-8c7b-723e0b101135'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T07:13:13.998Z',
          createdAt: '2023-08-20T14:47:11.559Z',
          content: [
            {
              id: 'e3db8bc0-f179-4d04-8e5d-ef45c75f038a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/d541f6b2-6a20-4b7c-9f01-9d44d0c5a4fd/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd541f6b2-6a20-4b7c-9f01-9d44d0c5a4fd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6e87b117-eb16-467e-bf4a-6f66877caf73',
        firstName: 'Marionette',
        lastName: 'Solorio',
        email: 'ladyshulammite@gmail.com',
        instagramProfile: {
          handle: 'thisismarionette',
          followers: 10627,
          engagementRate: 0.16373388538628023,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ladyshulammite016',
          followers: 673,
          engagementRate: 9.772727272727273,
          isPrimary: true
        }
      },
      userCampaignId: '6e87b117-eb16-467e-bf4a-6f66877caf73'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T19:25:26.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:16:31.183Z',
          createdAt: '2023-08-14T19:53:24.409Z',
          content: [
            {
              id: '9d16973a-effe-4a6f-80a6-1a0d506df008',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c952d6b5-82c5-4838-ae5f-e12322f2f116/ugc/c0a0ea32-baca-4980-b44f-f6158f758333/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '936d9544-745c-400c-9447-63d3b97999d6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c952d6b5-82c5-4838-ae5f-e12322f2f116/ugc/c0a0ea32-baca-4980-b44f-f6158f758333/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '20645d6a-d672-4a38-ab09-2c778dc6bf8c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c952d6b5-82c5-4838-ae5f-e12322f2f116/ugc/c0a0ea32-baca-4980-b44f-f6158f758333/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c0a0ea32-baca-4980-b44f-f6158f758333'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T21:32:42.202Z',
          createdAt: '2023-08-16T01:29:56.805Z',
          content: [
            {
              id: 'b451f951-6cca-433e-b45c-18854b295b8c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c952d6b5-82c5-4838-ae5f-e12322f2f116/ugc/105b66f6-65f9-4563-8250-02b6de49cff7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '105b66f6-65f9-4563-8250-02b6de49cff7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c952d6b5-82c5-4838-ae5f-e12322f2f116',
        firstName: 'Deborah',
        lastName: 'Tambunan',
        email: 'deborahcing12@gmail.com',
        instagramProfile: {
          handle: 'debbieecing',
          followers: 1000,
          engagementRate: 3.1300000000000003,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'debdebbiee',
          followers: 2656,
          engagementRate: 14.058956916099774,
          isPrimary: true
        }
      },
      userCampaignId: 'c952d6b5-82c5-4838-ae5f-e12322f2f116'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-18T05:42:39.000Z',
        amountCents: 1581
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T21:25:30.672Z',
          createdAt: '2023-08-24T20:45:47.600Z',
          content: [
            {
              id: '856f3d04-42da-4f09-a5d7-c3869027b886',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fdd464f6-2289-480f-a5ce-4b5b7f230e53/ugc/cafe2853-c785-4984-8c8e-f282a968f209/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '82fb8054-6bb1-427b-8bdd-46135bb73dd9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fdd464f6-2289-480f-a5ce-4b5b7f230e53/ugc/cafe2853-c785-4984-8c8e-f282a968f209/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9c587e88-a65a-4f52-b7b2-d9f40a0f1560',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fdd464f6-2289-480f-a5ce-4b5b7f230e53/ugc/cafe2853-c785-4984-8c8e-f282a968f209/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cafe2853-c785-4984-8c8e-f282a968f209'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T21:25:23.131Z',
          createdAt: '2023-08-25T19:16:22.215Z',
          content: [
            {
              id: 'f3d929c9-4cb2-4952-bf34-e1412def6ef6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fdd464f6-2289-480f-a5ce-4b5b7f230e53/ugc/38c47060-e5ba-49cb-a2c6-11dbcbdeefb4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '38c47060-e5ba-49cb-a2c6-11dbcbdeefb4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'fdd464f6-2289-480f-a5ce-4b5b7f230e53',
        firstName: 'Ryanne',
        lastName: 'Carl',
        email: 'bellryanne@gmail.com',
        instagramProfile: {
          handle: 'llebennayr',
          followers: 1285,
          engagementRate: 3.6731517509727625,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'crazywiththecarls',
          followers: 171,
          isPrimary: true
        }
      },
      userCampaignId: 'fdd464f6-2289-480f-a5ce-4b5b7f230e53'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T20:34:06.000Z',
        amountCents: 1581
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-10T20:31:30.489Z',
          createdAt: '2023-08-10T15:53:52.886Z',
          content: [
            {
              id: '57ae5289-7a64-47bc-9983-c46aa340960a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/52048da6-26c8-4160-a3fa-c9a82b47f15f/UGC-CONTENT-5.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6cde3db9-0b7a-41e8-9a73-4cf019f738e8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/52048da6-26c8-4160-a3fa-c9a82b47f15f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fb7510f7-1246-4cfb-af59-a0c80a2e73e6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/52048da6-26c8-4160-a3fa-c9a82b47f15f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3faa4158-8c44-45f1-8e53-7600d3c8d351',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/52048da6-26c8-4160-a3fa-c9a82b47f15f/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ac9ede3e-1c6b-423d-99e1-38a0463d567d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/52048da6-26c8-4160-a3fa-c9a82b47f15f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '52048da6-26c8-4160-a3fa-c9a82b47f15f'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-08-21T23:08:35.671Z',
          createdAt: '2023-08-10T20:31:24.194Z',
          content: [
            {
              id: 'ac5b47f7-cb78-4169-94a4-51e173a01fa6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1e2b1519-5829-46db-a99b-1a40e8f58baa/ugc/daade05d-e65b-42d7-bf2d-d8c8fbb1c6a2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'daade05d-e65b-42d7-bf2d-d8c8fbb1c6a2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1e2b1519-5829-46db-a99b-1a40e8f58baa',
        firstName: 'Violet',
        lastName: 'Nelson',
        email: 'whatvioletwears2023@gmail.com',
        instagramProfile: {
          handle: '_violetnelson_',
          followers: 830,
          engagementRate: 2.289156626506024,
          isPrimary: true
        },
        tiktokProfile: {
          handle: '_violetnelson_',
          followers: 1392,
          engagementRate: 13.975694444444445,
          isPrimary: true
        }
      },
      userCampaignId: '1e2b1519-5829-46db-a99b-1a40e8f58baa'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T19:59:21.000Z',
        amountCents: 962
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T14:09:18.620Z',
          createdAt: '2023-08-19T13:04:27.198Z',
          content: [
            {
              id: '11becd88-b36d-40c4-af63-0dd9d33d66a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d3db0581-3956-46ac-b8b9-735a34b17b03/ugc/b09f7acc-066e-4eab-b677-56c2aaf81ca8/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '91e74cd8-1d04-4d96-b9b2-6d2b29cad710',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d3db0581-3956-46ac-b8b9-735a34b17b03/ugc/b09f7acc-066e-4eab-b677-56c2aaf81ca8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6bbe2dff-4172-4923-b761-7085f25f27b1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d3db0581-3956-46ac-b8b9-735a34b17b03/ugc/b09f7acc-066e-4eab-b677-56c2aaf81ca8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4324a11e-13bd-48f4-804e-656a398457fc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d3db0581-3956-46ac-b8b9-735a34b17b03/ugc/b09f7acc-066e-4eab-b677-56c2aaf81ca8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b09f7acc-066e-4eab-b677-56c2aaf81ca8'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-29T12:18:07.910Z',
          createdAt: '2023-08-20T01:50:57.404Z',
          content: [
            {
              id: '7660e5e7-d538-4e13-8176-b470e0e7b1f8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d3db0581-3956-46ac-b8b9-735a34b17b03/ugc/a14b2f18-3f92-406d-8b91-402a75f4265c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a14b2f18-3f92-406d-8b91-402a75f4265c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd3db0581-3956-46ac-b8b9-735a34b17b03',
        firstName: 'Emily',
        lastName: 'Marcelin',
        email: 'elomakin93@gmail.com',
        instagramProfile: {
          handle: 'em_marcelin',
          followers: 8162,
          engagementRate: 1.7226170056358736,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'em_marcelin',
          followers: 783,
          engagementRate: 11.264822134387352,
          isPrimary: true
        }
      },
      userCampaignId: 'd3db0581-3956-46ac-b8b9-735a34b17b03'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T19:02:33.000Z',
        amountCents: 1645
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T10:20:50.228Z',
          createdAt: '2023-08-22T16:42:48.670Z',
          content: [
            {
              id: '859f18a8-0065-47d1-a7e9-38aef7b9aec5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98/ugc/41d15926-bf21-4c55-bf2f-5d7496e16b47/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1f277011-a981-4c9d-b2d7-a933895e66e5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98/ugc/41d15926-bf21-4c55-bf2f-5d7496e16b47/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '35dee988-165f-4428-91bd-919878e5096e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98/ugc/41d15926-bf21-4c55-bf2f-5d7496e16b47/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '41d15926-bf21-4c55-bf2f-5d7496e16b47'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-04T10:20:41.026Z',
          createdAt: '2023-09-04T02:25:40.582Z',
          content: [
            {
              id: 'a73aa0d3-ed35-4dcc-a599-06b9249fc3a2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98/ugc/cc62e158-4912-4872-9fa5-f8b8bec5e85b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cc62e158-4912-4872-9fa5-f8b8bec5e85b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98',
        firstName: 'Danielle',
        lastName: 'Reid',
        email: 'danielle@drandassociates.com',
        instagramProfile: {
          handle: 'iamdaniellereid',
          followers: 9691,
          engagementRate: 0.9441750077391394,
          isPrimary: true
        }
      },
      userCampaignId: '7e2b0ee4-23d2-4a46-a0d1-acc4b07e1b98'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T22:05:35.000Z',
        amountCents: 1629
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T19:17:32.253Z',
          createdAt: '2023-08-12T00:01:10.087Z',
          content: [
            {
              id: '6f9b4354-7b92-42c3-93d6-26b7d72ce468',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ff1153a1-9bd3-4cc2-96fd-caf288aece88/ugc/3191e6b6-ce2d-4dc1-b6a2-2cc653edbaf7/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd5da9c8b-6086-465e-a5c9-3b445868b496',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ff1153a1-9bd3-4cc2-96fd-caf288aece88/ugc/3191e6b6-ce2d-4dc1-b6a2-2cc653edbaf7/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '806abe11-2d08-4c7a-9440-7aeef3cfe71d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ff1153a1-9bd3-4cc2-96fd-caf288aece88/ugc/3191e6b6-ce2d-4dc1-b6a2-2cc653edbaf7/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3191e6b6-ce2d-4dc1-b6a2-2cc653edbaf7'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T20:50:36.240Z',
          createdAt: '2023-08-12T00:20:13.281Z',
          content: [
            {
              id: '18fe1696-0516-4626-93e1-d586d33b6dc7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ff1153a1-9bd3-4cc2-96fd-caf288aece88/ugc/01c135d0-54d7-4011-85dc-27437b4500d3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '01c135d0-54d7-4011-85dc-27437b4500d3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ff1153a1-9bd3-4cc2-96fd-caf288aece88',
        firstName: 'Anna',
        lastName: 'Sorochuk',
        email: 'anna.sorochuk@icloud.com',
        instagramProfile: {
          handle: 'anna_sorochuk',
          followers: 33243,
          engagementRate: 0.8678518785909816,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'annasorochuk',
          followers: 1085,
          engagementRate: 2.4734982332155475,
          isPrimary: true
        }
      },
      userCampaignId: 'ff1153a1-9bd3-4cc2-96fd-caf288aece88'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T05:45:27.000Z',
        amountCents: 2420
      },
      status: 'IN_PROGRESS',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T14:25:31.789Z',
          createdAt: '2023-09-06T00:45:04.059Z',
          content: [
            {
              id: '9edaac40-3bfe-437f-8bb8-ab5a963e4c5a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4b16e5fb-fc2e-47cf-b889-7abfc98bca69/ugc/7d52db9c-ab8c-447a-82e5-199d9793e794/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '624b07bc-add0-4fa4-8b6b-efc9a495841b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4b16e5fb-fc2e-47cf-b889-7abfc98bca69/ugc/7d52db9c-ab8c-447a-82e5-199d9793e794/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0ce50fe1-38f0-43a0-b5a3-71771821d2c6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4b16e5fb-fc2e-47cf-b889-7abfc98bca69/ugc/7d52db9c-ab8c-447a-82e5-199d9793e794/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7d52db9c-ab8c-447a-82e5-199d9793e794'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T14:25:20.780Z',
          createdAt: '2023-09-06T00:42:42.758Z',
          content: [
            {
              id: '1e938fad-f403-435b-84d3-592e70bfb31d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4b16e5fb-fc2e-47cf-b889-7abfc98bca69/ugc/347bd8ed-a25f-42bd-a944-56b00f473934/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '347bd8ed-a25f-42bd-a944-56b00f473934'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4b16e5fb-fc2e-47cf-b889-7abfc98bca69',
        firstName: 'Olena',
        lastName: 'Shepetko',
        email: 'olenashepetko@yahoo.com',
        instagramProfile: {
          handle: 'mrs.oreshko',
          followers: 7816,
          engagementRate: 3.4979529170931416,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'olenashepetko',
          followers: 72,
          engagementRate: 3.875,
          isPrimary: false
        }
      },
      userCampaignId: '4b16e5fb-fc2e-47cf-b889-7abfc98bca69'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T22:05:40.000Z',
        amountCents: 1653
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T21:36:58.155Z',
          createdAt: '2023-08-29T04:32:11.650Z',
          content: [
            {
              id: '0abfb1dc-f137-4963-843b-7b2db611b0ee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4898fd0d-bf5d-4275-b4ec-31614116ba0e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '52231752-b14e-48db-919d-49323c42ec6b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4898fd0d-bf5d-4275-b4ec-31614116ba0e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3257b1b5-8988-45be-a4b0-9dd45ea5b49b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4898fd0d-bf5d-4275-b4ec-31614116ba0e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '30877dc8-c204-4630-b812-6b438584fa51',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4898fd0d-bf5d-4275-b4ec-31614116ba0e/UGC-CONTENT-5.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2fc74acd-9e94-41b2-bd9a-ba15cbcbdebe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4898fd0d-bf5d-4275-b4ec-31614116ba0e/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4898fd0d-bf5d-4275-b4ec-31614116ba0e'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T21:36:52.091Z',
          createdAt: '2023-08-29T05:10:36.849Z',
          content: [
            {
              id: '159dd313-692d-4852-b78d-7c6019fe8bc5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c82bbea4-595f-4a0d-996f-f63612b35959/ugc/4b155204-d322-4793-b871-7bd61af67ea6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4b155204-d322-4793-b871-7bd61af67ea6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c82bbea4-595f-4a0d-996f-f63612b35959',
        firstName: 'Kendra',
        lastName: 'Muecke',
        email: 'writewithkendra@gmail.com',
        instagramProfile: {
          handle: 'kenbunny',
          followers: 228471,
          engagementRate: 4.5742785736482965,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kendraandthebunnies',
          followers: 20469,
          engagementRate: 2.4668790877075297,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kendraandthebunnies'
        }
      },
      userCampaignId: 'c82bbea4-595f-4a0d-996f-f63612b35959'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T18:51:28.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-08-25T22:51:57.000Z',
          createdAt: '2023-08-17T17:51:25.532Z',
          content: [
            {
              id: '21d9eacb-91b1-46a8-9d9c-186b33967eb6',
              caption:
                '#Ad I have been loving my new @armandhammerlaundry detergent sheets! 💖 I’m so used to a drippy mess with normal detergent and this is so much easier! These are available directly from Amazon and are climate pledge friendly with recyclable packaging and no plastic bottle waste. 🥰 (link in my bio to shop) 💕',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/CwYo2cJsWVD/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 60,
              likes: 777,
              views: 1429,
              engagements: 869
            }
          ],
          groupId: '4e073ee9-ec5d-49a6-b5b4-5cb92b67250d'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T00:00:00.000Z',
          createdAt: '2023-08-17T18:04:03.205Z',
          content: [
            {
              id: 'ccfdf7fa-07a8-4b91-8cb6-efa92baef4a4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18010942765890801/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '937030f6-254d-497a-83ce-b29cf1c6ee13'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T15:29:03.108Z',
          createdAt: '2023-08-28T15:29:03.131Z',
          content: [
            {
              id: '7ee25284-9dcd-488e-b6e3-b53d29b31910',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/10983a74-7d8c-4bde-b381-d3290ccb51ba/10983a74-7d8c-4bde-b381-d3290ccb51ba.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0092ca17-c756-4b51-ab4c-2604ab414d54'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ab134651-e64d-4acf-8bba-e5934c593de6',
        firstName: 'Alexis',
        lastName: 'Forstner',
        email: 'lexieforstner@gmail.com',
        instagramProfile: {
          handle: 'lexielforstner',
          followers: 20941,
          engagementRate: 2.8131416837782344,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lexielforstner',
          followers: 8343,
          engagementRate: 4.57796852646638,
          isPrimary: false
        }
      },
      userCampaignId: 'ab134651-e64d-4acf-8bba-e5934c593de6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T02:25:02.000Z',
        amountCents: 1499
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-02T06:31:42.519Z',
          createdAt: '2023-08-24T05:35:22.872Z',
          content: [
            {
              id: '0c4b31dd-c1ce-4678-bcac-adab66b32520',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a42f6d1-b5e8-43e5-aca2-769987f9763e/ugc/3e487ce1-3b2b-4698-8a09-87cae8fbb7fb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cdd2c85d-12e5-4001-b6f2-8bc28551879e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a42f6d1-b5e8-43e5-aca2-769987f9763e/ugc/3e487ce1-3b2b-4698-8a09-87cae8fbb7fb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '47e67515-daf7-4690-9a9c-df0269982ff6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a42f6d1-b5e8-43e5-aca2-769987f9763e/ugc/3e487ce1-3b2b-4698-8a09-87cae8fbb7fb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3e487ce1-3b2b-4698-8a09-87cae8fbb7fb'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-14T06:51:44.683Z',
          createdAt: '2023-08-24T05:32:33.791Z',
          content: [
            {
              id: '9e5b0880-4655-4aab-8d28-c8d71e341544',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6a42f6d1-b5e8-43e5-aca2-769987f9763e/ugc/d01998e6-5124-43c1-8ea9-d49234d538e0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd01998e6-5124-43c1-8ea9-d49234d538e0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6a42f6d1-b5e8-43e5-aca2-769987f9763e',
        firstName: 'Dani Marie',
        lastName: 'Trueba',
        email: 'truebadanielle@yahoo.com',
        instagramProfile: {
          handle: 'daniee.marieee',
          followers: 11603,
          engagementRate: 4.25751960699819,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'danielandpdx',
          followers: 228,
          engagementRate: 5.02092050209205,
          isPrimary: true
        }
      },
      userCampaignId: '6a42f6d1-b5e8-43e5-aca2-769987f9763e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-02T23:45:16.000Z',
        amountCents: 1619
      },
      status: 'COMPLETED',
      creatorType: 'IG',
      content: [
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-31T00:00:00.000Z',
          createdAt: '2023-08-17T00:32:09.950Z',
          content: [
            {
              id: '7fb1b8b0-191b-416a-b156-777e5af9accc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18301252996117092/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7d5eef09-0ae4-42f6-ae40-8dee5e45d430'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_REEL',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T22:18:24.000Z',
          createdAt: '2023-08-17T00:17:53.806Z',
          content: [
            {
              id: '538f49dd-9279-4462-82bc-67b3b06d5ace',
              caption:
                '#ad 🌟 Revolutionize Your Laundry Routine with @armandhammerlaundry 🌟\nHey there, everyone! I’m thrilled to introduce a total game-changer that’s transformed my daily grind – Arm & Hammer’s latest laundry innovation. Say farewell to the hassle of traditional detergents and join me on a journey to hassle-free, efficient, and sustainable laundry!\n🧺 Mess-Free Magic: Tired of drips and spills? These pre-measured, concentrated laundry detergent sheets are the answer. No more mess, no more fuss – just simplicity at its finest.\n❄️ Cold Water Wonder: Ever worried about dissolving sheets in cold water? Fear not! These sheets feature quick dissolve technology, ensuring they dissolve rapidly, leaving your clothes clean and fresh.\n🚢 On-the-Go Essential: Whether I’m tackling my regular laundry day, making a quick stop at the laundromat, or jet-setting on adventures, these sheets have seamlessly integrated into every aspect of my life.\n🌎 Sustainability Champion: Partnering with Arm & Hammer has given me the chance to support a brand committed to a sustainable future. These sheets are “Climate Pledge Friendly” certified on Amazon, and their recyclable packaging means a cleaner planet for all.\n🌬️ Fresh Linen Bliss: There’s nothing quite like the refreshing scent of Fresh Linen wafting from my clothes – it’s like a breath of crisp, clean air every time.\nLooking to simplify your laundry routine while making an eco-conscious choice? These sheets are the perfect solution. Swipe up now to find the Amazon link in my bio and embark on your own journey to hassle-free, sustainable laundry. Let’s revolutionize the way we clean! ♻️👚🌟',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/Cwlc7aKSTl-/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 37,
              likes: 43,
              views: 478,
              engagements: 83
            }
          ],
          groupId: '377764d3-e3e3-4415-b18d-e76c06925907'
        },
        {
          channel: 'INSTAGRAM',
          contentType: 'IG_STORY',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2023-09-20T18:42:21.400Z',
          createdAt: '2023-08-17T00:33:04.385Z',
          content: [
            {
              id: 'c50588eb-6f26-4af3-9156-4c80fcf44428',
              caption: '',
              assets: []
            }
          ],
          groupId: '79ef8c42-e144-4d48-97e3-d338f56b51ae'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd51a11c2-ebd6-40d9-ae2e-94155df25a85',
        firstName: 'Alice',
        lastName: 'Neves',
        email: 'damacenoaliceneves@gmail.com',
        instagramProfile: {
          handle: 'damacenoalice',
          followers: 97606,
          engagementRate: 1.2471569370735407,
          isPrimary: true
        }
      },
      userCampaignId: 'd51a11c2-ebd6-40d9-ae2e-94155df25a85'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T00:25:55.000Z',
        amountCents: 1615
      },
      status: 'COMPLETED',
      creatorType: 'TT',
      content: [
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-27T07:00:00.000Z',
          createdAt: '2023-08-12T23:49:29.637Z',
          content: [
            {
              id: 'd7bfd73d-41cf-4d17-b59b-d01657ce7c50',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/c0491df6-6338-4727-a72f-63f60e7bcd4d/IS_REQUIRED/90b28be7-361b-483e-b320-980303275434/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9539adee-5057-41eb-ab01-9d9cfd1556a4'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T07:00:00.000Z',
          createdAt: '2023-08-12T23:39:26.313Z',
          content: [
            {
              id: '95318271-8867-43d3-bf53-e28f666abb17',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/c0491df6-6338-4727-a72f-63f60e7bcd4d/IS_REQUIRED/3d100efe-237b-4b52-861e-0381376440e1/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '98b7546b-dc18-4818-b81d-0860cca40ea5'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-20T02:46:04.000Z',
          createdAt: '2023-08-12T23:20:50.395Z',
          content: [
            {
              id: 'd25ba222-c999-4635-abd0-d4fad31b4bf1',
              caption:
                '#ad How did I live without these detergent sheets for so long?? 🫧 This is by far the most brilliant addition to my household right now.   #amazonhome #amazonhomefinds @ARM & HAMMER Laundry ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7269230186866969887/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 0,
              likes: 7,
              views: 277
            }
          ],
          groupId: '06c87096-60dc-44ea-85f5-409960f793c8'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c0491df6-6338-4727-a72f-63f60e7bcd4d',
        firstName: 'Brittany',
        lastName: 'Zae',
        email: 'bzetempleton@gmail.com',
        instagramProfile: {
          handle: 'brittany_zae',
          followers: 4994,
          engagementRate: 0.39647577092511016,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'sidehustles_withbritt',
          followers: 2716,
          engagementRate: 5.351170568561873,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'shopzaeco'
        }
      },
      userCampaignId: 'c0491df6-6338-4727-a72f-63f60e7bcd4d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T21:15:40.000Z',
        amountCents: 1615
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T18:37:07.279Z',
          createdAt: '2023-08-17T04:06:49.243Z',
          content: [
            {
              id: '5c050155-8a01-404f-84c2-041dbcf66c42',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/304dbbf0-dad1-46ca-9eae-9d706c6918c8/ugc/bf5b598f-b613-429f-8f6b-2e5c3c87d144/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bf5b598f-b613-429f-8f6b-2e5c3c87d144'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T18:37:13.647Z',
          createdAt: '2023-08-17T03:49:13.006Z',
          content: [
            {
              id: 'afb71604-a604-4fd3-b178-b818abba5044',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/304dbbf0-dad1-46ca-9eae-9d706c6918c8/ugc/87958166-db90-4ec7-b2e0-55407e923140/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5cb49bdc-c89a-476d-b48e-d5a85dfc0461',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/304dbbf0-dad1-46ca-9eae-9d706c6918c8/ugc/87958166-db90-4ec7-b2e0-55407e923140/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4ab27e63-c699-4aa9-8f8a-247ce98673e8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/304dbbf0-dad1-46ca-9eae-9d706c6918c8/ugc/87958166-db90-4ec7-b2e0-55407e923140/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '87958166-db90-4ec7-b2e0-55407e923140'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '304dbbf0-dad1-46ca-9eae-9d706c6918c8',
        firstName: 'Ashley',
        lastName: 'Bourne',
        email: 'ashlopez92@gmail.com',
        instagramProfile: {
          handle: 'smashleybourne',
          followers: 9822,
          engagementRate: 3.7436367338627567,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'smashleybourne',
          followers: 466,
          engagementRate: 9.705882352941178,
          isPrimary: true
        }
      },
      userCampaignId: '304dbbf0-dad1-46ca-9eae-9d706c6918c8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-10T16:18:33.000Z',
        amountCents: 1619
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T11:14:41.531Z',
          createdAt: '2023-08-21T03:02:07.806Z',
          content: [
            {
              id: 'f8de562f-99d7-4801-8111-40389b72ad5c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c805272-2283-42d4-a36d-59e5b72e1871/ugc/37fd9831-6b4b-4b95-98d3-3a3bba4cff99/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bcb56d0e-f001-4d56-bac3-2200ad3d35b9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c805272-2283-42d4-a36d-59e5b72e1871/ugc/37fd9831-6b4b-4b95-98d3-3a3bba4cff99/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b031e87c-0524-4056-8c67-47235324baa5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c805272-2283-42d4-a36d-59e5b72e1871/ugc/37fd9831-6b4b-4b95-98d3-3a3bba4cff99/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '37fd9831-6b4b-4b95-98d3-3a3bba4cff99'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-21T11:14:37.211Z',
          createdAt: '2023-08-21T03:37:47.191Z',
          content: [
            {
              id: '7c7480ab-4b18-4b29-8fb8-37dcd8418f9a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1c805272-2283-42d4-a36d-59e5b72e1871/ugc/b807af8f-7905-4e00-8ff7-ae194d1209bd/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b807af8f-7905-4e00-8ff7-ae194d1209bd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1c805272-2283-42d4-a36d-59e5b72e1871',
        firstName: 'Monica',
        lastName: 'Seabourn',
        email: 'hello@monicaseabourn.com',
        instagramProfile: {
          handle: 'monicaseabourn',
          followers: 2320,
          engagementRate: 1.5474137931034482,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'monicaseabourn',
          followers: 165,
          engagementRate: 2.7709528332907087,
          isPrimary: false
        }
      },
      userCampaignId: '1c805272-2283-42d4-a36d-59e5b72e1871'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-09T20:42:03.000Z',
        amountCents: 1589
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T19:12:18.892Z',
          createdAt: '2023-08-17T14:21:54.444Z',
          content: [
            {
              id: 'eff8f07c-82b8-41de-9ba1-eb63421febf5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01117650-5db6-4065-b0be-ee53c02c812e/ugc/bddb762e-6bfd-40db-8993-61be8e0ad6bc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ffcef1e4-cffb-43be-b02a-e3ba3dd7ac3b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01117650-5db6-4065-b0be-ee53c02c812e/ugc/bddb762e-6bfd-40db-8993-61be8e0ad6bc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b34cdcbd-148d-499a-9958-b72fef88ddc9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01117650-5db6-4065-b0be-ee53c02c812e/ugc/bddb762e-6bfd-40db-8993-61be8e0ad6bc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bddb762e-6bfd-40db-8993-61be8e0ad6bc'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-22T19:25:39.767Z',
          createdAt: '2023-08-17T14:32:47.604Z',
          content: [
            {
              id: '6ab09a69-a423-4eb9-9f85-2192d00c173d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01117650-5db6-4065-b0be-ee53c02c812e/ugc/eb125182-8ebe-402f-a2f9-7abae2c5e071/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'eb125182-8ebe-402f-a2f9-7abae2c5e071'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '01117650-5db6-4065-b0be-ee53c02c812e',
        firstName: 'Tez',
        lastName: 'B',
        email: 'tbyrnesmed@gmail.com',
        instagramProfile: {
          handle: 'thepnppathway',
          followers: 5041,
          engagementRate: 4.181709978178933,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thepnppathway',
          followers: 32072,
          engagementRate: 7.622608993465092,
          isPrimary: true
        }
      },
      userCampaignId: '01117650-5db6-4065-b0be-ee53c02c812e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-16T07:23:55.000Z',
        amountCents: 2330
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T20:28:24.791Z',
          createdAt: '2023-08-20T13:46:38.547Z',
          content: [
            {
              id: '6aeebdc6-151b-450e-8bbb-81da51b8ec30',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f09ab1d6-fd05-421d-9491-82e7cb415de5/ugc/2cea5eea-f28d-4269-bd4b-9d047b71b073/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd60142c2-5ff3-4967-bf8e-bf22c4c33d2f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f09ab1d6-fd05-421d-9491-82e7cb415de5/ugc/2cea5eea-f28d-4269-bd4b-9d047b71b073/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a3ba9a00-74f6-4138-bbb2-cd14bcbd8b37',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f09ab1d6-fd05-421d-9491-82e7cb415de5/ugc/2cea5eea-f28d-4269-bd4b-9d047b71b073/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2cea5eea-f28d-4269-bd4b-9d047b71b073'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T20:28:28.564Z',
          createdAt: '2023-08-28T01:18:16.851Z',
          content: [
            {
              id: '94d5123a-b6eb-470e-804a-742e4f276d5a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f09ab1d6-fd05-421d-9491-82e7cb415de5/ugc/ee69b1c9-5a5f-408a-a3ad-d2a1da0a003a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ee69b1c9-5a5f-408a-a3ad-d2a1da0a003a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f09ab1d6-fd05-421d-9491-82e7cb415de5',
        firstName: 'Samantha',
        lastName: 'Burns',
        email: 'themom.journal@yahoo.com',
        instagramProfile: {
          handle: 'the.mom.journal',
          followers: 20261,
          engagementRate: 2.6504121218103744,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'the.mom.journal',
          followers: 366,
          engagementRate: 3.4979423868312756,
          isPrimary: true
        }
      },
      userCampaignId: 'f09ab1d6-fd05-421d-9491-82e7cb415de5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T19:36:14.000Z',
        amountCents: 1570
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T16:16:10.525Z',
          createdAt: '2023-08-22T20:54:22.850Z',
          content: [
            {
              id: '54d401f8-3665-4da1-8049-8fafc32e92ea',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dce3134-04d4-4ebd-8ad5-9b9623234712/ugc/33daca2d-a8d6-4d6e-ae6d-503690b8196d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5c4f0d11-5666-4207-a0bd-76c436eee446',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dce3134-04d4-4ebd-8ad5-9b9623234712/ugc/33daca2d-a8d6-4d6e-ae6d-503690b8196d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ca0cb363-4ae4-4d6b-8f03-1c877cf5d617',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dce3134-04d4-4ebd-8ad5-9b9623234712/ugc/33daca2d-a8d6-4d6e-ae6d-503690b8196d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '33daca2d-a8d6-4d6e-ae6d-503690b8196d'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-30T16:16:02.132Z',
          createdAt: '2023-08-22T20:48:44.313Z',
          content: [
            {
              id: 'b14f666d-8672-4f76-92b4-400edf207446',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dce3134-04d4-4ebd-8ad5-9b9623234712/ugc/07a3e90b-4294-4bbd-8eb6-f34c0b949827/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '07a3e90b-4294-4bbd-8eb6-f34c0b949827'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4dce3134-04d4-4ebd-8ad5-9b9623234712',
        firstName: 'Ronelle',
        lastName: 'Valera',
        email: 'valera.ronelle@gmail.com',
        tiktokProfile: {
          handle: 'ronelleyy',
          followers: 2938,
          engagementRate: 7.876204404954088,
          isPrimary: true
        }
      },
      userCampaignId: '4dce3134-04d4-4ebd-8ad5-9b9623234712'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T18:16:05.000Z',
        amountCents: 1641
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T06:46:47.546Z',
          createdAt: '2023-08-16T01:36:34.831Z',
          content: [
            {
              id: 'a7eadb21-635b-4b16-89d4-3f46678a3a73',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9601722-fcc5-4cbf-a4f8-7f557c57e7d8/ugc/9ed4abb5-2d9c-4072-ba20-5abb0c9adc26/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c702b5dc-8d3d-4129-81f7-2169e0efe2d1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9601722-fcc5-4cbf-a4f8-7f557c57e7d8/ugc/9ed4abb5-2d9c-4072-ba20-5abb0c9adc26/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a2d51807-e004-4831-86aa-a1c3eca703f2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9601722-fcc5-4cbf-a4f8-7f557c57e7d8/ugc/9ed4abb5-2d9c-4072-ba20-5abb0c9adc26/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9ed4abb5-2d9c-4072-ba20-5abb0c9adc26'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-19T06:46:36.711Z',
          createdAt: '2023-08-16T06:34:13.214Z',
          content: [
            {
              id: '3faf784e-8ef5-43d1-a2f4-9218ed8e4f93',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9601722-fcc5-4cbf-a4f8-7f557c57e7d8/ugc/2f3b436d-a5d1-42db-9c08-c6da7a62c952/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2f3b436d-a5d1-42db-9c08-c6da7a62c952'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a9601722-fcc5-4cbf-a4f8-7f557c57e7d8',
        firstName: 'Nadia',
        lastName: 'Garcia',
        email: 'garcianadialynn@gmail.com',
        instagramProfile: {
          handle: 'nadialynn',
          followers: 2491,
          engagementRate: 4.134885588117222,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gublertok',
          followers: 5384,
          engagementRate: 18.21672027369445,
          isPrimary: true
        }
      },
      userCampaignId: 'a9601722-fcc5-4cbf-a4f8-7f557c57e7d8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T15:24:14.000Z',
        amountCents: 1623
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T19:44:38.654Z',
          createdAt: '2023-08-17T19:17:03.134Z',
          content: [
            {
              id: '44f10b29-c245-4fdc-b3d1-e4881b6fc7ba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/211726bd-cea5-49e1-be7f-a4145bf7c63e/ugc/849a0c4c-86e6-4da3-b1f4-83f5d9c0113e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '52c852f3-261f-47d0-9f37-9d15f589fc60',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/211726bd-cea5-49e1-be7f-a4145bf7c63e/ugc/849a0c4c-86e6-4da3-b1f4-83f5d9c0113e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '933f40da-f341-4f8d-a99c-817356407f8a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/211726bd-cea5-49e1-be7f-a4145bf7c63e/ugc/849a0c4c-86e6-4da3-b1f4-83f5d9c0113e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '849a0c4c-86e6-4da3-b1f4-83f5d9c0113e'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T20:21:46.110Z',
          createdAt: '2023-08-17T20:03:23.382Z',
          content: [
            {
              id: '7fca0dae-cb23-403a-a396-1955b7045119',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/211726bd-cea5-49e1-be7f-a4145bf7c63e/ugc/7c88362d-a2e3-48dc-9b12-4ee1eae1494a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7c88362d-a2e3-48dc-9b12-4ee1eae1494a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '211726bd-cea5-49e1-be7f-a4145bf7c63e',
        firstName: 'Janet',
        lastName: 'Quiroga',
        email: 'janet.campos.jc@gmail.com',
        instagramProfile: {
          handle: 'jayqfields',
          followers: 6768,
          engagementRate: 8.117612293144209,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jayqfields',
          followers: 233,
          engagementRate: 5.6298839687426,
          isPrimary: true
        }
      },
      userCampaignId: '211726bd-cea5-49e1-be7f-a4145bf7c63e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-08T06:11:56.000Z',
        amountCents: 2330
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-15T21:23:05.526Z',
          createdAt: '2023-08-14T20:15:45.478Z',
          content: [
            {
              id: '07a2feef-b917-4403-9e97-d340ac2b914d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7bad2cd2-017a-48c9-8c26-b3638a868623/ugc/00075b2e-4ee9-42a8-8fec-8e5973f664e2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '455d2465-5f72-42fa-93e9-2ea3cd57e865',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7bad2cd2-017a-48c9-8c26-b3638a868623/ugc/00075b2e-4ee9-42a8-8fec-8e5973f664e2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8a2ba207-774e-4f65-94e0-a61cac99c747',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7bad2cd2-017a-48c9-8c26-b3638a868623/ugc/00075b2e-4ee9-42a8-8fec-8e5973f664e2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '00075b2e-4ee9-42a8-8fec-8e5973f664e2'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-16T22:20:29.939Z',
          createdAt: '2023-08-14T20:09:33.036Z',
          content: [
            {
              id: '675b8688-dd3c-4199-84bb-e8b7bf900d06',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7bad2cd2-017a-48c9-8c26-b3638a868623/ugc/74f7f141-9836-4b46-8171-141328c6f911/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '74f7f141-9836-4b46-8171-141328c6f911'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7bad2cd2-017a-48c9-8c26-b3638a868623',
        firstName: 'Madison',
        lastName: 'Bomgardner',
        email: 'wakeupandmakeup14@gmail.com',
        instagramProfile: {
          handle: 'madisonthebeautyaddict',
          followers: 1219,
          engagementRate: 2.100082034454471,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'madisonthebeautyaddict',
          followers: 1775,
          engagementRate: 10.130718954248366,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'madisonmichaelaa'
        }
      },
      userCampaignId: '7bad2cd2-017a-48c9-8c26-b3638a868623'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T20:02:51.000Z',
        amountCents: 1598
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T01:37:30.536Z',
          createdAt: '2023-08-24T04:13:28.254Z',
          content: [
            {
              id: 'db1d4fd0-7424-4d51-be3e-dfa74f9d3317',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/832dd8cb-2c36-469d-b392-984f7ef589e4/ugc/08e0553c-2a6a-4a43-9420-e08089073448/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd8d1979b-9d99-47dd-8ba7-4e665cad7592',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/832dd8cb-2c36-469d-b392-984f7ef589e4/ugc/08e0553c-2a6a-4a43-9420-e08089073448/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e78f57fd-9c3e-4b76-b0ca-4fdad2847820',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/832dd8cb-2c36-469d-b392-984f7ef589e4/ugc/08e0553c-2a6a-4a43-9420-e08089073448/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '08e0553c-2a6a-4a43-9420-e08089073448'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-26T01:37:23.876Z',
          createdAt: '2023-08-24T04:11:44.130Z',
          content: [
            {
              id: '80592e3b-f94e-40c5-9d75-a8c05ef56877',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/832dd8cb-2c36-469d-b392-984f7ef589e4/ugc/f35e89ca-41c4-4772-9ae4-2cfd00a6265f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f35e89ca-41c4-4772-9ae4-2cfd00a6265f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '832dd8cb-2c36-469d-b392-984f7ef589e4',
        firstName: 'Cynthia',
        lastName: 'Locke',
        email: 'cynthialocke215@gmail.com',
        instagramProfile: {
          handle: 'alockeoflove',
          followers: 11861,
          engagementRate: 0.7368687294494563,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alockeoflove1',
          followers: 70,
          engagementRate: 0.38340299439834624,
          isPrimary: true
        }
      },
      userCampaignId: '832dd8cb-2c36-469d-b392-984f7ef589e4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T17:53:38.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'TT',
      content: [
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T04:00:00.000Z',
          createdAt: '2023-08-11T19:26:41.308Z',
          content: [
            {
              id: 'b1a5d5a5-d4ee-4837-904c-254578f86413',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/f47ba419-fbe6-4ca7-a65a-21f5d351db72/IS_REQUIRED/d5606c31-ce14-4238-9053-e9a3c61e238a/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0b264c44-c21a-4ef6-92f3-3e4ae81df617'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-25T18:05:53.000Z',
          createdAt: '2023-08-11T19:06:23.173Z',
          content: [
            {
              id: '9a3655a8-f7f8-46ac-9177-e566aa918791',
              caption: '#ad Brand new product alert 🚨 #brandpartner @ARM & HAMMER Laundry ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7271322706467949870/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 2,
              likes: 12,
              views: 514
            }
          ],
          groupId: 'ce60477e-462c-4900-bce2-21e2daa00912'
        },
        {
          channel: 'TIKTOK',
          contentType: 'TT_STORY',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-28T04:00:00.000Z',
          createdAt: '2023-08-11T19:16:09.188Z',
          content: [
            {
              id: 'd1b70f62-c790-48da-8a60-db1d07560b00',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/f47ba419-fbe6-4ca7-a65a-21f5d351db72/IS_REQUIRED/995ba17e-02da-46d3-8bb9-5db512b36079/tiktok-story-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cc588f33-3775-47aa-a076-45ca75267caa'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f47ba419-fbe6-4ca7-a65a-21f5d351db72',
        firstName: 'Natalie',
        lastName: 'Cooke',
        email: 'nataliehagler@yahoo.com',
        instagramProfile: {
          handle: 'nataliee.cooke',
          followers: 7158,
          engagementRate: 1.7407096954456551,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'nataliee.cooke',
          followers: 57717,
          engagementRate: 5.730562695811995,
          isPrimary: true
        }
      },
      userCampaignId: 'f47ba419-fbe6-4ca7-a65a-21f5d351db72'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-17T09:25:58.000Z',
        amountCents: 2962
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T18:13:31.785Z',
          createdAt: '2023-08-24T03:00:18.287Z',
          content: [
            {
              id: '07ecc259-7c91-46de-932f-642876e1ede3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/872a4407-579f-4139-9f90-f2806bfc5810/ugc/7997897c-ea55-4726-98a6-710442d52536/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'aa77cb9d-8d0a-4f39-8f3d-149801b270fb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/872a4407-579f-4139-9f90-f2806bfc5810/ugc/7997897c-ea55-4726-98a6-710442d52536/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '12cd1064-f182-4038-b230-1d47aa11d897',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/872a4407-579f-4139-9f90-f2806bfc5810/ugc/7997897c-ea55-4726-98a6-710442d52536/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7997897c-ea55-4726-98a6-710442d52536'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-06T20:31:23.127Z',
          createdAt: '2023-09-06T20:29:17.280Z',
          content: [
            {
              id: '600eb9c5-f95c-4cfb-9e4c-ae6f2f60b4ec',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/872a4407-579f-4139-9f90-f2806bfc5810/ugc/8e23ef6c-5c65-4c40-b620-cd95ded6619c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8e23ef6c-5c65-4c40-b620-cd95ded6619c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '872a4407-579f-4139-9f90-f2806bfc5810',
        firstName: 'Brianna',
        lastName: 'Forest',
        email: 'ugcmamadess@gmail.com',
        instagramProfile: {
          handle: 'mamadess_ugc',
          followers: 63,
          engagementRate: 7.760141093474428,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mamadess_ugc',
          followers: 37,
          engagementRate: 0.8298755186721992,
          isPrimary: false
        }
      },
      userCampaignId: '872a4407-579f-4139-9f90-f2806bfc5810'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-11T21:44:40.000Z',
        amountCents: 1638
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:28.239Z',
          createdAt: '2023-08-17T16:52:36.995Z',
          content: [
            {
              id: '9d90dc4c-158e-4cb4-a957-2b834580e128',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2875c1f-7309-49f7-9f07-018feb812a4b/ugc/9551f87e-cd8a-4ed0-b142-f4cabcb6f009/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '834cc990-e59d-4a87-969d-54f6fa9ba714',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2875c1f-7309-49f7-9f07-018feb812a4b/ugc/9551f87e-cd8a-4ed0-b142-f4cabcb6f009/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3cce5ef9-d9ee-40c8-9f3a-a6629f445194',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2875c1f-7309-49f7-9f07-018feb812a4b/ugc/9551f87e-cd8a-4ed0-b142-f4cabcb6f009/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9551f87e-cd8a-4ed0-b142-f4cabcb6f009'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-24T19:32:20.387Z',
          createdAt: '2023-08-14T18:57:51.824Z',
          content: [
            {
              id: '9c04f643-11f3-43a3-b8f5-73c8fd05b2c2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f2875c1f-7309-49f7-9f07-018feb812a4b/ugc/59a42ed5-0d46-4652-8628-df7e7347bcae/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '59a42ed5-0d46-4652-8628-df7e7347bcae'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f2875c1f-7309-49f7-9f07-018feb812a4b',
        firstName: 'Caylin',
        lastName: 'Bullock',
        email: 'whittenfamofficial@gmail.com',
        instagramProfile: {
          handle: 'whittenfam_',
          followers: 1409,
          engagementRate: 3.385379701916253,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'whittenfam',
          followers: 80476,
          engagementRate: 7.582140558433201,
          isPrimary: true
        }
      },
      userCampaignId: 'f2875c1f-7309-49f7-9f07-018feb812a4b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-07T14:44:27.000Z',
        amountCents: 2292
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-17T14:49:38.531Z',
          createdAt: '2023-08-12T18:32:17.579Z',
          content: [
            {
              id: 'c03a0fa0-8dc5-4613-902e-7be723266ba6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/ad7c75a4-aefe-49a3-8735-a3b9502133fa/UGC-CONTENT-5.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1a20e82d-c8fc-4951-a674-18eecccb3309',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/ad7c75a4-aefe-49a3-8735-a3b9502133fa/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f41453b3-8197-45e5-8eb1-51d0a5f4d1ee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/ad7c75a4-aefe-49a3-8735-a3b9502133fa/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '40b1b96f-5a12-4c09-a669-cca0dec97256',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/ad7c75a4-aefe-49a3-8735-a3b9502133fa/UGC-CONTENT-4.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e087b2a9-631e-4b71-8c79-7a2aadca3549',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/ad7c75a4-aefe-49a3-8735-a3b9502133fa/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ad7c75a4-aefe-49a3-8735-a3b9502133fa'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-08-23T21:02:16.624Z',
          createdAt: '2023-08-12T18:31:09.361Z',
          content: [
            {
              id: 'dcd147a2-8056-4780-9a52-41329bc0b819',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/937d3a53-d466-475b-87a3-b423b7789364/ugc/87aba24a-bca8-4153-91be-0f4ee8b21432/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '87aba24a-bca8-4153-91be-0f4ee8b21432'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '937d3a53-d466-475b-87a3-b423b7789364',
        firstName: 'Gracielly',
        lastName: 'Araujo',
        email: 'graciellyqueiroz_3@hotmail.com',
        instagramProfile: {
          handle: 'graciellyqa',
          followers: 41210,
          engagementRate: 0.7774811938849794,
          isPrimary: true
        }
      },
      userCampaignId: '937d3a53-d466-475b-87a3-b423b7789364'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-15T16:21:46.000Z',
        amountCents: 1604
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-05T14:11:09.668Z',
          createdAt: '2023-08-24T23:24:13.538Z',
          content: [
            {
              id: '837ea531-df50-4035-91c7-d087cbbfacb1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3441b55c-c8a0-4a0f-9e1b-0fc825a55244/ugc/9816b5bd-c863-479e-9d51-4080bd828867/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bee98a51-156d-427b-9edc-a25dbf0d470c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3441b55c-c8a0-4a0f-9e1b-0fc825a55244/ugc/9816b5bd-c863-479e-9d51-4080bd828867/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ff70527a-bbbb-4769-8339-dea006cc8fdb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3441b55c-c8a0-4a0f-9e1b-0fc825a55244/ugc/9816b5bd-c863-479e-9d51-4080bd828867/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9816b5bd-c863-479e-9d51-4080bd828867'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-05T14:10:59.891Z',
          createdAt: '2023-08-24T23:15:33.141Z',
          content: [
            {
              id: '52d405eb-b688-499a-bbaa-86ffbbc2d848',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3441b55c-c8a0-4a0f-9e1b-0fc825a55244/ugc/6c651357-52cc-4750-ba81-f3155b6feffb/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6c651357-52cc-4750-ba81-f3155b6feffb'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3441b55c-c8a0-4a0f-9e1b-0fc825a55244',
        firstName: 'Sadie',
        lastName: 'Eliason',
        email: 'sadiehandmade@gmail.com',
        instagramProfile: {
          handle: 'sadiehandmade',
          followers: 10583,
          engagementRate: 1.1480676556741944,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sadiehandmade',
          followers: 16406,
          engagementRate: 6.485056584050549,
          isPrimary: true
        }
      },
      userCampaignId: '3441b55c-c8a0-4a0f-9e1b-0fc825a55244'
    },
    {
      purchaseDetails: {
        purchaseDate: '2023-08-14T06:42:33.000Z',
        amountCents: 2412
      },
      status: 'COMPLETED',
      creatorType: 'UGC',
      content: [
        {
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-19T18:28:21.471Z',
          createdAt: '2023-09-02T16:48:17.191Z',
          content: [
            {
              id: '68fafcf7-9cca-458b-bb7e-f7318a67c267',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a4c6e472-bb34-49d3-9e13-ddb47e5c81bc/ugc/e036c98f-b91d-45ad-980e-19bcc9d684d4/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '314ed764-fa57-45da-9489-072c29b8e957',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a4c6e472-bb34-49d3-9e13-ddb47e5c81bc/ugc/e036c98f-b91d-45ad-980e-19bcc9d684d4/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '15e4080e-7398-4dc3-92d5-fd6c1a117f4e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a4c6e472-bb34-49d3-9e13-ddb47e5c81bc/ugc/e036c98f-b91d-45ad-980e-19bcc9d684d4/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e036c98f-b91d-45ad-980e-19bcc9d684d4'
        },
        {
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2023-09-19T18:28:15.605Z',
          createdAt: '2023-09-02T16:25:28.821Z',
          content: [
            {
              id: 'ee9e1788-a666-4206-a36d-c6bc18ee49e4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a4c6e472-bb34-49d3-9e13-ddb47e5c81bc/ugc/ef04789c-fe96-491b-ab1b-a5d74f1ca7b5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ef04789c-fe96-491b-ab1b-a5d74f1ca7b5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a4c6e472-bb34-49d3-9e13-ddb47e5c81bc',
        firstName: 'Emma',
        lastName: 'Sagor',
        email: 'em.sagor6@gmail.com',
        instagramProfile: {
          handle: 'em.sagor',
          followers: 2460,
          engagementRate: 1.2235772357723578,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'em.sagor',
          followers: 3800,
          engagementRate: 5.058365758754864,
          isPrimary: true
        }
      },
      userCampaignId: 'a4c6e472-bb34-49d3-9e13-ddb47e5c81bc'
    }
  ]
}

import { createContext, useContext } from 'react'
import { DateTime } from 'luxon'
import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export type ReviewsContextType = {
  reviewsData: CampaignProductReviewRatingMetric[]
  filteredReviewData: CampaignProductReviewRatingMetric[]
  is3pReviewsVisible: boolean
  minDate: DateTime
  maxDate: DateTime
  topLevelViewType: TopLevelViewType
  setTopLevelViewType: (viewType: TopLevelViewType) => void
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
  startDateFilter?: DateTime
  setStartDateFilter: (date?: DateTime) => void
  endDateFilter?: DateTime
  setEndDateFilter: (date?: DateTime) => void
}

export enum TopLevelViewType {
  MOMENTUM = 'Momentum',
  LISTING = 'Listing'
}

export enum ViewType {
  COUNT = 'Count',
  RATING = 'Rating'
}

const ReviewsContext = createContext<ReviewsContextType>({
  reviewsData: [],
  filteredReviewData: [],
  is3pReviewsVisible: true,
  minDate: DateTime.now(),
  maxDate: DateTime.now(),
  topLevelViewType: TopLevelViewType.MOMENTUM,
  setTopLevelViewType: () => {},
  viewType: ViewType.COUNT,
  setViewType: () => {},
  startDateFilter: undefined,
  setStartDateFilter: () => {},
  endDateFilter: undefined,
  setEndDateFilter: () => {}
})
ReviewsContext.displayName = 'ReviewsContext'

export default ReviewsContext

export const useReviewsContext = () => useContext(ReviewsContext)

import { Stack } from '@mui/system'
import { TabBar } from '@momentum/routes/campaign/e-commerce/TabBar'
import { Outlet } from 'react-router-dom'

export const CampaignECommerce = () => {
  return (
    <Stack spacing={4} height={'100%'}>
      <TabBar />
      <Outlet />
    </Stack>
  )
}

import { Paper, Stack, Typography, useTheme } from '@mui/material'
import { pluralWord } from '@momentum/utils/stringUtils'
import { Selector, InputType, GraphQLTypes } from '@productwindtom/shared-momentum-zeus-types'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { RecommendOutlined, AutoAwesomeMotionOutlined } from '@mui/icons-material'
import CartIcon from '@momentum/components/icons/cart'

const brandSelector = Selector('Brand')({
  id: true,
  name: true,
  companyId: true,
  logo: true
})

type Brand = InputType<GraphQLTypes['Brand'], typeof brandSelector> & {
  proposals?: number
  products?: number
  recommendations?: number
}

export type BrandCardProps = {
  brand: Brand
  onClick?: () => void
}

export const BrandCard = ({ brand, onClick }: BrandCardProps) => {
  const theme = useTheme()
  const { products = 0, recommendations = 0 } = brand
  return (
    <Paper data-cy={`brandCard-${brand.id}`} sx={{ p: 1, cursor: 'pointer' }} onClick={onClick}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <img
          style={{
            padding: 4,
            width: 56,
            height: 56,
            objectFit: 'contain',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.grey.A200,
            borderRadius: '4px'
          }}
          src={getCdnImageUrl(brand.logo)}
          alt={`${brand.name} logo`}
        />
        <Stack spacing={0.5}>
          <Typography variant={'subtitle1'}>{brand.name}</Typography>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CartIcon fontSize={'small'} color={'primary'} />
            <Typography variant={'caption2'}>
              {products?.toLocaleString()} {pluralWord('Product', products !== 1)}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <AutoAwesomeMotionOutlined fontSize={'small'} color={'primary'} />
            <Typography variant={'caption2'}>
              {brand.proposals} {pluralWord('Proposal', (brand.proposals || 0) !== 1)}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <RecommendOutlined fontSize={'small'} color={'primary'} />
            <Typography variant={'caption2'}>
              {recommendations.toLocaleString()} {pluralWord('Recommendation', recommendations !== 1)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

import { mApi } from '@momentum/api'
import { Selector, GraphQLTypes, InputType } from '@productwindtom/shared-momentum-zeus-types'

const proposalSelector = Selector('Proposal')({
  id: true,
  brandId: true,
  goal: true,
  launchDate: true,
  productId: true,
  status: true,
  ratingGoal: true,
  creatorPricing: {
    type: true,
    numCreators: true,
    price: true,
    schedule: true
  },
  product: {
    name: true,
    image: true,
    priceCents: true,
    store: true,
    ratingSummary: {
      rating: true,
      numRatings: true
    }
  },
  createdBy: {
    firstName: true,
    lastName: true
  },
  updatedAt: true,
  createdAt: true,
  brand: {
    name: true
  }
})

export type Proposal = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>
export const listProposals = async (nextToken?: string): Promise<Proposal[]> => {
  const {
    listProposals: { nextToken: nt, items }
  } = await mApi('query')({
    listProposals: [{ nextToken }, { items: proposalSelector, nextToken: true }]
  })

  if (nt) {
    return [...items, ...(await listProposals(nt))]
  }
  return items
}

export const listBrandProposals = async (brandId: string, nextToken?: string): Promise<Proposal[]> => {
  const {
    listProposals: { items, nextToken: nt }
  } = await mApi('query')({
    listProposals: [
      { brandId, nextToken },
      {
        items: proposalSelector,
        nextToken: true
      }
    ]
  })
  if (nt) {
    const nextItems = await listBrandProposals(brandId, nt)
    return [...items, ...nextItems]
  }
  return items
}

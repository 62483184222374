// @ts-nocheck
import { ModelTypes, ProductFeedbackStatus } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_PRODUCT_FEEDBACK_HEADPHONES } from './headphones'
import { SAMPLE_PRODUCT_FEEDBACK_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_FEEDBACK_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/getProductFeedbackSummary/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_FEEDBACK_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getProductFeedbackSummary/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_FEEDBACK_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getProductFeedbackSummary/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_FEEDBACK_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getProductFeedbackSummary/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_PRODUCT_FEEDBACK_SUMMARY: Record<string, ModelTypes['ProductFeedbackSummary']> = {
  'sample-sku-headphones': SAMPLE_PRODUCT_FEEDBACK_HEADPHONES,
  'sample-sku-laundry': SAMPLE_PRODUCT_FEEDBACK_LAUNDRY,
  'sample-sku-water-flosser': SAMPLE_CAMPAIGN_FEEDBACK_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.skuId]: SAMPLE_CAMPAIGN_FEEDBACK_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.skuId]: SAMPLE_CAMPAIGN_FEEDBACK_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: SAMPLE_CAMPAIGN_FEEDBACK_WIRELESS_EARBUDS,
  'sample-sku-keyboard': {
    status: ProductFeedbackStatus.COMPLETED,
    jsonDownloadUrl:
      'https://media.productwind.com/productFeedback/20864361-7fb2-43bb-92a2-60552d6276b5/ae16e05f-635d-4e53-a49e-3a2adbc508e0/productFeedback.json',
    createdAt: '2024-03-22T15:01:10.807Z',
    updatedAt: '2024-03-22T15:01:10.807Z',
    skuId: 'sample-sku-keyboard'
  },
  'sample-sku-whitening': {
    status: ProductFeedbackStatus.COMPLETED,
    failureReason: undefined,
    jsonDownloadUrl:
      'https://media.productwind.com/productFeedback/2ed35213-06c7-423d-81eb-77ee716bd849/3cc1ead1-2592-4b57-97e1-35fcca616961/productFeedback.json',
    createdAt: '2024-03-20T21:39:19.210Z',
    updatedAt: '2024-03-20T21:39:19.210Z',
    skuId: 'sample-sku-whitening'
  },
  'sample-sku-marker': {
    status: ProductFeedbackStatus.COMPLETED,
    failureReason: undefined,
    jsonDownloadUrl:
      'https://media.productwind.com/productFeedback/9395d486-3c0f-4af1-8fb7-de2368168f8d/66aa157e-cdad-493c-a265-01ae9011f7ad/productFeedback.json',
    createdAt: '2024-03-18T17:26:42.309Z',
    updatedAt: '2024-03-18T17:26:42.309Z',
    skuId: 'sample-sku-marker'
  },
  'sample-sku-pillow': {
    status: ProductFeedbackStatus.COMPLETED,
    failureReason: undefined,
    jsonDownloadUrl:
      'https://media.productwind.com/productFeedback/0ea1a5d1-8baf-4539-b291-01aa6d38d329/eaea1984-b9fc-42d6-8991-909a3b3bfca4/productFeedback.json',
    createdAt: '2024-03-25T14:32:05.587Z',
    updatedAt: '2024-03-25T14:32:05.587Z',
    skuId: 'sample-sku-pillow'
  }
}

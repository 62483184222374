import { Box, Stack, Typography } from '@mui/material'
import { Form } from '@productwindtom/ui-base'
import { useCallback, useEffect, useMemo, useState } from 'react'
import RecommendationsFilters from './components/RecommendationsFilters'
import { useFormContext, useWatch } from 'react-hook-form'
import { debounce, first, groupBy, intersection, keyBy } from 'lodash'
import { Product, searchRecommendations } from './queries'
import { toast } from 'react-toastify'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { DataGrid } from '@momentum/components/table'
import { DEFAULT_SORT, RECOMMENDATION_COLUMN_DEFINITIONS, RECOMMENDATION_SORT_MAP } from './constants'
import Loading from '@momentum/components/loading'
import { RecommendationsFormData } from './types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { captureException } from '@sentry/react'

const Recommendations = () => {
  const { companies, brands } = useUserSessionContext()

  const [recommendations, setRecommendations] = useState<Product[]>()
  const [isLoading, setIsLoading] = useState(false)
  const { setValue } = useFormContext()
  const formData = useWatch() as RecommendationsFormData

  const { brandsByCompanyId, allBrandIds, companiesById, brandsById } = useMemo(
    () => ({
      companiesById: keyBy(companies, 'id'),
      brandsById: keyBy(brands, 'id'),
      brandsByCompanyId: groupBy(brands, 'companyId'),
      allBrandIds: brands.map(brand => brand.id)
    }),
    [brands, companies]
  )

  const handleFilter = useCallback(
    debounce(
      async ({
        companyIds,
        brandIds,
        retailers,
        recommendationTypes,
        search = '',
        sortBy
      }: RecommendationsFormData) => {
        try {
          setIsLoading(true)

          const searchBrandIds = companyIds?.length
            ? companyIds.reduce((acc, companyId) => {
                const companyBrandIds = brandsByCompanyId[companyId].map(brand => brand.id)
                const doesFilterHaveCompanyBrands = intersection(brandIds, companyBrandIds)

                return [
                  ...acc,
                  ...(doesFilterHaveCompanyBrands?.length ? doesFilterHaveCompanyBrands : companyBrandIds)
                ]
              }, [] as string[])
            : brandIds

          const filteredRecommendationTypes = recommendationTypes?.filter(type =>
            Object.values(RecommendationType).includes(type)
          )
          const isArchived = recommendationTypes?.includes('ARCHIVED')

          const sort = sortBy[0] || DEFAULT_SORT[0]

          const resp = await searchRecommendations({
            brandIds: searchBrandIds?.length ? searchBrandIds : allBrandIds,
            stores: retailers
              ? retailers === Store.amazon
                ? Object.values(Store).filter(store => store.includes(Store.amazon))
                : [retailers]
              : Object.values(Store),
            recommendationTypes: filteredRecommendationTypes?.length
              ? filteredRecommendationTypes
              : Object.values(RecommendationType),
            search,
            minShouldMatch: search ? 2 : 1,
            isArchived,
            archivedMinShouldMatch: isArchived ? 2 : 1,
            sortBy: JSON.stringify({
              [RECOMMENDATION_SORT_MAP?.[sort.field as keyof typeof RECOMMENDATION_SORT_MAP] || sort.field]: {
                order: sort.sort
              }
            })
          })

          setRecommendations(resp.items || [])
        } catch (err) {
          captureException(err)
          console.log('err', err)
          toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
            type: 'error'
          })
        } finally {
          setIsLoading(false)
        }
      },
      500
    ),
    [brandsByCompanyId, allBrandIds]
  )

  useEffect(() => {
    handleFilter(formData)
  }, [formData, handleFilter])

  const recommendationsList = useMemo(() => {
    return (recommendations || [])
      .map(r => {
        const brand = brandsById?.[r.brandId]
        const recommendation = getRecommendation(r.recommendations.map(r => r.recommendationType))

        return {
          recommendationType: recommendation,
          ...r,
          numRatings: r?.ratingSummary?.numRatings,
          rating: r?.ratingSummary?.rating,
          company: brand?.companyId && companiesById?.[brand.companyId]?.name,
          brand: brand.name
        }
      })
      .filter(r => r.recommendationType)
  }, [recommendations, companiesById, brandsById])

  return (
    <Stack py={2} spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
        <Stack spacing={1}>
          <Typography variant={'h3'}>All Recommendations</Typography>
        </Stack>
      </Stack>
      <RecommendationsFilters />
      {isLoading ? (
        <Box pt={10}>
          <Loading />
        </Box>
      ) : (
        <DataGrid
          autoHeight
          rows={recommendationsList || []}
          columns={RECOMMENDATION_COLUMN_DEFINITIONS}
          disableColumnMenu={true}
          disableColumnReorder={true}
          sortingMode="server"
          onSortModelChange={newSortModel => {
            setValue('sortBy', newSortModel)
          }}
          sortModel={formData.sortBy}
          initialState={{
            sorting: { sortModel: DEFAULT_SORT }
          }}
        />
      )}
    </Stack>
  )
}

export default () => (
  <Form
    defaultValues={{
      brandIds: [],
      recommendationTypes: Object.values(RecommendationType),
      sortBy: DEFAULT_SORT
    }}
  >
    <Recommendations />
  </Form>
)

const getRecommendation = (recommendations: RecommendationType[]) => {
  if (recommendations.includes(RecommendationType.PRODUCT_LAUNCH)) {
    return RecommendationType.PRODUCT_LAUNCH
  }

  return first(recommendations)
}

import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import {
  ProductKeywordSummary,
  ProductSeoSummary,
  productKeywordSummarySelector,
  productSeoSummarySelector
} from './selectors'

export const uploadProductSeoData = async (
  input: ValueTypes['UploadProductSeoDataInput']
): Promise<ProductSeoSummary | undefined> => {
  return (
    await mApi('mutation')({
      uploadProductSeoData: [
        {
          input
        },
        productSeoSummarySelector
      ]
    })
  ).uploadProductSeoData
}

export const updateProductSeoSummary = async (
  input: ValueTypes['ModelInputUpdateProductSeoSummary']
): Promise<ProductSeoSummary> => {
  return (
    await mApi('mutation')({
      updateProductSeoSummary: [
        {
          input
        },
        productSeoSummarySelector
      ]
    })
  ).updateProductSeoSummary
}

export const updateProductKeywordSummary = async (
  input: ValueTypes['ModelInputUpdateProductKeywordSummary']
): Promise<ProductKeywordSummary> => {
  return (
    await mApi('mutation')({
      updateProductKeywordSummary: [
        {
          input
        },
        productKeywordSummarySelector
      ]
    })
  ).updateProductKeywordSummary
}

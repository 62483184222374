import { Tab, Tabs, Avatar, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink, useNavigate, useMatch, useLocation } from 'react-router-dom'
import { ROUTES, ROUTE_NAMES_PRODUCT } from '@momentum/routes/RouteNames'
import { useEffect } from 'react'
import { useProductsContext } from '@momentum/routes/products/productsContext'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none'
  }
})

export const ProductTabs = () => {
  const { listingAlerts } = useProductsContext()
  const navigate = useNavigate()
  const base = [ROUTES.BRAND_PRODUCTS].join('/')
  const { pathname } = useLocation()

  const classes = useStyles()

  const baseMatch = useMatch(base)

  useEffect(() => {
    if (baseMatch) {
      navigate(ROUTE_NAMES_PRODUCT.ALERTS, { replace: true })
    }
  }, [baseMatch])

  const currentTab = baseMatch ? ROUTE_NAMES_PRODUCT.ALERTS : pathname.split('/').pop()

  return (
    <Tabs value={currentTab}>
      <Tab
        label={
          <Stack direction={'row'}>
            Product health alerts{' '}
            <Avatar sx={{ ml: 0.5, height: 18, width: 18, bgcolor: theme => theme.palette.primary.main }}>
              <Typography variant={'caption2'}>{listingAlerts.length}</Typography>
            </Avatar>
          </Stack>
        }
        value={ROUTE_NAMES_PRODUCT.ALERTS}
        to={ROUTE_NAMES_PRODUCT.ALERTS}
        component={RouterLink}
        className={classes.tab}
      />
      <Tab
        label="Products"
        value={ROUTE_NAMES_PRODUCT.VIEW}
        to={ROUTE_NAMES_PRODUCT.VIEW}
        component={RouterLink}
        className={classes.tab}
      />
    </Tabs>
  )
}

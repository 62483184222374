import { Stack } from '@mui/material'
import FinancialCommitments from './FinancialCommitments'
import FinancialSummaryProvider, { useFinancialSummaryContext } from '../../context/FinancialSummaryContext'
import FinancialFlowSummary from './FinancialFlowSummary'
import FinancialCampaignSummary from './FinancialCampaignSummary'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import useEstimatedDeliverables from '../useEstimatedDeliverables'
import { useFlag } from '@unleash/proxy-client-react'

const FinancialSummary = () => {
  const { isAdmin, isAdminView } = useUserSessionContext()
  const financialSummaryFlag = useFlag('FinancialSummary')

  if (!isAdmin || !isAdminView || !financialSummaryFlag) return null

  return (
    <FinancialSummaryProvider>
      <FinancialSummaryBody />
    </FinancialSummaryProvider>
  )
}

export default FinancialSummary

export const FinancialSummaryBody = () => {
  const { creatorsByType, flowSummary } = useFinancialSummaryContext()
  const { totalCreatorCostCredits = 0, totalProductCostCredits = 0, productPrice = 0 } = useEstimatedDeliverables()

  return (
    <Stack spacing={5} pb={10} pt={4}>
      <FinancialCommitments creatorsByType={creatorsByType} />
      <FinancialCampaignSummary
        flowSummary={flowSummary}
        productPrice={productPrice}
        totalCreatorCostCredits={totalCreatorCostCredits}
        totalProductCostCredits={totalProductCostCredits}
      />
      <FinancialFlowSummary flowSummary={flowSummary} productPrice={productPrice} />
    </Stack>
  )
}

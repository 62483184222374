import * as yup from 'yup'

export const passwordValidator = yup
  .string()
  .required('Required')
  .min(10, 'Must be at least 10 characters long')
  .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
  .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
  .matches(/(?=.*\d)/, 'Password must contain at least one number')
  .matches(/(?=.*[!@#$%^&*])/, 'Password must contain at least one special character')

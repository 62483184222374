import { ZeusInterceptor } from '../types'
import { SAMPLE_CAMPAIGN_CREATORS_FOR_APPROVAL } from './sample-data'
import { SAMPLE_CAMPAIGNS } from '@momentum/api/interceptors/getCampaign/sample-data'
import { STORAGE } from '@momentum/api/interceptors/storage'

export const listCampaignCreatorsForApproval: ZeusInterceptor<
  'query',
  'listCampaignCreatorsForApproval'
> = async args => {
  const params = args.o.listCampaignCreatorsForApproval?.[0]
  const campaign = params?.campaignId
    ? SAMPLE_CAMPAIGNS[params.campaignId] || STORAGE.campaigns[params.campaignId]
    : undefined
  return campaign ? SAMPLE_CAMPAIGN_CREATORS_FOR_APPROVAL[campaign.id] || [] : undefined
}

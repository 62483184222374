import {
  Stack,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as MuiTableCell,
  Button,
  IconButton,
  styled,
  Alert,
  Tooltip,
  Chip,
  Dialog
} from '@mui/material'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { DateTime } from 'luxon'
import { FormProvider, useForm, useFieldArray, useWatch, useFormContext } from 'react-hook-form'
import { chunk, max, sum, sumBy, capitalize, concat, fill } from 'lodash'
import { NumberInput } from '@productwindtom/ui-base'
import { CREATOR_TYPE_PRICING_CONFIG } from '@momentum/utils/brandPricing'
import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { ArrowDropDown, ArrowDropUp, Add, Cancel, InfoOutlined, Close } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { notEmpty } from '@productwindtom/shared-node'
import { useDisclose } from '@momentum/hooks/useDisclose'
import { LoadingButton } from '@mui/lab'

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`

enum FailureAction {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT'
}

type Failure = {
  type: PricingCreatorType
  action: FailureAction
  amount: number
}

const weekArray = Array.from({ length: 7 }, (_, i) => i + 1)

type FormData = {
  weeksData: Record<PricingCreatorType, number[]>[]
}

export const CreatorScheduleEditor = ({
  startDate,
  eventDate,
  creatorPricing,
  onSubmit: onSubmitProp,
  onClose,
  readonly
}: {
  startDate: DateTime
  eventDate?: DateTime
  creatorPricing: ProposalCreatorPricingInput[]
  onClose: () => void
  onSubmit?: (data: { type: PricingCreatorType; schedule: number[] }[]) => void
  readonly?: boolean
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number>()
  const [failures, setFailures] = useState<Failure[]>([])
  const [isHolidayNotOptimized, setIsHolidayNotOptimized] = useState<boolean>()
  const { onClose: onResetClose, onOpen: onResetOpen, isOpen: isResetOpen } = useDisclose()

  const eventDateString = eventDate?.toISODate()

  const mappedPricing = creatorPricing
    .filter(cp => cp.numCreators)
    .map(cp => ({
      numCreators: cp.numCreators,
      type: cp.type,
      originalSchedule: cp.schedule,
      weeklyScheduleChunks: chunk(cp.schedule, 7)
    }))

  const weeks = max(mappedPricing.map(creator => creator.weeklyScheduleChunks.length))!
  const weeksArray = Array.from({ length: weeks }, (_, i) => i + 1)

  const formData = weeksArray.map((week, index) => ({
    ...mappedPricing.reduce(
      (acc, creator) => {
        acc[creator.type] = concat(
          creator.weeklyScheduleChunks[index] || [],
          fill(Array(7 - creator.weeklyScheduleChunks[index]?.length || 0), 0)
        )
        return acc
      },
      {} as Record<PricingCreatorType, number[]>
    )
  }))

  const totalCreators = sumBy(mappedPricing, 'numCreators')

  const onSubmit = (data: FormData) => {
    const convertedData = data.weeksData.reduce(
      (acc: Record<PricingCreatorType, number[]>, ele) => {
        Object.entries(ele).forEach(([key, value]) => {
          acc[key as PricingCreatorType] = [
            ...(acc[key as PricingCreatorType] || []),
            ...value.map(v => (v == null ? 0 : v))
          ]
        })
        return acc
      },
      {} as Record<PricingCreatorType, number[]>
    )

    const returnValues = Object.entries(convertedData).map(([key, value]) => ({
      type: key as PricingCreatorType,
      schedule: value
    }))

    onSubmitProp?.(returnValues)
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { weeksData: formData }
  })

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isValid }
  } = methods

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const ff = mappedPricing
        .map(mp => {
          const allocated = sum(value.weeksData?.flatMap(wd => wd?.[mp.type] || []).filter(notEmpty)) || 0
          if (allocated > mp.numCreators) {
            return {
              type: mp.type,
              action: FailureAction.SUBTRACT,
              amount: allocated - mp.numCreators
            }
          } else if (allocated < mp.numCreators) {
            return {
              type: mp.type,
              action: FailureAction.ADD,
              amount: mp.numCreators - allocated
            }
          }
        })
        .filter(notEmpty)

      setFailures(ff)

      // FInd a value that has a count greater than the event (holiday/promo) date
      const afterEventDate =
        eventDateString &&
        value.weeksData?.find((wd, wIndex) =>
          Object.values(wd || {}).find(typeArray =>
            typeArray.find(
              (count, dIndex) =>
                startDate.plus({ week: wIndex, days: dIndex }).toISODate()! >= eventDateString && (count || 0) > 0
            )
          )
        )
      setIsHolidayNotOptimized(!!afterEventDate)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const { fields, append, remove } = useFieldArray({
    name: 'weeksData',
    control: methods.control
  })

  const addWeek = () => {
    append(
      mappedPricing.reduce(
        (acc, creator) => ({
          ...acc,
          [creator.type]: [0, 0, 0, 0, 0, 0, 0]
        }),
        {} as Record<PricingCreatorType, number[]>
      )
    )
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>Edit creator schedule</Typography>
            <IconButton size={'small'} onClick={onClose} sx={{ p: 0 }} data-cy={`scheduleInputCloseButton`}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'subtitle2'}>
            The below is an estimate of when creators will join this campaign. ProductWind will not follow this schedule
            exactly.
          </Typography>
        </Stack>
        <Box>
          <TableContainer
            sx={{
              borderRadius: '4px',
              borderTop: '1px solid #eaeaea',
              borderLeft: '1px solid #eaeaea',
              borderRight: '1px solid #eaeaea'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={24} />
                  <TableCell />
                  {mappedPricing.map(mp => (
                    <TableCell key={mp.type}>
                      <Stack
                        alignItems={'center'}
                        spacing={0.5}
                        py={0.5}
                        sx={{
                          borderRadius: '4px',
                          border: theme =>
                            failures.some(f => f.type === mp.type)
                              ? `1px solid ${theme.palette.warning.main}`
                              : undefined
                        }}
                      >
                        <Stack direction={'row'} alignItems={'flex-start'} spacing={0.5}>
                          <Typography variant={'label2'}>{CREATOR_TYPE_PRICING_CONFIG[mp.type]?.title}</Typography>
                          {mp.type === PricingCreatorType.UGC && !readonly && (
                            <Tooltip
                              title={
                                <Typography variant={'body1'}>
                                  Clients cannot edit the schedule for UGC creators because we’ve optimized this
                                  schedule for generating your reviews.
                                </Typography>
                              }
                            >
                              <InfoOutlined sx={{ color: 'grey.A700' }} />
                            </Tooltip>
                          )}
                        </Stack>
                        <TypeTotal type={mp.type} totalCreators={mp.numCreators} />
                      </Stack>
                    </TableCell>
                  ))}
                  <TableCell>
                    <Stack alignItems={'center'}>
                      <Typography variant={'label2'}>Total creators</Typography>
                      <AllTotal totalCreators={totalCreators} />
                    </Stack>
                  </TableCell>
                  <TableCell width={24} />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((week, weekIndex) => (
                  <>
                    <TableRow key={weekIndex}>
                      <TableCell>
                        {expandedRowIndex !== weekIndex ? (
                          <IconButton
                            size={'small'}
                            onClick={() => setExpandedRowIndex(weekIndex)}
                            data-cy={`scheduleInputWeekExpandButton-${weekIndex}`}
                          >
                            <ArrowDropDown sx={{ color: 'black' }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            size={'small'}
                            onClick={() => setExpandedRowIndex(undefined)}
                            data-cy={`scheduleInputWeekCollapseButton-${weekIndex}`}
                          >
                            <ArrowDropUp sx={{ color: 'black' }} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack>
                          <Typography variant={'label2'}>Week {weekIndex + 1}</Typography>
                          <Typography variant={'label3'} color={'grey.A700'}>
                            {startDate.plus({ week: weekIndex }).toFormat('MMM d, yyyy')} to{' '}
                            {startDate.plus({ week: weekIndex + 1, day: -1 }).toFormat('MMM d, yyyy')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      {mappedPricing.map((creator, creatorIndex) => (
                        <TotalWeekTypeCell type={creator.type} weekIndex={weekIndex} key={creatorIndex} />
                      ))}
                      <TotalWeekCell weekIndex={weekIndex} />
                      <TableCell>
                        {weekIndex > 3 && weekIndex + 1 === fields.length && (
                          <IconButton
                            size={'small'}
                            onClick={() => remove(weekIndex)}
                            sx={{ p: 0 }}
                            data-cy={`scheduleInputWeekDeleteButton-${weekIndex}`}
                          >
                            <Cancel />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                    {expandedRowIndex === weekIndex &&
                      weekArray.map((day, dayIndex) => (
                        <TableRow key={dayIndex}>
                          <TableCell />
                          <TableCell>
                            <Stack spacing={0.5}>
                              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography variant={'label3'}>Day {weekIndex * 7 + day}</Typography>
                                {weekIndex * 7 + day === 1 && (
                                  <Chip label={'Launch date'} color={'secondary'} size={'small'} />
                                )}
                                {startDate.plus({ week: weekIndex, days: dayIndex }).toISODate() ===
                                  eventDateString && <HolidayChip />}
                              </Stack>
                              <Typography variant={'label3'} color={'grey.A700'}>
                                {startDate.plus({ week: weekIndex, days: dayIndex }).toFormat('MMM d, yyyy')}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {mappedPricing.map((creator, creatorIndex) => (
                            <InputCell
                              key={creatorIndex}
                              weekIndex={weekIndex}
                              dayIndex={dayIndex}
                              type={creator.type}
                              readonly={readonly}
                            />
                          ))}
                          <TotalDayCell weekIndex={weekIndex} dayIndex={dayIndex} />
                          <TableCell />
                        </TableRow>
                      ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!readonly && (
          <Box>
            <Button variant={'text'} startIcon={<Add />} onClick={addWeek} disabled={fields.length >= 8}>
              Add week
            </Button>
          </Box>
        )}
        {!!failures.length && (
          <Alert variant={'outlined'} severity={'warning'} color={'warning'} data-cy={`scheduleInputErrorAlert`}>
            <Typography color={'black'} variant={'label3'}>
              {constructFailureMessage(failures)}
            </Typography>
          </Alert>
        )}
        {isHolidayNotOptimized && (
          <Alert variant={'outlined'} severity={'warning'} color={'warning'} data-cy={`scheduleInputHolidayAlert`}>
            <Typography color={'black'} variant={'label3'}>
              This creator schedule is not recommended to achieve optimal SEO results for your holiday or promo date. We
              recommend completing all creator purchases the day before your holiday or promo. Reset to the default
              recommendation for the best SEO results.
            </Typography>
          </Alert>
        )}
        {!readonly ? (
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <Button variant={'text'} onClick={onResetOpen} data-cy={`scheduleInputResetButton`}>
              Reset
            </Button>
            <Button
              variant={'contained'}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !!failures.length}
              data-cy={`scheduleInputSaveButton`}
            >
              Save
            </Button>
          </Stack>
        ) : (
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <Button
              variant={'contained'}
              onClick={onClose}
              disabled={!isValid || !!failures.length}
              data-cy={`scheduleInputDoneButton`}
            >
              Done
            </Button>
          </Stack>
        )}
      </Stack>
      <Dialog open={isResetOpen} onClose={onResetClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure you want to reset?</Typography>
            <IconButton onClick={onResetClose} data-cy={`scheduleInputResetCloseButton`}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'label3'}>
            This will reset the campaign schedule to ProductWind’s recommended schedule.
          </Typography>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} pt={2}>
            <Button variant={'text'} onClick={onResetClose} data-cy={`scheduleInputResetCancelButton`}>
              Cancel
            </Button>
            <LoadingButton
              variant={'contained'}
              onClick={() => {
                reset()
                onResetClose()
              }}
              data-cy={`scheduleInputResetConfirmButton`}
            >
              Reset
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </FormProvider>
  )
}

const constructFailureMessage = (failures: Failure[]) => {
  const failureString = failures.map(f => {
    const actionS = f.action === FailureAction.ADD ? 'add' : 'remove'

    return `${actionS} ${f.amount} ${CREATOR_TYPE_PRICING_CONFIG[f.type]?.lowerTitle}`
  })

  const joined = failureString.join(' and ')

  return capitalize(`${joined} to save this schedule`)
}

const AllTotal = ({ totalCreators }: { totalCreators: number }) => {
  const val = useWatch<FormData>({ name: `weeksData` })
  return (
    <Typography variant={'label3'} data-cy={`scheduleInputAllTotal`}>
      {sum(Object.values(val).map(v => sum(Object.values(v).flat())))} of {totalCreators}
    </Typography>
  )
}

const TypeTotal = ({ type, totalCreators }: { type: PricingCreatorType; totalCreators: number }) => {
  const val = useWatch<FormData>({ name: `weeksData` })
  return (
    <Typography variant={'label3'} data-cy={`scheduleInputTypeTotal-${type}`}>
      {sum(Object.values(val).map(v => sum(v[type])))} of {totalCreators}
    </Typography>
  )
}

const InputCell = ({
  type,
  weekIndex,
  dayIndex,
  readonly
}: {
  type: PricingCreatorType
  dayIndex: number
  weekIndex: number
  readonly?: boolean
}) => {
  const { getValues } = useFormContext()

  return (
    <TableCell align={'center'}>
      {type === PricingCreatorType.UGC || readonly ? (
        <Typography variant={'label3'} data-cy={`scheduleInputValue-${type}-${weekIndex * 7 + dayIndex}`}>
          {getValues(`weeksData.${weekIndex}.${type}.${dayIndex}`) || 0}
        </Typography>
      ) : (
        <NumberInput
          data-cy={`scheduleInput-${type}-${weekIndex * 7 + dayIndex}`}
          name={`weeksData.${weekIndex}.${type}.${dayIndex}`}
          sx={{ width: 72, textAlign: 'center' }}
          decimalScale={0}
          hideZero
          returnAsNumber
          allowNegative={false}
        />
      )}
    </TableCell>
  )
}

const TotalWeekTypeCell = ({ type, weekIndex }: { type: PricingCreatorType; weekIndex: number }) => {
  const val = useWatch({ name: `weeksData.${weekIndex}.${type}` })

  return (
    <TableCell>
      <Stack alignItems={'center'}>
        <Typography variant={'label3'} data-cy={`scheduleInputWeekTypeTotal-${type}-${weekIndex}`}>
          {sum(val) || 0}
        </Typography>
      </Stack>
    </TableCell>
  )
}

const TotalWeekCell = ({ weekIndex }: { weekIndex: number }) => {
  const val = useWatch({ name: `weeksData.${weekIndex}` })

  return (
    <TableCell>
      <Stack alignItems={'center'}>
        <Typography variant={'label2'} data-cy={`scheduleInputWeekTotal-${weekIndex}`}>
          {sum(Object.values(val).flat()) || 0}
        </Typography>
      </Stack>
    </TableCell>
  )
}

const TotalDayCell = ({ weekIndex, dayIndex }: { weekIndex: number; dayIndex: number }) => {
  const val = useWatch<FormData>({ name: `weeksData.${weekIndex}` })

  return (
    <TableCell>
      <Stack alignItems={'center'}>
        <Typography variant={'label2'} data-cy={`scheduleInputDayTotal-${weekIndex * 7 + dayIndex}`}>
          {sum(Object.values(val).map(v => v[dayIndex] || 0))}
        </Typography>
      </Stack>
    </TableCell>
  )
}

const HolidayChip = () => {
  return (
    <Chip
      label={
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'label3'}>Holiday or promo</Typography>
          <Tooltip title={'This creator schedule has been customized for your selected holiday or promo date!'}>
            <InfoOutlined fontSize={'mSmall'} />
          </Tooltip>
        </Stack>
      }
      color={'success'}
      size={'small'}
    />
  )
}

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { ChangeEvent, LegacyRef, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { InputBaseProps, withInputBase } from '@productwindtom/ui-base'
import { LoadingButtonOwnProps } from '@mui/lab/LoadingButton/LoadingButton'

export interface FileInputProps extends InputBaseProps {
  accept: string
  buttonText?: React.ReactNode
  multiple?: boolean
  buttonProps?: Omit<LoadingButtonOwnProps, 'loading' | 'onClick'>
}

export const FileInput = ({ name, buttonText, buttonProps, accept, multiple }: FileInputProps) => {
  const [uploading, setUploading] = useState(false)
  const ref = useRef<HTMLInputElement>()

  const { field } = useController({ name })
  const { onBlur, onChange } = field

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setUploading(true)
    const files = event.target.files

    if (files && files.length > 0) {
      onChange(multiple ? Array.from(files) : files.item(0))
      onBlur()
    }

    setUploading(false)
  }

  return (
    <Box>
      <LoadingButton variant={'outlined'} onClick={() => ref?.current?.click()} loading={uploading} {...buttonProps}>
        {buttonText || 'Upload'}
      </LoadingButton>
      <input
        ref={ref as LegacyRef<HTMLInputElement>}
        type="file"
        accept={accept}
        hidden
        onChange={handleChange}
        onBlur={onBlur}
        multiple={multiple}
      />
    </Box>
  )
}

export default withInputBase(FileInput)

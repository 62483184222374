import { SvgIconProps, SvgIcon } from '@mui/material'

export const NotSupportedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 210 145" fill="none">
      <path
        d="M100.207 144.993C140.246 144.993 172.704 112.535 172.704 72.4964C172.704 32.4577 140.246 0 100.207 0C60.1686 0 27.7109 32.4577 27.7109 72.4964C27.7109 112.535 60.1686 144.993 100.207 144.993Z"
        fill="#AAD994"
      />
      <path
        d="M168.252 111.358H31.2969V31.3725C31.2969 29.0628 33.1721 27.1875 35.4817 27.1875H164.073C166.382 27.1875 168.257 29.0628 168.257 31.3725V111.358H168.252Z"
        fill="#7C8EE1"
      />
      <path
        d="M168.25 112.215H31.295C30.8205 112.215 30.4375 111.832 30.4375 111.358V31.3726C30.4375 28.5942 32.7014 26.3301 35.4799 26.3301H164.071C166.849 26.3301 169.113 28.5942 169.113 31.3726V111.358C169.107 111.832 168.719 112.215 168.25 112.215ZM32.1469 110.5H167.387V31.3726C167.387 29.5375 165.894 28.0452 164.059 28.0452H35.4684C33.6333 28.0452 32.1411 29.5375 32.1411 31.3726V110.5H32.1469Z"
        fill="#243C98"
      />
      <path
        d="M164.504 111.359H35.0273V35.7354C35.0273 33.5515 36.7995 31.7793 38.9834 31.7793H160.548C162.732 31.7793 164.504 33.5515 164.504 35.7354V111.359Z"
        fill="white"
      />
      <path
        d="M143.281 47.0519V51.1224H56.2578V47.0519C56.2578 44.7365 58.133 42.8672 60.4369 42.8672H139.096C141.412 42.8672 143.281 44.7365 143.281 47.0519Z"
        fill="#324AA8"
      />
      <path
        d="M143.209 52.0248H56.3094C55.8356 52.0248 55.4531 51.6295 55.4531 51.1398V46.9385C55.4531 44.0709 57.7081 41.7344 60.4826 41.7344H139.03C141.805 41.7344 144.066 44.0709 144.066 46.9385V51.1398C144.066 51.6295 143.677 52.0248 143.209 52.0248ZM57.1658 50.2547H142.353V46.9385C142.353 45.0445 140.863 43.5045 139.03 43.5045H60.4826C58.6558 43.5045 57.1658 45.0445 57.1658 46.9385V50.2547Z"
        fill="#243C98"
      />
      <path
        d="M62.2273 48.0585C62.8146 48.0585 63.2907 47.5825 63.2907 46.9952C63.2907 46.4079 62.8146 45.9316 62.2273 45.9316C61.6401 45.9316 61.1641 46.4079 61.1641 46.9952C61.1641 47.5825 61.6401 48.0585 62.2273 48.0585Z"
        fill="#EA0000"
      />
      <path
        d="M65.329 48.0585C65.9163 48.0585 66.3923 47.5825 66.3923 46.9952C66.3923 46.4079 65.9163 45.9316 65.329 45.9316C64.7418 45.9316 64.2656 46.4079 64.2656 46.9952C64.2656 47.5825 64.7418 48.0585 65.329 48.0585Z"
        fill="#F3C94E"
      />
      <path
        d="M68.4384 48.0585C69.0257 48.0585 69.5018 47.5825 69.5018 46.9952C69.5018 46.4079 69.0257 45.9316 68.4384 45.9316C67.8511 45.9316 67.375 46.4079 67.375 46.9952C67.375 47.5825 67.8511 48.0585 68.4384 48.0585Z"
        fill="#12B76A"
      />
      <path
        d="M182.727 119.916H16.8164C14.0436 119.916 11.7969 117.669 11.7969 114.896V111.357H187.747V114.896C187.747 117.669 185.5 119.916 182.727 119.916Z"
        fill="#324AA8"
      />
      <path
        d="M182.725 120.773H16.8145C13.573 120.773 10.9375 118.138 10.9375 114.897V111.358C10.9375 110.883 11.3205 110.5 11.795 110.5H187.745C188.219 110.5 188.603 110.883 188.603 111.358V114.897C188.603 118.138 185.967 120.773 182.725 120.773ZM12.6526 112.215V114.897C12.6526 117.189 14.522 119.058 16.8145 119.058H182.725C185.018 119.058 186.887 117.189 186.887 114.897V112.215H12.6526Z"
        fill="#243C98"
      />
      <path
        d="M110.963 115.794H88.5755C86.2658 115.794 84.3906 113.919 84.3906 111.609V111.352H115.142V111.609C115.148 113.924 113.273 115.794 110.963 115.794Z"
        fill="white"
      />
      <path
        d="M110.961 116.651H88.5736C85.7952 116.651 83.5312 114.387 83.5312 111.609V111.352C83.5312 110.877 83.9143 110.494 84.3888 110.494H115.14C115.615 110.494 115.998 110.877 115.998 111.352V111.609C116.003 114.393 113.745 116.651 110.961 116.651ZM85.3093 112.215C85.5951 113.764 86.95 114.936 88.5793 114.936H110.967C112.596 114.936 113.951 113.758 114.237 112.215H85.3093Z"
        fill="#243C98"
      />
      <path
        d="M170.226 52.6431C184.052 52.6431 195.26 41.4348 195.26 27.6087C195.26 13.7825 184.052 2.57422 170.226 2.57422C156.4 2.57422 145.191 13.7825 145.191 27.6087C145.191 41.4348 156.4 52.6431 170.226 52.6431Z"
        fill="#F3C94E"
      />
      <path
        d="M169.656 31.8905C167.901 31.8905 166.448 30.5299 166.328 28.7806L165.265 12.7102C165.139 10.7893 166.666 9.1543 168.592 9.1543H170.719C172.646 9.1543 174.172 10.7836 174.046 12.7102L172.983 28.7806C172.869 30.5299 171.411 31.8905 169.656 31.8905Z"
        fill="white"
      />
      <path
        d="M169.654 43.6559C172.082 43.6559 174.05 41.6877 174.05 39.2596C174.05 36.8316 172.082 34.8633 169.654 34.8633C167.226 34.8633 165.258 36.8316 165.258 39.2596C165.258 41.6877 167.226 43.6559 169.654 43.6559Z"
        fill="white"
      />
      <path
        d="M193.609 120.772H0.857539C0.383034 120.772 0 120.389 0 119.914C0 119.44 0.383034 119.057 0.857539 119.057H193.609C194.084 119.057 194.467 119.44 194.467 119.914C194.467 120.389 194.084 120.772 193.609 120.772Z"
        fill="#243C98"
      />
      <path
        d="M209.143 120.772H201.373C200.899 120.772 200.516 120.389 200.516 119.914C200.516 119.44 200.899 119.057 201.373 119.057H209.143C209.617 119.057 210 119.44 210 119.914C210 120.389 209.617 120.772 209.143 120.772Z"
        fill="#243C98"
      />
      <path
        d="M56.3107 51.1661H143.208V96.044C143.208 98.0963 141.544 99.76 139.492 99.76H60.0267C57.9744 99.76 56.3107 98.0963 56.3107 96.044V51.1661Z"
        fill="#EAEAEA"
        stroke="#243C98"
        strokeWidth="1.71508"
      />
      <path
        d="M99.4736 72.0341C101.448 72.0341 102.904 70.5798 102.904 68.604C102.904 66.6282 101.448 65.1738 99.4736 65.1738C97.5013 65.1738 96.0434 66.6282 96.0434 68.604C96.0434 70.5798 97.5013 72.0341 99.4736 72.0341ZM99.4736 73.7492C95.685 73.7492 92.6133 76.5139 92.6133 79.9235V80.5855H106.334V79.9235C106.334 76.5139 103.262 73.7492 99.4736 73.7492Z"
        fill="#243C98"
      />
      <path
        d="M111.48 58.3125H87.4692C85.5775 58.3125 84.0391 59.8509 84.0391 61.7427V84.0387C84.0391 85.9304 85.5775 87.4688 87.4692 87.4688H94.3295L99.4748 92.6141L104.62 87.4688H111.48C113.372 87.4688 114.91 85.9304 114.91 84.0387V61.7427C114.91 59.8509 113.372 58.3125 111.48 58.3125ZM102.905 84.0387L99.4748 87.4688L96.0446 84.0387H87.4692V61.7427H111.48L111.484 84.0387H102.905Z"
        fill="#243C98"
      />
    </SvgIcon>
  )
}

import { Stack, Typography, IconButton, Chip } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNavigate, generatePath } from 'react-router-dom'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { ROUTES, ROUTE_NAMES_BRAND } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'

const CreateProposalHeader = ({ brandName }: { brandName: string }) => {
  const navigate = useNavigate()
  const { brand } = useBrandContext()
  const { proposal, isFinancialSummaryView } = useCreateProposalContext()

  const onClose = () => {
    navigate(generatePath([ROUTES.BRAND, ROUTE_NAMES_BRAND.PROPOSALS].join('/'), { brandId: brand.id }))
  }
  
  return (
    <Stack
      direction={'row'}
      py={2}
      px={3}
      spacing={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderBottom={theme => `1px solid ${theme.palette.divider}`}
      bgcolor={theme => theme.palette.background.default}
    >
      <Stack direction={'row'} spacing={2}>
        <Typography variant={'label1'}>
          {!isFinancialSummaryView ? `${proposal ? 'Edit' : 'New'} campaign` : 'Financial summary'}
        </Typography>
        <Chip color={'secondary'} label={brandName} size={'small'} />
      </Stack>
      <IconButton onClick={onClose} sx={{ p: 0 }} data-cy="closeProposal">
        <Close />
      </IconButton>
    </Stack>
  )
}

export default CreateProposalHeader

import { Box, CircularProgress } from '@mui/material'
import { LoadingProps } from './types'

const Loading = ({ boxProps = {}, circulaProgressProps = {} }: LoadingProps) => {
  return (
    <Box height={'100%'} width="100%" display={'flex'} alignItems={'center'} justifyContent={'center'} {...boxProps}>
      <CircularProgress {...circulaProgressProps} />
    </Box>
  )
}

export default Loading

import { SvgIconProps, SvgIcon } from '@mui/material'

export const NotSupportedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 178 191" fill="none">
      <path
        d="M87.6328 190.62C135.958 190.62 175.133 151.692 175.133 103.672C175.133 55.6515 135.958 16.7233 87.6328 16.7233C39.3079 16.7233 0.132812 55.6515 0.132812 103.672C0.132812 151.692 39.3079 190.62 87.6328 190.62Z"
        fill="#B3DAFF"
      />
      <path
        d="M155.38 42.9848V158.23C139.068 177.107 114.877 189.065 87.8765 189.065C67.5488 189.065 48.8151 182.29 33.8281 170.888V26.2754C33.8281 20.9615 38.1544 16.6555 43.502 16.6555H128.884L146.707 34.3662L154.6 42.2101L155.38 42.9848Z"
        fill="white"
      />
      <path
        d="M87.8281 190.5C70.3281 191.5 48.3281 184 33.204 171.697C32.9487 171.505 32.7969 171.204 32.7969 170.881V26.269C32.7969 20.3998 37.5993 15.6207 43.5058 15.6207H128.887C129.163 15.6207 129.426 15.7304 129.619 15.9223L156.115 42.2518C156.308 42.4437 156.419 42.7043 156.419 42.9786L156.328 157.5C156.328 157.746 155.987 158.314 155.828 158.5C138.328 179.5 111.328 191 87.8281 190.5ZM34.8669 170.374C50.2334 181.927 68.5531 188.03 87.8802 188.03C113.417 188.03 137.623 177.032 154.349 157.847V43.4105L128.453 17.6777H43.5058C38.7447 17.6777 34.8669 21.5311 34.8669 26.269V170.374Z"
        fill="#243C98"
      />
      <path
        d="M129.279 126.841H132.89C137.845 126.841 141.865 123.469 141.865 119.312V75.3135C141.865 71.1563 137.845 67.7838 132.89 67.7838H56.9051C51.9497 67.7838 47.9297 71.1563 47.9297 75.3135V119.312C47.9297 123.469 51.9497 126.841 56.9051 126.841H111.334L124.013 140.175C125.636 141.885 128.919 140.955 128.975 138.767L129.279 126.841Z"
        fill="#7C8EE1"
      />
      <path
        d="M126.175 141.839C125.072 141.839 124.013 141.423 123.288 140.659L110.887 127.616H56.9046C51.4413 127.616 47 123.89 47 119.307V75.3087C47 70.7254 51.4413 66.9996 56.9046 66.9996H132.889C138.353 66.9996 142.794 70.7254 142.794 75.3087V119.307C142.794 123.89 138.353 127.616 132.889 127.616H130.189L129.904 138.778C129.873 140.108 128.882 141.241 127.376 141.667C126.98 141.781 126.571 141.839 126.175 141.839ZM56.9046 68.5637C52.4695 68.5637 48.8583 71.5932 48.8583 75.3139V119.312C48.8583 123.033 52.4695 126.062 56.9046 126.062H111.333C111.618 126.062 111.885 126.171 112.058 126.353L124.738 139.687C125.339 140.316 126.187 140.363 126.782 140.191C127.376 140.02 128.021 139.552 128.045 138.747L128.349 126.821C128.361 126.395 128.77 126.057 129.278 126.057H132.889C137.324 126.057 140.936 123.028 140.936 119.307V75.3087C140.936 71.588 137.324 68.5585 132.889 68.5585H56.9046V68.5637Z"
        fill="#243C98"
      />
      <path
        d="M62.1296 91.8775L63.9536 95.6973L68.135 96.3616L65.0671 99.2773L65.7264 103.459L62.0052 101.439L58.2329 103.362L59.004 99.1985L56.0088 96.2041L60.2081 95.6483L62.1296 91.8775Z"
        fill="#F3C94E"
      />
      <path
        d="M65.7599 103.872C65.6802 103.879 65.5986 103.864 65.5258 103.824L61.9965 101.909L58.4175 103.731C58.2769 103.802 58.1096 103.789 57.9823 103.695C57.855 103.6 57.7941 103.442 57.8221 103.288L58.5511 99.3387L55.7107 96.4996C55.5985 96.3876 55.5618 96.2218 55.6125 96.0734C55.6632 95.925 55.7949 95.8165 55.9511 95.7973L59.9315 95.2719L61.7564 91.695C61.8273 91.5559 61.969 91.466 62.1298 91.4685C62.2879 91.4713 62.4287 91.5614 62.4966 91.705L64.2259 95.3282L68.1936 95.9558C68.3482 95.981 68.4769 96.0916 68.5233 96.2426C68.5698 96.3936 68.5288 96.5578 68.4135 96.6648L65.5015 99.4312L66.1281 103.397C66.1528 103.553 66.0861 103.708 65.9584 103.8C65.9039 103.841 65.8313 103.866 65.7599 103.872ZM61.9665 101.028C62.0462 101.021 62.1278 101.036 62.2006 101.077L65.1862 102.696L64.6546 99.3419C64.6346 99.208 64.6807 99.071 64.7777 98.9767L67.2397 96.6373L63.8868 96.1055C63.752 96.084 63.6364 95.9973 63.5787 95.8749L62.117 92.8099L60.5735 95.836C60.5121 95.9577 60.3947 96.0399 60.2578 96.0574L56.8906 96.5016L59.2911 98.9029C59.3882 98.9996 59.4306 99.1371 59.4062 99.2694L58.79 102.608L61.8147 101.066C61.863 101.048 61.9143 101.033 61.9665 101.028Z"
        fill="#F3C94E"
      />
      <path
        d="M78.6842 91.8775L80.5082 95.6973L84.6897 96.3616L81.6217 99.2773L82.2811 103.459L78.5599 101.439L74.7875 103.362L75.5587 99.1985L72.5635 96.2041L76.7628 95.6483L78.6842 91.8775Z"
        fill="#F3C94E"
      />
      <path
        d="M82.3224 103.872C82.2427 103.879 82.1611 103.864 82.0883 103.824L78.559 101.909L74.98 103.731C74.8394 103.802 74.6721 103.789 74.5448 103.695C74.4175 103.6 74.3566 103.442 74.3846 103.288L75.1136 99.3387L72.2732 96.4996C72.161 96.3876 72.1243 96.2218 72.175 96.0734C72.2257 95.925 72.3574 95.8165 72.5136 95.7973L76.494 95.2719L78.3189 91.695C78.3898 91.5559 78.5315 91.466 78.6923 91.4685C78.8504 91.4713 78.9912 91.5614 79.0591 91.705L80.7884 95.3282L84.7561 95.9558C84.9107 95.981 85.0394 96.0916 85.0858 96.2426C85.1323 96.3936 85.0913 96.5578 84.976 96.6648L82.064 99.4312L82.6906 103.397C82.7153 103.553 82.6486 103.708 82.5209 103.8C82.4664 103.841 82.3938 103.866 82.3224 103.872ZM78.529 101.028C78.6087 101.021 78.6903 101.036 78.7631 101.077L81.7487 102.696L81.2171 99.3419C81.1971 99.208 81.2432 99.071 81.3402 98.9767L83.8022 96.6373L80.4493 96.1055C80.3145 96.084 80.1989 95.9973 80.1412 95.8749L78.6795 92.8099L77.136 95.836C77.0746 95.9577 76.9572 96.0399 76.8203 96.0574L73.4531 96.5016L75.8536 98.9029C75.9507 98.9996 75.9931 99.1371 75.9687 99.2694L75.3525 102.608L78.3772 101.066C78.4255 101.048 78.4768 101.033 78.529 101.028Z"
        fill="#F3C94E"
      />
      <path
        d="M95.2546 91.8775L97.0786 95.6973L101.26 96.3616L98.1921 99.2773L98.8514 103.459L95.1302 101.439L91.3579 103.362L92.129 99.1985L89.1338 96.2041L93.3331 95.6483L95.2546 91.8775Z"
        fill="#F3C94E"
      />
      <path
        d="M98.8849 103.872C98.8052 103.879 98.7236 103.864 98.6508 103.824L95.1215 101.909L91.5425 103.731C91.4019 103.802 91.2346 103.789 91.1073 103.695C90.98 103.6 90.9191 103.442 90.9471 103.288L91.6761 99.3387L88.8357 96.4996C88.7235 96.3876 88.6868 96.2218 88.7375 96.0734C88.7882 95.925 88.9199 95.8165 89.0761 95.7973L93.0565 95.2719L94.8814 91.695C94.9523 91.5559 95.094 91.466 95.2548 91.4685C95.4129 91.4713 95.5537 91.5614 95.6216 91.705L97.3509 95.3282L101.319 95.9558C101.473 95.981 101.602 96.0916 101.648 96.2426C101.695 96.3936 101.654 96.5578 101.539 96.6648L98.6265 99.4312L99.2531 103.397C99.2778 103.553 99.2111 103.708 99.0834 103.8C99.0289 103.841 98.9563 103.866 98.8849 103.872ZM95.0915 101.028C95.1712 101.021 95.2528 101.036 95.3256 101.077L98.3112 102.696L97.7796 99.3419C97.7596 99.208 97.8057 99.071 97.9027 98.9767L100.365 96.6373L97.0118 96.1055C96.877 96.084 96.7614 95.9973 96.7037 95.8749L95.242 92.8099L93.6985 95.836C93.6371 95.9577 93.5197 96.0399 93.3828 96.0574L90.0156 96.5016L92.4161 98.9029C92.5132 98.9996 92.5556 99.1371 92.5312 99.2694L91.915 102.608L94.9397 101.066C94.988 101.048 95.0393 101.033 95.0915 101.028Z"
        fill="#F3C94E"
      />
      <path
        d="M111.809 91.8775L113.633 95.6973L117.815 96.3616L114.747 99.2773L115.406 103.459L111.685 101.439L107.913 103.362L108.684 99.1985L105.689 96.2041L109.888 95.6483L111.809 91.8775Z"
        fill="#F3C94E"
      />
      <path
        d="M115.447 103.872C115.368 103.879 115.286 103.864 115.213 103.824L111.684 101.909L108.105 103.731C107.964 103.802 107.797 103.789 107.67 103.695C107.543 103.6 107.482 103.442 107.51 103.288L108.239 99.3387L105.398 96.4996C105.286 96.3876 105.249 96.2218 105.3 96.0734C105.351 95.925 105.482 95.8165 105.639 95.7973L109.619 95.2719L111.444 91.695C111.515 91.5559 111.656 91.466 111.817 91.4685C111.975 91.4713 112.116 91.5614 112.184 91.705L113.913 95.3282L117.881 95.9558C118.036 95.981 118.164 96.0916 118.211 96.2426C118.257 96.3936 118.216 96.5578 118.101 96.6648L115.189 99.4312L115.816 103.397C115.84 103.553 115.774 103.708 115.646 103.8C115.591 103.841 115.519 103.866 115.447 103.872ZM111.654 101.028C111.734 101.021 111.815 101.036 111.888 101.077L114.874 102.696L114.342 99.3419C114.322 99.208 114.368 99.071 114.465 98.9767L116.927 96.6373L113.574 96.1055C113.44 96.084 113.324 95.9973 113.266 95.8749L111.805 92.8099L110.261 95.836C110.2 95.9577 110.082 96.0399 109.945 96.0574L106.578 96.5016L108.979 98.9029C109.076 98.9996 109.118 99.1371 109.094 99.2694L108.477 102.608L111.502 101.066C111.551 101.048 111.602 101.033 111.654 101.028Z"
        fill="#F3C94E"
      />
      <path
        d="M128.372 91.8794L130.196 95.6992L134.377 96.3635L131.309 99.2792L131.969 103.461L128.247 101.441L124.475 103.363L125.246 99.2004L122.251 96.2059L126.45 95.6502L128.372 91.8794Z"
        fill="#F3C94E"
      />
      <path
        d="M132.01 103.872C131.93 103.879 131.849 103.864 131.776 103.824L128.246 101.909L124.668 103.731C124.527 103.802 124.36 103.789 124.232 103.695C124.105 103.6 124.044 103.442 124.072 103.288L124.801 99.3387L121.961 96.4996C121.848 96.3876 121.812 96.2218 121.863 96.0734C121.913 95.925 122.045 95.8165 122.201 95.7973L126.182 95.2719L128.006 91.695C128.077 91.5559 128.219 91.466 128.38 91.4685C128.538 91.4713 128.679 91.5614 128.747 91.705L130.476 95.3282L134.444 95.9558C134.598 95.981 134.727 96.0916 134.773 96.2426C134.82 96.3936 134.779 96.5578 134.664 96.6648L131.751 99.4312L132.378 103.397C132.403 103.553 132.336 103.708 132.208 103.8C132.154 103.841 132.081 103.866 132.01 103.872ZM128.216 101.028C128.296 101.021 128.378 101.036 128.451 101.077L131.436 102.696L130.905 99.3419C130.885 99.208 130.931 99.071 131.028 98.9767L133.49 96.6373L130.137 96.1055C130.002 96.084 129.886 95.9973 129.829 95.8749L128.367 92.8099L126.823 95.836C126.762 95.9577 126.645 96.0399 126.508 96.0574L123.141 96.5016L125.541 98.9029C125.638 98.9996 125.681 99.1371 125.656 99.2694L125.04 102.608L128.065 101.066C128.113 101.048 128.164 101.033 128.216 101.028Z"
        fill="#F3C94E"
      />
      <path
        d="M145.1 65.544C163.2 65.544 177.872 50.8715 177.872 32.772C177.872 14.6725 163.2 0 145.1 0C127.001 0 112.328 14.6725 112.328 32.772C112.328 50.8715 127.001 65.544 145.1 65.544Z"
        fill="#F3C94E"
      />
      <path
        d="M144.351 38.3773C142.053 38.3773 140.152 36.5962 139.995 34.3061L138.603 13.2688C138.439 10.7542 140.437 8.61383 142.959 8.61383H145.743C148.265 8.61383 150.263 10.7467 150.099 13.2688L148.706 34.3061C148.557 36.5962 146.648 38.3773 144.351 38.3773Z"
        fill="white"
      />
      <path
        d="M144.349 53.7813C147.527 53.7813 150.104 51.2046 150.104 48.0262C150.104 44.8477 147.527 42.271 144.349 42.271C141.17 42.271 138.594 44.8477 138.594 48.0262C138.594 51.2046 141.17 53.7813 144.349 53.7813Z"
        fill="white"
      />
    </SvgIcon>
  )
}

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'
import { CreatorTypeFilter, useApproveCreatorsContext } from '../approveCreatorsContext'
import { CreatorRow } from './CreatorRow'
import { EmptyState } from '@momentum/routes/campaign/my-actions/approve-creators/creators-table/EmptyState'
import { SOCIAL_CREATOR_TYPES, UGC_CREATOR_TYPES } from '@productwindtom/shared-momentum'

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.label2
}))

const CreatorTypeFilterToCreatorTypes: Record<CreatorTypeFilter, CreatorType[]> = {
  [CreatorTypeFilter.UGC]: UGC_CREATOR_TYPES,
  [CreatorTypeFilter.SOCIAL]: SOCIAL_CREATOR_TYPES
}

export const ApproveCreatorsTable = () => {
  const { creators, search, approvalStatus, creatorTypeFilter } = useApproveCreatorsContext()

  const filteredCreators = useMemo(() => {
    const filteredBySearch = creators.filter(c => {
      const searchLower = search?.toLowerCase()
      return (
        !searchLower ||
        c.creator.instagramProfile?.handle?.toLowerCase().includes(searchLower) ||
        c.creator.tiktokProfile?.handle?.toLowerCase().includes(searchLower) ||
        c.creator.firstName?.toLowerCase().includes(searchLower) ||
        c.creator.lastName?.toLowerCase().includes(searchLower) ||
        c.creator.email?.toLowerCase().includes(searchLower)
      )
    })
    const filteredByApprovalStatus = filteredBySearch.filter(
      c => !approvalStatus || c.approvalState.status === approvalStatus
    )
    return filteredByApprovalStatus.filter(
      c => !creatorTypeFilter || CreatorTypeFilterToCreatorTypes[creatorTypeFilter].includes(c.creatorType)
    )
  }, [creators, search, approvalStatus, creatorTypeFilter])

  if (!filteredCreators.length) {
    return <EmptyState />
  }

  return (
    <Stack spacing={3}>
      <TableContainer>
        <Table
          sx={{
            width: '100%'
          }}
        >
          <TableHead>
            <TableRow>
              <HeadTableCell>Account</HeadTableCell>
              <HeadTableCell>Creator type</HeadTableCell>
              <HeadTableCell>Age</HeadTableCell>
              {creatorTypeFilter === CreatorTypeFilter.SOCIAL && (
                <>
                  <HeadTableCell>Followers</HeadTableCell>
                  <HeadTableCell>
                    <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                      <Typography variant="label2">ER</Typography>
                      <Tooltip
                        title={
                          <Stack direction={'column'}>
                            <Typography variant={'caption1'}>Engagement Rate</Typography>
                            <Typography variant={'caption2'}>
                              Engagement is calculated as: likes + comments / followers.
                            </Typography>
                          </Stack>
                        }
                      >
                        <InfoOutlinedIcon fontSize="mSmall" color={'action'} />
                      </Tooltip>
                    </Stack>
                  </HeadTableCell>
                </>
              )}

              <HeadTableCell
                sx={{
                  width: 200
                }}
              >
                Action
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCreators.map(c => (
              <CreatorRow key={c.userCampaignId} creator={c} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_BENCHMARK_METRICS_LAUNDRY: Array<ModelTypes['EtailerProductMetrics']> = [
  {
    unitsSold: 1046,
    totalSalesAmount: 2617287,
    pageViews: 0,
    date: '2022-03-08',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 965,
    totalSalesAmount: 2415186,
    pageViews: 0,
    date: '2022-03-09',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1040,
    totalSalesAmount: 2601248,
    pageViews: 0,
    date: '2022-03-10',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 983,
    totalSalesAmount: 2461664,
    pageViews: 7463,
    date: '2022-03-11',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1285,
    totalSalesAmount: 3215369,
    pageViews: 8543,
    date: '2022-03-12',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1384,
    totalSalesAmount: 3460511,
    pageViews: 8596,
    date: '2022-03-13',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1197,
    totalSalesAmount: 2993789,
    pageViews: 8065,
    date: '2022-03-14',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1179,
    totalSalesAmount: 2947026,
    pageViews: 7945,
    date: '2022-03-15',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  },
  {
    unitsSold: 1176,
    totalSalesAmount: 2938921,
    pageViews: 8235,
    date: '2022-03-16',
    skuId: 'aa6025b3-0aba-48d8-ba99-4f46d9453e22'
  }
]

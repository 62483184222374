import { Stack } from '@mui/system'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES, ROUTE_NAMES_BRAND } from '@momentum/routes/RouteNames'
import { Button, Link, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { NoItems } from '@momentum/components/no-items'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const EmptyState = ({ brandId }: { brandId: string }) => {
  const { isAdminView } = useUserSessionContext()
  return (
    <Stack mt={12} alignItems={'center'} justifyContent={'center'}>
      <NoItems />
      <Typography variant={'h5'} my={4}>
        You have no campaigns yet!
      </Typography>
      {isAdminView && (
        <Button
          sx={{ mb: 2 }}
          variant={'contained'}
          component={RouterLink}
          to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL, { brandId })}
          startIcon={<Add />}
        >
          Create campaign
        </Button>
      )}
      {isAdminView && (
        <Link
          component={RouterLink}
          to={generatePath(`${ROUTES.BRAND}/${ROUTE_NAMES_BRAND.RECOMMENDATIONS}`, { brandId })}
        >
          View recommendations
        </Link>
      )}
    </Stack>
  )
}

import { Store } from '../zeus'

export const STORE_TO_LOCALE: Record<Store, string> = {
  amazon: 'US',
  amazon_ca: 'CA',
  amazon_uk: 'UK',
  amazon_de: 'DE',
  bestBuy: 'US',
  walmart: 'US',
  target: 'US',
  costco: 'US',
  shopify: 'US',
  dtc: 'US',
  kroger: 'US',
  other: 'US'
}

import CardGrid from '@momentum/components/card-grid'
import { useContentContext } from '../context'
import ContentCard from './ContentCard'
import { Box } from '@mui/material'
import Loading from '@momentum/components/loading'

const ContentGrid = () => {
  const {
    filteredContent,
    selectedContent,
    selectContent,
    unselectContent,
    expandContent,
    unfavoriteContent,
    favoriteContent,
    setContentIsHidden,
    isLoading
  } = useContentContext()

  if (isLoading) {
    return (
      <Box pt={10}>
        <Loading />
      </Box>
    )
  }

  return (
    <CardGrid
      items={(filteredContent || [])?.map(c => (
        <ContentCard
          key={c.groupId}
          content={c}
          selected={!!selectedContent?.includes(c.groupId)}
          onSelect={selectContent}
          onUnSelect={unselectContent}
          onExpand={() => expandContent(c.groupId)}
          onFavorite={() => favoriteContent(c.groupId)}
          onUnfavorite={() => unfavoriteContent(c.groupId)}
          onHiddenChange={isHidden => setContentIsHidden(c.groupId, isHidden)}
        />
      ))}
    />
  )
}

export default ContentGrid

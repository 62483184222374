import { Text, View } from '@react-pdf/renderer'
import { orderBy } from 'lodash'
import { FeedbackPDFProps } from '.'
import { OTHER_THEME } from '../constants'
import { PagePDF } from './PagePDF'
import { styles } from './styles'

const FEEDBACK_CARDS_ROWS = 3
const FEEDBACK_CARDS_PER_ROW = 3

export const ThemeSummaryPDF = (props: FeedbackPDFProps) => {
  const feedbackByTheme = props.themes.reduce(
    (acc, t) => {
      acc[t.theme] = orderBy([...t.positiveFeedback, ...t.criticalFeedback], f => f.length, 'desc')
      return acc
    },
    {} as Record<string, string[]>
  )

  return (
    <>
      {props.themes
        .filter(t => t.theme !== OTHER_THEME)
        .map((t, index) => (
          <PagePDF key={index} {...props}>
            <View
              style={[
                styles.debug,
                {
                  paddingVertical: 8,
                  paddingHorizontal: 16
                }
              ]}
            >
              <View
                style={[
                  styles.row,
                  styles.debug,
                  {
                    alignItems: 'center'
                  }
                ]}
              >
                <Text style={[styles.label1, styles.debug]}>{t.theme}</Text>
                <Text
                  style={[
                    styles.body1,
                    styles.debug,
                    {
                      color: '#767676',
                      marginLeft: 8
                    }
                  ]}
                >
                  {t.positiveFeedback.length + t.criticalFeedback.length} creators said this
                </Text>
              </View>

              <Text
                style={[
                  styles.label2,
                  styles.debug,
                  {
                    marginTop: 24
                  }
                ]}
              >
                AI summary
              </Text>

              <Text
                style={[
                  styles.caption2,
                  styles.debug,
                  {
                    marginTop: 16
                  }
                ]}
              >
                {t.summary}
              </Text>

              <Text
                style={[
                  styles.label2,
                  styles.debug,
                  {
                    marginTop: 24
                  }
                ]}
              >
                Select quotes about “{t.theme}”
              </Text>

              <View
                style={[
                  styles.row,
                  styles.debug,
                  {
                    marginTop: 16,
                    flexWrap: 'wrap'
                  }
                ]}
              >
                {feedbackByTheme[t.theme].slice(0, FEEDBACK_CARDS_ROWS * FEEDBACK_CARDS_PER_ROW).map((f, index) => (
                  <View
                    key={index}
                    style={[
                      styles.card,
                      styles.debug,
                      {
                        width: 166,
                        minHeight: 100,
                        marginTop: index < FEEDBACK_CARDS_PER_ROW ? 0 : 16, // is first row
                        marginLeft: index % FEEDBACK_CARDS_PER_ROW === 0 ? 0 : 16 // first column
                      }
                    ]}
                  >
                    <Text
                      style={[
                        styles.body1,
                        styles.debug,
                        {
                          maxLines: 10,
                          textOverflow: 'ellipsis'
                        }
                      ]}
                    >
                      {f}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </PagePDF>
        ))}
    </>
  )
}

import { Box, Grid, Pagination, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

const PAGE_SIZE = 12

const CardGrid = ({ items, pageSize = PAGE_SIZE }: { items: any[]; pageSize?: number }) => {
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
  }, [items.length])

  const pageContent = items.slice((page - 1) * pageSize, page * pageSize)
  const totalPages = Math.max(1, Math.ceil(items.length / pageSize))

  return (
    <Stack py={1} alignItems={'center'}>
      <Grid container spacing={4}>
        {pageContent.map(c => (
          <Grid key={c.key} item xs={12} md={4} xl={3} display={'grid'}>
            {c}
          </Grid>
        ))}
      </Grid>
      {totalPages > 1 && (
        <Box mt={2}>
          <Pagination color={'primary'} page={page} onChange={(_, p) => setPage(p)} count={totalPages} />
        </Box>
      )}
    </Stack>
  )
}

export default CardGrid

import { CreatorApprovalStatus, CreatorType, ModelTypes, Region } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CREATORS_FOR_APPROVAL_MARKER: Array<ModelTypes['CampaignCreatorForApproval']> = [
  {
    dateOfBirth: '1992-08-24',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:01:56.126Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'n/a'
    },
    userCampaignId: 'c331c6e6-5601-44b5-aab2-a206d4c584a1',
    creator: {
      sponsoredPosts: [],
      id: 'c331c6e6-5601-44b5-aab2-a206d4c584a1',
      firstName: 'Jessica',
      lastName: 'Lemos',
      email: 'jehgaertner@gmail.com',
      instagramProfile: {
        handle: 'jehgaertnerlemos',
        followers: 10651,
        engagementRate: 7.793634400525773,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'jehgaertner',
        followers: 43,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1997-02-04',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:02:06.480Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Provocative content'
    },
    userCampaignId: 'fe01847d-46c7-4432-9778-2e8fae72dc86',
    creator: {
      sponsoredPosts: [],
      id: 'fe01847d-46c7-4432-9778-2e8fae72dc86',
      firstName: 'Cindy',
      lastName: 'D',
      email: 'alphaklassinc@gmail.com',
      instagramProfile: {
        handle: 'alphachiq',
        followers: 14521,
        engagementRate: 4.375043041112871,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'alphachiq',
        followers: 2797,
        engagementRate: 3.783783783783784,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1994-08-09',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:02:29.750Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Not American origin / audience'
    },
    userCampaignId: 'b6f9b52f-2524-448b-bc57-5855db1ec513',
    creator: {
      sponsoredPosts: [],
      id: 'b6f9b52f-2524-448b-bc57-5855db1ec513',
      firstName: 'Kateryna',
      lastName: 'Tsaruk',
      email: 'ketrintsaruk@gmail.com',
      instagramProfile: {
        handle: 'tsaruk_katrinka',
        followers: 82413,
        engagementRate: 0.3812100841695687,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'katrinkatsaruk',
        followers: 1486,
        engagementRate: 3.9684503557067736,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1982-10-10',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:03:23.436Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Quality of content '
    },
    userCampaignId: 'e1e55897-34be-4f89-9dda-34603adcde87',
    creator: {
      sponsoredPosts: [],
      id: 'e1e55897-34be-4f89-9dda-34603adcde87',
      firstName: 'Priscila',
      lastName: 'Mortensen',
      email: 'priscilamortensen@hotmail.com',
      instagramProfile: {
        handle: 'family.tips.review',
        followers: 3730,
        engagementRate: 0.32171581769436997,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'family.sp.ny',
        followers: 7058,
        engagementRate: 1.346336189975782,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'liviaoliveira',
        followers: 53200
      }
    }
  },
  {
    dateOfBirth: '1988-12-06',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:03:37.369Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Not US based'
    },
    userCampaignId: '96b5997a-0be0-4c8d-a44d-69cc9304ab49',
    creator: {
      sponsoredPosts: [],
      id: '96b5997a-0be0-4c8d-a44d-69cc9304ab49',
      firstName: 'Michele',
      lastName: 'Fedecostante',
      email: 'mikynate88@live.it',
      instagramProfile: {
        handle: 'mikynate',
        followers: 102254,
        engagementRate: 2.666497154145559,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mikynate',
        followers: 166,
        engagementRate: 1.532567049808429,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1992-11-17',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:03:53.288Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Content skews edgy '
    },
    userCampaignId: '8063b3c4-f5b9-4d50-b8a8-64716709bb4d',
    creator: {
      sponsoredPosts: [],
      id: '8063b3c4-f5b9-4d50-b8a8-64716709bb4d',
      firstName: 'Neesh',
      lastName: 'Riaz',
      email: 'neeshceo@gmail.com',
      instagramProfile: {
        handle: 'neesh__me',
        followers: 27480,
        engagementRate: 0.9625181950509462,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'neesh_me',
        followers: 34996,
        engagementRate: 7.463910761154854,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1997-01-30',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:04:00.639Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Pregnant '
    },
    userCampaignId: 'ab6f5574-f6b7-4c14-8672-1898837f66b5',
    creator: {
      sponsoredPosts: [],
      id: 'ab6f5574-f6b7-4c14-8672-1898837f66b5',
      firstName: 'Amanda',
      lastName: 'LaBeske',
      email: 'amandalabeske34@gmail.com',
      tiktokProfile: {
        handle: 'amandanicole_',
        followers: 60432,
        engagementRate: 5.996825876662635,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1994-07-08',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-01-30T17:04:21.791Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Appears to not have American origins / audience'
    },
    userCampaignId: '8cdc1767-57e1-41d2-ad1a-7218d57edc55',
    creator: {
      sponsoredPosts: [],
      id: '8cdc1767-57e1-41d2-ad1a-7218d57edc55',
      firstName: 'Kristina',
      lastName: 'Akbulut',
      email: 'akbulutkristina@gmail.com',
      instagramProfile: {
        handle: 'kristianakhomich',
        followers: 82554,
        engagementRate: 0.4653923492501878,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kristinaakbulut',
        followers: 4181,
        engagementRate: 4.296875,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'kristinaakbulut',
        followers: 38
      }
    }
  },
  {
    dateOfBirth: '1992-01-27',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:11:33.895Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: '1acd7870-905e-4d61-a139-968ac01b8429',
    creator: {
      sponsoredPosts: [],
      id: '1acd7870-905e-4d61-a139-968ac01b8429',
      firstName: 'Mariann',
      lastName: 'Yip',
      email: 'mariann.yip@gmail.com',
      instagramProfile: {
        handle: 'mariann_yip',
        followers: 112933,
        engagementRate: 0.7619562041210276,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mariann_yip',
        followers: 3999,
        engagementRate: 10.036992299562133,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1984-10-31',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:11:27.168Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: '0f318b1c-f767-4471-bec0-d71f75461984',
    creator: {
      sponsoredPosts: [],
      id: '0f318b1c-f767-4471-bec0-d71f75461984',
      firstName: 'Katherine',
      lastName: 'Baker',
      email: 'kittenkbshops@gmail.com',
      instagramProfile: {
        handle: 'katherinemarie.shops',
        followers: 15076,
        engagementRate: 1.4486601220482886,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'kittenkb',
        followers: 2147,
        engagementRate: 4.166666666666666,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1994-12-23',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:11:31.573Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: '2fd36cd1-0cb6-45dd-a0c7-01f4f2c7d4e2',
    creator: {
      sponsoredPosts: [],
      id: '2fd36cd1-0cb6-45dd-a0c7-01f4f2c7d4e2',
      firstName: 'Jessy',
      lastName: 'Clark',
      email: 'jessymaradasilvaclark@gmail.com',
      instagramProfile: {
        handle: '_jessyclark',
        followers: 102166,
        engagementRate: 1.0822582855353053,
        isPrimary: true
      },
      tiktokProfile: {
        handle: '_jessyclark',
        followers: 4187,
        engagementRate: 0.07702888583218706,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1989-09-12',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:12:04.796Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: '28c5f21e-5b47-4c7b-a703-084fd47bcabc',
    creator: {
      sponsoredPosts: [],
      id: '28c5f21e-5b47-4c7b-a703-084fd47bcabc',
      firstName: 'Abby',
      lastName: 'Lane',
      email: 'abbylaneblog@gmail.com',
      instagramProfile: {
        handle: 'lanestyled',
        followers: 20837,
        engagementRate: 7.224168546335845,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'itsmeabbylane',
        followers: 1132,
        engagementRate: 10.279747077984288,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1999-11-25',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:12:11.183Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: 'ed3bbda6-e9f4-4975-8373-1b7fa379d540',
    creator: {
      sponsoredPosts: [],
      id: 'ed3bbda6-e9f4-4975-8373-1b7fa379d540',
      firstName: 'Emily',
      lastName: 'Meyers',
      email: 'emilynnmeyers@gmail.com',
      tiktokProfile: {
        handle: 'emilynnmeyers',
        followers: 23296,
        engagementRate: 5.033665389779675,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-02-27',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:12:25.425Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: '23d257db-858a-4568-9d0a-336abc7caed9',
    creator: {
      sponsoredPosts: [],
      id: '23d257db-858a-4568-9d0a-336abc7caed9',
      firstName: 'Julia',
      lastName: 'Nitchman',
      email: 'j_nitchman@yahoo.com',
      instagramProfile: {
        handle: 'uhhactuallyitsjulia',
        followers: 9648,
        engagementRate: 1.7143449419568821,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'uhhactuallyitsjulia',
        followers: 5851,
        engagementRate: 2.4114783596237794,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1993-05-28',
    locale: Region.US,
    creatorType: CreatorType.UGC,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T01:13:12.547Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com'
    },
    userCampaignId: 'c9bd2669-478e-4cfb-bb90-7657981b944c',
    creator: {
      sponsoredPosts: [],
      id: 'c9bd2669-478e-4cfb-bb90-7657981b944c',
      firstName: 'Angie',
      lastName: 'Yon',
      email: 'ahugandquiche@gmail.com',
      instagramProfile: {
        handle: 'ahugandquiche',
        followers: 45773,
        engagementRate: 0.8363008760623075,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'ahugandquiche',
        followers: 222,
        engagementRate: 6.69792648055404,
        isPrimary: false
      }
    }
  }
]

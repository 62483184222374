import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { agencySelector } from '../queries'

export const updateCompany = async (input: ValueTypes['ModelInputUpdateCompany']) => {
  return (await mApi('mutation')({ updateCompany: [{ input }, { id: true }] })).updateCompany
}

export const createCompany = async (input: ValueTypes['ModelInputCreateCompany']) => {
  return (await mApi('mutation')({ createCompany: [{ input }, { id: true }] })).createCompany
}

export const updateAgency = async (input: ValueTypes['ModelInputUpdateAgency']) => {
  return (await mApi('mutation')({ updateAgency: [{ input }, agencySelector] })).updateAgency
}

export const createAgency = async (input: ValueTypes['ModelInputCreateAgency']) => {
  return (await mApi('mutation')({ createAgency: [{ input }, agencySelector] })).createAgency
}

export const createBrand = async (input: ValueTypes['CreateBrandInput']) => {
  return (await mApi('mutation')({ createBrand: [{ input }, { id: true }] })).createBrand
}

import { ToastContainer, toast } from 'react-toastify'
import { IconButton } from '@mui/material'
import CloseIcon from '@momentum/components/icons/close'
import { InfoOutlined, WarningAmberOutlined, HighlightOffOutlined } from '@mui/icons-material'
import CheckOutlineIcon from '@momentum/components/icons/check-outline'
import { Outlet } from 'react-router-dom'

const TOAST_ICONS = {
  [toast.TYPE.INFO]: <InfoOutlined color={'info'} />,
  [toast.TYPE.SUCCESS]: <CheckOutlineIcon color={'success'} />,
  [toast.TYPE.WARNING]: <WarningAmberOutlined color={'warning'} />,
  [toast.TYPE.ERROR]: <HighlightOffOutlined color={'error'} />
}

export const ToastProvider = () => (
  <>
    <Outlet />
    <ToastContainer
      autoClose={5000}
      theme={'colored'}
      position={'top-right'}
      hideProgressBar
      closeButton={({ closeToast }) => (
        <IconButton onClick={closeToast} size={'small'} disableRipple>
          <CloseIcon sx={{ color: 'black', alignSelf: 'center' }} />
        </IconButton>
      )}
      icon={p => TOAST_ICONS[p.type as any]}
      closeOnClick={false}
    />
  </>
)

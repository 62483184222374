import { createTheme } from '@mui/material/styles'
import { breakpoints } from '../breakpoints'
import { deepmerge } from '@mui/utils'
import theme from './global'

export const momentumThemeDefinition = {
  breakpoints: breakpoints,
  typography: {
    fontFamily: ['"Avenir"'].join(','),
    allVariants: {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      letterSpacing: '0.02em'
    },
    label1: {
      fontSize: '12px',
      fontWeight: 800,
      lineHeight: '20px',
      letterSpacing: '0.02em'
    }
  },
  palette: {
    primary: {
      main: '#002880'
    },
    secondary: {
      main: '#027BC6'
    }
  }
}
export const momentumTheme = createTheme(deepmerge(theme, momentumThemeDefinition))

// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_SEO_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/getProductSeoSummary/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_SEO_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getProductSeoSummary/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_SEO_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getProductSeoSummary/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_SEO_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getProductSeoSummary/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_SEO_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getProductSeoSummary/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_PRODUCT_SEO_METRICS: Record<string, ModelTypes['ProductSeoSummary']> = {
  'sample-sku-headphones': SAMPLE_CAMPAIGN_SEO_HEADPHONES,
  'sample-sku-water-flosser': SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.skuId]: SAMPLE_CAMPAIGN_SEO_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.skuId]: SAMPLE_CAMPAIGN_SEO_ENERGY_DRINK_PD_EXISTING,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.skuId]: SAMPLE_CAMPAIGN_SEO_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: SAMPLE_CAMPAIGN_SEO_WIRELESS_EARBUDS
}

import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyString } from '@productwindtom/shared-ws-currency'
import { useCampaignContext } from '../../context/CampaignContext'
import { SalesReportView, useSalesContext } from './salesContext'

export const useValueFormatter = () => {
  const { campaignDetails } = useCampaignContext()
  const { salesReportView } = useSalesContext()

  return (value: number) => {
    return salesReportView === SalesReportView.REVENUE
      ? toCurrencyString(value / 100, STORE_TO_LOCALE[campaignDetails.product.store])
      : value.toLocaleString()
  }
}

import Row from '@momentum/components/row'
import { Search } from '@mui/icons-material'
import { Box } from '@mui/material'
import { SelectInput, TextInput } from '@productwindtom/ui-base'
import { ContentSortBy } from '@momentum/sections/content/types'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import ContentTypeFilter from './ContentTypeFilter'
import { useContentContext } from '../context'

const ContentFilters = () => {
  const { campaigns } = useBrandContext()
  const { isSearch } = useContentContext()

  return (
    <Row spacing={2} width={'100%'}>
      <Box
        sx={{
          flex: '0 0 50%'
        }}
      >
        <TextInput
          fullWidth
          name="search"
          placeholder="Search"
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Box>
      <Box flex={1}>
        <ContentTypeFilter />
      </Box>
      <Box flex={1}>
        <SelectInput
          fullWidth
          name="sortBy"
          placeholder="Sort by"
          autocomplete
          options={Object.values(ContentSortBy).map(value => ({
            value,
            label: value
          }))}
          disableTags
          disableClearable
        />
      </Box>
      {isSearch && (
        <Box flex={1}>
          <SelectInput
            fullWidth
            name="campaignIds"
            placeholder="All campaigns"
            multiple
            autocomplete
            disableTags
            disableClearable
            options={(campaigns || [])?.map(({ id, product }) => ({
              label: product?.name,
              value: id
            }))}
          />
        </Box>
      )}
    </Row>
  )
}

export default ContentFilters

import { Stack, Typography } from '@mui/material'
import React from 'react'
import { NotSupportedIcon } from './NotSupportedIcon'

export const NotSupported = () => {
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <NotSupportedIcon sx={{ height: '191px', width: '178px' }} />

      <Typography variant={'h5'} textAlign={'center'}>
        Campaigns with only Brand Advocates do not generate reviews.
      </Typography>
      <Typography variant={'label4'} width={'327px'} textAlign={'center'}>
        Create a campaign with UGC Creators to generate reviews for your product.
      </Typography>
    </Stack>
  )
}

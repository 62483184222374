import { CampaignPerformance } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { PerformanceComparisonMetric, PerformanceSummaryTitleType } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_PERFORMANCE_OUTDOOR_CAMERA_BOOST: CampaignPerformance = {
  campaignId: SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id,
  skuId: SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.skuId,
  promotedProductId: '4035f60c-edf6-4662-aea4-ba1eb28bee03',
  isVisible: false,

  comparisonMetric: PerformanceComparisonMetric.REVIEWS,
  comparisonMetricValue: 100,
  summaryTitle: PerformanceSummaryTitleType.SIMILAR_PRODUCT,
  products: [
    {
      id: 'e23f8241-251d-492b-9c2b-1b027c028fd6',
      productName: 'Outdoor Wireless Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-2.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2023-10-20',

          salesRank: 701
        },
        {
          date: '2023-10-21',

          salesRank: 911
        },
        {
          date: '2023-10-22',

          salesRank: 1044
        },
        {
          date: '2023-10-23',

          salesRank: 686
        },
        {
          date: '2023-10-24',

          salesRank: 968
        },
        {
          date: '2023-10-25',

          salesRank: 1156
        },
        {
          date: '2023-10-26',

          salesRank: 1305
        },
        {
          date: '2023-10-27',

          salesRank: 1454
        },
        {
          date: '2023-10-28',

          salesRank: 1581
        },
        {
          date: '2023-10-29',

          salesRank: 1678
        },
        {
          date: '2023-10-30',

          salesRank: 251
        },
        {
          date: '2023-10-31',

          salesRank: 270
        },
        {
          date: '2023-11-01',

          salesRank: 243
        },
        {
          date: '2023-11-02',

          salesRank: 291
        },
        {
          date: '2023-11-03',

          salesRank: 215
        },
        {
          date: '2023-11-04',

          salesRank: 154
        },
        {
          date: '2023-11-05',

          salesRank: 90
        },
        {
          date: '2023-11-06',

          salesRank: 118
        },
        {
          date: '2023-11-07',

          salesRank: 174
        },
        {
          date: '2023-11-08',

          salesRank: 167
        },
        {
          date: '2023-11-09',

          salesRank: 165
        },
        {
          date: '2023-11-10',

          salesRank: 210
        },
        {
          date: '2023-11-11',

          salesRank: 215
        },
        {
          date: '2023-11-12',

          salesRank: 216
        },
        {
          date: '2023-11-13',

          salesRank: 239
        },
        {
          date: '2023-11-14',

          salesRank: 189
        },
        {
          date: '2023-11-15',

          salesRank: 238
        },
        {
          date: '2023-11-16',

          salesRank: 164
        },
        {
          date: '2023-11-17',

          salesRank: 150
        },
        {
          date: '2023-11-18',

          salesRank: 111
        },
        {
          date: '2023-11-19',

          salesRank: 167
        },
        {
          date: '2023-11-20',

          salesRank: 191
        },
        {
          date: '2023-11-21',

          salesRank: 171
        },
        {
          date: '2023-11-22',

          salesRank: 159
        },
        {
          date: '2023-11-23',

          salesRank: 156
        },
        {
          date: '2023-11-24',

          salesRank: 101
        },
        {
          date: '2023-11-25',

          salesRank: 112
        },
        {
          date: '2023-11-26',

          salesRank: 121
        },
        {
          date: '2023-11-27',

          salesRank: 126
        },
        {
          date: '2023-11-28',

          salesRank: 225
        },
        {
          date: '2023-11-29',

          salesRank: 241
        },
        {
          date: '2023-11-30',

          salesRank: 268
        },
        {
          date: '2023-12-01',

          salesRank: 303
        },
        {
          date: '2023-12-02',

          salesRank: 372
        },
        {
          date: '2023-12-03',

          salesRank: 284
        },
        {
          date: '2023-12-04',

          salesRank: 278
        },
        {
          date: '2023-12-05',

          salesRank: 247
        },
        {
          date: '2023-12-06',

          salesRank: 304
        },
        {
          date: '2023-12-07',

          salesRank: 301
        },
        {
          date: '2023-12-08',

          salesRank: 414
        },
        {
          date: '2023-12-09',

          salesRank: 210
        },
        {
          date: '2023-12-10',

          salesRank: 235
        },
        {
          date: '2023-12-11',

          salesRank: 231
        },
        {
          date: '2023-12-12',

          salesRank: 154
        },
        {
          date: '2023-12-13',

          salesRank: 220
        },
        {
          date: '2023-12-14',

          salesRank: 309
        },
        {
          date: '2023-12-15',

          salesRank: 248
        },
        {
          date: '2023-12-16',

          salesRank: 304
        },
        {
          date: '2023-12-17',

          salesRank: 338
        },
        {
          date: '2023-12-18',

          salesRank: 200
        },
        {
          date: '2023-12-19',

          salesRank: 84
        },
        {
          date: '2023-12-20',

          salesRank: 112
        },
        {
          date: '2023-12-21',

          salesRank: 100
        },
        {
          date: '2023-12-22',

          salesRank: 93
        },
        {
          date: '2023-12-23',

          salesRank: 83
        },
        {
          date: '2023-12-24',

          salesRank: 88
        },
        {
          date: '2023-12-25',

          salesRank: 123
        },
        {
          date: '2023-12-26',

          salesRank: 190
        },
        {
          date: '2023-12-27',

          salesRank: 181
        },
        {
          date: '2023-12-28',

          salesRank: 126
        },
        {
          date: '2023-12-29',

          salesRank: 127
        },
        {
          date: '2023-12-30',

          salesRank: 169
        },
        {
          date: '2023-12-31',

          salesRank: 149
        },
        {
          date: '2024-01-01',

          salesRank: 118
        },
        {
          date: '2024-01-02',

          salesRank: 161
        },
        {
          date: '2024-01-03',

          salesRank: 152
        },
        {
          date: '2024-01-04',

          salesRank: 111
        },
        {
          date: '2024-01-05',

          salesRank: 191
        },
        {
          date: '2024-01-06',

          salesRank: 200
        },
        {
          date: '2024-01-07',

          salesRank: 244
        },
        {
          date: '2024-01-08',

          salesRank: 258
        },
        {
          date: '2024-01-09',

          salesRank: 292
        },
        {
          date: '2024-01-10',

          salesRank: 82
        },
        {
          date: '2024-01-11',

          salesRank: 65
        },
        {
          date: '2024-01-12',

          salesRank: 83
        },
        {
          date: '2024-01-13',

          salesRank: 75
        },
        {
          date: '2024-01-14',

          salesRank: 99
        },
        {
          date: '2024-01-15',

          salesRank: 115
        },
        {
          date: '2024-01-16',

          salesRank: 95
        },
        {
          date: '2024-01-17',

          salesRank: 132
        },
        {
          date: '2024-01-18',

          salesRank: 144
        },
        {
          date: '2024-01-19',

          salesRank: 182
        },
        {
          date: '2024-01-20',

          salesRank: 191
        },
        {
          date: '2024-01-21',

          salesRank: 298
        },
        {
          date: '2024-01-22',

          salesRank: 353
        },
        {
          date: '2024-01-23',

          salesRank: 199
        },
        {
          date: '2024-01-24',

          salesRank: 244
        },
        {
          date: '2024-01-25',

          salesRank: 179
        },
        {
          date: '2024-01-26',

          salesRank: 118
        },
        {
          date: '2024-01-27',

          salesRank: 79
        },
        {
          date: '2024-01-28',

          salesRank: 112
        },
        {
          date: '2024-01-29',

          salesRank: 154
        },
        {
          date: '2024-01-30',

          salesRank: 141
        },
        {
          date: '2024-01-31',

          salesRank: 115
        },
        {
          date: '2024-02-01',

          salesRank: 105
        },
        {
          date: '2024-02-02',

          salesRank: 105
        },
        {
          date: '2024-02-03',

          salesRank: 98
        },
        {
          date: '2024-02-04',

          salesRank: 114
        },
        {
          date: '2024-02-05',

          salesRank: 94
        },
        {
          date: '2024-02-06',

          salesRank: 93
        },
        {
          date: '2024-02-07',

          salesRank: 115
        },
        {
          date: '2024-02-08',

          salesRank: 118
        },
        {
          date: '2024-02-09',

          salesRank: 129
        },
        {
          date: '2024-02-10',

          salesRank: 102
        },
        {
          date: '2024-02-11',

          salesRank: 101
        },
        {
          date: '2024-02-12',

          salesRank: 117
        },
        {
          date: '2024-02-13',

          salesRank: 117
        },
        {
          date: '2024-02-14',

          salesRank: 105
        },
        {
          date: '2024-02-15',

          salesRank: 86
        },
        {
          date: '2024-02-16',

          salesRank: 126
        },
        {
          date: '2024-02-17',

          salesRank: 90
        },
        {
          date: '2024-02-18',

          salesRank: 91
        },
        {
          date: '2024-02-19',

          salesRank: 108
        },
        {
          date: '2024-02-20',

          salesRank: 95
        },
        {
          date: '2024-02-21',

          salesRank: 101
        },
        {
          date: '2024-02-22',

          salesRank: 126
        },
        {
          date: '2024-02-23',

          salesRank: 119
        },
        {
          date: '2024-02-24',

          salesRank: 117
        },
        {
          date: '2024-02-25',

          salesRank: 120
        },
        {
          date: '2024-02-26',

          salesRank: 101
        },
        {
          date: '2024-02-27',

          salesRank: 114
        },
        {
          date: '2024-02-28',

          salesRank: 108
        },
        {
          date: '2024-02-29',

          salesRank: 121
        },
        {
          date: '2024-03-01',

          salesRank: 92
        },
        {
          date: '2024-03-02',

          salesRank: 106
        },
        {
          date: '2024-03-03',

          salesRank: 104
        },
        {
          date: '2024-03-04',

          salesRank: 102
        },
        {
          date: '2024-03-05',

          salesRank: 79
        },
        {
          date: '2024-03-06',

          salesRank: 102
        },
        {
          date: '2024-03-07',

          salesRank: 97
        },
        {
          date: '2024-03-08',

          salesRank: 111
        },
        {
          date: '2024-03-09',

          salesRank: 108
        },
        {
          date: '2024-03-10',

          salesRank: 97
        },
        {
          date: '2024-03-11',

          salesRank: 122
        },
        {
          date: '2024-03-12',

          salesRank: 105
        },
        {
          date: '2024-03-13',

          salesRank: 112
        },
        {
          date: '2024-03-14',

          salesRank: 124
        },
        {
          date: '2024-03-15',

          salesRank: 118
        },
        {
          date: '2024-03-16',

          salesRank: 133
        },
        {
          date: '2024-03-17',

          salesRank: 111
        },
        {
          date: '2024-03-18',

          salesRank: 136
        },
        {
          date: '2024-03-19',

          salesRank: 114
        },
        {
          date: '2024-03-20',

          salesRank: 161
        },
        {
          date: '2024-03-21',

          salesRank: 140
        },
        {
          date: '2024-03-22',

          salesRank: 147
        },
        {
          date: '2024-03-23',

          salesRank: 147
        },
        {
          date: '2024-03-24',

          salesRank: 154
        },
        {
          date: '2024-03-25',

          salesRank: 154
        },
        {
          date: '2024-03-26',

          salesRank: 140
        },
        {
          date: '2024-03-27',

          salesRank: 121
        },
        {
          date: '2024-03-28',

          salesRank: 116
        },
        {
          date: '2024-03-29',

          salesRank: 92
        },
        {
          date: '2024-03-30',

          salesRank: 92
        },
        {
          date: '2024-03-31',

          salesRank: 77
        },
        {
          date: '2024-04-01',

          salesRank: 89
        },
        {
          date: '2024-04-02',

          salesRank: 70
        },
        {
          date: '2024-04-03',

          salesRank: 85
        },
        {
          date: '2024-04-04',

          salesRank: 108
        },
        {
          date: '2024-04-05',

          salesRank: 97
        },
        {
          date: '2024-04-06',

          salesRank: 112
        },
        {
          date: '2024-04-07',

          salesRank: 132
        },
        {
          date: '2024-04-08',

          salesRank: 100
        },
        {
          date: '2024-04-09',

          salesRank: 125
        },
        {
          date: '2024-04-10',

          salesRank: 148
        },
        {
          date: '2024-04-11',

          salesRank: 127
        },
        {
          date: '2024-04-12',

          salesRank: 135
        },
        {
          date: '2024-04-13',

          salesRank: 132
        },
        {
          date: '2024-04-14',

          salesRank: 138
        },
        {
          date: '2024-04-15',

          salesRank: 113
        },
        {
          date: '2024-04-16',

          salesRank: 138
        },
        {
          date: '2024-04-17',

          salesRank: 118
        },
        {
          date: '2024-04-18',

          salesRank: 115
        },
        {
          date: '2024-04-19',

          salesRank: 170
        },
        {
          date: '2024-04-20',

          salesRank: 150
        },
        {
          date: '2024-04-21',

          salesRank: 161
        },
        {
          date: '2024-04-22',

          salesRank: 151
        },
        {
          date: '2024-04-23',

          salesRank: 144
        },
        {
          date: '2024-04-24',

          salesRank: 143
        },
        {
          date: '2024-04-25',

          salesRank: 130
        },
        {
          date: '2024-04-26',

          salesRank: 111
        },
        {
          date: '2024-04-27',

          salesRank: 77
        },
        {
          date: '2024-04-28',

          salesRank: 67
        },
        {
          date: '2024-04-29',

          salesRank: 59
        },
        {
          date: '2024-04-30',

          salesRank: 48
        },
        {
          date: '2024-05-01',

          salesRank: 50
        },
        {
          date: '2024-05-02',

          salesRank: 40
        },
        {
          date: '2024-05-03',

          salesRank: 45
        },
        {
          date: '2024-05-04',

          salesRank: 47
        },
        {
          date: '2024-05-05',

          salesRank: 56
        },
        {
          date: '2024-05-06',

          salesRank: 37
        },
        {
          date: '2024-05-07',

          salesRank: 45
        },
        {
          date: '2024-05-08',

          salesRank: 42
        },
        {
          date: '2024-05-09',

          salesRank: 37
        },
        {
          date: '2024-05-10',

          salesRank: 34
        },
        {
          date: '2024-05-11',

          salesRank: 48
        },
        {
          date: '2024-05-12',

          salesRank: 55
        },
        {
          date: '2024-05-13',

          salesRank: 38
        },
        {
          date: '2024-05-14',

          salesRank: 41
        },
        {
          date: '2024-05-15',

          salesRank: 36
        },
        {
          date: '2024-05-16',

          salesRank: 46
        },
        {
          date: '2024-05-17',

          salesRank: 58
        },
        {
          date: '2024-05-18',

          salesRank: 88
        },
        {
          date: '2024-05-19',

          salesRank: 69
        },
        {
          date: '2024-05-20',

          salesRank: 48
        },
        {
          date: '2024-05-21',

          salesRank: 61
        },
        {
          date: '2024-05-22',

          salesRank: 49
        },
        {
          date: '2024-05-23',

          salesRank: 56
        },
        {
          date: '2024-05-24',

          salesRank: 62
        },
        {
          date: '2024-05-25',

          salesRank: 55
        },
        {
          date: '2024-05-26',

          salesRank: 50
        },
        {
          date: '2024-05-27',

          salesRank: 47
        },
        {
          date: '2024-05-28',

          salesRank: 67
        },
        {
          date: '2024-05-29',

          salesRank: 100
        },
        {
          date: '2024-05-30',

          salesRank: 71
        },
        {
          date: '2024-05-31',

          salesRank: 65
        },
        {
          date: '2024-06-01',

          salesRank: 77
        },
        {
          date: '2024-06-02',

          salesRank: 78
        },
        {
          date: '2024-06-03',

          salesRank: 57
        },
        {
          date: '2024-06-04',

          salesRank: 58
        },
        {
          date: '2024-06-05',

          salesRank: 66
        },
        {
          date: '2024-06-06',

          salesRank: 59
        },
        {
          date: '2024-06-07',

          salesRank: 65
        },
        {
          date: '2024-06-08',

          salesRank: 53
        },
        {
          date: '2024-06-09',

          salesRank: 62
        },
        {
          date: '2024-06-10',

          salesRank: 47
        },
        {
          date: '2024-06-11',

          salesRank: 56
        },
        {
          date: '2024-06-12',

          salesRank: 53
        },
        {
          date: '2024-06-13',

          salesRank: 62
        },
        {
          date: '2024-06-14',

          salesRank: 52
        },
        {
          date: '2024-06-15',

          salesRank: 63
        },
        {
          date: '2024-06-16',

          salesRank: 55
        },
        {
          date: '2024-06-17',

          salesRank: 61
        },
        {
          date: '2024-06-18',

          salesRank: 56
        },
        {
          date: '2024-06-19',

          salesRank: 51
        },
        {
          date: '2024-06-20',

          salesRank: 64
        },
        {
          date: '2024-06-21',

          salesRank: 64
        },
        {
          date: '2024-06-22',

          salesRank: 63
        },
        {
          date: '2024-06-23',

          salesRank: 64
        },
        {
          date: '2024-06-24',

          salesRank: 68
        },
        {
          date: '2024-06-25',

          salesRank: 55
        },
        {
          date: '2024-06-26',

          salesRank: 58
        },
        {
          date: '2024-06-27',

          salesRank: 62
        },
        {
          date: '2024-06-28',

          salesRank: 52
        },
        {
          date: '2024-06-29',

          salesRank: 63
        },
        {
          date: '2024-06-30',

          salesRank: 55
        },
        {
          date: '2024-07-01',

          salesRank: 64
        },
        {
          date: '2024-07-02',

          salesRank: 58
        },
        {
          date: '2024-07-03',

          salesRank: 66
        },
        {
          date: '2024-07-04',

          salesRank: 68
        },
        {
          date: '2024-07-05',

          salesRank: 60
        },
        {
          date: '2024-07-06',

          salesRank: 55
        },
        {
          date: '2024-07-07',

          salesRank: 58
        },
        {
          date: '2024-07-08',

          salesRank: 49
        },
        {
          date: '2024-07-09',

          salesRank: 56
        },
        {
          date: '2024-07-10',

          salesRank: 52
        },
        {
          date: '2024-07-11',

          salesRank: 54
        },
        {
          date: '2024-07-12',

          salesRank: 66
        },
        {
          date: '2024-07-13',

          salesRank: 67
        },
        {
          date: '2024-07-14',

          salesRank: 70
        },
        {
          date: '2024-07-15',

          salesRank: 54
        },
        {
          date: '2024-07-16',

          salesRank: 56
        },
        {
          date: '2024-07-17',

          salesRank: 68
        },
        {
          date: '2024-07-18',

          salesRank: 64
        },
        {
          date: '2024-07-19',

          salesRank: 58
        },
        {
          date: '2024-07-20',

          salesRank: 57
        },
        {
          date: '2024-07-21',

          salesRank: 59
        },
        {
          date: '2024-07-22',

          salesRank: 62
        },
        {
          date: '2024-07-23',

          salesRank: 86
        },
        {
          date: '2024-07-24',

          salesRank: 78
        },
        {
          date: '2024-07-25',

          salesRank: 74
        },
        {
          date: '2024-07-26',

          salesRank: 75
        },
        {
          date: '2024-07-27',

          salesRank: 78
        },
        {
          date: '2024-07-28',

          salesRank: 76
        },
        {
          date: '2024-07-29',

          salesRank: 79
        },
        {
          date: '2024-07-30',

          salesRank: 77
        },
        {
          date: '2024-07-31',

          salesRank: 72
        },
        {
          date: '2024-08-01',

          salesRank: 76
        },
        {
          date: '2024-08-02',

          salesRank: 81
        },
        {
          date: '2024-08-03',

          salesRank: 86
        },
        {
          date: '2024-08-04',

          salesRank: 76
        },
        {
          date: '2024-08-05',

          salesRank: 82
        },
        {
          date: '2024-08-06',

          salesRank: 84
        },
        {
          date: '2024-08-07',

          salesRank: 67
        },
        {
          date: '2024-08-08',

          salesRank: 68
        },
        {
          date: '2024-08-09',

          salesRank: 68
        },
        {
          date: '2024-08-10',

          salesRank: 80
        },
        {
          date: '2024-08-11',

          salesRank: 86
        },
        {
          date: '2024-08-12',

          salesRank: 70
        },
        {
          date: '2024-08-13',

          salesRank: 69
        },
        {
          date: '2024-08-14',

          salesRank: 71
        },
        {
          date: '2024-08-15',

          salesRank: 69
        },
        {
          date: '2024-08-16',

          salesRank: 64
        },
        {
          date: '2024-08-19',

          salesRank: 42
        },
        {
          date: '2024-08-20',

          salesRank: 33
        },
        {
          date: '2024-08-22',

          salesRank: 43
        },
        {
          date: '2024-08-23',

          salesRank: 47
        },
        {
          date: '2024-08-24',

          salesRank: 52
        },
        {
          date: '2024-08-25',

          salesRank: 47
        },
        {
          date: '2024-08-26',

          salesRank: 47
        },
        {
          date: '2024-08-27',

          salesRank: 46
        },
        {
          date: '2024-08-28',

          salesRank: 44
        },
        {
          date: '2024-08-29',

          salesRank: 55
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-29T22:05:13.842Z'
    },
    {
      id: '908256c2-0583-4935-bac6-c97092b994ee',
      productName: 'Outdoor Wired Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-3.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2023-09-28',

          salesRank: 58
        },
        {
          date: '2023-09-29',

          salesRank: 60
        },
        {
          date: '2023-09-30',

          salesRank: 47
        },
        {
          date: '2023-10-01',

          salesRank: 44
        },
        {
          date: '2023-10-02',

          salesRank: 65
        },
        {
          date: '2023-10-03',

          salesRank: 71
        },
        {
          date: '2023-10-04',

          salesRank: 79
        },
        {
          date: '2023-10-05',

          salesRank: 80
        },
        {
          date: '2023-10-06',

          salesRank: 99
        },
        {
          date: '2023-10-07',

          salesRank: 94
        },
        {
          date: '2023-10-08',

          salesRank: 107
        },
        {
          date: '2023-10-09',

          salesRank: 99
        },
        {
          date: '2023-10-10',

          salesRank: 84
        },
        {
          date: '2023-10-11',

          salesRank: 79
        },
        {
          date: '2023-10-12',

          salesRank: 69
        },
        {
          date: '2023-10-13',

          salesRank: 65
        },
        {
          date: '2023-10-14',

          salesRank: 74
        },
        {
          date: '2023-10-15',

          salesRank: 61
        },
        {
          date: '2023-10-16',

          salesRank: 67
        },
        {
          date: '2023-10-17',

          salesRank: 71
        },
        {
          date: '2023-10-18',

          salesRank: 64
        },
        {
          date: '2023-10-19',

          salesRank: 61
        },
        {
          date: '2023-10-20',

          salesRank: 56
        },
        {
          date: '2023-10-21',

          salesRank: 65
        },
        {
          date: '2023-10-22',

          salesRank: 56
        },
        {
          date: '2023-10-23',

          salesRank: 69
        },
        {
          date: '2023-10-24',

          salesRank: 58
        },
        {
          date: '2023-10-25',

          salesRank: 64
        },
        {
          date: '2023-10-26',

          salesRank: 59
        },
        {
          date: '2023-10-27',

          salesRank: 56
        },
        {
          date: '2023-10-28',

          salesRank: 62
        },
        {
          date: '2023-10-29',

          salesRank: 63
        },
        {
          date: '2023-10-30',

          salesRank: 60
        },
        {
          date: '2023-10-31',

          salesRank: 63
        },
        {
          date: '2023-11-01',

          salesRank: 63
        },
        {
          date: '2023-11-02',

          salesRank: 55
        },
        {
          date: '2023-11-03',

          salesRank: 65
        },
        {
          date: '2023-11-04',

          salesRank: 60
        },
        {
          date: '2023-11-05',

          salesRank: 59
        },
        {
          date: '2023-11-06',

          salesRank: 65
        },
        {
          date: '2023-11-07',

          salesRank: 66
        },
        {
          date: '2023-11-08',

          salesRank: 72
        },
        {
          date: '2023-11-09',

          salesRank: 73
        },
        {
          date: '2023-11-10',

          salesRank: 68
        },
        {
          date: '2023-11-11',

          salesRank: 69
        },
        {
          date: '2023-11-12',

          salesRank: 61
        },
        {
          date: '2023-11-13',

          salesRank: 66
        },
        {
          date: '2023-11-14',

          salesRank: 64
        },
        {
          date: '2023-11-15',

          salesRank: 63
        },
        {
          date: '2023-11-16',

          salesRank: 68
        },
        {
          date: '2023-11-17',

          salesRank: 12
        },
        {
          date: '2023-11-19',

          salesRank: 14
        },
        {
          date: '2023-11-20',

          salesRank: 16
        },
        {
          date: '2023-11-22',

          salesRank: 15
        },
        {
          date: '2023-11-23',

          salesRank: 18
        },
        {
          date: '2023-11-24',

          salesRank: 15
        },
        {
          date: '2023-11-25',

          salesRank: 16
        },
        {
          date: '2023-11-27',

          salesRank: 17
        },
        {
          date: '2023-11-28',

          salesRank: 21
        },
        {
          date: '2023-11-29',

          salesRank: 24
        },
        {
          date: '2023-11-30',

          salesRank: 33
        },
        {
          date: '2023-12-01',

          salesRank: 45
        },
        {
          date: '2023-12-02',

          salesRank: 53
        },
        {
          date: '2023-12-03',

          salesRank: 53
        },
        {
          date: '2023-12-04',

          salesRank: 62
        },
        {
          date: '2023-12-05',

          salesRank: 64
        },
        {
          date: '2023-12-06',

          salesRank: 47
        },
        {
          date: '2023-12-07',

          salesRank: 68
        },
        {
          date: '2023-12-08',

          salesRank: 63
        },
        {
          date: '2023-12-09',

          salesRank: 55
        },
        {
          date: '2023-12-10',

          salesRank: 57
        },
        {
          date: '2023-12-11',

          salesRank: 52
        },
        {
          date: '2023-12-12',

          salesRank: 58
        },
        {
          date: '2023-12-13',

          salesRank: 55
        },
        {
          date: '2023-12-14',

          salesRank: 60
        },
        {
          date: '2023-12-15',

          salesRank: 59
        },
        {
          date: '2023-12-17',

          salesRank: 79
        },
        {
          date: '2023-12-18',

          salesRank: 21
        },
        {
          date: '2023-12-19',

          salesRank: 24
        },
        {
          date: '2023-12-20',

          salesRank: 23
        },
        {
          date: '2023-12-21',

          salesRank: 24
        },
        {
          date: '2023-12-22',

          salesRank: 23
        },
        {
          date: '2023-12-23',

          salesRank: 21
        },
        {
          date: '2023-12-24',

          salesRank: 23
        },
        {
          date: '2023-12-25',

          salesRank: 27
        },
        {
          date: '2023-12-26',

          salesRank: 48
        },
        {
          date: '2023-12-27',

          salesRank: 56
        },
        {
          date: '2023-12-28',

          salesRank: 65
        },
        {
          date: '2023-12-29',

          salesRank: 59
        },
        {
          date: '2023-12-30',

          salesRank: 59
        },
        {
          date: '2023-12-31',

          salesRank: 65
        },
        {
          date: '2024-01-01',

          salesRank: 63
        },
        {
          date: '2024-01-02',

          salesRank: 64
        },
        {
          date: '2024-01-03',

          salesRank: 54
        },
        {
          date: '2024-01-04',

          salesRank: 62
        },
        {
          date: '2024-01-05',

          salesRank: 68
        },
        {
          date: '2024-01-06',

          salesRank: 74
        },
        {
          date: '2024-01-07',

          salesRank: 66
        },
        {
          date: '2024-01-08',

          salesRank: 58
        },
        {
          date: '2024-01-09',

          salesRank: 66
        },
        {
          date: '2024-01-10',

          salesRank: 62
        },
        {
          date: '2024-01-11',

          salesRank: 67
        },
        {
          date: '2024-01-12',

          salesRank: 67
        },
        {
          date: '2024-01-13',

          salesRank: 67
        },
        {
          date: '2024-01-14',

          salesRank: 74
        },
        {
          date: '2024-01-15',

          salesRank: 75
        },
        {
          date: '2024-01-16',

          salesRank: 71
        },
        {
          date: '2024-01-17',

          salesRank: 88
        },
        {
          date: '2024-01-18',

          salesRank: 84
        },
        {
          date: '2024-01-19',

          salesRank: 76
        },
        {
          date: '2024-01-20',

          salesRank: 83
        },
        {
          date: '2024-01-21',

          salesRank: 87
        },
        {
          date: '2024-01-22',

          salesRank: 74
        },
        {
          date: '2024-01-23',

          salesRank: 45
        },
        {
          date: '2024-01-25',

          salesRank: 40
        },
        {
          date: '2024-01-26',

          salesRank: 38
        },
        {
          date: '2024-01-27',

          salesRank: 43
        },
        {
          date: '2024-01-28',

          salesRank: 40
        },
        {
          date: '2024-01-29',

          salesRank: 33
        },
        {
          date: '2024-01-30',

          salesRank: 57
        },
        {
          date: '2024-01-31',

          salesRank: 65
        },
        {
          date: '2024-02-01',

          salesRank: 78
        },
        {
          date: '2024-02-02',

          salesRank: 72
        },
        {
          date: '2024-02-03',

          salesRank: 74
        },
        {
          date: '2024-02-04',

          salesRank: 81
        },
        {
          date: '2024-02-05',

          salesRank: 81
        },
        {
          date: '2024-02-06',

          salesRank: 74
        },
        {
          date: '2024-02-07',

          salesRank: 57
        },
        {
          date: '2024-02-08',

          salesRank: 61
        },
        {
          date: '2024-02-09',

          salesRank: 74
        },
        {
          date: '2024-02-10',

          salesRank: 61
        },
        {
          date: '2024-02-11',

          salesRank: 59
        },
        {
          date: '2024-02-12',

          salesRank: 71
        },
        {
          date: '2024-02-13',

          salesRank: 62
        },
        {
          date: '2024-02-14',

          salesRank: 79
        },
        {
          date: '2024-02-15',

          salesRank: 67
        },
        {
          date: '2024-02-16',

          salesRank: 58
        },
        {
          date: '2024-02-17',

          salesRank: 63
        },
        {
          date: '2024-02-18',

          salesRank: 55
        },
        {
          date: '2024-02-19',

          salesRank: 52
        },
        {
          date: '2024-02-20',

          salesRank: 25
        },
        {
          date: '2024-02-21',

          salesRank: 28
        },
        {
          date: '2024-02-22',

          salesRank: 27
        },
        {
          date: '2024-02-23',

          salesRank: 30
        },
        {
          date: '2024-02-24',

          salesRank: 31
        },
        {
          date: '2024-02-25',

          salesRank: 34
        },
        {
          date: '2024-02-26',

          salesRank: 38
        },
        {
          date: '2024-02-27',

          salesRank: 53
        },
        {
          date: '2024-02-28',

          salesRank: 61
        },
        {
          date: '2024-02-29',

          salesRank: 58
        },
        {
          date: '2024-03-01',

          salesRank: 58
        },
        {
          date: '2024-03-02',

          salesRank: 59
        },
        {
          date: '2024-03-03',

          salesRank: 63
        },
        {
          date: '2024-03-04',

          salesRank: 64
        },
        {
          date: '2024-03-05',

          salesRank: 62
        },
        {
          date: '2024-03-06',

          salesRank: 56
        },
        {
          date: '2024-03-07',

          salesRank: 69
        },
        {
          date: '2024-03-08',

          salesRank: 72
        },
        {
          date: '2024-03-09',

          salesRank: 68
        },
        {
          date: '2024-03-10',

          salesRank: 76
        },
        {
          date: '2024-03-11',

          salesRank: 68
        },
        {
          date: '2024-03-12',

          salesRank: 57
        },
        {
          date: '2024-03-13',

          salesRank: 54
        },
        {
          date: '2024-03-14',

          salesRank: 57
        },
        {
          date: '2024-03-15',

          salesRank: 68
        },
        {
          date: '2024-03-16',

          salesRank: 53
        },
        {
          date: '2024-03-17',

          salesRank: 52
        },
        {
          date: '2024-03-18',

          salesRank: 49
        },
        {
          date: '2024-03-19',

          salesRank: 15
        },
        {
          date: '2024-03-20',

          salesRank: 16
        },
        {
          date: '2024-03-21',

          salesRank: 18
        },
        {
          date: '2024-03-22',

          salesRank: 15
        },
        {
          date: '2024-03-23',

          salesRank: 15
        },
        {
          date: '2024-03-24',

          salesRank: 15
        },
        {
          date: '2024-03-25',

          salesRank: 14
        },
        {
          date: '2024-03-26',

          salesRank: 18
        },
        {
          date: '2024-03-27',

          salesRank: 19
        },
        {
          date: '2024-03-28',

          salesRank: 26
        },
        {
          date: '2024-03-29',

          salesRank: 29
        },
        {
          date: '2024-03-30',

          salesRank: 30
        },
        {
          date: '2024-04-01',

          salesRank: 31
        },
        {
          date: '2024-04-02',

          salesRank: 40
        },
        {
          date: '2024-04-03',

          salesRank: 41
        },
        {
          date: '2024-04-04',

          salesRank: 43
        },
        {
          date: '2024-04-05',

          salesRank: 42
        },
        {
          date: '2024-04-06',

          salesRank: 48
        },
        {
          date: '2024-04-07',

          salesRank: 41
        },
        {
          date: '2024-04-08',

          salesRank: 52
        },
        {
          date: '2024-04-09',

          salesRank: 45
        },
        {
          date: '2024-04-10',

          salesRank: 48
        },
        {
          date: '2024-04-11',

          salesRank: 44
        },
        {
          date: '2024-04-12',

          salesRank: 44
        },
        {
          date: '2024-04-13',

          salesRank: 57
        },
        {
          date: '2024-04-14',

          salesRank: 53
        },
        {
          date: '2024-04-15',

          salesRank: 43
        },
        {
          date: '2024-04-16',

          salesRank: 48
        },
        {
          date: '2024-04-17',

          salesRank: 39
        },
        {
          date: '2024-04-18',

          salesRank: 44
        },
        {
          date: '2024-04-19',

          salesRank: 46
        },
        {
          date: '2024-04-20',

          salesRank: 46
        },
        {
          date: '2024-04-21',

          salesRank: 51
        },
        {
          date: '2024-04-22',

          salesRank: 28
        },
        {
          date: '2024-04-23',

          salesRank: 21
        },
        {
          date: '2024-04-24',

          salesRank: 20
        },
        {
          date: '2024-04-25',

          salesRank: 25
        },
        {
          date: '2024-04-26',

          salesRank: 26
        },
        {
          date: '2024-04-28',

          salesRank: 29
        },
        {
          date: '2024-04-29',

          salesRank: 26
        },
        {
          date: '2024-04-30',

          salesRank: 44
        },
        {
          date: '2024-05-01',

          salesRank: 51
        },
        {
          date: '2024-05-02',

          salesRank: 56
        },
        {
          date: '2024-05-03',

          salesRank: 52
        },
        {
          date: '2024-05-04',

          salesRank: 56
        },
        {
          date: '2024-05-05',

          salesRank: 68
        },
        {
          date: '2024-05-06',

          salesRank: 55
        },
        {
          date: '2024-05-07',

          salesRank: 54
        },
        {
          date: '2024-05-08',

          salesRank: 60
        },
        {
          date: '2024-05-09',

          salesRank: 53
        },
        {
          date: '2024-05-10',

          salesRank: 53
        },
        {
          date: '2024-05-11',

          salesRank: 43
        },
        {
          date: '2024-05-12',

          salesRank: 47
        },
        {
          date: '2024-05-13',

          salesRank: 42
        },
        {
          date: '2024-05-14',

          salesRank: 52
        },
        {
          date: '2024-05-15',

          salesRank: 39
        },
        {
          date: '2024-05-16',

          salesRank: 49
        },
        {
          date: '2024-05-17',

          salesRank: 49
        },
        {
          date: '2024-05-18',

          salesRank: 39
        },
        {
          date: '2024-05-19',

          salesRank: 46
        },
        {
          date: '2024-05-20',

          salesRank: 38
        },
        {
          date: '2024-05-21',

          salesRank: 38
        },
        {
          date: '2024-05-22',

          salesRank: 41
        },
        {
          date: '2024-05-23',

          salesRank: 40
        },
        {
          date: '2024-05-24',

          salesRank: 35
        },
        {
          date: '2024-05-25',

          salesRank: 38
        },
        {
          date: '2024-05-26',

          salesRank: 34
        },
        {
          date: '2024-05-27',

          salesRank: 39
        },
        {
          date: '2024-05-28',

          salesRank: 35
        },
        {
          date: '2024-05-29',

          salesRank: 36
        },
        {
          date: '2024-05-30',

          salesRank: 37
        },
        {
          date: '2024-05-31',

          salesRank: 40
        },
        {
          date: '2024-06-01',

          salesRank: 35
        },
        {
          date: '2024-06-02',

          salesRank: 41
        },
        {
          date: '2024-06-03',

          salesRank: 49
        },
        {
          date: '2024-06-04',

          salesRank: 38
        },
        {
          date: '2024-06-05',

          salesRank: 48
        },
        {
          date: '2024-06-06',

          salesRank: 47
        },
        {
          date: '2024-06-07',

          salesRank: 45
        },
        {
          date: '2024-06-08',

          salesRank: 48
        },
        {
          date: '2024-06-09',

          salesRank: 40
        },
        {
          date: '2024-06-10',

          salesRank: 46
        },
        {
          date: '2024-06-11',

          salesRank: 45
        },
        {
          date: '2024-06-12',

          salesRank: 43
        },
        {
          date: '2024-06-13',

          salesRank: 45
        },
        {
          date: '2024-06-14',

          salesRank: 42
        },
        {
          date: '2024-06-15',

          salesRank: 40
        },
        {
          date: '2024-06-16',

          salesRank: 39
        },
        {
          date: '2024-06-17',

          salesRank: 21
        },
        {
          date: '2024-06-18',

          salesRank: 17
        },
        {
          date: '2024-06-19',

          salesRank: 15
        },
        {
          date: '2024-06-20',

          salesRank: 18
        },
        {
          date: '2024-06-21',

          salesRank: 17
        },
        {
          date: '2024-06-22',

          salesRank: 25
        },
        {
          date: '2024-06-23',

          salesRank: 17
        },
        {
          date: '2024-06-24',

          salesRank: 24
        },
        {
          date: '2024-06-25',

          salesRank: 29
        },
        {
          date: '2024-06-26',

          salesRank: 31
        },
        {
          date: '2024-06-27',

          salesRank: 37
        },
        {
          date: '2024-06-28',

          salesRank: 36
        },
        {
          date: '2024-06-29',

          salesRank: 36
        },
        {
          date: '2024-06-30',

          salesRank: 40
        },
        {
          date: '2024-07-01',

          salesRank: 43
        },
        {
          date: '2024-07-02',

          salesRank: 47
        },
        {
          date: '2024-07-03',

          salesRank: 44
        },
        {
          date: '2024-07-04',

          salesRank: 39
        },
        {
          date: '2024-07-05',

          salesRank: 34
        },
        {
          date: '2024-07-06',

          salesRank: 44
        },
        {
          date: '2024-07-07',

          salesRank: 47
        },
        {
          date: '2024-07-08',

          salesRank: 33
        },
        {
          date: '2024-07-09',

          salesRank: 38
        },
        {
          date: '2024-07-10',

          salesRank: 40
        },
        {
          date: '2024-07-11',

          salesRank: 37
        },
        {
          date: '2024-07-12',

          salesRank: 38
        },
        {
          date: '2024-07-13',

          salesRank: 42
        },
        {
          date: '2024-07-14',

          salesRank: 47
        },
        {
          date: '2024-07-15',

          salesRank: 43
        },
        {
          date: '2024-07-16',

          salesRank: 13
        },
        {
          date: '2024-07-20',

          salesRank: 14
        },
        {
          date: '2024-07-21',

          salesRank: 17
        },
        {
          date: '2024-07-22',

          salesRank: 24
        },
        {
          date: '2024-07-23',

          salesRank: 31
        },
        {
          date: '2024-07-24',

          salesRank: 26
        },
        {
          date: '2024-07-25',

          salesRank: 30
        },
        {
          date: '2024-07-26',

          salesRank: 36
        },
        {
          date: '2024-07-27',

          salesRank: 41
        },
        {
          date: '2024-07-28',

          salesRank: 41
        },
        {
          date: '2024-07-29',

          salesRank: 35
        },
        {
          date: '2024-07-30',

          salesRank: 37
        },
        {
          date: '2024-07-31',

          salesRank: 38
        },
        {
          date: '2024-08-01',

          salesRank: 40
        },
        {
          date: '2024-08-02',

          salesRank: 47
        },
        {
          date: '2024-08-03',

          salesRank: 46
        },
        {
          date: '2024-08-04',

          salesRank: 41
        },
        {
          date: '2024-08-05',

          salesRank: 39
        },
        {
          date: '2024-08-06',

          salesRank: 46
        },
        {
          date: '2024-08-07',

          salesRank: 38
        },
        {
          date: '2024-08-09',

          salesRank: 48
        },
        {
          date: '2024-08-10',

          salesRank: 34
        },
        {
          date: '2024-08-11',

          salesRank: 45
        },
        {
          date: '2024-08-12',

          salesRank: 23
        },
        {
          date: '2024-08-13',

          salesRank: 25
        },
        {
          date: '2024-08-14',

          salesRank: 27
        },
        {
          date: '2024-08-15',

          salesRank: 26
        },
        {
          date: '2024-08-19',

          salesRank: 31
        },
        {
          date: '2024-08-20',

          salesRank: 28
        },
        {
          date: '2024-08-22',

          salesRank: 30
        },
        {
          date: '2024-08-23',

          salesRank: 24
        },
        {
          date: '2024-08-24',

          salesRank: 31
        },
        {
          date: '2024-08-25',

          salesRank: 29
        },
        {
          date: '2024-08-26',

          salesRank: 27
        },
        {
          date: '2024-08-27',

          salesRank: 37
        },
        {
          date: '2024-08-28',

          salesRank: 42
        },
        {
          date: '2024-08-29',

          salesRank: 56
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-29T22:12:10.090Z'
    },
    {
      id: '4035f60c-edf6-4662-aea4-ba1eb28bee03',
      productName: 'Outdoor Wireless Security Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2023-09-20',

          salesRank: 776
        },
        {
          date: '2023-09-21',

          salesRank: 218
        },
        {
          date: '2023-09-22',

          salesRank: 305
        },
        {
          date: '2023-09-23',

          salesRank: 283
        },
        {
          date: '2023-09-24',

          salesRank: 445
        },
        {
          date: '2023-09-25',

          salesRank: 335
        },
        {
          date: '2023-09-26',

          salesRank: 346
        },
        {
          date: '2023-09-27',

          salesRank: 419
        },
        {
          date: '2023-09-28',

          salesRank: 290
        },
        {
          date: '2023-09-29',

          salesRank: 192
        },
        {
          date: '2023-09-30',

          salesRank: 185
        },
        {
          date: '2023-10-01',

          salesRank: 207
        },
        {
          date: '2023-10-02',

          salesRank: 186
        },
        {
          date: '2023-10-03',

          salesRank: 293
        },
        {
          date: '2023-10-04',

          salesRank: 267
        },
        {
          date: '2023-10-05',

          salesRank: 307
        },
        {
          date: '2023-10-06',

          salesRank: 264
        },
        {
          date: '2023-10-07',

          salesRank: 375
        },
        {
          date: '2023-10-08',

          salesRank: 413
        },
        {
          date: '2023-10-09',

          salesRank: 381
        },
        {
          date: '2023-10-10',

          salesRank: 483
        },
        {
          date: '2023-10-11',

          salesRank: 232
        },
        {
          date: '2023-10-12',

          salesRank: 200
        },
        {
          date: '2023-10-13',

          salesRank: 201
        },
        {
          date: '2023-10-14',

          salesRank: 177
        },
        {
          date: '2023-10-15',

          salesRank: 155
        },
        {
          date: '2023-10-16',

          salesRank: 143
        },
        {
          date: '2023-10-17',
          reviewCount: 1,
          rating: 5,
          salesRank: 175
        },
        {
          date: '2023-10-18',
          reviewCount: 1,
          rating: 5,
          salesRank: 223
        },
        {
          date: '2023-10-19',
          reviewCount: 1,
          rating: 5,
          salesRank: 129
        },
        {
          date: '2023-10-20',
          reviewCount: 1,
          rating: 5,
          salesRank: 180
        },
        {
          date: '2023-10-21',
          reviewCount: 1,
          rating: 5,
          salesRank: 148
        },
        {
          date: '2023-10-22',
          reviewCount: 1,
          rating: 5,
          salesRank: 143
        },
        {
          date: '2023-10-23',
          reviewCount: 3,
          rating: 4,
          salesRank: 112
        },
        {
          date: '2023-10-24',
          reviewCount: 3,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2023-10-25',
          reviewCount: 3,
          rating: 4,
          salesRank: 66
        },
        {
          date: '2023-10-26',
          reviewCount: 3,
          rating: 4,
          salesRank: 69
        },
        {
          date: '2023-10-27',
          reviewCount: 3,
          rating: 4,
          salesRank: 73
        },
        {
          date: '2023-10-28',
          reviewCount: 3,
          rating: 4,
          salesRank: 62
        },
        {
          date: '2023-10-29',
          reviewCount: 10,
          rating: 4,
          salesRank: 75
        },
        {
          date: '2023-10-30',
          reviewCount: 10,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2023-10-31',
          reviewCount: 10,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2023-11-01',
          reviewCount: 10,
          rating: 4,
          salesRank: 80
        },
        {
          date: '2023-11-02',
          reviewCount: 10,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2023-11-03',
          reviewCount: 10,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2023-11-04',
          reviewCount: 14,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2023-11-05',
          reviewCount: 14,
          rating: 3,
          salesRank: 51
        },
        {
          date: '2023-11-06',
          reviewCount: 14,
          rating: 3,
          salesRank: 62
        },
        {
          date: '2023-11-07',
          reviewCount: 14,
          rating: 3,
          salesRank: 79
        },
        {
          date: '2023-11-08',
          reviewCount: 14,
          rating: 3,
          salesRank: 98
        },
        {
          date: '2023-11-09',
          reviewCount: 17,
          rating: 4,
          salesRank: 93
        },
        {
          date: '2023-11-10',
          reviewCount: 17,
          rating: 4,
          salesRank: 86
        },
        {
          date: '2023-11-11',
          reviewCount: 17,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2023-11-12',
          reviewCount: 17,
          rating: 4,
          salesRank: 128
        },
        {
          date: '2023-11-13',
          reviewCount: 17,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2023-11-14',
          reviewCount: 20,
          rating: 4,
          salesRank: 111
        },
        {
          date: '2023-11-15',
          reviewCount: 20,
          rating: 4,
          salesRank: 96
        },
        {
          date: '2023-11-16',
          reviewCount: 20,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2023-11-17',
          reviewCount: 20,
          rating: 4,
          salesRank: 53
        },
        {
          date: '2023-11-18',
          reviewCount: 20,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2023-11-19',
          reviewCount: 20,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2023-11-20',
          reviewCount: 22,
          rating: 3,
          salesRank: 26
        },
        {
          date: '2023-11-21',
          reviewCount: 22,
          rating: 3,
          salesRank: 26
        },
        {
          date: '2023-11-22',
          reviewCount: 22,
          rating: 3,
          salesRank: 26
        },
        {
          date: '2023-11-23',
          reviewCount: 22,
          rating: 3,
          salesRank: 25
        },
        {
          date: '2023-11-24',
          reviewCount: 22,
          rating: 3,
          salesRank: 26
        },
        {
          date: '2023-11-25',
          reviewCount: 24,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2023-11-26',
          reviewCount: 24,
          rating: 3,
          salesRank: 25
        },
        {
          date: '2023-11-27',
          reviewCount: 24,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2023-11-28',
          reviewCount: 24,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2023-11-29',
          reviewCount: 24,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2023-11-30',
          reviewCount: 24,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2023-12-01',
          reviewCount: 30,
          rating: 3,
          salesRank: 23
        },
        {
          date: '2023-12-02',
          reviewCount: 30,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2023-12-03',
          reviewCount: 30,
          rating: 3,
          salesRank: 21
        },
        {
          date: '2023-12-04',
          reviewCount: 30,
          rating: 3,
          salesRank: 23
        },
        {
          date: '2023-12-05',
          reviewCount: 30,
          rating: 3,
          salesRank: 25
        },
        {
          date: '2023-12-06',
          reviewCount: 38,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2023-12-07',
          reviewCount: 38,
          rating: 3,
          salesRank: 23
        },
        {
          date: '2023-12-08',
          reviewCount: 38,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2023-12-09',
          reviewCount: 38,
          rating: 3,
          salesRank: 19
        },
        {
          date: '2023-12-10',
          reviewCount: 38,
          rating: 3,
          salesRank: 20
        },
        {
          date: '2023-12-11',
          reviewCount: 38,
          rating: 3,
          salesRank: 21
        },
        {
          date: '2023-12-12',
          reviewCount: 43,
          rating: 3,
          salesRank: 15
        },
        {
          date: '2023-12-13',
          reviewCount: 43,
          rating: 3
        },
        {
          date: '2023-12-14',
          reviewCount: 43,
          rating: 3,
          salesRank: 16
        },
        {
          date: '2023-12-15',
          reviewCount: 43,
          rating: 3,
          salesRank: 17
        },
        {
          date: '2023-12-16',
          reviewCount: 43,
          rating: 3
        },
        {
          date: '2023-12-17',
          reviewCount: 43,
          rating: 3,
          salesRank: 17
        },
        {
          date: '2023-12-18',
          reviewCount: 51,
          rating: 3,
          salesRank: 18
        },
        {
          date: '2023-12-19',
          reviewCount: 51,
          rating: 3,
          salesRank: 18
        },
        {
          date: '2023-12-20',
          reviewCount: 51,
          rating: 3,
          salesRank: 50
        },
        {
          date: '2023-12-21',
          reviewCount: 51,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2023-12-22',
          reviewCount: 51,
          rating: 3,
          salesRank: 63
        },
        {
          date: '2023-12-23',
          reviewCount: 51,
          rating: 3,
          salesRank: 82
        },
        {
          date: '2023-12-24',
          reviewCount: 38,
          rating: 3,
          salesRank: 83
        },
        {
          date: '2023-12-25',
          reviewCount: 38,
          rating: 3,
          salesRank: 96
        },
        {
          date: '2023-12-26',
          reviewCount: 38,
          rating: 3,
          salesRank: 134
        },
        {
          date: '2023-12-27',
          reviewCount: 38,
          rating: 3,
          salesRank: 161
        },
        {
          date: '2023-12-28',
          reviewCount: 38,
          rating: 3,
          salesRank: 236
        },
        {
          date: '2023-12-29',
          reviewCount: 38,
          rating: 3,
          salesRank: 213
        },
        {
          date: '2023-12-30',
          reviewCount: 39,
          rating: 3,
          salesRank: 252
        },
        {
          date: '2023-12-31',
          reviewCount: 39,
          rating: 3,
          salesRank: 226
        },
        {
          date: '2024-01-01',
          reviewCount: 39,
          rating: 3,
          salesRank: 201
        },
        {
          date: '2024-01-02',
          reviewCount: 39,
          rating: 3,
          salesRank: 130
        },
        {
          date: '2024-01-03',
          reviewCount: 39,
          rating: 3,
          salesRank: 156
        },
        {
          date: '2024-01-04',
          reviewCount: 42,
          rating: 3,
          salesRank: 32
        },
        {
          date: '2024-01-05',
          reviewCount: 42,
          rating: 3,
          salesRank: 43
        },
        {
          date: '2024-01-06',
          reviewCount: 42,
          rating: 3,
          salesRank: 39
        },
        {
          date: '2024-01-07',
          reviewCount: 42,
          rating: 3,
          salesRank: 29
        },
        {
          date: '2024-01-08',
          reviewCount: 42,
          rating: 3,
          salesRank: 31
        },
        {
          date: '2024-01-09',
          reviewCount: 42,
          rating: 3,
          salesRank: 33
        },
        {
          date: '2024-01-10',
          reviewCount: 46,
          rating: 3,
          salesRank: 40
        },
        {
          date: '2024-01-11',
          reviewCount: 46,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-01-12',
          reviewCount: 46,
          rating: 3,
          salesRank: 39
        },
        {
          date: '2024-01-13',
          reviewCount: 46,
          rating: 3,
          salesRank: 34
        },
        {
          date: '2024-01-14',
          reviewCount: 46,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-01-15',
          reviewCount: 49,
          rating: 3,
          salesRank: 37
        },
        {
          date: '2024-01-16',
          reviewCount: 49,
          rating: 3,
          salesRank: 33
        },
        {
          date: '2024-01-17',
          reviewCount: 49,
          rating: 3,
          salesRank: 42
        },
        {
          date: '2024-01-18',
          reviewCount: 49,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-01-19',
          reviewCount: 49,
          rating: 3,
          salesRank: 53
        },
        {
          date: '2024-01-20',
          reviewCount: 49,
          rating: 3,
          salesRank: 54
        },
        {
          date: '2024-01-21',
          reviewCount: 54,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-01-22',
          reviewCount: 54,
          rating: 3,
          salesRank: 40
        },
        {
          date: '2024-01-23',
          reviewCount: 54,
          rating: 3,
          salesRank: 39
        },
        {
          date: '2024-01-24',
          reviewCount: 54,
          rating: 3,
          salesRank: 35
        },
        {
          date: '2024-01-25',
          reviewCount: 54,
          rating: 3,
          salesRank: 48
        },
        {
          date: '2024-01-26',
          reviewCount: 56,
          rating: 3,
          salesRank: 72
        },
        {
          date: '2024-01-27',
          reviewCount: 56,
          rating: 3,
          salesRank: 63
        },
        {
          date: '2024-01-28',
          reviewCount: 56,
          rating: 3,
          salesRank: 54
        },
        {
          date: '2024-01-29',
          reviewCount: 56,
          rating: 3,
          salesRank: 59
        },
        {
          date: '2024-01-30',
          reviewCount: 56,
          rating: 3,
          salesRank: 61
        },
        {
          date: '2024-01-31',
          reviewCount: 61,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2024-02-01',
          reviewCount: 61,
          rating: 3,
          salesRank: 63
        },
        {
          date: '2024-02-02',
          reviewCount: 61,
          rating: 3,
          salesRank: 51
        },
        {
          date: '2024-02-03',
          reviewCount: 61,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2024-02-04',
          reviewCount: 61,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-02-05',
          reviewCount: 61,
          rating: 3,
          salesRank: 43
        },
        {
          date: '2024-02-06',
          reviewCount: 63,
          rating: 3,
          salesRank: 34
        },
        {
          date: '2024-02-07',
          reviewCount: 63,
          rating: 3,
          salesRank: 42
        },
        {
          date: '2024-02-08',
          reviewCount: 63,
          rating: 3,
          salesRank: 39
        },
        {
          date: '2024-02-09',
          reviewCount: 63,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-02-10',
          reviewCount: 63,
          rating: 3,
          salesRank: 43
        },
        {
          date: '2024-02-11',
          reviewCount: 63,
          rating: 3,
          salesRank: 112
        },
        {
          date: '2024-02-12',
          reviewCount: 71,
          rating: 3,
          salesRank: 63
        },
        {
          date: '2024-02-13',
          reviewCount: 71,
          rating: 3,
          salesRank: 43
        },
        {
          date: '2024-02-14',
          reviewCount: 71,
          rating: 3,
          salesRank: 41
        },
        {
          date: '2024-02-15',
          reviewCount: 71,
          rating: 3,
          salesRank: 44
        },
        {
          date: '2024-02-16',
          reviewCount: 71,
          rating: 3,
          salesRank: 54
        },
        {
          date: '2024-02-17',
          reviewCount: 78,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-02-18',
          reviewCount: 78,
          rating: 3,
          salesRank: 40
        },
        {
          date: '2024-02-19',
          reviewCount: 78,
          rating: 3,
          salesRank: 45
        },
        {
          date: '2024-02-20',
          reviewCount: 78,
          rating: 3,
          salesRank: 61
        },
        {
          date: '2024-02-21',
          reviewCount: 78,
          rating: 3,
          salesRank: 54
        },
        {
          date: '2024-02-22',
          reviewCount: 79,
          rating: 3,
          salesRank: 63
        },
        {
          date: '2024-02-23',
          reviewCount: 79,
          rating: 3,
          salesRank: 77
        },
        {
          date: '2024-02-24',
          reviewCount: 79,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-02-25',
          reviewCount: 79,
          rating: 3,
          salesRank: 65
        },
        {
          date: '2024-02-26',
          reviewCount: 79,
          rating: 3,
          salesRank: 52
        },
        {
          date: '2024-02-27',
          reviewCount: 79,
          rating: 3,
          salesRank: 70
        },
        {
          date: '2024-02-28',
          reviewCount: 172,
          rating: 3,
          salesRank: 83
        },
        {
          date: '2024-02-29',
          reviewCount: 172,
          rating: 3,
          salesRank: 82
        },
        {
          date: '2024-03-01',
          reviewCount: 172,
          rating: 3,
          salesRank: 78
        },
        {
          date: '2024-03-02',
          reviewCount: 172,
          rating: 3,
          salesRank: 62
        },
        {
          date: '2024-03-03',
          reviewCount: 172,
          rating: 3,
          salesRank: 69
        },
        {
          date: '2024-03-04',
          reviewCount: 172,
          rating: 3,
          salesRank: 57
        },
        {
          date: '2024-03-05',
          reviewCount: 181,
          rating: 3,
          salesRank: 78
        },
        {
          date: '2024-03-06',
          reviewCount: 181,
          rating: 3,
          salesRank: 90
        },
        {
          date: '2024-03-07',
          reviewCount: 181,
          rating: 3,
          salesRank: 94
        },
        {
          date: '2024-03-08',
          reviewCount: 181,
          rating: 3,
          salesRank: 86
        },
        {
          date: '2024-03-09',
          reviewCount: 181,
          rating: 3,
          salesRank: 72
        },
        {
          date: '2024-03-10',
          reviewCount: 184,
          rating: 3,
          salesRank: 70
        },
        {
          date: '2024-03-11',
          reviewCount: 184,
          rating: 3,
          salesRank: 84
        },
        {
          date: '2024-03-12',
          reviewCount: 184,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2024-03-13',
          reviewCount: 184,
          rating: 3,
          salesRank: 53
        },
        {
          date: '2024-03-14',
          reviewCount: 184,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2024-03-15',
          reviewCount: 184,
          rating: 3,
          salesRank: 66
        },
        {
          date: '2024-03-16',
          reviewCount: 192,
          rating: 3,
          salesRank: 61
        },
        {
          date: '2024-03-17',
          reviewCount: 192,
          rating: 3,
          salesRank: 88
        },
        {
          date: '2024-03-18',
          reviewCount: 192,
          rating: 3,
          salesRank: 128
        },
        {
          date: '2024-03-19',
          reviewCount: 192,
          rating: 3,
          salesRank: 80
        },
        {
          date: '2024-03-20',
          reviewCount: 192,
          rating: 3,
          salesRank: 59
        },
        {
          date: '2024-03-21',
          reviewCount: 196,
          rating: 3,
          salesRank: 57
        },
        {
          date: '2024-03-22',
          reviewCount: 196,
          rating: 3,
          salesRank: 59
        },
        {
          date: '2024-03-23',
          reviewCount: 196,
          rating: 3,
          salesRank: 67
        },
        {
          date: '2024-03-24',
          reviewCount: 196,
          rating: 3,
          salesRank: 53
        },
        {
          date: '2024-03-25',
          reviewCount: 196,
          rating: 3,
          salesRank: 64
        },
        {
          date: '2024-03-26',
          reviewCount: 196,
          rating: 3,
          salesRank: 59
        },
        {
          date: '2024-03-27',
          reviewCount: 207,
          rating: 3,
          salesRank: 54
        },
        {
          date: '2024-03-28',
          reviewCount: 207,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-03-29',
          reviewCount: 207,
          rating: 3,
          salesRank: 53
        },
        {
          date: '2024-03-30',
          reviewCount: 207,
          rating: 3,
          salesRank: 45
        },
        {
          date: '2024-03-31',
          reviewCount: 207,
          rating: 3,
          salesRank: 82
        },
        {
          date: '2024-04-01',
          reviewCount: 212,
          rating: 3,
          salesRank: 134
        },
        {
          date: '2024-04-02',
          reviewCount: 212,
          rating: 3,
          salesRank: 136
        },
        {
          date: '2024-04-03',
          reviewCount: 212,
          rating: 3,
          salesRank: 153
        },
        {
          date: '2024-04-04',
          reviewCount: 212,
          rating: 3,
          salesRank: 120
        },
        {
          date: '2024-04-05',
          reviewCount: 212,
          rating: 3,
          salesRank: 107
        },
        {
          date: '2024-04-06',
          reviewCount: 212,
          rating: 3,
          salesRank: 108
        },
        {
          date: '2024-04-07',
          reviewCount: 218,
          rating: 3,
          salesRank: 130
        },
        {
          date: '2024-04-08',
          reviewCount: 218,
          rating: 3,
          salesRank: 113
        },
        {
          date: '2024-04-09',
          reviewCount: 218,
          rating: 3,
          salesRank: 128
        },
        {
          date: '2024-04-10',
          reviewCount: 218,
          rating: 3,
          salesRank: 132
        },
        {
          date: '2024-04-11',
          reviewCount: 218,
          rating: 3,
          salesRank: 137
        },
        {
          date: '2024-04-12',
          reviewCount: 218,
          rating: 3,
          salesRank: 113
        },
        {
          date: '2024-04-13',
          reviewCount: 218,
          rating: 3,
          salesRank: 116
        },
        {
          date: '2024-04-14',
          reviewCount: 218,
          rating: 3,
          salesRank: 92
        },
        {
          date: '2024-04-15',
          reviewCount: 218,
          rating: 3,
          salesRank: 84
        },
        {
          date: '2024-04-16',
          reviewCount: 218,
          rating: 3,
          salesRank: 70
        },
        {
          date: '2024-04-17',
          reviewCount: 218,
          rating: 3,
          salesRank: 56
        },
        {
          date: '2024-04-18',
          reviewCount: 218,
          rating: 3,
          salesRank: 50
        },
        {
          date: '2024-04-19',
          reviewCount: 218,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-04-20',
          reviewCount: 218,
          rating: 3,
          salesRank: 46
        },
        {
          date: '2024-04-21',
          reviewCount: 218,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-04-22',
          reviewCount: 218,
          rating: 3,
          salesRank: 45
        },
        {
          date: '2024-04-23',
          reviewCount: 218,
          rating: 3,
          salesRank: 35
        },
        {
          date: '2024-04-24',
          reviewCount: 244,
          rating: 3,
          salesRank: 29
        },
        {
          date: '2024-04-25',
          reviewCount: 244,
          rating: 3,
          salesRank: 27
        },
        {
          date: '2024-04-26',
          reviewCount: 244,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2024-04-27',
          reviewCount: 244,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-04-28',
          reviewCount: 244,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-04-29',
          reviewCount: 252,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-04-30',
          reviewCount: 252,
          rating: 3,
          salesRank: 23
        },
        {
          date: '2024-05-01',
          reviewCount: 252,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-05-02',
          reviewCount: 252,
          rating: 3
        },
        {
          date: '2024-05-03',
          reviewCount: 252,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2024-05-04',
          reviewCount: 252,
          rating: 3,
          salesRank: 23
        },
        {
          date: '2024-05-05',
          reviewCount: 258,
          rating: 3,
          salesRank: 41
        },
        {
          date: '2024-05-06',
          reviewCount: 258,
          rating: 3,
          salesRank: 70
        },
        {
          date: '2024-05-07',
          reviewCount: 258,
          rating: 3,
          salesRank: 68
        },
        {
          date: '2024-05-08',
          reviewCount: 258,
          rating: 3,
          salesRank: 47
        },
        {
          date: '2024-05-09',
          reviewCount: 258,
          rating: 3,
          salesRank: 46
        },
        {
          date: '2024-05-10',
          reviewCount: 258,
          rating: 3,
          salesRank: 45
        },
        {
          date: '2024-05-11',
          reviewCount: 264,
          rating: 3,
          salesRank: 35
        },
        {
          date: '2024-05-12',
          reviewCount: 264,
          rating: 3,
          salesRank: 52
        },
        {
          date: '2024-05-13',
          reviewCount: 264,
          rating: 3,
          salesRank: 37
        },
        {
          date: '2024-05-14',
          reviewCount: 264,
          rating: 3,
          salesRank: 42
        },
        {
          date: '2024-05-15',
          reviewCount: 264,
          rating: 3,
          salesRank: 37
        },
        {
          date: '2024-05-16',
          reviewCount: 269,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-05-17',
          reviewCount: 269,
          rating: 3,
          salesRank: 35
        },
        {
          date: '2024-05-18',
          reviewCount: 269,
          rating: 3,
          salesRank: 37
        },
        {
          date: '2024-05-19',
          reviewCount: 269,
          rating: 3,
          salesRank: 39
        },
        {
          date: '2024-05-20',
          reviewCount: 269,
          rating: 3,
          salesRank: 29
        },
        {
          date: '2024-05-21',
          reviewCount: 269,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-05-22',
          reviewCount: 274,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2024-05-23',
          reviewCount: 274,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2024-05-24',
          reviewCount: 274,
          rating: 3,
          salesRank: 13
        },
        {
          date: '2024-05-25',
          reviewCount: 274,
          rating: 3,
          salesRank: 14
        },
        {
          date: '2024-05-26',
          reviewCount: 274,
          rating: 3,
          salesRank: 13
        },
        {
          date: '2024-05-27',
          reviewCount: 286,
          rating: 3,
          salesRank: 13
        },
        {
          date: '2024-05-28',
          reviewCount: 286,
          rating: 3,
          salesRank: 15
        },
        {
          date: '2024-05-29',
          reviewCount: 286,
          rating: 3,
          salesRank: 13
        },
        {
          date: '2024-05-30',
          reviewCount: 286,
          rating: 3,
          salesRank: 14
        },
        {
          date: '2024-05-31',
          reviewCount: 286,
          rating: 3,
          salesRank: 14
        },
        {
          date: '2024-06-01',
          reviewCount: 286,
          rating: 3,
          salesRank: 18
        },
        {
          date: '2024-06-02',
          reviewCount: 365,
          rating: 3,
          salesRank: 19
        },
        {
          date: '2024-06-03',
          reviewCount: 365,
          rating: 3,
          salesRank: 24
        },
        {
          date: '2024-06-04',
          reviewCount: 365,
          rating: 3,
          salesRank: 25
        },
        {
          date: '2024-06-05',
          reviewCount: 365,
          rating: 3,
          salesRank: 33
        },
        {
          date: '2024-06-06',
          reviewCount: 365,
          rating: 3,
          salesRank: 31
        },
        {
          date: '2024-06-07',
          reviewCount: 365,
          rating: 3,
          salesRank: 33
        },
        {
          date: '2024-06-08',
          reviewCount: 455,
          rating: 3,
          salesRank: 27
        },
        {
          date: '2024-06-09',
          reviewCount: 455,
          rating: 3,
          salesRank: 28
        },
        {
          date: '2024-06-10',
          reviewCount: 455,
          rating: 3,
          salesRank: 22
        },
        {
          date: '2024-06-11',
          reviewCount: 455,
          rating: 3,
          salesRank: 36
        },
        {
          date: '2024-06-12',
          reviewCount: 455,
          rating: 3,
          salesRank: 38
        },
        {
          date: '2024-06-13',
          reviewCount: 508,
          rating: 4,
          salesRank: 36
        },
        {
          date: '2024-06-14',
          reviewCount: 508,
          rating: 4,
          salesRank: 39
        },
        {
          date: '2024-06-15',
          reviewCount: 508,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2024-06-16',
          reviewCount: 508,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2024-06-17',
          reviewCount: 508,
          rating: 4,
          salesRank: 25
        },
        {
          date: '2024-06-18',
          reviewCount: 508,
          rating: 4,
          salesRank: 23
        },
        {
          date: '2024-06-19',
          reviewCount: 547,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-06-20',
          reviewCount: 547,
          rating: 4,
          salesRank: 15
        },
        {
          date: '2024-06-21',
          reviewCount: 547,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-06-22',
          reviewCount: 547,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-06-23',
          reviewCount: 547,
          rating: 4,
          salesRank: 17
        },
        {
          date: '2024-06-24',
          reviewCount: 547,
          rating: 4,
          salesRank: 17
        },
        {
          date: '2024-06-25',
          reviewCount: 576,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-06-26',
          reviewCount: 576,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-06-27',
          reviewCount: 576,
          rating: 4,
          salesRank: 13
        },
        {
          date: '2024-06-28',
          reviewCount: 576,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 576,
          rating: 4,
          salesRank: 15
        },
        {
          date: '2024-06-30',
          reviewCount: 576,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-07-01',
          reviewCount: 617,
          rating: 4,
          salesRank: 20
        },
        {
          date: '2024-07-02',
          reviewCount: 617,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-07-03',
          reviewCount: 617,
          rating: 4,
          salesRank: 27
        },
        {
          date: '2024-07-04',
          reviewCount: 617,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2024-07-05',
          reviewCount: 617,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2024-07-06',
          reviewCount: 674,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-07-07',
          reviewCount: 674,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2024-07-08',
          reviewCount: 674,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2024-07-09',
          reviewCount: 674,
          rating: 4,
          salesRank: 21
        },
        {
          date: '2024-07-10',
          reviewCount: 674,
          rating: 4,
          salesRank: 16
        },
        {
          date: '2024-07-11',
          reviewCount: 701,
          rating: 4,
          salesRank: 12
        },
        {
          date: '2024-07-12',
          reviewCount: 701,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-07-13',
          reviewCount: 701,
          rating: 4,
          salesRank: 15
        },
        {
          date: '2024-07-14',
          reviewCount: 701,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 701,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-07-16',
          reviewCount: 701,
          rating: 4,
          salesRank: 18
        },
        {
          date: '2024-07-17',
          reviewCount: 729,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 729,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 729,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 729,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 729,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-07-22',
          reviewCount: 753,
          rating: 4,
          salesRank: 18
        },
        {
          date: '2024-07-23',
          reviewCount: 753,
          rating: 4,
          salesRank: 16
        },
        {
          date: '2024-07-24',
          reviewCount: 753,
          rating: 4,
          salesRank: 17
        },
        {
          date: '2024-07-25',
          reviewCount: 753,
          rating: 4,
          salesRank: 18
        },
        {
          date: '2024-07-26',
          reviewCount: 753,
          rating: 4,
          salesRank: 16
        },
        {
          date: '2024-07-27',
          reviewCount: 753,
          rating: 4,
          salesRank: 15
        },
        {
          date: '2024-07-28',
          reviewCount: 791,
          rating: 4,
          salesRank: 14
        },
        {
          date: '2024-07-29',
          reviewCount: 791,
          rating: 4,
          salesRank: 17
        },
        {
          date: '2024-07-30',
          reviewCount: 791,
          rating: 4,
          salesRank: 18
        },
        {
          date: '2024-07-31',
          reviewCount: 791,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2024-08-01',
          reviewCount: 791,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2024-08-02',
          reviewCount: 791,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2024-08-03',
          reviewCount: 844,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2024-08-04',
          reviewCount: 844,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2024-08-05',
          reviewCount: 844,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2024-08-06',
          reviewCount: 844,
          rating: 4,
          salesRank: 29
        },
        {
          date: '2024-08-07',
          reviewCount: 844,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-08-08',
          reviewCount: 875,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-08-09',
          reviewCount: 875,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-08-10',
          reviewCount: 875,
          rating: 4,
          salesRank: 30
        },
        {
          date: '2024-08-11',
          reviewCount: 875,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2024-08-12',
          reviewCount: 875,
          rating: 4,
          salesRank: 29
        },
        {
          date: '2024-08-13',
          reviewCount: 875,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-08-14',
          reviewCount: 909,
          rating: 4,
          salesRank: 27
        },
        {
          date: '2024-08-15',
          reviewCount: 909,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2024-08-16',
          reviewCount: 909,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 909,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 909,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 944,
          rating: 4,
          salesRank: 29
        },
        {
          date: '2024-08-20',
          reviewCount: 944,
          rating: 4,
          salesRank: 23
        },
        {
          date: '2024-08-21',
          reviewCount: 944,
          rating: 4,
          salesRank: 24
        },
        {
          date: '2024-08-22',
          reviewCount: 944,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-08-23',
          reviewCount: 944,
          rating: 4,
          salesRank: 22
        },
        {
          date: '2024-08-24',
          reviewCount: 944,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-08-25',
          reviewCount: 978,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2024-08-26',
          reviewCount: 978,
          rating: 4,
          salesRank: 21
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-05-16T21:57:07.477Z'
    },
    {
      id: '5f5d669e-6abf-4737-97e3-b2a64aaa51b7',
      productName: 'Outdoor Battery Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-2.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2023-11-17',
          reviewCount: 1,
          rating: 2
        },
        {
          date: '2023-11-18',
          reviewCount: 1,
          rating: 2
        },
        {
          date: '2023-11-19',
          reviewCount: 1,
          rating: 2
        },
        {
          date: '2023-11-20',
          reviewCount: 1,
          rating: 2
        },
        {
          date: '2023-11-21',
          reviewCount: 1,
          rating: 2
        },
        {
          date: '2023-11-22',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-23',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-24',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-25',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-26',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-27',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-28',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-29',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-11-30',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-12-01',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-12-02',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-12-03',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-04',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-05',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-06',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-07',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-08',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-09',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-10',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-11',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-12',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-13',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-14',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-15',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-16',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-17',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-18',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-19',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-20',
          reviewCount: 3,
          rating: 3
        },
        {
          date: '2023-12-21',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-22',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-23',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-24',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-25',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-26',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-27',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-28',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-29',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-30',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2023-12-31',
          reviewCount: 5,
          rating: 3
        },
        {
          date: '2024-01-01',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-02',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-03',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-04',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-05',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-06',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-07',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-08',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-09',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-10',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-11',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-12',
          reviewCount: 6,
          rating: 3
        },
        {
          date: '2024-01-13',
          reviewCount: 8,
          rating: 4
        },
        {
          date: '2024-01-14',
          reviewCount: 8,
          rating: 4
        },
        {
          date: '2024-01-15',
          reviewCount: 8,
          rating: 4
        },
        {
          date: '2024-01-16',
          reviewCount: 8,
          rating: 4
        },
        {
          date: '2024-01-17',
          reviewCount: 8,
          rating: 4
        },
        {
          date: '2024-01-18',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2024-01-19',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2024-01-20',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2024-01-21',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2024-01-22',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2024-01-23',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-24',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-25',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-26',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-27',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-28',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-29',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-30',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-01-31',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-01',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-02',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-03',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-04',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-05',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-06',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-07',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-08',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2024-02-09',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2024-02-10',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2024-02-11',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2024-02-12',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2024-02-13',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2024-02-14',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-15',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-16',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-17',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-18',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-19',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-20',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-21',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-22',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-23',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-24',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2024-02-25',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-02-26',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-02-27',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-02-28',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-02-29',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-03-01',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2024-03-02',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2024-03-03',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2024-03-04',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2024-03-05',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2024-03-06',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2024-03-07',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-08',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-09',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-10',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-11',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-12',
          reviewCount: 21,
          rating: 3
        },
        {
          date: '2024-03-13',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-14',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-15',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-16',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-17',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-18',
          reviewCount: 23,
          rating: 3
        },
        {
          date: '2024-03-19',
          reviewCount: 25,
          rating: 3
        },
        {
          date: '2024-03-20',
          reviewCount: 25,
          rating: 3
        },
        {
          date: '2024-03-21',
          reviewCount: 25,
          rating: 3
        },
        {
          date: '2024-03-22',
          reviewCount: 25,
          rating: 3
        },
        {
          date: '2024-03-23',
          reviewCount: 25,
          rating: 3
        },
        {
          date: '2024-03-24',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-25',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-26',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-27',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-28',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-29',
          reviewCount: 28,
          rating: 3
        },
        {
          date: '2024-03-30',
          reviewCount: 30,
          rating: 3
        },
        {
          date: '2024-03-31',
          reviewCount: 30,
          rating: 3
        },
        {
          date: '2024-04-01',
          reviewCount: 30,
          rating: 3
        },
        {
          date: '2024-04-02',
          reviewCount: 30,
          rating: 3
        },
        {
          date: '2024-04-03',
          reviewCount: 30,
          rating: 3
        },
        {
          date: '2024-04-04',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-05',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-06',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-07',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-08',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-09',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-10',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-11',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-12',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-13',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-14',
          reviewCount: 33,
          rating: 4
        },
        {
          date: '2024-04-15',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-16',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-17',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-18',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-19',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-20',
          reviewCount: 36,
          rating: 4
        },
        {
          date: '2024-04-21',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-22',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-23',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-24',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-25',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-26',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2024-04-27',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-04-28',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-04-29',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 42,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 51,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 53,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 53,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 53,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 53,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 53,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 59,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 64,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 64,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 64,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 64,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 64,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 65,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 65,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 65,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 65,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 65,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 68,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 72,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 80,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 80,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 80,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 80,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 80,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 84,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 86,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 86,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 86,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 86,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 86,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 93,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 97,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 97,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 97,
          rating: 4
        },
        {
          date: '2024-08-28',
          reviewCount: 97,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,

      createdAt: '2024-08-29T22:04:16.138Z'
    },
    {
      id: 'd6802113-ba4f-404e-84cf-523dd6eefa06',
      productName: 'Wired Outdoor Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-3.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2023-08-26',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-08-27',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-08-28',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-08-29',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-08-30',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-08-31',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-09-01',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-09-02',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-09-03',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-09-04',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2023-09-05',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-06',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-07',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-08',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-09',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-10',
          reviewCount: 5,
          rating: 4
        },
        {
          date: '2023-09-11',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2023-09-12',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2023-09-13',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2023-09-14',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2023-09-15',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2023-09-16',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-17',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-18',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-19',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-20',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-21',
          reviewCount: 18,
          rating: 4
        },
        {
          date: '2023-09-22',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-23',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-24',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-25',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-26',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-27',
          reviewCount: 27,
          rating: 4
        },
        {
          date: '2023-09-28',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2023-09-29',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2023-09-30',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2023-10-01',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2023-10-02',
          reviewCount: 43,
          rating: 4
        },
        {
          date: '2023-10-03',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-04',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-05',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-06',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-07',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-08',
          reviewCount: 56,
          rating: 4
        },
        {
          date: '2023-10-09',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-10',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-11',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-12',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-13',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-14',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2023-10-15',
          reviewCount: 79,
          rating: 4
        },
        {
          date: '2023-10-16',
          reviewCount: 79,
          rating: 4
        },
        {
          date: '2023-10-17',
          reviewCount: 79,
          rating: 4
        },
        {
          date: '2023-10-18',
          reviewCount: 79,
          rating: 4
        },
        {
          date: '2023-10-19',
          reviewCount: 79,
          rating: 4
        },
        {
          date: '2023-10-20',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-21',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-22',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-23',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-24',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-25',
          reviewCount: 85,
          rating: 4
        },
        {
          date: '2023-10-26',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-10-27',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-10-28',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-10-29',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-10-30',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-10-31',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2023-11-01',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-02',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-03',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-04',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-05',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-06',
          reviewCount: 117,
          rating: 4
        },
        {
          date: '2023-11-07',
          reviewCount: 131,
          rating: 4
        },
        {
          date: '2023-11-08',
          reviewCount: 131,
          rating: 4
        },
        {
          date: '2023-11-09',
          reviewCount: 131,
          rating: 4
        },
        {
          date: '2023-11-10',
          reviewCount: 131,
          rating: 4
        },
        {
          date: '2023-11-11',
          reviewCount: 131,
          rating: 4
        },
        {
          date: '2023-11-12',
          reviewCount: 140,
          rating: 4
        },
        {
          date: '2023-11-13',
          reviewCount: 140,
          rating: 4
        },
        {
          date: '2023-11-14',
          reviewCount: 140,
          rating: 4
        },
        {
          date: '2023-11-15',
          reviewCount: 140,
          rating: 4
        },
        {
          date: '2023-11-16',
          reviewCount: 140,
          rating: 4
        },
        {
          date: '2023-11-17',
          reviewCount: 152,
          rating: 4
        },
        {
          date: '2023-11-18',
          reviewCount: 152,
          rating: 4
        },
        {
          date: '2023-11-19',
          reviewCount: 152,
          rating: 4
        },
        {
          date: '2023-11-20',
          reviewCount: 152,
          rating: 4
        },
        {
          date: '2023-11-21',
          reviewCount: 152,
          rating: 4
        },
        {
          date: '2023-11-22',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-23',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-24',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-25',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-26',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-27',
          reviewCount: 166,
          rating: 4
        },
        {
          date: '2023-11-28',
          reviewCount: 186,
          rating: 4
        },
        {
          date: '2023-11-29',
          reviewCount: 186,
          rating: 4
        },
        {
          date: '2023-11-30',
          reviewCount: 186,
          rating: 4
        },
        {
          date: '2023-12-01',
          reviewCount: 186,
          rating: 4
        },
        {
          date: '2023-12-02',
          reviewCount: 186,
          rating: 4
        },
        {
          date: '2023-12-03',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-04',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-05',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-06',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-07',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-08',
          reviewCount: 201,
          rating: 4
        },
        {
          date: '2023-12-09',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-10',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-11',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-12',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-13',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-14',
          reviewCount: 221,
          rating: 4
        },
        {
          date: '2023-12-15',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-16',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-17',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-18',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-19',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-20',
          reviewCount: 251,
          rating: 4
        },
        {
          date: '2023-12-21',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-22',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-23',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-24',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-25',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-26',
          reviewCount: 270,
          rating: 4
        },
        {
          date: '2023-12-27',
          reviewCount: 290,
          rating: 4
        },
        {
          date: '2023-12-28',
          reviewCount: 290,
          rating: 4
        },
        {
          date: '2023-12-29',
          reviewCount: 290,
          rating: 4
        },
        {
          date: '2023-12-30',
          reviewCount: 290,
          rating: 4
        },
        {
          date: '2023-12-31',
          reviewCount: 290,
          rating: 4
        },
        {
          date: '2024-01-01',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-02',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-03',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-04',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-05',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-06',
          reviewCount: 313,
          rating: 4
        },
        {
          date: '2024-01-07',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-08',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-09',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-10',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-11',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-12',
          reviewCount: 333,
          rating: 4
        },
        {
          date: '2024-01-13',
          reviewCount: 349,
          rating: 4
        },
        {
          date: '2024-01-14',
          reviewCount: 349,
          rating: 4
        },
        {
          date: '2024-01-15',
          reviewCount: 349,
          rating: 4
        },
        {
          date: '2024-01-16',
          reviewCount: 349,
          rating: 4
        },
        {
          date: '2024-01-17',
          reviewCount: 349,
          rating: 4
        },
        {
          date: '2024-01-18',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-01-19',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-01-20',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-01-21',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-01-22',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-01-23',
          reviewCount: 376,
          rating: 4
        },
        {
          date: '2024-01-24',
          reviewCount: 376,
          rating: 4
        },
        {
          date: '2024-01-25',
          reviewCount: 376,
          rating: 4
        },
        {
          date: '2024-01-26',
          reviewCount: 376,
          rating: 4
        },
        {
          date: '2024-01-27',
          reviewCount: 376,
          rating: 4
        },
        {
          date: '2024-01-28',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-01-29',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-01-30',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-01-31',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-02-01',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-02-02',
          reviewCount: 393,
          rating: 4
        },
        {
          date: '2024-02-03',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-04',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-05',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-06',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-07',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-08',
          reviewCount: 406,
          rating: 4
        },
        {
          date: '2024-02-09',
          reviewCount: 420,
          rating: 4
        },
        {
          date: '2024-02-10',
          reviewCount: 420,
          rating: 4
        },
        {
          date: '2024-02-11',
          reviewCount: 420,
          rating: 4
        },
        {
          date: '2024-02-12',
          reviewCount: 420,
          rating: 4
        },
        {
          date: '2024-02-13',
          reviewCount: 420,
          rating: 4
        },
        {
          date: '2024-02-14',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-15',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-16',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-17',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-18',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-19',
          reviewCount: 431,
          rating: 4
        },
        {
          date: '2024-02-20',
          reviewCount: 442,
          rating: 4
        },
        {
          date: '2024-02-21',
          reviewCount: 442,
          rating: 4
        },
        {
          date: '2024-02-22',
          reviewCount: 442,
          rating: 4
        },
        {
          date: '2024-02-23',
          reviewCount: 442,
          rating: 4
        },
        {
          date: '2024-02-24',
          reviewCount: 442,
          rating: 4
        },
        {
          date: '2024-02-25',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-02-26',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-02-27',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-02-28',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-02-29',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-03-01',
          reviewCount: 450,
          rating: 4
        },
        {
          date: '2024-03-02',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-03-03',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-03-04',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-03-05',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-03-06',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-03-07',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-08',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-09',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-10',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-11',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-12',
          reviewCount: 466,
          rating: 4
        },
        {
          date: '2024-03-13',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-14',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-15',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-16',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-17',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-18',
          reviewCount: 479,
          rating: 4
        },
        {
          date: '2024-03-19',
          reviewCount: 482,
          rating: 4
        },
        {
          date: '2024-03-20',
          reviewCount: 482,
          rating: 4
        },
        {
          date: '2024-03-21',
          reviewCount: 482,
          rating: 4
        },
        {
          date: '2024-03-22',
          reviewCount: 482,
          rating: 4
        },
        {
          date: '2024-03-23',
          reviewCount: 482,
          rating: 4
        },
        {
          date: '2024-03-24',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-25',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-26',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-27',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-28',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-29',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-30',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-03-31',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-04-01',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-04-02',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-04-03',
          reviewCount: 493,
          rating: 4
        },
        {
          date: '2024-04-04',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-05',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-06',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-07',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-08',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-09',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-10',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-11',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-12',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-13',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-14',
          reviewCount: 517,
          rating: 4
        },
        {
          date: '2024-04-15',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-16',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-17',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-18',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-19',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-20',
          reviewCount: 531,
          rating: 4
        },
        {
          date: '2024-04-21',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-22',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-23',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-24',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-25',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-26',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-04-27',
          reviewCount: 562,
          rating: 4
        },
        {
          date: '2024-04-28',
          reviewCount: 562,
          rating: 4
        },
        {
          date: '2024-04-29',
          reviewCount: 562,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 562,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 562,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 568,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 568,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 568,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 568,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 568,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 573,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 573,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 573,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 573,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 573,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 586,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 599,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 599,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 599,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 599,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 599,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 603,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 620,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 620,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 620,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 620,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 620,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 629,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 638,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 638,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 638,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 638,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 638,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 650,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 660,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 660,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 660,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 660,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 660,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 669,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 680,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 680,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 680,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 680,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 680,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 691,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 691,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 691,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 691,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 691,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 702,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 715,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 715,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 715,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 715,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 715,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 731,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 762,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 775,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 775,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 775,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 775,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 775,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 786,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 796,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 796,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 796,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 796,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 796,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 811,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 826,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 826,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 826,
          rating: 4
        },
        {
          date: '2024-08-28',
          reviewCount: 826,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,

      createdAt: '2024-08-29T22:14:47.262Z'
    },
    {
      id: 'e4ac899f-3ad6-4c8f-81da-2db386e23221',
      productName: 'Outdoor Wired 2K Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-3.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2023-08-21',

          salesRank: 740
        },
        {
          date: '2023-08-22',

          salesRank: 936
        },
        {
          date: '2023-08-23',

          salesRank: 1082
        },
        {
          date: '2023-08-24',

          salesRank: 1301
        },
        {
          date: '2023-08-25',

          salesRank: 488
        },
        {
          date: '2023-08-26',

          salesRank: 289
        },
        {
          date: '2023-08-27',

          salesRank: 231
        },
        {
          date: '2023-08-28',

          salesRank: 199
        },
        {
          date: '2023-08-29',

          salesRank: 177
        },
        {
          date: '2023-08-30',

          salesRank: 153
        },
        {
          date: '2023-08-31',

          salesRank: 175
        },
        {
          date: '2023-09-01',

          salesRank: 126
        },
        {
          date: '2023-09-02',

          salesRank: 117
        },
        {
          date: '2023-09-03',

          salesRank: 93
        },
        {
          date: '2023-09-04',

          salesRank: 56
        },
        {
          date: '2023-09-05',

          salesRank: 62
        },
        {
          date: '2023-09-06',

          salesRank: 11
        },
        {
          date: '2023-09-07',

          salesRank: 10
        },
        {
          date: '2023-09-08',

          salesRank: 15
        },
        {
          date: '2023-09-09',

          salesRank: 24
        },
        {
          date: '2023-09-10',

          salesRank: 24
        },
        {
          date: '2023-09-11',

          salesRank: 25
        },
        {
          date: '2023-09-12',

          salesRank: 33
        },
        {
          date: '2023-09-13',

          salesRank: 47
        },
        {
          date: '2023-09-14',

          salesRank: 52
        },
        {
          date: '2023-09-15',

          salesRank: 45
        },
        {
          date: '2023-09-16',

          salesRank: 63
        },
        {
          date: '2023-09-17',

          salesRank: 59
        },
        {
          date: '2023-09-18',

          salesRank: 28
        },
        {
          date: '2023-09-19',

          salesRank: 23
        },
        {
          date: '2023-09-20',

          salesRank: 23
        },
        {
          date: '2023-09-21',

          salesRank: 21
        },
        {
          date: '2023-09-22',

          salesRank: 19
        },
        {
          date: '2023-09-23',

          salesRank: 28
        },
        {
          date: '2023-09-24',

          salesRank: 19
        },
        {
          date: '2023-09-25',

          salesRank: 33
        },
        {
          date: '2023-09-26',

          salesRank: 34
        },
        {
          date: '2023-09-27',

          salesRank: 37
        },
        {
          date: '2023-09-28',

          salesRank: 45
        },
        {
          date: '2023-09-29',

          salesRank: 40
        },
        {
          date: '2023-09-30',

          salesRank: 41
        },
        {
          date: '2023-10-01',

          salesRank: 37
        },
        {
          date: '2023-10-02',

          salesRank: 41
        },
        {
          date: '2023-10-03',

          salesRank: 43
        },
        {
          date: '2023-10-04',

          salesRank: 46
        },
        {
          date: '2023-10-05',

          salesRank: 48
        },
        {
          date: '2023-10-06',

          salesRank: 61
        },
        {
          date: '2023-10-07',

          salesRank: 48
        },
        {
          date: '2023-10-08',

          salesRank: 67
        },
        {
          date: '2023-10-09',

          salesRank: 65
        },
        {
          date: '2023-10-10',

          salesRank: 19
        },
        {
          date: '2023-10-11',

          salesRank: 14
        },
        {
          date: '2023-10-12',

          salesRank: 14
        },
        {
          date: '2023-10-13',

          salesRank: 16
        },
        {
          date: '2023-10-14',

          salesRank: 17
        },
        {
          date: '2023-10-15',

          salesRank: 22
        },
        {
          date: '2023-10-16',

          salesRank: 24
        },
        {
          date: '2023-10-17',

          salesRank: 33
        },
        {
          date: '2023-10-18',

          salesRank: 37
        },
        {
          date: '2023-10-19',

          salesRank: 42
        },
        {
          date: '2023-10-20',

          salesRank: 48
        },
        {
          date: '2023-10-21',

          salesRank: 43
        },
        {
          date: '2023-10-22',

          salesRank: 47
        },
        {
          date: '2023-10-23',

          salesRank: 52
        },
        {
          date: '2023-10-24',

          salesRank: 56
        },
        {
          date: '2023-10-25',

          salesRank: 50
        },
        {
          date: '2023-10-26',

          salesRank: 56
        },
        {
          date: '2023-10-27',

          salesRank: 48
        },
        {
          date: '2023-10-28',

          salesRank: 53
        },
        {
          date: '2023-10-29',

          salesRank: 48
        },
        {
          date: '2023-10-30',

          salesRank: 54
        },
        {
          date: '2023-10-31',

          salesRank: 53
        },
        {
          date: '2023-11-01',

          salesRank: 52
        },
        {
          date: '2023-11-02',

          salesRank: 46
        },
        {
          date: '2023-11-03',

          salesRank: 49
        },
        {
          date: '2023-11-04',

          salesRank: 54
        },
        {
          date: '2023-11-05',

          salesRank: 44
        },
        {
          date: '2023-11-06',

          salesRank: 53
        },
        {
          date: '2023-11-07',

          salesRank: 53
        },
        {
          date: '2023-11-08',

          salesRank: 49
        },
        {
          date: '2023-11-09',

          salesRank: 51
        },
        {
          date: '2023-11-11',

          salesRank: 55
        },
        {
          date: '2023-11-12',

          salesRank: 54
        },
        {
          date: '2023-11-13',

          salesRank: 60
        },
        {
          date: '2023-11-14',

          salesRank: 67
        },
        {
          date: '2023-11-15',

          salesRank: 68
        },
        {
          date: '2023-11-16',

          salesRank: 67
        },
        {
          date: '2023-11-17',

          salesRank: 33
        },
        {
          date: '2023-11-18',

          salesRank: 13
        },
        {
          date: '2023-11-19',

          salesRank: 12
        },
        {
          date: '2023-11-23',

          salesRank: 12
        },
        {
          date: '2023-11-24',

          salesRank: 14
        },
        {
          date: '2023-11-27',

          salesRank: 14
        },
        {
          date: '2023-11-28',

          salesRank: 14
        },
        {
          date: '2023-11-29',

          salesRank: 17
        },
        {
          date: '2023-11-30',

          salesRank: 19
        },
        {
          date: '2023-12-01',

          salesRank: 20
        },
        {
          date: '2023-12-02',

          salesRank: 26
        },
        {
          date: '2023-12-03',

          salesRank: 26
        },
        {
          date: '2023-12-04',

          salesRank: 33
        },
        {
          date: '2023-12-05',

          salesRank: 38
        },
        {
          date: '2023-12-06',

          salesRank: 37
        },
        {
          date: '2023-12-07',

          salesRank: 41
        },
        {
          date: '2023-12-08',

          salesRank: 39
        },
        {
          date: '2023-12-09',

          salesRank: 40
        },
        {
          date: '2023-12-10',

          salesRank: 40
        },
        {
          date: '2023-12-11',

          salesRank: 47
        },
        {
          date: '2023-12-12',

          salesRank: 47
        },
        {
          date: '2023-12-13',

          salesRank: 51
        },
        {
          date: '2023-12-14',

          salesRank: 56
        },
        {
          date: '2023-12-15',

          salesRank: 56
        },
        {
          date: '2023-12-16',

          salesRank: 54
        },
        {
          date: '2023-12-17',

          salesRank: 58
        },
        {
          date: '2023-12-18',

          salesRank: 56
        },
        {
          date: '2023-12-19',

          salesRank: 53
        },
        {
          date: '2023-12-20',

          salesRank: 48
        },
        {
          date: '2023-12-21',

          salesRank: 51
        },
        {
          date: '2023-12-22',

          salesRank: 52
        },
        {
          date: '2023-12-23',

          salesRank: 45
        },
        {
          date: '2023-12-24',

          salesRank: 39
        },
        {
          date: '2023-12-25',

          salesRank: 45
        },
        {
          date: '2023-12-26',

          salesRank: 46
        },
        {
          date: '2023-12-27',

          salesRank: 53
        },
        {
          date: '2023-12-28',

          salesRank: 60
        },
        {
          date: '2023-12-29',

          salesRank: 57
        },
        {
          date: '2023-12-30',

          salesRank: 63
        },
        {
          date: '2023-12-31',

          salesRank: 69
        },
        {
          date: '2024-01-01',

          salesRank: 61
        },
        {
          date: '2024-01-02',

          salesRank: 54
        },
        {
          date: '2024-01-03',

          salesRank: 57
        },
        {
          date: '2024-01-04',

          salesRank: 52
        },
        {
          date: '2024-01-05',

          salesRank: 45
        },
        {
          date: '2024-01-06',

          salesRank: 42
        },
        {
          date: '2024-01-07',

          salesRank: 37
        },
        {
          date: '2024-01-08',

          salesRank: 41
        },
        {
          date: '2024-01-09',

          salesRank: 56
        },
        {
          date: '2024-01-10',

          salesRank: 94
        },
        {
          date: '2024-01-11',

          salesRank: 92
        },
        {
          date: '2024-01-12',

          salesRank: 97
        },
        {
          date: '2024-01-13',

          salesRank: 85
        },
        {
          date: '2024-01-14',

          salesRank: 86
        },
        {
          date: '2024-01-15',

          salesRank: 71
        },
        {
          date: '2024-01-16',

          salesRank: 65
        },
        {
          date: '2024-01-17',

          salesRank: 71
        },
        {
          date: '2024-01-18',

          salesRank: 68
        },
        {
          date: '2024-01-19',

          salesRank: 70
        },
        {
          date: '2024-01-20',

          salesRank: 69
        },
        {
          date: '2024-01-21',

          salesRank: 75
        },
        {
          date: '2024-01-22',

          salesRank: 63
        },
        {
          date: '2024-01-23',

          salesRank: 70
        },
        {
          date: '2024-01-24',

          salesRank: 63
        },
        {
          date: '2024-01-25',

          salesRank: 61
        },
        {
          date: '2024-01-26',

          salesRank: 67
        },
        {
          date: '2024-01-27',

          salesRank: 60
        },
        {
          date: '2024-01-28',

          salesRank: 51
        },
        {
          date: '2024-01-29',

          salesRank: 60
        },
        {
          date: '2024-01-30',

          salesRank: 34
        },
        {
          date: '2024-01-31',

          salesRank: 33
        },
        {
          date: '2024-02-01',

          salesRank: 43
        },
        {
          date: '2024-02-02',

          salesRank: 49
        },
        {
          date: '2024-02-03',

          salesRank: 49
        },
        {
          date: '2024-02-04',

          salesRank: 39
        },
        {
          date: '2024-02-05',

          salesRank: 41
        },
        {
          date: '2024-02-06',

          salesRank: 45
        },
        {
          date: '2024-02-07',

          salesRank: 62
        },
        {
          date: '2024-02-08',

          salesRank: 55
        },
        {
          date: '2024-02-09',

          salesRank: 63
        },
        {
          date: '2024-02-10',

          salesRank: 69
        },
        {
          date: '2024-02-11',

          salesRank: 57
        },
        {
          date: '2024-02-12',

          salesRank: 62
        },
        {
          date: '2024-02-13',

          salesRank: 59
        },
        {
          date: '2024-02-14',

          salesRank: 57
        },
        {
          date: '2024-02-15',

          salesRank: 76
        },
        {
          date: '2024-02-16',

          salesRank: 71
        },
        {
          date: '2024-02-17',

          salesRank: 56
        },
        {
          date: '2024-02-18',

          salesRank: 66
        },
        {
          date: '2024-02-19',

          salesRank: 83
        },
        {
          date: '2024-02-20',

          salesRank: 53
        },
        {
          date: '2024-02-21',

          salesRank: 56
        },
        {
          date: '2024-02-22',

          salesRank: 88
        },
        {
          date: '2024-02-23',

          salesRank: 68
        },
        {
          date: '2024-02-24',

          salesRank: 82
        },
        {
          date: '2024-02-25',

          salesRank: 87
        },
        {
          date: '2024-02-26',

          salesRank: 51
        },
        {
          date: '2024-02-27',

          salesRank: 40
        },
        {
          date: '2024-02-28',

          salesRank: 34
        },
        {
          date: '2024-02-29',

          salesRank: 38
        },
        {
          date: '2024-03-01',

          salesRank: 45
        },
        {
          date: '2024-03-02',

          salesRank: 58
        },
        {
          date: '2024-03-03',

          salesRank: 61
        },
        {
          date: '2024-03-04',

          salesRank: 86
        },
        {
          date: '2024-03-05',

          salesRank: 61
        },
        {
          date: '2024-03-06',

          salesRank: 71
        },
        {
          date: '2024-03-07',

          salesRank: 57
        },
        {
          date: '2024-03-08',

          salesRank: 68
        },
        {
          date: '2024-03-09',

          salesRank: 65
        },
        {
          date: '2024-03-10',

          salesRank: 59
        },
        {
          date: '2024-03-11',

          salesRank: 64
        },
        {
          date: '2024-03-12',

          salesRank: 62
        },
        {
          date: '2024-03-13',

          salesRank: 60
        },
        {
          date: '2024-03-14',

          salesRank: 55
        },
        {
          date: '2024-03-15',

          salesRank: 51
        },
        {
          date: '2024-03-16',

          salesRank: 55
        },
        {
          date: '2024-03-17',

          salesRank: 65
        },
        {
          date: '2024-03-18',

          salesRank: 56
        },
        {
          date: '2024-03-19',

          salesRank: 50
        },
        {
          date: '2024-03-20',

          salesRank: 47
        },
        {
          date: '2024-03-21',

          salesRank: 43
        },
        {
          date: '2024-03-22',

          salesRank: 36
        },
        {
          date: '2024-03-23',

          salesRank: 29
        },
        {
          date: '2024-03-24',

          salesRank: 29
        },
        {
          date: '2024-03-25',

          salesRank: 35
        },
        {
          date: '2024-03-26',

          salesRank: 38
        },
        {
          date: '2024-03-27',

          salesRank: 39
        },
        {
          date: '2024-03-28',

          salesRank: 39
        },
        {
          date: '2024-03-29',

          salesRank: 42
        },
        {
          date: '2024-03-30',

          salesRank: 42
        },
        {
          date: '2024-03-31',

          salesRank: 36
        },
        {
          date: '2024-04-01',

          salesRank: 47
        },
        {
          date: '2024-04-02',

          salesRank: 40
        },
        {
          date: '2024-04-03',

          salesRank: 41
        },
        {
          date: '2024-04-04',

          salesRank: 44
        },
        {
          date: '2024-04-05',

          salesRank: 47
        },
        {
          date: '2024-04-06',

          salesRank: 46
        },
        {
          date: '2024-04-07',

          salesRank: 46
        },
        {
          date: '2024-04-08',

          salesRank: 56
        },
        {
          date: '2024-04-09',

          salesRank: 57
        },
        {
          date: '2024-04-10',

          salesRank: 48
        },
        {
          date: '2024-04-11',

          salesRank: 59
        },
        {
          date: '2024-04-12',

          salesRank: 66
        },
        {
          date: '2024-04-13',

          salesRank: 59
        },
        {
          date: '2024-04-14',

          salesRank: 64
        },
        {
          date: '2024-04-15',

          salesRank: 42
        },
        {
          date: '2024-04-16',

          salesRank: 34
        },
        {
          date: '2024-04-17',

          salesRank: 36
        },
        {
          date: '2024-04-18',

          salesRank: 31
        },
        {
          date: '2024-04-20',

          salesRank: 34
        },
        {
          date: '2024-04-21',

          salesRank: 34
        },
        {
          date: '2024-04-22',

          salesRank: 37
        },
        {
          date: '2024-04-23',

          salesRank: 48
        },
        {
          date: '2024-04-24',

          salesRank: 57
        },
        {
          date: '2024-04-25',

          salesRank: 54
        },
        {
          date: '2024-04-26',

          salesRank: 56
        },
        {
          date: '2024-04-27',

          salesRank: 66
        },
        {
          date: '2024-04-28',

          salesRank: 61
        },
        {
          date: '2024-04-29',

          salesRank: 54
        },
        {
          date: '2024-04-30',

          salesRank: 60
        },
        {
          date: '2024-05-01',

          salesRank: 57
        },
        {
          date: '2024-05-02',

          salesRank: 46
        },
        {
          date: '2024-05-03',

          salesRank: 57
        },
        {
          date: '2024-05-04',

          salesRank: 63
        },
        {
          date: '2024-05-05',

          salesRank: 64
        },
        {
          date: '2024-05-06',

          salesRank: 69
        },
        {
          date: '2024-05-07',

          salesRank: 70
        },
        {
          date: '2024-05-08',

          salesRank: 81
        },
        {
          date: '2024-05-09',

          salesRank: 66
        },
        {
          date: '2024-05-10',

          salesRank: 67
        },
        {
          date: '2024-05-11',

          salesRank: 57
        },
        {
          date: '2024-05-12',

          salesRank: 57
        },
        {
          date: '2024-05-13',

          salesRank: 63
        },
        {
          date: '2024-05-14',

          salesRank: 47
        },
        {
          date: '2024-05-15',

          salesRank: 41
        },
        {
          date: '2024-05-16',

          salesRank: 45
        },
        {
          date: '2024-05-17',

          salesRank: 45
        },
        {
          date: '2024-05-18',

          salesRank: 44
        },
        {
          date: '2024-05-19',

          salesRank: 41
        },
        {
          date: '2024-05-20',

          salesRank: 39
        },
        {
          date: '2024-05-21',

          salesRank: 36
        },
        {
          date: '2024-05-22',

          salesRank: 30
        },
        {
          date: '2024-05-23',

          salesRank: 31
        },
        {
          date: '2024-05-24',

          salesRank: 33
        },
        {
          date: '2024-05-25',

          salesRank: 33
        },
        {
          date: '2024-05-26',

          salesRank: 31
        },
        {
          date: '2024-05-27',

          salesRank: 52
        },
        {
          date: '2024-05-28',

          salesRank: 60
        },
        {
          date: '2024-05-29',

          salesRank: 55
        },
        {
          date: '2024-05-30',

          salesRank: 59
        },
        {
          date: '2024-05-31',

          salesRank: 57
        },
        {
          date: '2024-06-01',

          salesRank: 54
        },
        {
          date: '2024-06-02',

          salesRank: 55
        },
        {
          date: '2024-06-03',

          salesRank: 42
        },
        {
          date: '2024-06-04',

          salesRank: 56
        },
        {
          date: '2024-06-05',

          salesRank: 62
        },
        {
          date: '2024-06-07',

          salesRank: 52
        },
        {
          date: '2024-06-08',

          salesRank: 47
        },
        {
          date: '2024-06-09',

          salesRank: 59
        },
        {
          date: '2024-06-10',

          salesRank: 59
        },
        {
          date: '2024-06-11',

          salesRank: 49
        },
        {
          date: '2024-06-12',

          salesRank: 50
        },
        {
          date: '2024-06-13',

          salesRank: 62
        },
        {
          date: '2024-06-14',

          salesRank: 53
        },
        {
          date: '2024-06-15',

          salesRank: 43
        },
        {
          date: '2024-06-16',

          salesRank: 50
        },
        {
          date: '2024-06-17',

          salesRank: 42
        },
        {
          date: '2024-06-18',

          salesRank: 41
        },
        {
          date: '2024-06-19',

          salesRank: 42
        },
        {
          date: '2024-06-20',

          salesRank: 44
        },
        {
          date: '2024-06-21',

          salesRank: 43
        },
        {
          date: '2024-06-22',

          salesRank: 50
        },
        {
          date: '2024-06-23',

          salesRank: 42
        },
        {
          date: '2024-06-24',

          salesRank: 54
        },
        {
          date: '2024-06-25',

          salesRank: 61
        },
        {
          date: '2024-06-26',

          salesRank: 64
        },
        {
          date: '2024-06-27',

          salesRank: 67
        },
        {
          date: '2024-06-28',

          salesRank: 53
        },
        {
          date: '2024-06-29',

          salesRank: 54
        },
        {
          date: '2024-06-30',

          salesRank: 65
        },
        {
          date: '2024-07-01',

          salesRank: 98
        },
        {
          date: '2024-07-02',

          salesRank: 106
        },
        {
          date: '2024-07-03',

          salesRank: 94
        },
        {
          date: '2024-07-04',

          salesRank: 90
        },
        {
          date: '2024-07-05',

          salesRank: 75
        },
        {
          date: '2024-07-06',

          salesRank: 72
        },
        {
          date: '2024-07-07',

          salesRank: 77
        },
        {
          date: '2024-07-08',

          salesRank: 79
        },
        {
          date: '2024-07-09',

          salesRank: 88
        },
        {
          date: '2024-07-10',

          salesRank: 88
        },
        {
          date: '2024-07-11',

          salesRank: 74
        },
        {
          date: '2024-07-12',

          salesRank: 74
        },
        {
          date: '2024-07-13',

          salesRank: 78
        },
        {
          date: '2024-07-14',

          salesRank: 83
        },
        {
          date: '2024-07-15',

          salesRank: 84
        },
        {
          date: '2024-07-16',

          salesRank: 49
        },
        {
          date: '2024-07-17',

          salesRank: 25
        },
        {
          date: '2024-07-18',

          salesRank: 24
        },
        {
          date: '2024-07-19',

          salesRank: 26
        },
        {
          date: '2024-07-20',

          salesRank: 28
        },
        {
          date: '2024-07-22',

          salesRank: 29
        },
        {
          date: '2024-07-23',

          salesRank: 34
        },
        {
          date: '2024-07-24',

          salesRank: 45
        },
        {
          date: '2024-07-25',

          salesRank: 46
        },
        {
          date: '2024-07-26',

          salesRank: 48
        },
        {
          date: '2024-07-27',

          salesRank: 47
        },
        {
          date: '2024-07-28',

          salesRank: 45
        },
        {
          date: '2024-07-29',

          salesRank: 44
        },
        {
          date: '2024-07-30',

          salesRank: 43
        },
        {
          date: '2024-07-31',

          salesRank: 52
        },
        {
          date: '2024-08-01',

          salesRank: 52
        },
        {
          date: '2024-08-02',

          salesRank: 57
        },
        {
          date: '2024-08-03',

          salesRank: 54
        },
        {
          date: '2024-08-04',

          salesRank: 52
        },
        {
          date: '2024-08-05',

          salesRank: 47
        },
        {
          date: '2024-08-06',

          salesRank: 44
        },
        {
          date: '2024-08-07',

          salesRank: 42
        },
        {
          date: '2024-08-08',

          salesRank: 43
        },
        {
          date: '2024-08-10',

          salesRank: 45
        },
        {
          date: '2024-08-11',

          salesRank: 35
        },
        {
          date: '2024-08-12',

          salesRank: 39
        },
        {
          date: '2024-08-13',

          salesRank: 37
        },
        {
          date: '2024-08-14',

          salesRank: 43
        },
        {
          date: '2024-08-15',

          salesRank: 51
        },
        {
          date: '2024-08-16',

          salesRank: 53
        },
        {
          date: '2024-08-19',

          salesRank: 49
        },
        {
          date: '2024-08-20',

          salesRank: 53
        },
        {
          date: '2024-08-22',

          salesRank: 59
        },
        {
          date: '2024-08-23',

          salesRank: 55
        },
        {
          date: '2024-08-24',

          salesRank: 64
        },
        {
          date: '2024-08-25',

          salesRank: 52
        },
        {
          date: '2024-08-26',

          salesRank: 60
        },
        {
          date: '2024-08-27',

          salesRank: 59
        },
        {
          date: '2024-08-28',

          salesRank: 67
        },
        {
          date: '2024-08-29',

          salesRank: 65
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-29T22:15:30.531Z'
    },
    {
      id: '97d860b5-030b-4ce7-8486-7c0a298ee727',
      productName: 'Outdoor Wired 2K Camera',
      productUrl: 'https://www.amazon.com',
      productImage: 'sample/images/sample-sku-outdoor-camera-3.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2023-10-03',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-04',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-05',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-06',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-07',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-08',
          reviewCount: 2,
          rating: 3
        },
        {
          date: '2023-10-09',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-10',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-11',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-12',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-13',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-14',
          reviewCount: 9,
          rating: 4
        },
        {
          date: '2023-10-15',
          reviewCount: 10,
          rating: 4
        },
        {
          date: '2023-10-16',
          reviewCount: 10,
          rating: 4
        },
        {
          date: '2023-10-17',
          reviewCount: 10,
          rating: 4
        },
        {
          date: '2023-10-18',
          reviewCount: 10,
          rating: 4
        },
        {
          date: '2023-10-19',
          reviewCount: 10,
          rating: 4
        },
        {
          date: '2023-10-20',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-21',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-22',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-23',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-24',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-25',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2023-10-26',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-10-27',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-10-28',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-10-29',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-10-30',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-10-31',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-01',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-02',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-03',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-04',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-05',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-06',
          reviewCount: 16,
          rating: 4
        },
        {
          date: '2023-11-07',
          reviewCount: 39,
          rating: 4
        },
        {
          date: '2023-11-08',
          reviewCount: 39,
          rating: 4
        },
        {
          date: '2023-11-09',
          reviewCount: 39,
          rating: 4
        },
        {
          date: '2023-11-10',
          reviewCount: 39,
          rating: 4
        },
        {
          date: '2023-11-11',
          reviewCount: 39,
          rating: 4
        },
        {
          date: '2023-11-12',
          reviewCount: 49,
          rating: 4
        },
        {
          date: '2023-11-13',
          reviewCount: 49,
          rating: 4
        },
        {
          date: '2023-11-14',
          reviewCount: 49,
          rating: 4
        },
        {
          date: '2023-11-15',
          reviewCount: 49,
          rating: 4
        },
        {
          date: '2023-11-16',
          reviewCount: 49,
          rating: 4
        },
        {
          date: '2023-11-17',
          reviewCount: 52,
          rating: 4
        },
        {
          date: '2023-11-18',
          reviewCount: 52,
          rating: 4
        },
        {
          date: '2023-11-19',
          reviewCount: 52,
          rating: 4
        },
        {
          date: '2023-11-20',
          reviewCount: 52,
          rating: 4
        },
        {
          date: '2023-11-21',
          reviewCount: 52,
          rating: 4
        },
        {
          date: '2023-11-22',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-23',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-24',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-25',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-26',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-27',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2023-11-28',
          reviewCount: 75,
          rating: 4
        },
        {
          date: '2023-11-29',
          reviewCount: 75,
          rating: 4
        },
        {
          date: '2023-11-30',
          reviewCount: 75,
          rating: 4
        },
        {
          date: '2023-12-01',
          reviewCount: 75,
          rating: 4
        },
        {
          date: '2023-12-02',
          reviewCount: 75,
          rating: 4
        },
        {
          date: '2023-12-03',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-04',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-05',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-06',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-07',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-08',
          reviewCount: 92,
          rating: 4
        },
        {
          date: '2023-12-09',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-10',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-11',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-12',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-13',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-14',
          reviewCount: 107,
          rating: 4
        },
        {
          date: '2023-12-15',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-16',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-17',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-18',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-19',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-20',
          reviewCount: 114,
          rating: 4
        },
        {
          date: '2023-12-21',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-22',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-23',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-24',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-25',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-26',
          reviewCount: 124,
          rating: 4
        },
        {
          date: '2023-12-27',
          reviewCount: 143,
          rating: 4
        },
        {
          date: '2023-12-28',
          reviewCount: 143,
          rating: 4
        },
        {
          date: '2023-12-29',
          reviewCount: 143,
          rating: 4
        },
        {
          date: '2023-12-30',
          reviewCount: 143,
          rating: 4
        },
        {
          date: '2023-12-31',
          reviewCount: 143,
          rating: 4
        },
        {
          date: '2024-01-01',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-02',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-03',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-04',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-05',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-06',
          reviewCount: 160,
          rating: 4
        },
        {
          date: '2024-01-07',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-08',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-09',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-10',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-11',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-12',
          reviewCount: 175,
          rating: 4
        },
        {
          date: '2024-01-13',
          reviewCount: 184,
          rating: 4
        },
        {
          date: '2024-01-14',
          reviewCount: 184,
          rating: 4
        },
        {
          date: '2024-01-15',
          reviewCount: 184,
          rating: 4
        },
        {
          date: '2024-01-16',
          reviewCount: 184,
          rating: 4
        },
        {
          date: '2024-01-17',
          reviewCount: 184,
          rating: 4
        },
        {
          date: '2024-01-18',
          reviewCount: 197,
          rating: 4
        },
        {
          date: '2024-01-19',
          reviewCount: 197,
          rating: 4
        },
        {
          date: '2024-01-20',
          reviewCount: 197,
          rating: 4
        },
        {
          date: '2024-01-21',
          reviewCount: 197,
          rating: 4
        },
        {
          date: '2024-01-22',
          reviewCount: 197,
          rating: 4
        },
        {
          date: '2024-01-23',
          reviewCount: 206,
          rating: 4
        },
        {
          date: '2024-01-24',
          reviewCount: 206,
          rating: 4
        },
        {
          date: '2024-01-25',
          reviewCount: 206,
          rating: 4
        },
        {
          date: '2024-01-26',
          reviewCount: 206,
          rating: 4
        },
        {
          date: '2024-01-27',
          reviewCount: 206,
          rating: 4
        },
        {
          date: '2024-01-28',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-01-29',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-01-30',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-01-31',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-02-01',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-02-02',
          reviewCount: 213,
          rating: 4
        },
        {
          date: '2024-02-03',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-04',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-05',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-06',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-07',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-08',
          reviewCount: 226,
          rating: 4
        },
        {
          date: '2024-02-09',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-02-10',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-02-11',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-02-12',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-02-13',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-02-14',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-15',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-16',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-17',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-18',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-19',
          reviewCount: 252,
          rating: 4
        },
        {
          date: '2024-02-20',
          reviewCount: 264,
          rating: 4
        },
        {
          date: '2024-02-21',
          reviewCount: 264,
          rating: 4
        },
        {
          date: '2024-02-22',
          reviewCount: 264,
          rating: 4
        },
        {
          date: '2024-02-23',
          reviewCount: 264,
          rating: 4
        },
        {
          date: '2024-02-24',
          reviewCount: 264,
          rating: 4
        },
        {
          date: '2024-02-25',
          reviewCount: 274,
          rating: 4
        },
        {
          date: '2024-02-26',
          reviewCount: 274,
          rating: 4
        },
        {
          date: '2024-02-27',
          reviewCount: 274,
          rating: 4
        },
        {
          date: '2024-02-28',
          reviewCount: 274,
          rating: 4
        },
        {
          date: '2024-02-29',
          reviewCount: 274,
          rating: 4
        },
        {
          date: '2024-03-01',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-02',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-03',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-04',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-05',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-06',
          reviewCount: 281,
          rating: 4
        },
        {
          date: '2024-03-07',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-08',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-09',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-10',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-11',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-12',
          reviewCount: 298,
          rating: 4
        },
        {
          date: '2024-03-13',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-14',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-15',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-16',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-17',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-18',
          reviewCount: 307,
          rating: 4
        },
        {
          date: '2024-03-19',
          reviewCount: 316,
          rating: 4
        },
        {
          date: '2024-03-20',
          reviewCount: 316,
          rating: 4
        },
        {
          date: '2024-03-21',
          reviewCount: 316,
          rating: 4
        },
        {
          date: '2024-03-22',
          reviewCount: 316,
          rating: 4
        },
        {
          date: '2024-03-23',
          reviewCount: 316,
          rating: 4
        },
        {
          date: '2024-03-24',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-25',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-26',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-27',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-28',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-29',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-30',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-03-31',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-04-01',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-04-02',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-04-03',
          reviewCount: 326,
          rating: 4
        },
        {
          date: '2024-04-04',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-05',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-06',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-07',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-08',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-09',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-10',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-11',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-12',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-13',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-14',
          reviewCount: 337,
          rating: 4
        },
        {
          date: '2024-04-15',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-16',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-17',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-18',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-19',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-20',
          reviewCount: 363,
          rating: 4
        },
        {
          date: '2024-04-21',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-22',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-23',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-24',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-25',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-26',
          reviewCount: 374,
          rating: 4
        },
        {
          date: '2024-04-27',
          reviewCount: 383,
          rating: 4
        },
        {
          date: '2024-04-28',
          reviewCount: 383,
          rating: 4
        },
        {
          date: '2024-04-29',
          reviewCount: 383,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 383,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 383,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 391,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 391,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 391,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 391,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 391,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 403,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 403,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 403,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 403,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 403,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 412,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 429,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 449,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 449,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 449,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 449,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 449,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 458,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 468,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 468,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 468,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 468,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 468,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 471,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 483,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 483,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 483,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 483,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 483,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 495,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 502,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 502,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 502,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 502,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 502,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 508,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 508,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 508,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 508,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 508,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 523,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 535,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 535,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 535,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 535,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 535,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 548,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 582,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 582,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 582,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 582,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 582,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 598,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 614,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 614,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 614,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 614,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 614,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 631,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 649,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 649,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 649,
          rating: 4
        },
        {
          date: '2024-08-28',
          reviewCount: 649,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,

      createdAt: '2024-08-29T22:11:29.434Z'
    }
  ]
}

import { max } from 'lodash'
import { DateTime } from 'luxon'
import { Stack, Stepper, Step, StepLabel, Typography, Divider } from '@mui/material'
import { getStringForStep, TimelineStep, TimelineStepType } from '@productwindtom/shared-momentum'
import SaleIcon from '@momentum/components/icons/sale'

export const EstimatedTimeline = ({
  timelineSteps,
  launchDate,
  isMonthly
}: {
  launchDate?: DateTime
  isMonthly?: boolean
  timelineSteps: TimelineStep[]
}) => {
  const lastDate = max(timelineSteps.map(step => step.date))

  const durationString =
    launchDate && isMonthly
      ? 'Monthly'
      : launchDate && lastDate
        ? `${launchDate.toLocaleString(DateTime.DATE_MED)} - ${lastDate.toLocaleString(DateTime.DATE_MED)}`
        : `-`

  return (
    <Stack spacing={3}>
      <Stepper orientation="vertical">
        {timelineSteps.map((step, index) => (
          <Step key={index} index={step.displayIndex === -1 ? index : step.displayIndex} active>
            <StepLabel
              StepIconProps={
                step.type === TimelineStepType.EVENT
                  ? {
                      icon: (
                        <Stack
                          direction={'row'}
                          spacing={0.5}
                          sx={{ borderRadius: '100px', px: 2, py: 0.5, background: '#EBEDF6' }}
                        >
                          <Typography variant={'label1'} color={'primary'}>
                            {getStringForStep(step)}
                          </Typography>
                          <SaleIcon color={'primary'} />
                        </Stack>
                      )
                    }
                  : {}
              }
            >
              <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                <Typography variant={'label1'}>
                  {step.type === TimelineStepType.EVENT ? '' : getStringForStep(step)}
                </Typography>
                <Typography variant={'label1'}>
                  {step.date
                    ? step.date.toLocaleString(DateTime.DATE_SHORT)
                    : step.type !== TimelineStepType.REVIEWS_PER_WEEK
                      ? '--'
                      : ''}
                </Typography>
              </Stack>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Divider />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'label1'} mb={1}>
          Campaign duration
        </Typography>
        <Stack alignItems={'flex-end'}>
          <Typography variant={'label1'}>{durationString}</Typography>
          <Typography variant={'label2'} color={theme => theme.palette.grey.A700}>
            {isMonthly && launchDate
              ? `Starting ${launchDate.toLocaleString(DateTime.DATE_MED)}`
              : launchDate && lastDate
                ? `(${Math.ceil(lastDate.diff(launchDate, 'weeks').weeks)} weeks)`
                : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

import { CampaignCreatorApprovalAction, CreatorApprovalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import { SAMPLE_CAMPAIGN_CREATORS_FOR_APPROVAL } from '../listCampaignCreatorsForApproval/sample-data'
import { ZeusInterceptor } from '../types'

export const submitCampaignCreatorApproval: ZeusInterceptor<
  'mutation',
  'submitCampaignCreatorApproval'
> = async args => {
  const sampleCreator = Object.values(SAMPLE_CAMPAIGN_CREATORS_FOR_APPROVAL)
    .flat()
    .find(c => c.userCampaignId === args.o.submitCampaignCreatorApproval?.[0].input.id)

  if (sampleCreator) {
    return {
      ...sampleCreator,
      approvalState: {
        status:
          args.o.submitCampaignCreatorApproval?.[0].input.action === CampaignCreatorApprovalAction.APPROVE
            ? CreatorApprovalStatus.APPROVED
            : CreatorApprovalStatus.REJECTED,
        feedback: args.o.submitCampaignCreatorApproval?.[0].input.feedback!,
        updatedAt: DateTime.now().toISO(),
        reviewedByUserEmail: 'sample@productwind.com',
        reviewedByUserId: 'sample-user',
        reviewedByUserName: 'Sample User'
      }
    }
  }
}

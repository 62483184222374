import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useCallback, useEffect, useState } from 'react'
import { debounce, isString } from 'lodash'
import { TextField } from '@mui/material'

export const DebouncedTextField = ({
  onChange,
  value,
  ...rest
}: { onChange: (value: string) => void } & Omit<TextFieldProps, 'onChange'>) => {
  const [tempSearch, setTempSearch] = useState(value)

  const handleSearchChange = useCallback(
    debounce((value: string) => onChange(value), 500),
    []
  )

  useEffect(() => {
    if (isString(value)) {
      setTempSearch(value)
    }
  }, [value])

  const handleTempSearchChange = (value: string) => {
    setTempSearch(value)
    handleSearchChange(value)
  }
  return <TextField onChange={event => handleTempSearchChange(event.target.value)} value={tempSearch} {...rest} />
}

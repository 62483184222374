import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { getWeekIndex } from '../../common/utils'
import { useSeoContext } from '../../context'
import { ProductSeoSummaryRecord } from '../../context/selectors'

export const SeoOrganicImpressionsGraph = () => {
  const theme = useTheme()
  const { campaignDetails } = useCampaignContext()
  const { productSeoSummary } = useSeoContext()

  const campaignStartDate = DateTime.fromISO(campaignDetails.startDate)
  const campaignEndDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined

  const weeklySearchVolume = useMemo(() => {
    return sortBy(
      productSeoSummary?.productSeoSummaryRecords.map(record => ({
        ...record,
        weekIndex: getWeekIndex(campaignDetails.startDate, record.fromDate)
      })),
      r => r.weekIndex
    )
      .filter(record => record.weekIndex !== undefined)
      .reduce(
        (acc, record, index) => {
          acc.push({
            ...record,
            weekIndex: record.weekIndex!,
            accumulatedSearchVolume: (acc[index - 1]?.accumulatedSearchVolume ?? 0) + (record.searchVolume ?? 0)
          })
          return acc
        },
        [] as (ProductSeoSummaryRecord & { weekIndex: number; accumulatedSearchVolume: number })[]
      )
  }, [productSeoSummary])

  const isWeekStartOfCampaign = (record: ProductSeoSummaryRecord) =>
    DateTime.fromISO(record.fromDate).startOf('week').equals(campaignStartDate.startOf('week'))

  const isWeekEndOfCampaign = (record: ProductSeoSummaryRecord) =>
    !!campaignEndDate && DateTime.fromISO(record.fromDate).startOf('week').equals(campaignEndDate.startOf('week'))

  if (!weeklySearchVolume.length) {
    return null
  }

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Organic search impressions</Typography>
      <Box>
        <Line
          data={{
            labels: weeklySearchVolume.map(({ weekIndex }) =>
              weekIndex >= 0 ? `Week ${weekIndex + 1}` : 'Pre-launch'
            ),
            datasets: [
              {
                borderColor: theme.palette.primary.main,
                pointStyle: 'circle',
                pointRadius: 9,
                pointBorderWidth: 2,
                pointHoverRadius: 9,
                pointHoverBorderWidth: 2,
                pointBorderColor: 'white',
                pointBackgroundColor: theme.palette.primary.main,
                data: weeklySearchVolume.map(({ accumulatedSearchVolume }) => accumulatedSearchVolume)
              },
              {
                borderColor: theme.palette.primary.main,
                pointStyle: 'circle',
                pointRadius: 9,
                pointBorderWidth: 2,
                pointHoverRadius: 9,
                pointHoverBorderWidth: 2,
                pointBorderColor: 'white',
                pointBackgroundColor: theme.palette.primary.main,
                showLine: false,
                data: weeklySearchVolume.map(record =>
                  isWeekStartOfCampaign(record) || isWeekEndOfCampaign(record) ? 0 : null
                ) as number[]
              }
            ]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                ...defaultTooltipOptions,
                filter: tooltipItem => tooltipItem.datasetIndex === 1,
                callbacks: {
                  title: () => '',
                  label: tooltipItem => {
                    if (isWeekStartOfCampaign(weeklySearchVolume[tooltipItem.dataIndex])) {
                      return 'Campaign start date'
                    }
                    if (isWeekEndOfCampaign(weeklySearchVolume[tooltipItem.dataIndex])) {
                      return 'Campaign end date'
                    }
                    return ''
                  }
                }
              },
              legend: {
                display: false
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                offset: false,
                ticks: {
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                type: 'category',
                ticks: {
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: value => {
                    return isWeekStartOfCampaign(weeklySearchVolume[value.index]) ||
                      isWeekEndOfCampaign(weeklySearchVolume[value.index])
                      ? theme.palette.primary.main
                      : 'black'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: function (value) {
                    return isWeekStartOfCampaign(weeklySearchVolume[value.index]) ||
                      isWeekEndOfCampaign(weeklySearchVolume[value.index])
                      ? theme.palette.grey.A400
                      : 'transparent'
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}

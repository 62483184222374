import { SvgIcon, SvgIconProps } from '@mui/material'

export default function FoodMenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path d="M3 2H5V22H3V2ZM10 6H17V8H10V6ZM10 10H17V12H10V10Z" fill="currentColor" />
      <path
        d="M19 2H14H13.937H6V22H13.938H14H19C20.103 22 21 21.103 21 20V4C21 2.897 20.103 2 19 2ZM19 20H14H13.937H8V4H13.938H14H19V20Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

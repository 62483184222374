import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_KEYBOARD: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: '5db63445-f87b-486f-9a78-23fccb787d71',
    creator: {
      id: '5db63445-f87b-486f-9a78-23fccb787d71',
      email: 'gamer@iamdjepic.com',
      firstName: 'Dwayne',
      lastName: 'Ingram',
      instagramProfile: {
        handle: 'iamdjepic',
        followers: 4333,
        engagementRate: 0.24924994230325412,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'iamdeejayepic',
        followers: 12367,
        engagementRate: 9.030239833159541,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: 'd34fd65c-319b-4e45-af20-8402038824e8',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-02-18T19:17:50.550Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          '5db63445-f87b-486f-9a78-23fccb787d71/ugc/d34fd65c-319b-4e45-af20-8402038824e8/UGC-CONTENT-1.mp4'
        ]
      }
    ]
  },
  {
    id: 'cac023c2-601f-4e69-8140-f57ca7cf728f',
    creator: {
      id: 'cac023c2-601f-4e69-8140-f57ca7cf728f',
      email: 'tolaanif@gmail.com',
      firstName: 'Tola',
      lastName: 'Anif',
      instagramProfile: {
        handle: 'tolaanif',
        followers: 11144,
        engagementRate: 0.01346015793251974,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'tolaanif',
        followers: 2295,
        engagementRate: 1.1816838995568686,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: 'c21e107f-d0bb-4c4c-8a85-6cbe79b7240d',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-01-25T15:42:41.261Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          'cac023c2-601f-4e69-8140-f57ca7cf728f/ugc/c21e107f-d0bb-4c4c-8a85-6cbe79b7240d/UGC-CONTENT-1.mp4'
        ]
      }
    ]
  },
  {
    id: '6e87b117-eb16-467e-bf4a-6f66877caf73',
    creator: {
      id: '6e87b117-eb16-467e-bf4a-6f66877caf73',
      email: 'ladyshulammite@gmail.com',
      firstName: 'Marionette',
      lastName: 'Solorio',
      instagramProfile: {
        handle: 'thisismarionette',
        followers: 10672,
        engagementRate: 0.203335832083958,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'ladyshulammite016',
        followers: 673,
        engagementRate: 9.772727272727273,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '836dbc37-4ba5-4a6b-8c7b-723e0b101135',
        channel: Channel.UGC,
        contentType: ContentType.UGC_PHOTO,
        isHidden: false,
        draftSubmittedAt: '2023-08-22T07:13:20.438Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          '6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-1.jpeg',
          '6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-2.jpeg',
          '6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/836dbc37-4ba5-4a6b-8c7b-723e0b101135/UGC-CONTENT-3.jpeg'
        ]
      },
      {
        id: 'd541f6b2-6a20-4b7c-9f01-9d44d0c5a4fd',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2023-08-22T07:13:13.998Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          '6e87b117-eb16-467e-bf4a-6f66877caf73/ugc/d541f6b2-6a20-4b7c-9f01-9d44d0c5a4fd/UGC-CONTENT-1.mp4'
        ]
      }
    ]
  }
]

import { Stack } from '@mui/material'
import { ProductsHeader } from './ProductsHeader'
import { ProductsSummary } from './ProductsSummary'
import {  Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ProductTabs } from './ProductTabs'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { entries, groupBy, orderBy, first } from 'lodash'
import { DateTime } from 'luxon'
import { notEmpty } from '@productwindtom/shared-node'
import ProductsContext, { AlertType } from '@momentum/routes/products/productsContext'

const SCRAPE_THRESHOLD_HOURS = 6

export const Products = () => {
  const { isAdminView } = useUserSessionContext()
  const { products } = useBrandContext()

  if (!isAdminView) {
    return <Navigate to={ROUTES.HOME} />
  }

  const groupedByParentId = groupBy(products, p => p.parentSkuId)

  const reviewAlertListings = entries(groupedByParentId)
    .map(([key, value]) => {
      const byScrapeDate = orderBy(value, p => p.name, 'desc')
      const latest = first(byScrapeDate)?.lastScrapedAt

      if (!latest) {
        return undefined
      }
      const latestDateTime = DateTime.fromISO(latest)

      const productsWithin = value.filter(p => {
        if (!p.lastScrapedAt) {
          return false
        }
        const dateTime = DateTime.fromISO(p.lastScrapedAt)
        return Math.abs(dateTime.diff(latestDateTime, 'hours').hours) < SCRAPE_THRESHOLD_HOURS
      })
      const minRatings = Math.min(...productsWithin.map(p => p.ratingSummary?.numRatings).filter(notEmpty))
      const maxRatings = Math.max(...productsWithin.map(p => p.ratingSummary?.numRatings).filter(notEmpty))
      if (maxRatings - minRatings > getRatingsThreshold(maxRatings)) {
        return {
          alert: AlertType.REVIEWS_NOT_MERGED,
          skuIds: productsWithin.map(p => p.id),
          parentSkuId: key
        }
      }
      return undefined
    })
    .filter(notEmpty)

  return (
    <ProductsContext.Provider value={{ listingAlerts: reviewAlertListings }}>
      <Stack pb={4} spacing={6}>
        <Stack>
          <ProductsHeader />
          <ProductsSummary />
        </Stack>
        <ProductTabs />
        <Outlet />
      </Stack>
    </ProductsContext.Provider>
  )
}

const getRatingsThreshold = (numRatings: number) => (numRatings < 50 ? 1 : 5)

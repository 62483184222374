import { ProductSeoSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'

export const SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY: ProductSeoSummary = {
  campaignId: SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.id,
  productSeoSummaryRecords: [
    {
      fromDate: '2024-07-15',
      toDate: '2024-07-21',
      searchVolume: 204872,
      organicPageOneWins: 12,
      sponsoredPageOneWins: 18,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-07-22',
      toDate: '2024-07-28',
      searchVolume: 359769,
      organicPageOneWins: 19,
      sponsoredPageOneWins: 41,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-07-29',
      toDate: '2024-08-04',
      searchVolume: 239563,
      organicPageOneWins: 10,
      sponsoredPageOneWins: 27,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-08-05',
      toDate: '2024-08-11',
      searchVolume: 250548,
      organicPageOneWins: 6,
      sponsoredPageOneWins: 18,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-08-12',
      toDate: '2024-08-18',
      searchVolume: 294330,
      organicPageOneWins: 1,
      sponsoredPageOneWins: 0,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-08-19',
      toDate: '2024-08-25',
      searchVolume: 443487,
      organicPageOneWins: 3,
      sponsoredPageOneWins: 2,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-08-26',
      toDate: '2024-09-01',
      searchVolume: 381594,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 3,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-09-02',
      toDate: '2024-09-08',
      searchVolume: 352517,
      organicPageOneWins: 2,
      sponsoredPageOneWins: 4,
      isOutOfStock: undefined
    },
    {
      fromDate: '2024-09-09',
      toDate: '2024-09-15',
      searchVolume: 353960,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0,
      isOutOfStock: undefined
    }
  ]
}

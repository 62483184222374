import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_MY_ACTIONS } from '@momentum/routes/RouteNames'
import { Avatar, Tab, Tabs, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Stack } from '@mui/system'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { useCampaignContext } from '../context/CampaignContext'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none'
  }
})

export const TabBar = () => {
  const navigate = useNavigate()
  const base = [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.MY_ACTIONS].join('/')
  const { pathname } = useLocation()
  const classes = useStyles()

  const baseMatch = useMatch(base)

  const { contentPendingApprovalCount, creatorsPendingApprovalCount, needsSellerIntegration, needsVendorIntegration } =
    useCampaignContext()

  const defaultTab = getDefaultTab(
    creatorsPendingApprovalCount,
    contentPendingApprovalCount,
    needsSellerIntegration,
    needsVendorIntegration
  )

  useEffect(() => {
    if (baseMatch) {
      navigate(defaultTab, { replace: true })
    }
  }, [baseMatch])

  const currentTab = baseMatch ? defaultTab : pathname.split('/').pop()

  return (
    <Tabs value={currentTab}>
      <Tab
        label={<TabLabel textLabel={'Approve creators'} count={creatorsPendingApprovalCount} />}
        value={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CREATORS}
        to={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CREATORS}
        component={RouterLink}
        className={classes.tab}
      />
      <Tab
        label={<TabLabel textLabel={'Approve content'} count={contentPendingApprovalCount} />}
        value={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CONTENT}
        to={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CONTENT}
        component={RouterLink}
        className={classes.tab}
      />
      {(needsSellerIntegration || currentTab === ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_SELLER) && (
        <Tab
          label={<TabLabel textLabel={'Link Amazon seller account'} count={~~needsSellerIntegration} />}
          value={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_SELLER}
          to={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_SELLER}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      {(needsVendorIntegration || currentTab === ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_VENDOR) && (
        <Tab
          label={<TabLabel textLabel={'Link Amazon vendor account'} count={~~needsVendorIntegration} />}
          value={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_VENDOR}
          to={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_VENDOR}
          component={RouterLink}
          className={classes.tab}
        />
      )}
    </Tabs>
  )
}

const TabLabel = ({ textLabel, count }: { textLabel: string; count?: number }) => (
  <Stack direction={'row'} spacing={0.5}>
    <Typography variant="inherit">{textLabel}</Typography>
    {!!count && count > 0 && (
      <Avatar sx={{ ml: 0.5, height: 18, width: 18, bgcolor: theme => theme.palette.primary.main }}>
        <Typography variant={'caption2'}>{count}</Typography>
      </Avatar>
    )}
  </Stack>
)

const getDefaultTab = (
  creatorsPendingApprovalCount: number | undefined,
  contentPendingApprovalCount: number | undefined,
  needsSellerIntegration: boolean,
  needsVendorIntegration: boolean
) => {
  if (creatorsPendingApprovalCount) {
    return ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CREATORS
  }
  if (contentPendingApprovalCount) {
    return ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CONTENT
  }
  if (needsSellerIntegration) {
    return ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_SELLER
  }
  if (needsVendorIntegration) {
    return ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_VENDOR
  }
  return ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CREATORS
}

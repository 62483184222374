import { Stack, Typography } from '@mui/material'
import { ListingBadge } from '@productwindtom/shared-momentum-zeus-types'
import { sum, sumBy } from 'lodash'
import { useCampaignContext } from '../../context/CampaignContext'
import { Metric } from '../common/Metric'

const MIN_AMAZON_CHOICE_BADGES = 3

export const Summary = () => {
  const { legacyProductSEOSummary } = useCampaignContext()

  const impressionsSum = sumBy(legacyProductSEOSummary?.productSEOSummaryRecords, r => r.impressions ?? 0)
  const pageOneWins = sum(legacyProductSEOSummary?.keywords.map(s => (s.pageOneWin ? 1 : 0)))
  const improvedSlotsSum = legacyProductSEOSummary!.keywords
    .map(k =>
      k.highestRank && k.startRank && k.highestRank <= k.startRank ? Math.abs(k.highestRank - k.startRank) : 0
    )
    .reduce((acc, curr) => acc + curr, 0)
  const choiceBadgesSum = sumBy(
    legacyProductSEOSummary?.keywords,
    k => k.badges?.filter(b => b.badge === ListingBadge.AMAZON_CHOICE).length ?? 0
  )

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Campaign SEO summary</Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'organic search impressions'} value={impressionsSum.toLocaleString() ?? '--'} />
        <Metric label={'organic page 1 wins'} value={pageOneWins.toLocaleString() ?? '--'} />
        <Metric label={'slots moved up in search results'} value={improvedSlotsSum.toLocaleString() ?? '--'} />
        {choiceBadgesSum >= MIN_AMAZON_CHOICE_BADGES && (
          <Metric label={'Amazon’s Choice badges'} value={choiceBadgesSum.toLocaleString() ?? '--'} />
        )}
      </Stack>
    </Stack>
  )
}

export const CONTENT_RELEVANCE_SORT = [
  {
    _script: {
      type: 'number',
      script: {
        lang: 'painless',
        source: `
            if (doc['channel.keyword'].size() > 0 && doc['channel.keyword'].value == 'UGC') {
              return 0;
            } else {
              return 1;
            }
          `
      },
      order: 'desc'
    }
  },
  {
    qualityScore: {
      order: 'desc'
    }
  },
  {
    _script: {
      type: 'number',
      nested: {
        path: 'metrics'
      },
      script: {
        lang: 'painless',
        source: `
          if (doc.containsKey('metrics') && doc['metrics'].size() > 0) {
            double maxViews = 0;
            for (def metric : doc['metrics']) {
              if (metric['name.keyword'] == 'VIEWS') {
                double currentValue = metric['value'];
                if (currentValue > maxViews) {
                  maxViews = currentValue;
                }
              }
            }
            return maxViews;
          } else {
            return 0;  // Default to 0 if no metrics
          }
        `
      },
      order: 'desc'
    }
  }
]

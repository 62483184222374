import { IntegratePrompt } from '@momentum/components/integrate-prompt'
import { ROUTES } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { INTEGRATION_TYPE_LABELS, IntegrationType } from './utils'

export const AccountLinked = ({ type }: { type: IntegrationType }) => {
  const { brand } = useBrandContext()
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <IntegratePrompt />
      <Typography variant={'h5'} textAlign={'center'} maxWidth={'442px'}>
        Your Amazon {INTEGRATION_TYPE_LABELS[type]} account is linked!
      </Typography>
      <Button variant={'text'} component={RouterLink} to={generatePath(ROUTES.BRAND_PROFILE, { brandId: brand.id })}>
        Manage my brand profile
      </Button>
    </Stack>
  )
}

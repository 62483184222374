import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Summary } from '@momentum/routes/campaign/e-commerce/sales/Summary'
import { SalesGraph } from '@momentum/routes/campaign/e-commerce/sales/sales-graph'
import { SalesTable } from '@momentum/routes/campaign/e-commerce/sales/sales-table'
import SalesContext, { SalesData, SalesReportView } from '@momentum/routes/campaign/e-commerce/sales/salesContext'
import { Paper, Stack } from '@mui/material'
import { notEmpty } from '@productwindtom/shared-node'
import { min, minBy, orderBy, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useCampaignContext } from '../../context/CampaignContext'
import { NotIntegrated } from '../common/NotIntegrated'
import { isWithinPeriod } from '../common/utils'
import { PeriodSelector } from './PeriodSelector'
import SalesReportViewSelector from './SalesReportViewSelector'
import { SAMPLE_CAMPAIGN_SALES } from './sample-data'

export const Sales = () => {
  const [isActivatedCreatorsHidden, setIsActivatedCreatorsHidden] = useState(false)
  const [isOtherCustomersHidden, setIsOtherCustomersHidden] = useState(false)
  const [isTotalHidden, setIsTotalHidden] = useState(true)
  const [salesReportView, setSalesReportView] = useState(SalesReportView.UNITS_SOLD)

  const { isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()
  const { productEtailerMetrics, campaignDetails } = useCampaignContext()

  const { startDate, endDate } = campaignDetails

  const startDateTime = DateTime.fromISO(startDate).startOf('day')
  const minMetricsDate = minBy(productEtailerMetrics, 'date')?.date
  const minDate = minMetricsDate
    ? min([DateTime.now(), DateTime.fromISO(minMetricsDate).startOf('day'), startDateTime])!
    : min([DateTime.now(), startDateTime])!
  const maxDate = endDate ? DateTime.fromISO(endDate).endOf('day') : DateTime.now()

  const [startDateFilter, setStartDateFilter] = useState<DateTime | undefined>(min([DateTime.now(), startDateTime])!)
  const [endDateFilter, setEndDateFilter] = useState<DateTime | undefined>(maxDate)

  if (!productEtailerMetrics) {
    return <Loading />
  }

  let salesData: SalesData[]

  if (SAMPLE_CAMPAIGN_SALES[campaignDetails.id]) {
    salesData = SAMPLE_CAMPAIGN_SALES[campaignDetails.id]
  } else {
    const days = Math.ceil(maxDate.diff(minDate, 'days').days) + 1
    const daysArray = new Array(days).fill(0)
    salesData = daysArray
      .map((_, index) => {
        const date = minDate.plus({ days: index })
        const dateString = date.toISODate()
        const momentumPurchases = campaignDetails.creators
          .map(c => c.purchaseDetails)
          .filter(notEmpty)
          .filter(p => DateTime.fromISO(p.purchaseDate).toISODate() === dateString)
        const metrics = productEtailerMetrics.find(d => d.date === dateString)

        const revenueFromMomentum = sumBy(momentumPurchases, p => p.amountCents)

        return {
          date,
          ...(salesReportView === SalesReportView.REVENUE
            ? {
                fromMomentum: revenueFromMomentum,
                fromOtherCustomers:
                  metrics?.totalSalesAmount !== undefined ? metrics?.totalSalesAmount - revenueFromMomentum : 0,
                total: metrics?.totalSalesAmount ?? revenueFromMomentum
              }
            : {
                fromMomentum: momentumPurchases.length,
                fromOtherCustomers:
                  metrics?.unitsSold !== undefined ? metrics?.unitsSold - momentumPurchases.length : 0,
                total: metrics?.unitsSold ?? momentumPurchases.length
              })
        }
      })
      .filter(d => isWithinPeriod(d.date.toISODate()!, minDate.toISODate()!, maxDate.toISODate()!))
  }

  const filteredSalesData = orderBy(
    salesData.filter(
      d => (!startDateFilter || d.date >= startDateFilter) && (!endDateFilter || d.date <= endDateFilter)
    ),
    'date'
  )

  const integrated = brand.brandApis.find(api => api.enabled && api.isIntegrated)
  if (!isAdminView && !integrated) {
    return <NotIntegrated />
  }

  return (
    <SalesContext.Provider
      value={{
        isIntegrated: !!integrated,
        salesData,
        filteredSalesData,
        minDate,
        maxDate,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        isActivatedCreatorsHidden,
        setIsActivatedCreatorsHidden,
        isOtherCustomersHidden,
        setIsOtherCustomersHidden,
        isTotalHidden,
        setIsTotalHidden,
        salesReportView,
        setSalesReportView
      }}
    >
      <Stack spacing={5}>
        <SalesReportViewSelector />
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary />
        </Paper>
        <PeriodSelector />
        <SalesGraph />
        <SalesTable />
      </Stack>
    </SalesContext.Provider>
  )
}

import { Headers } from 'react-csv/lib/core'

export const CREATOR_DATA_HEADERS: Headers = [
  { key: 'name', label: 'Name' },
  { key: 'instagramProfile.handle', label: 'Instagram Handle' },
  { key: 'instagramProfileLink', label: 'Instagram Profile Link' },
  { key: 'instagramProfile.followers', label: 'Instagram Followers' },
  { key: 'tiktokProfile.engagementRate', label: 'Instagram Engagement Rate' },
  { key: 'tiktokProfile.handle', label: 'Tiktok Handle' },
  { key: 'tiktokProfileLink', label: 'Tiktok Profile Link' },
  { key: 'tiktokProfile.followers', label: 'Tiktok Followers' },
  { key: 'tiktokProfile.engagementRate', label: 'Tiktok Engagement Rate' },
  { key: 'sponsoredPosts[0]', label: 'Sponsored Post #1' },
  { key: 'sponsoredPosts[1]', label: 'Sponsored Post #2' },
  { key: 'sponsoredPosts[2]', label: 'Sponsored Post #3' },
  { key: 'sponsoredPosts[3]', label: 'Sponsored Post #4' },
  { key: 'sponsoredPosts[4]', label: 'Sponsored Post #5' }
]

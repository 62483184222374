import SelectInputAdd from '@momentum/components/form-inputs/SelectInputAdd'
import { ImageSelect } from '@momentum/components/image-select'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Agency } from '@momentum/routes/queries'
import { Stack, Typography } from '@mui/material'
import { PaymentTermsType, UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { TextInput } from '@productwindtom/ui-base'
import { useCallback, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import PaymentTermsFields from '../inputs/PaymentTermsFields'
import { AgencyInputData } from '../types'

const AgencyFormFields = ({ agency }: { agency?: Agency }) => {
  const { agencies } = useUserSessionContext()
  const { setValue: setFormValue, trigger, watch } = useFormContext<{ agency?: AgencyInputData }>()

  const agencyWatch = watch('agency')
  useEffect(() => {
    if (agencyWatch) {
      const agency = agencies.find(({ id }) => id === agencyWatch.id)
      setFormValue('agency.existingAgency', !!agency)
    }
  }, [agencyWatch])

  const agencyId = useWatch({ name: 'agency.id' })

  const handleChange = useCallback(
    (value?: { value: string; name: string } | null) => {
      if (value) {
        const agency = agencies.find(({ id }) => id === value.value)
        setFormValue('agency.id', agency?.id || v4())
        setFormValue('agency.name', value.name ?? agency?.name)
        setFormValue('agency.websiteUrl', agency?.websiteUrl || '')
        setFormValue('agency.logo', agency?.logo || '')
        setFormValue('agency.paymentTermsType', agency?.paymentTermsType || PaymentTermsType.DEFAULT_7_DAYS)
        setFormValue('agency.paymentTermsCustomNetDays', agency?.paymentTermsCustomNetDays)
      } else {
        setFormValue('agency', undefined)
      }

      trigger(['agency'])
    },
    [agencies, setFormValue, trigger]
  )

  return (
    <Stack spacing={1}>
      <Typography variant={'h4'}>Select or create new agency</Typography>
      {!agency ? (
        <SelectInputAdd
          name={'agency.name'}
          primaryText="Search existing or add new agency"
          options={agencies.map(agency => ({ name: agency.name, value: agency.id }))}
          onChange={handleChange}
        />
      ) : (
        <TextInput name={'agency.name'} primaryText={'Agency name'} placeholder={'Agency name'} />
      )}
      {agencyId && (
        <Stack spacing={2}>
          <TextInput name={'agency.websiteUrl'} primaryText={'Agency website'} placeholder={'Agency website URL'} />
          <ImageSelect
            name={'agency.logo'}
            primaryText="Upload agency logo"
            buttonText={'Click to upload'}
            agencyId={agencyId}
            buttonProps={{
              variant: 'outlined'
            }}
            uploadType={UploadRequestType.UPLOAD_AGENCY_LOGO}
          />
          <PaymentTermsFields
            paymentTermsTypeName="agency.paymentTermsType"
            paymentTermsCustomNetDaysName="agency.paymentTermsCustomNetDays"
            primaryText="What are the default payment terms for the agency?"
            subtext="If payment terms are set for the agency, we will use these terms when the agency creates a  self service campaign."
          />
        </Stack>
      )}
    </Stack>
  )
}

export default AgencyFormFields

import { Button, ButtonGroup } from '@mui/material'
import { SalesReportView, useSalesContext } from './salesContext'

export default () => {
  const { salesReportView, setSalesReportView } = useSalesContext()

  const handleChange = (value: SalesReportView) => {
    if (value) {
      setSalesReportView(value)
    }
  }

  return (
    <ButtonGroup
      color="primary"
      variant="outlined"
      size="medium"
      disableRipple
      disableElevation
      style={{
        alignSelf: 'flex-end'
      }}
    >
      <Button
        variant={salesReportView === SalesReportView.UNITS_SOLD ? 'contained' : 'outlined'}
        value={SalesReportView.UNITS_SOLD}
        onClick={() => handleChange(SalesReportView.UNITS_SOLD)}
      >
        Units sold
      </Button>
      <Button
        variant={salesReportView === SalesReportView.REVENUE ? 'contained' : 'outlined'}
        value={SalesReportView.REVENUE}
        onClick={() => handleChange(SalesReportView.REVENUE)}
      >
        Revenue
      </Button>
    </ButtonGroup>
  )
}

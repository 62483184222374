import { SvgIcon } from '@mui/material'
import React from 'react'

export const CollectingFeedbackIcon = () => {
  return (
    <SvgIcon sx={{ width: 202, height: 166 }} width="202" height="166" viewBox="0 0 202 166" fill="none">
      <path
        d="M119.421 165.781C164.911 165.781 201.788 128.903 201.788 83.4132C201.788 37.923 164.911 1.0459 119.421 1.0459C73.9308 1.0459 37.0537 37.923 37.0537 83.4132C37.0537 128.903 73.9308 165.781 119.421 165.781Z"
        fill="#AAD994"
      />
      <path
        d="M183.2 25.9225V135.096C167.845 152.978 145.072 164.306 119.656 164.306C100.521 164.306 82.8862 157.888 68.7783 147.086V10.0934C68.7783 5.05956 72.8509 0.980469 77.8847 0.980469H158.258L175.035 17.758L182.466 25.1886L183.2 25.9225Z"
        fill="white"
      />
      <path
        d="M119.656 165.274C100.871 165.274 83.0742 159.253 68.1869 147.853C67.9466 147.671 67.8037 147.386 67.8037 147.08V10.0873C67.8037 4.52726 72.3244 0 77.8844 0H158.258C158.517 0 158.764 0.103887 158.946 0.285757L183.888 25.2279C184.07 25.4098 184.174 25.6567 184.174 25.9165V135.09C184.174 135.324 184.09 135.551 183.94 135.726C167.812 154.504 144.384 165.274 119.656 165.274ZM69.7523 146.6C84.2174 157.544 101.462 163.325 119.656 163.325C143.695 163.325 166.481 152.907 182.225 134.733V26.3256L157.848 1.9486H77.8844C73.4027 1.9486 69.7523 5.59899 69.7523 10.0873V146.6Z"
        fill="#243C98"
      />
      <path
        d="M183.2 29.2152H165.929C161.694 29.2152 158.258 25.7791 158.258 21.5442V3.57812L183.2 28.5201V29.2152Z"
        fill="#324AA8"
      />
      <path
        d="M183.2 30.1896H165.928C161.161 30.1896 157.283 26.3119 157.283 21.5443V3.57826C157.283 3.18204 157.524 2.83136 157.887 2.67547C158.251 2.52608 158.673 2.61041 158.946 2.88971L183.888 27.8319C184.07 28.0138 184.174 28.2605 184.174 28.5203V29.2153C184.174 29.7479 183.739 30.1896 183.2 30.1896ZM159.225 5.92314V21.5378C159.225 25.2336 162.233 28.2345 165.922 28.2345H181.537L159.225 5.92314Z"
        fill="#243C98"
      />
      <path
        d="M128.561 33.3141H90.1541C89.2578 33.3141 88.5303 32.5866 88.5303 31.6902C88.5303 30.7939 89.2578 30.0664 90.1541 30.0664H128.561C129.457 30.0664 130.185 30.7939 130.185 31.6902C130.185 32.5866 129.457 33.3141 128.561 33.3141Z"
        fill="#243C98"
      />
      <path
        d="M161.421 76.4693H92.5311C91.6347 76.4693 90.9072 75.7419 90.9072 74.8455C90.9072 73.9492 91.6347 73.2217 92.5311 73.2217H161.421C162.317 73.2217 163.044 73.9492 163.044 74.8455C163.044 75.7419 162.317 76.4693 161.421 76.4693Z"
        fill="#243C98"
      />
      <path
        d="M161.421 89.7193H92.5311C91.6347 89.7193 90.9072 88.9919 90.9072 88.0955C90.9072 87.1992 91.6347 86.4717 92.5311 86.4717H161.421C162.317 86.4717 163.044 87.1992 163.044 88.0955C163.044 88.9919 162.317 89.7193 161.421 89.7193Z"
        fill="#243C98"
      />
      <path
        d="M161.421 102.971H92.5311C91.6347 102.971 90.9072 102.244 90.9072 101.347C90.9072 100.451 91.6347 99.7236 92.5311 99.7236H161.421C162.317 99.7236 163.044 100.451 163.044 101.347C163.044 102.244 162.317 102.971 161.421 102.971Z"
        fill="#243C98"
      />
      <path
        d="M161.421 116.227H92.5311C91.6347 116.227 90.9072 115.5 90.9072 114.603C90.9072 113.707 91.6347 112.979 92.5311 112.979H161.421C162.317 112.979 163.044 113.707 163.044 114.603C163.044 115.5 162.317 116.227 161.421 116.227Z"
        fill="#243C98"
      />
      <path
        d="M86.4907 115.707H90.2775C95.4737 115.707 99.6892 111.492 99.6892 106.296V51.2994C99.6892 46.1032 95.4737 41.8877 90.2775 41.8877H10.5992C5.40296 41.8877 1.1875 46.1032 1.1875 51.2994V106.296C1.1875 111.492 5.40296 115.707 10.5992 115.707H67.6737L80.9697 132.374C82.6714 134.511 86.114 133.349 86.1724 130.614L86.4907 115.707Z"
        fill="#324AA8"
      />
      <path
        d="M83.2362 134.453C82.08 134.453 80.9694 133.933 80.2094 132.979L67.2057 116.675H10.5989C4.87004 116.675 0.212891 112.018 0.212891 106.289V51.2933C0.212891 45.5644 4.87004 40.9072 10.5989 40.9072H90.2772C96.006 40.9072 100.663 45.5644 100.663 51.2933V106.289C100.663 112.018 96.006 116.675 90.2772 116.675H87.4452L87.1464 130.627C87.1139 132.29 86.0747 133.706 84.4963 134.239C84.0806 134.382 83.6519 134.453 83.2362 134.453ZM10.5989 42.8623C5.94826 42.8623 2.16149 46.6491 2.16149 51.2998V106.296C2.16149 110.946 5.94826 114.733 10.5989 114.733H67.6734C67.9722 114.733 68.2515 114.87 68.4334 115.097L81.7293 131.764C82.3594 132.55 83.2492 132.608 83.8728 132.394C84.4963 132.18 85.1718 131.595 85.1978 130.588L85.5161 115.682C85.5291 115.149 85.9578 114.727 86.4904 114.727H90.2772C94.9278 114.727 98.7146 110.94 98.7146 106.289V51.2933C98.7146 46.6426 94.9278 42.8558 90.2772 42.8558H10.5989V42.8623Z"
        fill="#243C98"
      />
      <path
        d="M49.7903 61.6717L55.0213 72.6263L67.0131 74.5314L58.2147 82.8934L60.1057 94.8864L49.4337 89.0935L38.6151 94.6065L40.8266 82.6673L32.2369 74.0797L44.2799 72.4859L49.7903 61.6717Z"
        fill="#F3C94E"
      />
      <path
        d="M60.2081 96.0699C59.9795 96.0899 59.7455 96.0468 59.5367 95.9301L49.4152 90.4387L39.1512 95.6648C38.748 95.8668 38.2683 95.8294 37.9032 95.5595C37.5381 95.2897 37.3634 94.8364 37.4437 94.3926L39.5342 83.0676L31.3885 74.9254C31.0666 74.6042 30.9615 74.1289 31.1069 73.7032C31.2523 73.2775 31.6301 72.9664 32.0779 72.9113L43.4932 71.4044L48.7267 61.1465C48.93 60.7476 49.3363 60.4897 49.7976 60.497C50.2509 60.505 50.6548 60.7635 50.8496 61.1753L55.8089 71.5659L67.1879 73.3658C67.631 73.4382 68.0002 73.7553 68.1334 74.1884C68.2666 74.6214 68.149 75.0923 67.8185 75.3992L59.4671 83.3329L61.2643 94.7069C61.3351 95.1534 61.1438 95.5989 60.7776 95.8613C60.6211 95.9782 60.413 96.052 60.2081 96.0699ZM49.3292 87.9127C49.5578 87.8927 49.7918 87.9357 50.0006 88.0525L58.5629 92.6957L57.0383 83.0768C56.9809 82.6927 57.1133 82.2999 57.3914 82.0294L64.4522 75.3205L54.8365 73.7952C54.4499 73.7337 54.1184 73.4848 53.9527 73.134L49.7609 64.344L45.3343 73.0223C45.1583 73.3712 44.8216 73.6071 44.4289 73.6574L34.7721 74.9311L41.6565 81.8177C41.9349 82.0951 42.0567 82.4895 41.9866 82.8689L40.2194 92.4424L48.894 88.0223C49.0325 87.9705 49.1795 87.9258 49.3292 87.9127Z"
        fill="#F3C94E"
      />
    </SvgIcon>
  )
}

import {
  CampaignCreatorStatus,
  Channel,
  ContentType,
  CreatorType,
  ModelTypes,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_CAMPAIGN_PILLOW: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-pillow',
  brandId: BRANDS.CE,
  skuId: 'sample-sku-pillow',
  title: 'Down pillow',
  numCreators: 230,
  numCreatorsJoined: 230,
  numAdditionalBrandAdvocateCreatorsJoined: 175,
  numAdditionalBrandAdvocateCreators: 175,
  numBrandAdvocateCreators: 0,
  numSocialCreators: 5,
  numPremiumUgcCreators: 0,
  numUgcCreators: 50,
  expectedSocialPostCount: 10,
  expectedUgcPostCount: 50,
  releaseSchedule: {
    socialWeeklySchedule: [5, 0, 0],
    premiumUgcWeeklySchedule: [0, 0, 0],
    ugcWeeklySchedule: [50, 0, 0],
    brandAdvocatesWeeklySchedule: [39, 59, 27]
  },
  createdAt: '2024-02-20T17:43:28.000Z',
  anticipatedStartDate: '2024-03-05T17:43:28.000Z',
  startDate: '2024-03-05T17:43:28.000Z',
  cost: 2194200,
  product: {
    id: 'sample-sku-pillow',
    name: 'Down pillow',
    skuId: 'B0CJT69H2K',
    price: '$79',
    image: 'https://media.productwind.com/sample/images/pillow.png',
    listingLink: 'https://www.amazon.com/dp/B0CJT69H2K?th=1',
    store: Store.amazon,
    ratingSnapshots: {
      items: []
    },
    brandId: BRANDS.CE,
    recommendations: [],
    createdAt: '2024-01-13T16:22:12.000Z',
    updatedAt: '2024-01-13T16:22:12.000Z',
    childSkus: { items: [] }
  },
  creators: [
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T17:43:27.000Z',
        amountCents: 7504
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3a255aea-cd5f-47a2-9245-30c50707ece0',
        firstName: 'Ashleigh',
        lastName: 'Samson',
        email: 'ashleighsamson91@icloud.com',
        instagramProfile: {
          handle: 'realrawboymomlife',
          followers: 8605,
          engagementRate: 4.969203951191168,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ashleighsamson81',
          followers: 3050,
          engagementRate: 5.688073394495413,
          isPrimary: true
        }
      },
      userCampaignId: '3a255aea-cd5f-47a2-9245-30c50707ece0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T14:23:09.000Z',
        amountCents: 8453
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '03be3309-c096-4174-a6a3-0260232c94bc',
        firstName: 'Autumn',
        lastName: 'Lee',
        email: 'autumnlee.insta@gmail.com',
        instagramProfile: {
          handle: 'autumn_lee96',
          followers: 12121,
          engagementRate: 2.18298820229354,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'autumn_lee96',
          followers: 1515,
          engagementRate: 2.6552984165651643,
          isPrimary: true
        }
      },
      userCampaignId: '03be3309-c096-4174-a6a3-0260232c94bc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T17:46:06.000Z',
        amountCents: 9265
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cd31f709-2028-46e0-93a5-0dfb50166eda',
        firstName: 'Janet',
        lastName: 'Chavez ',
        email: 'janetchavez456@gmail.com',
        tiktokProfile: {
          handle: 'mommmaaa.x2',
          followers: 13285,
          engagementRate: 4.244274288857974,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'janetchavez3032',
          followers: 290
        }
      },
      userCampaignId: 'cd31f709-2028-46e0-93a5-0dfb50166eda'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T08:56:05.000Z',
        amountCents: 7504
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '75e2d290-bf58-4aa4-aca7-5a6b6497343d',
        firstName: 'Donya',
        lastName: 'Fox',
        email: 'donya_fox@hotmail.com',
        instagramProfile: {
          handle: 'donyafox',
          followers: 5337,
          engagementRate: 2.3927299981262884,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'glamifoxbeauty',
          followers: 18217,
          engagementRate: 5.286540203548783,
          isPrimary: true
        }
      },
      userCampaignId: '75e2d290-bf58-4aa4-aca7-5a6b6497343d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-10T05:35:16.000Z',
        amountCents: 9115
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '316de310-f797-4d83-aa4b-418d0edda931',
        firstName: 'Ana',
        lastName: 'Suliman',
        email: 'anamariesuliman@gmail.com',
        instagramProfile: {
          handle: 'ana.suliman',
          followers: 4385,
          engagementRate: 5.860889395667047,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'anamarie1_',
          followers: 399,
          engagementRate: 12.607449856733524,
          isPrimary: false
        }
      },
      userCampaignId: '316de310-f797-4d83-aa4b-418d0edda931'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T08:23:28.000Z',
        amountCents: 7495
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '012826e4-d6cd-4b25-9758-d0ba89ebe0a7',
        firstName: 'Gilmari',
        lastName: 'Alvelo',
        email: 'gilmarialvelo@gmail.com',
        instagramProfile: {
          handle: 'gilmarialvelo',
          followers: 9300,
          engagementRate: 0.8774193548387096,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gilmarialvelo',
          followers: 2464,
          engagementRate: 7.631784625094544,
          isPrimary: true
        }
      },
      userCampaignId: '012826e4-d6cd-4b25-9758-d0ba89ebe0a7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T17:46:02.000Z',
        amountCents: 7383
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '88c3d3c1-7da4-4b06-9372-1d95a92b8122',
        firstName: 'Alexandra',
        lastName: 'Noyes',
        email: 'alexandranoyescollab@gmail.com',
        instagramProfile: {
          handle: 'alexandra__noyes',
          followers: 1155,
          engagementRate: 25.281385281385283,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alexslifeinmotherhood',
          followers: 5111,
          engagementRate: 5.145403439593962,
          isPrimary: false
        }
      },
      userCampaignId: '88c3d3c1-7da4-4b06-9372-1d95a92b8122'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T03:16:06.000Z',
        amountCents: 8591
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '64364b09-a25a-4de5-b721-ad4a8f9c821d',
        firstName: 'Sara',
        lastName: 'Ritchey',
        email: 'theabeytafam@gmail.com',
        tiktokProfile: {
          handle: 'sara.ritchey',
          followers: 7063,
          engagementRate: 11.08856599889144,
          isPrimary: true
        }
      },
      userCampaignId: '64364b09-a25a-4de5-b721-ad4a8f9c821d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T19:16:34.000Z',
        amountCents: 7418
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1e437419-c980-478c-847f-44a8665c15a4',
        firstName: 'Janessa',
        lastName: 'Marcos',
        email: 'wearethemarcos@gmail.com',
        instagramProfile: {
          handle: 'wearethemarcos',
          followers: 28077,
          engagementRate: 0.8487374007194501,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'rocknessa',
          followers: 24960,
          engagementRate: 20.556803319527916,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'wearethemarcos',
          followers: 126
        }
      },
      userCampaignId: '1e437419-c980-478c-847f-44a8665c15a4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T09:13:56.000Z',
        amountCents: 8982
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e38f52f7-ace9-4399-a19b-18efd8549f77',
        firstName: 'Destinie',
        lastName: 'Forest',
        email: 'destinieforest18@gmail.com',
        instagramProfile: {
          handle: 'destiniebrianna_',
          followers: 2818,
          engagementRate: 0.8587650816181689,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'destiniebrianna_',
          followers: 9370,
          engagementRate: 14.604024044391938,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'mommyndrose'
        }
      },
      userCampaignId: 'e38f52f7-ace9-4399-a19b-18efd8549f77'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T19:23:29.000Z',
        amountCents: 8423
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '76a23a44-596c-487e-bd61-b8ff70cc40ae',
        firstName: 'Miava',
        lastName: 'Smith',
        email: 'miavanelson@gmail.com',
        instagramProfile: {
          handle: 'miava_smith',
          followers: 11578,
          engagementRate: 0.0038577456298975283,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'miavasmith',
          followers: 30319,
          engagementRate: 1.4465094043652986,
          isPrimary: true
        }
      },
      userCampaignId: '76a23a44-596c-487e-bd61-b8ff70cc40ae'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T12:26:32.000Z',
        amountCents: 8453
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ecd9978c-c6ec-4d86-80e8-958791858e05',
        firstName: 'Mariana',
        lastName: 'Komula',
        email: 'bohowildflower.co@gmail.com',
        instagramProfile: {
          handle: 'thebohowildflower',
          followers: 6179,
          engagementRate: 1.793170415924907,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thebohowildflower',
          followers: 1500,
          engagementRate: 11.35646687697161,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'thebohowildflower'
        }
      },
      userCampaignId: 'ecd9978c-c6ec-4d86-80e8-958791858e05'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T17:46:02.000Z',
        amountCents: 7331
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'dea03e70-d926-46ed-bab8-101cdbbb756d',
        firstName: 'Kierstyn',
        lastName: 'Garcia',
        email: 'kiley.kierstyn@gmail.com',
        instagramProfile: {
          handle: 'kieerstyn',
          followers: 2529,
          engagementRate: 17.279557137208382,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kierstyngar',
          followers: 3308,
          engagementRate: 29.046624449425522,
          isPrimary: true
        }
      },
      userCampaignId: 'dea03e70-d926-46ed-bab8-101cdbbb756d'
    },
    {
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '60aa5da2-2a15-44e8-9342-0ba7993087af',
        firstName: 'Jamie',
        lastName: 'Gudino',
        email: 'jamiegudino@hotmail.com',
        instagramProfile: {
          handle: '_jamiegudino',
          followers: 5329,
          engagementRate: 1.022705948583224,
          isPrimary: false
        }
      },
      userCampaignId: '60aa5da2-2a15-44e8-9342-0ba7993087af'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T16:56:34.000Z',
        amountCents: 7383
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3f7b769f-afc6-4311-8623-ab6e241dd7fa',
        firstName: 'Dariyan',
        lastName: 'Bell',
        email: 'contact.dariyan@gmail.com',
        instagramProfile: {
          handle: 'dariyan.monique',
          followers: 5208,
          engagementRate: 15.251536098310293,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'raisedbymagik',
          followers: 26181,
          engagementRate: 16.386406883428478,
          isPrimary: true
        }
      },
      userCampaignId: '3f7b769f-afc6-4311-8623-ab6e241dd7fa'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-13T16:16:17.000Z',
        amountCents: 8552
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5845d832-58f4-4c85-9825-9f055884586a',
        firstName: 'Ashlie',
        lastName: 'Snow ',
        email: 'ashliesnow18@gmail.com',
        instagramProfile: {
          handle: 'ashliesnow18',
          followers: 578,
          engagementRate: 0.9515570934256056,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ashliesnow18',
          followers: 6156,
          engagementRate: 8.270015290790694,
          isPrimary: false
        }
      },
      userCampaignId: '5845d832-58f4-4c85-9825-9f055884586a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T15:45:54.000Z',
        amountCents: 7493
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-11T19:14:48.398Z',
          createdAt: '2024-03-21T19:50:56.170Z',
          content: [
            {
              id: 'c2f364b0-b297-43e8-a8dd-f9ab9b6a6702',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/291b5473-edb0-496d-a2cf-d0c6eb6dc1ab/ugc/87d9f69e-4e34-4c2f-86ca-a3ecc9a1d3cf/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '87d9f69e-4e34-4c2f-86ca-a3ecc9a1d3cf'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-11T19:14:53.896Z',
          createdAt: '2024-03-21T19:53:47.101Z',
          content: [
            {
              id: 'a2deb593-1473-484e-aea0-29419c2711ba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/291b5473-edb0-496d-a2cf-d0c6eb6dc1ab/ugc/2c21488e-4119-4dcc-aca7-295dfa19c170/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fff80310-ad63-4b6a-a3a0-b9b9b1ea6e46',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/291b5473-edb0-496d-a2cf-d0c6eb6dc1ab/ugc/2c21488e-4119-4dcc-aca7-295dfa19c170/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2c21488e-4119-4dcc-aca7-295dfa19c170'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '291b5473-edb0-496d-a2cf-d0c6eb6dc1ab',
        firstName: 'Eyricka',
        lastName: 'Frisbie',
        email: 'eyrickafrisbie@gmail.com',
        instagramProfile: {
          handle: 'eyricka_lynn',
          followers: 16601,
          engagementRate: 1.0390940304800915,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'eyricka_lynn',
          followers: 33478,
          engagementRate: 10.34638757162933,
          isPrimary: false
        }
      },
      userCampaignId: '291b5473-edb0-496d-a2cf-d0c6eb6dc1ab'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T17:23:28.000Z',
        amountCents: 7314
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '105ae5b7-05e9-4503-81af-77523a3af7e1',
        firstName: 'Alicia',
        lastName: 'Triplett',
        email: 'alysiaportis@gmail.com',
        instagramProfile: {
          handle: '_alysialaree_',
          followers: 25281,
          engagementRate: 0.5434911593686959,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alysialaree',
          followers: 3459,
          engagementRate: 12.380952380952381,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'alysialaree'
        }
      },
      userCampaignId: '105ae5b7-05e9-4503-81af-77523a3af7e1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-17T18:25:34.000Z',
        amountCents: 7407
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aa1c5618-3326-4588-9732-56b3a47097e6',
        firstName: 'Bree',
        lastName: 'Lopez',
        email: 'bree.vogt@ymail.com',
        instagramProfile: {
          handle: 'breedandy',
          followers: 13225,
          engagementRate: 1.3277882797731568,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'breedandyy',
          followers: 13427,
          engagementRate: 2.265673494723774,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'breedandyy'
        }
      },
      userCampaignId: 'aa1c5618-3326-4588-9732-56b3a47097e6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-18T09:42:43.000Z',
        amountCents: 9556
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-12T14:53:11.779Z',
          createdAt: '2024-04-11T05:30:50.861Z',
          content: [
            {
              id: '68c0763b-a2af-4f18-965c-2e7a3828fd1c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa0d9917-cd29-4f7e-ae8a-242f0e35f81d/ugc/f5dec061-a47c-432c-89e2-6562fda055f0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd6c0701b-b196-44c3-bf1f-66de0025ffc5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa0d9917-cd29-4f7e-ae8a-242f0e35f81d/ugc/f5dec061-a47c-432c-89e2-6562fda055f0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f5dec061-a47c-432c-89e2-6562fda055f0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'aa0d9917-cd29-4f7e-ae8a-242f0e35f81d',
        firstName: 'Danielle',
        lastName: 'Johnson',
        email: 'danielle.r.brewer@gmail.com',
        instagramProfile: {
          handle: 'daniellerosejohnsonnn',
          followers: 5971,
          engagementRate: 1.5474794841735053,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'daniellerosejohnsonnn',
          followers: 631,
          engagementRate: 0.6211180124223602,
          isPrimary: true
        }
      },
      userCampaignId: 'aa0d9917-cd29-4f7e-ae8a-242f0e35f81d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-18T08:03:07.000Z',
        amountCents: 7545
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '78da9d0d-150a-4361-834f-1bfded655da9',
        firstName: 'Taylor',
        lastName: 'Renyer',
        email: 'tkrenyer@gmail.com',
        instagramProfile: {
          handle: 'realifeariel',
          followers: 75690,
          engagementRate: 0.2364909499273352,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'taylorrenyer',
          followers: 213,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'taylorrenyer'
        }
      },
      userCampaignId: '78da9d0d-150a-4361-834f-1bfded655da9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T21:23:04.000Z',
        amountCents: 9746
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '560b9c2a-4b0c-41b0-9261-98af3e34ce16',
        firstName: 'Yunjia',
        lastName: 'Ge',
        email: 'msjoange@gmail.com',
        instagramProfile: {
          handle: 'msjoange',
          followers: 42502,
          engagementRate: 0.38845230812667636,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'msjoange',
          followers: 3472,
          engagementRate: 8.17374304050321,
          isPrimary: true
        }
      },
      userCampaignId: '560b9c2a-4b0c-41b0-9261-98af3e34ce16'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T19:25:33.000Z',
        amountCents: 7494
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '07c8af34-cfe7-4a66-8240-cef411f8a7ac',
        firstName: 'Diana',
        lastName: 'Ly',
        email: 'collabwithdianaly@gmail.com',
        tiktokProfile: {
          handle: 'duuhdiana',
          followers: 7960,
          engagementRate: 31.919999999999998,
          isPrimary: false
        }
      },
      userCampaignId: '07c8af34-cfe7-4a66-8240-cef411f8a7ac'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T00:51:55.000Z',
        amountCents: 8765
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:29:10.042Z',
          createdAt: '2024-03-10T22:49:15.091Z',
          content: [
            {
              id: 'cea961dd-8f8e-413d-abb4-a70d981853b8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2bef712f-9324-44a9-8a7e-fea4ceabf2a2/ugc/4a8de462-4d1e-423a-8ee9-02912f5ac10e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '01554bf5-9059-4194-95a2-36e0f449793b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2bef712f-9324-44a9-8a7e-fea4ceabf2a2/ugc/4a8de462-4d1e-423a-8ee9-02912f5ac10e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '35d589c0-ec2f-4ea1-93c6-feb107586fb9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2bef712f-9324-44a9-8a7e-fea4ceabf2a2/ugc/4a8de462-4d1e-423a-8ee9-02912f5ac10e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4a8de462-4d1e-423a-8ee9-02912f5ac10e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-14T19:29:03.565Z',
          createdAt: '2024-03-10T18:10:21.136Z',
          content: [
            {
              id: 'f1468fed-3de6-4ee0-bc18-8daa49b9e635',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2bef712f-9324-44a9-8a7e-fea4ceabf2a2/ugc/a7bc129c-9be0-4e24-bc0c-7dbc656261aa/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a7bc129c-9be0-4e24-bc0c-7dbc656261aa'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2bef712f-9324-44a9-8a7e-fea4ceabf2a2',
        firstName: 'Rachel',
        lastName: 'Meador',
        email: 'rachellmeador01@gmail.com',
        instagramProfile: {
          handle: 'rachellmeador',
          followers: 3218,
          engagementRate: 2.784338098197638,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'rachellmeador',
          followers: 1972,
          engagementRate: 10.427969186688275,
          isPrimary: true
        }
      },
      userCampaignId: '2bef712f-9324-44a9-8a7e-fea4ceabf2a2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T21:12:23.000Z',
        amountCents: 8601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-31T22:33:09.851Z',
          createdAt: '2024-03-21T23:16:43.358Z',
          content: [
            {
              id: 'ea136fe1-3ecb-4a61-b9c2-31f19e2e43c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fd9fcfbf-c720-4019-adc6-977ac4081e46/ugc/2aa04c01-da1f-4672-bbfc-487ccdb31a86/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ec1b69c6-8e8e-4abe-b29a-c98b0d60b474',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fd9fcfbf-c720-4019-adc6-977ac4081e46/ugc/2aa04c01-da1f-4672-bbfc-487ccdb31a86/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0fdedac0-d9c4-4687-aa94-4285fa1d952c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fd9fcfbf-c720-4019-adc6-977ac4081e46/ugc/2aa04c01-da1f-4672-bbfc-487ccdb31a86/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2aa04c01-da1f-4672-bbfc-487ccdb31a86'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-31T22:33:05.200Z',
          createdAt: '2024-03-22T01:02:54.110Z',
          content: [
            {
              id: '67b64646-14ee-42cf-ba92-fdf87de50495',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fd9fcfbf-c720-4019-adc6-977ac4081e46/ugc/97e56b0a-37bc-4d72-81b2-fad66144e969/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '97e56b0a-37bc-4d72-81b2-fad66144e969'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'fd9fcfbf-c720-4019-adc6-977ac4081e46',
        firstName: 'Dianna',
        lastName: 'SilasQiu',
        email: 'diannaqiu@gmail.com',
        instagramProfile: {
          handle: 'silasqiu',
          followers: 14561,
          engagementRate: 0.7581896847743974,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'silasqiu',
          followers: 369,
          engagementRate: 1.5822784810126582,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'silasqiu'
        }
      },
      userCampaignId: 'fd9fcfbf-c720-4019-adc6-977ac4081e46'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T19:15:16.000Z',
        amountCents: 8374
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-19T03:58:17.005Z',
          createdAt: '2024-03-16T14:08:14.132Z',
          content: [
            {
              id: '29b03c7a-890f-4595-8d42-f144ef418c1e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69f2fe10-4809-43c0-b18b-7737b7820e45/ugc/26e3ead2-9937-4bce-bb01-fcbca1e2b47b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4b93a665-464a-4376-936b-a70d60cf3ca3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69f2fe10-4809-43c0-b18b-7737b7820e45/ugc/26e3ead2-9937-4bce-bb01-fcbca1e2b47b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '191e14ba-f458-4028-8462-11280aa94866',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69f2fe10-4809-43c0-b18b-7737b7820e45/ugc/26e3ead2-9937-4bce-bb01-fcbca1e2b47b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '26e3ead2-9937-4bce-bb01-fcbca1e2b47b'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-19T18:16:47.469Z',
          createdAt: '2024-03-19T03:58:44.403Z',
          content: [
            {
              id: 'ce0bd4f3-f992-4007-8f02-c657ee991929',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69f2fe10-4809-43c0-b18b-7737b7820e45/ugc/a4e99e63-ef9e-4e80-9b22-099ddabd04aa/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a4e99e63-ef9e-4e80-9b22-099ddabd04aa'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '69f2fe10-4809-43c0-b18b-7737b7820e45',
        firstName: 'Rachelle',
        lastName: 'Farquhar',
        email: 'farquharboys@gmail.com',
        instagramProfile: {
          handle: '_james.family',
          followers: 12408,
          engagementRate: 3.0375564152159895,
          isPrimary: true
        }
      },
      userCampaignId: '69f2fe10-4809-43c0-b18b-7737b7820e45'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T23:26:05.000Z',
        amountCents: 7607
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-26T05:41:41.434Z',
          createdAt: '2024-03-18T06:37:45.611Z',
          content: [
            {
              id: '1f48ed03-f9c3-4b17-ac83-287a1c1f5d10',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/57765f59-e3f8-4462-b105-54792321f765/ugc/10a89ffd-7dfc-4fcc-a5f7-95cd8c37cab3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '10a89ffd-7dfc-4fcc-a5f7-95cd8c37cab3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-26T04:14:18.976Z',
          createdAt: '2024-03-18T06:38:35.506Z',
          content: [
            {
              id: '24057301-1c14-4286-9225-48971818e503',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/57765f59-e3f8-4462-b105-54792321f765/ugc/d4e32e7d-eca5-4c81-8df2-f10dd0fde4e2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '862296f1-2659-41f9-acd0-86bfb6565add',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/57765f59-e3f8-4462-b105-54792321f765/ugc/d4e32e7d-eca5-4c81-8df2-f10dd0fde4e2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4dda4ec7-ddaf-4260-a97d-2177d6140e2f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/57765f59-e3f8-4462-b105-54792321f765/ugc/d4e32e7d-eca5-4c81-8df2-f10dd0fde4e2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd4e32e7d-eca5-4c81-8df2-f10dd0fde4e2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '57765f59-e3f8-4462-b105-54792321f765',
        firstName: 'Hailey',
        lastName: 'Juarez',
        email: 'haileycjuarez@gmail.com',
        instagramProfile: {
          handle: 'haileycjuarez',
          followers: 6825,
          engagementRate: 1.1765567765567764,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'haileycjuarez',
          followers: 66686,
          engagementRate: 7.396214706794911,
          isPrimary: true
        }
      },
      userCampaignId: '57765f59-e3f8-4462-b105-54792321f765'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T03:03:27.000Z',
        amountCents: 7512
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-19T02:08:43.228Z',
          createdAt: '2024-03-15T18:27:52.518Z',
          content: [
            {
              id: '603f3e9a-6bc3-49d1-8512-aeb6e89852ea',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f920304b-854e-4677-9bdd-e2a5bc203742/ugc/5b20a278-2be6-4163-9896-904e432e2a45/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6691269e-9780-40d2-9c26-e32c15a506d7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f920304b-854e-4677-9bdd-e2a5bc203742/ugc/5b20a278-2be6-4163-9896-904e432e2a45/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b4a54e40-10f5-43e0-88b6-c6bfc5aa6789',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f920304b-854e-4677-9bdd-e2a5bc203742/ugc/5b20a278-2be6-4163-9896-904e432e2a45/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5b20a278-2be6-4163-9896-904e432e2a45'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-21T02:08:49.158Z',
          createdAt: '2024-03-15T18:23:05.550Z',
          content: [
            {
              id: '0a47be93-6cfc-4255-8e68-8b8d726db4eb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f920304b-854e-4677-9bdd-e2a5bc203742/ugc/91f4b607-0190-4312-af79-251c31badecc/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '91f4b607-0190-4312-af79-251c31badecc'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f920304b-854e-4677-9bdd-e2a5bc203742',
        firstName: 'Emily',
        lastName: 'Toplin',
        email: 'emily.hellobalance@gmail.com',
        tiktokProfile: {
          handle: 'hello.balance',
          followers: 23825,
          engagementRate: 5.046095691495482,
          isPrimary: true
        }
      },
      userCampaignId: 'f920304b-854e-4677-9bdd-e2a5bc203742'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T20:15:26.000Z',
        amountCents: 8900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.IG,
      content: [
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_REEL,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-19T23:45:31.000Z',
          createdAt: '2024-03-15T01:55:53.923Z',
          content: [
            {
              id: '055537d3-be0d-4cea-8c1a-886a26583b9d',
              caption:
                '@coopsleepgoods alternative down pillows are like sleeping on a cloud ☁️ ! I love how they are adjustable and you can remove or add more fluff. Personally, I like them to be extra fluffy ☺️ #brandpartner #yousleepyou #CoopSleepsGoods',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/C4tvZ_4JWku/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 16,
              likes: 444,
              views: 4432,
              engagements: 481
            }
          ],
          groupId: '02413b88-fc3e-434c-9dc8-d175d6838dec'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-23T00:00:00.000Z',
          createdAt: '2024-03-15T02:12:27.055Z',
          content: [
            {
              id: '339d5592-e7a1-4af0-a191-cf82341fe43b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18253870813245657/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c4d93457-1b0a-4a6b-844e-7e254ccf46d4'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-26T00:00:00.000Z',
          createdAt: '2024-03-15T02:23:25.245Z',
          content: [
            {
              id: 'db0331a3-a281-4f9d-bfcf-40c1dc526b0e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18017899880488568/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f75efcf7-5944-477f-9147-635849847154'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353',
        firstName: 'Vanessa',
        lastName: 'Acuna',
        email: 'jnvutube@gmail.com',
        instagramProfile: {
          handle: 'this.season.of.life',
          followers: 24792,
          engagementRate: 4.204985479186835,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'vanessamilian07',
          followers: 3767,
          engagementRate: 8.993432515748559,
          isPrimary: true
        }
      },
      userCampaignId: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T18:15:20.000Z',
        amountCents: 10049
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '479feadc-75e5-485a-8225-01765e331656',
        firstName: 'Jackie',
        lastName: 'Bobbitt',
        email: 'mamafitnation@gmail.com',
        instagramProfile: {
          handle: 'coachjaqe',
          followers: 5532,
          engagementRate: 1.294287780187997,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'coachjaqe',
          followers: 225,
          engagementRate: 9.108945373161598,
          isPrimary: false
        }
      },
      userCampaignId: '479feadc-75e5-485a-8225-01765e331656'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T21:47:49.000Z',
        amountCents: 6900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-09T03:03:57.293Z',
          createdAt: '2024-04-04T17:31:25.972Z',
          content: [
            {
              id: '9e3d3fc1-0943-45ec-a178-65b431ba9225',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cef68925-a287-4c63-acdc-450595f49e2e/ugc/32b165b0-be6c-4fa1-ad2e-d59ea7fbbdac/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '462f75d3-8a32-4949-8683-81b271b8c278',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cef68925-a287-4c63-acdc-450595f49e2e/ugc/32b165b0-be6c-4fa1-ad2e-d59ea7fbbdac/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '32b165b0-be6c-4fa1-ad2e-d59ea7fbbdac'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-12T00:20:40.316Z',
          createdAt: '2024-04-10T12:17:44.054Z',
          content: [
            {
              id: 'd4d50f5f-4330-4f17-9f1b-a2119795fb70',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cef68925-a287-4c63-acdc-450595f49e2e/ugc/275b4c74-fe41-4e37-ad7a-a7c6e18e6cac/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '275b4c74-fe41-4e37-ad7a-a7c6e18e6cac'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cef68925-a287-4c63-acdc-450595f49e2e',
        firstName: 'Sheree',
        lastName: 'Brown',
        email: 'shereeb807@gmail.com',
        instagramProfile: {
          handle: 'sheree_nicole_',
          followers: 5313,
          engagementRate: 4.057971014492753,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sheree_nicole_',
          followers: 2954,
          engagementRate: 17.85484291024457,
          isPrimary: false
        }
      },
      userCampaignId: 'cef68925-a287-4c63-acdc-450595f49e2e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-10T18:20:02.000Z',
        amountCents: 13293
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-18T16:14:04.834Z',
          createdAt: '2024-03-15T15:50:31.211Z',
          content: [
            {
              id: 'e76a4a87-206b-43db-89fa-6464dba3fc2e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e83762e-cdcb-481c-b37f-82b63654aa84/ugc/b771b03a-1e70-4388-875b-aa240de4d33d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '940a9002-7e50-4629-9684-74d81955b79c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e83762e-cdcb-481c-b37f-82b63654aa84/ugc/b771b03a-1e70-4388-875b-aa240de4d33d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'dbed6440-4274-4c99-b4c3-ff50d7c4d3d9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e83762e-cdcb-481c-b37f-82b63654aa84/ugc/b771b03a-1e70-4388-875b-aa240de4d33d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b771b03a-1e70-4388-875b-aa240de4d33d'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-18T16:14:00.111Z',
          createdAt: '2024-03-15T16:03:03.351Z',
          content: [
            {
              id: 'e7bd50bf-840a-4265-be02-835d2e4cc111',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7e83762e-cdcb-481c-b37f-82b63654aa84/ugc/b95a3b57-2f68-4c31-bd39-ccd0bf7d3993/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b95a3b57-2f68-4c31-bd39-ccd0bf7d3993'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7e83762e-cdcb-481c-b37f-82b63654aa84',
        firstName: 'Marta',
        lastName: 'Adames',
        email: 'marta_adames@live.com',
        instagramProfile: {
          handle: 'magicalmarta_',
          followers: 11714,
          engagementRate: 1.0056342837630186,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'magicalmarta_',
          followers: 18804,
          engagementRate: 2.564102564102564,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'martaproductionsllc',
          followers: 26000
        }
      },
      userCampaignId: '7e83762e-cdcb-481c-b37f-82b63654aa84'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T14:03:29.000Z',
        amountCents: 8423
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-18T16:19:38.055Z',
          createdAt: '2024-03-17T20:14:06.120Z',
          content: [
            {
              id: '838e6b35-53ec-4eff-9daa-70601063d180',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f266a663-d73f-4e6c-af92-a54dddd72e2d/ugc/10a95f64-3dc7-4633-add0-325ddae12e00/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '10a95f64-3dc7-4633-add0-325ddae12e00'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f266a663-d73f-4e6c-af92-a54dddd72e2d',
        firstName: 'Kaci',
        lastName: 'Kilfoile',
        email: 'kacicollins421@gmail.com',
        instagramProfile: {
          handle: 'kacicollins',
          followers: 3459,
          engagementRate: 0.731425267418329,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kacicollins4',
          followers: 215,
          isPrimary: true
        }
      },
      userCampaignId: 'f266a663-d73f-4e6c-af92-a54dddd72e2d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T17:33:34.000Z',
        amountCents: 7512
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-02T01:41:30.516Z',
          createdAt: '2024-03-20T23:59:00.455Z',
          content: [
            {
              id: 'b8e4ec42-d57f-497c-bbaf-a3ab8695f188',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9e0cdd03-1c58-458c-9df2-759b88679714/ugc/a0ec45a4-5ebd-4973-b2e4-5e8ac338bbd6/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1d381942-7e1f-49cf-8b3b-0a6f2fe6e049',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9e0cdd03-1c58-458c-9df2-759b88679714/ugc/a0ec45a4-5ebd-4973-b2e4-5e8ac338bbd6/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8be8f5c3-e5a4-46d4-9c3a-021ece08ce0f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9e0cdd03-1c58-458c-9df2-759b88679714/ugc/a0ec45a4-5ebd-4973-b2e4-5e8ac338bbd6/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a0ec45a4-5ebd-4973-b2e4-5e8ac338bbd6'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-02T01:41:25.365Z',
          createdAt: '2024-03-20T23:53:42.174Z',
          content: [
            {
              id: '4ae6e7d0-1ed4-4852-b829-605373c69210',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9e0cdd03-1c58-458c-9df2-759b88679714/ugc/f04a36df-5079-408e-bc49-826f70a250d1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f04a36df-5079-408e-bc49-826f70a250d1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '9e0cdd03-1c58-458c-9df2-759b88679714',
        firstName: 'Katelyn',
        lastName: 'davis',
        email: 'katejoandavis@gmail.com',
        instagramProfile: {
          handle: 'kate_davis',
          followers: 11858,
          engagementRate: 0.4258728284702311,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'katedavisnyc',
          followers: 221,
          isPrimary: false
        }
      },
      userCampaignId: '9e0cdd03-1c58-458c-9df2-759b88679714'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T20:06:06.000Z',
        amountCents: 7504
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:37.927Z',
          createdAt: '2024-03-14T02:36:49.001Z',
          content: [
            {
              id: '5cb6cc42-2829-4467-b5e9-6845b66b6c10',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/367ad986-00cd-400f-a467-c5bbd3b8f439/ugc/cbe3030b-dae5-4d6d-ba1a-200a61e7f007/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'aa0b6828-8ef2-4ef7-991d-ea94c7c1d0a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/367ad986-00cd-400f-a467-c5bbd3b8f439/ugc/cbe3030b-dae5-4d6d-ba1a-200a61e7f007/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ef801295-9b64-4c6f-a3ce-686e0e1f7412',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/367ad986-00cd-400f-a467-c5bbd3b8f439/ugc/cbe3030b-dae5-4d6d-ba1a-200a61e7f007/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cbe3030b-dae5-4d6d-ba1a-200a61e7f007'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:32.895Z',
          createdAt: '2024-03-11T23:20:58.061Z',
          content: [
            {
              id: 'd4acbe99-5a0f-4ecb-a8eb-b8208fe72acc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/367ad986-00cd-400f-a467-c5bbd3b8f439/ugc/2b47d483-71da-468d-807e-1da269772a9a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2b47d483-71da-468d-807e-1da269772a9a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '367ad986-00cd-400f-a467-c5bbd3b8f439',
        firstName: 'Denise',
        lastName: 'Ferreira',
        email: 'denisecollabs@gmail.com',
        instagramProfile: {
          handle: 'idesignbydenise',
          followers: 11431,
          engagementRate: 2.7023007610882686,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'deniise.ferreira',
          followers: 5039,
          engagementRate: 10.919066508680645,
          isPrimary: true
        }
      },
      userCampaignId: '367ad986-00cd-400f-a467-c5bbd3b8f439'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T18:33:41.000Z',
        amountCents: 7314
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-12T15:09:09.949Z',
          createdAt: '2024-04-10T14:49:07.703Z',
          content: [
            {
              id: '5dd1fe01-6f67-4764-b236-99d02bd99cf8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0331c84b-c480-4f92-be88-179b606e3e0a/ugc/ad7bc47f-0aad-4736-bc7c-a76b2dc7cc10/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2ad6fa98-abba-41e3-9cb8-53d829d18c40',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0331c84b-c480-4f92-be88-179b606e3e0a/ugc/ad7bc47f-0aad-4736-bc7c-a76b2dc7cc10/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ad7bc47f-0aad-4736-bc7c-a76b2dc7cc10'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-12T15:09:06.267Z',
          createdAt: '2024-04-10T15:06:56.938Z',
          content: [
            {
              id: 'c5fd1682-3721-4b70-bd73-d483db6da7ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0331c84b-c480-4f92-be88-179b606e3e0a/ugc/4ef95310-446b-4001-aa93-d9fa70cebdd9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4ef95310-446b-4001-aa93-d9fa70cebdd9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0331c84b-c480-4f92-be88-179b606e3e0a',
        firstName: 'Kaitlynn',
        lastName: 'Ramirez',
        email: 'mamakaitebiz@gmail.com',
        instagramProfile: {
          handle: '_.mamakaite._',
          followers: 721,
          engagementRate: 9.251040221914009,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'mamakaite',
          followers: 8385,
          engagementRate: 4.600238195978876,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'mamakaite',
          followers: 760
        }
      },
      userCampaignId: '0331c84b-c480-4f92-be88-179b606e3e0a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T23:48:52.000Z',
        amountCents: 7314
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-21T05:10:37.884Z',
          createdAt: '2024-03-19T16:54:47.253Z',
          content: [
            {
              id: '818f0dcd-2621-4915-b726-7bc9c9b6d7d8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc48dd73-9868-4152-abdd-ec88e6a8c478/ugc/32f4487c-2551-40c7-a262-f7d87bb7b3a0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0bf38583-342a-4b99-84d6-fd3f689d6bc5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc48dd73-9868-4152-abdd-ec88e6a8c478/ugc/32f4487c-2551-40c7-a262-f7d87bb7b3a0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c5e55244-889c-4357-ba81-6f333266dee4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc48dd73-9868-4152-abdd-ec88e6a8c478/ugc/32f4487c-2551-40c7-a262-f7d87bb7b3a0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '32f4487c-2551-40c7-a262-f7d87bb7b3a0'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-21T05:09:15.568Z',
          createdAt: '2024-03-19T17:59:17.438Z',
          content: [
            {
              id: 'e3a1b3c9-56a1-476f-af2c-3f37302ab8f1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dc48dd73-9868-4152-abdd-ec88e6a8c478/ugc/11d2d29b-8f31-4521-a155-cc1b3406c4f7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '11d2d29b-8f31-4521-a155-cc1b3406c4f7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'dc48dd73-9868-4152-abdd-ec88e6a8c478',
        firstName: 'Chelsea',
        lastName: 'Greer',
        email: 'chelsea757@gmail.com',
        instagramProfile: {
          handle: 'chelsealizgreer',
          followers: 8772,
          engagementRate: 3.6867305061559503,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chelsealizgreer',
          followers: 177,
          engagementRate: 5.839416058394161,
          isPrimary: true
        }
      },
      userCampaignId: 'dc48dd73-9868-4152-abdd-ec88e6a8c478'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-11T13:59:53.000Z',
        amountCents: 7383
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.TT,
      content: [
        {
          channel: Channel.TIKTOK,
          contentType: ContentType.TT_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-21T15:21:21.000Z',
          createdAt: '2024-03-15T17:29:19.008Z',
          content: [
            {
              id: '4d478a7a-6c04-427a-ac60-a537dd551097',
              caption: 'Find @Coop Sleep Goods on Amaz0n! 😍👏🏻 #brandpartner #yousleepyou #coopsleepgoods ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7348837074495360302/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 1,
              likes: 120,
              views: 4188
            }
          ],
          groupId: '0af27087-4fb6-4df5-beb5-35878c2b9f5f'
        },
        {
          channel: Channel.TIKTOK,
          contentType: ContentType.TT_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-22T00:04:11.000Z',
          createdAt: '2024-03-15T17:28:03.466Z',
          content: [
            {
              id: '522d3be7-5957-4418-9d03-8cc8e04c5114',
              caption:
                'Find @Coop Sleep Goods on Amaz0n! Love their adjustability 🙌🏻😍 #brandpartner #yousleepyou #coopsleepgoods ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7348971836878654766/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 11,
              likes: 215,
              views: 6218
            }
          ],
          groupId: 'f6d79ff9-3007-45ed-a3fd-214338d92e63'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3dddfca3-3108-4d4c-a1b3-fb2e1e2903ef',
        firstName: 'Blair',
        lastName: 'Newman',
        email: 'blairnewman9@gmail.com',
        tiktokProfile: {
          handle: 'ttcbabynewman',
          followers: 73316,
          engagementRate: 7.345122121074923,
          isPrimary: false
        }
      },
      userCampaignId: '3dddfca3-3108-4d4c-a1b3-fb2e1e2903ef'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T16:36:33.000Z',
        amountCents: 9648
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T11:20:51.814Z',
          createdAt: '2024-03-26T22:02:40.623Z',
          content: [
            {
              id: '5d6fd5a8-3957-4e7c-9b17-b7163f6df63b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b45345c9-abd1-4d12-bc1f-a6a068c17348/ugc/3c1eb062-3469-4cf0-b84c-ce6a413f2dba/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fdd2fb93-43ee-4ae4-801f-159504a4bc06',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b45345c9-abd1-4d12-bc1f-a6a068c17348/ugc/3c1eb062-3469-4cf0-b84c-ce6a413f2dba/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'dbd05cb8-99bb-4504-853e-28fc79656b65',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b45345c9-abd1-4d12-bc1f-a6a068c17348/ugc/3c1eb062-3469-4cf0-b84c-ce6a413f2dba/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3c1eb062-3469-4cf0-b84c-ce6a413f2dba'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-28T11:20:47.634Z',
          createdAt: '2024-03-26T22:00:37.566Z',
          content: [
            {
              id: '958a3fd8-6a65-4e5d-ba2f-82bdd541c7ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b45345c9-abd1-4d12-bc1f-a6a068c17348/ugc/26a447e4-02f4-4a00-b0ad-d78adfec435f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '26a447e4-02f4-4a00-b0ad-d78adfec435f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b45345c9-abd1-4d12-bc1f-a6a068c17348',
        firstName: 'Makayla',
        lastName: 'Reid',
        email: 'makaylae.reid@outlook.com',
        instagramProfile: {
          handle: 'charm.gold',
          followers: 9210,
          engagementRate: 0.498371335504886,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'charm.gold',
          followers: 210,
          isPrimary: false
        }
      },
      userCampaignId: 'b45345c9-abd1-4d12-bc1f-a6a068c17348'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T18:33:05.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-26T20:29:13.060Z',
          createdAt: '2024-03-19T22:00:31.662Z',
          content: [
            {
              id: 'b77a7060-d44b-4ef2-a519-c86a97c31dec',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7205c94d-cec5-479d-9317-53d6ac4a097a/ugc/611184e4-ccd8-4b8d-9821-a70175aef1c6/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '686b10a4-92e7-4a38-9cad-278f06b00823',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7205c94d-cec5-479d-9317-53d6ac4a097a/ugc/611184e4-ccd8-4b8d-9821-a70175aef1c6/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '382df636-3a8a-4c1d-b150-f71713681e34',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7205c94d-cec5-479d-9317-53d6ac4a097a/ugc/611184e4-ccd8-4b8d-9821-a70175aef1c6/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '611184e4-ccd8-4b8d-9821-a70175aef1c6'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-20T13:35:57.856Z',
          createdAt: '2024-03-19T21:53:49.608Z',
          content: [
            {
              id: '18e29965-9855-4cc1-b4fa-af109ee4e1dd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7205c94d-cec5-479d-9317-53d6ac4a097a/ugc/2b0f4c49-49e5-42ff-b81f-6734fbe401d1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2b0f4c49-49e5-42ff-b81f-6734fbe401d1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7205c94d-cec5-479d-9317-53d6ac4a097a',
        firstName: 'Sam',
        lastName: 'Small',
        email: 'samsmalldesigns@gmail.com',
        instagramProfile: {
          handle: 'ssma11z',
          followers: 6042,
          engagementRate: 0.7994041708043693,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ssma11z',
          followers: 4195,
          engagementRate: 1.1560693641618496,
          isPrimary: false
        }
      },
      userCampaignId: '7205c94d-cec5-479d-9317-53d6ac4a097a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T14:53:02.000Z',
        amountCents: 8587
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '11cf0784-125e-46f4-a20a-9fc9d45d4e1f',
        firstName: 'Brittany',
        lastName: 'Ferrouge',
        email: 'blh10e@gmail.com',
        tiktokProfile: {
          handle: 'brittanyferrouge',
          followers: 27322,
          engagementRate: 6.0780938561508835,
          isPrimary: false
        }
      },
      userCampaignId: '11cf0784-125e-46f4-a20a-9fc9d45d4e1f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T04:46:33.000Z',
        amountCents: 7383
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-18T18:41:42.205Z',
          createdAt: '2024-03-16T04:17:52.841Z',
          content: [
            {
              id: '937bc68b-cbf7-45cb-adf7-da01b2fd5f04',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/502f3a1d-f9c4-4537-8c05-1c1ad02fe804/ugc/307b52af-a149-4d18-a748-760bf58c1494/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '307b52af-a149-4d18-a748-760bf58c1494'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-18T18:41:50.549Z',
          createdAt: '2024-03-16T04:19:57.874Z',
          content: [
            {
              id: 'd31e4762-45b7-4003-836e-0e521eef2be3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/502f3a1d-f9c4-4537-8c05-1c1ad02fe804/ugc/afb44af6-d5f1-422d-af6a-2f7624ae341b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2c5fe8b8-0964-4dcf-934c-9e6d9b165af1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/502f3a1d-f9c4-4537-8c05-1c1ad02fe804/ugc/afb44af6-d5f1-422d-af6a-2f7624ae341b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7cbea6dd-a0a8-4bed-81d2-d26cb7c81a35',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/502f3a1d-f9c4-4537-8c05-1c1ad02fe804/ugc/afb44af6-d5f1-422d-af6a-2f7624ae341b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'afb44af6-d5f1-422d-af6a-2f7624ae341b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '502f3a1d-f9c4-4537-8c05-1c1ad02fe804',
        firstName: 'tannesia',
        lastName: 'speed',
        email: 'tannesias@yahoo.com',
        tiktokProfile: {
          handle: 'tannesias',
          followers: 6880,
          engagementRate: 9.256811492276835,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'tannesiatv',
          followers: 78
        }
      },
      userCampaignId: '502f3a1d-f9c4-4537-8c05-1c1ad02fe804'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T17:45:27.000Z',
        amountCents: 17024
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-04T05:55:02.640Z',
          createdAt: '2024-03-27T13:46:35.895Z',
          content: [
            {
              id: '647431c8-387c-4404-a22d-66463fef56b7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/950135fa-2129-41a2-b7d3-58f864f42a0a/ugc/5c7ae221-95b3-4986-a084-ff49bb0a634e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5171bf62-bfa7-482c-9660-c57b7b933199',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/950135fa-2129-41a2-b7d3-58f864f42a0a/ugc/5c7ae221-95b3-4986-a084-ff49bb0a634e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '649ac7fe-4260-41ad-a7dd-67f3c2f74b38',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/950135fa-2129-41a2-b7d3-58f864f42a0a/ugc/5c7ae221-95b3-4986-a084-ff49bb0a634e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5c7ae221-95b3-4986-a084-ff49bb0a634e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-04T05:54:57.247Z',
          createdAt: '2024-03-19T14:42:44.898Z',
          content: [
            {
              id: 'a4891597-e37e-49dc-ae24-e5561fc9f9f0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/950135fa-2129-41a2-b7d3-58f864f42a0a/ugc/f388bda4-4a2e-473c-af2d-a6c2aecff2a1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f388bda4-4a2e-473c-af2d-a6c2aecff2a1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '950135fa-2129-41a2-b7d3-58f864f42a0a',
        firstName: 'Jessica',
        lastName: 'West',
        email: 'collabwjess@gmail.com',
        instagramProfile: {
          handle: 'jess.di0rr',
          followers: 35230,
          engagementRate: 0.2228214589838206,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jess.di0rr',
          followers: 7444,
          engagementRate: 5.3615411681914145,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jess.di0rr',
          followers: 24
        }
      },
      userCampaignId: '950135fa-2129-41a2-b7d3-58f864f42a0a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-13T23:03:31.000Z',
        amountCents: 7357
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-30T18:09:28.710Z',
          createdAt: '2024-03-26T23:24:01.830Z',
          content: [
            {
              id: '2f7c7509-afc5-40fc-88ee-a24cd045e734',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5930790b-7892-48a3-99e6-e9074a803b72/ugc/6da26c5c-a6f6-41df-9c5b-e736469457cc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e67379a9-2629-4bd8-a8d7-1ae43c5f9d3a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5930790b-7892-48a3-99e6-e9074a803b72/ugc/6da26c5c-a6f6-41df-9c5b-e736469457cc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e82936e1-6617-4d86-9325-c2ee50e78393',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5930790b-7892-48a3-99e6-e9074a803b72/ugc/6da26c5c-a6f6-41df-9c5b-e736469457cc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6da26c5c-a6f6-41df-9c5b-e736469457cc'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-29T19:18:48.121Z',
          createdAt: '2024-03-26T23:23:16.404Z',
          content: [
            {
              id: '0f69e7e0-609d-4ff1-a4c9-5929d7b882ea',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5930790b-7892-48a3-99e6-e9074a803b72/ugc/b23150e9-3bc0-4710-a782-544915f6e6a5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b23150e9-3bc0-4710-a782-544915f6e6a5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5930790b-7892-48a3-99e6-e9074a803b72',
        firstName: 'Celestina',
        lastName: 'Antwi',
        email: 'melaninbytina@gmail.com',
        tiktokProfile: {
          handle: 'emprezz_xx1',
          followers: 8641,
          engagementRate: 9.110169491525424,
          isPrimary: true
        }
      },
      userCampaignId: '5930790b-7892-48a3-99e6-e9074a803b72'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-14T19:29:14.000Z',
        amountCents: 8601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-20T11:31:13.286Z',
          createdAt: '2024-03-19T18:05:02.296Z',
          content: [
            {
              id: 'b08c9eb9-0fae-429d-98e8-bfd59cfb3df5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3ecc2660-10cd-4db6-95bc-4985b73fb227/ugc/3c39412a-da15-45f9-9adb-31201773ea66/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2f584824-a3f6-46f8-a689-4a39afe477a4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3ecc2660-10cd-4db6-95bc-4985b73fb227/ugc/3c39412a-da15-45f9-9adb-31201773ea66/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '00eab34b-76db-413c-8cc3-670ed4021761',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3ecc2660-10cd-4db6-95bc-4985b73fb227/ugc/3c39412a-da15-45f9-9adb-31201773ea66/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3c39412a-da15-45f9-9adb-31201773ea66'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-20T11:31:09.027Z',
          createdAt: '2024-03-19T18:04:28.340Z',
          content: [
            {
              id: 'c2314411-e073-4db1-bb4a-1ae50dd7eec2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3ecc2660-10cd-4db6-95bc-4985b73fb227/ugc/f67b3fef-63c7-465a-b085-614c613709b5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f67b3fef-63c7-465a-b085-614c613709b5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3ecc2660-10cd-4db6-95bc-4985b73fb227',
        firstName: 'Charlene',
        lastName: 'Bottois',
        email: 'charlenebottois@gmail.com',
        tiktokProfile: {
          handle: 'chachabtts',
          followers: 8508,
          engagementRate: 9.38842341279207,
          isPrimary: false
        }
      },
      userCampaignId: '3ecc2660-10cd-4db6-95bc-4985b73fb227'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T17:43:42.000Z',
        amountCents: 7314
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:42.797Z',
          createdAt: '2024-03-14T01:36:14.545Z',
          content: [
            {
              id: '3179f222-d7a4-446f-912a-417b2bc69024',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/8cabc0dd-1cf5-404c-8ebc-99c624bae668/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8ea00ddd-5f8d-44be-820e-be032d0ae521',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/8cabc0dd-1cf5-404c-8ebc-99c624bae668/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7ef30512-8b23-4be1-9ff5-2571ac88fc33',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/8cabc0dd-1cf5-404c-8ebc-99c624bae668/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8cabc0dd-1cf5-404c-8ebc-99c624bae668'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:32.274Z',
          createdAt: '2024-03-14T19:15:32.474Z',
          content: [
            {
              id: '3d5c7a81-7d27-4f2d-91de-5be9dbc1de8e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/82628a2a-7569-402b-8bba-afab51298563/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '529fb76a-af9b-4b4e-b3bf-9f8dcb4c1757',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/82628a2a-7569-402b-8bba-afab51298563/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '82628a2a-7569-402b-8bba-afab51298563'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:34.792Z',
          createdAt: '2024-03-14T19:15:34.992Z',
          content: [
            {
              id: '58068320-f99b-4a1e-a44c-1d74499f15d6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/0a113856-e1ba-45dd-b3bf-746d22d286fa/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b7645e3e-dd12-4aff-841b-1443567d8053',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/0a113856-e1ba-45dd-b3bf-746d22d286fa/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0a113856-e1ba-45dd-b3bf-746d22d286fa'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-14T19:16:10.074Z',
          createdAt: '2024-03-14T13:02:18.744Z',
          content: [
            {
              id: 'cc008600-599e-4f47-8ac9-73900ba45d45',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/80b7b9a5-1aa1-4946-9ba5-7f8de7d6fb53/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '80b7b9a5-1aa1-4946-9ba5-7f8de7d6fb53'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T19:15:38.396Z',
          createdAt: '2024-03-14T19:15:40.214Z',
          content: [
            {
              id: '1a012ed9-8270-4ae5-ac0a-9ad85bde113c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/b0c90b5e-455f-482c-bf67-c17baa1c6782/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '486fc4a7-7b84-4cde-8bf4-d26e840b2183',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/173d0ef8-b858-4d2c-a147-6164d521161f/ugc/b0c90b5e-455f-482c-bf67-c17baa1c6782/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b0c90b5e-455f-482c-bf67-c17baa1c6782'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '173d0ef8-b858-4d2c-a147-6164d521161f',
        firstName: 'Megan',
        lastName: 'Syed',
        email: 'meganlsyed@gmail.com',
        instagramProfile: {
          handle: 'enlighteningells',
          followers: 50267,
          engagementRate: 0.19794298446296774,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'enlighteningells',
          followers: 22,
          isPrimary: false
        }
      },
      userCampaignId: '173d0ef8-b858-4d2c-a147-6164d521161f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-15T18:43:30.000Z',
        amountCents: 8374
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-26T09:53:54.374Z',
          createdAt: '2024-03-25T00:46:21.973Z',
          content: [
            {
              id: '9d186c1d-5202-4a8f-9b1a-b5de3b1871d1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/29c55bb0-5d1d-4c18-ba1b-b7cd2177743d/ugc/2f631848-9ee5-4bef-b288-050cc6a9bb8f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2f631848-9ee5-4bef-b288-050cc6a9bb8f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-26T09:53:59.502Z',
          createdAt: '2024-03-24T20:42:52.852Z',
          content: [
            {
              id: 'f5166bd8-5a3a-47f2-9ec4-61cc0581067b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/29c55bb0-5d1d-4c18-ba1b-b7cd2177743d/ugc/73f86a75-d236-414a-89a1-e9028c785e7b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bb56c5e2-8d33-4b71-a671-e251740601a8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/29c55bb0-5d1d-4c18-ba1b-b7cd2177743d/ugc/73f86a75-d236-414a-89a1-e9028c785e7b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7c7da437-ba71-41d8-901a-c3baa2b4220e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/29c55bb0-5d1d-4c18-ba1b-b7cd2177743d/ugc/73f86a75-d236-414a-89a1-e9028c785e7b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '73f86a75-d236-414a-89a1-e9028c785e7b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '29c55bb0-5d1d-4c18-ba1b-b7cd2177743d',
        firstName: 'CORBI',
        lastName: 'Greenwalt',
        email: 'corbicorb7@gmail.com',
        instagramProfile: {
          handle: 'misstryb4youbuy',
          followers: 14045,
          engagementRate: 3.1776432894268423,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'unb0thered_777',
          followers: 871,
          engagementRate: 25.423728813559322,
          isPrimary: true
        }
      },
      userCampaignId: '29c55bb0-5d1d-4c18-ba1b-b7cd2177743d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-15T22:55:18.000Z',
        amountCents: 7314
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-04T17:57:13.586Z',
          createdAt: '2024-03-24T18:05:13.828Z',
          content: [
            {
              id: '075f3a02-908c-4e1f-b67c-bf7eb5032651',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/10c889a0-287e-47bf-abad-8364e0461e4a/ugc/dc7afa71-c5d5-468c-8bd5-dd5a857f6bf8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '725f667f-b82b-49b2-bce3-805e592e69d3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/10c889a0-287e-47bf-abad-8364e0461e4a/ugc/dc7afa71-c5d5-468c-8bd5-dd5a857f6bf8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7be3256e-1b9d-4762-9a1f-391f42d9428e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/10c889a0-287e-47bf-abad-8364e0461e4a/ugc/dc7afa71-c5d5-468c-8bd5-dd5a857f6bf8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'dc7afa71-c5d5-468c-8bd5-dd5a857f6bf8'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-04T17:57:00.694Z',
          createdAt: '2024-03-24T18:03:50.431Z',
          content: [
            {
              id: '83647f61-e284-4fa2-a7b9-96b3fcfe5465',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/10c889a0-287e-47bf-abad-8364e0461e4a/ugc/bb3d6ee9-70ee-4234-8c25-e74045dca604/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bb3d6ee9-70ee-4234-8c25-e74045dca604'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '10c889a0-287e-47bf-abad-8364e0461e4a',
        firstName: 'Julie',
        lastName: 'Piccolo',
        email: 'juliedanaepiccolo@gmail.com',
        instagramProfile: {
          handle: 'mrs.juliedanaepiccolo',
          followers: 7856,
          engagementRate: 0.23930753564154789,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'juliedanaepiccolo',
          followers: 161,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'juliepiccolo2868'
        }
      },
      userCampaignId: '10c889a0-287e-47bf-abad-8364e0461e4a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-15T17:46:06.000Z',
        amountCents: 8512
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-01T18:11:02.626Z',
          createdAt: '2024-03-26T05:34:47.126Z',
          content: [
            {
              id: 'da1c153d-1167-4058-bd9b-406d56a3021c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3bff27a9-efbf-4c94-9e03-cd7428796265/ugc/e3383603-77b1-4ba9-bcb9-bf0f0679a1cc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd90108ac-77b5-4786-b4b8-acaf1ae2eaaa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3bff27a9-efbf-4c94-9e03-cd7428796265/ugc/e3383603-77b1-4ba9-bcb9-bf0f0679a1cc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e0fe17b7-e291-4238-b322-27cc42c98888',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3bff27a9-efbf-4c94-9e03-cd7428796265/ugc/e3383603-77b1-4ba9-bcb9-bf0f0679a1cc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e3383603-77b1-4ba9-bcb9-bf0f0679a1cc'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-01T18:07:56.197Z',
          createdAt: '2024-03-25T04:45:45.760Z',
          content: [
            {
              id: '330d6f2d-83f2-4a49-a700-2f512aaf9882',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3bff27a9-efbf-4c94-9e03-cd7428796265/ugc/800c2a16-1e78-4330-9024-014f391e8222/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '800c2a16-1e78-4330-9024-014f391e8222'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3bff27a9-efbf-4c94-9e03-cd7428796265',
        firstName: 'Ashley',
        lastName: 'Bourne',
        email: 'ashlopez92@gmail.com',
        instagramProfile: {
          handle: 'smashleybourne',
          followers: 9822,
          engagementRate: 3.7436367338627567,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'smashleybourne',
          followers: 466,
          engagementRate: 9.705882352941178,
          isPrimary: true
        }
      },
      userCampaignId: '3bff27a9-efbf-4c94-9e03-cd7428796265'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-16T16:30:33.000Z',
        amountCents: 7452
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-19T19:35:47.075Z',
          createdAt: '2024-03-18T19:57:55.513Z',
          content: [
            {
              id: 'f9570062-1010-4cc1-820f-73313eefdea4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f6c7c592-e195-424b-a4bc-de84e05ced6e/ugc/776ef5ea-9255-4821-b6db-007ad09cbe43/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '776ef5ea-9255-4821-b6db-007ad09cbe43'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-18T16:10:06.935Z',
          createdAt: '2024-03-18T04:14:42.073Z',
          content: [
            {
              id: 'ea567ca0-1d05-4af6-bd1f-7e0944b8ed95',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f6c7c592-e195-424b-a4bc-de84e05ced6e/ugc/78e9a32b-ad51-4078-945a-09171b6d5f1e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '940a0f63-7d95-49a5-85df-2386a4ef4c0b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f6c7c592-e195-424b-a4bc-de84e05ced6e/ugc/78e9a32b-ad51-4078-945a-09171b6d5f1e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '07130f79-1cfe-4d2f-9305-56be50ce15d3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f6c7c592-e195-424b-a4bc-de84e05ced6e/ugc/78e9a32b-ad51-4078-945a-09171b6d5f1e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '78e9a32b-ad51-4078-945a-09171b6d5f1e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f6c7c592-e195-424b-a4bc-de84e05ced6e',
        firstName: 'Tiana',
        lastName: 'Wilson',
        email: 'tiana.doing.things@gmail.com',
        instagramProfile: {
          handle: 'tiana.doing.things',
          followers: 155,
          engagementRate: 5.29032258064516,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tiana.doing.things',
          followers: 10996,
          engagementRate: 10.197106072689985,
          isPrimary: false
        }
      },
      userCampaignId: 'f6c7c592-e195-424b-a4bc-de84e05ced6e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T18:54:40.000Z',
        amountCents: 8562
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T17:38:14.790Z',
          createdAt: '2024-03-20T09:11:14.613Z',
          content: [
            {
              id: 'f4182b65-5434-4f00-8c16-a257a4714069',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aae83a65-d937-49ff-b693-087051201505/ugc/450c64fd-fabf-457e-896d-f515cddd9825/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3f50713c-7d49-41b3-a552-5462aee57897',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aae83a65-d937-49ff-b693-087051201505/ugc/450c64fd-fabf-457e-896d-f515cddd9825/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd2404317-90a2-45a4-b9fa-6a87f63fb6c9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aae83a65-d937-49ff-b693-087051201505/ugc/450c64fd-fabf-457e-896d-f515cddd9825/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '450c64fd-fabf-457e-896d-f515cddd9825'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T19:02:32.735Z',
          createdAt: '2024-03-28T01:22:15.871Z',
          content: [
            {
              id: '33d999f4-9f26-410b-a06a-f49c2e01cab2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aae83a65-d937-49ff-b693-087051201505/ugc/f35fa4fa-2370-4a43-b597-756fd511c8f2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f35fa4fa-2370-4a43-b597-756fd511c8f2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'aae83a65-d937-49ff-b693-087051201505',
        firstName: 'Neychea',
        lastName: 'Colvin',
        email: 'info.cheaandrea@gmail.com',
        instagramProfile: {
          handle: 'iamcheaandrea',
          followers: 16601,
          engagementRate: 1.0113848563339558,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'bossylifeempire',
          followers: 967
        }
      },
      userCampaignId: 'aae83a65-d937-49ff-b693-087051201505'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T07:16:05.000Z',
        amountCents: 7478
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-20T20:54:22.081Z',
          createdAt: '2024-03-20T05:11:14.814Z',
          content: [
            {
              id: '9e333d61-23d3-4a00-a93a-6d2e035f50ec',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ed7290e9-58b5-4190-afab-e8cf8108c1b9/ugc/a11e0bda-a09d-4e92-84f0-f86c42ec1817/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b270e248-a640-481b-852f-7f4d72854a14',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ed7290e9-58b5-4190-afab-e8cf8108c1b9/ugc/a11e0bda-a09d-4e92-84f0-f86c42ec1817/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b1119725-4bc2-4085-9aac-7c3fae9d14ad',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ed7290e9-58b5-4190-afab-e8cf8108c1b9/ugc/a11e0bda-a09d-4e92-84f0-f86c42ec1817/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a11e0bda-a09d-4e92-84f0-f86c42ec1817'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-21T18:38:48.390Z',
          createdAt: '2024-03-21T05:20:38.787Z',
          content: [
            {
              id: 'dd4b3179-3aa0-47ac-9d4d-268458e878d3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ed7290e9-58b5-4190-afab-e8cf8108c1b9/ugc/13e2b559-1643-4f8d-a1fa-da00e4c4f19e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '13e2b559-1643-4f8d-a1fa-da00e4c4f19e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ed7290e9-58b5-4190-afab-e8cf8108c1b9',
        firstName: 'Frewena',
        lastName: 'Deloria',
        email: 'frenniedeloria@gmail.com',
        instagramProfile: {
          handle: 'frewena',
          followers: 100780,
          engagementRate: 0.3143480849374876,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'frewena',
          followers: 222769,
          engagementRate: 8.264934665804729,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'frewena'
        }
      },
      userCampaignId: 'ed7290e9-58b5-4190-afab-e8cf8108c1b9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T18:26:04.000Z',
        amountCents: 8226
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.IG,
      content: [
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-19T06:10:41.725Z',
          content: [
            {
              id: 'b4093c32-f6fb-4511-b316-d8f9b09dafc3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18000203873290318/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a76ff051-9229-43f2-a5a7-4443c937398b'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-19T06:11:33.106Z',
          content: [
            {
              id: '5866bc74-0822-4d52-bd3b-3eee851dd2ae',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/18274281793206435/IMAGE.jpg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '59a6b2d6-f180-4ee6-ba33-774abeafee93'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_REEL,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-26T23:44:25.000Z',
          createdAt: '2024-03-19T05:55:28.866Z',
          content: [
            {
              id: 'fafb435c-aaac-48df-aaef-266992aaa6fc',
              caption:
                '#brandpartner @coopsleepgoods pillow review. Love that it can be adjusted based on your and your partner’s comfort level. Plus, it’s a perfect balance of softness and support The set comes with 2 pillows and extra puff.\nAvailable on @amazon Link in Bio #yousleepyou #CoopSleepGoods',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/C4_w-JFgrqB/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 1,
              likes: 3993,
              views: 30618,
              engagements: 3994
            }
          ],
          groupId: '0fd3d24d-38de-4865-8187-4a2e2fa317c0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe',
        firstName: 'Svitlana',
        lastName: 'Ato',
        email: 'svitlanaato@gmail.com',
        instagramProfile: {
          handle: 'lanaato',
          followers: 101219,
          engagementRate: 3.36102905580968,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lanaato',
          followers: 20091,
          engagementRate: 1.8603956973930895,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'lanaato3257'
        }
      },
      userCampaignId: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-12T16:06:32.000Z',
        amountCents: 7383
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-11T20:28:23.265Z',
          createdAt: '2024-04-01T18:54:25.093Z',
          content: [
            {
              id: 'b4ee8256-fbc2-4860-bb28-4541c90c246f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47071694-ba41-482a-90cf-10a561ff6e2e/ugc/bb00fa39-01da-4919-b89d-3fe6338ce1f8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b2265435-dd2b-4990-85e2-61982af7f425',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47071694-ba41-482a-90cf-10a561ff6e2e/ugc/bb00fa39-01da-4919-b89d-3fe6338ce1f8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a5642f11-c823-4d5d-bddb-28f1e95c696c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47071694-ba41-482a-90cf-10a561ff6e2e/ugc/bb00fa39-01da-4919-b89d-3fe6338ce1f8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bb00fa39-01da-4919-b89d-3fe6338ce1f8'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-11T20:28:19.651Z',
          createdAt: '2024-04-01T20:21:40.858Z',
          content: [
            {
              id: '8c89be74-6b03-43c9-bd0e-d441f7d3467a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47071694-ba41-482a-90cf-10a561ff6e2e/ugc/de5325b4-8670-44e4-a151-06fb9736e03b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'de5325b4-8670-44e4-a151-06fb9736e03b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '47071694-ba41-482a-90cf-10a561ff6e2e',
        firstName: 'Stephany',
        lastName: 'Brown',
        email: 'bbrownsteph@gmail.com',
        instagramProfile: {
          handle: 'themomfluence',
          followers: 19917,
          engagementRate: 1.0046693779183613,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'themomfluence',
          followers: 357,
          engagementRate: 8.08305985494986,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'themomfluence'
        }
      },
      userCampaignId: '47071694-ba41-482a-90cf-10a561ff6e2e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T22:15:32.000Z',
        amountCents: 8489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.TT,
      content: [
        {
          channel: Channel.TIKTOK,
          contentType: ContentType.TT_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-02T01:30:17.000Z',
          createdAt: '2024-04-01T17:20:15.070Z',
          content: [
            {
              id: 'a4fb564d-9449-4897-8d73-ce3d71d98298',
              caption:
                'Unboxing @Coop Sleep Goods queen pillow set of 2 ☁️ #brandpartner #coopsleepgoods #unboxing #haul #unboxinghaul #bedding #yousleepyou ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7353075948696145195/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 13,
              likes: 64,
              views: 3057
            }
          ],
          groupId: '6f675059-7f59-40bd-af2f-69af7fb3620c'
        },
        {
          channel: Channel.TIKTOK,
          contentType: ContentType.TT_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-05T16:24:49.000Z',
          createdAt: '2024-04-01T17:26:50.951Z',
          content: [
            {
              id: '0218f495-23fe-48fa-91a2-1039cb822a48',
              caption:
                'Let’s makeover my bed with @Coop Sleep Goods 🛏️✨ #brandpartner #coopsleepgoods #yousleepyou #morningroutine #bedding #haul #amroutine ',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/7354419532632575274/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 5,
              likes: 60,
              views: 1653
            }
          ],
          groupId: '21c61c6a-e662-4d5b-99b6-04228aa5b4d6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2f44d75d-d004-4734-b9fd-309ec55af8bd',
        firstName: 'Alyssa',
        lastName: 'Kimber',
        email: 'alyssakimberstyle@gmail.com',
        instagramProfile: {
          handle: 'alyssa_kimber',
          followers: 41347,
          engagementRate: 0.5069291605195057,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alyssakimber',
          followers: 513935,
          engagementRate: 5.194610770548379,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'alyssakimber',
          followers: 1660
        }
      },
      userCampaignId: '2f44d75d-d004-4734-b9fd-309ec55af8bd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T17:42:52.000Z',
        amountCents: 10783
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.IG,
      content: [
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-26T23:22:47.838Z',
          content: [
            {
              id: '3224b8c7-9bd1-4dc8-afdb-56daaadf649e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17984640332497152/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0d27ef29-5a8b-4376-b593-b0607db56d79'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_REEL,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T20:19:10.000Z',
          createdAt: '2024-03-26T15:39:33.746Z',
          content: [
            {
              id: '695fe2d0-8f51-409b-a8b0-b1aebacafb4b',
              caption:
                '#brandpartner @coopsleepgoods my search for the perfect pillow is over! Loving these OEKO certified Coop pillows! They are a down alternative and adjustable - I love how comfy they are no matter how I sleep! Check them out on Amazon in my stories!\n#yousleepyou #coophomegoods',
              assets: [
                {
                  url: 'https://media.productwind.com/posts/C5B-VBogpby/MEDIA_0.mp4',
                  type: 'IMAGE'
                }
              ],
              comments: 33,
              likes: 1862,
              views: 59840,
              engagements: 2004
            }
          ],
          groupId: '3a0d54c8-16a0-4656-a059-7ed6f499c64a'
        },
        {
          channel: Channel.INSTAGRAM,
          contentType: ContentType.IG_STORY,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-26T23:24:56.060Z',
          content: [
            {
              id: '4df96ccf-5dcf-4a84-80d0-4942a28d77d3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/stories/17898281843972020/VIDEO.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '26a48e70-b846-4640-b042-66bdda6ef4e6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bed50908-bd13-473d-91cf-3c9555b7382f',
        firstName: 'Jennifer',
        lastName: 'Wakumelo',
        email: 'wakumelocollabs@gmail.com',
        instagramProfile: {
          handle: 'our.blog.life',
          followers: 32744,
          engagementRate: 5.249816760322502,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'our.blog.life',
          followers: 6039,
          engagementRate: 2.564102564102564,
          isPrimary: true
        }
      },
      userCampaignId: 'bed50908-bd13-473d-91cf-3c9555b7382f'
    }
  ]
}

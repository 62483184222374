import { ZeusInterceptor } from '../types'
import { SAMPLE_PRODUCT_FEEDBACK_SUMMARY } from './sample-data'
import { SAMPLE_CAMPAIGNS } from '@momentum/api/interceptors/getCampaign/sample-data'

export const getProductFeedbackSummary: ZeusInterceptor<'query', 'getProductFeedbackSummary'> = async args => {
  const campaign = args?.o?.getProductFeedbackSummary?.[0].campaignId
    ? SAMPLE_CAMPAIGNS[args.o.getProductFeedbackSummary[0].campaignId]
    : undefined
  return campaign?.skuId ? SAMPLE_PRODUCT_FEEDBACK_SUMMARY[campaign.skuId] : undefined
}

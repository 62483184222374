import { Box, IconButton } from '@mui/material'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import 'video-react/dist/video-react.css'
import { Pause, PlayArrow } from '@mui/icons-material'

interface VideoProps {
  height?: number | string
  maxHeight?: number | string
  width?: number | string
  objectFit?: 'cover' | 'contain'
  borderRadius?: number | string
  src: string
  hoverOverlay?: boolean
  disabled?: boolean
  controls?: boolean
}

export default forwardRef(function Video({ src, hoverOverlay, disabled, controls = false, ...rest }: VideoProps, ref) {
  const [paused, setPaused] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)

  const video = useRef<HTMLVideoElement | null>(null)
  const videoRef = ref as React.MutableRefObject<HTMLVideoElement | null> || video

  useEffect(() => {
    const currentMedia = videoRef.current

    const handleFullScreenChange = () => {
      setFullScreen(document.fullscreenElement === currentMedia)
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
    }
  }, [videoRef])

  return (
    <Box
      position={'relative'}
      flex={1}
      display={'flex'}
      sx={{
        '&:hover .pause-button': {
          display: 'inline-flex'
        }
      }}
    >
      {!disabled && paused && (
        <IconButton
          sx={{
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'zIndex': 8,
            'transform': 'translate(-50%, -50%)',
            'background': 'white',
            '&:hover': {
              background: 'white'
            }
          }}
          onClick={() => videoRef?.current?.play()}
        >
          <PlayArrow color={'primary'} fontSize={'large'} />
        </IconButton>
      )}
      {!paused && (
        <IconButton
          className={'pause-button'}
          sx={{
            'display': 'none',
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'zIndex': 8,
            'transform': 'translate(-50%, -50%)',
            'background': 'white',
            '&:hover': {
              background: 'white'
            }
          }}
          onClick={() => videoRef?.current?.pause()}
        >
          <Pause color={'primary'} fontSize={'large'} />
        </IconButton>
      )}
      <video
        ref={videoRef}
        controls={controls}
        controlsList={'nodownload'}
        preload={'metadata'}
        onPlay={() => setPaused(false)}
        onPause={() => setPaused(true)}
        style={{ height: '100%', width: '100%', objectFit: fullScreen ? 'contain' : 'cover', ...rest }}
        onClick={e => {
          if (!videoRef?.current?.paused) {
            videoRef?.current?.pause()
          }
        }}
      >
        <source src={src + '#t=0.5'} type="video/mp4" />
      </video>
    </Box>
  )
})

import { Button, Paper, Stack, Typography } from '@mui/material'
import { CreatorApprovalStatus, CreatorType, Channel } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'
import { Metric } from '../../e-commerce/common/Metric'
import { useApproveCreatorsContext } from './approveCreatorsContext'
import { FileDownloadOutlined } from '@mui/icons-material'
import { CSVLink } from 'react-csv'
import { getHandleLink } from '@momentum/utils/socialUtils'
import { CREATOR_DATA_HEADERS } from '@momentum/routes/campaign/my-actions/approve-creators/utils'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'

export const ApproveCreatorsSummary = () => {
  const { campaignDetails } = useCampaignContext()
  const { creators } = useApproveCreatorsContext()

  const creatorsMappedForDownload = creators
    .filter(c => c.approvalState.status === CreatorApprovalStatus.PENDING)
    .map(c => ({
      name: `${c.creator.firstName} ${c.creator.lastName}`,
      ...c.creator,
      instagramProfileLink: getHandleLink(Channel.INSTAGRAM, c.creator),
      tiktokProfileLink: getHandleLink(Channel.TIKTOK, c.creator)
    }))

  const { approvedTT, approvedIG, approvedPremiumUGC, approvedUGC } = useMemo(() => {
    return creators.reduce(
      (acc, creator) =>
        creator.approvalState.status === CreatorApprovalStatus.APPROVED
          ? {
              approvedTT: acc.approvedTT + ~~(creator.creatorType === CreatorType.TT),
              approvedIG: acc.approvedIG + ~~(creator.creatorType === CreatorType.IG),
              approvedPremiumUGC: acc.approvedPremiumUGC + ~~(creator.creatorType === CreatorType.PREMIUM_UGC),
              approvedUGC: acc.approvedUGC + ~~(creator.creatorType === CreatorType.UGC)
            }
          : acc,
      {
        approvedTT: 0,
        approvedIG: 0,
        approvedPremiumUGC: 0,
        approvedUGC: 0
      }
    )
  }, [creators])

  return (
    <Paper sx={{ px: 4, py: 2 }}>
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Approved creators summary</Typography>
          </Stack>
          <CSVLink
            headers={CREATOR_DATA_HEADERS}
            data={creatorsMappedForDownload}
            filename={`${campaignDetails.product.name}-creators.csv`}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Button disableRipple startIcon={<FileDownloadOutlined />}>
              Download creator report
            </Button>
          </CSVLink>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-evenly'}>
          <Metric label={'Approved Tiktok social creators'} value={approvedTT ? approvedTT.toLocaleString() : '--'} />
          <Metric
            label={'Approved Instagram social creators'}
            value={approvedIG ? approvedIG.toLocaleString() : '--'}
          />
          <Metric
            label={'Approved premium UGC creators'}
            value={approvedPremiumUGC ? approvedPremiumUGC.toLocaleString() : '--'}
          />
          <Metric label={'Approved UGC creators'} value={approvedUGC ? approvedUGC.toLocaleString() : '--'} />
        </Stack>
      </Stack>
    </Paper>
  )
}

import { CampaignPerformance } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { PerformanceComparisonMetric, PerformanceSummaryTitleType } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_CAMPAIGN_PERFORMANCE_WATER_FLOSSER_PRIME_DAY: CampaignPerformance = {
  campaignId: 'sample-campaign-water-flosser-prime-day',
  skuId: 'sample-sku-water-flosser',
  promotedProductId: 'd4618714-32ca-422b-b49a-6fd5884d6c19',
  isVisible: false,
  comparisonMetric: PerformanceComparisonMetric.REVIEWS,
  comparisonMetricValue: 2000,
  summaryTitle: PerformanceSummaryTitleType.SIMILAR_PRODUCT,
  products: [
    {
      id: '2e337eee-ceca-4e4a-b14b-f01dfaf9948d',
      productName: 'Cordless Water Flosser',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-2.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2022-03-23',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-24',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-25',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-26',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-27',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-28',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-29',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-30',
          reviewCount: 1,
          rating: 4
        },
        {
          date: '2022-03-31',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-01',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-02',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-03',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-04',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-05',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-06',
          reviewCount: 3,
          rating: 4
        },
        {
          date: '2022-04-07',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-08',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-09',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-10',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-11',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-12',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-13',
          reviewCount: 21,
          rating: 4
        },
        {
          date: '2022-04-14',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-15',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-16',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-17',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-18',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-19',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-20',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-21',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-04-22',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-23',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-24',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-25',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-26',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-27',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-28',
          reviewCount: 34,
          rating: 4
        },
        {
          date: '2022-04-29',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-04-30',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-01',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-02',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-03',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-04',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-05',
          reviewCount: 40,
          rating: 4
        },
        {
          date: '2022-05-06',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-07',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-08',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-09',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-10',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-11',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-12',
          reviewCount: 44,
          rating: 4
        },
        {
          date: '2022-05-13',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-14',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-15',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-16',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-17',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-18',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-19',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-20',
          reviewCount: 57,
          rating: 4
        },
        {
          date: '2022-05-21',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-22',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-23',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-24',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-25',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-26',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-27',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-28',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-29',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-30',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2022-05-31',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-01',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-02',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-03',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-04',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-05',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-06',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-07',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-08',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-09',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-06-10',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-11',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-12',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-13',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-14',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-15',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-16',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-17',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-18',
          reviewCount: 142,
          rating: 4
        },
        {
          date: '2022-06-19',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-20',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-21',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-22',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-23',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-24',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-25',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-26',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-27',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-28',
          reviewCount: 173,
          rating: 4
        },
        {
          date: '2022-06-29',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-06-30',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-01',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-02',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-03',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-04',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-05',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-06',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-07',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-08',
          reviewCount: 216,
          rating: 4
        },
        {
          date: '2022-07-09',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-10',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-11',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-12',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-13',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-14',
          reviewCount: 282,
          rating: 4
        },
        {
          date: '2022-07-15',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-16',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-17',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-18',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-19',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-20',
          reviewCount: 305,
          rating: 4
        },
        {
          date: '2022-07-21',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-22',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-23',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-24',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-25',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-26',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-27',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-28',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-29',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-30',
          reviewCount: 355,
          rating: 4
        },
        {
          date: '2022-07-31',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-01',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-02',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-03',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-04',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-05',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-06',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-07',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-08',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-09',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-10',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-11',
          reviewCount: 494,
          rating: 4
        },
        {
          date: '2022-08-12',
          reviewCount: 684,
          rating: 4
        },
        {
          date: '2022-08-13',
          reviewCount: 709,
          rating: 4
        },
        {
          date: '2022-08-14',
          reviewCount: 730,
          rating: 4
        },
        {
          date: '2022-08-15',
          reviewCount: 747,
          rating: 4
        },
        {
          date: '2022-08-16',
          reviewCount: 766,
          rating: 4
        },
        {
          date: '2022-08-17',
          reviewCount: 788,
          rating: 4
        },
        {
          date: '2022-08-18',
          reviewCount: 808,
          rating: 4
        },
        {
          date: '2022-08-19',
          reviewCount: 831,
          rating: 4
        },
        {
          date: '2022-08-20',
          reviewCount: 857,
          rating: 4
        },
        {
          date: '2022-08-21',
          reviewCount: 871,
          rating: 4
        },
        {
          date: '2022-08-22',
          reviewCount: 924,
          rating: 4
        },
        {
          date: '2022-08-23',
          reviewCount: 948,
          rating: 4
        },
        {
          date: '2022-08-24',
          reviewCount: 971,
          rating: 4
        },
        {
          date: '2022-08-25',
          reviewCount: 992,
          rating: 4
        },
        {
          date: '2022-08-26',
          reviewCount: 1012,
          rating: 4
        },
        {
          date: '2022-08-27',
          reviewCount: 1036,
          rating: 4
        },
        {
          date: '2022-08-28',
          reviewCount: 1055,
          rating: 4
        },
        {
          date: '2022-08-29',
          reviewCount: 1073,
          rating: 4
        },
        {
          date: '2022-08-30',
          reviewCount: 1097,
          rating: 4
        },
        {
          date: '2022-08-31',
          reviewCount: 1110,
          rating: 4
        },
        {
          date: '2022-09-01',
          reviewCount: 1125,
          rating: 4
        },
        {
          date: '2022-09-02',
          reviewCount: 1146,
          rating: 4
        },
        {
          date: '2022-09-03',
          reviewCount: 1166,
          rating: 4
        },
        {
          date: '2022-09-04',
          reviewCount: 1185,
          rating: 4
        },
        {
          date: '2022-09-05',
          reviewCount: 1199,
          rating: 4
        },
        {
          date: '2022-09-06',
          reviewCount: 1214,
          rating: 4
        },
        {
          date: '2022-09-07',
          reviewCount: 1235,
          rating: 4
        },
        {
          date: '2022-09-08',
          reviewCount: 1235,
          rating: 4
        },
        {
          date: '2022-09-09',
          reviewCount: 1263,
          rating: 4
        },
        {
          date: '2022-09-10',
          reviewCount: 1287,
          rating: 4
        },
        {
          date: '2022-09-11',
          reviewCount: 1300,
          rating: 4
        },
        {
          date: '2022-09-12',
          reviewCount: 1321,
          rating: 4
        },
        {
          date: '2022-09-13',
          reviewCount: 1336,
          rating: 4
        },
        {
          date: '2022-09-14',
          reviewCount: 1353,
          rating: 4
        },
        {
          date: '2022-09-15',
          reviewCount: 1353,
          rating: 4
        },
        {
          date: '2022-09-16',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-17',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-18',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-19',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-20',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-21',
          reviewCount: 1380,
          rating: 4
        },
        {
          date: '2022-09-22',
          reviewCount: 1521,
          rating: 4
        },
        {
          date: '2022-09-23',
          reviewCount: 1538,
          rating: 4
        },
        {
          date: '2022-09-24',
          reviewCount: 1552,
          rating: 4
        },
        {
          date: '2022-09-25',
          reviewCount: 1575,
          rating: 4
        },
        {
          date: '2022-09-26',
          reviewCount: 1594,
          rating: 4
        },
        {
          date: '2022-09-27',
          reviewCount: 1618,
          rating: 4
        },
        {
          date: '2022-09-28',
          reviewCount: 1631,
          rating: 4
        },
        {
          date: '2022-09-29',
          reviewCount: 1650,
          rating: 4
        },
        {
          date: '2022-09-30',
          reviewCount: 1671,
          rating: 4
        },
        {
          date: '2022-10-01',
          reviewCount: 1685,
          rating: 4
        },
        {
          date: '2022-10-02',
          reviewCount: 1696,
          rating: 4
        },
        {
          date: '2022-10-03',
          reviewCount: 1739,
          rating: 4
        },
        {
          date: '2022-10-04',
          reviewCount: 1740,
          rating: 4
        },
        {
          date: '2022-10-05',
          reviewCount: 1772,
          rating: 4
        },
        {
          date: '2022-10-06',
          reviewCount: 1772,
          rating: 4
        },
        {
          date: '2022-10-07',
          reviewCount: 1806,
          rating: 4
        },
        {
          date: '2022-10-08',
          reviewCount: 1823,
          rating: 4
        },
        {
          date: '2022-10-09',
          reviewCount: 1840,
          rating: 4
        },
        {
          date: '2022-10-10',
          reviewCount: 1855,
          rating: 4
        },
        {
          date: '2022-10-11',
          reviewCount: 1873,
          rating: 4
        },
        {
          date: '2022-10-12',
          reviewCount: 1886,
          rating: 4
        },
        {
          date: '2022-10-13',
          reviewCount: 1895,
          rating: 4
        },
        {
          date: '2022-10-14',
          reviewCount: 1907,
          rating: 4
        },
        {
          date: '2022-10-15',
          reviewCount: 1922,
          rating: 4
        },
        {
          date: '2022-10-16',
          reviewCount: 1938,
          rating: 4
        },
        {
          date: '2022-10-17',
          reviewCount: 1961,
          rating: 4
        },
        {
          date: '2022-10-18',
          reviewCount: 1969,
          rating: 4
        },
        {
          date: '2022-10-19',
          reviewCount: 1989,
          rating: 4
        },
        {
          date: '2022-10-20',
          reviewCount: 2013,
          rating: 4
        },
        {
          date: '2022-10-21',
          reviewCount: 2025,
          rating: 4
        },
        {
          date: '2022-10-22',
          reviewCount: 2037,
          rating: 4
        },
        {
          date: '2022-10-23',
          reviewCount: 2053,
          rating: 4
        },
        {
          date: '2022-10-24',
          reviewCount: 2071,
          rating: 4
        },
        {
          date: '2022-10-25',
          reviewCount: 2083,
          rating: 4
        },
        {
          date: '2022-10-26',
          reviewCount: 2093,
          rating: 4
        },
        {
          date: '2022-10-27',
          reviewCount: 2111,
          rating: 4
        },
        {
          date: '2022-10-28',
          reviewCount: 2111,
          rating: 4
        },
        {
          date: '2022-10-29',
          reviewCount: 2111,
          rating: 4
        },
        {
          date: '2022-10-30',
          reviewCount: 2150,
          rating: 4
        },
        {
          date: '2022-10-31',
          reviewCount: 2150,
          rating: 4
        },
        {
          date: '2022-11-01',
          reviewCount: 2150,
          rating: 4
        },
        {
          date: '2022-11-02',
          reviewCount: 2150,
          rating: 4
        },
        {
          date: '2022-11-03',
          reviewCount: 2216,
          rating: 4
        },
        {
          date: '2022-11-04',
          reviewCount: 2225,
          rating: 4
        },
        {
          date: '2022-11-05',
          reviewCount: 2236,
          rating: 4
        },
        {
          date: '2022-11-06',
          reviewCount: 2249,
          rating: 4
        },
        {
          date: '2022-11-07',
          reviewCount: 2255,
          rating: 4
        },
        {
          date: '2022-11-08',
          reviewCount: 2271,
          rating: 4
        },
        {
          date: '2022-11-09',
          reviewCount: 2284,
          rating: 4
        },
        {
          date: '2022-11-10',
          reviewCount: 2301,
          rating: 4
        },
        {
          date: '2022-11-11',
          reviewCount: 2318,
          rating: 4
        },
        {
          date: '2022-11-12',
          reviewCount: 2333,
          rating: 4
        },
        {
          date: '2022-11-13',
          reviewCount: 2343,
          rating: 4
        },
        {
          date: '2022-11-14',
          reviewCount: 2354,
          rating: 4
        },
        {
          date: '2022-11-15',
          reviewCount: 2375,
          rating: 4
        },
        {
          date: '2022-11-16',
          reviewCount: 2398,
          rating: 4
        },
        {
          date: '2022-11-17',
          reviewCount: 2409,
          rating: 4
        },
        {
          date: '2022-11-18',
          reviewCount: 2421,
          rating: 4
        },
        {
          date: '2022-11-19',
          reviewCount: 2433,
          rating: 4
        },
        {
          date: '2022-11-20',
          reviewCount: 2439,
          rating: 4
        },
        {
          date: '2022-11-21',
          reviewCount: 2451,
          rating: 4
        },
        {
          date: '2022-11-22',
          reviewCount: 2466,
          rating: 4
        },
        {
          date: '2022-11-23',
          reviewCount: 2481,
          rating: 4
        },
        {
          date: '2022-11-24',
          reviewCount: 2506,
          rating: 4
        },
        {
          date: '2022-11-25',
          reviewCount: 2520,
          rating: 4
        },
        {
          date: '2022-11-26',
          reviewCount: 2527,
          rating: 4
        },
        {
          date: '2022-11-27',
          reviewCount: 2527,
          rating: 4
        },
        {
          date: '2022-11-28',
          reviewCount: 2491,
          rating: 4
        },
        {
          date: '2022-11-29',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-11-30',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-01',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-02',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-03',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-04',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-05',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-06',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-07',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-08',
          reviewCount: 2498,
          rating: 4
        },
        {
          date: '2022-12-09',
          reviewCount: 2745,
          rating: 4
        },
        {
          date: '2022-12-10',
          reviewCount: 2745,
          rating: 4
        },
        {
          date: '2022-12-11',
          reviewCount: 2745,
          rating: 4
        },
        {
          date: '2022-12-12',
          reviewCount: 2745,
          rating: 4
        },
        {
          date: '2022-12-13',
          reviewCount: 2745,
          rating: 4
        },
        {
          date: '2022-12-14',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-15',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-16',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-17',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-18',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-19',
          reviewCount: 2846,
          rating: 4
        },
        {
          date: '2022-12-20',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-21',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-22',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-23',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-24',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-25',
          reviewCount: 2942,
          rating: 4
        },
        {
          date: '2022-12-26',
          reviewCount: 3057,
          rating: 4
        },
        {
          date: '2022-12-27',
          reviewCount: 3057,
          rating: 4
        },
        {
          date: '2022-12-28',
          reviewCount: 3057,
          rating: 4
        },
        {
          date: '2022-12-29',
          reviewCount: 3057,
          rating: 4
        },
        {
          date: '2022-12-30',
          reviewCount: 3114,
          rating: 4
        },
        {
          date: '2022-12-31',
          reviewCount: 3114,
          rating: 4
        },
        {
          date: '2023-01-01',
          reviewCount: 3114,
          rating: 4
        },
        {
          date: '2023-01-02',
          reviewCount: 3157,
          rating: 4
        },
        {
          date: '2023-01-03',
          reviewCount: 3157,
          rating: 4
        },
        {
          date: '2023-01-04',
          reviewCount: 3157,
          rating: 4
        },
        {
          date: '2023-01-05',
          reviewCount: 3157,
          rating: 4
        },
        {
          date: '2023-01-06',
          reviewCount: 3221,
          rating: 4
        },
        {
          date: '2023-01-07',
          reviewCount: 3221,
          rating: 4
        },
        {
          date: '2023-01-08',
          reviewCount: 3221,
          rating: 4
        },
        {
          date: '2023-01-09',
          reviewCount: 3361,
          rating: 4
        },
        {
          date: '2023-01-10',
          reviewCount: 3361,
          rating: 4
        },
        {
          date: '2023-01-11',
          reviewCount: 3361,
          rating: 4
        },
        {
          date: '2023-01-12',
          reviewCount: 3361,
          rating: 4
        },
        {
          date: '2023-01-13',
          reviewCount: 3446,
          rating: 4
        },
        {
          date: '2023-01-14',
          reviewCount: 3446,
          rating: 4
        },
        {
          date: '2023-01-15',
          reviewCount: 3446,
          rating: 4
        },
        {
          date: '2023-01-16',
          reviewCount: 3446,
          rating: 4
        },
        {
          date: '2023-01-17',
          reviewCount: 3546,
          rating: 4
        },
        {
          date: '2023-01-18',
          reviewCount: 3546,
          rating: 4
        },
        {
          date: '2023-01-19',
          reviewCount: 3546,
          rating: 4
        },
        {
          date: '2023-01-20',
          reviewCount: 3546,
          rating: 4
        },
        {
          date: '2023-01-21',
          reviewCount: 3654,
          rating: 4
        },
        {
          date: '2023-01-22',
          reviewCount: 3654,
          rating: 4
        },
        {
          date: '2023-01-23',
          reviewCount: 3654,
          rating: 4
        },
        {
          date: '2023-01-24',
          reviewCount: 3654,
          rating: 4
        },
        {
          date: '2023-01-25',
          reviewCount: 3767,
          rating: 4
        },
        {
          date: '2023-01-26',
          reviewCount: 3767,
          rating: 4
        },
        {
          date: '2023-01-27',
          reviewCount: 3767,
          rating: 4
        },
        {
          date: '2023-01-28',
          reviewCount: 3767,
          rating: 4
        },
        {
          date: '2023-01-29',
          reviewCount: 3830,
          rating: 4
        },
        {
          date: '2023-01-30',
          reviewCount: 3830,
          rating: 4
        },
        {
          date: '2023-01-31',
          reviewCount: 3830,
          rating: 4
        },
        {
          date: '2023-02-01',
          reviewCount: 3830,
          rating: 4
        },
        {
          date: '2023-02-02',
          reviewCount: 3896,
          rating: 4
        },
        {
          date: '2023-02-03',
          reviewCount: 3896,
          rating: 4
        },
        {
          date: '2023-02-04',
          reviewCount: 3896,
          rating: 4
        },
        {
          date: '2023-02-05',
          reviewCount: 3936,
          rating: 4
        },
        {
          date: '2023-02-06',
          reviewCount: 3936,
          rating: 4
        },
        {
          date: '2023-02-07',
          reviewCount: 3936,
          rating: 4
        },
        {
          date: '2023-02-08',
          reviewCount: 3936,
          rating: 4
        },
        {
          date: '2023-02-09',
          reviewCount: 3994,
          rating: 4
        },
        {
          date: '2023-02-10',
          reviewCount: 3994,
          rating: 4
        },
        {
          date: '2023-02-11',
          reviewCount: 3994,
          rating: 4
        },
        {
          date: '2023-02-12',
          reviewCount: 3994,
          rating: 4
        },
        {
          date: '2023-02-13',
          reviewCount: 3595,
          rating: 4
        },
        {
          date: '2023-02-14',
          reviewCount: 3595,
          rating: 4
        },
        {
          date: '2023-02-15',
          reviewCount: 3595,
          rating: 4
        },
        {
          date: '2023-02-16',
          reviewCount: 3659,
          rating: 4
        },
        {
          date: '2023-02-17',
          reviewCount: 3659,
          rating: 4
        },
        {
          date: '2023-02-18',
          reviewCount: 3659,
          rating: 4
        },
        {
          date: '2023-02-19',
          reviewCount: 3659,
          rating: 4
        },
        {
          date: '2023-02-20',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-21',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-22',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-23',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-24',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-25',
          reviewCount: 3753,
          rating: 4
        },
        {
          date: '2023-02-26',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-02-27',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-02-28',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-03-01',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-03-02',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-03-03',
          reviewCount: 3693,
          rating: 4
        },
        {
          date: '2023-03-04',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-05',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-06',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-07',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-08',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-09',
          reviewCount: 3989,
          rating: 4
        },
        {
          date: '2023-03-10',
          reviewCount: 4089,
          rating: 4
        },
        {
          date: '2023-03-11',
          reviewCount: 4089,
          rating: 4
        },
        {
          date: '2023-03-12',
          reviewCount: 4089,
          rating: 4
        },
        {
          date: '2023-03-13',
          reviewCount: 4089,
          rating: 4
        },
        {
          date: '2023-03-14',
          reviewCount: 4089,
          rating: 4
        },
        {
          date: '2023-03-15',
          reviewCount: 4240,
          rating: 4
        },
        {
          date: '2023-03-16',
          reviewCount: 4240,
          rating: 4
        },
        {
          date: '2023-03-17',
          reviewCount: 4240,
          rating: 4
        },
        {
          date: '2023-03-18',
          reviewCount: 4240,
          rating: 4
        },
        {
          date: '2023-03-19',
          reviewCount: 4240,
          rating: 4
        },
        {
          date: '2023-03-20',
          reviewCount: 4357,
          rating: 4
        },
        {
          date: '2023-03-21',
          reviewCount: 4357,
          rating: 4
        },
        {
          date: '2023-03-22',
          reviewCount: 4357,
          rating: 4
        },
        {
          date: '2023-03-23',
          reviewCount: 4432,
          rating: 4
        },
        {
          date: '2023-03-24',
          reviewCount: 4432,
          rating: 4
        },
        {
          date: '2023-03-25',
          reviewCount: 4432,
          rating: 4
        },
        {
          date: '2023-03-26',
          reviewCount: 4432,
          rating: 4
        },
        {
          date: '2023-03-27',
          reviewCount: 4302,
          rating: 4
        },
        {
          date: '2023-03-28',
          reviewCount: 4302,
          rating: 4
        },
        {
          date: '2023-03-29',
          reviewCount: 4302,
          rating: 4
        },
        {
          date: '2023-03-30',
          reviewCount: 4573,
          rating: 4
        },
        {
          date: '2023-03-31',
          reviewCount: 4573,
          rating: 4
        },
        {
          date: '2023-04-01',
          reviewCount: 4573,
          rating: 4
        },
        {
          date: '2023-04-02',
          reviewCount: 4573,
          rating: 4
        },
        {
          date: '2023-04-03',
          reviewCount: 4657,
          rating: 4
        },
        {
          date: '2023-04-04',
          reviewCount: 4657,
          rating: 4
        },
        {
          date: '2023-04-05',
          reviewCount: 4657,
          rating: 4
        },
        {
          date: '2023-04-06',
          reviewCount: 4732,
          rating: 4
        },
        {
          date: '2023-04-07',
          reviewCount: 4732,
          rating: 4
        },
        {
          date: '2023-04-08',
          reviewCount: 4732,
          rating: 4
        },
        {
          date: '2023-04-09',
          reviewCount: 4732,
          rating: 4
        },
        {
          date: '2023-04-10',
          reviewCount: 4812,
          rating: 4
        },
        {
          date: '2023-04-11',
          reviewCount: 4812,
          rating: 4
        },
        {
          date: '2023-04-12',
          reviewCount: 4812,
          rating: 4
        },
        {
          date: '2023-04-13',
          reviewCount: 4812,
          rating: 4
        },
        {
          date: '2023-04-14',
          reviewCount: 4916,
          rating: 4
        },
        {
          date: '2023-04-15',
          reviewCount: 4916,
          rating: 4
        },
        {
          date: '2023-04-16',
          reviewCount: 4916,
          rating: 4
        },
        {
          date: '2023-04-17',
          reviewCount: 4972,
          rating: 4
        },
        {
          date: '2023-04-18',
          reviewCount: 4972,
          rating: 4
        },
        {
          date: '2023-04-19',
          reviewCount: 4972,
          rating: 4
        },
        {
          date: '2023-04-20',
          reviewCount: 5035,
          rating: 4
        },
        {
          date: '2023-04-21',
          reviewCount: 5035,
          rating: 4
        },
        {
          date: '2023-04-22',
          reviewCount: 5035,
          rating: 4
        },
        {
          date: '2023-04-23',
          reviewCount: 5035,
          rating: 4
        },
        {
          date: '2023-04-24',
          reviewCount: 5112,
          rating: 4
        },
        {
          date: '2023-04-25',
          reviewCount: 5112,
          rating: 4
        },
        {
          date: '2023-04-26',
          reviewCount: 5112,
          rating: 4
        },
        {
          date: '2023-04-27',
          reviewCount: 5112,
          rating: 4
        },
        {
          date: '2023-04-28',
          reviewCount: 5186,
          rating: 4
        },
        {
          date: '2023-04-29',
          reviewCount: 5186,
          rating: 4
        },
        {
          date: '2023-04-30',
          reviewCount: 5186,
          rating: 4
        },
        {
          date: '2023-05-01',
          reviewCount: 5186,
          rating: 4
        },
        {
          date: '2023-05-02',
          reviewCount: 5260,
          rating: 4
        },
        {
          date: '2023-05-03',
          reviewCount: 5260,
          rating: 4
        },
        {
          date: '2023-05-04',
          reviewCount: 5260,
          rating: 4
        },
        {
          date: '2023-05-05',
          reviewCount: 5260,
          rating: 4
        },
        {
          date: '2023-05-06',
          reviewCount: 5337,
          rating: 4
        },
        {
          date: '2023-05-07',
          reviewCount: 5337,
          rating: 4
        },
        {
          date: '2023-05-08',
          reviewCount: 5337,
          rating: 4
        },
        {
          date: '2023-05-09',
          reviewCount: 5111,
          rating: 4
        },
        {
          date: '2023-05-10',
          reviewCount: 5111,
          rating: 4
        },
        {
          date: '2023-05-11',
          reviewCount: 5111,
          rating: 4
        },
        {
          date: '2023-05-12',
          reviewCount: 5111,
          rating: 4
        },
        {
          date: '2023-05-13',
          reviewCount: 5174,
          rating: 4
        },
        {
          date: '2023-05-14',
          reviewCount: 5174,
          rating: 4
        },
        {
          date: '2023-05-15',
          reviewCount: 5174,
          rating: 4
        },
        {
          date: '2023-05-16',
          reviewCount: 5231,
          rating: 4
        },
        {
          date: '2023-05-17',
          reviewCount: 5231,
          rating: 4
        },
        {
          date: '2023-05-18',
          reviewCount: 5231,
          rating: 4
        },
        {
          date: '2023-05-19',
          reviewCount: 5231,
          rating: 4
        },
        {
          date: '2023-05-20',
          reviewCount: 5312,
          rating: 4
        },
        {
          date: '2023-05-21',
          reviewCount: 5312,
          rating: 4
        },
        {
          date: '2023-05-22',
          reviewCount: 5312,
          rating: 4
        },
        {
          date: '2023-05-23',
          reviewCount: 5714,
          rating: 4
        },
        {
          date: '2023-05-24',
          reviewCount: 5714,
          rating: 4
        },
        {
          date: '2023-05-25',
          reviewCount: 5714,
          rating: 4
        },
        {
          date: '2023-05-26',
          reviewCount: 5769,
          rating: 4
        },
        {
          date: '2023-05-27',
          reviewCount: 5769,
          rating: 4
        },
        {
          date: '2023-05-28',
          reviewCount: 5769,
          rating: 4
        },
        {
          date: '2023-05-29',
          reviewCount: 5769,
          rating: 4
        },
        {
          date: '2023-05-30',
          reviewCount: 5839,
          rating: 4
        },
        {
          date: '2023-05-31',
          reviewCount: 5839,
          rating: 4
        },
        {
          date: '2023-06-01',
          reviewCount: 5839,
          rating: 4
        },
        {
          date: '2023-06-02',
          reviewCount: 5839,
          rating: 4
        },
        {
          date: '2023-06-03',
          reviewCount: 5894,
          rating: 4
        },
        {
          date: '2023-06-04',
          reviewCount: 5894,
          rating: 4
        },
        {
          date: '2023-06-05',
          reviewCount: 5894,
          rating: 4
        },
        {
          date: '2023-06-06',
          reviewCount: 5949,
          rating: 4
        },
        {
          date: '2023-06-07',
          reviewCount: 5949,
          rating: 4
        },
        {
          date: '2023-06-08',
          reviewCount: 5949,
          rating: 4
        },
        {
          date: '2023-06-09',
          reviewCount: 5999,
          rating: 4
        },
        {
          date: '2023-06-10',
          reviewCount: 5999,
          rating: 4
        },
        {
          date: '2023-06-11',
          reviewCount: 5999,
          rating: 4
        },
        {
          date: '2023-06-12',
          reviewCount: 5999,
          rating: 4
        },
        {
          date: '2023-06-13',
          reviewCount: 6052,
          rating: 4
        },
        {
          date: '2023-06-14',
          reviewCount: 6052,
          rating: 4
        },
        {
          date: '2023-06-15',
          reviewCount: 6052,
          rating: 4
        },
        {
          date: '2023-06-16',
          reviewCount: 6112,
          rating: 4
        },
        {
          date: '2023-06-17',
          reviewCount: 6112,
          rating: 4
        },
        {
          date: '2023-06-18',
          reviewCount: 6112,
          rating: 4
        },
        {
          date: '2023-06-19',
          reviewCount: 6112,
          rating: 4
        },
        {
          date: '2023-06-20',
          reviewCount: 6183,
          rating: 4
        },
        {
          date: '2023-06-21',
          reviewCount: 6183,
          rating: 4
        },
        {
          date: '2023-06-22',
          reviewCount: 6183,
          rating: 4
        },
        {
          date: '2023-06-23',
          reviewCount: 6183,
          rating: 4
        },
        {
          date: '2023-06-24',
          reviewCount: 6257,
          rating: 4
        },
        {
          date: '2023-06-25',
          reviewCount: 6257,
          rating: 4
        },
        {
          date: '2023-06-26',
          reviewCount: 6257,
          rating: 4
        },
        {
          date: '2023-06-27',
          reviewCount: 6318,
          rating: 4
        },
        {
          date: '2023-06-28',
          reviewCount: 6318,
          rating: 4
        },
        {
          date: '2023-06-29',
          reviewCount: 6318,
          rating: 4
        },
        {
          date: '2023-06-30',
          reviewCount: 6372,
          rating: 4
        },
        {
          date: '2023-07-01',
          reviewCount: 6372,
          rating: 4
        },
        {
          date: '2023-07-02',
          reviewCount: 6372,
          rating: 4
        },
        {
          date: '2023-07-03',
          reviewCount: 6372,
          rating: 4
        },
        {
          date: '2023-07-04',
          reviewCount: 6449,
          rating: 4
        },
        {
          date: '2023-07-05',
          reviewCount: 6449,
          rating: 4
        },
        {
          date: '2023-07-06',
          reviewCount: 6449,
          rating: 4
        },
        {
          date: '2023-07-07',
          reviewCount: 6506,
          rating: 4
        },
        {
          date: '2023-07-08',
          reviewCount: 6506,
          rating: 4
        },
        {
          date: '2023-07-09',
          reviewCount: 6506,
          rating: 4
        },
        {
          date: '2023-07-10',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-11',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-12',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-13',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-14',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-15',
          reviewCount: 6569,
          rating: 4
        },
        {
          date: '2023-07-16',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-17',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-18',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-19',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-20',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-21',
          reviewCount: 6692,
          rating: 4
        },
        {
          date: '2023-07-22',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-23',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-24',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-25',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-26',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-27',
          reviewCount: 6860,
          rating: 4
        },
        {
          date: '2023-07-28',
          reviewCount: 7033,
          rating: 4
        },
        {
          date: '2023-07-29',
          reviewCount: 7033,
          rating: 4
        },
        {
          date: '2023-07-30',
          reviewCount: 7033,
          rating: 4
        },
        {
          date: '2023-07-31',
          reviewCount: 7117,
          rating: 4
        },
        {
          date: '2023-08-01',
          reviewCount: 7117,
          rating: 4
        },
        {
          date: '2023-08-02',
          reviewCount: 7117,
          rating: 4
        },
        {
          date: '2023-08-03',
          reviewCount: 7117,
          rating: 4
        },
        {
          date: '2023-08-04',
          reviewCount: 7235,
          rating: 4
        },
        {
          date: '2023-08-05',
          reviewCount: 7235,
          rating: 4
        },
        {
          date: '2023-08-06',
          reviewCount: 7235,
          rating: 4
        },
        {
          date: '2023-08-07',
          reviewCount: 7313,
          rating: 4
        },
        {
          date: '2023-08-08',
          reviewCount: 7313,
          rating: 4
        },
        {
          date: '2023-08-09',
          reviewCount: 7313,
          rating: 4
        },
        {
          date: '2023-08-10',
          reviewCount: 7407,
          rating: 4
        },
        {
          date: '2023-08-11',
          reviewCount: 7407,
          rating: 4
        },
        {
          date: '2023-08-12',
          reviewCount: 7407,
          rating: 4
        },
        {
          date: '2023-08-13',
          reviewCount: 7407,
          rating: 4
        },
        {
          date: '2023-08-14',
          reviewCount: 7512,
          rating: 4
        },
        {
          date: '2023-08-15',
          reviewCount: 7512,
          rating: 4
        },
        {
          date: '2023-08-16',
          reviewCount: 7512,
          rating: 4
        },
        {
          date: '2023-08-17',
          reviewCount: 7608,
          rating: 4
        },
        {
          date: '2023-08-18',
          reviewCount: 7608,
          rating: 4
        },
        {
          date: '2023-08-19',
          reviewCount: 7608,
          rating: 4
        },
        {
          date: '2023-08-20',
          reviewCount: 7608,
          rating: 4
        },
        {
          date: '2023-08-21',
          reviewCount: 7712,
          rating: 4
        },
        {
          date: '2023-08-22',
          reviewCount: 7712,
          rating: 4
        },
        {
          date: '2023-08-23',
          reviewCount: 7712,
          rating: 4
        },
        {
          date: '2023-08-24',
          reviewCount: 7712,
          rating: 4
        },
        {
          date: '2023-08-25',
          reviewCount: 7821,
          rating: 4
        },
        {
          date: '2023-08-26',
          reviewCount: 7821,
          rating: 4
        },
        {
          date: '2023-08-27',
          reviewCount: 7821,
          rating: 4
        },
        {
          date: '2023-08-28',
          reviewCount: 7604,
          rating: 4
        },
        {
          date: '2023-08-29',
          reviewCount: 7604,
          rating: 4
        },
        {
          date: '2023-08-30',
          reviewCount: 7604,
          rating: 4
        },
        {
          date: '2023-08-31',
          reviewCount: 7664,
          rating: 4
        },
        {
          date: '2023-09-01',
          reviewCount: 7664,
          rating: 4
        },
        {
          date: '2023-09-02',
          reviewCount: 7664,
          rating: 4
        },
        {
          date: '2023-09-03',
          reviewCount: 7729,
          rating: 4
        },
        {
          date: '2023-09-04',
          reviewCount: 7729,
          rating: 4
        },
        {
          date: '2023-09-05',
          reviewCount: 7729,
          rating: 4
        },
        {
          date: '2023-09-06',
          reviewCount: 7729,
          rating: 4
        },
        {
          date: '2023-09-07',
          reviewCount: 7832,
          rating: 4
        },
        {
          date: '2023-09-08',
          reviewCount: 7832,
          rating: 4
        },
        {
          date: '2023-09-09',
          reviewCount: 7832,
          rating: 4
        },
        {
          date: '2023-09-10',
          reviewCount: 7832,
          rating: 4
        },
        {
          date: '2023-09-11',
          reviewCount: 7931,
          rating: 4
        },
        {
          date: '2023-09-12',
          reviewCount: 7931,
          rating: 4
        },
        {
          date: '2023-09-13',
          reviewCount: 7931,
          rating: 4
        },
        {
          date: '2023-09-14',
          reviewCount: 7931,
          rating: 4
        },
        {
          date: '2023-09-15',
          reviewCount: 8034,
          rating: 4
        },
        {
          date: '2023-09-16',
          reviewCount: 8034,
          rating: 4
        },
        {
          date: '2023-09-17',
          reviewCount: 8034,
          rating: 4
        },
        {
          date: '2023-09-18',
          reviewCount: 8422,
          rating: 4
        },
        {
          date: '2023-09-19',
          reviewCount: 8422,
          rating: 4
        },
        {
          date: '2023-09-20',
          reviewCount: 8422,
          rating: 4
        },
        {
          date: '2023-09-21',
          reviewCount: 8422,
          rating: 4
        },
        {
          date: '2023-09-22',
          reviewCount: 8521,
          rating: 4
        },
        {
          date: '2023-09-23',
          reviewCount: 8521,
          rating: 4
        },
        {
          date: '2023-09-24',
          reviewCount: 8521,
          rating: 4
        },
        {
          date: '2023-09-25',
          reviewCount: 8521,
          rating: 4
        },
        {
          date: '2023-09-26',
          reviewCount: 8624,
          rating: 4
        },
        {
          date: '2023-09-27',
          reviewCount: 8624,
          rating: 4
        },
        {
          date: '2023-09-28',
          reviewCount: 8624,
          rating: 4
        },
        {
          date: '2023-09-29',
          reviewCount: 8624,
          rating: 4
        },
        {
          date: '2023-09-30',
          reviewCount: 8738,
          rating: 4
        },
        {
          date: '2023-10-01',
          reviewCount: 8738,
          rating: 4
        },
        {
          date: '2023-10-02',
          reviewCount: 8738,
          rating: 4
        },
        {
          date: '2023-10-03',
          reviewCount: 8810,
          rating: 4
        },
        {
          date: '2023-10-04',
          reviewCount: 8810,
          rating: 4
        },
        {
          date: '2023-10-05',
          reviewCount: 8810,
          rating: 4
        },
        {
          date: '2023-10-06',
          reviewCount: 7768,
          rating: 4
        },
        {
          date: '2023-10-07',
          reviewCount: 7768,
          rating: 4
        },
        {
          date: '2023-10-08',
          reviewCount: 7768,
          rating: 4
        },
        {
          date: '2023-10-09',
          reviewCount: 7768,
          rating: 4
        },
        {
          date: '2023-10-10',
          reviewCount: 8983,
          rating: 4
        },
        {
          date: '2023-10-11',
          reviewCount: 8983,
          rating: 4
        },
        {
          date: '2023-10-12',
          reviewCount: 8983,
          rating: 4
        },
        {
          date: '2023-10-13',
          reviewCount: 9053,
          rating: 4
        },
        {
          date: '2023-10-14',
          reviewCount: 9053,
          rating: 4
        },
        {
          date: '2023-10-15',
          reviewCount: 9053,
          rating: 4
        },
        {
          date: '2023-10-16',
          reviewCount: 9053,
          rating: 4
        },
        {
          date: '2023-10-17',
          reviewCount: 9165,
          rating: 4
        },
        {
          date: '2023-10-18',
          reviewCount: 9165,
          rating: 4
        },
        {
          date: '2023-10-19',
          reviewCount: 9165,
          rating: 4
        },
        {
          date: '2023-10-20',
          reviewCount: 9251,
          rating: 4
        },
        {
          date: '2023-10-21',
          reviewCount: 9251,
          rating: 4
        },
        {
          date: '2023-10-22',
          reviewCount: 9251,
          rating: 4
        },
        {
          date: '2023-10-23',
          reviewCount: 9251,
          rating: 4
        },
        {
          date: '2023-10-24',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2023-10-25',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2023-10-26',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2023-10-27',
          reviewCount: 9393,
          rating: 4
        },
        {
          date: '2023-10-28',
          reviewCount: 9393,
          rating: 4
        },
        {
          date: '2023-10-29',
          reviewCount: 9393,
          rating: 4
        },
        {
          date: '2023-10-30',
          reviewCount: 9393,
          rating: 4
        },
        {
          date: '2023-10-31',
          reviewCount: 9516,
          rating: 4
        },
        {
          date: '2023-11-01',
          reviewCount: 9516,
          rating: 4
        },
        {
          date: '2023-11-02',
          reviewCount: 9516,
          rating: 4
        },
        {
          date: '2023-11-03',
          reviewCount: 9590,
          rating: 4
        },
        {
          date: '2023-11-04',
          reviewCount: 9590,
          rating: 4
        },
        {
          date: '2023-11-05',
          reviewCount: 9590,
          rating: 4
        },
        {
          date: '2023-11-06',
          reviewCount: 9590,
          rating: 4
        },
        {
          date: '2023-11-07',
          reviewCount: 9684,
          rating: 4
        },
        {
          date: '2023-11-08',
          reviewCount: 9684,
          rating: 4
        },
        {
          date: '2023-11-09',
          reviewCount: 9684,
          rating: 4
        },
        {
          date: '2023-11-10',
          reviewCount: 9684,
          rating: 4
        },
        {
          date: '2023-11-11',
          reviewCount: 9755,
          rating: 4
        },
        {
          date: '2023-11-12',
          reviewCount: 9755,
          rating: 4
        },
        {
          date: '2023-11-13',
          reviewCount: 9755,
          rating: 4
        },
        {
          date: '2023-11-14',
          reviewCount: 9789,
          rating: 4
        },
        {
          date: '2023-11-15',
          reviewCount: 9789,
          rating: 4
        },
        {
          date: '2023-11-16',
          reviewCount: 9789,
          rating: 4
        },
        {
          date: '2023-11-17',
          reviewCount: 9789,
          rating: 4
        },
        {
          date: '2023-11-18',
          reviewCount: 9830,
          rating: 4
        },
        {
          date: '2023-11-19',
          reviewCount: 9830,
          rating: 4
        },
        {
          date: '2023-11-20',
          reviewCount: 9830,
          rating: 4
        },
        {
          date: '2023-11-21',
          reviewCount: 10493,
          rating: 4
        },
        {
          date: '2023-11-22',
          reviewCount: 10493,
          rating: 4
        },
        {
          date: '2023-11-23',
          reviewCount: 10493,
          rating: 4
        },
        {
          date: '2023-11-24',
          reviewCount: 10493,
          rating: 4
        },
        {
          date: '2023-11-25',
          reviewCount: 10540,
          rating: 4
        },
        {
          date: '2023-11-26',
          reviewCount: 10540,
          rating: 4
        },
        {
          date: '2023-11-27',
          reviewCount: 10540,
          rating: 4
        },
        {
          date: '2023-11-28',
          reviewCount: 9980,
          rating: 4
        },
        {
          date: '2023-11-29',
          reviewCount: 9980,
          rating: 4
        },
        {
          date: '2023-11-30',
          reviewCount: 9980,
          rating: 4
        },
        {
          date: '2023-12-01',
          reviewCount: 9980,
          rating: 4
        },
        {
          date: '2023-12-02',
          reviewCount: 10047,
          rating: 4
        },
        {
          date: '2023-12-03',
          reviewCount: 10047,
          rating: 4
        },
        {
          date: '2023-12-04',
          reviewCount: 10047,
          rating: 4
        },
        {
          date: '2023-12-05',
          reviewCount: 10047,
          rating: 4
        },
        {
          date: '2023-12-06',
          reviewCount: 10107,
          rating: 4
        },
        {
          date: '2023-12-07',
          reviewCount: 10107,
          rating: 4
        },
        {
          date: '2023-12-08',
          reviewCount: 10107,
          rating: 4
        },
        {
          date: '2023-12-09',
          reviewCount: 10107,
          rating: 4
        },
        {
          date: '2023-12-10',
          reviewCount: 10170,
          rating: 4
        },
        {
          date: '2023-12-11',
          reviewCount: 10170,
          rating: 4
        },
        {
          date: '2023-12-12',
          reviewCount: 10170,
          rating: 4
        },
        {
          date: '2023-12-13',
          reviewCount: 10170,
          rating: 4
        },
        {
          date: '2023-12-14',
          reviewCount: 10236,
          rating: 4
        },
        {
          date: '2023-12-15',
          reviewCount: 10236,
          rating: 4
        },
        {
          date: '2023-12-16',
          reviewCount: 10236,
          rating: 4
        },
        {
          date: '2023-12-17',
          reviewCount: 10290,
          rating: 4
        },
        {
          date: '2023-12-18',
          reviewCount: 10290,
          rating: 4
        },
        {
          date: '2023-12-19',
          reviewCount: 10290,
          rating: 4
        },
        {
          date: '2023-12-20',
          reviewCount: 10290,
          rating: 4
        },
        {
          date: '2023-12-21',
          reviewCount: 10358,
          rating: 4
        },
        {
          date: '2023-12-22',
          reviewCount: 10358,
          rating: 4
        },
        {
          date: '2023-12-23',
          reviewCount: 10358,
          rating: 4
        },
        {
          date: '2023-12-24',
          reviewCount: 10415,
          rating: 4
        },
        {
          date: '2023-12-25',
          reviewCount: 10415,
          rating: 4
        },
        {
          date: '2023-12-26',
          reviewCount: 10415,
          rating: 4
        },
        {
          date: '2023-12-27',
          reviewCount: 10415,
          rating: 4
        },
        {
          date: '2023-12-28',
          reviewCount: 10479,
          rating: 4
        },
        {
          date: '2023-12-29',
          reviewCount: 10479,
          rating: 4
        },
        {
          date: '2023-12-30',
          reviewCount: 10479,
          rating: 4
        },
        {
          date: '2023-12-31',
          reviewCount: 10533,
          rating: 4
        },
        {
          date: '2024-01-01',
          reviewCount: 10533,
          rating: 4
        },
        {
          date: '2024-01-02',
          reviewCount: 10533,
          rating: 4
        },
        {
          date: '2024-01-03',
          reviewCount: 10586,
          rating: 4
        },
        {
          date: '2024-01-04',
          reviewCount: 10586,
          rating: 4
        },
        {
          date: '2024-01-05',
          reviewCount: 10586,
          rating: 4
        },
        {
          date: '2024-01-06',
          reviewCount: 10586,
          rating: 4
        },
        {
          date: '2024-01-07',
          reviewCount: 10654,
          rating: 4
        },
        {
          date: '2024-01-08',
          reviewCount: 10654,
          rating: 4
        },
        {
          date: '2024-01-09',
          reviewCount: 10654,
          rating: 4
        },
        {
          date: '2024-01-10',
          reviewCount: 10709,
          rating: 4
        },
        {
          date: '2024-01-11',
          reviewCount: 10709,
          rating: 4
        },
        {
          date: '2024-01-12',
          reviewCount: 10709,
          rating: 4
        },
        {
          date: '2024-01-13',
          reviewCount: 10769,
          rating: 4
        },
        {
          date: '2024-01-14',
          reviewCount: 10769,
          rating: 4
        },
        {
          date: '2024-01-15',
          reviewCount: 10769,
          rating: 4
        },
        {
          date: '2024-01-16',
          reviewCount: 10833,
          rating: 4
        },
        {
          date: '2024-01-17',
          reviewCount: 10833,
          rating: 4
        },
        {
          date: '2024-01-18',
          reviewCount: 10833,
          rating: 4
        },
        {
          date: '2024-01-19',
          reviewCount: 10833,
          rating: 4
        },
        {
          date: '2024-01-20',
          reviewCount: 10892,
          rating: 4
        },
        {
          date: '2024-01-21',
          reviewCount: 10892,
          rating: 4
        },
        {
          date: '2024-01-22',
          reviewCount: 10892,
          rating: 4
        },
        {
          date: '2024-01-23',
          reviewCount: 10941,
          rating: 4
        },
        {
          date: '2024-01-24',
          reviewCount: 10941,
          rating: 4
        },
        {
          date: '2024-01-25',
          reviewCount: 10941,
          rating: 4
        },
        {
          date: '2024-01-26',
          reviewCount: 10999,
          rating: 4
        },
        {
          date: '2024-01-27',
          reviewCount: 10999,
          rating: 4
        },
        {
          date: '2024-01-28',
          reviewCount: 10999,
          rating: 4
        },
        {
          date: '2024-01-29',
          reviewCount: 10999,
          rating: 4
        },
        {
          date: '2024-01-30',
          reviewCount: 11046,
          rating: 4
        },
        {
          date: '2024-01-31',
          reviewCount: 11046,
          rating: 4
        },
        {
          date: '2024-02-01',
          reviewCount: 11046,
          rating: 4
        },
        {
          date: '2024-02-02',
          reviewCount: 11098,
          rating: 4
        },
        {
          date: '2024-02-03',
          reviewCount: 11098,
          rating: 4
        },
        {
          date: '2024-02-04',
          reviewCount: 11098,
          rating: 4
        },
        {
          date: '2024-02-05',
          reviewCount: 11098,
          rating: 4
        },
        {
          date: '2024-02-06',
          reviewCount: 11169,
          rating: 4
        },
        {
          date: '2024-02-07',
          reviewCount: 11169,
          rating: 4
        },
        {
          date: '2024-02-08',
          reviewCount: 11169,
          rating: 4
        },
        {
          date: '2024-02-09',
          reviewCount: 11169,
          rating: 4
        },
        {
          date: '2024-02-10',
          reviewCount: 11229,
          rating: 4
        },
        {
          date: '2024-02-11',
          reviewCount: 11229,
          rating: 4
        },
        {
          date: '2024-02-12',
          reviewCount: 11229,
          rating: 4
        },
        {
          date: '2024-02-13',
          reviewCount: 11279,
          rating: 4
        },
        {
          date: '2024-02-14',
          reviewCount: 11279,
          rating: 4
        },
        {
          date: '2024-02-15',
          reviewCount: 11279,
          rating: 4
        },
        {
          date: '2024-02-16',
          reviewCount: 11279,
          rating: 4
        },
        {
          date: '2024-02-17',
          reviewCount: 11348,
          rating: 4
        },
        {
          date: '2024-02-18',
          reviewCount: 11348,
          rating: 4
        },
        {
          date: '2024-02-19',
          reviewCount: 11348,
          rating: 4
        },
        {
          date: '2024-02-20',
          reviewCount: 11410,
          rating: 4
        },
        {
          date: '2024-02-21',
          reviewCount: 11410,
          rating: 4
        },
        {
          date: '2024-02-22',
          reviewCount: 11410,
          rating: 4
        },
        {
          date: '2024-02-23',
          reviewCount: 11410,
          rating: 4
        },
        {
          date: '2024-02-24',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-02-25',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-02-26',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-02-27',
          reviewCount: 11552,
          rating: 4
        },
        {
          date: '2024-02-28',
          reviewCount: 11552,
          rating: 4
        },
        {
          date: '2024-02-29',
          reviewCount: 11552,
          rating: 4
        },
        {
          date: '2024-03-01',
          reviewCount: 11598,
          rating: 4
        },
        {
          date: '2024-03-02',
          reviewCount: 11598,
          rating: 4
        },
        {
          date: '2024-03-03',
          reviewCount: 11598,
          rating: 4
        },
        {
          date: '2024-03-04',
          reviewCount: 11598,
          rating: 4
        },
        {
          date: '2024-03-05',
          reviewCount: 11646,
          rating: 4
        },
        {
          date: '2024-03-06',
          reviewCount: 11646,
          rating: 4
        },
        {
          date: '2024-03-07',
          reviewCount: 11646,
          rating: 4
        },
        {
          date: '2024-03-08',
          reviewCount: 11646,
          rating: 4
        },
        {
          date: '2024-03-09',
          reviewCount: 11725,
          rating: 4
        },
        {
          date: '2024-03-10',
          reviewCount: 11725,
          rating: 4
        },
        {
          date: '2024-03-11',
          reviewCount: 11725,
          rating: 4
        },
        {
          date: '2024-03-12',
          reviewCount: 11775,
          rating: 4
        },
        {
          date: '2024-03-13',
          reviewCount: 11775,
          rating: 4
        },
        {
          date: '2024-03-14',
          reviewCount: 11775,
          rating: 4
        },
        {
          date: '2024-03-15',
          reviewCount: 11845,
          rating: 4
        },
        {
          date: '2024-03-16',
          reviewCount: 11845,
          rating: 4
        },
        {
          date: '2024-03-17',
          reviewCount: 11845,
          rating: 4
        },
        {
          date: '2024-03-18',
          reviewCount: 11845,
          rating: 4
        },
        {
          date: '2024-03-19',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-20',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-21',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-22',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-23',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-24',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-25',
          reviewCount: 11905,
          rating: 4
        },
        {
          date: '2024-03-26',
          reviewCount: 12042,
          rating: 4
        },
        {
          date: '2024-03-27',
          reviewCount: 12042,
          rating: 4
        },
        {
          date: '2024-03-28',
          reviewCount: 12042,
          rating: 4
        },
        {
          date: '2024-03-29',
          reviewCount: 12042,
          rating: 4
        },
        {
          date: '2024-03-30',
          reviewCount: 12132,
          rating: 4
        },
        {
          date: '2024-03-31',
          reviewCount: 12132,
          rating: 4
        },
        {
          date: '2024-04-01',
          reviewCount: 12132,
          rating: 4
        },
        {
          date: '2024-04-02',
          reviewCount: 12132,
          rating: 4
        },
        {
          date: '2024-04-03',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-04',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-05',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-06',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-07',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-08',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-09',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-10',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-11',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-12',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-13',
          reviewCount: 12204,
          rating: 4
        },
        {
          date: '2024-04-14',
          reviewCount: 12411,
          rating: 4
        },
        {
          date: '2024-04-15',
          reviewCount: 12411,
          rating: 4
        },
        {
          date: '2024-04-16',
          reviewCount: 12411,
          rating: 4
        },
        {
          date: '2024-04-17',
          reviewCount: 12411,
          rating: 4
        },
        {
          date: '2024-04-18',
          reviewCount: 12480,
          rating: 4
        },
        {
          date: '2024-04-19',
          reviewCount: 12480,
          rating: 4
        },
        {
          date: '2024-04-20',
          reviewCount: 12480,
          rating: 4
        },
        {
          date: '2024-04-21',
          reviewCount: 12529,
          rating: 4
        },
        {
          date: '2024-04-22',
          reviewCount: 12529,
          rating: 4
        },
        {
          date: '2024-04-23',
          reviewCount: 12529,
          rating: 4
        },
        {
          date: '2024-04-24',
          reviewCount: 12529,
          rating: 4
        },
        {
          date: '2024-04-25',
          reviewCount: 12607,
          rating: 4
        },
        {
          date: '2024-04-26',
          reviewCount: 12607,
          rating: 4
        },
        {
          date: '2024-04-27',
          reviewCount: 12607,
          rating: 4
        },
        {
          date: '2024-04-28',
          reviewCount: 12665,
          rating: 4
        },
        {
          date: '2024-04-29',
          reviewCount: 12665,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 12665,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 12665,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 12734,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 12734,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 12734,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 12776,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 12776,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 12776,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 12776,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 12851,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 12851,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 12851,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 12902,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 12902,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 12902,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 12902,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 12961,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 12961,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 12961,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 12998,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 12998,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 12998,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 12998,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 13009,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 13009,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 13009,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 13081,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 13081,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 13081,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 13081,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 13132,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 13132,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 13132,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 13170,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 13170,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 13170,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 13170,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 13230,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 13230,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 13230,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 13276,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 13276,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 13276,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 13312,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 13312,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 13312,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 13312,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 13371,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 13371,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 13371,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 13371,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 13431,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 13431,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 13431,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 13485,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 13623,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 13623,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 13623,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 13687,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 13687,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 13687,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 13745,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 13745,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 13745,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 13745,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 13817,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 13817,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 13817,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 13873,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 13873,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 13873,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 13873,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 13961,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 13961,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 13961,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 14024,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 14024,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 14024,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 14024,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 14130,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 14130,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 14130,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 14198,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 14198,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 14198,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 14198,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 14282,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 14282,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 14282,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 14346,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 14346,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 14346,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 14346,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 14435,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 14435,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 14435,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 14495,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 14495,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 14495,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 14495,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 14584,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 14584,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 14584,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 14584,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 14644,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 14644,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 14644,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 14644,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 14719,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 14719,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 14719,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 14776,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 14776,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,

      createdAt: '2024-08-28T17:03:20.731Z'
    },
    {
      id: 'b9d91c61-2eb0-46cf-aef3-b442aa579ada',
      productName: 'Travel Water Flosser',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-3.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2024-04-29',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 66,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 70,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 13,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 13,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 13,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 13,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 13,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 26,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 48,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 63,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 71,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 106,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 149,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 149,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 149,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 149,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 149,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 164,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 164,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 164,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 164,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 164,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 178,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 192,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 192,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 192,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 192,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 192,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 208,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 242,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 271,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 271,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 271,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 271,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 271,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 295,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 318,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 318,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 318,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 318,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 318,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 334,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 359,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 359,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,

      createdAt: '2024-08-28T17:04:48.708Z'
    },
    {
      id: '9e08c369-336e-4765-a4ad-d33be58d333d',
      productName: 'Portable Water Flosser for Teeth',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-3.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2024-04-29',

          salesRank: 28638
        },
        {
          date: '2024-04-30',

          salesRank: 25673
        },
        {
          date: '2024-05-01',

          salesRank: 39050
        },
        {
          date: '2024-05-02',

          salesRank: 42414
        },
        {
          date: '2024-05-03',

          salesRank: 44106
        },
        {
          date: '2024-05-04',

          salesRank: 47988
        },
        {
          date: '2024-05-05',

          salesRank: 50079
        },
        {
          date: '2024-05-06',

          salesRank: 29625
        },
        {
          date: '2024-05-07',

          salesRank: 23437
        },
        {
          date: '2024-05-08',

          salesRank: 14632
        },
        {
          date: '2024-05-09',

          salesRank: 8563
        },
        {
          date: '2024-05-10',

          salesRank: 12293
        },
        {
          date: '2024-05-11',

          salesRank: 12797
        },
        {
          date: '2024-05-12',

          salesRank: 11613
        },
        {
          date: '2024-05-13',

          salesRank: 14855
        },
        {
          date: '2024-05-14',

          salesRank: 14796
        },
        {
          date: '2024-05-15',

          salesRank: 16295
        },
        {
          date: '2024-05-16',

          salesRank: 25122
        },
        {
          date: '2024-05-17',

          salesRank: 14575
        },
        {
          date: '2024-05-18',

          salesRank: 13778
        },
        {
          date: '2024-05-19',

          salesRank: 16821
        },
        {
          date: '2024-05-20',

          salesRank: 16635
        },
        {
          date: '2024-05-21',

          salesRank: 19019
        },
        {
          date: '2024-05-22',

          salesRank: 16498
        },
        {
          date: '2024-05-23',

          salesRank: 17527
        },
        {
          date: '2024-05-24',

          salesRank: 17546
        },
        {
          date: '2024-05-25',

          salesRank: 15798
        },
        {
          date: '2024-05-26',

          salesRank: 15651
        },
        {
          date: '2024-05-27',

          salesRank: 14926
        },
        {
          date: '2024-05-28',

          salesRank: 15516
        },
        {
          date: '2024-05-29',

          salesRank: 10281
        },
        {
          date: '2024-05-30',

          salesRank: 11635
        },
        {
          date: '2024-05-31',

          salesRank: 9411
        },
        {
          date: '2024-06-01',

          salesRank: 10509
        },
        {
          date: '2024-06-02',

          salesRank: 11877
        },
        {
          date: '2024-06-03',

          salesRank: 9184
        },
        {
          date: '2024-06-04',

          salesRank: 7683
        },
        {
          date: '2024-06-05',

          salesRank: 8604
        },
        {
          date: '2024-06-06',

          salesRank: 7025
        },
        {
          date: '2024-06-07',

          salesRank: 8272
        },
        {
          date: '2024-06-08',

          salesRank: 8478
        },
        {
          date: '2024-06-09',

          salesRank: 7891
        },
        {
          date: '2024-06-10',

          salesRank: 8784
        },
        {
          date: '2024-06-11',

          salesRank: 10240
        },
        {
          date: '2024-06-12',

          salesRank: 9096
        },
        {
          date: '2024-06-13',

          salesRank: 10050
        },
        {
          date: '2024-06-14',

          salesRank: 9294
        },
        {
          date: '2024-06-15',

          salesRank: 9686
        },
        {
          date: '2024-06-16',

          salesRank: 10407
        },
        {
          date: '2024-06-17',

          salesRank: 11264
        },
        {
          date: '2024-06-18',

          salesRank: 10283
        },
        {
          date: '2024-06-19',

          salesRank: 10199
        },
        {
          date: '2024-06-20',

          salesRank: 12948
        },
        {
          date: '2024-06-21',

          salesRank: 12387
        },
        {
          date: '2024-06-22',

          salesRank: 13083
        },
        {
          date: '2024-06-23',

          salesRank: 11656
        },
        {
          date: '2024-06-24',

          salesRank: 11379
        },
        {
          date: '2024-06-25',

          salesRank: 11517
        },
        {
          date: '2024-06-26',

          salesRank: 9698
        },
        {
          date: '2024-06-27',

          salesRank: 9745
        },
        {
          date: '2024-06-28',

          salesRank: 9902
        },
        {
          date: '2024-06-29',

          salesRank: 8816
        },
        {
          date: '2024-06-30',

          salesRank: 7591
        },
        {
          date: '2024-07-01',

          salesRank: 6893
        },
        {
          date: '2024-07-02',

          salesRank: 7159
        },
        {
          date: '2024-07-03',

          salesRank: 6649
        },
        {
          date: '2024-07-04',

          salesRank: 5731
        },
        {
          date: '2024-07-05',

          salesRank: 6063
        },
        {
          date: '2024-07-06',

          salesRank: 6419
        },
        {
          date: '2024-07-07',

          salesRank: 6012
        },
        {
          date: '2024-07-08',

          salesRank: 5734
        },
        {
          date: '2024-07-09',

          salesRank: 6275
        },
        {
          date: '2024-07-10',

          salesRank: 6059
        },
        {
          date: '2024-07-11',

          salesRank: 6134
        },
        {
          date: '2024-07-12',

          salesRank: 6285
        },
        {
          date: '2024-07-13',

          salesRank: 5541
        },
        {
          date: '2024-07-14',

          salesRank: 6372
        },
        {
          date: '2024-07-15',

          salesRank: 6291
        },
        {
          date: '2024-07-16',

          salesRank: 4626
        },
        {
          date: '2024-07-17',

          salesRank: 5003
        },
        {
          date: '2024-07-18',

          salesRank: 5610
        },
        {
          date: '2024-07-19',

          salesRank: 6996
        },
        {
          date: '2024-07-20',

          salesRank: 7618
        },
        {
          date: '2024-07-21',

          salesRank: 9774
        },
        {
          date: '2024-07-22',

          salesRank: 11599
        },
        {
          date: '2024-07-23',

          salesRank: 11304
        },
        {
          date: '2024-07-24',

          salesRank: 13087
        },
        {
          date: '2024-07-25',

          salesRank: 14346
        },
        {
          date: '2024-07-26',

          salesRank: 12622
        },
        {
          date: '2024-07-27',

          salesRank: 15083
        },
        {
          date: '2024-07-28',

          salesRank: 14252
        },
        {
          date: '2024-07-29',

          salesRank: 14799
        },
        {
          date: '2024-07-30',

          salesRank: 14824
        },
        {
          date: '2024-07-31',

          salesRank: 13884
        },
        {
          date: '2024-08-01',

          salesRank: 14141
        },
        {
          date: '2024-08-02',

          salesRank: 12910
        },
        {
          date: '2024-08-03',

          salesRank: 13596
        },
        {
          date: '2024-08-04',

          salesRank: 14275
        },
        {
          date: '2024-08-05',

          salesRank: 12621
        },
        {
          date: '2024-08-06',

          salesRank: 10610
        },
        {
          date: '2024-08-07',

          salesRank: 11391
        },
        {
          date: '2024-08-08',

          salesRank: 11479
        },
        {
          date: '2024-08-09',

          salesRank: 10067
        },
        {
          date: '2024-08-10',

          salesRank: 9297
        },
        {
          date: '2024-08-11',

          salesRank: 9122
        },
        {
          date: '2024-08-12',

          salesRank: 9506
        },
        {
          date: '2024-08-13',

          salesRank: 10202
        },
        {
          date: '2024-08-14',

          salesRank: 10315
        },
        {
          date: '2024-08-15',

          salesRank: 11008
        },
        {
          date: '2024-08-16',

          salesRank: 11047
        },
        {
          date: '2024-08-19',

          salesRank: 12351
        },
        {
          date: '2024-08-20',

          salesRank: 11834
        },
        {
          date: '2024-08-21',

          salesRank: 12515
        },
        {
          date: '2024-08-22',

          salesRank: 11892
        },
        {
          date: '2024-08-23',

          salesRank: 11551
        },
        {
          date: '2024-08-24',

          salesRank: 9351
        },
        {
          date: '2024-08-25',

          salesRank: 10821
        },
        {
          date: '2024-08-26',

          salesRank: 12748
        },
        {
          date: '2024-08-27',

          salesRank: 12606
        },
        {
          date: '2024-08-28',

          salesRank: 11861
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-28T17:05:45.755Z'
    },
    {
      id: 'd4618714-32ca-422b-b49a-6fd5884d6c19',
      productName: 'Rechargeable Portable Water Flosser',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2023-12-08',

          salesRank: 38788
        },
        {
          date: '2023-12-09',

          salesRank: 27968
        },
        {
          date: '2023-12-10',

          salesRank: 25044
        },
        {
          date: '2023-12-11',

          salesRank: 22087
        },
        {
          date: '2023-12-12',

          salesRank: 13905
        },
        {
          date: '2023-12-13',

          salesRank: 13380
        },
        {
          date: '2023-12-14',
          reviewCount: 2,
          rating: 4,
          salesRank: 13345
        },
        {
          date: '2023-12-15',
          reviewCount: 2,
          rating: 4,
          salesRank: 9594
        },
        {
          date: '2023-12-16',
          reviewCount: 2,
          rating: 4,
          salesRank: 8507
        },
        {
          date: '2023-12-17',
          reviewCount: 2,
          rating: 4,
          salesRank: 8281
        },
        {
          date: '2023-12-18',
          reviewCount: 2,
          rating: 4,
          salesRank: 6530
        },
        {
          date: '2023-12-19',
          reviewCount: 3,
          rating: 4,
          salesRank: 5336
        },
        {
          date: '2023-12-20',
          reviewCount: 3,
          rating: 4,
          salesRank: 4689
        },
        {
          date: '2023-12-21',
          reviewCount: 3,
          rating: 4,
          salesRank: 4313
        },
        {
          date: '2023-12-22',
          reviewCount: 3,
          rating: 4,
          salesRank: 4410
        },
        {
          date: '2023-12-23',
          reviewCount: 3,
          rating: 4,
          salesRank: 4755
        },
        {
          date: '2023-12-24',
          reviewCount: 11,
          rating: 4,
          salesRank: 5488
        },
        {
          date: '2023-12-25',
          reviewCount: 11,
          rating: 4,
          salesRank: 4708
        },
        {
          date: '2023-12-26',
          reviewCount: 11,
          rating: 4,
          salesRank: 3906
        },
        {
          date: '2023-12-27',
          reviewCount: 11,
          rating: 4,
          salesRank: 3696
        },
        {
          date: '2023-12-28',
          reviewCount: 11,
          rating: 4,
          salesRank: 3971
        },
        {
          date: '2023-12-29',
          reviewCount: 11,
          rating: 4,
          salesRank: 4430
        },
        {
          date: '2023-12-30',
          reviewCount: 21,
          rating: 4,
          salesRank: 4847
        },
        {
          date: '2023-12-31',
          reviewCount: 21,
          rating: 4,
          salesRank: 4525
        },
        {
          date: '2024-01-01',
          reviewCount: 21,
          rating: 4,
          salesRank: 5183
        },
        {
          date: '2024-01-02',
          reviewCount: 21,
          rating: 4,
          salesRank: 4659
        },
        {
          date: '2024-01-03',
          reviewCount: 21,
          rating: 4,
          salesRank: 5187
        },
        {
          date: '2024-01-04',
          reviewCount: 33,
          rating: 4,
          salesRank: 4859
        },
        {
          date: '2024-01-05',
          reviewCount: 33,
          rating: 4,
          salesRank: 5691
        },
        {
          date: '2024-01-06',
          reviewCount: 33,
          rating: 4,
          salesRank: 4848
        },
        {
          date: '2024-01-07',
          reviewCount: 33,
          rating: 4,
          salesRank: 5167
        },
        {
          date: '2024-01-08',
          reviewCount: 33,
          rating: 4,
          salesRank: 4552
        },
        {
          date: '2024-01-09',
          reviewCount: 33,
          rating: 4,
          salesRank: 3415
        },
        {
          date: '2024-01-10',
          reviewCount: 51,
          rating: 4,
          salesRank: 3103
        },
        {
          date: '2024-01-11',
          reviewCount: 51,
          rating: 4,
          salesRank: 3178
        },
        {
          date: '2024-01-12',
          reviewCount: 51,
          rating: 4,
          salesRank: 3625
        },
        {
          date: '2024-01-13',
          reviewCount: 51,
          rating: 4,
          salesRank: 3171
        },
        {
          date: '2024-01-14',
          reviewCount: 51,
          rating: 4,
          salesRank: 3795
        },
        {
          date: '2024-01-15',
          reviewCount: 64,
          rating: 4,
          salesRank: 3958
        },
        {
          date: '2024-01-16',
          reviewCount: 64,
          rating: 4,
          salesRank: 3387
        },
        {
          date: '2024-01-17',
          reviewCount: 64,
          rating: 4,
          salesRank: 3192
        },
        {
          date: '2024-01-18',
          reviewCount: 64,
          rating: 4,
          salesRank: 3073
        },
        {
          date: '2024-01-19',
          reviewCount: 64,
          rating: 4,
          salesRank: 2914
        },
        {
          date: '2024-01-20',
          reviewCount: 64,
          rating: 4,
          salesRank: 2675
        },
        {
          date: '2024-01-21',
          reviewCount: 78,
          rating: 4,
          salesRank: 3014
        },
        {
          date: '2024-01-22',
          reviewCount: 78,
          rating: 4,
          salesRank: 2316
        },
        {
          date: '2024-01-23',
          reviewCount: 78,
          rating: 4,
          salesRank: 2121
        },
        {
          date: '2024-01-24',
          reviewCount: 88,
          rating: 4,
          salesRank: 1733
        },
        {
          date: '2024-01-25',
          reviewCount: 88,
          rating: 4,
          salesRank: 1710
        },
        {
          date: '2024-01-26',
          reviewCount: 88,
          rating: 4,
          salesRank: 1557
        },
        {
          date: '2024-01-27',
          reviewCount: 88,
          rating: 4,
          salesRank: 1798
        },
        {
          date: '2024-01-28',
          reviewCount: 103,
          rating: 4,
          salesRank: 1788
        },
        {
          date: '2024-01-29',
          reviewCount: 103,
          rating: 4,
          salesRank: 1944
        },
        {
          date: '2024-01-30',
          reviewCount: 103,
          rating: 4,
          salesRank: 2129
        },
        {
          date: '2024-01-31',
          reviewCount: 103,
          rating: 4,
          salesRank: 2112
        },
        {
          date: '2024-02-01',
          reviewCount: 117,
          rating: 4,
          salesRank: 942
        },
        {
          date: '2024-02-02',
          reviewCount: 117,
          rating: 4,
          salesRank: 654
        },
        {
          date: '2024-02-03',
          reviewCount: 117,
          rating: 4,
          salesRank: 610
        },
        {
          date: '2024-02-04',
          reviewCount: 117,
          rating: 4,
          salesRank: 534
        },
        {
          date: '2024-02-05',
          reviewCount: 130,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2024-02-06',
          reviewCount: 130,
          rating: 4,
          salesRank: 424
        },
        {
          date: '2024-02-07',
          reviewCount: 130,
          rating: 4,
          salesRank: 341
        },
        {
          date: '2024-02-08',
          reviewCount: 130,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2024-02-09',
          reviewCount: 143,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2024-02-10',
          reviewCount: 143,
          rating: 4,
          salesRank: 296
        },
        {
          date: '2024-02-11',
          reviewCount: 143,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2024-02-12',
          reviewCount: 162,
          rating: 4,
          salesRank: 321
        },
        {
          date: '2024-02-13',
          reviewCount: 162,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2024-02-14',
          reviewCount: 162,
          rating: 4,
          salesRank: 243
        },
        {
          date: '2024-02-15',
          reviewCount: 178,
          rating: 4,
          salesRank: 325
        },
        {
          date: '2024-02-16',
          reviewCount: 178,
          rating: 4,
          salesRank: 449
        },
        {
          date: '2024-02-17',
          reviewCount: 178,
          rating: 4,
          salesRank: 586
        },
        {
          date: '2024-02-18',
          reviewCount: 205,
          rating: 4,
          salesRank: 793
        },
        {
          date: '2024-02-19',
          reviewCount: 205,
          rating: 4,
          salesRank: 779
        },
        {
          date: '2024-02-20',
          reviewCount: 205,
          rating: 4,
          salesRank: 938
        },
        {
          date: '2024-02-21',
          reviewCount: 205,
          rating: 4,
          salesRank: 1102
        },
        {
          date: '2024-02-22',
          reviewCount: 238,
          rating: 4,
          salesRank: 1345
        },
        {
          date: '2024-02-23',
          reviewCount: 238,
          rating: 4,
          salesRank: 1207
        },
        {
          date: '2024-02-24',
          reviewCount: 238,
          rating: 4,
          salesRank: 1364
        },
        {
          date: '2024-02-25',
          reviewCount: 265,
          rating: 4,
          salesRank: 1363
        },
        {
          date: '2024-02-26',
          reviewCount: 265,
          rating: 4,
          salesRank: 1546
        },
        {
          date: '2024-02-27',
          reviewCount: 265,
          rating: 4,
          salesRank: 1594
        },
        {
          date: '2024-02-28',
          reviewCount: 297,
          rating: 4,
          salesRank: 1694
        },
        {
          date: '2024-02-29',
          reviewCount: 297,
          rating: 4,
          salesRank: 2020
        },
        {
          date: '2024-03-01',
          reviewCount: 297,
          rating: 4,
          salesRank: 2377
        },
        {
          date: '2024-03-02',
          reviewCount: 297,
          rating: 4,
          salesRank: 2719
        },
        {
          date: '2024-03-03',
          reviewCount: 331,
          rating: 4,
          salesRank: 3150
        },
        {
          date: '2024-03-04',
          reviewCount: 331,
          rating: 4,
          salesRank: 3124
        },
        {
          date: '2024-03-05',
          reviewCount: 331,
          rating: 4,
          salesRank: 2810
        },
        {
          date: '2024-03-06',
          reviewCount: 331,
          rating: 4,
          salesRank: 2305
        },
        {
          date: '2024-03-07',
          reviewCount: 382,
          rating: 4,
          salesRank: 1898
        },
        {
          date: '2024-03-08',
          reviewCount: 382,
          rating: 4,
          salesRank: 2020
        },
        {
          date: '2024-03-09',
          reviewCount: 382,
          rating: 4,
          salesRank: 2003
        },
        {
          date: '2024-03-10',
          reviewCount: 491,
          rating: 4,
          salesRank: 2037
        },
        {
          date: '2024-03-11',
          reviewCount: 491,
          rating: 4,
          salesRank: 1656
        },
        {
          date: '2024-03-12',
          reviewCount: 491,
          rating: 4,
          salesRank: 1473
        },
        {
          date: '2024-03-13',
          reviewCount: 184,
          rating: 4,
          salesRank: 1690
        },
        {
          date: '2024-03-14',
          reviewCount: 184,
          rating: 4,
          salesRank: 1659
        },
        {
          date: '2024-03-15',
          reviewCount: 184,
          rating: 4,
          salesRank: 1898
        },
        {
          date: '2024-03-16',
          reviewCount: 184,
          rating: 4,
          salesRank: 1973
        },
        {
          date: '2024-03-17',
          reviewCount: 666,
          rating: 4,
          salesRank: 2498
        },
        {
          date: '2024-03-18',
          reviewCount: 666,
          rating: 4,
          salesRank: 2058
        },
        {
          date: '2024-03-19',
          reviewCount: 666,
          rating: 4,
          salesRank: 1696
        },
        {
          date: '2024-03-20',
          reviewCount: 754,
          rating: 4,
          salesRank: 1727
        },
        {
          date: '2024-03-21',
          reviewCount: 754,
          rating: 4,
          salesRank: 1733
        },
        {
          date: '2024-03-22',
          reviewCount: 754,
          rating: 4,
          salesRank: 1586
        },
        {
          date: '2024-03-23',
          reviewCount: 754,
          rating: 4,
          salesRank: 1735
        },
        {
          date: '2024-03-24',
          reviewCount: 754,
          rating: 4,
          salesRank: 1800
        },
        {
          date: '2024-03-25',
          reviewCount: 754,
          rating: 4,
          salesRank: 1638
        },
        {
          date: '2024-03-26',
          reviewCount: 754,
          rating: 4,
          salesRank: 1043
        },
        {
          date: '2024-03-27',
          reviewCount: 754,
          rating: 4,
          salesRank: 1090
        },
        {
          date: '2024-03-28',
          reviewCount: 923,
          rating: 4,
          salesRank: 1031
        },
        {
          date: '2024-03-29',
          reviewCount: 923,
          rating: 4,
          salesRank: 1202
        },
        {
          date: '2024-03-30',
          reviewCount: 923,
          rating: 4,
          salesRank: 1101
        },
        {
          date: '2024-03-31',
          reviewCount: 970,
          rating: 4,
          salesRank: 875
        },
        {
          date: '2024-04-01',
          reviewCount: 970,
          rating: 4,
          salesRank: 869
        },
        {
          date: '2024-04-02',
          reviewCount: 970,
          rating: 4,
          salesRank: 777
        },
        {
          date: '2024-04-03',
          reviewCount: 970,
          rating: 4,
          salesRank: 722
        },
        {
          date: '2024-04-04',
          reviewCount: 1021,
          rating: 4,
          salesRank: 860
        },
        {
          date: '2024-04-05',
          reviewCount: 1021,
          rating: 4,
          salesRank: 868
        },
        {
          date: '2024-04-06',
          reviewCount: 1021,
          rating: 4,
          salesRank: 1013
        },
        {
          date: '2024-04-07',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1261
        },
        {
          date: '2024-04-08',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1395
        },
        {
          date: '2024-04-09',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1375
        },
        {
          date: '2024-04-10',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1343
        },
        {
          date: '2024-04-11',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1304
        },
        {
          date: '2024-04-12',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1227
        },
        {
          date: '2024-04-13',
          reviewCount: 1068,
          rating: 4,
          salesRank: 1393
        },
        {
          date: '2024-04-14',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1907
        },
        {
          date: '2024-04-15',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1952
        },
        {
          date: '2024-04-16',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1724
        },
        {
          date: '2024-04-17',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1601
        },
        {
          date: '2024-04-18',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1487
        },
        {
          date: '2024-04-19',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1642
        },
        {
          date: '2024-04-20',
          reviewCount: 1179,
          rating: 4,
          salesRank: 1861
        },
        {
          date: '2024-04-21',
          reviewCount: 1296,
          rating: 4,
          salesRank: 2310
        },
        {
          date: '2024-04-22',
          reviewCount: 1296,
          rating: 4,
          salesRank: 2125
        },
        {
          date: '2024-04-23',
          reviewCount: 1296,
          rating: 4,
          salesRank: 2016
        },
        {
          date: '2024-04-24',
          reviewCount: 1296,
          rating: 4,
          salesRank: 1766
        },
        {
          date: '2024-04-25',
          reviewCount: 1360,
          rating: 4,
          salesRank: 1761
        },
        {
          date: '2024-04-26',
          reviewCount: 1360,
          rating: 4,
          salesRank: 1765
        },
        {
          date: '2024-04-27',
          reviewCount: 1360,
          rating: 4,
          salesRank: 1948
        },
        {
          date: '2024-04-28',
          reviewCount: 1407,
          rating: 4,
          salesRank: 2336
        },
        {
          date: '2024-04-29',
          reviewCount: 1407,
          rating: 4,
          salesRank: 2136
        },
        {
          date: '2024-04-30',
          reviewCount: 1407,
          rating: 4,
          salesRank: 1223
        },
        {
          date: '2024-05-01',
          reviewCount: 1407,
          rating: 4,
          salesRank: 1377
        },
        {
          date: '2024-05-02',
          reviewCount: 1469,
          rating: 4,
          salesRank: 1266
        },
        {
          date: '2024-05-03',
          reviewCount: 1469,
          rating: 4,
          salesRank: 1254
        },
        {
          date: '2024-05-04',
          reviewCount: 1469,
          rating: 4,
          salesRank: 1336
        },
        {
          date: '2024-05-05',
          reviewCount: 1512,
          rating: 4,
          salesRank: 1537
        },
        {
          date: '2024-05-06',
          reviewCount: 1512,
          rating: 4,
          salesRank: 1317
        },
        {
          date: '2024-05-07',
          reviewCount: 1512,
          rating: 4,
          salesRank: 1486
        },
        {
          date: '2024-05-08',
          reviewCount: 1512,
          rating: 4,
          salesRank: 1634
        },
        {
          date: '2024-05-09',
          reviewCount: 1574,
          rating: 4,
          salesRank: 1697
        },
        {
          date: '2024-05-10',
          reviewCount: 1574,
          rating: 4,
          salesRank: 1824
        },
        {
          date: '2024-05-11',
          reviewCount: 1574,
          rating: 4,
          salesRank: 2013
        },
        {
          date: '2024-05-12',
          reviewCount: 1609,
          rating: 4,
          salesRank: 2410
        },
        {
          date: '2024-05-13',
          reviewCount: 1609,
          rating: 4,
          salesRank: 1837
        },
        {
          date: '2024-05-14',
          reviewCount: 1609,
          rating: 4,
          salesRank: 1717
        },
        {
          date: '2024-05-15',
          reviewCount: 1609,
          rating: 4,
          salesRank: 2041
        },
        {
          date: '2024-05-16',
          reviewCount: 1658,
          rating: 4,
          salesRank: 2053
        },
        {
          date: '2024-05-17',
          reviewCount: 1658,
          rating: 4,
          salesRank: 2615
        },
        {
          date: '2024-05-18',
          reviewCount: 1658,
          rating: 4,
          salesRank: 2487
        },
        {
          date: '2024-05-19',
          reviewCount: 1692,
          rating: 4,
          salesRank: 2970
        },
        {
          date: '2024-05-20',
          reviewCount: 1692,
          rating: 4,
          salesRank: 2996
        },
        {
          date: '2024-05-21',
          reviewCount: 1692,
          rating: 4,
          salesRank: 2130
        },
        {
          date: '2024-05-22',
          reviewCount: 1692,
          rating: 4,
          salesRank: 1802
        },
        {
          date: '2024-05-23',
          reviewCount: 1728,
          rating: 4,
          salesRank: 1732
        },
        {
          date: '2024-05-24',
          reviewCount: 1728,
          rating: 4,
          salesRank: 1922
        },
        {
          date: '2024-05-25',
          reviewCount: 1728,
          rating: 4,
          salesRank: 2027
        },
        {
          date: '2024-05-26',
          reviewCount: 1789,
          rating: 4,
          salesRank: 2289
        },
        {
          date: '2024-05-27',
          reviewCount: 1789,
          rating: 4,
          salesRank: 2612
        },
        {
          date: '2024-05-28',
          reviewCount: 1789,
          rating: 4,
          salesRank: 2121
        },
        {
          date: '2024-05-29',
          reviewCount: 1789,
          rating: 4,
          salesRank: 2022
        },
        {
          date: '2024-05-30',
          reviewCount: 1844,
          rating: 4,
          salesRank: 1842
        },
        {
          date: '2024-05-31',
          reviewCount: 1844,
          rating: 4,
          salesRank: 1879
        },
        {
          date: '2024-06-01',
          reviewCount: 1844,
          rating: 4,
          salesRank: 1934
        },
        {
          date: '2024-06-02',
          reviewCount: 1881,
          rating: 4,
          salesRank: 2147
        },
        {
          date: '2024-06-03',
          reviewCount: 1881,
          rating: 4,
          salesRank: 1991
        },
        {
          date: '2024-06-04',
          reviewCount: 1881,
          rating: 4,
          salesRank: 1828
        },
        {
          date: '2024-06-05',
          reviewCount: 1881,
          rating: 4,
          salesRank: 1805
        },
        {
          date: '2024-06-06',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1749
        },
        {
          date: '2024-06-07',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1708
        },
        {
          date: '2024-06-08',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1773
        },
        {
          date: '2024-06-09',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1947
        },
        {
          date: '2024-06-10',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1576
        },
        {
          date: '2024-06-11',
          reviewCount: 1931,
          rating: 4,
          salesRank: 1391
        },
        {
          date: '2024-06-12',
          reviewCount: 2006,
          rating: 4,
          salesRank: 1237
        },
        {
          date: '2024-06-13',
          reviewCount: 2006,
          rating: 4,
          salesRank: 1143
        },
        {
          date: '2024-06-14',
          reviewCount: 2006,
          rating: 4,
          salesRank: 1150
        },
        {
          date: '2024-06-15',
          reviewCount: 2006,
          rating: 4,
          salesRank: 1209
        },
        {
          date: '2024-06-16',
          reviewCount: 2048,
          rating: 4,
          salesRank: 1505
        },
        {
          date: '2024-06-17',
          reviewCount: 2048,
          rating: 4,
          salesRank: 1440
        },
        {
          date: '2024-06-18',
          reviewCount: 2048,
          rating: 4,
          salesRank: 1347
        },
        {
          date: '2024-06-19',
          reviewCount: 2048,
          rating: 4,
          salesRank: 1345
        },
        {
          date: '2024-06-20',
          reviewCount: 2112,
          rating: 4,
          salesRank: 1364
        },
        {
          date: '2024-06-21',
          reviewCount: 2112,
          rating: 4,
          salesRank: 1424
        },
        {
          date: '2024-06-22',
          reviewCount: 2112,
          rating: 4,
          salesRank: 1673
        },
        {
          date: '2024-06-23',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1818
        },
        {
          date: '2024-06-24',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1258
        },
        {
          date: '2024-06-25',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1051
        },
        {
          date: '2024-06-26',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1009
        },
        {
          date: '2024-06-27',
          reviewCount: 2144,
          rating: 4,
          salesRank: 965
        },
        {
          date: '2024-06-28',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1035
        },
        {
          date: '2024-06-29',
          reviewCount: 2144,
          rating: 4,
          salesRank: 1182
        },
        {
          date: '2024-06-30',
          reviewCount: 2213,
          rating: 4,
          salesRank: 1304
        },
        {
          date: '2024-07-01',
          reviewCount: 2213,
          rating: 4,
          salesRank: 1238
        },
        {
          date: '2024-07-02',
          reviewCount: 2213,
          rating: 4,
          salesRank: 1372
        },
        {
          date: '2024-07-03',
          reviewCount: 2268,
          rating: 4,
          salesRank: 1403
        },
        {
          date: '2024-07-04',
          reviewCount: 2268,
          rating: 4,
          salesRank: 1864
        },
        {
          date: '2024-07-05',
          reviewCount: 2268,
          rating: 4,
          salesRank: 2143
        },
        {
          date: '2024-07-06',
          reviewCount: 2268,
          rating: 4,
          salesRank: 2498
        },
        {
          date: '2024-07-07',
          reviewCount: 2325,
          rating: 4,
          salesRank: 3257
        },
        {
          date: '2024-07-08',
          reviewCount: 2325,
          rating: 4,
          salesRank: 3156
        },
        {
          date: '2024-07-09',
          reviewCount: 2325,
          rating: 4,
          salesRank: 3048
        },
        {
          date: '2024-07-10',
          reviewCount: 2325,
          rating: 4,
          salesRank: 3230
        },
        {
          date: '2024-07-11',
          reviewCount: 2376,
          rating: 4,
          salesRank: 2849
        },
        {
          date: '2024-07-12',
          reviewCount: 2376,
          rating: 4,
          salesRank: 3225
        },
        {
          date: '2024-07-13',
          reviewCount: 2376,
          rating: 4,
          salesRank: 3601
        },
        {
          date: '2024-07-14',
          reviewCount: 2408,
          rating: 4,
          salesRank: 4038
        },
        {
          date: '2024-07-15',
          reviewCount: 2408,
          rating: 4,
          salesRank: 3509
        },
        {
          date: '2024-07-16',
          reviewCount: 2408,
          rating: 4,
          salesRank: 3374
        },
        {
          date: '2024-07-17',
          reviewCount: 2408,
          rating: 4,
          salesRank: 3887
        },
        {
          date: '2024-07-18',
          reviewCount: 2452,
          rating: 4,
          salesRank: 2252
        },
        {
          date: '2024-07-19',
          reviewCount: 2452,
          rating: 4,
          salesRank: 1996
        },
        {
          date: '2024-07-20',
          reviewCount: 2452,
          rating: 4,
          salesRank: 1220
        },
        {
          date: '2024-07-21',
          reviewCount: 2488,
          rating: 4,
          salesRank: 1323
        },
        {
          date: '2024-07-22',
          reviewCount: 2488,
          rating: 4,
          salesRank: 757
        },
        {
          date: '2024-07-23',
          reviewCount: 2488,
          rating: 4,
          salesRank: 381
        },
        {
          date: '2024-07-24',
          reviewCount: 2488,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2024-07-25',
          reviewCount: 2550,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2024-07-26',
          reviewCount: 2550,
          rating: 4,
          salesRank: 303
        },
        {
          date: '2024-07-27',
          reviewCount: 2550,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2024-07-28',
          reviewCount: 2595,
          rating: 4,
          salesRank: 345
        },
        {
          date: '2024-07-29',
          reviewCount: 2595,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2024-07-30',
          reviewCount: 2595,
          rating: 4,
          salesRank: 254
        },
        {
          date: '2024-07-31',
          reviewCount: 2595,
          rating: 4,
          salesRank: 205
        },
        {
          date: '2024-08-01',
          reviewCount: 2640,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2024-08-02',
          reviewCount: 2640,
          rating: 4,
          salesRank: 175
        },
        {
          date: '2024-08-03',
          reviewCount: 2640,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2024-08-04',
          reviewCount: 2693,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2024-08-05',
          reviewCount: 2693,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-08-06',
          reviewCount: 2693,
          rating: 4,
          salesRank: 222
        },
        {
          date: '2024-08-07',
          reviewCount: 2693,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2024-08-08',
          reviewCount: 2768,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 2768,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2024-08-10',
          reviewCount: 2768,
          rating: 4,
          salesRank: 214
        },
        {
          date: '2024-08-11',
          reviewCount: 2828,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2024-08-12',
          reviewCount: 2828,
          rating: 4,
          salesRank: 161
        },
        {
          date: '2024-08-13',
          reviewCount: 2828,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2024-08-14',
          reviewCount: 2828,
          rating: 4,
          salesRank: 111
        },
        {
          date: '2024-08-15',
          reviewCount: 2917,
          rating: 4,
          salesRank: 173
        },
        {
          date: '2024-08-16',
          reviewCount: 2917,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2024-08-17',
          reviewCount: 2917,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 2969,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 2969,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2024-08-20',
          reviewCount: 2969,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2024-08-21',
          reviewCount: 2969,
          rating: 4,
          salesRank: 350
        },
        {
          date: '2024-08-22',
          reviewCount: 3060,
          rating: 4,
          salesRank: 355
        },
        {
          date: '2024-08-23',
          reviewCount: 3060,
          rating: 4,
          salesRank: 403
        },
        {
          date: '2024-08-24',
          reviewCount: 3060,
          rating: 4,
          salesRank: 467
        },
        {
          date: '2024-08-25',
          reviewCount: 3156,
          rating: 4,
          salesRank: 647
        },
        {
          date: '2024-08-26',
          reviewCount: 3156,
          rating: 4,
          salesRank: 692
        },
        {
          date: '2024-08-27',
          reviewCount: 3156,
          rating: 4,
          salesRank: 700
        },
        {
          date: '2024-08-28',

          salesRank: 804
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-28T16:58:58.326Z'
    },
    {
      id: 'aaa54d0e-f523-4b51-8033-fa704f826b41',
      productName: 'Cordless Water Flosser',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-2.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2022-03-10',

          salesRank: 183417
        },
        {
          date: '2022-03-11',

          salesRank: 225557
        },
        {
          date: '2022-03-12',

          salesRank: 199065
        },
        {
          date: '2022-03-13',

          salesRank: 188599
        },
        {
          date: '2022-03-14',

          salesRank: 85251
        },
        {
          date: '2022-03-15',

          salesRank: 55600
        },
        {
          date: '2022-03-16',

          salesRank: 39474
        },
        {
          date: '2022-03-17',

          salesRank: 48324
        },
        {
          date: '2022-03-18',

          salesRank: 38001
        },
        {
          date: '2022-03-19',

          salesRank: 55949
        },
        {
          date: '2022-03-20',

          salesRank: 33355
        },
        {
          date: '2022-03-21',

          salesRank: 42742
        },
        {
          date: '2022-03-22',

          salesRank: 44168
        },
        {
          date: '2022-03-23',

          salesRank: 41153
        },
        {
          date: '2022-03-24',

          salesRank: 41788
        },
        {
          date: '2022-03-25',

          salesRank: 47435
        },
        {
          date: '2022-03-26',

          salesRank: 51925
        },
        {
          date: '2022-03-27',

          salesRank: 60918
        },
        {
          date: '2022-03-28',

          salesRank: 67460
        },
        {
          date: '2022-03-29',

          salesRank: 47629
        },
        {
          date: '2022-03-30',

          salesRank: 38704
        },
        {
          date: '2022-03-31',

          salesRank: 36633
        },
        {
          date: '2022-04-01',

          salesRank: 37933
        },
        {
          date: '2022-04-02',

          salesRank: 37295
        },
        {
          date: '2022-04-03',

          salesRank: 30297
        },
        {
          date: '2022-04-04',

          salesRank: 25003
        },
        {
          date: '2022-04-05',

          salesRank: 32299
        },
        {
          date: '2022-04-06',

          salesRank: 35931
        },
        {
          date: '2022-04-07',

          salesRank: 31030
        },
        {
          date: '2022-04-08',

          salesRank: 30325
        },
        {
          date: '2022-04-09',

          salesRank: 41514
        },
        {
          date: '2022-04-10',

          salesRank: 21510
        },
        {
          date: '2022-04-11',

          salesRank: 19769
        },
        {
          date: '2022-04-12',

          salesRank: 19851
        },
        {
          date: '2022-04-13',

          salesRank: 12091
        },
        {
          date: '2022-04-14',

          salesRank: 22955
        },
        {
          date: '2022-04-15',

          salesRank: 31014
        },
        {
          date: '2022-04-16',

          salesRank: 27376
        },
        {
          date: '2022-04-17',

          salesRank: 32105
        },
        {
          date: '2022-04-18',

          salesRank: 22428
        },
        {
          date: '2022-04-19',

          salesRank: 15861
        },
        {
          date: '2022-04-20',

          salesRank: 14372
        },
        {
          date: '2022-04-21',

          salesRank: 18575
        },
        {
          date: '2022-04-22',

          salesRank: 18487
        },
        {
          date: '2022-04-23',

          salesRank: 15510
        },
        {
          date: '2022-04-24',

          salesRank: 11416
        },
        {
          date: '2022-04-25',

          salesRank: 10247
        },
        {
          date: '2022-04-26',

          salesRank: 9413
        },
        {
          date: '2022-04-27',

          salesRank: 11321
        },
        {
          date: '2022-04-28',

          salesRank: 5217
        },
        {
          date: '2022-04-29',

          salesRank: 8499
        },
        {
          date: '2022-04-30',

          salesRank: 11044
        },
        {
          date: '2022-05-01',

          salesRank: 8604
        },
        {
          date: '2022-05-02',

          salesRank: 9417
        },
        {
          date: '2022-05-03',

          salesRank: 9841
        },
        {
          date: '2022-05-04',

          salesRank: 10261
        },
        {
          date: '2022-05-05',

          salesRank: 9527
        },
        {
          date: '2022-05-06',

          salesRank: 13256
        },
        {
          date: '2022-05-07',

          salesRank: 13757
        },
        {
          date: '2022-05-08',

          salesRank: 15281
        },
        {
          date: '2022-05-09',

          salesRank: 17581
        },
        {
          date: '2022-05-10',

          salesRank: 10375
        },
        {
          date: '2022-05-11',

          salesRank: 9921
        },
        {
          date: '2022-05-12',

          salesRank: 9413
        },
        {
          date: '2022-05-13',

          salesRank: 12180
        },
        {
          date: '2022-05-14',

          salesRank: 11216
        },
        {
          date: '2022-05-15',

          salesRank: 12111
        },
        {
          date: '2022-05-16',

          salesRank: 13371
        },
        {
          date: '2022-05-17',

          salesRank: 19615
        },
        {
          date: '2022-05-18',

          salesRank: 16978
        },
        {
          date: '2022-05-19',

          salesRank: 16031
        },
        {
          date: '2022-05-20',

          salesRank: 8699
        },
        {
          date: '2022-05-21',

          salesRank: 11623
        },
        {
          date: '2022-05-22',

          salesRank: 13093
        },
        {
          date: '2022-05-23',

          salesRank: 10627
        },
        {
          date: '2022-05-24',

          salesRank: 6977
        },
        {
          date: '2022-05-25',

          salesRank: 8908
        },
        {
          date: '2022-05-26',

          salesRank: 8370
        },
        {
          date: '2022-05-27',

          salesRank: 10409
        },
        {
          date: '2022-05-28',

          salesRank: 13368
        },
        {
          date: '2022-05-29',

          salesRank: 12372
        },
        {
          date: '2022-05-30',

          salesRank: 10256
        },
        {
          date: '2022-05-31',

          salesRank: 10753
        },
        {
          date: '2022-06-01',

          salesRank: 12814
        },
        {
          date: '2022-06-02',

          salesRank: 12783
        },
        {
          date: '2022-06-03',

          salesRank: 11275
        },
        {
          date: '2022-06-04',

          salesRank: 12806
        },
        {
          date: '2022-06-05',

          salesRank: 8702
        },
        {
          date: '2022-06-06',

          salesRank: 9280
        },
        {
          date: '2022-06-07',

          salesRank: 7163
        },
        {
          date: '2022-06-08',

          salesRank: 7050
        },
        {
          date: '2022-06-09',

          salesRank: 5721
        },
        {
          date: '2022-06-10',

          salesRank: 6431
        },
        {
          date: '2022-06-11',

          salesRank: 6067
        },
        {
          date: '2022-06-12',

          salesRank: 6689
        },
        {
          date: '2022-06-13',

          salesRank: 8229
        },
        {
          date: '2022-06-14',

          salesRank: 6182
        },
        {
          date: '2022-06-15',

          salesRank: 4881
        },
        {
          date: '2022-06-16',

          salesRank: 4038
        },
        {
          date: '2022-06-17',

          salesRank: 4126
        },
        {
          date: '2022-06-18',

          salesRank: 4719
        },
        {
          date: '2022-06-19',

          salesRank: 3908
        },
        {
          date: '2022-06-20',

          salesRank: 4046
        },
        {
          date: '2022-06-21',

          salesRank: 3447
        },
        {
          date: '2022-06-22',

          salesRank: 4009
        },
        {
          date: '2022-06-23',

          salesRank: 3339
        },
        {
          date: '2022-06-24',

          salesRank: 3780
        },
        {
          date: '2022-06-25',

          salesRank: 5180
        },
        {
          date: '2022-06-26',

          salesRank: 6736
        },
        {
          date: '2022-06-27',

          salesRank: 5851
        },
        {
          date: '2022-06-28',

          salesRank: 3733
        },
        {
          date: '2022-06-29',

          salesRank: 3543
        },
        {
          date: '2022-06-30',

          salesRank: 4160
        },
        {
          date: '2022-07-01',

          salesRank: 3646
        },
        {
          date: '2022-07-02',

          salesRank: 4369
        },
        {
          date: '2022-07-03',

          salesRank: 5083
        },
        {
          date: '2022-07-04',

          salesRank: 4822
        },
        {
          date: '2022-07-05',

          salesRank: 5092
        },
        {
          date: '2022-07-06',

          salesRank: 3744
        },
        {
          date: '2022-07-07',

          salesRank: 3337
        },
        {
          date: '2022-07-08',

          salesRank: 3131
        },
        {
          date: '2022-07-09',

          salesRank: 3716
        },
        {
          date: '2022-07-10',

          salesRank: 2842
        },
        {
          date: '2022-07-11',

          salesRank: 1613
        },
        {
          date: '2022-07-12',

          salesRank: 938
        },
        {
          date: '2022-07-13',

          salesRank: 1186
        },
        {
          date: '2022-07-14',

          salesRank: 1198
        },
        {
          date: '2022-07-15',

          salesRank: 1683
        },
        {
          date: '2022-07-16',

          salesRank: 2352
        },
        {
          date: '2022-07-17',

          salesRank: 3064
        },
        {
          date: '2022-07-18',

          salesRank: 3718
        },
        {
          date: '2022-07-19',

          salesRank: 2805
        },
        {
          date: '2022-07-20',

          salesRank: 2625
        },
        {
          date: '2022-07-21',

          salesRank: 2477
        },
        {
          date: '2022-07-22',

          salesRank: 2405
        },
        {
          date: '2022-07-23',

          salesRank: 2077
        },
        {
          date: '2022-07-24',

          salesRank: 1817
        },
        {
          date: '2022-07-25',

          salesRank: 1520
        },
        {
          date: '2022-07-26',

          salesRank: 1559
        },
        {
          date: '2022-07-27',

          salesRank: 1632
        },
        {
          date: '2022-07-28',

          salesRank: 1628
        },
        {
          date: '2022-07-29',

          salesRank: 1650
        },
        {
          date: '2022-07-30',

          salesRank: 1504
        },
        {
          date: '2022-07-31',

          salesRank: 1850
        },
        {
          date: '2022-08-01',

          salesRank: 2068
        },
        {
          date: '2022-08-02',

          salesRank: 1594
        },
        {
          date: '2022-08-03',

          salesRank: 1650
        },
        {
          date: '2022-08-04',

          salesRank: 1713
        },
        {
          date: '2022-08-05',

          salesRank: 873
        },
        {
          date: '2022-08-06',

          salesRank: 1346
        },
        {
          date: '2022-08-07',

          salesRank: 1686
        },
        {
          date: '2022-08-08',

          salesRank: 1687
        },
        {
          date: '2022-08-09',

          salesRank: 1488
        },
        {
          date: '2022-08-10',

          salesRank: 1285
        },
        {
          date: '2022-08-11',

          salesRank: 1206
        },
        {
          date: '2022-08-12',

          salesRank: 1110
        },
        {
          date: '2022-08-13',

          salesRank: 1261
        },
        {
          date: '2022-08-14',

          salesRank: 1563
        },
        {
          date: '2022-08-15',

          salesRank: 845
        },
        {
          date: '2022-08-16',

          salesRank: 737
        },
        {
          date: '2022-08-17',

          salesRank: 918
        },
        {
          date: '2022-08-18',

          salesRank: 955
        },
        {
          date: '2022-08-19',

          salesRank: 541
        },
        {
          date: '2022-08-20',

          salesRank: 515
        },
        {
          date: '2022-08-21',

          salesRank: 585
        },
        {
          date: '2022-08-22',

          salesRank: 942
        },
        {
          date: '2022-08-23',

          salesRank: 1053
        },
        {
          date: '2022-08-24',

          salesRank: 1178
        },
        {
          date: '2022-08-25',

          salesRank: 1189
        },
        {
          date: '2022-08-26',

          salesRank: 1272
        },
        {
          date: '2022-08-27',

          salesRank: 1159
        },
        {
          date: '2022-08-28',

          salesRank: 1477
        },
        {
          date: '2022-08-29',

          salesRank: 1372
        },
        {
          date: '2022-08-30',

          salesRank: 1402
        },
        {
          date: '2022-08-31',

          salesRank: 1260
        },
        {
          date: '2022-09-01',

          salesRank: 1256
        },
        {
          date: '2022-09-02',

          salesRank: 1333
        },
        {
          date: '2022-09-03',

          salesRank: 1575
        },
        {
          date: '2022-09-04',

          salesRank: 1655
        },
        {
          date: '2022-09-05',

          salesRank: 1318
        },
        {
          date: '2022-09-06',

          salesRank: 1620
        },
        {
          date: '2022-09-07',

          salesRank: 1438
        },
        {
          date: '2022-09-08',

          salesRank: 1438
        },
        {
          date: '2022-09-09',

          salesRank: 1423
        },
        {
          date: '2022-09-10',

          salesRank: 1836
        },
        {
          date: '2022-09-11',

          salesRank: 1640
        },
        {
          date: '2022-09-12',

          salesRank: 1599
        },
        {
          date: '2022-09-13',

          salesRank: 1550
        },
        {
          date: '2022-09-14',

          salesRank: 1805
        },
        {
          date: '2022-09-15',

          salesRank: 1612
        },
        {
          date: '2022-09-16',

          salesRank: 1835
        },
        {
          date: '2022-09-17',

          salesRank: 1840
        },
        {
          date: '2022-09-18',

          salesRank: 2157
        },
        {
          date: '2022-09-19',

          salesRank: 1906
        },
        {
          date: '2022-09-20',

          salesRank: 1718
        },
        {
          date: '2022-09-21',

          salesRank: 1180
        },
        {
          date: '2022-09-22',

          salesRank: 1104
        },
        {
          date: '2022-09-23',

          salesRank: 1396
        },
        {
          date: '2022-09-24',

          salesRank: 1670
        },
        {
          date: '2022-09-25',

          salesRank: 2453
        },
        {
          date: '2022-09-26',

          salesRank: 2367
        },
        {
          date: '2022-09-27',

          salesRank: 2186
        },
        {
          date: '2022-09-28',

          salesRank: 2318
        },
        {
          date: '2022-09-29',

          salesRank: 2194
        },
        {
          date: '2022-09-30',

          salesRank: 1916
        },
        {
          date: '2022-10-01',

          salesRank: 2238
        },
        {
          date: '2022-10-02',

          salesRank: 2840
        },
        {
          date: '2022-10-03',

          salesRank: 2995
        },
        {
          date: '2022-10-04',

          salesRank: 2541
        },
        {
          date: '2022-10-05',

          salesRank: 3037
        },
        {
          date: '2022-10-06',

          salesRank: 3302
        },
        {
          date: '2022-10-07',

          salesRank: 2219
        },
        {
          date: '2022-10-08',

          salesRank: 2209
        },
        {
          date: '2022-10-09',

          salesRank: 2539
        },
        {
          date: '2022-10-10',

          salesRank: 2449
        },
        {
          date: '2022-10-11',

          salesRank: 1703
        },
        {
          date: '2022-10-12',

          salesRank: 615
        },
        {
          date: '2022-10-13',

          salesRank: 614
        },
        {
          date: '2022-10-14',

          salesRank: 957
        },
        {
          date: '2022-10-15',

          salesRank: 1410
        },
        {
          date: '2022-10-16',

          salesRank: 1717
        },
        {
          date: '2022-10-17',

          salesRank: 968
        },
        {
          date: '2022-10-18',

          salesRank: 871
        },
        {
          date: '2022-10-19',

          salesRank: 833
        },
        {
          date: '2022-10-20',

          salesRank: 824
        },
        {
          date: '2022-10-21',

          salesRank: 1006
        },
        {
          date: '2022-10-22',

          salesRank: 943
        },
        {
          date: '2022-10-23',

          salesRank: 949
        },
        {
          date: '2022-10-24',

          salesRank: 1211
        },
        {
          date: '2022-10-25',

          salesRank: 1230
        },
        {
          date: '2022-10-26',

          salesRank: 1225
        },
        {
          date: '2022-10-27',

          salesRank: 1258
        },
        {
          date: '2022-10-28',

          salesRank: 1214
        },
        {
          date: '2022-10-29',

          salesRank: 1009
        },
        {
          date: '2022-10-30',

          salesRank: 797
        },
        {
          date: '2022-10-31',

          salesRank: 1144
        },
        {
          date: '2022-11-01',

          salesRank: 1343
        },
        {
          date: '2022-11-02',

          salesRank: 1248
        },
        {
          date: '2022-11-03',

          salesRank: 761
        },
        {
          date: '2022-11-04',

          salesRank: 1054
        },
        {
          date: '2022-11-05',

          salesRank: 1082
        },
        {
          date: '2022-11-06',

          salesRank: 1228
        },
        {
          date: '2022-11-07',

          salesRank: 740
        },
        {
          date: '2022-11-08',

          salesRank: 980
        },
        {
          date: '2022-11-09',

          salesRank: 1020
        },
        {
          date: '2022-11-10',

          salesRank: 1211
        },
        {
          date: '2022-11-11',

          salesRank: 1165
        },
        {
          date: '2022-11-12',

          salesRank: 1238
        },
        {
          date: '2022-11-13',

          salesRank: 1543
        },
        {
          date: '2022-11-14',

          salesRank: 332
        },
        {
          date: '2022-11-15',

          salesRank: 154
        },
        {
          date: '2022-11-16',

          salesRank: 151
        },
        {
          date: '2022-11-17',

          salesRank: 144
        },
        {
          date: '2022-11-18',

          salesRank: 135
        },
        {
          date: '2022-11-19',

          salesRank: 166
        },
        {
          date: '2022-11-20',

          salesRank: 217
        },
        {
          date: '2022-11-21',

          salesRank: 318
        },
        {
          date: '2022-11-22',

          salesRank: 390
        },
        {
          date: '2022-11-23',

          salesRank: 374
        },
        {
          date: '2022-11-24',

          salesRank: 391
        },
        {
          date: '2022-11-25',

          salesRank: 207
        },
        {
          date: '2022-11-26',

          salesRank: 270
        },
        {
          date: '2022-11-27',

          salesRank: 391
        },
        {
          date: '2022-11-28',

          salesRank: 255
        },
        {
          date: '2022-11-29',

          salesRank: 366
        },
        {
          date: '2022-11-30',

          salesRank: 512
        },
        {
          date: '2022-12-01',

          salesRank: 576
        },
        {
          date: '2022-12-02',

          salesRank: 718
        },
        {
          date: '2022-12-03',

          salesRank: 830
        },
        {
          date: '2022-12-04',

          salesRank: 896
        },
        {
          date: '2022-12-05',

          salesRank: 947
        },
        {
          date: '2022-12-06',

          salesRank: 987
        },
        {
          date: '2022-12-07',

          salesRank: 961
        },
        {
          date: '2022-12-08',

          salesRank: 824
        },
        {
          date: '2022-12-09',

          salesRank: 967
        },
        {
          date: '2022-12-10',

          salesRank: 1022
        },
        {
          date: '2022-12-11',

          salesRank: 1202
        },
        {
          date: '2022-12-12',

          salesRank: 362
        },
        {
          date: '2022-12-13',

          salesRank: 229
        },
        {
          date: '2022-12-14',

          salesRank: 240
        },
        {
          date: '2022-12-15',

          salesRank: 202
        },
        {
          date: '2022-12-16',

          salesRank: 198
        },
        {
          date: '2022-12-17',

          salesRank: 208
        },
        {
          date: '2022-12-18',

          salesRank: 179
        },
        {
          date: '2022-12-19',

          salesRank: 198
        },
        {
          date: '2022-12-20',

          salesRank: 246
        },
        {
          date: '2022-12-21',

          salesRank: 325
        },
        {
          date: '2022-12-22',

          salesRank: 369
        },
        {
          date: '2022-12-23',

          salesRank: 439
        },
        {
          date: '2022-12-24',

          salesRank: 563
        },
        {
          date: '2022-12-25',

          salesRank: 798
        },
        {
          date: '2022-12-26',

          salesRank: 953
        },
        {
          date: '2022-12-27',

          salesRank: 1183
        },
        {
          date: '2022-12-28',

          salesRank: 1264
        },
        {
          date: '2022-12-29',

          salesRank: 441
        },
        {
          date: '2022-12-30',

          salesRank: 1095
        },
        {
          date: '2022-12-31',

          salesRank: 1569
        },
        {
          date: '2023-01-01',

          salesRank: 1657
        },
        {
          date: '2023-01-02',

          salesRank: 1752
        },
        {
          date: '2023-01-03',

          salesRank: 1539
        },
        {
          date: '2023-01-04',

          salesRank: 1426
        },
        {
          date: '2023-01-05',

          salesRank: 1762
        },
        {
          date: '2023-01-06',

          salesRank: 1326
        },
        {
          date: '2023-01-07',

          salesRank: 1286
        },
        {
          date: '2023-01-08',

          salesRank: 1191
        },
        {
          date: '2023-01-09',

          salesRank: 1245
        },
        {
          date: '2023-01-10',

          salesRank: 1243
        },
        {
          date: '2023-01-11',

          salesRank: 1214
        },
        {
          date: '2023-01-12',

          salesRank: 1254
        },
        {
          date: '2023-01-13',

          salesRank: 498
        },
        {
          date: '2023-01-14',

          salesRank: 519
        },
        {
          date: '2023-01-15',

          salesRank: 784
        },
        {
          date: '2023-01-16',

          salesRank: 1040
        },
        {
          date: '2023-01-17',

          salesRank: 1279
        },
        {
          date: '2023-01-18',

          salesRank: 1394
        },
        {
          date: '2023-01-19',

          salesRank: 1275
        },
        {
          date: '2023-01-20',

          salesRank: 1385
        },
        {
          date: '2023-01-21',

          salesRank: 2132
        },
        {
          date: '2023-01-22',

          salesRank: 2579
        },
        {
          date: '2023-01-23',

          salesRank: 3107
        },
        {
          date: '2023-01-24',

          salesRank: 3159
        },
        {
          date: '2023-01-25',

          salesRank: 2779
        },
        {
          date: '2023-01-26',

          salesRank: 2472
        },
        {
          date: '2023-01-27',

          salesRank: 2679
        },
        {
          date: '2023-01-28',

          salesRank: 3329
        },
        {
          date: '2023-01-29',

          salesRank: 3637
        },
        {
          date: '2023-01-30',

          salesRank: 3351
        },
        {
          date: '2023-01-31',

          salesRank: 3764
        },
        {
          date: '2023-02-01',

          salesRank: 2460
        },
        {
          date: '2023-02-02',

          salesRank: 1984
        },
        {
          date: '2023-02-03',

          salesRank: 1509
        },
        {
          date: '2023-02-04',

          salesRank: 1915
        },
        {
          date: '2023-02-05',

          salesRank: 2748
        },
        {
          date: '2023-02-06',

          salesRank: 1283
        },
        {
          date: '2023-02-07',

          salesRank: 455
        },
        {
          date: '2023-02-08',

          salesRank: 291
        },
        {
          date: '2023-02-09',

          salesRank: 170
        },
        {
          date: '2023-02-10',

          salesRank: 27
        },
        {
          date: '2023-02-11',

          salesRank: 151
        },
        {
          date: '2023-02-12',

          salesRank: 269
        },
        {
          date: '2023-02-13',

          salesRank: 518
        },
        {
          date: '2023-02-14',

          salesRank: 761
        },
        {
          date: '2023-02-15',

          salesRank: 1116
        },
        {
          date: '2023-02-16',

          salesRank: 1276
        },
        {
          date: '2023-02-17',

          salesRank: 1135
        },
        {
          date: '2023-02-18',

          salesRank: 1599
        },
        {
          date: '2023-02-19',

          salesRank: 2009
        },
        {
          date: '2023-02-20',

          salesRank: 1966
        },
        {
          date: '2023-02-21',

          salesRank: 2221
        },
        {
          date: '2023-02-22',

          salesRank: 2425
        },
        {
          date: '2023-02-23',

          salesRank: 1950
        },
        {
          date: '2023-02-27',

          salesRank: 1553
        },
        {
          date: '2023-02-28',

          salesRank: 1255
        },
        {
          date: '2023-03-01',

          salesRank: 1557
        },
        {
          date: '2023-03-02',

          salesRank: 1839
        },
        {
          date: '2023-03-03',

          salesRank: 2205
        },
        {
          date: '2023-03-04',

          salesRank: 2492
        },
        {
          date: '2023-03-05',

          salesRank: 2990
        },
        {
          date: '2023-03-06',

          salesRank: 961
        },
        {
          date: '2023-03-07',

          salesRank: 391
        },
        {
          date: '2023-03-08',

          salesRank: 287
        },
        {
          date: '2023-03-09',

          salesRank: 330
        },
        {
          date: '2023-03-10',

          salesRank: 198
        },
        {
          date: '2023-03-11',

          salesRank: 273
        },
        {
          date: '2023-03-12',

          salesRank: 369
        },
        {
          date: '2023-03-13',

          salesRank: 624
        },
        {
          date: '2023-03-14',

          salesRank: 932
        },
        {
          date: '2023-03-15',

          salesRank: 927
        },
        {
          date: '2023-03-16',

          salesRank: 1023
        },
        {
          date: '2023-03-17',

          salesRank: 1258
        },
        {
          date: '2023-03-18',

          salesRank: 1317
        },
        {
          date: '2023-03-19',

          salesRank: 1457
        },
        {
          date: '2023-03-20',

          salesRank: 2296
        },
        {
          date: '2023-03-21',

          salesRank: 1377
        },
        {
          date: '2023-03-22',

          salesRank: 1667
        },
        {
          date: '2023-03-23',

          salesRank: 1045
        },
        {
          date: '2023-03-24',

          salesRank: 712
        },
        {
          date: '2023-03-25',

          salesRank: 1072
        },
        {
          date: '2023-03-26',

          salesRank: 1368
        },
        {
          date: '2023-03-27',

          salesRank: 1830
        },
        {
          date: '2023-03-28',

          salesRank: 1903
        },
        {
          date: '2023-03-29',

          salesRank: 2344
        },
        {
          date: '2023-03-30',

          salesRank: 2051
        },
        {
          date: '2023-03-31',

          salesRank: 2108
        },
        {
          date: '2023-04-01',

          salesRank: 2095
        },
        {
          date: '2023-04-02',

          salesRank: 1934
        },
        {
          date: '2023-04-03',

          salesRank: 877
        },
        {
          date: '2023-04-04',

          salesRank: 289
        },
        {
          date: '2023-04-05',

          salesRank: 217
        },
        {
          date: '2023-04-06',

          salesRank: 162
        },
        {
          date: '2023-04-07',

          salesRank: 229
        },
        {
          date: '2023-04-08',

          salesRank: 312
        },
        {
          date: '2023-04-09',

          salesRank: 425
        },
        {
          date: '2023-04-10',

          salesRank: 746
        },
        {
          date: '2023-04-11',

          salesRank: 1012
        },
        {
          date: '2023-04-12',

          salesRank: 1220
        },
        {
          date: '2023-04-13',

          salesRank: 1234
        },
        {
          date: '2023-04-14',

          salesRank: 1406
        },
        {
          date: '2023-04-15',

          salesRank: 1644
        },
        {
          date: '2023-04-16',

          salesRank: 1978
        },
        {
          date: '2023-04-17',

          salesRank: 1608
        },
        {
          date: '2023-04-18',

          salesRank: 1632
        },
        {
          date: '2023-04-19',

          salesRank: 1720
        },
        {
          date: '2023-04-20',

          salesRank: 1641
        },
        {
          date: '2023-04-21',

          salesRank: 1702
        },
        {
          date: '2023-04-22',

          salesRank: 1349
        },
        {
          date: '2023-04-23',

          salesRank: 1974
        },
        {
          date: '2023-04-24',

          salesRank: 2031
        },
        {
          date: '2023-04-25',

          salesRank: 1158
        },
        {
          date: '2023-04-26',

          salesRank: 995
        },
        {
          date: '2023-04-27',

          salesRank: 1082
        },
        {
          date: '2023-04-28',

          salesRank: 1299
        },
        {
          date: '2023-04-29',

          salesRank: 1499
        },
        {
          date: '2023-04-30',

          salesRank: 1605
        },
        {
          date: '2023-05-01',

          salesRank: 881
        },
        {
          date: '2023-05-02',

          salesRank: 713
        },
        {
          date: '2023-05-03',

          salesRank: 841
        },
        {
          date: '2023-05-04',

          salesRank: 724
        },
        {
          date: '2023-05-05',

          salesRank: 639
        },
        {
          date: '2023-05-06',

          salesRank: 724
        },
        {
          date: '2023-05-07',

          salesRank: 758
        },
        {
          date: '2023-05-08',

          salesRank: 1039
        },
        {
          date: '2023-05-09',

          salesRank: 1490
        },
        {
          date: '2023-05-10',

          salesRank: 1920
        },
        {
          date: '2023-05-11',

          salesRank: 2068
        },
        {
          date: '2023-05-12',

          salesRank: 2318
        },
        {
          date: '2023-05-13',

          salesRank: 2785
        },
        {
          date: '2023-05-14',

          salesRank: 3155
        },
        {
          date: '2023-05-15',

          salesRank: 3295
        },
        {
          date: '2023-05-16',

          salesRank: 3197
        },
        {
          date: '2023-05-17',

          salesRank: 4005
        },
        {
          date: '2023-05-18',

          salesRank: 3516
        },
        {
          date: '2023-05-19',

          salesRank: 2996
        },
        {
          date: '2023-05-20',

          salesRank: 3202
        },
        {
          date: '2023-05-21',

          salesRank: 3915
        },
        {
          date: '2023-05-22',

          salesRank: 3794
        },
        {
          date: '2023-05-23',

          salesRank: 4034
        },
        {
          date: '2023-05-24',

          salesRank: 2886
        },
        {
          date: '2023-05-25',

          salesRank: 2844
        },
        {
          date: '2023-05-26',

          salesRank: 3142
        },
        {
          date: '2023-05-27',

          salesRank: 3749
        },
        {
          date: '2023-05-28',

          salesRank: 3810
        },
        {
          date: '2023-05-29',

          salesRank: 3810
        },
        {
          date: '2023-05-30',

          salesRank: 3594
        },
        {
          date: '2023-05-31',

          salesRank: 1023
        },
        {
          date: '2023-06-01',

          salesRank: 1889
        },
        {
          date: '2023-06-02',

          salesRank: 2269
        },
        {
          date: '2023-06-03',

          salesRank: 2288
        },
        {
          date: '2023-06-04',

          salesRank: 2421
        },
        {
          date: '2023-06-05',

          salesRank: 2474
        },
        {
          date: '2023-06-06',

          salesRank: 2582
        },
        {
          date: '2023-06-07',

          salesRank: 2386
        },
        {
          date: '2023-06-08',

          salesRank: 2177
        },
        {
          date: '2023-06-09',

          salesRank: 1923
        },
        {
          date: '2023-06-10',

          salesRank: 2012
        },
        {
          date: '2023-06-11',

          salesRank: 2363
        },
        {
          date: '2023-06-12',

          salesRank: 2285
        },
        {
          date: '2023-06-13',

          salesRank: 2254
        },
        {
          date: '2023-06-14',

          salesRank: 1712
        },
        {
          date: '2023-06-15',

          salesRank: 1541
        },
        {
          date: '2023-06-16',

          salesRank: 1388
        },
        {
          date: '2023-06-17',

          salesRank: 1267
        },
        {
          date: '2023-06-18',

          salesRank: 1189
        },
        {
          date: '2023-06-19',

          salesRank: 665
        },
        {
          date: '2023-06-20',

          salesRank: 512
        },
        {
          date: '2023-06-21',

          salesRank: 459
        },
        {
          date: '2023-06-22',

          salesRank: 450
        },
        {
          date: '2023-06-23',

          salesRank: 410
        },
        {
          date: '2023-06-24',

          salesRank: 412
        },
        {
          date: '2023-06-25',

          salesRank: 434
        },
        {
          date: '2023-06-26',

          salesRank: 496
        },
        {
          date: '2023-06-27',

          salesRank: 668
        },
        {
          date: '2023-06-28',

          salesRank: 838
        },
        {
          date: '2023-06-29',

          salesRank: 709
        },
        {
          date: '2023-06-30',

          salesRank: 838
        },
        {
          date: '2023-07-01',

          salesRank: 817
        },
        {
          date: '2023-07-02',

          salesRank: 1010
        },
        {
          date: '2023-07-03',

          salesRank: 959
        },
        {
          date: '2023-07-04',

          salesRank: 958
        },
        {
          date: '2023-07-05',

          salesRank: 748
        },
        {
          date: '2023-07-06',

          salesRank: 653
        },
        {
          date: '2023-07-07',

          salesRank: 648
        },
        {
          date: '2023-07-08',

          salesRank: 787
        },
        {
          date: '2023-07-09',

          salesRank: 404
        },
        {
          date: '2023-07-10',

          salesRank: 598
        },
        {
          date: '2023-07-11',

          salesRank: 407
        },
        {
          date: '2023-07-12',

          salesRank: 440
        },
        {
          date: '2023-07-13',

          salesRank: 63
        },
        {
          date: '2023-07-14',

          salesRank: 115
        },
        {
          date: '2023-07-15',

          salesRank: 194
        },
        {
          date: '2023-07-16',

          salesRank: 317
        },
        {
          date: '2023-07-17',

          salesRank: 396
        },
        {
          date: '2023-07-18',

          salesRank: 443
        },
        {
          date: '2023-07-19',

          salesRank: 473
        },
        {
          date: '2023-07-20',

          salesRank: 418
        },
        {
          date: '2023-07-21',

          salesRank: 366
        },
        {
          date: '2023-07-22',

          salesRank: 347
        },
        {
          date: '2023-07-23',

          salesRank: 386
        },
        {
          date: '2023-07-24',

          salesRank: 489
        },
        {
          date: '2023-07-25',

          salesRank: 572
        },
        {
          date: '2023-07-26',

          salesRank: 643
        },
        {
          date: '2023-07-27',

          salesRank: 620
        },
        {
          date: '2023-07-28',

          salesRank: 395
        },
        {
          date: '2023-07-29',

          salesRank: 435
        },
        {
          date: '2023-07-30',

          salesRank: 606
        },
        {
          date: '2023-07-31',

          salesRank: 702
        },
        {
          date: '2023-08-01',

          salesRank: 648
        },
        {
          date: '2023-08-02',

          salesRank: 703
        },
        {
          date: '2023-08-03',

          salesRank: 721
        },
        {
          date: '2023-08-04',

          salesRank: 694
        },
        {
          date: '2023-08-05',

          salesRank: 615
        },
        {
          date: '2023-08-06',

          salesRank: 607
        },
        {
          date: '2023-08-07',

          salesRank: 697
        },
        {
          date: '2023-08-08',

          salesRank: 468
        },
        {
          date: '2023-08-09',

          salesRank: 527
        },
        {
          date: '2023-08-10',

          salesRank: 643
        },
        {
          date: '2023-08-11',

          salesRank: 457
        },
        {
          date: '2023-08-12',

          salesRank: 687
        },
        {
          date: '2023-08-13',

          salesRank: 1034
        },
        {
          date: '2023-08-14',

          salesRank: 683
        },
        {
          date: '2023-08-15',

          salesRank: 790
        },
        {
          date: '2023-08-16',

          salesRank: 662
        },
        {
          date: '2023-08-17',

          salesRank: 726
        },
        {
          date: '2023-08-18',

          salesRank: 733
        },
        {
          date: '2023-08-19',

          salesRank: 854
        },
        {
          date: '2023-08-20',

          salesRank: 1021
        },
        {
          date: '2023-08-21',

          salesRank: 970
        },
        {
          date: '2023-08-22',

          salesRank: 704
        },
        {
          date: '2023-08-23',

          salesRank: 433
        },
        {
          date: '2023-08-24',

          salesRank: 461
        },
        {
          date: '2023-08-25',

          salesRank: 504
        },
        {
          date: '2023-08-26',

          salesRank: 545
        },
        {
          date: '2023-08-27',

          salesRank: 693
        },
        {
          date: '2023-08-28',

          salesRank: 835
        },
        {
          date: '2023-08-29',

          salesRank: 837
        },
        {
          date: '2023-08-30',

          salesRank: 599
        },
        {
          date: '2023-08-31',

          salesRank: 873
        },
        {
          date: '2023-09-01',

          salesRank: 1019
        },
        {
          date: '2023-09-02',

          salesRank: 649
        },
        {
          date: '2023-09-03',

          salesRank: 1070
        },
        {
          date: '2023-09-04',

          salesRank: 1298
        },
        {
          date: '2023-09-05',

          salesRank: 1133
        },
        {
          date: '2023-09-06',

          salesRank: 1154
        },
        {
          date: '2023-09-07',

          salesRank: 768
        },
        {
          date: '2023-09-08',

          salesRank: 838
        },
        {
          date: '2023-09-09',

          salesRank: 907
        },
        {
          date: '2023-09-10',

          salesRank: 1099
        },
        {
          date: '2023-09-11',

          salesRank: 828
        },
        {
          date: '2023-09-12',

          salesRank: 902
        },
        {
          date: '2023-09-13',

          salesRank: 760
        },
        {
          date: '2023-09-14',

          salesRank: 868
        },
        {
          date: '2023-09-15',

          salesRank: 944
        },
        {
          date: '2023-09-16',

          salesRank: 1095
        },
        {
          date: '2023-09-17',

          salesRank: 1230
        },
        {
          date: '2023-09-18',

          salesRank: 890
        },
        {
          date: '2023-09-19',

          salesRank: 767
        },
        {
          date: '2023-09-20',

          salesRank: 887
        },
        {
          date: '2023-09-21',

          salesRank: 755
        },
        {
          date: '2023-09-22',

          salesRank: 738
        },
        {
          date: '2023-09-23',

          salesRank: 847
        },
        {
          date: '2023-09-24',

          salesRank: 1017
        },
        {
          date: '2023-09-25',

          salesRank: 627
        },
        {
          date: '2023-09-26',

          salesRank: 1029
        },
        {
          date: '2023-09-27',

          salesRank: 1146
        },
        {
          date: '2023-09-28',

          salesRank: 1322
        },
        {
          date: '2023-09-29',

          salesRank: 1257
        },
        {
          date: '2023-09-30',

          salesRank: 1083
        },
        {
          date: '2023-10-01',

          salesRank: 829
        },
        {
          date: '2023-10-02',

          salesRank: 1043
        },
        {
          date: '2023-10-03',

          salesRank: 1134
        },
        {
          date: '2023-10-04',

          salesRank: 1168
        },
        {
          date: '2023-10-05',

          salesRank: 1086
        },
        {
          date: '2023-10-06',

          salesRank: 2481
        },
        {
          date: '2023-10-07',

          salesRank: 2622
        },
        {
          date: '2023-10-08',

          salesRank: 3128
        },
        {
          date: '2023-10-09',

          salesRank: 1447
        },
        {
          date: '2023-10-10',

          salesRank: 564
        },
        {
          date: '2023-10-11',

          salesRank: 201
        },
        {
          date: '2023-10-12',

          salesRank: 268
        },
        {
          date: '2023-10-13',

          salesRank: 418
        },
        {
          date: '2023-10-14',

          salesRank: 562
        },
        {
          date: '2023-10-15',

          salesRank: 839
        },
        {
          date: '2023-10-16',

          salesRank: 912
        },
        {
          date: '2023-10-17',

          salesRank: 999
        },
        {
          date: '2023-10-18',

          salesRank: 1085
        },
        {
          date: '2023-10-19',

          salesRank: 1226
        },
        {
          date: '2023-10-20',

          salesRank: 1391
        },
        {
          date: '2023-10-21',

          salesRank: 1209
        },
        {
          date: '2023-10-22',

          salesRank: 1339
        },
        {
          date: '2023-10-23',

          salesRank: 1905
        },
        {
          date: '2023-10-24',

          salesRank: 915
        },
        {
          date: '2023-10-25',

          salesRank: 1253
        },
        {
          date: '2023-10-26',

          salesRank: 1181
        },
        {
          date: '2023-10-27',

          salesRank: 1044
        },
        {
          date: '2023-10-28',

          salesRank: 459
        },
        {
          date: '2023-10-29',

          salesRank: 866
        },
        {
          date: '2023-10-30',

          salesRank: 886
        },
        {
          date: '2023-10-31',

          salesRank: 867
        },
        {
          date: '2023-11-01',

          salesRank: 792
        },
        {
          date: '2023-11-02',

          salesRank: 712
        },
        {
          date: '2023-11-03',

          salesRank: 564
        },
        {
          date: '2023-11-04',

          salesRank: 541
        },
        {
          date: '2023-11-05',

          salesRank: 621
        },
        {
          date: '2023-11-06',

          salesRank: 626
        },
        {
          date: '2023-11-07',

          salesRank: 627
        },
        {
          date: '2023-11-08',

          salesRank: 729
        },
        {
          date: '2023-11-09',

          salesRank: 762
        },
        {
          date: '2023-11-10',

          salesRank: 833
        },
        {
          date: '2023-11-11',

          salesRank: 561
        },
        {
          date: '2023-11-12',

          salesRank: 836
        },
        {
          date: '2023-11-13',

          salesRank: 828
        },
        {
          date: '2023-11-14',

          salesRank: 619
        },
        {
          date: '2023-11-15',

          salesRank: 810
        },
        {
          date: '2023-11-16',

          salesRank: 795
        },
        {
          date: '2023-11-17',

          salesRank: 673
        },
        {
          date: '2023-11-18',

          salesRank: 860
        },
        {
          date: '2023-11-19',

          salesRank: 619
        },
        {
          date: '2023-11-20',

          salesRank: 817
        },
        {
          date: '2023-11-21',

          salesRank: 768
        },
        {
          date: '2023-11-22',

          salesRank: 807
        },
        {
          date: '2023-11-23',

          salesRank: 540
        },
        {
          date: '2023-11-24',

          salesRank: 304
        },
        {
          date: '2023-11-25',

          salesRank: 256
        },
        {
          date: '2023-11-26',

          salesRank: 472
        },
        {
          date: '2023-11-27',

          salesRank: 449
        },
        {
          date: '2023-11-28',

          salesRank: 496
        },
        {
          date: '2023-11-29',

          salesRank: 738
        },
        {
          date: '2023-11-30',

          salesRank: 860
        },
        {
          date: '2023-12-01',

          salesRank: 888
        },
        {
          date: '2023-12-02',

          salesRank: 1029
        },
        {
          date: '2023-12-03',

          salesRank: 577
        },
        {
          date: '2023-12-04',

          salesRank: 552
        },
        {
          date: '2023-12-05',

          salesRank: 493
        },
        {
          date: '2023-12-06',

          salesRank: 525
        },
        {
          date: '2023-12-07',

          salesRank: 375
        },
        {
          date: '2023-12-08',

          salesRank: 346
        },
        {
          date: '2023-12-09',

          salesRank: 411
        },
        {
          date: '2023-12-10',

          salesRank: 446
        },
        {
          date: '2023-12-11',

          salesRank: 527
        },
        {
          date: '2023-12-12',

          salesRank: 606
        },
        {
          date: '2023-12-13',

          salesRank: 724
        },
        {
          date: '2023-12-14',

          salesRank: 738
        },
        {
          date: '2023-12-15',

          salesRank: 422
        },
        {
          date: '2023-12-16',

          salesRank: 570
        },
        {
          date: '2023-12-17',

          salesRank: 658
        },
        {
          date: '2023-12-18',

          salesRank: 666
        },
        {
          date: '2023-12-19',

          salesRank: 559
        },
        {
          date: '2023-12-20',

          salesRank: 425
        },
        {
          date: '2023-12-21',

          salesRank: 465
        },
        {
          date: '2023-12-22',

          salesRank: 413
        },
        {
          date: '2023-12-23',

          salesRank: 604
        },
        {
          date: '2023-12-24',

          salesRank: 742
        },
        {
          date: '2023-12-25',

          salesRank: 903
        },
        {
          date: '2023-12-26',

          salesRank: 1014
        },
        {
          date: '2023-12-27',

          salesRank: 1069
        },
        {
          date: '2023-12-28',

          salesRank: 1066
        },
        {
          date: '2023-12-29',

          salesRank: 1205
        },
        {
          date: '2023-12-30',

          salesRank: 1155
        },
        {
          date: '2023-12-31',

          salesRank: 1116
        },
        {
          date: '2024-01-01',

          salesRank: 1038
        },
        {
          date: '2024-01-02',

          salesRank: 1023
        },
        {
          date: '2024-01-03',

          salesRank: 989
        },
        {
          date: '2024-01-04',

          salesRank: 807
        },
        {
          date: '2024-01-05',

          salesRank: 677
        },
        {
          date: '2024-01-06',

          salesRank: 670
        },
        {
          date: '2024-01-07',

          salesRank: 685
        },
        {
          date: '2024-01-08',

          salesRank: 644
        },
        {
          date: '2024-01-09',

          salesRank: 772
        },
        {
          date: '2024-01-10',

          salesRank: 1096
        },
        {
          date: '2024-01-11',

          salesRank: 1008
        },
        {
          date: '2024-01-12',

          salesRank: 1088
        },
        {
          date: '2024-01-13',

          salesRank: 1105
        },
        {
          date: '2024-01-14',

          salesRank: 1244
        },
        {
          date: '2024-01-15',

          salesRank: 1241
        },
        {
          date: '2024-01-16',

          salesRank: 1346
        },
        {
          date: '2024-01-17',

          salesRank: 1102
        },
        {
          date: '2024-01-18',

          salesRank: 1174
        },
        {
          date: '2024-01-19',

          salesRank: 1177
        },
        {
          date: '2024-01-20',

          salesRank: 1335
        },
        {
          date: '2024-01-21',

          salesRank: 982
        },
        {
          date: '2024-01-22',

          salesRank: 1237
        },
        {
          date: '2024-01-23',

          salesRank: 1187
        },
        {
          date: '2024-01-24',

          salesRank: 847
        },
        {
          date: '2024-01-25',

          salesRank: 1044
        },
        {
          date: '2024-01-26',

          salesRank: 1106
        },
        {
          date: '2024-01-27',

          salesRank: 1287
        },
        {
          date: '2024-01-28',

          salesRank: 1414
        },
        {
          date: '2024-01-29',

          salesRank: 1066
        },
        {
          date: '2024-01-30',

          salesRank: 609
        },
        {
          date: '2024-01-31',

          salesRank: 580
        },
        {
          date: '2024-02-01',

          salesRank: 505
        },
        {
          date: '2024-02-02',

          salesRank: 284
        },
        {
          date: '2024-02-03',

          salesRank: 412
        },
        {
          date: '2024-02-04',

          salesRank: 459
        },
        {
          date: '2024-02-05',

          salesRank: 587
        },
        {
          date: '2024-02-06',

          salesRank: 668
        },
        {
          date: '2024-02-07',

          salesRank: 517
        },
        {
          date: '2024-02-08',

          salesRank: 688
        },
        {
          date: '2024-02-09',

          salesRank: 581
        },
        {
          date: '2024-02-10',

          salesRank: 503
        },
        {
          date: '2024-02-11',

          salesRank: 539
        },
        {
          date: '2024-02-12',

          salesRank: 522
        },
        {
          date: '2024-02-13',

          salesRank: 490
        },
        {
          date: '2024-02-14',

          salesRank: 584
        },
        {
          date: '2024-02-15',

          salesRank: 555
        },
        {
          date: '2024-02-16',

          salesRank: 490
        },
        {
          date: '2024-02-17',

          salesRank: 587
        },
        {
          date: '2024-02-18',

          salesRank: 638
        },
        {
          date: '2024-02-19',

          salesRank: 600
        },
        {
          date: '2024-02-20',

          salesRank: 379
        },
        {
          date: '2024-02-21',

          salesRank: 293
        },
        {
          date: '2024-02-22',

          salesRank: 245
        },
        {
          date: '2024-02-23',

          salesRank: 167
        },
        {
          date: '2024-02-24',

          salesRank: 178
        },
        {
          date: '2024-02-25',

          salesRank: 159
        },
        {
          date: '2024-02-26',

          salesRank: 223
        },
        {
          date: '2024-02-27',

          salesRank: 308
        },
        {
          date: '2024-02-28',

          salesRank: 355
        },
        {
          date: '2024-02-29',

          salesRank: 318
        },
        {
          date: '2024-03-01',

          salesRank: 374
        },
        {
          date: '2024-03-02',

          salesRank: 330
        },
        {
          date: '2024-03-03',

          salesRank: 367
        },
        {
          date: '2024-03-04',

          salesRank: 629
        },
        {
          date: '2024-03-05',

          salesRank: 458
        },
        {
          date: '2024-03-06',

          salesRank: 560
        },
        {
          date: '2024-03-07',

          salesRank: 523
        },
        {
          date: '2024-03-08',

          salesRank: 493
        },
        {
          date: '2024-03-09',

          salesRank: 425
        },
        {
          date: '2024-03-10',

          salesRank: 495
        },
        {
          date: '2024-03-11',

          salesRank: 547
        },
        {
          date: '2024-03-12',

          salesRank: 450
        },
        {
          date: '2024-03-13',

          salesRank: 454
        },
        {
          date: '2024-03-14',

          salesRank: 477
        },
        {
          date: '2024-03-15',

          salesRank: 420
        },
        {
          date: '2024-03-16',

          salesRank: 484
        },
        {
          date: '2024-03-17',

          salesRank: 604
        },
        {
          date: '2024-03-18',

          salesRank: 585
        },
        {
          date: '2024-03-19',

          salesRank: 540
        },
        {
          date: '2024-03-20',

          salesRank: 301
        },
        {
          date: '2024-03-21',

          salesRank: 484
        },
        {
          date: '2024-03-22',

          salesRank: 513
        },
        {
          date: '2024-03-23',

          salesRank: 871
        },
        {
          date: '2024-03-24',

          salesRank: 1036
        },
        {
          date: '2024-03-25',

          salesRank: 1260
        },
        {
          date: '2024-03-26',

          salesRank: 1233
        },
        {
          date: '2024-03-27',

          salesRank: 981
        },
        {
          date: '2024-03-28',

          salesRank: 740
        },
        {
          date: '2024-03-29',

          salesRank: 809
        },
        {
          date: '2024-03-30',

          salesRank: 884
        },
        {
          date: '2024-03-31',

          salesRank: 819
        },
        {
          date: '2024-04-01',

          salesRank: 921
        },
        {
          date: '2024-04-02',

          salesRank: 788
        },
        {
          date: '2024-04-03',

          salesRank: 651
        },
        {
          date: '2024-04-04',

          salesRank: 443
        },
        {
          date: '2024-04-05',

          salesRank: 432
        },
        {
          date: '2024-04-06',

          salesRank: 555
        },
        {
          date: '2024-04-07',

          salesRank: 695
        },
        {
          date: '2024-04-08',

          salesRank: 830
        },
        {
          date: '2024-04-09',

          salesRank: 905
        },
        {
          date: '2024-04-10',

          salesRank: 774
        },
        {
          date: '2024-04-11',

          salesRank: 896
        },
        {
          date: '2024-04-12',

          salesRank: 862
        },
        {
          date: '2024-04-13',

          salesRank: 929
        },
        {
          date: '2024-04-14',

          salesRank: 1157
        },
        {
          date: '2024-04-15',

          salesRank: 1160
        },
        {
          date: '2024-04-16',

          salesRank: 1315
        },
        {
          date: '2024-04-17',

          salesRank: 1375
        },
        {
          date: '2024-04-18',

          salesRank: 1089
        },
        {
          date: '2024-04-19',

          salesRank: 906
        },
        {
          date: '2024-04-20',

          salesRank: 979
        },
        {
          date: '2024-04-21',

          salesRank: 1249
        },
        {
          date: '2024-04-22',

          salesRank: 1174
        },
        {
          date: '2024-04-23',

          salesRank: 1258
        },
        {
          date: '2024-04-24',

          salesRank: 1334
        },
        {
          date: '2024-04-25',

          salesRank: 1184
        },
        {
          date: '2024-04-26',

          salesRank: 1181
        },
        {
          date: '2024-04-27',

          salesRank: 1266
        },
        {
          date: '2024-04-28',

          salesRank: 1424
        },
        {
          date: '2024-04-29',

          salesRank: 1403
        },
        {
          date: '2024-04-30',

          salesRank: 1253
        },
        {
          date: '2024-05-01',

          salesRank: 1487
        },
        {
          date: '2024-05-02',

          salesRank: 1453
        },
        {
          date: '2024-05-03',

          salesRank: 1574
        },
        {
          date: '2024-05-04',

          salesRank: 1790
        },
        {
          date: '2024-05-05',

          salesRank: 1993
        },
        {
          date: '2024-05-06',

          salesRank: 1961
        },
        {
          date: '2024-05-07',

          salesRank: 1988
        },
        {
          date: '2024-05-08',

          salesRank: 1973
        },
        {
          date: '2024-05-09',

          salesRank: 1543
        },
        {
          date: '2024-05-10',

          salesRank: 1632
        },
        {
          date: '2024-05-11',

          salesRank: 1614
        },
        {
          date: '2024-05-12',

          salesRank: 1950
        },
        {
          date: '2024-05-13',

          salesRank: 552
        },
        {
          date: '2024-05-14',

          salesRank: 595
        },
        {
          date: '2024-05-15',

          salesRank: 809
        },
        {
          date: '2024-05-16',

          salesRank: 909
        },
        {
          date: '2024-05-17',

          salesRank: 975
        },
        {
          date: '2024-05-18',

          salesRank: 1186
        },
        {
          date: '2024-05-19',

          salesRank: 1480
        },
        {
          date: '2024-05-20',

          salesRank: 755
        },
        {
          date: '2024-05-21',

          salesRank: 580
        },
        {
          date: '2024-05-22',

          salesRank: 425
        },
        {
          date: '2024-05-23',

          salesRank: 390
        },
        {
          date: '2024-05-24',

          salesRank: 278
        },
        {
          date: '2024-05-25',

          salesRank: 357
        },
        {
          date: '2024-05-26',

          salesRank: 392
        },
        {
          date: '2024-05-27',

          salesRank: 593
        },
        {
          date: '2024-05-28',

          salesRank: 771
        },
        {
          date: '2024-05-29',

          salesRank: 950
        },
        {
          date: '2024-05-30',

          salesRank: 1038
        },
        {
          date: '2024-05-31',

          salesRank: 1061
        },
        {
          date: '2024-06-01',

          salesRank: 950
        },
        {
          date: '2024-06-02',

          salesRank: 1068
        },
        {
          date: '2024-06-03',

          salesRank: 1444
        },
        {
          date: '2024-06-04',

          salesRank: 1567
        },
        {
          date: '2024-06-05',

          salesRank: 1735
        },
        {
          date: '2024-06-06',

          salesRank: 1825
        },
        {
          date: '2024-06-07',

          salesRank: 1794
        },
        {
          date: '2024-06-08',

          salesRank: 1672
        },
        {
          date: '2024-06-09',

          salesRank: 1578
        },
        {
          date: '2024-06-10',

          salesRank: 1718
        },
        {
          date: '2024-06-11',

          salesRank: 1668
        },
        {
          date: '2024-06-12',

          salesRank: 1571
        },
        {
          date: '2024-06-13',

          salesRank: 725
        },
        {
          date: '2024-06-14',

          salesRank: 1056
        },
        {
          date: '2024-06-15',

          salesRank: 1162
        },
        {
          date: '2024-06-16',

          salesRank: 1289
        },
        {
          date: '2024-06-17',

          salesRank: 481
        },
        {
          date: '2024-06-18',

          salesRank: 327
        },
        {
          date: '2024-06-19',

          salesRank: 240
        },
        {
          date: '2024-06-20',

          salesRank: 220
        },
        {
          date: '2024-06-21',

          salesRank: 149
        },
        {
          date: '2024-06-22',

          salesRank: 166
        },
        {
          date: '2024-06-23',

          salesRank: 202
        },
        {
          date: '2024-06-24',

          salesRank: 370
        },
        {
          date: '2024-06-25',

          salesRank: 385
        },
        {
          date: '2024-06-26',

          salesRank: 418
        },
        {
          date: '2024-06-27',

          salesRank: 144
        },
        {
          date: '2024-06-28',

          salesRank: 165
        },
        {
          date: '2024-06-29',

          salesRank: 199
        },
        {
          date: '2024-06-30',

          salesRank: 512
        },
        {
          date: '2024-07-01',

          salesRank: 199
        },
        {
          date: '2024-07-02',

          salesRank: 94
        },
        {
          date: '2024-07-03',

          salesRank: 103
        },
        {
          date: '2024-07-04',

          salesRank: 56
        },
        {
          date: '2024-07-05',

          salesRank: 107
        },
        {
          date: '2024-07-06',

          salesRank: 155
        },
        {
          date: '2024-07-07',

          salesRank: 143
        },
        {
          date: '2024-07-08',

          salesRank: 314
        },
        {
          date: '2024-07-09',

          salesRank: 446
        },
        {
          date: '2024-07-10',

          salesRank: 631
        },
        {
          date: '2024-07-11',

          salesRank: 816
        },
        {
          date: '2024-07-12',

          salesRank: 925
        },
        {
          date: '2024-07-13',

          salesRank: 1169
        },
        {
          date: '2024-07-14',

          salesRank: 1135
        },
        {
          date: '2024-07-15',

          salesRank: 182
        },
        {
          date: '2024-07-16',

          salesRank: 237
        },
        {
          date: '2024-07-17',

          salesRank: 239
        },
        {
          date: '2024-07-18',

          salesRank: 278
        },
        {
          date: '2024-07-19',

          salesRank: 462
        },
        {
          date: '2024-07-20',

          salesRank: 482
        },
        {
          date: '2024-07-21',

          salesRank: 672
        },
        {
          date: '2024-07-22',

          salesRank: 1069
        },
        {
          date: '2024-07-23',

          salesRank: 1252
        },
        {
          date: '2024-07-24',

          salesRank: 1255
        },
        {
          date: '2024-07-25',

          salesRank: 1349
        },
        {
          date: '2024-07-26',

          salesRank: 916
        },
        {
          date: '2024-07-27',

          salesRank: 1057
        },
        {
          date: '2024-07-28',

          salesRank: 1536
        },
        {
          date: '2024-07-29',

          salesRank: 1265
        },
        {
          date: '2024-07-30',

          salesRank: 1423
        },
        {
          date: '2024-07-31',

          salesRank: 77
        },
        {
          date: '2024-08-01',

          salesRank: 222
        },
        {
          date: '2024-08-02',

          salesRank: 426
        },
        {
          date: '2024-08-03',

          salesRank: 590
        },
        {
          date: '2024-08-04',

          salesRank: 630
        },
        {
          date: '2024-08-05',

          salesRank: 224
        },
        {
          date: '2024-08-06',

          salesRank: 158
        },
        {
          date: '2024-08-07',

          salesRank: 211
        },
        {
          date: '2024-08-09',

          salesRank: 1104
        },
        {
          date: '2024-08-10',

          salesRank: 1152
        },
        {
          date: '2024-08-11',

          salesRank: 1115
        },
        {
          date: '2024-08-12',

          salesRank: 590
        },
        {
          date: '2024-08-13',

          salesRank: 750
        },
        {
          date: '2024-08-14',

          salesRank: 904
        },
        {
          date: '2024-08-15',

          salesRank: 1007
        },
        {
          date: '2024-08-16',

          salesRank: 982
        },
        {
          date: '2024-08-19',

          salesRank: 219
        },
        {
          date: '2024-08-20',

          salesRank: 113
        },
        {
          date: '2024-08-21',

          salesRank: 127
        },
        {
          date: '2024-08-22',

          salesRank: 29
        },
        {
          date: '2024-08-23',

          salesRank: 47
        },
        {
          date: '2024-08-24',

          salesRank: 40
        },
        {
          date: '2024-08-25',

          salesRank: 57
        },
        {
          date: '2024-08-26',

          salesRank: 69
        },
        {
          date: '2024-08-27',

          salesRank: 59
        },
        {
          date: '2024-08-28',

          salesRank: 85
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-28T17:04:06.719Z'
    },
    {
      id: '8902c960-fcca-4292-b3f1-523a45b671c4',
      productName: 'Water Dental Flosser Teeth',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-3.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2022-10-12',

          salesRank: 80312
        },
        {
          date: '2022-10-13',

          salesRank: 52044
        },
        {
          date: '2022-10-14',

          salesRank: 31637
        },
        {
          date: '2022-10-15',

          salesRank: 14106
        },
        {
          date: '2022-10-16',

          salesRank: 12154
        },
        {
          date: '2022-10-17',

          salesRank: 9910
        },
        {
          date: '2022-10-18',

          salesRank: 11033
        },
        {
          date: '2022-10-19',

          salesRank: 9019
        },
        {
          date: '2022-10-20',

          salesRank: 11021
        },
        {
          date: '2022-10-21',

          salesRank: 9224
        },
        {
          date: '2022-10-22',

          salesRank: 11109
        },
        {
          date: '2022-10-23',

          salesRank: 11626
        },
        {
          date: '2022-10-24',

          salesRank: 9454
        },
        {
          date: '2022-10-25',

          salesRank: 8450
        },
        {
          date: '2022-10-26',

          salesRank: 7179
        },
        {
          date: '2022-10-27',

          salesRank: 6834
        },
        {
          date: '2022-10-28',

          salesRank: 7595
        },
        {
          date: '2022-10-29',

          salesRank: 5365
        },
        {
          date: '2022-10-30',

          salesRank: 5014
        },
        {
          date: '2022-10-31',

          salesRank: 6295
        },
        {
          date: '2022-11-01',

          salesRank: 6735
        },
        {
          date: '2022-11-02',

          salesRank: 6322
        },
        {
          date: '2022-11-03',

          salesRank: 6835
        },
        {
          date: '2022-11-04',

          salesRank: 7055
        },
        {
          date: '2022-11-05',

          salesRank: 8573
        },
        {
          date: '2022-11-06',

          salesRank: 9573
        },
        {
          date: '2022-11-07',

          salesRank: 4787
        },
        {
          date: '2022-11-08',

          salesRank: 6080
        },
        {
          date: '2022-11-09',

          salesRank: 5166
        },
        {
          date: '2022-11-10',

          salesRank: 5793
        },
        {
          date: '2022-11-11',

          salesRank: 5394
        },
        {
          date: '2022-11-12',

          salesRank: 6274
        },
        {
          date: '2022-11-13',

          salesRank: 6251
        },
        {
          date: '2022-11-14',

          salesRank: 5384
        },
        {
          date: '2022-11-15',

          salesRank: 5610
        },
        {
          date: '2022-11-16',

          salesRank: 6286
        },
        {
          date: '2022-11-17',

          salesRank: 6394
        },
        {
          date: '2022-11-18',

          salesRank: 3711
        },
        {
          date: '2022-11-19',

          salesRank: 4817
        },
        {
          date: '2022-11-20',

          salesRank: 4931
        },
        {
          date: '2022-11-21',

          salesRank: 5903
        },
        {
          date: '2022-11-22',

          salesRank: 5897
        },
        {
          date: '2022-11-23',

          salesRank: 5766
        },
        {
          date: '2022-11-24',

          salesRank: 7997
        },
        {
          date: '2022-11-25',

          salesRank: 9712
        },
        {
          date: '2022-11-26',

          salesRank: 11216
        },
        {
          date: '2022-11-27',

          salesRank: 13971
        },
        {
          date: '2022-11-28',

          salesRank: 12274
        },
        {
          date: '2022-11-29',

          salesRank: 13262
        },
        {
          date: '2022-11-30',

          salesRank: 12780
        },
        {
          date: '2022-12-01',

          salesRank: 10234
        },
        {
          date: '2022-12-02',

          salesRank: 10068
        },
        {
          date: '2022-12-03',

          salesRank: 10929
        },
        {
          date: '2022-12-04',

          salesRank: 11466
        },
        {
          date: '2022-12-05',

          salesRank: 14018
        },
        {
          date: '2022-12-06',

          salesRank: 12896
        },
        {
          date: '2022-12-07',

          salesRank: 12065
        },
        {
          date: '2022-12-08',

          salesRank: 15388
        },
        {
          date: '2022-12-09',

          salesRank: 10236
        },
        {
          date: '2022-12-10',

          salesRank: 11212
        },
        {
          date: '2022-12-11',

          salesRank: 11818
        },
        {
          date: '2022-12-12',

          salesRank: 11909
        },
        {
          date: '2022-12-13',

          salesRank: 12969
        },
        {
          date: '2022-12-14',

          salesRank: 12497
        },
        {
          date: '2022-12-15',

          salesRank: 14366
        },
        {
          date: '2022-12-16',

          salesRank: 8910
        },
        {
          date: '2022-12-17',

          salesRank: 11654
        },
        {
          date: '2022-12-18',

          salesRank: 10860
        },
        {
          date: '2022-12-19',

          salesRank: 11551
        },
        {
          date: '2022-12-20',

          salesRank: 12624
        },
        {
          date: '2022-12-21',

          salesRank: 13905
        },
        {
          date: '2022-12-22',

          salesRank: 13752
        },
        {
          date: '2022-12-23',

          salesRank: 15420
        },
        {
          date: '2022-12-24',

          salesRank: 16704
        },
        {
          date: '2022-12-25',

          salesRank: 14627
        },
        {
          date: '2022-12-26',

          salesRank: 19457
        },
        {
          date: '2022-12-27',

          salesRank: 17270
        },
        {
          date: '2022-12-28',

          salesRank: 15037
        },
        {
          date: '2022-12-29',

          salesRank: 15237
        },
        {
          date: '2022-12-30',

          salesRank: 15466
        },
        {
          date: '2022-12-31',

          salesRank: 17193
        },
        {
          date: '2023-01-01',

          salesRank: 14793
        },
        {
          date: '2023-01-02',

          salesRank: 18105
        },
        {
          date: '2023-01-03',

          salesRank: 17751
        },
        {
          date: '2023-01-04',

          salesRank: 16565
        },
        {
          date: '2023-01-05',

          salesRank: 18409
        },
        {
          date: '2023-01-06',

          salesRank: 17824
        },
        {
          date: '2023-01-07',

          salesRank: 17386
        },
        {
          date: '2023-01-08',

          salesRank: 17602
        },
        {
          date: '2023-01-09',

          salesRank: 17253
        },
        {
          date: '2023-01-10',

          salesRank: 17494
        },
        {
          date: '2023-01-11',

          salesRank: 17729
        },
        {
          date: '2023-01-12',

          salesRank: 18697
        },
        {
          date: '2023-01-13',

          salesRank: 18264
        },
        {
          date: '2023-01-14',

          salesRank: 19177
        },
        {
          date: '2023-01-15',

          salesRank: 21560
        },
        {
          date: '2023-01-16',

          salesRank: 21337
        },
        {
          date: '2023-01-17',

          salesRank: 20763
        },
        {
          date: '2023-01-18',

          salesRank: 19965
        },
        {
          date: '2023-01-19',

          salesRank: 19036
        },
        {
          date: '2023-01-20',

          salesRank: 21291
        },
        {
          date: '2023-01-21',

          salesRank: 23228
        },
        {
          date: '2023-01-22',

          salesRank: 25511
        },
        {
          date: '2023-01-23',

          salesRank: 25728
        },
        {
          date: '2023-01-24',

          salesRank: 22809
        },
        {
          date: '2023-01-25',

          salesRank: 19794
        },
        {
          date: '2023-01-26',

          salesRank: 18540
        },
        {
          date: '2023-01-27',

          salesRank: 21457
        },
        {
          date: '2023-01-28',

          salesRank: 16362
        },
        {
          date: '2023-01-29',

          salesRank: 19484
        },
        {
          date: '2023-01-30',

          salesRank: 19479
        },
        {
          date: '2023-01-31',

          salesRank: 22078
        },
        {
          date: '2023-02-01',

          salesRank: 25491
        },
        {
          date: '2023-02-02',

          salesRank: 7719
        },
        {
          date: '2023-02-03',

          salesRank: 7008
        },
        {
          date: '2023-02-04',

          salesRank: 6458
        },
        {
          date: '2023-02-05',

          salesRank: 6408
        },
        {
          date: '2023-02-06',

          salesRank: 3705
        },
        {
          date: '2023-02-07',

          salesRank: 2262
        },
        {
          date: '2023-02-08',

          salesRank: 1755
        },
        {
          date: '2023-02-09',

          salesRank: 1551
        },
        {
          date: '2023-02-10',

          salesRank: 2393
        },
        {
          date: '2023-02-11',

          salesRank: 1919
        },
        {
          date: '2023-02-12',

          salesRank: 2053
        },
        {
          date: '2023-02-13',

          salesRank: 1767
        },
        {
          date: '2023-02-14',

          salesRank: 1555
        },
        {
          date: '2023-02-15',

          salesRank: 1348
        },
        {
          date: '2023-02-16',

          salesRank: 2453
        },
        {
          date: '2023-02-17',

          salesRank: 1269
        },
        {
          date: '2023-02-18',

          salesRank: 1482
        },
        {
          date: '2023-02-19',

          salesRank: 1740
        },
        {
          date: '2023-02-20',

          salesRank: 720
        },
        {
          date: '2023-02-21',

          salesRank: 1353
        },
        {
          date: '2023-02-22',

          salesRank: 1426
        },
        {
          date: '2023-02-23',

          salesRank: 1510
        },
        {
          date: '2023-02-24',

          salesRank: 1792
        },
        {
          date: '2023-02-25',

          salesRank: 1783
        },
        {
          date: '2023-02-26',

          salesRank: 1895
        },
        {
          date: '2023-02-27',

          salesRank: 2663
        },
        {
          date: '2023-02-28',

          salesRank: 2797
        },
        {
          date: '2023-03-01',

          salesRank: 4475
        },
        {
          date: '2023-03-02',

          salesRank: 6783
        },
        {
          date: '2023-03-03',

          salesRank: 8891
        },
        {
          date: '2023-03-04',

          salesRank: 10271
        },
        {
          date: '2023-03-05',

          salesRank: 13092
        },
        {
          date: '2023-03-06',

          salesRank: 13911
        },
        {
          date: '2023-03-07',

          salesRank: 12112
        },
        {
          date: '2023-03-08',

          salesRank: 11569
        },
        {
          date: '2023-03-09',

          salesRank: 12793
        },
        {
          date: '2023-03-10',

          salesRank: 10454
        },
        {
          date: '2023-03-11',

          salesRank: 10927
        },
        {
          date: '2023-03-12',

          salesRank: 11135
        },
        {
          date: '2023-03-13',

          salesRank: 10370
        },
        {
          date: '2023-03-14',

          salesRank: 11229
        },
        {
          date: '2023-03-15',

          salesRank: 10258
        },
        {
          date: '2023-03-16',

          salesRank: 7525
        },
        {
          date: '2023-03-17',

          salesRank: 5474
        },
        {
          date: '2023-03-18',

          salesRank: 5128
        },
        {
          date: '2023-03-19',

          salesRank: 5257
        },
        {
          date: '2023-03-20',

          salesRank: 4433
        },
        {
          date: '2023-03-21',

          salesRank: 3367
        },
        {
          date: '2023-03-22',

          salesRank: 3355
        },
        {
          date: '2023-03-23',

          salesRank: 2618
        },
        {
          date: '2023-03-24',

          salesRank: 1943
        },
        {
          date: '2023-03-25',

          salesRank: 2975
        },
        {
          date: '2023-03-26',

          salesRank: 3504
        },
        {
          date: '2023-03-27',

          salesRank: 2741
        },
        {
          date: '2023-03-28',

          salesRank: 2365
        },
        {
          date: '2023-03-29',

          salesRank: 1894
        },
        {
          date: '2023-03-30',

          salesRank: 1592
        },
        {
          date: '2023-03-31',

          salesRank: 1287
        },
        {
          date: '2023-04-01',

          salesRank: 1304
        },
        {
          date: '2023-04-02',

          salesRank: 1526
        },
        {
          date: '2023-04-03',

          salesRank: 1453
        },
        {
          date: '2023-04-04',

          salesRank: 1370
        },
        {
          date: '2023-04-05',

          salesRank: 1505
        },
        {
          date: '2023-04-06',

          salesRank: 1844
        },
        {
          date: '2023-04-07',

          salesRank: 1937
        },
        {
          date: '2023-04-08',

          salesRank: 2114
        },
        {
          date: '2023-04-09',

          salesRank: 2152
        },
        {
          date: '2023-04-10',

          salesRank: 2169
        },
        {
          date: '2023-04-11',

          salesRank: 2190
        },
        {
          date: '2023-04-12',

          salesRank: 2130
        },
        {
          date: '2023-04-13',

          salesRank: 2443
        },
        {
          date: '2023-04-14',

          salesRank: 2007
        },
        {
          date: '2023-04-15',

          salesRank: 2405
        },
        {
          date: '2023-04-16',

          salesRank: 2578
        },
        {
          date: '2023-04-17',

          salesRank: 2337
        },
        {
          date: '2023-04-18',

          salesRank: 2662
        },
        {
          date: '2023-04-19',

          salesRank: 2536
        },
        {
          date: '2023-04-20',

          salesRank: 2912
        },
        {
          date: '2023-04-21',

          salesRank: 4460
        },
        {
          date: '2023-04-22',

          salesRank: 4737
        },
        {
          date: '2023-04-23',

          salesRank: 5627
        },
        {
          date: '2023-04-24',

          salesRank: 5906
        },
        {
          date: '2023-04-25',

          salesRank: 6194
        },
        {
          date: '2023-04-26',

          salesRank: 4248
        },
        {
          date: '2023-04-27',

          salesRank: 3212
        },
        {
          date: '2023-04-28',

          salesRank: 3112
        },
        {
          date: '2023-04-29',

          salesRank: 3463
        },
        {
          date: '2023-04-30',

          salesRank: 3612
        },
        {
          date: '2023-05-01',

          salesRank: 3077
        },
        {
          date: '2023-05-02',

          salesRank: 2807
        },
        {
          date: '2023-05-03',

          salesRank: 2789
        },
        {
          date: '2023-05-04',

          salesRank: 2739
        },
        {
          date: '2023-05-05',

          salesRank: 2522
        },
        {
          date: '2023-05-06',

          salesRank: 2893
        },
        {
          date: '2023-05-07',

          salesRank: 2891
        },
        {
          date: '2023-05-08',

          salesRank: 2870
        },
        {
          date: '2023-05-09',

          salesRank: 3811
        },
        {
          date: '2023-05-10',

          salesRank: 3520
        },
        {
          date: '2023-05-11',

          salesRank: 2812
        },
        {
          date: '2023-05-12',

          salesRank: 2841
        },
        {
          date: '2023-05-13',

          salesRank: 3398
        },
        {
          date: '2023-05-14',

          salesRank: 3535
        },
        {
          date: '2023-05-15',

          salesRank: 3349
        },
        {
          date: '2023-05-16',

          salesRank: 4396
        },
        {
          date: '2023-05-17',

          salesRank: 3348
        },
        {
          date: '2023-05-18',

          salesRank: 5059
        },
        {
          date: '2023-05-19',

          salesRank: 4859
        },
        {
          date: '2023-05-20',

          salesRank: 4130
        },
        {
          date: '2023-05-21',

          salesRank: 5387
        },
        {
          date: '2023-05-22',

          salesRank: 3332
        },
        {
          date: '2023-05-23',

          salesRank: 2788
        },
        {
          date: '2023-05-24',

          salesRank: 2626
        },
        {
          date: '2023-05-25',

          salesRank: 2371
        },
        {
          date: '2023-05-26',

          salesRank: 2309
        },
        {
          date: '2023-05-27',

          salesRank: 2286
        },
        {
          date: '2023-05-28',

          salesRank: 2235
        },
        {
          date: '2023-05-29',

          salesRank: 2235
        },
        {
          date: '2023-05-30',

          salesRank: 2139
        },
        {
          date: '2023-05-31',

          salesRank: 2307
        },
        {
          date: '2023-06-01',

          salesRank: 2425
        },
        {
          date: '2023-06-02',

          salesRank: 1277
        },
        {
          date: '2023-06-03',

          salesRank: 2079
        },
        {
          date: '2023-06-04',

          salesRank: 2420
        },
        {
          date: '2023-06-05',

          salesRank: 2053
        },
        {
          date: '2023-06-06',

          salesRank: 2114
        },
        {
          date: '2023-06-07',

          salesRank: 1615
        },
        {
          date: '2023-06-08',

          salesRank: 1443
        },
        {
          date: '2023-06-09',

          salesRank: 1403
        },
        {
          date: '2023-06-10',

          salesRank: 1370
        },
        {
          date: '2023-06-11',

          salesRank: 1484
        },
        {
          date: '2023-06-12',

          salesRank: 1243
        },
        {
          date: '2023-06-13',

          salesRank: 1254
        },
        {
          date: '2023-06-14',

          salesRank: 1118
        },
        {
          date: '2023-06-15',

          salesRank: 1239
        },
        {
          date: '2023-06-16',

          salesRank: 977
        },
        {
          date: '2023-06-17',

          salesRank: 930
        },
        {
          date: '2023-06-18',

          salesRank: 1104
        },
        {
          date: '2023-06-19',

          salesRank: 1072
        },
        {
          date: '2023-06-20',

          salesRank: 1118
        },
        {
          date: '2023-06-21',

          salesRank: 1309
        },
        {
          date: '2023-06-22',

          salesRank: 1338
        },
        {
          date: '2023-06-23',

          salesRank: 1363
        },
        {
          date: '2023-06-24',

          salesRank: 1378
        },
        {
          date: '2023-06-25',

          salesRank: 1418
        },
        {
          date: '2023-06-26',

          salesRank: 1564
        },
        {
          date: '2023-06-27',

          salesRank: 1134
        },
        {
          date: '2023-06-28',

          salesRank: 1328
        },
        {
          date: '2023-06-29',

          salesRank: 1238
        },
        {
          date: '2023-06-30',

          salesRank: 1524
        },
        {
          date: '2023-07-01',

          salesRank: 1426
        },
        {
          date: '2023-07-02',

          salesRank: 1617
        },
        {
          date: '2023-07-03',

          salesRank: 1618
        },
        {
          date: '2023-07-04',

          salesRank: 1516
        },
        {
          date: '2023-07-05',

          salesRank: 1567
        },
        {
          date: '2023-07-06',

          salesRank: 1004
        },
        {
          date: '2023-07-07',

          salesRank: 974
        },
        {
          date: '2023-07-08',

          salesRank: 956
        },
        {
          date: '2023-07-09',

          salesRank: 1050
        },
        {
          date: '2023-07-10',

          salesRank: 822
        },
        {
          date: '2023-07-11',

          salesRank: 628
        },
        {
          date: '2023-07-12',

          salesRank: 689
        },
        {
          date: '2023-07-13',

          salesRank: 498
        },
        {
          date: '2023-07-14',

          salesRank: 559
        },
        {
          date: '2023-07-15',

          salesRank: 687
        },
        {
          date: '2023-07-16',

          salesRank: 911
        },
        {
          date: '2023-07-17',

          salesRank: 822
        },
        {
          date: '2023-07-18',

          salesRank: 566
        },
        {
          date: '2023-07-19',

          salesRank: 606
        },
        {
          date: '2023-07-20',

          salesRank: 588
        },
        {
          date: '2023-07-21',

          salesRank: 753
        },
        {
          date: '2023-07-22',

          salesRank: 831
        },
        {
          date: '2023-07-23',

          salesRank: 929
        },
        {
          date: '2023-07-24',

          salesRank: 919
        },
        {
          date: '2023-07-25',

          salesRank: 884
        },
        {
          date: '2023-07-26',

          salesRank: 870
        },
        {
          date: '2023-07-27',

          salesRank: 949
        },
        {
          date: '2023-07-28',

          salesRank: 784
        },
        {
          date: '2023-07-29',

          salesRank: 860
        },
        {
          date: '2023-07-30',

          salesRank: 1109
        },
        {
          date: '2023-07-31',

          salesRank: 846
        },
        {
          date: '2023-08-01',

          salesRank: 811
        },
        {
          date: '2023-08-02',

          salesRank: 830
        },
        {
          date: '2023-08-03',

          salesRank: 923
        },
        {
          date: '2023-08-04',

          salesRank: 971
        },
        {
          date: '2023-08-05',

          salesRank: 988
        },
        {
          date: '2023-08-06',

          salesRank: 1146
        },
        {
          date: '2023-08-07',

          salesRank: 1057
        },
        {
          date: '2023-08-08',

          salesRank: 455
        },
        {
          date: '2023-08-09',

          salesRank: 329
        },
        {
          date: '2023-08-10',

          salesRank: 213
        },
        {
          date: '2023-08-11',

          salesRank: 163
        },
        {
          date: '2023-08-12',

          salesRank: 148
        },
        {
          date: '2023-08-13',

          salesRank: 205
        },
        {
          date: '2023-08-14',

          salesRank: 208
        },
        {
          date: '2023-08-15',

          salesRank: 280
        },
        {
          date: '2023-08-16',

          salesRank: 431
        },
        {
          date: '2023-08-17',

          salesRank: 439
        },
        {
          date: '2023-08-18',

          salesRank: 470
        },
        {
          date: '2023-08-19',

          salesRank: 682
        },
        {
          date: '2023-08-20',

          salesRank: 756
        },
        {
          date: '2023-08-21',

          salesRank: 583
        },
        {
          date: '2023-08-22',

          salesRank: 555
        },
        {
          date: '2023-08-23',

          salesRank: 666
        },
        {
          date: '2023-08-24',

          salesRank: 1004
        },
        {
          date: '2023-08-25',

          salesRank: 1388
        },
        {
          date: '2023-08-26',

          salesRank: 1323
        },
        {
          date: '2023-08-27',

          salesRank: 1914
        },
        {
          date: '2023-08-28',

          salesRank: 1867
        },
        {
          date: '2023-08-29',

          salesRank: 1406
        },
        {
          date: '2023-08-30',

          salesRank: 1388
        },
        {
          date: '2023-08-31',

          salesRank: 488
        },
        {
          date: '2023-09-01',

          salesRank: 731
        },
        {
          date: '2023-09-02',

          salesRank: 820
        },
        {
          date: '2023-09-03',

          salesRank: 889
        },
        {
          date: '2023-09-04',

          salesRank: 389
        },
        {
          date: '2023-09-05',

          salesRank: 295
        },
        {
          date: '2023-09-06',

          salesRank: 216
        },
        {
          date: '2023-09-07',

          salesRank: 174
        },
        {
          date: '2023-09-08',

          salesRank: 111
        },
        {
          date: '2023-09-09',

          salesRank: 106
        },
        {
          date: '2023-09-10',

          salesRank: 129
        },
        {
          date: '2023-09-11',

          salesRank: 163
        },
        {
          date: '2023-09-12',

          salesRank: 210
        },
        {
          date: '2023-09-13',

          salesRank: 281
        },
        {
          date: '2023-09-14',

          salesRank: 182
        },
        {
          date: '2023-09-15',

          salesRank: 235
        },
        {
          date: '2023-09-16',

          salesRank: 273
        },
        {
          date: '2023-09-17',

          salesRank: 359
        },
        {
          date: '2023-09-18',

          salesRank: 321
        },
        {
          date: '2023-09-19',

          salesRank: 300
        },
        {
          date: '2023-09-20',

          salesRank: 355
        },
        {
          date: '2023-09-21',

          salesRank: 173
        },
        {
          date: '2023-09-22',

          salesRank: 226
        },
        {
          date: '2023-09-23',

          salesRank: 357
        },
        {
          date: '2023-09-24',

          salesRank: 478
        },
        {
          date: '2023-09-25',

          salesRank: 243
        },
        {
          date: '2023-09-26',

          salesRank: 304
        },
        {
          date: '2023-09-27',

          salesRank: 369
        },
        {
          date: '2023-09-28',

          salesRank: 444
        },
        {
          date: '2023-09-29',

          salesRank: 450
        },
        {
          date: '2023-09-30',

          salesRank: 523
        },
        {
          date: '2023-10-01',

          salesRank: 628
        },
        {
          date: '2023-10-02',

          salesRank: 325
        },
        {
          date: '2023-10-03',

          salesRank: 232
        },
        {
          date: '2023-10-04',

          salesRank: 202
        },
        {
          date: '2023-10-05',

          salesRank: 141
        },
        {
          date: '2023-10-06',

          salesRank: 127
        },
        {
          date: '2023-10-07',

          salesRank: 138
        },
        {
          date: '2023-10-08',

          salesRank: 164
        },
        {
          date: '2023-10-09',

          salesRank: 217
        },
        {
          date: '2023-10-10',

          salesRank: 242
        },
        {
          date: '2023-10-11',

          salesRank: 338
        },
        {
          date: '2023-10-12',

          salesRank: 333
        },
        {
          date: '2023-10-13',

          salesRank: 452
        },
        {
          date: '2023-10-14',

          salesRank: 443
        },
        {
          date: '2023-10-15',

          salesRank: 568
        },
        {
          date: '2023-10-16',

          salesRank: 555
        },
        {
          date: '2023-10-17',

          salesRank: 603
        },
        {
          date: '2023-10-18',

          salesRank: 612
        },
        {
          date: '2023-10-19',

          salesRank: 594
        },
        {
          date: '2023-10-20',

          salesRank: 789
        },
        {
          date: '2023-10-21',

          salesRank: 828
        },
        {
          date: '2023-10-22',

          salesRank: 876
        },
        {
          date: '2023-10-23',

          salesRank: 687
        },
        {
          date: '2023-10-24',

          salesRank: 505
        },
        {
          date: '2023-10-25',

          salesRank: 550
        },
        {
          date: '2023-10-26',

          salesRank: 435
        },
        {
          date: '2023-10-27',

          salesRank: 291
        },
        {
          date: '2023-10-28',

          salesRank: 231
        },
        {
          date: '2023-10-29',

          salesRank: 241
        },
        {
          date: '2023-10-30',

          salesRank: 210
        },
        {
          date: '2023-10-31',

          salesRank: 129
        },
        {
          date: '2023-11-01',

          salesRank: 176
        },
        {
          date: '2023-11-02',

          salesRank: 208
        },
        {
          date: '2023-11-03',

          salesRank: 248
        },
        {
          date: '2023-11-04',

          salesRank: 257
        },
        {
          date: '2023-11-05',

          salesRank: 316
        },
        {
          date: '2023-11-06',

          salesRank: 276
        },
        {
          date: '2023-11-07',

          salesRank: 236
        },
        {
          date: '2023-11-08',

          salesRank: 258
        },
        {
          date: '2023-11-09',

          salesRank: 252
        },
        {
          date: '2023-11-10',

          salesRank: 123
        },
        {
          date: '2023-11-11',

          salesRank: 180
        },
        {
          date: '2023-11-12',

          salesRank: 236
        },
        {
          date: '2023-11-13',

          salesRank: 231
        },
        {
          date: '2023-11-14',

          salesRank: 206
        },
        {
          date: '2023-11-15',

          salesRank: 205
        },
        {
          date: '2023-11-16',

          salesRank: 188
        },
        {
          date: '2023-11-17',

          salesRank: 135
        },
        {
          date: '2023-11-18',

          salesRank: 119
        },
        {
          date: '2023-11-19',

          salesRank: 119
        },
        {
          date: '2023-11-20',

          salesRank: 100
        },
        {
          date: '2023-11-21',

          salesRank: 68
        },
        {
          date: '2023-11-22',

          salesRank: 58
        },
        {
          date: '2023-11-23',

          salesRank: 56
        },
        {
          date: '2023-11-24',

          salesRank: 31
        },
        {
          date: '2023-11-25',

          salesRank: 67
        },
        {
          date: '2023-11-26',

          salesRank: 82
        },
        {
          date: '2023-11-27',

          salesRank: 97
        },
        {
          date: '2023-11-28',

          salesRank: 84
        },
        {
          date: '2023-11-29',

          salesRank: 115
        },
        {
          date: '2023-11-30',

          salesRank: 149
        },
        {
          date: '2023-12-01',

          salesRank: 209
        },
        {
          date: '2023-12-02',

          salesRank: 236
        },
        {
          date: '2023-12-03',

          salesRank: 259
        },
        {
          date: '2023-12-04',

          salesRank: 278
        },
        {
          date: '2023-12-05',

          salesRank: 238
        },
        {
          date: '2023-12-06',

          salesRank: 220
        },
        {
          date: '2023-12-07',

          salesRank: 200
        },
        {
          date: '2023-12-08',

          salesRank: 178
        },
        {
          date: '2023-12-09',

          salesRank: 117
        },
        {
          date: '2023-12-10',

          salesRank: 161
        },
        {
          date: '2023-12-11',

          salesRank: 131
        },
        {
          date: '2023-12-12',

          salesRank: 98
        },
        {
          date: '2023-12-13',

          salesRank: 89
        },
        {
          date: '2023-12-14',

          salesRank: 91
        },
        {
          date: '2023-12-15',

          salesRank: 109
        },
        {
          date: '2023-12-16',

          salesRank: 140
        },
        {
          date: '2023-12-17',

          salesRank: 184
        },
        {
          date: '2023-12-18',

          salesRank: 180
        },
        {
          date: '2023-12-19',

          salesRank: 163
        },
        {
          date: '2023-12-20',

          salesRank: 179
        },
        {
          date: '2023-12-21',

          salesRank: 176
        },
        {
          date: '2023-12-22',

          salesRank: 186
        },
        {
          date: '2023-12-23',

          salesRank: 235
        },
        {
          date: '2023-12-24',

          salesRank: 306
        },
        {
          date: '2023-12-25',

          salesRank: 336
        },
        {
          date: '2023-12-26',

          salesRank: 376
        },
        {
          date: '2023-12-27',

          salesRank: 367
        },
        {
          date: '2023-12-28',

          salesRank: 344
        },
        {
          date: '2023-12-29',

          salesRank: 328
        },
        {
          date: '2023-12-30',

          salesRank: 350
        },
        {
          date: '2023-12-31',

          salesRank: 309
        },
        {
          date: '2024-01-01',

          salesRank: 355
        },
        {
          date: '2024-01-02',

          salesRank: 198
        },
        {
          date: '2024-01-03',

          salesRank: 188
        },
        {
          date: '2024-01-04',

          salesRank: 180
        },
        {
          date: '2024-01-05',

          salesRank: 153
        },
        {
          date: '2024-01-06',

          salesRank: 149
        },
        {
          date: '2024-01-07',

          salesRank: 167
        },
        {
          date: '2024-01-08',

          salesRank: 211
        },
        {
          date: '2024-01-09',

          salesRank: 222
        },
        {
          date: '2024-01-10',

          salesRank: 230
        },
        {
          date: '2024-01-11',

          salesRank: 281
        },
        {
          date: '2024-01-12',

          salesRank: 270
        },
        {
          date: '2024-01-13',

          salesRank: 268
        },
        {
          date: '2024-01-14',

          salesRank: 215
        },
        {
          date: '2024-01-15',

          salesRank: 184
        },
        {
          date: '2024-01-16',

          salesRank: 219
        },
        {
          date: '2024-01-17',

          salesRank: 200
        },
        {
          date: '2024-01-18',

          salesRank: 141
        },
        {
          date: '2024-01-19',

          salesRank: 184
        },
        {
          date: '2024-01-20',

          salesRank: 244
        },
        {
          date: '2024-01-21',

          salesRank: 301
        },
        {
          date: '2024-01-22',

          salesRank: 335
        },
        {
          date: '2024-01-23',

          salesRank: 187
        },
        {
          date: '2024-01-24',

          salesRank: 233
        },
        {
          date: '2024-01-25',

          salesRank: 336
        },
        {
          date: '2024-01-26',

          salesRank: 343
        },
        {
          date: '2024-01-27',

          salesRank: 484
        },
        {
          date: '2024-01-28',

          salesRank: 466
        },
        {
          date: '2024-01-29',

          salesRank: 403
        },
        {
          date: '2024-01-30',

          salesRank: 314
        },
        {
          date: '2024-01-31',

          salesRank: 168
        },
        {
          date: '2024-02-01',

          salesRank: 135
        },
        {
          date: '2024-02-02',

          salesRank: 182
        },
        {
          date: '2024-02-03',

          salesRank: 206
        },
        {
          date: '2024-02-04',

          salesRank: 222
        },
        {
          date: '2024-02-05',

          salesRank: 203
        },
        {
          date: '2024-02-06',

          salesRank: 117
        },
        {
          date: '2024-02-07',

          salesRank: 103
        },
        {
          date: '2024-02-08',

          salesRank: 91
        },
        {
          date: '2024-02-09',

          salesRank: 83
        },
        {
          date: '2024-02-10',

          salesRank: 75
        },
        {
          date: '2024-02-11',

          salesRank: 94
        },
        {
          date: '2024-02-12',

          salesRank: 55
        },
        {
          date: '2024-02-13',

          salesRank: 84
        },
        {
          date: '2024-02-14',

          salesRank: 107
        },
        {
          date: '2024-02-15',

          salesRank: 105
        },
        {
          date: '2024-02-16',

          salesRank: 93
        },
        {
          date: '2024-02-17',

          salesRank: 126
        },
        {
          date: '2024-02-18',

          salesRank: 141
        },
        {
          date: '2024-02-19',

          salesRank: 116
        },
        {
          date: '2024-02-20',

          salesRank: 71
        },
        {
          date: '2024-02-21',

          salesRank: 30
        },
        {
          date: '2024-02-22',

          salesRank: 33
        },
        {
          date: '2024-02-23',

          salesRank: 25
        },
        {
          date: '2024-02-24',

          salesRank: 20
        },
        {
          date: '2024-02-25',

          salesRank: 22
        },
        {
          date: '2024-02-26',

          salesRank: 27
        },
        {
          date: '2024-02-27',

          salesRank: 35
        },
        {
          date: '2024-02-28',

          salesRank: 39
        },
        {
          date: '2024-02-29',

          salesRank: 52
        },
        {
          date: '2024-03-01',

          salesRank: 57
        },
        {
          date: '2024-03-02',

          salesRank: 64
        },
        {
          date: '2024-03-03',

          salesRank: 70
        },
        {
          date: '2024-03-04',

          salesRank: 79
        },
        {
          date: '2024-03-05',

          salesRank: 64
        },
        {
          date: '2024-03-06',

          salesRank: 58
        },
        {
          date: '2024-03-07',

          salesRank: 60
        },
        {
          date: '2024-03-08',

          salesRank: 60
        },
        {
          date: '2024-03-09',

          salesRank: 68
        },
        {
          date: '2024-03-10',

          salesRank: 85
        },
        {
          date: '2024-03-11',

          salesRank: 76
        },
        {
          date: '2024-03-12',

          salesRank: 79
        },
        {
          date: '2024-03-13',

          salesRank: 64
        },
        {
          date: '2024-03-14',

          salesRank: 66
        },
        {
          date: '2024-03-15',

          salesRank: 57
        },
        {
          date: '2024-03-16',

          salesRank: 62
        },
        {
          date: '2024-03-17',

          salesRank: 79
        },
        {
          date: '2024-03-18',

          salesRank: 52
        },
        {
          date: '2024-03-19',

          salesRank: 42
        },
        {
          date: '2024-03-20',

          salesRank: 32
        },
        {
          date: '2024-03-21',

          salesRank: 18
        },
        {
          date: '2024-03-22',

          salesRank: 20
        },
        {
          date: '2024-03-23',

          salesRank: 22
        },
        {
          date: '2024-03-24',

          salesRank: 24
        },
        {
          date: '2024-03-25',

          salesRank: 25
        },
        {
          date: '2024-03-26',

          salesRank: 26
        },
        {
          date: '2024-03-27',

          salesRank: 36
        },
        {
          date: '2024-03-28',

          salesRank: 44
        },
        {
          date: '2024-03-29',

          salesRank: 44
        },
        {
          date: '2024-03-30',

          salesRank: 50
        },
        {
          date: '2024-03-31',

          salesRank: 54
        },
        {
          date: '2024-04-01',

          salesRank: 61
        },
        {
          date: '2024-04-02',

          salesRank: 65
        },
        {
          date: '2024-04-03',

          salesRank: 64
        },
        {
          date: '2024-04-04',

          salesRank: 56
        },
        {
          date: '2024-04-05',

          salesRank: 57
        },
        {
          date: '2024-04-06',

          salesRank: 62
        },
        {
          date: '2024-04-07',

          salesRank: 70
        },
        {
          date: '2024-04-08',

          salesRank: 68
        },
        {
          date: '2024-04-09',

          salesRank: 66
        },
        {
          date: '2024-04-10',

          salesRank: 53
        },
        {
          date: '2024-04-11',

          salesRank: 48
        },
        {
          date: '2024-04-12',

          salesRank: 42
        },
        {
          date: '2024-04-13',

          salesRank: 50
        },
        {
          date: '2024-04-14',

          salesRank: 58
        },
        {
          date: '2024-04-15',

          salesRank: 66
        },
        {
          date: '2024-04-16',

          salesRank: 68
        },
        {
          date: '2024-04-17',

          salesRank: 61
        },
        {
          date: '2024-04-18',

          salesRank: 61
        },
        {
          date: '2024-04-19',

          salesRank: 58
        },
        {
          date: '2024-04-20',

          salesRank: 63
        },
        {
          date: '2024-04-21',

          salesRank: 94
        },
        {
          date: '2024-04-22',

          salesRank: 54
        },
        {
          date: '2024-04-23',

          salesRank: 40
        },
        {
          date: '2024-04-24',

          salesRank: 32
        },
        {
          date: '2024-04-25',

          salesRank: 30
        },
        {
          date: '2024-04-26',

          salesRank: 24
        },
        {
          date: '2024-04-27',

          salesRank: 32
        },
        {
          date: '2024-04-28',

          salesRank: 37
        },
        {
          date: '2024-04-29',

          salesRank: 62
        },
        {
          date: '2024-04-30',

          salesRank: 63
        },
        {
          date: '2024-05-01',

          salesRank: 64
        },
        {
          date: '2024-05-02',

          salesRank: 59
        },
        {
          date: '2024-05-03',

          salesRank: 61
        },
        {
          date: '2024-05-04',

          salesRank: 66
        },
        {
          date: '2024-05-05',

          salesRank: 92
        },
        {
          date: '2024-05-06',

          salesRank: 78
        },
        {
          date: '2024-05-07',

          salesRank: 69
        },
        {
          date: '2024-05-08',

          salesRank: 78
        },
        {
          date: '2024-05-09',

          salesRank: 77
        },
        {
          date: '2024-05-10',

          salesRank: 80
        },
        {
          date: '2024-05-11',

          salesRank: 96
        },
        {
          date: '2024-05-12',

          salesRank: 104
        },
        {
          date: '2024-05-13',

          salesRank: 146
        },
        {
          date: '2024-05-14',

          salesRank: 114
        },
        {
          date: '2024-05-15',

          salesRank: 98
        },
        {
          date: '2024-05-16',

          salesRank: 94
        },
        {
          date: '2024-05-17',

          salesRank: 111
        },
        {
          date: '2024-05-18',

          salesRank: 123
        },
        {
          date: '2024-05-19',

          salesRank: 147
        },
        {
          date: '2024-05-20',

          salesRank: 117
        },
        {
          date: '2024-05-21',

          salesRank: 70
        },
        {
          date: '2024-05-22',

          salesRank: 104
        },
        {
          date: '2024-05-23',

          salesRank: 109
        },
        {
          date: '2024-05-24',

          salesRank: 113
        },
        {
          date: '2024-05-25',

          salesRank: 140
        },
        {
          date: '2024-05-26',

          salesRank: 171
        },
        {
          date: '2024-05-27',

          salesRank: 69
        },
        {
          date: '2024-05-28',

          salesRank: 57
        },
        {
          date: '2024-05-29',

          salesRank: 62
        },
        {
          date: '2024-05-30',

          salesRank: 52
        },
        {
          date: '2024-05-31',

          salesRank: 51
        },
        {
          date: '2024-06-01',

          salesRank: 64
        },
        {
          date: '2024-06-02',

          salesRank: 85
        },
        {
          date: '2024-06-03',

          salesRank: 119
        },
        {
          date: '2024-06-04',

          salesRank: 110
        },
        {
          date: '2024-06-05',

          salesRank: 132
        },
        {
          date: '2024-06-06',

          salesRank: 84
        },
        {
          date: '2024-06-07',

          salesRank: 81
        },
        {
          date: '2024-06-08',

          salesRank: 100
        },
        {
          date: '2024-06-09',

          salesRank: 141
        },
        {
          date: '2024-06-10',

          salesRank: 117
        },
        {
          date: '2024-06-11',

          salesRank: 102
        },
        {
          date: '2024-06-12',

          salesRank: 106
        },
        {
          date: '2024-06-13',

          salesRank: 104
        },
        {
          date: '2024-06-14',

          salesRank: 15
        },
        {
          date: '2024-06-15',

          salesRank: 31
        },
        {
          date: '2024-06-16',

          salesRank: 36
        },
        {
          date: '2024-06-17',

          salesRank: 54
        },
        {
          date: '2024-06-18',

          salesRank: 59
        },
        {
          date: '2024-06-19',

          salesRank: 97
        },
        {
          date: '2024-06-20',

          salesRank: 115
        },
        {
          date: '2024-06-21',

          salesRank: 141
        },
        {
          date: '2024-06-22',

          salesRank: 152
        },
        {
          date: '2024-06-23',

          salesRank: 149
        },
        {
          date: '2024-06-24',

          salesRank: 118
        },
        {
          date: '2024-06-25',

          salesRank: 74
        },
        {
          date: '2024-06-26',

          salesRank: 66
        },
        {
          date: '2024-06-27',

          salesRank: 52
        },
        {
          date: '2024-06-28',

          salesRank: 60
        },
        {
          date: '2024-06-29',

          salesRank: 70
        },
        {
          date: '2024-06-30',

          salesRank: 92
        },
        {
          date: '2024-07-01',

          salesRank: 138
        },
        {
          date: '2024-07-02',

          salesRank: 153
        },
        {
          date: '2024-07-03',

          salesRank: 152
        },
        {
          date: '2024-07-04',

          salesRank: 63
        },
        {
          date: '2024-07-05',

          salesRank: 68
        },
        {
          date: '2024-07-06',

          salesRank: 100
        },
        {
          date: '2024-07-07',

          salesRank: 101
        },
        {
          date: '2024-07-08',

          salesRank: 161
        },
        {
          date: '2024-07-09',

          salesRank: 142
        },
        {
          date: '2024-07-10',

          salesRank: 136
        },
        {
          date: '2024-07-11',

          salesRank: 102
        },
        {
          date: '2024-07-12',

          salesRank: 65
        },
        {
          date: '2024-07-13',

          salesRank: 60
        },
        {
          date: '2024-07-14',

          salesRank: 77
        },
        {
          date: '2024-07-15',

          salesRank: 60
        },
        {
          date: '2024-07-16',

          salesRank: 89
        },
        {
          date: '2024-07-17',

          salesRank: 100
        },
        {
          date: '2024-07-18',

          salesRank: 77
        },
        {
          date: '2024-07-19',

          salesRank: 88
        },
        {
          date: '2024-07-20',

          salesRank: 106
        },
        {
          date: '2024-07-21',

          salesRank: 135
        },
        {
          date: '2024-07-22',

          salesRank: 113
        },
        {
          date: '2024-07-23',

          salesRank: 111
        },
        {
          date: '2024-07-24',

          salesRank: 116
        },
        {
          date: '2024-07-25',

          salesRank: 127
        },
        {
          date: '2024-07-26',

          salesRank: 165
        },
        {
          date: '2024-07-27',

          salesRank: 207
        },
        {
          date: '2024-07-28',

          salesRank: 237
        },
        {
          date: '2024-07-29',

          salesRank: 210
        },
        {
          date: '2024-07-30',

          salesRank: 171
        },
        {
          date: '2024-07-31',

          salesRank: 220
        },
        {
          date: '2024-08-01',

          salesRank: 177
        },
        {
          date: '2024-08-02',

          salesRank: 129
        },
        {
          date: '2024-08-03',

          salesRank: 167
        },
        {
          date: '2024-08-04',

          salesRank: 208
        },
        {
          date: '2024-08-05',

          salesRank: 262
        },
        {
          date: '2024-08-06',

          salesRank: 143
        },
        {
          date: '2024-08-07',

          salesRank: 156
        },
        {
          date: '2024-08-09',

          salesRank: 48
        },
        {
          date: '2024-08-10',

          salesRank: 44
        },
        {
          date: '2024-08-11',

          salesRank: 40
        },
        {
          date: '2024-08-12',

          salesRank: 47
        },
        {
          date: '2024-08-13',

          salesRank: 42
        },
        {
          date: '2024-08-14',

          salesRank: 51
        },
        {
          date: '2024-08-15',

          salesRank: 50
        },
        {
          date: '2024-08-16',

          salesRank: 40
        },
        {
          date: '2024-08-19',

          salesRank: 46
        },
        {
          date: '2024-08-20',

          salesRank: 38
        },
        {
          date: '2024-08-21',

          salesRank: 64
        },
        {
          date: '2024-08-22',

          salesRank: 57
        },
        {
          date: '2024-08-23',

          salesRank: 60
        },
        {
          date: '2024-08-24',

          salesRank: 68
        },
        {
          date: '2024-08-25',

          salesRank: 84
        },
        {
          date: '2024-08-26',

          salesRank: 77
        },
        {
          date: '2024-08-27',

          salesRank: 68
        },
        {
          date: '2024-08-28',

          salesRank: 38
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-28T17:02:53.192Z'
    },
    {
      id: 'd617cbcf-8a4a-4ecd-a739-0f93ab754e6c',
      productName: 'Water Dental Flosser',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-water-flosser-2.png',
      comparisonMetric: PerformanceComparisonMetric.REVIEWS,
      productPerformanceRecords: [
        {
          date: '2022-10-17',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-18',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-19',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-20',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-21',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-22',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-23',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-24',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-25',
          reviewCount: 14,
          rating: 4
        },
        {
          date: '2022-10-26',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-10-27',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-10-28',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-10-29',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-10-30',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-10-31',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-11-01',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-11-02',
          reviewCount: 24,
          rating: 4
        },
        {
          date: '2022-11-03',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-04',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-05',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-06',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-07',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-08',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-09',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-10',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-11',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-12',
          reviewCount: 45,
          rating: 4
        },
        {
          date: '2022-11-13',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-14',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-15',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-16',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-17',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-18',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-19',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-20',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-21',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-22',
          reviewCount: 74,
          rating: 4
        },
        {
          date: '2022-11-23',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-24',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-25',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-26',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-27',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-28',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-29',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-11-30',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-12-01',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-12-02',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-12-03',
          reviewCount: 105,
          rating: 4
        },
        {
          date: '2022-12-04',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-05',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-06',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-07',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-08',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-09',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-10',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-11',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-12',
          reviewCount: 136,
          rating: 4
        },
        {
          date: '2022-12-13',
          reviewCount: 150,
          rating: 4
        },
        {
          date: '2022-12-14',
          reviewCount: 150,
          rating: 4
        },
        {
          date: '2022-12-15',
          reviewCount: 150,
          rating: 4
        },
        {
          date: '2022-12-16',
          reviewCount: 150,
          rating: 4
        },
        {
          date: '2022-12-17',
          reviewCount: 150,
          rating: 4
        },
        {
          date: '2022-12-18',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-19',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-20',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-21',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-22',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-23',
          reviewCount: 422,
          rating: 4
        },
        {
          date: '2022-12-24',
          reviewCount: 436,
          rating: 4
        },
        {
          date: '2022-12-25',
          reviewCount: 436,
          rating: 4
        },
        {
          date: '2022-12-26',
          reviewCount: 436,
          rating: 4
        },
        {
          date: '2022-12-27',
          reviewCount: 436,
          rating: 4
        },
        {
          date: '2022-12-28',
          reviewCount: 436,
          rating: 4
        },
        {
          date: '2022-12-29',
          reviewCount: 444,
          rating: 4
        },
        {
          date: '2022-12-30',
          reviewCount: 444,
          rating: 4
        },
        {
          date: '2022-12-31',
          reviewCount: 444,
          rating: 4
        },
        {
          date: '2023-01-01',
          reviewCount: 444,
          rating: 4
        },
        {
          date: '2023-01-02',
          reviewCount: 444,
          rating: 4
        },
        {
          date: '2023-01-03',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-04',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-05',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-06',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-07',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-08',
          reviewCount: 454,
          rating: 4
        },
        {
          date: '2023-01-09',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-10',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-11',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-12',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-13',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-14',
          reviewCount: 477,
          rating: 4
        },
        {
          date: '2023-01-15',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-16',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-17',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-18',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-19',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-20',
          reviewCount: 496,
          rating: 4
        },
        {
          date: '2023-01-21',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-22',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-23',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-24',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-25',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-26',
          reviewCount: 515,
          rating: 4
        },
        {
          date: '2023-01-27',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-01-28',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-01-29',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-01-30',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-01-31',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-02-01',
          reviewCount: 528,
          rating: 4
        },
        {
          date: '2023-02-02',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-03',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-04',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-05',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-06',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-07',
          reviewCount: 534,
          rating: 4
        },
        {
          date: '2023-02-08',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-09',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-10',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-11',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-12',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-13',
          reviewCount: 540,
          rating: 4
        },
        {
          date: '2023-02-14',
          reviewCount: 553,
          rating: 4
        },
        {
          date: '2023-02-15',
          reviewCount: 553,
          rating: 4
        },
        {
          date: '2023-02-16',
          reviewCount: 553,
          rating: 4
        },
        {
          date: '2023-02-17',
          reviewCount: 553,
          rating: 4
        },
        {
          date: '2023-02-18',
          reviewCount: 561,
          rating: 4
        },
        {
          date: '2023-02-19',
          reviewCount: 561,
          rating: 4
        },
        {
          date: '2023-02-20',
          reviewCount: 561,
          rating: 4
        },
        {
          date: '2023-02-21',
          reviewCount: 593,
          rating: 4
        },
        {
          date: '2023-02-22',
          reviewCount: 593,
          rating: 4
        },
        {
          date: '2023-02-23',
          reviewCount: 593,
          rating: 4
        },
        {
          date: '2023-02-24',
          reviewCount: 593,
          rating: 4
        },
        {
          date: '2023-02-25',
          reviewCount: 617,
          rating: 4
        },
        {
          date: '2023-02-26',
          reviewCount: 617,
          rating: 4
        },
        {
          date: '2023-02-27',
          reviewCount: 617,
          rating: 4
        },
        {
          date: '2023-02-28',
          reviewCount: 639,
          rating: 4
        },
        {
          date: '2023-03-01',
          reviewCount: 639,
          rating: 4
        },
        {
          date: '2023-03-02',
          reviewCount: 639,
          rating: 4
        },
        {
          date: '2023-03-03',
          reviewCount: 639,
          rating: 4
        },
        {
          date: '2023-03-04',
          reviewCount: 664,
          rating: 4
        },
        {
          date: '2023-03-05',
          reviewCount: 664,
          rating: 4
        },
        {
          date: '2023-03-06',
          reviewCount: 664,
          rating: 4
        },
        {
          date: '2023-03-07',
          reviewCount: 684,
          rating: 4
        },
        {
          date: '2023-03-08',
          reviewCount: 684,
          rating: 4
        },
        {
          date: '2023-03-09',
          reviewCount: 684,
          rating: 4
        },
        {
          date: '2023-03-10',
          reviewCount: 697,
          rating: 4
        },
        {
          date: '2023-03-11',
          reviewCount: 697,
          rating: 4
        },
        {
          date: '2023-03-12',
          reviewCount: 697,
          rating: 4
        },
        {
          date: '2023-03-13',
          reviewCount: 722,
          rating: 4
        },
        {
          date: '2023-03-14',
          reviewCount: 722,
          rating: 4
        },
        {
          date: '2023-03-15',
          reviewCount: 722,
          rating: 4
        },
        {
          date: '2023-03-16',
          reviewCount: 742,
          rating: 4
        },
        {
          date: '2023-03-17',
          reviewCount: 742,
          rating: 4
        },
        {
          date: '2023-03-18',
          reviewCount: 742,
          rating: 4
        },
        {
          date: '2023-03-19',
          reviewCount: 742,
          rating: 4
        },
        {
          date: '2023-03-20',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-21',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-22',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-23',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-24',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-25',
          reviewCount: 769,
          rating: 4
        },
        {
          date: '2023-03-26',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-03-27',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-03-28',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-03-29',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-03-30',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-03-31',
          reviewCount: 822,
          rating: 4
        },
        {
          date: '2023-04-01',
          reviewCount: 875,
          rating: 4
        },
        {
          date: '2023-04-02',
          reviewCount: 875,
          rating: 4
        },
        {
          date: '2023-04-03',
          reviewCount: 875,
          rating: 4
        },
        {
          date: '2023-04-04',
          reviewCount: 903,
          rating: 4
        },
        {
          date: '2023-04-05',
          reviewCount: 903,
          rating: 4
        },
        {
          date: '2023-04-06',
          reviewCount: 903,
          rating: 4
        },
        {
          date: '2023-04-07',
          reviewCount: 903,
          rating: 4
        },
        {
          date: '2023-04-08',
          reviewCount: 937,
          rating: 4
        },
        {
          date: '2023-04-09',
          reviewCount: 937,
          rating: 4
        },
        {
          date: '2023-04-10',
          reviewCount: 937,
          rating: 4
        },
        {
          date: '2023-04-11',
          reviewCount: 965,
          rating: 4
        },
        {
          date: '2023-04-12',
          reviewCount: 965,
          rating: 4
        },
        {
          date: '2023-04-13',
          reviewCount: 965,
          rating: 4
        },
        {
          date: '2023-04-14',
          reviewCount: 965,
          rating: 4
        },
        {
          date: '2023-04-15',
          reviewCount: 1010,
          rating: 4
        },
        {
          date: '2023-04-16',
          reviewCount: 1010,
          rating: 4
        },
        {
          date: '2023-04-17',
          reviewCount: 1010,
          rating: 4
        },
        {
          date: '2023-04-18',
          reviewCount: 1049,
          rating: 4
        },
        {
          date: '2023-04-19',
          reviewCount: 1049,
          rating: 4
        },
        {
          date: '2023-04-20',
          reviewCount: 1049,
          rating: 4
        },
        {
          date: '2023-04-21',
          reviewCount: 1049,
          rating: 4
        },
        {
          date: '2023-04-22',
          reviewCount: 1092,
          rating: 4
        },
        {
          date: '2023-04-23',
          reviewCount: 1092,
          rating: 4
        },
        {
          date: '2023-04-24',
          reviewCount: 1092,
          rating: 4
        },
        {
          date: '2023-04-25',
          reviewCount: 1126,
          rating: 4
        },
        {
          date: '2023-04-26',
          reviewCount: 1126,
          rating: 4
        },
        {
          date: '2023-04-27',
          reviewCount: 1126,
          rating: 4
        },
        {
          date: '2023-04-28',
          reviewCount: 1165,
          rating: 4
        },
        {
          date: '2023-04-29',
          reviewCount: 1165,
          rating: 4
        },
        {
          date: '2023-04-30',
          reviewCount: 1165,
          rating: 4
        },
        {
          date: '2023-05-01',
          reviewCount: 1165,
          rating: 4
        },
        {
          date: '2023-05-02',
          reviewCount: 1222,
          rating: 4
        },
        {
          date: '2023-05-03',
          reviewCount: 1222,
          rating: 4
        },
        {
          date: '2023-05-04',
          reviewCount: 1222,
          rating: 4
        },
        {
          date: '2023-05-05',
          reviewCount: 1222,
          rating: 4
        },
        {
          date: '2023-05-06',
          reviewCount: 1260,
          rating: 4
        },
        {
          date: '2023-05-07',
          reviewCount: 1260,
          rating: 4
        },
        {
          date: '2023-05-08',
          reviewCount: 1260,
          rating: 4
        },
        {
          date: '2023-05-09',
          reviewCount: 1290,
          rating: 4
        },
        {
          date: '2023-05-10',
          reviewCount: 1290,
          rating: 4
        },
        {
          date: '2023-05-11',
          reviewCount: 1290,
          rating: 4
        },
        {
          date: '2023-05-12',
          reviewCount: 1290,
          rating: 4
        },
        {
          date: '2023-05-13',
          reviewCount: 1324,
          rating: 4
        },
        {
          date: '2023-05-14',
          reviewCount: 1324,
          rating: 4
        },
        {
          date: '2023-05-15',
          reviewCount: 1324,
          rating: 4
        },
        {
          date: '2023-05-16',
          reviewCount: 1355,
          rating: 4
        },
        {
          date: '2023-05-17',
          reviewCount: 1355,
          rating: 4
        },
        {
          date: '2023-05-18',
          reviewCount: 1355,
          rating: 4
        },
        {
          date: '2023-05-19',
          reviewCount: 1355,
          rating: 4
        },
        {
          date: '2023-05-20',
          reviewCount: 1218,
          rating: 4
        },
        {
          date: '2023-05-21',
          reviewCount: 1218,
          rating: 4
        },
        {
          date: '2023-05-22',
          reviewCount: 1218,
          rating: 4
        },
        {
          date: '2023-05-23',
          reviewCount: 1234,
          rating: 4
        },
        {
          date: '2023-05-24',
          reviewCount: 1234,
          rating: 4
        },
        {
          date: '2023-05-25',
          reviewCount: 1234,
          rating: 4
        },
        {
          date: '2023-05-26',
          reviewCount: 1256,
          rating: 4
        },
        {
          date: '2023-05-27',
          reviewCount: 1256,
          rating: 4
        },
        {
          date: '2023-05-28',
          reviewCount: 1256,
          rating: 4
        },
        {
          date: '2023-05-29',
          reviewCount: 1256,
          rating: 4
        },
        {
          date: '2023-05-30',
          reviewCount: 1280,
          rating: 4
        },
        {
          date: '2023-05-31',
          reviewCount: 1280,
          rating: 4
        },
        {
          date: '2023-06-01',
          reviewCount: 1280,
          rating: 4
        },
        {
          date: '2023-06-02',
          reviewCount: 1280,
          rating: 4
        },
        {
          date: '2023-06-03',
          reviewCount: 1317,
          rating: 4
        },
        {
          date: '2023-06-04',
          reviewCount: 1317,
          rating: 4
        },
        {
          date: '2023-06-05',
          reviewCount: 1317,
          rating: 4
        },
        {
          date: '2023-06-06',
          reviewCount: 1568,
          rating: 4
        },
        {
          date: '2023-06-07',
          reviewCount: 1568,
          rating: 4
        },
        {
          date: '2023-06-08',
          reviewCount: 1568,
          rating: 4
        },
        {
          date: '2023-06-09',
          reviewCount: 1611,
          rating: 4
        },
        {
          date: '2023-06-10',
          reviewCount: 1611,
          rating: 4
        },
        {
          date: '2023-06-11',
          reviewCount: 1611,
          rating: 4
        },
        {
          date: '2023-06-12',
          reviewCount: 1611,
          rating: 4
        },
        {
          date: '2023-06-13',
          reviewCount: 1642,
          rating: 4
        },
        {
          date: '2023-06-14',
          reviewCount: 1642,
          rating: 4
        },
        {
          date: '2023-06-15',
          reviewCount: 1642,
          rating: 4
        },
        {
          date: '2023-06-16',
          reviewCount: 1700,
          rating: 4
        },
        {
          date: '2023-06-17',
          reviewCount: 1700,
          rating: 4
        },
        {
          date: '2023-06-18',
          reviewCount: 1700,
          rating: 4
        },
        {
          date: '2023-06-19',
          reviewCount: 1700,
          rating: 4
        },
        {
          date: '2023-06-20',
          reviewCount: 1752,
          rating: 4
        },
        {
          date: '2023-06-21',
          reviewCount: 1752,
          rating: 4
        },
        {
          date: '2023-06-22',
          reviewCount: 1752,
          rating: 4
        },
        {
          date: '2023-06-23',
          reviewCount: 1752,
          rating: 4
        },
        {
          date: '2023-06-24',
          reviewCount: 1803,
          rating: 4
        },
        {
          date: '2023-06-25',
          reviewCount: 1803,
          rating: 4
        },
        {
          date: '2023-06-26',
          reviewCount: 1803,
          rating: 4
        },
        {
          date: '2023-06-27',
          reviewCount: 1842,
          rating: 4
        },
        {
          date: '2023-06-28',
          reviewCount: 1842,
          rating: 4
        },
        {
          date: '2023-06-29',
          reviewCount: 1842,
          rating: 4
        },
        {
          date: '2023-06-30',
          reviewCount: 1880,
          rating: 4
        },
        {
          date: '2023-07-01',
          reviewCount: 1880,
          rating: 4
        },
        {
          date: '2023-07-02',
          reviewCount: 1880,
          rating: 4
        },
        {
          date: '2023-07-03',
          reviewCount: 1880,
          rating: 4
        },
        {
          date: '2023-07-04',
          reviewCount: 1939,
          rating: 4
        },
        {
          date: '2023-07-05',
          reviewCount: 1939,
          rating: 4
        },
        {
          date: '2023-07-06',
          reviewCount: 1939,
          rating: 4
        },
        {
          date: '2023-07-07',
          reviewCount: 1981,
          rating: 4
        },
        {
          date: '2023-07-08',
          reviewCount: 1981,
          rating: 4
        },
        {
          date: '2023-07-09',
          reviewCount: 1981,
          rating: 4
        },
        {
          date: '2023-07-10',
          reviewCount: 2035,
          rating: 4
        },
        {
          date: '2023-07-11',
          reviewCount: 2035,
          rating: 4
        },
        {
          date: '2023-07-12',
          reviewCount: 2035,
          rating: 4
        },
        {
          date: '2023-07-13',
          reviewCount: 2035,
          rating: 4
        },
        {
          date: '2023-07-14',
          reviewCount: 2101,
          rating: 4
        },
        {
          date: '2023-07-15',
          reviewCount: 2101,
          rating: 4
        },
        {
          date: '2023-07-16',
          reviewCount: 2101,
          rating: 4
        },
        {
          date: '2023-07-17',
          reviewCount: 2147,
          rating: 4
        },
        {
          date: '2023-07-18',
          reviewCount: 2147,
          rating: 4
        },
        {
          date: '2023-07-19',
          reviewCount: 2147,
          rating: 4
        },
        {
          date: '2023-07-20',
          reviewCount: 2147,
          rating: 4
        },
        {
          date: '2023-07-21',
          reviewCount: 2220,
          rating: 4
        },
        {
          date: '2023-07-22',
          reviewCount: 2220,
          rating: 4
        },
        {
          date: '2023-07-23',
          reviewCount: 2220,
          rating: 4
        },
        {
          date: '2023-07-24',
          reviewCount: 2287,
          rating: 4
        },
        {
          date: '2023-07-25',
          reviewCount: 2287,
          rating: 4
        },
        {
          date: '2023-07-26',
          reviewCount: 2287,
          rating: 4
        },
        {
          date: '2023-07-27',
          reviewCount: 2287,
          rating: 4
        },
        {
          date: '2023-07-28',
          reviewCount: 2366,
          rating: 4
        },
        {
          date: '2023-07-29',
          reviewCount: 2366,
          rating: 4
        },
        {
          date: '2023-07-30',
          reviewCount: 2366,
          rating: 4
        },
        {
          date: '2023-07-31',
          reviewCount: 2427,
          rating: 4
        },
        {
          date: '2023-08-01',
          reviewCount: 2427,
          rating: 4
        },
        {
          date: '2023-08-02',
          reviewCount: 2427,
          rating: 4
        },
        {
          date: '2023-08-03',
          reviewCount: 2427,
          rating: 4
        },
        {
          date: '2023-08-04',
          reviewCount: 2521,
          rating: 4
        },
        {
          date: '2023-08-05',
          reviewCount: 2521,
          rating: 4
        },
        {
          date: '2023-08-06',
          reviewCount: 2521,
          rating: 4
        },
        {
          date: '2023-08-07',
          reviewCount: 2595,
          rating: 4
        },
        {
          date: '2023-08-08',
          reviewCount: 2595,
          rating: 4
        },
        {
          date: '2023-08-09',
          reviewCount: 2595,
          rating: 4
        },
        {
          date: '2023-08-10',
          reviewCount: 2661,
          rating: 4
        },
        {
          date: '2023-08-11',
          reviewCount: 2661,
          rating: 4
        },
        {
          date: '2023-08-12',
          reviewCount: 2661,
          rating: 4
        },
        {
          date: '2023-08-13',
          reviewCount: 2661,
          rating: 4
        },
        {
          date: '2023-08-14',
          reviewCount: 2767,
          rating: 4
        },
        {
          date: '2023-08-15',
          reviewCount: 2767,
          rating: 4
        },
        {
          date: '2023-08-16',
          reviewCount: 2767,
          rating: 4
        },
        {
          date: '2023-08-17',
          reviewCount: 2861,
          rating: 4
        },
        {
          date: '2023-08-18',
          reviewCount: 2861,
          rating: 4
        },
        {
          date: '2023-08-19',
          reviewCount: 2861,
          rating: 4
        },
        {
          date: '2023-08-20',
          reviewCount: 2861,
          rating: 4
        },
        {
          date: '2023-08-21',
          reviewCount: 2961,
          rating: 4
        },
        {
          date: '2023-08-22',
          reviewCount: 2961,
          rating: 4
        },
        {
          date: '2023-08-23',
          reviewCount: 2961,
          rating: 4
        },
        {
          date: '2023-08-24',
          reviewCount: 2961,
          rating: 4
        },
        {
          date: '2023-08-25',
          reviewCount: 3084,
          rating: 4
        },
        {
          date: '2023-08-26',
          reviewCount: 3084,
          rating: 4
        },
        {
          date: '2023-08-27',
          reviewCount: 3084,
          rating: 4
        },
        {
          date: '2023-08-28',
          reviewCount: 3134,
          rating: 4
        },
        {
          date: '2023-08-29',
          reviewCount: 3134,
          rating: 4
        },
        {
          date: '2023-08-30',
          reviewCount: 3134,
          rating: 4
        },
        {
          date: '2023-08-31',
          reviewCount: 3203,
          rating: 4
        },
        {
          date: '2023-09-01',
          reviewCount: 3203,
          rating: 4
        },
        {
          date: '2023-09-02',
          reviewCount: 3203,
          rating: 4
        },
        {
          date: '2023-09-03',
          reviewCount: 3269,
          rating: 4
        },
        {
          date: '2023-09-04',
          reviewCount: 3269,
          rating: 4
        },
        {
          date: '2023-09-05',
          reviewCount: 3269,
          rating: 4
        },
        {
          date: '2023-09-06',
          reviewCount: 3269,
          rating: 4
        },
        {
          date: '2023-09-07',
          reviewCount: 3362,
          rating: 4
        },
        {
          date: '2023-09-08',
          reviewCount: 3362,
          rating: 4
        },
        {
          date: '2023-09-09',
          reviewCount: 3362,
          rating: 4
        },
        {
          date: '2023-09-10',
          reviewCount: 3362,
          rating: 4
        },
        {
          date: '2023-09-11',
          reviewCount: 3362,
          rating: 4
        },
        {
          date: '2023-09-12',
          reviewCount: 3562,
          rating: 4
        },
        {
          date: '2023-09-13',
          reviewCount: 3562,
          rating: 4
        },
        {
          date: '2023-09-14',
          reviewCount: 3562,
          rating: 4
        },
        {
          date: '2023-09-15',
          reviewCount: 3562,
          rating: 4
        },
        {
          date: '2023-09-16',
          reviewCount: 3562,
          rating: 4
        },
        {
          date: '2023-09-17',
          reviewCount: 3744,
          rating: 4
        },
        {
          date: '2023-09-18',
          reviewCount: 3744,
          rating: 4
        },
        {
          date: '2023-09-19',
          reviewCount: 3744,
          rating: 4
        },
        {
          date: '2023-09-20',
          reviewCount: 3744,
          rating: 4
        },
        {
          date: '2023-09-21',
          reviewCount: 3744,
          rating: 4
        },
        {
          date: '2023-09-22',
          reviewCount: 3912,
          rating: 4
        },
        {
          date: '2023-09-23',
          reviewCount: 3912,
          rating: 4
        },
        {
          date: '2023-09-24',
          reviewCount: 3912,
          rating: 4
        },
        {
          date: '2023-09-25',
          reviewCount: 3912,
          rating: 4
        },
        {
          date: '2023-09-26',
          reviewCount: 3912,
          rating: 4
        },
        {
          date: '2023-09-27',
          reviewCount: 4105,
          rating: 4
        },
        {
          date: '2023-09-28',
          reviewCount: 4105,
          rating: 4
        },
        {
          date: '2023-09-29',
          reviewCount: 4105,
          rating: 4
        },
        {
          date: '2023-09-30',
          reviewCount: 4105,
          rating: 4
        },
        {
          date: '2023-10-01',
          reviewCount: 4105,
          rating: 4
        },
        {
          date: '2023-10-02',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-03',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-04',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-05',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-06',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-07',
          reviewCount: 4257,
          rating: 4
        },
        {
          date: '2023-10-08',
          reviewCount: 4454,
          rating: 4
        },
        {
          date: '2023-10-09',
          reviewCount: 4454,
          rating: 4
        },
        {
          date: '2023-10-10',
          reviewCount: 4454,
          rating: 4
        },
        {
          date: '2023-10-11',
          reviewCount: 4454,
          rating: 4
        },
        {
          date: '2023-10-12',
          reviewCount: 4454,
          rating: 4
        },
        {
          date: '2023-10-13',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-14',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-15',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-16',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-17',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-18',
          reviewCount: 4633,
          rating: 4
        },
        {
          date: '2023-10-19',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-20',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-21',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-22',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-23',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-24',
          reviewCount: 4870,
          rating: 4
        },
        {
          date: '2023-10-25',
          reviewCount: 5097,
          rating: 4
        },
        {
          date: '2023-10-26',
          reviewCount: 5097,
          rating: 4
        },
        {
          date: '2023-10-27',
          reviewCount: 5097,
          rating: 4
        },
        {
          date: '2023-10-28',
          reviewCount: 5097,
          rating: 4
        },
        {
          date: '2023-10-29',
          reviewCount: 5097,
          rating: 4
        },
        {
          date: '2023-10-30',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-10-31',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-11-01',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-11-02',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-11-03',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-11-04',
          reviewCount: 5333,
          rating: 4
        },
        {
          date: '2023-11-05',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-06',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-07',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-08',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-09',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-10',
          reviewCount: 5608,
          rating: 4
        },
        {
          date: '2023-11-11',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-12',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-13',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-14',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-15',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-16',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-17',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-18',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-19',
          reviewCount: 5961,
          rating: 4
        },
        {
          date: '2023-11-20',
          reviewCount: 6356,
          rating: 4
        },
        {
          date: '2023-11-21',
          reviewCount: 6356,
          rating: 4
        },
        {
          date: '2023-11-22',
          reviewCount: 6356,
          rating: 4
        },
        {
          date: '2023-11-23',
          reviewCount: 6511,
          rating: 4
        },
        {
          date: '2023-11-24',
          reviewCount: 6511,
          rating: 4
        },
        {
          date: '2023-11-25',
          reviewCount: 6511,
          rating: 4
        },
        {
          date: '2023-11-26',
          reviewCount: 6511,
          rating: 4
        },
        {
          date: '2023-11-27',
          reviewCount: 6705,
          rating: 4
        },
        {
          date: '2023-11-28',
          reviewCount: 6705,
          rating: 4
        },
        {
          date: '2023-11-29',
          reviewCount: 6705,
          rating: 4
        },
        {
          date: '2023-11-30',
          reviewCount: 6705,
          rating: 4
        },
        {
          date: '2023-12-01',
          reviewCount: 6954,
          rating: 4
        },
        {
          date: '2023-12-02',
          reviewCount: 6954,
          rating: 4
        },
        {
          date: '2023-12-03',
          reviewCount: 6954,
          rating: 4
        },
        {
          date: '2023-12-04',
          reviewCount: 7061,
          rating: 4
        },
        {
          date: '2023-12-05',
          reviewCount: 7061,
          rating: 4
        },
        {
          date: '2023-12-06',
          reviewCount: 7061,
          rating: 4
        },
        {
          date: '2023-12-07',
          reviewCount: 7182,
          rating: 4
        },
        {
          date: '2023-12-08',
          reviewCount: 7182,
          rating: 4
        },
        {
          date: '2023-12-09',
          reviewCount: 7182,
          rating: 4
        },
        {
          date: '2023-12-10',
          reviewCount: 7182,
          rating: 4
        },
        {
          date: '2023-12-11',
          reviewCount: 7351,
          rating: 4
        },
        {
          date: '2023-12-12',
          reviewCount: 7351,
          rating: 4
        },
        {
          date: '2023-12-13',
          reviewCount: 7351,
          rating: 4
        },
        {
          date: '2023-12-14',
          reviewCount: 7351,
          rating: 4
        },
        {
          date: '2023-12-15',
          reviewCount: 7495,
          rating: 4
        },
        {
          date: '2023-12-16',
          reviewCount: 7495,
          rating: 4
        },
        {
          date: '2023-12-17',
          reviewCount: 7495,
          rating: 4
        },
        {
          date: '2023-12-18',
          reviewCount: 7495,
          rating: 4
        },
        {
          date: '2023-12-19',
          reviewCount: 7624,
          rating: 4
        },
        {
          date: '2023-12-20',
          reviewCount: 7624,
          rating: 4
        },
        {
          date: '2023-12-21',
          reviewCount: 7624,
          rating: 4
        },
        {
          date: '2023-12-22',
          reviewCount: 7711,
          rating: 4
        },
        {
          date: '2023-12-23',
          reviewCount: 7711,
          rating: 4
        },
        {
          date: '2023-12-24',
          reviewCount: 7711,
          rating: 4
        },
        {
          date: '2023-12-25',
          reviewCount: 7711,
          rating: 4
        },
        {
          date: '2023-12-26',
          reviewCount: 7849,
          rating: 4
        },
        {
          date: '2023-12-27',
          reviewCount: 7849,
          rating: 4
        },
        {
          date: '2023-12-28',
          reviewCount: 7849,
          rating: 4
        },
        {
          date: '2023-12-29',
          reviewCount: 7849,
          rating: 4
        },
        {
          date: '2023-12-30',
          reviewCount: 8014,
          rating: 4
        },
        {
          date: '2023-12-31',
          reviewCount: 8014,
          rating: 4
        },
        {
          date: '2024-01-01',
          reviewCount: 8014,
          rating: 4
        },
        {
          date: '2024-01-02',
          reviewCount: 8129,
          rating: 4
        },
        {
          date: '2024-01-03',
          reviewCount: 8129,
          rating: 4
        },
        {
          date: '2024-01-04',
          reviewCount: 8129,
          rating: 4
        },
        {
          date: '2024-01-05',
          reviewCount: 8129,
          rating: 4
        },
        {
          date: '2024-01-06',
          reviewCount: 8301,
          rating: 4
        },
        {
          date: '2024-01-07',
          reviewCount: 8301,
          rating: 4
        },
        {
          date: '2024-01-08',
          reviewCount: 8301,
          rating: 4
        },
        {
          date: '2024-01-09',
          reviewCount: 8426,
          rating: 4
        },
        {
          date: '2024-01-10',
          reviewCount: 8426,
          rating: 4
        },
        {
          date: '2024-01-11',
          reviewCount: 8426,
          rating: 4
        },
        {
          date: '2024-01-12',
          reviewCount: 8426,
          rating: 4
        },
        {
          date: '2024-01-13',
          reviewCount: 8565,
          rating: 4
        },
        {
          date: '2024-01-14',
          reviewCount: 8565,
          rating: 4
        },
        {
          date: '2024-01-15',
          reviewCount: 8565,
          rating: 4
        },
        {
          date: '2024-01-16',
          reviewCount: 8653,
          rating: 4
        },
        {
          date: '2024-01-17',
          reviewCount: 8653,
          rating: 4
        },
        {
          date: '2024-01-18',
          reviewCount: 8653,
          rating: 4
        },
        {
          date: '2024-01-19',
          reviewCount: 8653,
          rating: 4
        },
        {
          date: '2024-01-20',
          reviewCount: 8840,
          rating: 4
        },
        {
          date: '2024-01-21',
          reviewCount: 8840,
          rating: 4
        },
        {
          date: '2024-01-22',
          reviewCount: 8840,
          rating: 4
        },
        {
          date: '2024-01-23',
          reviewCount: 8969,
          rating: 4
        },
        {
          date: '2024-01-24',
          reviewCount: 8969,
          rating: 4
        },
        {
          date: '2024-01-25',
          reviewCount: 8969,
          rating: 4
        },
        {
          date: '2024-01-26',
          reviewCount: 9136,
          rating: 4
        },
        {
          date: '2024-01-27',
          reviewCount: 9136,
          rating: 4
        },
        {
          date: '2024-01-28',
          reviewCount: 9136,
          rating: 4
        },
        {
          date: '2024-01-29',
          reviewCount: 9136,
          rating: 4
        },
        {
          date: '2024-01-30',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2024-01-31',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2024-02-01',
          reviewCount: 9319,
          rating: 4
        },
        {
          date: '2024-02-02',
          reviewCount: 9435,
          rating: 4
        },
        {
          date: '2024-02-03',
          reviewCount: 9435,
          rating: 4
        },
        {
          date: '2024-02-04',
          reviewCount: 9435,
          rating: 4
        },
        {
          date: '2024-02-05',
          reviewCount: 9435,
          rating: 4
        },
        {
          date: '2024-02-06',
          reviewCount: 9623,
          rating: 4
        },
        {
          date: '2024-02-07',
          reviewCount: 9623,
          rating: 4
        },
        {
          date: '2024-02-08',
          reviewCount: 9623,
          rating: 4
        },
        {
          date: '2024-02-09',
          reviewCount: 9623,
          rating: 4
        },
        {
          date: '2024-02-10',
          reviewCount: 9871,
          rating: 4
        },
        {
          date: '2024-02-11',
          reviewCount: 9871,
          rating: 4
        },
        {
          date: '2024-02-12',
          reviewCount: 9871,
          rating: 4
        },
        {
          date: '2024-02-13',
          reviewCount: 9999,
          rating: 4
        },
        {
          date: '2024-02-14',
          reviewCount: 9999,
          rating: 4
        },
        {
          date: '2024-02-15',
          reviewCount: 9999,
          rating: 4
        },
        {
          date: '2024-02-16',
          reviewCount: 9999,
          rating: 4
        },
        {
          date: '2024-02-17',
          reviewCount: 10194,
          rating: 4
        },
        {
          date: '2024-02-18',
          reviewCount: 10194,
          rating: 4
        },
        {
          date: '2024-02-19',
          reviewCount: 10194,
          rating: 4
        },
        {
          date: '2024-02-20',
          reviewCount: 10366,
          rating: 4
        },
        {
          date: '2024-02-21',
          reviewCount: 10366,
          rating: 4
        },
        {
          date: '2024-02-22',
          reviewCount: 10366,
          rating: 4
        },
        {
          date: '2024-02-23',
          reviewCount: 10366,
          rating: 4
        },
        {
          date: '2024-02-24',
          reviewCount: 10611,
          rating: 4
        },
        {
          date: '2024-02-25',
          reviewCount: 10611,
          rating: 4
        },
        {
          date: '2024-02-26',
          reviewCount: 10611,
          rating: 4
        },
        {
          date: '2024-02-27',
          reviewCount: 10799,
          rating: 4
        },
        {
          date: '2024-02-28',
          reviewCount: 10799,
          rating: 4
        },
        {
          date: '2024-02-29',
          reviewCount: 10799,
          rating: 4
        },
        {
          date: '2024-03-01',
          reviewCount: 10991,
          rating: 4
        },
        {
          date: '2024-03-02',
          reviewCount: 10991,
          rating: 4
        },
        {
          date: '2024-03-03',
          reviewCount: 10991,
          rating: 4
        },
        {
          date: '2024-03-04',
          reviewCount: 10991,
          rating: 4
        },
        {
          date: '2024-03-05',
          reviewCount: 11233,
          rating: 4
        },
        {
          date: '2024-03-06',
          reviewCount: 11233,
          rating: 4
        },
        {
          date: '2024-03-07',
          reviewCount: 11233,
          rating: 4
        },
        {
          date: '2024-03-08',
          reviewCount: 11233,
          rating: 4
        },
        {
          date: '2024-03-09',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-03-10',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-03-11',
          reviewCount: 11492,
          rating: 4
        },
        {
          date: '2024-03-12',
          reviewCount: 11654,
          rating: 4
        },
        {
          date: '2024-03-13',
          reviewCount: 11654,
          rating: 4
        },
        {
          date: '2024-03-14',
          reviewCount: 11654,
          rating: 4
        },
        {
          date: '2024-03-15',
          reviewCount: 11830,
          rating: 4
        },
        {
          date: '2024-03-16',
          reviewCount: 11830,
          rating: 4
        },
        {
          date: '2024-03-17',
          reviewCount: 11830,
          rating: 4
        },
        {
          date: '2024-03-18',
          reviewCount: 11830,
          rating: 4
        },
        {
          date: '2024-03-19',
          reviewCount: 12082,
          rating: 4
        },
        {
          date: '2024-03-20',
          reviewCount: 12082,
          rating: 4
        },
        {
          date: '2024-03-21',
          reviewCount: 12082,
          rating: 4
        },
        {
          date: '2024-03-22',
          reviewCount: 12309,
          rating: 4
        },
        {
          date: '2024-03-23',
          reviewCount: 12309,
          rating: 4
        },
        {
          date: '2024-03-24',
          reviewCount: 12309,
          rating: 4
        },
        {
          date: '2024-03-25',
          reviewCount: 12309,
          rating: 4
        },
        {
          date: '2024-03-26',
          reviewCount: 12605,
          rating: 4
        },
        {
          date: '2024-03-27',
          reviewCount: 12605,
          rating: 4
        },
        {
          date: '2024-03-28',
          reviewCount: 12605,
          rating: 4
        },
        {
          date: '2024-03-29',
          reviewCount: 12605,
          rating: 4
        },
        {
          date: '2024-03-30',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-03-31',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-01',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-02',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-03',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-04',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-05',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-06',
          reviewCount: 12893,
          rating: 4
        },
        {
          date: '2024-04-07',
          reviewCount: 13462,
          rating: 4
        },
        {
          date: '2024-04-08',
          reviewCount: 13462,
          rating: 4
        },
        {
          date: '2024-04-09',
          reviewCount: 13462,
          rating: 4
        },
        {
          date: '2024-04-10',
          reviewCount: 13462,
          rating: 4
        },
        {
          date: '2024-04-11',
          reviewCount: 13758,
          rating: 4
        },
        {
          date: '2024-04-12',
          reviewCount: 13758,
          rating: 4
        },
        {
          date: '2024-04-13',
          reviewCount: 13758,
          rating: 4
        },
        {
          date: '2024-04-14',
          reviewCount: 13948,
          rating: 4
        },
        {
          date: '2024-04-15',
          reviewCount: 13948,
          rating: 4
        },
        {
          date: '2024-04-16',
          reviewCount: 13948,
          rating: 4
        },
        {
          date: '2024-04-17',
          reviewCount: 13948,
          rating: 4
        },
        {
          date: '2024-04-18',
          reviewCount: 14216,
          rating: 4
        },
        {
          date: '2024-04-19',
          reviewCount: 14216,
          rating: 4
        },
        {
          date: '2024-04-20',
          reviewCount: 14216,
          rating: 4
        },
        {
          date: '2024-04-21',
          reviewCount: 14426,
          rating: 4
        },
        {
          date: '2024-04-22',
          reviewCount: 14426,
          rating: 4
        },
        {
          date: '2024-04-23',
          reviewCount: 14426,
          rating: 4
        },
        {
          date: '2024-04-24',
          reviewCount: 14426,
          rating: 4
        },
        {
          date: '2024-04-25',
          reviewCount: 14730,
          rating: 4
        },
        {
          date: '2024-04-26',
          reviewCount: 14730,
          rating: 4
        },
        {
          date: '2024-04-27',
          reviewCount: 14730,
          rating: 4
        },
        {
          date: '2024-04-28',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-04-29',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-04-30',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-05-01',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-05-02',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-05-03',
          reviewCount: 14942,
          rating: 4
        },
        {
          date: '2024-05-04',
          reviewCount: 15376,
          rating: 4
        },
        {
          date: '2024-05-05',
          reviewCount: 15376,
          rating: 4
        },
        {
          date: '2024-05-06',
          reviewCount: 15376,
          rating: 4
        },
        {
          date: '2024-05-07',
          reviewCount: 15376,
          rating: 4
        },
        {
          date: '2024-05-08',
          reviewCount: 15376,
          rating: 4
        },
        {
          date: '2024-05-09',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-10',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-11',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-12',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-13',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-14',
          reviewCount: 15754,
          rating: 4
        },
        {
          date: '2024-05-15',
          reviewCount: 16127,
          rating: 4
        },
        {
          date: '2024-05-16',
          reviewCount: 16127,
          rating: 4
        },
        {
          date: '2024-05-17',
          reviewCount: 16127,
          rating: 4
        },
        {
          date: '2024-05-18',
          reviewCount: 16127,
          rating: 4
        },
        {
          date: '2024-05-19',
          reviewCount: 16127,
          rating: 4
        },
        {
          date: '2024-05-20',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-21',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-22',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-23',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-24',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-25',
          reviewCount: 16401,
          rating: 4
        },
        {
          date: '2024-05-26',
          reviewCount: 16883,
          rating: 4
        },
        {
          date: '2024-05-27',
          reviewCount: 16883,
          rating: 4
        },
        {
          date: '2024-05-28',
          reviewCount: 16883,
          rating: 4
        },
        {
          date: '2024-05-29',
          reviewCount: 16883,
          rating: 4
        },
        {
          date: '2024-05-30',
          reviewCount: 17155,
          rating: 4
        },
        {
          date: '2024-05-31',
          reviewCount: 17155,
          rating: 4
        },
        {
          date: '2024-06-01',
          reviewCount: 17155,
          rating: 4
        },
        {
          date: '2024-06-02',
          reviewCount: 17348,
          rating: 4
        },
        {
          date: '2024-06-03',
          reviewCount: 17348,
          rating: 4
        },
        {
          date: '2024-06-04',
          reviewCount: 17348,
          rating: 4
        },
        {
          date: '2024-06-05',
          reviewCount: 17348,
          rating: 4
        },
        {
          date: '2024-06-06',
          reviewCount: 17633,
          rating: 4
        },
        {
          date: '2024-06-07',
          reviewCount: 17633,
          rating: 4
        },
        {
          date: '2024-06-08',
          reviewCount: 17633,
          rating: 4
        },
        {
          date: '2024-06-09',
          reviewCount: 17859,
          rating: 4
        },
        {
          date: '2024-06-10',
          reviewCount: 17859,
          rating: 4
        },
        {
          date: '2024-06-11',
          reviewCount: 17859,
          rating: 4
        },
        {
          date: '2024-06-12',
          reviewCount: 18049,
          rating: 4
        },
        {
          date: '2024-06-13',
          reviewCount: 18049,
          rating: 4
        },
        {
          date: '2024-06-14',
          reviewCount: 18049,
          rating: 4
        },
        {
          date: '2024-06-15',
          reviewCount: 18049,
          rating: 4
        },
        {
          date: '2024-06-16',
          reviewCount: 18291,
          rating: 4
        },
        {
          date: '2024-06-17',
          reviewCount: 18291,
          rating: 4
        },
        {
          date: '2024-06-18',
          reviewCount: 18291,
          rating: 4
        },
        {
          date: '2024-06-19',
          reviewCount: 18291,
          rating: 4
        },
        {
          date: '2024-06-20',
          reviewCount: 18572,
          rating: 4
        },
        {
          date: '2024-06-21',
          reviewCount: 18572,
          rating: 4
        },
        {
          date: '2024-06-22',
          reviewCount: 18572,
          rating: 4
        },
        {
          date: '2024-06-23',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-24',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-25',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-26',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-27',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-28',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-29',
          reviewCount: 18765,
          rating: 4
        },
        {
          date: '2024-06-30',
          reviewCount: 19148,
          rating: 4
        },
        {
          date: '2024-07-01',
          reviewCount: 19148,
          rating: 4
        },
        {
          date: '2024-07-02',
          reviewCount: 19148,
          rating: 4
        },
        {
          date: '2024-07-03',
          reviewCount: 19386,
          rating: 4
        },
        {
          date: '2024-07-04',
          reviewCount: 19386,
          rating: 4
        },
        {
          date: '2024-07-05',
          reviewCount: 19386,
          rating: 4
        },
        {
          date: '2024-07-06',
          reviewCount: 19386,
          rating: 4
        },
        {
          date: '2024-07-07',
          reviewCount: 19619,
          rating: 4
        },
        {
          date: '2024-07-08',
          reviewCount: 19619,
          rating: 4
        },
        {
          date: '2024-07-09',
          reviewCount: 19619,
          rating: 4
        },
        {
          date: '2024-07-10',
          reviewCount: 19619,
          rating: 4
        },
        {
          date: '2024-07-11',
          reviewCount: 19880,
          rating: 4
        },
        {
          date: '2024-07-12',
          reviewCount: 19880,
          rating: 4
        },
        {
          date: '2024-07-13',
          reviewCount: 19880,
          rating: 4
        },
        {
          date: '2024-07-14',
          reviewCount: 20064,
          rating: 4
        },
        {
          date: '2024-07-15',
          reviewCount: 20064,
          rating: 4
        },
        {
          date: '2024-07-16',
          reviewCount: 20064,
          rating: 4
        },
        {
          date: '2024-07-17',
          reviewCount: 20064,
          rating: 4
        },
        {
          date: '2024-07-18',
          reviewCount: 20299,
          rating: 4
        },
        {
          date: '2024-07-19',
          reviewCount: 20299,
          rating: 4
        },
        {
          date: '2024-07-20',
          reviewCount: 20299,
          rating: 4
        },
        {
          date: '2024-07-21',
          reviewCount: 20487,
          rating: 4
        },
        {
          date: '2024-07-22',
          reviewCount: 20487,
          rating: 4
        },
        {
          date: '2024-07-23',
          reviewCount: 20487,
          rating: 4
        },
        {
          date: '2024-07-24',
          reviewCount: 20487,
          rating: 4
        },
        {
          date: '2024-07-25',
          reviewCount: 20745,
          rating: 4
        },
        {
          date: '2024-07-26',
          reviewCount: 20745,
          rating: 4
        },
        {
          date: '2024-07-27',
          reviewCount: 20745,
          rating: 4
        },
        {
          date: '2024-07-28',
          reviewCount: 20938,
          rating: 4
        },
        {
          date: '2024-07-29',
          reviewCount: 20938,
          rating: 4
        },
        {
          date: '2024-07-30',
          reviewCount: 20938,
          rating: 4
        },
        {
          date: '2024-07-31',
          reviewCount: 20938,
          rating: 4
        },
        {
          date: '2024-08-01',
          reviewCount: 21188,
          rating: 4
        },
        {
          date: '2024-08-02',
          reviewCount: 21188,
          rating: 4
        },
        {
          date: '2024-08-03',
          reviewCount: 21188,
          rating: 4
        },
        {
          date: '2024-08-04',
          reviewCount: 21361,
          rating: 4
        },
        {
          date: '2024-08-05',
          reviewCount: 21361,
          rating: 4
        },
        {
          date: '2024-08-06',
          reviewCount: 21361,
          rating: 4
        },
        {
          date: '2024-08-07',
          reviewCount: 21361,
          rating: 4
        },
        {
          date: '2024-08-08',
          reviewCount: 21630,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 21630,
          rating: 4
        },
        {
          date: '2024-08-10',
          reviewCount: 21630,
          rating: 4
        },
        {
          date: '2024-08-11',
          reviewCount: 21839,
          rating: 4
        },
        {
          date: '2024-08-12',
          reviewCount: 21839,
          rating: 4
        },
        {
          date: '2024-08-13',
          reviewCount: 21839,
          rating: 4
        },
        {
          date: '2024-08-14',
          reviewCount: 21839,
          rating: 4
        },
        {
          date: '2024-08-15',
          reviewCount: 22070,
          rating: 4
        },
        {
          date: '2024-08-16',
          reviewCount: 22070,
          rating: 4
        },
        {
          date: '2024-08-17',
          reviewCount: 22070,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 22252,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 22252,
          rating: 4
        },
        {
          date: '2024-08-20',
          reviewCount: 22252,
          rating: 4
        },
        {
          date: '2024-08-21',
          reviewCount: 22252,
          rating: 4
        },
        {
          date: '2024-08-22',
          reviewCount: 22504,
          rating: 4
        },
        {
          date: '2024-08-23',
          reviewCount: 22504,
          rating: 4
        },
        {
          date: '2024-08-24',
          reviewCount: 22504,
          rating: 4
        },
        {
          date: '2024-08-25',
          reviewCount: 22748,
          rating: 4
        },
        {
          date: '2024-08-26',
          reviewCount: 22748,
          rating: 4
        },
        {
          date: '2024-08-27',
          reviewCount: 22748,
          rating: 4
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,
      createdAt: '2024-08-28T17:02:01.294Z'
    }
  ]
}

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ErrorStateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 280 258" fill="none">
      <path
        d="M130.935 253.62C200.97 253.62 257.745 196.845 257.745 126.81C257.745 56.7748 200.97 0 130.935 0C60.8997 0 4.125 56.7748 4.125 126.81C4.125 196.845 60.8997 253.62 130.935 253.62Z"
        fill="#B3DAFF"
      />
      <path
        d="M187.683 36.2202V158.09C187.683 161.3 185.084 163.9 181.874 163.9H7.5835C4.3735 163.9 1.77344 161.3 1.77344 158.09V36.2202C1.77344 33.0102 4.3735 30.4102 7.5835 30.4102H181.874C185.084 30.4102 187.683 33.0102 187.683 36.2202Z"
        fill="white"
      />
      <path
        d="M181.874 165.41H7.5835C3.5535 165.41 0.273438 162.13 0.273438 158.1V36.23C0.273438 32.2 3.5535 28.9199 7.5835 28.9199H181.874C185.904 28.9199 189.183 32.2 189.183 36.23V158.1C189.183 162.13 185.904 165.41 181.874 165.41ZM7.5835 31.9099C5.2035 31.9099 3.27344 33.85 3.27344 36.22V158.09C3.27344 160.47 5.2135 162.4 7.5835 162.4H181.874C184.254 162.4 186.183 160.46 186.183 158.09V36.22C186.183 33.84 184.254 31.9099 181.874 31.9099H7.5835Z"
        fill="#243C98"
      />
      <path
        d="M187.683 36.2202V50.6702H1.77344V36.2202C1.77344 33.0102 4.3735 30.4102 7.5835 30.4102H181.874C185.084 30.4102 187.683 33.0102 187.683 36.2202Z"
        fill="#324AA8"
      />
      <path
        d="M187.683 52.1702H1.77344C0.943438 52.1702 0.273438 51.5002 0.273438 50.6702V36.2202C0.273438 32.1902 3.5535 28.9102 7.5835 28.9102H181.874C185.904 28.9102 189.183 32.1902 189.183 36.2202V50.6702C189.183 51.5002 188.513 52.1702 187.683 52.1702ZM3.27344 49.1702H186.183V36.2202C186.183 33.8402 184.254 31.9102 181.874 31.9102H7.5835C5.2035 31.9102 3.27344 33.8502 3.27344 36.2202V49.1702Z"
        fill="#243C98"
      />
      <path
        d="M92.7325 137.151C109.119 137.151 122.402 123.867 122.402 107.481C122.402 91.0943 109.119 77.8105 92.7325 77.8105C76.3463 77.8105 63.0625 91.0943 63.0625 107.481C63.0625 123.867 76.3463 137.151 92.7325 137.151Z"
        fill="#EA0000"
      />
      <path
        d="M96.9924 107.48L103.652 100.82C104.672 99.8004 104.932 98.1705 104.122 96.9705C103.032 95.3305 100.792 95.1704 99.4723 96.4904L92.7323 103.23L86.0723 96.5704C85.0523 95.5504 83.4223 95.2904 82.2223 96.0904C80.5823 97.1804 80.4224 99.4204 81.7424 100.74L88.4823 107.48L81.8323 114.13C80.8523 115.11 80.5922 116.91 81.3822 118.04C81.9922 118.91 82.9324 119.35 83.8724 119.35C84.6424 119.35 85.4122 119.06 86.0022 118.47L92.7424 111.73L99.4823 118.47C100.072 119.06 100.842 119.35 101.612 119.35C102.552 119.35 103.492 118.91 104.102 118.04C104.892 116.9 104.632 115.11 103.652 114.13L96.9924 107.48Z"
        fill="white"
      />
      <path
        d="M101.603 120.86C100.403 120.86 99.2627 120.39 98.4127 119.54L92.7328 113.86L87.0528 119.54C86.2028 120.39 85.0728 120.86 83.8628 120.86C82.3828 120.86 80.9927 120.13 80.1427 118.91C78.9627 117.22 79.2427 114.6 80.7627 113.08L86.3528 107.49L80.6728 101.81C79.7128 100.85 79.2428 99.5304 79.3728 98.1804C79.5028 96.8304 80.2427 95.6204 81.3827 94.8604C83.1527 93.6804 85.5628 93.9604 87.1228 95.5204L92.7228 101.12L98.4027 95.4405C99.3627 94.4805 100.693 94.0104 102.033 94.1404C103.383 94.2704 104.593 95.0104 105.353 96.1504C106.533 97.9204 106.253 100.33 104.693 101.89L99.0928 107.49L104.683 113.08C106.203 114.6 106.483 117.22 105.303 118.91C104.473 120.13 103.083 120.86 101.603 120.86ZM92.7328 110.23C93.1328 110.23 93.5128 110.39 93.7928 110.67L100.533 117.41C101.173 118.05 102.323 117.95 102.853 117.18C103.213 116.67 103.073 115.68 102.573 115.19L95.9228 108.54C95.3328 107.95 95.3328 107 95.9228 106.42L102.583 99.7604C103.123 99.2204 103.253 98.3704 102.873 97.8004C102.513 97.2604 102.013 97.1404 101.753 97.1104C101.303 97.0704 100.853 97.2204 100.533 97.5404L93.7928 104.28C93.5128 104.56 93.1328 104.72 92.7328 104.72C92.3328 104.72 91.9528 104.56 91.6728 104.28L85.0127 97.6204C84.4727 97.0804 83.6228 96.9505 83.0528 97.3305C82.5128 97.6905 82.3928 98.1905 82.3628 98.4505C82.3228 98.9005 82.4728 99.3504 82.7928 99.6704L89.5327 106.41C90.1227 107 90.1227 107.95 89.5327 108.53L82.8827 115.18C82.3827 115.68 82.2528 116.66 82.6028 117.17C83.1328 117.94 84.2828 118.04 84.9228 117.4L91.6627 110.66C91.9527 110.39 92.3328 110.23 92.7328 110.23Z"
        fill="#EA0000"
      />
      <path
        d="M25.9325 42.4099C27.2414 42.4099 28.3025 41.3488 28.3025 40.0399C28.3025 38.731 27.2414 37.6699 25.9325 37.6699C24.6236 37.6699 23.5625 38.731 23.5625 40.0399C23.5625 41.3488 24.6236 42.4099 25.9325 42.4099Z"
        fill="#12B76A"
      />
      <path
        d="M18.2216 42.4099C19.5305 42.4099 20.5916 41.3488 20.5916 40.0399C20.5916 38.731 19.5305 37.6699 18.2216 37.6699C16.9126 37.6699 15.8516 38.731 15.8516 40.0399C15.8516 41.3488 16.9126 42.4099 18.2216 42.4099Z"
        fill="#F3C94E"
      />
      <path
        d="M10.5106 42.4099C11.8195 42.4099 12.8806 41.3488 12.8806 40.0399C12.8806 38.731 11.8195 37.6699 10.5106 37.6699C9.20171 37.6699 8.14062 38.731 8.14062 40.0399C8.14062 41.3488 9.20171 42.4099 10.5106 42.4099Z"
        fill="#EA0000"
      />
      <path
        d="M278.238 142.97V249.77C278.238 252.98 275.638 255.58 272.428 255.58H119.138C115.928 255.58 113.328 252.98 113.328 249.77V142.97C113.328 139.76 115.928 137.16 119.138 137.16H272.428C275.628 137.15 278.238 139.76 278.238 142.97Z"
        fill="white"
      />
      <path
        d="M272.42 257.08H119.13C115.1 257.08 111.82 253.8 111.82 249.77V142.97C111.82 138.94 115.1 135.66 119.13 135.66H272.42C276.45 135.66 279.73 138.94 279.73 142.97V249.77C279.74 253.8 276.45 257.08 272.42 257.08ZM119.13 138.65C116.75 138.65 114.82 140.58 114.82 142.96V249.76C114.82 252.14 116.76 254.07 119.13 254.07H272.42C274.8 254.07 276.73 252.13 276.73 249.76V142.96C276.73 140.58 274.8 138.65 272.42 138.65H119.13Z"
        fill="#243C98"
      />
      <path
        d="M278.24 142.97V155.13H113.32V142.97C113.32 139.76 115.92 137.16 119.13 137.16H272.42C275.63 137.15 278.24 139.76 278.24 142.97Z"
        fill="#324AA8"
      />
      <path
        d="M278.24 156.63H113.32C112.49 156.63 111.82 155.96 111.82 155.13V142.97C111.82 138.94 115.1 135.66 119.13 135.66H272.42C276.45 135.66 279.73 138.94 279.73 142.97V155.13C279.74 155.96 279.06 156.63 278.24 156.63ZM114.82 153.63H276.74V142.97C276.74 140.59 274.81 138.66 272.43 138.66H119.14C116.76 138.66 114.83 140.59 114.83 142.97V153.63H114.82Z"
        fill="#243C98"
      />
      <path
        d="M134.756 147.8C135.916 147.8 136.856 146.859 136.856 145.7C136.856 144.54 135.916 143.6 134.756 143.6C133.596 143.6 132.656 144.54 132.656 145.7C132.656 146.859 133.596 147.8 134.756 147.8Z"
        fill="#12B76A"
      />
      <path
        d="M127.92 147.8C129.08 147.8 130.02 146.859 130.02 145.7C130.02 144.54 129.08 143.6 127.92 143.6C126.76 143.6 125.82 144.54 125.82 145.7C125.82 146.859 126.76 147.8 127.92 147.8Z"
        fill="#F3C94E"
      />
      <path
        d="M121.077 147.8C122.236 147.8 123.177 146.859 123.177 145.7C123.177 144.54 122.236 143.6 121.077 143.6C119.917 143.6 118.977 144.54 118.977 145.7C118.977 146.859 119.917 147.8 121.077 147.8Z"
        fill="#EA0000"
      />
      <path
        d="M249.683 181.371H141.883C141.053 181.371 140.383 180.701 140.383 179.871C140.383 179.041 141.053 178.371 141.883 178.371H249.683C250.513 178.371 251.183 179.041 251.183 179.871C251.183 180.701 250.513 181.371 249.683 181.371Z"
        fill="#243C98"
      />
      <path
        d="M230.484 195.471H161.094C160.264 195.471 159.594 194.801 159.594 193.971C159.594 193.141 160.264 192.471 161.094 192.471H230.484C231.314 192.471 231.984 193.141 231.984 193.971C231.984 194.801 231.304 195.471 230.484 195.471Z"
        fill="#243C98"
      />
      <path
        d="M230.589 227.451H160.979C156.359 227.451 152.609 223.701 152.609 219.081C152.609 214.461 156.359 210.711 160.979 210.711H230.589C235.209 210.711 238.959 214.461 238.959 219.081C238.959 223.701 235.209 227.451 230.589 227.451Z"
        fill="#B3DAFF"
      />
      <path
        d="M230.589 228.951H160.979C155.539 228.951 151.109 224.521 151.109 219.081C151.109 213.641 155.539 209.211 160.979 209.211H230.589C236.029 209.211 240.459 213.641 240.459 219.081C240.459 224.521 236.029 228.951 230.589 228.951ZM160.969 212.211C157.179 212.211 154.099 215.291 154.099 219.081C154.099 222.871 157.179 225.951 160.969 225.951H230.579C234.369 225.951 237.449 222.871 237.449 219.081C237.449 215.291 234.369 212.211 230.579 212.211H160.969Z"
        fill="#243C98"
      />
    </SvgIcon>
  )
}
